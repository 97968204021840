import { getEmotionLevelData } from "@utils/helpers";
import React from "react";
import styled, { css } from "styled-components/macro";
import CustomTooltip from "../../components/CustomTooltip";
import { FlexAlignCenter } from "shared/ui-basics/index";

export default function EmotionLevel({
	variant,
	level,
	size = "m",
	isActive,
	isRounded = true,
	isRoundedLeft,
	isRoundedRight,
    showBorder,
    borderSize = "2px",
	borderType = "solid",
	tooltipText,
	showTooltip = true,
	handleClick,
	id,
}) {
	const data = getEmotionLevelData({ level:Math.round(level) });
	const isClickable = handleClick ? true : false;

	const displayEmotion = (variant) => {
		if (variant === "smiley")
			return (
				<CustomTooltip text={tooltipText ? tooltipText : data?.description} disabled={!showTooltip} >
					<Smiley
					id={id}
						isclickable={isClickable}
						onClick={handleClick}
						size={isActive ? "l" : size}
						isactive={isActive}
						isrounded={isRounded}
						isroundedleft={isRoundedLeft}
						isroundedright={isRoundedRight}
						showborder={showBorder}
                        bordercolor={data?.borderColor}
                        bordersize={ borderSize}
						bordertype={borderType}
						background={data?.backgroundColor}>
						{data?.smiley}
					</Smiley>
				</CustomTooltip>
			);
		if (variant === "full")
			return (
				<CustomTooltip text={tooltipText} disabled={!tooltipText}>
					<FlexAlignCenter>
						<Smiley size={size} isroundedleft background={data?.backgroundColor}>
							{data?.smiley}
						</Smiley>
						<Container size={size} textcolor={data?.textColor} background={data?.backgroundColor}>
							<div className="mx-2">{Number(level).toFixed(1)}</div>
							<Description className="me-2">{data?.description}</Description>
						</Container>
					</FlexAlignCenter>
				</CustomTooltip>
			);
		if (variant === "score")
			return (
				<CustomTooltip text={tooltipText} disabled={!tooltipText}>
					<FlexAlignCenter>
						<Smiley size={size} isroundedleft background={data?.backgroundColor}>
							{data?.smiley}
						</Smiley>
						<Container size={size} textcolor={data?.textColor} background={data?.backgroundColor}>
							<div className="mx-2">{Number(level).toFixed(1)}</div>
						</Container>
					</FlexAlignCenter>
				</CustomTooltip>
			);
		if (variant === "description")
			return (
				<CustomTooltip text={tooltipText} disabled={!tooltipText}>
					<FlexAlignCenter>
						<Smiley size={size} isroundedleft background={data?.backgroundColor}>
							{data?.smiley}
						</Smiley>
						<Container size={size} textcolor={data?.textColor} background={data?.backgroundColor}>
							<Description className="mx-2">{data?.description}</Description>
						</Container>
					</FlexAlignCenter>
				</CustomTooltip>
			);
		else return <></>;
	};

	return <>{displayEmotion(variant)}</>;
}

const Container = styled.div`
	display: flex;
	align-items: center;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border: 1px solid ${(props) => props.background};
	color: ${(props) => props.textcolor};
	${(props) =>
		props.size === "xxs" &&
		css`
            height: 20px
			font-size: 12px;
		`}

	${(props) =>
		props.size === "xs" &&
		css`
            height: 24px
			font-size: 14px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			min-width: 36px;
			height: 36px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 40px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			font-size: 24px;
		`}
`;

const Smiley = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: ${(props) => props.background};

	${(props) =>
		props.showborder &&
		css`
			border: ${(props) => props.bordersize} ${(props) => props.bordertype} ${(props) => props.bordercolor};
		`}

	${(props) =>
		props.isclickable &&
		css`
			:hover {
				cursor: pointer;
			}
		`}

	${(props) =>
		props.isactive &&
		css`
			filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
		`}

	${(props) =>
		props.isrounded &&
		css`
			border-radius: 4px;
		`};

	${(props) =>
		props.isroundedleft &&
		css`
			border-radius: 0px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		`};

	${(props) =>
		props.isroundedright &&
		css`
			border-radius: 0px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		`};
	svg {
		width: 14px;
		height: 14px;
	}
    ${(props) =>
		props.size === "xxs" &&
		css`
			height: 20px;
			width: 20px;
			svg {
				width: 10px !important;
				height: 10px !important;
			}
		`}

	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			width: 24px;
			svg {
				width: 14px !important;
				height: 14px !important;
			}
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			width: 32px;
			svg {
				width: 16px !important;
				height: 16px !important;
			}
		`}
    ${(props) =>
		props.size === "m" &&
		css`
			height: 36px;
			width: 36px;
			svg {
				width: 18px !important;
				height: 18px !important;
			}
		`}
    ${(props) =>
		props.size === "l" &&
		css`
			height: 40px;
			width: 40px;
			svg {
				width: 20px !important;
				height: 20px !important;
			}
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			width: 48px;
			svg {
				width: 24px !important;
				height: 24px !important;
			}
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			width: 60px;
			svg {
				width: 28px !important;
				height: 28px !important;
			}
		`}
`;

const Description = styled.div`
	text-align: center;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
`;
