import { IconDispatcher } from "shared/Icons";
import { RestrictUserAccess, getItemStyleSwimlanes, getListStyleSwimLanes, handleAddRenderElement } from "@utils/helpers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useEffect } from "react";

export default function VerticalDND({ state, CardsValidationRender, className, reordingTable, handleStyle }) {
	const current_user = useSelector((state) => state.maps.currentUser);
	const isLocked = useSelector((state) => state.maps.current_map.isLocked && !state.maps.current_map.isTemplate);
	const location = useLocation().search;
	const hideForPdf = location.hideForPdf();
	const inMap = useLocation().pathname === "/EditMap";
	const restrictAccess = RestrictUserAccess(current_user?.roleName?.toLowerCase(), ["viewer", "contributor"]) || isLocked || hideForPdf;

	function onDragEnd(result) {
		const { source, destination } = result;

		if (!destination) {
			return;
		}
		reordingTable(source.index, destination.index);
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={"droppableId"}>
				{(provided, snapshot) => (
					<div className={className + " p-0 "} ref={provided.innerRef} style={getListStyleSwimLanes(snapshot.isDraggingOver)} {...provided.droppableProps}>
						{state &&
							state?.map((item, index) => (
								<Draggable className="position-relative " key={item?.id} draggableId={item?.key || item?.id} index={index}>
									{(provided, snapshot) => (
										<div ref={provided.innerRef} {...provided.draggableProps} style={getItemStyleSwimlanes(snapshot.isDragging, provided.draggableProps.style, inMap)}>
											<Container restrictAccess={restrictAccess}>
												<div className="position-relative">
													<Handle {...provided.dragHandleProps} className="handle" style={{ ...handleStyle }} onMouseDown={(e) => e.currentTarget.focus()}>
														{IconDispatcher("DRAG", "drag")}{" "}
													</Handle>
												</div>
												<CardsValidationRender item={item} index={index} />
											</Container>
											{hideForPdf && index === state.length - 1 && <div id="addRenderElement">{handleAddRenderElement(5000)} </div>}{" "}
										</div>
									)}
								</Draggable>
							))}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

const Handle = styled.div`
	position: absolute;
	width: 35px;
	top: 8px;
	border: 0;
	z-index: 60;
	left: 4px;
	visibility: hidden;
	${(props) => (props.hide ? "display: none;" : "")}
`;

const Container = styled.div`
	position: relative;
	padding: 0;
	:hover ${Handle} {
		visibility: ${(props) => (props.restrictAccess ? "hidden" : "inherit")};
	}
`;
