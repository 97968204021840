import { success, error } from "@redux-requests/core";
import { appendListsAndRemoveDuplicates, decode, EMPTY_GUID, firstLetterUppercase, hasAttribute, toastWrapper } from "@utils/helpers";
import * as Helpers from "./helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets";

const initialState = {
	current_program: {},
	all_cxPrograms: [],
	current_linked_users: [],
	loading: false,
};

const CxProgramReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const responseData = action?.response?.data;
	switch (action.type) {
		case "CX_PROGRAM_GET_ALL":
			return {
				...state,
				loading: state.all_cxPrograms.length === 0,
			};

		case error("CX_PROGRAM_GET_ALL"):
			return {
				...state,
				loading: false,
			};
		case success("CX_PROGRAM_GET_ALL"): {
			const { url } = action?.meta?.requestAction?.request;
			if (url.includes("redirectReducer")) return state;

			return {
				...state,
				all_cxPrograms:
					action.response.data.map((cx) => {
						const CxNumbers = [
							{ title: "JS_71", linkName: "linkedCjms", icon: "CJM", color: "#3F3D56" },
							{ title: "CS_31", linkName: "linkedPersonas", icon: "PERSONA", color: "#18AEAD" },
							{ title: "OPPORTUNITIES", linkName: "linkedOpps", icon: "OPPORTUNITY", color: "#818089" },
							{ title: "SOLUTIONS", linkName: "linkedSols", icon: "SOLUTION", color: "#60C1EF" },
							{ title: "JS_74", linkName: "linkedActions", icon: "MENU-ACTION", color: "#F8AD48" },
							{ title: "PROJECTS", linkName: "linkedProjects", icon: "PROJECT", color: "#F8AD48" },
						];
						cx.CxNumbers = CxNumbers;
						return cx;
					}) || [],
				loading: false,
			};
		}
		case success("GET_STRATEGY_CX_PROGRAM"): {
			const strategyId = action.response.data;
			return {
				...state,
				current_program: { ...state.current_program, strategyId },
			};
		}
		case "GET_CX_PROGRAM":
			return {
				...state,
				loading: true,
			};
		case "NO_ACCESS_cxProgram": {
			return {
				...state,
				current_program: { ...state.current_program, noAccess: true },
			};
		}
		case success("GET_CX_PROGRAM"): {
			const data = action.response.data;
			const CxNumbers = [
				{ title: "JS_71", linkName: "linkedCjms", icon: "CJM", color: "#3F3D56" },
				{ title: "CS_31", linkName: "linkedPersonas", icon: "PERSONA", color: "#18AEAD" },
				{ title: "OPPORTUNITIES", linkName: "linkedOpps", icon: "OPPORTUNITY", color: "#818089" },
				{ title: "SOLUTIONS", linkName: "linkedSols", icon: "SOLUTION", color: "#60C1EF" },
				{ title: "JS_74", linkName: "linkedActions", icon: "MENU-ACTION", color: "#F8AD48" },
				{ title: "PROJECTS", linkName: "linkedProjects", icon: "PROJECT", color: "#F8AD48" },
			];
			data.attributesDic = data.attributesDic === null ? [] : Object.entries(data.attributesDic)?.map((att) => att[1]);
			data.attributesPositions = eval(data?.attributesPositions || "");
			data.CxNumbers = CxNumbers;
			return {
				...state,
				current_program: { ...state.current_program, ...data },
				loading: false,
			};
		}
		case error("GET_CX_PROGRAM"):
			return {
				...state,
				loading: false,
			};
		case "RESET_PROGRAM":
			return {
				...state,
				current_program: {},
				loading: false,
			};

		case success("CREATE_CX_PROGRAM"):
			return {
				...state,
				all_cxPrograms: [...state.all_cxPrograms, action.response.data],
			};
		case success("UPDATE_CX_PROGRAM"): {
			const resData = action.response.data;
			const modifiedAttribNames = action.meta.requestAction.request.data.modifiedAttribNames;
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (cxProgram.id === action.meta.requestAction.request.data.modifiedCxProgram.id) {
						cxProgram = resData;
					}
					return cxProgram;
				}),
				current_program: modifiedAttribNames.includes("name") ? { ...state.current_program, name: resData.name } : state.current_program,
			};
		}
		case success("RENAME_CX_PROGRAM"):
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (cxProgram.id === action.meta.requestAction.request.data.id) {
						return {
							...cxProgram,
							name: decode(action.meta.requestAction.request.data.newValue),
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return cxProgram;
				}),
				current_program: {
					...state.current_program,
					name: decode(action.meta.requestAction.request.data.newValue),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("COPY_CX_PROGRAM"): {
			const data = action.response.data;
			const CxNumbers = [
				{ title: "JS_71", linkName: "linkedCjms", icon: "CJM", color: "#3F3D56" },
				{ title: "CS_31", linkName: "linkedPersonas", icon: "PERSONA", color: "#18AEAD" },
				{ title: "OPPORTUNITIES", linkName: "linkedOpps", icon: "OPPORTUNITY", color: "#818089" },
				{ title: "SOLUTIONS", linkName: "linkedSols", icon: "SOLUTION", color: "#60C1EF" },
				{ title: "JS_74", linkName: "linkedActions", icon: "MENU-ACTION", color: "#F8AD48" },
				{ title: "PROJECTS", linkName: "linkedProjects", icon: "PROJECT", color: "#F8AD48" },
			];
			data.CxNumbers = CxNumbers;
			return {
				...state,
				all_cxPrograms: [...state.all_cxPrograms, action.response.data],
			};
		}
		case success("ARCHIVE_CX_PROGRAM"):
			return {
				...state,
				all_cxPrograms: state?.all_cxPrograms?.map((cxProgram) => {
					if (cxProgram.id === action.meta.requestAction.request.data.id) {
						return {
							...cxProgram,
							statusCode: StatusCode.Archived,
						};
					}
					return cxProgram;
				}),
			};
		case success("ARCHIVE_MANY_CX_PROGRAMS"):
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (action.response.data.includes(cxProgram.id)) {
						cxProgram.statusCode = StatusCode.Archived;
					}
					return cxProgram;
				}),
			};
		case success("RESTORE_CX_PROGRAM"):
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms.map((cxProgram) => {
					if (cxProgram.id === action.meta.requestAction.request.data.id) {
						cxProgram.statusCode = StatusCode.Active;
					}
					return cxProgram;
				}),
			};
		case success("RESTORE_MANY_CX_PROGRAMS"):
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (action.response.data.includes(cxProgram.id)) {
						cxProgram.statusCode = StatusCode.Active;
					}
					return cxProgram;
				}),
			};
		case success("DELETE_CX_PROGRAM"):
			return {
				...state,
				all_cxPrograms: state?.all_cxPrograms?.map((cxProgram) => {
					if (cxProgram.id === action.meta.requestAction.request.data.id) {
						return {
							...cxProgram,
							statusCode: StatusCode.Trashed,
						};
					}
					return cxProgram;
				}),
			};
		case success("DELETE_MANY_CX_PROGRAMS"):
			return {
				...state,
				all_cxPrograms: state?.all_cxPrograms?.map((cxProgram) => {
					if (action.response.data.includes(cxProgram.id)) {
						cxProgram.statusCode = StatusCode.Trashed;
					}
					return cxProgram;
				}),
			};
		case success("DELETE_CX_PROGRAM_PERMANENT"):
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms.filter((cxProgram) => cxProgram.id !== action.meta.requestAction.request.data.id),
			};
		case success("DELETE_MANY_CX_PROGRAMS_PERMANENT"):
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms.filter((cxProgram) => !action.response.data.includes(cxProgram.id)),
			};
		case "UPDATE_CX_PROGRAM_ORDER": {
			const newConfig = JSON.parse(action.request.data.newPositions) || "";
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesPositions: newConfig,
				},
			};
		}
		case success("UPDATE_CX_PROGRAM_ORDER"):
			return {
				...state,
				current_program: {
					...state.current_program,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("ADD_ATTRIBUTE"): {
			const newAtt = [
				...state.current_program.attributesDic.map((x) => {
					return {
						...x,
						setFocus: false,
					};
				}),
			];
			newAtt?.push({ ...action.response?.data, setFocus: true });
			const newPosition = {
				w: 1,
				h: 240,
				x: 0,
				y: Infinity,
				i: action.response?.data.id,
				minH: 240,
				// maxH: 220,
				moved: false,
				static: false,
			};
			if (responseData?.id === EMPTY_GUID) {
				return state;
			}

			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: newAtt,
					attributesPositions: [...state.current_program.attributesPositions, newPosition],
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("DELETE_ATTRIBUTE"): {
			const attributeID = action.meta.requestAction.request.data.attributeId;
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.filter((att) => {
						return att.id !== attributeID;
					}),
					attributesPositions: state.current_program?.attributesPositions?.filter((att) => {
						return att.i !== attributeID;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("UPDATE_CXPROGRAM_ATTRIBUTE_COLOR"): {
			const { newColor, attributeId } = requestData;
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((att) => {
						if (att.id === attributeId) {
							att.colorCode = newColor;
						}
						return att;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}

		case success("UPDATE_CXPROGRAM_ATTRIBUTE_NAME"): {
			const attributeIdStr_name = action.meta.requestAction.request.data.attributeId;
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((att) => {
						if (att.id === attributeIdStr_name) {
							att.name = action?.response.data?.name;
						}
						return att;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("UPDATE_CXPROGRAM_ATTRIBUTE_DESC"): {
			const attributeIdStr_desc = action.meta.requestAction.request.data.attributeId;
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((att) => {
						if (att.id === attributeIdStr_desc) {
							att = action.response.data;
						}
						return att;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPSERT_CXPROGRAM_ATTRIBUTE_ITEM": {
			const { attributeItem, attribute, cxGoal } = action.request.data;
			const upsertItem = {
				...attributeItem,
				name: decode(attributeItem?.name ?? ""),
				description: decode(attributeItem?.description ?? ""),
				cxGoalId: cxGoal?.id
			};
			const validateItems = (items) => {
				if (items.find((item) => item.id === upsertItem.id)) {
					return items.map((item) => {
						if (item.id === upsertItem.id) {
							item = { ...upsertItem };
						}
						return item;
					});
				}
				return [...items, upsertItem];


			}
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((att) => {
						if (att.id === attribute.id) {
							att.items = validateItems(att.items);
						}

						return att;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "DELETE_CXPROGRAM_ATTRIBUTE_ITEM": {
			const { attributeId, attributeItemId } = action.request.data;
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((section) => {
						if (section.id === attributeId) {
							section.items = section.items.filter((item) => item.id !== attributeItemId);
						}
						return section;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}

		case "UPDATE_CXPROGRAM_ATTRIBUTE_ITEM_ORDER": {
			const itemsIds = action.request.data.newItemsOrder.split("|");
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((section) => {
						if (section.id === action.request.data.attributeIdStr) {
							section.items = itemsIds.map((id) => section.items.find((i) => i.id === id)).flat();
						}
						return section;
					}),
				},
			};
		}
		case success("UPDATE_CXPROGRAM_ATTRIBUTE_ITEM_ORDER"):
			return {
				...state,
				current_program: {
					...state.current_program,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("SHARE_USERS_SINGLE_CX_PROGRAM"): {
			const cxProgId = action.meta.requestAction.request.data.recId;
			const roleDetails = action.meta.requestAction.request.data.roleDetails;
			const userId_ = action.meta.requestAction.request.data?.userId || action.meta.requestAction.request.data?.teamId;

			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (cxProgram.id === cxProgId) {
						return {
							...cxProgram,
							authorizedUsers: cxProgram.authorizedUsers.map((user) => {
								if (user.id === userId_) {
									user.roleId = roleDetails.split("_")[1];
									user.roleName = roleDetails.split("_")[0];
								}
								return user;
							}),
						};
					}
					return cxProgram;
				}),

				current_linked_users: state.current_linked_users.map((user) => {
					if (user.id === userId_) {
						user.roleId = roleDetails.split("_")[1];
						user.roleName = roleDetails.split("_")[0];
					}
					return user;
				}),
			};
		}
		case success("SHARE_USERS_MANY_CX_PROGRAMS"): {
			const recIds = action.meta.requestAction.request.data.recIds;
			let linkUsers = [...state.current_linked_users];
			if (recIds.length === 1) {
				linkUsers = action.response.data.find((item) => item.id === recIds[0]).authorizedUsers;
			}
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					const getMapFromResponse = action.response.data.find((item) => item.id === cxProgram.id);
					if (getMapFromResponse) {
						return {
							...getMapFromResponse,
						};
					}
					return cxProgram;
				}),
				current_linked_users: linkUsers,
			};
		}
		case success("UNSHARE_CX_PROGRAM"): {
			const { recId, userId, teamId } = requestData;
			const id = teamId || userId;
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (cxProgram.id === recId) {
						return {
							...cxProgram,
							authorizedUsers: cxProgram.authorizedUsers.filter((user) => user.id !== id),
						};
					}
					return cxProgram;
				}),
				current_linked_users: state.current_linked_users.filter((user) => user.id !== id),
			};
		}
		case success("GET_CXPROGRAM_USERS"): {
			const isPrint = action.meta.requestAction.params.isPrint;
			const userId = action.meta.requestAction.params.userId;

			const linkedUsers = Object.entries(action.response.data).map((user) => user[1]) || [];
			const user = linkedUsers.find((users) => users.id === userId && users.roleId === EMPTY_GUID);
			if (hasAttribute(user, "roleName")) user.roleName = isPrint ? "viewer" : firstLetterUppercase(user?.roleName); // only to export pdf
			if (user?.roleName?.toLowerCase() === "viewer" && isPrint === undefined) toastWrapper("", "CX_PROGRAM_VIEWER_WARNING", { autoClose: 10000 });
			return {
				...state,
				current_linked_users: linkedUsers,
				currentUser: user,
			};
		}
		case success("LINK_RECORD"):
		case success("UNLINK_RECORD"): {
			const program = { ...state.current_program };
			program.linkedCjms = action.response.data[0];
			program.linkedPersonas = action.response.data[1];
			program.linkedOpps = action.response.data[2];
			program.linkedSols = action.response.data[3];
			program.linkedActions = action.response.data[4];
			program.linkedProjects = action.response.data[5];
			return { ...state, current_program: program };
		}
		case success("UPSERT_CXPROGRAM_STAKEHOLDER"): {
			if (requestData.stakeholder.id === EMPTY_GUID) {
				return {
					...state,
					current_program: {
						...state.current_program,
						stakeholders: [...state.current_program.stakeholders, { ...action.response.data }],
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return {
				...state,
				current_program: {
					...state.current_program,
					stakeholders: state.current_program.stakeholders.map((stakeholder) => {
						if (stakeholder.id === requestData.stakeholder.id) stakeholder = responseData;
						return stakeholder;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};

		}
		case success("DELETE_CXPROGRAM_STAKEHOLDER"):
			return {
				...state,
				current_program: {
					...state.current_program,
					stakeholders: state.current_program.stakeholders.filter((stakeholder) => stakeholder.id !== requestData.stakeholderId),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("DELETE_STAKEHOLDER_ROLE"):
			return {
				...state,
				current_program: {
					...state.current_program,
					stakeholders: state.current_program?.stakeholders?.filter((stakeholder) => stakeholder.roleId !== requestData.roleId),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_STAKEHOLDER_ROLE"):
			return {
				...state,
				current_program: {
					...state.current_program,
					stakeholders: state.current_program?.stakeholders?.map((stakeholder) => {
						if (stakeholder.roleId === requestData.roleId) {
							stakeholder.roleName = decode(requestData.newName);
						}
						return stakeholder;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("DELETE_CX_METRIC"):
			return {
				...state,
				current_program: {
					...state.current_program,
					attributesDic: state.current_program?.attributesDic?.map((attribute) => {
						if (attribute.type === 100000019) {
							attribute.items.map((item) => {
								item.metrics = item.metrics.filter((metricId) => metricId !== requestData.cxMeasureId);
								return item;
							});
						}
						return attribute;
					}),
				},
			};
		case success("GET_ALL_CX_ASSESSMENTS"):
			return Helpers.updateCxProgramState(state, "cxAssessments", action?.response?.data);
		case success("CREATE_CX_ASSESSMENT"): {
			const { cxAssessments = [] } = state.current_program;
			return Helpers.updateCxProgramState(state, "cxAssessments", [...cxAssessments, action?.response?.data]);
		}
		case success("UPDATE_CX_ASSESSMENT"): {
			const cxAssessments = state.current_program.cxAssessments.map((assessment) => {
				if (assessment.id === requestData.modifiedCxAssessment.id) assessment = action?.response?.data;
				return assessment;
			});
			return Helpers.updateCxProgramState(state, "cxAssessments", [...cxAssessments, action?.response?.data]);
		}
		case success("DELETE_CX_ASSESSMENTS"): {
			const filteredAssessments = state.current_program.cxAssessments.filter((assessment) => !requestData.cxAssessmentIds.includes(assessment.id));
			return Helpers.updateCxProgramState(state, "cxAssessments", [...filteredAssessments]);
		}

		case success("LINK_TAGS"): {
			const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_cxprogram") return state;
			return {
				...state,
				all_cxPrograms: state.all_cxPrograms?.map((cxProgram) => {
					if (recordIds.includes(cxProgram.id)) {
						cxProgram.tags = [...appendListsAndRemoveDuplicates(cxProgram.tags, tagIds)];
					}
					return cxProgram;
				}),
			};
		}
		case "RESET_CXPROGRAMS_USERS": {
			return {
				...state,
				current_linked_users: null,
			};
		}

		default:
			return state;
	}
};
export default CxProgramReducer;
