import { NotNullOrEmpty, applyFilters, emptyListData, filterUnSelect, joinByProperty } from "@utils/helpers";
import { setFilterIdsService, setFiltersService, setSelectedIdTable, setSortedService } from "features/grid-table/reducer/TableSlice";
import { get } from "lodash";
import { Column } from "primereact/column";
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { EmptyList } from "shared/ui-basics/ui-components/EmptyList";
import { Spinner } from "../../shared/components/spinners/Spinner";
import { TableColumnsManager } from "./TableColumnsManager";
import * as Helpers from "./TableHelpers";
import * as Style from "./columns-components/Styles";
import { FloatingToolbar } from "./components/TableSettings";
import { HeaderTable } from "./header/HeaderTable";

const DataTable = forwardRef((props, ref) => {
	const { context } = props;
	const folderId = useLocation().search.getFolderId();

	const { contextData, rename, setRename, disableBulkActions, setDisableBulkActions } = Helpers.useDataTableState(props);
	const getContext = Helpers.useGetContext(context);
	const loadTableWithSettings = Helpers.useLoadTableWithSettings(contextData, context);
	const savedDefaultViews = Helpers.useDefaultViews();
	const visibleItems = contextData?.showOnlyLinked ? contextData?.linkedObjects : contextData?.listData;

	useEffect(() => {
		loadTableWithSettings();
	}, [savedDefaultViews, folderId]);


	if (contextData?.loading) return <Spinner />;

	if (!NotNullOrEmpty(visibleItems)) {
        const route = getContext(0);
		const { icon, color } = emptyListData[route] || emptyListData.map;
		contextData.emptyListIconColor = color;
		contextData.emptyListIcon = icon;
		const className = ["editPersona", "cxProgram"].includes(getContext(1)) ? "h-100 align-self-center p-3" : "mt-5 pt-5";
		return (
			<div className={className}>
				<EmptyList data={contextData} />
			</div>
		);
	}

	const combinedProps = {
		...props,
		contextData,
		rename,
		setRename,
		disableBulkActions,
		setDisableBulkActions,
	};
	return <Table {...combinedProps} ref={ref} />;
});

const Table = memo(
	forwardRef((props, ref) => {
        const { contextData, setDisableBulkActions } = props;
		const { listData, showCheckbox, showRadioButton, bulkActions, dataKey, entityType, filter  } = contextData || {};
		const { visibleItems, selectedInitialState } = Helpers.getInitialStates(props, contextData);
		const context = Helpers.useGetContext(props.context)(1);
		const sort = useSelector((state) => get(state, ["ui", "table", "entity", entityType, context, "sort"]));
		const filtersIds = useSelector((state) => get(state, ["ui", "table", "entity", entityType, context, "filteredIds"]));

		const [selected, setSelected] = useState(selectedInitialState);
		const [range, setRange] = useState([0, 5]);
		const [dataByFilter, setDataByFilter] = useState("");
		const [forceRefresh, setForceRefresh] = useState(0);

		const tableColumns = TableColumnsManager({ ...props, range, setRange });
		const filters = filter || Helpers.filterInit(contextData?.columns, tableColumns);

		useImperativeHandle(ref, () => ({
			exportRelatedExcelClick(id, entityName) {
				contextData?.getLinkedDataExportExcel(id, entityName);
			},
			selected: selected,
			numberOfItems: listData.length,
			toLink: !showRadioButton && selected?.ArrayToIdsStr(dataKey),
			unLink: !showRadioButton && filterUnSelect(selected, props.linked, dataKey).join("|"),
			updateFilter: (value) => {
				let _filters1 = JSON.parse(JSON.stringify(filters));
				_filters1.global.value = value;
				setFiltersService({ context, value: _filters1, entityType });
			},
			singleSelected: selected,
        }));

		useEffect(() => {
			handleFilter();
		}, []);

		function handleFilter(fil) {
			const filter = fil ? fil : filters;
			// requestAnimationFrame(() => {
				if (visibleItems) {
					const filteredItems = applyFilters(visibleItems, filter);
					updateFilter(filteredItems);
					setDataByFilter(joinByProperty(filteredItems, dataKey));
					if(fil) setForceRefresh(forceRefresh + 1);
				}
			// });
		}

		function updateFilter(filteredItems) {
			const filteredId = joinByProperty(filteredItems, dataKey, "none");
			if(filtersIds?.join("|") !== filteredId?.join("|"))
				setFilterIdsService({ context: context, filteredIds:filteredId, entityType });
		}

		const onSuccess = () => {
			setDisableBulkActions(false);
			setSelected(null);
		};

		const handleSelectionChange = (e) => {
			if (e.type === "all") {
				const dataFiltered = visibleItems.filter(item => filtersIds.includes(item?.[dataKey]))

				setSelected(e.value.length > 0 ? dataFiltered : []);
				setSelectedIdTable(e.value.length > 0 ? dataFiltered : []);
			} else {
				setSelected(e.value);
				setSelectedIdTable(e.value);
			}

			props?.setDisabled && props.setDisabled(false);
		};

		const handleChangeValue = (filteredData) => {
			updateFilter(filteredData);
			setDataByFilter(joinByProperty(filteredData, dataKey));
		};

		const handleChangeFilter = ({ filters }) => {
			setFiltersService({ context, value: filters, entityType });
		};

		const handleChangeSort = ({ sortField, sortOrder }) => {
			setSortedService({ context, sortField, sortOrder, entityType });
		};

		const getSelectionMode = () => {
			if (showRadioButton) return "radiobutton";
			if (showCheckbox) return "checkbox";
			return null;
		};
		const DataTableParameters = {
			responsiveLayout: "scroll",
			value: visibleItems,
			dataKey: dataKey,
			selection: selected,
			onSelectionChange: handleSelectionChange,
			...Helpers.getVirtualScroller(context, visibleItems),
			selectionMode: getSelectionMode(),
			onValueChange: handleChangeValue,
			filters: filters,
			onFilter: handleChangeFilter,
			sortField: sort?.sortField,
			sortOrder: sort?.sortOrder,
			onSort: handleChangeSort,
			resizableColumns: true,
			columnResizeMode: "expand",
			rows: 10,
			globalFilterFields: !contextData.noGlobalSearch ? Helpers.extractGlobalFilters(contextData?.columns) : undefined,
			header: contextData?.visibleHeader ? HeaderTable({ contextData, props, dataByFilter, selected, filters, handleFilter ,visibleItems,updateFilter}) : null,
			nocolumnheader: contextData?.noColumnHeader?.toString(),
			disablefixheader: contextData?.disableFixHeader?.toString(),
			totalRecords: listData?.length,
			selectionAutoFocus: false,
			isheadervisible: contextData?.visibleHeader?.toString() || "false",
			context : context,
			readOnly : contextData?.readonly
		};
		const columns = contextData.columns?.map((col) => tableColumns[col]);
		return (
			<Style.MainDataTableContainer small={contextData?.emptyListSmall} nonDropDown={contextData?.nonDropDown?.toString()} context={context} key={forceRefresh}>
			<div className="">
					{NotNullOrEmpty(selected) && bulkActions && context !== "cxActionsListGroup" && (
						<FloatingToolbar right={() => contextData.bulkActionsFunction(selected, setSelected, onSuccess, setDisableBulkActions)}></FloatingToolbar>
					)}
					{visibleItems.length > 0 && (
						<Style.DataTableCustom {...DataTableParameters}>
							{columns?.map((col, i) => (
								<Column id={col?.field} key={i} {...col} />
							))}
						</Style.DataTableCustom>
					)}
				</div>
			</Style.MainDataTableContainer>
		);
	})
);

DataTable.displayName = "DataTable";
Table.displayName = "Table";
export default memo(DataTable);
