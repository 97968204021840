import { SentimentButtonsGroup } from 'shared/ui-basics/buttons';
import GenericPopover from 'shared/popovers/GenericPopover';
import EmotionLevel from 'shared/ui-basics/ui-components/EmotionLevel';
import React, { useRef } from 'react'
import PropTypes from 'prop-types';

function Score({ interaction, setInteraction, updateFunction, disabled }) {

    const { emotionLevel } = interaction;
    const innerRef = useRef(null);

    const handleClick = (lvl) => {
        const level = lvl - 1
        const updatedLevel = level === emotionLevel ? -1 : level // if the same level is clicked, set to -1
        const updatedInteraction = { ...interaction, emotionLevel: updatedLevel }
        setInteraction(updatedInteraction);
        updateFunction && updateFunction({ interaction: updatedInteraction })
        innerRef?.current?.hide_popover();

    }
    const custom = (<div className="p-2"><SentimentButtonsGroup emotionLevel={emotionLevel + 1} handleClick={handleClick} /></div>)
    return (
        <div>
            <GenericPopover custom={custom} disabled={disabled} innerRef={innerRef} >
                <EmotionLevel variant="smiley" level={emotionLevel + 1} size="xs" />
            </GenericPopover>
        </div>
    )
}
Score.displayName = "Score_EditInteraction";
Score.propTypes = {
    interaction: PropTypes.object,
    updateFunction: PropTypes.func,
}
export default Score;