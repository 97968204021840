import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Sidebar } from "primereact/sidebar";
import VersionHistoryCard from "./VersionHistoryCard";
import { useLocation } from "react-router-dom";
import { getMapVersionsSorted } from "@redux/memoizedSelectors";

const MapVersionsHistory = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const { cjmIdStr: currentMapId } = useLocation().search.getAllMapsParams();

	const allVersionsSorted = useSelector(getMapVersionsSorted({ currentMapId }));

	return (
		<CustomSidebar visible={isOpen} onHide={onClose} baseZIndex={900} position={"right"}>
			<Header className="p-2" closeButton>
				<Title>{t("VERSIONS_HISTORY")}</Title>
			</Header>
			<Body className="p-0 m-0">
				{allVersionsSorted.map((map, i) => (
					<VersionHistoryCard key={i} record={map} onClose={onClose} />
				))}
			</Body>
		</CustomSidebar>
	);
};

export default MapVersionsHistory;

const CustomSidebar = styled(Sidebar)`
	width: 480px !important;
	.p-sidebar-header {
		position: absolute;
		right: -10px;
		top: -15px;
	}
	.p-sidebar-icon {
		border-radius: 4px !important;
	}
	.p-sidebar-icon:focus {
		box-shadow: none !important;
	}
	.p-sidebar-content {
		display: flex;
		flex-direction: column;
		padding: 0px;
	}
`;

const Header = styled.div`
	border-bottom: 1px solid #c3c3c3;
`;

const Title = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
`;

const Body = styled.div``;
