import { Input } from "reactstrap";
import styled from "styled-components/macro";

export const Checkbox = styled(Input)`
	:hover {
		cursor: pointer;
	}
	:checked {
		background-color: #333333;
		border-color: #333333;
	}
	:focus {
		box-shadow: none;
		border-color: ${(props) => (props.disabled === "" ? "#F96209" : "#c4c4c4")};
		background-image: none;
	}

	${(props) =>
		props.sze === "md" &&
		`
   width: 20px;
   height: 20px;
   min-width: 20px;
    min-height: 20px;
   `}
	${(props) =>
		props.sze === "sm" &&
		`
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;

    `}
    ${(props) =>
		props.sze === "lg" &&
		`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    `}
`;
