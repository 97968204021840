import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { BenefitCard } from "features/solutions/solutionsComponents/BenefitCard.js";
import { SolutionType } from "@utils/optionsSets/OptionSets";
import { Badge, BadgeWrapper, RemoveButton } from "./PicklistCard_style";
const { GenericButton } = require("shared/ui-basics/buttons");

export const SolutionItemCard = ({ entity, unlink, openRoute, disable, index,length }) => {
	const SolutionTypeLabel = () => {
		let labelSolution;
		switch (entity?.solutionType) {
			case SolutionType.QuickWin:
				labelSolution = {
					variant: "quickWin",
					icon: "LIKE",
					text: "QUICK_WIN"
				}
				break;
			case SolutionType.NiceToHave:
				labelSolution = {
					variant: "niceToHave",
					icon: "STAR-LIFE",
					text: "NICE_TO_HAVE"
				}
				break;
			case SolutionType.ComplexProject:
				labelSolution = {
					variant: "complexProj",
					icon: "PUZZLE",
					text: "COMPLEX_PROJ"
				}
				break;
			case SolutionType.ToAvoid:
				labelSolution = {
					variant: "toAvoid",
					icon: "ROAD-BARRIER",
					text: "TO_AVOID"
				}
				break;
			default:
				return null;
		}
		return <BenefitCard classes={"px-2 mx-2"} isActive={true} variant={labelSolution.variant} icon={labelSolution.icon} text={labelSolution.text} />;
	};
	return (
		<Badge key={entity?.id} id={entity?.id}>
			<BadgeWrapper index={index} length={length}>
				<div className="ellipsis obj-name px-2" id={entity?.id} onClick={(e) => openRoute(entity?.id, '/edit-solution')}>
					{entity?.subject.defaultPlaceholder("SOLUTION_NAME")}
				</div>
				<FlexBetweenAlignCenter className="px-2" minWidth='170px'>
					<div>
						{SolutionTypeLabel()}
					</div>
					{!disable && (
						<RemoveButton>
							<GenericButton variant="light-link" id={entity?.id} icon="X" iconClassName="p-0" onClick={unlink} />
						</RemoveButton>
					)}
				</FlexBetweenAlignCenter>
			</BadgeWrapper>

		</Badge>
	);
};

