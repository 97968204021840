import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/connector/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/connector/", method: "GET" }); // api actions type get

export const connectorInit = () => createGetAction({ type: "CONNECTOR_INIT", endPoint: "getAll" });

export const upsertConnector = ({ connector, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPSERT_CONNECTOR",
			endPoint: "upsert",
			data: {
				connector,
			},
		}),
	});

export const deleteConnector = ({ connectorId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CONNECTOR",
			endPoint: "delete",
			data: {
				connectorId,
			},
		}),
		displayToast: "DELETE",
	});

export const getQuestionsAndFilters = ({ connectorId, surveyId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "GET_QUESTIONS_AND_FILTERS",
			endPoint: "getQuestionsAndFilters",
			data: {
				connectorId,
				surveyId,
			},
		}),
	});
