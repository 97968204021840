import { useEffect, useState } from "react";

export const IconDispatcher = (type, styling = "", params = {}) => {
	const icon = iconMapping[type]; // Get the icon based on the type
	const LocalIcon = localIconMapping[type]; // Get the icon based on the type
	// case 1 it's one of the label we gave
	if (icon) {
		return <FaIcon iconName={icon} style={params.style} className={styling} size={params?.size} color={params?.color} family={params.family} />;
	}
	// case 2 : it's an import from the local icons
	if (LocalIcon) {
		return <DynamicIcon iconName={LocalIcon} className={styling} params={params} />;
	}
	if (typeof type === "string" && !LocalIcon) {
		// case 3: straight from font awesome iconDispatcher("user", "fas", { size: "14px", style:'regular', color: "red" });

		return <FaIcon iconName={type} style={params.style} className={styling} size={params?.size} color={params?.color} family={params.family}/>;
	}

	return <></>;
};

const FaIcon = ({ iconName, style = "regular", className, size, color , family ="" }) => {
	if (size === "sm" || size === "small") size = "14px";
	if (size === "md" || size === "medium") size = "16px";
	if (size === "lg" || size === "large") size = "24px";
	if (size === "xl" || size === "extra-large") size = "32px";
	if(size === "2xl") size = "40px";

	if(family === "sharp") family = "fa-sharp";

	return <i className={`${family} fa-${style} fa-${iconName} ${className}`} style={{ fontSize: size, color: color }}></i>;
};

const DynamicIcon = ({ iconName, ...props }) => {
	const [iconSrc, setIconSrc] = useState("");
	useEffect(() => {
		let isMounted = true;
		importSVG(iconName).then((src) => {
			if (isMounted) setIconSrc(src);
		});
		return () => {
			isMounted = false;
		};
	}, [iconName]);
	if (!iconSrc) return null; // or a placeholder while loading
	return <img src={iconSrc} alt={iconName} {...props} style={props.params} />;
};

const importSVG = async (iconName) => {
	if (!iconName) return null;
	try {
		const svg = await import(`../Assets/All/${iconName}.svg`);
		return svg.default; // Adapt based on your file structure
	} catch (error) {
		// console.error(`Could not load icon: ${iconName}`, error);
		return null;
	}
};

const iconMapping = {
	HOME: "house-blank",
	FOLDER: "folder",
	"FOLDER-OPEN": "folder-open",
	PERSONA: "user-hair",
	CJM: "map",
	"MENU-ACTION": "bolt-lightning",
	TEMPLATE: "clone",
	SAMPLE: "memo-pad",
	ARCHIVE: "box-archive",
	TRASH: "trash",
	DELETE: "trash",
	SETTINGS: "gear",
	COPY: "copy",
	EXPORT: "file-export",
	REFRESH: "arrows-rotate",
	EDIT: "pen",
	RESTORE: "clock-rotate-left",
	ACCESS: "key",
	REMOVE: "circle-minus",
	VIEW: "eye",
	"MANAGE-PERSONAS": "user-group",
	"MAP-HIERARCHY": "sitemap",
	"ACTION-DROPDOWN": "ellipsis-vertical",
	"ACTION-DROPDOWN-HORIZONTAL": "ellipsis",
	CAMERA: "camera",
	LINK: "link-simple",
	UNLINK: "link-simple-slash",
	PLUS: "plus",
	SQUARE_PLUS: "plus",
	"CIRCLE-PLUS": "circle-plus",
	SQUARE_ADD: "square-dashed-circle-plus",
	"LIST-VIEW": "list",
	"GRID-VIEW": "grid-2",
	CHECK: "check",
	LOCK: "lock-keyhole",
	UPLOAD: "upload",
	X: "x",
	DRIP: "fill-drip",
	MALE: "mars",
	FEMALE: "venus",
	GENDERLESS: "genderless",
	"ANGLE-DOWN": "angle-down",
	"ANGLE-UP": "angle-up",
	"CIRCLE-QUESTION": "circle-question",
	COMPRESS: "arrow-down-left-and-arrow-up-right-to-center",
	EXPAND: "arrow-up-right-and-arrow-down-left-from-center",
	DRAG: "grip-dots-vertical",
	USERS: "users",
	COMMENT: "comment-lines",
	EXCEL: "file-excel",
	PDF: "file-pdf",
	OPPORTUNITY: "telescope",
	LIKE: "thumbs-up",
	UNLIKE: "thumbs-down",
	HEART: "heart",
	SOLUTION: "lightbulb",
	"FILL-DRIP": "fill-drip",
	"CIRCLE-CHECK": "circle-check",
	"CIRCLE-X": "circle-x",
	"LEFT-ARROW": "arrow-left-long",
	ICONS: "circle-dashed",
	ARROW_UP: "chevron-up",
	ARROW_DOWN: "chevron-down",
	ARROW_LEFT: "arrow-left",
	WAND: "wand-sparkles",
	ARROW_RIGHT: "arrow-right",
	CHECKED: "check-circle",
	"ANGLE-LEFT": "chevron-left",
	"ANGLE-RIGHT": "chevron-right",
	"CHEVRON-DOWN": "chevron-down",
	PLAY: "play",
	"CX-STRATEGY": "chess-rook-piece",
	"CX-PROGRAM": "solar-system",
	"CIRCLE-X-MARK": "circle-xmark",
	BRAIN: "brain",
	PUZZLE: "puzzle-piece",
	"ROAD-BARRIER": "road-barrier",
	"STAR-LIFE": "star-of-life",
	"X-MARK": "xmark",
	"CHART-SCATTER-BUBBLE": "chart-scatter-bubble",
	"VERTICAL-AXIS": "ruler-vertical",
	"HORIZONTAL-AXIS": "ruler-horizontal",
	"TABLE-CELLS": "table-cells",
	TABLE: "table",
	FILTER: "filter",
	"FILTER-LIST": "filter-list",
	TEXT: "text",
	NUMBER: "input-numeric",
	GENDER: "venus-mars",
	IMAGE: "image",
	SLIDERS: "sliders-simple",
	RATING: "star-half-stroke",
	TAG: "tag",
	SEND: "circle-arrow-up-right",
	NOTIFICATION: "bell",
	DEMOGRAPHIC: "id-card",
	QUOTE: "comment-quote",
	"BULLSEYE-ARROW": "bullseye-arrow",
	CHART: "chart-mixed",
	"USER-GROUP-SIMPLE": "user-group",
	"SIMPLE-COMMENT": "comment",
	REOPEN: "reply",
	"SWIMLANE-KPIS": "chart-line-up",
	"CHART-LINE-UP": "chart-line-up",
	BOOKMARK: "bookmark",
	DASH: "dash",
	CIRCLE_DOT: "circle-dot",
	CHEVRON_UP: "chevrons-up",
	CHEVRON_DOWN: "chevrons-down",
	INFO: "info-circle",
	PRO: "thumbs-up",
	CON: "thumbs-down",
	INSIGHTS: "lightbulb",
	ACTIONS: "bolt",
	ACTION: "bolt",
	TIME: "timer",
	"CIRCLE-EXCLAMATION": "circle-exclamation",
	SEARCH: "search",
	SIDEBAR: "sidebar",
	SHAPES: "shapes",
	SIDEBAR_TRASH: "trash-can-arrow-up",
	"LIB-BRANDVALUES": "book-bookmark",
	"CLOCK-ROTATE": "clock-rotate-left",
	"ARROW-TURN": "arrow-turn-down-right",
	"ARROWS-TURN": "arrows-turn-right",
	"GRIP-LINE": "grip-lines",
	ARROW_UP_RIGHT: "arrow-up-right-from-square",
	"MENU-SIDEBAR": "bars-sort",
	"FOLDER-PLUS": "folder-plus",
	PROJECT: "diagram-project",
	"PROJECT-LIGHT": "diagram-project",
	"SAVE-VERSION": "layer-plus",
	"SAVED-VERSIONS": "layer-group",
	"USER-GROUP": "user-group",
	ENVELOPE: "envelope",
	"GRADUATION-HAT": "graduation-cap",
	"MAGNIFYING-GLASS-CHART": "magnifying-glass-chart",
	"MESSAGE-SMILE": "message-smile",
	HIDE_EYE: "eye-slash",
	BOOKS: "books",
	USERS_LIGHT: "users",
	"PUZZLE-PIECE": "puzzle-piece",
	DRAG_HORIZONTAL: "grip-dots",
	HASHTAG: "hashtag-lock",
	"GRID-DIVIDERS": "grid-dividers",
	"CHART-SIMPLE": "chart-simple",
	CALENDAR: "calendar-days",
	SPARKLES: "sparkles",
	"PIE-CHART": "pie-chart",
	"CHART-COLUMN": "chart-column",
	"CHART-BAR": "chart-bar",
	"CHART-SCATTER": "chart-scatter",
	"CLOUD-WORD": "cloud-word",
	"CHART-LINE-UP-THIN": "chart-line-up",
	"CHART-RADAR": "chart-radar",
	"CHART-AREA": "chart-area",
	"BULLSEYES-POINTER": "bullseye-pointer",
	GEM: "gem",
	"CIRCLE-CARET-DOWN": "circle-caret-down",
	"FILE-XLS-THIN": "file-excel",
	"FILE-XLS-LIGHT": "file-excel",
	"PEN-TO-SQUARE": "pen-to-square",
	"UPLOAD-LIGHT": "upload",
	"ARROW-UP-RIGHT-FROM-SQUARE-LIGHT": "arrow-up-right-from-square",
	ARROW_LONG_UP: "long-arrow-alt-up",
	EXCLAMATION: "triangle-exclamation",
	"FA-LIGHTBULB-EXCLAMATION-ON": "lightbulb-exclamation-on",
	GHOST: "ghost",
	"CIRCLE-PAUSE": "circle-pause",
	// Add more mappings as needed
};
const localIconMapping = {
	"SUBSCRIPTION-END": "hourglass1",
	"LOG-OUT": "open-door",
	"WHITE-CHECK": "white-checkmark",
	"DELETE-BIG": "delete-big",
	"UPLOAD-IMG": "upload-image",
	"SLIDER-ARROW": "slider-arrow",
	"CRYSTAL-BALL": "crystal-ball",
	"WIZARD-BIRD": "persona-wizard-bird",
	"WIZARD-BAG": "wizard-bag",
	"EMPTY-LIBRARY": "empty-library",
	"PRIMARY-CHECK": "primary-check",
	"MAIN-ROUTE": "voc-main",
	"ALTERNATIVE-ROUTE": "voc-alternative",
	"GROUP-BY": "group-list",
	"SWIMLANE-CUSTOM": "swimlane-custom",
	"SWIMLANE-SOLUTION": "solutions-swimlane",
	"SWIMLANE-ACTIONS": "swimlane-actions",
	"SWIMLANE-DEPARTMENT": "swimlane-department",
	"SWIMLANE-DIVIDER": "swimlane-divider",
	"SWIMLANE-FEEDBACK-DATA": "swimlane-feedbackdata",
	"SWIMLANE-FEELINGS": "swimlane-feelings",
	"SWIMLANE-IMAGE": "swimlane-image",
	"SWIMLANE-LI": "swimlane-li",
	"SWIMLANE-OPPT": "swimlane-oppt",
	"SWIMLANE-SYSTEM": "swimlane-system",
	"SWIMLANE-TEXT": "swimlane-text",
	"SWIMLANE-TIMING": "swimlane-timing",
	"SWIMLANE-TOUCHPOINTS": "swimlane-touchpoints&intractions",
	MONDAY: "monday",
	"PERSONA-EMPTY-LIST": "persona-empty-list",
	DEPARTMENT: "department-atr",
	GROUP: "group-atr",
	LINK: "linked-atr",
	PERSONA: "persona-atr",
	SENTIMENT: "sentiment-atr",
	STAKEHOLDERS: "stakeholders-atr",
	SYSTEM: "system-atr",
	TAG: "tag-atr",
	SPARKLES: "sparkles-thin",
	CONNECTORS: "plug-thin",
	ANALYTICS: "chart-simple-light",
	"LIB-BRAND-VALUES": "lib-brandValues",
	"LIB-CUSTOM": "lib-custom",
	"LIB-DEPARTMENTS": "lib-departments",
	"LIB-KPI": "lib-kpi",
	"LIB-STICKERS": "lib-stickers",
	"LIB-SYSTEMS": "lib-systems",
	"LIB-TOUCHPOINTS": "lib-touchpoints",
	MEASURE: "measure",
	PIN: "pin-info",
	"PENCIL-LOWER-LEFT": "pencil-lower-left",
	"EXCELLENT-BTN": "excellent-btn",
	"EXCELLENT-BTN-ACTIVE": "excellent-btn-active",
	"GENERIC-BTN": "generic-btn",
	"GENERIC-BTN-ACTIVE": "generic-btn-active",
	"GOOD-BTN": "good-btn",
	"GOOD-BTN-ACTIVE": "good-btn-active",
	"NEUTRAL-BTN": "neutral-btn",
	"POOR-BTN": "poor-btn",
	"POOR-BTN-ACTIVE": "poor-btn-active",
	"TERIBLE-BTN": "terrible-btn",
	"TERIBLE-BTN-ACTIVE": "terrible-btn-active",
	"EMOTION-EXCELLENT": "emotion-excellent",
	"EMOTION-GENERIC": "emotion-generic",
	"EMOTION-GOOD": "emotion-good",
	"EMOTION-POOR": "emotion-poor",
	"EMOTION-TERRIBLE": "emotion-terrible",
	"NO-SENTIMENT-SMILEY": "nosentiment-smiley",
	"NOSENTIMENT-SMILEY": "nosentiment-smiley",
	"ALTERNATIVE-CUSTOMER-ROUTE": "alternative-customer-route",
	"LINE-GRAPH": "chart-line-graph",
	"MAIN-CUSTOMER-ROUTE": "main-customer-route",
	"BV-1": "bv-1",
	"BV-2": "bv-2",
	"BV-3": "bv-3",
	"BV-4": "bv-4",
	"BV-5": "bv-5",
	"BV-6": "bv-6",
	"BV-7": "bv-7",
	"BV-8": "bv-8",
	"BV-9": "bv-9",
	"BV-10": "bv-10",
	"BV-11": "bv-11",
	"BV-12": "bv-12",
	"BV-13": "bv-13",
	"BV-14": "bv-14",
	"BV-15": "bv-15",
	"BV-16": "bv-16",
	"LINE-DASH-GRAPH": "dash-solid",
	"PROJECT-HEADER": "project-header",
	NOTEBOOK: "notebook",
	BRIEFCASE: "briefcase",
	PACKAGE: "package",
	SPARKLES_HOME: "sparkles-thin",
	GROUP_DUOTONE: "group-atr",
	DEPARTMENT_DUOTONE: "department-atr",
	LINK_DUOTONE: "linked-atr",
	PERSONA_DUOTONE: "persona-atr",
	SENTIMENT_DUOTONE: "sentiment-atr",
	STAKEHOLDERS_DUOTONE: "stakeholders-atr",
	SYSTEM_DUOTONE: "system-atr",
	TAG_DUOTONE: "tag-atr",
	SPARKLES_DUOTONE: "sparkles-thin",
	CONNECTORS_DUOTONE: "plug-thin",
	ANALYTICS_DUOTONE: "chart-simple-light",
    INTEGRATION_WIDGET: "integration-widget",
    WIDGET_AI: "widget-ai",
    WIDGET_INTEGRATION: "widget-integration",
    WIDGET_ANALYTIC: "widget-analytic",
	"SPARKLES-LIGHT":"sparkles-ligh",
    "MAP-LIGHT": "map-light",
    "MAP-DEFAULT":"map-default",

};

export default DynamicIcon;
