import { deleteStakeholder } from "@redux/index";
import { GetUserColor, getInitials } from "@utils/helpers";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";
import { FlexAlignCenter, FlexColumn, GenericButton } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import StakeHolderForm from "./StakeHolderForm";

export default function StakeHolderCard({ recordId, item, roleName, entityName, onSuccess, stakeHoldersPath, actionType }) {
	const panelRef = useRef(null);
	const [disableDelete, setDisableDelete] = useState(false);
	const isViewer = roleName === "viewer";
	const isContributor = roleName === "contributor";
	const isDeletable = !disableDelete && !isViewer && !isContributor;

	const handleDelete = () => {
		setDisableDelete(true);
		deleteStakeholder({ stakeholderId: item.id, recId: recordId, entityName, actionType: "DELETE_" + actionType, onSuccess: onSuccess });
		setDisableDelete(false);
	};

	return (
		<CardContainer>
			<Card color={GetUserColor(item?.fullName)}>
				<FlexAlignCenter gap="8" onClick={(e) => panelRef.current.toggle(e)}>
					<InitialsBubble initials={getInitials({ name: item?.fullName })?.slice(0, 3)} size="s" />
					<FlexColumn gap="4">
						<Name title={item?.fullName}>{item?.fullName}</Name>
						<JobTitle title={item?.roleName}>{item?.roleName}</JobTitle>
					</FlexColumn>
				</FlexAlignCenter>
				{isDeletable && (
					<ButtonWrapper>
						<GenericButton variant="light-link" size="icon-12" icon="x" onClick={handleDelete} />
					</ButtonWrapper>
				)}
				<OverlayPanel ref={panelRef}>
					<StakeHolderForm recId={recordId} entityName={entityName} selectedData={item} actionType={actionType} stakeHoldersPath={stakeHoldersPath} roleName={roleName} panelRef={panelRef} />
				</OverlayPanel>
			</Card>
		</CardContainer>
	);
}

const CardContainer = styled.div`
	:hover {
		cursor: pointer;
	}
	background-color: #efefef;
	padding: 8px;
	border-radius: 4px;
`;

const Card = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
`;

const Name = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const JobTitle = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #92919d;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
	font-weight: 400;
	color: #433d56;
    display: flex;
    align-items: center;
`;

