import EmptyData from "features/analytics/Components/EmptyData";
import CustomTick from "features/analytics/Components/Ticks/CustomZigZagTick";
import TextAndValueTooltip from "features/analytics/Components/Tooltips/TextAndValueTooltip";
import { bigNumberFormatter } from "features/analytics/analyticsHelper";
import { useSelector } from "react-redux";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import { KpiDataSelector } from "../roiAnalysisDataSelectors";

const FinancialImpactPerMeasure = () => {
	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";
	const data = useSelector((state) => KpiDataSelector(state))
		?.filter((item) => item?.financialImpact > 0)
		?.sort((a, b) => b.financialImpact - a.financialImpact);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const value = payload[0].value.toLocaleString() + currencySymbol;
			return <TextAndValueTooltip text={label} value={value} />;
		}

		return null;
	};

	return (
		<>
			{data?.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={data}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}>
						<XAxis dataKey="name" interval={0} textAnchor="middle" tick={<CustomTick />} height={50} />
                        <YAxis tickFormatter={(number) => bigNumberFormatter({number, currencySymbol})} />
						<Tooltip cursor={{ fill: "#efefef" }} isAnimationActive={false} content={<CustomTooltip />} />
						<Bar dataKey="financialImpact" stackId="a" fill="#8A86A9" barSize={60}></Bar>
					</BarChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="ROI_BY_MEASURES_REPORT_DESCRIPTION" icon="CHART-COLUMN" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
};
FinancialImpactPerMeasure.displayName = "FinancialImpactPerMeasure";
export default FinancialImpactPerMeasure;
