import { handleAddRenderElement } from "@utils/helpers";
import CustomWordCloud from "features/analytics/Components/CustomWordCloud";
import Export from "features/analytics/Components/Export";
import FiltersManager from "features/analytics/Components/Filters/FiltersManager";
import Section from "features/analytics/Components/Section";
import { DashboardContainer, TopSection } from "features/analytics/Style";
import { applyFilterVocAnalysis } from "features/analytics/reducer/analyticActions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomLineChart from "./Reports/CustomLineChart";
import GlobalSentimentOverTime from "./Reports/GlobalSentimentOverTime";
import SentimentByTouchpoints from "./Reports/SentimentByTouchpoints";
import SentimentGeolocalization from "./Reports/SentimentGeolocalization";
import VOCAnalysisCounter from "./Reports/VOCAnalysisCounter";
import { sentimentByEntityOverTimeDataSelector, wordCloudDataSelector } from "./vocAnalysisDataSelectors";

function VoCAnalysisReports() {
	const { t } = useTranslation();
	const hideForPdf = useLocation().search.hideForPdf();

	const getFiltersToSave = ({ currentEntityData }) => {

		return {
			contactInteractions: currentEntityData?.contactInteractions?.map((ci) => ci?.id),
			personaInteractions: currentEntityData?.personaInteractions?.map((pi) => pi?.id),
			date: currentEntityData?.date ? currentEntityData?.date : new Date(),
		};
	};

	return (
		<DashboardContainer className="container my-4">
			<TopSection className="p-4" xs={12}>
				<FiltersManager filterEntities={["journeys", "personas", "touchpoints", "metrics", "date"]} applyAction={applyFilterVocAnalysis} />
				<Export dashboardName="vocanalysis" fileName={t("VOC_ANALYSIS")} nSections="8" getFiltersToSave={getFiltersToSave} />
			</TopSection>
			<VOCAnalysisCounter />
			<Section titles={[`${t("SENTIMENT_BY_TOUCHPOINTS")}`]}>
				<SentimentByTouchpoints />
			</Section>
			<Section titles={[`${t("GLOBAL_SENTIMENT_OVER_TIME")}`]}>
				<GlobalSentimentOverTime />
			</Section>
			<Section titles={[`${t("CUSTOMER_SENTIMENT_LEVEL_PER_PERSONA")}`]}>
				<CustomLineChart
					dataSelector={(state) => sentimentByEntityOverTimeDataSelector(state, { entityName: "personas", relatedAttributeId: "personaId" })}
					entityAttribute="personas"
					emptyDataText="CUSTOMER_SENTIMENT_BY_PERSONA_REPORT_DESCRIPTION"
				/>
			</Section>
			<Section titles={[`${t("METRICS_TREND")}`]}>
				<CustomLineChart
					dataSelector={(state) => sentimentByEntityOverTimeDataSelector(state, { entityName: "metrics", relatedAttributeId: "metricId" })}
					entityAttribute="metrics"
					emptyDataText="METRICS_OVER_TIME_REPORT_DESCRIPTION"
				/>
			</Section>
			<Section titles={[`${t("SENTIMENT_GEO_LOCALIZATION")}`]}>
				<SentimentGeolocalization />
			</Section>
			<Section titles={[t("FEEDBACK_WORD_CLOUD")]}>
				<CustomWordCloud
					dataSelector={(state) => wordCloudDataSelector(state)}
					width={300}
					height={50}
					colorPalette={["#138A8A", "#18AEAD", "#9DF2F2"]}
					emptyDataText="FEEDBACK_WORD_CLOUD_REPORT_DESCRIPTION"
				/>
			</Section>
			{hideForPdf && <div id="addRenderElement">{handleAddRenderElement(3000)} </div>}
		</DashboardContainer>
	);
}

VoCAnalysisReports.displayName = "VoCAnalysisReports";
export default VoCAnalysisReports;
