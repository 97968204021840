import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GenericButton } from 'shared/ui-basics/index';

 export const  PopoverSubscription = ({children,disabled,placement='right',trigger=["click",'focus'],access} ) =>  {

	const subscriptionDetails = useSelector(({settings}) => settings.subscriptionDetails);
    const {t} = useTranslation()
    const onlyEnterprise = access?.length === 1 && access[0] === 'Enterprise'

    if(disabled) return children

    const redirect = () => {
            if (subscriptionDetails?.upgradeButtonLink?.isEmptyString()) return window.open("https://www.cemantica.com/Pricing");
            else {
                const url = subscriptionDetails?.upgradeButtonLink?.split("'")[1];
                return window.open(url)
        }
    }
  const popover = (
    <Pop id="popover-basic">
      <Popover.Body>
        <span className='content' >
        <span className='me-1'>{t('AVAILABLE_ON')}</span>
        {
        !onlyEnterprise && <>
         <Strong className='me-1'>{t("EXPERT")} </Strong>
        <span className='me-1'>{t('AND')}</span>

        </>}
        <Strong className='me-1'>{t("ENTERPRISE")}</Strong>
        <span>{t(!onlyEnterprise ?'PLANS' : 'PLAN')}.</span>
        </span>
        <GenericButton variant='primary-outline' className='my-2' onClick={redirect}>{t('UPGRADE_SUBSCRIPTION')}</GenericButton>
      </Popover.Body>
    </Pop>
  );

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
    //   show={true}
    //   onToggle={(show) => setShowPopover(show)}
      overlay={popover}
      rootClose
    >
      <div>
        {children}
      </div>
    </OverlayTrigger>
  );
}

const Strong = styled.div`
color:#F96209;
`
const Pop = styled(Popover)`
z-index: 1200 !important;
.popover-arrow {
    left:calc(-0.5rem - 0px);
}
.popover-body{
    flex-wrap: wrap;
    white-space:nowrap;
    width: 203.751px;
height: 111px;
padding: 1rem;
font-size:14px;
button {
    font-size: 14px !important;
}
box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.content {
    display: flex;
    flex-wrap: wrap;
    color: #605F60;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}


`
