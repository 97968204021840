import { relinkPersona } from "@redux/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FlexAlignJustifyCenter, FlexColumnGap, IconDispatcher } from "shared/ui-basics/index";
import styled, { css } from "styled-components/macro";


export default function HistoricalData(item) {
    const { t } = useTranslation();
    const history = useHistory();
	const previousPersonasLink = useSelector((state) => state.maps.current_map?.previousPersonasLink);
	const cjmIdStr = useSelector((state) => state.maps.current_map?.cjmId);

	const onSuccess = () => {
		history.push(`/EditMap?cjmid=${cjmIdStr}&personaId=${item.personaId}`);
		history.go() ;
	};

	return (
		<>
			{previousPersonasLink?.includes(item.personaId) && (
				<FlexColumnGap>
					<FlexAlignJustifyCenter>
						{IconDispatcher("INFO", "me-2")}
						<Text color="#605f60">{t("DATA_ON_JOURNEY")}</Text>
					</FlexAlignJustifyCenter>
					<Text color="#F96209" clikable={true} onClick={() => relinkPersona({ cjmIdStr, personaIdStr: item.personaId, onSuccess, displayToast: "RELINK" })}>
						{t("USE_DATA")}
					</Text>
				</FlexColumnGap>
			)}
		</>
	);
}

const Text = styled.div`
	color: ${(props) => props.color};
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	${(props) =>
		props.clikable &&
		css`
			:hover {
				cursor: pointer;
			}
		`}
`;
