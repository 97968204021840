import { NavLink } from "reactstrap";
import styled, { css } from "styled-components/macro";

export const LogoHeader = styled.img`
	object-fit: contain !important;
	position:sticky;
	top:0;
	width: 155px !important;
	min-height: 40px;
	justify-content: center;
	object-position: left;
	background-color: #ffffff;
	overflow: hidden;
	${(props) =>
		props.hideForPdf &&
		`
        left: 85rem;
        width: 380px;
   `}
`;

export const SideBar = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-width: 60px;
	color: #262626;
	background-color: #FFF;
	/* margin-top: 40px; */
	position: relative;
	z-index: 152;
	padding-bottom: 13px;
	border-right: 1px solid #efefef;

	${(props) =>
		props.variant === "open" &&
		css`
			width: 10%;
			transition: all 0.5s ease-in-out;
			min-width: 240px;
		`}

	${(props) =>
		props.variant === "close" &&
		css`
			width: 2%;
			transition: all 0.5s ease-in-out;
			min-width: 48px;
		`}

	${(props) =>
		props.hideSidebar &&
		css`
			display: none;
		`}
`;

export const SidebarTop = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
	padding-bottom: 40px;
	max-height: calc(100vh - 90px);
	overflow-y: auto;
	overflow-x: hidden;
	transition: overflow-y 0.3s ease;
`;

export const SidebarBottom = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	height: fit-content;
	background: #FFF;
	border-right: 1px solid #efefef;
	padding: 0px 8px;
`;

export const MenuDivider = styled.div`
	margin: auto;
	width: 90%;
	background-color: #efefef;
	height: 1px;
`;
export const CustomNavLink = styled(NavLink)`
	cursor: pointer;
	color: #262626;
	display: flex;
	align-items: center;
	padding: 8px;
	height: 40px;
	display: flex;
	font-weight: 400;
	font-size: 14px;
	font-family: 'Inter';
	margin-top: 0.25rem;
	line-height: 20px; /* 142.857% */
font-style: normal;
text-transform: capitalize;

	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
			cursor: default;
		`};
	path {
		fill: #262626;
	}
	${(props) =>
		props.active &&
		css`
			background-color: #F5F5F5;

			font-weight: 600;
			border-radius: 4px;
		`}

	:hover {
		background-color: #F5F5F5;
		color: #262626;
		border-radius: 4px;
	}
	:active {
		font-weight: 600;
		background-color: #F5F5F5;
		color: #262626;
	}
	:focus {
		color: #262626;
		font-weight: 600;
	}
`;

export const TextWrapper = styled.div`
	white-space: nowrap !important;
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	//: ${(props) => (props.isFooterRoutes ? "180px" : "140px")};
	max-width: 152px;
	width: 100%;
`;

export const ActionButtons = styled.div`
	position: absolute;
	right: 5px;
`;

export const CollapseLabel = styled.div`
	color: #605f60;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	cursor: pointer;
	font-size: 14px;
	position: relative;
	/* ${ActionButtons}{
        visibility:hidden;
    }
	  ${ActionButtons} :focus{
        visibility:visible;
;
    }
	:focus ${ActionButtons}{
        visibility:visible;    }
    :hover ${ActionButtons}{
        visibility:visible;    } */
`;
export const Flip = styled.div`
	${(props) =>
		props.open &&
		css`
			height: calc(100vh - 50px);
		`}
`;

export const SubSidebarLabel = styled.div`
	color: #262626;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	padding: 8px 4px;
	width: 100%;
	transition: all 0.5s ease-in-out;
	height: 40px;

`;
