import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { EditInsights } from "features/index";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import BaseActions from "features/insights/actions/BaseActions";
import { BulkActions } from "features/insights/actions/BulkActions";
import { createInsight, getInsightsExcelExport } from "features/insights/reducer/InsightActions";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { FilterItemsByStatus, createDefaultInsight, tableDefaultColumn } from "@utils/helpers";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useModal } from "use-modal-hook";
import { updateFolderLinks } from "@redux/index";

export const GetInsightContext = (props) => {
	const { linked, setRename, disableBulkActions } = props;
	const { pathname, search } = useLocation();

	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const userInfo = useSelector((state) => state.auth.userInfo);
	const insightId = search.getId();
	const folderId = search.getFolderId();
	const all_maps = useSelector((state) => state.maps.all_maps).getActiveRecords(!showSampleData);

	const allInsights = useSelector((state) => state.insights?.all_insights);
	const insights = useMemo(() => {
		const relevantInsights = allInsights.filter((insight) => (!insightId || insight.id === insightId) && FilterItemsByStatus(insight, pathname, "/insights", showSampleData, folderId));
		return transfromData(relevantInsights, all_maps);
	}, [allInsights, all_maps, folderId, pathname, showSampleData]);
	const specificContext = props.context.split(".")[1];
	const linkedObjects = linked ? insights.filter((ins) => linked?.includes(ins.id)) : undefined;
	const loading = useSelector((state) => state.insights.loading);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.Insight]?.[specificContext]?.columns ?? tableDefaultColumn(EntityList.Insight);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Insight]?.[specificContext]?.filters;


	const [showEditModal] = useModal(EditInsights);
	const create = ({ setIsCreateBtnDisabled }) => {
		if (setIsCreateBtnDisabled) setIsCreateBtnDisabled(true);
		const onSuccess = (data) => {
			if (setIsCreateBtnDisabled) setIsCreateBtnDisabled(false);
			if (folderId) updateFolderLinks({ folderIds: [folderId], idsToLink: [data.id], entityName: "cem_insight" });
			showEditModal({ insightId: data.id })
		};
		const onFailed = () => setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
		createInsight({ item: createDefaultInsight({ currentUser: userInfo }), onSuccess, onFailed });
	};

	const [showListLinkedModal] = useModal(ListLinkedModal);

	const data = useMemo(() => {
		const newData = {
			insightList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "INSIGHT_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
				visibleHeader: true,
				buttonCreate: create,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
				listEditable: true,
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "insightName", "linkedPersonas", "occurance", "insightType", "actions"],
				emptyListText: "INSIGHT_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "insightName", "linkedPersonas", "occurance", "insightType", "actions"],
				emptyListText: "INSIGHT_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
		};

		const contextData = _.get(newData, specificContext);

		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;
		contextData.exportExcel = getInsightsExcelExport;
		contextData.Actions = (insight) => CreateActionsDropdown({ actions: BaseActions({ insight, setRename }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = linkedObjects;
		contextData.listData = insights;
		contextData.dataKey = "id";
		contextData.entityType = EntityList.Insight;
		contextData.loading = loading;
		contextData.buttonCreate = create;
		contextData.route = "";
		contextData.buttonCreateLabel = "NEW_INSIGHT";
		contextData.filter = filters;
		// folder case
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.Insight, saveButtonLabel: "ASPX_44" });
			contextData.LinkActionLabel = "LINK_UNLINK_INSIGHTS";
			contextData.displayExport = false;
		}

		return contextData;
	}, [isReadOnly, linked, linkedObjects,columnsArray, insights, loading, disableBulkActions, setRename, filters]);
	return data;
};

const transfromData = (insights, allMapsActive) => {
	return insights?.map((insight) => {
		const lastUpdatedNew = new Date(insight?.lastUpdated);

		return {
			...insight,
			lastUpdated:lastUpdatedNew,
			occurance: allMapsActive?.filter(obj => Object.keys(insight?.linkedCjms).includes(obj["cjmId"])).length,
			cjmIds: allMapsActive?.filter(obj => Object.keys(insight?.linkedCjms).includes(obj["cjmId"]))?.map(linkCjm=>linkCjm?.cjmId),

			cjmIdsStr: allMapsActive?.filter(obj => Object.keys(insight?.linkedCjms).includes(obj["cjmId"]))?.map(linkCjm=>linkCjm?.cjmId)?.join("|"),
			groupIds: insight?.groups?.join("|"),
			tagIds: insight?.tags?.join("|"),
		};
	});
};
