import { Input } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { Button } from "shared/ui-basics/index";
import { Offcanvas } from "react-bootstrap";

export const CheckBoxWrapper = styled(Input)`
	width: 20px;
	height: 20px;
	margin: 0px;
	margin-right: 0.5rem; // Add this line
	border: 1px solid #605f60;
	${(props) =>
		props.sizecheckbox === "s" &&
		css`
			width: 16px;
			height: 16px;
		`}
	:hover {
		cursor: pointer;
	}
	&:checked {
		background-color: #333333;
		border-color: #333333;
	}
	&:focus {
		box-shadow: none;
		border-color: ${(props) => (props.disabled === "" ? "#333333" : "#c4c4c4")};
	}
`;
export const DivDivider = styled.div`
	margin: auto;
	height: 80%;
	background-color: #c4c4c4;
	width: 1px;
`;

export const ModalBox = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: scroll;
	background-color: #fff;
	max-height: calc(100vh - 343px);
`;
export const ButtonWrapper = styled(Button)`
	display: none;
	height: 100%;
	min-width: 5px;
`;
export const SearchWrapper = styled.div`
	width: 100%;
	height: 36px;
`;
export const FirstRow = styled.div``;
export const DivWrapper = styled.div`
	width: 100%;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	align-items: center;
	${(props) =>
		props.mode === "create" &&
		css`
			border: 1px dashed #c4c4c4;
			font-style: italic;
		`}
	${(props) =>
		props.mode === "view" &&
		`
        opacity:1;
      `}
          ${(props) => props.focus && ` animation: name 2s ease-out;`}

      ${(props) => props.mode === "update" && `border: 1px dashed #F96209;`}
      textarea {
		border: none;
		box-shadow: none;
	}

	&:focus-within ${ButtonWrapper} {
		display: flex;
		justify-content: center;
		cursor: pointer;
		align-items: center;
	}
`;
export const PathWrapper = styled.div`
	color: #8c8c8c;
	border-bottom: 1px dashed #c4c4c4;
	margin-left: -1rem;
	margin-right: -1rem;
	margin-top: -1rem;
`;
export const PhaseName = styled.div``;
export const StageName = styled.div`
	/* border: 1px solid #C4C4C4;
  border-radius: 4px; */
`;
export const OffcanvasLayout = styled(Offcanvas)`
	width: 480px;
`;
OffcanvasLayout.displayName = "OffcanvasLayout";

export const ListObjectActions = styled.div`
	display: none;
	border-left: 1px solid #c4c4c4;
	padding: 0px 0.5rem;
`;
export const ListObjectWrapper = styled.div`
	display: flex;
	:hover ${ListObjectActions} {
		display: flex;
	}
`;
export const CheckBoxContainer = styled.div`
	display: flex;
	border-right: 1px solid #c4c4c4;
	min-width: 40px;
	min-height: 30px;
`;

export const ListDescription = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
