import { InputSwitch } from "primereact/inputswitch";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tabs } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Breadcrumb, Container, Dropdown, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { Colors, GenericButton, Input } from "shared/ui-basics/index";
import { PriorityValidation } from "@utils/helpers";
import { StateTypeOption } from "@utils/optionsSets/OptionSets";

export const ChangeColor = styled.div`
	color: ${(props) => props.color};
`;
export const CustomModal = styled(Modal)`
	width: 1140px;
	min-width: 1140px;
	max-width: 1140px;
	:disabled {
		background-color: inherit !important;
	}
	.form-control:disabled {
		background-color: inherit !important;
		color: inherit !important;
		opacity: inherit !important;
	}
	.modal-content {
		min-height: 80vh;
	}
`;

export const Body = styled(ModalBody)`
	padding: 0;
`;

export const Header = styled.div`
	background: #ffffff;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	height: 160px;
	margin: 0.5rem 0rem;
	width: 100%;
	${(props) => props.query && ` height: 96px; margin: 0; border-radius: 0px;box-shadow: none;`}
`;
export const ModalTitle = styled.div`
	margin-left: 3rem;
	margin-top: 2rem;
	width: 100%;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 36px;
	color: #333333;
	overflow: hidden;
	word-break: break-word;
	padding-right: 40px;
`;

export const Title = styled(Input)`
	resize: none;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	color: #333333;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	width: 100%;
	text-overflow: ellipsis;



	&.form-control:focus {
		background-color: transparent;
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	&.form-control:disabled,
	.form-control[readonly] {
		background-color: #FFFFFF;
		color: #333333;
		opacity: 1;
		cursor: no-drop;
	}
`;
export const Footer = styled(ModalFooter)`
	border-top: 1px solid #c4c4c4 !important;
	height: 80px !important;
	bottom: 0px;
	width: 100%;
	display: flex;
	background-color: #ffffff;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
	margin: 0.5rem 0rem;
`;
export const Labels = styled(Label)`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	${(props) => props.fs && `font-size: ${props.fs}px;`}
`;
export const Checkbox = styled(Input)`
	margin-top: 0;
	width: auto;
	&:checked {
		background-color: #333333 !important;
		border: 1px solid #333333 !important;
	}
`;

export const GoMap = styled.a`
	text-decoration: none;
	color: #333333 !important;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 310px;
	font-size: 16px;
	font-weight: 500;
	line-height: 25px;
	min-width: 150px;
	/* color:#7676D2 !important */
`;

export const PersonaContainer = styled.div`
    max-width: 150px;
`

export const Search = styled(Typeahead)`
	/* z-index: 4; */
	${(props) => props.disabled && "background: #e5e4e5 !important;"}
	.form-control {
		border: none !important;
	}
	.rbt-menu {
		::-webkit-scrollbar-track-piece {
			background-color: #ffffff;
		}
	}
	${(props) =>
		props.type === "mapId" &&
		css`
			.rbt-menu {
				height: 240px;
			}
		`}

	.rbt-token {
		background: #ffffff;
		border-radius: 4px;
		color: #333333 !important;
		font-weight: 500;
		align-self: flex-start;
		margin: 0px;

		${(props) =>
		props.owner === "true" &&
		css`
				background: #d7ebff;
				min-height: 30px;
				padding: 0px;
				/* max-height: 45px; */
			`}
	}

	.rbt-input-multi.focus {
		box-shadow: none;
		border: 1px solid ${Colors.primaryText} !important;
	}

	.rbt-input-multi.disabled {
		cursor: no-drop;
		background-color:#ffffff;
		/* .rbt-token {
			color: #92919d;
			border-radius: 4px;
		}
		.rbt-token-label {
			color: #92919d;
		}
		${GoMap} {
			color: #92919d !important;
		} */

	}

	.rbt-token-label {
		height: 100%;
		width: 100%;
		align-self: center;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
		font-size: 16px;
		font-weight: 500;
		${(props) =>
		props.owner == "true" &&
		css`
				background: #d7ebff;
				display: flex;
				border-radius: 5px;
			`}
	}

	.rbt-input-multi.form-control {
		border-radius: 4px;
		max-width: 100%;
		min-height: ${(props) => (props.minHeight ? props.minHeight : 44)}px;
		padding-left: ${(props) => (props.showPlaceholder ? "0.5rem;" : "0;")};
		align-items: center;
	}

	.rbt-token-remove-button {
		display: flex;
		align-items: center;
		padding: 1px;
		align-self: center;
		border-radius: 5px;
	}

	.rbt-input-wrapper {
		align-self: center;

		> div {
			align-self: center;
		}
	}
	${(props) =>
		props.type === "stageId" &&
		css`
			.rbt-menu {
				height: 160px;
			}
			.form-control {
				border-top-right-radius: 0px !important;
				border-top-left-radius: 0px !important;
				border-top: 1px solid #c4c4c4 !important;
			}
		`}
`;
export const NotData = styled.div`
	color: #c4c4c4;
`;
export const StateTypes = styled.div`
	border-radius: 4px;
	color: ${(props) => (props.activeoption ? ColorCheckState(props.type) : "#92919D")};
	width: fit-content;
	margin-right: 0.75rem;
	font-size: 14px;
	${(props) => (props.size === "s" ? `font-size:14px;` : ``)}
`;
export const StatusTypes = styled.div`

	width: max-content;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	color: ${(props) => props.colorcode};
`;
export const OwnerName = styled.div`
	color: #333333;
	font-weight: 400;
	font-size: 16px;
	align-self: center;
	margin-left: 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
`;
export const Left = styled.div`
  flex: 1 1 auto; // This allows the container to grow and shrink, but not beyond its base size
  flex-basis: 70%;
  max-width: 70%;
  	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	@media screen and (max-width: 1440px) {
		width: 60%;
	}
	@media screen  and (max-height: 780px){
		height: max-content;

	}

`;
export const Right = styled.div`
	  flex: 1 1 auto;
  flex-basis: 30%;
  max-width: 30%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	border-radius: 4px;
	padding:16px;
	height: max-content;

	border: 1px solid var(--color-Grey-Border, #D9D9D9);
	@media screen and (max-width: 1440px) {
		width: 35%;

	}

`;
export const MaxWidth = styled.div`
	max-width: ${(props) => props?.width ?? 90}%;
	font-size: 14px;

	${(props) =>
		props.oppLabel === "true" &&
		css`
			color: #605f60;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		`}
`;
export const PriorityTypes = styled.div`
	min-width: 20%;
	font-size: 16px;
	align-items: center;
	text-transform: capitalize;
	align-self: center;
	display: flex;
	& #point {
		border-radius: 50%;
		/* padding:0 11px; */
		height: 16px;
		width: 16px;
		align-self: center;
		margin-right: 0.5rem;
		/* ${(props) => PriorityValidation(props.level, true)} */
		border: 1px solid #e6e6e6;
		background-color: ${(props) => props.colorcode};
	}
`;
export const Switch = styled(InputSwitch)`
margin-left: 1rem;
height: 18px;
	.p-inputswitch-slider {
		height: 15px;
		width: 41px;
		:before {
			border: 1px solid #92919d !important;
			width: 1.3rem;
			height: 1.3rem;
			left: 0rem;
		}
	}
`;
export const ColorCheckState = (type, bg = false) => {
	if (bg === false) {
		switch (type) {
			case StateTypeOption.CurrentState:
				return "rgb(32, 169, 233)";
			case StateTypeOption.FutureState:
				return "rgb(244, 142, 9)";

			default:
				return;
		}
	}
	if (bg === true) {
		switch (type) {
			case StateTypeOption.CurrentState:
				return "#D2EEFB";
			case StateTypeOption.FutureState:
				return "#FDE7CB";
			default:
				return;
		}
	}
};
const ColorCheck = (type, bg = false) => {
	if (bg === false) {
		switch (type) {
			case "win":
				return "#138A8A";
			case "truth":
				return "#BD3DBF";
			default:
				return;
		}
	}
	if (bg === true) {
		switch (type) {
			case "win":
				return "#D8FAFA";
			case "truth":
				return "#bd3dbf3b";
			default:
				return;
		}
	}
};
export const WrapperBox = styled.div`
	/* background: ${(props) => (props.active ? ColorCheck(props.type, true) : "#ffffff")}; */
	color: ${(props) => (props.active ? ColorCheck(props.type) : "#92919D")};
	display: flex;
	align-items: center;
	gap: 0.5rem;
	.state-header {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		svg {
			--fa-animation-iteration-count: 2;
		}
	}
	.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
		background: ${(props) => (props.active ? (props.type === "win" ? "#9DF2F2" : "#d185d2") : "#92919D")};
	}
	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
		background: ${(props) => (props.active ? (props.type === "win" ? "#9DF2F2" : "#d185d2") : "#92919D")};

		:hover {
			background: ${(props) => (props.active ? (props.type === "win" ? "#9DF2F2" : "#d185d2") : "#92919D")};
		}
		:focus {
			box-shadow: none !important;
		}
	}
	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
		background: ${(props) => ColorCheck(props.type)};
		border: none !important;
	}
	.p-inputswitch.p-focus .p-inputswitch-slider {
		box-shadow: none !important;
	}
`;
export const Select = styled(Typeahead)`
	.form-control:focus {
		box-shadow: none;
		border: 1px solid ${Colors.primaryText} !important;
	}

	.rbt-close {
		zoom: 0.8;
	}
	.rbt-close-content {
		visibility: hidden !important;
	}
`;
export const XButton = styled.div`
	display: none;
`;
export const Badge = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	width: 100%;
	cursor: pointer;
	height: 49px;
	:hover ${XButton} {
		display: block;
	}
`;
export const BadgeWrapper = styled.div`
	border-radius: ${(props) => (props.index === 0 ? "4px 4px 0px 0px;" : props.index === props.length - 1 ? "0px 0px 4px 4px;" : " 0px")};
	border: 1px solid #c4c4c4;
	${(props) => ((props.index === 0 && props.length === 1) || props.index === props.length - 1 ? `` : "border-bottom: none;")}

	background: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	height: 49px;

	width: 95%;
	:hover {
		box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25), -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;
export const ItemsContainer = styled.div`
	${(props) => (props.minHeight ? `min-height:${props.minHeight}px;overflow: scroll;` : "")}
	/* ${(props) => (props.maxHeight ? `max-height:${props.maxHeight}px;` : "")};
	padding-bottom: 1rem; */
	padding-top: 12px;
	.obj-name {
		width: 432px;
		color: #333;
		font-family: "Inter";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		padding: 0.5rem;
	}
`;
export const Menu = styled(DropdownMenu)`
	top: -42px !important;
	min-width: 400px;
	/* left: -13px !important; */
`;
export const Toggle = styled(DropdownToggle)`
	margin-bottom: 2rem;
	text-transform: capitalize;
	white-space: nowrap;
	background-color: transparent !important;
	color: #605f60 !important;
	border: 1px solid transparent !important;

	:focus {
		color: #605f60 !important;
		background-color: #e4e7f1 !important;
		border: 1px solid #e4e7f1 !important;
	}
	:hover {
		color: #605f60 !important;
		background-color: #e4e7f1 !important;
		border: 1px solid #e4e7f1 !important;
	}
`;
export const Owner = styled.div`
	color: #92919d;
	min-width: 36px;
	min-height: 36px;
	width: 36px;
	height: 36px;
	background: #ffffff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
`;

export const OwnerPickList = styled.div`
	border-radius: 4px;
    .rbt-input-multi.form-control {
        margin: 0px;
        padding: 0px;
    }
`;

export const CustomDropdown = styled(Dropdown)`
	/* margin-bottom: 2rem; */
	button {
		margin: 0px !important;
	}
`;
export const WrapperButton = styled(GenericButton)`
	/* background-color: #EDF3FB !important; */
	/* border:1px solid #ced4da !important; */
	border-radius: 4px;
	color: #605f60;
	position: absolute;
	top: -50px;
	right: 0px;
	width: fit-content !important;

	/* width:60px; */
`;
export const WrapperList = styled.div`
	display: flex;
	flex-direction: column;
	/* width:fit-content; */
	height: 350px;
	overflow-y: scroll;
`;
export const WrapperOverlayPanel = styled(OverlayPanel)`
	width: 512px !important;
	height: 303px !important;
	z-index: 1060 !important;
	.p-overlaypanel-content {
	padding: 0px;
}
	.p-overlaypanel-close {
		display: none;

	}

	button {
		/* background:#F96209!important; */
	}
`;

export const PositionButtonAdd = styled.div`
	position: absolute;
	right: 7px;
	top: 7px;
	height: 85%;
`;

export const ToggleCaret = styled(DropdownToggle)`
	//all: unset;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	border: 1px solid transparent !important ;
	transition: none !important;
	/* padding:0.375rem 0.75rem !important; */

	${(props) =>
		props.bordervisible === "true" &&
		`
	border: 1px solid #d9dbde !important;
	border-radius: 4px;
	padding:0.375rem 0.75rem !important;

	`}
	/* padding: 12px !important; */
	//Size
	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			font-size: 14px;
		`}

		${(props) =>
		props.size === "s" &&
		css`
			width: 32px;
			font-size: 14px;
		`}

	${(props) =>
		props.size === "m" &&
		css`
			height: 36px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 40px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			font-size: 24px;
		`}
	.caret {
		min-width: 18px;
		visibility: hidden;
	}
	button:focus:not(:focus-visible) {
		outline: none;
	}
	button {
		cursor: none;
	}
	:hover {
		background-color: transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border: 1px solid #d9dbde !important;
		.caret {
			min-width: 18px;
			visibility: visible;
		}
	}
	.btn-secondary:focus {
		border: 1px solid ${Colors.primary} !important;
		border: 1px solid #d9dbde !important;
		.caret {
			visibility: visible;
		}
	}
	.form-control:focus {
		border: 1px solid ${Colors.primary} !important;
		.caret {
			min-width: 18px;
			visibility: visible;
		}
	}
	${(props) =>
		props.disabled === 'true' &&
		css`
		cursor: pointer !important;
		`}

`;

export const MenuDrop = styled(DropdownMenu)`
	width: 100%;
	padding: 0;
	border-radius: 6px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	border: none;
	background: #ffffff;
    max-height: 250px;
    overflow: scroll;
`;

export const Item = styled.div`
	padding: 0.3rem;
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
			cursor:default;
		`}

	:hover {
		background-color: #d9d9d9;
	}
`;
export const MainContainer = styled.div`
	display: flex;
	width: 100%;
	height:  ${props => props.query ? 'calc(100vh - 320px)' : 'calc(100vh - 276px)'};
	overflow: auto;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	padding: 1rem 2rem;
	gap:24px;

	background-color: #ffffff;
	${(props) => props.solution === "true" && " display:block;"}
	${(props) =>
		props.query &&
		css`
		border-radius: 0px;
		box-shadow: none;
	`}
`;
export const ContainerPage = styled(Container)`
	height: calc(100vh - 76px);
	flex-direction: row-reverse;
	${(props) =>
		props.query &&
		css`
			/* background-color: #f8f9fa; */
			padding: 0;
			height: unset;
		`}
`;
export const CustomBreadcrumb = styled(Breadcrumb)`
	ol {
		margin: 0 !important;
		display: flex;
		align-items: center;
	}
	li {
		display: flex;
		align-items: center;
	}
`;
export const Path = styled.div`
	white-space: nowrap;
	color: #8c8c8c;
	font-size: 14px;
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
	max-height: 100%;
	display: inline-block;
	max-width: 500px;
	overflow: hidden;
`;
export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	/* border-left: 1px solid #c4c4c4; */
`;
export const PathContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
`;
export const MainTitle = styled.div`
	${({ query }) => !query && `border-top: 1px dashed #c4c4c4;`}
	min-height: 100px;
	padding: 0.5rem 1rem;
	.title {
		color: #333;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 36px */
		${({ query }) => query && `white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: calc(100% - 100px)`}
	}
	@media screen and (max-width: 1280px) {
		.title {
			font-size: 20px;
		}
	}
`;
export const MapStageContainer = styled.div`
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	background: #fff;
`;
export const Image = styled.img`
	width: 36px;
	height: 36px;
	border-radius: 5px;
`;

export const IsFutureLabel = styled.div`
	border-radius: 4px;
	padding: 0.2rem 0.5rem;
	margin-left: 0.5rem;
	background: ${(props) => (props.type === "true" ? "#FDE7CB" : "#D2EEFB")};
	color: ${(props) => (props.type === "true" ? "rgb(244, 142, 9)" : "rgb(32, 169, 233)")};
	width: fit-content;
	${(props) => (props.size === "s" ? `font-size:12px;` : ``)}
`;

export const PositionButtonADD = styled.div`
	position: relative;
	> button {
		position: absolute;
		top: 2px;
		z-index: 2;
		font-size: 14px;
		color: #262626;
		${props => props.disabled ? "pointer-events: none;opacity: 0.5;" : ""}

	}
`;
export const StagesContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	background: #fff;
	height: 36px;
	padding: 0 8px;
	min-width: 191px;
`;

export const CustomTabs = styled(Tabs)`
	display: flex;
	justify-content: start;
	margin-bottom: 1rem;
	/* border: none !important; */

	button {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
`;
export const FooterWrapper = styled.div`
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
	height: 82px;
	width: 100%;
	margin-top: 8px;
	border-radius: 4px;
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 1rem;
`;
export const PaginationTitle = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	margin-left: 1rem;
	min-width: 140px;
`;

export const QuickSelectionCard = styled.div`

		flex: 1 0 21%; // adjust this as needed
  margin: 1%;
  max-width: 100%;
  box-sizing: border-box;

	padding: 0.5rem 1rem;
	background: var(--color-Grey-Background, #F5F5F5);
	color:var(--color-Grey-Primary-Text, #262626);
	font-weight: 400;
	cursor: pointer;

	${(props) =>
		props.active &&
		css`
border-left: 4px solid var(--color-Grey-Secondary-Text, #595959);
			${QuickSelectionCardTitle} {
				font-weight: 600;
			}
		`};
`;
export const QuickSelectionCardTitle = styled.div`
color: var(--color-Grey-Primary-Text, #262626);
width: 100%; // or use max-width

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const QuickSelectionCardDescription = styled.div`
  width: 100%;
  color: var(--color-Grey-Secondary-Text, #595959);
  font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const ListComponentWrapper = styled.div`
	background: var(--color-Grey-Background, #F5F5F5);
	${(props) =>
		props.query &&
		css`
			/* border-right: 1px solid #c4c4c4;
			border-left: 1px solid #c4c4c4; */
		`}
`;
export const HeaderListComponent = styled.div`
	/* display: flex; */
	background: var(--color-Grey-Background, #F5F5F5);
		justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	/* height: 45px; */
	${(props) =>
		props.query &&
		css`
			height: unset;
			border: none;
			padding: 1rem;
		`}
	width: 320px;
	transition: all 0.5s ease-out;
`;
export const WrapperItems = styled.div`
	max-height: 100%;
	/* width: auto; */
	width: 320px;
	overflow: scroll;
	max-height: ${props => props.query ? 'calc(100vh - 320px)' : 'calc(100vh - 276px)'};
	transition: all 0.5s ease-out;
	gap:8px;
	    display: flex;
		flex-wrap: wrap;
		${props => props.disableScroll && "overflow: hidden;"}

`;
export const AddButton = styled(GenericButton)`
	color: #605f60;
	font-size: 14px;
`;
export const ContainerItem = styled.div`
	width: 100%;

`;

export const ContainerCxPage = styled.div`
	display: flex;
	/* width: 100%;
	height: 100%; */

	${(props) => props.disableViewer && `cursor: no-drop;;`}
`;

export const RelatedItemsContainer = styled.div`
	${(props) => (props.minHeight ? `min-height:${props.minHeight}px;overflow: auto;` : "")}
	${(props) => (props.maxHeight ? `max-height:${props.maxHeight}px;` : "")};
	/* padding-bottom: 1rem; */
	overflow:auto;
	.obj-name {
		width: 432px;
		color: #333;
		font-family: "Inter";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		padding: 0.5rem;
	}
`;

export const CustomLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
export const Divider = styled.div`
height: 1px;
background: var(--color-Grey-Dividers, #F0F0F0);
width: 95%;
justify-content: center;
align-items: center;
align-self: center;
`;

export const BorderWrapper = styled.div`
border : 1px solid #c4c4c4;
border-radius: 4px;
padding: 0.3rem 0rem;
 button {
	color: #262626;

}
`