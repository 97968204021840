import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";
import permissionsTree from "./permissionsTree.json";
import { UserTypeByValue } from "./optionsSets/OptionSets";

export const PermissionManager = ({ context, userAccess, isLock, isPreview }) => {
	let pathname = useLocation().pathname;
	const query = useLocation().search;
	const hideForPdf = query.hideForPdf();

	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const userTypeValue = Number(useSelector((state) => state.auth?.userInfo?.UserType));

	const userTypeName = UserTypeByValue[userTypeValue];
	const userAccessValue = userAccess !== undefined ? userAccess.toLowerCase() : undefined;

	const permissionData = _.get(permissionsTree, context ? context : pathname);
	if (!permissionData) return false;

	const isSpecificAction = "userType" in permissionData;

	const checkOneAction = (data) => {
		if (isPreview) return false;
		if (data.subscriptionPlan && !data.subscriptionPlan.includes(subscriptionPlan)) return false;
		if (data.userType && !data.userType.includes(userTypeName)) return false;
		if (data.hideForPdf && hideForPdf && !data.hideForPdf.includes(hideForPdf)) return false;
		if (data.isLock && isLock && !data.isLock.includes(isLock)) return false;
		if (data.userAccess && !data.userAccess.includes(userAccessValue)) return false;

		return true;
	};

	if (isSpecificAction) {
		return checkOneAction(permissionData);
	}
	const results = {};
	for (const key in permissionData) {
		results[key + "Allowed"] = checkOneAction(permissionData[key]);
	}
	return results;
};
