import { createSolution, getExcelExportListSol, updateFolderLinks, updateMultiSolutions } from "@redux/index";
import { FilterItemsByStatus, createDefaultSol, tableDefaultColumn, toastWrapper } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { BulkActions } from "features/solutions/actions/BulkActions";
import { t } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { useModal } from "use-modal-hook";
import BaseActions from "../../../solutions/actions/BaseActions";
import { ActionLink } from "../../columns-components/All_actions/ActionLink";
import { getAllMapsList } from "@redux/memoizedSelectors";

export const GetSolutionContext = (props) => {
	const { linked, showAllActiveRecord, disableBulkActions } = props;
	const { pathname, search } = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const cxProgramId = search.getCxProgramID();
	const folderId = search.getFolderId();
	const isReadOnly =  useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const all_maps = useSelector(getAllMapsList);
	const all_solutions = useSelector((state) => state.solutions.all_solutions || [], shallowEqual);
	const loading = useSelector((state) => state.solutions.loading);
	const specificContext = props.context.split(".")[1];

	const solution = useMemo(() => {
		return all_solutions?.filter((opp) => FilterItemsByStatus(opp, pathname, "/solutions", showSampleData, all_maps))
	}, [all_solutions, showSampleData, all_maps]);
	const userInfo = useSelector((state) => state.auth.userInfo);

	const linkedObjects = linked ? solution.filter((map) => linked?.includes(map.id)) : null;
	const solutionsData = useMemo(() => FormatSolutionData(solution, all_maps), [solution, all_maps]);

	const handleRedirect = ({ setIsCreateBtnDisabled }) => {
		if (setIsCreateBtnDisabled) setIsCreateBtnDisabled(true);
		const onSuccess = (data) => {
			if (setIsCreateBtnDisabled) setIsCreateBtnDisabled(false);
			if (folderId) {
				updateFolderLinks({ folderIds: [folderId], idsToLink: [data.id], entityName: "cem_cxsolution" });
			} else {
				history.push(`/edit-solution?id=${data.id}`);
			}
		};
		const onFailed = () => setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
		const newSolution = createDefaultSol(userInfo);
		createSolution({ item: newSolution, onSuccess, onFailed, cxProgramId, displayToast: "CREATE_NO_SUCCESS_TOAST" });
	};
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.Solution]?.[specificContext]?.columns || tableDefaultColumn(EntityList.Solution);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Solution]?.[specificContext]?.filters;

	const [showListLinkedModal] = useModal(ListLinkedModal);


 const getExcelExportList = (ids, columnView) => {
	const promise = async () => {
		const { data, error } = await dispatch(getExcelExportListSol(ids, columnView));
		var fileDownload = require("js-file-download");
		fileDownload(data, "Solutions List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};
	const data = useMemo(() => {
		const newData = {
			solutionsList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "SOLUTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				listEditable: true,
				showPagination: true,
				visibleHeader: true,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "subject", "statusId", "tagIds", "solutionType", "actions"],
				emptyListText: "SOLUTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "subject", "statusId", "tagIds", "solutionType", "actions"],
				emptyListText: "SOLUTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			cxProgram: {
				showCheckbox: false, // modals of cxProgram that link/unlink
				columns: ["subject", "mapId", "stageName", "statusId", "solutionType", "actions"],
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				showPagination: linked?.length > 10,
				nonDropDown: true,
				emptyListText: "SOLUTION_PLACEHOLDER_PROGRAM",
			},
		};
		const contextData = _.get(newData, specificContext);
		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;

		contextData.Actions = (solution) => CreateActionsDropdown({ actions: BaseActions({ solution }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = FormatSolutionData(linkedObjects, all_maps);
		contextData.listData = solutionsData;
		contextData.dataKey = "id";
		contextData.reducerPath = "solutions.all_solutions";
		contextData.route = "edit-solution";
		contextData.entityType = EntityList.Solution;
		contextData.exportExcel = getExcelExportList;
		contextData.loading = loading;
		contextData.onChangeObject = contextData?.listEditable ? (modifiedSolutions,modifiedAttribNames,onSuccess) => updateMultiSolutions({modifiedSolutions,modifiedAttribNames,onSuccess}) : null;
		contextData.buttonCreate = handleRedirect;
		contextData.buttonCreateLabel = "NEW_SOLUTION";
		contextData.filter = filters;

		if (specificContext === "cxProgram") {
			contextData.Actions = props?.disableAction ? () => {} : (options) => ActionLink(options, "cem_cxsolution", cxProgramId);
		}
		// folder case
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.Solution ,saveButtonLabel:'ASPX_44'});
			contextData.LinkActionLabel = "LINK_UNLINK_SOLUTIONS";
			contextData.displayExport = false;
		}

		return contextData;
	}, [isReadOnly, linked, linkedObjects, solutionsData, loading, columnsArray, disableBulkActions, showAllActiveRecord, cxProgramId, filters]);
	return data;
};

const FormatSolutionData = (data, allMap) => {
	return data?.map((solution) => {
		const newDate = new Date(solution?.lastUpdated);
		const StageName = allMap.find((map) => map.cjmId === solution?.mapId)?.stages?.filter((stage) => stage.id === solution?.stageId)[0]?.name;
		const map = allMap.find((map) => map.cjmId === solution?.mapId);
		const newDueDate = new Date(solution.dueDate);

		return {
			...solution,
			dueDateNew: newDueDate,
			lastUpdated: newDate,
			stageName: StageName === "" || !StageName ? t("NEW_STAGE") : StageName,
			mapName: map?.name,
			authorizedUsers: map?.authorizedUsers,
			groupIds: solution?.groups?.join("|"),
			tagIds: solution?.tags?.join("|"),
		};
	});
};
