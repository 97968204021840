import DataTable from "features/grid-table/DataTable";
import { useAsyncs } from "shared/hooks/index";
import { getAllMapsListData, getAllPersonas, groupInit, statusInit, tagInit } from "@redux/index";
import { getFolderById } from "@redux/memoizedSelectors";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { PageTemplate } from "shared/global-layouts/PageTemplate";

export default function JourneyMapList() {
	const { t } = useTranslation();
	const folderId = useLocation().search.getFolderId();
	const { linkedCjms } = useSelector((state) => getFolderById(state, folderId), shallowEqual);

	useAsyncs({
		asyncFns: [{ asyncFn: getAllMapsListData }, { asyncFn: getAllPersonas }, { asyncFn: groupInit }, { asyncFn: tagInit }, { asyncFn: statusInit }],
	});
	//<DataTable context="map.journeyMap" linked={linkedCjms} />
	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("ASPX_97")}`}>
			<DataTable context="map.journeyMap" linked={linkedCjms} />
		</PageTemplate>
	);
}
