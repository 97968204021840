import { filterItemsByUserMapRole } from "@utils/helpers";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { BulkActionsGenerator } from "shared/components/BulkActionsGenerator";
import BulkModalLinkComponent from "shared/modals/bulk-links/BulkModalLinkComponent";
import { ActionConfirmModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { archiveManyCxActions, deleteManyCxActions, deleteManyCxActionsPermanently, restoreManyCxActions } from "../reducer/CxActionActions";
export const BulkActions = (selectedActions, setSelected, onSuccess, setDisableBulkActions) => {
	const pathname = useLocation().pathname;
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";

	const actions = [];
	const allMaps = useSelector((state) => state.maps.all_maps);
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const selectedItemsIdsStr = selectedActions.map((item) => item?.id).join("|");
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);
	const filteredItemsIdsStr = filterItemsByUserMapRole({ allItems: allMaps, selectedItems: selectedActions, currentUserId, isSubscriptionStandard: !isSubscriptionAllowed });

	const filteredItemsByOwnersAndContrib = filterItemsByUserMapRole({
		allItems: allMaps,
		selectedItems: selectedActions,
		currentUserId,
		isSubscriptionStandard: !isSubscriptionAllowed,
		userRoles: ["owner", "contributor"],
	});

	const areAllActionsValid = selectedItemsIdsStr.length === filteredItemsIdsStr.length;

	const [showModalArchivePopup] = useModal(ActionConfirmModal, {
		bodyContent: "ARCHIVE_MANY",
		Action: () => {
			setDisableBulkActions(true);
			archiveManyCxActions({ids: filteredItemsIdsStr,displayToast: areAllActionsValid ? "BULK_ARCHIVE_SUCCESS" : "BULK_ARCHIVE_PARTIAL", onSuccess});
		},
		actionBtnText: "ARCHIVE",
	});

	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY",
		Action: () => {
			setDisableBulkActions(true);
			deleteManyCxActions({ids:filteredItemsIdsStr, displayToast: areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess});
		},
		actionBtnText: "ASPX_13",
	});

	const [showModalDeletePermanentPopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_PERMANENT",
		Action: () => {
			setDisableBulkActions(true);
			deleteManyCxActionsPermanently({ids:filteredItemsIdsStr, displayToast: areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess});
		},
		actionBtnText: "ASPX_13",
	});

	const [showBulkModalLinkComponent] = useModal(BulkModalLinkComponent);

	if (isTrash || isArchive) {
		if (filteredItemsIdsStr) actions.push({ handleClick: () => restoreManyCxActions({ids:filteredItemsIdsStr, onSuccess}), icon: "RESTORE", description: "JS_96" });
		if (filteredItemsIdsStr) actions.push({ handleClick: isArchive ? showModalDeletePopup : showModalDeletePermanentPopup, icon: "DELETE", description: isArchive ? "ASPX_13" : "JS_97" });
	} else {
		if (filteredItemsIdsStr) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "folder", idAttribute: "id" }), icon: "FOLDER-OPEN", description: "MOVE_TO_FOLDER" });
			if (isSubscriptionAllowed) actions.push({ handleClick: showModalArchivePopup, icon: "ARCHIVE", description: "ARCHIVE" });
			actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", description: "ASPX_13" });
		}

		if (filteredItemsByOwnersAndContrib) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "tag", idAttribute: "id" }), icon: "TAG", description: "TAG" });
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "group", idAttribute: "id" }), icon: "SHAPES", description: "GROUP" });
		}
	}

	return <BulkActionsGenerator actions={actions} setSelected={setSelected} warningText={"BULK_ACTIONS_MAP_OWNER_ONLY"} />;
};
