import { success, error } from "@redux-requests/core";
import { appendListsAndRemoveDuplicates } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets";

const initialState = {
    all_solutions: [],
    loading: false

}

const SolutionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SOLUTIONS':
            return {
                ...state,
                loading: state.all_solutions.length === 0
            }

        case error("GET_ALL_SOLUTIONS"):
            return {
                ...state,
                loading: false
            }
        case success('GET_ALL_SOLUTIONS'):
            return {
                ...state,
                all_solutions: action.response.data || [],
                loading: false
            }
        case success('CREATE_SOLUTION'):
            return {
                ...state,
                all_solutions: [...state.all_solutions, action?.response?.data]
            }
        case success('COPY_SOLUTION'):
            return {
                ...state,
                all_solutions: [...state.all_solutions, action?.response?.data]
            }
        case success('UPDATE_SOLUTION'):
            return {
                ...state,
                all_solutions: state.all_solutions?.map(solution => {
                    if (solution.id === action.meta.requestAction.request.data.modifiedSolution.id) {
                        solution = action.response.data
                    }
                    return solution
                })
            }
        case success("LINK_TAGS"): {
            const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
            if (recordType !== "cem_cxsolution") return state;
            return {
                ...state,
                all_solutions: state.all_solutions?.map(solution => {
                    if (recordIds.includes(solution.id)) {
                        solution.tags = [...appendListsAndRemoveDuplicates(solution.tags, tagIds)]
                    }
                    return solution
                })
            }
        }
        case success("LINK_GROUPS"): {
            const { groupIds, recordType, recordIds } = action.meta.requestAction.request.data;
            if (recordType !== "cem_cxsolution") return state;

            return {
                ...state,
                all_solutions: state.all_solutions?.map(solution => {
                    if (recordIds.includes(solution.id)) {
                        solution.groups = [...appendListsAndRemoveDuplicates(solution.groups, groupIds)]
                    }
                    return solution
                })
            }
        }
        case success('UPDATE_MULTI_SOLUTION'): {
            const modifiedSolutions = action.meta.requestAction.request.data.modifiedSolutions;
            const ids = action.meta.requestAction.request.data.modifiedSolutions?.map(x => x.id);
            const modifiedAttribNames = action.meta.requestAction.request.data.modifiedAttribNames

            return {
                ...state,
                all_solutions: state.all_solutions.map(solution => {
                    if (ids.includes(solution.id)) {
                        return {
                            ...solution,
                            [modifiedAttribNames]: modifiedSolutions?.find(x => x.id === solution?.id)?.[modifiedAttribNames]
                        }
                    }
                    return solution
                })
            }
        }
        case success('ARCHIVE_SOLUTION'):
            return {
                ...state,
                all_solutions: state?.all_solutions?.map(solution => {
                    if (solution.id === action.meta.requestAction.request.data.id) {
                        return {...solution, statusCode: StatusCode.Archived}
                    }
                    return solution
                })
            };
        case success('ARCHIVE_MANY_SOLUTIONS'):
            return {
                ...state,
                all_solutions: state.all_solutions?.map(solution => {
                    if (action.response.data.includes(solution.id)) {
                        return {...solution, statusCode: StatusCode.Archived}
                    }
                    return solution
                })
            };
        case success('DELETE_SOLUTION'):
            return {
                ...state,
                all_solutions: state?.all_solutions?.map(solution => {
                    if (solution.id === action.meta.requestAction.request.data.id) {
                        return {...solution, statusCode: StatusCode.Trashed}
                    }
                    return solution
                })
            };
        case success('DELETE_MANY_SOLUTIONS'):
            return {
                ...state,
                all_solutions: state.all_solutions?.map(solution => {
                    if (action.response.data.includes(solution.id)) {
                        return {...solution, statusCode: StatusCode.Trashed}
                    }
                    return solution
                })
            };
        case success('DELETE_SOLUTION_PERMANENT'):
            return {
                ...state,
                all_solutions: state.all_solutions.filter(solution => solution.id !== action.meta.requestAction.request.data.id)
            }
        case success('DELETE_MANY_SOLUTIONS_PERMANENT'):
            return {
                ...state,
                all_solutions: state.all_solutions.filter(solution => !action.response.data.includes(solution.id))
            };
        case success('RESTORE_SOLUTION'):
            return {
                ...state,
                all_solutions: state.all_solutions.map(solution => {
                    if (solution.id === action.meta.requestAction.request.data.id) {
                        return {...solution, statusCode: StatusCode.Active}
                    }
                    return solution
                })
            };
        case success('RESTORE_MANY_SOLUTIONS'):
            return {
                ...state,
                all_solutions: state.all_solutions?.map(solution => {
                    if (action.response.data.includes(solution.id)) {
                        return {...solution, statusCode: StatusCode.Active}
                    }
                    return solution
                })
            };

        default:
            return state;
    }
}

export default SolutionsReducer;
