import { ActionConfirmModal } from "shared/modals/index";
import { archiveInsights, deleteInsights, deleteInsightsPermanent, restoreInsights } from "features/insights/reducer/InsightActions";
import { PermissionManager } from "@utils/permissionManager";
import { useTranslation } from "react-i18next";
import { useModal } from "use-modal-hook";
import { useSelector } from "react-redux";
import { EMPTY_GUID } from "@utils/helpers";

export default function BaseActions({ insight, context, handleRedirect }) {
	const { t } = useTranslation();
	const [actionConfirmModal] = useModal(ActionConfirmModal);
	const id = insight.id;
	const userId = useSelector((state) => state.auth?.userInfo?.UserId);

	const userAccess = insight?.authorizedUsers?.find((user) => user.id === userId && user.roleId === EMPTY_GUID)?.roleName;

	const handleArchive = () => {
		actionConfirmModal({
			bodyContent: "ARCHIVE_GENERIC",
			Action: () => archiveInsights({ ids: [id], onSuccess: handleRedirect }),
			actionBtnText: "ARCHIVE",
		});
	};

	const handleDelete = () => {
		actionConfirmModal({
			bodyContent: "DELETE_GENERIC",
			Action: () => deleteInsights({ ids: [id], onSuccess: handleRedirect }),
			actionBtnText: "ASPX_13",
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deleteInsightsPermanent({ ids: [id] }),
			actionBtnText: "ASPX_13",
		});
	};

	const { archiveAllowed, deleteAllowed, restoreAllowed, deletePermanentAllowed } = PermissionManager({

	});

	const createActions = () => {
		let actions = [];
		if (archiveAllowed) {
			actions.push({
				id: "archive",
				handleClick: handleArchive,
				icon: "ARCHIVE",
				text: t("ARCHIVE"),
			});
		}
		if (deleteAllowed) {
			actions.push({
				id: "delete",
				handleClick: handleDelete,
				icon: "DELETE",
				text: t("CS_45"),
			});
		}
		if (restoreAllowed) actions.push({ id: "restore", handleClick: () => restoreInsights({ ids: [id] }), icon: "RESTORE", text: t("JS_96") });
		if (deletePermanentAllowed) actions.push({ id: "deletePermanent", handleClick: handleDeletePermanent, itemStyle: "warning-delete", icon: "DELETE", text: t("JS_97") });
		if (insight?.isSample) {
			actions = []
		}
		return actions;
	};

	return createActions();
}
