import { IconDispatcher } from "shared/Icons";
import styled, { css } from "styled-components";

const InitialsBubble = ({ initials, fullName, showRoleIcon = false,role, size = "s" }) => {
	if (fullName) {
		initials = fullName?.getInitials();
	}
	return <Container size={size} title={fullName} showIcon={showRoleIcon.toString()}>
		<span id="initials">{initials?.toUpperCase()}</span>
		{showRoleIcon && <span id="icon" title={role}>{roleValidation(role)}</span>}
		</Container>;
};
function roleValidation (roleMode){
	switch (roleMode) {
		case "owner":
			return IconDispatcher("ACCESS");
		case "contributor":
			return IconDispatcher("EDIT");
		case "viewer":
			return IconDispatcher("VIEW");
		default:
			return IconDispatcher("ACCESS");
	}
}
InitialsBubble.displayName = "InitialsBubble";
export default InitialsBubble;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	border-radius: 50%;
	border: 1px solid #605f60;
	color: #605f60;
	text-align: center;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	#icon {
		display: none;
	}
	:hover #initials {
		${(props) => props.showIcon === "true" && "display:none"};
	}
	:hover #icon {
		${(props) => props.showIcon === "true" && "display:block"};
	}
	${(props) =>
		props.size === "xs" &&
		css`
			width: 24px;
			height: 24px;
			font-size: 10px;
            font-weight: 600;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			width: 32px;
			height: 32px;
			font-size: 14px;
		`}


	${(props) =>
		props.size === "m" &&
		css`
			width: 36px;
			height: 36px;
			font-size: 14px;
		`}

	${(props) =>
		props.size === "l" &&
		css`
			width: 80px;
			height: 80px;
			font-size: 32px;
		`}
`;
