import { BasicDrillDownModal } from "features/analytics/modals/BasicDrillDownModal";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import { useSelector } from "react-redux";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useModal } from "use-modal-hook";
import EmptyData from "./EmptyData";
import styled from "styled-components";
import CustomTooltip from "shared/components/CustomTooltip";

function HorizontalBarsChart({ dataSelector, barColor, relatedItemsTitle, listItemComponent, itemAttributeId = "id", emptyDataText }) {
	const [drillDownModal] = useModal(BasicDrillDownModal);
	const data = useSelector(dataSelector);

	const LabelName = ({ x, y, name }) => {
		const newX = x;
		const newY = y - 24;
		return (
			<foreignObject x={newX} y={newY} width="100%" height="25">
				<CustomTooltip text={name}>
					<CustomLabelName>{name}</CustomLabelName>
				</CustomTooltip>
			</foreignObject>
		);
	};

	const LabelValue = ({ x, y, width, value, index }) => {
		const { currencySymbol } = data[index];
		const newX = x + width + 5;
		const newY = y + 15;

		return (
			<text x={newX} y={newY} textAnchor="start" fontSize={14} color="#605F60">
				{value.toLocaleString()} {currencySymbol ?? ""}
			</text>
		);
	};

	const renderDrillDownIllustration = ({ data }) => {
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					data={data}
					layout="vertical"
					margin={{
						right: 100,
						left: -60,
					}}>
					<XAxis type="number" axisLine={false} display="none" />
					<YAxis type="category" axisLine={false} display="none" />
					<Bar dataKey="value" fill={barColor} barSize={20} >
						<LabelList dataKey="name" content={<LabelName />} />
						<LabelList dataKey="value" content={<LabelValue />} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		);
	};

	const handleBarClick = ({ value, name, relatedItems }) => {
		const data = [{ name, value }];
		drillDownModal({
			title: `${name}`,
			relatedItemsTitle,
			renderIllustration: () => renderDrillDownIllustration({ data }),
			ListItemComponent: listItemComponent,
			items: relatedItems,
			itemAttributeId,
		});
	};

	return (
		<>
			{data?.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={data}
						layout="vertical"
						margin={{
							right: 100,
							left: -60,
						}}>
						<XAxis type="number" axisLine={false} display="none" />
						<YAxis type="category" axisLine={false} display="none" />
						<Bar dataKey="value" fill={barColor} barSize={20} cursor={listItemComponent && "pointer"} onClick={listItemComponent && handleBarClick}>
							<LabelList dataKey="name" content={<LabelName />} />
							<LabelList dataKey="value" content={<LabelValue />} />
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text={emptyDataText} icon="CHART-BAR" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

HorizontalBarsChart.displayName = "HorizontalBarsChart";
export default HorizontalBarsChart;

const CustomLabelName = styled.div`
	color: #605f60;
	font-size: 14px;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
