import { constructUrl } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};

const BASE_URL = "api/cxassessment/";

const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" });
const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" });

export const getAllCxAssessments = ({ cxProgramId }) =>
	createGetAction({
		type: "GET_ALL_CX_ASSESSMENTS",
		endPoint: constructUrl("getAll", {
			cxProgramId,
		}),
	});

export const createCxAssessment = ({ cxProgramId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_CX_ASSESSMENT",
			endPoint: "create",
			data: {
				cxProgramId,
			},
		}),
	});

export const updateCxAssessment = ({ cxProgramId, modifiedCxAssessment, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CX_ASSESSMENT",
			endPoint: "update",
			data: {
				cxProgramId,
				modifiedCxAssessment,
				modifiedAttribNames,
			},
		}),
	});

export const deleteCxAssessment = ({ cxProgramId, cxAssessmentIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CX_ASSESSMENTS",
			endPoint: "deletePermanently",
			data: {
				cxProgramId,
				cxAssessmentIds,
			},
		}),
		displayToast: props?.displayToast || "DELETE",
	});

export const getCxProgramAssessmentsExcelExportList = ({ cxProgramId }) =>
	createGetAction({
		type: "GET_CX_PROGRAM_ASSESSMENTS_EXCEL",
		endPoint: constructUrl("getExcelExport", {
			cxProgramId,
		}),
		responseType: "blob",
	});
