import { GenericButton, RadioButton } from "shared/ui-basics/buttons";
import { FlexAlign } from "shared/ui-basics/index";
import InsightType from "shared/ui-basics/ui-components/InsightType";
import { updateInsight } from "@redux/index";
import { getAllInsights } from "@redux/memoizedSelectors";
import { EMPTY_GUID } from "@utils/helpers";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";

const SelectInsightTypeModal = memo(({ isOpen, onClose, selectedItems }) => {
	const { t } = useTranslation();
	const insightIds = selectedItems.map(({ insightId }) => insightId);
	const selectedInsights = useSelector(getAllInsights)?.filter((insight) => insightIds?.includes(insight.id));
	const insightTypes = useSelector((state) => state.libraries?.insightTypes);
	const [selectedType, setSelectedType] = useState(EMPTY_GUID);

	const apply = ({typeId}) => {
		onClose();
		selectedInsights.forEach((insight) => {
			updateInsight({ modifiedInsight: { ...insight, typeId: typeId??selectedType }, modifiedAttribNames: ["type"] });
		});
	};
	return (
		<ModalContainer fade={false} isOpen={isOpen} toggle={onClose} size="sm" centered={true}>
			<ModalHeader toggle={onClose}>{t("SELECT_TYPE")}</ModalHeader>
			<TypesContainer className="p-4">
				{insightTypes?.map(({ id }) => (
					<FlexAlign key={id} gap="10" className="mb-3">
						<RadioButton selected={selectedType === id} onClick={() => setSelectedType(id)} showpointer />
						<InsightType typeId={id}  />
					</FlexAlign>
				))}
			</TypesContainer>
			<CustomFooter>
				<GenericButton variant="light" className="text-capitalized" onClick={()=>{apply({typeId:EMPTY_GUID})}}>
					{t("CLEAN")}
				</GenericButton>
				<GenericButton variant="primary" className="text-capitalized" onClick={apply}>
					{t("APPLY")}
				</GenericButton>
			</CustomFooter>
		</ModalContainer>
	);
});

SelectInsightTypeModal.displayName = "SelectInsightTypeModal";
export { SelectInsightTypeModal };

const ModalContainer = styled(Modal)`
	max-width: 400px;
`;

const CustomFooter = styled(ModalFooter)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const TypesContainer = styled.div`
	border-bottom: 1px solid #c4c4c4;
	max-height: 500px;
	overflow: scroll;
`;
