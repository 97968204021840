import { FlexAlignJustifyCenter, IconDispatcher } from "shared/ui-basics/index";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import CustomTooltip from "../../../../shared/components/CustomTooltip";
import React from "react";

const PersonaCardWithSelectedCheck = ({ persona, selected, setSelected }) => {
	const MAX_LENGTH = 15;

	// redux
	const personaDetails = useSelector((state) => state.personas.all_personas?.find((pers) => persona.personaId === pers.personaId));

	return (
		<Card className="p-2" selected={personaDetails?.personaId === selected} onClick={() => setSelected(personaDetails?.personaId)}>
			<CheckLabel selected={personaDetails?.personaId === selected}>{IconDispatcher("PRIMARY-CHECK")}</CheckLabel>
			<FlexAlignJustifyCenter className="m-2">
			<PersonaBubble id="persona-picture"  persona={personaDetails} size={"80"} />
			</FlexAlignJustifyCenter>
			<CustomTooltip id={personaDetails?.personaId} text={personaDetails?.name} disabled={!personaDetails?.name || personaDetails?.name?.length <= MAX_LENGTH}>
				<PersonaName className="ellipsis">{personaDetails?.name}</PersonaName>
			</CustomTooltip>
		</Card>
	);
};

export default PersonaCardWithSelectedCheck;

const CheckLabel = styled.div`
	position: absolute;
	height: 16px;
	width: 16px;
	display: flex;
	top: 5px;
	left: 5px;
	visibility: ${(props) => (props.selected === true ? "visible" : "hidden")};
`;

const Card = styled.div`
	position: relative;
	width: 160px;
	height: 180px;
	/* display: flex;
	flex-direction: column;
	justify-content: space-around; */
	align-items: center;
	border-radius: 4px;
	border: 1px solid ${(props) => (props.selected ? "#F96209" : "#c4c4c4")};
	:hover {
		cursor: pointer;
		box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;

const PersonaName = styled.div`
	margin: 1rem 0;
	width: 100%;
	color: #605f60;
	text-align: center;
	font-size: 16px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-overflow: ellipsis;
`;
