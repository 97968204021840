import GenericSwitch, { GenericButton } from "shared/ui-basics/buttons";
import VerticalDND from "features/journey-map/components/DnD/VerticalDND";
import { setColumnsArray } from "features/grid-table/reducer/TableSlice";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { reorder, tableDefaultColumn } from "@utils/helpers";
import React, { useCallback, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as S from "./Styles";

const settingInfo = () => {
	const array = {
		[EntityList.Persona]: [
			{ name: "JS_82", id: "personaName" },
			{ name: "OCCUPATION", id: "occupation" },
			{ name: "JS_72", id: "lastModifiedOn" },
			{ name: "CS_41", id: "associatedContactsCount" },
			{ name: "ASPX_97", id: "cjmCount" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "JS_77", id: "isFutureLabel" },
			{ name: "CS_35", id: "owner" },
		],
		[EntityList.CustomerJourney]: [
			{ name: "JOURNEY_MAP_NAME", id: "name" },
			{ name: "RELATED_PERSONAS", id: "linkedPersonas" },
			{ name: "JS_77", id: "isFutureLabel" },
			{ name: "JS_72", id: "lastModifiedOn" },
			{ name: "JS_78", id: "mapType" },
			{ name: "CS_35", id: "owner" },
			{ name: "JS_79", id: "score" },
			{ name: "ASPX_222", id: "statusId" },
			{ name: "GROUPS", id: "groupIds" },
			{ name: "TAGS", id: "tagIds" },
		],

		[EntityList.Opportunity]: [
			{ name: "OPPORTUNITY_NAME", id: "subject" },
			{ name: "PRIORITY", id: "priorityId" },
			{ name: "JOURNEY_MAP", id: "mapId" },
			{ name: "ASPX_172", id: "stageName" },
			{ name: "GROUPS", id: "groupIds" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "CS_35", id: "ownerId" },
			{ name: "CUSTOMER_VALUE", id: "customerValue" },
			{ name: "BUSINESS_VALUE", id: "businessValue" },
			{ name: "QUICK_WIN", id: "win" },
			{ name: "MOMENT_TRUTH", id: "truth" },
			{ name: "DUE_DATE", id: "dueDateNew" },
			{ name: "ASPX_222", id: "statusId" },
			{ name: "JS_72", id: "lastUpdated" },
			{ name: "ASPX_9", id: "description" },
		],
		[EntityList.Action]: [
			{ name: "ACTION_NAME", id: "subject" },
			{ name: "PRIORITY", id: "priorityId" },
			{ name: "JOURNEY_MAP", id: "mapId" },
			{ name: "ASPX_172", id: "stageName" },
			{ name: "GROUPS", id: "groupIds" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "DUE_DATE", id: "dueDateNew" },
			{ name: "CS_35", id: "ownerId" },
			{ name: "ASPX_222", id: "statusId" },
			{ name: "JS_72", id: "lastUpdated" },
			{ name: "ASPX_9", id: "description" },
		],
		[EntityList.Solution]: [
			{ name: "SOLUTION_NAME", id: "subject" },
			{ name: "JOURNEY_MAP", id: "mapId" },
			{ name: "ASPX_172", id: "stageName" },
			{ name: "JS_78", id: "solutionType" },
			{ name: "GROUPS", id: "groupIds" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "CS_35", id: "ownerId" },
			{ name: "ASPX_222", id: "statusId" },
			{ name: "JS_72", id: "lastUpdated" },
			{ name: "DUE_DATE", id: "dueDateNew" },
			{ name: "ASPX_9", id: "description" },
		],
		[EntityList.CXProgram]: [
			{ name: "CX_PROGRAM_NAME", id: "cxProgramName" },
			{ name: "ASPX_97", id: "totalNumCjms" },
			{ name: "ASPX_98", id: "totalNumPersonas" },
			{ name: "OPPORTUNITIES", id: "totalNumOpps" },
			{ name: "SOLUTIONS", id: "totalNumSols" },
			{ name: "JS_80", id: "totalNumActions" },
			{ name: "CS_35", id: "authorizedUsers" },
			{ name: "JS_72", id: "lastUpdated" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "PROJECTS", id: "totalNumProjects" },
		],
		[EntityList.Project]: [
			{ name: "PROJECT_NAME", id: "projectName" },
			{ name: "CREATED_ON", id: "createdOn" },
			{ name: "JS_72", id: "lastUpdated" },
			{ name: "START_DATE", id: "startDate" },
			{ name: "END_DATE", id: "endDate" },
			{ name: "PROJECT_OWNER", id: "ownerId" },
			{ name: "ASPX_222", id: "statusId" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "GROUPS", id: "groupIds" },
			{ name: "PRIORITY", id: "priorityId" },
			{ name: "PROGRESS", id: "projectProgress" },
		],
		[EntityList.Insight]: [
			{ name: "NAME", id: "insightName" },
			{ name: "ASPX_98", id: "linkedPersonas" },
			{ name: "OCCURANCE", id: "occurance" },
			{ name: "JOURNEY_MAP", id: "cjmIds" },
			{ name: "JS_78", id: "insightType" },
			{ name: "TAGS", id: "tagIds" },
			{ name: "GROUPS", id: "groupIds" },
			{ name: "JS_72", id: "lastUpdated" },
			{ name: "ASPX_9", id: "description" },
		],
		[EntityList.User]: [
			{ name: "CS_52", id: "userDetails" },
			{ name: "ROLE", id: "role" },
			{ name: "CS_59", id: "userTypeName" },
			{ name: "PLAN_TYPE", id: "subscriptionPlan" },
			{ name: "WORKSPACES", id: "workspaces" },
			{ name: "TEAMS", id: "teams" },
			{ name: "CS_48", id: "isActive" },
		],
	};
	return array;
};
export const ColumnSettingsModal = ({ isOpen, onClose, context, entityType }) => {
	const { t } = useTranslation();

	const [columnToDisplay, setColumnToDisplay] = useState(TransformColumnData(context, entityType));

	const updateColumns = (column) => {
		setColumnToDisplay((prevState) => {
			return prevState.map((col) => {
				if (col.id === column.id) {
					return { ...column, display: !column?.display };
				}
				return col;
			});
		});
	};
	const saveQuery = async () => {
		const column = columnToDisplay?.filter((i) => i.display === true).map((i) => i.id);
		if (column && !column.includes("actions")) column.push("actions");
		if (column && !column.includes("select")) column.unshift("select");
		setColumnsArray({ entityType: entityType, columns: column, context: context });
		onClose();
	};
	const rendering = ({ item }) => {
		return (
			<S.DivWrapper className="my-1 d-flex">
				<GenericSwitch isChecked={item.display} handleToggle={() => updateColumns(item)} text={item.name} justifyContent="space-between" gap={"10"} className="w-100 me-3 ms-5" />
			</S.DivWrapper>
		);
	};
	const RenderValidation = useCallback(
		({ item }) => {
			return rendering({ item });
		},
		[columnToDisplay?.length, rendering]
	);

	const updateTable = (source, destination) => {
		const newState = [...reorder(columnToDisplay, source, destination)];
		setColumnToDisplay(newState);
	};

	return (
		<S.OffcanvasLayout show={isOpen} onHide={onClose} scroll={true} backdrop={true} placement={"end"}>
			<S.HeaderContainer closeButton className="border-bottom">
				{t("CONFIGURE_COLUMNS")}
			</S.HeaderContainer>
			<Offcanvas.Body className="pt-3">
				<DivWrapper>
					<VerticalDND state={columnToDisplay} className="" CardsValidationRender={RenderValidation} reordingTable={updateTable} />
				</DivWrapper>
				<GenericButton variant="primary" className="ms-3 mt-3" onClick={saveQuery}>
					{t("APPLY")}
				</GenericButton>
			</Offcanvas.Body>
		</S.OffcanvasLayout>
	);
};
const TransformColumnData = (context, entityType) => {
	const getColumn = settingInfo()[entityType];
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[entityType]?.[context]?.columns || tableDefaultColumn(entityType);
	///check from back display props
	const sortByGetColumn = getColumn
		?.map((col) => {
			return { ...col, display: columnsArray?.includes(col.id) };
		})
		.sort((a, b) => columnsArray?.indexOf(a?.id) - columnsArray?.indexOf(b?.id));
	return sortByGetColumn?.sort((a, b) => b.display - a.display);
};
const DivWrapper = styled.div`
	height: calc(100% - 80px);
	overflow-y: auto;
`;
