import WorkspaceDropdown from "features/workspace/components/dropdowns/workspace-dropdown";
import { Dropdown } from "react-bootstrap";
import { useGetUserWorkspaces } from "shared/hooks";
import { CustomDropdownButton } from "shared/ui-basics";
import styled from "styled-components/macro";

export const WorkspacesColumn = ({ user }) => {
	const currentUserWorkspaces = useGetUserWorkspaces(user?.loginName?.toLowerCase());
	const name = currentUserWorkspaces[0]?.name;

	const ToggleComponent = () => {
		return (
			<Dropdown.Toggle as={CustomDropdownButton}>
				<Title title={name}>{`${currentUserWorkspaces[2]?.name} + ${currentUserWorkspaces?.length - 1}`}</Title>
			</Dropdown.Toggle>
		);
	};

	const render = () => {
		if (currentUserWorkspaces?.length === 0) return "-";
		if (currentUserWorkspaces?.length === 1) return <Title title={name}>{name}</Title>;
		return <WorkspaceDropdown toggleComponent={ToggleComponent} size="xs" isMenuItemDisabled={true} specificWorkspaces={currentUserWorkspaces} />;
	};

	return <>{render()}</>;
};

WorkspacesColumn.displayName = "WorkspacesColumn";

const Title = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	width: 120px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
