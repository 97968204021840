import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const CustomTooltip = (props) => {
  const { id, text, placement,  delay, children, disabled = false, style, className, buttonRef , defaultOpen = false} = props;

  const [show, setShow] = React.useState(defaultOpen);
  const handleToggle = (visible) => {
    setShow(visible);
  }
  const handleClick = () => {
    setShow(false);
  };

  const displayTooltip = () => {
    if (disabled) {
      return children;
    } else {
      return (
        <OverlayTrigger
          target={() => buttonRef.current}
          delay={delay !== undefined ? delay : ''}
          placement={placement !== undefined ? placement : 'auto'}
          onToggle={handleToggle}
          show={show}
          overlay={
            <Tooltip id={`tooltip-${id !== undefined ? id : Math.random()}`} style={{ ...style, display: text?.length === 0 ? 'none' : 'flex' }}>
              {text}
            </Tooltip>
          }
        >
            <div onClick={handleClick} className={className} style={{ ...style }}>
              {children}
            </div>

        </OverlayTrigger>
      );
    }
  };

  return displayTooltip();
}


export default CustomTooltip;

