import WorkspaceLogoAndName from "features/workspace/components/others/workspace-logo-and-name";
import { Dropdown } from "primereact/dropdown";
import "react-bootstrap-typeahead/css/Typeahead.css";
import styled, { css } from "styled-components";

const WorkspaceSelectorCard = ({ workspace, roleName, isNoneDisabled, handleChange, isDisabled }) => {
	const options = [
		{ name: "None", value: "none", disabled: isNoneDisabled },
		{ name: "Owner", value: "owner", disabled: false },
		{ name: "Member", value: "viewer", disabled: false },
	];

	const onChange = (e) => {
		const roleName = e.value;
		handleChange({ workspaceId: workspace?.id, roleName });
	};

	return (
		<SelectorContainer disabled={isDisabled}>
			<LogoContainer>
				<WorkspaceLogoAndName workspace={workspace} isDisabled={true} />
			</LogoContainer>
			<CustomDropdown value={roleName} onChange={onChange} options={options} optionLabel="name" optionDisabled="disabled" disabled={isDisabled} />
		</SelectorContainer>
	);
};

WorkspaceSelectorCard.displayName = "WorkspaceSelectorCard";
export default WorkspaceSelectorCard;

const SelectorContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
	background: #fff;
	padding: 8px 4px;
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			background-color: #e9ecef !important;
		`}
`;

const LogoContainer = styled.div`
	max-width: 300px;
`;


const CustomDropdown = styled(Dropdown)`
	box-shadow: none !important;
	width: 160px;
	height: 36px;
    display: flex;
    align-items: center;
`;
