import { EMPTY_GUID, filterByActiveUsers, findFirstAlphabeticalCharacter } from "@utils/helpers";
import { modes } from "@utils/optionsSets/OptionSets";
import BaseActions from "features/workspace/actions/BaseActions";
import { isActive } from "features/workspace/utils";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import CustomTooltip from "shared/components/CustomTooltip";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { FlexAlignCenter, FlexColumn, IconDispatcher, Label } from "shared/ui-basics";
import styled, { css } from "styled-components";
import WorkspaceLogo from "../others/workspace-logo";
import { useSelector } from "react-redux";

export const WorkspaceCard = ({ workspace, isCompanyAdmin }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const users = useSelector((state) => state.settings.users);
	const authorizedUsers = useSelector((state) => state.settings.authorizedUsers);

	const firstFilterStep = workspace?.authorizedUsers?.filter((user) => user?.roleId !== EMPTY_GUID);
	const filteredUsers = filterByActiveUsers({ authorizedUsers: authorizedUsers, registeredUsers: users });

	const workspaceUsers = {
		users: filteredUsers?.filter((user) => firstFilterStep?.find((fu) => user.id === fu.id) && user?.accessType === "user"),
		teams: firstFilterStep?.filter((user) => user?.accessType === "team"),
	};
	const isDisabled = workspace?.statusCode === 2;
	const redirect = () => {
		history.push(`/edit-workspace?id=${workspace.id}&mode=${modes.update}`);
	};

	return (
		<CardContainer onClick={redirect} disabled={isDisabled}>
			<Body>
				<Header>
					<FlexAlignCenter gap="24">
						<WorkspaceLogo letter={findFirstAlphabeticalCharacter(workspace?.name)} colorCode={workspace?.colorCode} size="l" />
						<FlexColumn>
							<CustomTooltip text={workspace?.name} disabled={workspace?.name?.length < 30}>
								<Name disabled={isDisabled}>{workspace?.name}</Name>
							</CustomTooltip>
							<FlexAlignCenter gap="20">
								<FlexAlignCenter gap="5">
									<div>{IconDispatcher(isActive({ workspace }) ? "CIRCLE-CHECK" : "CIRCLE-PAUSE", "", { style: "thin" })}</div>
									<Label>{`${isActive({ workspace }) ? t("CS_48") : t("DISABLED")}`}</Label>
								</FlexAlignCenter>
								<div>{workspace?.isDefault && <Default>{t("DEFAULT")}</Default>}</div>
							</FlexAlignCenter>
						</FlexColumn>
					</FlexAlignCenter>
					{isCompanyAdmin && (
						<ActionsContainer>
							<Actions>{CreateActionsDropdown({ actions: BaseActions({ workspace }), variant: "", dropdownClasses: "", stopPropagation: true })}</Actions>
						</ActionsContainer>
					)}
				</Header>
				<Footer>
					<FlexAlignCenter>
						<Label>{`${workspaceUsers?.users?.length} ${workspaceUsers?.users?.length > 1 ? t("ASPX_210") : t("CS_52")} | ${workspaceUsers?.teams?.length} ${
							workspaceUsers?.teams?.length > 1 ? t("TEAMS") : t("TEAM")
						}`}</Label>
						<Label className="ms-4 me-2">{`${t("CREATED_ON")}:`}</Label>
						<Label>
							<Moment format="MMM D, YYYY">{workspace?.createdOn}</Moment>
						</Label>
					</FlexAlignCenter>
				</Footer>
			</Body>
		</CardContainer>
	);
};

const Actions = styled.div``;

const CardContainer = styled.div`
	display: flex;

	height: 135px;
	width: 960px;
	border-radius: 4px;
	border: 1px solid #efefef;
	background: #fff;
	cursor: pointer;
	:hover {
		box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
	}
	&:hover ${Actions} {
		visibility: visible;
	}
`;

const Body = styled.div`
	width: 100%;
	margin: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #efefef;
	padding-bottom: 12px;
	width: 100%;
`;

const ActionsContainer = styled.div`
	align-self: flex-start;
`;

const Footer = styled.div`
	padding-top: 12px;
`;

const Name = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	max-width: max-content;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 555px;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.disabled &&
		css`
			color: rgba(51, 51, 51, 0.6);
			:hover {
				pointer-events: none;
			}
		`}
`;

const Default = styled.div`
	height: 26px;
	width: 50px;
	padding: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: #efefef;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
`;
