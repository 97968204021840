import { EMPTY_GUID, constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta, ...props }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
		...props
	});
};

const createPostActionLib = InitActionCreator({ baseUrl: "api/library/", method: "POST" }); // api actions type post
const createGetActionLib = InitActionCreator({ baseUrl: "api/library/", method: "GET" });

export const libraryInit = () => createGetActionLib({ type: "LIBRARY_INIT", endPoint: "getAll", meta: { joinRequest: 'LIBRARY_INIT' } });

export const upsertLibrary = ({ library, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'UPSERT_LIBRARY', endPoint: "upsert",
		data: {
			library,
		},
	}),
	displayToast: props.displayToast || "CREATE_NO_SUCCESS_TOAST"
});
export const copyLibrary = ({ libraryId, name, workspaceIds, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'COPY_LIBRARY', endPoint: "copy",
		data: {
			libraryId,
			newSubject: encode(name),
			workspaceIds
		},
	}),
	displayToast: "COPY"
});
export const deleteLibrary = ({ libraryId, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'DELETE_LIBRARY', endPoint: "delete",
		data: {
			libraryId,
		},
	}),
	displayToast: "DELETE"
});
export const upsertValueLibrary = ({ libraryValue, libraryId, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'UPSERT_VALUE_LIBRARY', endPoint: "upsertValue",
		data: {
			libraryValue,
			libraryId,
		},
	}),
	displayToast: props.displayToast || "CREATE_NO_SUCCESS_TOAST"
});
export const deleteValueLibrary = ({ libraryId, libraryValueId, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'DELETE_VALUE_LIBRARY', endPoint: "deleteValue",
		data: {
			libraryId,
			libraryValueId,
		},
	}),
	displayToast: "DELETE"
});

export const copyValueLibrary = ({ libraryId, libraryValueId, name, workspaceIds, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'COPY_VALUE_LIBRARY', endPoint: "copyValue",
		data: {
			libraryId,
			libraryValueId,
			newSubject: encode(name),
			workspaceIds
		},
	}),
	displayToast: "COPY"
});

export const unlinkLibraryValue = ({ swimLaneItemId, customSwimLaneId, stageId, cjmId, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'UNLINK_LIBRARY_VALUE', endPoint: "unlinkLibraryValue",
		data: {
			customSwimLaneId,
			swimLaneItemId,
			stageId,
			cjmId,
		},
	}),
});
export const getCustomLibraryLinkedRecords = ({ libraryId, libraryValueId }) => createGetActionLib({
	type: "CUSTOM_LIBRARY_GET_RELATED_RECORDS",
	endPoint: constructUrl("getRelatedRecord", {
		libraryId,
		libraryValueId,
	}),
	params: { libraryId }
});
export const linkLibraryValues = ({ swimLaneItemIds = [], libraryValueIds = [], customSwimLaneId, stageId, cjmId, ...props }) => genericService({
	...props, action: createPostActionLib({
		type: 'LINK_LIBRARY_VALUES_MULTI', endPoint: "linkLibraryValues",
		data: {
			swimLaneItemIds,
			libraryValueIds,
			customSwimLaneId,
			stageId,
			cjmId,
		},
	}),
});


const createPostActionPriorities = InitActionCreator({ baseUrl: "api/priority/", method: "POST" }); // api actions type post
const createGetActionPriorities = InitActionCreator({ baseUrl: "api/priority/", method: "GET" });

export const priorityInit = () => createGetActionPriorities({ type: "PRIORITY_INIT", endPoint: "getAll", meta: { joinRequest: 'PRIORITY_INIT' } });

export const updatePriorityAttribute = ({ modifiedPriority, modifiedAttribNames, type, ...props }) => genericService({
	...props, action: createPostActionPriorities({
		type: 'UPDATE_PRIORITY', endPoint: "update",
		data: {
			modifiedPriority: modifiedPriority,
			modifiedAttribNames: modifiedAttribNames,
		},
		params: {
			type: type,
		},
	}),
});
export const addPriority = ({ newPriority, type, ...props }) => genericService({
	...props, action: createPostActionPriorities({
		type: 'ADD_PRIORITY', endPoint: "create",
		data: {
			newPriority,
		},
		params: {
			type: type,
		},
	}),
});
export const removePriority = ({ id, type, ...props }) => genericService({
	...props, action: createPostActionPriorities({
		type: 'REMOVE_PRIORITY', endPoint: "delete",
		data: {
			priorityId: id,
		},
		params: {
			type: type,
		},
	}),
});
export const saveOrderPriority = ({ ids, ...props }) => genericService({
	...props, action: createPostActionPriorities({
		type: 'ORDER_PRIORITY', endPoint: "reorderPriorities",
		data: {
			newPriorityOrder: ids,
		},
		params: props?.params
	}),
});

const createPostActionStatus = InitActionCreator({ baseUrl: "api/status/", method: "POST" }); // api actions type post
const createGetActionStatus = InitActionCreator({ baseUrl: "api/status/", method: "GET" });

export const statusInit = (props) => createGetActionStatus({
	type: "STATUS_INIT", endPoint: constructUrl("getAll", {
		checkIsUsed: props?.showUsedData
	}), meta: { joinRequest: 'STATUS_INIT' }
});
export const updateStatusAttribute = ({ modifiedStatus, modifiedAttribNames, type, ...props }) => genericService({
	...props, action: createPostActionStatus({
		type: 'UPDATE_STATUS', endPoint: "update",
		data: {
			modifiedStatus: modifiedStatus,
			modifiedAttribNames: modifiedAttribNames,
		},
		params: {
			type: type,
		},
	}),
});
export const addStatus = ({ newStatus, type, ...props }) => genericService({
	...props, action: createPostActionStatus({
		type: 'ADD_STATUS', endPoint: "create",
		data: {
			newStatus: newStatus,
		},
		params: {
			type: type,
		},
	}),
});
export const removeStatus = ({ id, type, ...props }) => genericService({
	...props, action: createPostActionStatus({
		type: 'REMOVE_STATUS', endPoint: "delete",
		data: {
			StatusId: id,
		},
		params: {
			type: type,
		},
	}),
});
export const saveOrderStatus = ({ ids, ...props }) => genericService({
	...props, action: createPostActionStatus({
		type: 'ORDER_STATUS', endPoint: "reorderStatus",
		data: {
			newStatusOrder: ids,
		},
		params: props.params
	}),
});

const createPostActionGroup = InitActionCreator({ baseUrl: "api/group/", method: "POST" }); // api actions type post
const createGetActionGroup = InitActionCreator({ baseUrl: "api/group/", method: "GET" });

export const groupInit = () => createGetActionGroup({ type: "GROUP_INIT", endPoint: "getAll", meta: { joinRequest: 'GROUP_INIT' } });

export const saveOrderGroup = ({ ids, ...props }) => genericService({
	...props, action: createPostActionGroup({
		type: 'ORDER_GROUP', endPoint: "reorderGroups",
		data: {
			newGroupOrder: ids,
		}
	}),
});

export const updateGroupAttribute = ({ modifiedGroup, modifiedAttribNames, ...props }) => genericService({
	...props, action: createPostActionGroup({
		type: 'UPDATE_GROUP', endPoint: "update",
		data: {
			modifiedGroup: modifiedGroup,
			modifiedAttribNames: modifiedAttribNames,
		}
	}),
});
export const addGroup = ({ newGroup, ...props }) => genericService({
	...props, action: createPostActionGroup({
		type: 'ADD_GROUP', endPoint: "create",
		data: {
			newGroup: { ...newGroup, name: encode(newGroup.name ?? "") }
		}
	}),
});
export const removeGroup = ({ id, ...props }) => genericService({
	...props, action: createPostActionGroup({
		type: 'REMOVE_GROUP', endPoint: "delete",
		data: {
			groupId: id,
		}
	}),
});
export const linkGroups = ({ groupIds, recordIds, recordType, ...props }) => genericService({
	...props, action: createPostActionGroup({
		type: 'LINK_GROUPS', endPoint: "linkRecords",
		data: {
			groupIds,
			recordIds,
			recordType,
		}
	}),
});
export const unlinkGroup = ({ groupId, recordId, recordType, ...props }) => genericService({
	...props, action: createPostActionGroup({
		type: 'UNLINK_GROUP', endPoint: "unlinkRecords",
		data: {
			groupId,
			recordId,
			recordType,
		}
	}),
});

const createPostActionIcon = InitActionCreator({ baseUrl: "api/icon/", method: "POST" }); // api actions type post
const createGetActionIcon = InitActionCreator({ baseUrl: "api/icon/", method: "GET" });

export const iconsInit = () => createGetActionIcon({ type: "ICONS_INIT", endPoint: "getAll", meta: { joinRequest: 'ICONS_INIT' } });

export const createCustomIcon = ({ newIconName, category, imageExtension, imageBody64, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: 'CREATE_ICON', endPoint: "createCustomIcon",
		data: {
			newIconName: encode(newIconName),
			category: Number(category),
			imageExtension: imageExtension,
			imageBody64: imageBody64,
		}
	}),
});
export const updateCustomIcon = ({ iconIdStr, newIconName, category, imageExtension, imageBody64, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: 'UPDATE_CUSTOM_ICON', endPoint: "updateCustomIcon",
		data: {
			iconIdStr,
			newIconName: encode(newIconName),
			category: Number(category),
			imageExtension: imageExtension,
			imageBody64: imageBody64,
		}
	}),
});
export const deleteCustomIcon = ({ id, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: 'DELETE_CUSTOM_ICON', endPoint: "deleteCustomIcon",
		data: {
			iconIdStr: id,
		}
	}),
	displayToast: "DELETE"
});
export const copyCustomIcon = ({ id, name, workspaceIds, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: 'COPY_CUSTOM_ICON', endPoint: "copy",
		data: {
			iconId: id,
			newSubject: encode(name),
			workspaceIds: workspaceIds
		}
	}),
	displayToast: "COPY"
});
export const deleteCustomImage = ({ iconIdStr, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: 'DELETE_CUSTOM_IMAGE', endPoint: "deleteCustomImage",
		data: {
			iconIdStr
		}
	}),
});
export const getIconsLinkedRecords = (props) => createGetActionIcon({
	type: "ICONS_GET_RELATED_RECORDS", endPoint: constructUrl("getRelatedRecord", {
		iconNumber: props?.iconNumber
	})
});


const createPostActionBV = InitActionCreator({ baseUrl: "api/brandValue/", method: "POST" }); // api actions type post
const createGetActionBV = InitActionCreator({ baseUrl: "api/brandValue/", method: "GET" });

export const brandValueInit = () => createGetActionBV({ type: "BRAND_VALUE_INIT", endPoint: "getAll", meta: { joinRequest: 'BRAND_VALUE_INIT' } });

export const addBrandValue = ({ brandValue, ...props }) => genericService({
	...props, action: createPostActionBV({
		type: 'ADD_BRAND_VALUE', endPoint: "create",
		data: {
			newbrandValueParams: {
				Name: encode(brandValue?.name),
				Description: brandValue.description ? encode(brandValue?.description) : "",
				Type: brandValue?.type || -1,
				Number: brandValue?.number || 1,
				isCustom: brandValue?.isCustom || false,
			}
		}
	}),
});
export const deleteBrandValue = ({ id, ...props }) => genericService({
	...props, action: createPostActionBV({
		type: 'DELETE_BRAND_VALUE', endPoint: "delete",
		data: {
			brandValueId: id

		}
	}),
});
export const copyBrandValue = ({ brandValueId, name, workspaceIds, ...props }) => genericService({
	...props, action: createPostActionBV({
		type: 'COPY_BRAND_VALUE', endPoint: "copy",
		data: {
			brandValueId,
			newSubject: encode(name),
			workspaceIds,
		}
	}),
	displayToast: "COPY",
});
export const updateBrandValue = ({ modifiedBrandValue, modifiedAttribNames, ...props }) => genericService({
	...props, action: createPostActionBV({
		type: 'UPDATE_BRAND_VALUE', endPoint: "update",
		data: {
			modifiedBrandValue,
			modifiedAttribNames,

		}
	}),
	displayToast: "UPDATE",
});
export const getBrandValueLinkedRecords = (props) => createGetActionBV({
	type: "BRAND_VALUE_GET_RELATED_RECORDS", endPoint: constructUrl("getRelatedRecord", {
		brandValueId: props?.brandValueId
	})
});

const createPostActionMeasure = InitActionCreator({ baseUrl: "api/measures/", method: "POST" }); // api actions type post
const createGetActionMeasure = InitActionCreator({ baseUrl: "api/measures/", method: "GET" });

export const measureInit = () => createGetActionMeasure({ type: "MEASURE_INIT", endPoint: "getAll", meta: { joinRequest: 'MEASURE_INIT' } });

export const upsertMeasure = ({ measure, ...props }) => genericService({
	...props, action: createPostActionMeasure({
		type: 'UPSERT_MEASURE', endPoint: "upsert",
		data: {
			measure,
		}
	}),
});
export const copyMeasure = ({ measureId, name, workspaceIds, ...props }) => genericService({
	...props, action: createPostActionMeasure({
		type: 'COPY_MEASURE', endPoint: "copy",
		data: {
			measureId,
			newSubject: encode(name),
			workspaceIds
		}
	}),
});
export const deleteMeasure = ({ measureId, ...props }) => genericService({
	...props, action: createPostActionMeasure({
		type: 'DELETE_MEASURE', endPoint: "delete",
		data: {
			measureId,
		}
	})
});
export const getMeasuresLinkedRecords = (props) => createGetActionMeasure({
	type: "MEASURE_GET_RELATED_RECORDS", endPoint: constructUrl("getRelatedRecord", {
		measureId: props?.measureId
	})
});

const createPostActionTags = InitActionCreator({ baseUrl: "api/tag/", method: "POST" });
const createGetActionTags = InitActionCreator({ baseUrl: "api/tag/", method: "GET" });

export const tagInit = () => createGetActionTags({ type: "TAG_INIT", endPoint: "getAll", meta: { joinRequest: 'TAG_INIT' } });

export const upsertTag = ({ tag, ...props }) => genericService({
	...props, action: createPostActionTags({
		type: 'UPSERT_TAG', endPoint: "upsert",
		data: {
			tag: { ...tag, name: encode(tag.name) },
		}
	}),
});
export const deleteTag = ({ tagId, ...props }) => genericService({
	...props, action: createPostActionTags({
		type: 'DELETE_TAG', endPoint: "delete",
		data: {
			tagId,
		}
	}),
	displayToast: "DELETE"

});
export const getTagLinkedRecords = (props) => createGetActionTags({
	type: "TAG_GET_RELATED_RECORDS", endPoint: constructUrl("getRelatedRecord", {
		tagId: props?.tagId
	})
});
export const linkTags = ({ tagIds, recordIds, recordType, ...props }) => genericService({
	...props, action: createPostActionTags({
		type: 'LINK_TAGS', endPoint: "linkRecords",
		data: {
			tagIds,
			recordIds,
			recordType,
		}
	})
});
export const unlinkTag = ({ tagId, recordId, recordType, ...props }) => genericService({
	...props, action: createPostActionTags({
		type: 'UNLINK_TAG', endPoint: "unlinkRecords",
		data: {
			tagId,
			recordId,
			recordType,
		}
	})
});

export const saveOrderTags = ({ ids, ...props }) => genericService({
	...props, action: createPostActionTags({
		type: 'ORDER_TAG', endPoint: "reorderTags",
		data: {
			newTagsOrder: ids,
		}
	}),
});
const createPostActionMetrics = InitActionCreator({ baseUrl: "api/cxmetric/", method: "POST" });
const createGetActionMetrics = InitActionCreator({ baseUrl: "api/cxmetric/", method: "GET" });

export const getAllCxMetrics = () => createGetActionMetrics({ type: "GET_ALL_CX_METRICS", endPoint: "getAll", meta: { joinRequest: 'GET_ALL_CX_METRICS' } });

export const createCxMetric = ({ name, ...props }) => genericService({
	...props, action: createPostActionMetrics({
		type: 'CREATE_CX_METRIC', endPoint: "create",
		data: {
			name: encode(name),
		}
	}),
	displayToast: "CREATE"
});
export const updateCxMetric = ({ cxMeasureId, newName, ...props }) => genericService({
	...props, action: createPostActionMetrics({
		type: 'UPDATE_CX_METRIC', endPoint: "update",
		data: {
			cxMeasureId,
			newName: encode(newName),
		}
	}),
	displayToast: "UPDATE"
});
export const deleteCxMetric = ({ cxMeasureId, ...props }) => genericService({
	...props, action: createPostActionMetrics({
		type: 'DELETE_CX_METRIC', endPoint: "delete",
		data: {
			cxMeasureId
		}
	}),
	displayToast: "DELETE"
});

const createPostActionTP = InitActionCreator({ baseUrl: "api/touchpoint/", method: "POST" });
const createGetActionTP = InitActionCreator({ baseUrl: "api/touchpoint/", method: "GET" });

export const touchPointInit = () => createGetActionTP({ type: "TOUCHPOINT_INIT", endPoint: "getAll", meta: { joinRequest: 'TOUCHPOINT_INIT' } });

export const createCustomChannel = ({ newChannelName, category, weight, imageExtension, imageBody64, ...props }) => genericService({
	...props, action: createPostActionTP({
		type: 'CREATE_TOUCHPOINT', endPoint: "createCustomChannel",
		data: {
			newChannelName: encode(newChannelName),
			categoryId: (category),
			weight: parseFloat(weight),
			imageExtension,
			imageBody64,
		},

	}),
	displayToast: "CREATE"
});
export const updateCustomTouchpoint = ({ channelIdStr, newChannelName, category, weight, imageExtension, imageBody64, ...props }) => genericService({
	...props, action: createPostActionTP({
		type: 'UPDATE_CUSTOM_TOUCHPOINT', endPoint: "updateCustomChannel",
		data: {
			channelIdStr: channelIdStr,
			newChannelName: encode(newChannelName),
			categoryId: (category),
			weight: parseFloat(weight),
			imageExtension: imageExtension,
			imageBody64: imageBody64,
		},

	}),
	displayToast: "UPDATE"
});
export const deleteCustomTouchpoint = ({ id, ...props }) => genericService({
	...props, action: createPostActionTP({
		type: 'DELETE_CUSTOM_TOUCHPOINT', endPoint: "deleteCustomChannel",
		data: {
			channelIdStr: id,
		},

	}),
	displayToast: "DELETE"
});

export const copyCustomTouchpoint = ({ channelIdStr, newChannelName, workspaceIds, ...props }) => genericService({
	...props, action: createPostActionTP({
		type: 'COPY_CUSTOM_TOUCHPOINT', endPoint: "copyChannel",
		data: {
			channelId: channelIdStr,
			newChannelName: encode(newChannelName),
			workspaceIds: workspaceIds,
		},

	}),
	displayToast: "COPY"
});
export const deleteCustomTouchpointImage = ({ channelIdStr, ...props }) => genericService({
	...props, action: createPostActionTP({
		type: 'DELETE_CUSTOM_TOUCHPOINT_IMAGE', endPoint: "deleteCustomImage",
		data: {
			channelIdStr,
		},

	}),
	displayToast: "DELETE"
});
export const recomputeSentimentLevels = ({ channelIdStr, ...props }) => genericService({
	...props, action: createPostActionTP({
		type: 'RECOMPUTE_SENTIMENT_LEVELS', endPoint: "recomputeSentimentLevel",
		data: {
			channelIdStr,
		},

	}),
});
export const getTouchpointLinkedRecords = (props) => createGetActionTP({
	type: "TOUCHPOINT_GET_RELATED_RECORDS", endPoint: constructUrl("getRelatedRecord", {
		touchpointId: props?.touchpointId
	})
});

const createPostActionStake = InitActionCreator({ baseUrl: "api/stakeholder/", method: "POST" });
const createGetActionStake = InitActionCreator({ baseUrl: "api/stakeholder/", method: "GET" });

export const getAllStakeholderRoles = () => createGetActionStake({ type: "GET_ALL_STAKEHOLDER_ROLES", endPoint: "getAllRoles", meta: { joinRequest: 'GET_ALL_STAKEHOLDER_ROLES' } });

export const upsertStakeholder = ({ stakeholder, recId, entityName, actionType, ...props }) => genericService({
	...props, action: createPostActionStake({
		type: actionType, endPoint: "upsert",
		data: { stakeholder: { ...stakeholder, id: stakeholder?.id || EMPTY_GUID }, recId, entityName }

	}),
});
export const deleteStakeholder = ({ stakeholderId, recId, entityName, actionType, ...props }) => genericService({
	...props, action: createPostActionStake({
		type: actionType, endPoint: "delete",
		data: { stakeholderId, recId, entityName },

	}),
});
export const createStakeholderRole = ({ name, ...props }) => genericService({
	...props, action: createPostActionStake({
		type: "CREATE_STAKEHOLDER_ROLE", endPoint: "createRole",
		data: { name: encode(name) },
	}),
	displayToast: "CREATE"
});
export const updateStakeholderRole = ({ roleId, newName, ...props }) => genericService({
	...props, action: createPostActionStake({
		type: "UPDATE_STAKEHOLDER_ROLE", endPoint: "updateRole",
		data: { roleId, newName: encode(newName) },
	}),
	displayToast: "UPDATE"
});
export const deleteStakeholderRole = ({ roleId, ...props }) => genericService({
	...props, action: createPostActionStake({
		type: "DELETE_STAKEHOLDER_ROLE", endPoint: "deleteRole",
		data: { roleId },
	}),
	displayToast: "DELETE"
});

const createPostActionCxGoals = InitActionCreator({ baseUrl: "api/cxgoal/", method: "POST" });
const createGetActionCxGoals = InitActionCreator({ baseUrl: "api/cxgoal/", method: "GET" });

export const cxGoalsInit = () => createGetActionCxGoals({ type: "CX_GOALS_INIT", endPoint: "getAll", meta: { joinRequest: 'CX_GOALS_INIT' } });

export const cxGoalsUpsert = ({ CxGoals, stageId, measures, ...props }) => genericService({
	...props, action: createPostActionCxGoals({
		type: "CX_GOALS_UPSERT", endPoint: "upsert",
		data: {
			CxGoals: CxGoals.map((cxGoal) => {
				return {
					...cxGoal,
					description: encode(cxGoal.description),
					goal: ["", "-", null].includes(cxGoal.goal) ? 0 : Number(cxGoal.goal),
					actual: ["", "-", null].includes(cxGoal.actual) ? 0 : Number(cxGoal.actual),
					financialImpact: ["", "-", null].includes(cxGoal.financialImpact) ? 0 : Number(cxGoal.financialImpact),
				};
			}),
		},
		params: { stageId, measures },
	}),
});
export const updateStageCxGoalsOrder = ({ stageId, cjmId, newOrder, ...props }) => genericService({
	...props, action: createPostActionCxGoals({
		type: "UPDATE_STAGE_CX_GOALS_ORDER", endPoint: "updateStageCxGoalsOrder",
		data: {
			stageId,
			cjmId,
			newCxGoalsOrder: newOrder,
		},
	}),
});
export const deleteCxGoal = ({ id, stageId, ...props }) => genericService({
	...props, action: createPostActionCxGoals({
		type: "DELETE_CX_GOAL", endPoint: "delete",
		data: {
			id
		},
		params: { stageId },
	}),
});

const createPostAction = InitActionCreator({ baseUrl: "api/insightType/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/insightType/", method: "GET" }); // api actions type get

export const getAllInsightTypes = () => createGetAction({ type: "GET_ALL_INSIGHT_TYPES", endPoint: "getAll" });

export const createInsightType = (props) => genericService({
	...props, action: createPostAction({
		type: "CREATE_INSIGHT_TYPE", endPoint: "create",
		data: {
			newInsightType: props?.newInsightType
		}
	})
});
export const updateInsightType = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_INSIGHT_TYPE", endPoint: "update",
		data: {
			modifiedInsightType: { id: props.item.id, colorCode: props.item.colorCode, name: encode(props.item.name) },
			modifiedAttribNames: [props.item.update.toLowerCase()]
		}
	})
});
export const saveOrderInsightType = (props) => genericService({
	...props, action: createPostAction({
		type: "ORDER_INSIGHT_TYPE", endPoint: "reorderInsightTypes",
		data: {
			newInsightTypeOrder: props?.ids
		}
	})
});
export const deleteInsightType = (props) => genericService({
	...props, action: createPostAction({
		type: "DELETE_INSIGHT_TYPE", endPoint: "delete",
		data: { id: props?.id }
	})
});

//TouchPoint Category
const touchpointCategoryPostAction = InitActionCreator({ baseUrl: "api/touchpointCategory/", method: "POST" }); // api actions type post
const touchpointCategoryGetAction = InitActionCreator({ baseUrl: "api/touchpointCategory/", method: "GET" }); // api actions type get

export const touchPointCategoriesInit = () => touchpointCategoryGetAction({ type: "TOUCHPOINT_CATEGORIES_INIT", endPoint: "getAll" });

export const upsertTouchpointCategory = (props) => genericService({
	...props, action: touchpointCategoryPostAction({
		type: "UPSERT_TOUCHPOINT_CATEGORY", endPoint: "upsert",
		data: {
			touchpointCategory: { ...props?.touchpointCategory, name: encode(props.touchpointCategory.name) }
		}
	})
});
export const deleteTouchpointCategory = (props) => genericService({
	...props, action: touchpointCategoryPostAction({
		type: "DELETE_TOUCHPOINT_CATEGORY", endPoint: "delete",
		data: {
			id: props?.id
		}
	})
});

export const copyTouchpointCategory = ({ id, name, workspaceIds, ...props }) => genericService({
	...props, action: touchpointCategoryPostAction({
		type: "COPY_TOUCHPOINT_CATEGORY", endPoint: "copy",
		data: {
			id,
			newSubject: encode(name),
			workspaceIds
		}
	})
});