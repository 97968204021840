import { PromptAICreation } from "shared/modals/index";
import GenericPopover from "shared/popovers/GenericPopover";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { PopoverItem } from "features/grid-table/columns-components/Styles";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";

export const EmptyList = ({ data }) => {
	const aiAllowed = useSelector(({ settings }) => settings.subscriptionDetails.aiEnabled);

	const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(false);
	const [showAiModal] = useModal(data?.aiModal)
	const ref = useRef(null);

	const onCreate = () => {
		data?.buttonCreate({ setIsCreateBtnDisabled })
	}
	const handleClick = e => {
		const id = e.currentTarget.id;
		ref?.current?.hide_popover();
		if (id === 'ai') showAiModal()
		else if (id === 'create') onCreate()
	}
	const custom = <div>
		<PopoverItem ai onClick={handleClick} id="ai">
				{IconDispatcher("SPARKLES-LIGHT","me-1")}<span> {t("GENERATE_AI")}</span>
			</PopoverItem>
			<PopoverItem onClick={handleClick} id="create">
				{IconDispatcher(data?.emptyListText === 'MAP_EMPTY_LIST' ? "MAP-LIGHT": "SQUARE_PLUS","me-1")}<span> {t(data?.buttonCreateLabel)}</span>
			</PopoverItem>
	</div>
	const handleCreate = () => {
		if (!data.aiGeneration || !aiAllowed) onCreate()
	}
	const ButtonCreate = () => {
		if (data.aiGeneration && aiAllowed) {
			return (
				<GenericPopover custom={custom} innerRef={ref} >
					<GenericButton variant="primary" onClick={handleCreate} iconClassName="me-1" icon="SQUARE_PLUS" disabled={isCreateBtnDisabled}>
						{t(data?.buttonCreateLabel)}
					</GenericButton>
				</GenericPopover>
			)
		}
		return <GenericButton variant="primary" onClick={handleCreate} iconClassName="me-1" icon="SQUARE_PLUS" disabled={isCreateBtnDisabled}>
		{t(data?.buttonCreateLabel)}
	</GenericButton>
	}
	return (
		<EmptyListContainer>
			<EmptyListIcon color={data?.emptyListIconColor}>{data?.emptyListIcon}</EmptyListIcon>
			<EmptyListText>{t(data?.emptyListText)}</EmptyListText>
			{data?.visibleButtonCreate && (
				<div>
					<ButtonCreate />
				</div>
			)}
		</EmptyListContainer>
	);
};


const EmptyListContainer = styled.div`
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    gap: 32px;
`;

const EmptyListIcon = styled.div`
	width: 56px;
	height: 64px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color:#605f60;
`;

export const EmptyListText = styled.div`
	color: #605f60;
	text-align: center;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	width: 408px;
`;
