import { store } from "..";
import { toastWrapper } from "@utils/helpers";
import { joinRequest } from '@redux-requests/core';

export const genericService = (params) => {
    const promise = async () => {

        const actionObject = typeof params.action === "object" ? params.action : params.action(params);

		const result = await store.dispatch(actionObject);

		params.setLoading && params.setLoading(true);

		if ([200, 202,208].includes(result?.action?.response?.status)) {
			params?.onSuccess && params.onSuccess(result?.action?.response?.data, result?.action);
			params.setLoading && params.setLoading(false);

		}
		if (result?.error) {
			params?.onFailed && params.onFailed(result.error);
			params.setLoading && params.setLoading(false);

			throw result.error;
		}
	};

	if (params.displayToast) {
		toastWrapper(promise, params.displayToast, params.toastParams);
	} else {
		promise();
	}
};
