import { NewModal } from "shared/modals/index";
import { GenericButton } from "shared/ui-basics/index";
import { CardView } from "features/grid-table/components/CardView.js";
import DataTable from "features/grid-table/DataTable";
import { useAsync } from "shared/hooks/index";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { createCxProgram, getAllAuthorizedUsers, getAllCxProgram, tagInit } from "@redux/index";
import { getFolderById } from "@redux/memoizedSelectors";
import { FilterItemsByStatus } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useModal } from "use-modal-hook";

export default function CXProgram() {
	const { t } = useTranslation();

	const history = useHistory();
	const { pathname, search } = useLocation();
	const hideForPdf = search.hideForPdf();
	const folderId = search.getFolderId();
	const folderView = folderId === undefined ? false : true;
	const isList = search.getTab() === 'list-view';

	useAsync({ asyncFn: getAllCxProgram });
	useAsync({ asyncFn: getAllAuthorizedUsers });
	useAsync({ asyncFn: tagInit });

	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const cxPrograms = useSelector((state) => state.cxProgram.all_cxPrograms?.filter((cxProgram) => FilterItemsByStatus(cxProgram, pathname, "/cx-program", showSampleData)));
	const { linkedCxPrograms } = useSelector((state) => getFolderById(state, folderId), shallowEqual);

	const [showcardView, setCardView] = useState(folderId || isList ? false : true);

	useEffect(() => {
		if (folderView && showcardView) {
			setCardView(false);
		}
	}, [folderView]);

	const [showCreateModal] = useModal(NewModal, {
		title: "CREATE_CX_PROGRAM",
		closeBtnLabel: "ASPX_135",
		inputTitle: "CX_PROGRAM_NAME",
		placeholder: "NEW_CX_PROGRAM",
		action: (name) => createCxProgram({ name, onSuccess: (data) => history.push(`/EditCxProgram?programid=${data.id}`) }),
	});

	const isShowViewButtons = () => {
		if (hideForPdf || folderView) return false;
		return true;
	};

	const renderViewButtons = (t) => {
		if (!isShowViewButtons()) return <></>;
		function handleCardViewChange(isCardView) {
			const searchParams = new URLSearchParams(window.location.search);
			if (isCardView) {
				// If setting to card view, remove the tab parameter
				searchParams.delete('tab');
			} else {
				// If setting to list view, add tab=list-view
				searchParams.set('tab', 'list-view');
			}
			// Update the URL without reloading the page
			window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
		}

		return (
			<>
				<GenericButton variant="light-link" className="ms-2" tooltip tooltipText={t("VIEW_CARD")} icon="GRID-VIEW" active={showcardView}
					onClick={() => {
						setCardView(true);
						handleCardViewChange(true);
					}} />
				<GenericButton variant="light-link" className="ms-2" tooltip tooltipText={t("LIST_VIEW")} icon="LIST-VIEW" active={!showcardView}
					onClick={() => {
						setCardView(false);
						handleCardViewChange(false);
					}} />
			</>
		);
	};

	const renderView = () => {
		if (showcardView) return <CardView cxPrograms={cxPrograms} showCreateModal={showCreateModal} />;
		return <DataTable context="cxPrograms.cxProgramList" linked={linkedCxPrograms} />;
	};

	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("CX_PROGRAMS")}`} renderViewButtons={renderViewButtons}>
			{renderView()}
		</PageTemplate>
	);
}
