import { error, success } from "@redux-requests/core";
import { InformationType, StatusCode, TemplateStatus } from "@utils/optionsSets/OptionSets";
import {
	EMPTY_GUID,
	InteractionArrayValidations,
	appendListsAndRemoveDuplicates,
	createDefaultPersonaInteraction,
	decode,
	firstLetterUppercase,
	hasAttribute,
	isNullOrUndefined,
	reorder,
	toastWrapper
} from "@utils/helpers";
import { t } from "i18next";
import $ from "jquery";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as Helpers from "./helpers";
import axios from "axios";

const initialState = {
	current_map: { cjmData: [] },
	all_maps: [],
	current_linked_users: [],
	loading: false,
	connection: false,
};

const CJMReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const responseData = action?.response?.data;
	const requestParams = action?.meta?.requestAction?.params;

	switch (action.type) {
		case success("GET_MAPS"):
			return {
				...state,
				all_maps: responseData || [],
			};
		case "GET_ALL_MAPS_LIST_DATA":
			return {
				...state,
				loading: state.all_maps.length === 0,
			};

		case error("GET_ALL_MAPS_LIST_DATA"):
			return {
				...state,
				loading: false,
			};
		case success("GET_ALL_MAPS_LIST_DATA"): {
			const { url } = action?.meta?.requestAction?.request;
			if (url.includes("redirectReducer")) return state;

			return {
				...state,
				all_maps: responseData || {},
				loading: false,
			};
		}
		case success("DELETE_CJM"):
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmIdStr) {
						return { ...map, statusCode: StatusCode.Trashed };
					}
					return map;
				}),
			};
		case success("ARCHIVE_CJM"):
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmIdStr) {
						return { ...map, statusCode: StatusCode.Archived };
					}
					return map;
				}),
			};
		case success("RESTORE_CJM"):
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmIdStr) {
						return { ...map, statusCode: StatusCode.Active };
					}
					return map;
				}),
			};
		case success("DELETE_PERMANENTLY_MAP"):
			return {
				...state,
				all_maps: state.all_maps.filter((map) => map.cjmId !== requestData.cjmIdStr),
			};
		case success("COPY_MAP"):
			return {
				...state,
				all_maps: [...state.all_maps, responseData],
			};
		case success("CREATE_CJM"):
			return {
				...state,
				all_maps: [...state.all_maps, responseData],
				lastJourneyMapCreated: responseData.cjmId
			};

		case "GET_CURRENT_MAP":
			return { ...state, loading: true };
		case error("GET_CURRENT_MAP"):
			return { ...state, loading: false };
		case success("GET_CURRENT_MAP"): {
			const data = responseData;
			localStorage.setItem("loadAllData", false);

			// Exit early if data is not valid
			if (!data) return state;

			// Extract from action
			const { personaIdStr, onlySentimentData } = action.meta.requestAction.params;

			// Default persona object
			const defaultPersonaObject = { [personaIdStr]: {} };

			if (onlySentimentData) {
				//data.cjmPersonasInteractions = Helpers.formatPersonaInteractions(data.cjmData, personaIdStr);
				data.cjmData = Helpers.formatCjmData(data.cjmData, state.current_map.cjmStagesData, personaIdStr);
				data.cjmPersonasData = Object.entries(data.cjmPersonasData).map(([, persona]) => persona);

				return {
					...state,
					current_map: {
						...state.current_map,
						cjmGlobalSentimentLevel: data.cjmGlobalSentimentLevel,
						cjmData: data.cjmData,
						cjmPersonasData: data.cjmPersonasData,
					},
					loading: false,
				};
			}

			// Phases and stages extraction and sorting
			const phases = Helpers.extractAndSortData(data.cjmPhasesData);
			const stages = Helpers.extractAndSortData(data.cjmStagesData);

			// Data updates
			data.cjmPersonasData = Object.entries(data.cjmPersonasData).map(([, persona]) => persona);
			data.cjmPhasesData = phases;
			data.cjmStagesData = phases.map((phase) => stages.filter((stage) => stage.phase.id == phase.id)).flat();
			data.cjmStagesCustomerExperienceData =
				data.cjmStagesCustomerExperienceData === null || !data.cjmStagesCustomerExperienceData[personaIdStr] ? defaultPersonaObject : data.cjmStagesCustomerExperienceData;

			data.cjmSwimLanes = Helpers.extractAndSortData(data.cjmSwimLanes)?.map((swimLane) => {
				swimLane.sentimentsConfig = JSON.parse(swimLane.sentimentsConfig);
				if (requestParams.exportingPdf) {
					swimLane.expandedCard = true;
				}
				return swimLane;
			});
			const StageIndex = data.cjmSwimLanes.findIndex((stage) => stage.id === "StageHeaders");
			data.cjmSwimLanes = reorder(data.cjmSwimLanes, StageIndex, data.cjmSwimLanes.length - 1);
			const PhaseIndex = data.cjmSwimLanes.findIndex((stage) => stage.id === "PhaseHeaders");
			data.cjmSwimLanes = reorder(data.cjmSwimLanes, PhaseIndex, data.cjmSwimLanes.length - 1);


			data.cjmPersonasInteractions = Helpers.formatPersonaInteractions(data.cjmData, personaIdStr);
			data.cjmData = Helpers.formatCjmData(data.cjmData, data.cjmStagesData, personaIdStr);
			data.cjmUsersData = Object.entries(data.cjmUsersData).map(([, user]) => user);
			return {
				...state,

				current_map: { ...state.current_map, ...data },
			};
		}
		case 'NO_ACCESS_map':
			return {
				...state,
				current_map: {
					...state.current_map,
					noAccess: true,
				}
			}

		case "RESET_CURRENT_MAP":
			return {
				...state,
				current_map: {},
			};
		case "ORDER_SWIMLANES":
			return {
				...state,
				current_map: { ...state.current_map, cjmSwimLanes: action.payload },
			};
		case "ORDER_STAGES": {
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: state.current_map?.cjmPhasesData?.filter((phase) => action.payload.newStagesOrder.find((stage) => stage.phase.id === phase.id) !== undefined),
					cjmStagesData: action.payload.newStagesOrder,
					cjmData: action.payload.newStagesOrder.map((stagemap, index) => {
						return state.current_map.cjmData
							?.map((cjm) => cjm?.filter((int) => int.stageId === stagemap.id))
							?.filter((int) => int.length > 0)
							.flat();
					}),
				},
			};
		}
		case "ORDER_PHASES":
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: reorder(state.current_map.cjmPhasesData, action.payload.source, action.payload.destination),
				},
			};
		case success("REORDER_PHASE_DB"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_SWIMLANES_DESCRIPTION"): {
			const {
				current_map: { cjmSwimLaneDetails = {} },
			} = state;
			const { customSwimLaneIdStr, stageIdStr: stageIdStr_descriptions, description: descriptions } = requestData;
			const currentSwimlane = cjmSwimLaneDetails[customSwimLaneIdStr] || {};
			const currentStage = currentSwimlane[stageIdStr_descriptions] || {
				id: uuidv4(),
				swimLaneId: customSwimLaneIdStr,
				stageId: stageIdStr_descriptions,
				description: "",
				icons: [],
				image64: "",
				imageType: "",
			};

			const updatedStage = { ...currentStage, description: decode(descriptions) };
			const updatedSwimlane = { ...currentSwimlane, [stageIdStr_descriptions]: updatedStage };

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLaneDetails: {
						...cjmSwimLaneDetails,
						[customSwimLaneIdStr]: updatedSwimlane,
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}

		case "UPDATE_SWIMLANES_ICONS": {
			const {
				current_map: { cjmSwimLaneDetails },
			} = state;
			const {
				payload: { key: swimLaneKey, stageId: swimLaneStageId, newIcon },
			} = action;
			const newObject = {
				id: uuidv4(),
				swimLaneId: swimLaneKey,
				stageId: swimLaneStageId,
				description: "",
				icons: [newIcon],
				image64: "",
				imageType: "",
			};

			const currentSwimlane = cjmSwimLaneDetails[swimLaneKey] || {};
			const currentStage = currentSwimlane[swimLaneStageId] || {};

			// Update the icons list if it exists; otherwise, create a new one
			const updatedIcons = currentStage.icons ? [...currentStage.icons, newIcon] : [newIcon];

			// Update the stage
			const updatedStage = { ...currentStage, icons: updatedIcons };

			// If the current swimlane already has this stage, update it; otherwise, use newObject
			const updatedSwimlane = { ...currentSwimlane, [swimLaneStageId]: currentStage.icons ? updatedStage : newObject };

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLaneDetails: {
						...cjmSwimLaneDetails,
						[swimLaneKey]: updatedSwimlane,
					},
				},
			};
		}

		case "UPDATE_ICON_SIMPLE_INTERACTION": {
			const { personaIdStr, stageId, newIcon: iconId, attribute } = action.payload;
			const { cjmStagesCustomerExperienceData: stageCustomerExp = {} } = state.current_map;
			const updatedData = Helpers.updateCustomerExperienceData(stageCustomerExp, personaIdStr, stageId, attribute, iconId);
			return Helpers.updatedState(state, "cjmStagesCustomerExperienceData", updatedData);
		}

		case "DELETE_ICON_SIMPLE_INTERACTION": {
			const { personaIdStr, stageId, Icon: iconId, attribute } = action.payload;
			const { cjmStagesCustomerExperienceData: stageCustomerExp = {} } = state.current_map;
			const updatedData = Helpers.deleteIconFromExperienceData(stageCustomerExp, personaIdStr, stageId, attribute, iconId);
			return Helpers.updatedState(state, "cjmStagesCustomerExperienceData", updatedData);
		}

		case "UPDATE_ADVANCED_INTERACTION_ICON":
		case "DELETE_ADVANCED_INTERACTION_ICON": {
			const { cardId, attribute, value: iconId } = action.payload;
			const { cjmData } = state.current_map;
			const updatedData =
				action.type === "UPDATE_ADVANCED_INTERACTION_ICON" ? Helpers.addIconToCardData(cjmData, cardId, attribute, iconId) : Helpers.deleteIconFromCardData(cjmData, cardId, attribute, iconId);
			return Helpers.updatedState(state, "cjmData", updatedData);
		}

		case "DELETE_SWIMLANES_ICONS": {
			const { key, stageId, iconToDelete } = action.payload;
			const { cjmSwimLaneDetails = {} } = state.current_map;
			const updatedData = Helpers.deleteIconFromSwimLaneDetails(cjmSwimLaneDetails, key, stageId, iconToDelete);
			return Helpers.updatedState(state, "cjmSwimLaneDetails", updatedData);
		}

		case "UPDATE_STAGE_ATTRIBUTE": {
			const stageIdSTR = action.payload.stageId;
			const VALUE_STG = action.payload.value;
			const attributeSTG = action.payload.attribute;
			const stagesData = state.current_map.cjmStagesData;

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: stagesData.map((stage) => {
						if (stage.id === stageIdSTR) {
							return { ...stage, [attributeSTG]: [...stage[attributeSTG], VALUE_STG] };
						}
						return stage;
					}),
				},
				lastModifiedOn: new Date().toISOString(),
			};
		}
		case "DELETE_STAGE_ATTRIBUTE": {
			const stageIdSTR_DELETE = action.payload.stageId;
			const VALUE_STG_DELETE = action.payload.value;
			const attributeSTG_DELETE = action.payload.attribute;
			const stagesData_DELETE = state.current_map.cjmStagesData;

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: stagesData_DELETE.map((stage) => {
						if (stage.id === stageIdSTR_DELETE) {
							return { ...stage, [attributeSTG_DELETE]: stage[attributeSTG_DELETE]?.filter((icon) => icon !== VALUE_STG_DELETE) };
						}
						return stage;
					}),
				},
				lastModifiedOn: new Date().toISOString(),
			};
		}
		case success("LINK_SWIMLANES_ICONS"):
			return {
				...state,
				current_map: { ...state.current_map, lastModifiedOn: new Date().toISOString() },
			};
		case success("UNLINK_SWIMLANES_ICONS"):
			return {
				...state,
				current_map: { ...state.current_map, lastModifiedOn: new Date().toISOString() },
			};
		case "UPDATE_PHASE_ID_IN_STAGE":
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData.map((stage) => {
						if (stage.id === action.payload.stageId) {
							return {
								...stage,
								phase: state.current_map.cjmPhasesData.find((phase) => phase.id === action.payload.phaseId),
							};
						}
						return stage;
					}),
				},
			};
		case "FILTER_PHASE_WITH_NO_STAGE":
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: state.current_map?.cjmPhasesData?.filter((phase) => state.current_map.cjmStagesData.find((stage) => stage.phase.id === phase.id) !== undefined),
				},
			};
		case success("REMOVE_STAGE"): {
			const stgIndexToDelete = state.current_map.cjmStagesData.findIndex((stage) => stage.id === requestData.stageIdStr);
			const newStagesAfterDelete = state.current_map.cjmStagesData.filter((stage) => stage.id !== requestData.stageIdStr);
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: state.current_map?.cjmPhasesData?.filter((phase) => newStagesAfterDelete.find((stage) => stage.phase.id === phase.id) !== undefined),
					cjmStagesData: newStagesAfterDelete,
					cjmData: state.current_map.cjmData?.filter((data, index) => index !== stgIndexToDelete),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("ADD_STAGE_AND_PHASE_DB"): {
			const newStageId = responseData.split("|")[0];
			const newPhaseId = responseData.split("|")[1];
			const phaseIdFrom = action.meta.requestAction.params.phaseIdFrom;
			const colorCodePhase = state.current_map.cjmPhasesData.find((phase) => phase.id === phaseIdFrom)?.colorCode;
			const phaseIdFromIndex = state.current_map.cjmPhasesData.findIndex((phase) => phase.id === phaseIdFrom);
			const colorCodeStages = state.current_map.cjmStagesData.filter((stage) => stage.phase.id === phaseIdFrom)?.pop()?.colorCode;

			const newPhaseCreated = {
				id: newPhaseId,
				name: "",
				relatedStageIds: [],
				order: state.current_map.cjmPhasesData.length + 1,
				width: -1,
				description: "",
				linkedCjms: [],
				colorCode: colorCodePhase,
			};
			const newStageCreated = {
				id: newStageId,
				name: "",
				phase: newPhaseCreated,
				order: state.current_map.cjmStagesData.length + 1,
				linkedCjms: [],
				width: -1,
				description: "",
				conclusions: "",
				image64: "",
				comments: [],
				colorCode: colorCodeStages,
			};
			const allPhases = [...state.current_map.cjmPhasesData, newPhaseCreated];
			const newCjmPhases = phaseIdFrom === undefined ? allPhases : reorder(allPhases, state.current_map.cjmPhasesData.length, phaseIdFromIndex + 1);
			const newStagesPhaseAdded = newCjmPhases
				.map((phase) => {
					return [...state.current_map.cjmStagesData, newStageCreated].filter((stage) => stage.phase.id === phase.id);
				})
				.flat();

			const newCjmData3 = newStagesPhaseAdded?.map((stagemap, index) => {
				return state.current_map.cjmData
					?.map((cjm) => cjm?.filter((int) => int.stageId === stagemap.id))
					?.filter((int) => int.length > 0)
					.flat();
			});
			const swimlanesPhaseStage = state.current_map.cjmSwimLanes.find((stage) => stage.id === "StageHeaders");
			let newComments = Helpers.updateComments(state.current_map.cjmComments, newStagesPhaseAdded);

			if (swimlanesPhaseStage?.key) {
				newComments = {
					...newComments,
					[swimlanesPhaseStage?.key]: {
						...newComments[swimlanesPhaseStage?.key],
						[newPhaseId]: { [EMPTY_GUID]: { comments: [], showComments: false } },
					},
				};
			}
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: newStagesPhaseAdded,
					cjmPhasesData: newCjmPhases,
					cjmComments: newComments,
					cjmData: newCjmData3,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_STAGE_WIDTH": {
			const { stageIdStr, newValue } = action.request.data;
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData.map((stage) => {
						if (stage.id === stageIdStr) {
							return { ...stage, width: newValue };
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("UPDATE_ORDER_STAGE_DB"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_SWIMLANES_ORDER_DB"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("SHARE_USERS_SINGLE_MAP"): {
			const linkedUsers1 = Object.entries(responseData).map((user) => user[1]) || [];

			return {
				...state,
				all_maps: state.all_maps?.map((x) => {
					if (x.cjmId === requestData.cjmIdStr) {
						return {
							...x,
							authorizedUsers: linkedUsers1,
						};
					}
					return x;
				}),

				current_linked_users: linkedUsers1,
			};
		}
		case success("SHARE_USERS_MANY_MAP"): {
			return {
				...state,
				all_maps: state.all_maps?.map((x) => {
					const getMapFromResponse = responseData.find((y) => y.cjmId === x.cjmId);
					if (getMapFromResponse) {
						return {
							...getMapFromResponse,
						};
					}
					return x;
				}),
			};
		}
		case success("UPDATE_SHARE_CJM_DB"): {
			//current_linked_users

			const userId = state.current_linked_users.find((user) => user.id === requestData.userId);
			const teamId = state.current_linked_users.find((user) => user.id === requestData.teamId);
			const roleName = requestData.roleDetails.split("_")[0];
			const roleId = requestData.roleDetails.split("_")[1];
			const fullName = action.meta.requestAction.request.param.fullName;

			const newLinkUsers = userId === undefined && teamId === undefined ? [...state.current_linked_users, { id: userId, roleName, roleId, fullName }] :
				state.current_linked_users.map((user) => (user.id === userId || user.id === teamId ? { ...user, roleName, roleId } : user));

			return {
				...state,
				current_linked_users: newLinkUsers,
			};
		}
		case success("UPDATE_UNSHARE_CJM_DB"): {
			const userIdOrTeamId = requestData.userId || requestData.teamId;
			return {
				...state,
				all_maps: state.all_maps?.map((x) => {
					if (x.cjmId === requestData.cjmIdStr) {
						return {
							...x,
							authorizedUsers: x.authorizedUsers.filter((user) => user.id !== userIdOrTeamId),
						};
					}
					return x;
				}),
				current_linked_users: state.current_linked_users.filter((user) => user.id !== userIdOrTeamId),
			};
		}
		case success("UPDATE_PHASE_NAME"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: state.current_map.cjmPhasesData?.map((phase) => {
						if (phase.id === requestData.phaseIdStr) {
							return { ...phase, name: decode(requestData.newValue) };
						}
						return phase;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("DELETE_PHASE"): {
			const newPhases = state.current_map.cjmPhasesData.filter((phase) => phase.id !== requestData.phaseIdStr);
			const newStagesData = state.current_map.cjmStagesData.filter((stage) => stage.phase.id !== requestData.phaseIdStr);
			const newCjmData2 = newStagesData?.map((stagemap, index) => {
				return state.current_map.cjmData
					?.map((cjm) => cjm?.filter((int) => int.stageId === stagemap.id))
					?.filter((int) => int.length > 0)
					.flat();
			});
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: newPhases,
					cjmStagesData: newStagesData,
					cjmData: newCjmData2,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_STAGE_NAME": {
			const { stageIdStr, newValue, cjmIdStr } = action.request.data;
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData.map((stage) => {
						if (stage.id === stageIdStr) {
							return { ...stage, name: decode(newValue) };
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
				all_maps: state.all_maps.map((cjm) => {
					if (cjm.cjmId === cjmIdStr) {
						const stages = cjm.stages.map((stage) => {
							if (stage.id === stageIdStr) {
								return { ...stage, name: decode(newValue) };
							}
							return stage;
						});
						cjm = { ...cjm, stages: stages };
					}

					return cjm;
				}),
			};
		}
		case success("ADD_STAGE_FROM_PHASE"): {
			const newStageIdFromPhase = responseData;
			const stageIdFrom = action.meta.requestAction.params.stageIdFrom;
			const colorCode = state.current_map.cjmStagesData.find((stage) => stage.id === stageIdFrom).colorCode;
			const destIndex = state.current_map.cjmStagesData.findIndex((stage) => stage.id === stageIdFrom) + 1;

			const newStages = [
				...state.current_map.cjmStagesData,
				{
					id: newStageIdFromPhase,
					name: "",
					phase: { id: requestData.phaseIdStr },
					order: +1,
					linkedCjms: [],
					width: -1,
					description: "",
					conclusions: "",
					image64: "",
					globalInsightsIcon: [],
					descriptionIcons: [],
					targetEmotionLevel: 0,
					timing: "",
					kpIs: "",
					issues: "",
					processes: "",
					kpIsIcons: [],
					issuesIcons: [],
					processesIcons: [],
					comments: [],
					colorCode: colorCode,
				},
			];
			const newStagesOrder = reorder(newStages, newStages.length - 1, destIndex);
			const newStagesOrdered = state.current_map.cjmPhasesData
				.map((phase) => {
					return newStagesOrder.filter((stage) => stage.phase.id === phase.id);
				})
				.flat();

			const newCjmData4 = newStagesOrdered?.map((stagemap, index) => {
				return state.current_map.cjmData
					?.map((cjm) => cjm?.filter((int) => int.stageId === stagemap.id))
					?.filter((int) => int.length > 0)
					.flat();
			});

			const newComments = Helpers.updateComments(state.current_map.cjmComments, newStagesOrdered);

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: newStagesOrdered,
					cjmComments: newComments,
					cjmData: newCjmData4,

					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("COPY_STAGE"): {
			const { stageIdOld, stageIdNew, personaIdStr: personaIdSTR_COPY } = requestData;
			const data_copy = { ...responseData };

			const updatedCopy = Helpers.updateCopyData(data_copy, personaIdSTR_COPY, state.current_map.cjmComments);
			const updatedChart = Helpers.updateChart(state.current_map?.chart, stageIdOld, stageIdNew);

			return {
				...state,
				current_map: {
					...state.current_map,
					...updatedCopy,
					chart: updatedChart,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_SWIMLANES_PROPERTIES": {
			const { modifiedAttribNames, modifiedSwimLane } = action.request.data;

			const updatedcjmSwimLanes = state.current_map.cjmSwimLanes.map((swimLane) => {
				if (swimLane.key !== modifiedSwimLane.key) {
					return swimLane;
				}
				let isChanged = false;
				let updatedSwimLane = { ...swimLane };
				modifiedAttribNames.forEach(attr => {
					switch (attr) {
						case "sentimentsconfig": {
							const { sentimentsConfig } = modifiedSwimLane;
							if (swimLane.sentimentsConfig !== sentimentsConfig) {
								updatedSwimLane.sentimentsConfig = { ...updatedSwimLane.sentimentsConfig, ...JSON.parse(sentimentsConfig) };
								isChanged = true;
							}
							break;
						}
						// Add other cases for different attributes here, setting isChanged to true if there's a change
						// ...
						case "showhidedescription":
							updatedSwimLane.showHideDescription = !updatedSwimLane.showHideDescription;
							isChanged = true;
							break;
						case "name":
							updatedSwimLane.name = decode(modifiedSwimLane.name);
							isChanged = true;
							break;
						case "description":
							updatedSwimLane.description = decode(modifiedSwimLane.description);
							isChanged = true;
							break;
						case "dividerlineheight":
							updatedSwimLane.dividerLineHeight = modifiedSwimLane.dividerLineHeight;
							isChanged = true;
							break;
						case "dividerpattern":
							updatedSwimLane.dividerPattern = modifiedSwimLane.dividerPattern;
							isChanged = true;
							break;
						case "colorcode":
							updatedSwimLane.colorCode = modifiedSwimLane.colorCode;
							isChanged = true;
							break;
						case "expanded":
							updatedSwimLane.expanded = modifiedSwimLane.expanded;
							isChanged = true;
							break;
						case "expandedcard":
							updatedSwimLane.expandedCard = modifiedSwimLane.expandedCard;
							isChanged = true;
							break;

					}
				});

				return isChanged ? updatedSwimLane : swimLane; // Only return a new object if there was a change
			});


			return Helpers.updatedState(state, "cjmSwimLanes", updatedcjmSwimLanes);
		}

		case success("UPDATE_SWIMLANES_PROPERTIES"):
			return state;

		case success("ADD_MULTI_SWIMLANES"): {
			const { current_map: newCurrentMap } = state;

			const personaId = action.meta.requestAction.params.personaIdStr;
			const { Text, Image, ListItems, KPIs, Timing, Systems, Departments, Feelings, AdvancedInteractions, Touchpoints, ActionsList, Opportunity, CustomLibrary, Solution, CustomerFeedback } = InformationType;

			responseData.forEach((response, index) => {
				const sentimentsConfig = !isNullOrUndefined(response.swimLaneObj?.sentimentsConfig) && JSON.parse(response.swimLaneObj?.sentimentsConfig);
				const swimLaneObj = { ...response.swimLaneObj, sentimentsConfig: sentimentsConfig };
				const type = swimLaneObj.type;
				const swimLaneInitValues = response?.swimLaneInitValues;

				const errorCode = response?.errorCode;
				if (errorCode !== 0 && errorCode !== undefined) return state;

				newCurrentMap.cjmSwimLanes = [...newCurrentMap.cjmSwimLanes, swimLaneObj];
				if ([CustomLibrary, Systems, Departments, Text, Image, ListItems, KPIs].includes(type))
					newCurrentMap.cjmSwimLaneDetails = { ...newCurrentMap.cjmSwimLaneDetails, [swimLaneObj.id]: {} };
				//let cjmStages = [...state.current_map?.cjmStagesData];

				if (swimLaneInitValues !== null) {
					if (type === ActionsList) newCurrentMap.cjmCxActions = swimLaneInitValues;
					if (type === Opportunity) newCurrentMap.cjmOpportunities = swimLaneInitValues;
					if (type === Solution) newCurrentMap.cjmSolutions = swimLaneInitValues;
					if (type === CustomerFeedback) newCurrentMap.cjmCustomerFeedback = swimLaneInitValues;
					if (type === KPIs) newCurrentMap.cjmCxGoals = swimLaneInitValues;
					if (type === Timing) newCurrentMap.cjmTimeLines = swimLaneInitValues;
					if ([CustomLibrary, Systems, Departments].includes(type))
						newCurrentMap.cjmSwimLaneDetails = { ...state.current_map?.cjmSwimLaneDetails, [swimLaneObj.key]: swimLaneInitValues[swimLaneObj.key] };
					if (type === Touchpoints) newCurrentMap.cjmData = InteractionArrayValidations(state.current_map.cjmStagesData, personaId, swimLaneInitValues);
					if ([Feelings, AdvancedInteractions].includes(type)) {
						if (swimLaneInitValues?.personaInteractions)
							newCurrentMap.cjmData = InteractionArrayValidations(state.current_map.cjmStagesData, personaId, swimLaneInitValues?.personaInteractions);
						if (swimLaneInitValues?.personaExperiences) newCurrentMap.cjmStagesCustomerExperienceData = swimLaneInitValues?.personaExperiences;
						if (swimLaneInitValues?.globalSentimentLevel) newCurrentMap.cjmGlobalSentimentLevel = swimLaneInitValues?.globalSentimentLevel;
						const chartSwimlaneObj = { ...swimLaneInitValues?.chartSwimlaneObj, sentimentsConfig: JSON.parse(swimLaneInitValues?.chartSwimlaneObj?.sentimentsConfig) };
						newCurrentMap.cjmSwimLanes = [...newCurrentMap.cjmSwimLanes, chartSwimlaneObj, ...swimLaneInitValues?.additionalSwimlanes];

						const { cjmPersonasData, cjmSwimLanes } = newCurrentMap;
						newCurrentMap.chart = Helpers.processResponseDataChart(swimLaneInitValues.chartData);
						newCurrentMap.mapLegend = Helpers.getLegend(cjmSwimLanes, cjmPersonasData);
						newCurrentMap.showTargetValues = false;
					}
				}
			});

			if (!hasAttribute(newCurrentMap.cjmStagesCustomerExperienceData, personaId)) newCurrentMap.cjmStagesCustomerExperienceData = { [personaId]: {} };

			const newSwimlanes = newCurrentMap.cjmSwimLanes.reduce((result, lane) => {
				result[lane.key] = newCurrentMap.cjmStagesData.reduce((stageResult, stage) => {
					stageResult[stage.id] = { [EMPTY_GUID]: { comments: [], showComments: false } };
					return stageResult;
				}, {});
				return result;
			}, {});

			newCurrentMap.cjmComments = { ...newSwimlanes, ...state.current_map.cjmComments };

			return {
				...state,
				current_map: newCurrentMap,
			};
		}

		case "DELETE_CUSTOM_SWIMLANE": {
			const customSwimLaneIdStr = action.request?.data?.customSwimLaneIdStr;
			let cjmSwimlanes_copy = [...state.current_map.cjmSwimLanes];
			const swimLaneType = state.current_map.cjmSwimLanes?.find((swimLane) => swimLane.key === customSwimLaneIdStr).type;
			let cjmDatas = state.current_map.cjmData ? [...state.current_map.cjmData] : [];
			let showMainRoute = state.current_map.showMainRoute;
			let showAlternativeRoute = state.current_map.showAlternativeRoute;
			const genericId = action.request.params?.genericId;
			let chart = state.current_map.chart;
			let mapLegend = state.current_map.mapLegend;
			if (swimLaneType === InformationType.Touchpoints) {
				// keep only generic
				cjmDatas = cjmDatas.map((group) => group.filter((tp) => tp.touchPointId === genericId));
			}
			if ([InformationType.Feelings, InformationType.AdvancedInteractions].includes(swimLaneType)) {
				cjmDatas = state.current_map.cjmStagesData.map((s) => []);
				cjmSwimlanes_copy = cjmSwimlanes_copy.filter(
					(swimLane) =>
						![InformationType.SentimentChart].includes(swimLane.type)
				);
				chart = null;
				mapLegend = null;
				if (InformationType.AdvancedInteractions === swimLaneType) {
					showMainRoute = false;
					showAlternativeRoute = false;
				}
			}

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLanes: cjmSwimlanes_copy.filter((swimLane) => swimLane.key !== customSwimLaneIdStr),
					cjmSwimLaneDetails: Object.fromEntries(Object.entries(state.current_map.cjmSwimLaneDetails).filter((entry) => entry[0] !== customSwimLaneIdStr)),
					cjmCxActions: swimLaneType === 100000003 ? {} : state.current_map.cjmCxActions,
					cjmOpportunities: swimLaneType === 100000004 ? {} : state.current_map.cjmOpportunities,
					cjmSolutions: swimLaneType === InformationType.Solution ? {} : state.current_map.cjmSolutions,
					cjmStagesSystemData: swimLaneType === 100000009 ? {} : state.current_map.cjmStagesSystemData,
					cjmStagesDepartmentData: swimLaneType === 100000010 ? {} : state.current_map.cjmStagesDepartmentData,
					cjmCxGoals: swimLaneType === InformationType.KPIs ? {} : state.current_map.cjmCxGoals,
					cjmTimeLines: swimLaneType === InformationType.Timing ? {} : state.current_map.cjmTimeLines,
					cjmCustomerFeedback: swimLaneType === InformationType.CustomerFeedback ? {} : state.current_map.cjmCustomerFeedback,
					cjmData: cjmDatas,
					cjmStagesCustomerExperienceData: swimLaneType === 100000006 ? {} : state.current_map.cjmStagesCustomerExperienceData,
					cjmStagesData:
						swimLaneType === 100000011
							? state.current_map.cjmStagesData.map((st) => {
								return { ...st, timing: "" };
							})
							: state.current_map.cjmStagesData,
					showMainRoute: showMainRoute,

					showAlternativeRoute: showAlternativeRoute,
					chart: chart,
					mapLegend: mapLegend,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_TOUCHPOINT_ORDER":
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmData: action.payload,
				},
			};
		case "UPDATE_PERSONA_INTERACTION": {
			const { personaId, stageId, newChannelId, personaInteractionId } = action.request.data;
			const stageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === stageId);
			const cjmDataCopy = [...state.current_map.cjmData];
			const personaInteractionsCopy = { ...state.current_map.cjmPersonasInteractions };
			const updated = state.current_map.cjmData[stageIndex]?.map((interaction) => {
				if (interaction.id === action.params.id) {
					const copy = { ...action.params };
					copy.modifiedOn = new Date().toISOString();
					return copy;
				}
				return interaction;
			});
			cjmDataCopy[stageIndex] = updated;
			if (personaInteractionsCopy[personaId]?.[stageId]?.[newChannelId]) {
				personaInteractionsCopy[personaId][stageId][newChannelId] = personaInteractionsCopy[personaId][stageId][newChannelId]?.map((interaction) => {
					if (interaction.id === personaInteractionId) {
						const copy = { ...action.params };
						copy.modifiedOn = new Date().toISOString();
						return copy;
					}
					return interaction;
				});
			}

			return Helpers.updatedState(state, "cjmData", cjmDataCopy, "cjmPersonasInteractions", personaInteractionsCopy);
		}
		case "LINK_INTERACTION_RECORDS": {
			const { recIds, personaInteractionId } = action.request.data;
			const { stageId, attribute } = action.params;
			const stageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === stageId);
			const cjmData = [...state.current_map.cjmData];
			const updated = state.current_map.cjmData[stageIndex]?.map((interaction) => {
				if (interaction.id === personaInteractionId) {
					interaction = { ...interaction, [attribute]: [...interaction?.[attribute] || [], ...recIds] };
					return interaction;
				}
				return interaction;
			});
			cjmData[stageIndex] = updated;
			return Helpers.updatedState(state, "cjmData", cjmData);
		}
		case "UNLINK_INTERACTION_RECORDS": {
			const { recIds, personaInteractionId } = action.request.data;
			const { stageId, attribute } = action.params;
			const stageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === stageId);
			const cjmData = [...state.current_map.cjmData];
			const updated = state.current_map.cjmData[stageIndex]?.map((interaction) => {
				if (interaction.id === personaInteractionId) {
					interaction = { ...interaction, [attribute]: interaction?.[attribute].filter((item) => !recIds.includes(item)) };
					return interaction;
				}
				return interaction;
			});
			cjmData[stageIndex] = updated;
			return Helpers.updatedState(state, "cjmData", cjmData);
		}
		case "DELETE_PERSONA_INTERACTION": {
			const stageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === action.request.data.stageId);
			const cjmDataCopy = [...state.current_map.cjmData];
			const updated = state.current_map.cjmData[stageIndex]?.filter((interaction) => interaction.id !== action.params.id);
			cjmDataCopy[stageIndex] = updated;

			return Helpers.updatedState(state, "cjmData", cjmDataCopy);
		}
		case success("CREATE_PERSONA_INTERACTION"): {
			const stageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === requestData.stageId);
			const cjmDataCopy = [...state.current_map.cjmData];
			if (action.meta.requestAction.lastElement) toast.update(action.meta.requestAction.toastId, { render: t("TOUCHPOINT_DUPLICATED"), type: "success", isLoading: false, autoClose: 2000 });
			const newInteraction = action.meta.requestAction.params;
			newInteraction.id = action.response.data;
			const cjmPersonasInteractionsCopy = { ...state.current_map.cjmPersonasInteractions };
			if (cjmPersonasInteractionsCopy[requestData.personaId]?.[requestData.stageId]?.[requestData.channelId]) {
				cjmPersonasInteractionsCopy[requestData.personaId][requestData.stageId][requestData.channelId] = [
					...cjmPersonasInteractionsCopy[requestData.personaId][requestData.stageId][requestData.channelId],
					newInteraction,
				];
			}

			cjmDataCopy[stageIndex] = [...state.current_map.cjmData[stageIndex], newInteraction];
			return Helpers.updatedState(state, "cjmData", cjmDataCopy, "cjmPersonasInteractions", cjmPersonasInteractionsCopy);
		}
		case error("CREATE_PERSONA_INTERACTION"): {
			toast.update(action.meta.requestAction.toastId, { render: "Error Creating Persona Interaction", type: "error", isLoading: false, autoClose: 2000 });
			return state;
		}
		case "CREATE_MULTI_PERSONA_INTERACTION": {
			const { stageId, newPersonaInteractionIds } = action.request.data;
			const stageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === stageId);
			const lengthStages = state.current_map.cjmData[stageIndex]?.length || 0;
			const personaInteractions = Object.keys(newPersonaInteractionIds)?.reduce((acc, touchpointId, index) => {
				const order = lengthStages + index + 1;
				const interactionId = newPersonaInteractionIds[touchpointId]
				const interaction = createDefaultPersonaInteraction(interactionId, stageId, touchpointId, order);
				return { ...acc, [touchpointId]: interaction };
			}, {});
			const cjmDataCopy = [...state.current_map.cjmData];
			cjmDataCopy[stageIndex] = [...state.current_map.cjmData?.[stageIndex] || [], ...Object.values(personaInteractions)];
			return Helpers.updatedState(state, "cjmData", cjmDataCopy);
		}
		case success("REORDER_STAGE_INTERACTIONS"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("CHANGE_INTERACTION_STAGE"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_PERSONA_EXPERIENCE_TEXT"): {
			const personaIdStr_UPET = requestData.personaIdStr;
			const stageIdStr_UPET = requestData.stageIdStr;
			const textType_UPET = requestData.textType;
			const newValue = requestData.newValue;
			if (!hasAttribute(state.current_map.cjmStagesCustomerExperienceData, personaIdStr_UPET)) return state;

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesCustomerExperienceData: {
						...state.current_map.cjmStagesCustomerExperienceData,
						[personaIdStr_UPET]: {
							...state.current_map.cjmStagesCustomerExperienceData[personaIdStr_UPET],
							[stageIdStr_UPET]: {
								...state.current_map.cjmStagesCustomerExperienceData[personaIdStr_UPET][stageIdStr_UPET],
								[textType_UPET]: decode(newValue),
							},
						},
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}

		case success("UPDATE_STAGE_DESCRIPTION"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData?.map((stage) => {
						if (stage.id === requestData.stageIdStr) {
							stage.description = decode(requestData.newValue);
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case "UPDATE_PERSONA_STAGE_SIMPLE_SENTIMENT": {
			const genericId = action?.params?.genericId;
			const { stageId, newValue, personaInteractionId } = action.request.data;

			const cjmData = state.current_map.cjmData;
			const currentStageIndex = state.current_map.cjmStagesData?.findIndex((stage) => stage.id === stageId);
			const cjmDataCopy = [...cjmData];


			const newData = cjmData[currentStageIndex]?.map((int) => {
				if (int.id === personaInteractionId) {
					int.emotionLevel = newValue - 1;
				}
				return int;
			});

			cjmDataCopy[currentStageIndex] = newData;

			return Helpers.updatedState(state, "cjmData", cjmDataCopy);
		}
		case success("GET_MAP_CHART_DATA"): {
			const { current_map } = state;
			const { cjmPersonasData, cjmSwimLanes } = current_map;

			const legend = Helpers.getLegend(cjmSwimLanes, cjmPersonasData);
			const dataChart = Helpers.processResponseDataChart(responseData);
			return Helpers.updateStateMuliCJM(state, { chart: dataChart, mapLegend: legend, showTargetValues: false });
		}

		case success("UPDATE_STAGE_TARGET_EMOTION_LEVEL"): {
			const getTargetPoints = (number) => ` <div class="chart-label"><div class="stage"></div><div class="line">Target</div><div class="sentiment-level">${number}</div></div>`;
			return {
				...state,
				current_map: {
					...state.current_map,
					chart: {
						...state.current_map.chart,
						feelingPoints_Target: state.current_map.chart.feelingPoints_Target.map((point) => {
							if (point[0] === requestData.stageIdStr) {
								point[1] = Number(requestData.newValue - 1);
								point[2] = getTargetPoints(Number(requestData.newValue));
							}

							return point;
						}),
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}


		case "UNLINK_LIBRARY_VALUE": {
			if (!hasAttribute(state.current_map, "cjmSwimLaneDetails")) return state;
			const { swimLaneItemId, customSwimLaneId, stageId } = action.request.data;
			const currentSwimLaneDetails = state.current_map.cjmSwimLaneDetails;
			const stageDetails = currentSwimLaneDetails[customSwimLaneId][stageId];
			const updatedStageDetails = Helpers.deleteLibraryItemFromStage(stageDetails, swimLaneItemId);

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLaneDetails: {
						...currentSwimLaneDetails,
						[customSwimLaneId]: {
							...currentSwimLaneDetails[customSwimLaneId],
							[stageId]: updatedStageDetails,
						},
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "LINK_LIBRARY_VALUES_MULTI": {
			const { swimLaneItemIds, libraryValueIds, customSwimLaneId, stageId } = action.request.data;
			const currentMap = { ...state.current_map };

			// Ensure the necessary nested objects exist
			currentMap.cjmSwimLaneDetails = currentMap.cjmSwimLaneDetails || {};
			currentMap.cjmSwimLaneDetails[customSwimLaneId] = currentMap.cjmSwimLaneDetails[customSwimLaneId] || {};
			currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId] = currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId] || {};

			// Ensure StageSwimlanes has an 'id' property
			if (!hasAttribute(currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId], "id")) {
				currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId].id = uuidv4();
				currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId].swimLaneId = customSwimLaneId;
			}

			const pastItems = currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId].items || [];
			const newLibraryValueIds = libraryValueIds?.filter(libraryValue => !(pastItems.find(item => libraryValue == (item?.libraryValueId))));
			const newItems = newLibraryValueIds?.map((libraryValueId, index) => ({
				id: swimLaneItemIds[index],
				libraryValueId,
				icons: [],
			}));

			currentMap.cjmSwimLaneDetails[customSwimLaneId][stageId].items = [...pastItems, ...newItems];
			currentMap.lastModifiedOn = new Date().toISOString();

			return {
				...state,
				current_map: currentMap,
			};
		}
		case error("LINK_LIBRARY_VALUES_MULTI"): {

			if (!hasAttribute(state.current_map, "cjmSwimLaneDetails")) return state;
			const { swimLaneItemIds, customSwimLaneId, stageId } = action.meta.requestAction.request.data
			const currentSwimLaneDetails = state.current_map.cjmSwimLaneDetails;
			const stageDetails = currentSwimLaneDetails[customSwimLaneId][stageId];
			const updatedStageDetails = Helpers.deleteLibraryItemsFromStage(stageDetails, swimLaneItemIds);

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLaneDetails: {
						...currentSwimLaneDetails,
						[customSwimLaneId]: {
							...currentSwimLaneDetails[customSwimLaneId],
							[stageId]: updatedStageDetails,
						},
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};

		}

		// case success("UPDATE_CJM_NAME"):
		// 	return {
		// 		...state,
		// 		all_maps: state.all_maps?.map((map) => {
		// 			if (map.cjmId === requestData.cjmIdStr) {
		// 				return {
		// 					...map,
		// 					name: decode(requestData.newValue),
		// 					lastModifiedOn: new Date().toISOString(),
		// 				};
		// 			}
		// 			return map;
		// 		}),
		// 		current_map: {
		// 			...state.current_map,
		// 			cjmName: decode(requestData.newValue),
		// 			lastModifiedOn: new Date().toISOString(),
		// 		},
		// 	};
		case success("UPDATE_STAGE_KPIS"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData?.map((stage) => {
						if (stage.id === requestData.stageIdStr) {
							stage.kpIs = decode(requestData.newValue);
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_STAGE_ISSUES"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData?.map((stage) => {
						if (stage.id === requestData.stageIdStr) {
							stage.issues = decode(requestData.newValue);
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_STAGE_PROCESSES"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData?.map((stage) => {
						if (stage.id === requestData.stageIdStr) {
							stage.processes = decode(requestData.newValue);
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("REPLACE_MAP_LOGO"):
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmId) {
						return {
                            ...map,
                            isCustomImage: true,
							customImageBody: requestData.DocumentBody,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;
				}),
			};

		case "UPDATE_CUSTOM_LIST_ITEM_SWIMLANE_DETAILS": {
			if (!hasAttribute(state.current_map, "cjmSwimLaneDetails")) return state;
			const { customSwimLaneItemIdStr, customSwimLaneIdStr: swimlaneKey, stageIdStr, description } = action.request.data;

			return Helpers.updateSwimlaneItem(state, customSwimLaneItemIdStr, swimlaneKey, stageIdStr, description);
		}

		case success("UPDATE_CX_ACTION"): {
			if (!hasAttribute(state.current_map, "cjmCxActions")) return state;
			const stgId = requestData.modifiedCxAction.stageId;
			const actionId = requestData.modifiedCxAction.id;
			const beforeModifiedCxAction = action.meta.requestAction.params?.beforeModifiedCxAction;
			//case of regular update on existing task in a stage
			if (hasAttribute(state.current_map.cjmCxActions, stgId)) {
				return {
					...state,
					current_map: {
						...state.current_map,
						cjmCxActions: {
							...state.current_map.cjmCxActions,
							[stgId]: state.current_map.cjmCxActions[stgId]?.map((act) => {
								if (act.id === actionId) {
									return responseData;
								}
								return act;
							}),
						},
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			if (beforeModifiedCxAction !== undefined) {
				//case that task changed map in the form
				if (requestData.modifiedCxAction.mapId !== state.current_map.cjmId) {
					return {
						...state,
						current_map: {
							...state.current_map,
							cjmCxActions: {
								...state.current_map.cjmCxActions,
								[beforeModifiedCxAction.stageId]: state.current_map.cjmCxActions[beforeModifiedCxAction.stageId]?.filter((act) => act.id !== beforeModifiedCxAction.id),
							},
							lastModifiedOn: new Date().toISOString(),
						},
					};
				}
				// case that task changed stage in the form
				return {
					...state,
					current_map: {
						...state.current_map,
						cjmCxActions: {
							...state.current_map.cjmCxActions,
							[beforeModifiedCxAction.stageId]: state.current_map.cjmCxActions[beforeModifiedCxAction.stageId]?.filter((act) => act.id !== beforeModifiedCxAction.id),
							[stgId]: [responseData],
						},
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return state;
		}
		case success("DELETE_CX_ACTION"): {
			const taskToDelete = action.meta.requestAction.params?.taskToDelete;
			if (!hasAttribute(state.current_map, "cjmCxActions") || !taskToDelete?.stageId) return state;
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmCxActions: {
						...state.current_map.cjmCxActions,
						[taskToDelete.stageId]: state.current_map.cjmCxActions[taskToDelete.stageId]?.filter((act) => act.id !== taskToDelete.id),
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("COPY_CX_ACTION"): {
			if (!hasAttribute(state.current_map, "cjmCxActions")) return state;
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmCxActions: {
						...state.current_map.cjmCxActions,
						[action?.response?.data?.stageId]: [...state.current_map.cjmCxActions?.[action?.response?.data?.stageId], action?.response?.data],
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_ORDER_CX_ACTION": {
			const newcxAction = state.current_map.cjmStagesData.map((stage, index) => {
				return { [stage.id]: action.payload.newOrder[index] };
			});

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmCxActions: newcxAction.reduce((acc, curr) => {
						return { ...acc, ...curr };
					}),
				},
			};
		}
		case success("UPDATE_SWIMLANES_IMAGE"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("DELETE_SWIMLANES_IMAGE"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_CJM_DESCRIPTION"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmDescription: decode(requestData.newValue),

					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("GET_MAP_USERS"): {
			const cookies = Cookies.get("Authorization-cemantica")
			const isReadOnly = cookies ? jwt_decode(cookies).UserType.isReadOnly() : true;
			const isPrint = action.meta.requestAction.params.isPrint;
			const userId = action.meta.requestAction.params.userId;
			const cxOwnerEntity = action.meta.requestAction.params.cxOwnerEntity;
			const pathname = window.location.pathname.split("/");
			const inMap = pathname.includes("EditMap");
			const incxAction = pathname.includes("edit-cxactions");
			const inOpp = pathname.includes("edit-opportunity");
			const inSolutions = pathname.includes("edit-solution");
			const linkedUsers = Object.entries(responseData).map((user) => user[1]) || [];
			const user = linkedUsers.find((users) => users.id === userId && users.roleId === EMPTY_GUID);
			if (hasAttribute(user, "roleName")) user.roleName = isPrint ? "viewer" : firstLetterUppercase(user?.roleName); // only to export pdf
			if (user?.roleName.toLowerCase() === "viewer" && isPrint === false && inMap) {
				toastWrapper("", "MAP_VIEWER_WARNING", { autoClose: 10000 });
			} else {
				if ((isReadOnly && incxAction) || (user?.roleName.toLowerCase() === "viewer" && incxAction && cxOwnerEntity !== userId))
					toastWrapper("", "ACTION_VIEWER_WARNING", { autoClose: 10000 });
				if ((isReadOnly && inOpp) || (user?.roleName.toLowerCase() === "viewer" && inOpp && cxOwnerEntity !== userId)) toastWrapper("", "OPPORTUNITY_VIEWER_WARNING", { autoClose: 10000 });
				if ((isReadOnly && inSolutions) || (user?.roleName.toLowerCase() === "viewer" && inSolutions && cxOwnerEntity !== userId))
					toastWrapper("", "SOLUTION_VIEWER_WARNING", { autoClose: 10000 });
			}

			return {
				...state,
				current_linked_users: linkedUsers,
				currentUser: user,
			};
		}
		case "RESET_MAP_USER":
			return {
				...state,
				currentUser: null,
				current_linked_users: null,
			};
		case success("LINK_PERSONA"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPersonasData: [...state.current_map.cjmPersonasData, { personaId: requestData.personaIdStr }],
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UNLINK_PERSONA"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPersonasData: state.current_map.cjmPersonasData.filter((persona) => persona.personaId !== requestData.personaIdStr),
					lastModifiedOn: new Date().toISOString(),
				},
			};

		case success("UPDATE_CJM_TYPE"): {
			const ids = requestData.cjmIdsStr.split("|") || [];
			return {
				...state,
				current_map: {
					...state.current_map,
					isFuture: requestData.newValue === 100000001 ? true : false,
					lastModifiedOn: new Date().toISOString(),
				},
				all_maps: state.all_maps?.map((map) => {
					if (ids.includes(map.cjmId)) {
						return {
							...map,
							isFuture: requestData.newValue === 100000001 ? true : false,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;
				}),
			};
		}

		case success("UPDATE_MAP_LINKS_CJM"): {
			let link = state.current_map.linkedCjms;
			if (requestData.cjmIdsToLink && !requestData.cjmIdsToLink.isEmptyString()) {
				const newSet = new Set([...link, ...requestData.cjmIdsToLink.split("|")]);
				link = Array.from(newSet);
			}
			if (requestData.cjmIdsToUnlink && !requestData.cjmIdsToUnlink.isEmptyString()) {
				const filtered = link.filter((l) => !requestData.cjmIdsToUnlink.split("|").includes(l));
				link = filtered;
			}
			const updatedMap = state.all_maps?.map((map) => {
				if (map.cjmId === requestData.cjmIdStr) {
					map.linkedCjms = link;
					return {
						...map,
						lastModifiedOn: new Date().toISOString(),
					};
				}
				return map;
			});

			return Helpers.updateStateMuliCJM(state, { linkedCjms: link }, { all_maps: updatedMap });
		}
		case "UPDATE_CJM_TEMPLATE_LOCKED":
			return {
				...state,
				current_map: {
					...state.current_map,
					isLocked: action.request.data.isChecked,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case "UPDATE_CJM_TEMPLATE_PUBLISH":
			return {
				...state,
				current_map: {
					...state.current_map,
					templateStatus: action.request.data.isChecked ? TemplateStatus.published : TemplateStatus.draft,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("CREATE_CX_ACTION"): {
			if (!hasAttribute(state.current_map, "cjmId")) return state;
			const cjmCxActions = state.current_map?.cjmCxActions ? JSON.parse(JSON.stringify(state.current_map.cjmCxActions)) : {};
			const stageId_CXACTION = action?.response?.data?.stageId;
			const newAction = responseData;
			if (hasAttribute(cjmCxActions, stageId_CXACTION)) {
				cjmCxActions[stageId_CXACTION] = [...cjmCxActions[stageId_CXACTION], newAction];
			} else {
				cjmCxActions[stageId_CXACTION] = [newAction];
			}

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmCxActions: cjmCxActions,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "SET_CURRENT_USER_ROLE_MAP":
			return {
				...state,
				current_map: {
					...state.current_map,
					currentUser: state.current_linked_users?.find((users) => users.id === action.payload),
				},
			};

		case success("UPDATE_STAGE_LINKS_CJM"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData?.map((stage) => {
						if (stage.id === requestData.stageIdStr) {
							stage.linkedCjms = requestData.cjmIdsToLink !== "" ? requestData.cjmIdsToLink?.split("|") || [] : [];
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmIdStr) {
						const stageIdx = map.stages.findIndex((stage) => stage.id === requestData.stageIdStr);
						map.stages[stageIdx].linkedCjms = map.stages[stageIdx].linkedCjms.filter((linkedCjm) => linkedCjm !== requestData.cjmIdsToUnlink);
						return {
							...map,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;
				}),
			};

		case success("UPDATE_PHASE_LINKS_CJM"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: state.current_map.cjmPhasesData?.map((phase) => {
						if (phase.id === requestData.phaseIdStr) {
							phase.linkedCjms = requestData.cjmIdsToLink !== "" ? requestData.cjmIdsToLink?.split("|") || [] : [];
						}
						return phase;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmIdStr) {
						const phaseIdx = map.phases.findIndex((phase) => phase.id === requestData.phaseIdStr);
						map.phases[phaseIdx].linkedCjms = map.phases[phaseIdx].linkedCjms.filter((linkedCjm) => linkedCjm !== requestData.cjmIdsToUnlink);
						return {
							...map,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;
				}),
			};
		case "FILTER_EMOTION_MAP": {

			// reconstruction,persona order
			const newFilter = Helpers.getLegend(state.current_map.cjmSwimLanes, state.current_map.cjmPersonasData)
			if (newFilter?.length === 1) {
				toast.info("You need to select at least one line");
				return state;
			}
			return Helpers.updatedState(state, "mapLegend", newFilter);
		}
		case "SHOW_TARGET_VALUES":
			return {
				...state,
				current_map: {
					...state.current_map,
					showTargetValues: !state.current_map.showTargetValues,
				},
			};
		case success("UPDATE_STAGE_ACTIONS_ORDER"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_STAGE_OPPORTUNITY_ORDER"):
			return {
				...state,
				current_map: {
					...state.current_map,
					lastModifiedOn: new Date().toISOString(),
				},
			};

		case success("UPDATE_SHOW_CUSTOMER_SENTIMENT"):
			return {
				...state,
				current_map: {
					...state.current_map,
					showContactsSentimentLevel: requestData.showSentiment,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_SHOW_PERSONA_SENTIMENT"):
			return {
				...state,
				current_map: {
					...state.current_map,
					showPersonaSentimentLevel: requestData.showSentiment,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case "UPDATE_SHOW_ROUTE": {
			const { showRoute, isMain } = action.request.data;
			return {
				...state,
				current_map: {
					...state.current_map,
					showMainRoute: isMain ? showRoute : state.current_map.showMainRoute,
					showAlternativeRoute: isMain ? state.current_map.showAlternativeRoute : showRoute,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "DESACTIVATE_ROUTE": {
			return {
				...state,
				current_map: {

					...state.current_map,

					desactivateRoutes: action.payload.value,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("RETRIEVE_PERVIOUS_LINK_PERSONA"):
			return {
				...state,
				current_map: {
					...state.current_map,
					previousPersonasLink: responseData,
				},
			};
		case "UPDATE_HEADER_EXPANDED":
			return {
				...state,
				current_map: {
					...state.current_map,
					isHeaderExpanded: action.request.data.isExpanded,
				},
			};
		case success("UPDATE_HEADER_EXPANDED"):
			return {
				...state,
				current_map: {
					...state.current_map,
					// isHeaderExpanded :requestData.isExpanded ,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case "FORCE_PERSONA_INTERACTION_ROUTE": {
			const { newValue, isMain, stageId, personaInteractionId } = action.request.data;
			const cjmData = state.current_map.cjmData;
			const currentStageIndex = state.current_map.cjmStagesData.findIndex((stage) => stage.id === stageId);
			const cjmDataCopy = [...cjmData];
			let modifiedRouteAttr = "forcedMainRoute";

			if (!isMain) modifiedRouteAttr = "forcedAlternativeRoute";

			const newData = cjmData[currentStageIndex].map((int) => {
				if (int.id === personaInteractionId) {
					int[modifiedRouteAttr] = newValue;
				} else int[modifiedRouteAttr] = false;
				return int;
			});

			cjmDataCopy[currentStageIndex] = newData;

			return Helpers.updatedState(state, "cjmData", cjmDataCopy);
		}

		case success("DELETE_MAP_IMAGE"):
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmIdStr) {
						return {
                            ...map,
                            isCustomImage: false,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;
				}),
			};
		case success("DELETE_MANY_MAPS"): {
			const successIdDeleted = responseData;
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (successIdDeleted.includes(map.cjmId)) {
						return { ...map, statusCode: StatusCode.Trashed };
					}
					return map;
				}),
			};
		}
		case success("ARCHIVE_MANY_MAPS"): {
			const successIdArchived = responseData;

			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (successIdArchived.includes(map.cjmId)) {
						return { ...map, statusCode: StatusCode.Archived };
					}
					return map;
				}),
			};
		}
		case success("RESTORE_MANY_MAPS"): {
			const successIdRestore = responseData;

			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (successIdRestore.includes(map.cjmId)) {
						return { ...map, statusCode: StatusCode.Active };
					}
					return map;
				}),
			};
		}
		case success("DELETE_PERMANENTLY_MANY_MAPS"): {
			const successIdDeletePerma = responseData;

			return {
				...state,
				all_maps: state.all_maps.filter((map) => !successIdDeletePerma.includes(map.cjmId)),
			};
		}

		case success("UPDATE_SOLUTION"): {
			if (!hasAttribute(state.current_map, "cjmSolutions")) return state;

			const stageId = requestData.modifiedSolution?.StageId;
			if (!hasAttribute(state.current_map.cjmSolutions, stageId)) return state;
			const updatedSolutions = {
				...state.current_map.cjmSolutions,
				[stageId]: state.current_map.cjmSolutions[stageId]?.map((act) => {
					if (act.id === responseData.id) {
						act = responseData;
					}
					return act;
				}),
			};
			return Helpers.updateStateMuliCJM(state, { cjmSolutions: updatedSolutions });
		}

		case success("DELETE_SOLUTION"): {
			if (!hasAttribute(state.current_map, "cjmSolutions")) return state;

			const solution = action.meta.requestAction?.params;
			const cjmSolutions = state.current_map.cjmSolutions;

			if (isNullOrUndefined(solution?.stageId)) return state;
			const updatedCjmSolutions = {
				...cjmSolutions,
				[solution.stageId]: cjmSolutions[solution.stageId]?.filter((sol) => sol.id !== solution.id),
			};
			return Helpers.updateStateMuliCJM(state, { cjmSolutions: updatedCjmSolutions });
		}
		case success("COPY_SOLUTION"): {
			if (!hasAttribute(state.current_map, "cjmSolutions")) return state;
			const cjmSolutions = state.current_map.cjmSolutions;
			const stageId = action?.response?.data?.stageId;

			const updatedSolution = {
				...cjmSolutions,
				[stageId]: [...cjmSolutions[stageId], responseData],
			};
			return Helpers.updateStateMuliCJM(state, { cjmSolutions: updatedSolution });
		}

		case "UPDATE_ORDER_SOLUTION": {
			const newOrder = action.payload.newOrder;
			const cjmStagesData = state.current_map.cjmStagesData;
			const newSolutionOrder = cjmStagesData.map((stage, index) => {
				return { [stage.id]: newOrder[index] };
			});

			return Helpers.updateStateMuliCJM(state, {
				cjmSolutions: newSolutionOrder.reduce((acc, curr) => {
					return { ...acc, ...curr };
				}),
			});
		}

		case success("CREATE_SOLUTION"): {
			if (!hasAttribute(state.current_map, "cjmId")) return state;

			const cjmSolutions = state.current_map.cjmSolutions ? JSON.parse(JSON.stringify(state.current_map.cjmSolutions)) : {};
			const stageId = action?.response?.data?.stageId;
			const newSolution = responseData;

			if (hasAttribute(cjmSolutions, stageId)) {
				cjmSolutions[stageId] = [...cjmSolutions[stageId], newSolution];
			} else {
				cjmSolutions[stageId] = [newSolution];
			}
			return Helpers.updateStateMuliCJM(state, { cjmSolutions: cjmSolutions });
		}
		case success("UPDATE_OPPORTUNITY"): {
			if (!hasAttribute(state.current_map, "cjmOpportunities")) return state;
			const stgeId = requestData.modifiedOpportunity.StageId;
			//case of regular update on existing task in a stage
			if (hasAttribute(state.current_map.cjmOpportunities, stgeId)) {
				return {
					...state,
					current_map: {
						...state.current_map,
						cjmOpportunities: {
							...state.current_map.cjmOpportunities,
							[stgeId]: state.current_map.cjmOpportunities[stgeId]?.map((act) => {
								if (act.id === responseData.id) {
									act = responseData;
								}
								return act;
							}),
						},
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return state;
		}
		case success("DELETE_OPPORTUNITY"): {
			const oppToDelete = action.meta.requestAction?.params;

			if (!hasAttribute(state.current_map, "cjmOpportunities") || oppToDelete?.stageId === undefined) return state;
			else
				return {
					...state,
					current_map: {
						...state.current_map,
						cjmOpportunities: {
							...state.current_map.cjmOpportunities,
							[oppToDelete?.stageId]: state.current_map.cjmOpportunities[oppToDelete.stageId]?.filter((act) => act.id !== oppToDelete.id),
						},
						lastModifiedOn: new Date().toISOString(),
					},
				};
		}
		case success("COPY_OPPORTUNITY"): {
			if (!hasAttribute(state.current_map, "cjmOpportunities")) return state;
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmOpportunities: {
						...state.current_map.cjmOpportunities,
						[action?.response?.data?.stageId]: [...state.current_map.cjmOpportunities[action?.response?.data?.stageId], action?.response?.data],
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_ORDER_OPPORTUNITY": {
			const newOppOrder = state.current_map.cjmStagesData.map((stage, index) => {
				return { [stage.id]: action.payload.newOrder[index] };
			});

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmOpportunities: newOppOrder.reduce((acc, curr) => {
						return { ...acc, ...curr };
					}),
				},
			};
		}
		case success("CREATE_OPPORTUNITY"): {
			if (!hasAttribute(state.current_map, "cjmId")) return state;
			const cjmOpportunities_COPY = state.current_map?.cjmOpportunities ? JSON.parse(JSON.stringify(state.current_map?.cjmOpportunities)) : {};
			const stageId_OPP = action?.response?.data?.stageId;
			const newOpp = responseData;
			if (hasAttribute(cjmOpportunities_COPY, stageId_OPP)) {
				cjmOpportunities_COPY[stageId_OPP] = [...cjmOpportunities_COPY[stageId_OPP], newOpp];
			} else {
				cjmOpportunities_COPY[stageId_OPP] = [newOpp];
			}

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmOpportunities: cjmOpportunities_COPY,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "ORDER_LIST_ITEM_ORDER": {
			const newState = action.payload.newState;
			const source = action.payload.source;
			const dest = action.payload.destination;
			const swimlaneId = action.payload.swimlaneId;
			let swimlaneListItem = JSON.parse(JSON.stringify({ ...state.current_map.cjmSwimLaneDetails[swimlaneId] }));
			const sourceStageId = state.current_map.cjmStagesData[source]?.id;
			const destStageId = state.current_map.cjmStagesData[dest]?.id;
			if (sourceStageId) {
				swimlaneListItem = {
					...swimlaneListItem,
					[sourceStageId]: {
						id: swimlaneListItem[sourceStageId]?.id || uuidv4(),
						swimLaneId: swimlaneId,
						stageId: sourceStageId,
						items: newState[source],
					},
				};
			}
			if (destStageId) {
				swimlaneListItem = {
					...swimlaneListItem,
					[destStageId]: {
						id: swimlaneListItem[destStageId]?.id || uuidv4(),
						swimLaneId: swimlaneId,
						stageId: destStageId,
						items: newState[dest],
					},
				};
			}

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLaneDetails: {
						...state.current_map.cjmSwimLaneDetails,
						[swimlaneId]: swimlaneListItem,
					},
				},
			};
		}
		case success("DELETE_LIST_ITEMS"): {
			if (!hasAttribute(state.current_map, "cjmSwimLaneDetails")) return state;

			const { customSwimLaneItemIdStr, customSwimLaneIdStr: swimlaneKey, stageIdStr } = requestData;

			const currentSwimLaneDetails = state.current_map.cjmSwimLaneDetails;
			const stageDetails = currentSwimLaneDetails[swimlaneKey][stageIdStr];
			const updatedStageDetails = Helpers.deleteItemFromStage(stageDetails, customSwimLaneItemIdStr);

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmSwimLaneDetails: {
						...currentSwimLaneDetails,
						[swimlaneKey]: {
							...currentSwimLaneDetails[swimlaneKey],
							[stageIdStr]: updatedStageDetails,
						},
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}

		case success("ENABLE_COMMENT"): {
			const { swimlaneId, stageId, attributeId } = requestData;

			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments?.[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];

			setTimeout(() => {
				const commentBox = $(`#comment_manager_${swimlaneId}_${stageId}_${attributeId || EMPTY_GUID}`);
				if (commentBox) {
					commentBox.click();
				}
			}, 500);
			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;

			if (currentAttributeComments?.showComments) {
				toast.info(t("ALREADY_HAVE_COMMENT"));
				return state;
			}

			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, showComments: true },
					},
				},
			};

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmComments: updatedComments,
				},
			};
		}
		case success("DISABLE_COMMENT"): {
			const { swimlaneId, stageId, attributeId } = requestData;

			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];

			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;
			// if (currentAttributeComments?.show) {
			// 	toast.info(t("ALREADY_HAVE_COMMENT"));
			// 	return state;
			// }

			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, showComments: false },
					},
				},
			};

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmComments: updatedComments,
				},
			};
		}
		case success("ADD_COMMENT"): {
			const { swimlaneId, stageId, attributeId } = requestData;
			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];
			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;
			const relevantComments = currentAttributeComments.comments || [];
			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, comments: [...relevantComments, responseData] },
					},
				},
			};

			return Helpers.updatedState(state, "cjmComments", updatedComments);
		}

		case success("REMOVE_COMMENT"): {
			const { commentId, swimlaneId, stageId, attributeId } = requestData;

			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];

			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;
			const relevantComments = currentAttributeComments.comments || [];

			const updatedCommentsArray = relevantComments.filter((comment) => comment.id !== commentId);

			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, comments: updatedCommentsArray },
					},
				},
			};

			return Helpers.updatedState(state, "cjmComments", updatedComments);
		}

		case success("UPDATE_COMMENT"): {
			const { commentId, newDescription, swimlaneId, stageId, attributeId } = requestData;

			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];

			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;

			const relevantComments = currentAttributeComments.comments || [];

			const updatedCommentsArray = relevantComments.map((comment) => (comment.id === commentId ? { ...comment, description: decode(newDescription) } : comment));

			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, comments: updatedCommentsArray },
					},
				},
			};

			return Helpers.updatedState(state, "cjmComments", updatedComments);
		}

		case success("RESOLVED_COMMENTS"): {
			const { swimlaneId, stageId, attributeId } = requestData;

			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];

			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;

			const relevantComments = currentAttributeComments.comments || [];

			const updatedCommentsArray = relevantComments.map((comment) => ({ ...comment, isResolved: true }));
			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, comments: updatedCommentsArray },
					},
				},
			};

			return Helpers.updatedState(state, "cjmComments", updatedComments);
		}
		case success("RESOLVE_SINGLE_COMMENT"):
		case success("REOPEN_COMMENTS"): {
			const { swimlaneId, stageId, attributeId, commentId, isResolved } = requestData;

			const currentComments = state.current_map.cjmComments;
			const currentSwimlaneComments = currentComments[swimlaneId] || {};
			const currentStageComments = currentSwimlaneComments[stageId] || {};
			const currentAttributeComments = attributeId ? currentStageComments[attributeId] : currentStageComments[EMPTY_GUID];

			// Define the key we are interested in (attributeId or not)
			const targetKey = attributeId || EMPTY_GUID;

			const relevantComments = currentAttributeComments.comments || [];

			const updatedCommentsArray = relevantComments.map((comment) => (commentId === comment.id ? { ...comment, isResolved: isResolved } : comment));

			const updatedComments = {
				...currentComments,
				[swimlaneId]: {
					...currentSwimlaneComments,
					[stageId]: {
						...currentStageComments,
						[targetKey]: { ...currentAttributeComments, comments: updatedCommentsArray, showComments: isResolved === false ? true : currentAttributeComments.showComments },
					},
				},
			};

			return Helpers.updatedState(state, "cjmComments", updatedComments);
		}
		case success("UPDATE_SHOW_COMMENT"):
			return Helpers.updatedState(state, "showComments", requestData.show);

		case "ADD_ICON_SWIMLANE_ITEMS": {
			const { swimlaneKey, stageId, iconId, cardId } = action.payload;
			const swimLaneDetails = state.current_map.cjmSwimLaneDetails;
			const swimLane = swimLaneDetails[swimlaneKey];

			const updatedSwimLane = {
				...swimLane,
				[stageId]: {
					...swimLane[stageId],
					items: swimLane[stageId].items.map((item) => {
						if (item.id === cardId) {
							return {
								...item,
								icons: [...item.icons, iconId],
							};
						}
						return item;
					}),
				},
			};
			const updatedSwimLaneDetails = {
				...swimLaneDetails,
				[swimlaneKey]: updatedSwimLane,
			};
			return Helpers.updatedState(state, "cjmSwimLaneDetails", updatedSwimLaneDetails);
		}
		case "DELETE_ICON_SWIMLANE_ITEMS": {
			const { swimlaneKey, stageId, iconId, cardId } = action.payload;
			const swimLaneDetails = state.current_map.cjmSwimLaneDetails;
			const swimLane = swimLaneDetails[swimlaneKey];
			const updatedSwimLane = {
				...swimLane,
				[stageId]: {
					...swimLane[stageId],
					items: swimLane[stageId].items.map((item) => {
						if (item.id === cardId) {
							return {
								...item,
								icons: item.icons.filter((icon) => icon !== iconId),
							};
						}
						return item;
					}),
				},
			};
			const updatedSwimLaneDetails = {
				...swimLaneDetails,
				[swimlaneKey]: updatedSwimLane,
			};
			return Helpers.updatedState(state, "cjmSwimLaneDetails", updatedSwimLaneDetails);
		}
		case "ADD_ICON_SYSTEM": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const system = currentMap.cjmStagesSystemData;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmStagesSystemData: {
						...system,
						[stageId]: {
							...system[stageId],
							[cardId]: {
								...system[stageId]?.[cardId],
								icons: [...(system[stageId]?.[cardId]?.icons ?? []), iconId],
							},
						},
					},
				},
			};
		}
		case "DELETE_ICON_SYSTEM": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const system = currentMap.cjmStagesSystemData;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmStagesSystemData: {
						...system,
						[stageId]: {
							...system[stageId],
							[cardId]: {
								...system[stageId]?.[cardId],
								icons: system[stageId]?.[cardId]?.icons?.filter((icon) => icon !== iconId) ?? [],
							},
						},
					},
				},
			};
		}
		case "ADD_ICON_DEPARTMENT": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const system = currentMap.cjmStagesDepartmentData;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmStagesDepartmentData: {
						...system,
						[stageId]: {
							...system[stageId],
							[cardId]: {
								...system[stageId]?.[cardId],
								icons: [...(system[stageId]?.[cardId]?.icons ?? []), iconId],
							},
						},
					},
				},
			};
		}
		case "DELETE_ICON_DEPARTMENT": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const system = currentMap.cjmStagesDepartmentData;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmStagesDepartmentData: {
						...system,
						[stageId]: {
							...system[stageId],
							[cardId]: {
								...system[stageId]?.[cardId],
								icons: system[stageId]?.[cardId]?.icons?.filter((icon) => icon !== iconId) ?? [],
							},
						},
					},
				},
			};
		}
		case "ADD_ICON_KPI": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const kpiData = currentMap.cjmStagesKpiData;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmStagesKpiData: {
						...kpiData,
						[stageId]: kpiData[stageId].map((kpi) => (kpi.id === cardId ? { ...kpi, icons: [...(kpi.icons ?? []), iconId] } : kpi)),
					},
				},
			};
		}
		case "DELETE_ICON_KPI": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const kpiData = currentMap.cjmStagesKpiData;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmStagesKpiData: {
						...kpiData,
						[stageId]: kpiData[stageId].map((kpi) => (kpi.id === cardId ? { ...kpi, icons: kpi.icons?.filter((icon) => icon !== iconId) ?? [] } : kpi)),
					},
				},
			};
		}
		case "ADD_ICON_OPPORTUNITY": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const opportunityData = currentMap.cjmOpportunities;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmOpportunities: {
						...opportunityData,
						[stageId]: opportunityData[stageId].map((opportunity) => (opportunity.id === cardId ? { ...opportunity, icons: [...(opportunity.icons ?? []), iconId] } : opportunity)),
					},
				},
			};
		}
		case "DELETE_ICON_OPPORTUNITY": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const opportunityData = currentMap.cjmOpportunities;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmOpportunities: {
						...opportunityData,
						[stageId]: opportunityData[stageId].map((opportunity) =>
							opportunity.id === cardId ? { ...opportunity, icons: opportunity.icons?.filter((icon) => icon !== iconId) ?? [] } : opportunity
						),
					},
				},
			};
		}
		case "ADD_ICON_SOLUTION": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const cjmSolutions = currentMap.cjmSolutions;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmSolutions: {
						...cjmSolutions,
						[stageId]: cjmSolutions[stageId].map((sol) => (sol.id === cardId ? { ...sol, icons: [...(sol.icons ?? []), iconId] } : sol)),
					},
				},
			};
		}
		case "DELETE_ICON_SOLUTION": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const cjmSolutions = currentMap.cjmSolutions;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmSolutions: {
						...cjmSolutions,
						[stageId]: cjmSolutions[stageId].map((sol) => (sol.id === cardId ? { ...sol, icons: sol.icons?.filter((icon) => icon !== iconId) ?? [] } : sol)),
					},
				},
			};
		}
		case "ADD_ICON_CXACTION": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const cxActionData = currentMap.cjmCxActions;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmCxActions: {
						...cxActionData,
						[stageId]: cxActionData[stageId].map((cxAction) => (cxAction.id === cardId ? { ...cxAction, icons: [...(cxAction.icons ?? []), iconId] } : cxAction)),
					},
				},
			};
		}
		case "DELETE_ICON_CXACTION": {
			const { stageId, iconId, cardId } = action.payload;
			const { current_map: currentMap } = state;
			const cxActionData = currentMap.cjmCxActions;

			return {
				...state,
				current_map: {
					...currentMap,
					cjmCxActions: {
						...cxActionData,
						[stageId]: cxActionData[stageId].map((cxAction) => (cxAction.id === cardId ? { ...cxAction, icons: cxAction.icons?.filter((icon) => icon !== iconId) ?? [] } : cxAction)),
					},
				},
			};
		}
		case "UPDATE_COMMENT_STAGE_ID": {
			const { swimlaneId, stageIdOrigin, stageIdDestination, attributeId } = action.payload;
			const { current_map: currentMap } = state;
			const origin = currentMap.cjmComments?.[swimlaneId]?.[stageIdOrigin]?.[attributeId];
			let destination = currentMap.cjmComments?.[swimlaneId]?.[stageIdDestination]?.[attributeId];

			if (origin && destination) {
				const cjmComments = Helpers.moveComments(currentMap.cjmComments, swimlaneId, stageIdOrigin, stageIdDestination, attributeId);

				return {
					...state,
					current_map: {
						...currentMap,
						cjmComments: cjmComments,
					},
				};
			} else if (origin && !destination) {
				destination = { ...origin };

				return {
					...state,
					current_map: {
						...currentMap,
						cjmComments: {
							...currentMap.cjmComments,
							[swimlaneId]: {
								...currentMap.cjmComments?.[swimlaneId],
								[stageIdDestination]: {
									...currentMap.cjmComments?.[swimlaneId]?.[stageIdDestination],
									[attributeId]: destination,
								},
								[stageIdOrigin]: {
									...currentMap.cjmComments?.[swimlaneId]?.[stageIdOrigin],
									[attributeId]: {
										comments: [],
										showComments: false,
									},
								},
							},
						},
					},
				};
			} else {

				return state;
			}
		}
		case success("GET_MAP_COMMENT"): {
			return Helpers.updatedState(state, "cjmComments", responseData);
		}
		case "UPDATE_ALL_STAGE_COLOR": {
			const { newValue } = action?.request?.data || {};
			const { current_map: currentMap } = state;
			const stageData = currentMap.cjmStagesData.map((stage) => {
				stage.colorCode = newValue;
				return stage;
			});
			return Helpers.updatedState(state, "cjmStagesData", stageData);
		}
		case "UPDATE_STAGE_COLOR": {
			const { newValue, stageId } = action?.request?.data || {};
			const { current_map: currentMap } = state;
			const stageData = currentMap.cjmStagesData.map((stage) => {
				if (stage.id === stageId) {
					stage.colorCode = newValue;
				}
				return stage;
			});
			return Helpers.updatedState(state, "cjmStagesData", stageData);
		}
		case "UPDATE_ALL_PHASE_COLOR": {
			const { newValue } = action?.request?.data || {};
			const { current_map: currentMap } = state;
			const phaseData = currentMap.cjmPhasesData.map((phase) => {
				phase.colorCode = newValue;

				return phase;
			});
			return Helpers.updatedState(state, "cjmPhasesData", phaseData);
		}
		case "UPDATE_PHASE_COLOR": {
			const { newValue, phaseId } = action?.request?.data || {};
			const { current_map: currentMap } = state;
			const PhasesData = currentMap.cjmPhasesData.map((stage) => {
				if (stage.id === phaseId) {
					stage.colorCode = newValue;
				}
				return stage;
			});
			return Helpers.updatedState(state, "cjmPhasesData", PhasesData);
		}
		case success("LINK_GROUPS"): {
			const { groupIds, recordType, recordIds } = action.meta.requestAction.request.data;
			const { current_map: currentMap } = state;

			if (recordType !== "cem_customerjourney") return state;
			const updatedGroups = [...appendListsAndRemoveDuplicates(currentMap.groups, groupIds)];

			return Helpers.updatedState(state, "groups", updatedGroups, null, null, recordIds);
		}
		case success("UNLINK_GROUP"): {
			const { groupId, recordType } = requestData;
			const { current_map: currentMap } = state;
			if (recordType !== "cem_customerjourney") return state;
			const updatedGroups = currentMap.groups.filter((id) => id !== groupId);

			return Helpers.updatedState(state, "groups", updatedGroups);
		}

		case success("LINK_TAGS"): {
			const { tagIds, recordType, recordIds } = requestData;
			const { current_map: currentMap } = state;
			if (recordType !== "cem_customerjourney") return state;
			const updatedTags = [...appendListsAndRemoveDuplicates(currentMap.tags, tagIds)];

			return Helpers.updatedState(state, "tags", updatedTags, null, null, recordIds);
		}
		case success("UNLINK_TAG"): {
			const { tagId, recordType } = requestData;
			const { current_map: currentMap } = state;
			if (recordType !== "cem_customerjourney") return state;
			const updatedTags = currentMap.tags.filter((id) => id !== tagId);

			return Helpers.updatedState(state, "tags", updatedTags);
		}
		case success("GET_PERSONAS_INTERACTION"): {
			const { current_map: currentMap } = state;
			const personaIdStr = action.meta.requestAction.params.personaIdStr;
			const cjmPersonasInteractions = currentMap?.cjmPersonasInteractions || {};
			cjmPersonasInteractions[personaIdStr] = { cjmData: responseData.cjmData[personaIdStr], cjmContactData: responseData.cjmContactData[personaIdStr] };
			return Helpers.updatedState(state, "cjmPersonasInteractions", cjmPersonasInteractions);
		}

		case success("UPDATE_CJM_STATUS"): {
			const { newValueId, cjmIdsStr } = requestData;
			const idsToUpdate = cjmIdsStr.split("|") || [];

			return Helpers.updatedState(state, "cxStatus", newValueId, null, null, idsToUpdate);
		}
		case success("CX_GOALS_UPSERT"): {
			const { current_map: currentMap } = state;
			const { stageId, id, measures } = requestData.CxGoals[0];
			const updatedCjmCxGoals = { ...currentMap.cjmCxGoals };
			const response = responseData.map((goal) => {
				return { ...goal, measureName: measures?.find((measure) => measure.id === goal.measureId)?.name };
			});

			if (stageId) {
				if (id === EMPTY_GUID) {
					updatedCjmCxGoals[stageId] = updatedCjmCxGoals[stageId]
						? [...updatedCjmCxGoals[stageId], ...response]
						: [...response];
				} else {
					// Update the specific goal
					updatedCjmCxGoals[stageId] = updatedCjmCxGoals[stageId]?.map(goal =>
						goal.id === id ? response[0] : goal
					) || [response[0]];
				}
			}
			return Helpers.updatedState(state, "cjmCxGoals", updatedCjmCxGoals);
		}
		case "ADD_ICON_SWIMLANE_CX_GOALS": {
			const { current_map: currentMap } = state;
			const { stageId, iconId, cardId } = action.payload;
			const cjmCxGoals = { ...currentMap?.cjmCxGoals };
			cjmCxGoals[stageId] = cjmCxGoals[stageId].map((goal) => {
				if (goal.id === cardId) {
					goal.icons = [...goal.icons, iconId];
				}
				return goal;
			});
			return Helpers.updatedState(state, "cjmCxGoals", cjmCxGoals);
		}
		case "DELETE_ICON_SWIMLANE_CX_GOALS": {
			const { current_map: currentMap } = state;
			const { stageId, iconId, cardId } = action.payload;
			const cjmCxGoals = { ...currentMap?.cjmCxGoals };
			cjmCxGoals[stageId] = cjmCxGoals[stageId].map((goal) => {
				if (goal.id === cardId) {
					goal.icons = goal.icons.filter((id) => id !== iconId);
				}
				return goal;
			});
			return Helpers.updatedState(state, "cjmCxGoals", cjmCxGoals);
		}
		case "UPDATE_LOCAL_CX_GOAL_ORDER": {
			const newcxGoalsOrder = state.current_map.cjmStagesData?.map((stage, index) => {
				return { [stage.id]: action.payload.newState[index] };
			});

			return {
				...state,
				current_map: {
					...state.current_map,
					cjmCxGoals: newcxGoalsOrder.reduce((acc, curr) => {
						return { ...acc, ...curr };
					}),
				},
			};
		}
		case success("DELETE_CX_GOAL"): {
			const { current_map: currentMap } = state;
			const id = requestData.id;
			const stageId = action.meta.requestAction.params.stageId;
			const cjmCxGoals = { ...currentMap?.cjmCxGoals };
			const previousData = cjmCxGoals[stageId] || [];
			cjmCxGoals[stageId] = previousData.filter((goal) => goal.id !== id);
			return Helpers.updatedState(state, "cjmCxGoals", cjmCxGoals);
		}
		case success("UPSERT_TIMELINE"): {
			const { current_map: currentMap } = state;
			const { cjmTimeLines } = currentMap;
			const { id, stageId } = requestData?.timeLine;
			const timeLines = JSON.parse(JSON.stringify(cjmTimeLines));
			timeLines[stageId] = [responseData];
			return Helpers.updatedState(state, "cjmTimeLines", timeLines);
		}
		case success("DELETE_TIMELINE"): {
			const { current_map: currentMap } = state;
			const { cjmTimeLines } = currentMap;
			const { stageId } = action.meta.requestAction?.params;
			const timeLines = JSON.parse(JSON.stringify(cjmTimeLines));

			delete timeLines[stageId];
			return Helpers.updatedState(state, "cjmTimeLines", timeLines);
		}
		case "ADD_ICON_TIMELINE": {
			const { current_map: currentMap } = state;
			const { cjmTimeLines } = currentMap;
			const { cardId, iconId, stageId } = action.payload;
			const timeLines = JSON.parse(JSON.stringify(cjmTimeLines));
			timeLines[stageId] = timeLines[stageId]?.map((timeline) => {
				if (timeline.id === cardId) {
					timeline.icons = [...timeline?.icons, iconId];
				}
				return timeline;
			});
			return Helpers.updatedState(state, "cjmTimeLines", timeLines);
		}
		case "DELETE_ICON_TIMELINE": {
			const { current_map: currentMap } = state;
			const { cjmTimeLines } = currentMap;
			const { cardId, iconId, stageId } = action.payload;
			const timeLines = JSON.parse(JSON.stringify(cjmTimeLines));
			timeLines[stageId] = timeLines[stageId].map((timeline) => {
				if (timeline.id === cardId) {
					timeline.icons = timeline.icons.filter((id) => id !== iconId);
				}
				return timeline;
			});
			return Helpers.updatedState(state, "cjmTimeLines", timeLines);
		}
		case success("UPDATE_MAP_VERSION"):
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === requestData.cjmId) {
						return {
							...map,
							nameMapVersion: decode(requestData.name),
							descriptionMapVersion: decode(requestData.description),
						};
					}
					return map;
				}),
			};
		case "UPSERT_CUSTOMER_FEEDBACK":
		case "UPLOAD_CUSTOMER_FEEDBACK": {
			const { current_map: currentMap } = state;
			const { cjmCustomerFeedback } = currentMap;
			const newCustomerFeedbacks = action.request.data?.customerFeedback;
			const { id, personaId, stageId } = action.request.data?.customerFeedback;

			const customerFeedbacks = JSON.parse(JSON.stringify(cjmCustomerFeedback));
			if (customerFeedbacks[personaId][stageId]?.find((item) => item.id === id)) {
				customerFeedbacks[personaId][stageId] = customerFeedbacks[personaId][stageId]?.map((customerFeedback) => {
					if (customerFeedback.id === id) {
						customerFeedback = newCustomerFeedbacks;
					}
					return customerFeedback;
				});
			}
			else {
				customerFeedbacks[personaId][stageId] = [...(customerFeedbacks?.[personaId]?.[stageId] || []), newCustomerFeedbacks];
			}
			return Helpers.updatedState(state, "cjmCustomerFeedback", customerFeedbacks);
		}

		case "DELETE_CUSTOMER_FEEDBACK": {
			const { current_map: currentMap } = state;
			const { cjmCustomerFeedback } = currentMap;
			const { personaId, customerFeedbackId } = action.request.data;
			const { stageId } = action?.params;

			const customerFeedbacks = JSON.parse(JSON.stringify(cjmCustomerFeedback));

			customerFeedbacks[personaId][stageId] = customerFeedbacks[personaId][stageId]?.filter(customerFeedback => customerFeedback.id !== customerFeedbackId)
			return Helpers.updatedState(state, "cjmCustomerFeedback", customerFeedbacks);
		}
		case "ADD_ICON_CUSTOMER_FEEDBACK": {
			const { current_map: currentMap } = state;
			const { cjmCustomerFeedback } = currentMap;
			const { cardId, iconId, stageId, personaId } = action.payload;
			const customerFeedbacks = JSON.parse(JSON.stringify(cjmCustomerFeedback));

			customerFeedbacks[personaId][stageId] = customerFeedbacks[personaId][stageId]?.map((customerFeedback) => {
				if (customerFeedback.id === cardId) {
					customerFeedback.icons = [...customerFeedback?.icons, iconId];
				}
				return customerFeedback;
			});

			return Helpers.updatedState(state, "cjmCustomerFeedback", customerFeedbacks);
		}
		case "DELETE_ICON_CUSTOMER_FEEDBACK": {
			const { current_map: currentMap } = state;
			const { cjmCustomerFeedback } = currentMap;
			const { cardId, iconId, stageId, personaId } = action.payload;
			const customerFeedbacks = JSON.parse(JSON.stringify(cjmCustomerFeedback));
			customerFeedbacks[personaId][stageId] = customerFeedbacks[personaId][stageId].map((customerFeedback) => {
				if (customerFeedback.id === cardId) {
					customerFeedback.icons = customerFeedback.icons.filter((id) => id !== iconId);
				}
				return customerFeedback;
			});
			return Helpers.updatedState(state, "cjmCustomerFeedback", customerFeedbacks);
		}
		case "UPSERT_CUSTOMER_FEEDBACK_DATA": {
			const { current_map: currentMap } = state;
			const { stageId, personaId, customerFeedbackData, customerFeedbackId } = action.request.data;
			const { cjmCustomerFeedback } = currentMap;
			const customerFeedbacks = JSON.parse(JSON.stringify(cjmCustomerFeedback));

			if (customerFeedbackId) {
				customerFeedbacks[personaId][stageId] = customerFeedbacks[personaId][stageId].map((customerFeedback) => {
					if (customerFeedback.id === customerFeedbackId) {
						if (customerFeedback?.customerFeedbackDatas?.find(contact => contact.id === customerFeedbackData?.id)) {
							customerFeedback.customerFeedbackDatas = customerFeedback.customerFeedbackDatas?.map((customerFeedbackSummary) => {
								if (customerFeedbackSummary?.id === customerFeedbackData?.id)
									customerFeedbackSummary = { ...customerFeedbackData, sourcesOfData: decode(customerFeedbackData?.sourcesOfData), description: decode(customerFeedbackData?.description) }
								return customerFeedbackSummary;
							});
						}
						else {
							customerFeedback.customerFeedbackDatas = [customerFeedbackData, ...customerFeedback.customerFeedbackDatas]
						}
					}
					return customerFeedback;
				});
			}
			return Helpers.updatedState(state, "cjmCustomerFeedback", customerFeedbacks);
		}
		case "DELETE_CUSTOMER_FEEDBACK_DATA": {
			const { current_map: currentMap } = state;
			const { cjmCustomerFeedback } = currentMap;

			const { stageId, personaId, customerFeedbackDataId } = action.request.data;
			const { customerFeedbackId } = action.request?.params || action?.params;
			const customerFeedbacks = JSON.parse(JSON.stringify(cjmCustomerFeedback));

			if (customerFeedbackId) {
				customerFeedbacks[personaId][stageId] = customerFeedbacks[personaId][stageId].map((customerFeedback) => {
					if (customerFeedback.id === customerFeedbackId) {
						customerFeedback.customerFeedbackDatas = customerFeedback?.customerFeedbackDatas?.filter(contact => contact.id !== customerFeedbackDataId) || []
					}
					return customerFeedback;
				});
			}
			return Helpers.updatedState(state, "cjmCustomerFeedback", customerFeedbacks);
		}
		case "ORDER_INSIGHT_LOCALLY": {
			const newState = action.payload.newState;
			const sourceIndex = action.payload.source;
			const destinationIndex = action.payload.destination;
			const swimlaneId = action.payload.swimlaneId;
			let cjmSwimLaneDetails = JSON.parse(JSON.stringify({ ...state.current_map.cjmSwimLaneDetails[swimlaneId] }));
			const stageId_source = state.current_map.cjmStagesData[sourceIndex]?.id;
			const stageId_destination = state.current_map.cjmStagesData[destinationIndex]?.id;

			if (stageId_source) {
				cjmSwimLaneDetails = {
					...cjmSwimLaneDetails,
					[stageId_source]: {
						id: cjmSwimLaneDetails[stageId_source]?.id || uuidv4(),
						swimLaneId: swimlaneId,
						stageId: stageId_source,
						items: newState[sourceIndex],
					},
				};
			}
			if (stageId_destination) {
				cjmSwimLaneDetails = {
					...cjmSwimLaneDetails,
					[stageId_destination]: {
						id: cjmSwimLaneDetails[stageId_destination]?.id || uuidv4(),
						swimLaneId: swimlaneId,
						stageId: stageId_destination,
						items: newState[destinationIndex],
					},
				};
			}
			cjmSwimLaneDetails = {
				...state.current_map.cjmSwimLaneDetails,
				[swimlaneId]: cjmSwimLaneDetails,
			}
			return Helpers.updateStateMuliCJM(state, { cjmSwimLaneDetails });
		}
		case 'UNLINK_INSIGHT_ON_MAP': {
			const { swimLaneItemId, customSwimLaneId, stageId } = action.request.data;
			const cjmSwimLaneDetails = { ...state.current_map.cjmSwimLaneDetails };
			const swimLane = cjmSwimLaneDetails[customSwimLaneId];
			const stage = swimLane[stageId];
			stage.items = stage.items.remove(swimLaneItemId)
			return Helpers.updateStateMuliCJM(state, cjmSwimLaneDetails);
		}
		case 'LINK_INSIGHT_ON_MAP': {
			const { swimLaneItemIds, insightIds, customSwimLaneId, stageId } = action.request.data;
			const cjmSwimLaneDetails = { ...state.current_map.cjmSwimLaneDetails };
			if (!cjmSwimLaneDetails[customSwimLaneId])
				cjmSwimLaneDetails[customSwimLaneId] = {};

			const swimLane = cjmSwimLaneDetails[customSwimLaneId];
			if (!swimLane[stageId]) {
				swimLane[stageId] = { id: uuidv4(), swimLaneId: customSwimLaneId, stageId: stageId, items: [] }
			}
			const stage = swimLane?.[stageId];
			const newItems = swimLaneItemIds.map((id, i) => {
				return { id: id, insightId: insightIds[i], icons: [], name: '', description: '' }
			})
			stage.items = stage.items.add(...newItems)
			return Helpers.updateStateMuliCJM(state, { cjmSwimLaneDetails });
		}
		case "UPDATE_GLOBAL_SWIMLANE_ORDER": {
			const { stageId, prevCustomSwimLaneId, newCustomSwimLaneId, customSwimLaneItemId } = action.request.data
			const updatedCjmSwimLaneDetails = { ...state.current_map?.cjmSwimLaneDetails }
			const itemToMove = updatedCjmSwimLaneDetails[prevCustomSwimLaneId][stageId].items.find(item => item.id === customSwimLaneItemId)

			// remove the item from the prevCustomSwimLaneId
			updatedCjmSwimLaneDetails[prevCustomSwimLaneId][stageId].items = updatedCjmSwimLaneDetails[prevCustomSwimLaneId][stageId].items.filter(item => item.id !== customSwimLaneItemId)
			// add the item from the newCustomSwimLaneId
			if (!updatedCjmSwimLaneDetails[newCustomSwimLaneId])
				updatedCjmSwimLaneDetails[newCustomSwimLaneId] = {};

			if (!updatedCjmSwimLaneDetails[newCustomSwimLaneId][stageId]) {
				updatedCjmSwimLaneDetails[newCustomSwimLaneId][stageId] = { id: uuidv4(), swimLaneId: newCustomSwimLaneId, stageId: stageId, items: [] }
			}

			updatedCjmSwimLaneDetails[newCustomSwimLaneId][stageId].items = [...updatedCjmSwimLaneDetails[newCustomSwimLaneId][stageId].items || [], itemToMove]

			return Helpers.updatedState(state, "cjmSwimLaneDetails", updatedCjmSwimLaneDetails, null, null);
		}
		case success("ASSISTANT_CJM"): {
			const { keys } = action.meta.requestAction.params;
			const extractedKeys = keys?.split("|");
			const swimLaneKey = extractedKeys?.[0];
			const stageId = extractedKeys?.[1];

			if (!swimLaneKey) return state;

			const response = JSON.parse(JSON.stringify({ ...state.cjmAssistant }));
			if (hasAttribute(response, swimLaneKey)) {
				response[swimLaneKey] = {};
			}

			response[swimLaneKey] = { ...response[swimLaneKey], [stageId]: responseData };

			return Helpers.updateStateMuliCJM(state, {}, { cjmAssistant: response });
		}
		case "RESET_ASSISTANT_CJM": {
			const keys = action.payload.split("|")
			const response = JSON.parse(JSON.stringify({ ...state.cjmAssistant }));

			delete response[keys[0]][keys[1]];
			return Helpers.updateStateMuliCJM(state, {}, { cjmAssistant: response });

		}
		case "SET_CONNECTOR": {
			const id = action.payload.connection.connectionId
			axios.interceptors.request.use((config) => {
				config.headers.ConnectionKey = id;
				return config;
			});


			return {
				...state,
				connection: action.payload.connection,
			};
		}
		case "CONNECTION_RECONNECTED": {
			const id = action.payload.connectionId
			axios.interceptors.request.use((config) => {
				config.headers.ConnectionKey = id;
				return config;
			});


			return {
				...state,
				connection: { ...state.connection, connectionId: action.payload.connectionId }
			};
		}
		case "UPDATE_CJM_NAME": {
			const { newValue, cjmIdStr } = action?.payload;
			return {
				...state,
				all_maps: state.all_maps?.map((map) => {
					if (map.cjmId === cjmIdStr) {
						return {
							...map,
							name: decode(newValue),
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;
				}),
				current_map: {
					...state.current_map,
					cjmName: decode(newValue),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "ONLINE_USER_REGISTRY":
		case "ONLINE_USER_REGISTRY_LOGOUT":
		case "ONLINE_USER_REGISTRY_LOGOUT_CLOSE":

			{
				const { userIds } = action?.payload;
				return {
					...state,
					current_map: {
						...state.current_map,
						usersLogin: userIds
					},
				};
			}

		case "GET_STAGES_PHASES":
		case "GET_CJM_CXGOALS":
		case "GET_CJM_TIME_LINES":
		case "GET_CJM_OPPORTUNITIES":
		case "GET_CJM_CXACTIONS":
		case "GET_CJM_SOLUTIONS":
		case "GET_CJM_HEADER_FIELDS":
		case "GET_CJM_SWIM_LANE_DETAILS":
		case "GET_CJM_CUSTOMER_FEEDBACK":
		case "GET_CHANNELS":
		case "GET_COMMENTS":
		case "GET_CJM_STAGES_CUSTOME_REXPERIENCE_DATA":
		case "GET_PERSONA_DATA":
		case "GET_CJM_DATA":
		case "GET_CJM_SWIM_LANE":
		case "GET_CONTACT_DATA":
		case "GET_PERSONA_INTERACTION_BY_STAGE":
			return Helpers.incrementPendingRequests(state)
		case success("GET_STAGES_PHASES"): {
			const data = responseData;
			// Exit early if data is not valid
			if (!data) return state;

			// Phases and stages extraction and sorting
			const phases = Helpers.extractAndSortData(data.cjmPhasesData);
			const stages = Helpers.extractAndSortData(data.cjmStagesData);

			// Data updates
			data.cjmPhasesData = phases;
			data.cjmStagesData = phases.map((phase) => stages.filter((stage) => stage.phase.id == phase.id)).flat();


			return {
				...state,
				current_map: {
					...state.current_map,
					cjmPhasesData: data.cjmPhasesData,
					cjmStagesData: data.cjmStagesData?.map((stage) => {
						if (requestParams?.type === "width" && requestParams?.stageId === stage.id) {
							stage.render = Math.random();
						}
						return stage;
					}),

					cjmData: requestParams?.type === "order" ? data.cjmStagesData?.map((stagemap, index) => {
						return state.current_map.cjmData
							?.map((cjm) => cjm?.filter((int) => int.stageId === stagemap.id))
							?.filter((int) => int.length > 0)
							.flat();
					}) : state.current_map.cjmData,

				},
				pendingRequests: state.pendingRequests - 1
			};
		}
		case success("GET_CJM_CXGOALS"):
		case success("GET_CJM_TIME_LINES"):
		case success("GET_CJM_OPPORTUNITIES"):
		case success("GET_CJM_CXACTIONS"):
		case success("GET_CJM_SOLUTIONS"):
		case success("GET_CJM_HEADER_FIELDS"):
		case success("GET_CJM_SWIM_LANE_DETAILS"):
		case success("GET_CJM_CUSTOMER_FEEDBACK"):
		case success("GET_CHANNELS"):
		case success("GET_COMMENTS"): {
			const data = responseData;
			// Exit early if data is not valid
			if (!data) return state;


			return {
				...state,
				current_map: {
					...state.current_map,
					...data
				},
				pendingRequests: state.pendingRequests - 1,
				renderCollaborative: action.meta.requestAction.params?.collab ? `${action.type}_${Math.random()}` : null
			};
		}
		case success('GET_CJM_STAGES_CUSTOME_REXPERIENCE_DATA'): {
			const data = responseData;
			// Exit early if data is not valid
			if (!data) return state;


			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesCustomerExperienceData: { ...data.cjmStagesCustomerExperienceData, render: Math.random() }
				},
				pendingRequests: state.pendingRequests - 1

			};
		}
		case success("GET_PERSONA_DATA"): {
			const data = responseData;
			// Exit early if data is not valid
			if (!data) return state;

			data.cjmPersonasData = Object.entries(data.cjmPersonasData).map(([, persona]) => persona);
			return {
				...state,
				current_map: {
					...state.current_map,
					...data
				},
				pendingRequests: state.pendingRequests - 1

			};
		}
		case success("GET_CJM_DATA"): {
			const { personaId } = action.meta.requestAction.params;
			const data = responseData;
			// Exit early if data is not valid
			if (!data) return state;
			const cjmData = { ...data.cjmData }
			const cjmPersonasInteractions = Helpers.formatPersonaInteractions(cjmData, personaId);
			const cjmDataUpdated = Helpers.formatCjmData(cjmData, state.current_map.cjmStagesData, personaId);


			return {
				...state,
				current_map: {
					...state.current_map,
					cjmData: cjmDataUpdated,
					cjmPersonasInteractions: { ...state.cjmPersonasInteractions, ...cjmPersonasInteractions }
				},
				pendingRequests: state.pendingRequests - 1

			};
		}
		case success("GET_CONTACT_DATA"): {
			const cjmContactData = responseData?.cjmContactData || {};
			const { personaIdStr } = action.meta.requestAction.params;
			const stages = state.current_map.cjmStagesData;
			let interactionCountByStage = Helpers.calculteRoutes(cjmContactData, personaIdStr);
			const cjmData = Helpers.applyRoutesToCJMData(state.current_map.cjmData, interactionCountByStage, stages);
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmContactData: cjmContactData,
					cjmData: cjmData,
				},
				pendingRequests: state.pendingRequests - 1
			};
		}
		case success("GET_CJM_SWIM_LANE"):
			{
				const data = responseData;
				// Exit early if data is not valid
				if (!data) return state;

				data.cjmSwimLanes = Helpers.extractAndSortData(data.cjmSwimLanes)?.map((swimLane) => {
					swimLane.sentimentsConfig = JSON.parse(swimLane.sentimentsConfig);
					if (requestParams.exportingPdf) {
						swimLane.expandedCard = true;
					}
					return swimLane;
				});
				const StageIndex = data.cjmSwimLanes.findIndex((stage) => stage.id === "StageHeaders");
				data.cjmSwimLanes = reorder(data.cjmSwimLanes, StageIndex, data.cjmSwimLanes.length - 1);
				const PhaseIndex = data.cjmSwimLanes.findIndex((stage) => stage.id === "PhaseHeaders");
				data.cjmSwimLanes = reorder(data.cjmSwimLanes, PhaseIndex, data.cjmSwimLanes.length - 1);

				return {
					...state,
					current_map: {
						...state.current_map,
						...data
					},
					pendingRequests: state.pendingRequests - 1

				};
			}
		case "UPLOAD_IMG":
			{
				const { customSwimLaneId, stageId } = action?.payload;
				const collaborative_update1 = state.current_map?.collaborative_update || {};
				return Helpers.updatedState(state, "collaborative_update", {
					...collaborative_update1, [`${customSwimLaneId}_${stageId}`]: stageId + '_' + Math.random()
				});

			}
		case "UPLOAD_STAGE_IMG": {
			const { stageId } = action?.payload;

			const updatedcjmStagesData = state.current_map?.cjmStagesData?.map((stage) => {
				if (stage.id === stageId) {
					stage.random = Math.random();
				}

				return stage
			});

			return Helpers.updatedState(state, "cjmStagesData", updatedcjmStagesData);

		}
		case "SET_CREATION_CJM_PARAMS":
			return { ...state, creationCjmParams: action.payload };
		case "SET_AI_GENERATION":
			return { ...state, aiGeneration: action.payload };
		case error("GET_STAGES_PHASES"):
		case error("GET_CJM_CXGOALS"):
		case error("GET_CJM_TIME_LINES"):
		case error("GET_CJM_OPPORTUNITIES"):
		case error("GET_CJM_CXACTIONS"):
		case error("GET_CJM_SOLUTIONS"):
		case error("GET_CJM_HEADER_FIELDS"):
		case error("GET_CJM_SWIM_LANE_DETAILS"):
		case error("GET_CJM_CUSTOMER_FEEDBACK"):
		case error("GET_CHANNELS"):
		case error("GET_COMMENTS"):
		case error('GET_CJM_STAGES_CUSTOME_REXPERIENCE_DATA'):
		case error("GET_PERSONA_DATA"):
		case error("GET_CJM_DATA"):
		case error("GET_CJM_SWIM_LANE"):
		case error("GET_PERSONA_INTERACTION_BY_STAGE"):
			{
				return {
					...state,
					pendingRequests: state.pendingRequests - 1

				};
			}
		case success("GET_PERSONA_INTERACTION_BY_STAGE"): {
			const { current_map: currentMap } = state;
			const { personaId, stageId, cjmId } = action.meta.requestAction?.params;
			const stageIndex = currentMap.cjmStagesData.findIndex((stage) => stage.id === stageId);
			let personaInteraction = [];

			if (responseData) {
				const allTouchpoints = Object.entries(responseData).flatMap(([key, value]) => (
					value.map(personaInteraction => ({ ...personaInteraction, touchPointId: key, stageId: stageId }))));
				personaInteraction = allTouchpoints
			}


			const cjmData = currentMap?.cjmData || Array.from({ length: currentMap?.cjmStagesData?.length }, () => []);
			const updatedCjmData = cjmData?.map((data, index) => {
				if (index === stageIndex) {
					return personaInteraction;
				}
				return data;
			});
			const cjmPersonasInteractions = currentMap.cjmPersonasInteractions || {};
			if (!cjmPersonasInteractions[personaId])
				cjmPersonasInteractions[personaId] = {}

			cjmPersonasInteractions[personaId][stageId] = personaInteraction


			return Helpers.updateStateMuliCJM(state, { cjmData: updatedCjmData, cjmPersonasInteractions: cjmPersonasInteractions }, { pendingRequests: state.pendingRequests - 1 });
		}
		case "SET_IS_EXPORTING": {
			const { isExporting } = action.payload;
			return Helpers.updatedState(state, "isExporting", isExporting);
		}
		case "UPDATE_CJM_NAME_v1": {
			const { newValue, cjmIdStr } = action?.request.data;
			const value = decode(newValue);
			return Helpers.updateStateMuliCJM(state, { cjmName: value }, {
				all_maps: state.all_maps.map((map) => {
					if (map.cjmId === cjmIdStr) {
						return {
							...map,
							name: value,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return map;

				})
			});
		}
		case success("UPDATE_STAGE_GLOBAL_INSIGHTS"):
			return {
				...state,
				current_map: {
					...state.current_map,
					cjmStagesData: state.current_map.cjmStagesData?.map((stage) => {
						if (stage.id === requestData.stageIdStr) {
							stage.conclusions = decode(requestData.newValue);
						}
						return stage;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		default:
			return state;
	}
};
export default CJMReducer;
