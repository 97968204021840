import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";
import { WorkspaceTypehead } from "features/workspace/components/others/workspace-typehead";
import { currentWorkspaceId } from "index";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAutoFocus } from "shared/hooks/index";
import { GenericButton, Input } from "shared/ui-basics/index";
import styled from "styled-components";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

const DuplicateModal = memo(({ isOpen, onClose, title, inputTitle, inputPlaceholder, inputValue, actionBtnLabel = "ASPX_83", action }) => {
	const { t } = useTranslation();
	const ref = useAutoFocus();

	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);
	const currentWorkspace = allActivesWorkspaces.find((ws) => ws.id === currentWorkspaceId);
	const [name, setName] = useState(inputValue?.truncate(90) || "");
	const [selectedWorkspaces, setSelectedWorkspaces] = useState([currentWorkspace]);
	const userInfo = useSelector((state) => state?.auth?.userInfo);
	const isConfigAllowed = ["Enterprise"].includes(userInfo?.UserSubscriptionPlanName);

	const duplicate = () => {
        action({ name, selectedWorkspaces });
		onClose();
	};

	const handleChange = (e) => {
		e.preventDefault();
		if (e.charCode === 13) {
			return;
		}
		setName(e.target.value.truncate(100));
	};

	const onTypeheadChange = (selected) => {
		setSelectedWorkspaces([...selected]);
	};

	return (
		<>
			<Modal fade={false} isOpen={isOpen} centered={true} style={customStyles} backdrop="static" toggle={onClose}>
				<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
				<ModalBody>
					<FormGroup className="mb-4">
						<Label for="name" className="m-2">
							{t(inputTitle)}
						</Label>
						<Input variant="default" type="text" name="name" onChange={handleChange} placeholder={t(inputPlaceholder)} value={name} innerRef={ref} autoComplete="off" />
					</FormGroup>
					<FormGroup>
						<Label for="workspaceId" className="m-2">
							{t("WORKSPACES")}
						</Label>
						<DropdownContainer>
							<WorkspaceTypehead innerRef={ref} selected={selectedWorkspaces} onChange={onTypeheadChange} nameWidth={400} disabled={!isConfigAllowed} />
						</DropdownContainer>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<GenericButton variant="primary" onClick={duplicate} disabled={name.length === 0 || selectedWorkspaces?.length === 0}>
						{t(actionBtnLabel)}
					</GenericButton>
				</ModalFooter>
			</Modal>
		</>
	);
});

DuplicateModal.displayName = "DuplicateModal";
export { DuplicateModal };

const DropdownContainer = styled.div`
	height: 150px;
`;
