import { GenericButton } from "shared/ui-basics/buttons";
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import DisplayStatus from "shared/cx-components/DisplayStatus";
import { Badge, BadgeWrapper, RemoveButton } from "./PicklistCard_style";

export const OpportunityItemCard = ({ index, entity, unlink, openRoute, disable ,length}) => {
	return (
		<Badge key={entity?.id} id={entity?.id}>
			<BadgeWrapper index={index} length={length} >
				<div className="ellipsis obj-name px-2" onClick={() => openRoute(entity?.id, "/edit-opportunity")}>
					{entity?.subject?.defaultPlaceholder("OPPORTUNITY_NAME")}
				</div>
				<FlexBetweenAlignCenter className="px-2" minWidth='170px'>
					<DisplayStatus object={entity} type="status" name={'opportunity'} disable={true} style={{ border:"false" }} />
					{!disable && (
						<RemoveButton>
							<GenericButton variant="light-link" id={entity?.id} icon="X" iconClassName="p-0" onClick={unlink} />
						</RemoveButton>
					)}
				</FlexBetweenAlignCenter>
			</BadgeWrapper>

		</Badge>
	);
};

