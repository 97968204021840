import { updateLockPersonaTemplate, updateStatusPersonaTemplate } from '@redux/index';
import { StatusCode, TemplateStatus } from "@utils/optionsSets/OptionSets";
import { updateLockedCJM, updateTemplateCJM } from 'features/index';
import GenericSwitch from 'shared/ui-basics/buttons';

export default function Template({ options, templateStatus, context }) {

    const status = templateStatus ? options?.templateStatus === TemplateStatus.published : options?.isLocked
    const inPersonas = context.includes("personas")
    const updateTemplate = inPersonas ?(modifiedPersona,modifiedAttribNames) =>  updateStatusPersonaTemplate({modifiedPersona,modifiedAttribNames}) : updateTemplateCJM
    const updateLocked = inPersonas ?(modifiedPersona,modifiedAttribNames) =>  updateLockPersonaTemplate({modifiedPersona,modifiedAttribNames}) : updateLockedCJM

    const update = () => {
        if (inPersonas) {
            if (templateStatus) {
                const newStatus = options?.templateStatus === TemplateStatus.published ? TemplateStatus.draft : TemplateStatus.published
                updateTemplate({ personaId: options?.personaId, templateStatus: newStatus }, ["templateStatus"])
            } else {
                updateLocked({ personaId: options?.personaId, isLocked: !options?.isLocked }, ["isLocked"])
            }
        } else {
            if (templateStatus) {
                const newStatus = options?.templateStatus === TemplateStatus.published ? false : true
                updateTemplate({ id: options?.cjmId, isChecked: newStatus })
            } else {
                updateLocked({ id: options?.cjmId, isChecked: !options?.isLocked })
            }

        }
    }

    return (
        <GenericSwitch
            isChecked={status}
            handleToggle={update}
            isdisabled={options?.statusCode!==StatusCode.Active}
        />
    )
}

