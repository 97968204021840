import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';



const SelectButton = ({ options, selected, onChange }) => {
    const { t } = useTranslation()
    const isSelected = (option) => selected.includes(`${option?.key}`);

    const Button = styled.button`
            border-radius: 18px;
            background-color: ${props => isSelected(props.option) ? '#333333' : '#FFFFFF'};
            color: ${props => isSelected(props.option) ? '#EFEFEF' : '#333333'};
            border: 1px solid #333333;
            padding: 8px;
            width: fit-content;
            cursor: pointer;
            height: 38px;
            font-size: 14px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        `;

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {options.map((option) => (
                <Button
                    key={`${option?.key}`}
                    option={option}
                    onClick={() => onChange(`${option?.key}`)}
                >
                    {t(option.label)}
                </Button>
            ))}
        </div>
    );
};


export default SelectButton;