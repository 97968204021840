import { SubscriptionPlan } from "@utils/optionsSets/OptionSets";
import { isFullUsersLicenseAvailable } from "@utils/helpers";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";

export default function UserSubscriptionPlanSelector({ user, handleChange, isDisabled }) {
	const { t } = useTranslation();

	const users = useSelector((state) => state.settings.users);
	const contracts = useSelector((state) => state.settings?.subscriptionDetails?.subscriptionContracts)?.sort((a, b) => b.subscriptionPlanValue - a.subscriptionPlanValue);

	const displayDropDownTitle = (user) => {
		if (user?.subscriptionPlan === SubscriptionPlan?.Standard) return t("STANDARD") + "    ";
		else if (user?.subscriptionPlan === SubscriptionPlan?.Expert) return t("EXPERT") + "    ";
		else if (user?.subscriptionPlan === SubscriptionPlan?.Enterprise) return t("ENTERPRISE") + "    ";
		return t("SELECT_PLAN_TYPE");
	};

	const isSeatLeftOnSubscriptionPlan = (subscriptionPlanValue) => {
		if (subscriptionPlanValue === SubscriptionPlan.Enterprise || subscriptionPlanValue === SubscriptionPlan.Expert) return true;
		else if (isFullUsersLicenseAvailable(users, contracts, subscriptionPlanValue).answer) return true;
		return false;
	};

	return (
		<StyledDropdown
			variant="secondary"
			title={displayDropDownTitle(user)}
			onSelect={(planTypeCode) => handleChange({ attribute: "subscriptionPlan", value: Number(planTypeCode) })}
			disabled={isDisabled}>
			{contracts?.map((contract, index) => {
				if (isSeatLeftOnSubscriptionPlan(contract.subscriptionPlanValue)) {
					return (
						<StyledDropdownItem key={index} eventKey={contract.subscriptionPlanValue}>
							<div className="p-2">{contract.subscriptionPlan}</div>
						</StyledDropdownItem>
					);
				}
			})}
		</StyledDropdown>
	);
}

const StyledDropdown = styled(DropdownButton)`
	height: 36px !important;
	border: 1px solid #c4c4c4;
	border-radius: 0.25rem;

	.btn {
		width: 100%;
		text-align: start;
	}

	.dropdown-menu {
		width: 298px !important;
		padding: 0;
	}
	.dropdown-toggle::after {
		display: none;
	}
	:hover {
		.dropdown-toggle::after {
			display: inline-block;
		}
	}

	${(props) =>
		props.disabled &&
		css`
			.dropdown-toggle::after {
				display: none !important;
			}
			:hover {
				border-bottom: 1px solid #d3d3d3 !important;
				cursor: not-allowed;
			}
			background-color: #e9ecef !important;
		`}
`;

const StyledDropdownItem = styled(Dropdown.Item)`
	border-bottom: 1px solid #e9ecef !important;
	padding-bottom: 5px;
	font-size: 14px;
`;
