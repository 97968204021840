import { PickListMultipleTemplate, PickListSingleFilterTemplate, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { selectGroup, selectPriorities, selectQueryView, selectSelectedViewId, selectStatus, selectTag } from "@redux/memoizedSelectors";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Groups from "../../columns-components/Groups";
import { DatePicker, Description, Map, Owner, Rating, Stage, Status, Subject, TablePriority, Truth, Win } from "../../columns-components/ReusableListComponents";
import Tags from "../../columns-components/Tags";

export const GetOpportunityColumns = (props) => {
    const context = props.context.split(".")[1];
    const { dataKey } = props?.contextData;
    const priorityTypes = useSelector(selectPriorities({entity:'opportunity'}));
    const statusTypes = useSelector(selectStatus({entity:'opportunity'}));
    const group = useSelector(selectGroup);
    const tag = useSelector(selectTag);

    const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.Opportunity, context));
    const queryView = useSelector(state => selectQueryView(state, EntityList.Opportunity))?.getById(selectedViewId)?.columnSetting;

	const list = props?.contextData?.listData

	const journeyMapActionsFilters = list
	?.filter((obj, index) => list?.findIndex((item) => item?.mapId === obj?.mapId) === index)
	?.FormatFilterArray("mapId", "mapName");
	const isSample = context === 'sampleData'

    return {
        subject: {
            field: "subject",
            header: t("OPPORTUNITY_NAME"),
            body: (opp) => Subject(opp, { route: props?.contextData?.route, allowClick: props?.contextData?.clickToOpen }),
            sortable: true,
            filterField: "subject",
            filter: true,
            showFilterOperator: false,
            style: checkStyle(queryView, "subject",isSample ? 'xxl': "xl"),
            className: "py-0",
        },
        mapId: {
            field: "mapId",
            filterField: "mapId",
            header: t("JOURNEY_MAP"),
            sortable: true,
            showAddButton: false,
            showFilterOperator: false,
            body: (opp) => Map(opp, { route: props?.contextData?.route, allowClick: props?.contextData?.clickToOpen }),
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "mapId", "l", "160px"),
            filterElement: (options) => PickListMultipleTemplate(options, journeyMapActionsFilters),
        },
        priorityId: {
            field: "priorityId",
            header: t("PRIORITY"),
            sortable: true,
            showFilterMatchModes: false,
            filter: true,
            filterField: "priorityId",
            style: checkStyle(queryView, "priorityId", "m", "150px"),
            body: (opp) => TablePriority(opp, dataKey, "opportunity", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListMultipleTemplate(options, priorityTypes),
        },
        stageName: {
            field: "stageName",
            header: t("ASPX_172"),
            sortable: true,
            body: (opp) => Stage(opp, { route: props?.contextData?.route, allowClick: props?.contextData?.clickToOpen }),
            style: checkStyle(queryView, "stageName", "m"),
            filterField: "stageName",
            filter: true,
            showFilterOperator: false,
        },
        ownerId: {
            field: "ownerId",
            header: t("CS_35"),
            body: Owner,
            style: checkStyle(queryView, "ownerId", "xs"),
        },
        customerValue: {
            field: "customerValue",
            filterField: "customerValue",
            header: t("CUSTOMER_VALUE"),
            sortable: true,
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "customerValue", "s"),
            body: (opp) => Rating(opp, "customerValue", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListMultipleTemplate(options, range),
        },
        businessValue: {
            field: "businessValue",
            filterField: "businessValue",
            header: t("BUSINESS_VALUE"),
            sortable: true,
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "businessValue", "s"),
            body: (opp) => Rating(opp, "businessValue", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListMultipleTemplate(options, range),
        },
        win: {
            field: "win",
            filterField: "win",
            header: t("QUICK_WIN"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "win", "m"),
            body: (opp) => Win(opp, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListSingleFilterTemplate(options, winTruthOptions, "yes/no?"),
        },
        truth: {
            field: "truth",
            filterField: "truth",
            header: t("MOMENT_TRUTH"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "truth", "s"),
            body: (opp) => Truth(opp, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListSingleFilterTemplate(options, winTruthOptions, "yes/no?"),
        },
        groupIds: {
            field: "groupIds",
            filterField: "groupIds",
            header: t("GROUPS"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            filter: true,
            body: (action) => Groups(action, EntityList.Opportunity, context),
            style: checkStyle(queryView, "groupIds", "m", "120px"),
            filterElement: (options) => PickListSingleFilterTemplate(options, group, t("SELECT_GROUP")),
        },
        tagIds: {
            field: "tagIds",
            filterField: "tagIds",
            header: t("TAGS"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            filter: true,
            body: (action) => Tags(action, EntityList.Opportunity, context),
            style: checkStyle(queryView, "tagIds", "m"),
            filterElement: (options) => PickListSingleFilterTemplate(options, tag, t("SELECT_TAG")),
        },
        statusId: {
            field: "statusId",
            filterField: "statusId",
            header: t("ASPX_222"),
            sortable: true,
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "statusId", "m"),
            body: (action) => Status(action, dataKey, "opportunity", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListMultipleTemplate(options, statusTypes),
        },
        dueDateNew: {
            field: "dueDateNew",
            filterField: "dueDateNew",
            header: t("DUE_DATE"),
            sortable: true,
            showFilterOperator: false,
            dataType: "date",
            filter: true,
            style: checkStyle(queryView, "dueDateNew", "m"),
            body: (action) => DatePicker(action, dataKey, "dueDate", props?.contextData?.onChangeObject),
            filterElement: dateFilterTemplate,
        },
        lastUpdated: {
            field: "lastUpdated",
            filterField: "lastUpdated",
            header: t("JS_72"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            style: checkStyle(queryView, "lastUpdated", "m"),
            dataType: "date",
            body: (solution) => dateBodyTemplate(solution.lastUpdated, "action"),
            filterElement: dateFilterTemplate,
        },
        description: {
			field: "description",
			sortable: true,
			header: t("ASPX_9"),
			dataType: "text",
			style: checkStyle(queryView, "description", "l"),
			body: (ac) => Description(ac),

		},
    };
};
const range = [
    { value: 0, name: 0 },
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
];

const winTruthOptions = [
    { value: true, name: "Yes" },
    { value: false, name: "No" },
];