import { cxGoalsInit, getAllMapsListData, getOrganizationSettings, measureInit } from "@redux/index";
import { getDashboardData } from "features/analytics/reducer/analyticActions";
import { getAllProjects } from "features/projects/reducer/ProjectsActions";
import { useLocation } from "react-router-dom";
import DataLoader from "shared/components/data-loader";
import ROIAnalysisReports from "./ROIAnalysisReports";

function ROIAnalysisDashboard() {
	const isExportMode = useLocation().search.hideForPdf();

	return (
		<DataLoader
			asyncFns={[
				{ asyncFn: getOrganizationSettings, parameter: { redirectReducer: "analytics" } },
				{ asyncFn: getAllMapsListData, parameter: { redirectReducer: "analytics", dashboardCode: "roiAnalysis" } },
				{ asyncFn: getAllProjects, parameter: { redirectReducer: "analytics" } },
			]}
			reduxActions={[measureInit, cxGoalsInit]}>
			<DataLoader asyncFns={[{ asyncFn: getDashboardData, parameter: { dashboardCode: "roiAnalysis", isExportMode } }]}>
				<ROIAnalysisReports />
			</DataLoader>
		</DataLoader>
	);
}

ROIAnalysisDashboard.displayName = "ROIAnalysisDashboard";
export default ROIAnalysisDashboard;
