import { useTranslation } from "react-i18next";
import { StateTypeOption } from "@utils/optionsSets/OptionSets";

export default function PickListData(type, arrayofType) {
	const { t } = useTranslation();
	const getPicklistData = (type) => {
		switch (type) {
			case "priority":
				return [
					{ name: t("LOW"), value: 0, colorCode: "#CFFF47" },
					{ name: t("NORMAL"), value: 1, colorCode: "#FCFF5C" },
					{ name: t("HIGH"), value: 2, colorCode: "#FF71E0" },
				];
			case "status":
				return [
					{ name: t("NEW_ACTION_STATUS"), value: 100000000, colorCode: "#F5F5F5" },
					{ name: t("IN_PROGRESS"), value: 100000001, colorCode: "#FFF8B9" },
					{ name: t("STUCK"), value: 100000002, colorCode: "#FD7171" },
					{ name: t("DEFERRED"), value: 100000003, colorCode: "#92919D" },
					{ name: t("DONE"), value: 100000004, colorCode: "#57FFD6" },
				];
			case "status-solution":
				return [
					{ name: t("NEW"), value: 100000000, colorCode: "#F5F5F5" },
					{ name: t("IN_PROGRESS"), value: 100000001, colorCode: "#FFF8B9" },
					{ name: t("LIVE"), value: 100000002, colorCode: "#FD7171" },
					{ name: t("REJECTED"), value: 100000003, colorCode: "#92919D" },
					{ name: t("VALIDATED"), value: 100000004, colorCode: "#57FFD6" },
				];
			case "state":
				return [
					{ name: t("CURRENT"), value: StateTypeOption.CurrentState },
					{ name: t("FUTURE"), value: StateTypeOption.FutureState },
				];
			case "gender":
				return [
					{ name: "ASPX_59", translatedName: t("ASPX_59"), value: "Man" },
					{ name: "ASPX_60", translatedName: t("ASPX_60"), value: "Woman" },
					{ name: "ASPX_61", translatedName: t("ASPX_61"), value: "Other" },
				];
			case "mapMode":
				return [
					{ name: t("ASPX_117"), value: 100000001 },
					{ name: t("ASPX_128"), value: 100000002 },
					{ name: t("ASPX_256"), value: 100000003 },
					{ name: t("ASPX_116"), value: 100000000 },
				];
			case "templateStatus":
				return [
					{ name: t("PUBLISHED"), value: 100000001 },
					{ name: t("DRAFT"), value: 100000000 },
				];
			case "mapType":
				return [
					{ name: t("ASPX_117"), value: "Detailed" },
					{ name: t("ASPX_128"), value: "Flexi" },
					{ name: t("ASPX_256"), value: "Service Blueprint" },
					{ name: t("ASPX_116"), value: "High Level" },
				];
			case "isFutureLabel":
				return [
					{ name: "Current", value: StateTypeOption.CurrentState },
					{ name: "Future", value: StateTypeOption.FutureState },
				];
			case "palette1":
				return ["#05C597", "#6EA7FC", "#F96209", "#A17CD2", "#9747FF", "#C53E05", "#FF3A6A", "#333333", "#C2F200", "#C4C4C4", "#0D60DD", "#05C546", "#05B3D9", "#FECB15", "#C50561"];
			case "palette-status":
				return ["#FBF8CC", "#FDE4CF", "#FFCFD2", "#F1C0E8", "#CFBAF0", "#A3C4F3", "#A6E1FA", "#8EECF5", "#98F5E1", "#B9FBC0", "#F5F5F5", "#C4C4C4", "#B3BFB8", "#B8D0EB", "#FF928B"];
			case "palette-priority":
				return ["#FF0000", "#FF8700", "#FFD300", "#DEFF0A", "#A1FF0A", "#0AFF99", "#0AEFFF", "#147DF5", "#580AFF", "#BE0AFF", "#F20089", "#DB00B6", "#70E000", "#CCFF33", "#3a86ff"];
			case "palette-system":
				return ["#FCA8CE", "#E1A2D8", "#BB9DDE", "#B4C0F8", "#B6D5F9", "#99D5E9", "#66ADD3", "#9AB2D0", "#B7A8C2", "#DBD3E0", "#EFD3D7", "#FFDAB9", "#FBC4AB", "#F8AD9D", "#F4978E", "#F08080"];
			case "palette-department":
				return ["#FFAC81", "#FF928B", "#FEC3A6", "#FFE49F", "#E6EFAE", "#CDEAC0", "#BAF2BB", "#BAF2D8", "#CBF3F0", "#E3F2FD", "#BBDEFB", "#90CAF9", "#98C1D9", "#EFD3D7", "#CBC0D3", "#CCACBE"];
			case "palette-library":
				return [
					"#E9ECEF",
					"#D8FAFA",
					"#CAF0F8",
					"#FDE7CB",
					"#FFF6CC",
					"#F8C8AE",
					"#F7CAD0",
					"#FFCBF8",
					"#E0CDF8",
					"#D7E3FC",
					"#CED4DA",
					"#9DF2F2",
					"#90E0EF",
					"#FCD6A4",
					"#FFEE99",
					"#F6B28E",
					"#FBB1BD",
					"#FEA3FF",
					"#BD99EA",
					"#ABC4FF",
				];
			case "divider-pattern":
				return [
					{ name: t("SOLID"), value: 100000000 },
					{ name: t("LINES"), value: 100000001 },
					{ name: t("ZIGZAG"), value: 100000002 },
					{ name: t("DIAGONAL"), value: 100000003 },
					{ name: t("RECTANGLES"), value: 100000004 },
				];
			case "department":
				return [
					{ key: "1", label: t("ADMINISTRATION") },
					{ key: "3", label: t("CONSULTING") },
					{ key: "100000000", label: t("CUSTOMER_EXPERIENCE") },
					{ key: "100000001", label: t("CUSTOMER_SERVICE") },
					{ key: "100000002", label: t("EDUCATION") },
					{ key: "100000003", label: t("ENGINEERING") },
					{ key: "100000004", label: t("EXECUTIVE_MANAGEMENT") },
					{ key: "2", label: t("ASPX_182") },
					{ key: "100000005", label: t("JS_146") },
					{ key: "100000006", label: t("IT") },
					{ key: "100000007", label: t("LEGAL") },
					{ key: "100000008", label: t("LOGISTICS_DELIVERY") },
					{ key: "100000009", label: t("MARKETING_COMMUNICATIONS") },
					{ key: "100000010", label: t("OPERATIONS_SUPPLY_CHAIN") },
					{ key: "100000011", label: t("PROCUREMENT") },
					{ key: "100000018", label: t("PRODUCT_MANAGEMENT") },
					{ key: "100000012", label: t("PRODUCTION_QUALITY_ASSURANCE") },
					{ key: "100000013", label: t("PROJECT_MANAGEMENT") },
					{ key: "100000014", label: t("R_D") },
					{ key: "100000015", label: t("SALES") },
					{ key: "100000016", label: t("SERVICE_DESIGN") },
					{ key: "100000017", label: t("VOC_INSIGHTS") },
					{ key: "100000019", label: t("ASPX_61") },
                ];
                case "user-type":
                    return [
                        { key: "100000000", label: t("CS_52") },
                        { key: "100000001", label: t("CS_53") },
                        { key: "100000002", label: t("SUPER_ADMIN") },
                        { key: "100000003", label: t("READ_ONLY") },
                      
                    ];
			default:
		}
	};
	if (type === "multiple") {
		const result = {};
		arrayofType.forEach((item) => {
			result[item] = getPicklistData(item);
		});
		return result;
	} else {
		return getPicklistData(type);
	}
}
