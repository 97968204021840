import { createSlice } from "@reduxjs/toolkit";
import { store } from "../../..";
import { EMPTY_GUID, tableDefaultColumn } from "@utils/helpers";


export const Table = createSlice({
    name: 'Table',
    initialState: {
        entity: {},
        selectedIds: [],
    },
    reducers: {
        loadTableProps: (state, action) => {
            const { columns, selectedViewId, entityType, filter, sort, context } = action.payload
            if (selectedViewId === EMPTY_GUID) {
                state.entity[entityType] = { ...state.entity[entityType], [context]: { columns: tableDefaultColumn(entityType), filters: undefined, selectedViewId: EMPTY_GUID, sort: undefined } }
            } else {
                state.entity[entityType] = { ...state.entity[entityType], [context]: { columns: columns, filters: filter, selectedViewId: selectedViewId, sort: sort } }
            }

        },
        setSelectedIdTable: (state, action) => {
            state.selectedIds = action.payload;
        },
        setColumnsArray: (state, action) => {
            const { columns, entityType, context } = action.payload
            state.entity[entityType][context] = { ...state.entity[entityType][context], columns: columns }

        },
        setFiltersService: (state, action) => {
            const { value, entityType, context } = action.payload
            state.entity[entityType][context] = { ...state.entity[entityType][context], filters: value }

        },
        setIdsAfterFilter: (state, action) => {
            const { filteredIds, context, entityType } = action.payload
            if(!state.entity[entityType]) state.entity[entityType] = { [context]: { filteredIds: filteredIds } }
            else if(!state.entity[entityType]?.[context]) {
                state.entity[entityType][context] = { filteredIds: filteredIds }
            }else {
                state.entity[entityType][context] = { ...state.entity?.[entityType]?.[context], filteredIds: filteredIds }
            }

        },
        setSortedService: (state, action) => {
            const { sortField, sortOrder, entityType, context } = action.payload
            state.entity[entityType][context] = { ...state.entity[entityType][context], sort: { sortField: sortField, sortOrder: sortOrder } }
        },
    }
})
export const loadTableProps = (data) => store.dispatch(Table.actions.loadTableProps(data))

export const setSelectedIdTable = (data) => store.dispatch(Table.actions.setSelectedIdTable(data))
export const setColumnsArray = (data) => store.dispatch(Table.actions.setColumnsArray(data))
export const setFiltersService = (data) => store.dispatch(Table.actions.setFiltersService(data))
export const setSortedService = (data) => store.dispatch(Table.actions.setSortedService(data))
export const setFilterIdsService = (data) => store.dispatch(Table.actions.setIdsAfterFilter(data))