import { GenericButton } from "shared/ui-basics/buttons";
import { EmptyList } from "shared/ui-basics/ui-components/EmptyList";
import { emptyListData } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import { CxProgramCard } from "../../forms/cx-programs/cards/CxProgramCard";
import { Spinner } from "../../../shared/components/spinners/Spinner";
import { CardsContainer } from "./Style";


export const CardView = ({ cxPrograms, showCreateModal }) => {
	const { t } = useTranslation();
	const loading = useSelector((state) => state.cxProgram.loading);
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const { icon, color } = emptyListData.cxPrograms;

	const render = () => {
		if (loading) return <Spinner />;

		if (cxPrograms?.length > 0)
			return (
				<CardsContainer>
					{cxPrograms?.map((card) => (
						<div key={card.id}>
							<CxProgramCard card={card} />
						</div>
					))}
				</CardsContainer>
			);

		return (
			<div className="mt-5 pt-5">
				<EmptyList
					data={{
						visibleButtonCreate: !isReadOnly,
						buttonCreate: showCreateModal,
						buttonCreateLabel: "NEW_PROGRAM",
						emptyListIconColor: color,
						emptyListIcon: icon,
						emptyListText: "PROGRAM_EMPTY_LIST",
					}}
				/>
			</div>
		);
	};

	return (
		<>
			{!isReadOnly && cxPrograms?.length > 0 && (
				<GenericButton className="mb-4" variant="primary" icon="SQUARE_PLUS" iconClassName="me-1" onClick={showCreateModal}>
					{t("NEW_PROGRAM")}
				</GenericButton>
			)}
			<Col>{render()}</Col>
		</>
	);
};