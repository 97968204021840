import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useModal } from "use-modal-hook";
import { ActionConfirmModal } from "shared/modals/index";
import { filterItemsByUserRole } from "@utils/helpers";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import BulkModalLinkComponent from "shared/modals/bulk-links/BulkModalLinkComponent";
import { BulkActionsGenerator } from "shared/components/BulkActionsGenerator";
import React from "react";
import { archiveManyCxPrograms, deleteManyCxPrograms, deleteManyCxProgramsPermanent, restoreManyCxPrograms } from "../reducer/CxProgramActions";

export const BulkActions = (selectedCxPrograms, setSelected, onSuccess, setDisableBulkActions) => {
	const pathname = useLocation().pathname;
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";
	const isTemplate = pathname === "/templates";

	const actions = [];
	const cxPrograms = useSelector((state) => state.cxProgram.all_cxPrograms);
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const selectedItemsIdsStr = selectedCxPrograms.map((item) => item?.id).join("|");
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);

	const isManageAccessAllowed = isSubscriptionAllowed;
	const filteredItemsIdsStr = filterItemsByUserRole({ allItems: cxPrograms, selectedItems: selectedCxPrograms, currentUserId, isSubscriptionStandard: !isSubscriptionAllowed });
	const filteredItemsByOwnersAndContrib = filterItemsByUserRole({
		allItems: cxPrograms,
		selectedItems: selectedCxPrograms,
		currentUserId,
		isSubscriptionStandard: !isSubscriptionAllowed,
		userRoles: ["owner", "contributor"],
	});

	const areAllActionsValid = selectedItemsIdsStr.length === filteredItemsIdsStr.length;

	const [showAccessModal] = useModal(GenericManageAccess);
	const manageAccessMany = () => showAccessModal({ ids: filteredItemsIdsStr, bulk: true, onSuccess });

	const [showModalArchivePopup] = useModal(ActionConfirmModal, {
		bodyContent: "ARCHIVE_MANY_GENERIC",
		Action: () => {
			setDisableBulkActions(true);
			archiveManyCxPrograms({ ids: filteredItemsIdsStr, toast: areAllActionsValid ? "BULK_ARCHIVE_SUCCESS" : "BULK_ARCHIVE_PARTIAL", onSuccess });
		},
		actionBtnText: "ARCHIVE",
	});

	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_GENERIC",
		Action: () => {
			setDisableBulkActions(true);
			deleteManyCxPrograms({ ids: filteredItemsIdsStr, toast: areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess });
		},
		actionBtnText: "ASPX_13",
	});

	const [showModalDeletePermanentPopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_PERMANENT_GENERIC",
		Action: () => {
			setDisableBulkActions(true);
			deleteManyCxProgramsPermanent({ ids: filteredItemsIdsStr, toast: areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess });
		},
		actionBtnText: "ASPX_13",
	});

	const [showBulkModalLinkComponent] = useModal(BulkModalLinkComponent);

	if (isTrash || isArchive) {
        if (filteredItemsIdsStr) actions.push({ handleClick: () => restoreManyCxPrograms({ ids: filteredItemsIdsStr, onSuccess }), icon: "RESTORE", description: "JS_96" });
		if (filteredItemsIdsStr) actions.push({ handleClick: isArchive ? showModalDeletePopup : showModalDeletePermanentPopup, icon: "DELETE", description: isArchive ? "ASPX_13" : "JS_97" });
	} else {
		if (filteredItemsIdsStr) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "folder", idAttribute: "id" }), icon: "FOLDER-OPEN", description: "MOVE_TO_FOLDER" });

			if (isSubscriptionAllowed) actions.push({ handleClick: showModalArchivePopup, icon: "ARCHIVE", description: "ARCHIVE" });
			actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", description: "ASPX_13" });
			if (isManageAccessAllowed && !isTemplate) actions.push({ handleClick: manageAccessMany, icon: "ACCESS", description: "ASPX_17" });
		}
		if (filteredItemsByOwnersAndContrib) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "tag", idAttribute: "id" }), icon: "TAG", description: "TAG" });
		}
	}

	return <BulkActionsGenerator actions={actions} setSelected={setSelected} warningText={"BULK_ACTIONS_MAP_ONLY"} />;
};
