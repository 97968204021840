import { BasicDrillDownModal } from "features/analytics/modals/BasicDrillDownModal";
import { FlexAlignJustifyCenter, FlexColumnGap } from "shared/ui-basics/index";
import { CustomRating } from "shared/ui-basics/ui-components/CustomRating";
import { AnalyticCustomTooltip } from "features/analytics/Components/Tooltips/AnalyticCustomTooltip";
import OpportunityCard from "features/analytics/Components/Cards/OpportunityListCard";
import EmptyData from "features/analytics/Components/EmptyData";
import { oppPointColorByCoordinates } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CartesianGrid, LabelList, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import { opportunityAnalysisDataSelector } from "../journeyTrendDataSelectors";

function OpportunityAnalysisMatrix() {
	const { t } = useTranslation();
	const data = useSelector((state) => opportunityAnalysisDataSelector(state));

	const [drillDownModal] = useModal(BasicDrillDownModal);

	const renderDrillDownIllustration = ({ x, y }) => {
		return (
			<Illustration>
				<FlexColumnGap gap="5">
					<div>{t("BUSINESS_VALUE")}</div>
					<CustomRating object={{ x }} attribute="x" disabled={true} />
				</FlexColumnGap>
				<FlexColumnGap gap="5">
					<div>{t("CUSTOMER_VALUE")}</div>
					<CustomRating object={{ y }} attribute="y" disabled={true} />
				</FlexColumnGap>
			</Illustration>
		);
	};

	const CustomScatterPoint = ({ cx, cy, payload }) => {
		const { x, y, opportunities } = payload;
		const color = oppPointColorByCoordinates(x, y);

		const handleClick = () => {
			drillDownModal({
				title: t("OPPORTUNITIES"),
				relatedItemsTitle: t("OPPORTUNITIES"),
				renderIllustration: () => renderDrillDownIllustration({ x, y }),
				ListItemComponent: OpportunityCard,
				items: opportunities,
			});
		};
		return (
			<a onClick={() => handleClick({ items: opportunities })} style={{ cursor: "pointer" }}>
				<circle cx={cx} cy={cy} r={opportunities.length > 1 ? 16 : 10} fill={color} />
			</a>
		);
	};

	const CustomLabel = ({ cx, cy, value }) => {
		return (
			<text x={cx} y={cy + 4} fill="#fff" textAnchor="middle" fontSize={12} fontFamily="Inter" style={{ pointerEvents: "none" }}>
				{value.length > 1 ? `${value.length}` : ""}
			</text>
		);
	};

	const renderTooltipContent = ({ active, payload }) => {
		if (active && payload && payload.length) {
			const dataPoint = payload[0].payload;
			return <AnalyticCustomTooltip data={dataPoint?.opportunities} entity={t("OPPORTUNITIES")} />;
		}
		return null;
	};

	const axisTicks = [0, 1, 2, 3, 4, 5];

	const tickStyle = {
		fontSize: "14px",
		fontFamilly: "Inter",
		color: "#605F60",
	};

	return (
		<>
			{data.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<ScatterChart
						margin={{
							top: 17,
							left: 10,
							right: 17,
							bottom: 17,
						}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							type="number"
							dataKey="x"
							domain={[0, 5]}
							ticks={axisTicks}
							label={{ value: `${t("BUSINESS_VALUE")} ->`, position: "insideBottomLeft", dy: 15 }}
							tick={{ ...tickStyle }}
							axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }}
						/>
						<YAxis
							type="number"
							dataKey="y"
							domain={[0, 5]}
							ticks={axisTicks}
							label={{ value: `${t("CUSTOMER_VALUE")} ->`, angle: -90, position: "insideBottomLeft", dx: 15 }}
							tick={{ ...tickStyle }}
							axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }}
						/>
						<Scatter data={data} shape={<CustomScatterPoint />}>
							<LabelList dataKey="opportunities" content={<CustomLabel />} />
						</Scatter>
						<Tooltip content={renderTooltipContent} isAnimationActive={false} />
					</ScatterChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="MAP_PER_STATUS_EMPTY" icon="CHART-SCATTER" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

OpportunityAnalysisMatrix.displayName = "OpportunityAnalysisMatrix";
export default OpportunityAnalysisMatrix;

const Illustration = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
`;
