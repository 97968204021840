import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { StatusCode } from "@utils/optionsSets/OptionSets";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BaseActions from "../../../folders/actions/BaseActions";
import { GetActiveCJM } from "@utils/helpers";

export const GetFolderContext = (props) => {
	const { linked, setRename, disableBulkActions } = props;
	const { pathname } = useLocation();
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();

	const all_maps = useSelector((state) => state.maps.all_maps);
	const folders = useSelector((state) => state.folders?.filter((folder) => FolderValidation(folder, pathname))).map((folder) => {
		const newDate = new Date(folder.modifiedOn);

		return { ...folder, modifiedOn: newDate };
	});

	const linkedObjects = undefined;
	folders.forEach((folder) => {
		folder.linkedCjms = GetActiveCJM(folder?.linkedCjms,all_maps)
	});
	const data = useMemo(() => {
		const newData = {
			archived: {
				showCheckbox: false,
				columns: ["folderName", "linkedCjms", "modifiedOn", "createdByName", "actions"],
				emptyListText: "NO_FOLDER",
				bulkActions: false,
				clickToOpen: false,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: false,
				columns: ["folderName", "linkedCjms", "modifiedOn", "createdByName", "actions"],
				emptyListText: "NO_FOLDER",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				disableFixHeader: false,
				showPagination: true,
			},
		};
		const specificContext = props.context.split(".")[1];
		const contextData = _.get(newData, specificContext);

		if (isReadOnly) {
			contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.Actions = (folder) => CreateActionsDropdown({ actions: BaseActions({ folder, setRename }), variant: "default", dropdownClasses: "" });
		contextData.linkedObjects = linkedObjects;
		contextData.listData = folders;
		contextData.dataKey = "id";
		contextData.entityType = "EntityList.";
		contextData.emptyListSmall = "true";
		return contextData;
	}, [isReadOnly, linked, linkedObjects, folders, disableBulkActions, setRename]);

	return data;
};

export const FolderValidation = (folder, pathname) => {
	const isAccount = pathname === "/folders";
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";

	if (isAccount) {
		return folder.statusCode === StatusCode.Active && folder.isSample === false;
	}
	if (isTrash) {
		return folder.statusCode === StatusCode.Trashed && folder.isSample === false;
	}
	if (isArchive) {
		return folder.statusCode === StatusCode.Archived && folder.isSample === false;
	}
	return folder;
};
