import { Borders, IconDispatcher } from "shared/ui-basics/index";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Collapse } from "reactstrap";
import styled from "styled-components/macro";
import { TabTitleContext } from "./RelationManager";

function RelatedCjmsManager({ item, openRoute, tabsData }) {
	const linkedMapIds = Object.keys(item?.linkedCjms);
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);

	const relatedMaps = useSelector((state) => state?.maps?.all_maps)?.getActiveRecords(!showSampleData)?.filter((map) => linkedMapIds?.includes(map.cjmId))
	const { count, setCount } = useContext(TabTitleContext);
	useEffect(() => {
		if( count?.[tabsData.attribute] !== relatedMaps?.length) {
			setCount((prev) => ({ ...prev || {}, [tabsData.attribute]: relatedMaps?.length }));
		}
	}, [relatedMaps]);
	return (
		<>
			{relatedMaps.map((relatedMap) => (
				<div key={relatedMap?.cjmId}>
					<RowCJM cjmId={relatedMap?.cjmId} cjm={relatedMap} item={item} openRoute={openRoute} />

				</div>
			))}
		</>
	);
}
function RowCJM({ cjmId, cjm, item, openRoute }) {
	const { cjmIdStr } = useLocation().search.getAllMapsParams();

	const [isOpen, setIsOpen] = useState(false);

	const viewJourney = () => {
		if (cjmId !== cjmIdStr) {
			openRoute("", `/EditMap?cjmid=${cjmId}&personaId=${cjm?.linkedPersonas[0]?.personaId}`)
		}
	};
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	return <>
		<CustomBorders className="p-2" top bottom >
			<WrapperCollapseIcon onClick={toggle}>{IconDispatcher(isOpen ? "ARROW_DOWN" : "ANGLE-RIGHT", "me-2")}</WrapperCollapseIcon>
			<MapName onClick={viewJourney}>{cjm?.name}</MapName>
		</CustomBorders>
		<Collapse isOpen={isOpen}>
			{item?.linkedCjms[cjmId].map((insightLocation, i) => <Details key={i} map={cjm} insightLocation={insightLocation} />)}
		</Collapse>
	</>
}

function Details({ map, insightLocation }) {
	const { t } = useTranslation();
	const stage = map?.stages.find((stage) => stage?.id === insightLocation?.stageId);
	return (
		<Container key={map?.cjmId} className="p-2" bottom>
			<div className="ms-5">{stage?.name || t("NEW") + " " + t("STAGE")}</div>
		</Container>
	);
}

RelatedCjmsManager.displayName = "RelatedCjmsManager";

export default RelatedCjmsManager;
const WrapperCollapseIcon = styled.div`
	width:20px;
`
const CustomBorders = styled(Borders)`
	display: flex;
	align-items: center;
	:hover {
		cursor: pointer;
	}
`;

const MapName = styled.div`
	color: #333;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
`;

const Container = styled(Borders)`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
`;
const CountContainer = styled.div`
	color: #605f60 !important;
	text-align: end;
	font-family: "Inter";
	font-size: 14px;
`;