import { GenericButton } from "shared/ui-basics/buttons";
import GenericPopover from "shared/popovers/GenericPopover";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const JourneyMaps = ({ cjmIds }) => {
    const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
    const all_maps = useSelector((state) => state.maps.all_maps).getActiveRecords(!showSampleData);

    const MapList = ({ cjmId,className }) => {

        const cjm = all_maps?.find((map) => map.cjmId === cjmId);
        return <WrapperMap className={`text-truncate ${className}`}>{cjm?.name}</WrapperMap>
    }
    const custom = (
        <div className="px-3 py-1">
            {cjmIds?.slice(1, Infinity).map((cjm) => (
                <div key={cjm} className="mb-1">
                    <MapList cjmId={cjm} className={"py-1"}/>
                </div>
            ))}
        </div>
    );
    return (
        <WrapperDiv className="d-flex">
            {cjmIds?.slice(0, 1).map((cjm) => (
                <div key={cjm} className="mb-1 ms-1">
                    <MapList cjmId={cjm} />
                </div>
            ))}
            {cjmIds?.length > 1 && (
                <div className="ms-1">
                    <GenericPopover custom={custom}>
                        <GenericButton variant="light" iconClassName="ms-2" size={"xs"} className="p-1"  >
                            +{cjmIds?.length - 1}
                        </GenericButton>
                    </GenericPopover>
                </div>
            )}
        </WrapperDiv>
    );
}
const WrapperMap=styled.div`
    max-width:320px;

`
const WrapperDiv = styled.div`
	overflow: hidden;
`;
const Counter = styled.div`
	height: 24px;
	padding: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #605f60;
	display: flex;

	background: #fff;
`;