import { isSubscriptionAllowed, isUserAllowed } from "@utils/helpers";
import PersonaProfileCard from "features/forms/personas/cards/persona-sections-cards/PersonaProfileCard";
import { t } from "i18next";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import { EmptyListImg, EmptyListText, GenericButton, IconDispatcher } from "shared/ui-basics/index";
import * as S from "./PersonaCreationStyles";
import { StatusCode } from "@utils/optionsSets/OptionSets";

export default function PersonaType({ nextStep }) {
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<S.BackButton variant="primary-link" onClick={history.goBack}>
				{IconDispatcher("ARROW_LEFT", "me-1")}
				{t("ASPX_132")}
			</S.BackButton>
			<Row>
				<S.Welcome>
					<S.CrytalBall>{IconDispatcher("CRYSTAL-BALL")}</S.CrytalBall>
					<S.DefinePersonaText>{t("DEFINE_PERSONA")}</S.DefinePersonaText>
					<S.SelectPersonaText>{t("PERSONA_WIZARD_SELECT")}</S.SelectPersonaText>
					<GenericButton variant="primary" size="m" className="mt-4" iconPosition="after" icon="ANGLE-RIGHT" iconClassName="ms-2" onClick={nextStep}>
						{t("START_WIZARD")}
					</GenericButton>
				</S.Welcome>
			</Row>
			<Row>
				<PersonaWizardTemplates />
			</Row>
		</React.Fragment>
	);
}



function PersonaWizardTemplates() {
	const { t } = useTranslation();

	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const personaCemTemplates = useSelector((state) => state.personas.all_personas).filter((persona) => persona.isTemplate && persona.templateStatus === 100000002);
	const personaOrgTemplates = useSelector((state) => state.personas.all_personas).filter(
		(persona) => persona.isTemplate && persona.templateStatus === 100000001 && persona.statusCode===StatusCode.Active
	);

	const [key, setKey] = useState("cemantica");

	return (
		<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="m-3" mountOnEnter={true} unmountOnExit={true}>
			<Tab eventKey="cemantica" title={t("CEMANTICA")}>
				<GetTemplates personas={personaCemTemplates} />
			</Tab>
			{isSubscriptionAllowed(subscriptionPlan, "regular") && (
				<Tab eventKey="organization" title={t("YOUR_ORGANIZATION")}>
					<GetTemplates personas={personaOrgTemplates} />
				</Tab>
			)}
		</Tabs>
	);
}

const GetTemplates = ({ personas }) => {
	const userType = Number(useSelector((state) => state.auth?.userInfo?.UserType));
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const isSubscriptionAllowed = ["Expert", "Enterprise"].includes(subscriptionPlan);

	return (
		<S.TemplatesContainer>
			{personas?.map((persona, index) => (
				<S.PersonaTemplateCard key={index} className="m-2">
					<PersonaProfileCard key={index} personaId={persona.personaId} isTemplateView={true} />
				</S.PersonaTemplateCard>
			))}
			{personas.length === 0 && (
				<S.EmptyContainer className="p-0 mt-4">
					{<EmptyListImg src={require("@Assets/images/EmptyListImages/no-personas.svg").default} alt="no maps" />}
					<EmptyListText> {t("NO_PERSONA_TEMPLATE_CREATION")}</EmptyListText>
					{isSubscriptionAllowed && isUserAllowed(userType, "template") && (
						<S.GOTemplate tag={Link} to="/templates">
							{t("GO_TEMPLATE")}
						</S.GOTemplate>
					)}
				</S.EmptyContainer>
			)}
		</S.TemplatesContainer>
	);
};