import { ConnectorSource, ConnectorType } from "@utils/optionsSets/OptionSets";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal, ModalHeader } from "reactstrap";
import GenericSwitch, { GenericButton } from "shared/ui-basics/buttons";
import { Input, Label } from "shared/ui-basics/index";
import styled from "styled-components";
import { upsertConnector } from "../reducer/ConnectorAction";
import { EMPTY_GUID } from "@utils/helpers";
import { toast } from "react-toastify";

export const MondayFormConnector = ({ isOpen, onClose, connector }) => {
	const { t } = useTranslation();
	const [toggle, setToggle] = useState(false);
	const [validated, setValidated] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [currentConnector, setCurrentConnector] = useState(
		(connector && {
			id: connector.id,
			name: connector.name,
			token: connector.token,
			host: connector.host,
			apiUrl: connector?.mondaySettings?.apiUrl,
			dedicatedBoardTemplateId: connector?.mondaySettings?.dedicatedBoardTemplateId,
			globalProjectsBoardId: connector?.mondaySettings?.globalProjectsBoardId,
			enableConnection: connector.enableConnection,
		}) || { name: t("MONDAY") + " " + t("INTEGRATION") }
	);

	useEffect(() => {
		setToggle(currentConnector?.enableConnection);
	}, [currentConnector?.enableConnection]);

	const handleChange = (e, type) => {
		let { name, value } = e.target;
		if (name === "enableConnection") {
			setToggle(!toggle);
			value = !toggle;
		}
		setCurrentConnector({ ...currentConnector, [type ?? name]: value });
	};

	const submit = (event) => {
		setIsDisabled(true);
		event.preventDefault();
		if (validation()) {
			upsertConnector({
				connector: {
					id: currentConnector?.id,
					name: currentConnector.name,
					source: ConnectorSource.Monday,
					type: ConnectorType.ProjectManagement,
					token: currentConnector.token,
					host: currentConnector.host,
					enableConnection: currentConnector.enableConnection,
					retrieveDataFrequency: "100000001",
					mondaySettings: {
						apiUrl: currentConnector.apiUrl,
						dedicatedBoardTemplateId: currentConnector.dedicatedBoardTemplateId,
						globalProjectsBoardId: currentConnector.globalProjectsBoardId,
					},
				},
				displayToast: "UPDATE_NO_SUCCESS_TOAST",
				onSuccess: (responseData) => {
					setIsDisabled(false);
					if (responseData?.id !== EMPTY_GUID) onClose();
					else toast.warning(t("INTEGRATION_FAILED"));
				},
			});
		} else {
			setValidated(false);
		}
	};

	const validation = () => {
		const { name, token, host, apiUrl, globalProjectsBoardId, dedicatedBoardTemplateId } = currentConnector;
		if (!name || !token || !host || !apiUrl || !globalProjectsBoardId || !dedicatedBoardTemplateId) return false;
		return true;
	};

	return (
		<Modal fade={false} isOpen={isOpen} centered={true} toggle={onClose} size="lg">
			<ModalHeader toggle={onClose}>{t("MONDAY") + " " + t("INTEGRATION")}</ModalHeader>
			<WrapperForm id={currentConnector?.id} onSubmit={submit}>
				<div className="d-flex flex-column gap-4 p-4">
					<WrapperRow>
						<div className="w-100">
							<Label className="pb-2">{t("NAME")}</Label>
							<Input
								variant="default"
								invalid={!currentConnector?.name && !validated}
								name="name"
								type="text"
								value={currentConnector?.name}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						</div>
						<div className="w-100">
							<Label className="pb-2">{t("TOKEN")}</Label>
							<Input
								variant="default"
								invalid={!currentConnector?.token && !validated}
								name="token"
								type="text"
								value={currentConnector?.token || ""}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						</div>
					</WrapperRow>
					<WrapperRow>
						<div className="w-100">
							<Label className="pb-2">{t("ASPX_233")}</Label>
							<Input
								variant="default"
								invalid={!currentConnector?.host && !validated}
								name="host"
								type="text"
								value={currentConnector?.host || ""}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						</div>
						<div className="w-100">
							<Label className="pb-2">{t("API_URL")}</Label>
							<Input
								variant="default"
								invalid={!currentConnector?.apiUrl && !validated}
								name="apiUrl"
								type="text"
								value={currentConnector?.apiUrl || ""}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						</div>
					</WrapperRow>
					<WrapperRow>
						<div className="w-100">
							<Label className="pb-2">{t("PROJECT_GLOBAL_BOARD_ID")}</Label>
							<Input
								variant="default"
								invalid={!currentConnector?.globalProjectsBoardId && !validated}
								name="globalProjectsBoardId"
								type="text"
								value={currentConnector?.globalProjectsBoardId || ""}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						</div>
						<div className="w-100">
							<Label className="pb-2">{t("PROJECT_TEMPLATE_ID")}</Label>
							<Input
								variant="default"
								invalid={!currentConnector?.dedicatedBoardTemplateId && !validated}
								name="dedicatedBoardTemplateId"
								type="text"
								value={currentConnector?.dedicatedBoardTemplateId || ""}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						</div>
					</WrapperRow>

					<div className="d-flex ">
						<Label className="pe-4">{t("ENABLE_CONNECTION")}</Label>
						<GenericSwitch isChecked={toggle} name="enableConnection" handleToggle={handleChange} isdisabled={isDisabled} />
					</div>
				</div>
				<WrapperFooter className=" p-4">
					<GenericButton variant="primary" type="submit" disabled={!validation() || isDisabled}>
						{connector ? `${t("ASPX_165")} ${t("CONNECTION")}` : t("BTN-CONNECT")}
					</GenericButton>
				</WrapperFooter>
			</WrapperForm>
		</Modal>
	);
};

export const WrapperFooter = styled.div`
	border-top: 1px solid #c4c4c4;
	display: flex;
	justify-content: end;
`;

const WrapperForm = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const WrapperRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 20px;
`;
