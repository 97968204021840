import React from "react";
import * as Style from "./style";
import { useSelector } from "react-redux";
import { IconDispatcher } from "shared/Icons";
import { Link, useLocation } from "react-router-dom";
import CustomTooltip from "shared/components/CustomTooltip";
import { useTranslation } from "react-i18next";
import { GenericButton } from "shared/ui-basics/buttons";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { Flex } from "shared/ui-basics/index";
import EditTitle from "shared/components/EditableText";
import { updateFolderName } from "@redux/index";
import { allRoutes, librariesRoutes } from "../../../shared/Routes/routes";

export function NavLinkComponent(props) {
	const { route, text, icon } = props;
	const { pathname, search } = useLocation();
	const { t } = useTranslation();
	const { UserSubscriptionPlanName = "" } = useSelector(({ auth }) => auth?.userInfo || {});
	const isFooterRoutes = allRoutes.footer?.map((m) => m.route)?.includes(route);

	const isSubscriptionAllowed = props.access.includes(UserSubscriptionPlanName);
	const getSelectedRoute = () => {
		if (pathname === '/edit-workspace' && route === "/workspaces") return true
		if (pathname === '/edit-user' && route === "/users") return true
		if (props.isWorkspace) {
			const folderId = search.getFolderId();
			return folderId ? false : props.activeRoutes?.includes(pathname);
		}
		if (route === '/libraries') {
			return `${pathname}${search}` === `${route}` || librariesRoutes.includes(pathname);
		}
		if (route === '/templates') {
			return `${pathname}` === `${route}`
		}
		return `${pathname}${search}` === `${route}`;
	};
	const isActive = getSelectedRoute();

	return (
		<Style.CustomNavLink active={isActive} tag={route ? Link : "div"} to={route} disabled={!isSubscriptionAllowed && props.allowPopover} iconcolor={props.iconcolor}>
			<div className='d-flex justify-content-center align-items-center'
				style={{ width: '20px', height: '20px' }} >
				{IconDispatcher(icon, "", { style: "regular", family: 'sharp', size: "md" })}
			</div>
			<Style.TextWrapper isFooterRoutes={isFooterRoutes}>{t(text)}</Style.TextWrapper>
		</Style.CustomNavLink>
	);
}

export const CollapseButton = ({ text, icon, onClick, actions, id, rename, setRename, isReadOnly }) => {
	const { t } = useTranslation();

	const [folderName, setFolderName] = React.useState(text);

	const handleClick = (dropdownAction) => {
		const elementToScroll = document.getElementById(`folderId=${id}`);
		if (elementToScroll) {
			elementToScroll.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	const renderActions = () => {
		if (isReadOnly) return null;
		switch (actions.type) {
			case "button":
				return <GenericButton variant="light-link" icon={actions.icon} onClick={actions.onClick} />;
			case "dropdown":
				return CreateActionsDropdown({ id: `${id}_Main`, actions: actions.actions, autoClose: "outside", right: "0", dropdownClasses: " ", handleClick, menuVariant: "sidebar" });
			case "folder":
				break;
			default:
				return null;
		}
	};
	return (
		<Style.CollapseLabel>
			<Flex onClick={onClick}>
				<div>{IconDispatcher(icon)}</div>
				{id ? (
					<>
						{rename === id ? (
							<div className="w-75">
								<EditTitle obj_id={id} obj_name={folderName} updateName={updateFolderName} setName={setFolderName} setRename={setRename} />
							</div>
						) : (
							<CustomTooltip id={id} text={folderName} placement={"bottom"} disabled={!folderName || folderName.length <= 20}>
								<Style.TextWrapper>{t(text)}</Style.TextWrapper>
							</CustomTooltip>
						)}
					</>
				) : (
					<Style.TextWrapper>{t(text)}</Style.TextWrapper>
				)}
			</Flex>
			{actions && <Style.ActionButtons>{renderActions()}</Style.ActionButtons>}
		</Style.CollapseLabel>
	);
};
