export const updateInsightState = ({ state, otherState = {} }) => {
	return {
		...state,
		...otherState,
		lastModifiedOn: new Date().toISOString(),
	};
};
export function fetchArraysAndMerge(array1, array2) {
	const map = new Map();     // Add all objects from array1 to the map    
	array1.forEach(obj => map.set(obj.id, obj));     // Add objects from array2 to the map, duplicates will be overwritten 
	array2.forEach(obj => map.set(obj.id, obj));
	// Convert map values back to an array
	const mergedArrayUnique = Array.from(map.values());
	return mergedArrayUnique;
}
