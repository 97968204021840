import { IconDispatcher } from "shared/Icons";
import styled from "styled-components/macro";

export function Counter({ data }) {
	return (
		<ConterContainer>
			<IconContainer>
				{data?.icon && IconDispatcher(data.icon, "", { style: "light" })}
				{data?.iconComponent && data?.iconComponent(data.icon)}
			</IconContainer>
			<Value>{data?.value}</Value>
			{data?.nameComponent()}
		</ConterContainer>
	);
}

const ConterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

const Value = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 48px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
`;

const IconContainer = styled.div`
    height: 30px;
`