import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { FlexAlignJustifyCenter, IconDispatcher } from "shared/ui-basics/index";
import CustomTooltip from "shared/components/CustomTooltip";
import { useHistory } from "react-router-dom";
import BaseActions from "../actions/BaseActions";
import EditableItem from "../../../../shared/ui-basics/ui-components/EditableItem";
import * as Style from "./Styles";
import { UploadImage } from "shared/components/UploadImage";
import React, { useState } from "react";
import { getSessionId } from "@redux/memoizedSelectors";
import { useSelector } from "react-redux";
import { detectImageExtension } from "@utils/helpers";
import { getterApis } from "@utils/optionsSets/getterApis";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { deleteCxProgramImage, updateCxProgramImage } from "../reducer/CxProgramActions";

export const CxProgramCard = ({ card }) => {
	const history = useHistory();
	const redirect = () => history.push(`EditCxProgram?programid=${card.id}`);
	const sessionId = useSelector(getSessionId);
	const userInfo = useSelector((state) => state.auth.userInfo);
	const isReadOnly = userInfo.UserType?.isReadOnly();
	const roleName = card?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName?.toLowerCase();
	const isOwner = roleName === "owner";
	const isViewer = roleName === "viewer";
	const [picture, setPicture] = useState(getterApis["GET_CX_PROGRAM_IMG"](card?.id, sessionId));
	const [loading, setLoading] = useState(false);

	const onSuccess = () => {
		setPicture(getterApis["GET_CX_PROGRAM_IMG"](card?.id, sessionId)+ `&${Math.random()}=true`);
		setLoading(false);

	};

	const imageData = {
		api: getterApis["GET_CX_PROGRAM_IMG"](card?.id, sessionId),
		deleteImage: () => deleteCxProgramImage({ cxProgramId: card?.id, onSuccess }),
	};

	const updateImage = (image) => {
		setLoading(true);
		updateCxProgramImage({ cxProgramId: card?.id, imageExtension: detectImageExtension(image.split(",")[0]), imageBody64: image.split(",")[1], onSuccess });
	};

	return (
		<Style.CardContainer id="CxProgramCard" className="mb-4">
			<Style.Header>
				<Style.Image>
					<UploadImage
						variantImg="ImageSection"
						keyFor={card?.id + "id"}
						id={card?.id}
						deleteImage={isOwner}
						addImageAllowed={!isViewer}
						access={isReadOnly ? "viewer" : true}
						customImage={picture}
						setCustomImage={updateImage}
						onDelete={imageData.deleteImage}
						zoom={true}
						load={loading}
					/>
				</Style.Image>
				<Style.Actions>{CreateActionsDropdown({ actions: BaseActions({ cxProgram: card }) })}</Style.Actions>
			</Style.Header>
			<Style.Body className="p-3">
				<CustomTooltip text={card?.name} disabled={card?.name?.length < 60}>
					<Style.Title className="pb-0" onClick={redirect}>
						{card?.name || t("CX_PROGRAM_NAME")}
					</Style.Title>
				</CustomTooltip>
				<Style.Tags>
					{card?.tags?.slice(0, 2)?.map((tag) => (
						<div key={tag?.id || tag} className="me-1">
							<EditableItem objectId={tag} itemSelectorPath="libraries.tags" context="tag" title="TAGS" />
						</div>
					))}
					{card?.tags.length > 2 && "..."}
				</Style.Tags>
				<Style.Footer className="pt-2">
					{card?.CxNumbers?.map((cx, i) => (
						<div key={i}>
							{card?.[cx.linkName]?.length > 0 && <LinkedEntityBubble key={cx.linkName + i} title={cx.title} count={card?.[cx.linkName]?.length} icon={cx.icon} color={cx.color} />}
						</div>
					))}
				</Style.Footer>
			</Style.Body>
		</Style.CardContainer>
	);
};

const LinkedEntityBubble = ({ title, count, icon, color }) => {
	const { t } = useTranslation();

	return (
		<CustomTooltip text={t(title)}>
			<Style.BubbleCount className="me-2 px-2 py-1" color={color}>
				<FlexAlignJustifyCenter>
					<div className="me-1">{IconDispatcher(icon)}</div>
					<div>{count}</div>
				</FlexAlignJustifyCenter>
			</Style.BubbleCount>
		</CustomTooltip>
	);
};
