import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { FilterItemsByStatus, oppPointColorByCoordinates } from "@utils/helpers";
import { Spinner } from "shared/components/spinners/Spinner";
import { useTranslation } from "react-i18next";
import { Chart } from "shared/components/charts-components/Chart";
import { useLocation } from "react-router";

export const OpportunityChart = ({ selectedOpportunities, pdfRef, loading, filterParams, baseUnit, isDoubleWidth = true }) => {
	const { t } = useTranslation();
	const pathname = useLocation().pathname;

	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const opportunities = useSelector((state) => state.opportunity.all_Opportunities.filter((opp) => FilterItemsByStatus(opp, pathname, "/opportunity", showSampleData)));
	const getFilteredOpps = () => {
		return opportunities.filter((opp) => {
			let statusRes = false;
			let mapRes = false;
			let groupRes = false;
			if (filterParams?.statusIds.length === 0 || filterParams.statusIds.includes(opp.statusId)) statusRes = true;
			if (filterParams?.mapIds.length === 0 || filterParams.mapIds.includes(opp.mapId)) mapRes = true;
			if (filterParams?.groupIds.length === 0 || filterParams.groupIds.filter((groupId) => opp?.groups?.includes(groupId))?.length > 0) groupRes = true;

			return statusRes && mapRes && groupRes;
		});
	};

	const actualOpportunities = selectedOpportunities ? selectedOpportunities : getFilteredOpps();

	const getOppsByPosition = (actualOpportunities) => {
		const oppsByPositionCalc = {};
		actualOpportunities.forEach((opp) => {
			let strCoordinates = (opp.businessValue ?? 0) + "|" + (opp.customerValue ?? 0);
			if (strCoordinates in oppsByPositionCalc) oppsByPositionCalc[strCoordinates].push(opp);
			else oppsByPositionCalc[strCoordinates] = [opp];
		});

		return Object.keys(oppsByPositionCalc)
			?.sort((a, b) => (Number(a.split("|")[1]) < Number(b.split("|")[1]) ? 1 : -1))
			.reduce((obj, key) => {
				obj[key] = oppsByPositionCalc[key];
				return obj;
			}, {});
	};

	const renderFunction = () => {
		if (loading) return <Spinner placeholder={t("LOADING_DATA")} />;
		else if (opportunities?.length > 0)
			return (
				<Chart
					itemsByPosition={getOppsByPosition(actualOpportunities)}
					CustomPoint={OpportunityChartPoint}
					route="edit-opportunity"
					xTitle="BUSINESS_VALUE"
					yTitle="CUSTOMER_VALUE"
					scale={[0, 1, 2, 3, 4, 5]}
					baseUnit={baseUnit}
					pdfRef={pdfRef}
					yAxisPosLeft="-62"
					yAxisPosBottom="-8"
					yAxisTitlePos="50"
					isDoubleWidth={isDoubleWidth}
				/>
			);
	};

	return <>{renderFunction()}</>;
};

const OpportunityChartPoint = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: all 1.5s ease;
	background-color: #c4c4c4;
	background: ${(props) => oppPointColorByCoordinates(props.xCoordinate, props.yCoordinate)};
	color: "#FFFFFF";

	:hover {
		cursor: pointer;
	}

	${(props) =>
		props.multiItems &&
		css`
			width: 40px;
			height: 40px;
		`}
`;
