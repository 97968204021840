import { OverlayPanel } from "primereact/overlaypanel";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const FilterBox = styled.div`
	display: flex;
	align-items: center;
	height: 36px;
	border-radius: 4px;
	border: 1px solid #605f60;
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;
`;

export const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 450px;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

export const CustomOverlayPanel = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0px;
	}

	.p-overlaypanel-close {
		background: #f96209;
		color: #ffffff;
		width: 1.5rem;
		height: 1.5rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		position: absolute;
		top: -12px;
		right: -12px;
	}
`;

export const ItemSelectorCard = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	:hover {
		cursor: pointer;
		background-color: #efefef;
	}
`;
