import { InitActionCreator } from "@redux/store";
import { encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

// ai actions
const BASE_URL = "api/ai/";
const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" });

export const askAlex = (props) => genericService({ ...props, action: createPostAction({ type: "ASK_ALEX", endPoint: "generate", data: {
    prompt: encode(props?.prompt) ,
    max_tokens : props.max_tokens || 100},
    params:{cardId:props.cardId} })
});
export const askCXAssistant = (props) => genericService({ ...props, action: createPostAction({ type: "ASK_CX_ASSISTANT", endPoint: "assistant", data: {
    prompt: encode(props?.prompt) ,
    max_tokens : props.max_tokens || 100}
}) });

export const generatePersonaSection_AI = (props) => genericService({ ...props, action: createPostAction({ type: "GENERATE_PERSONA_SECTION_AI", endPoint: "generateSectionText", data: {
    section: props?.section ,
    name: props.name,
    age: props.age,
    profession: props.profession,
    gender:props.gender,
    language: props?.language,
    location:props?.location,
    sectionCategory:props.sectionCategory,
    advanced: props?.advanced || false},
    params:{cardId:props.cardId}
}) });

export const resetAlexGeneration = ({id}) => {
	return {
		type: "RESET_ALEX_GENERATION",
		payload: id
	};
}

export const generateImage = (props) => genericService({ ...props, action: createPostAction({ type: "GENERATE_IMAGE", endPoint: "generateImage", data: {
    prompt: encode(props?.prompt),
    size: props?.size || ""}
}) });

export const alexAssistantCjm = (props) => genericService({ ...props, action: createPostAction({ type: "ASSISTANT_CJM", endPoint: "assistant", data: {
    prompt: encode(props?.prompt),
    max_tokens: props?.max_tokens},
    params:{ keys: props.keys}
}) });

export const resetAlexAssistantCJM = ({keys}) => {
	return {
		type: "RESET_ASSISTANT_CJM",
		payload: keys
	};
}