import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import React, { useRef, useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { getResultService } from "../reducer/QueryAction";
import { SearchResultComponent } from "./search-result-components";
import { currentWorkspaceId } from "index";
///when we have debounced on the value we need to run the show popOver
//show must get ant target do we create fake button call 'target' and then we run the event click
export const NavigationSearchBar = () => {
	const { t } = useTranslation();
	const envName = window.location.pathname.split("/")[1];
	const [value, setValue] = useState("");
	const [showMenu, setShowMenu] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const searchRef = useRef(null);

	const callbackData = (data) => {
		const formattedOptions =
			Object.keys(data)?.map((category) => ({
				name: category,
				options: data[category],
			})) || [];
		setOptions(formattedOptions);
		setIsLoading(false);
	};

	const redirectAdvanced = (e) => {
		restartOptions();
		window.open(`${envName}/${currentWorkspaceId}/search?query=${value}`, "_blank");
	};
	const restartOptions = (e) => {
		searchRef.current?.clear();
		setIsLoading(false);
		setShowMenu(false);
	};

	const handleSearch = (query) => {
		setShowMenu(true);
		setIsLoading(true);
		setValue(query);
		getResultService(query, false, callbackData);
	};
	const returnObject = (option) => {
		return { [option.name]: option.options };
	};
	const renderMenu = (results, menuProps) => {
		if (!showMenu) return <></>;
		return (
			<MenuWrapper id="async-search">
				<BodyData>
					{isLoading && <>{t("SEARCH_IN_PROGRESS")}</>}
					{!isLoading && results.map((option, index) => <SearchResultComponent key={index} result={returnObject(option)} resetFunction={restartOptions} />)}
					{!isLoading && results.length === 0 && <>{t("NO_DATA")}</>}
				</BodyData>
				<MenuItemWrapper option={{}}>
					<Footer className="pt-3">
						<GenericButton variant="primary-outline" onClick={redirectAdvanced}>
							{t("ADVANCED_SEARCH")}
						</GenericButton>
					</Footer>
				</MenuItemWrapper>
			</MenuWrapper>
		);
	};
	const filterBy = () => true;
	const clickAutoFocus = () => {
		setTimeout(() => searchRef.current?.focus(), 100);
	};
	return (
		<div>
			<div className="input-wrapper-ConceptSearchBar">
				<button className="icon-ConceptSearchBar" onClick={clickAutoFocus}>
					{IconDispatcher("SEARCH")}
				</button>
				{
					<AsyncTypeahead
						className={`div-ConceptSearchBar `}
						filterBy={filterBy}
						id="async-search"
						isLoading={false}
						labelKey="name"
						minLength={2}
						onSearch={handleSearch}
						options={options}
                        placeholder={`${t("SEARCH")}...`}
						renderMenu={renderMenu}
						onBlur={restartOptions}
						delay={600}
						useCache={false}
						ref={searchRef}
						inputProps={{ className: "input-NavigationSearchBar" }}
					/>
				}
			</div>
		</div>
	);
};

const MenuWrapper = styled(Menu)`
	width: 425px;
	direction: ltr;
	max-height: 420px !important;
`;
const BodyData = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 340px;
	padding: 1.25rem;
`;
const MenuItemWrapper = styled(MenuItem)`
	:hover {
		background-color: transparent;
	}
`;
const Footer = styled.div`
	width: 100%;
	border-top: 1px solid #c4c4c4;
`;
