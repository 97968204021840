import DataTable from "features/grid-table/DataTable";
import { useAsyncs } from "shared/hooks/index";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { libraryInit, getAllPersonas, touchPointInit, tagInit, groupInit, getAllInsightTypes, getAllMapsListData, touchPointCategoriesInit, statusInit, priorityInit } from "@redux/index";
import { getAllInsights } from "features/insights/reducer/InsightActions";
import { getFolderById } from "@redux/memoizedSelectors";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Insights() {
	const { t } = useTranslation();
	const { search } = useLocation();

	const folderId = search.getFolderId();
	const { linkedInsights } = useSelector((state) => getFolderById(state, folderId), shallowEqual);
	useAsyncs({
		asyncFns: [
			{ asyncFn: getAllInsights },
			{ asyncFn: getAllMapsListData },
			{ asyncFn: getAllInsightTypes },
			{ asyncFn: getAllPersonas },
			{ asyncFn: touchPointInit },
			{ asyncFn: touchPointCategoriesInit },
			{ asyncFn: libraryInit },
			{ asyncFn: tagInit },
			{ asyncFn: groupInit },
			{asyncFn: statusInit},
			{ asyncFn: priorityInit}
		],
	});

	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("ASPX_33")}`}>
			<DataTable context="insights.insightList" linked={linkedInsights} />
		</PageTemplate>
	);
}
