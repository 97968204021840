import { EMPTY_GUID } from "@utils/helpers";
import { CardContainer } from "features/analytics/analyticsHelper";
import Personas from "features/grid-table/columns-components/Personas";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components/macro";

function MapListCard({ item, isLastItem, onClose }) {
	const history = useHistory();
	const redirect = () => {
		onClose();
		history.push(`/EditMap?cjmid=${item?.cjmId}&personaId=${item?.linkedPersonas[0]?.personaId || EMPTY_GUID}&origin=1`);
	};
	return (
		<CardContainer className="p-4" islast={isLastItem}>
			<Body>
				<Name onClick={redirect}>{item?.name}</Name>
				<div>{Personas(item)}</div>
			</Body>
		</CardContainer>
	);
}

MapListCard.displayName = "MapListCard";
export default MapListCard;

const Body = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.div`
	cursor: pointer;
	max-width: 500px;
`;
