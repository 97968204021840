import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Dropdown,
    DropdownItem
} from 'reactstrap';
import { IconDispatcher } from 'shared/ui-basics/index';
import * as Style from './style';

export default function Priority({ object, setObject, name, disable, updateFunction }) {

    const { t } = useTranslation()
    const all_priority = useSelector(state => state.libraries.priority)
    const priority = all_priority && all_priority[name]
    const [togglePriority, setTogglePriority] = React.useState(false);
    const selected = getSelected(priority, object)
    const showPlaceHolder = Object.keys(selected)?.length === 0

    const togglePriorityToggle = () => {
        setTogglePriority(!togglePriority);

    }
    const handlePriority = (e) => {
        const id = e.currentTarget.id
        const updatedObject = { ...object, priorityId: id }
        setObject && setObject(updatedObject)
        if (updateFunction) {
            updateFunction(updatedObject, ["priorityId"])
        }
    }
    return <>
        {priority && <Dropdown className='w-100' isOpen={togglePriority} toggle={togglePriorityToggle} direction={'down'} disabled={disable}>
            <Style.ToggleCaret className='w-100' disabled={disable}>
                {showPlaceHolder ? <div>{t('NO_PRIORITY')}</div> : <>
                    <Style.PriorityTypes colorcode={selected?.colorCode}>
                        <div id='point'></div>
                        <Style.MaxWidth className='text-truncate'>{selected?.name}</Style.MaxWidth></Style.PriorityTypes>
                    <div className="caret">{IconDispatcher(togglePriority ? "ANGLE-UP" : "ANGLE-DOWN")}</div>
                </>
                }

            </Style.ToggleCaret>
            <Style.MenuDrop className="p-3" >
                {priority?.map(priorityLevel => <DropdownItem key={priorityLevel?.id} id={priorityLevel?.id} onClick={handlePriority}>
                    <Style.PriorityTypes className='py-2' colorcode={priorityLevel.colorCode} ><div id='point'></div><Style.MaxWidth className='text-truncate'>{priorityLevel?.name}</Style.MaxWidth></Style.PriorityTypes>
                </DropdownItem>)}
            </Style.MenuDrop>
        </Dropdown>}
    </>
}

const getSelected = (priority, object) => {
    if (priority?.length > 0) {
        const found = priority?.find(prio => prio.id === object?.priorityId)
        if (found) return found
        else return priority[0]

    } else {
        return {}
    }
}