import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { getExcelExportListService, getLinkedCjmsExcelExportService, updateCjmStatus, updateStateCJM } from "features/index";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { BulkActions } from "features/journey-map/actions/BulkActions";
import { EntityList, StateTypeOption, StatusCode } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, isFutureValidation, mapModeValidation, tableDefaultColumn } from "@utils/helpers";
import { t } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useModal } from "use-modal-hook";
import BaseActions from "../../../journey-map/actions/BaseActions";
import { ActionLink } from "../../columns-components/All_actions/ActionLink";
import AICreation from "features/journey-map/modals/AI/ai-creation";

export function GetJourneyMapContext(props) {
	const { linked, showAllActiveRecord, setRename, disableBulkActions } = props;
	const { pathname, search } = useLocation();
	const cxProgramId = useLocation().search.getCxProgramID();
	const history = useHistory();
	const specificContext = props.context.split(".")[1];
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const folderId = search.getFolderId();
	const maps = useSelector((state) => state.maps.all_maps).filter((map) => MapValidation(map, props.tab, pathname, showSampleData, folderId));
	const linkedObjects = linked ? maps?.filter((map) => linked?.includes(map.cjmId)) : undefined;
	const loading = useSelector((state) => state.maps.loading);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.CustomerJourney]?.[specificContext]?.columns ?? tableDefaultColumn(EntityList.CustomerJourney);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.CustomerJourney]?.[specificContext]?.filters ;

	const mapData = useMemo(() => FormatMapData(maps), [maps]);
	const goToMapCreation = () => {
		history.push(`/map-creation${folderId ? `?folderId=${folderId}` : ""}`);
	};
	const updateCjmType = (value, ids) => {
		updateStateCJM({ id: ids, value });
	};
	const updateStatus = (cjms, attKey) => {
		updateCjmStatus({ cjmIdsStr: cjms?.map((cjm) => cjm.cjmId).join("|"), newValueId: cjms?.[0]?.[attKey] });
	};
	const [showListLinkedModal] = useModal(ListLinkedModal);

	const data = useMemo(() => {
		const newData = {
			home: {
				showCheckbox: false,
				columns: ["select","name", "linkedPersonas", "lastModifiedOn", "isFutureLabel", "score", "actions"],
				emptyListText: "MAP_EMPTY_LIST",
				bulkActions: false,
				linked: linked,
				clickToOpen: true,
				disableFixHeader: false,
				showOnlyLinked: true,
				noColumnHeader: false,
				showPagination: false,
				nonDropDown: true,
			},
			journeyMap: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "MAP_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				visibleHeader: true,
				listEditable: true,
				showPagination: true,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
				aiGeneration: true

			},
			archived: {
				showCheckbox: true,
				columns: ["select", "name", "linkedPersonas", "lastModifiedOn", "isFutureLabel", "score", "actions"],
				emptyListText: "MAP_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "name", "linkedPersonas", "lastModifiedOn", "isFutureLabel", "score", "actions"],
				emptyListText: "MAP_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			templates: {
				showCheckbox: true,
				columns: ["select", "name", "linkedPersonas", "lastModifiedOn", "owner", "isFutureLabel", "templateStatus", "actions"],
				emptyListText: "NO_JOURNEY_MAP_TEMPLATE_REGULAR",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			archivedTemplates: {
				showCheckbox: true,
				columns: ["select", "name", "linkedPersonas", "lastModifiedOn", "owner", "isFutureLabel", "templateStatus", "actions"],
				emptyListText: "NO_JOURNEY_MAP_TEMPLATE_REGULAR",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deletedTemplates: {
				showCheckbox: true,
				columns: ["select", "name", "linkedPersonas", "lastModifiedOn", "owner", "isFutureLabel", "templateStatus", "actions"],
				emptyListText: "NO_JOURNEY_MAP_TEMPLATE_REGULAR",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			cxProgram: {
				showCheckbox: false, // modals of cxProgram that link/unlink
				columns: ["name", "linkedPersonas", "lastModifiedOn", "owner", "isFutureLabel", "actions"],
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				showPagination: linked?.length > 10,
				nonDropDown: true,
				emptyListText: "JOURNEY_MAP_PLACEHOLDER_PROGRAM",
			},
			editPersona: {
				showCheckbox: false,
				bulkActions: false,
				columns: ["name", "linkedPersonas", "isFutureLabel", "lastModifiedOn", "statusId", "score","actions"],
				emptyListText: "NO_MAP_TO_DISPLAY",
				clickToOpen: true,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: false,
				emptyListSmall: "true",
				showPagination: false,
			},
			library: {
				showCheckbox: false,
				bulkActions: false,
				columns: ["name", "stage", "linkedPersonas", "owner"],
				emptyListText: "NO_MAP_TO_DISPLAY",
				clickToOpen: true,
				showOnlyLinked: true,
				linked: linked,
				disableFixHeader: true,
				showPagination: false,
			},
		};
		const contextData = _.get(newData, specificContext);
		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.Actions = (map) => CreateActionsDropdown({ actions: BaseActions({ map, setRename, personaIdStr: map.linkedPersonas[0]?.personaId || EMPTY_GUID }) ,variant:'default',dropdownClasses:""});
		if(specificContext === 'editPersona') {
			// no need to return action but for css we return empty component
			contextData.Actions = (map) => FakeComponent();
		}
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = linkedObjects;
		contextData.listData = mapData;
		contextData.dataKey = "cjmId";

		contextData.entityType = EntityList.CustomerJourney;
		contextData.reducerPath = "maps.all_maps";
		contextData.exportExcel = getExcelExportListService;
		contextData.getLinkedDataExportExcel = getLinkedCjmsExcelExportService;
		contextData.loading = loading;
		//   contextData.all_personas = all_personas;
		contextData.buttonCreateLabel = "ASPX_124";
		contextData.buttonCreate = goToMapCreation;
		contextData.filter = filters;
		contextData.onChangeStatus = contextData?.listEditable ? updateStatus : null;
		contextData.onChangeState = contextData?.listEditable ? updateCjmType : null;
		contextData.readonly = isReadOnly;
		if(contextData.aiGeneration) {
			contextData.aiModal = AICreation;
		}

		if (specificContext === "library") {
			contextData.linkedObjects = linked; // in this case we recieved straights the entire object and not just the id because of stageName that is specific recieved from the server
		}
		if (specificContext === "cxProgram") {
			contextData.Actions = props?.disableAction ? () => {} : (options) => ActionLink(options, "cem_customerjourney", cxProgramId);
		}

		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.CustomerJourney, saveButtonLabel: "ASPX_44" });
			contextData.LinkActionLabel = "ASPX_164";
			contextData.displayExport = false;
		}
		return contextData;
	}, [isReadOnly, linked, linkedObjects, mapData, columnsArray, loading, disableBulkActions, showAllActiveRecord, folderId, setRename, cxProgramId, filters]);
	return data;
}

const FormatMapData = (maps) => {
	return maps?.map((map) => {
		const newDate = new Date(map.lastModifiedOn);
		const linkedPersonas = map?.linkedPersonas?.map((persona) => {
			return persona.personaId;
		});
		return {
			...map,
			lastModifiedOn: newDate,
			concatPersona: linkedPersonas?.join("|"),
			mapType: t(mapModeValidation(map?.mapMode)),
			isFutureLabel: t(isFutureValidation(map?.isFuture)),
			isFutureValue: map?.isFuture ? StateTypeOption.FutureState : StateTypeOption.CurrentState,
			groupIds: map?.groups?.join("|"),
			tagIds: map?.tags?.join("|"),
			statusId: map?.cxStatus,
		};
	});
};
export const MapValidation = (map, tab, pathname, showSampleData, folderId) => {
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";
	const isTemplate = pathname === "/templates" || tab === "template";
	const isCJM = pathname === "/journey-map";
	const cxProgram = pathname === "/EditCxProgram";
	const isActive = pathname === tab;

	if (map?.statusCode === StatusCode.Revised) return false;

	if (cxProgram) {
		if (showSampleData) {
			return map.statusCode === StatusCode.Active && map.isTemplate === false ;
		} else {
			return map.statusCode === StatusCode.Active && map.isTemplate === false  && map.isSample === false;
		}
	}
	if (isCJM || isActive) {
		if (showSampleData) return map.statusCode === StatusCode.Active && map.isTemplate === false ;
		return map.statusCode === StatusCode.Active && map.isSample === false && map.isTemplate === false ;
	}

	if (isTrash && !isArchive) {
		return tab === "template" ? map.statusCode === StatusCode.Trashed && map.isTemplate === true : map.statusCode === StatusCode.Trashed && map.isTemplate === false;
	}

	if (isTemplate && !isArchive) {
		return map.isTemplate === true && map.statusCode === StatusCode.Active;
	}
	if (isArchive && !isTrash) {
		return tab === "template" ? map.statusCode === StatusCode.Archived && map.isTemplate === true : map.statusCode === StatusCode.Archived && map.isTemplate === false;
	}
	return map;
};

function FakeComponent()  {
	return null;
}
FakeComponent.displayName = "FakeComponent";