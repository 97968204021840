import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components";
import WorkspaceDropdown from "../components/dropdowns/workspace-dropdown";
import { invalidWorkspaceStatus } from "@utils/optionsSets/OptionSets";
import { Dropdown } from "react-bootstrap";

//const statuses = { 1: "DISABLED", 2: "REMOVED", 3: "DISABLED-SWITCH-ON", 4: "REMOVED-SWITCH-ON" };

export default function WorkspaceInvalidPage(props) {
	const { t } = useTranslation();
	const { search } = useLocation();

	const { statusCode } = search.getInvalidWorkspaceProps();
	const code = statusCode ? statusCode : props?.statusCode;
	const { Disabled, Removed, DisabledSwitch, RemovedSwitch } = invalidWorkspaceStatus;

	const getFirstLabel = () => {
		if ([Disabled, DisabledSwitch].includes(code)) return "WORKSPACE_DISABLED";
		return "REMOVED_FROM_WORKSPACE";
	};

	const ToggleComponent = () => {
		return (
			<Dropdown.Toggle className="px-1" as={Button} variant="primary" isicon="false" size="m">
				<div>{t("SWITCH_WORKSPACE")}</div>
			</Dropdown.Toggle>
		);
	};

	return (
		<div className="full-screen d-flex flex-column">
			<WrapperImg>{IconDispatcher("GHOST", "m-3 mt-0 mb-4", { style: "thin" })}</WrapperImg>
			<CustomContainer>
				<div className="mb-2">{t(getFirstLabel())}</div>
				{[Disabled, Removed].includes(code) && <div>{t("CONTACT_ADMINISTRATOR")}</div>}
				{[DisabledSwitch, RemovedSwitch].includes(code) && (
					<div className="mt-4">
						<WorkspaceDropdown toggleComponent={ToggleComponent} nameWidth="134" />
					</div>
				)}
			</CustomContainer>
		</div>
	);
}
const WrapperImg = styled.div`
	svg {
		height: 48px;
	}
`;

export const CustomContainer = styled.div`
	width: 50% !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: #333;
	text-align: center;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
