import styled from "styled-components/macro";

export const CardContainer = styled.div`
	text-decoration: none;
	width: 360px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
`;

export const Actions = styled.div`
	position: absolute;
	display: none;
	top: 20px;
	right: -10px;
`;

export const Header = styled.div`
	position: relative;
	height: 180px;
	border-bottom: 1px solid #c4c4c4;
	:hover ${Actions} {
		display: flex;
	}
`;

export const Image = styled.div`
	height: 100%;
    img {
		max-width: 100%;
		width: 100%;
		max-height: 179px;
		cursor: pointer;
		object-fit: cover;
		&.selected {
			border: 3px solid #f96209;
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		}
	}
`;

export const Body = styled.div`
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
`;

export const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	min-height: 40px;
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-top: 1px solid #c4c4c4;
	min-height: 40px;
`;

export const Title = styled.div`
	min-height: 40px;
	color: #333;
	font-family: "Inter";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	max-width: max-content;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	:hover {
		cursor: pointer;
	}
`;

export const BubbleCount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	color: ${(props) => props.color};
	border: 1px solid ${(props) => props.color};

	font-family: "Source Code Pro";
	font-size: 12px;
	font-style: normal;
	line-height: normal;
	font-weight: 400;
`;
