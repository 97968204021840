import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { PermissionManager } from "@utils/permissionManager";
import DisplayState from "shared/cx-components/DisplayState";

 function State({entity, dataKey, path, onChangeState}) {
    const object = useSelector((state) => _.get(state, path))?.find((x) => x[dataKey] === entity[dataKey]);

	const userInfo = useSelector((state) => state.auth?.userInfo);
	const roleName = object?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName;
	const { editRowAllowed } = PermissionManager({ userAccess: roleName });

	const selectedIds = useSelector((state) => state.ui.table.selectedIds);
	const handleChange = (newObject) => {
		if (selectedIds?.map((x) => x[dataKey])?.includes(object[dataKey])) {
			//updated all the selected list
			const ids = selectedIds?.map((x) => x[dataKey]).join("|");
			onChangeState && onChangeState(newObject["isFuture"], ids);
		} else {
			onChangeState && onChangeState(newObject["isFuture"], object[dataKey]);
		}
	};
	return <DisplayState object={object} updateFunction={handleChange} disable={!editRowAllowed} />;
}
export default State;