import { genericService } from "shared/GenericService";
import { constructUrl } from "@utils/helpers";

const InitActionCreator = ({ baseUrl, method }) => {
    return ({ type, endPoint, data, responseType, params, meta }) => ({
        type,
        request: {
            url: baseUrl + endPoint,
            method,
            data,
            responseType,
        },
        meta,
        params,
    });
};
const createPostAction = InitActionCreator({ baseUrl: "api/settings/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/settings/", method: "GET" }); // api actions type get

export const getSubscriptionDetails = () => createGetAction({ type: 'GET_SUBSCRIPTION_DETAILS', endPoint: "getSubscriptionDetails" })
export const getSubscriptionConfig = () => createGetAction({ type: 'GET_CONFIGURATIONS', endPoint: "getSubscriptionConfig" })
export const getOrganizationSettings = (props) => createGetAction({
    type: 'GET_ORGANIZATION_SETTINGS',
    endPoint: constructUrl("getOrganizationSettings", {
        redirectReducer: props?.redirectReducer
    })
})
export const getOrganizationFn = (props) => genericService({ ...props, action: getOrganizationSettings });

export const updateAccountSettings = ({ newLangValue, newShowSampleValue, ...props }) => genericService({
    ...props, action: createPostAction({
        type: "UPDATE_ACCOUNT_SETTINGS",
        endPoint: "updateAccountSettings",
        data: { newLangValue, newShowSampleValue }
    })
});

export const uploadAccountImage = ({ imageExtension, imageBody64, ...props }) => genericService({
    ...props, action: createPostAction({
        type: "UPDATE_ACCOUNT_IMAGE",
        endPoint: "uploadAccountImage",
        data: {imageExtension, imageBody64 }
    })
});

export const deleteAccountImage = ({...props }) => genericService({
    ...props, action: createPostAction({
        type: "DELETE_ACCOUNT_IMAGE",
        endPoint: "deleteAccountImage",
    })
});

export const updateIntegrationCredentials = ({id, mode, password, url, ...props }) => genericService({
    ...props, action: createPostAction({
        type: "UPDATE_INTEGRATION_CREDENTIALS",
        endPoint: "verifyAndUpdateIntegrationCredentials",
        data: {
            IntegrationPassword: window.btoa(unescape(encodeURIComponent(password))),
            IntegrationUserName: id,
            OrganizationName: url,
            IntegrationMode: mode,
        }
    })
});

export const updateQuery = (type, queryInfo) => ({ type: 'UPDATE_QUERY', payload: { type, queryInfo }})

export const aiAgreementPolicy = (props) => genericService({
    ...props, action: createPostAction({
        type: "AGREEMENT_POLICY",
        endPoint: "consentAI",
        data: {
            newValue: props.value
        }
    })
});

export const enableAlex = (props) => genericService({
    ...props, action: createPostAction({
        type: "ENABLE_ALEX",
        endPoint: "enableAI",
        data: {
            newValue: props.enable
        }
    })
});