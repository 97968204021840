import { FilterItemsByStatus } from "@utils/helpers";
import { Column } from "primereact/column";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import DataTable from "../DataTable";
import * as Style from "../columns-components/Styles";

const GroupByMap = forwardRef(() => {

    const { t } = useTranslation()
    const pathname = useLocation().pathname
    const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);

    const maps = useSelector(state => state.maps.all_maps?.filter(map => state.actions.all_actions?.filter(action => FilterItemsByStatus(action, pathname, "/cx-actions", showSampleData))?.find(action => action.mapId === map.cjmId)))

    const [expandedRows, setExpandedRows] = useState(null);

    const RowExpansionTemplate = (data) => {

        return (<div className=" px-2">
            <DataTable context='cxActions.cxActionsListGroup' cjmId={data.cjmId} />
        </div>);
    }

    const allowExpansion = (rowData) => {
        return true || rowData?.orders?.length > 0;
    };
    const onRowClick = (cjmId) => {
        if(expandedRows?.includes(cjmId)) return setExpandedRows([]);
        setExpandedRows([cjmId]);
    };

    return <Style.DataTableCustom value={maps}
        expandedRows={expandedRows?.map(id => maps.find(map => map.cjmId === id))}
         onRowClick={(e) => onRowClick(e.data?.cjmId)}
        onRowToggle={(e) => {
            onRowClick(e.data[0]?.cjmId);
        }}
        responsiveLayout="scroll"
        rowExpansionTemplate={RowExpansionTemplate}
        disablefixheader='true'

    >
        <Column expander={allowExpansion}  style={{ width: '3em' }} />
        <Column field="name" header={t('ASPX_125')}  style={{ width: '100%' ,cursor:'pointer'}} sortable />

    </Style.DataTableCustom>
})
GroupByMap.displayName = 'GroupByMap'
export { GroupByMap };
