import React, { useImperativeHandle } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { FlexAlignCenter, FlexBetweenAlignCenter, Label } from '../ui-basics';
import GenericSwitch from '../ui-basics/buttons';
import Draggable from 'react-draggable';

import { IconDispatcher } from '../Icons';
const DraggablePopoverContent = ({ children }) => {
    // Prevent dragging from triggering click events (like closing the popover)
    const handleStart = (event) => {
        event.stopPropagation();
    };

    return (
        <Draggable onStart={handleStart}>
            <div>
                {children} {/* This is your popover content */}
            </div>
        </Draggable>
    );
};

function GenericPopover({ children, params, custom, disabled, innerRef, onToggle }) {

    const [show, setShow] = React.useState(false);

    useImperativeHandle(innerRef, () => ({
        hide_popover: () => setShow(false),
        show_popover: () => setShow(true),
        show
    }));

    const handleToggle = (visible) => {
        if (disabled) return;
        setShow(visible);
        onToggle && onToggle(visible);
    };
    const ItemComponent = (props) => {
        const { id, icon,customIcon, text, onClick, separator, switch: { value, disabled } = {} } = props
        const click = (e) => {
            e.stopPropagation();
            onClick(e);
        }
        return <Item id={id} onClick={click} className='px-2'>
            {separator ? <Separator /> : <FlexBetweenAlignCenter className='w-100'>
                <FlexAlignCenter>
                    {customIcon && customIcon}
                    {icon && IconDispatcher(icon?.name || icon, icon.className || "", icon?.params || {})}
                    {text && <Label className="px-2 ellipsis" size={14}>{text}</Label>}
                </FlexAlignCenter>
                {props.switch && <GenericSwitch isChecked={value} isdisabled={disabled} handleToggle={() => { }} />}
            </FlexBetweenAlignCenter>}
        </Item>
    }
    const popoverBody = <Popover.Body className={'overflow-hidden '}>
        {custom ? custom : params?.map((param) => <ItemComponent key={param?.id} {...param} />)}
    </Popover.Body>

    const popoverContent = (
        <PopoverContainer id="popover-basic"
            width={params?.width}
            hidearrow={params?.hideArrow?.toString()}
            background={params?.background}
            shadow={params?.shadow}
            annimation={params?.annimation}>
            {params?.draggable ? <DraggablePopoverContent> {popoverBody}</DraggablePopoverContent> :
                popoverBody}
        </PopoverContainer>

    );
    return (<div><OverlayTrigger
        trigger="click"
        placement={params?.placement || "bottom"}
        overlay={popoverContent}
        show={show}
        onToggle={handleToggle}
        rootClose

    >
        <div className='pointer'>
            {children}
        </div>
    </OverlayTrigger>
    </div>
    )
}
export default React.memo(GenericPopover)

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px 0;
    ${props => props.id !== 'separator' && 'height:38px;'};
    cursor: ${props => props.id !== 'separator' && 'pointer'};
    min-width: 150px;
    &:hover {
        ${props => props.id !== 'separator' && 'background:  #f4f4f4;'};
    }
`;
const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: #C4C4C4;
    margin: 5px 0;
`;
const PopoverContainer = styled(Popover)`

border: none;
background: transparent;
box-shadow: unset;
width: ${props => props.width + 'px' || 'auto'};
max-width: ${props => props.width + 'px' || 'auto'};
.popover-arrow {
   ${props => props.hidearrow === 'true' && 'display:none;'}
}
.popover-body {
    border-radius: 4px;
    padding:2px;
    background: ${props => props.background ? props.background : '#ffffff'} ;
    ${props => props.annimation && `animation: animatedgradient 5s ease alternate infinite`};

    box-shadow: ${props => props.shadow ? props.shadow : '-2px -2px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 4px 0px rgba(0, 0, 0, 0.25)'};
}
@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

`