import { getAllPersonas } from '@redux/index';
import TextSuggestions from 'features/ai/components/TextSuggestion';
import PersonaBubble from 'features/forms/personas/components/PersonaBubble';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CascadeLoader from 'shared/components/spinners/CascadeLoader';
import { Spinner } from 'shared/components/spinners/Spinner';
import GenericHeader from 'shared/headers/GenericHeader';
import { useAsync } from 'shared/hooks';
import { FlexAlignCenter } from 'shared/ui-basics';
import styled from 'styled-components';

export default function JourneyMapLoading() {

    const [details, setDetails] = useState(null)
    const persona = useSelector((state) => state.personas?.all_personas?.find(p => p.personaId === details?.personaId))
    const params = useSelector((state) => state.maps?.creationCjmParams)
    const isAiGeneration = useSelector((state) => state.maps?.aiGeneration)


    useAsync({ asyncFn: getAllPersonas })
    useEffect(() => {
        if (params) {
            setDetails(params)
        }
    }, [params]);
    return (
        <MapContainer>
            <GenericHeader backLabel='Journey map'>
                <FlexAlignCenter>
                    {persona && <PersonaBubble size='80' persona={persona} />}
                    <div className='ms-3'>
                        <h3 className='p-0 m-0'>{isAiGeneration ? <TextSuggestions text={details?.name} displayButton={false} size='24' speed={30} className='' regularMode={true} /> : details?.name}</h3>
                        <div> {isAiGeneration ? <TextSuggestions text={details?.description} displayButton={false} size='14' speed={15} className=''  regularMode={true}/> : details?.description}</div>
                    </div>
                </FlexAlignCenter>
            </GenericHeader>
            <SwimlanesContainer>
                {isAiGeneration ? <CascadeLoader words={["Stages", "Touchpoints", "Gains", "Pains", "Findings"]} /> : <Spinner />}
            </SwimlanesContainer>
        </MapContainer>
    )
}

const MapContainer = styled.div`
	padding: 0.5rem;
	font-size: 14px;
`;
const SwimlanesContainer = styled.div`
	margin-top: 8px;
	width: 100%;
	overflow: auto;
	height: calc(100vh - 220px);
	position: relative;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	background: #fff;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;

`;
