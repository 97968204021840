import BulkModalLinkComponent from "shared/modals/bulk-links/BulkModalLinkComponent";
import { SelectInsightTypeModal } from "features/insights/modals/SelectInsightTypeModal";
import { ActionConfirmModal } from "shared/modals/index";
import { BulkActionsGenerator } from "shared/components/BulkActionsGenerator";
import { archiveInsights, deleteInsights, deleteInsightsPermanent, restoreInsights } from "features/insights/reducer/InsightActions";
import { filterItemsByUserRole } from "@utils/helpers";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useModal } from "use-modal-hook";

export const BulkActions = (selectedInsights, setSelected, onSuccess, setDisableBulkActions) => {
	const pathname = useLocation().pathname;
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";

	const actions = [];
	const insights = useSelector((state) => state.insights.all_insights);
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const selectedItemsIdsStr = selectedInsights.map((item) => item?.id).join("|");
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);

	const filteredItemsIdsStr = selectedInsights?.map((insight) => insight.id).join("|")
	const filteredItemsByOwnersAndContrib = filterItemsByUserRole({
		allItems: insights,
		selectedItems: selectedInsights,
		currentUserId,
		isSubscriptionStandard: !isSubscriptionAllowed,
		userRoles: ["owner", "contributor"],
	});
	const areAllActionsValid = selectedItemsIdsStr.length === filteredItemsIdsStr.length;
	const [showModalArchivePopup] = useModal(ActionConfirmModal, {
		bodyContent: "ARCHIVE_MANY_GENERIC",
		Action: () => {
			setDisableBulkActions(true);
			archiveInsights({ ids: filteredItemsIdsStr.split("|"), displayToast: areAllActionsValid ? "BULK_ARCHIVE_SUCCESS" : "BULK_ARCHIVE_PARTIAL", onSuccess });
		},
		actionBtnText: "ARCHIVE",
	});

	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_GENERIC",
		Action: () => {
			setDisableBulkActions(true);
			deleteInsights({ ids: filteredItemsIdsStr.split("|"), displayToast: areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess });
		},
		actionBtnText: "ASPX_13",
	});

	const [showModalDeletePermanentPopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_PERMANENT_GENERIC",
		Action: () => {
			setDisableBulkActions(true);
			deleteInsightsPermanent({ ids: filteredItemsIdsStr.split("|"), displayToast: areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess });
		},
		actionBtnText: "ASPX_13",
	});
	const [selectInsightTypeModal] = useModal(SelectInsightTypeModal);

	const [showBulkModalLinkComponent] = useModal(BulkModalLinkComponent);

	if (isTrash || isArchive) {
		if (filteredItemsIdsStr) actions.push({ handleClick: () => restoreInsights({ ids: filteredItemsIdsStr.split("|"), onSuccess }), icon: "RESTORE", description: "JS_96" });
		if (filteredItemsIdsStr) actions.push({ handleClick: isArchive ? showModalDeletePopup : showModalDeletePermanentPopup, icon: "DELETE", description: isArchive ? "ASPX_13" : "JS_97" });
	} else {
		if (filteredItemsIdsStr) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "folder", idAttribute: "id" }), icon: "FOLDER-OPEN", description: "MOVE_TO_FOLDER" });

			if (isSubscriptionAllowed) actions.push({ handleClick: showModalArchivePopup, icon: "ARCHIVE", description: "ARCHIVE" });
			actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", description: "ASPX_13" });
		}
		// if (filteredItemsByOwnersAndContrib) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "tag", idAttribute: "id" }), icon: "TAG", description: "TAG" });
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "group", idAttribute: "id" }), icon: "SHAPES", description: "GROUP" });
			actions.push({ handleClick: () => selectInsightTypeModal({ selectedItems: selectedInsights?.map((insight)=>({insightId:insight?.id}))}), icon: "CIRCLE-CARET-DOWN", description: "JS_78" });
		// }
	}

	return <BulkActionsGenerator actions={actions} setSelected={setSelected} warningText={"BULK_ACTIONS_MAP_ONLY"} />;
};
