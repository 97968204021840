import { filterPersonasByLinkedMaps, filterPersonasByUserRole } from "@utils/helpers";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { BulkActionsGenerator } from "shared/components/BulkActionsGenerator";
import BulkModalLinkComponent from "shared/modals/bulk-links/BulkModalLinkComponent";
import { ActionConfirmModal } from "shared/modals/index";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import { useModal } from "use-modal-hook";
import { archiveManyPersonas, deleteManyPersonas, deletePermanentlyManyPersonas, restoreManyPersonas } from "../reducer/PersonaActions";
export const BulkActions = (selectedPersonas, setSelected, onSuccess, setDisableBulkActions) => {
	const pathname = useLocation().pathname;
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";
	const isTemplate = pathname === "/templates";
	const actions = [];

	const allMaps = useSelector((state) => state.maps.all_maps);
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const selectedItemsIdsStr = selectedPersonas.map((item) => item?.personaId).join("|");
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);

	const filteredItemsIdsStr = filterPersonasByLinkedMaps({ allMaps, selectedPersonas, currentUserId, isSubscriptionStandard: !isSubscriptionAllowed });

	const filteredItemsByOwnersAndContrib = filterPersonasByLinkedMaps({
		allItems: allMaps,
		selectedPersonas,
		currentUserId,
		isSubscriptionStandard: !isSubscriptionAllowed,
		userRoles: ["owner", "contributor"],
	});

	// For Manage access : Filter personas based on user role only
	const filteredItemsByRoleIdsStr = filterPersonasByUserRole({ selectedPersonas, currentUserId });

	const areAllActionsValid = selectedItemsIdsStr.length === filteredItemsIdsStr.length;

	const [showAccessModal] = useModal(GenericManageAccess);
	const manageAccessMany = () => showAccessModal({ ids: filteredItemsByRoleIdsStr, bulk: true, onSuccess });

	const [showModalArchivePopup] = useModal(ActionConfirmModal, {
		bodyContent: "ARCHIVE_MANY_PERSONAS",
		Action: () => {
			setDisableBulkActions(true);
			archiveManyPersonas({ids:filteredItemsIdsStr, displayToast: areAllActionsValid ? "BULK_ARCHIVE_SUCCESS" : "BULK_ARCHIVE_PARTIAL", onSuccess});
		},
		actionBtnText: "ARCHIVE",
	});

	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_PERSONAS",
		Action: () => {
			setDisableBulkActions(true);
			deleteManyPersonas({ids:filteredItemsIdsStr, displayToast:areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess});
		},
		actionBtnText: "ASPX_13",
	});

	const [showModalDeletePermanentPopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_PERMANENT_PERSONAS",
		Action: () => {
			setDisableBulkActions(true);
			deletePermanentlyManyPersonas({ids:filteredItemsIdsStr, displayToast:areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess});
		},
		actionBtnText: "ASPX_13",
	});

	const [showBulkModalLinkComponent] = useModal(BulkModalLinkComponent);

	if (isTrash || isArchive) {
		if (filteredItemsIdsStr) actions.push({ handleClick: () => restoreManyPersonas({ids:filteredItemsIdsStr, onSuccess}), icon: "RESTORE", description: "JS_96" });
		if (filteredItemsIdsStr) actions.push({ handleClick: isArchive ? showModalDeletePopup : showModalDeletePermanentPopup, icon: "DELETE", description: isArchive ? "ASPX_13" : "JS_97" });
	} else {
		if (filteredItemsByOwnersAndContrib) {

			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "folder", idAttribute: "personaId" }), icon: "FOLDER-OPEN", description: "MOVE_TO_FOLDER" });
		}
		if (filteredItemsIdsStr) {

			if (isSubscriptionAllowed) actions.push({ handleClick: showModalArchivePopup, icon: "ARCHIVE", description: "ARCHIVE" });
			actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", description: "ASPX_13" });
		}
		if (filteredItemsByRoleIdsStr && !isTemplate && isSubscriptionAllowed) actions.push({ handleClick: manageAccessMany, icon: "ACCESS", description: "ASPX_17" });
		if (filteredItemsByOwnersAndContrib) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "tag", idAttribute: "personaId" }), icon: "TAG", description: "TAG" });
		}
	}

	return <BulkActionsGenerator actions={actions} setSelected={setSelected} warningText={"BULK_ACTIONS_PERSONA_WARNING"} />;
};
