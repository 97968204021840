import { InputGroup, InputGroupText, Modal as ReactModal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";


export const Modal = styled(ReactModal)`
	width: 796px !important;
    max-width: 796px !important;
    textarea {
		background-color: transparent;
		width: calc(100% - 42px);
		color: #333333;
		resize: none;
		overflow: hidden;
		border: none;
		padding: 0.5rem;
        border: 1px solid #ced4da;
        border-radius: 5px;
        border-left: 0;
        ::placeholder {
            color: #ced4da;
            font-size: 14px;
            font-style: italic;
            align-content: center;
        }
	}
.modal-header {
    border: none;
}
.modal-footer {
    border:none;
}
.input-group-text {
    background-color: #ffffff !important;


}
.modal-content {
    /* background: linear-gradient(45deg, #F96209,#F8AD48, #18AEAD, #60C1EF, #3F3D56); */
    border: none !important;
    //padding: 2px;
}
.white-container {
    background: #ffffff;
}
.form-text {
    margin-left: 4px;
    font-size:12px;
    font-style: italic; // Added italic style

    }
`;
export const Body = styled(ModalBody)`
padding: 0px 1rem;
`;
export const Footer = styled(ModalFooter)`
`;
export const Header = styled(ModalHeader)`
	background: var(--AI-gradient-bg, linear-gradient(104deg, #FFEEE3 0.73%, #FFF8EE 32.71%, #E8F7FF 66.16%, #F1F0FF 97.16%));
    padding:16px;

`;
export const Info = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    border-radius: 4px;
background: #EFEFEF;
color: #605F60;
padding: 8px;
width: 100%;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%;

`
