import React from 'react';
import styled, { css } from 'styled-components/macro';
import { IconDispatcher } from 'shared/Icons';
import { useTranslation } from 'react-i18next'


export function BenefitCard({classes, isActive, variant, icon, text, isQuadrant, baseUnit,height,background}) {
    const { t } = useTranslation();

    return (
        <Card className={classes} isActive={isActive} variant={variant} isQuadrant={isQuadrant} baseUnit={baseUnit} height={height} background={background}>
            <>{IconDispatcher(icon)}</>
            <CardText>{t(text)}</CardText>
        </Card>
    )
}

const Card = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    height: 30px;
    border-radius: 6px;
    background: #F5F5F5;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #C4C4C4;

    ${(props) => props.isActive && props.variant === "quickWin" && css`
        background: #DCFFFF;
        color: #18AEAD;
        ${(props) => props.isQuadrant && css`
            transform: rotate(-90deg);
            position: absolute;
            left: calc(-1.55 * ${(props) => props.baseUnit}px);
            top: calc(2.7 * ${(props) => props.baseUnit}px);
        `}
    `}
    ${(props) => props.isActive && props.variant === "niceToHave" && css`
        background: #D2EEFB;
        color: #20A9E9;
        ${(props) => props.isQuadrant && css`
            transform: rotate(-90deg);
            position: absolute;
            left: calc(-1.75 * ${(props) => props.baseUnit}px);
            bottom: calc(2.2 * ${(props) => props.baseUnit}px);
        `}
    `}
    ${(props) => props.isActive && props.variant === "complexProj" && css`
        background: #C5C2D4;
        color: #333044;
        ${(props) => props.isQuadrant && css`
            transform: rotate(-90deg);
            position: absolute;
            right: calc(-1.92 * ${(props) => props.baseUnit}px);
            top: calc(2.8 * ${(props) => props.baseUnit}px);
        `}
    `}
    ${(props) => props.isActive && props.variant === "toAvoid" && css`
        background: #FDE7CB;
        color: #F48E09;

        ${(props) => props.isQuadrant && css`
            transform: rotate(-90deg);
            position: absolute;
            right: calc(-1.52 * ${(props) => props.baseUnit}px);
            bottom: calc(2.3 * ${(props) => props.baseUnit}px);
        `}
    `}
    ${(props) => props.height && css`
        height: ${props.height}px;
    `}
    ${(props) => props.background && css`
        background: ${props.background};
    `}
`

const CardText = styled.div`
    display: flex;
    white-space: nowrap;
`