export const BasicColors = {
	primary: "#00bcd4",
	secondary: "#ff4081",
	success: "#4caf50",
	warning: "#ff9800",
	danger: "#f44336",
	info: "#2196f3",
	light: "#f4f4f4",
	dark: "#212121",
	white: "#ffffff",
	black: "#000000",
	gray: "#9e9e9e",
	gray2: "#f5f5f5",
	gray3: "#eeeeee",
	gray4: "#e0e0e0",
	gray5: "#bdbdbd",
	gray6: "#757575",
	gray7: "#616161",
	gray8: "#424242",
	gray9: "#dee2e6",
	gray10: "#F3F2F1",
	orange: " #f96209",
	orangeHover: "#E35806",
	purple: "#7676d2",
	primaryPurple: "#4b4b7b",
	cemOrange: "#f96209",
	backgroundGreySidebar: "#3F3D56",
	warning2: "#FC74B3",
	cemPurple: "#3f3d56",
	MaleActive: "#15AABF",
	FemaleActive: "#FC74B3",
	yellow: "#eef982",
	lightGrey: "#f3f2f1",
	primlightbg: "#d9d8fb",
};

export const Colors = {
    primary: "#F96209",
    label: "#605F60",
    turquoise: '#18AEAD',
    terrible: '#cc5858',
    terribleIcon: '#FF8B8B',
    poor: '#ca5215',
    poorIcon: '#FCA57A',
    generic: '#ed9e00',
    genericIcon: '#FEE591',
    good: "#59ad62",
    goodIcon: "#B9FBC0",
    excellent: "#049493",
    excellentIcon: "#A3DFDE",
    nosentimentIcon: "#c3c3c3",
    primaryText: "#333"
}
