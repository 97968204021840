import { isBlanksSelected } from "features/analytics/analyticsHelper";
import { error, success } from "@redux-requests/core";
import { formatJourneyTrendsData, formatRoiData, getAllProjectRelatedMaps, getInteractionsRelatedItems, getSelectableIds } from "./helpers";
import { areYearsSimilar, checkStartEndDate } from "@utils/helpers";

const initialState = {
	originalData: {},
	currentData: {},
	selectableData: {},
	all_maps: [],
	all_personas: [],
	all_programs: [],
	all_projects: [],
	all_touchpoints: [],
	all_metrics: [],
	filterBy: [],
	currentFilterBy: "",
	savedAnalyticFilters: {},
	organization: {},
	loading: false,
};

const AnalyticReducer = (state = initialState, action) => {
	const responseData = action?.response?.data;

	switch (action.type) {
		case success("GET_DASHBOARD_DATA"): {
			const { dashboardCode, isExportMode } = action?.meta?.requestAction?.params;
			switch (dashboardCode) {
				case "journeyTrends": {
					formatJourneyTrendsData({ state, data: responseData });
					if (isExportMode) {
						return {
							...state,
							originalData: responseData,
							currentData: {
								...responseData,
								programs: responseData?.programs?.filter((p) => state?.savedAnalyticFilters?.programs?.includes(p.id)),
								journeys: responseData?.journeys?.filter((p) => state?.savedAnalyticFilters?.journeys?.includes(p.id)),
								personas: responseData?.personas?.filter((p) => state?.savedAnalyticFilters?.personas?.includes(p.id)),
							},
							selectableData: responseData,
							loading: false,
						};
					}
					return {
						...state,
						originalData: responseData,
						currentData: { ...responseData, programs: [] },
						selectableData: responseData,
						loading: false,
					};
				}
				case "vocAnalysis": {
					if (!responseData)
						return {
							...state,
							originalData: {},
							currentData: {},
							selectableData: {
								journeys: [],
								personas: [],
								touchpoints: [],
								metrics: [],
								date: "",
							},
							loading: false,
						};

					const data = { ...responseData };
					if (!isExportMode) {
						data.contactInteractions = data.contactInteractions?.filter((interaction) => areYearsSimilar(interaction?.date, new Date()));
					} else {
						data.personaInteractions = responseData?.personaInteractions.filter((pi) => state?.savedAnalyticFilters?.personaInteractions?.includes(pi?.id));
						data.contactInteractions = responseData?.contactInteractions.filter((ci) => state?.savedAnalyticFilters?.contactInteractions?.includes(ci?.id));
					}

					const formatedData = { ...data, ...getInteractionsRelatedItems({ interactions: [...data.personaInteractions, ...data.contactInteractions], state }) };
					const date = isExportMode ? state?.savedAnalyticFilters?.date : new Date();

					return {
						...state,
						originalData: { ...formatedData, date },
						currentData: {
							...formatedData,
							date,
							contactInteractions: data.contactInteractions,
						},
						selectableData: {
							journeys: formatedData.journeys,
							personas: formatedData.personas,
							touchpoints: formatedData.touchpoints,
							metrics: formatedData.metrics,
							date,
						},
						loading: false,
					};
				}
				case "roiAnalysis": {
					const data = { ...responseData };
					data.currentFilterBy = "maps";
					if (isExportMode) {
						data.projects = responseData?.projects.filter((p) => state?.savedAnalyticFilters?.projects?.includes(p?.id));
						data.currentFilterBy = state?.savedAnalyticFilters?.currentFilterBy;
					}
					formatRoiData({ state, data });
					const allRelatedMaps = getAllProjectRelatedMaps({ projects: data?.projects, allMaps: state.all_maps });
					const projectsFilteredByJourneys = data?.projects.filter((project) => allRelatedMaps?.map((m) => m.id)?.some((id) => project.cjmIds?.includes(id))) || [];
					const projects = data.currentFilterBy === "maps" ? projectsFilteredByJourneys : data?.projects;
					return {
						...state,
						filterBy: data.currentFilterBy === "maps" ? ["filterBy", "journeys"] : ["filterBy", "projects", "dateRange"],
						originalData: { projects: data?.projects, journeys: allRelatedMaps },
						currentData: { date: isExportMode ? state?.savedAnalyticFilters?.date : undefined, projects, currentFilterBy: data.currentFilterBy, journeys: allRelatedMaps },
						selectableData: { projects: data?.projects, journeys: allRelatedMaps },
						loading: false,
					};
				}

				default:
					return { ...state };
			}
		}
		case error("GET_DASHBOARD_DATA"):
			return state;
		case "APPLY_FILTER_JOURNEY_TRENDS": {
			const { dataAttribute, selectedItems } = action?.payload;

			switch (dataAttribute) {
				case "programs": {
					if (selectedItems.length === 0) {
						return { ...state, currentData: { ...state.originalData, programs: [] }, selectableData: { ...state.originalData } };
					}
					const selectableJourneyIds = getSelectableIds({
						updatedData: selectedItems,
						allItems: state.all_programs,
						linkedAttribute: "linkedCjms",
					});

					const selectablePersonaIds = getSelectableIds({
						updatedData: selectableJourneyIds,
						allItems: state.all_maps,
						linkedAttribute: "linkedPersonas",
						currentItemidAttribute: "cjmId",
						linkedAttributeIdAttribute: "personaId",
					});

					const updatedJourneyData = state.originalData.journeys?.filter((journey) => selectableJourneyIds.includes(journey.id));
					const updatedPersonaData = state.originalData.personas?.filter((persona) => selectablePersonaIds.includes(persona.id));

					return {
						...state,
						currentData: {
							...state.currentData,
							programs: selectedItems,
							journeys: updatedJourneyData,
							personas: updatedPersonaData,
						},
						selectableData: {
							...state.selectableData,
							journeys: updatedJourneyData,
							personas: updatedPersonaData,
						},
					};
				}
				case "journeys": {
					if (selectedItems.length === 0) {
						return {
							...state,
							currentData: { ...state.currentData, journeys: [], personas: [...state.originalData.personas] },
							selectableData: { ...state.selectableData, personas: [...state.originalData.personas] },
						};
					}

					const selectablePersonaIds = getSelectableIds({
						updatedData: selectedItems,
						allItems: state.all_maps,
						linkedAttribute: "linkedPersonas",
						currentItemidAttribute: "cjmId",
						linkedAttributeIdAttribute: "personaId",
					});

					const updatedPersonaData = state.originalData.personas?.filter((persona) => selectablePersonaIds.includes(persona.id));

					return {
						...state,
						currentData: {
							...state.currentData,
							journeys: selectedItems,
							personas: updatedPersonaData,
						},
						selectableData: {
							...state.selectableData,
							personas: updatedPersonaData,
						},
					};
				}
				case "personas": {
					if (selectedItems.length !== 0) {
						const selectableJourneyIds = getSelectableIds({
							updatedData: selectedItems,
							allItems: state.all_personas,
							linkedAttribute: "linkedCjms",
							currentItemidAttribute: "personaId",
						});
						const updatedJourneyData = state.originalData.journeys?.filter((journey) => selectableJourneyIds.includes(journey.id));
						return { ...state, currentData: { ...state.currentData, journeys: updatedJourneyData, personas: selectedItems }, selectableData: { ...state.selectableData } };
					}
					return { ...state, currentData: { ...state.currentData, personas: selectedItems }, selectableData: { ...state.selectableData } };
				}
				default:
					return state;
			}
		}
		case "APPLY_FILTER_VOC_ANALYSIS": {
			const { dataAttribute, selectedItems } = action?.payload;
			const empty = { personas: [], touchpoints: [], metrics: [], personaInteractions: [], contactInteractions: [] };
			let updatedState = {};

			switch (dataAttribute) {
				case "journeys": {
					if (selectedItems.length === 0) return { ...state, currentData: { ...state.currentData, journeys: [], ...empty }, selectableData: { ...state.selectableData, ...empty } };

					// Select all the interactions depending on selected maps
					const currentPersonaInteractions = state.originalData.personaInteractions.filter((interaction) => selectedItems.map((m) => m.id).includes(interaction?.mapId));
					const currentContactInteractions = state.originalData.contactInteractions.filter(
						(interaction) => selectedItems.map((m) => m.id).includes(interaction?.mapId) && areYearsSimilar(interaction?.date, state.currentData?.date)
					);
					const currentInteractions = [...currentPersonaInteractions, ...currentContactInteractions];

					// Select all the uniques personas, touchpoints, metrics depending on selected interactions
					const relatedItems = getInteractionsRelatedItems({ interactions: currentInteractions, state });

					return {
						...state,
						currentData: {
							...state.currentData,
							journeys: selectedItems,
							personas: relatedItems.personas,
							touchpoints: relatedItems.touchpoints,
							metrics: relatedItems.metrics,
							personaInteractions: currentPersonaInteractions,
							contactInteractions: currentContactInteractions,
						},
						selectableData: {
							...state.selectableData,
							personas: relatedItems.personas,
							touchpoints: relatedItems.touchpoints,
							metrics: relatedItems.metrics,
						},
					};
				}
				case "personas": {
					if (selectedItems.length === 0) return { ...state, currentData: { ...state.currentData, ...empty }, selectableData: { ...state.selectableData, touchpoints: [], metrics: [] } };

					// Select all the interactions depending on selected maps & personas
					const currentPersonaInteractions = state.originalData.personaInteractions.filter(
						(interaction) => state.currentData.journeys.map((m) => m.id).includes(interaction?.mapId) && selectedItems.map((m) => m.id).includes(interaction?.personaId)
					);
					const currentContactInteractions = state.originalData.contactInteractions.filter(
						(interaction) => state.currentData.journeys.map((m) => m.id).includes(interaction?.mapId) && selectedItems.map((m) => m.id).includes(interaction?.personaId)
					);
					const currentInteractions = [...currentPersonaInteractions, ...currentContactInteractions];

					const relatedItems = getInteractionsRelatedItems({ interactions: currentInteractions, state });

					return {
						...state,
						currentData: {
							...state.currentData,
							personas: selectedItems,
							touchpoints: relatedItems.touchpoints,
							metrics: relatedItems.metrics,
							personaInteractions: currentPersonaInteractions,
							contactInteractions: currentContactInteractions?.filter((interaction) => areYearsSimilar(interaction?.date, state.currentData?.date)),
						},
						selectableData: {
							...state.selectableData,
							touchpoints: relatedItems.touchpoints,
							metrics: relatedItems.metrics,
						},
					};
				}
				case "touchpoints": {
					updatedState = { ...state, currentData: { ...state.currentData, touchpoints: selectedItems } };
					break;
				}
				case "metrics": {
					updatedState = { ...state, currentData: { ...state.currentData, metrics: selectedItems } };
					break;
				}
				case "date": {
					updatedState = { ...state, currentData: { ...state.currentData, date: selectedItems } };
					break;
				}
				default:
					return state;
			}

			const currentSelectedJourneys = updatedState.currentData.journeys;
			const currentSelectedPersonas = updatedState.currentData.personas;
			const currentSelectedTps = updatedState.currentData.touchpoints;
			const currentSelectedMetrics = updatedState.currentData.metrics;
			const currentSelectedDate = updatedState.currentData.date;

			const isSelectable = (selectedItems, relatedId) => {
				// If no items selected in the filter
				if (selectedItems?.length === 0) return true;

				if (relatedId) {
					return selectedItems.map((m) => m.id).includes(relatedId);
				}

				if (!relatedId) {
					if (isBlanksSelected(selectedItems)) return true;
					return false;
				}
			};

			updatedState.currentData.personaInteractions = state.originalData.personaInteractions.filter(
				(interaction) =>
					currentSelectedJourneys.map((m) => m.id).includes(interaction?.mapId) &&
					currentSelectedPersonas.map((m) => m.id).includes(interaction?.personaId) &&
					isSelectable(currentSelectedTps, interaction?.touchpointId)
			);

			updatedState.currentData.contactInteractions = state.originalData.contactInteractions.filter(
				(interaction) =>
					currentSelectedJourneys.map((m) => m.id).includes(interaction?.mapId) &&
					currentSelectedPersonas.map((m) => m.id).includes(interaction?.personaId) &&
					isSelectable(currentSelectedTps, interaction?.touchpointId) &&
					isSelectable(currentSelectedMetrics, interaction?.metricId) &&
					(currentSelectedDate === "" || areYearsSimilar(interaction?.date, currentSelectedDate))
			);

			return updatedState;
		}
		case "APPLY_FILTER_ROI_ANALYSIS": {
			const { dataAttribute, selectedItems, currentFilterBy } = action?.payload;
			switch (dataAttribute) {
				case "filterBy": {
					return {
						...state,
						filterBy: selectedItems,
						currentData: {
							...state.currentData,
							projects: state.originalData.projects,
							journeys: state.originalData.journeys,
							currentFilterBy,
						},
					};
				}
				case "projects": {
					return {
						...state,
						currentData: {
							...state.currentData,
							projects: selectedItems,
						},
					};
				}
				case "journeys": {
					const currentProjectsList = state.originalData.projects.filter((project) => selectedItems.map((m) => m.id)?.some((id) => project.cjmIds?.includes(id)));

					return {
						...state,
						currentData: {
							...state.currentData,
							journeys: selectedItems,
							projects: currentProjectsList,
						},
					};
				}
				case "date": {
					const currentProjectsList = state.currentData.projects?.filter((selectedProject) => {
						const project = state.all_projects?.find((project) => project?.id === selectedProject?.id);
						return checkStartEndDate(selectedItems, project);
					});
					return {
						...state,
						currentData: {
							...state.currentData,
							date: selectedItems,
							projects: currentProjectsList,
						},
					};
				}
				default:
					return state;
			}
		}
		case success("GET_ANALYTICS_FILTERS"): {
			return {
				...state,
				savedAnalyticFilters: responseData,
			};
		}
		case success("GET_ALL_MAPS_LIST_DATA"): {
			const { url } = action?.meta?.requestAction?.request;
			if (!url.includes("redirectReducer")) return state;
			return {
				...state,
				all_maps: responseData || {},
				loading: false,
			};
		}
		case success("GET_ALL_PERSONAS"): {
			const { url } = action?.meta?.requestAction?.request;
			if (!url.includes("redirectReducer")) return state;

			const all_personas =
				responseData?.map((persona) => {
					const personaAtt = Object.entries(persona.personaAttributes)?.map((x) => x[1]) || [];
					return {
						...persona,
						personaAttributes: personaAtt,
					};
				}) || [];
			return {
				...state,
				all_personas: all_personas,
			};
		}
		case success("CX_PROGRAM_GET_ALL"): {
			const { url } = action?.meta?.requestAction?.request;
			if (!url.includes("redirectReducer")) return state;

			return {
				...state,
				all_programs:
					action.response.data.map((cx) => {
						const CxNumbers = [
							{ title: "JS_71", linkName: "linkedCjms", icon: "CJM", color: "#3F3D56" },
							{ title: "CS_31", linkName: "linkedPersonas", icon: "PERSONA", color: "#18AEAD" },
							{ title: "OPPORTUNITIES", linkName: "linkedOpps", icon: "OPPORTUNITY", color: "#818089" },
							{ title: "SOLUTIONS", linkName: "linkedSols", icon: "SOLUTION", color: "#60C1EF" },
							{ title: "JS_74", linkName: "linkedActions", icon: "MENU-ACTION", color: "#F8AD48" },
							{ title: "PROJECTS", linkName: "linkedProjects", icon: "PROJECT", color: "#F8AD48" },
						];
						cx.CxNumbers = CxNumbers;
						return cx;
					}) || [],
				loading: false,
			};
		}
		case success("TOUCHPOINT_INIT"): {
			const TouchpointArray = Object.entries(action.response.data).map((touch) => touch[1]);
			return {
				...state,
				all_touchpoints: TouchpointArray || [],
			};
		}
		case success("GET_ALL_CX_METRICS"):
			return {
				...state,
				all_metrics: Object.entries(action.response.data).map((metric) => metric[1]) || [],
			};
		case success("GET_ALL_PROJECTS"): {
			const { url } = action?.meta?.requestAction?.request;
			if (!url.includes("redirectReducer")) return state;

			return {
				...state,
				all_projects: action.response.data,
			};
		}
		case success("GET_ORGANIZATION_SETTINGS"): {
			const { url } = action?.meta?.requestAction?.request;
			if (!url.includes("redirectReducer")) return state;
			return {
				...state,
				organization: { ...responseData, logoRefreshValue: null } || {},
			};
		}
		default:
			return state;
	}
};
export default AnalyticReducer;
