import { EMPTY_GUID } from "@utils/helpers";
import { PermissionManager } from "@utils/permissionManager";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ActionConfirmModal, DuplicateModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { archiveOpportunity, copyOpportunity, deleteOpportunity, deleteOpportunityPermanently, restoreOpportunity } from "../reducer/OpportunityActions";

export default function BaseActions({ opportunity, context, handleRedirect,onClose }) {
	const { t } = useTranslation();
	const history = useHistory();

	const userId = useSelector((state) => state.auth?.userInfo?.UserId);
	const allMaps = useSelector((state) => state.maps.all_maps);

	const id = opportunity.id;

	const userAccess = (function () {
		if (opportunity?.mapId === EMPTY_GUID) return "owner";
		const linkedMap = allMaps.find((map) => map?.cjmId === opportunity?.mapId);
		return linkedMap?.authorizedUsers?.find((user) => user?.id === userId && user.roleId === EMPTY_GUID)?.roleName;
	})();

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const handleArchive = () => {
		actionConfirmModal({
			bodyContent: "ARCHIVE_GENERIC",
			Action: () => archiveOpportunity({ id, onSuccess:onClose ?? handleRedirect }),
			actionBtnText: "ARCHIVE",
		});
	};

	const handleDelete = () => {
		actionConfirmModal({
			bodyContent: "DELETE_GENERIC",
			Action: () => deleteOpportunity({ id, onSuccess: onClose ?? handleRedirect }),
			actionBtnText: "ASPX_13",
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deleteOpportunityPermanently({ id }),
			actionBtnText: "ASPX_13",
		});
	};

	const callBackCopy = (response) => {
		if (typeof response === "object") {
			history.push(`/edit-opportunity?id=${response?.id}`);

		}
	};

	const [showCopyModal] = useModal(DuplicateModal, {
		title: `${t("JS_61")} ${t("OPPORTUNITY")}`,
		inputTitle: "OPPORTUNITY_NAME",
		closeBtnLabel: "COPY",
		inputValue: `[${t("COPY_NOUN").toUpperCase()}]- ` + opportunity?.subject,
		action: ({ name, selectedWorkspaces }) => copyOpportunity({ id, name, workspaceIds: selectedWorkspaces.map((w) => w?.id), onSuccess: handleRedirect ? handleRedirect : callBackCopy }),
	});

	const { copyAllowed, archiveAllowed, deleteAllowed, restoreAllowed, deletePermanentAllowed } = PermissionManager({
		userAccess,
		context,
	});

	const createActions = () => {
		let actions = [];
		if (copyAllowed) actions.push({ id: "copy", handleClick: showCopyModal, icon: "COPY", text: t("JS_61") });
		if ((archiveAllowed || deleteAllowed) && actions?.length > 0) actions.push({ id: "divider", divider: true });
		if (archiveAllowed) {
			actions.push({
				id: "archive",
				handleClick: handleArchive,
				icon: "ARCHIVE",
				text: t("ARCHIVE"),
			});
		}
		if (deleteAllowed) {
			actions.push({
				id: "delete",
				handleClick: handleDelete,
				icon: "DELETE",
				text: t("CS_45"),
			});
		}
		if (restoreAllowed) actions.push({ id: "restore", handleClick: () => restoreOpportunity({ id }), icon: "RESTORE", text: t("JS_96") });
		if (deletePermanentAllowed) actions.push({ id: "deletePermanent", handleClick: handleDeletePermanent, itemStyle: "warning-delete", icon: "DELETE", text: t("JS_97") });
		if (opportunity?.isSample) {
			actions = actions.filter((action) => action.id === "copy");
		}
		return actions;
	};

	return createActions();
}
