import ReactColor from "shared/popovers/ReactColor";
import { JumpDotSpinner } from "shared/components/spinners/Spinner";
import { getRequestStatus } from "@redux/memoizedSelectors";
import { isDarkColor } from "@utils/helpers";
import { t } from "i18next";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { Input as BoostrapInput } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { GenericButton } from "./buttons";
import { Colors } from "./colors";
import { IconDispatcher } from "../Icons";
const COLORS = {
	black: "#333333",
	grey: "#C4C4C4",
};

export const SwimlaneTitleInput = styled(BoostrapInput)`
	border: 0;
	text-transform: capitalize !important;
	max-width: 40%;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	min-width: 100px;
	color: ${COLORS.black} !important;
	background-color: transparent !important;
	${(props) => (props.restrict === "true" ? "pointer-events: none !important;" : "")}
	&:focus {
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
	&.form-control:focus {
		outline: none !important;
	}
	::-webkit-input-placeholder {
		color: ${COLORS.grey};
	}
	text-overflow: ellipsis;
`;

export const Input = styled(BoostrapInput)`
	width: 100%;
	font-style: normal;
	&.form-control:disabled {
		background-color: transparent;
		opacity: 1;
	}
	&.form-control[readonly] {
		background-color: transparent;
		opacity: 1;
	}
	&.form-control::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		font-style: italic;
		font-weight: 300;
	}
	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}
	&::-webkit-input-decoration {
		display: none;
	}
	&::-webkit-clear-button,
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 36px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 40px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			font-size: 24px;
		`}
	${(props) =>
		props?.lock === "lock" &&
		css`
			pointer-events: none;
		`}
	${(props) =>
		props.variant === "default" &&
		css`
			color: ${COLORS.black};

			&:focus {
				border: 1px solid ${Colors.primaryText} !important;
				box-shadow: none;
			}
			&.form-control:focus {
				outline: none !important;
			}
		`}
        ${(props) =>
		props.variant === "greyed-disable" &&
		css`
			color: ${COLORS.black};

			&:focus {
				border: 1px solid ${Colors.primaryText} !important;
				box-shadow: none;
			}
			&.form-control:focus {
				outline: none !important;
			}
			&.form-control:disabled {
				background-color: #efefef;
				opacity: 1;
			}
		`}

        ${(props) =>
		props.variant === "financialImpact" &&
		css`
			color: ${Colors.primaryText} !important;

			&:focus {
				border: 1px solid ${Colors.primaryText} !important;
				box-shadow: none;
			}
			&.form-control:focus {
				outline: none !important;
			}
		`}

  ${(props) =>
		props.variant === "no-border-no-focus" &&
		css`
			border: none;
			&:focus {
				/* -webkit-box-shadow: 0 0 3px #9df2f2;
				-moz-box-shadow: 0 0 3px #9df2f2; */
				box-shadow: none;
			}
			&.form-control:focus {
				border: none;
				outline: none !important;
			}
		`}

  ${(props) =>
		props.variant === "section-title" &&
		css`
			padding: 0px !important;
			font-size: 1.2rem !important;
			font-weight: 500 !important;
			line-height: 2rem !important;
			margin-left: 1rem !important;
			background: transparent !important;
			border-radius: 0rem !important;
			border: 0 !important;
			color: ${(props) => (props.colorcode && isDarkColor(props.colorcode) ? "#ffffff" : "#333333")} !important;
			&:focus {
				border: 1px solid ${Colors.primaryText} !important;
				/* -webkit-box-shadow: 0 0 3px #9df2f2;
				-moz-box-shadow: 0 0 3px #9df2f2; */
				box-shadow: none;
			}
		`}
`;
export const SectionTitle = ({ placeholder, value, handleChange, updateSectionTitle, updateColor, showDeletePopup, editable = true, accessDelete = true, colorCode, AIfunction }) => {
	const { t } = useTranslation();
	const { pending: textPending } = useSelector(getRequestStatus({ type: "GENERATE_PERSONA_SECTION_AI" }), shallowEqual);
	const { pending: otherPending } = useSelector(getRequestStatus({ type: "ASK_ALEX" }), shallowEqual);
	const loading = textPending === 1 || otherPending === 1;

	return (
		<TitleWrapper>
			<Input
				variant="section-title"
				className="text-truncate"
				title={value}
				placeholder={placeholder}
				type="text"
				value={value}
				onChange={handleChange}
				onBlur={updateSectionTitle}
				disabled={!editable}
				colorcode={colorCode}
			/>
			{editable && (
				<ActionBox personalityType={!accessDelete}>
					{AIfunction && (
						<>{loading ? <JumpDotSpinner /> : <GenericButton variant="light-link" onMouseUp={AIfunction} disabled={loading} tooltip tooltipText={t("ASK_ALEX")} icon={"SPARKLES"} />}</>
					)}
					<ReactColor color={colorCode} updateColor={updateColor} placement={"left"} autoClose />
					{accessDelete && <GenericButton variant="light-link" onClick={showDeletePopup} tooltip tooltipText={t("ASPX_13")} icon={"TRASH"} />}
				</ActionBox>
			)}
		</TitleWrapper>
	);
};
export const PrimeInput = styled(InputText)`
	height: ${(props) => props.specificheight}px;
	border-radius: 4px;
	width: inherit;
	:focus {
		border: 1px solid #605f60 !important;
	}
	:hover {
		border-color: #605f60 !important;
	}
	${(props) =>
		props.height === "small" &&
		css`
			padding: 0.25rem;
			width: 100%;
		`}
	${(props) =>
		props.height === "large" &&
		css`
			padding: 0.25rem;
			width: 425px;
		`}
`;
const TitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 36px;
`;
export const ActionBox = styled.div`
	align-items: center;
	justify-content: space-between;
	display: none;
	color: #605f60;
	/* width:${(props) => (props.personalityType ? "30px" : "70px")}; */
	#action {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
		text-align: center;

		&:hover {
			background-color: #e4e7f1;
			box-shadow: 0px 0px 5px #bfbfbf;
			border-radius: 50%;
		}
	}
`;

export const SearchBar = ({ globalFilterValue, handleChange, size, label = "ASPX_178", height = 38, handleClick, onKeyDown = null, createAction, disabledCreation, isAutoFocus = false }) => {
	const handle = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};
	return (
		<span className={`p-input-icon-left w-100`} onClick={handleClick ? handleClick : () => {}}>
			<i className="pi pi-search" />
			<PrimeInput
				id="search-bar"
				placeholder={t(label)}
				onChange={handleChange}
				height={size}
				specificheight={height}
				value={globalFilterValue}
				autoComplete="off"
				// onFocus={handle}
				// onKeyDown={onKeyDown}
				onBlur={(e) => e.preventDefault()}
				autoFocus={isAutoFocus}
			/>
			{createAction && (
				<ButtonPosition>
					<GenericButton variant="dark" disabled={disabledCreation} onClick={() => createAction(globalFilterValue)}>
						{t("CS_13")}
					</GenericButton>
				</ButtonPosition>
			)}
		</span>
	);
};

export const StateDropdownSelector = ({ style, value, options, handleChange, disabled }) => {
	const selectedStateTemplate = (option, props) => {
		if (option) {
			return (
				<Label variant={"normal"} background={option?.value === 100000001 ? "#FDE7CB" : "#D2EEFB"} color={option?.value === 100000001 ? "rgb(244, 142, 9)" : "rgb(32, 169, 233)"}>
					{option?.name}
				</Label>
			);
		}

		return <>{props.placeholder}</>;
	};

	return (
		<CustomDropdown
			className={"w-100 " + style}
			value={value}
			valueTemplate={selectedStateTemplate}
			itemTemplate={selectedStateTemplate}
			options={options}
			onChange={handleChange}
			optionLabel="name"
			disabled={disabled}
		/>
	);
};

export const GenderDropdownSelector = ({ value, genderOptions, handleChange }) => {
	const displayIcon = (name) => {
		if (name === "ASPX_59") return IconDispatcher("MALE", "me-2");
		if (name === "ASPX_60") return IconDispatcher("FEMALE", "me-2");
		if (name === "ASPX_61") return IconDispatcher("GENDERLESS", "me-2");
	};

	const selectedGenderTemplate = (option, props) => {
		if (option) {
			return (
				<div className="d-flex align-items-center">
					{displayIcon(option?.name)}
					<div>{option?.translatedName}</div>
				</div>
			);
		}

		return <>{props.placeholder}</>;
	};

	return (
		<CustomDropdown
			value={value}
			valueTemplate={selectedGenderTemplate}
			itemTemplate={selectedGenderTemplate}
			options={genderOptions}
			onChange={handleChange}
			optionLabel="translatedName"
			placeholder={t("SELECT_A_GENDER")}
			className="w-100"
		/>
	);
};
export const ConceptSearchBar = ({ globalFilterValue, handleChange, label = "ASPX_178", onKeyDown = null }) => {
	const { t } = useTranslation();

	const startSearch = (e) => {
		e.stopPropagation();
		if (globalFilterValue && onKeyDown) onKeyDown(e);
	};
	const props = {
		...(onKeyDown ? { onClick: startSearch, variant: "light" } : {}),
	};
	return (
		<div className="input-wrapper-ConceptSearchBar">
			<button className="icon-ConceptSearchBar">{IconDispatcher("SEARCH")}</button>
			<input
				placeholder={t(label) + "..."}
				className={`input-ConceptSearchBar  ${globalFilterValue?.trim().length > 0 ? "has-text" : ""}`}
				value={globalFilterValue}
				onChange={handleChange}
				name="text"
				type="text"
				autoComplete="off"
				onKeyDown={onKeyDown}
			/>
		</div>
	);
};

export const CustomDropdown = styled(Dropdown)`
	box-shadow: none !important;
	width: 100%;
	height: 100%;
	padding: 0;
	align-self: center;
	.p-dropdown:not(.p-disabled) {
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important;
		box-shadow: 0 0 3px #9df2f2 !important;
	}
	:focus {
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important;
		box-shadow: 0 0 3px #9df2f2 !important;
	}
	:hover {
		border-color: ${Colors.primaryText} !important;
	}
	:active {
		border-color: ${Colors.primaryText} !important;
	}
	.p-dropdown-label.p-placeholder {
		align-self: center;
	}
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 4px;
	height: 26px;
	padding: 4px;
	width: ${(props) => (props.labelWidth ? props.labelWidth : "100%")};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: ${(props) => props.background};
	color: ${(props) => props.color};
	${(props) =>
		props.variant === "small" &&
		css`
			font-size: 14px !important;
		`}
`;
const ButtonPosition = styled.div`
	position: absolute;
	right: 1px;
	top: 1px;
`;
