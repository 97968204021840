import { getTeams } from "@redux/memoizedSelectors";
import { EMPTY_GUID, filterByActiveUsers, GetPickListUsers } from "@utils/helpers";
import { filterRecords } from "features/workspace/utils";
import { useImperativeHandle, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UsersSelectorTypeahead from "shared/components/typeahead/users-selector-typeahead";

const WorkspaceManageUsers = ({ innerRef, workspace, modeType, userType, setModifiedAttribNames }) => {
	const { t } = useTranslation();
	const authorizedUsers = useSelector((state) => state.settings.authorizedUsers);
	const users = useSelector((state) => state.settings.users);
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);

	const allActiveUSers = filterByActiveUsers({ authorizedUsers, registeredUsers: users });
	const currentUser = allActiveUSers?.filter((user) => user?.id === currentUserId);
	const teams = useSelector(getTeams);

	const init = () => {
		if (modeType === "update") {
			const filteredUsers = workspace?.authorizedUsers?.filter((user) => user?.roleId !== EMPTY_GUID) || [];
			const selectedUsers = filteredUsers?.filter((fu) => {
				if (fu.accessType === "user") {
					const domaineName = authorizedUsers?.find((user) => user.id === fu.id)?.domainName;
					const userDetails = users?.find((u) => domaineName?.toLowerCase() === u?.loginName?.toLowerCase());
					if (userDetails) return userDetails?.isActive;
				}
				return false;
			});
			// A company admin or workspace owner cannot remove itself from the owners
			selectedUsers?.map((user) => {
				if (user.id === currentUserId) {
					user.isNotRemovable = true;
				}
				return true;
			});
			return {
				users: filterRecords({ records: selectedUsers, accessType: "user", roleName: userType }) || [],
				teams: filterRecords({ records: filteredUsers, accessType: "team", roleName: userType }) || [],
			};
		}
		return {
			users: userType === "owner" ? currentUser : allActiveUSers,
			teams: [],
		};
	};

	const [workspaceUsers, setWorkspaceUsers] = useState(init());
	const [isModified, setIsModified] = useState(false);
	const nonSelectedRecords = [{ id: "", fullName: "All" }, ...GetPickListUsers([...allActiveUSers, ...teams], filterRecords({ records: workspace?.authorizedUsers, roleName: userType }))];

	useImperativeHandle(innerRef, () => ({
		getWorkspaceUsers: () => {
			return {
				users: workspaceUsers?.users?.map((u) => u.id),
				teams: workspaceUsers?.teams?.map((u) => u.id),
				isModified,
			};
		},
	}));

	const getSelected = () => {
		if (allActiveUSers?.length === workspaceUsers?.users?.length) return [{ id: "", fullName: "All" }];
		return [...workspaceUsers?.users, ...workspaceUsers?.teams];
	};

	const onUsersChange = ({ selected }) => {
		const isAllSelected = selected?.find((user) => user.fullName === "All");
		const users = isAllSelected ? filterRecords({ records: nonSelectedRecords, accessType: "user" }) : filterRecords({ records: selected, accessType: "user" });
		const teams = selected?.filter((user) => user.accessType === "team" || user["memberUserIds"]);

		setIsModified(true);
		setWorkspaceUsers({ users, teams });
		if (setModifiedAttribNames)
			setModifiedAttribNames((prev) => {
				const newSet = new Set(prev);
				if (!newSet.has("authorizedUsers")) newSet.add("authorizedUsers");
				return newSet;
			});
	};

	return <UsersSelectorTypeahead placeholder={t("SELECT_USERS")} options={nonSelectedRecords} selected={getSelected()} onChange={(selected) => onUsersChange({ selected })} showBorder={true} />;
};

WorkspaceManageUsers.displayName = "WorkspaceManageUsers";
export default WorkspaceManageUsers;
