import { ActionConfirmModal } from "shared/modals/index";
import { GenericButton } from "shared/ui-basics/buttons";
import { IconDispatcher, Input } from "shared/ui-basics/index";
import { getSessionId } from "@redux/memoizedSelectors";
import { EMPTY_GUID, detectImageExtension, isUserAllowed } from "@utils/helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, FormFeedback, Label } from "reactstrap";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import { UploadImageModal } from "../../../../shared/components/UploadImage";

export default function TouchPointForm({ index, selectedItem,  srcImg, onClose, onCreate, onUpdate, onDelete, mode = "update", data }) {
	const { t } = useTranslation();

	const sessionId = useSelector(getSessionId);
	const userType = useSelector((state) => state.auth.userInfo.UserType);
	const subscriptionPlanName = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const category = [{ name: "ALL", id: EMPTY_GUID },...useSelector((state) => state.libraries.touchPointCategories)||[]];

	const isStandard = subscriptionPlanName === "Standard";
	const isSharedExpert = subscriptionPlanName === "SharedExpert";
	const customCategory = category.find(category => category.name === "Custom")?.id;
	const [customImage, setCustomImage] = useState(index !== undefined && selectedItem !== null ? srcImg(selectedItem, sessionId) : mode === "create" ? "upload" : "default");
    const [selectCategory, setSelectCategory] = useState(index ? selectedItem.categoryId :  customCategory);
	const [name, setName] = useState(index ? selectedItem.name : undefined);
	const [nameExist, setNameExist] = useState(false);
	const [weight, setWeight] = useState(index ? (selectedItem.weight !== "" && selectedItem.weight > 0 ? selectedItem.weight : 1) : 1);

	const isDeleteAllowed = selectedItem?.isCustom && isUserAllowed(userType, "delete-library");
    const isSaveAllowed = !selectedItem?.isCustom && (isStandard || isSharedExpert) ? false : true;

	const handleDelete = (e) => {
		onDelete(e);
		hideModalDelete();
		onClose();
	};

	const [showModalDelete, hideModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: "DELETE_TOUCHPOINT",
		Action: handleDelete,
		actionBtnText: "ASPX_13",
	});

	const create = (e) => {
		if (data) {
			if (name !== "" && name !== undefined && weight !== "" && weight >= 1) {
				const dataFiltering = data?.filter((type) => type.name.toUpperCase() === name.toUpperCase()) || [];
				if (dataFiltering.length > 0) {
					setNameExist(true);
				} else {
					const FileExtension = customImage !== "upload" && customImage !== "default" ? detectImageExtension(customImage.split(",")[0]) : "";
					const DocumentBody = customImage !== "upload" && customImage !== "default" ? customImage.split(",").pop() : "";
					onCreate(name, selectCategory, weight, FileExtension, DocumentBody);
					setName(undefined);
					setNameExist(false);
					setCustomImage("upload");
					setWeight(1);
				}
			} else {
				if (name === undefined) setName("");
			}
		}
	};

	const update = (e) => {
		if (name !== "" && name !== undefined && weight !== "" && weight >= 1) {
			if (customImage === "delete") {
				onUpdate(index, name, selectCategory, weight, "", ""); //update +remove img
			} else {
				const FileExtension = detectImageExtension(customImage.split(",")[0]);
				const DocumentBody = customImage.split(";").length > 1 ? customImage.split(",").pop() : undefined;
				onUpdate(index, name, selectCategory, weight, FileExtension === "svg+xml" ? "svg" : FileExtension, DocumentBody);
			}
		}
	};

	const handleChange = (e) => {
		setName(e.target.value.truncate(100));
		setNameExist(false);
	};

	const handleChangeWeight = (e) => setWeight(e.target.value);

	return (
		<div>
			<NameWrapper>
				<Form onSubmit={onClose}>
					<LabelWrapper for="icon-name">{t("NAME")}</LabelWrapper>
					<Input
						variant="default"
						invalid={name === "" || nameExist}
						type="text"
						id="icon-name"
						value={name ? name : ""}
						onChange={handleChange}
						disabled={index && !selectedItem?.isCustom}
						placeholder={t("ENTER_NAME")}
					/>
					<FormFeedback>{nameExist ? t("NAME_EXISTS") : t("ICON_NAME_MISSING")}</FormFeedback>
				</Form>
			</NameWrapper>
			<ImgBox disabled={index && selectedItem?.isCustom}>
				<UploadImageModal
					variantImg={"librariesImage"}
					keyFor={mode + "touchPoint"}
					id={mode + "touchPoint"}
					disabled={index && selectedItem?.isCustom}
					deleteImage={true}
					customImage={customImage}
					setCustomImage={setCustomImage}
					fileSizeParent={null}
				/>
			</ImgBox>
			{!isStandard && !isSharedExpert ? (
				<InputWrapper onSubmit={onClose}>
					<LabelWrapper>{t("JS_184")}</LabelWrapper>
					<WariningWrapper className="fs-10">
						{IconDispatcher("INFO")}
						{t("JS_192")}
					</WariningWrapper>
					<Input variant="default" invalid={weight === "" || weight < 1} type="number" value={weight} onChange={handleChangeWeight} />
					<FormFeedback>{t("WEIGHT_MISSING")}</FormFeedback>
				</InputWrapper>
			) : (
				<></>
			)}
			<InputWrapper>
				<LabelWrapper>{t("SELECTCATEGORY")}</LabelWrapper>
				<SelectWrapper
					disabled={index && !selectedItem?.isCustom}
					onChange={(e) => {
						setSelectCategory(e.target.value);
					}}
					value={selectCategory}>
					{category?.map((item, index) =>
						item.id !== EMPTY_GUID ? (
							<option value={item.id} key={index}>
								{t(item.name)}
							</option>
						) : (
							""
						)
					)}
				</SelectWrapper>
			</InputWrapper>
			{mode !== "create" ? (
				<ButtonWrapper>
					<FooterDiv IsDeleteAllowed={isDeleteAllowed}>
						{isDeleteAllowed && (
							<GenericButton variant="outline-danger" className="ms-1 d-flex align-items-center" icon="TRASH" onClick={showModalDelete} iconClassName="me-1">
								{t("ASPX_13")}
							</GenericButton>
						)}
						{isSaveAllowed && (
							<div>
								<GenericButton variant="primary" disabled={customImage === "error"} className=" me-2 align-self-end" onClick={update}>
									{t("ASPX_12")}
								</GenericButton>
							</div>
						)}
					</FooterDiv>
				</ButtonWrapper>
			) : (
				<GenericButton variant="primary-outline" className="btn-block w-100" disabled={customImage === "error"} onClick={create}>
					{t("ASPX_108")}
				</GenericButton>
			)}
		</div>
	);
}

const NameWrapper = styled.div`
	margin-bottom: 15px;
`;

const ImgBox = styled.div`
	background-color: #ffffff;
	border: 1px dashed #9e9292;
	border-radius: 0.5rem;
	color: #7676d2;
	max-height: 250px;
	min-height: 140px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.75rem;
	${(props) => !props.disabled && props.disabled !== undefined && `    background-color: #e9ecef !important; `};
	margin-bottom: 14px;
`;

const FooterDiv = styled.div`
	padding-top: 1rem;
	display: flex;
	justify-content: ${(props) => (props.IsDeleteAllowed ? "space-between" : "flex-end")};
	width: 100%;
`;

const SelectWrapper = styled.select`
	border: 1px solid #adacb5 !important;
	border-radius: 4px !important;
	width: 100%;
	height: 38px;
	margin-bottom: 16px;
	:disabled {
		background-color: #e9ecef;
	}
`;

const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

const WariningWrapper = styled.span`
	margin-bottom: 0.55rem;
	color: #92919d;
	font-size: 12px;
`;

const InputWrapper = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;
