import { constructUrl, encode, toastWrapper } from "@utils/helpers";
import { store } from "index";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/user/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/user/", method: "GET" }); // api actions type get

export const getAllRegisteredUsers = () => createGetAction({ type: "GET_ALL_REGISTERED_USERS", endPoint: "getAllRegisteredUsers" });
export const getAllAuthorizedUsers = () => createGetAction({ type: "GET_AUTHORIZED_USERS", endPoint: "getAllAuthorizedUsers" });
export const getAllRoles = () => createGetAction({ type: "GET_ALL_ROLES", endPoint: "getAllRoles" });
export const getSettingUser = () => createGetAction({ type: "GET_SETTING_USER", endPoint: "getUserSetting" });

export const getSettingUserFn = (props) => genericService({ ...props, action: getSettingUser });

export const createUser = ({ user, workspaceIds, roleNames, teamIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_USER",
			endPoint: "register",
			data: { userToRegister: { ...user, firstName: encode(user?.firstName), lastName: encode(user?.lastName), role: encode(user?.role) }, workspaceIds, roleNames, teamIds },
		}),
	});

export const deleteUser = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_USER",
			endPoint: "unregister",
			data: { registeredUserId: id },
		}),
		displayToast: "DELETE",
	});

export const updateUser = ({ modifiedUser, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_USER",
			endPoint: "update",
			data: {
				userToUpdate: {
					...modifiedUser,
					firstName: encode(modifiedUser?.firstName),
					lastName: encode(modifiedUser?.lastName),
					fullName: encode(modifiedUser?.fullName),
					role: encode(modifiedUser?.role),
				},
				modifiedAttribNames,
			},
		}),
	});

export const updateUserType = ({ modifiedUser, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_USER_TYPE",
			endPoint: "update",
			data: {
				userToUpdate: {
					...modifiedUser,
					firstName: encode(modifiedUser?.firstName),
					lastName: encode(modifiedUser?.lastName),
					fullName: encode(modifiedUser?.fullName),
					role: encode(modifiedUser?.role),
				},
				modifiedAttribNames: ["userType"],
				params: { updatedUser: { ...modifiedUser } },
			},
		}),
	});

export const activateUsers = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ACTIVATE_USERS",
			endPoint: "activate",
			data: { ids },
		}),
		displayToast: props?.displayToast ? props?.displayToast : "ACTIVATE_USER",
	});

export const deactivateUsers = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DEACTIVATE_USERS",
			endPoint: "deactivate",
			data: { ids },
		}),
		displayToast: props?.displayToast ? props?.displayToast : "DEACTIVATE_USER",
	});

export const transfertOwnerShip = ({ userContactIdFrom, userContactIdTo, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "TRANSFER_OWNERSHIP",
			endPoint: "transferOwnership",
			data: { userContactIdFrom, userContactIdTo },
		}),
		displayToast: "UPDATE",
	});

export const upsertSettingUser = ({ userSetting, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_SETTING_USER",
			endPoint: "upsertUserSetting",
			data: { userSetting, modifiedAttribNames },
		}),
	});

export const upsertSavedView = ({ savedView, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_SAVED_VIEW",
			endPoint: "upsertSavedView",
			data: { savedView },
		}),
	});

export const deleteSavedView = ({ savedViewToDelete, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_SAVED_VIEW",
			endPoint: "deleteSavedView",
			data: { savedViewToDelete },
		}),
		displayToast: "UPDATE",
	});

export const getAnalyticsFilters = (props) =>
	createGetAction({
		type: "GET_ANALYTICS_FILTERS",
		endPoint: constructUrl("getAnalyticsFilters", {
			id: props?.id,
		}),
	});

export const getUsersExcelExport = (ids, columns) => {
	const promise = async () => {
		const { data, error } = await store.dispatch(
			createPostAction({
				type: "GET_USER_EXCEL",
				endPoint: "getExcelExport",
				data: {
					ids,
					columns: columns?.join("|"),
				},
				responseType: "blob",
			})
		);
		var fileDownload = require("js-file-download");
		fileDownload(data, "User List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};
