import { activateUsers, deactivateUsers } from "@redux/index";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { Button, IconDispatcher } from "shared/ui-basics";
import styled from "styled-components/macro";

export const BulkUpdateStatus = ({ ids, onSuccess, setDisableBulkActions, successToast }) => {
	const { t } = useTranslation();

	const onActivate = () => {
		setDisableBulkActions(true);
        activateUsers({ ids, onSuccess, displayToast: "UPDATE", toastParams: {success: successToast} });
    };
    
    const onDeactivate = () => {
		setDisableBulkActions(true);
		deactivateUsers({ ids, onSuccess, displayToast: "UPDATE", toastParams: {success: successToast} });
	};

	return (
		<DropDownContainer drop="up">
			<Dropdown.Toggle id="dropdown-custom-components" as={Button} variant="light-bulk">
				<ActionContainer>
					<div className="me-2">{IconDispatcher("circle")}</div>
					<div>{t("ASPX_222")}</div>
				</ActionContainer>
			</Dropdown.Toggle>
			<MenuContainer>
				<Dropdown.Item>
					<ActionContainer onClick={onActivate}>
						<div className="me-2">{IconDispatcher("circle-check")}</div>
						<div>{t("JS_119")}</div>
					</ActionContainer>
				</Dropdown.Item>
				<Dropdown.Item>
					<ActionContainer onClick={onDeactivate}>
						<div className="me-2">{IconDispatcher("circle-pause")}</div>
						<div>{t("JS_118")}</div>
					</ActionContainer>
				</Dropdown.Item>
			</MenuContainer>
		</DropDownContainer>
	);
};

BulkUpdateStatus.displayName = "BulkUpdateStatus";

const DropDownContainer = styled(Dropdown)`
	.dropdown-toggle::after {
		display: none;
	}
`;

const MenuContainer = styled(Dropdown.Menu)`
	transform: translate3d(0px, -54px, 0px) !important;
`;
const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`;
