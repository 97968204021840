import { IconDispatcher } from "shared/Icons";
import CustomTooltip from "shared/components/CustomTooltip";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

function PersonaBadge({ object, unlink, disabled, info, isEdit, classNames = "mb-2" }) {
	const { t } = useTranslation();
	const handleRemove = (e) => {
		e.stopPropagation();
		unlink(object?.personaId)
	};
	return (
		<Badge key={object?.personaId} className={classNames} disabled={disabled}>
			<PersonaBubble persona={object} size={"22"} />
			<div className="name ellipsis" title={object?.name}>
				{object?.name}
			</div>
			{info && (
				<CustomTooltip id={object?.personaId} text={t("NOT_LINK_TO_MAP")} placement={"top"}>
					<div>{IconDispatcher("INFO", "ne-2", { style: { color: "#605F60" } })}</div>
				</CustomTooltip>
			)}
			{unlink && !disabled && isEdit && <UnlinkButton onClick={handleRemove}>{IconDispatcher("X", "", { size: "xs" })}</UnlinkButton>}
		</Badge>
	);
}
PersonaBadge.displayName = "PersonaBadge";
PersonaBadge.propTypes = {
	id: PropTypes.string,
	disabled: PropTypes.bool,
};
export default PersonaBadge;

const Badge = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	max-width: 320px;
	height: 32px;
	padding: 4px;
	gap: 8px;
	.name {
		color: #333;

		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	${({ disabled }) =>
		disabled &&
		`
    opacity: 0.5;
`}
`;

const UnlinkButton = styled.div`
	:hover {
		cursor: pointer;
	}
`;
