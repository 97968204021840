import { OverlayPanel } from "primereact/overlaypanel";
import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { ActionsDropdown, IconDispatcher } from "shared/ui-basics/index";
import TargetForm from "features/forms/components/sections/TargetSection/TargetForm";
import { useModal } from "use-modal-hook";
import { ActionConfirmModal } from "shared/modals/index";
import { t } from "i18next";
import CustomTooltip from "shared/components/CustomTooltip";

function TargetCard({ item, saveTarget, deleteTarget, roleName, cardWidth = "100%" }) {
	const panelRef = useRef(null);
	const tooltipRef = useRef(null);
	const allMetrics = useSelector((state) => state.libraries.cxMetrics);
	const isReadOnly = useSelector((state) => state.auth.userInfo?.UserType)?.isReadOnly();

	const [isEllipsisActive, setIsEllipsisActive] = useState(false);

	useEffect(() => {
		if (tooltipRef?.current?.offsetWidth < tooltipRef?.current?.scrollWidth) {
			setIsEllipsisActive(true);
		}
	}, [tooltipRef]);

	const isViewer = roleName === "viewer";
	const isContributor = roleName === "contributor";
	const isDeletable = !isViewer && !isContributor;

	return (
		<CardContainer id="TargetCard" cardWidth={cardWidth}>
			<Card className="p-2 " onClick={(e) => panelRef.current.toggle(e)}>
				<Header>
					<Logo>{IconDispatcher("BULLSEYE-ARROW", "me-2")}</Logo>
					<CustomTooltip text={item?.name} disabled={!isEllipsisActive}>
						<Title className="me-4" ref={tooltipRef}>{item?.name}</Title>
					</CustomTooltip>
				</Header>
				{item?.metrics.length > 0 && (
					<Metrics className="ms-4">
						{item?.metrics.map((metricId, index) => {
							const metricObj = allMetrics?.find((m) => m.id === metricId);
							const len = item?.metrics.length - 1;
							return (
								<Metric key={index}>
									<Name className="mx-1">{metricObj?.name}</Name>
									{len !== index && " |"}
								</Metric>
							);
						})}
					</Metrics>
				)}
				<OverlayPanel ref={panelRef}>
					<TargetForm selectedTarget={item} saveTarget={saveTarget} panelRef={panelRef} />
				</OverlayPanel>
			</Card>
			{isDeletable && !isReadOnly && <TargetActions item={item} deleteTarget={deleteTarget} isDeleteAllowed={isDeletable} />}
		</CardContainer>
	);
}
const TargetActions = memo(({ item, deleteTarget, isDeleteAllowed }) => {
	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_TARGET",
		Action: () => deleteTarget({ itemId: item.id }),
		actionBtnText: "ASPX_13",
	});

	const createActions = () => {
		const actions = [];
		if (isDeleteAllowed) actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", text: t("ASPX_13") });
		return actions;
	};

	return (
		<Actions id="actions-list">
			<ActionsDropdown id={item.id} actions={createActions()} toggleButton={IconDispatcher("ACTION-DROPDOWN-HORIZONTAL")} autoClose="outside" isicon="true" />
		</Actions>
	);
});
TargetCard.displayName = "TargetCard";
TargetActions.displayName = "TargetActions";
export default memo(TargetCard);

const Actions = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	z-index: 2;
	display: none;
`;

const CardContainer = styled.div`
	position: relative;
	width: ${(props) => props.cardWidth};
	display: flex;
	align-items: center;
	:hover {
		cursor: pointer;
	}
	:hover ${Actions} {
		display: flex;
	}
`;

const Card = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	background-color: #ffffff;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
`;

const Logo = styled.div`
	color: #f96209;
	path {
		fill: #f96209 !important;
	}
`;

const Title = styled.div`
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #333333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Metrics = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Metric = styled.div`
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #605f60;
`;

const Name = styled.div`
	max-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
