import { getAllRegisteredUsers } from "@redux/index";
import { getAllWorkspaces } from "features/workspace/reducer/WorkspaceActions";

export const methodWorkspaceFun = (methodName, args, dispatch, cjmIdStr, personaIdStr, userId, forceReload) => {

    switch (methodName) {
        case "GET_ALL_WORKSPACE":
            dispatch(getAllWorkspaces());
            break;
        case "GET_ALL_REGISTERED_USERS":
            dispatch(getAllWorkspaces());
            dispatch(getAllRegisteredUsers());
            break;

    }
    return null;
}