import { ActionConfirmModal } from "shared/modals/index";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { PermissionManager } from "@utils/permissionManager";
import { t } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useModal } from "use-modal-hook";
import { toastWrapper } from "@utils/helpers";
import { deleteCxAssessment, getCxProgramAssessmentsExcelExportList } from "@redux/index";
import { EntityList } from "@utils/optionsSets/OptionSets";

export const GetAssessmentContext = (props) => {

	const specificContext = props.context.split(".")[1];
	const loading = useSelector((state) => state.settings.loading);
	const cxAssessments = useSelector(({ cxProgram }) => cxProgram?.current_program?.cxAssessments).map((assessment) => ({
		...assessment,
		name : assessment.contactName
		}));
	const { search } = useLocation();
	const cxProgramId = search.getCxProgramID();
	const isDeletable = props.isEditable && !props.isContributor;
	const hideForPdf = search.hideForPdf();
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Global]?.[specificContext]?.filters;

	const dispatch = useDispatch();

	const { deleteAllowed } = PermissionManager({
		context: "EditCxProgram.cxAssessments",
		hideForPdf,
	});

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const handleDeleteAssessment = (assessment) => {
		actionConfirmModal({
			bodyContent: "DELETE_RECORD",
			Action: () => deleteCxAssessment({ cxProgramId, cxAssessmentIds: [assessment.id] }),
			actionBtnText: "ASPX_13",
		});
	};

	const data = useMemo(() => {
		const newData = {
			assessmentList: {
				visibleHeader: true,
				visibleButtonCreate: props.isEditable && !hideForPdf,
				columns: ["contactName", "date", "score", isDeletable && "actions"],
				displayView: !hideForPdf,
				displayExport: !hideForPdf,
				showCheckbox: false,
				emptyListText: "NO_ASSESSMENTS",
				bulkActions: false,
				disableFixHeader: true,
				showPagination: false,
			},
		};
		const specificContext = props.context.split(".")[1];
		const contextData = _.get(newData, specificContext);
		contextData.listData = cxAssessments;
		contextData.Actions = (assessment) =>
			deleteAllowed &&
			CreateActionsDropdown({
				actions: [
					{
						id: "delete",
						handleClick: () => handleDeleteAssessment(assessment),
						icon: "DELETE",
						text: t("CS_45"),
					},
				],
				variant: "default",
				dropdownClasses: "",
			});
		contextData.loading = loading;
		contextData.buttonCreateLabel = "NEW_ASSESSMENT";
		contextData.buttonCreate = props.handleCreate;
		contextData.displayView = false;
		contextData.redirectUrl = `edit-cx-assessment?programid=${cxProgramId}&id=`;
		contextData.attribute = "contactName";
		contextData.entityType = EntityList.Global;
		contextData.filter = filters;

		contextData.exportExcel = (cxProgramId) => {
			const promise = async () => {
				const { data, error } = await dispatch(getCxProgramAssessmentsExcelExportList({ cxProgramId }));
				var fileDownload = require("js-file-download");
				fileDownload(data, "CX Programs Assessments List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
				if (error) throw error;
			};
			toastWrapper(promise, "EXPORT");
		};
		return contextData;
	}, [loading, cxAssessments,filters]);

	return data;
};
