import { BasicDrillDownModal } from "features/analytics/modals/BasicDrillDownModal";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import MapListCard from "features/analytics/Components/Cards/MapListCard";
import CustomZigZagTick from "features/analytics/Components/Ticks/CustomZigZagTick";
import EmptyData from "features/analytics/Components/EmptyData";
import TextAndValueTooltip from "features/analytics/Components/Tooltips/TextAndValueTooltip";
import { topNTouchpointsDataSelector } from "../journeyTrendDataSelectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useModal } from "use-modal-hook";

function TopNTouchpoints() {
	const { t } = useTranslation();
	const [drillDownModal] = useModal(BasicDrillDownModal);
	const data = useSelector((state) => topNTouchpointsDataSelector(state, { n: 10 }));
	const maps = useSelector((state) => state.analytics.all_maps);

	const renderDrillDownIllustration = ({ data }) => {
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					data={data}
					maxBarSize={data[0]?.value}
					margin={{
						left: -30,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Bar
						dataKey="value"
						fill="#0093E5"
						barSize={20}
					/>
				</BarChart>
			</ResponsiveContainer>
		);
	};

	const handleBarClick = ({ value, name, mapIds }) => {
		const data = [{ name, value }];
		drillDownModal({
			title: `${name}  ${t("JS_193")}`,
			relatedItemsTitle: t("ASPX_97"),
			renderIllustration: () => renderDrillDownIllustration({ data }),
			ListItemComponent: MapListCard,
			items: maps.filter((map) => Array.from(mapIds)?.includes(map.cjmId)),
			itemAttributeId: "cjmId",
		});
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return <TextAndValueTooltip text={label} value={payload[0].value} />;
		}

		return null;
	};

	const tickStyle = {
		fontSize: "14px",
		fontFamilly: "Inter",
		color: "#605F60",
	};

	return (
		<>
			{data?.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={data}
						maxBarSize={data[0]?.value}
						margin={{
							top: 12,
							left: -30,
						}}>
						<CartesianGrid strokeDasharray="3 3" vertical={false} layer="back" />
						<XAxis dataKey="name" interval={0} axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }} textAnchor="middle" tick={<CustomZigZagTick />} height={55} />
						<YAxis axisLine={false} tick={{ ...tickStyle }} />
						<Tooltip cursor={{ fill: "#efefef" }} isAnimationActive={false} content={<CustomTooltip />} />
						<Bar dataKey="value" fill="#0093E5" barSize={20} onClick={handleBarClick} cursor="pointer" />
					</BarChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="TOP_TOUCHPOINTS_REPORT_DESCRIPTION" icon="CHART-COLUMN" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

TopNTouchpoints.displayName = "TopNTouchpoints";
export default TopNTouchpoints;
