import { getEmotionLevelData } from "@utils/helpers";
import { forwardRef } from "react";
import { Button as BoostrapButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import CustomTooltip from "shared/components/CustomTooltip";
import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import styled, { css } from "styled-components/macro";
import { IconDispatcher } from "../Icons";

export const COLORS = {
	white: "#FFFFFF",
	primary: "#F96209",
	primaryHover: "#E35806",
	primaryFocus: "#FA7222",
	lightHover: "#EFEFEF",
	purple: "#7676d2",
	primaryPurple: "#4b4b7b",
	secondary: "#92919D",
	secondaryBg: "#E4E7F1",
	label: "#605F60",
	secondaryLight: "#E4E7F1",
	secondaryOutLine: "#6C757D",
	danger: "#DC3545",
	labelText: "#605F60",
	turquoise: "#18AEAD",
	turquoiseHover: "#138A8A",
	dangerHover: "#DC3545",
	dangerFocus: "#DC3545",
	lightOutline: "#C4C4C4",
};

export const Button = styled(BoostrapButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
	white-space: nowrap;
	line-height: 24px;

	${(props) =>
		props.isicon === "true" &&
		css`
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			border: none;
		`}

	${(props) =>
		props.fullWidth &&
		css`
			width: 100%;
		`}

    ${(props) =>
		props.justifyStart &&
		css`
			justify-content: flex-start !important;
		`}

	///// SIZES \\\\\\

	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			font-size: 14px;

			svg {
				width: 14px !important;
				height: 14px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 24px;
			`}
		`}

    ${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			font-size: 14px;

			svg {
				width: 16px !important;
				height: 16px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 32px;
			`}
		`}

    ${(props) =>
		props.size === "m" &&
		css`
			height: 36px;
			font-size: 14px;

			svg {
				width: 16px !important;
				height: 16px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 36px;
			`}
		`}

    ${(props) =>
		props.size === "l" &&
		css`
			height: 40px;
			font-size: 16px;

			svg {
				width: 16px !important;
				height: 16px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 40px;
			`}
		`}

    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			font-size: 16px;

			svg {
				width: 16px !important;
				height: 16px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 48px;
			`}
		`}

    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			font-size: 24px;

			svg {
				width: 24px !important;
				height: 24px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 60px;
			`}
		`}

        ${(props) =>
		props.size === "home" &&
		css`
			height: 36px;
			font-size: 14px;

			svg {
				width: 20px !important;
				height: 20px !important;
			}

			${props.isicon === "true" &&
			css`
				width: 36px;
			`}
		`}

        ${(props) =>
		props.size === "icon-12" &&
		css`
			svg {
				width: 12px !important;
				height: 12px !important;
			}
		`}


    /// SIZES END \\\

    &.form-control:focus {
		outline: none;
		box-shadow: none;
	}

	&:focus {
		outline: none;
		box-shadow: none;
		&:active {
			outline: none;
			box-shadow: none;
		}
	}

	${(props) =>
		props.disabled === true &&
		css`
			opacity: 0.7 !important;
			pointer-events: none !important;
		`}

	${(props) =>
		props.variant === "clean" &&
		css`
			width: 100%;
			height: 100%;
			padding: 0px;
			margin: 0px;
			background-color: none;
			border: none !important;
			color: none;

			&:hover {
				color: none;
				background-color: none;
				border: none;
			}

			&:focus {
				color: none;
				background-color: none;
				border: none;
			}
		`}

   ${(props) =>
		props.variant === "default" &&
		css`
			background: #f8f9fa;
			box-shadow: 0px 1px 0.5px rgba(0, 0, 0, 0.25);
			&:hover {
				opacity: 0.8;
			}
		`}


    ///// PRIMARY \\\\\\
    ${(props) =>
		props.variant === "primary" &&
		css`
			background-color: ${COLORS.primary} !important;
			border: 1px solid ${COLORS.primary} !important;
			color: ${COLORS.white};

			&:hover {
				color: ${COLORS.white};
				background-color: ${COLORS.primaryHover} !important;
				border: 1px solid ${COLORS.primaryHover} !important;
			}

			&:focus {
				color: ${COLORS.white};
				background-color: ${COLORS.primaryFocus} !important;
				border: 1px solid ${COLORS.primaryFocus} !important;
			}
		`}

    ///// PRIMARY- OUTLINE \\\\\\

   ${(props) =>
		props.variant === "primary-outline" &&
		css`
			color: ${COLORS.primary} !important;
			background-color: transparent !important;
			border: 1px solid ${COLORS.primary};
			/* ${props.active && `color: #FFFFFF !important;background-color: ${COLORS.primaryFocus} !important;`} */
			&:hover {
				color: #ffffff !important;
				background-color: ${COLORS.primaryHover} !important;
				border: 1px solid ${COLORS.primaryHover} !important;
			}

			&:focus {
				color: #ffffff !important;
				background-color: ${COLORS.primaryFocus} !important;
				border: 1px solid ${COLORS.primaryFocus} !important;
			}
		`}
        ///// PRIMARY-LINK \\\\\\

   ${(props) =>
		props.variant === "primary-link" &&
		css`
			color: ${COLORS.primary} !important;
			background-color: transparent !important;

			&:hover {
				background-color: transparent !important;
				color: ${COLORS.primaryHover} !important;
				border: 1px solid ${COLORS.primaryHover} !important;
			}

			&:focus {
				background-color: transparent !important;
				color: ${COLORS.primaryFocus} !important;
				border: 1px solid ${COLORS.primaryFocus} !important;
			}
		`}
       ///// LIGHT \\\\\\
       ${(props) =>
		props.variant === "light" &&
		css`
			background-color: ${COLORS.lightHover} !important;
			color: ${COLORS.label} !important;
			border: 1px solid transparent !important;
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				width: 18px;
				height: 18px;
			}
			${props.active &&
			css`
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
				border: 1px solid ${COLORS.lightHover} !important;
			`}

			&:hover, &:focus {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
				border: 1px solid ${COLORS.lightHover} !important;
			}
		`}


        ///// LIGHT-OUTLINE \\\\\\

        ${(props) =>
		props.variant === "light-outline" &&
		css`
			background-color: ${COLORS.white} !important;
			color: ${COLORS.label} !important;
			border: 1px solid ${COLORS.lightOutline} !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${props.active &&
			css`
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightOutline} !important;
				border: 1px solid ${COLORS.lightOutline} !important;
			`}

			&:hover, &:focus {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightOutline} !important;
				border: 1px solid ${COLORS.lightOutline} !important;
			}
		`}
       ///// LIGHT-LINK \\\\\\

       ${(props) =>
		props.variant === "light-link" &&
		css`
			background-color: transparent !important;
			color: ${COLORS.label} !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${props.active &&
			css`
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			`}

			:hover {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			}
			:focus {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			}
		`}

         ///// DASHED-LIGHT-LINK \\\\\\

       ${(props) =>
		props.variant === "dashed-light-link" &&
		css`
			width: 100%;
			background-color: transparent !important;
			color: ${COLORS.label} !important;
			border: 1px dashed #c4c4c4 !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${props.active &&
			css`
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			`}

			:hover {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			}
			:focus {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			}
		`}
           ///// LINK \\\\\\

        ${(props) =>
		props.variant === "link" &&
		css`
			color: ${COLORS.labelText} !important;
			padding-top: 0.25rem;
			&:hover {
				border-radius: 4px;
			}
		`}

    ${(props) =>
		props.variant === "tertiary-outline" &&
		css`
			background-color: transparent !important;
			color: ${COLORS.turquoise} !important;
			border: 1px solid ${COLORS.turquoise} !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${(props) =>
				props.active &&
				`
            color: ${COLORS.white} !important;
            background-color: ${COLORS.turquoise} !important;
        `}

			&:hover {
				color: ${COLORS.white} !important;
				background-color: ${COLORS.turquoise} !important;
			}
		`}

    ${(props) =>
		props.variant === "tertiary-ghost" &&
		css`
			background-color: transparent !important;
			color: ${COLORS.turquoise} !important;
			border: 1px solid transparent !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${(props) =>
				props.active &&
				`
                color: ${COLORS.turquoise} !important;
                background-color: transparent !important;
            `}

			&:hover {
				color: #138a8a !important;
				background-color: transparent !important;
			}
		`}

    ${(props) =>
		props.variant === "chart" &&
		css`
			background-color: ${COLORS.white};
			border: 1px solid ${COLORS.secondaryLight};
			border-radius: 0.25rem;
			height: 40px;
			min-width: 30px;
			min-height: 40px;
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0.2rem;
			padding: 0.4rem;
			opacity: 0.5;
			&.active {
				opacity: 1;
			}
		`}
     ${(props) =>
		props.variant === "floating" &&
		css`
			background-color: transparent !important;
			color: ${COLORS.secondary1} !important;
			border: 1px solid transparent !important;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 2px -2px 4px rgba(0, 0, 0, 0.25);
			width: 30px;
			height: 30px;
			border-radius: 50%;
			padding: 0.2rem;

			&:hover {
				border: 1px solid ${COLORS.secondaryLight} !important;
			}
		`}
    ${(props) =>
		props.variant === "arrow" &&
		css`
			background-color: ${COLORS.white} !important;
			color: #c4c4c4 !important;
			border: 1px solid #c4c4c4 !important;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15), 2px -2px 4px rgba(0, 0, 0, 0.15);
			width: 22px;
			height: 22px;
			border-radius: 50%;
			padding: 0.2rem;

			&:hover {
				border: 1px solid ${COLORS.secondaryLight} !important;
			}
			svg {
				width: 10px !important;
				height: 10px !important;
			}
		`}
       ${(props) =>
		props.variant === "add" &&
		css`
			background-color: ${COLORS.primary} !important;
			color: ${COLORS.white} !important;
			border: 1px solid #c4c4c4 !important;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15), 2px -2px 4px rgba(0, 0, 0, 0.15);
			height: inherit;
			border-radius: 5px;
			padding: 0.2rem 0.5rem;

			&:hover {
				border: 1px solid ${COLORS.secondaryLight} !important;
			}
		`}
		${(props) =>
		props.variant === "floating-light-sidebar" &&
		css`
			background-color: ${COLORS.white} !important;
			color: ${COLORS.black} !important;
			border: 1px solid #c4c4c4 !important;
			box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
			border-radius: 3.2px;
			&:hover {
				border: 1px solid ${COLORS.secondaryLight} !important;
			}
		`}
		${(props) =>
		props.variant === "danger" &&
		css`
			color: ${COLORS.white} !important;
			background-color: ${COLORS.danger} !important;
			border: 1px solid ${COLORS.danger} !important;
			/* ${props.active && `color: #FFFFFF !important;background-color: ${COLORS.primaryFocus} !important;`} */
			&:hover {
				color: ${COLORS.danger} !important;
				background-color: transparent !important;
				border: 1px solid ${COLORS.dangerHover} !important;
			}

			&:focus {
				color: ${COLORS.danger} !important;
				background-color: transparent !important;
				border: 1px solid ${COLORS.dangerHover} !important;
			}
		`}
		${(props) =>
		props.variant === "danger-outline" &&
		css`
			color: ${COLORS.danger} !important;
			background-color: transparent !important;
			border: 1px solid ${COLORS.danger};
			/* ${props.active && `color: #FFFFFF !important;background-color: ${COLORS.primaryFocus} !important;`} */
			&:hover {
				color: #ffffff !important;
				background-color: ${COLORS.dangerHover} !important;
				border: 1px solid ${COLORS.dangerHover} !important;
			}

			&:focus {
				color: #ffffff !important;
				background-color: ${COLORS.dangerFocus} !important;
				border: 1px solid ${COLORS.dangerFocus} !important;
			}
		`}

		${(props) =>
		props.variant === "dashed" &&
		css`
			border: 1px dashed #c4c4c4 !important;

			background-color: #fff !important;
			color: #c4c4c4 !important;
			:hover {
				opacity: 0.8;
			}
		`}
		${(props) =>
		props.variant === "ai" &&
		css`
			border: 1px solid transparent !important;
			background-color: #ffffff !important;
			background: linear-gradient(45deg, #f96209, #f8ad48, #18aead, #60c1ef, #3f3d56), linear-gradient(90deg, #f8ad48 7.21%, #18aead 51.97%, #3f3d56 97.69%);

			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			svg {
				color: ${COLORS.primary};
			}
			:hover {
				opacity: 0.8;
				border: 1px solid transparent !important;
			}
		`}
		${(props) =>
		props.variant === "light-bulk" &&
		css`
			color: ${COLORS.white} !important;
			background-color: transparent !important;
			border: 1px solid transparent !important;
			&:hover {
				color: ${COLORS.primary} !important;
				background-color: transparent !important;
				border: 1px solid transparent !important;
			}

			&:focus {
				color: ${COLORS.primary} !important;
				background-color: transparent !important;
				border: 1px solid transparent !important;
			}
		`}
        ${(props) =>
		props.variant === "dark" &&
		css`
			background-color: #333 !important;
			color: #fff !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${props.active &&
			css`
				color: #fff !important;
				background-color: #000 !important;
			`}

			:hover {
				color: #fff !important;
				background-color: #000 !important;
			}
			:focus {
				color: #fff !important;
				background-color: #000 !important;
			}
			${props.disabled &&
			css`
				background-color: #888 !important;
			`}
		`}
        ${(props) =>
		props.variant === "dark-outline" &&
		css`
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #333;
			border-radius: 0.25rem;
			background-color: transparent !important;
			color: #333 !important;

			${props.active &&
			css`
				color: #fff !important;
				background-color: #333 !important;
			`}
			:hover {
				color: #fff !important;
				background-color: #333 !important;
			}
			:focus {
				color: #fff !important;
				background-color: #333 !important;
			}
			${props.disabled &&
			css`
				border: 1px solid #888;
				color: #888;
			`}
		`}
        ${(props) =>
		props.variant === "secondary" &&
		css`
			background-color: #3f3d56 !important;
			color: #fff !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${props.active &&
			css`
				color: #fff !important;
				background-color: #333044 !important;
			`}

			:hover {
				color: #fff !important;
				background-color: #333044 !important;
			}
			:focus {
				color: #fff !important;
				background-color: #333044 !important;
			}

			${props.disabled &&
			css`
				background-color: #888796 !important;
			`}
		`}
        
        
		${(props) =>
		props.variant === "black-link" &&
		css`
			background-color: transparent !important;
			color: #333333 !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${props.active &&
			css`
				color: #fff !important;
				background-color: #000 !important;
			`}

			:hover {
				/* color: #fff !important; */
				/* background-color: #000 !important; */
			}
			:focus {
				/* color: #fff !important;
				background-color: #000 !important; */
			}
		`}
`;

export const Switch = styled(Form.Switch)`
	line-height: 1.5 !important;
	.form-check-input {
		background-color: #c4c4c4;
		border-color: #c4c4c4;
	}
	input[type="checkbox" i] {
		cursor: ${(props) => (props.isdisabled === "true" ? "not-allowed" : "pointer")}!important;
	}
	.form-check-input:checked {
		background-color: #f9650d;
		border-color: #f9650d;
	}

	.form-check-input:focus {
		border-color: none;
		box-shadow: none;
	}
`;

export default function GenericSwitch({ text, tooltipText, isChecked, handleToggle, gap, justifyContent, name, isdisabled, ...props }) {
	const { t } = useTranslation();
	return (
		<GenericSwitchContainer gap={gap} justifyContent={justifyContent} className={props?.className}>
			{text && (
				<div className="d-flex align-items-center">
					<div className="me-1">{t(text)}</div>
					{tooltipText && (
						<CustomTooltip text={t(tooltipText)}>
							<div className="d-flex align-items-center">{IconDispatcher("CIRCLE-QUESTION")}</div>
						</CustomTooltip>
					)}
				</div>
			)}
			<Switch checked={isChecked} onChange={handleToggle} disabled={isdisabled} name={name} />
		</GenericSwitchContainer>
	);
}

const GenericSwitchContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: ${(props) => props.justifyContent};
	gap: ${(props) => props.gap}px;
`;

export const UploadLabel = styled(Label)`
	color: ${COLORS.label};
	background-color: ${COLORS.lightHover} !important;
	border: 1px solid transparent !important;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	font-size: 14px;

	svg {
		width: 14px !important;
		height: 14px !important;
	}

	&:hover,
	&:focus {
		color: ${COLORS.label};
		background-color: ${COLORS.lightHover}!important;
		border: 1px solid ${COLORS.lightHover} !important;
	}

	${(props) =>
		props.variant === "tertiary-outline" &&
		css`
			background-color: transparent !important;
			color: ${COLORS.primary} !important;
			border: 1px solid ${COLORS.primary} !important;
			display: flex;
			justify-content: center;
			align-items: center;

			${(props) =>
				props.active &&
				`
            color: ${COLORS.white} !important;
            background-color: ${COLORS.primary} !important;
        `}

			&:hover {
				color: ${COLORS.white} !important;
				background-color: ${COLORS.primary} !important;
			}
		`}
`;

export const SentimentButtonsGroup = ({ emotionLevel, handleClick, ...props }) => {
	const { t } = useTranslation();

	const { description, textColor } = getEmotionLevelData({ level: emotionLevel });
	return (
		<GroupContainer>
			{!props?.onlyIcon && (
				<SentimentLevelContainer>
					<Text>{t("SENTIMENT_LEVEL") + ": "}</Text>
					<SentimentDescription className="ms-2" textcolor={textColor}>
						{description}
					</SentimentDescription>
				</SentimentLevelContainer>
			)}
			<SentimentButtons>
				<EmotionLevel variant="smiley" isActive={emotionLevel === 1} handleClick={() => handleClick(1)} level={1} isRoundedLeft={true} />
				<EmotionLevel variant="smiley" isActive={emotionLevel === 2} handleClick={() => handleClick(2)} level={2} isRounded={false} />
				<EmotionLevel variant="smiley" isActive={emotionLevel === 3} handleClick={() => handleClick(3)} level={3} isRounded={false} />
				<EmotionLevel variant="smiley" isActive={emotionLevel === 4} handleClick={() => handleClick(4)} level={4} isRounded={false} />
				<EmotionLevel variant="smiley" isActive={emotionLevel === 5} handleClick={() => handleClick(5)} level={5} isRoundedRight={true} />
			</SentimentButtons>
		</GroupContainer>
	);
};
export const GenericButton = forwardRef(
	(
		{
			tooltip,
			disableIfEllipsis,
			id,
			tooltipText,
			placement = "bottom",
			icon,
			iconClassName = "",
			iconParams = {},
			tooltipProps = {},
			children,
			hide,
			iconPosition = "before",
			size = "m",
			loading = false,
			...props
		},
		ref
	) => {
		// const [buttonRef, isEllipsis] = useIsEllipsis(disableIfEllipsis);
		const shouldDisplayTooltip = tooltip && tooltipText && !disableIfEllipsis;
		const isicon = props.isicon ? props.isicon : children === undefined ? "true" : "false";
		if (hide) return null;

		const Btn = () => {
			return (
				<Button id={id} size={size} isicon={isicon} ref={ref} {...props}>
					{loading && <Spinner animation="grow" size="sm" className="me-2" />}
					{icon && iconPosition === "before" && IconDispatcher(icon, iconClassName, iconParams)}
					{children}
					{icon && iconPosition === "after" && IconDispatcher(icon, iconClassName, iconParams)}
				</Button>
			);
		};
		return (
			<>
				{shouldDisplayTooltip ? (
					<CustomTooltip id={id} text={tooltipText} placement={placement} {...tooltipProps}>
						<Btn />
					</CustomTooltip>
				) : (
					<Btn />
				)}
			</>
		);
	}
);
GenericButton.displayName = "GenericButton";

export const CustomDropdownButton = forwardRef(({ children, onClick, disabled, width }, ref) => {
	return (
		<ButtonContainer
			className="py-2 px-2"
			width={width}
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			disabled={disabled}>
			<div>{children}</div>
			<div>{IconDispatcher("angle-down")}</div>
		</ButtonContainer>
	);
});

CustomDropdownButton.displayName = "CustomButton";

const GroupContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const SentimentButtons = styled.div`
	display: flex;
	align-items: center;
	height: 55px;
`;
const SentimentLevelContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Text = styled.div`
	font-size: 14px;
	line-height: 17px;
	color: #333333;
	margin-bottom: 16px;
`;

const SentimentDescription = styled.div`
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 16px;
	color: ${(props) => props.textcolor};
`;

export const RadioButton = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 100px;
	border: ${(props) => (props.selected ? "4px solid #333333" : "1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15))")};
	background: var(--gray-white, #fff);
	${(props) =>
		props.showpointer &&
		css`
			cursor: pointer;
		`}
`;

const ButtonContainer = styled.div`
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	:hover {
		cursor: pointer;
		background-color: #efefef;
	}
	${(props) =>
		props.disabled &&
		css`
			background-color: #efefef;
			pointer-events: none;
		`}
`;
