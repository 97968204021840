import { ConceptSearchBar, GenericButton } from "shared/ui-basics/index";
import React,{ useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import DataTable from "../../../grid-table/DataTable";

export default function MapTemplates(props) {
	const { t } = useTranslation();
	const childRef = useRef(null);
	const history = useHistory();
	const [globalFilterValue, setGlobalFilterValue] = useState("");

	const handleChange = (e) => {
		setGlobalFilterValue(e.target.value);
		childRef.current.updateFilter(e.target.value);
	};

	const Search = ConceptSearchBar({ globalFilterValue, handleChange });

	return (
		<Wrapper className="px-3">
			<div className="d-flex justify-content-between mt-3 mb-4">
				<div>
					<GenericButton variant="primary" icon="SQUARE_PLUS" iconClassName="me-1" onClick={() => history.push("/map-creation?isTemplate=true")}>
						{t("JS_145")}
					</GenericButton>
				</div>
				<div>{Search}</div>
			</div>
			<ScrollContainer>
				<div>
					<DataTable context={"map.templates"} ref={childRef} />
				</div>
			</ScrollContainer>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
`;

const ScrollContainer = styled.div`
	overflow: auto;
	width: 100%;
	height: calc(100vh - 350px);
`;

const TableContainer = styled.div`
	padding-right: 2.5rem !important;
	padding-left: 3rem !important;
	@media screen and (max-width: 1280px) {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
`;
