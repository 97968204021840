import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { EMPTY_GUID } from "@utils/helpers";
import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const CreateGenericItem = ({ title, upsertItem, addItem, showSelectColor = true, ...props }) => {
	const { t } = useTranslation();

	const [changedColor, setChangedColor] = useState(false);
	const [selectedColor, setSelectedColor] = useState("#20A9E9");

	const onSuccess = (item) => addItem({ id: item?.id || item });

	const create =  (e) => {
		const newItem = {
			id: EMPTY_GUID,
			name: title,
			colorCode: selectedColor,
		};
		upsertItem(newItem, onSuccess);
		props?.setGlobalFilterValue("");
	};

	return (
		<div>
			<WrapperDivTextInput className="mb-3 d-flex">
				<div className="d-flex align-items-center">
					{showSelectColor && (
						<GenericButton variant="light-link" className="me-1 p-1" disabled onClick={() => setChangedColor(!changedColor)}>
							<CircleColor color={selectedColor} tabindex="0"></CircleColor>
						</GenericButton>
					)}
					<div>{title}</div>
				</div>
				<GenericButton variant="light" disabled={title === ""} className="ms-3" onMouseUp={create}>
					{IconDispatcher("PLUS", "me-1")}
					{t("ASPX_108")}
				</GenericButton>
			</WrapperDivTextInput>
		</div>
	);
};

const CircleColor = styled.div`
	background: ${(props) => props.color};
	height: 30px;
	width: 30px;
	border-radius: 4px;
`;

const WrapperDivTextInput = styled.div`
	border-radius: 4px;
	input {
		border: 0px;
		box-shadow: none !important;
		:focus {
			border: 0px !important;
			box-shadow: none !important;
		}
	}
	img {
		width: 40px;
		height: auto;
	}
`;
