import React, { forwardRef, memo, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { useClickOutside } from "shared/hooks";
import { SearchBar } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import InsightCreator from "./insight-creator";
import InsightsList from "./insights-list";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createDefaultInsight, EMPTY_GUID, encode } from "@utils/helpers";
import { createInsight, linkInsightOnMap, linkInsightRecords, linkInteraction } from "@redux/index";
import { v4 as uuid } from "uuid";

const InsightSearchPickList = forwardRef(({ swimlaneKey, stageId, interaction,selected }, ref) => {
	const innerRef = React.useRef();
	//useClickOutside(innerRef, () => ref.current.hide(),["insight-pick-list-card","buttons__RadioButton","buttons__Button","p-inputtext","insight-name-creator"," BackgroundLabel__Container","insight-creator__Card","ReactVirtualized"]);
	const { cjmIdStr, personaIdStr } = useLocation().search.getAllMapsParams();

	const userInfo = useSelector(({ auth }) => auth?.userInfo);
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allItems = useSelector((state) => state?.insights?.all_insights || []).getActiveRecords(!showSampleData);
	const insightTypes = useSelector((state) => state.libraries?.insightTypes);


	const [searchValue, setSearchValue] = useState("");

	const filterList = allItems?.filter((data) => {
		const value = data?.name?.toLowerCase();
		return value?.includes(searchValue?.toLowerCase());
	});

	filterList?.sortAsc("name");

	const isCreateMode = filterList?.length === 0 && searchValue?.length > 0;
	const isLinkMode = !isCreateMode;

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchValue(value);
	};

	const Search = SearchBar({
		globalFilterValue: searchValue,
		handleChange: handleSearchChange,
		size: "small",
		label: `SEARCH_CREATE_GENERIC`,
		isAutoFocus: true,
    });

    const linkInsightOnMapWrapper = (recordId,onSuccess) => linkInsightOnMap({ swimLaneItemIds: [uuid()], insightIds: [recordId], swimlaneKey: swimlaneKey || uuid(), stageId, cjmIdStr, personaIdStr,onSuccess });

	const handleLink = ({ recordId }) => {
		setSearchValue("");
		ref?.current?.hide();
		if (interaction) {
			linkInteraction({
				recEntityName: "cem_insight",
				recIds: [recordId],
				itemId: interaction?.id,
				stageId,
				attribute: "linkedInsights",
			});
			linkInsightRecords({ itemId: recordId, recIds: [interaction?.touchPointId], recEntityName: "cem_touchpoints", attribute: "linkedTouchpoints" })
			linkInsightOnMapWrapper(recordId)


		}else {
			linkInsightOnMapWrapper(recordId)

		}
	};

	const handleCreate = ({ searchValue, typeId }) => {
		setSearchValue("");
		ref?.current?.hide();
		localStorage.setItem("lastUsedInsightTypeId", typeId);
		const currentTypeId  = insightTypes?.find((it) => it.id === typeId) ? typeId : EMPTY_GUID;

		const item = createDefaultInsight({ currentUser: userInfo, name: encode(searchValue.truncate(100, true)), typeId: currentTypeId});
		createInsight({ item, onSuccess: () =>  handleLink ({ recordId: item.id }),displayToast: "CREATE"});
		// handleLink({ recordId: item.id });

	};

	return (
		<div className="position-relative"ref={innerRef}>
			<OverlayPanelWrapper id="overlay_panel" ref={ref} showCloseIcon>
				{isLinkMode && (
					<>
						<InputContainer>{Search}</InputContainer>
						<InsightsList insights={filterList} handleLink={handleLink} selected={selected} />
					</>
				)}
				{isCreateMode && <InsightCreator searchValue={searchValue} handleSearchChange={handleSearchChange} handleCreate={handleCreate} />}
			</OverlayPanelWrapper>
		</div>
	);
});

InsightSearchPickList.displayName = "InsightSearchPickList";
export default memo(InsightSearchPickList);

const OverlayPanelWrapper = styled(OverlayPanel)`
	width: 512px !important;
	max-height: 800px !important;
	z-index: 1060 !important;
	.p-overlaypanel-content {
		padding: 0px;
	}
	.p-overlaypanel-close {
		display: none;
	}
`;

const InputContainer = styled.div`
	position: relative;
	padding: 16px;
`;
