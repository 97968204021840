import { Input } from "shared/ui-basics/input";
import CustomTooltip from "shared/components/CustomTooltip";
import ReactColor from "shared/popovers/ReactColor";
import { ColorPickerProvider } from "shared/dynamic-imports";
import { removeS } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";
import React from "react";

export const LibraryCardModal = ({ item, setItem, mode, libraryId, title }) => {

    const library = useSelector(state => state.libraries.customLibraries?.find(l => l.id === libraryId))
    const libraryName = library?.name
    const libraryType = library?.isCustom
    const { t } = useTranslation()
    const disabled = mode !== 'create' && !item.isCustom || mode === "view"
    const eventEnterLimit = (e) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13 && !e.shiftKey) {
            // Don't generate a new line
            e.preventDefault();
        }
    }
    const handleChange = (e) => {
        if (e.target.value.length > 190) {
            toast.info(t('EXCEEDED_MAX_SIZE_INPUT'))

        }
        setItem((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value.truncate(190),
        }));
    }
    const handleChangeColor = (color) => {

        setItem((prevState) => ({
            ...prevState,
            colorCode: color,
        }));
    }
    return <DivWrapper>
        <ColorPickerProvider>
            <ReactColor ButtonComponent={<Badge color={item?.colorCode} libraryName={libraryName} libraryType={libraryType} disabled={disabled} />} updateColor={handleChangeColor} disabled={disabled} autoSave autoClose />
        </ColorPickerProvider>
        <CustomTooltip text={item?.name} disabled={item?.name?.length < 20} className={'w-100 d-flex align-items-center'}>
            <TextareaWithIcon className="item-text-area" rows={1} disabled={disabled} mode={mode} onKeyDown={eventEnterLimit} placeholder={t("ENTER_NAME_OF_NEW") + " " + removeS(title) + " ..."} id={"textarea_" + item.id} value={item?.name} name="name" onChange={handleChange} >
            </TextareaWithIcon>
        </CustomTooltip>
    </DivWrapper>
}
const DivWrapper = styled.div`
position:relative;
width:100%;
align-items: center;
    display: flex;
    min-height: 40px;
`
export const TextareaWithIcon = styled(Input)`
padding:0.3rem;
width: 100%;
min-height:1px;
opacity:1 !important;
font-family: 'Inter';
display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;


${props => props.mode === "create" && css`
    color: #c4c4c4;
      font-size: 14px;
      font-style: italic;
`}
:hover {
    border-color:transparent !important;
    box-shadow:none!important;
}
:focus {
    border-color:transparent !important;
    box-shadow:none!important;
}
::placeholder {
      color: #c4c4c4;
      font-size: 14px;
      font-style: italic;
    }

/* line-height:1; */
/* height:auto !important; */

`
export const IconWrapper = styled.div`
    min-width: 30px;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
`
const Badge = styled.div`
  align-self: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: ${({ libraryName }) => (
        libraryName === "Systems" ? "50%" : "4px"
    )};
  cursor:${props => props.disabled ? 'default' : 'pointer'};
  margin: 0.5rem;
  position: relative;
  ${props => props.libraryType ? css`

            border-left: 15px solid transparent;
            border-right:  15px solid transparent;
            border-bottom:  25px solid ${(props) => (props?.color !== "" ? props?.color : "#7A7D82;")}


  ` : css`background-color: ${(props) => (props?.color !== "" ? props?.color : "#7A7D82;")};`
    }
`
