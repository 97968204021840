import React,{ memo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";
import { EditorProvider } from "shared/dynamic-imports";

 const CxComponents = memo(({ isOpen, onClose, title, Component, ...props }) => {
	const { t } = useTranslation();
	const [modal ,setModal] = useState({id: props.id});

	return (
		<BigModal fade={false} isOpen={isOpen} centered={true} toggle={onClose} size="lg" container={document.getElementById("modal-root")}>
			<Header toggle={onClose} className="no-border p-2 pe-3">{t(title)}</Header>
			<ModalBodyCustom key={props.id}>
				<EditorProvider>
					<Component {...props} query={modal} setQuery={setModal} openBlankForm={true} onClose={onClose} />
				</EditorProvider>
			</ModalBodyCustom>
		</BigModal>
	);
});

CxComponents.displayName = "CxComponents";
export { CxComponents };

const BigModal = styled(Modal)`

	min-width: 1300px;
    overflow: hidden;
    .modal {
        overflow: hidden;
    }
       `;
const ModalBodyCustom = styled(ModalBody)`
	overflow: scroll;
	padding:0px;
`;
const Header = styled(ModalHeader)`
	background: var(--color-Grey-Background, #F5F5F5);
`