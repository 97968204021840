import { useImperativeHandle, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/cx-components/style";
import { CheckBoxWrapper } from "shared/modals/library-links/Style";
import { FlexAlignCenter, FlexColumn, IconDispatcher } from "shared/ui-basics";
import styled from "styled-components";

const DuplicateWorkspaceAddOn = ({ innerRef }) => {
	const { t } = useTranslation();

	const [duplicateOptions, setDuplicateOptions] = useState(["customization"]);

	useImperativeHandle(innerRef, () => ({ getDuplicateOptions: () => duplicateOptions }));

	const handleChange = (option) => {
		let newOptions = [];
		if (duplicateOptions.includes(option)) newOptions = duplicateOptions?.filter((opt) => opt !== option);
		else newOptions = [...duplicateOptions, option];
		setDuplicateOptions(newOptions);
	};

	return (
		<DuplicateSection className="p-4 mb-4">
			<FlexAlignCenter className="mb-4">
				<div>{IconDispatcher("FA-LIGHTBULB-EXCLAMATION-ON", "me-2", { style: "thin", size: "xl" })}</div>
				<div>{t("SELECT_CONTENT_DUPLICATE")}</div>
			</FlexAlignCenter>
			<FlexColumn>
				<FlexAlignCenter className="mb-1">
					<CheckBoxWrapper
						type="checkbox"
						sizecheckbox="s"
						checked={duplicateOptions?.includes("libraries")}
						onClick={(e) => e.stopPropagation()}
						onChange={() => handleChange("libraries")}
					/>
					<div>{t("LIBRARIES")}</div>
				</FlexAlignCenter>
				<Labels>{`${t("TOUCHPOINTS")}, ${t("JS_186")}, ${t("SYSTEMS")}, ${t("DEPARTMENTS")}, ${t("BRAND_VALUES")}, ${t("MEASURES")} ${t("AND")} ${t(
					"YOUR_CUSTOM_LIBRARIES"
				)}`}</Labels>
			</FlexColumn>
			<FlexColumn className="mb-4">
				<FlexAlignCenter className="mb-1">
					<CheckBoxWrapper
						type="checkbox"
						sizecheckbox="s"
						checked={duplicateOptions?.includes("customization")}
						onClick={(e) => e.stopPropagation()}
						onChange={() => handleChange("customization")}
					/>
					<div>{t("CUSTOMIZATION")}</div>
				</FlexAlignCenter>
				<Labels>{`${t("TAGS")}, ${t("GROUPS")}, ${t("PRIORITIES")}, ${t("STATUSES")} ${t("AND")} ${t("INSIGHT_TYPES")}`}</Labels>
			</FlexColumn>

		</DuplicateSection>
	);
};

DuplicateWorkspaceAddOn.displayName = "DuplicateWorkspaceAddOn";
export default DuplicateWorkspaceAddOn;

const DuplicateSection = styled.div`
	border-radius: 4px;
	background: #f8f9fa;
`;
