import { PickListMultipleTemplate, PickListSingleFilterTemplate, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { selectGroup, selectPriorities, selectQueryView, selectSelectedViewId, selectStatus, selectTag } from "@redux/memoizedSelectors";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Groups from "../../columns-components/Groups";
import { ProjectName } from "../../columns-components/ProjectName";
import {
    Owner,
    Score,
    Status,
    TablePriority
} from "../../columns-components/ReusableListComponents";
import Tags from "../../columns-components/Tags";

export const GetProjectColumns = (props) => {
    const context = props.context.split(".")[1];
    const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.Project, context));
    const queryView = useSelector(state => selectQueryView(state, EntityList.Project))?.getById(selectedViewId)?.columnSetting;

    const priorityTypes = useSelector(selectPriorities({entity:'project'}));
    const statusTypes = useSelector(selectStatus({entity:'project'}));

    const groups = useSelector(selectGroup);
    const tags = useSelector(selectTag);
    const { dataKey } = props?.contextData;
    const isSample = context === 'sampleData'

	return {
		projectName: {
			field: "name",
			filterField: "name",
			filter: true,
			showFilterOperator: false,
			header: t("PROJECT_NAME"),
			sortable: true,
			style: checkStyle(queryView, "projectName", isSample ? 'xxxl': "xxl"),
			body: (project) => ProjectName(project, props.rename, props.setRename, props.contextData.clickToOpen),
		},
		createdOn: {
			field: "createdOn",
			filterField: "createdOn",
			header: t("CREATED_ON"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			style: checkStyle(queryView, "createdOn", "m"),
			dataType: "date",
			body: (project) => dateBodyTemplate(project.createdOn, "action"),
			filterElement: dateFilterTemplate,
		},
		lastUpdated: {
			field: "lastUpdated",
			filterField: "lastUpdated",
			header: t("JS_72"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			style: checkStyle(queryView, "lastUpdated", "l"),
			dataType: "date",
			body: (project) => dateBodyTemplate(project.lastUpdated, "action"),
			filterElement: dateFilterTemplate,
		},
		startDate: {
			field: "startDate",
			filterField: "startDate",
			header: t("START_DATE"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			style: checkStyle(queryView, "startDate", "m"),
			dataType: "date",
			body: (project) => dateBodyTemplate(project.startDate, "action"),
			filterElement: dateFilterTemplate,
		},
		endDate: {
			field: "endDate",
			filterField: "endDate",
			header: t("END_DATE"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			style: checkStyle(queryView, "endDate", "m"),
			dataType: "date",
			body: (project) => dateBodyTemplate(project.endDate, "action"),
			filterElement: dateFilterTemplate,
		},
		projectProgress: {
			field: "projectProgress",
			header: t("PROGRESS"),
			sortable: true,
			body: (project) => Score(project?.projectProgress),
			style: checkStyle(queryView, "project", "m", "150px"),

		},
		ownerId: {
			field: "ownerId",
			header: t("PROJECT_OWNER"),
			body: Owner,
			style: checkStyle(queryView, "ownerId", "s"),
		},
		priorityId: {
			field: "priorityId",
			header: t("PRIORITY"),
			sortable: true,
			showFilterMatchModes: false,
			filter: true,
			filterField: "priorityId",
			style: checkStyle(queryView, "project", "m", "150px"),

			body: (opp) => TablePriority(opp, dataKey, "project", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
			filterElement: (options) => PickListMultipleTemplate(options, priorityTypes),
		},
		statusId: {
			field: "statusId",
			filterField: "statusId",
			header: t("ASPX_222"),
			sortable: true,
			showFilterMatchModes: false,
			filter: true,
			style: checkStyle(queryView, "statusId", "l"),
			body: (action) => Status(action, dataKey, "project", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
			filterElement: (options) => PickListMultipleTemplate(options, statusTypes),
		},
		tagIds: {
			field: "tagIds",
			filterField: "tagIds",
			header: t("TAGS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Tags(action, EntityList.Project, context),
			style: checkStyle(queryView, "tagIds", "l"),
			filterElement: (options) => PickListSingleFilterTemplate(options, tags, t("SELECT_TAG")),
		},
		groupIds: {
			field: "groupIds",
			filterField: "groupIds",
			header: t("GROUPS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Groups(action, EntityList.Project, context),
			style: checkStyle(queryView, "groupIds", "m"),
			filterElement: (options) => PickListSingleFilterTemplate(options, groups, t("SELECT_GROUP")),
		},
	};
};