import { aiAgreementPolicy, deleteAccountImage, enableAlex, updateAccountSettings, uploadAccountImage } from "@redux/index";
import { getSessionId } from "@redux/memoizedSelectors";
import { UserType } from "@utils/optionsSets/OptionSets";
import { getterApis } from "@utils/optionsSets/getterApis";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { EditLogoModal } from "shared/modals/index";
import GenericSwitch, { Switch } from "shared/ui-basics/buttons";
import { Colors, FlexAlignCenter, IconDispatcher } from "shared/ui-basics/index";
import { Checkbox } from "shared/ui-basics/ui-components/Checkbox";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import { v4 as uuidv4 } from "uuid";
import "../Settings.scss";
import { supportedLanguages } from "@utils/helpers";

// licensedUsersNumber (number of User / Admin / CompanyAdmin users) that the client bought
// readOnlyLicensedUsersNumber (number of Only ReadOnly users) that the client bought
// usersNumber = Actual number of (User / Admin / CompanyAdmin users) that the client can actually use (for example sometime we can add more users for a demo...)
// readOnlyUsersNumber = Actual number of (readOnly users) that the client can actually use

export default function Organization() {
	const { t, i18n } = useTranslation();

	const defaultLanguage = useSelector((state) => state.settings.organization.defaultDisplayLanguage);
	const subscriptionDetails = useSelector((state) => state.settings.subscriptionDetails);
	const showSample = useSelector((state) => state.settings.organization.showSampleData);
	const sessionId = useSelector(getSessionId);
	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const isLoggerCompanyAdmin = Number(userType) === UserType.companyAdmin;
	const aiPurchased = useSelector(({ settings }) => settings.subscriptionDetails.aiPurchased);
	const [languages, setLanguages] = React.useState(supportedLanguages);
	const [selectedLanguage, setSelectedLanguage] = React.useState(defaultLanguage);
	const [checked, setChecked] = React.useState(showSample);
	const [accountImage, setAccountImage] = React.useState(getterApis["GET_ACCOUNT_IMG"](sessionId));

	const contracts = subscriptionDetails?.subscriptionContracts?.sort((a, b) => b.subscriptionPlanValue - a.subscriptionPlanValue);
	const showButtonUpdate = subscriptionDetails.upgradeButtonLink !== null && subscriptionDetails?.upgradeButtonLink?.length > 0;

	useEffect(() => {
		if (checked !== showSample) setChecked(showSample);
		if (selectedLanguage !== defaultLanguage) setSelectedLanguage(defaultLanguage);
	}, [showSample, defaultLanguage]);

	const updateAccountImg = async (data) => {
		if (data === "delete") {
			deleteAccountImg();
		} else {
			const imageExtension = data.split(",")[0];
			const imageBody64 = data.split(",")[1];
			uploadAccountImage({ imageExtension, imageBody64 })
			setAccountImage(data);
		}
	};

	const deleteAccountImg = async () => {
		deleteAccountImage({
			onSuccess: () => {
				hideModalIcon();
				setAccountImage(getterApis["GET_ACCOUNT_IMG"](sessionId) + "&" + uuidv4());
			}
		})
	};

	const handleChangeLanguage = async (e) => {
		const { value } = e.target;
		const language = supportedLanguages?.find(lang => lang.lcid === Number(value))?.value

		updateAccountSettings({
			newLangValue: value, newShowSampleValue: checked, onSuccess: () => {
				setSelectedLanguage(value);
				i18n.changeLanguage(language);

			}
		})
	};

	const handleCheckbox = async (e) => {
		updateAccountSettings({ newLangValue: selectedLanguage, newShowSampleValue: !checked })
		setChecked(e.target.checked);
	};

	const [showModal, hideModalIcon] = useModal(EditLogoModal, {
		sessionId: sessionId,
		updateImg: updateAccountImg,
	});


	return (
		<PageTemplate classNames="container pt-5" pageTitle={t("DISPLAY_SETTINGS")}>
			<SettingsContainer>
				<SettingsCard>
					<CompanyLogo>
						<TitleLabel>{t("ASPX_216")}</TitleLabel>
						<ImageContainer>
							<ImageLogo onClick={showModal}>{IconDispatcher("CAMERA")}</ImageLogo>
							<img src={accountImage} loading="lazy" alt="" />
						</ImageContainer>
					</CompanyLogo>
					<Language>
						<TitleLabel>{t("DISPLAY_LANGUAGE")}</TitleLabel>
						<CustomInput type="select" value={selectedLanguage} onChange={handleChangeLanguage}>
							{languages.map((language, index) => {
								return (
									<option key={index} value={language.lcid}>
										{language.name}
									</option>
								);
							})}
						</CustomInput>
					</Language>
					<ShowSampleData>
						<TitleLabel>{t("ASPX_218")}</TitleLabel>
						<GenericSwitch handleToggle={handleCheckbox} isChecked={checked} />
					</ShowSampleData>
				</SettingsCard>
			</SettingsContainer>
			<SubDetailsContainer>
				<PageTitle className="m-4">{t("ASPX_221")}</PageTitle>
				{contracts?.map((contract, index) => (
					<SubscriptionCard key={index} data={contract} showButtonUpdate={showButtonUpdate} />
				))}
				{isLoggerCompanyAdmin && aiPurchased && <AIcontent />}
			</SubDetailsContainer>
		</PageTemplate>
	);
}

function SubscriptionCard({ data }) {
	const { t } = useTranslation();

	return (
		<CustomContainer className="m-4 p-4">
			<DivWrap>
				<TitleLabel>{t("PLAN_TYPE")}</TitleLabel>
				<SubscriptionPlanCustom>{data.subscriptionPlan}</SubscriptionPlanCustom>
			</DivWrap>
			<DivWrap>
				<TitleLabel>{t("EXPIRE_ON")}</TitleLabel>
				<div className="fw-bold">
					<Moment format="DD/MM/YYYY">{data.validUntil}</Moment>
				</div>
			</DivWrap>
			<DivWrap>
				<TitleLabel>{t("BILLED_USERS")}</TitleLabel>
				<div className="fw-bold">{data.licensedUsersNumber + data.readOnlyLicensedUsersNumber}</div>
			</DivWrap>
		</CustomContainer>
	);
}

function AIcontent() {
	const { t } = useTranslation();
	const aiEnabled = useSelector(({ settings }) => settings.subscriptionDetails.aiEnabled);
	const aiConsentGiven = useSelector(({ settings }) => settings.subscriptionDetails.aiConsentGiven);

	useEffect(() => {
		if (!aiConsentGiven && aiEnabled)
			onEnable()
	}, [aiConsentGiven])

	const onCheck = () => {
		const update = !aiConsentGiven
		aiAgreementPolicy({ value: update })

	}
	const onEnable = () => {
		const update = !aiEnabled
		enableAlex({ enable: update })
	}
	return (<>
		<PageTitle className="m-4 mt-5">{t("AI_CONTENT_ALEX")}</PageTitle>
		<ContainerAlex className="m-4 p-3" >
			<PolicyText>{t("ALEX_POLICY")}
				<PolicyText color='#F96209'><a href='https://www.cemantica.com/privacy-policy' target="_blank" rel="noreferrer noopener">{t("PRIVATE_POLICY")}</a></PolicyText>
			</PolicyText>
			<FlexAlignCenter className="my-2">
				<Checkbox sze="sm" type="checkbox" className="m-1" checked={aiConsentGiven} onChange={onCheck} />
				<PolicyText>{t("AGREE")}</PolicyText>
			</FlexAlignCenter>
		</ContainerAlex>
		<FlexAlignCenter className=" m-2 mx-4 pb-4">
			<Switch checked={aiEnabled} onChange={onEnable} disabled={!aiConsentGiven} />
			<PolicyText bold>{t("ENABLE_AI")}</PolicyText>
		</FlexAlignCenter>
	</>
	)
}
const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const SubDetailsContainer = styled.div``;

const PageTitle = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	color: #333333;
`;

const SettingsCard = styled.div`
	align-self: center;
	display: flex;
	flex-direction: column;
	width: 400px;
	gap: 40px;
`;

const CompanyLogo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const ImageLogo = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	color: #3f3d56;
	border: 1px solid #3f3d56;
	background-color: #ffffff;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	display: none;
	cursor: pointer;
`;
const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px dashed #dee2e6;
	height: 150px;
	max-height: 150px;
	padding: 15px;
	background-color: #f6f6ff;
	:hover ${ImageLogo} {
		display: flex;
	}
	:hover img {
		display: none;
	}
	img {
		width: 250px;
		cursor: pointer;
		max-height: 150px;
		object-fit: cover;
	}
`;

const SubscriptionPlanCustom = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 39px;
	color: #333333;
`;

const TitleLabel = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #605f60;
	white-space: nowrap;
`;

const DivWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	width: 100px;
`;

const CustomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background: #f8f9fa;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
`;

const Language = styled.div`
	display: flex;
	align-items: center;
	gap: 50px;
`;

const ShowSampleData = styled.div`
	display: flex;
	align-items: center;
	gap: 85px;
`;

const CustomInput = styled(Input)`
	&.form-control:focus {
		background-color: transparent;
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	&.form-control:disabled,
	.form-control[readonly] {
		background-color: #e5e4e5;
		color: #92919d;
		opacity: 1;
	}
`;
const PolicyText = styled.span`
color: ${props => props.color ? props.color : '#33333'};

font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
font-weight: ${props => props.bold ? 'bold' : '400'};
a {
	color: ${props => props.color ? props.color : '#33333'};
	text-decoration: none;

}
`;
const ContainerAlex = styled.div`
background: #f8f9fa;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
`;