import { InitActionCreator } from "@redux/store";
import { EMPTY_GUID, constructUrl, encode, toastWrapper } from "@utils/helpers";
import { t } from "i18next";
import { store } from "index";
import { genericService } from "shared/GenericService";

const BASE_URL = "api/map/";

const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" }); // api actions type get
const createPostActionPhase = InitActionCreator({ baseUrl: "api/phase/", method: "POST" }); // api actions type post
const createPostActionStage = InitActionCreator({ baseUrl: "api/stage/", method: "POST" }); // api actions type post
const createPostActionSwim = InitActionCreator({ baseUrl: "api/swimlane/", method: "POST" }); // api actions type post
const createPostActionIcon = InitActionCreator({ baseUrl: "api/icon/", method: "POST" }); // api actions type post
const createPostActionPersInt = InitActionCreator({ baseUrl: "api/personaInteraction/", method: "POST" }); // api actions type post


export const deleteCJM = (props) => genericService({ ...props, action: createPostAction({ type: "DELETE_CJM", endPoint: "deleteCJM", data: { cjmIdStr: props.id } }), displayToast: "DELETE" });
export const deletePermanentlyMap = (props) => genericService({
	...props, action: createPostAction({
		type: "DELETE_PERMANENTLY_MAP", endPoint: "deletePermanentlyCJM", data: {
			cjmIdStr: props?.id,
			deleteAllVersion: props?.deleteAllVersion || false,
			generatedByAI: props?.generatedByAI || false,
		}
	}), displayToast: "DELETE"
});

export const copyCJM = (props) => genericService({
	...props,
	action: createPostAction({
		type: "COPY_MAP", endPoint: "copy",
		data: {
			cjmId: props.id,
			copiedMapName: encode(props.name),
			workspaceIds: props.workspaceIds
		},
	}),
	displayToast: props.displayToast || "COPY",

});

export const archiveCJM = (props) => genericService({
	...props, action: createPostAction({
		type: "ARCHIVE_CJM", endPoint: "archiveCJM", data: {
			cjmIdStr: props.id
		}
	}),
	displayToast: "ARCHIVE"
});
export const restoreCJM = (props) => genericService({
	...props, action: createPostAction({
		type: "RESTORE_CJM", endPoint: "restoreCJM", data: {
			cjmIdStr: props.id
		}
	}),
	displayToast: "RESTORE"
});

export const createCJM = (props) => genericService({
	...props, action: createPostAction({
		type: "CREATE_CJM", endPoint: "createCJM", data: {
			newCjmParams: {
				...props.newCjmParams,
				name: encode(props?.newCjmParams?.name),
				description: encode(props?.newCjmParams?.description),
			}
		}
	})
});


export const createJourneyMap = ({ newMap, history, onSuccess, goToMap = true }) => {
	goToMap && history?.push("/map-creation-loading");

	const callbackFunc = (data) => {
		if (goToMap) history?.push(`/EditMap?cjmid=${data.cjmId}&personaId=${newMap.personaId}${newMap.linkToCxProgramId  ? `&cxProgramId=${newMap.linkToCxProgramId}` : ''}`);
		if (onSuccess) onSuccess(data);
	}
	const onFailed = (error) => {
		history?.push("/journey-map");
		throw error;
	}
	createCJM({
		newCjmParams: newMap,
		onSuccess: callbackFunc,
		onFailed,
		displayToast: "CREATE"
	})
};
export const CreateMapWithTemplate = ({ cjmId, name, history, folderId }) => {
	history.push("/map-creation-loading");
	const callbackFunc = (data) => {
		history.push(`/EditMap?cjmid=${data.cjmId}&personaId=${data?.linkedPersonas[0]?.personaId || "00000000-0000-0000-0000-000000000000"}`);
	}
	const onFailed = (error) => {
		history?.push("/journey-map");
		throw error;
	}
	createCJM({
		newCjmParams: {
			name: name,
			isTemplate: false,
			linkToFolderId: folderId || EMPTY_GUID,
			fromTemplateId: cjmId,

		},
		onSuccess: callbackFunc,
		onFailed,
		displayToast: "CREATE"
	})
};
export const getAllMapsListData = (props) => createGetAction({
	type: "GET_ALL_MAPS_LIST_DATA", endPoint: constructUrl("getAllMapsListData", {
		redirectReducer: props?.redirectReducer
	}),
	meta: { joinRequest: 'GET_ALL_MAPS_LIST_DATA' },
	params: { dashboardCode: props?.dashboardCode }
});

export const getPersonaMapDetails = (props) => createGetAction({
	type: "GET_CURRENT_MAP", endPoint: constructUrl("getPersonaMapDetails", {
		cjmIdStr: props?.cjmIdStr,
		personaIdStr: props?.personaIdStr,
		onlySentimentData: props?.onlySentimentData,
	}),
	meta: { joinRequest: 'GET_CURRENT_MAP' },
	params: {
		personaIdStr: props?.personaIdStr,
		cjmIdStr: props?.cjmIdStr,
		onlySentimentData: props?.onlySentimentData,
		exportingPdf: props?.exportingPdf
	}
});

export const resetCurrentMap = () => ({ type: "RESET_CURRENT_MAP" });

export const getMapChartData = (props) => createGetAction({
	type: "GET_MAP_CHART_DATA", endPoint: constructUrl("getMapChartData", {
		cjmIdStr: props?.cjmIdStr,
		personaIdStr: props?.personaIdStr,
		forceRecalculate: props?.forceRecalculate || false,
	}),
	meta: { joinRequest: 'GET_MAP_CHART_DATA' },
	params: {
		personaIdStr: props?.personaIdStr,
	}
});
export const shareUsersManyMaps = (props) => genericService({
	...props, action: createPostAction({
		type: "SHARE_USERS_MANY_MAP", endPoint: "shareManyMap", data: {
			ids: props?.usersIds,
			cjmIds: props?.cjmIds,
			roleDetails: props?.roleDetails,
			teamIds: props?.teamIds,
		}
	}),
	displayToast: "UPDATE"
});
export const shareManyCjm = (props) => genericService({
	...props, action: createPostAction({
		type: "SHARE_USERS_SINGLE_MAP", endPoint: "shareMany", data: {
			ids: props?.usersIds,
			cjmIdStr: props?.cjmIdStr,
			roleDetails: props?.roleDetails,
			teamIds: props?.teamIds,
		}
	}),
	displayToast: "UPDATE"
});

export const shareCjm = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_SHARE_CJM_DB", endPoint: "shareCJM",
		data: {
			userId: props?.userId,
			cjmIdStr: props?.cjmIdStr,
			roleDetails: props?.roleDetails,
			teamId: props?.teamId,
		},
		param: {
			fullName: props?.userIdName,
		}
	}),
	displayToast: "UPDATE"
});

export const unShareCjm = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_UNSHARE_CJM_DB", endPoint: "unShareCJM",
		data: {
			userId: props?.userId,
			cjmIdStr: props?.cjmIdStr,
			teamId: props?.teamId,
		}
	}),
	displayToast: "UPDATE"
});

export const updateCjmName = (props) => createPostAction({
	type: "UPDATE_CJM_NAME_v1", endPoint: "updateCjmName",
	data: {
		cjmIdStr: props?.id,
		newValue: window.btoa(unescape(encodeURIComponent(props?.name))),
	}
})

export const replaceMapLogo = (props) => genericService({
	...props, action: createPostAction({
		type: "REPLACE_MAP_LOGO", endPoint: "replaceMapLogo",
		data: {
			cjmId: props?.cjmId,
			FileExtension: props?.FileExtension,
			DocumentBody: props?.DocumentBody,
		}
	}),
});
export const deleteMapImage = (props) => genericService({
	...props, action: createPostAction({
		type: "DELETE_MAP_IMAGE", endPoint: "deleteMapImage",
		data: {
			cjmIdStr: props?.cjmId,

		}
	}),
});

export const updateIconSimpleInteraction = (personaSTR, stageId, icon, attribute) => ({
	type: "UPDATE_ICON_SIMPLE_INTERACTION",
	payload: { personaIdStr: personaSTR, stageId: stageId, newIcon: icon, attribute: attribute },
});
export const deleteIconSimpleInteraction = (personaIdStr, stageId, Icon, attribute) => ({
	type: "DELETE_ICON_SIMPLE_INTERACTION",
	payload: { personaIdStr: personaIdStr, stageId: stageId, Icon: Icon, attribute: attribute },
});
export const updateStageAttribute = (stageId, attribute, value) => ({
	type: "UPDATE_STAGE_ATTRIBUTE",
	payload: { stageId: stageId, attribute: attribute, value: value },
});
export const deleteStageAttribute = (stageId, attribute, value) => ({
	type: "DELETE_STAGE_ATTRIBUTE",
	payload: { stageId: stageId, attribute: attribute, value: value },
});
export const updateAdvancedInteractionIcon = (cardId, attribute, value) => ({
	type: "UPDATE_ADVANCED_INTERACTION_ICON",
	payload: { cardId: cardId, attribute: attribute, value: value },
});
export const deleteAdvancedInteractionIcon = (cardId, attribute, value) => ({
	type: "DELETE_ADVANCED_INTERACTION_ICON",
	payload: { cardId: cardId, attribute: attribute, value: value },
});

export const getMapUsers = (props) => createGetAction({
	type: "GET_MAP_USERS", endPoint: constructUrl("getMapUsers", {
		cjmIdStr: props?.cjmId,
		forceViewer: props?.forceViewer,

	}),
	params: {
		isPrint: props?.isPrint,
		userId: props?.userId,
		cxOwnerEntity: props?.cxOwnerEntity,
	}
});

export const resetMapUsers = () => ({ type: "RESET_MAP_USER" });

export const updateStateCJM = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_CJM_TYPE", endPoint: "updateCjmType",
		data: {
			cjmIdsStr: props?.id,
			newValue: props?.value,
		}
	}),
});

export const updateCjmDescription = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_CJM_DESCRIPTION", endPoint: "updateCjmDescription",
		data: {
			cjmIdStr: props?.id,
			newValue: props?.value !== null ? encode(props?.value) : "",
		}
	}),
});

export const updateTemplateCJM = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_CJM_TEMPLATE_PUBLISH", endPoint: "updateTemplatePublished",
		data: {
			cjmIdStr: props?.id,
			isChecked: props?.isChecked,
		}
	}),
});
export const updateLockedCJM = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_CJM_TEMPLATE_LOCKED", endPoint: "updateTemplateLocked",
		data: {
			cjmIdStr: props?.id,
			isChecked: props?.isChecked,
		}
	}),
});

export const updateLinksCjms = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_MAP_LINKS_CJM", endPoint: "updateMapLinksWithCjms",
		data: {
			cjmIdStr: props?.cjmIdStr,
			cjmIdsToLink: props?.mapsStr,
			personaIdStr: props?.personaIdStr,
			cjmIdsToUnlink: props?.cjmIdsToUnlink,
		}
	}),
});

export const getExcelExportMap = (props) => createGetAction({
	type: "GET_EXCEL_EXPORT", endPoint: constructUrl("getExcelExport", {
		cjmIdStr: props?.cjmIdStr,
	}),
	responseType: "blob",

});

export const getPDFExportMap = (props) => createGetAction({
	type: "GET_PDF_EXPORT_CJM", endPoint: constructUrl("getPdfExport", {
		cjmIdStr: props?.cjmIdStr,
		personaId: props?.personaIdStr,
		swimLaneIds: props?.swimLaneIds,
		width: props?.width + 300,
		height: props?.height + 300,
	}),
	responseType: "blob",

});

export const getIMGExportMap = (props) => createGetAction({
	type: "GET_IMG_EXPORT", endPoint: constructUrl("getImgExport", {
		cjmIdStr: props?.cjmIdStr,
		personaId: props?.personaIdStr,
		swimLaneIds: props?.swimLaneIds,
		width: props?.width + 300,
		height: props?.height + 300,
	}),
	responseType: "blob",

});

export const filterEmotionMap = (id) => ({ type: "FILTER_EMOTION_MAP", payload: id });

export const toggleTargetValues = () => ({ type: "SHOW_TARGET_VALUES" });

export const updateExpandedHeader = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_HEADER_EXPANDED", endPoint: "updateHeaderExpanded",
		data: {
			cjmIdStr: props?.cjmIdStr,
			isExpanded: props?.show,
		}
	}),
});

export const recomputeMapSentimentLevel = (props) => genericService({
	...props, action: createPostAction({
		type: "RECOMPUTE_MAP_SENTIMENT_LEVEL", endPoint: "recomputeMapSentimentLevel",
		data: {
			channelIdStr: props?.channelIdStr,
			cjmIdStr: props?.cjmIdStr,
			personaIdStr: props?.personaIdStr,
		}
	})
});

export const deleteManyMaps = (props) => genericService({
	...props, action: createPostAction({
		type: "DELETE_MANY_MAPS", endPoint: "deleteMany",
		data: {
			cjmIds: props?.ids,
		}
	}),
});

export const deletePermanentlyManyMaps = (props) => genericService({
	...props, action: createPostAction({
		type: "DELETE_PERMANENTLY_MANY_MAPS", endPoint: "deletePermanentlyMany",
		data: {
			cjmIds: props?.ids,
		}
	}),
});

export const archiveManyMaps = (props) => genericService({
	...props, action: createPostAction({
		type: "ARCHIVE_MANY_MAPS", endPoint: "archiveMany",
		data: {
			cjmIds: props?.ids,
		}
	}),
});

export const restoreManyMaps = (props) => genericService({
	...props, action: createPostAction({
		type: "RESTORE_MANY_MAPS", endPoint: "restoreMany",
		data: {
			cjmIds: props?.ids,
		}
	}),
	displayToast: "RESTORE"
});

export const convertCJMToTemplate = (props) => genericService({
	...props, action: createPostAction({
		type: "CONVERT_CJM_TO_TEMPLATE", endPoint: "convertCJMToTemplate",
		data: {
			cjmId: props?.cjmId,
			newName: encode(props?.name),
		}
	}),
	displayToast: "SAVE_MAP_AS_TEMPLATE_SUCCESS"
});

export const getExcelExportListCjm = (ids, columns) => createPostAction({
	type: "GET_EXCEL", endPoint: "getListExcelExport",
	data: {
		ids,
		columns: columns?.join("|"),
	},
	responseType: "blob",

});

export const getLinkedCjmsExcelExport = (entityName, id) => createGetAction({
	type: "GET_EXCEL_LINK_CJM", endPoint: constructUrl("getLinkedCjmsExcelExport", {
		entityName: entityName,
		id: id,
	}),
	responseType: "blob",

});

export const updateShowRoute = ({ cjmIdStr, showRoute, isMain, ...props }) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_SHOW_ROUTE",
		endPoint: "updateShowRoutes",
		data: { cjmIdStr, showRoute, isMain },
	}),
});

export const updateCjmStatus = (props) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_CJM_STATUS", endPoint: "updateCjmStatus",
		data: {
			newValueId: props?.newValueId,
			cjmIdsStr: props?.cjmIdsStr,
		}
	}),
});

export const getHierachyPdf = ({ cjmIdStr, personaIdStr, width, height, ...props }) => genericService({
	...props,
	action: createGetAction({
		type: "GET_PDF_EXPORT_HIERARCHY", endPoint: constructUrl("getHierarchyPdfExport", {
			cjmIdStr,
			personaId: personaIdStr,
			width,
			height
		}),
		responseType: "blob",
	})
});
/*********** VERSION - MAP  ************/

export const createMapVersion = ({ cjmId, name, description, versionNumber, ...props }) => genericService({
	...props, action: createPostAction({
		type: "CREATE_MAP_VERSION", endPoint: "createMapVersion",
		data: {
			cjmId,
			name,
			description,
			versionNumber,
		}
	}),
	displayToast: "SAVE"
});

export const updateMapVersion = ({ cjmId, name, description, ...props }) => genericService({
	...props, action: createPostAction({
		type: "UPDATE_MAP_VERSION", endPoint: "updateMapVersion",
		data: {
			cjmId,
			name,
			description,
		}
	}),
});

export const restoreMapVersion = ({ cjmId, versionNumber, ...props }) => genericService({
	...props, action: createPostAction({
		type: "RESTORE_MAP_VERSION", endPoint: "restoreMapVersion",
		data: {
			cjmId,
			versionNumber,
		}
	}),
	displayToast: "RESTORE_VERSION"
});


export const getMapAudit = ({ cjmId, personaId, ...props }) => genericService({
	...props,
	action: createGetAction({
		type: "GET_MAP_AUDIT", endPoint: constructUrl("getMapAuditTrail", {
			cjmId: cjmId,
			personaId: personaId,

		}),
	})
});



// **** PHASES ****
export const reorderPhases = (props) => genericService({
	...props, action: createPostActionPhase({
		type: "REORDER_PHASE_DB", endPoint: "reorderPhases",
		data: {
			cjmIdStr: props?.cjmIdStr,
			newPhasesOrder: props?.newPhasesOrder,
		}
	}),
});

export const updateOrderPhases = (source, destination) => ({
	type: "ORDER_PHASES",
	payload: { source: source, destination: destination }
});

export const updateNamePhase = (props) => genericService({
	...props, action: createPostActionPhase({
		type: "UPDATE_PHASE_NAME", endPoint: "updatePhaseName",
		data: {
			phaseIdStr: props?.phaseIdStr,
			newValue: encode(props?.newValue),
			cjmIdStr: props?.cjmIdStr,
		}
	}),
});

export const removePhase = (props) => genericService({
	...props, action: createPostActionPhase({
		type: "DELETE_PHASE", endPoint: "deletePhase",
		data: {
			phaseIdStr: props?.phaseIdStr,
			cjmIdStr: props?.cjmIdStr,
			personaIdStr: props?.personaIdStr,
			deleteOnlyPhase: props?.deleteOnlyPhase,
		}
	}),
});

export const addStage = (props) => genericService({
	...props, action: createPostActionPhase({
		type: "ADD_STAGE_FROM_PHASE", endPoint: "addStageFromPhase",
		data: {
			phaseIdStr: props?.phaseIdStr,
			name: props?.stageName,
			mapMode: props?.mapMode,
			cjmIdStr: props?.cjmIdStr,
			personaIdStr: props?.personaIdStr,
			srcStageIdStr: props?.stageIdFrom,
		},
		params: { stageIdFrom: props?.stageIdFrom },

	}),
});

export const updatePhaseLinksWithCjms = (props) => genericService({
	...props, action: createPostActionPhase({
		type: "UPDATE_PHASE_LINKS_CJM", endPoint: "updatePhaseLinksWithCjms",
		data: {
			phaseIdStr: props?.phaseIdStr,
			cjmIdStr: props?.cjmIdStr,
			personaIdStr: props?.personaIdStr,
			cjmIdsToLink: props?.cjmIdsToLink,
			cjmIdsToUnlink: props?.cjmIdsToUnlink,
		},
	}),
});

export const updatePhasesColor = ({ newValue, cjmId, ...props }) => genericService({
	...props, action: createPostActionPhase({
		type: "UPDATE_ALL_PHASE_COLOR", endPoint: "updateAllPhasesColor",
		data: {
			newValue: newValue,
			cjmId: cjmId,
		},
	}),
});

export const updateSinglePhaseColor = ({ newValue, phaseIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionPhase({
		type: "UPDATE_PHASE_COLOR", endPoint: "updatePhaseColor",
		data: {
			phaseId: phaseIdStr,
			newValue: newValue,
			cjmId: cjmIdStr,
		},
	}),
});

export const updatePhaseIdInStage = (stageId, phaseId) => ({
	type: "UPDATE_PHASE_ID_IN_STAGE",
	payload: { stageId: stageId, phaseId: phaseId },
});

/// **** STAGES ****
export const removeStage = ({ stageId, personaIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "REMOVE_STAGE", endPoint: "deleteStage",
		data: {
			stageIdStr: stageId,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const updateWidthStage = ({ stageIdStr, width, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_WIDTH", endPoint: "updateStageWidth",
		data: {
			stageIdStr: stageIdStr,
			newValue: width,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const reorderStages = ({ cjmIdStr, newStagesOrder, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_ORDER_STAGE_DB", endPoint: "reorderStages",
		data: {
			cjmIdStr: cjmIdStr,
			newStagesOrder: newStagesOrder,
		},
	}),
});

export const updateOrderStages = (newStagesOrder) => ({
	type: "ORDER_STAGES",
	payload: { newStagesOrder: newStagesOrder }
});

export const addPhase = ({ stageName, phaseName, mapMode, cjmIdStr, personaIdStr, phaseIdFrom, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "ADD_STAGE_AND_PHASE_DB", endPoint: "addPhaseAndStage",
		data: {
			name: stageName,
			namePhase: phaseName,
			mapMode: mapMode,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
			srcPhaseIdStr: phaseIdFrom,
		},
		params: { phaseIdFrom: phaseIdFrom },

	}),
});

export const updateNameStage = ({ stageIdStr, newValue, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_NAME", endPoint: "updateStageName",
		data: {
			stageIdStr: stageIdStr,
			newValue: encode(newValue),
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const copyStage = ({ stageIdStr, stageIdNew, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "COPY_STAGE", endPoint: "copyStage",
		data: {
			stageIdOld: stageIdStr,
			cjmIdStr: cjmIdStr,
			stageIdNew: stageIdNew,
			personaIdStr: personaIdStr,
		},
	}),
});

export const reorderStageInteraction = ({ newStageInteractionsOrder, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "REORDER_STAGE_INTERACTIONS", endPoint: "reorderStageInteractions",
		data: {
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
			newStageInteractionsOrder: newStageInteractionsOrder,
		},
	}),
});

export const updatePersonaExperienceText = ({ newValue, textType, stageIdStr, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_PERSONA_EXPERIENCE_TEXT", endPoint: "updatePersonaExperienceText",
		data: {
			stageIdStr: stageIdStr,
			textType: textType,
			newValue: encode(newValue),
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const updateStageDesc = ({ newValue, stageIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_DESCRIPTION", endPoint: "updateStageDescription",
		data: {
			stageIdStr: stageIdStr,
			newValue: encode(newValue),
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const updateEmotionLevel = ({ newValue, stageIdStr, personaIdStr, cjmIdStr, personaInteractionId,genericId, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_PERSONA_STAGE_SIMPLE_SENTIMENT", endPoint: "updatePersonaStageSimpleSentiment",
		data: {
			stageId: stageIdStr,
			personaId: personaIdStr,
			newValue: newValue,
			cjmId: cjmIdStr,
			personaInteractionId: personaInteractionId,
		},
		params: {genericId: genericId}
	}),
});

export const updateStageTargetEmotionLevel = ({ newValue, stageIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_TARGET_EMOTION_LEVEL", endPoint: "updateStageTargetEmotionLevel",
		data: {
			stageIdStr: stageIdStr,
			newValue: newValue,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const updateStageKPIs = (newValue, stageIdStr, cjmIdStr) => createPostActionStage({
	type: "UPDATE_STAGE_KPIS", endPoint: "updateStageKPIs",
	data: {
		stageIdStr: stageIdStr,
		newValue: encode(newValue),
		cjmIdStr: cjmIdStr,
	},
})

export const updateStageIssues = (newValue, stageIdStr, cjmIdStr) => createPostActionStage({
	type: "UPDATE_STAGE_ISSUES", endPoint: "updateStageIssues",
	data: {
		stageIdStr: stageIdStr,
		newValue: encode(newValue),
		cjmIdStr: cjmIdStr,
	},
})

export const updateStageProcesses = (newValue, stageIdStr, cjmIdStr) => createPostActionStage({
	type: "UPDATE_STAGE_PROCESSES", endPoint: "updateStageProcesses",
	data: {
		stageIdStr: stageIdStr,
		newValue: encode(newValue),
		cjmIdStr: cjmIdStr,
	},
})

export const updateStageImage = ({ stageIdStr, imageExtension, imageBody64, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_IMAGE", endPoint: "updateStageImage",
		data: {
			stageIdStr: stageIdStr,
			imageExtension: imageExtension,
			imageBody64: imageBody64,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const deleteStageImage = ({ stageIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "DELETE_STAGE_IMAGE", endPoint: "deleteStageImage",
		data: {
			stageIdStr: stageIdStr,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const updateStageLinksWithCjms = ({ stageIdStr, cjmIdStr, cjmIdsToLink, cjmIdsToUnlink, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_LINKS_CJM", endPoint: "updateStageLinksWithCjms",
		data: {
			stageIdStr,
			cjmIdStr,
			personaIdStr,
			cjmIdsToLink,
			cjmIdsToUnlink,
		},
	}),
});

export const updateStagesColor = ({ newValue, cjmId, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_ALL_STAGE_COLOR", endPoint: "updateAllStageColor",
		data: {
			newValue: newValue,
			cjmId: cjmId,
		},
	}),
});

export const updateSingleStageColor = ({ newValue, stageIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionStage({
		type: "UPDATE_STAGE_COLOR", endPoint: "updateStageColor",
		data: {
			stageId: stageIdStr,
			newValue: newValue,
			cjmId: cjmIdStr,
		},
	}),
});

////// **** Swimlanes **** //////
export const orderSwimlanes = (newOrder) => ({
	type: "ORDER_SWIMLANES",
	payload: newOrder,
});

export const updateSwimLanesOrder = ({ newOrder, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_SWIMLANES_ORDER_DB", endPoint: "updateSwimLamesOrder",
		data: {
			newOrder: newOrder,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const updateTextSwimlaneDesc = ({ key, stageId, value, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_SWIMLANES_DESCRIPTION", endPoint: "updateCustomSwimLaneDetails",
		data: {
			customSwimLaneIdStr: key,
			stageIdStr: stageId,
			description: encode(value),
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const updateSwimlaneProps = ({ cjmId, modifiedSwimLane, modifiedAttribNames, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_SWIMLANES_PROPERTIES", endPoint: "updateProperties",
		data: {
			cjmId,
			modifiedSwimLane: {
				...modifiedSwimLane,
				name: encode(modifiedSwimLane.name),
				description: encode(modifiedSwimLane.description)
			},
			modifiedAttribNames,
		},
	}),
});
export const deleteSwimLane = ({ customSwimLaneIdStr, cjmIdStr, personaIdStr, genericId, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "DELETE_CUSTOM_SWIMLANE", endPoint: "deleteCustomSwimLane",
		data: {
			customSwimLaneIdStr: customSwimLaneIdStr,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
		params: { genericId },
	}),
	// displayToast: "DELETE"
});

export const updateTouchPointOrder = (newOrder) => ({
	type: "UPDATE_TOUCHPOINT_ORDER",
	payload: newOrder,
});
export const upsertListItem = ({ item, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_CUSTOM_LIST_ITEM_SWIMLANE_DETAILS", endPoint: "upsertCustomListItem",
		data: {
			customSwimLaneItemIdStr: item.id,
			customSwimLaneIdStr: item.swimlaneKey,
			stageIdStr: item.stageId,
			description: encode(item.description),
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});
export const updateStageListItem = ({ customSwimLaneItemIdStr, customSwimLaneIdStr, prevStageIdStr, newStageIdStr, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_STAGE_LIST_ITEM", endPoint: "updateCustomListItemStage",
		data: {
			customSwimLaneItemIdStr: customSwimLaneItemIdStr,
			customSwimLaneIdStr: customSwimLaneIdStr,
			prevStageIdStr: prevStageIdStr,
			newStageIdStr: newStageIdStr,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const updateStageLibraryItem = ({ customSwimLaneItemIdStr, customSwimLaneIdStr, prevStageIdStr, newStageIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_STAGE_LIST_ITEM", endPoint: "updateCustomLibraryItemStage",
		data: {
			customSwimLaneItemIdStr: customSwimLaneItemIdStr,
			customSwimLaneIdStr: customSwimLaneIdStr,
			prevStageIdStr: prevStageIdStr,
			newStageIdStr: newStageIdStr,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const updateOrderCxAction = (newOrder, swimlaneKey) => ({
	type: "UPDATE_ORDER_CX_ACTION",
	payload: { newOrder: newOrder, swimlaneKey: swimlaneKey }
});

export const updateOrderKPI = (newOrder, swimlaneKey) => ({
	type: "UPDATE_ORDER_KPI",
	payload: { newOrder: newOrder, swimlaneKey: swimlaneKey }
});
export const updateSwimLanesImage = ({ customSwimLaneIdStr, stageIdStr, imageExtension, imageBody64, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_SWIMLANES_IMAGE", endPoint: "uploadCustomImage",
		data: {
			customSwimLaneIdStr: customSwimLaneIdStr,
			stageIdStr: stageIdStr,
			imageExtension: imageExtension,
			imageBody64: imageBody64,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const deleteSwimLaneImage = ({ customSwimLaneIdStr, stageIdStr, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "DELETE_SWIMLANES_IMAGE", endPoint: "deleteCustomImage",
		data: {
			customSwimLaneIdStr: customSwimLaneIdStr,
			stageIdStr: stageIdStr,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const OrderListItemSwimlanes = ({ newState, source, destination, swimlaneId }) => ({
	type: "ORDER_LIST_ITEM_ORDER",
	payload: {
		newState: newState,
		source: source,
		destination: destination,
		swimlaneId: swimlaneId,
	}
});

export const updateListItemOrder = ({ newItemsOrder, customSwimLaneIdStr, stageIdStr, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "ORDER_LIST_ITEM_DB", endPoint: "updateCustomListItemsOrder",
		data: {
			newItemsOrder: newItemsOrder,
			customSwimLaneIdStr: customSwimLaneIdStr,
			stageIdStr: stageIdStr,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});

export const updateLibraryItemOrder = ({ newItemsOrder, customSwimLaneIdStr, stageIdStr, cjmIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "ORDER_LIBRARY_ITEM_DB", endPoint: "updateGlobalSwimLaneItemsOrder",
		data: {
			nnewItemsOrder: newItemsOrder,
			customSwimLaneIdStr: customSwimLaneIdStr,
			stageIdStr: stageIdStr,
			cjmIdStr: cjmIdStr,
		},
	}),
});

export const deleteListItems = ({ customSwimLaneItemIdStr, customSwimLaneIdStr, stageIdStr, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "DELETE_LIST_ITEMS", endPoint: "deleteCustomListItem",
		data: {
			customSwimLaneItemIdStr: customSwimLaneItemIdStr,
			customSwimLaneIdStr: customSwimLaneIdStr,
			stageIdStr: stageIdStr,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
	displayToast: "DELETE"
});
export const addMultiSwimlanes = ({ swimlanes, cjmId, order, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "ADD_MULTI_SWIMLANES", endPoint: "addMultiSwimLane",
		data: {
			swimlanes,
			cjmId,
			order,
			personaId: personaIdStr,
		},
		params: {
			personaIdStr: personaIdStr,
		},
	}),
	displayToast: "UPDATE",
	toastParams: { success: t("NEW_LANE_READY") }
});
export const updateGlobalSwimLaneOrder = ({ cjmId, stageId, prevCustomSwimLaneId, newCustomSwimLaneId, customSwimLaneItemId, ...props }) => genericService({
	...props, action: createPostActionSwim({
		type: "UPDATE_GLOBAL_SWIMLANE_ORDER", endPoint: "updateGlobalSwimLaneOrder",
		data: {
			cjmId,
			stageId,
			prevCustomSwimLaneId,
			newCustomSwimLaneId,
			customSwimLaneItemId,
		},

	}),
});


/// *** persona Interaction *** //createPostActionPersInt

export const updateInteraction = ({ interaction, newChannelId, cjmIdStr, personaIdStr, previousChannelIdStr = EMPTY_GUID, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "UPDATE_PERSONA_INTERACTION", endPoint: "updatePersonaInteraction",
		data: {
			personaInteractionId: interaction?.id,
			newSentimentLevel: interaction?.emotionLevel + 1 === 0 ? -1 : interaction?.emotionLevel + 1,
			newChannelId: newChannelId || interaction?.touchPointId,
			previousChannelId: previousChannelIdStr,
			newActions: encode(interaction?.actions?.truncate(19999)),
			newJsonProperty: interaction?.jsonProperty,
			stageId: interaction?.stageId,
			ownerId: interaction?.ownerId,
			cjmId: cjmIdStr,
			personaId: personaIdStr,
		},
		params: { ...interaction, touchPointId: previousChannelIdStr !== EMPTY_GUID ? newChannelId : interaction?.touchPointId },

	})
});
export const addInteraction = ({ interaction, cjmIdStr, personaIdStr, lastElement, toastId, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "CREATE_PERSONA_INTERACTION", endPoint: "createPersonaInteraction",
		data: {
			sentimentLevel: interaction.emotionLevel === -1 ? -1 : interaction.emotionLevel !== "" ? interaction.emotionLevel + 1 : -1,
			channelId: interaction.touchPointId,
			actions: encode(interaction.actions.truncate(19999)) || "",
			pros: encode(interaction.pros.truncate(19999)) || "",
			cons: encode(interaction.cons.truncate(19999)) || "",
			insights: encode(interaction.insights.truncate(19999)) || "",
			stageId: interaction.stageId,
			ownerId: interaction.ownerId,
			cjmId: cjmIdStr,
			personaId: personaIdStr,
			order: interaction.order,
		},
		params: { ...interaction, emotionLevel: interaction.emotionLevel !== "" ? interaction.emotionLevel : -1 },
		toastId,
		lastElement,

	})
});
export const deleteInteraction = ({ interaction, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "DELETE_PERSONA_INTERACTION", endPoint: "deletePersonaInteraction",
		data: {
			personaInteractionId: interaction?.id,
			channelId: interaction?.touchPointId,
			stageId: interaction?.stageId,
			ownerId: interaction?.ownerId,
			cjmId: cjmIdStr,
			personaId: personaIdStr,
		},
		params: interaction,
	})
});

export const forcePersonaInteractionRoute = ({ interaction, newValue, isMain, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "FORCE_PERSONA_INTERACTION_ROUTE", endPoint: "forcePersonaInteractionRoute",
		data: {
			personaInteractionId: interaction.id,
			channelId: interaction.touchPointId,
			stageId: interaction.stageId,
			ownerId: interaction.ownerId,
			newValue,
			isMain,
			cjmId: cjmIdStr,
			personaId: personaIdStr,
		}
	})
});
export const createMultipleInteractions = ({ personaInteractionsIds, personaIdStr, cjmIdStr, stageId, ownerId, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "CREATE_MULTI_PERSONA_INTERACTION", endPoint: "createPersonaInteractions",
		data: {
			newPersonaInteractionIds: personaInteractionsIds,
			personaId: personaIdStr,
			cjmId: cjmIdStr,
			stageId: stageId,
			ownerId,
			sentimentLevel: -1,
		}
	})
});
export const linkInteraction = ({ recEntityName, recIds = [], itemId = EMPTY_GUID, prefixRelationships = "", stageId, attribute, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "LINK_INTERACTION_RECORDS", endPoint: "linkRecords",
		data: {
			recEntityName,
			recIds,
			personaInteractionId: itemId,
			prefixRelationships,
		},
		params: {
			stageId,
			attribute,
		},
	})
});
export const unlinkInteraction = ({ recEntityName, recIds = [], itemId = EMPTY_GUID, prefixRelationships = "", stageId, attribute, ...props }) => genericService({
	...props, action: createPostActionPersInt({
		type: "UNLINK_INTERACTION_RECORDS", endPoint: "unLinkRecords",
		data: {
			recEntityName,
			recIds,
			personaInteractionId: itemId,
			prefixRelationships,
		},
		params: {
			stageId,
			attribute,
		},
	})
});

//// **** timeline **** //createPostActionTimeline
const createPostActionTimeline = InitActionCreator({ baseUrl: "api/timeline/", method: "POST" }); // api actions type post

export const upsertTimeline = ({ timeLine, ...props }) => genericService({
	...props, action: createPostActionTimeline({
		type: "UPSERT_TIMELINE", endPoint: "upsert",
		data: {
			timeLine
		}
	})
});
export const deleteTimeline = ({ id, stageId, ...props }) => genericService({
	...props, action: createPostActionTimeline({
		type: "DELETE_TIMELINE", endPoint: "delete",
		data: {
			id
		},
		params: {
			stageId,
		},
	})
});

/// **** Icons ****** //
export const updateIconsSwimlane = (key, stageId, value) => ({
	type: "UPDATE_SWIMLANES_ICONS",
	payload: { key: key, stageId: stageId, newIcon: value },
});

export const deleteIconsSwimlane = (key, stageId, value) => ({
	type: "DELETE_SWIMLANES_ICONS",
	payload: { key: key, stageId: stageId, iconToDelete: value },
})

export const linkIconSwimlane = ({ iconIdStr, objectIdStr, IconType, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: "LINK_SWIMLANES_ICONS", endPoint: "linkIcon",
		data: {
			iconIdStr: String(iconIdStr).padStart(5, "0"),
			objectIdStr: objectIdStr,
			IconType: IconType,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});
export const unLinkIconSwimlane = ({ iconIdStr, objectIdStr, IconType, cjmIdStr, personaIdStr, ...props }) => genericService({
	...props, action: createPostActionIcon({
		type: "UNLINK_SWIMLANES_ICONS", endPoint: "unLinkIcon",
		data: {
			iconIdStr: String(iconIdStr).padStart(5, "0"),
			objectIdStr: objectIdStr,
			IconType: IconType,
			cjmIdStr: cjmIdStr,
			personaIdStr: personaIdStr,
		},
	}),
});
/***********CUSTOMER FEEBACKS ************/
const createPostActionFeedbacks = InitActionCreator({ baseUrl: "api/customerFeedback/", method: "POST" });
const createGetActionFeedbacks = InitActionCreator({ baseUrl: "api/customerFeedback/", method: "GET" }); // api actions type post

export const upsertCustomerFeedback = ({ customerFeedback, cjmId, ...props }) => genericService({
	...props, action: createPostActionFeedbacks({
		type: "UPSERT_CUSTOMER_FEEDBACK", endPoint: "upsert",
		data: {
			customerFeedback,
			cjmId,
		}
	})
});
export const deleteCustomerFeedback = ({ customerFeedbackId, personaId, cjmId, stageId, ...props }) => genericService({
	...props, action: createPostActionFeedbacks({
		type: "DELETE_CUSTOMER_FEEDBACK", endPoint: "delete",
		data: {
			customerFeedbackId,
			personaId,
			cjmId,
		},
		params: {
			stageId,
		},
	})
});
export const uploadExcelVOC = ({ customerFeedback, fileBody64, cjmId, ...props }) => genericService({
	...props, action: createPostActionFeedbacks({
		type: "UPSERT_CUSTOMER_FEEDBACK", endPoint: "uploadExcel",
		data: {
			customerFeedback,
			fileBody64,
			cjmId
		}
	})
});

export const getExcelTemplateExport = () => createGetActionFeedbacks({ type: "GET_EXCEL_TEMPLATE_EXPORT", endPoint: "getExcelTemplateExport" ,responseType:"blob"});

export const upsertCustomerFeedbackSummary = ({ customerFeedbackData, customerFeedbackId, cjmId, stageId, personaId, touchPointId, cxMetricsId, ...props }) => genericService({
	...props, action: createPostActionFeedbacks({
		type: "UPSERT_CUSTOMER_FEEDBACK_DATA", endPoint: "upsertCustomerFeedBackSummary",
		data: {
			customerFeedbackData: { ...customerFeedbackData, sourcesOfData: encode(customerFeedbackData?.sourcesOfData), description: encode(customerFeedbackData?.description) },
			customerFeedbackId,
			cjmId,
			stageId,
			personaId,
			touchPointId,
			cxMetricsId,
		},
	})
});
export const deleteCustomerFeedbackSummary = ({ customerFeedbackDataId, cjmId, stageId, personaId, touchPointId, customerFeedbackId, ...props }) => genericService({
	...props, action: createPostActionFeedbacks({
		type: "DELETE_CUSTOMER_FEEDBACK_DATA", endPoint: "deleteCustomerFeedBackSummary",
		data: {
			customerFeedbackDataId,
			cjmId,
			stageId,
			personaId,
			touchPointId,
		},
		params: {
			customerFeedbackId,
		},
	})
});





// SERVICE ****************************************************************
export const loadChart = async (props) => genericService({ ...props, action: getMapChartData });
export const getCurrentMap = async (props) => genericService({ ...props, action: getPersonaMapDetails });


export const getExcelExportListService = (ids, columnView) => {
	const promise = async () => {
		const { data, error } = await store.dispatch(getExcelExportListCjm(ids, columnView));
		var fileDownload = require("js-file-download");
		fileDownload(data, "Journey Maps List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};
export const getLinkedCjmsExcelExportService = (ids, entityName) => {
	const promise = async () => {
		const { data, error } = await store.dispatch(getLinkedCjmsExcelExport(entityName, ids));
		var fileDownload = require("js-file-download");
		fileDownload(data, "Journey Maps List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};
export const getExcelTemplateExportService = () => {
	const promise = async () => {
		const { data, error } = await store.dispatch(getExcelTemplateExport());
		var fileDownload = require("js-file-download");
		fileDownload(data, "Feedback Data Import Template", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};
export const getStagesPhases = (props) => createGetAction({
	type: "GET_STAGES_PHASES", endPoint: constructUrl("getStagesPhases", {
		cjmId: props?.cjmIdStr,

	}),
	meta: { joinRequest: 'GET_STAGES_PHASES' },
	params: props?.params || {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getStagesPhasesFn = async (props) => genericService({ ...props, action: getStagesPhases });
export const getCjmHeaderFields = (props) => createGetAction({
	type: "GET_CJM_HEADER_FIELDS", endPoint: constructUrl("getCjmHeaderFields", {
		cjmId: props?.cjmIdStr,

	}),
	meta: { joinRequest: 'GET_CJM_HEADER_FIELDS' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmHeaderFieldsFn = async (props) => genericService({ ...props, action: getCjmHeaderFields });
export const getCjmSwimLaneDetails = (props) => createGetAction({
	type: "GET_CJM_SWIM_LANE_DETAILS", endPoint: constructUrl("getCjmSwimLaneDetails", {
		cjmId: props?.cjmIdStr,
		personaId: props?.personaIdStr

	}),
	meta: { joinRequest: 'GET_CJM_SWIM_LANE_DETAILS' },
	params: {
		cjmIdStr: props?.cjmIdStr,
		collab : props?.collab || false
	}
});
export const getCjmSwimLaneDetailsFn = async (props) => genericService({ ...props, action: getCjmSwimLaneDetails  });

export const getCjmSwimLane = (props) => createGetAction({
	type: "GET_CJM_SWIM_LANE", endPoint: constructUrl("getCjmSwimLanes", {
		cjmId: props?.cjmIdStr,
		personaId: props?.personaIdStr
	}),
	meta: { joinRequest: 'GET_CJM_SWIM_LANE' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmSwimLaneFn = async (props) => genericService({ ...props, action: getCjmSwimLane });

export const getCjmPersonasData = (props) => createGetAction({
	type: "GET_PERSONA_DATA", endPoint: constructUrl("getCjmPersonasData", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_PERSONA_DATA' },
	params: {
		cjmIdStr: props?.cjmIdStr,

	}
});
export const getCjmPersonasDataFn = async (props) => genericService({ ...props, action: getCjmPersonasData });

export const getCjmData = (props) => createGetAction({
	type: "GET_CJM_DATA", endPoint: constructUrl("getCjmData", {
		cjmId: props?.cjmIdStr,
		personaId : props?.onlyPersona ?  props?.personaIdStr : EMPTY_GUID

	}),
	meta: { joinRequest: 'GET_CJM_DATA' },
	params: {
		cjmIdStr: props?.cjmIdStr,
		personaId: props?.personaIdStr
	}
});
export const getCjmDataFn = async (props) => genericService({ ...props, action: getCjmData });

export const getCjmChannels = (props) => createGetAction({
	type: "GET_CHANNELS", endPoint: constructUrl("getCjmChannels", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CHANNELS' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmChannelsFn = async (props) => genericService({ ...props, action: getCjmChannels });
export const getCjmContactData = (props) => createGetAction({
	type: "GET_CONTACT_DATA", endPoint: constructUrl("getCjmContactData", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CONTACT_DATA' },
	params: {
		cjmIdStr: props?.cjmIdStr,
		personaIdStr : props?.personaIdStr
	}
});
export const getCjmContactDataFn = async (props) => genericService({ ...props, action: getCjmContactData });
export const getCjmStagesCustomerExperienceData = (props) => createGetAction({
	type: "GET_CJM_STAGES_CUSTOME_REXPERIENCE_DATA", endPoint: constructUrl("getCjmStagesCustomerExperienceData", {
		cjmId: props?.cjmIdStr,
		personaId: props?.personaIdStr

	}),
	meta: { joinRequest: 'GET_CJM_STAGES_CUSTOME_REXPERIENCE_DATA' },
	params: {
		cjmIdStr: props?.cjmIdStr,

	}
});
export const getCjmStagesCustomerExperienceDataFn = async (props) => genericService({ ...props, action: getCjmStagesCustomerExperienceData });
export const getCjmCustomerFeedback = (props) => createGetAction({
	type: "GET_CJM_CUSTOMER_FEEDBACK", endPoint: constructUrl("getCjmCustomerFeedback", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CJM_CUSTOMER_FEEDBACK' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmCustomerFeedbackFn = async (props) => genericService({ ...props, action: getCjmCustomerFeedback });


export const getCjmTimeLines = (props) => createGetAction({
	type: "GET_CJM_TIME_LINES", endPoint: constructUrl("getCjmTimeLines", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CJM_TIME_LINES' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmTimeLinesFn = async (props) => genericService({ ...props, action: getCjmTimeLines });


export const getCjmCxGoals = (props) => createGetAction({
	type: "GET_CJM_CXGOALS", endPoint: constructUrl("getCjmCxGoals", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CJM_CXGOALS' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmCxGoalsFn = async (props) => genericService({ ...props, action: getCjmCxGoals });


export const getCjmSolutions = (props) => createGetAction({
	type: "GET_CJM_SOLUTIONS", endPoint: constructUrl("getCjmSolutions", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CJM_SOLUTIONS' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmSolutionsFn = async (props) => genericService({ ...props, action: getCjmSolutions });


export const getCjmOpportunities = (props) => createGetAction({
	type: "GET_CJM_OPPORTUNITIES", endPoint: constructUrl("getCjmOpportunities", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CJM_OPPORTUNITIES' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmOpportunitiesFn = async (props) => genericService({ ...props, action: getCjmOpportunities });


export const getCjmCxActions = (props) => createGetAction({
	type: "GET_CJM_CXACTIONS", endPoint: constructUrl("getCjmCxActions", {
		cjmId: props?.cjmIdStr,
	}),
	meta: { joinRequest: 'GET_CJM_CXACTIONS' },
	params: {
		cjmIdStr: props?.cjmIdStr,
	}
});
export const getCjmCxActionsFn = async (props) => genericService({ ...props, action: getCjmCxActions });
export const getAllInsightOfMap = ({ cjmId }) => {
	return {
		type: "GET_ALL_INSIGHTS_MAP",
		request: {
			url: `api/map/getAllMapInsights?cjmId=${cjmId}`,
			method: "get",
		},
	};
};
export const getAllInsightOfMapFu = async (props) => genericService({ ...props, action: getAllInsightOfMap });

export const getPersonaInteractionByStage = ({ cjmId, personaId, stageId }) => {
	return {
		type: "GET_PERSONA_INTERACTION_BY_STAGE",
		request: {
			url: `api/map/getPersonaInteractionByStage?cjmId=${cjmId}&personaId=${personaId}&stageId=${stageId}`,
			method: "get",
		},
		params: { cjmId, personaId, stageId }
	};
};
export const getPersonaInteractionByStageFn = async (props) => genericService({ ...props, action: getPersonaInteractionByStage });

export const setIsExporting = (isExporting) => ({
	type: "SET_IS_EXPORTING",
	payload: { isExporting: isExporting },
});

export const getAllCjmData = (props) => createGetAction({
	type: "GET_ALL_CJM_DATA", endPoint: constructUrl("getAllCjmData", {
		cjmId: props?.cjmId,
	})
});
export const getAllCjmDataFn = async (props) => genericService({ ...props, action: getAllCjmData });

export const updateStageGlobalInsights = (newValue, stageIdStr, cjmIdStr) => {
	return {
		type: "UPDATE_STAGE_GLOBAL_INSIGHTS",
		request: {
			url: "api/stage/updateStageGlobalInsights",
			method: "post",
			data: {
				stageIdStr: stageIdStr,
				newValue: encode(newValue),
				cjmIdStr: cjmIdStr,
			},
		},
	};
};