import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { GenericButton } from "shared/ui-basics/index";
import React from "react";
export function IframeModal({ isOpen, onClose, title, url, add, objectId, img }) {

	const { t } = useTranslation();

	return (
		<Modal fade={false} isOpen={isOpen} size="xl" centered={true} toggle={onClose}>
			<ModalHeader className="pt-1 pb-1" toggle={onClose}>
				<span className="small-title">{t(title)}</span>
			</ModalHeader>
			<ModalBody className='overflow-scroll'>
				<div>
					{img ?<img src={img} alt=''/> : <Frame src={url}></Frame>}
				</div>
			</ModalBody>
            {add && <ModalFooter className="d-flex justify-content-center">
                <GenericButton
                    variant="primary-outline"
                    onClick={() => {
                        add(objectId);
                        onClose();
                    }}>
                    {t("USE")}
                </GenericButton>
            </ModalFooter>}
		</Modal>
	);
}
const Frame = styled.iframe`
	width: 100%;
	height: 1000px;
	overflow: auto;
	zoom: 0.7;
	border: 1px solid #c4c4c4;
`;
