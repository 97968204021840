import { EMPTY_GUID } from "@utils/helpers";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";
import { AvatarGroup } from "primereact/avatargroup";

export default function AuthorizedUsers(item ,wrap) {
	const authorizedUsersBased = item?.authorizedUsers || item?.cjmUsersData || [];
	const authorizedUsers = authorizedUsersBased?.filter((user) => user.roleId !== EMPTY_GUID );
	return (
		<AvatarGroup className={`overflow-hidden  px-2 ${wrap ? 'flex-wrap' :''}`}>
			{authorizedUsers?.map((owner) => (
				<InitialsBubble key={owner?.id} fullName={owner.fullName} size='s' showRoleIcon={true}  	role={owner?.roleName?.toLowerCase()}
/>
			))}
		</AvatarGroup>
	);
}
