import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import EmptyData from "features/analytics/Components/EmptyData";
import { stringToColor } from "@utils/helpers";
import { useSelector } from "react-redux";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomLegend from "../../../Components/CustomLegend";
import CustomTooltipById from "../../../Components/Tooltips/CustomTooltipById";
import { TickStyle } from "features/analytics/Style";

function CustomLineChart({ dataSelector, entityAttribute, emptyDataText }) {
	const allItems = useSelector((state) => state.analytics?.currentData[entityAttribute]);
	const data = useSelector(dataSelector);

	const allUniquesIds = new Set();
	data.forEach((obj) => {
		Object.keys(obj).forEach((id) => {
			if (id !== "date") allUniquesIds.add(id);
		});
	});

	const RenderLegend = (props) => {
		const { payload } = props;
		const legendData = [];
		payload.map((entry) => {
			const entity = allItems.find((item) => item.id === entry.dataKey);
			legendData.push({ color: entry.color, name: entity.name });
		});

		return <CustomLegend data={legendData} />;
	};

	const RenderTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) return <CustomTooltipById payload={payload} allItems={allItems} />;

		return null;
	};

	// ticksCount: number of numbers displayed on Y axis
	return (
		<>
			{data.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<LineChart
						data={data}
						margin={{
							left: -30,
							top: 10,
						}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }} tick={{ ...TickStyle }} />
						<YAxis domain={[1, 5]} tickCount={5} axisLine={false} tick={{ ...TickStyle }} />
						<Tooltip content={<RenderTooltip />} isAnimationActive={false} />
						<Legend
							layout="vertical"
							align="right"
							verticalAlign="top"
							wrapperStyle={{
								paddingLeft: "30px",
							}}
							content={<RenderLegend />}
						/>
						{Array.from(allUniquesIds)?.map((id, index) => (
							<Line key={id} type="monotone" dataKey={id} stroke={stringToColor(id, index)} strokeWidth="2" activeDot={{ r: 8 }} />
						))}
					</LineChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text={emptyDataText} icon="CHART-AREA" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

CustomLineChart.displayName = "CustomLineChart";
export default CustomLineChart;
