import { OverlayPanel } from "primereact/overlaypanel";
import styled from "styled-components/macro";

export const ChartDisplayContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem !important;
`;

export const Filter = styled.div`
	width: 120px;
`;

export const CustomOverlayPanel = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0px;
	}
`;
