import WordCloud from "react-d3-cloud";
import styled from "styled-components";
import EmptyData from "./EmptyData";
import { useSelector } from "react-redux";

function CustomWordCloud({dataSelector, colorPalette, width, height, emptyDataText }) {
    // min value 20 max value 200
	const data = useSelector(dataSelector);
    

	const getColor = (value) => {
		if (value >= 140) {
			return colorPalette[0];
		} else if (value >= 80) {
			return colorPalette[1];
		}
		return colorPalette[2];
	};

	const getFontWeight = (value) => {
		if (value >= 140) {
			return 900;
		} else if (value >= 80) {
			return 700;
		}
		return 500;
	};

	const fill = (d) => getColor(d.value);
	const fontWeight = (d) => getFontWeight(d.value);

	return (
		<>
			{data?.length > 0 ? (
				<WordCloud width={width} height={height} data={data} font="Inter" fontWeight={fontWeight} fill={fill} padding={0} spiral="archimedean" rotate={0} />
			) : (
				<EmptyDataContainer>
					<EmptyData text={emptyDataText} icon="CLOUD-WORD" />
				</EmptyDataContainer>
			)}
		</>
	);
}

CustomWordCloud.displayName = "CustomWordCloud";
export default CustomWordCloud;

const EmptyDataContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 400px;
	width: 100%;
`;
