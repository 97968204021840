import { createSlice } from "@reduxjs/toolkit"
import { store } from ".."



export const Page = createSlice({
    name: 'Page',
    initialState: {
        headers: {
            size:{width:0,height:0},
            showHeaderDetails:false
        },
        sidebar: {
            isOpen : true,
        },
        main: [],
        allowSectionsOverFlow: false, // This is used to allow the sections to overflow the page
        selected_bulk : [],
        bulk_type : '',
    },
    reducers: {
        loadDataHeaders: (state, action) => {
            state.headers = action.payload
        },
        loadMain:(state, action) => {
            state.main = action.payload
        },
        loadDataExportPdfHeaders: (state, action) => {
            state.headers.url = action.payload
        },
        loadSizeExportPdfHeaders: (state, action) => {
            state.headers.size = action.payload
        },
        setAllowSectionsOverFlow: (state, action) => {
            state.allowSectionsOverFlow = action.payload
        },
        setShowHeaderDetails: (state, action) => {
            state.headers.showHeaderDetails = action.payload
        },
        setNavigationSidebar: (state, action) => {
            state.sidebar.isOpen = action.payload
        },
        setChangeTouchpoint : (state, action) => {
            state.changeTouchpoint = action.payload
        },
        setSelectBulk : (state, action) => {
            state.selected_bulk = action.payload
        },
        setBulkType : (state, action) => {
            state.bulk_type = action.payload
        }


    }
})

// Action Creators
export const loadDataHeader = (data) => store.dispatch(Page.actions.loadDataHeaders(data))
export const loadMainData= (data) => store.dispatch(Page.actions.loadMain(data))
export const loadDataExportPdfHeaders= (data) => store.dispatch(Page.actions.loadDataExportPdfHeaders(data))
export const loadSizeExportPdfHeaders= (data) => store.dispatch(Page.actions.loadSizeExportPdfHeaders(data))
export const setAllowSectionsOverFlow= (data) => store.dispatch(Page.actions.setAllowSectionsOverFlow(data))
export const setShowHeaderDetails= (data) => store.dispatch(Page.actions.setShowHeaderDetails(data))
export const setNavigationSidebar = (data) => store.dispatch(Page.actions.setNavigationSidebar(data))
export const setChangeTouchpoint = (data) => store.dispatch(Page.actions.setChangeTouchpoint(data))
export const setSelectBulk = (data) => store.dispatch(Page.actions.setSelectBulk(data))
export const setBulkType = (data) => store.dispatch(Page.actions.setBulkType(data))
