import React, { useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import styled from "styled-components/macro";
import { Input } from "reactstrap";
import { Colors, IconDispatcher } from "shared/ui-basics/index";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ManageGenericItems = ({ title, allItems, createItem, updateItem, deleteItem, maxHeight, placeholder = "ADD_ROLE" }) => {
	const { t } = useTranslation();

	const [items, setItems] = useState(JSON.parse(JSON.stringify(allItems)));
	const [newItem, setNewItem] = useState("");

	const handleTextChange = (e, index) => {
		setItems((prev) => prev.map((item, idx) => (idx === index ? { ...item, name: e.target.value } : item)));
	};

	const handleUpdate = (e, index) => {
		const newValue = e?.target?.value || items[index]?.name;
		if (newValue !== allItems[index]?.name) {
			if (newValue.length > 90) {
				toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
				setItems((prev) => prev.map((item, idx) => (idx === index ? { ...item, name: newValue.truncate(90) } : item)));
			}
			updateItem(items[index]?.id, newValue.truncate(90));
		}
	};
	const handleCreate = (e) => {
		const newValue = e?.target?.value || newItem;
		if (newValue === "") return;
		if (e.target.value.length > 90) {
			toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
		}
		createItem(newValue.truncate(90), (newItem) => setItems([...items, newItem]));
		setNewItem("");

	};

	const handleEnter = (e, index, action) => {
		if (e.key === "Enter") {
			if (action === "create") {
				handleCreate(e);
			} else if (action === "update") {
				handleUpdate(e, index);
			}
		}
	};
	return (
		<ManageItemsContainer className="p-2">
			<Title className="mb-3">{t(title)}</Title>
			<ItemsContainer maxHeight={maxHeight}>
				{items?.map((item, index) => (
					<ItemContainer key={index} className="mb-2">
						<CustomInput value={item?.name} onChange={(e) => handleTextChange(e, index)} onBlur={(e) => handleUpdate(e, index)} onKeyDown={(e) => handleEnter(e, index, "update")} />
						<Delete deleteItem={deleteItem} toDelete={item} items={items} setItems={setItems} />
					</ItemContainer>
				))}
			</ItemsContainer>
			<AddItem className="mt-2">
				<CustomInput value={newItem} placeholder={t(placeholder)} onChange={(e) => setNewItem(e.target.value)} onBlur={handleCreate} onKeyDown={(e) => handleEnter(e, null, "create")} />
			</AddItem>
		</ManageItemsContainer>
	);
};

export default ManageGenericItems;

function Delete({ deleteItem, toDelete, items, setItems }) {
	const { t } = useTranslation();
	const openModal = () => {
		Swal.fire({
			text: t("DELETE_ITEM_PERMANENT"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#F96209",
			cancelButtonColor: "#EFEFEF",
			confirmButtonText: t("ASPX_13"),
		}).then((result) => {
			if (result.isConfirmed) {
				setItems(items.filter((item) => item.id !== toDelete.id));
				deleteItem(toDelete.id);
			}
		});
	};

	return (
		<div className="ps-2 pe-4" onClick={openModal}>
			{IconDispatcher("X")}
		</div>
	);
}



const ManageItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
`;

const CustomInput = styled(Input)`
	width: ${(props) => (props.width ? props.width : "100%")}px;
	resize: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.form-control:focus {
		background-color: transparent;
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		color: #333333;
	}

	&.form-control:disabled,
	.form-control[readonly] {
		background-color: #e5e4e5;
		color: #333333;
	}
`;

const ItemsContainer = styled.div`
	max-height: ${(props) => props.maxHeight}px;
	overflow: scroll;
`;

const ItemContainer = styled.div`
	display: flex;
	align-items: center;

	:hover {
		cursor: pointer;
	}
	input[type="checkbox"] {
		accent-color: #333333;
		width: 15px !important;
		height: 15px !important;
		:hover {
			cursor: pointer;
		}
	}
`;

const AddItem = styled.div`
	margin-right: 60px;
	input[type="text"] {
		height: 30px;
		font-weight: 400;
		border: 1px solid #c4c4c4;
		border-radius: 4px;
		font-size: 16px;
		line-height: 19px;
		color: #c4c4c4;
	}
`;
