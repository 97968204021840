import styled, { css } from "styled-components/macro";

export const RemoveButton = styled.div`
	visibility: hidden;
`;

export const Badge = styled.div`
	display: flex;
	align-items: center;
	background: #ffffff;
	width: 100%;
	cursor: pointer;
	height: 49px;
	:hover ${RemoveButton} {
		visibility: visible;
	}
`;

export const BadgeWrapper = styled.div`
	${(props) => props.index < props.length -1 ? 'border-bottom: 1px solid #c4c4c4;' :""};
	background: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	height: 49px;

	width: 100%;
	:hover {
        //box-shadow: 0px 0px 5px #00000029;
    }
`;

export const PriorityTypes = styled.div`
	min-width: 20%;
	font-size: 16px;
	align-items: center;
	text-transform: capitalize;
	align-self: center;
	display: flex;
	& #point {
		border-radius: 50%;
		height: 16px;
		width: 16px;
		align-self: center;
		margin-right: 0.5rem;
		border: 1px solid #e6e6e6;
		background-color: ${(props) => props.colorcode};
	}
`;

export const MaxWidth = styled.div`
	max-width: ${(props) => props?.width ?? 90}%;
	font-size: 14px;

	${(props) =>
		props.oppLabel === "true" &&
		css`
			color: #605f60;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
		`}
`;
