import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { Description, Icon, LibraryName, NumberOfRecords } from "../../columns-components/ReusableListComponents";


export const GetLibraryColumns = (props) => {
	return {
		icon: {
			field: "icon",
			header: "",
			style: checkStyle(undefined, "icon", "s", "50px"),
			body: Icon,
		},
		name: {
			field: "name",
			header: t("NAME"),
			sortable: true,
			style: checkStyle(undefined, "name", "xxl"),
			body: LibraryName,
		},
		numberOfRecords: {
			field: "nRecords",
			header: t("NUMBER_OF_RECORDS"),
			sortable: true,
			style: checkStyle(undefined, "name", "l"),
			body: NumberOfRecords,
		},
		description: {
			field: "description",
			header: t("ASPX_9"),
			style: checkStyle(undefined, "name", "xxxl"),
			body: (data) =>  Description(data,true),
		},
	};
};
