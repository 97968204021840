import { createOpportunity, updateSolution } from "@redux/index";
import { EMPTY_GUID, createDefaultOpp, encode } from "@utils/helpers";
import { createProject } from "features/projects/reducer/ProjectsActions";
import { memo, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomEditor } from "shared/components/CustomEditor";
import { OpportunityItemCard } from "shared/components/cards/cx-cards/OpportunityCard";
import { ProjectItemCard } from "shared/components/cards/cx-cards/ProjectCard";
import { GenericButton, Input } from "shared/ui-basics/index";
import styled, { css } from "styled-components/macro";
import DisplayStatus from "../../../shared/cx-components/DisplayStatus";
import Group from "../../../shared/cx-components/Group";
import MapAndStagePicker from "../../../shared/cx-components/MapAndStagePicker";
import OwnerPickList from "../../../shared/cx-components/OwnerPickList";
import SearchPickList from "../../../shared/cx-components/SearchPickList";
import * as Style from "../../../shared/cx-components/style";
import {useModal} from "use-modal-hook";
import { CxComponents } from "features/journey-map/modals/CxComponents";
import { Component } from "react";
import { OpportunityPage } from "features";

const SolutionGeneral = ({ solution, setSolution, isEditable, handleChange, handleBlur, query, setQuery ,onClose}) => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const refProjects = useRef(null);

	const { search ,pathname} = useLocation();
	const history = useHistory();
	const { origin } = search.getCxProps(query);

	const userInfo = useSelector((state) => state.auth.userInfo);
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allOpportunities = useSelector((state) => state.opportunity.all_Opportunities?.getActiveRecords(!showSampleData))
	const all_projects = useSelector((state) => state.projects.all_projects?.getActiveRecords(!showSampleData))
	const [openModal]= useModal(CxComponents)

	const linkedOpportunities = solution.linkedOpportunities?.map((item) => allOpportunities?.find((it) => it?.id === item?.id) || item) || [];
	const linkedProjects = solution.linkedProjects?.map((item) => all_projects?.find((it) => it?.id === item)) || [];

	const updateFunc = (modifiedSolution, modifiedAttribNames, onSuccess) => updateSolution({ modifiedSolution, modifiedAttribNames, onSuccess });
	const openRoute = (id, route) => {
		if (origin) {
			return toast.info(t("WARNING_NOT_ALLOWED_MULTIPLE_OPEN"));

		}
		if(onClose && route === '/edit-opportunity'){
			openModal({
				Component: OpportunityPage,
				id: id,
			})
			onClose()
		}else {
			history.push(`${route}?id=${id}&origin=${encode(solution?.subject)}`);

		}

	};

	const Dates = memo(() => {
		const [localDate, setLocalDate] = useState(solution?.dueDate?.formatDate());

		const handleBlur = () => {
			if(localDate)
			handleChange("dueDate", new Date(localDate).toISOString(), true);
		};

		return (
			<div>
				<Style.Labels className="my-2" > {t("DUE_DATE")}</Style.Labels>
				<Input
					variant="default"
					id="Date"
					name="dueDate"
					className="no-border"
					placeholder="date placeholder"
					disabled={!isEditable}
					value={localDate}
					type="date"
					onChange={(e) => setLocalDate(e.target.value)}
					onBlur={handleBlur}
				/>
			</div>
		);
	});
	Dates.displayName = "Dates_solutionGeneral";

	const Opportunities = () => {
		const handleCreateOpp = ({ globalFilterValue }) => {
			const newOpportunity = createDefaultOpp(userInfo);
			newOpportunity.subject = encode(globalFilterValue);
			newOpportunity.stageId = solution.stageId || EMPTY_GUID;
			newOpportunity.mapId = solution.mapId || EMPTY_GUID;
			newOpportunity.ownerId = solution.ownerId || userInfo?.UserId || EMPTY_GUID;
			createOpportunity({ item: newOpportunity, displayToast: "CREATE", onSuccess: (response) => handleLinkRecord({ recordId: response.id, globalFilterValue }) });
		};

		const handleLinkRecord = ({ recordId, globalFilterValue }) => {
			let item;
			if (globalFilterValue) {
				item = {
					id: recordId,
					subject: globalFilterValue,
				};
			} else {
				item = allOpportunities?.find((obj) => obj.id === recordId);
			}

			const updatedObject = { ...solution, linkedOpportunities: [...linkedOpportunities, item] };
			setSolution(updatedObject);
			updateFunc(updatedObject, ["linkedOpportunities"]);
		};

		const handleUnlinkRecord = (e) => {
			const updatedObject = { ...solution, linkedOpportunities: solution?.linkedOpportunities?.filter((obj) => obj?.id !== e.currentTarget?.id) };

			setSolution(updatedObject);
			updateFunc(updatedObject, ["linkedOpportunities"]);

		};

		return <div className="my-1">
			<Style.Labels className="my-2">{t("OPPORTUNITIES")}</Style.Labels>
			<Style.BorderWrapper>
				<GenericButton variant="black-link" onClick={(e) => ref.current.toggle(e)} disabled={!isEditable} aria-haspopup aria-controls="overlay_panel" icon="SQUARE_PLUS" iconClassName='me-1'>
					{t("ADD_OPPORTUNITY")}
				</GenericButton>
				<Style.RelatedItemsContainer maxHeight="220">
					{linkedOpportunities?.map((opp, i) => (
						<div key={opp?.id || i} >
							<OpportunityItemCard key={opp?.id || i} index={i} entity={opp} unlink={handleUnlinkRecord} openRoute={openRoute} length={linkedOpportunities?.length} isRemovable={isEditable} />
						</div>
					))}
				</Style.RelatedItemsContainer>
			</Style.BorderWrapper>
			<SearchPickList
				ref={ref}
				currentItem={solution}
				allItems={allOpportunities}
				linkedAttribute="linkedOpportunities"
				handleCreate={handleCreateOpp}
				handleLinkRecord={handleLinkRecord}
				noItemsText={t("EMPTY_OPPORTUNITY_LIST")}
				idAttribute="id"
				entityType="cem_cxopportunity"
			/>

		</div>
	}
	const Projects = () => {
		const createNewProject = ({ globalFilterValue }) => {
			createProject({ item: { name: encode(globalFilterValue) }, onSuccess: (response) => linkRecord({ recordId: response.id, globalFilterValue }) });
		};

		const linkRecord = ({ recordId, globalFilterValue }) => {
			let item;
			if (globalFilterValue) {
				item = {
					id: recordId,
					subject: globalFilterValue,
				};
			} else {
				item = all_projects?.find((obj) => obj.id === recordId);
			}
			const updatedObject = { ...solution, linkedProjects: [...solution.linkedProjects, item.id] };
			setSolution(updatedObject);
			updateFunc(updatedObject, ["linkedprojects"]);
		};

		const unlinkRecord = (e) => {
			const updatedObject = { ...solution, linkedProjects: solution?.linkedProjects?.filter((obj) => obj !== e.currentTarget.id) };

			setSolution(updatedObject);
			updateFunc(updatedObject, ["linkedprojects"]);

		};
		return <div className="my-1">
			<Style.Labels className="my-2">{t("PROJECTS")}</Style.Labels>
			<Style.BorderWrapper>
				<GenericButton variant="black-link" onClick={(e) => refProjects.current.toggle(e)} disabled={!isEditable} aria-haspopup aria-controls="overlay_panel" icon="SQUARE_PLUS" iconClassName='me-1'>
					{t("ADD_PROJECT")}
				</GenericButton>
				<Style.RelatedItemsContainer maxHeight="220">
					{linkedProjects?.map((pro, i) => (
						<div key={pro?.id || i} >
							<ProjectItemCard key={pro?.id} index={i} entity={pro} unlink={unlinkRecord} openRoute={openRoute} length={linkedProjects?.length} isRemovable={isEditable} />
						</div>
					))}
				</Style.RelatedItemsContainer>
			</Style.BorderWrapper>
			<SearchPickList
				ref={refProjects}
				currentItem={solution}
				allItems={all_projects}
				linkedAttribute="linkedProjects"
				handleCreate={createNewProject}
				handleLinkRecord={linkRecord}
				// noItemsText={t("EMPTY_PROJECT_LIST")}
				idAttribute="id"
				titleAttribute="name"
				entityType="cem_project"
			/>


		</div>
	}
	return (
		<SolutionGeneralContainer query={query}>
			<Style.Left>
				<div>
					<Style.Labels className="my-2">{t("ASPX_9")}</Style.Labels>
					<CustomEditor
						html={solution?.description || ""}
						sethtml={(value) => handleChange("description", value)}
						onBlur={() => handleBlur("description")}
						disabled={!isEditable}
						variant={"description"}
					/>
				</div>
				<div>
					<Style.Labels className="my-2">
						{t("JOURNEY")} & {t("ASPX_172")}
					</Style.Labels>
					<Style.MapStageContainer>
						<MapAndStagePicker object={solution} attribute="mapId" setObject={setSolution} disable={!isEditable} updateFunction={updateFunc} />
						<MapAndStagePicker object={solution} attribute="stageId" setObject={setSolution} disable={!isEditable} updateFunction={updateFunc} />
					</Style.MapStageContainer>
				</div>
				<Opportunities />
				<Projects />
			</Style.Left>
			<Style.Right className={query && 'mt-3'}>
				<div>
					<Style.Labels className="my-2">{t("ASPX_222")}</Style.Labels>
					<DisplayStatus object={solution} type="status" name="solutions" setObject={setSolution} disable={!isEditable} updateFunction={updateFunc} />
				</div>
				<div>
					<Style.Labels className="my-2">{t("CS_35")}</Style.Labels>
					<OwnerPickList object={solution} setObject={setSolution} disable={!isEditable} updateFunction={updateFunc} />
				</div>
				<div>
					<Style.Labels className="my-2">{t("GROUP")}</Style.Labels>
					<Group object={solution} setObject={setSolution} disable={!isEditable} recordType="cem_cxsolution" disabled={!isEditable} />
				</div>
				<Dates />
			</Style.Right>
		</SolutionGeneralContainer>
	);
};
SolutionGeneral.displayName = "SolutionGeneral";
export default SolutionGeneral;

const SolutionGeneralContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 24px;
	${(props) =>
		props.query &&
		css`

		`}
`;
