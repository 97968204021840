import { MAX_SIZE_TEXT_BOX, encode } from '@utils/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomEditor } from 'shared/components/CustomEditor';
import styled from 'styled-components/macro';
import * as Style from "../../../shared/cx-components/style";
import { updateSolution } from '../reducer/SolutionsActions';


const SolutionImpact = ({ solution, modifiedAttribNames, isEditable }) => {
    const { t } = useTranslation()
    return (
        <Impact>
            <Flex >
                <ImpactCard id={"people"} title={t("IMPACT_PEOPLE")} solution={solution} modifiedAttribNames={modifiedAttribNames} isEditable={isEditable} />
                <ImpactCard id={"process"} title={t("IMPACT_PROCESS")} solution={solution}  modifiedAttribNames={modifiedAttribNames} isEditable={isEditable} />
            </Flex>
            <Flex >
                <ImpactCard id={"technology"} title={t("IMPACT_TECH")} solution={solution}  modifiedAttribNames={modifiedAttribNames} isEditable={isEditable} />
                <ImpactCard id={"value"} title={t("IMPACT_VALUES")} solution={solution} modifiedAttribNames={modifiedAttribNames} isEditable={isEditable} />
            </Flex>
            <Flex last={true}>
                <ImpactCard id={"metric"} title={t("KEY_METRICS")} solution={solution}  modifiedAttribNames={modifiedAttribNames} isEditable={isEditable} />
            </Flex>
        </Impact>
    )
}


const ImpactCard = ({ id, title, solution, modifiedAttribNames, isEditable }) => {

    const [sectionDescr, setSectionDescr] = useState(solution.impacts[id].description)
    const handleEditorChange = (value) => {
        setSectionDescr(value)
        modifiedAttribNames.add("impacts." + id + ".description")
        solution.impacts[id].description = value
    };
    const handleBlur = () => {
        if (modifiedAttribNames.has("impacts." + id + ".description")) {
            const sol = { ...solution }
                sol.impacts[id].description = encode(sol?.impacts?.[id].description.truncate(MAX_SIZE_TEXT_BOX, false));
                updateSolution({modifiedSolution:sol,modifiedAttribNames:["impacts." + id + ".description"]})
                modifiedAttribNames.delete("impacts." + id + ".description")
        }
    }

    return (
        <Impact className='w-100 me-4'>
            <Style.Labels>{title}</Style.Labels>
            <CustomEditor html={sectionDescr} sethtml={handleEditorChange}  onBlur={handleBlur} disabled={!isEditable} style={{ height: '100%' }} variant='description' />
        </Impact>
    )
}

export default SolutionImpact

const Impact = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
   gap:10px;
   `
const Flex = styled.div`
margin-top: 0.5rem;
display: flex;
gap:5px;
${props => props.last && 'max-width:50%;'}
`