import { GenericButton } from "shared/ui-basics/index";
import { Popover } from "react-bootstrap";
import styled, { css } from "styled-components/macro";

export const DivWrapper = styled.div`
	display: inline-flex;
	height: 24px;
	padding: 0px 8px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	align-self: center;
`;

export const X = styled.div`
	margin-left: 0.5rem;
	padding: 0px 0px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	width: 9px;
	height: 9px;
	${(props) => props.size == "lg" && `width:12px; height:12px;`};
	svg {
		path {
			fill: #605f60;
		}
		width: inherit !important;
		height: inherit;
	}
`;

export const Container = styled.div`
align-items: center;
	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 36px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 40px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			font-size: 24px;
		`}
	:hover ${X} {
		opacity: 1;
		cursor: pointer;
		visibility: visible;
	}
	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}
	#editable {
		opacity: 0;
		visibility: hidden;
	}
	:hover #editable {
		opacity: 1;
		visibility: visible;
	}
`;

export const AddButton = styled(GenericButton)`
	border-radius: 4px;
	color: #605f60;
	height: 24px;
	font-size: 14px;
`;

export const CustomLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const ItemsContainer = styled.div`
	padding-bottom: 1rem;
	color: #333;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	padding: 0.5rem;
    height: ${props => props.variant === 'interactions' ? '260px' : '200px'};
    overflow-y: scroll;
	overflow-x: hidden;
`;
export const Footer = styled.div`
border-top: 1px solid #C4C4C4;
padding: 0.5rem;
display: flex;
justify-content: start;
`;
export const Pop = styled(Popover)`
  max-width: 450px !important;
  width: 450px;

  .popover-body {
    overflow: scroll;
  }


	${(props) => props.variant === 'interactions' && css`
		width: 360px !important;
		max-width: 360px !important;

		.popover-body {

		overflow: hidden;

  }

	`}
`;

export const Body = styled(Popover.Body)`
	${(props) => props.variant === 'interactions' && css`
		padding: 1rem;
		/* height: 360px;
		max-height: 360px; */

	`}
`;
export const ManageButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: #F96209;
    :hover {
        color: #FF7900;
        cursor: pointer;
    }
`

