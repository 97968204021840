import React, { useState } from "react";
import StepWizard from "react-step-wizard";
import { useAsync } from "shared/hooks/index";
import { getAllPersonas } from "@redux/index.js";
import PersonaType from "../creation/PersonaType.js";
import PersonaDetails from "../creation/PersonaDetails.js";
import styled from "styled-components/macro";
import { Container } from "reactstrap";
import PersonaPicture from "../creation/PersonaPicture.js";

let custom = {
	enterRight: "your custom css transition classes",
	enterLeft: "your custom css transition classes",
	exitRight: "your custom css transition classes",
	exitLeft: "your custom css transition classes",
	intro: "your custom css transition classes",
};

export default function PersonaCreationWizard() {
	const query = new URLSearchParams(window.location.search.toLowerCase());
	const isTemplate = query.get("istemplate") === "true";
	const [newPersona, setNewPersona] = useState({
		name: "",
		occupation: "",
		age: "",
		gender: "",
		isFuture: false,
		standardImage: "",
		customImage: "upload",
		imageExtension: "",
		isTemplate,
	});

	useAsync({ asyncFn: getAllPersonas });

	return (
		<MainContainer className="mt-5">
			<StepWizard transitions={custom} isLazyMount={true}>
				<PersonaType step={1} newPersona={newPersona} setNewPersona={setNewPersona} />
				<PersonaDetails step={2} newPersona={newPersona} setNewPersona={setNewPersona} />
				<PersonaPicture step={3} newPersona={newPersona} setNewPersona={setNewPersona} />
			</StepWizard>
		</MainContainer>
	);
}

const MainContainer = styled(Container)`
	position: relative;
`;
