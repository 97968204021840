import { createSelector } from "reselect";

export const currentProjectsData = (state) => state.analytics?.currentData?.projects || [];
export const allMaps = (state) => state.analytics?.all_maps || [];
export const allCxGoals = (state) => state.libraries?.cxGoals || [];
export const allMeasures = (state) => state.libraries?.measures || [];
export const allUsers = (state) => state.settings?.authorizedUsers || [];
export const allProjects = (state) => state.analytics?.all_projects || [];
export const currencySymbol = (state) => state?.settings?.organization?.defaultCurrency?.symbol || "$";

export const KpiDataSelector = createSelector([currentProjectsData, allCxGoals, allMeasures], (currentProjectsData, allCxGoals, allMeasures) => {

    const counter = {};
    currentProjectsData?.forEach((projectCxGoal) => {
        projectCxGoal["cxGoalIds"]?.forEach((linkedId) => {
            const cxGoal = allCxGoals?.find((cxGoal) => linkedId === cxGoal?.id);
            const measure = allMeasures?.find(me => me.id === cxGoal?.measureId);
            if (measure && cxGoal?.achieved !== -1) {
                if (!counter[cxGoal?.measureId]) {
                    counter[cxGoal?.measureId] = {
                        name: measure?.name,
                        id: cxGoal?.measureId,
                        countMeasure: 1,
                        sumAchieved: cxGoal?.achieved ?? 0,
                        actual: cxGoal?.actual ?? 0,
                        goal: cxGoal?.goal ?? 0,
                        financialImpact: cxGoal?.financialImpact ?? 0
                    }
                }
                else counter[cxGoal?.measureId] = {
                    ...counter[cxGoal?.measureId],
                    sumAchieved: counter[cxGoal?.measureId].sumAchieved + cxGoal?.achieved ?? 0,
                    countMeasure: ++counter[cxGoal?.measureId].countMeasure,

                    actual: counter[cxGoal?.measureId].actual + cxGoal?.actual ?? 0,
                    goal: counter[cxGoal?.measureId].goal + cxGoal?.goal ?? 0,
                    financialImpact: counter[cxGoal?.measureId].financialImpact + cxGoal?.financialImpact ?? 0
                };
            }

        });
    });

    const avgMeasure = Object.values(counter)?.map((measure) => {
        return {
            ...measure,
            achieved: (measure?.sumAchieved / measure?.countMeasure).toFixed(1),
            notAchieved: (100 - (measure?.sumAchieved / measure?.countMeasure)).toFixed(1)
        }
    })
    return avgMeasure;//.slice(0,10);
});


export const countMeasuresSelector = createSelector([currentProjectsData, allCxGoals, allMeasures], (currentProjectsData, allCxGoals, allMeasures) => {
    const counter = {};
    currentProjectsData?.forEach((projectCxGoal) => {
        projectCxGoal["cxGoalIds"]?.forEach((linkedId) => {
            const cxGoal = allCxGoals?.find((cxGoal) => linkedId === cxGoal?.id);
            const measure = allMeasures?.find(me => me.id === cxGoal?.measureId)
            if (measure) {
                if (!counter[cxGoal?.measureId]) {
                    counter[cxGoal?.measureId] =
                    {
                        name: measure?.name,
                        value: 1
                    };
                }
                else counter[cxGoal?.measureId] = {
                    ...counter[cxGoal?.measureId],
                    value: counter[cxGoal?.measureId].value + 1
                };
            }
        });
    });
    //Sort the list
    const sortedMeasures = Object.values(counter)?.sort((a, b) => b.value - a.value);
    return sortedMeasures.slice(0, 5);

});

export const projectAndKpiSelector = createSelector([currentProjectsData, allCxGoals, allProjects, currencySymbol], (currentProjectsData, allCxGoals, allProjects, currencySymbol) => {
    const projects = {};
    allCxGoals?.forEach((cxGoal) => {
        currentProjectsData?.forEach((projectCxGoal) => {
            if (projectCxGoal?.cxGoalIds?.includes(cxGoal?.id)) {
                const project = allProjects?.find(project => project?.id === projectCxGoal?.id);

                if (!projects[projectCxGoal?.id]) {
                    projects[projectCxGoal?.id] = {
                        name: project?.name,
                        currencySymbol,
                        value: cxGoal?.financialImpact ?? 0
                    };
                }
                else projects[projectCxGoal?.id] = {
                    ...projects[projectCxGoal?.id],
                    value: projects[projectCxGoal?.id].value + cxGoal?.financialImpact ?? 0
                };

            }
        });
    });

    //Sort the list
    const sortedProjects = Object.values(projects)?.filter((item) => item?.value > 0)?.sort((a, b) => b.value - a.value);
    return sortedProjects.slice(0, 5);
});

export const ownerByFinancialImpactSelector = createSelector([currentProjectsData, allCxGoals, allUsers, allProjects,currencySymbol], (currentProjectsData, allCxGoals, allUsers, allProjects,currencySymbol) => {
    const projects = {};

    allCxGoals?.forEach((cxGoal) => {
        currentProjectsData?.forEach((projectCxGoal) => {
            if (projectCxGoal?.cxGoalIds?.includes(cxGoal?.id)) {
                const project = allProjects?.find(project => project?.id === projectCxGoal?.id);
                const nameOwner = allUsers?.find(user => user?.id === project?.ownerId)?.fullName;
                if (!projects[project?.ownerId]) {
                    projects[project?.ownerId] = {
                        name: nameOwner,
                        currencySymbol,
                        value: cxGoal?.financialImpact ?? 0
                    };
                }
                else projects[project?.ownerId] = {
                    ...projects[project?.ownerId],
                    value: projects[project?.ownerId].value + cxGoal?.financialImpact ?? 0
                };

            }
        });
    });
    //Sort the list
    const sortedProjects = Object.values(projects)?.filter((item) => item?.value > 0)?.sort((a, b) => b.value - a.value);
    return sortedProjects;//.slice(0,10);
});