import { EMPTY_GUID, calculatesolutionType, extractTextFromHtml, getEntityAndContext, handleRedirectEntityList } from "@utils/helpers";
import { SolutionType } from "@utils/optionsSets/OptionSets";
import { PermissionManager } from "@utils/permissionManager";
import { EditInsights } from "features/index";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import DisplayStatus from "shared/cx-components/DisplayStatus";
import Priority from "shared/cx-components/Priority";
import { IconDispatcher } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import { CustomRatingNew } from "../../../shared/ui-basics/ui-components/CustomRating";
import { BenefitCard } from "./BenefitCard";
import * as Style from "./Style";

export const Map = (action, params) => {
	const { pathname, search } = useLocation();
	const isInHome = pathname === "/home";
	const desactivateClick = pathname === "/trash" || pathname === "/archive" || !params.allowClick;
	const showMapName = !isInHome;
	const history = useHistory();
	const { cxProgramId } = search.getCxProps();
	const folderId = search.getFolderId();

	const table = useSelector(({ ui }) => ui.table);

	const handleClick = () => {
		const { entityType, context } = getEntityAndContext(params.route);
		const backQuery = cxProgramId !== undefined;
		const route = "/" + params.route;
		handleRedirectEntityList({ entityType, context, route, forceId: action.id, history, table, backQuery, folderId });
	};
	return (
		<>
			{showMapName && (
				<CustomTooltip text={action?.mapName} disabled={!action?.mapName || action?.mapName.length <= 21}>
					<Style.TextInfo desactivateclick={`${desactivateClick}`} onClick={desactivateClick ? () => { } : handleClick} className="me-1">
						{action?.mapName}
					</Style.TextInfo>
				</CustomTooltip>
			)}
		</>
	);
};

export const Owner = (action) => {
	const owner = useSelector((state) => state.settings.authorizedUsers?.find((user) => user.id === action?.ownerId)) || {};
	return (
		<div className="d-flex ">
			<InitialsBubble  fullName={owner.fullName} size='s' />
		</div>
	);
};

export const TablePriority = (action, dataKey, attribute, listEditable, onChangeObject) => {
	const all_priority = useSelector((state) => state.libraries?.priority);
	const priority = all_priority && all_priority[attribute]?.find((pt) => pt.id === action.priorityId);
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const roleName = action?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName ?? "owner";
	const { editRowAllowed } = PermissionManager({ userAccess: roleName });
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);

	const update = (object) => {
		const modifiedOpportunities = [];
		if (selectedIds?.map((x) => x[dataKey])?.includes(action[dataKey])) {
			//updated all the selected list
			const ids = selectedIds?.map((x) => ({ ...x, priorityId: object["priorityId"] }));
			modifiedOpportunities.push.apply(modifiedOpportunities, ids);
		} else {
			modifiedOpportunities.push(object);
		}
		onChangeObject(modifiedOpportunities, ["priorityId"]);
	};
	return (
		<Style.PriorityWrapper className="w-100" colorcode={priority?.colorCode}>
			<Priority object={action} updateFunction={update} name={attribute} disable={!editRowAllowed} />
		</Style.PriorityWrapper>
	);
};

export const Stage = (action, params) => {
	const { pathname, search } = useLocation();

	const isInHome = pathname === "/home";
	const desactivateClick = pathname === "/trash" || pathname === "/archive" || !params.allowClick;
	const history = useHistory();
	const table = useSelector(({ ui }) => ui.table);

	const { cxProgramId } = search.getCxProps();
	const folderId = search.getFolderId();

	const handleClick = () => {
		const { entityType, context } = getEntityAndContext(params.route);
		const backQuery = cxProgramId !== undefined;

		const route = "/" + params.route;
		handleRedirectEntityList({ entityType, context, route, forceId: action.id, history, table, backQuery, folderId });
	};

	return (
		<CustomTooltip text={isInHome ? `${action?.mapName}-${action?.stageName}` : action?.stageName}>
			<Style.TextInfo isInHome={isInHome} stage="true" desactivateclick={`${desactivateClick}`} onClick={desactivateClick ? () => { } : handleClick}>
				{action?.stageName}
			</Style.TextInfo>
		</CustomTooltip>
	);
};

export const Status = (action, dataKey, type, listEditable, onChangeObject, attKey = "statusId") => {
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);
	const userInfo = useSelector((state) => state.auth?.userInfo);
	// in case of opp/sol/action authorizedUsers will be undefined if no mapId
	const roleName = action?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName ?? "owner";
	const { editRowAllowed } = PermissionManager({ userAccess: roleName });

	const update = (object) => {
		const modifiedObject = [];
		if (selectedIds?.map((x) => x[dataKey])?.includes(action[dataKey])) {
			//updated all the selected list
			const ids = selectedIds?.map((x) => ({ ...x, [attKey]: object["statusId"] }));
			modifiedObject.push.apply(modifiedObject, ids);
		} else {
			modifiedObject.push({ ...action, [attKey]: object["statusId"] });
		}
		onChangeObject && onChangeObject(modifiedObject, [attKey]);
	};

	return (
		<Style.StatusWrapper className="w-100">
			<DisplayStatus object={action} attKey={attKey} type="status" name={type} disable={!listEditable || !editRowAllowed} updateFunction={update} />
		</Style.StatusWrapper>
	);
};

export const Subject = (action, params) => {
	const { pathname, search } = useLocation();
	const history = useHistory();
	const desactivateClick = pathname === "/trash" || pathname === "/archive" || !params.allowClick;
	const { cxProgramId } = search.getCxProps();
	const folderId = search.getFolderId();
	const subject = params?.attribute ? action[params?.attribute] : action?.subject;

	const table = useSelector(({ ui }) => ui.table);

	const handleClick = () => {
		const { entityType, context } = getEntityAndContext(params.route);
		const route = "/" + params.route;
		const backQuery = cxProgramId !== undefined;

		handleRedirectEntityList({ entityType, context, route, forceId: action.id, history, table, backQuery, folderId });
	};
	const getLabel = () => {
		switch (params.route) {
			case "edit-opportunity":
				return "NEW_OPPORTUNITY_NAME";
			case "edit-solution":
				return "NEW_SOLUTION_NAME";
			case "edit-cxactions":
				return "NEW_CX_ACTION_NAME";
			case "edit-insight":
				return "NEW_INSIGHT_NAME";
		}
	};
	return (
		<CustomTooltip id={action?.id} text={subject} disabled={!subject || subject.length <= 21}>
			<Style.SubjectWrapper className="w-100" desactivateclick={`${desactivateClick}`} onClick={desactivateClick ? () => { } : handleClick}>
				{subject?.defaultPlaceholder(getLabel())}
			</Style.SubjectWrapper>
		</CustomTooltip>
	);
};

export const Ranking = (solution) => {
	return <Style.Ranking>{solution.score}</Style.Ranking>;
};

export const Win = (item, onChangeObject) => {
	const userInfo = useSelector((state) => state.auth?.userInfo);

	const role = item?.mapId === EMPTY_GUID ? "owner" : item?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName;
	const { editRowAllowed } = PermissionManager({ userAccess: role });

	const selectedIds = useSelector((state) => state.ui.table.selectedIds);
	const onClick = (e) => {
		const modifiedOpportunities = [];
		if (selectedIds?.map((x) => x.id)?.includes(item?.id)) {
			const ids = selectedIds?.map((x) => ({ ...x, win: !item.win }));
			modifiedOpportunities.push.apply(modifiedOpportunities, ids);
		} else {
			const copyObject = { ...item };
			copyObject.win = !item.win;
			modifiedOpportunities.push(copyObject);
		}

		onChangeObject(modifiedOpportunities, ["win"]);
	};
	return (
		<Style.SwitchWrapper className="mt-2 position-relative" type="win" active={item.win}>
			<Style.Switch checked={item.win} disabled={!editRowAllowed} onChange={onClick} />
			{item.win && <div className="switch-win-truth position-absolute">{IconDispatcher("LIKE", "me-1", { beat: item.win ? true : false, style: { color: "#138A8A" } })}</div>}
		</Style.SwitchWrapper>
	);
};

export const Truth = (item, onChangeObject) => {
	const color = item.truth ? "#BD3DBF" : "#D9D9D9";
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const role = item?.mapId === EMPTY_GUID ? "owner" : item?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName;

	const { editRowAllowed } = PermissionManager({ userAccess: role });
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);

	const onClick = () => {
		const modifiedOpportunities = [];
		if (selectedIds?.map((x) => x.id)?.includes(item?.id)) {
			const ids = selectedIds?.map((x) => ({ ...x, truth: !item.truth }));
			modifiedOpportunities.push.apply(modifiedOpportunities, ids);
		} else {
			const copyObject = { ...item };
			copyObject.truth = !item.truth;
			modifiedOpportunities.push(copyObject);
		}

		onChangeObject(modifiedOpportunities, ["truth"]);
	};
	return (
		<Style.SwitchWrapper className="mt-2 position-relative" type="truth" active={item.truth}>
			<Style.Switch checked={item.truth} disabled={!editRowAllowed} onChange={onClick} />
			{item.truth && <div className="switch-win-truth position-absolute">{IconDispatcher("HEART", "me-1", { beat: item.truth ? true : false, style: { color: color } })}</div>}
		</Style.SwitchWrapper>
	);
};

export const Group = (object) => {
	const currentGroup = useSelector((state) => state.libraries?.group?.find((gp) => gp.id === object.groupId));

	return <Style.EllipsisText title={currentGroup?.name}>{currentGroup?.name}</Style.EllipsisText>;
};

export const Rating = (object, attribute, listEditable, onChangeObject) => {
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);

	const update = (copyObject) => {
		const modifiedOpportunities = [];
		if (selectedIds?.map((x) => x.id)?.includes(object?.id)) {
			const ids = selectedIds?.map((x) => ({ ...x, [attribute]: copyObject[attribute] }));
			modifiedOpportunities.push.apply(modifiedOpportunities, ids);
		} else {
			modifiedOpportunities.push(copyObject);
		}
		onChangeObject(modifiedOpportunities, [attribute]);
	};
	return (
		<Style.RatingContainer>
			<div className="inner">
				<CustomRatingNew disabled={!listEditable} object={object} attribute={attribute} update={update} />
			</div>
		</Style.RatingContainer>
	);
};

export const displayBenefitCard = (solution, solutionType, size) => {
	let type = solutionType
	if (!solutionType) {
		type = calculatesolutionType(solution);
	}
	if (type === SolutionType.QuickWin) return <BenefitCard classes="px-2" isActive={true} variant="quickWin" icon="LIKE" text="QUICK_WIN" size={size} />;
	if (type === SolutionType.ComplexProject) return <BenefitCard classes="px-2" isActive={true} variant="complexProj" icon="PUZZLE" text="COMPLEX_PROJ" size={size} />;
	if (type === SolutionType.NiceToHave) return <BenefitCard classes="px-2" isActive={true} variant={"niceToHave"} icon={"STAR-LIFE"} text="NICE_TO_HAVE" size={size} />;
	if (type === SolutionType.ToAvoid) return <BenefitCard classes="px-2" isActive={true} variant="toAvoid" icon="ROAD-BARRIER" text="TO_AVOID" size={size} />;
	return <></>;
};
export const FolderName = (folder) => {
	return (
		<CustomTooltip id={folder.id} text={folder?.name} placement={"bottom"} disabled={!folder?.name || folder?.name?.length <= 44}>
			<Style.FolderNameStyle>{folder.name}</Style.FolderNameStyle>
		</CustomTooltip>
	);
};

export const OwnerFolder = (folder) => {
	return 	<InitialsBubble key={folder?.id}  fullName={folder.createdByName} size='s' />

};


export const Text = (text) => {
	return <Style.Total>{text}</Style.Total>;
};
export const DatePicker = (object, dataKey, attName, onChange) => {
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);

	const dateNew = object?.[attName]?.formatDate(); // object?.[attName]?.split("T")[0] || new Date().toISOString().split("T")[0];
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const roleName = object?.authorizedUsers?.find((user) => user.id === userInfo?.UserId)?.roleName;
	const { editRowAllowed } = PermissionManager({ userAccess: roleName });
	const isdisabled = roleName === undefined ? false : !editRowAllowed;
	const handleChange = (e) => {
		const { name, value } = e.target;
		const copyObject = { ...object };
		copyObject[attName] = new Date(value)?.toISOString();
		const modifiedOpportunities = [];
		if (selectedIds?.map((x) => x[dataKey])?.includes(object?.[dataKey])) {
			const ids = selectedIds?.map((x) => ({ ...x, [attName]: copyObject[attName] }));
			modifiedOpportunities.push.apply(modifiedOpportunities, ids);
		} else {
			modifiedOpportunities.push(copyObject);
		}
		onChange(modifiedOpportunities, [attName]);
	};

	return (
		<Style.DateContainer isundefined={`${!dateNew}`}>
			<Style.Date isundefined={`${!dateNew}`} variant="default" name={attName} placeholder="date placeholder" disabled={isdisabled} value={dateNew} type="date" onChange={handleChange} />
		</Style.DateContainer>
	);
};

export const RedirectText = (object, data, attribute) => {
	const history = useHistory();
	const text = object[attribute];

	const handleClick = () => {
		history.push(`/${data?.redirectUrl}${object?.id}`);
	};

	return (
		<CustomTooltip id={object.id} text={text} disabled={text?.length <= 21}>
			<Style.SubjectWrapper className="w-100" onClick={handleClick}>
				{text || "-"}
			</Style.SubjectWrapper>
		</CustomTooltip>
	);
};

export const Score = (score) => {
	return <Style.Score>{score}</Style.Score>;
};

export const LibraryName = (data) => {
	const history = useHistory();
	return (
		<Style.LibraryName className="w-100 text-truncate" onClick={() => history.push(`/library-${data?.url}${data.url === "custom" ? `?id=${data?.id}` : ""}`)}>
			<div>{data.name || "-"}</div>
		</Style.LibraryName>
	);
};

export const Icon = (data) => {
	return <div>{IconDispatcher(data?.icon, "m-2", { size: "xl" })}</div>;
};

export const NumberOfRecords = (data) => {
	return (
		<Style.LibraryName className="w-100" onClick={() => { }}>
			<div className="ms-5">{data?.nRecords}</div>
		</Style.LibraryName>
	);
};

export const Description = (data, translateDescription) => {
	const { t } = useTranslation();
	//translateDescription for library description
	const getText = (data) => {
		// translate label for library and regular description for custom library
		if(data?.library?.isCustom){
			return  data?.description?.isEmptyString() ? "-" : extractTextFromHtml(data.description);
		}
		if(translateDescription){
			return t(data?.description);
		}
		return data?.description?.isEmptyString() ? "-" : extractTextFromHtml(data.description);
	}

	const text = getText(data);
	return (
		<Style.TextInfo title={text !== "-" && text}>
			{text}
		</Style.TextInfo>
	);
};

export const UserName = (data) => {
	return (
		<div className="overflow-hidden">
			<CustomTooltip text={data} disabled={data.length <= 25}>
				<Style.DescriptionWrapper className="w-100 ">{data}</Style.DescriptionWrapper>
			</CustomTooltip>
		</div>
	);
};

export const InsightName = (insight, params) => {
	const desactivateClick = !params.allowClick;

	const [showEditModal] = useModal(EditInsights, {
		insightId: insight?.id,
	});

	return (
		<CustomTooltip id={insight?.id} text={insight?.name} disabled={!insight?.name || insight?.name.length <= 21}>
			<Style.SubjectWrapper className="w-100" desactivateclick={`${desactivateClick}`} onClick={desactivateClick ? () => { } : showEditModal}>
				{insight?.name?.defaultPlaceholder("NEW_INSIGHT_NAME")}
			</Style.SubjectWrapper>
		</CustomTooltip>
	);
};
