import styled, { css } from "styled-components/macro";
import InsightPickListCard from "../cards/insight-pick-list-card";
import { List } from "react-virtualized";

const InsightsList = ({ insights, handleLink, selected }) => {
	return (
		<ItemsContainer className="mb-4" minHeight="80" maxHeight="200">
			{insights.length > 0 ? (
				<List
					width={512}
					height={200}
					rowCount={insights?.length}
					rowHeight={56}
					rowRenderer={({ index, style }) => (
						<div id={`searchPickList${insights[index].id}`} key={insights[index].id} style={style}>
							<InsightPickListCard insight={insights[index]} handleLink={handleLink} selected={selected?.includes(insights[index].id)} />
						</div>
					)}
				/>
			) : (
				<div className="ms-2">{""}</div>
			)}
		</ItemsContainer>
	);
};

InsightsList.displayName = "InsightsList";
export default InsightsList;

const ItemsContainer = styled.div`
	${(props) => (props.minHeight ? `min-height:${props.minHeight}px;overflow: scroll;` : "")}
	width: 100%;
	height: 100%;
	overflow: hidden;
`;
