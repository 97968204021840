import React from "react";
import { pictureValidationPersona } from "@utils/helpers";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import { useSelector } from "react-redux";
import * as Style from "./Styles";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import { getSessionId } from "@redux/memoizedSelectors";
import { FlexAlignCenter } from "shared/ui-basics/index";

export default function Personas(item, n = 1, size = 35, displayEmotionLevel = true) {
    const getPersonaIds = () => {
        if (item?.linkedPersonas.length === 0) return []
        if (typeof item?.linkedPersonas[0] === "string") return item?.linkedPersonas
        return item?.linkedPersonas?.map((persona) => persona.personaId) || []
    }
    const personaIds = getPersonaIds()
	const sessionId = useSelector(getSessionId);

	const personaData = useSelector((state) => state.personas.all_personas?.filter((persona) => personaIds?.includes(persona?.personaId)) || []);
	const hideForPdf = useLocation().search.hideForPdf();
	const listPersonas = (n) => {
		return personaData?.slice(0, n).map((persona) => {
			const sentimentLevel = item.linkedPersonas.find((lnk) => lnk.personaId === persona.personaId)?.sentimentLevel;
			const level = Math.round(sentimentLevel);
			return (
				<div className="d-flex" key={persona.personaId}>
					<CustomTooltip key={persona.personaId} id={persona.personaId} text={persona.name}>
						<div key={persona.personaId} className="position-relative">
							<Style.PersonaPicture className="me-0" imgSize={size} src={pictureValidationPersona(persona, sessionId)} alt="" loading={hideForPdf ? "eager" : "lazy"} />
							{displayEmotionLevel &&  level > 0 && (
								<Style.EmotionPosition>
									<EmotionLevel variant="smiley" size="xxs" level={Math.round(level)} showTooltip={false} />
								</Style.EmotionPosition>
							)}
						</div>
					</CustomTooltip>
					{personaData?.length > 1 ? <Style.BubbleCount bubbleSize={size}>+{personaData?.length - 1}</Style.BubbleCount> : null}
				</div>
			);
		});
	};

	return <FlexAlignCenter className=" w-100 h-100">{listPersonas(1)}</FlexAlignCenter>;
}
