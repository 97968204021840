import { calculatesolutionType } from '@utils/helpers';
import { SolutionType } from '@utils/optionsSets/OptionSets';
import { updateSolutionService } from 'features/index';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { updateSolution } from '../reducer/SolutionsActions';
import { BenefitCard } from './BenefitCard';
import { RatingSection } from './RatingSection';

const SolutionRating = ({solution, setSolution, modifiedAttribNames, disabled}) => {

    const [solutionTypeLabel , setSolutionTypeLabel] = useState(calculatesolutionType(solution))
    const updateSol = (modifiedSolution, modifiedAttribNames,onSuccess) => updateSolution({modifiedSolution, modifiedAttribNames})
    const calculateWrapper = () => {
        const result = calculatesolutionType(solution)
        if (result !== solution.solutionType) {
            setSolution({...solution,solutionType:result})
            updateSolution({modifiedSolution:{...solution,solutionType:result},modifiedAttribNames: ["solutionType"]})
            setSolutionTypeLabel(result)

        }
    }

    return (
        <SolutionRatingWrapper>
            <ResultSection className='mt-4 mb-4'>
                <BenefitCard classes={"px-2"} isActive={SolutionType.QuickWin === solutionTypeLabel} variant={"quickWin"} icon={"LIKE"} text={"QUICK_WIN"}/>
                <BenefitCard classes={"px-2"} isActive={SolutionType.NiceToHave === solutionTypeLabel} variant={"niceToHave"} icon={"STAR-LIFE"} text={"NICE_TO_HAVE"}/>
                <BenefitCard classes={"px-2"} isActive={SolutionType.ComplexProject === solutionTypeLabel} variant={"complexProj"} icon={"PUZZLE"} text={"COMPLEX_PROJ"}/>
                <BenefitCard classes={"px-2"} isActive={SolutionType.ToAvoid === solutionTypeLabel} variant={"toAvoid"} icon={"ROAD-BARRIER"} text={"TO_AVOID"}/>
            </ResultSection>

            <Ratings >
                <Col>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.impactCustomer"} title={"IMPACT_ON_CUSTOMER"} updateFunction={updateSol} calculateWrapper={calculateWrapper}/>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.customerBenefit"} title={"CUSTOMER_BENEFITS"} updateFunction={updateSol} calculateWrapper={calculateWrapper}/>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.revenueIncrease"} title={"REVENUE_INCREASE"} updateFunction={updateSol} calculateWrapper={calculateWrapper}/>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.impactBrand"} title={"IMPACT_ON_BRAND"} updateFunction={updateSol} calculateWrapper={calculateWrapper}/>
                </Col>
                <Col>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.cost"} title={"IMPL_COST"} updateFunction={updateSol} calculateWrapper={calculateWrapper}/>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.implComplexity"} title={"COMPLEXITY_IMPL"}updateFunction={updateSol} calculateWrapper={calculateWrapper}/>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.engagementEffortRanking"} title={"ENGAGEMENT_EFFORT"}updateFunction={updateSol}calculateWrapper={calculateWrapper}/>
                    <RatingSection solution={solution} setSolution={setSolution} modifiedAttribNames={modifiedAttribNames} disabled={disabled} path={"ratings.implDuration"} title={"TIME_HORIZON"}updateFunction={updateSol}calculateWrapper={calculateWrapper}/>
                </Col>
            </Ratings>
        </SolutionRatingWrapper>
    )
}

export default SolutionRating


const SolutionRatingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`

const ResultSection = styled.div`
   display: flex;
    align-items: center;
    gap: 20px;
`

const Ratings = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-evenly;
    gap: 15%;
`

const Col = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
`



