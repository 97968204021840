import { DropdownMenu } from "reactstrap";
import { Button, Input } from 'shared/ui-basics/index';
import styled, { css } from "styled-components/macro";

export const X = styled(Button)`
	display: none;
`;
export const DeleteAction = styled.div`
display: none;
align-items: center;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	max-height:100%;// ${(props) => (props.vertical ? "100%" : "calc(100vh - 200px)")};
	/* ${(props) => (props.vertical ? "" : "flex-flow: wrap")};
	${(props) => (props.vertical ? "" : "overflow: auto")}; */
	padding: 15px;
	    min-height: 50vh;
`;
export const WrapperDiv = styled.div`
	width: 260px;
	max-height: 44px;
	display: flex;

	:hover ${DeleteAction} {
		display: flex;
	}
`;
export const Container = styled.div`
	display: flex;
	background: #ffffff;
	border-radius: 4px;
	width: 230px;
	/* border: 1px solid #c4c4c4; */

	:hover {
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	}
	/* margin:0.7rem; */
`;
export const Color = styled.div`
	width: 42px;
	height: 44px;
	border-radius: 4px 0px 0px 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	background-color: ${(props) => props.color};
	cursor: pointer;
`;
export const Menu = styled(DropdownMenu)`
	width: 292px;
	.dropdown-item {
		width: inherit;
		padding: 0;
	}
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
	border: none !important;
	background: #ffffff;
`;
export const WrapperMenu = styled.div`
	display: flex;
	max-width: 350px;
	flex-wrap: wrap;
	padding: 0;
	border-radius: 6px;
`;
export const CustomInput = styled(Input)`
	height: 44px;
	/* border: none; */
	border-radius: 0;
	border-radius: ${(props) => (props?.iscoloreditable === 'true' ? "0px 4px 4px 0px;" : "4px;")};
	color: ${(props) => (props.color ? props.color  : "#333333")};

	:disabled {
		background-color: transparent !important;
		color: #c4c4c4 !important;
	}
`;

export const AddButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	cursor: pointer;
	color: #605f60;
	width: 230px;
	height: 44px;

	:hover {
		background-color: #efefef;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	}
	${(props) =>
		props?.fromCustomization &&
		props?.fromCustomization === true &&
		`
        margin-right: 30px !important;
    `}
`;
export const AddContainer = styled.div`
	width: 275px;
`

export const CategoryWrapper = styled.div`
	/* width:25%; */
	padding: 10px;
	width: 211px;
`;
export const CategoryButton = styled(Button)`
	${(props) =>
		props.active &&
		css`
			background-color: #e4e7f1 !important;
			border: 1px solid #e4e7f1 !important;
		`}
`;
export const OverlayPanelWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	${(props) => props.size && `width:${props.size}px;`}
`;
export const WrapperDropdownToggle = styled.div`
	border-radius:0.25rem;
	padding:0.375rem 0.75rem;
    height: 34px;
    background-color: transparent !important;
    color: #605F60 !important;
    font-size:14px;
:hover{
  color: #605F60 !important;
    background-color: #EFEFEF !important
}
`