import { createAction } from "@reduxjs/toolkit";
import { constructUrl } from "@utils/helpers";
import { genericService } from "shared/GenericService";
import { InitActionCreator } from "../../../redux/store";

const BASE_URL = "api/report/";

const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" });
const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" });

export const getDashboardData = ({ dashboardCode, isExportMode }) =>
	createGetAction({
		type: "GET_DASHBOARD_DATA",
		endPoint: constructUrl("getDashboardData", {
			dashboardCode,
		}),
		params: { dashboardCode, isExportMode },
	});

export const applyFilterJourneyTrend = createAction("APPLY_FILTER_JOURNEY_TRENDS");
export const applyFilterVocAnalysis = createAction("APPLY_FILTER_VOC_ANALYSIS");
export const applyFilterRoiAnalysis = createAction("APPLY_FILTER_ROI_ANALYSIS");

export const getPdfExportDashboard = ({ dashboardName, filters, fileName, width, height, ...props }) => {
	genericService({
		...props,
		action: createPostAction({
			type: "GET_PDF_EXPORT_DASHBOARD",
			endPoint: "prepareAndGetPdfExport",
			data: {
				dashboardName,
				filters,
				width,
				height,
				renderDelay: 6000,
			},
			responseType: "blob",
		}),
		displayToast: "EXPORT",
        onSuccess: (data) => {
			var fileDownload = require("js-file-download");
			fileDownload(data, fileName + ".pdf");
        },
        onFailed: (error) => {
			if (error) throw error;
		},
	});
};

// export const getPdfExportDashboard = ({ dashboardName, filters, fileName, width, height }) => {
// 	const promise = async () => {
// 		const { data, error } = await store.dispatch(
// 			createGetAction({
// 				type: "GET_PDF_EXPORT_DASHBOARD",
// 				endPoint: constructUrl("getPdfExport", {
// 					dashboardName,
// 					filters,
// 					width,
// 					height,
// 					renderDelay: 6000,
// 				}),
// 				responseType: "blob",
// 			})
//         );
//         console.log(data)
// 		var fileDownload = require("js-file-download");
// 		fileDownload(data, fileName + ".pdf");
// 		if (error) throw error;
// 	};
// 	toastWrapper(promise, "EXPORT");
// };
