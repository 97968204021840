import { EMPTY_GUID, isToday, reorderActions } from "@utils/helpers";
import { ConnectorSource } from "@utils/optionsSets/OptionSets";
import { PermissionManager } from "@utils/permissionManager";
import { SyncWithModal } from "features/projects/modals/SyncWithModal";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { ActionPopOver } from "shared/popovers/ActionsPopOver";
import { GenericButton } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import { synchronizeProject } from "../reducer/ProjectsActions";
import BaseActions from "./BaseActions";

export default function HeaderActions({ onRefresh, handleRedirect, handleExport }) {
	const { t } = useTranslation();
	const userId = useSelector((state) => state.auth?.userInfo?.UserId);
	const project = useSelector((state) => state.projects.current_project);
    const mondayConnector = useSelector((state) => state.settings.connector)?.find((connector) => connector.source === ConnectorSource.Monday);

	const userAccess = project?.authorizedUsers?.find((user) => user.id === userId && user.roleId === EMPTY_GUID)?.roleName;
	const lastModifiedOn = project?.lastModifiedOn;

	const LastSave = useCallback(() => {
		return (
			<div>
				{t("JS_56")} @<Moment format="HH:mm:ss">{lastModifiedOn}</Moment>
				{isToday(lastModifiedOn) ? (
					""
				) : (
					<>
						[<Moment format="DD/MM/YYYY">{lastModifiedOn}</Moment>]
					</>
				)}{" "}
			</div>
		);
	}, [lastModifiedOn]);

	const [showSyncModal] = useModal(SyncWithModal, {
		title: `${t("SYNCHRONIZE_WITH")} ${t("MONDAY")}`,
        bodyText: `${t("SYNC_ALERT_1")} [${mondayConnector?.mondaySettings?.globalProjectsBoardName}] ${t("SYNC_ALERT_2")}  [${project?.name}] ${t("SYNC_ALERT_3")}`,
        actionBtnText: t("SYNCHRONIZE"),
		action: () => synchronizeProject({ projectId: project?.id }),
	});

	const { exportAllowed, refreshAllowed, mondaySyncAllowed } = PermissionManager({
		userAccess,
	});

	const createActions = () => {
		const actions = [];
		if (refreshAllowed) actions.push({ id: "refresh", handleClick: onRefresh, icon: "REFRESH", text: <LastSave /> });
		if (exportAllowed) actions.push({ id: "export", handleClick: () => handleExport(project), icon: "EXPORT", text: t("ASPX_14"), itemClasses: "d-flex align-items-center" });
		if (mondaySyncAllowed && mondayConnector) {
			actions.push({ id: "divider", divider: true });
			actions.push({
				id: "mondaySync",
				handleClick: showSyncModal,
				icon: "MONDAY",
				text: `${t("SYNCHRONIZE_WITH")} ${t("MONDAY")}`,
				itemClasses: "d-flex align-items-center",
				type: "textTransformNone",
				disabled: project?.externalUrl !== "" || !mondayConnector?.enableConnection,
			});
		}

		const baseActions = BaseActions({ project, handleRedirect });
		const allActions = actions.concat(baseActions);

		return {
			inDropdownActions: reorderActions({
				actions: allActions?.filter((action) => !["manageAccess", "refresh"].includes(action?.id)),
				order: ["export", "copy", "divider", "archive", "delete", "divider", "mondaySync"],
			}),
			outsideDropdownActions: reorderActions({
				actions: allActions?.filter((action) => ["manageAccess", "refresh"].includes(action?.id)),
				order: ["manageAccess", "refresh"],
			}),
		};
	};

	const actionsData = createActions();

	const actionValidation = (action, index) => {
		if (action?.id === "addSection") return <ActionPopOver key={index} actions={action.actions} variant={action?.variant} icon={action?.icon} tooltipText={action?.tooltipText} label={""} />;
		return <GenericButton variant="light-link" tooltip tooltipText={action?.text} key={index} icon={action?.icon} onClick={action?.handleClick} />;
	};

	return (
		<HeaderActionsContainer>
			{actionsData?.outsideDropdownActions?.map((action, index) => actionValidation(action, index))}
			<CreateActionsDropdown actions={actionsData?.inDropdownActions} variant="" dropdownClasses="" />
		</HeaderActionsContainer>
	);
}

const HeaderActionsContainer = styled.div`
	display: flex;
	align-items: center;
`;
