import { FlexAlignCenter, FlexAlignJustifyCenter, FlexColumnGap } from "shared/ui-basics/index";
import CustomLegend from "features/analytics/Components/CustomLegend";
import EmptyData from "features/analytics/Components/EmptyData";
import CustomZigZagTick from "features/analytics/Components/Ticks/CustomZigZagTick";
import { ColorCircle, CustomTooltip } from "features/analytics/Style";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Bar, BarChart, LabelList, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { KpiDataSelector } from "../roiAnalysisDataSelectors";

const KpiByActual = () => {
	const { t } = useTranslation();

	const data = useSelector((state) => KpiDataSelector(state))
		.slice(0, 12)
		?.sort((a, b) => b.name - a.name);

	const CustomBarChartLabel = ({ x, y, fill, value }) => {
		const labelX = x + 30;
		const labelY = y + 20;
		if (value < 8) return <></>;
		return (
			<text x={labelX} y={labelY} fill={fill} textAnchor="middle" style={{ fontSize: "14px" }}>
				{value}%
			</text>
		);
	};

	const RenderTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<CustomTooltip className="custom-tooltip p-2 " style={{ background: "white" }}>
					<FlexColumnGap>
						{payload[0]?.payload?.name}
						<FlexAlignCenter>
							<ColorCircle className="me-2" color="#18AEAD" />
							{`${t("ACHIEVED")}: ${payload[0]?.value}%`}
						</FlexAlignCenter>
						<FlexAlignCenter>
							<ColorCircle className="me-2" color="#9DF2F2" />
							{`${t("NOT_ACHIEVED")}: ${payload[1]?.value}%`}
						</FlexAlignCenter>
					</FlexColumnGap>
				</CustomTooltip>
			);
		}

		return null;
	};

	const RenderLegend = () => {
		const legendData = [
			{ color: "#18AEAD", name: t("ACHIEVED") + " (%)" },
			{ color: "#9DF2F2", name: t("NOT_ACHIEVED") + " (%)" },
		];
		return <CustomLegend data={legendData} />;
	};

	return (
		<>
			{data?.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={data}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}>
						<XAxis dataKey="name" interval={0} axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }} textAnchor="middle" tick={<CustomZigZagTick />} height={50} />
						<YAxis hide />
						<Tooltip cursor={{ fill: "#efefef" }} isAnimationActive={false} content={<RenderTooltip />} />
						<Legend
							layout="vertical"
							align="right"
							verticalAlign="top"
							wrapperStyle={{
								paddingLeft: "30px",
							}}
							content={<RenderLegend />}
						/>
						<ReferenceLine y={0} stroke="#000" />

						<Bar dataKey="achieved" stackId="a" fill="#18AEAD" barSize={60}>
							<LabelList dataKey="achieved" position="insideBottom" content={<CustomBarChartLabel fill="#9DF2F2" />} />
						</Bar>
						<Bar dataKey="notAchieved" stackId="a" fill="#9DF2F2" barSize={60}>
							<LabelList dataKey="notAchieved" position="insideBottom" content={<CustomBarChartLabel fill="#18AEAD" />} />
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="KPI_REPORT_DESCRIPTION" icon="CHART-BAR" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
};
KpiByActual.displayName = "KpiByActual";
export default KpiByActual;
