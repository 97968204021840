import React from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { IconDispatcher } from "shared/Icons";

export function OneRow({ scale, halfYAxisSquare, baseUnit, isDoubleWidth }) {
	return (
		<Row>
			{Array.from(Array(scale.length - 1)).map((empty, idx) => (
				<Square
					key={idx}
					lastSquare={idx === scale.length - 2}
					halfYAxisSquare={halfYAxisSquare}
					halfXAxisSquare={idx === (scale.length - 1) / 2 ? true : false}
					baseUnit={baseUnit}
					isDoubleWidth={isDoubleWidth}
				/>
			))}
		</Row>
	);
}

export function XAxis({ scale, baseUnit, title }) {
	const { t } = useTranslation();
	return (
		<XAxisWrapper>
			<XScale baseUnit={baseUnit}>
				{scale.map((idx) => (
					<Unit key={idx}>{idx}</Unit>
				))}
			</XScale>
			<XAxisTitle className="ps-2">
				<div>{t(title)}</div>
				<div>{IconDispatcher("ARROW_RIGHT", "ms-2")}</div>
			</XAxisTitle>
		</XAxisWrapper>
	);
}

export function YAxis({ scale, baseUnit, title, yAxisPosLeft = "-150", yAxisPosBottom = "-8", yAxisTitlePos = "20" }) {
	const { t } = useTranslation();
	return (
		<YAxisWrapper yaxisposleft={yAxisPosLeft} yaxisposbottom={yAxisPosBottom}>
			<YAxisTitle yaxistitlepos={yAxisTitlePos}>
				<Test>
					<div>{t(title)}</div>
					<div>{IconDispatcher("ARROW_RIGHT", "ms-2")}</div>
				</Test>
			</YAxisTitle>
			<YScale baseUnit={baseUnit}>
				{scale.map((idx) => (
					<Unit key={idx}>{scale[scale.length - 1] - idx}</Unit>
				))}
			</YScale>
		</YAxisWrapper>
	);
}

const Row = styled.div`
	display: flex;
`;

const XAxisTitle = styled.div`
	display: flex;
	font-weight: 400;
	font-size: 14px;
	color: #8c8c8c;
`;

const YAxisWrapper = styled.div`
	position: absolute;
	display: flex;
	align-items: flex-end;
	left: ${(props) => props.yaxisposleft}px;
	bottom: ${(props) => props.yaxisposbottom}px;
`;

const YAxisTitle = styled.div`
	position: relative;
	width: 20px;
	/* margin-bottom: ${(props) => props.yaxistitlepos}px; */
`;

const Test = styled.div`
	display: flex;
	font-weight: 400;
	font-size: 14px;
	color: #8c8c8c;
	position: absolute;
	transform: rotate(-90deg);
	left: -70px;
    bottom: 71px;
    width: 150px;

`;

const XAxisWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 10px;
	left: -12px;
	bottom: -70px;
`;

const XScale = styled.div`
	display: flex;
	gap: ${(props) => props.baseUnit - 26}px;
`;

const YScale = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.baseUnit - 18}px;
`;

const Square = styled.div`
	border-top: 1px solid #eeeeee;
	border-left: 1px solid #eeeeee;
	border-bottom: 1px solid #eeeeee;
	height: ${(props) => props.baseUnit}px;
	width: ${(props) => props.baseUnit}px;

    ${props => props.isDoubleWidth && css`
        width: ${(props) => props.baseUnit * 2}px;
    `}

	${(props) =>
		props.lastSquare &&
		`
        border-right: 1px solid #EEEEEE;
    `}

	${(props) =>
		props.axis &&
		`
        border: none;
    `}

    ${(props) =>
		props.halfXAxisSquare &&
		`
        border-left: 1px solid #605F60;
    `}

    ${(props) =>
		props.halfYAxisSquare &&
		`
        border-bottom: 1px solid #605F60;
    `}
`;

const Unit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 18px;
	background: #ffffff;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #8c8c8c;
`;
