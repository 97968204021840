import { getPreviousLinkedPersonas, linkPersona } from "@redux/index";
import { toastWrapper } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets";
import DataTable from "features/grid-table/DataTable";
import PersonaCardWithSelectedCheck from "features/journey-map/modals/components/PersonaCardWithSelectedCheck";
import { PersonaModalCard } from "features/journey-map/modals/components/PersonaModalCard";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useAsync } from "shared/hooks/index";
import { Button, FlexColumnGap, GenericButton, SearchBar } from "shared/ui-basics/index";
import styled from "styled-components/macro";

export const ManagePersonaModal = ({ isOpen, onClose, cjmId, personaId }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const personasAll = useSelector((state) =>
		state.personas.all_personas.filter((persona) => {
			if (showSampleData) return persona.statusCode === StatusCode.Active && !persona?.isTemplate;
			else return persona.statusCode === StatusCode.Active && !persona?.isTemplate && !persona?.isSample;
		})
	);

	const personasLinkCJM = useSelector((state) => state.maps.current_map?.cjmPersonasData?.map((persona) => personasAll?.filter((all) => all.personaId === persona.personaId)).flat());

	const currentPersonasLinkCJM = personasAll?.filter((persona) => persona.personaId === personaId) || [];
	const cjmPersonasData = personasLinkCJM?.map((persona) => persona.personaId);

	const [selectToPersona, setToPersona] = useState(currentPersonasLinkCJM[0]);
	const [selectFromPersona, setFromPersona] = useState(currentPersonasLinkCJM[0]);

	const checkOnClose = () => {
		if (!cjmPersonasData.includes(personaId) && personaId !== undefined) {
			history.push(`/EditMap?cjmid=${cjmId}&personaId=${cjmPersonasData[0]}`);

		} else onClose();
	};

	return (
		<ModalWrapper isOpen={isOpen} fade={false} centered={true} toggle={checkOnClose} size="lg">
			<PersonaHeaderTitle toggle={checkOnClose}>{t("ADD_PERSONA_TO_JOURNEY")}</PersonaHeaderTitle>
			<ModalBody className="pt-0 px-0">
				<StepWizard transitions="nothing">
					<Step1
						cjmId={cjmId}
						currentPersona={personaId}
						personasAll={personasAll}
						setFromPersona={setFromPersona}
						selectFromPersona={selectFromPersona}
						setToPersona={setToPersona}
						onClose={onClose}
					/>
					<Step2 selectToPersona={selectToPersona} selectFromPersona={selectFromPersona} cjmId={cjmId} onClose={onClose} />
					<Step3 selectToPersona={selectToPersona} selectFromPersona={selectFromPersona} cjmId={cjmId} onClose={onClose} personasAll={personasAll} />
				</StepWizard>
			</ModalBody>
		</ModalWrapper>
	);
};

const Step1 = ({ onClose, personasAll, currentPersona, cjmId, selectFromPersona, setToPersona, setFromPersona, ...props }) => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const [globalFilterValue, setGlobalFilterValue] = useState("");

	useAsync({ asyncFn: getPreviousLinkedPersonas, parameter: {cjmId} });

	const handleChange = (e) => {
		setGlobalFilterValue(e.target.value);
		ref.current.updateFilter(e.target.value);
	};

	const addToJourney = () => {
		if (!ref.current.singleSelected) toastWrapper("", "WARNING_SELECT_PERSONA");
		else {
			setToPersona(ref.current.singleSelected);
			props.nextStep();
		}
	};

	const Search = SearchBar({ globalFilterValue, handleChange, height: 40 });

	return (
		<FlexColumnGap gap="32" className="p-4">
			<div className="ms-5">{Search}</div>
			<MapContainer >
				<DataTable context={"personas.managePersonas"} ref={ref} />
			</MapContainer>
			<WrappperFooter className="pt-4 pb-2 px-3">
				<GenericButton variant="primary-outline" className="me-2" onClick={() => addToJourney()}>
					{t("ADD_SELECTED")}
				</GenericButton>
			</WrappperFooter>
		</FlexColumnGap>
	);
};

const Step2 = ({ onClose, cjmId, selectToPersona, selectFromPersona, ...props }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedOption, setSelectedOption] = useState("blank");
	const [disable, setDisable] = useState(false);

	const LinkPersona = () => {
		const onSuccess = () => {
			onClose();
			history.push(`/EditMap?cjmid=${cjmId}&personaId=${selectToPersona.personaId}`);

		};
		linkPersona({ cjmId, personaId: selectToPersona.personaId, fromPersonaId: "", setLoading: setDisable, onSuccess, displayToast: "LINK" })
	};

	return (
		<>
			<WrappperBody className={"my-5"}>
				<div className="d-flex justify-content-center align-items-center pb-4">
					<PersonaModalCard variant="new" onClose={onClose} cjmId={cjmId} persona={selectToPersona} />
				</div>
				<OptionBox className="p-2 mb-2" selected={selectedOption === "blank"} onClick={() => setSelectedOption("blank")}>
					<RadioButton selected={selectedOption === "blank"} />
					<OptionDetails>
						<OptionTitle>{t("START_FROM_BLANK")}</OptionTitle>
						<OptionDescription>{t("START_FROM_BLANK_DESCR")}</OptionDescription>
					</OptionDetails>
				</OptionBox>
				<OptionBox className="p-2" selected={selectedOption === "copy"} onClick={() => setSelectedOption("copy")}>
					<RadioButton selected={selectedOption === "copy"} />
					<OptionDetails>
						<OptionTitle>{t("COPY_DATA")}</OptionTitle>
						<OptionDescription>{t("COPY_DATA_DESCR")}</OptionDescription>
					</OptionDetails>
				</OptionBox>
			</WrappperBody>
			<WrappperFooter className="pt-4 pb-2 px-3">
				<ModelButton variant="primary-outline" className="me-2 justify-content-center" size='m' onClick={props.previousStep}>
					{t("JS_155")}
				</ModelButton>
				<ModelButton variant="primary" size='m' className='justify-content-center' disabled={disable} onClick={selectedOption === "blank" ? LinkPersona : props.nextStep}>
					{t("JS_154")}
				</ModelButton>
			</WrappperFooter>
		</>
	);
};

const Step3 = ({ onClose, cjmId, personasAll, selectToPersona, selectFromPersona, ...props }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const personasLinkCJM = useSelector((state) => state.maps.current_map?.cjmPersonasData?.map((persona) => personasAll?.filter((all) => all.personaId === persona.personaId)).flat());

	const [selected, setSelected] = useState("");
	const [disable, setDisable] = useState(false);
	useEffect(() => {
		if (personasLinkCJM.length === 1) setSelected(personasLinkCJM[0].personaId);
	}, []);

	const LinkPersona = () => {
		const onSuccess = () => {
			onClose();
			history.push(`/EditMap?cjmid=${cjmId}&personaId=${selectToPersona.personaId}`);

		};

		linkPersona({ cjmId, personaId: selectToPersona.personaId, fromPersonaId: selected, setLoading: setDisable, onSuccess, displayToast: "LINK" })
	};

	return (
		<>
			<div className=" my-5 d-flex flex-column align-items-center">
				<Text>{t("SELECT_PERSONA_TO_COPY_FROM")}</Text>
				<CardsContainer className="my-5">
					{personasLinkCJM?.map((persona, index) => (
						<PersonaCardWithSelectedCheck key={persona.personaId} persona={persona} selected={selected} setSelected={setSelected} />
					))}
				</CardsContainer>
			</div>
			<WrappperFooter className="pt-4 pb-2 px-3">
				<ModelButton variant="primary-outline" className="me-2 justify-content-center" size='m' onClick={props.previousStep}>
					{t("JS_155")}
				</ModelButton>
				<ModelButton variant="primary" disabled={!selected || disable} className='justify-content-center' size='m' onClick={LinkPersona}>
					{t("JS_154")}
				</ModelButton>
			</WrappperFooter>
		</>
	);
};


const Text = styled.div`
	color: #605f60;
	font-size: 20px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const CardsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	overflow-x: auto;
`;

const OptionBox = styled.div`
	width: 390px;
	height: 109px;
	display: flex;
	align-items: center;
	gap: 20px;
	border: 1px solid ${(props) => (props.selected ? "#F96209" : "#c4c4c4")};
	border-radius: 4px;
	:hover {
		cursor: pointer;
	}
`;

const OptionDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const OptionTitle = styled.div`
	color: #605f60;
	font-size: 24px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const OptionDescription = styled.div`
	color: #605f60;
	font-size: 14px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const ModalWrapper = styled(Modal)`
	max-width: 1000px;
	width: 100%;
	@media screen and (max-width: 1280px) {
		max-width: 900px;
	}
`;

const PersonaHeaderTitle = styled(ModalHeader)`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;

	color: #353535;
`;

const WrappperBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const WrappperFooter = styled.div`
	padding-top: 10px;
	border-top: 1px solid #d9d9d9;
	display: flex;
	justify-content: end;
	align-items: center;
`;

const ModelButton = styled(Button)`
	height: 50px;
	width: 100px;
`;

const RadioButton = styled.div`
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: 100px;
	border: ${(props) => (props.selected ? "4px solid #333333" : "1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15))")};
	background: var(--gray-white, #fff);
`;

const MapContainer = styled.div`
	/* display: flex; */
	/* padding-right: 2.5rem !important;
	padding-left: 3rem !important;
	overflow-x: auto;
	max-height: 450px;
	@media screen and (max-width: 1280px) {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	} */
`;
