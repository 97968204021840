import { genericService } from "shared/GenericService";
import { createAction } from "@reduxjs/toolkit";
import { constructUrl, encode, toastWrapper } from "@utils/helpers";
import { InitActionCreator } from "../../../redux/store";
import { store } from "index";

const BASE_URL = "api/insight/";
const BASE_URL_SWIMLANE = "api/swimlane/";

const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" }); // api actions type get
const createPostActionSwimlane = InitActionCreator({ baseUrl: BASE_URL_SWIMLANE, method: "POST" }); // api actions type post

export const resetProject = () => createAction("RESET_INSIGHT"); // local actions

export const getAllInsights = () => createGetAction({ type: "GET_ALL_INSIGHTS", endPoint: "getAll",meta: { joinRequest: 'GET_ALL_INSIGHTS' }  });
export const getInsightRelatedRecords = (props) => createGetAction({ type: "GET_INSIGHT", endPoint:  constructUrl("get", {
	insightId: props?.insightId,
}),params: {insightId: props?.insightId} });



export const createInsight = (props) => genericService({ ...props,
	action: createPostAction({ type: "CREATE_INSIGHT", endPoint: "create", data: { newInsight: props?.item } }),
	//displayToast: "CREATE"
});
export const updateInsight = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_INSIGHT",
			endPoint: "update",
			data: {
				modifiedInsight: { ...props?.modifiedInsight, name: encode(props?.modifiedInsight?.name), description: encode(props?.modifiedInsight?.description) },
				modifiedAttribNames: props?.modifiedAttribNames,
			},
		}),
	});
export const updateMultiInsight = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_NULTI_INSIGHT",
			endPoint: "updateMulti",
			data: {
				modifiedInsights: props?.modifiedInsights,
				modifiedAttribNames: props?.modifiedAttribNames,
			},
		}),
	});
export const deleteInsights = (props) => genericService({ ...props, action: createPostAction({ type: "DELETE_INSIGHTS", endPoint: "delete", data: { ids: props?.ids } }) });
export const deleteInsightsPermanent = (props) =>
	genericService({ ...props, action: createPostAction({ type: "DELETE_INSIGHTS_PERMANENT", endPoint: "deletePermanently", data: { ids: props?.ids } }) });
export const archiveInsights = (props) => genericService({ ...props, action: createPostAction({ type: "ARCHIVE_INSIGHTS", endPoint: "archive", data: { ids: props?.ids } }) });
export const restoreInsights = (props) => genericService({ ...props, action: createPostAction({ type: "RESTORE_INSIGHTS", endPoint: "restore", data: { ids: props?.ids } }) });

export const linkInsightRecords = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "LINK_INSIGHT_RECORDS",
			endPoint: "linkRecords",
			data: { recToLinkEntityName: props.recEntityName, recIds: props.recIds, insightId: props?.itemId, prefixRelationships: props?.prefixRelationships || "" },
			params: { attribute: props?.attribute },
		}),
	});
export const unLinkInsightRecords = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UNLINK_INSIGHT_RECORDS",
			endPoint: "unLinkRecords",
			data: { recEntityName: props.recEntityName, recIds: props.recIds, insightId: props?.itemId, prefixRelationships: props?.prefixRelationships|| "" },
			params: { attribute: props?.attribute },
		}),
	});
export const orderInsights = (props) =>
	genericService({
		...props,
		action: createPostActionSwimlane({
			type: "ORDER_INSIGHTS",
			endPoint: "updateGlobalSwimLaneItemsOrder",
			data: {
				newItemsOrder: props?.newItemsOrder,
				customSwimLaneIdStr: props?.customSwimLaneIdStr,
				stageIdStr: props?.stageIdStr,
				cjmIdStr: props?.cjmIdStr,
			},
		}),
	});
export const linkInsightOnMap = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "LINK_INSIGHT_ON_MAP",
			endPoint: "linkInsightsOnMap",
			data: {
				swimLaneItemIds: props?.swimLaneItemIds,
				insightIds: props?.insightIds,
				customSwimLaneId: props?.swimlaneKey,
				stageId: props?.stageId,
				cjmId: props?.cjmIdStr,
				personaId: props?.personaIdStr,
			},
		}),
	});

export const unlinkInsightOnMap = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UNLINK_INSIGHT_ON_MAP",
			endPoint: "unlinkInsightOnMap",
			data: {
				swimLaneItemId: props?.swimLaneItemId,
				customSwimLaneId: props?.swimlaneKey,
				stageId: props?.stageId,
				cjmId: props?.cjmIdStr,
			},
		}),
	});

export const getInsightsExcelExport = (ids, columns) => {
	const promise = async () => {
		const { data, error } = await store.dispatch(createPostAction({
            type: "GET_INSIGHT_EXCEL",
            endPoint: "getExcelExport",
            data: {
                ids,
                columns: columns?.join("|"),//.replace("insightName", "Insight Name"),
            },
            responseType: "blob",
        }));
		var fileDownload = require("js-file-download");
		fileDownload(data, "Insight List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};

