import { linkTags, unlinkTag } from "@redux/index";
import { useSelector } from "react-redux";
import PopoverEditMode from "shared/popovers/PopoverEditMode";
import EditableItem from "shared/ui-basics/ui-components/EditableItem";

export default function Tags({ object, setObject, recordType, recordId, disabled, isContributor = false, size = 'xs'}) {
	const all_tags = useSelector((state) => state.libraries.tags?.map(t => t.id));
	const elementIds = object?.tags?.filter(a => all_tags?.includes(a)) || [];
	const linkTag = (id) => {
		linkTags({ tagIds: [id], recordIds: [recordId || object.id], recordType: recordType });
		setObject && setObject({ ...object, tags: [...object?.tags || [], id] });
	};

	const unlinkTags = (tagId) => {
		unlinkTag({ tagId, recordId: recordId || object.id, recordType: recordType });
		setObject && setObject({ ...object, tags: object.tags?.filter((id) => id !== tagId) });
	};
	const props = {
		label: "TAG",
		popoverParams: {
			addLabel: "ADD_TAG",
			addFunction: ({ id }) => linkTag(id),
			type: "tag",
			disabled,
			relatedItems: object?.tags
		},
		RenderElement: (id, isEdit) => {
			return (
				<EditableItem
					key={id}
					objectId={id}
					unlink={unlinkTags}
					mode={!disabled && !isContributor && isEdit && "unlinkable"}
					itemSelectorPath="libraries.tags"
					context="tag"
					title="TAGS"
					size={size} />
			);
		}

	}
	return (
		<PopoverEditMode elementIds={elementIds}  {...props} />
	);

}
