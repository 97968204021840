import { FlexColumnGap, GenericButton, IconDispatcher, Input } from "shared/ui-basics/index";
import { useAutoFocus } from "shared/hooks/index.js";
import DisplayState from "shared/cx-components/DisplayState.js";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import * as Style from "./Style.js";
import { toast } from "react-toastify";

export default function MapName(props) {
	const { name, description } = props?.newMap;
	const { t } = useTranslation();
	const ref = useAutoFocus();

	const update = (e) => {
		const name = e.target.name;
		let value = e.target.value;
		if (name === "name") {
			value = value.truncate(90, true);
		}
		else if (name === "description") value = value.truncate(19999, true);
		props.setNewMap({ ...props.newMap, [name]: value });

	};

	const updateState = (object) => {
		props.setNewMap({ ...props.newMap, mapType: object?.mapType });
	};

	return (
		<Style.StepWrapper>
			<Style.BackButton variant="primary-link" onClick={props.previousStep}>
				{IconDispatcher("ARROW_LEFT", "me-1")}
				{t("ASPX_132")}
			</Style.BackButton>
			<FlexColumnGap gap="24">
				<Style.Title className="">{t("MAP_CREATION_TITLE_STEP2")}</Style.Title>
				<Style.SubTitle>{t("MAP_CREATION_SUBTITLE_STEP2")}</Style.SubTitle>
			</FlexColumnGap>
			<div className="d-flex">
				<Style.NameContainer>
					<Label className="mt-3 d-flex">
						{t("JOURNEY_MAP_NAME")}
						<Style.Mandatory className="ms-2">{"(" + t("MANDATORY") + ")"}</Style.Mandatory>
					</Label>
					<Input variant="default" className="mt-3" value={name} name="name" onChange={update} innerRef={ref} autoComplete="off" />
					<Label className="mt-3">{t("ASPX_9")}</Label>
					<Style.TextArea rows={5} value={description} name="description" onChange={update} placeholder={t("MAP_CREATION_DESCRIPTION_PLACEHOLDER_STEP2")} autoResize />
					<div>
						<Label className="mt-3 ">{t("JS_77")}</Label>
						<DisplayState size="lg" object={props?.newMap} attKey="mapType" updateFunction={updateState} />
					</div>
				</Style.NameContainer>
				<div className="w-50 d-flex justify-content-center align-items-end">
					<Style.Potion />
				</div>
			</div>
			<div className="w-100 d-flex justify-content-center m-3">
				<GenericButton variant="primary" className="align-self-center" onClick={props.nextStep} disabled={name.isEmptyString()} icon={"ARROW_RIGHT"} iconPosition="after" iconClassName="ms-1">
					{t("CONTINUE")}
				</GenericButton>
			</div>
		</Style.StepWrapper>
	);
}
