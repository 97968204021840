import { createPersona, updateLockPersonaTemplate, updatePersonaName, updateStatusPersonaTemplate } from "@redux/index";
import { GetActiveCJM, isUserAllowed } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardBody, NavLink } from "reactstrap";
import { Spinner } from "shared/components/spinners/Spinner";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { IframeModal } from "shared/modals/index";
import GenericSwitch, { GenericButton } from "shared/ui-basics/buttons";
import { IconDispatcher } from "shared/ui-basics/index";
import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";
import { base } from "../../../../..";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import EditTitle from "../../../../../shared/components/EditableText";
import { DropDownPosition } from "../../../../grid-table/columns-components/Styles";
import BaseActions from "../../actions/BaseActions";



function PersonaProfileCard({ personaId, selectPersonas, newMap, isTemplateView }) {
	const { t } = useTranslation();
	const pathname = useLocation().pathname;
	const history = useHistory();
	const [showModal] = useModal(IframeModal);

	const all_maps = useSelector((state) => state.maps.all_maps);
	const userType = useSelector((state) => state.auth.userInfo.UserType);
	const persona = useSelector((state) => state.personas.all_personas.find((p) => p.personaId === personaId));
	const { name,statusCode,  isLocked, templateStatus, sentimentLevel, linkedCjms, associatedContactsCount, occupation } = persona;

	const [rename, setRename] = React.useState("");
	const [personaName, setName] = useState(name);
	const [disableButton, setDisableButton] = useState(false);
	const linkedJourneyMapActive = GetActiveCJM(linkedCjms, all_maps);
	const isActionsAllowed = isUserAllowed(userType, "actions-dropdown") && selectPersonas === undefined && !isTemplateView;
	const MAX_OCCUPATION_LENGTH = 24;
	const MAX_PERSONA_LENGTH = 14;
	const emotionLevel = Math.round(sentimentLevel);
	const disableRedirect = isTemplateView || statusCode===StatusCode.Archived ||statusCode===StatusCode.Trashed || selectPersonas !== undefined;
	const query = new URLSearchParams(window.location.search.toLowerCase());
	const isTemplate = query.get("istemplate") === "true";

	useEffect(() => {
		setName(name);
	}, [persona.name]);

	const selectPersona = () => {
		if (selectPersonas !== undefined) selectPersonas(personaId);
	};
	const callbacks = {
		onSuccess: (persona) => {
			setDisableButton(false);
			history.push(`/EditPersona?personaIdStr=${persona.personaId}`);
		},
		onFailed: () => {
			setDisableButton(false);
		},
	};

	const preview = (persona) => {
		showModal({
			title: "PREVIEW",
			url: `${base}EditPersona?personaIdStr=${persona.personaId}&ispreview=true`,
			add: () => createPersona({ fromTemplateIdStr: persona.personaId }, null, callbacks.onSuccess, null, callbacks.onFailed),
			objectId: persona.personaId,
		});
	};

	const displayJourneyText = () => {
		if (linkedJourneyMapActive?.length === 1) return t("JOURNEY");
		else return t("JOURNEYS");
	};

	const displayContactText = () => {
		if (associatedContactsCount === 1) return t("CONTACT");
		else return t("CONTACTS");
	};

	return (
		<ProfileCard selected={personaId === newMap?.personaId} selectable={`${selectPersonas !== undefined && !isTemplateView}`} onClick={selectPersona}>
			<CardTop>
				{selectPersonas !== undefined && personaId === newMap?.personaId && <BubbleChecked>{IconDispatcher("CHECKED")}</BubbleChecked>}
				<Redirect tag={disableRedirect ? "div" : Link} to={`/EditPersona?personaIdStr=${personaId}&origin=1`}>
					<PersonaBubble persona={persona} size={"120"} border={true} className="mt-3" />
					{emotionLevel > 0 && (
						<EmotionPosition>
							<EmotionLevel variant="smiley" size="xs" level={emotionLevel} showTooltip={false} />
						</EmotionPosition>
					)}
				</Redirect>
				{isActionsAllowed && CreateActionsDropdown({ actions: BaseActions({ persona, setRename }), right: "-45", top: "0" })}
			</CardTop>
			<CardBody className="p-0 ">
				{rename !== personaId && (
					<Redirect tag={disableRedirect ? "div" : Link} to={`/EditPersona?personaIdStr=${personaId}&origin=1`}>
						<CustomTooltip id={personaId} text={personaName} disabled={!personaName || personaName.length <= MAX_PERSONA_LENGTH}>
							<Title selectable={!isTemplateView ? "true" : "false"}>{personaName}</Title>
						</CustomTooltip>
					</Redirect>
				)}
				{rename === personaId && <EditTitle id={personaId} obj_id={personaId} obj_name={personaName} updateName={updatePersonaName} setName={setName} setRename={setRename} />}
				<CustomTooltip disabled={!occupation || occupation?.length < MAX_OCCUPATION_LENGTH} text={occupation}>
					<Occupation>{occupation}</Occupation>
				</CustomTooltip>
			</CardBody>

			<CardBottom>
				{!persona.isTemplate && (
					<>
						<div id="cjm">{linkedJourneyMapActive?.length + " " + displayJourneyText()}</div>
						<div id="contacts">{associatedContactsCount + " " + displayContactText()}</div>
					</>
				)}
				{pathname === "/persona-creation" && (
					<Actions className=" d-flex justify-content-end py-3 mt-2">
						<GenericButton className={"me-2 no-border"} variant="primary-outline" size="s" onClick={preview ? () => preview(persona) : undefined} disabled={disableButton}>
							{t("PREVIEW")}
						</GenericButton>
						<GenericButton
							variant="primary-outline"
							size="s"
							onClick={() => {
								setDisableButton(true);
								createPersona({ item: { fromTemplateIdStr: personaId ,isTemplate}, ...callbacks });
							}}
							disabled={disableButton}>
							{t("USE")}
						</GenericButton>
					</Actions>
				)}
				{pathname === "/templates" && (
					<TemplateActions className="p-2">
						<GenericSwitch
							isChecked={isLocked}
							handleToggle={() => updateLockPersonaTemplate({modifiedPersona:{ personaId, isLocked: !isLocked },modifiedAttribNames: ["isLocked"]})}
							text={"JS_169"}
							tooltipText={"PERSONA_TEMPLATE_TOOLTIP_LOCK"}
							justifyContent="space-between"
							gap={"20"}
							isdisabled={statusCode===StatusCode.Archived ||statusCode===StatusCode.Trashed}
						/>
						<GenericSwitch
							isChecked={templateStatus === 100000001}
							handleToggle={() => updateStatusPersonaTemplate({modifiedPersona:{ personaId, templateStatus: templateStatus === 100000001 ? 100000000 : 100000001 }, modifiedAttribNames:["templateStatus"]})}
							text="JS_170"
							tooltipText="PERSONA_TEMPLATE_TOOLTIP_PUBLISH"
							justifyContent="space-between"
							gap="20"
							isdisabled={statusCode===StatusCode.Archived ||statusCode===StatusCode.Trashed}
						/>
					</TemplateActions>
				)}
				{disableButton && <Spinner />}
			</CardBottom>
		</ProfileCard>
	);
}

export const ProfileCard = styled(Card)`
	width: 220px;
	height: 280px;
	background: #ffffff;
	border-radius: 4px;
	position: relative;
	${(props) =>
		props.selectable === "true" &&
		css`
			cursor: pointer;
		`}
	${(props) =>
		props.selected === true &&
		props.selectable === "true" &&
		css`
			border: 2px solid #f96209;
		`}

    ${DropDownPosition} {
		left: -25px;
		top: 0px;
	}
	:hover {
		box-shadow: rgba(33, 43, 54, 0.1) 0px 1px 5px;
	}
	:hover ${DropDownPosition} {
		display: flex;
	}
`;

const CardTop = styled.div`
	margin-top: 5px;
	display: flex;
	justify-content: center;
	position: relative;
	#icon {
		position: absolute;
		bottom: 5px;
		right: 45px;
		background: #ffffff;
		border-radius: 50%;
		width: fit-content;
	}
`;

const Title = styled.div`
	border: none;
	padding: 5px;
	color: #333333;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	width: 100%;
	text-align: center;
	margin: 5px 0px;
	max-width: 219px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0px 20px;
	cursor: ${(props) => (props.selectable === "true" ? "pointer" : "auto")};
`;

const Redirect = styled(NavLink)`
	text-decoration: none;
`;

const Occupation = styled.div`
	max-width: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	min-height: 20px;
	line-height: 19px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #333333;
	width: 100%;
	padding: 0px 20px;
`;

const CardBottom = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
	#cjm {
		background: #d6d4e1;
		align-self: center;
		padding: 4px 10px;
		color: #3f3d56;
		border-radius: 4px;
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
	}
	#contacts {
		background: #d2eefb;
		align-self: center;
		padding: 4px 10px;
		color: #20a9e9;
		border-radius: 4px;
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
	}
`;

const BubbleChecked = styled.div`
	width: 30px;
	height: 30px;
	position: absolute;
	background-color: #fdc09d;
	border-radius: 50%;
	left: 2px;
	color: #f96209;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
`;

const Actions = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 90%;
	border-top: 1px solid #c4c4c4;
`;

const TemplateActions = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-top: 1px solid #c4c4c4;
`;

export const EmotionPosition = styled.div`
	position: absolute;
	bottom: 0px;
	right: 50px;
`;

export default PersonaProfileCard;
