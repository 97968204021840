import { linkGroups, unlinkGroup } from "@redux/index";
import { useSelector } from "react-redux";
import PopoverEditMode from "shared/popovers/PopoverEditMode";
import EditableItem from "shared/ui-basics/ui-components/EditableItem";

export default function Group({ object, setObject, recordType, size = 'xs', disabled, mode, scroll }) {
	const all_groups = useSelector((state) => state.libraries.group?.map(t => t.id));
	const elementIds = object?.groups?.filter(a => all_groups?.includes(a)) || [];

	const linkGroup = (id) => {
		linkGroups({ groupIds: [id], recordIds: [object.id], recordType: recordType });
		setObject({ ...object, groups: [...object?.groups || [], id] });
	};

	const unlinkGroups = (groupId) => {
		unlinkGroup({ groupId, recordId: object.id, recordType: recordType });
		setObject({ ...object, groups: object.groups.filter((id) => id !== groupId) });
	};

	const props = {
		popoverParams: {
			addLabel: "ADD_GROUP",
			addFunction: ({ id }) => linkGroup(id),
			type: "group",
			disabled,
			relatedItems: object?.groups
		},
		RenderElement: (id, isEdit) => {
			return (
				<EditableItem
					key={id}
					objectId={id}
					unlink={unlinkGroups}
					mode={mode ? mode : !disabled && isEdit && "unlinkable"}
					itemSelectorPath="libraries.group"
					context="group"
					title="GROUPS"
					size={size} />
			);
		}

	}
	return (
		<PopoverEditMode elementIds={elementIds}  {...props} />
	);
}
