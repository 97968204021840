import "bootstrap/dist/css/bootstrap.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";

import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "shared/components/ErrorBoundary";
import { Spinner } from "shared/components/spinners/Spinner";
import App from "./App";
import { ConfigureStore } from "./redux/store";
import { initializeAppInsights } from "./utils/appInsights";
import "./utils/i18n";
import { existingRoutes, publicRoutes } from "shared/Routes/routes";

export const base = getBasename(window.location.pathname);
const history = createBrowserHistory({ basename:base });
urlValidationAndRedirect(window.location.pathname, history,base);
export const store = ConfigureStore(history, window.initialReduxState);
export const currentWorkspaceId = isGUID(base.split("/")[2]) ? base.split("/")[2] : null;
initializeAppInsights();

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<BrowserRouter basename={base}>
				<Suspense fallback={<Spinner />}>
					<ErrorBoundary>
						<App history={history} />
					</ErrorBoundary>
				</Suspense>
			</BrowserRouter>
		</ConnectedRouter>
	</Provider>,
	document.getElementById("root")
);

function isGUID(str) {
	const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
	return guidRegex.test(str);
}
function urlValidationAndRedirect(path,history,baseurl) {
    const paths = path.split("/").filter(Boolean);
	const basename = baseurl?.split("/").filter(Boolean);
	const token = Cookies.get('Authorization-cemantica');



    if (paths.length === 0) return;

    const orgName = paths[0];
    const secondSegment = paths[1];
    const thirdSegment = paths[2];
    // Check for /publicroutes
    if (publicRoutes.includes(orgName)) return;

	if(paths.length === 1 && token) {
		return history.push(`/`);
	}
	if(paths.length === 1 && !token) {
		return
	}

    // Check for /orgname/login or /orgname/authenticate
    if (secondSegment === 'login' || secondSegment === 'authenticate') return;

    // Check for /orgname/guid/existingRoutes or /orgname/guid/
    if (isGUID(secondSegment)) {
        if (paths.length === 2 || (paths.length === 3 && existingRoutes.includes(thirdSegment))) {
            return;

        }
    }
	if(basename?.length > 1) {
		if(isGUID(basename[1]) && !basename[2]) {
			return history.push("/home");
		}
	}

    // If none of the valid patterns match, redirect to /invalid-url
    return history.push("/invalid-url");
}

function getBasename  (path) {
	const paths = path.split("/").filter(Boolean);
	const orgName = paths[0];
	const token = Cookies.get('Authorization-cemantica');

	if (paths.length === 0 && token) {
		const userInfo = jwt_decode(token);
		const workspaceIdDefault = userInfo?.DefaultWorkspaceId;
		const envName = userInfo?.EnvName;
		return `/${envName}/${workspaceIdDefault}/`;
	}

	// Handle single-segment path
	if (paths.length === 1) {
		if (publicRoutes.includes(orgName)) return `/`;
		if(token) {
			const workspaceIdDefault = jwt_decode(token)?.DefaultWorkspaceId;
			const envName = jwt_decode(token)?.EnvName;
			if(envName === orgName) return `/${envName}/${workspaceIdDefault}/`; // when switching orgname
		}
		return `/${orgName}/`;
	}

	// Handle two-segment path
	if (paths.length === 2) {
		const route = paths[1];

		if (existingRoutes.includes(route) && token) {
			const workspaceIdDefault = jwt_decode(token)?.DefaultWorkspaceId;
			if (['authenticate', 'login'].includes(route)) return `/${orgName}/`; // When switching accounts
			return `/${orgName}/${workspaceIdDefault}/`;
		}

		if ([...existingRoutes, ...publicRoutes].includes(route)) return `/${orgName}/`;
	}

	// Handle three-segment path or more
	if (paths.length >= 2) {
		const workspaceId = paths[1];

		// Check if orgName is not empty and workspaceId is a GUID
		if (orgName && isGUID(workspaceId)) {
			return `/${orgName}/${workspaceId}/`;
		}
	}

	// Default return for invalid paths
	return "/";
}
