import { ActionConfirmModal } from "shared/modals/index";
import { FlexAlignStart, FlexBetweenAlignCenter, FlexColumnAlignCenter } from "shared/ui-basics/index";
import { extractTextFromHtml } from "@utils/helpers";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";
import InsightType from "../../../ui-basics/ui-components/InsightType";

const getstatusType = (entityType) => {
	switch (entityType) {
		case "cem_cxsolution":
			return "solutions";
		case "cem_cxopportunity":
			return "opportunity";
		case "cem_cxactiontask":
			return "cxActions";
		case "cem_project":
			return "project";
		default:
			return "";
	}
};
export const DefaultPickListSelectCard = ({ obj, link, label = "Default name", idAttribute, titleAttribute, showConfirmAssociatedModal, refOver, disabled, entityType }) => {
	const all_status = useSelector((state) => state.libraries?.status);
	const type = getstatusType(entityType);
	const status = all_status && all_status[type]?.find((st) => st.id === obj?.statusId);
	const [showConfirmAssociatedObjects] = useModal(ActionConfirmModal, {
		bodyContent: "PROJECT_LINK_ASSOCIATED_OBJECTS",
		Action: (linkRelated) => link(obj?.[idAttribute], linkRelated),
		actionBtnText: "LINK",
		secondBtnText: "NO",
		beforeClose: () => link(obj?.[idAttribute], false),
	});
	const handleClick = () => {
		refOver.current.hide();
		if (showConfirmAssociatedModal) showConfirmAssociatedObjects();
		else link(obj?.[idAttribute]);
	};
	return (
		<Item key={obj?.[idAttribute]} id={obj?.[idAttribute]} onClick={handleClick} className="ms-3" disabled={disabled}>
			<FlexBetweenAlignCenter>
				<FlexColumnAlignCenter>
					<div className="ellipsis obj-name-pick">{obj?.[titleAttribute]?.defaultPlaceholder(label)}</div>
					<div className="ellipsis obj-desc ">{extractTextFromHtml(obj?.description)}</div>
				</FlexColumnAlignCenter>
				<FlexAlignStart>
					{entityType === "cem_insight" && <InsightType typeId={obj.typeId} maxWidth={"100px"} />}
					{status && <Status colorcode={status.colorCode}>{status?.name}</Status>}
				</FlexAlignStart>
			</FlexBetweenAlignCenter>
		</Item>
	);
};

const Item = styled.div`
	padding: 0.3rem 1rem;
	cursor: pointer;
	max-height: 50px;
	.obj-name-pick {
		color: #333;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		align-self: flex-start;
		max-width: 350px;
	}
	.obj-desc {
		color: #605f60;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		align-self: flex-start;
		max-width: 350px;
	}
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}

	:hover {
		background-color: #efefef;
		border-radius: 4px;
	}
`;

const Status = styled.div`
	border-radius: 4px;
	text-align: start;
	font-weight: 500;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${(props) => props.colorcode};
	/* color:#5c5858; */
`;
