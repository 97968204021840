import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import Draggable from 'react-draggable';

export default function ZoomImageModal({ isOpen, onClose, image }) {
  const [zoomLevel, setZoomLevel] = useState(1);
  const handleZoomChange = (event) => {
    setZoomLevel(event.target.value);
  };
  const handlePropagation = (event) => {
    event.stopPropagation();
  };
  const handlePreventDefault = (event) => {
    event.preventDefault();
  }

  return (
    <ModalContainer fade={false} isOpen={isOpen} toggle={onClose} size='xl' centered={true}>
      <ModalHeader toggle={onClose} />
      <Body>
        <ImageContainer zoomLevel={zoomLevel} onWheel={handlePropagation}>
          <Draggable onStart={handlePropagation}>
            <img src={image} alt="zoom" className='img-fluid' onDrag={handlePropagation} onDragStart={handlePreventDefault} />
          </Draggable>
        </ImageContainer>
        <ZoomSlider
          type="range"
          min="1"
          max="3"
          step="0.1"
          value={zoomLevel}
          onChange={handleZoomChange}
        />
      </Body>
    </ModalContainer>
  )
}

const Body = styled(ModalBody)`
  padding: 0;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(${(props) => props.zoomLevel});
  z-index: 1; /* Add this line to set the z-index */
  cursor: move;

  img {
    /* width: 100%;
    height: 100%; */
    /* object-fit: none; */
  }
`;

const ZoomSlider = styled.input`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 10px;
  background-color: #d3d3d3;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: #555;
    border-radius: 50%;
    cursor: pointer;
  }
`;
const ModalContainer = styled(Modal)`
max-width: 1300px;
`;
