import { EMPTY_GUID, constructUrl } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { genericService } from "shared/GenericService";

let currentRequest = null;
let requestId = EMPTY_GUID;
let intervalId = null;

const InitActionCreator = ({ baseUrl, method }) => {
  return ({ type, endPoint, data, responseType, signal, params, meta }) => ({
    type,
    request: {
      url: baseUrl + endPoint,
      method,
      signal,
      data,
      responseType,
    },
    meta,
    params,
  });
};
const createGetAction = InitActionCreator({ baseUrl: "api/query/", method: "GET" });
export const getRequestId = ({ query, advancedSearch, signal, ...props }) => genericService({
  ...props, action: createGetAction({
    type: 'GET_REQUEST_ID', endPoint: constructUrl("getRequestId", {
      query:btoa(query.toLowerCase()),
      advancedSearch
    }),
    signal,
  })
});
export const getResult = ({ requestId, ...props }) => genericService({
  ...props, action: createGetAction({
    type: 'GET_REQUEST_ID', endPoint: constructUrl("checkTaskStatus", {
      requestId: requestId,
    }),
  })
});

export const getResultService = async (query, advancedSearch, callbackData) => {

  if (currentRequest) {
    currentRequest.abort(); // Cancel previous request
  }
  currentRequest = new AbortController();
  const onSuccess = (data) => {
    if (intervalId !== null)
      clearInterval(intervalId); // Cleanup on component unmount
    requestId = data;

    intervalId = setInterval(async () => {

      getResult({ requestId, onSuccess: (data) => {
          if (data) {
            callbackData(JSON.parse(data?.result));
            clearInterval(intervalId);
          }
        }
      })
    }, 5000);
  }

  getRequestId({ query, advancedSearch, signal: currentRequest.signal, onSuccess });

};
export const useAdvancedSearch = (query, advancedSearch, callbackData, debouncedSearchTerm) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchTerm && query && query !== "") {
        if (currentRequest) {
          currentRequest.abort(); // Cancel previous request
        }
        currentRequest = new AbortController();
        const onSuccess = (data) => {
         const requestId  = data;
          if (intervalId !== null) {
            clearInterval(intervalId); // Cleanup on component unmount
          }

          intervalId = setInterval(async () => {
            getResult({
              requestId, onSuccess: (data) => {
                if (data) {
                  callbackData(data?.result !== "" && JSON.parse(data?.result));
                  clearInterval(intervalId);
                  setLoading(false);
                }

              }
            })
          }, 5000);

        }
        getRequestId({ query, advancedSearch, signal: currentRequest.signal, onSuccess })

      }
    };

    fetchData();

    // Cleanup on component unmount
    return () => {
      if (currentRequest) {
        currentRequest.abort();
      }
      if (intervalId !== null) {
        clearInterval(intervalId);
        setLoading(false);
      }
    };
  }, [debouncedSearchTerm, dispatch]);

  return [loading];
};
