import { createStakeholderRole, deleteStakeholderRole, getAllRegisteredUsers, getAllStakeholderRoles, updateStakeholderRole, upsertStakeholder } from "@redux/index";
import { UserType } from "@utils/optionsSets/OptionSets";
import _ from "lodash";
import { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from "reactstrap";
import { useAsyncs, useAutoFocus } from "shared/hooks/index";
import { Colors, GenericButton } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import ManageGenericItems from "../ManageGenericItems";

const StakeHolderForm = ({ recId, entityName, selectedData, actionType, stakeHoldersPath, panelRef, roleName, onSuccess }) => {
	// If a stakeholder role is deleted, all the related stakeholders are also deleted
	// A stakeholder must have a role (a default one is provided)

	const { t } = useTranslation();
	const ref = useAutoFocus();

	useAsyncs({
		asyncFns: [{ asyncFn: getAllStakeholderRoles }, { asyncFn: getAllRegisteredUsers }],
	});

	const stakeholderRoles = useSelector((state) => state.libraries?.stakeholderRoles)?.sortAsc("name");
	const authorizedUsers = useSelector(({ settings }) => {
		const authorizedUsers = settings.authorizedUsers;
		const activeUsersEmails = settings.users?.map((user) => (user.isActive ? user.loginName : null))?.filter((user) => user !== null);
		return authorizedUsers.filter((user) => activeUsersEmails.includes(user.domainName));
	}, shallowEqual);
	const currentStakeholders = useSelector((state) => _.get(state, stakeHoldersPath));
	const loggerUserInfo = useSelector((state) => state.auth.userInfo);

	const [showManageRole, setShowManageRole] = useState(false);
	const [nameDropdownStatus, setNameDropdownStatus] = useState(false);
	const [roleDropdownStatus, setRoleDropdownStatus] = useState(false);

	const isUserAlreadyUsed = (user) => (currentStakeholders?.find((stakeholder) => stakeholder.userId === user.id) ? true : false);
	const filteredAuthUsers = authorizedUsers.filter((user) => !isUserAlreadyUsed(user));

	const loggerUserType = Number(loggerUserInfo.UserType);
	const isUserAllowed = loggerUserType === UserType.companyAdmin || loggerUserType === UserType.admin;

	const isViewer = roleName === "viewer";
	const isShowManageAllowed = !showManageRole && !isViewer && isUserAllowed;

	const [stakeholder, setStakeholder] = useState(selectedData);

	useEffect(() => {
		setStakeholder({ ...stakeholder, roleName: stakeholderRoles.find((role) => role.id === stakeholder?.roleId)?.name });
	}, [stakeholderRoles]);

	const handleUserSelection = (user) => {
		setStakeholder({ ...stakeholder, userId: user.id, fullName: user.fullName });
	};

	const handleRoleSelection = (role) => {
		setStakeholder({ ...stakeholder, roleId: role.id, roleName: role.name });
	};

	const closeForm = (e) => panelRef.current.toggle(e);

	const handleSave = (e) => {
		if (!stakeholder?.fullName) return toast.info(t("WARNING_SELECT_NAME"));
		if (!stakeholder?.roleName) return toast.info(t("WARNING_SELECT_ROLE"));
		upsertStakeholder({
			stakeholder,
			recId,
			entityName,
			actionType: "UPSERT_" + actionType,
			onSuccess: onSuccess,
		});
		closeForm(e);
	};

	return (
		<FormContainer onClick={(e) => e.stopPropagation()}>
			<Body>
				{!showManageRole && (
					<Form>
						<div>
							<Labels className="mb-3">{t("ASPX_136")}</Labels>
							{!selectedData && (
								<Dropdown isOpen={nameDropdownStatus} toggle={() => setNameDropdownStatus(!nameDropdownStatus)} autoFocus>
									<DropdownToggle caret innerRef={ref}>
										<Name>{stakeholder?.fullName ? stakeholder.fullName : t("SELECT_USER")}</Name>
									</DropdownToggle>
									<Menu>
										{filteredAuthUsers.length > 0 ? (
											filteredAuthUsers?.map((user, index) => (
												<DropdownItem key={index} onClick={() => handleUserSelection(user)}>
													{user?.fullName}
												</DropdownItem>
											))
										) : (
											<DropdownItem disabled>{t("NO_USERS_AVAILABLE")}</DropdownItem>
										)}
									</Menu>
								</Dropdown>
							)}
							{selectedData && <NameBox className="p-2">{stakeholder?.fullName}</NameBox>}
						</div>
						<div>
							<Wrapper className="mb-3">
								<Labels>{t("ROLE")}</Labels>
								{isShowManageAllowed && (
									<GenericButton className="py-2" variant="light-link" size="s" icon={"SETTINGS"} onClick={() => setShowManageRole(!showManageRole)} iconClassName="me-1">
										{t("MANAGE_ROLES")}
									</GenericButton>
								)}
							</Wrapper>
							{!isViewer && (
								<Dropdown isOpen={roleDropdownStatus} toggle={() => setRoleDropdownStatus(!roleDropdownStatus)}>
									<DropdownToggle caret>
										<Name>{stakeholder?.roleName ? stakeholder?.roleName : t("SELECT_ROLE")}</Name>
									</DropdownToggle>
									<Menu>
										{stakeholderRoles?.map((role, index) => (
											<DropdownItem key={index} onClick={() => handleRoleSelection(role)}>
												{role?.name}
											</DropdownItem>
										))}
									</Menu>
								</Dropdown>
							)}
							{isViewer && <NameBox className="p-2">{stakeholder?.roleName}</NameBox>}
						</div>
					</Form>
				)}
				{showManageRole && (
					<ManageGenericItems
						title="MANAGE_ROLES"
						allItems={stakeholderRoles}
						createItem={(name, onSuccess) => createStakeholderRole({ name, onSuccess })}
						updateItem={(roleId, newName) => updateStakeholderRole({ roleId, newName })}
						deleteItem={(roleId) => deleteStakeholderRole({ roleId })}
						maxHeight={220}
					/>
				)}
			</Body>
			<Footer className="p-2">
				{showManageRole && (
					<GenericButton className="py-2" variant="light-link" size="s" icon={"ANGLE-LEFT"} iconClassName="me-1" onClick={() => setShowManageRole(!showManageRole)}>
						{t("BACK_TO_STAKEHOLDER")}
					</GenericButton>
				)}
				{!showManageRole && (
					<FooterButtons>
						<GenericButton variant="outline-secondary" className="me-2 align-self-end" onClick={closeForm}>
							{t("ASPX_86")}
						</GenericButton>
						{!isViewer && (
							<GenericButton variant="primary" className="me-2 align-self-end" onClick={handleSave}>
								{t("ASPX_12")}
							</GenericButton>
						)}
					</FooterButtons>
				)}
			</Footer>
		</FormContainer>
	);
};

export default StakeHolderForm;

const FormContainer = styled.div`
	width: 462px;
	height: 350px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Body = styled.div`
	width: 100%;
	height: 100%;
`;

const Form = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	.dropdown-toggle {
		height: 40px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border: 1px solid #ced4da !important;
		:focus {
			background-color: transparent;
			border: 1px solid ${Colors.primaryText} !important;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		:disabled {
			cursor: not-allowed !important;
		}
	}
`;

const Labels = styled(Label)`
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: ${Colors.label};
`;

const Footer = styled.div``;

const FooterButtons = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const NameBox = styled.div`
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	cursor: not-allowed;
`;

const Menu = styled(DropdownMenu)`
	width: 100%;
	::-webkit-scrollbar-track {
		background: #ffffff !important;
	}
	overflow: scroll;
	max-height: 200px;
`;

const Name = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #433d56;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
