import { error, success } from "@redux-requests/core";
import { EMPTY_GUID } from "@utils/helpers";
import * as Helpers from "./helpers";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { currentWorkspaceId } from "index";

const initialState = {
	all_workspaces: [],
	loading: false,
};

const WorkspaceReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const requestParams = action?.meta?.requestAction?.params;
	const responseData = action?.response?.data;

	switch (action.type) {
		case "GET_ALL_WORKSPACES":
			return Helpers.updateState({ state, otherState: { loading: state.all_workspaces.length === 0 } });
		case error("GET_ALL_WORKSPACES"):
			return Helpers.updateState({ state, otherState: { loading: false } });
		case success("GET_ALL_WORKSPACES"): {
			const token = Cookies.get("Authorization-cemantica");
			let currentUserId = EMPTY_GUID;
			if (token) currentUserId = jwt_decode(token)?.UserId;
			const currentWorkspace = responseData?.find((ws) => ws?.id === currentWorkspaceId);
			const currentWorkspaceRole = currentWorkspace?.authorizedUsers?.find((u) => u?.id === currentUserId && u?.roleId !== EMPTY_GUID)?.roleName;
			return Helpers.updateState({ state, otherState: { all_workspaces: responseData, currentWorkspaceRole, loading: false } });
		}
		case success("GET_WORKSPACE"): {
			const data = responseData;
			const all_workspaces = state.all_workspaces?.map((workspace) => {
				if (workspace.id === requestParams?.workspaceId) {
					workspace = { ...workspace, ...data };
				}
				return workspace;
			});

			return Helpers.updateState({ state, otherState: { all_workspaces: all_workspaces } });
		}
		case success("CREATE_WORKSPACE"): {
			const isDefault = responseData?.isDefault;
			let workspaces = state.all_workspaces;
			if (isDefault) {
				workspaces = state.all_workspaces?.map((workspace) => {
					if (workspace?.isDefault) {
						workspace.isDefault = false;
					}
					return workspace;
				});
			}
			return Helpers.updateState({ state, otherState: { all_workspaces: [...workspaces, responseData], loading: false } });
		}
		case success("COPY_WORKSPACE"): {
			return Helpers.updateState({ state, otherState: { all_workspaces: [...state.all_workspaces, responseData], loading: false } });
		}
		case success("UPDATE_WORKSPACE"): {
			const workspaces = state.all_workspaces?.map((workspace) => {
				if (requestData?.modifiedAttribNames?.length === 1 && requestData?.modifiedAttribNames.includes("isDefault")) {
					workspace.isDefault = false;
					if (workspace.id === requestData?.modifiedWorkspace.id) {
						workspace.isDefault = true;
					}
				} else if (workspace.id === requestData?.modifiedWorkspace.id) {
					workspace = responseData;
				}
				return workspace;
			});
			return Helpers.updateState({ state, otherState: { all_workspaces: workspaces } });
		}
		case success("SHARE_MANY_WORKSPACES"): {
			const userIds = requestData?.userIds;
			const workspaceIds = requestData?.workspaceIds;
			const authUsers = requestParams.authorizedUsers;
			const updatedWorkspaces = state.all_workspaces?.map((ws) => {
				userIds.forEach((userId) => {
					if (workspaceIds?.includes(ws.id)) {
						if (!ws.authorizedUsers.find((au) => au.id === userId)) {
							const authUser = authUsers.find((au) => au.id === userId);
							ws.authorizedUsers.push(authUser);
						}
					}
				});
				return ws;
			});
			return Helpers.updateState({ state, otherState: { all_workspaces: updatedWorkspaces } });
		}

		case success("DELETE_WORKSPACE"): {
			const updatedWorkspaces = state.all_workspaces?.filter((workspace) => workspace.id !== requestData.workspaceId);
			return Helpers.updateState({ state, otherState: { all_workspaces: updatedWorkspaces } });
		}

		default:
			return state;
	}
};
export default WorkspaceReducer;
