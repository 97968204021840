import { GenericButton } from "shared/ui-basics/buttons";
import { Label } from "shared/ui-basics/index";
import { ConnectorSource } from "@utils/optionsSets/OptionSets";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { WrapperFooter, dataFrequency } from "./FormConnector";
import { newConnector } from "./IntegrationModal";
import React from "react";
import { getKeyByValue } from "@utils/helpers";
export const ListConnector = ({ setSelectConnector, source, ...props }) => {

    const { t } = useTranslation();
    const connectors = useSelector((state) => state.settings.connector)?.filter(connector => connector.source === source) || [];
	const name =t(getKeyByValue(ConnectorSource,source).toUpperCase())+" "+t("INTEGRATION");

    const emptyForm = () => {
        setSelectConnector({...newConnector,source,name});
        props.nextStep()
    }
    return <WrapperBody className="d-flex flex-column justify-content-between">
        <div className="p-4">
            <WrapperLabelDiv>
                <Label size={14} className="mb-3">{t("EXISTING_CONNECTION")}</Label>
            </WrapperLabelDiv>
            <div>
                {connectors?.map(connector => <ListConnectorCard key={connector?.id} setSelectConnector={setSelectConnector} connector={connector} {...props} />)}
            </div>
        </div>
        <WrapperFooter className=" p-4">
            <div></div>
            <GenericButton variant="primary" onClick={emptyForm}  >
                {t('NEW_INTEGRATION')}
            </GenericButton>
        </WrapperFooter>

    </WrapperBody>
}
const ListConnectorCard = ({ connector, setSelectConnector, ...props }) => {
    const frequency = dataFrequency?.find(x => x.id === (connector?.retrieveDataFrequency || '100000000').toString())?.value
    const nextStep = () => {
        setSelectConnector({ ...connector, retrieveDataFrequency: connector?.retrieveDataFrequency?.toString() });
        props.nextStep();
    }
    return <WrapperDiv>
        <Row onClick={nextStep}>
            <Col>
                {connector?.name + ", " + frequency}
            </Col>
        </Row>
    </WrapperDiv>
}
const WrapperLabelDiv = styled.div`
border-bottom:1px solid #BFBFBF;
`
const WrapperDiv = styled.div`
display:flex;
align-items:center;
cursor: pointer;
height:60px;
border-bottom :1px solid #BFBFBF;
`
const WrapperBody = styled.div`

    height:532px !important;
`
