import React from "react";
import BaseActions from "./BaseActions";
import { reorderActions } from "@utils/helpers";
import styled from "styled-components/macro";
import { GenericButton } from "shared/ui-basics/index";


export default function HeaderActions({ opportunity, handleRedirect }) {
	const createActions = () => {
		const baseActions = BaseActions({ opportunity, handleRedirect });

		return {
			inDropdownActions: [],
			outsideDropdownActions: reorderActions({
				actions: baseActions,
				order: ["copy", "archive", "delete"],
			}),
		};
	};

	const actionsData = createActions();

	return (
		<HeaderActionsContainer>
			{actionsData?.outsideDropdownActions?.map((action, index) => <GenericButton key={index} variant="light-link" tooltip tooltipText={action?.text} icon={action?.icon} onClick={action?.handleClick} />)}
		</HeaderActionsContainer>
	);
}

const HeaderActionsContainer = styled.div`
	display: flex;
	align-items: center;
`;
