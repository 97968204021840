import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { IconDispatcher } from "../Icons";
import { SearchBar } from "../ui-basics/input";
import styled from "styled-components";

export function GenericDropdown(props) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [globalFilterValue, setGlobalFilterValue] = useState("");

	const result = props.options?.searchFilter(globalFilterValue, "value")||[];
	const selectedOption = props.options?.find((option) => option.id?.toString() === props.value?.toString());
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const handleChange = (e) => setGlobalFilterValue(e.target.value);
	const Search = SearchBar({ globalFilterValue, handleChange, size: "small", label: `SEARCH` });
	return (
		<GenericDropdownWrapper
			validated={props?.validated ? "true" : "false"}
			isborder={props?.border ? "true" : "false"}
			isOpen={dropdownOpen}
			toggle={toggle}
			disabled={props?.disabled}
			className={props.className}
			invalid={props?.invalid}>
			{props?.customToggle ? (
				props?.customToggle
			) : (
				<DropdownToggle className={`w-100 text-start d-flex justify-content-between ${props.disabled && "c-default"}`}>
					{selectedOption?.value? <SelectedOption className="text-truncate" color={selectedOption?.color}>{selectedOption.value}</SelectedOption> : <LabelPlaceHolder>{props?.placeholder}</LabelPlaceHolder>}
					{props.icon && !props?.disabled && <span className={`float-end ${props.hoverCaret ? 'caret' : ''} ms-1`}>{IconDispatcher(dropdownOpen ? "ANGLE-UP" : "ANGLE-DOWN")}</span>}
				</DropdownToggle>
			)}
			{result && (
				<DropdownMenuWrapper minwidth={props?.minwidth} className={`${props.disabled && "prevent-events"}`}>
					{props.search && <div className="p-2 mt-3 position-relative">{ Search}</div>}
					{result?.map((option, index) => (
						<Item key={index} id={option.id} value={option.id} name={props.name} onClick={props.onClick} className="text-truncate" color={option.color}>
							{props.customItem ? props.customItem(option) : option.value}
						</Item>
					))}
				</DropdownMenuWrapper>
			)}
		</GenericDropdownWrapper>
	);
}
const DropdownMenuWrapper=styled(DropdownMenu)`
${props=>props?.minwidth?`min-width:${props?.minwidth}px`:"width:100%"}

`
const LabelPlaceHolder = styled.div`
	color: #c4c4c4;
	font-style: italic;
`;
export const GenericDropdownWrapper = styled(Dropdown)`
	${(props) => props.isborder === "true" && `border: 1px solid #d9dbde; border-radius:4px;`}
	height:36px;
	.dropdown-menu {
		max-height: 250px;
		overflow-y: scroll;
	}
	${(props) => props.validated === "true" && `border: 1px solid #dc3545;`};
	.caret {
	max-width: 20px;
	visibility: hidden;
 }
 :hover {
	.caret {
		visibility: visible;
	}
	 }

`;

const SelectedOption = styled.div `
 ${props => props.color && `color: ${props.color};`}
 font-size: 14px;
`
  const Item = styled(DropdownItem)`
  ${props => props.color && `color: ${props.color};`}
 
  ${props=>!props?.onClick &&` pointer-events:none`}
  `;