import styled, { css } from "styled-components/macro";
import { ActionsDropdown } from "./ActionsDropdown";
import { IconDispatcher } from "../Icons";
import React from "react";
import { v4 as uuid } from "uuid";

export function CreateActionsDropdown({
	id,
	actions,
	variant = "position-relative",
	right = "-10",
	top = "-16",
	dropdownClasses = "px-4",
	autoClose,
	handleClick,
	menuVariant,
	size,
	isicon = "true",
	toggleButton = IconDispatcher("ACTION-DROPDOWN-HORIZONTAL"),
	stopPropagation = false,
}) {
	const dropdownId = uuid();
	return (
		<div className={variant} id={`dropdown_${dropdownId}`}>
			<DropDownPosition2 variant={variant} right={right} top={top}>
				<ActionsDropdown
					toggleButton={toggleButton}
					autoClose={autoClose}
					actions={actions}
					dropdownClasses={dropdownClasses}
					isDisabled={actions?.length === 0}
					handleClick={handleClick}
					menuVariant={menuVariant}
					size={size}
					isicon={isicon}
					relativeId={`#dropdown_${dropdownId}`}
					id={id}
					stopPropagation={stopPropagation}
				/>
			</DropDownPosition2>
		</div>
	);
}

export const DropDownPosition2 = styled.div`
	display: flex;
	${(props) =>
		props.variant === "position-relative" &&
		css`
			position: absolute;
			right: ${(props) => props.right}px;
			top: ${(props) => props.top}px;
		`}
`;
