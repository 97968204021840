import { getAllMapsListData, getAllPersonas } from "@redux/index";
import { findWorkspaceById } from "@redux/memoizedSelectors";
import MapTemplates from "features/settings/components/templates/MapTemplates";
import PersonaTemplates from "features/settings/components/templates/PersonaTemplates";
import { currentWorkspaceId } from "index";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { GenericButton, PageTitle } from "shared/ui-basics/index";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";


export default function Templates() {
	const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const currentWorkspace = useSelector(findWorkspaceById(currentWorkspaceId));
    const [viewCard, setCardView] = React.useState(true);
	const label = `${currentWorkspace?.name} ${t("TEMPLATES")}`;

    const tabName = search.getTabName()?.tab || "maps";

    const [key, setKey] = useState(tabName);

    const handleChangeTab = (k) => {
		setKey(k);
		history.push(`/templates?tab=${k}`);
	};

	return (
		<DataLoader reduxActions={[getAllMapsListData, getAllPersonas]}>
			<PageTemplate classNames="container pt-5">
				<PageHeader>
					<PageTitle className="col xs-10 ellipsis w-100" title={label}>{label}</PageTitle>
					<Col xs='2' className="justify-content-end align-items-center m-4 d-flex" style={{ visibility: key !== "personas" && "hidden" }}>
						<GenericButton variant="light-link" className="me-2" active={viewCard} icon="GRID-VIEW" tooltip={true} tooltipText={t("VIEW_CARD")} onClick={() => setCardView(true)} />
						<GenericButton variant="light-link" className="me-2" active={!viewCard} icon="LIST-VIEW" tooltip={true} tooltipText={t("LIST_VIEW")} onClick={() => setCardView(false)} />
					</Col>
				</PageHeader>
				<Tabs activeKey={key} onSelect={handleChangeTab} className="m-3">
					<Tab eventKey="maps" title={t("ASPX_97")}>
						<MapTemplates viewCard={viewCard} />
					</Tab>

					<Tab eventKey="personas" title={t("ASPX_98")}>
						<PersonaTemplates viewCard={viewCard} />
					</Tab>
				</Tabs>
			</PageTemplate>
		</DataLoader>
	);
}

const PageHeader = styled(Row)`
	justify-content: space-between;
	display: flex;
`;
