import {
	GetCxProgram,
	brandValueInit,
	getAllCxAction,
	getAllCxMetrics,
	getAllMapsListData,
	getAllOpportunity,
	getAllPersonas,
	getAllSolutions,
	getAllStakeholderRoles,
	groupInit,
	priorityInit,
	statusInit,
} from "@redux/index";
import { projectFinancialImpact } from "@redux/memoizedSelectors";
import { calculateTotalFinancialImpact, encode, toastWrapper } from "@utils/helpers";
import { InformationType, urlOriginStatus } from "@utils/optionsSets/OptionSets";
import HeaderActions from "features/projects/actions/HeaderActions";
import { SyncWithModal } from "features/projects/modals/SyncWithModal";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DisplayStatus from "shared/cx-components/DisplayStatus";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import GenericHeader from "shared/headers/GenericHeader";
import { Title } from "shared/headers/Reusable";
import { FlexAlignJustifyCenter, FlexBetweenAlignCenter, IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import { stopSynchronizationProject, updateProject } from "../reducer/ProjectsActions";

export default function ProjectHeader({ forceReload, handleExport }) {
	const { search } = useLocation();
	const projectId = search.getId();
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";
	const project = useSelector((state) => state.projects?.current_project);
	const loggedUserId = useSelector((state) => state.auth.userInfo.UserId);
	const { attributesList, userProvided } = useSelector(projectFinancialImpact, shallowEqual);
	const kpiAttribute = attributesList?.find((attr) => attr.type === InformationType.KPIs);
	const allCxGoals = useSelector((state) => state.libraries.cxGoals);
	const allMeasures = useSelector((state) => state.libraries.measures);

	const roleName = project.authorizedUsers?.find((user) => user.id === loggedUserId).roleName.toLowerCase();
	const isOwner = roleName === "owner";
	const isViewer = roleName === "viewer";
	const isContributor = roleName === "contributor";
	const origin = search.getOrigin();
	const folderId = search.getFolderId();

	const onRefresh = () => {
		const allFunctions = [
			GetCxProgram,
			getAllOpportunity,
			getAllSolutions,
			getAllCxAction,
			getAllMapsListData,
			getAllPersonas,
			statusInit,
			groupInit,
			priorityInit,
			getAllStakeholderRoles,
			getAllCxMetrics,
			brandValueInit,
		];

		const promise = async () => {
			return await Promise.all([
				allFunctions.forEach((func, i) => {
					dispatch(func(i === 0 ? { projectId } : null));
				}),
			])
				.then(() => forceReload())
				.catch(() => toast.error(t("JS_13")));
		};
		toastWrapper(promise, "REFRESH");
	};

	const handleRedirect = () => {
		history.push(`/projects${folderId ? `?folderid=${folderId}` : ""}`);
	};

	const getBackPath = () => {
		if (origin && origin === urlOriginStatus.DefaultReturn) return null;
		return "/projects" + (folderId ? `?folderid=${folderId}` : "");
	};

	const [showSyncModal] = useModal(SyncWithModal, {
		title: `${t("STOP_SYNCING_WITH")} ${t("MONDAY")}`,
		bodyText: t("STOP_SYNCING_WARNING"),
		alertType: "danger",
		actionBtnText: t("STOP_SYNCING"),
		action: () => stopSynchronizationProject({ projectId: project?.id }),
	});

	const createSyncActions = () => {
		const actions = [];
		actions.push({
			handleClick: () => window.open(project?.externalUrl, "_blank"),
			icon: "ARROW-UP-RIGHT-FROM-SQUARE-LIGHT",
			text: `${t("VIEW_PROJECT")}`,
			itemClasses: "d-flex align-items-center",
		});
		if (isOwner) actions.push({ handleClick: showSyncModal, icon: "X", text: t("STOP_SYNCING"), itemClasses: "d-flex align-items-center" });
		return actions;
	};

	const toggleButton = (
		<ToggleButton>
			{IconDispatcher("MONDAY", "me-1")}
			{t("SYNCHRONIZED")}
			{IconDispatcher("RETWEET-REGULAR", "ms-2")}
		</ToggleButton>
	);

	return (
		<GenericHeader
			createActions={() => HeaderActions({ onRefresh, handleRedirect, handleExport })}
			backLabel={"PROJECT"}
			backPath={getBackPath()}
			namePath={"projects.current_project.name"}
			id={projectId}>
			<FlexBetweenAlignCenter>
				<Title
					path="projects.current_project"
					itemId={projectId}
					recordType="cem_cxproject"
					updateFunction={(obj, modifiedAttribNames) => updateProject({ modifiedProject: { ...project, name: encode(obj.name) }, modifiedAttribNames })}
					icon="PROJECT-HEADER"
					isEditable={!isViewer}
					isContributor={isContributor}
				/>
				<FlexAlignJustifyCenter gap="15" className="mt-4">
					{project?.externalSyncEnabled && <CreateActionsDropdown toggleButton={toggleButton} actions={createSyncActions()} isicon="false" variant="" dropdownClasses="" />}
					<DisplayStatus
						object={project}
						type="status"
						name="project"
						disable={isViewer}
						updateFunction={(modifiedProject, modifiedAttribNames) => updateProject({ modifiedProject, modifiedAttribNames })}
					/>
					<Wrapper>
						<Oswald>{userProvided ? project?.financialImpact?.toLocaleString() : calculateTotalFinancialImpact(kpiAttribute, allCxGoals, allMeasures)}</Oswald>
						<Oswald>{currencySymbol}</Oswald>
					</Wrapper>
				</FlexAlignJustifyCenter>
			</FlexBetweenAlignCenter>
		</GenericHeader>
	);
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #f96209;
	text-align: right;
	font-family: "Oswald", sans-serif;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Oswald = styled.div`
	font-family: "Oswald", sans-serif;
`;

const ToggleButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
