import { GenericButton } from "shared/ui-basics/buttons";
import styled, { css } from "styled-components/macro";

const ColorTextCard = ({ text, color, handleClick, showBorder = true, width = "100%", fontSize = '0.75rem', className, style = {}, isEdit = true, ...props }) => {
	const handleDelete = (e) => {
		e.stopPropagation();
		props?.onRemove && props.onRemove();
	}
	return (
		<Card onClick={handleClick} isclickable={handleClick} showborder={showBorder} width={width} className={className} style={style}>
			<ColorContainer color={color !== "" ? color : "#c4c4c4"} />
			<TextContainer size={fontSize} className="ellipsis" title={text}>{text}</TextContainer>
			{props?.onRemove && isEdit && <GenericButton variant="light-link"  size="xs" icon="X" iconClassName="ms-1" onClick={handleDelete} />}
		</Card>
	);
};

ColorTextCard.dipslayName = "ColorTextCard";

export { ColorTextCard };

const Card = styled.div`
	height: 2.25rem;
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
	position: relative;
	gap:8px;
	${(props) =>
		props.showborder &&
		css`
			border: 1px solid #c4c4c4;
		`};

	${(props) =>
		props.isclickable &&
		css`
			:hover {
				cursor: pointer;
			}
		`};
		.remove-button {
			display:none !important;
			position: absolute;
			right: 2px;
			top: 2px;
		}
		:hover .remove-button {
			display:flex!important;;
		}
`;

const ColorContainer = styled.div`
	width: 1rem;
	height: 1rem;
	min-width: 1rem;
	min-height: 1rem;
	border-radius: 0.125rem;
	background: ${(props) => props.color};
`;

const TextContainer = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: ${props => props.size};
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

`;
