import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { encode, reorder } from "@utils/helpers";
import VerticalDND from "../../journey-map/components/DnD/VerticalDND";
import PickListData from "../../../utils/optionsSets/PickListData";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import * as Style from "./Style";

import { OverlayPanel } from "primereact/overlaypanel";
import ReactColor from "../../../shared/popovers/ReactColor";

export function ColorListItem({ data, addFunction, updateFunction, deleteFunction, params }) {
	const { t } = useTranslation();
	const [list, setList] = useState(data);

	useEffect(() => {
		setList(data);
	}, [data]);

	const handleStyle = {
		position: "absolute",
		width: "35px",
		top: "10px",
		border: "0",
		zIndex: "60",
		left: "-7px",
	};

	const Render = ({ item, index }) => {
		return (
			<Style.WrapperDiv key={item?.id} className="m-3 mb-2 me-0 p-0">
				<Style.Container>
					{params?.isColorEditable && <ColorSelector item={item} updateFunction={updateFunction} params={params} lastItem={list?.length - 1 === index} />}
					<Text item={item} updateFunction={updateFunction} params={params} />
				</Style.Container>
				{deleteFunction && (!params?.onlyCustom || (params?.onlyCustom && item?.isCustom)) && (
					<Style.DeleteAction className=" mx-1">
						<GenericButton
							tooltip
							variant="light-link"
							size={"xs"}
							icon="X"
							onClick={() => deleteFunction(item)}
							disabled={item?.isUsed}
							tooltipText={t(item?.isUsed ? "VALUE_IS_USED" : params.deleteTooltip)}
						/>
					</Style.DeleteAction>
				)}
			</Style.WrapperDiv>
		);
	};

	const reOrder = (source, destination) => {
		const newList = reorder(list, source, destination);
		setList(newList);
		if (params.updateOrderFunction)
			params.updateOrderFunction(newList);
	};

	const DragValidation = () => {
		switch (params.drag) {
			case true:
				return <VerticalDND state={list} CardsValidationRender={Render} reordingTable={reOrder} handleStyle={handleStyle} />;
			case false:
				return list?.map((item, index) => <Render key={item.id} item={item} index={index} />);
			default:
				return list?.map((item, index) => <Render key={item.id} item={item} index={index} />);
		}
	};

	return (
		<Style.Wrapper vertical={params.drag}>
			{addFunction && !params.drag && (
				<Style.AddContainer>
					<Style.AddButton onClick={addFunction} className={"m-3 mb-2 p-0 ms-3 me-4 "} fromCustomization={params?.fromCustomization}>
						{IconDispatcher("CIRCLE-PLUS", "me-1")}
						{t(params?.buttonLabel)}
					</Style.AddButton>
				</Style.AddContainer>
			)}

			{DragValidation()}
			{addFunction && params.drag && (
				<Style.AddContainer drag={params.drag} className={"m-3 mb-2 p-0 ms-3 me-4 "}>
					<Style.AddButton onClick={addFunction}>
						{IconDispatcher("CIRCLE-PLUS", "me-1")}
						{t(params?.buttonLabel)}
					</Style.AddButton>
				</Style.AddContainer>
			)}
		</Style.Wrapper>
	);
}

export const ColorSelector = ({ item, updateFunction, params, isSystemDepartment = false }) => {
	const [selected, setSelected] = useState(item?.colorCode);
	const disable = params?.onlyCustom ? !item.isCustom : false;

	const update = (color) => {
		// const id = e.currentTarget.id;
		updateFunction({ ...item, colorCode: color, update: "colorCode" });
		setSelected(color);
	};

	const updateSAndD = (color) => {
		const copyObject = { ...item };
		copyObject.colorCode = color;
		copyObject.name = encode(copyObject.name);
		copyObject.update = "colorCode";
		updateFunction({ item: copyObject, modifiedAttribNames: ["colorCode"] });
		setSelected(color);
	};

	return (
		<div className="position-relative">
			<ReactColor
				color={selected}
				updateColor={isSystemDepartment ? updateSAndD : update}
				ButtonComponent={<Style.Color color={selected}>{IconDispatcher("FILL-DRIP", "", { size: "lg" })}</Style.Color>}
				disabled={disable}
				colorsType={params?.colorsType}
				autoClose={true}
			/>
		</div>
	);
};

export const SystemDepartmentColorSelector = ({ item, handleChange, palette, disabled }) => {
	const colors = PickListData(palette);
	return (
		<Container className="p-2" disabled={disabled}>
			<WrapperMenu>
				{colors?.map((color, i) => (
					<BoxColor key={i} color={color} onClick={() => handleChange("colorCode", color)}>
						{item?.colorCode === color && IconDispatcher("WHITE-CHECK")}
					</BoxColor>
				))}
			</WrapperMenu>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
	border: none !important;
	background: #ffffff;
	${(props) =>
		props.disabled === true &&
		css`
			pointer-events: none;
		`}
`;

const WrapperMenu = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	border-radius: 6px;
	gap: 8px;
`;

const BoxColor = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	background-color: ${(props) => props.color};
	:hover {
		cursor: pointer;
	}
`;

export const ColorSelectorPopOver = ({ item, updateFunction, palette, params }) => {
	const colors = PickListData(palette);
	const [selected, setSelected] = useState(item?.colorCode);
	const disable = !item.isCustom;
	const refOver = useRef();

	const update = (e) => {
		const id = e.currentTarget.id;
		updateFunction({ ...item, colorCode: id, update: "colorCode" });
		setSelected(id);
		refOver.current.toggle(e);
	};
	return (
		<div>
			<Style.Color color={selected} onClick={(e) => (disable ? {} : refOver.current.toggle(e))}>
				{IconDispatcher("FILL-DRIP", "", { size: "lg" })}
			</Style.Color>
			<OverlayPanel ref={refOver} id="overlay_panel">
				<Style.OverlayPanelWrap size={params?.size} id="">
					{colors?.map((color, i) => (
						<div key={i} className="m-2">
							<Style.Color color={color} id={color} onClick={update} />
						</div>
					))}
					<></>
				</Style.OverlayPanelWrap>
			</OverlayPanel>
		</div>
	);
};
const Text = ({ item, updateFunction, params }) => {
	const { t } = useTranslation();
	const [text, setText] = useState(item?.name);
	const disable = params?.onlyCustom ? !item?.isCustom : false;
	const update = () => {
		if (item.description === text) return;
		updateFunction({ ...item, name: text, update: "name" });
	};
	const handleChange = (e) => setText(e.target.value.truncate(params?.maxChar || 100));
	return (
		<CustomTooltip text={item?.name} disabled={item?.name?.length < 20}>
			<Style.CustomInput
				variant="default"
				className="ellipsis"
				placeholder={t(params?.inputLabel)}
				value={text}
				onBlur={update}
				onChange={handleChange}
				disabled={disable}
				iscoloreditable={`${params?.isColorEditable}`}
				color={params?.isColorOnInput && item?.colorCode}
			/>
		</CustomTooltip>
	);
};
