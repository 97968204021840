import { error, success } from "@redux-requests/core"
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import axios from 'axios';

const initState = () => {
    const urlToken = window.location.search.split("token=")[1]
    const pathname = window.location.pathname.split("/")

    const token = urlToken?.length > 0 ? urlToken : !pathname.includes('authenticate') ? Cookies.get('Authorization-cemantica') : null;
    return {
        token: token,
        userInfo: token ? jwt_decode(token) : null,
        error:null,
        loading: false,
        loginStatus: false
    }
}
const initialState = initState()


const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTHENTICATE':
            Cookies.remove('Authorization-cemantica');

            return {
                ...state,
                token : null,
                userInfo: null,
                loading:true,
                error:null,

            }
        case success('AUTHENTICATE'):{

            let expires = new Date()
            expires.setTime(expires.getTime() + (86400 * 60 * 1000 * 6))
            Cookies.set('Authorization-cemantica', action.response.data.token, { path: '/', expires })

            axios.interceptors.request.use(function (config) {
                config.headers.Authorization = `Bearer ${action.response.data.token}`;
                return config;
            });
            return {
                ...state,
                token: action.response.data.token,
                userInfo: action.response.data.token ? jwt_decode(action.response.data.token) : null,
                loading:false,
                error: null,
                loginStatus: true
            }
        }
        case "UPDATE_LOGIN_STATUS":
            return {
                ...state,
                loginStatus: false
            }
        case error('AUTHENTICATE') :
            return {
                ...state,
                loading:false,
                error:action?.error?.data
            }
        case 'LOGOUT':
            Cookies.remove('Authorization-cemantica');
            return {
                ...state,
                token: null,
                userInfo: {}
            };

        default:
            return state;
    }


}
export default AuthReducer;