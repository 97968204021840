import { updateSwimlaneProps } from "features/index";
import { Colors, IconDispatcher } from "shared/ui-basics/index";
import ReactColor from "shared/popovers/ReactColor";
import { ColorPickerProvider } from "shared/dynamic-imports";
import { memoizedSwimlanes } from "@redux/memoizedSelectors";
import { InformationType } from "@utils/optionsSets/OptionSets";
import { firstLetterUppercase } from "@utils/helpers";
import { PermissionManager } from "@utils/permissionManager";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Sidebar } from "primereact/sidebar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { CustomerRoutesDetails, DividerDetails, GeneralDetails, GeneralSentimentLineDetails, PersonaSentimentLinesDetails, SentimentLevelDetails } from "./SwimlaneDetailsComponents";

function SwimlaneDetailsCanvas({ isOpen, onClose, cjmId, swimlaneKey, isEditable = true }) {
	const { t } = useTranslation();

	const swimLane = useSelector(memoizedSwimlanes).find((swimLane) => swimLane.key === swimlaneKey);
	const userAccess = useSelector((state) => state?.maps?.currentUser?.roleName);
	const { routesAllowed } = PermissionManager({ userAccess });
	const updateColor = (color) => updateSwimlaneProps({ cjmId, modifiedSwimLane: { ...swimLane, colorCode: color }, modifiedAttribNames: ["colorcode"] });
	const { Text, Divider, Image, ListItems, CustomLibrary, Systems, Departments, Opportunity, ActionsList, Feelings, KPIs, Timing, Touchpoints, AdvancedInteractions, SentimentChart, CustomerActions } =
		InformationType;

	const getContext = () => {
		if (['Processes', 'KPIs', 'KPI', 'Issues'].includes(swimLane?.id)) return ["general", "color"];

		if ([Feelings, Image, Systems, Departments, CustomLibrary, KPIs, Timing, Opportunity, ActionsList, Touchpoints].includes(swimLane?.type)) return ["general"];
		if ([Text, ListItems, CustomerActions].includes(swimLane?.type)) return ["general", "color"];
		if (swimLane?.type === Divider) return ["general", "color", "divider"];
		if (swimLane?.type === AdvancedInteractions) return ["general", "sentimentLevel", "routes"];
		if (swimLane?.type === SentimentChart) return ["general", "generalSentimentLine", "personaSentimentLine"];
		return ["general"];
	};

	const dataByContext = {
		general: {
			component: GeneralDetails,
			props: { cjmId, swimlaneKey, isEditable },
			label: "ASPX_185",
		},
		color: {
			component: ReactColor,
			props: { color: swimLane?.colorCode ? swimLane.colorCode : "#FFFFFF", updateColor, autoSave: true, withPopover: false, openWithBtn: false, isEditable },
			label: "BACKGROUND_COLOR",
		},
		sentimentLevel: {
			component: SentimentLevelDetails,
			props: { isEditable, swimlaneKey },
			label: "SENTIMENT_LEVEL",
		},
		generalSentimentLine: {
			component: GeneralSentimentLineDetails,
			props: { isEditable, swimlaneKey },
			label: "GLOBAL_SENTIMENT_LINES",
		},
		personaSentimentLine: {
			component: PersonaSentimentLinesDetails,
			props: { isEditable, swimlaneKey },
			label: "PERSONA_SENTIMENT_LINES",
		},
		routes: {
			component: CustomerRoutesDetails,
			props: { cjmId, routesAllowed },
			label: "CUSTOMER_ROUTES",
		},
		divider: {
			component: DividerDetails,
			props: { cjmId, swimlaneKey, isEditable },
			label: "HEIGHT_AND_PATTERN",
		},
	};

	const contextData = getContext();
	const activeIndex = [...Array(contextData.length).keys()];

	return (
		<CustomSidebar visible={isOpen} onHide={onClose} baseZIndex={900} position={"right"}>
			<Header >
				<Title>{t("DETAILS")}</Title>
			</Header>
			<Offcanvas.Body className="p-0 m-0">
				<ColorPickerProvider>
					<CustomAccordion multiple activeIndex={activeIndex}>
						{contextData.map((context, index) => {
							const data = dataByContext[context];
							return (
								<AccordionTab key={index} header={<SimpleHeaderDisplay label={data.label} />}>
									<div className="p-4">
										<data.component lastElement={index === (contextData?.length - 1)} {...data.props} />
									</div>
								</AccordionTab>
							);
						})}
					</CustomAccordion>
				</ColorPickerProvider>
			</Offcanvas.Body>
		</CustomSidebar>
	);
}
SwimlaneDetailsCanvas.displayName = "SwimlaneDetailsCanvas";

export default SwimlaneDetailsCanvas;

function SimpleHeaderDisplay({ type, icon, label }) {
	const { t } = useTranslation();

	return (
		<CustomLabel className="ms-2" type={type}>
			{icon && IconDispatcher(icon, "mx-2")}
			{firstLetterUppercase(t(label))}
		</CustomLabel>
	);
}
const CustomLabel = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	color: #92919d;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;

	${(props) =>
		props.type === "actions" &&
		css`
			color: #605f60;
		`};
	${(props) =>
		props.type === "pros" &&
		css`
			color: ${Colors.excellent};
		`};
	${(props) =>
		props.type === "cons" &&
		css`
			color: ${Colors.terrible};
		`};
	${(props) =>
		props.type === "insights" &&
		css`
			color: #20a9e9;
		`};
`;


const CustomSidebar = styled(Sidebar)`
	width: 480px !important;
	.p-sidebar-header {
		position: absolute;
		right: 5px;
		top: -7px;
	}
	.p-sidebar-icon {
		border-radius: 4px !important;
	}
	.p-sidebar-icon:focus {
		box-shadow: none !important;
	}
	.p-sidebar-content {
		display: flex;
		flex-direction: column;
		padding: 0px;
	}
`;

const Header = styled.div`
	border-bottom: 1px solid #c4c4c4;
	margin-top: 1rem ;
`;
const Title = styled.div`
	font-size: 20px;
	margin-left: 1rem;
	margin-bottom: 1rem;
`;

const CustomAccordion = styled(Accordion)`
	border-radius: 0px !important;

	.p-accordion-tab {
		margin-bottom: 0px;
	}
	.p-accordion-header-link {
		padding-left: 1px !important;
		padding: 0px !important;
		height: 30px;
		border-radius: 0px !important;
	}

	.p-accordion-header-link:focus {
		box-shadow: none !important;
	}

	.p-accordion-content {
		margin: 0px;
		padding: 0px;
		border: none;
	}
	.p-accordion-header-link {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
`;
