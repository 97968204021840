import { isFullUser, isFullUsersLicenseAvailable } from "@utils/helpers";
import { SubscriptionPlan, UserType } from "@utils/optionsSets/OptionSets";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomDropdownButton } from "shared/ui-basics";
import styled from "styled-components/macro";

export default function UserTypeDropdown({ user, handleUserTypeChange }) {
	const { t } = useTranslation();
	const users = useSelector((state) => state.settings.users);
	const subscriptionDetails = useSelector((state) => state.settings?.subscriptionDetails);
	const loggerUserInfo = useSelector((state) => state.auth.userInfo);

	const isNewUser = user?.userType === -1;
	const loggerUserType = Number(loggerUserInfo.UserType);
	const isLoggerCompanyAdmin = loggerUserType === UserType.companyAdmin;
	const isLoggerAdmin = loggerUserType === UserType.admin;
	const contracts = subscriptionDetails.subscriptionContracts;
	const isSingleSubscription = contracts?.length === 1;

	const isEnterprise = user?.subscriptionPlan === SubscriptionPlan.Enterprise;
	const isExpert = user?.subscriptionPlan === SubscriptionPlan.Expert;
	const isSharedExpert = user?.subscriptionPlan === SubscriptionPlan.SharedExpert;
	const isStandard = user?.subscriptionPlan === SubscriptionPlan.Standard;

	const isFullUserSeatAvailable = isFullUsersLicenseAvailable(users, contracts, user?.subscriptionPlan).answer || isFullUser(user?.userType);

	const showCompanyAdmin = () => {
		if (user?.userType !== UserType.companyAdmin && isLoggerCompanyAdmin) {
			if (isSingleSubscription) {
				if (isFullUserSeatAvailable && (isEnterprise || isExpert || isStandard)) return true;
				return false;
			}
			if (isFullUserSeatAvailable && (isEnterprise || isSharedExpert)) return true;
			return false;
		}
		return false;
	};

	const showAdmin = () => {
		if (user?.userType !== UserType.admin) {
			if (isSingleSubscription) {
				if (isFullUserSeatAvailable && (isEnterprise || isExpert || isSharedExpert)) return true;
				return false;
			}
			if (isFullUserSeatAvailable && (isEnterprise || isSharedExpert)) return true;
			return false;
		}
		return false;
	};

	const showUser = () => {
		if (user?.userType !== UserType.user) {
			if (isSingleSubscription) {
				if (isFullUserSeatAvailable && (isEnterprise || isExpert || isSharedExpert)) return true;
				return false;
			}
			if (isFullUserSeatAvailable) return true;
			return false;
		}
		return false;
	};

	const showReadOnly = () => {
		if (user?.userType !== UserType.readOnly) {
			if (isSingleSubscription) {
				if (!isStandard) return true;
				return false;
			}
			if (isEnterprise || isSharedExpert) return true;
			return false;
		}
		return false;
	};

	const onUserTypeChange = (user) => (userTypeCode) => {
		handleUserTypeChange(user, userTypeCode);
	};

	const displayDropDownTitle = (user) => {
		if (user?.userType === UserType.companyAdmin) return t("SUPER_ADMIN") + "    ";
		else if (user?.userType === UserType.admin) return t("CS_53") + "    ";
		else if (user?.userType === UserType.readOnly) return t("READ_ONLY") + "    ";
		else if (user?.userType === UserType.user) return t("CS_52") + "    ";
		return t("SELECT_USER_TYPE");
	};

	const isDropdownDisabled = () => {
		if (!user?.isActive) return true;
		if (isStandard && !isNewUser) return true;

		if (user?.subscriptionPlan === -1) return true;

		// If logged user is Admin, He can only modify roles / activate-deactivate User and Admin
		// He cannot modify / activate-deactivate a user with Company Admin role
		if (isLoggerAdmin && user?.userType === UserType.companyAdmin) return true;

		// In any case, logged user actions are disabled (except for create new user)
		if (loggerUserInfo.UserEmail.toLowerCase() === user?.loginName.toLowerCase() && !isNewUser) return true;

        if (!isFullUserSeatAvailable && user?.userType === UserType.readOnly) return true;
        
        if (!showCompanyAdmin() && !showAdmin() && !showUser() && !showReadOnly()) return true

		return false;
	};

	return (
		<Dropdown onSelect={onUserTypeChange(user)}>
			<Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownButton} disabled={isDropdownDisabled()}>
				{displayDropDownTitle(user)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{showCompanyAdmin() && (
					<StyledDropdownItem eventKey={UserType.companyAdmin}>
						<div>
							<span>{t("SUPER_ADMIN")}</span>
							<StyledDetails>{t("COMPANY_ADMIN_ROLE")}</StyledDetails>
						</div>
					</StyledDropdownItem>
				)}
				{showAdmin() && (
					<StyledDropdownItem eventKey={UserType.admin}>
						<div>
							<span>{t("CS_53")}</span>
							<StyledDetails>{t("ADMIN_ROLE")}</StyledDetails>
						</div>
					</StyledDropdownItem>
				)}
				{showUser() && (
					<StyledDropdownItem eventKey={UserType.user}>
						<div>
							<span>{t("CS_52")}</span>
							<StyledDetails>{t("USER_ROLE")}</StyledDetails>
						</div>
					</StyledDropdownItem>
				)}
				{showReadOnly() && (
					<StyledDropdownItem eventKey={UserType.readOnly}>
						<div>
							<span>{t("READ_ONLY")}</span>
							<StyledDetails>{t("READ_ONLY_DETAILS")}</StyledDetails>
						</div>
					</StyledDropdownItem>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
}

const StyledDropdownItem = styled(Dropdown.Item)`
	border-bottom: 1px solid #e9ecef !important;
	padding-bottom: 5px;
	font-size: 14px;
`;

const StyledDetails = styled.span`
	width: inherit;
	color: #858585;
	display: block;
	word-wrap: break-word;
	white-space: break-spaces;
`;
