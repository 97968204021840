import CustomTooltip from "shared/components/CustomTooltip";
import $ from "jquery";
import React, { memo, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled, { css } from "styled-components/macro";
import { Button, GenericButton, IconDispatcher } from "../ui-basics";

const ActionsDropdown = memo(
	({
		id = "",
		dropDownVariant = "",
		dropdownClasses = "",
		autoClose = "true",
		handleOnSelect = () => { },
		handleToggle = () => { },
		buttonVariant = "light-link",
		isDisabled = false,
		isicon = "false",
		toggleButton = IconDispatcher("ACTION-DROPDOWN"),
		menuVariant = "sidebar",
		size = "m",
		actions,
		drop = "bottom",
		handleClick = false,
		stopPropagation = true,
	}) => {
		const [showDropdown, setShowDropdown] = useState(false);
		const externalContainer = document.getElementById("external-container");
		const pathname = useLocation().pathname;
		const handleDropdownToggle = async (isOpen, event, metadata) => {
			if(stopPropagation)event.originalEvent.stopPropagation();
			handleToggle && handleToggle(isOpen, event);

			const isIconSelector = typeof event?.originalEvent?.srcElement?.className === "string" && event.originalEvent.srcElement.className.split("_")[0] === "IconSelector";
			if (!isIconSelector) {
				if (handleClick) {
					handleClick(isOpen);
					setShowDropdown(isOpen);
				} else setShowDropdown(isOpen);
			}
		};

		const handleClickItem = (action, e) => {
			e.stopPropagation();
			action?.handleClick && action?.handleClick();
			if (action.customItem) return;
			setShowDropdown(!showDropdown);
		};

		const DisplayDropdownItem = ({ action, index }) => {
			if (action?.divider) return <Divider className="mx-3 my-1" />;

			return (
				<CustomTooltip text={action?.tooltipText} disabled={!action?.tooltipText}>
					<DropdownItem
						key={index}
						variant={action?.itemStyle}
						className={action?.itemClasses}
						eventKey={action?.eventKey}
						onClick={(e) => handleClickItem(action, e)}
						disabled={action?.disabled ? action.disabled : false}
						clickable={action?.clickable ? action.clickable.toString() : "true"}
						draggable="false" // Add this attribute to disable dragging
					>
						{action?.customItem ? (
							action.customItem
						) : (
							<div className="item">
								<GenericButton variant="actiondropdown" disabled={action?.disabled} icon={action?.icon && action?.icon} iconClassName={action?.iconStyle}>
									<TextWrapper className="ms-1" type={action?.type}>
										{action?.text}
									</TextWrapper>
								</GenericButton>
							</div>
						)}
					</DropdownItem>
				</CustomTooltip>
			);
		};
		useEffect(() => {
			if (pathname === "/EditMap") {
				const closeDropdownOnScroll = () => setShowDropdown(false);
				const swimlaneContainer = $("#swimlane_Container");
				swimlaneContainer.on("scroll", closeDropdownOnScroll);

				return () => {
					swimlaneContainer.off("scroll", closeDropdownOnScroll);
				};
			}
		}, []);
		const Menu = showDropdown && (
			<div
				style={{
					position: "absolute",
				}}>
				<DropdownMenu variant={menuVariant} id={`dropdown-menu-${id}`}>
					{actions?.map((action, index) => (
						<DisplayDropdownItem key={index} action={action} index={index} />
					))}
				</DropdownMenu>
			</div>
		);

		return (
			<>
				<div id={`original-dropdown_${id}`}>
					<DropdownWrapper
						id={id}
						drop={drop}
						show={showDropdown}
						onToggle={handleDropdownToggle}
						variant={dropDownVariant}
						className={dropdownClasses}
						autoClose={autoClose}
						onSelect={handleOnSelect}>
						<Dropdown.Toggle as={Button} variant={buttonVariant} isicon={isicon} size={size} disabled={isDisabled || actions?.length === 0} className="px-1" id={`dropdown-toggle-${id}`}>
							{toggleButton}
						</Dropdown.Toggle>
						{ReactDOM.createPortal(Menu, externalContainer)}
					</DropdownWrapper>
				</div>
			</>
		);
	}
);
ActionsDropdown.displayName = "ActionsDropdown";
export { ActionsDropdown };

const DropdownWrapper = styled(Dropdown)`
	margin: 0 !important;
	display: flex;

	.dropdown-toggle:after {
		display: none;
	}

	.dropdown-item:hover {
		visibility: visible;
	}
	${(props) =>
		props.variant === "withCaret" &&
		css`
			.btn {
				color: #212529;
				font: 400;
				font-size: 16px;
			}

			.dropdown-toggle:after {
				display: inline-block;
			}
		`}

	.dropdown-menu.show {
		padding: 0 !important;
		box-shadow: 1px 1px 3px rgba(33, 33, 33, 0.3);
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		background-color: #e4e7f1;
	}
`;

const DropdownMenu = styled(Dropdown.Menu)`
	z-index: 1001;
	${(props) =>
		props.variant === "NavMenuHeader" &&
		css`
			width: 207px;
			transform: translate(-16px, 40px) !important;
		`}
	${(props) =>
		props.variant === "CJMHeader" &&
		css`
			width: 207px;
			transform: translate(-163px, 43px) !important;
		`}
    ${(props) =>
		props.variant === "sidebar" &&
		css`
			border-radius: 4px;
			border: 1px solid #c4c4c4;
			background: #fff;
			box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 4px 0px rgba(0, 0, 0, 0.25);

			.btn {
				color: #605f60;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
			}
		`}
`;

const DropdownItem = styled.div`
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	display: block;
	align-items: center;
	justify-content: left;
	line-height: 1.5rem;
	.item {
		width: 100%;
		:hover {
			background-color: #efefef;
		}
	}

	${(props) =>
		props.variant === "warning-delete" &&
		`
      path {
         fill: #FC74B3
      }

   `}
	${(props) =>
		props.disabled
			? css`
					pointer-events: none;
					opacity: 0.5;
			  `
			: ""}
    ${(props) =>
		props.clickable === "false" &&
		css`
			pointer-events: none;
		`}
`;

const TextWrapper = styled.div`
	${(props) =>
		props.type === "email" &&
		css`
			text-transform: lowercase;
		`}
	${(props) =>
		props.type === "needHelp" &&
		css`
			text-transform: none;
		`}
    ${(props) =>
		props.type === "textTransformNone" &&
		css`
			text-transform: none;
		`}
`;

const Divider = styled.div`
	border-bottom: 1px solid #c4c4c4;
`;
