import { success } from "@redux-requests/core";
import { StatusCode } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, decode } from "@utils/helpers";

const FolderReducer = (state = [], action) => {
  const requestData = action.meta?.requestAction?.request?.data;
  switch (action.type) {
    case success("GET_FOLDERS"):
      return (
        Object.entries(action.response.data).map((folder) => {
          return { ...folder[1], expanded: false };
        }) || []
      );

    case success("UPDATE_FOLDER_NAME"):
      return state.map((folder) => {
        if (folder.id === requestData.folderId) {
          return {
            ...folder,
            name: decode(action.meta.requestAction.request.data.newValue),
            modifiedOn: new Date().toISOString()

          };
        }
        return folder;
      });
    case success("DELETE_FOLDER"):
      return state.map((folder) => {
        if (folder.id === requestData.folderId) {
          return {
            ...folder,
            statusCode: StatusCode.Trashed

          };
        }
        return folder;
      });
    case success("ARCHIVE_FOLDER"):
      return state.map((folder) => {
        if (folder.id === requestData.folderId) {
          return {
            ...folder,
            statusCode: StatusCode.Archived
          };
        }
        return folder;
      });
    case success("DELETE_FOLDER_PERMANENTLY"): {
      const folderToDelete = state.find((folder) => folder.id === requestData.folderId);

      let newState = state.filter((folder) => folder.id !== requestData.folderId);

      if (folderToDelete.parentId !== EMPTY_GUID) {
        newState = newState.map((folder) => {
          if (folder.id === folderToDelete.parentId) {
            return {
              ...folder,
              linkedFolders: folder.linkedFolders.filter((id) => id !== requestData.folderId),
            };
          }
          return folder;
        });
      }

      return newState;
    }

    case success("RESTORE_FOLDER"):
      return state.map((folder) => {
        if (folder.id === requestData.folderId) {
          return {
            ...folder,
            statusCode: StatusCode.Active

          };
        }
        return folder;
      });

    case success("CREATE_FOLDER"): {
      const { data } = action.response;
      let newState = [...state, data];

      if (data.parentId !== EMPTY_GUID) {
        newState = newState.map((folder) => {
          if (folder.id === data.parentId) {
            return {
              ...folder,
              linkedFolders: [...folder.linkedFolders, data.id],
            };
          }
          return folder;
        });
      }

      return newState;
    }


    case "UPDATE_LINKED_FOLDERS": {
      const { folderIds, idsToLink, idsToUnlink, entityName } = action?.request?.data;

      const newState = state.map(folder => {
        if (folderIds.includes(folder.id)) {
          // Make a copy of the folder to avoid modifying the original
          const updatedFolder = { ...folder };

          switch (entityName) {
            case "cem_customerjourney":
              updatedFolder.linkedCjms = Array.from(new Set([...updatedFolder.linkedCjms, ...idsToLink]));
              updatedFolder.linkedCjms = updatedFolder.linkedCjms.filter(cjm => !idsToUnlink.includes(cjm));
              break;
            case "cem_persona":
              updatedFolder.linkedPersonas = Array.from(new Set([...updatedFolder.linkedPersonas, ...idsToLink]));
              updatedFolder.linkedPersonas = updatedFolder.linkedPersonas.filter(cjm => !idsToUnlink.includes(cjm));
              break;

            case "cem_cxopportunity":
              updatedFolder.linkedOpps = Array.from(new Set([...updatedFolder.linkedOpps, ...idsToLink]));
              updatedFolder.linkedOpps = updatedFolder.linkedOpps.filter(cjm => !idsToUnlink.includes(cjm));
              break
            case "cem_cxactiontask":
              updatedFolder.linkedActions = Array.from(new Set([...updatedFolder.linkedActions, ...idsToLink]));
              updatedFolder.linkedActions = updatedFolder.linkedActions.filter(cjm => !idsToUnlink.includes(cjm));
              break
            case "cem_cxsolution":
              updatedFolder.linkedSols = Array.from(new Set([...updatedFolder.linkedSols, ...idsToLink]));
              updatedFolder.linkedSols = updatedFolder.linkedSols.filter(cjm => !idsToUnlink.includes(cjm));
              break
            case "cem_cxprogram":
              updatedFolder.linkedCxPrograms = Array.from(new Set([...updatedFolder.linkedCxPrograms, ...idsToLink]));
              updatedFolder.linkedCxPrograms = updatedFolder.linkedCxPrograms.filter(cjm => !idsToUnlink.includes(cjm));
              break
            case "cem_cxproject":
              updatedFolder.linkedProjects = Array.from(new Set([...updatedFolder.linkedProjects, ...idsToLink]));
              updatedFolder.linkedProjects = updatedFolder.linkedProjects.filter(cjm => !idsToUnlink.includes(cjm));
              break
            case "cem_folder":
              updatedFolder.linkedFolders = Array.from(new Set([...updatedFolder.linkedFolders, ...idsToLink]));
              updatedFolder.linkedFolders = updatedFolder.linkedFolders.filter(cjm => !idsToUnlink.includes(cjm));
              break
            case "cem_insight":
              updatedFolder.linkedInsights = Array.from(new Set([...updatedFolder.linkedInsights, ...idsToLink]));
              updatedFolder.linkedInsights = updatedFolder.linkedInsights.filter(cjm => !idsToUnlink.includes(cjm));
              break
          }
          return updatedFolder;
        }
        if (idsToLink.includes(folder.id)) {
          const updatedFolder = { ...folder };
          updatedFolder.parentId = folderIds[0];
          return updatedFolder;
        }
        return folder;
      });
      return newState;
    }


    default:
      return state;
  }
};

export default FolderReducer;
