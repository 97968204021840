import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights = undefined;
export const initializeAppInsights = () => {

  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: "97fee9f8-650a-4839-9925-86a517654f28",
      }
    });

    appInsights.loadAppInsights();
    // Intercept console log methods
    const oldConsoleWarn = console.warn;
    const oldConsoleError = console.error;

    console.warn = function () {
      oldConsoleWarn.apply(this, arguments);
      appInsights.trackTrace({
        message: Array.prototype.slice.call(arguments).join(' '),
        severityLevel: 2
      });
    }
    console.error = function () {
      oldConsoleError.apply(console, arguments);
      appInsights.trackTrace({
        message: Array.prototype.slice.call(arguments).join(' '),
        severityLevel: 3
      });
    }
  }
};

export const trackPageView = () => {
  appInsights.trackPageView();
};

export const trackEvent = (name, properties) => {
  appInsights.trackEvent({ name, properties });
};

