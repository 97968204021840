import { updatePersonaLinksWithPersonas } from "@redux/index";
import { useTranslation } from "react-i18next";
import { GenericButton } from "shared/ui-basics/index";

const ActionLinkPersona = (persona, linkedPersonas, personaCurrentId) => {
    const { t } = useTranslation()

    const { personaId } = persona

    const unlinkPersona = (e) => {
        updatePersonaLinksWithPersonas({personaIdsToLink:linkedPersonas.filter(x => x !== personaId).join("|"), personaIdsToUnlink:personaId,personaIdStr:personaCurrentId});
    }
    return <div className='d-flex justify-content-end'>
        <GenericButton variant="light-link" disableIfEllipsis={false} tooltipText={t("UNLINK_PERSONA")} onClick={unlinkPersona} icon={"UNLINK"} />
    </div>
}
export default ActionLinkPersona