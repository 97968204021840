import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import { updatePersonaName } from "@redux/index";
import { urlOriginStatus } from "@utils/optionsSets/OptionSets";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import EditTitle from "../../../shared/components/EditableText";
import * as Style from "./Style";

export const isEllipsisActiveFun = (id) => {
	const e = $("persona-name-" + id);
	return e[0]?.offsetWidth < e[0]?.scrollWidth;
};

export default function PersonaName(persona, rename, setRename, allowClick) {
	const [personaName, setName] = React.useState(persona.name);
	const { pathname, search } = useLocation();
	const emotionLevel = Math.round(persona?.sentimentLevel);
	const { cxProgramId } = search.getCxProps();
	const folderId = search.getFolderId();
	const history = useHistory();
	const blank = pathname === "/libraries" ? "_blank" : "";
	const Link = `/EditPersona?personaIdStr=${persona.personaId}${folderId ? `&folderid=${folderId}` : ""}`;


	useEffect(() => {
		setName(persona.name);
	}, [persona]);

	const handleRedirect = (e) => {
		if (pathname === "/EditCxProgram") {
			e.preventDefault(); // Prevents the default Link behavior
			history.push(`/EditPersona?personaIdStr=${persona.personaId}${cxProgramId ? `&origin=${urlOriginStatus.DefaultReturn}` : ""}${folderId ? `&folderid=${folderId}` : ""}`);
		}
		if (pathname === "/EditPersona") {
			e.preventDefault(); // Prevents the default Link behavior
			history.push(`/EditPersona?personaIdStr=${persona.personaId}${`&origin=${urlOriginStatus.DefaultReturn}`}${folderId ? `&folderid=${folderId}` : ""}`);

		}


	};

	return (
		<div className="d-flex w-100 align-items-center">
			<div className="m-1 position-relative">
				<PersonaBubble className="me-0" persona={persona} size={"40"} />

				{emotionLevel > 0 && (
					<Style.PersonaPosition>
						<EmotionLevel variant="smiley" size="xxs" level={emotionLevel} showTooltip={false} />
					</Style.PersonaPosition>
				)}
			</div>
			{rename !== persona.personaId && (
				<Style.Redirect className='w-100'  isinactive={(!allowClick).toString()} onClick={handleRedirect} target={blank} to={Link}>
					<CustomTooltip id={persona.name} className='w-100' text={personaName} disabled={personaName.length < 30}>
						<Style.StyledName id={"persona-name-" + persona.personaId}>{personaName}</Style.StyledName>
					</CustomTooltip>
				</Style.Redirect>
			)}
			{rename === persona.personaId && <EditTitle obj_id={persona.personaId} obj_name={personaName} setName={setName} updateName={updatePersonaName} setRename={setRename} />}
		</div>
	);
}
