import { useSelector } from "react-redux";
import styled from "styled-components";
import AIWidget from "./widgets/ai-widget.jsx";
import AnalyticsWidget from "./widgets/analytics-widget.jsx";
import IntegrationWidget from "./widgets/integration-widget.jsx";
import { UserType } from "@utils/optionsSets/OptionSets.js";

export default function Widgets() {
    const userInfo = useSelector((state) => state.auth?.userInfo);

	const userType = Number(userInfo.UserType);
	const isUser = userType === UserType.user;
	const isEnterprise = userInfo.UserSubscriptionPlanName?.toLowerCase() === "enterprise";

	return (
		<Container>
			<AIWidget isEnterprise={isEnterprise} />
			{isEnterprise && (
				<>
					<AnalyticsWidget />
                    {!isUser && <IntegrationWidget />}
				</>
			)}
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	display: flex;
	gap: 16px;
`;
