import { cxGoalsUpsert } from "@redux/index";
import { projectBusinessCase } from "@redux/memoizedSelectors";
import { EMPTY_GUID } from "@utils/helpers";
import { InformationType } from "@utils/optionsSets/OptionSets";
import TargetCard from "features/forms/components/sections/TargetSection/TargetCard";
import TargetForm from "features/forms/components/sections/TargetSection/TargetForm";
import { deleteAttributeItem, upsertAttributeItem } from "features/projects/reducer/ProjectsActions";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import { IconDispatcher } from "shared/Icons";
import { CxGoalCard } from "shared/components/cards/cx-goals/CxGoalCard";
import { ActionsDropdown } from "shared/dropdowns/ActionsDropdown";
import { ActionConfirmModal, EditCxGoalModal } from "shared/modals/index";
import { LibrariesLinks } from "shared/modals/library-links/LibrariesLinks";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import * as Style from "../SectionsStyling";
import ProjectFinancialImpact from "./ProjectFinancialImpact";

export const ProjectBusinessCase = ({ setActiveLink, innerRef }) => {
	const { t } = useTranslation();
	const panelRef = useRef(null);

	const { id: projectId, attributesList } = useSelector(projectBusinessCase);
	const allCxGoals = useSelector((state) => state.libraries.cxGoals);
	const roleName = useSelector((s) => s.projects?.currentUser?.roleName)?.toLowerCase();

	const hideForPdf = useLocation().search.hideForPdf();

	const targetAttribute = attributesList?.find((attr) => attr.type === InformationType.Target);
	const kpiAttribute = attributesList?.find((attr) => attr.type === InformationType.KPIs); // prototype
	const isViewer = roleName === "viewer";
	const isOwner = roleName === "owner";

	const cxGoals = [];
	kpiAttribute?.items?.forEach((item) => {
		const currentCxGoal = allCxGoals.find((cxGoal) => cxGoal.id === item.cxGoalId);
		if (currentCxGoal) cxGoals.push(currentCxGoal);
	});

	const { ref: inViewRef } = useInView({
		onChange: (inView) => {
			if (inView) setActiveLink("projectBusinessCase");
		},
		threshold: 0.5,
		root: innerRef.current,
	});

	const addSelectedKpis = ({ CxGoals }) => {
		CxGoals.forEach((cxGoal) => {
			upsertAttributeItem({
				attributeItem: { id: EMPTY_GUID },
				attribute: kpiAttribute,
				cxGoal,
				displayToast: "UPDATE",
			});
		});
	};

	const deleteKpi = ({ cxGoal }) => {
		const kpiAttributeItem = kpiAttribute?.items?.find((item) => item.cxGoalId === cxGoal.id);
		deleteAttributeItem({ projectId, attributeId: kpiAttribute.id, attributeItemId: kpiAttributeItem?.id, cXGoalId: cxGoal.id });
	};

	const [showModalLibrary] = useModal(LibrariesLinks, {
		context: "kpis",
		indexLibrary: -1,
		addSelected: addSelectedKpis,
	});

	const saveTarget = ({ selectedTarget, name, description, metrics }) =>
		upsertAttributeItem({
			attributeItem: { id: selectedTarget ? selectedTarget.id : EMPTY_GUID, name, description, metrics },
			attribute: targetAttribute,
			displayToast: selectedTarget ? "UPDATE" : "CREATE",
		});

	const deleteTarget = ({ itemId }) => deleteAttributeItem({ projectId, attributeId: targetAttribute.id, attributeItemId: itemId });

	const createCardActions = ({ cxGoal }) => <CardActions cxGoal={cxGoal} deleteKpi={deleteKpi} isEditAllowed={!isViewer} isDeleteAllowed={isOwner} />;

	return (
		<Style.Section id="projectBusinessCase" ref={inViewRef}>
			<Style.CustomRow>
				<Col xs={12}>
					<Style.SectionTitle>{t("BUSINESS_CASE")}</Style.SectionTitle>
				</Col>
			</Style.CustomRow>
			<Style.CustomRow className="mb-4 gx-5">
				<ProjectFinancialImpact isViewer={isViewer} />
			</Style.CustomRow>

			<Style.CustomRow className="gx-5">
				<Col xs={6}>
					<div>
						<Style.CustomLabel>{t("TARGETS")}</Style.CustomLabel>
						<AttributeItemsContainer>
							{targetAttribute?.items?.map((item, index) => (
								<TargetCard key={index} item={item} saveTarget={saveTarget} deleteTarget={deleteTarget} roleName={roleName} />
							))}
						</AttributeItemsContainer>
						{!hideForPdf && (
							<GenericButton
								className={targetAttribute?.items.length > 0 && "mt-3"}
								variant="dashed-light-link"
								size="xl"
								icon="SQUARE_PLUS"
								iconClassName="me-1"
								onClick={(e) => panelRef.current.toggle(e)}
								disabled={isViewer}>
								{t("ASPX_75")}
							</GenericButton>
						)}
						<OverlayPanel ref={panelRef}>
							<TargetForm saveTarget={saveTarget} panelRef={panelRef} isViewer={isViewer} />
						</OverlayPanel>
					</div>
				</Col>
				<Col xs={6}>
					<div>
						<Style.CustomLabel>{t("KPIS")}</Style.CustomLabel>
						<AttributeItemsContainer>
							{cxGoals?.map((cxGoal) => (
								<CardContainer key={cxGoal.id}>
									<CxGoalCard cxGoal={cxGoal} createCardActions={() => createCardActions({ cxGoal })} isEditable={!isViewer} noBorder={true} />
								</CardContainer>
							))}
						</AttributeItemsContainer>
						{!hideForPdf && (
							<GenericButton
								className={cxGoals?.length > 0 && "mt-2"}
								variant="dashed-light-link"
								size="xl"
								icon="SQUARE_PLUS"
								iconClassName="me-1"
								onClick={showModalLibrary}
								disabled={isViewer}>
								{t("KPI")}
							</GenericButton>
						)}
					</div>
				</Col>
			</Style.CustomRow>
		</Style.Section>
	);
};

const CardActions = ({ cxGoal, deleteKpi, isEditAllowed, isDeleteAllowed }) => {
	const { t } = useTranslation();

	const deleteCxGoal = () => deleteKpi({ cxGoal });
	const updateCxGoal = (updatedCxGoal) => cxGoalsUpsert({ CxGoals: [updatedCxGoal] });

	const [showEditModal] = useModal(EditCxGoalModal, {
		cxGoal,
		handleAction: updateCxGoal,
	});

	const createActions = () => {
		const actions = [];
		if (isEditAllowed) actions.push({ handleClick: showEditModal, icon: "EDIT", text: t("EDIT") });
		if (isDeleteAllowed) actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", text: t("ASPX_13") });
		return actions;
	};

	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_KPI_GENERIC",
		Action: deleteCxGoal,
		actionBtnText: "ASPX_13",
	});

	return (
		<Actions id="actions-list">
			<ActionsDropdown
				id={cxGoal.id}
				actions={createActions()}
				toggleButton={IconDispatcher("ACTION-DROPDOWN-HORIZONTAL")}
				autoClose="outside"
				handleOnSelect={(eventKey, e) => e.stopPropagation()}
				handleToggle={(show, e) => e?.originalEvent?.stopPropagation()}
				isicon="true"
			/>
		</Actions>
	);
};

const AttributeItemsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const Actions = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	z-index: 2;
	display: none;
`;

const CardContainer = styled.div`
	padding: 16px;
	border-radius: 4px;
	border: 1px solid #d9d9d9;
	:hover {
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
	}
`;
