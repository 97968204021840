import { addGroup, linkGroups, linkTags, updateFolderLinks, upsertTag } from "@redux/index";
import { EMPTY_GUID, User } from "@utils/helpers";
import { linkTagsService } from "features/index";
import { t } from "i18next";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FlexAlignCenter, GenericButton, IconDispatcher, SearchBar } from "shared/ui-basics/index";
import EditableItem from "shared/ui-basics/ui-components/EditableItem";
import styled from "styled-components";
import { CheckBoxWrapper } from "../library-links/Style";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";

const transferData = (entityType, pathname, idAttribute, folderId) => {
	let recordType = "";
	const data = {};
	switch (pathname) {
		case "/solutions":
			recordType = "cem_cxsolution";
			break;
		case "/cx-actions":
			recordType = "cem_cxactiontask";
			break;
		case "/opportunity":
			recordType = "cem_cxopportunity";
			break;
		case "/cx-program":
			recordType = "cem_cxprogram";
			break;
		case "/journey-map":
			recordType = "cem_customerjourney";
			break;
		case "/personas":
			recordType = "cem_persona";
			break;
		case "/projects":
			recordType = "cem_cxproject";
			break;
		case "/insights":
			recordType = "cem_insight";
			break;
		default:
			break;
	}
	const linkTag = (selectedFromList, selectedIds) => {
		linkTags({ tagIds: selectedFromList, recordIds: selectedIds?.map((x) => x[idAttribute]), recordType: recordType });
	};
	const linkGroupsFun = (selectedFromList, selectedIds) => {
		linkGroups({ groupIds: selectedFromList, recordIds: selectedIds?.map((x) => x[idAttribute]), recordType: recordType });
	};
	const linkMapsToFolders = (selectedFromList, selectedIds, entityName) => {
		const folderIds = selectedFromList;
		const idsToLink = selectedIds?.map((x) => x[idAttribute]);

		updateFolderLinks({ folderIds, idsToLink, entityName });
	};
	switch (entityType) {
		case "folder": {
			data.path = "folders";
			data.ViewList = FolderListView;
			data.onUpdate = (selectedFromList, selectedIds) => linkMapsToFolders(selectedFromList, selectedIds, recordType);
			data.label = t("MOVE_TO_FOLDER");
			data.submitLabel = t("MOVE");
			break;
		}
		case "subFolder": {
			data.path = "folders";
			data.ViewList = FolderListView;
			data.onUpdate = (selectedFromList, selectedIds) => updateFolderLinks({ folderIds: selectedFromList, idsToLink: [folderId], entityName: "cem_folder" });
			data.label = t("MOVE_TO_FOLDER");
			data.submitLabel = t("MOVE");

			break;
		}
		case "tag": {
			const defaultTag = {
				id: EMPTY_GUID,
				name: "",
				colorCode: "#E9ECEF",
				isCustom: true,
			};
			data.label = t("SELECT") + " " + t("TAG_S");
			data.path = "libraries.tags";
			const ViewListTag = ({ object, selectedFromList }) => {
				return <EditableItem objectId={object?.id} itemSelectorPath="libraries.tags" mode="selectable" selectedFromList={selectedFromList} />;
			};

			data.ViewList = ViewListTag;
			data.onUpdate = linkTag;
			data.createAction = (name) => upsertTag({ tag: { ...defaultTag, name } });

			break;
		}
		case "group": {
			data.label = t("SELECT") + " " + t("GROUP_S");
			data.path = "libraries.group";
			const ViewListGroup = ({ object, selectedFromList }) => {
				return <EditableItem objectId={object?.id} itemSelectorPath="libraries.group" mode="selectable" title="GROUPS" selectedFromList={selectedFromList} />;
			};
			data.ViewList = ViewListGroup;
			data.onUpdate = linkGroupsFun;
			data.createAction = (name) => addGroup({ newGroup: { name } });
			break;
		}
	}
	return data;
};

const FolderListView = ({ object, selectedFromList, folderId, componentName }) => {
	const buttonType = componentName === "subFolder" ? "radio" : "checkbox";
	return (
		<WrapperDiv className="p-2 my-2" key={object?.id} id={object?.id}>
			<FlexAlignCenter gap="16">
				<CheckBoxWrapper type={buttonType} className="m-1" checked={selectedFromList.includes(object?.id)} onChange={() => {}} />
				<FlexAlignCenter>
					{IconDispatcher("folder-open", "pe-2", { color: "#F96209" })}
					<NameContainer title={object?.name}>{object?.name}</NameContainer>
				</FlexAlignCenter>
			</FlexAlignCenter>
			<OwnerFolder folder={object} />
		</WrapperDiv>
	);
};

const BulkModalLinkComponent = ({ isOpen, onClose, componentName, idAttribute, folderId }) => {
	const { t } = useTranslation();
	const pathname = useLocation().pathname;

	const data = transferData(componentName, pathname, idAttribute, folderId);
	const { ViewList } = data;
	let listData = useSelector((state) => _.get(state, data.path));
	if (["folder", "subFolder"].includes(componentName)) {
		listData = listData
			?.filter((x) => (folderId ? x?.id !== folderId : true))
			?.getActiveRecords()
			.getMainFolders();
	}

	const [selectedFromList, setSelectedFromList] = useState([]);
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);
	const [globalFilterValue, setGlobalFilterValue] = useState("");

	const addToList = (e) => {
		const id = e.currentTarget.id;
		if (componentName === "subFolder") {
			setSelectedFromList([id]);
			return;
		}
		const index = selectedFromList.indexOf(id);
		if (index === -1) {
			setSelectedFromList([...selectedFromList, id]);
		} else {
			const newSelectedMaps = selectedFromList.filter((item) => item !== id);
			setSelectedFromList(newSelectedMaps);
		}
	};
	const update = () => {
		data.onUpdate(selectedFromList, selectedIds);
		onClose();
	};
	const handleChange = (e) => {
		setGlobalFilterValue(e.target.value);
	};
	const filteredList = listData?.filter((data) => data?.name.toUpperCase().includes(globalFilterValue.toUpperCase()));

	return (
		<Modal size="m" fade={false} isOpen={isOpen} centered={true} toggle={onClose}>
			<ModalHeader className="py-3" toggle={onClose}>
				<span>{data?.label}</span>
			</ModalHeader>
			<Body>
				<div className="pb-3">
					<SearchBar globalFilterValue={globalFilterValue} handleChange={handleChange} floatLabel={false} createAction={filteredList?.length === 0 && data.createAction} />
				</div>
				<WrapperBody componentName={componentName}>
					{filteredList?.map((object, index) => (
						<div key={index} id={object.id} onClick={addToList}>
							<ViewList object={object} selectedFromList={selectedFromList} folderId={folderId} componentName={componentName} />
						</div>
					))}
				</WrapperBody>
			</Body>
			<ModalFooter className="d-flex justify-content-end">
				<GenericButton variant="primary" onClick={update} disabled={selectedFromList?.length === 0}>
					{data.submitLabel ? data.submitLabel : t("ADD_SELECTED")}
				</GenericButton>
			</ModalFooter>
		</Modal>
	);
};
export default BulkModalLinkComponent;

const OwnerFolder = ({ folder }) => {

	return <InitialsBubble key={folder?.id} fullName={folder?.createdByName} size='s' />;
};

const WrapperBody = styled.div`
	display: flex;
	${(props) => ["folder", "subFolder"].includes(props.componentName) && `display:block;`}
	${(props) => ["folder", "subFolder"].includes(props.componentName) && `flex-direction:column;`}
	height:fit-content;
	overflow-y: scroll;
	justify-content: start;
	flex-wrap: wrap;
	gap: 5px;
	max-height: 80%;
`;

const WrapperDiv = styled.div`
	display: flex;
	justify-content: space-between;
	height: 68px;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	align-items: center;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.disabled &&
		`
		opacity: 0.5;
		pointer-events: none;
	`}
`;

const Body = styled(ModalBody)`
	height: 300px;
`;

const NameContainer = styled.div`
	width: 300px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
