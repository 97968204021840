import { updateSolution } from "@redux/index";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dropdown, DropdownItem } from "reactstrap";
import CustomTooltip from "shared/components/CustomTooltip";
import { IconDispatcher } from "shared/ui-basics/index";
import * as Style from "./style";

export default function DisplayStatus({ object, setObject, style, disable, name, updateFunction, size = "m", attKey = "statusId" }) {
	const { t } = useTranslation();
	const pathname = useLocation().pathname;
	const tooltipRef = useRef(null);

	const [toggleStatus, setToggleStatus] = React.useState(false);
	const allStatus = useSelector((state) => state.libraries?.status);
	const status = allStatus && allStatus[name]?.filter((status) => !status.name.isEmptyString());
	const selectedStatus = getSelected(status, object, attKey);
	const showPlaceHolder = Object.keys(selectedStatus)?.length === 0;
	const toggle = () => setToggleStatus(!toggleStatus);
	const borderVisible = style?.border || ["/home", "/journey-map", "/opportunity", "/cx-actions", "/solutions", "/personas", "/cx-program", "/projects"].includes(pathname) ? "false" : "true"; //border not visible on table
	const [isEllipsisActive, setIsEllipsisActive] = useState(false);
	const isInactive = ['/trash', '/archive'].includes(pathname);
	useEffect(() => {
		if (tooltipRef?.current?.offsetWidth < tooltipRef?.current?.scrollWidth) {
			setIsEllipsisActive(true);
		}
	}, [tooltipRef]);

	const handleClick = (value) => {
		const updatedObject = { ...object, statusId: value };
		setObject && setObject(updatedObject);
		if (updateFunction) updateFunction(updatedObject, ["statusId"]);
		else updateSolution({modifiedSolution:updatedObject, modifiedAttribNames: ["statusId"]});
	};
	if(isInactive && !selectedStatus.name) return null;
	return (
		<>
			{status && (
				<Dropdown isOpen={toggleStatus} toggle={toggle} direction={"down"} disabled={disable} className={style?.Dropdown}>
					<Style.ToggleCaret bordervisible={borderVisible} disabled={disable} size={size} className={`w-100 ${style?.dropdownToggle}`}>
						{showPlaceHolder ? (
							<div className="overflow-hidden ellipsis">{disable ? t("NO_STATUS") : t("SELECT_VALUE")}</div>
							) : (
							<>
								<Style.StatusTypes className="" toggle="true" status={object?.statusId} colorcode={selectedStatus.colorCode}>
									{selectedStatus.name}
								</Style.StatusTypes>
								<div className="caret">{IconDispatcher(toggleStatus ? "ANGLE-UP" : "ANGLE-DOWN")}</div>
							</>
						)}
					</Style.ToggleCaret>
					<Style.MenuDrop className="p-2">
						{status?.map((status) => (
							<DropdownItem className="p-2" key={status?.id} onClick={() => handleClick(status.id)}>
								<CustomTooltip text={status?.name} disabled={!isEllipsisActive}>
									<Style.StatusTypes ref={tooltipRef} className="w-100" colorcode={status.colorCode}>
										{status.name}
									</Style.StatusTypes>
								</CustomTooltip>
							</DropdownItem>
						))}
					</Style.MenuDrop>
				</Dropdown>
			)}
		</>
	);
}

const getSelected = (status, object, attKey) => {
	if (status?.length > 0) {
		const found = status?.find((prio) => prio.id === object?.[attKey]);
		if (found) return found;
		else return {};
	} else {
		return {};
	}
};
