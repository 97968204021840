import InsightType from "shared/ui-basics/ui-components/InsightType";
import { GenericFilter, PickListSingleFilterTemplate, balanceFilterTemplate, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { selectGroup, selectQueryView, selectSelectedViewId, selectTag } from "@redux/memoizedSelectors";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { alphabeticSort, checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Groups from "../../columns-components/Groups";
import { JourneyMaps } from "../../columns-components/JourneyMaps";
import Personas from "../../columns-components/Personas";
import { Description, InsightName } from "../../columns-components/ReusableListComponents";
import Tags from "../../columns-components/Tags";

export const GetInsightColumns = (props) => {
	const context = props.context.split(".")[1];
	const selectedViewId = useSelector((state) => selectSelectedViewId(state, EntityList.Insight, context));
	const queryView = useSelector((state) => selectQueryView(state, EntityList.Insight))?.getById(selectedViewId)?.columnSetting;
	const insightTypes = useSelector(({ libraries }) => libraries?.insightTypes)?.map(insightTypes => ({ value: insightTypes.id, name: insightTypes?.name }));
	const maps = useSelector((state) => state.maps.all_maps);

	const groups = useSelector(selectGroup);
	const tags = useSelector(selectTag);

	const list = props?.contextData?.listData;
	function groupUniqueIdsWithNames(objects) {
		let uniqueIdsMap = new Map();

		objects.forEach(obj => {
			obj.cjmIds.forEach(id => {
				if (!uniqueIdsMap.has(id)) {
					const cjm = maps?.find((map) => map.cjmId === id);
					uniqueIdsMap.set(id, cjm.name);
				}
			});
		});

		let result = [];
		uniqueIdsMap.forEach((name, id) => {
			result.push({ value: id, name });
		});

		return result.sort(alphabeticSort);
	}
	const journeyMapFilters = groupUniqueIdsWithNames(list);

	return {
		insightName: {
			field: "name",
			header: t("ASPX_8"),
			body: (insight) => InsightName(insight, { allowClick: props?.contextData?.clickToOpen }),
			sortable: true,
			filterField: "name",
			filter: true,
			showFilterOperator: false,
			style: checkStyle(queryView, "insightName", "xxl"),
			className: "py-0",
		},
		linkedPersonas: {
			field: "linkedPersonas",
			header: t("ASPX_98"),
			sortable: true,
			className: "py-0 ",
			body: Personas,
			style: checkStyle(queryView, "linkedPersonas", "s", "120px"),
		},
		occurance: {
			field: "occurance",
			filterField: "occurance",
			header: t("OCCURANCE"),
			sortable: true,
			filter: true,
			showFilterOperator: false,
			filterElement: balanceFilterTemplate,
			dataType: "numeric",
			className: "py-0 text-center",
			style: checkStyle(queryView, "occurance", "s", "120px"),
		},
		cjmIds: {
			field: "cjmIds",
			header: t("JOURNEY_MAP"),
			filterField: "cjmIdsStr",
			sortable: true,
			showAddButton: false,
			showFilterOperator: false,
			body: (ac) => JourneyMaps(ac),
			showFilterMatchModes: false,
			filter: true,
			style: checkStyle(queryView, "cjmIds", "xl"),
			filterElement: (options) => PickListSingleFilterTemplate(options, journeyMapFilters, t("SELECT_VALUE")),

		},
		insightType: {
			field: "typeId",
			filterField: "typeId",
			header: t("JS_78"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			showFilterMatchModes: false,
			filterElement: (options) => GenericFilter(options, insightTypes),
			className: "py-0 ",
			body: ({ typeId }) => InsightType({ typeId }),
			style: checkStyle(queryView, "insightType", "m", "120px"),

		},
		lastUpdated: {
			field: "lastUpdated",
			filterField: "lastUpdated",
			header: t("JS_72"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			style: checkStyle(queryView, "lastUpdated", "l"),
			dataType: "date",
			body: (insight) => dateBodyTemplate(insight.lastUpdated, "action"),
			filterElement: dateFilterTemplate,
		},
		groupIds: {
			field: "groupIds",
			filterField: "groupIds",
			header: t("GROUPS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Groups(action, EntityList.Opportunity, context),
			style: checkStyle(queryView, "groupIds", "m", "120px"),
			filterElement: (options) => PickListSingleFilterTemplate(options, groups, t("SELECT_GROUP")),
		},
		description: {
			field: "description",
			sortable: true,
			header: t("ASPX_9"),
			dataType: "text",
			style: checkStyle(queryView, "description", "m"),
			body: (ac) => Description(ac),

		},
		tagIds: {
			field: "tagIds",
			filterField: "tagIds",
			header: t("TAGS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Tags(action, EntityList.Opportunity, context),
			style: checkStyle(queryView, "tagIds", "m"),
			filterElement: (options) => PickListSingleFilterTemplate(options, tags, t("SELECT_TAG")),
		},

	};
};
