import GenericPopover from "shared/popovers/GenericPopover";
import styled from "styled-components";
import EditableItem from "../../../shared/ui-basics/ui-components/EditableItem";
import { GenericButton } from "shared/ui-basics/buttons";

const Tags = (object) => {
	const tagIds = object?.tags;

	const custom = (
		<div>
			{tagIds?.slice(2, Infinity).map((tag) => (
				<div key={tag} className="mb-1">
					<EditableItem objectId={tag} itemSelectorPath="libraries.tags" mode={"display"} />
				</div>
			))}
		</div>
	);
	return (
		<WrapperDiv className="d-flex">
			{tagIds?.slice(0, 2).map((tag) => (
				<div key={tag} className="mb-1 ms-1">
					<EditableItem objectId={tag} itemSelectorPath="libraries.tags" mode={"display"} />
				</div>
			))}
			{tagIds?.length > 2 && (
				<div className="ms-1">
					<GenericPopover custom={custom}>
						<GenericButton variant="light" iconClassName="ms-2" size={"xs"} className="p-1"  >
                            +{tagIds?.length - 2}
                        </GenericButton>
					</GenericPopover>
				</div>
			)}
		</WrapperDiv>
	);
};
const WrapperDiv = styled.div`
	display: -webkit-box;
	overflow: hidden;
`;
export default Tags;
const Counter = styled.div`
	height: 24px;
	padding: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #605f60;
	display: flex;

	background: #fff;
`;
