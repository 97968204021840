import { createCxProgram, createCxAction, createOpportunity, createPersona, createSolution } from "@redux/index";
import { createDefaultAction, createDefaultMap, createDefaultOpp, createDefaultPersona, createDefaultSol, encode, getEntityName } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { createJourneyMap } from "features/journey-map/reducer/CJMActions";
import _ from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Col } from "reactstrap";
import { ProjectRelatedItemCard } from "shared/components/cards/cx-cards/ProjectRelatedItemCard";
import SearchPickList from "shared/cx-components/SearchPickList";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components";
import { linkRecords, unlinkRecords } from "../reducer/ProjectsActions";
import * as Style from "./SectionsStyling";

export const ProjectRelatedItems = ({ setActiveLink, innerRef }) => {
	const { t } = useTranslation();
	const hideForPdf = useLocation().search.hideForPdf();

	const project = useSelector((state) => state.projects.current_project);
	const userInfo = useSelector((state) => state.auth.userInfo);

	const getLinkedItems = (linkedItemsIds, allLinkedItems, idAttribute = "id") => {
		return linkedItemsIds?.map((id) => allLinkedItems?.find((item) => item?.[idAttribute] === id));
	};

	const { ref: inViewRef } = useInView({
		onChange: (inView) => {
			if (inView) setActiveLink("projectRelatedItems");
		},
		threshold: 1,
		root: innerRef?.current,
	});

	const handleLinkRecord = ({ recordId, linkedAttribute, linkRelated }) => {
		linkRecords({ projectId: project.id, recToLinkEntityName: getEntityName(linkedAttribute), recToLinkIds: [recordId], linkRelated });
	};

	const handleUnlinkRecord = ({ recordId, linkedAttribute, unlinkRelated }) => {
		unlinkRecords({ projectId: project.id, recToUnlinkEntityName: getEntityName(linkedAttribute), recToUnlinkIds: [recordId], unlinkRelated });
	};

	const handleCreate = ({ entityType, globalFilterValue }) => {
		switch (entityType) {
			case EntityList.Opportunity: {
				const newOpportunity = createDefaultOpp(userInfo);
				newOpportunity.subject = encode(globalFilterValue);
				createOpportunity({ item: newOpportunity, displayToast: "CREATE", onSuccess: (response) => handleLinkRecord({ recordId: response.id, linkedAttribute: "linkedOpps" }) });
				break;
			}
			case EntityList.Solution: {
				const newSolution = createDefaultSol(userInfo);
				newSolution.subject = encode(globalFilterValue);
				createSolution({ item: newSolution, displayToast: "CREATE", onSuccess: (response) => handleLinkRecord({ recordId: response.id, linkedAttribute: "linkedSols" }) });
				break;
			}
			case EntityList.Action: {
				const newAction = createDefaultAction(userInfo);
				createCxAction({
					item: { ...newAction, subject: encode(globalFilterValue) },
					displayToast: "CREATE",
					onSuccess: (response) => handleLinkRecord({ recordId: response.id, linkedAttribute: "linkedActions" }),
				});
				break;
			}
			case EntityList.Persona: {
				const newPersona = createDefaultPersona();
				createPersona({
					item: { ...newPersona, name: globalFilterValue },
					displayToast: "CREATE",
					onSuccess: (response) => handleLinkRecord({ recordId: response.personaId, linkedAttribute: "linkedPersonas" }),
				});
				break;
			}
			case EntityList.CXProgram: {
				createCxProgram({ name: globalFilterValue, onSuccess: (response) => handleLinkRecord({ recordId: response.id, linkedAttribute: "linkedCxProgram" }) });
				break;
			}
			case EntityList.CustomerJourney: {
				const newMap = createDefaultMap(undefined, false);
				createJourneyMap({
					newMap: { ...newMap, name: globalFilterValue },
					onSuccess: (response) => handleLinkRecord({ recordId: response.cjmId, linkedAttribute: "linkedCjms" }),
					goToMap: false,
				});
				break;
			}
			default:
				break;
		}
	};

	const RelatedItemSection = ({ title, linkedAttribute, titleAttribute, idAttribute = "id", pathToAllRecords, entityType, addText, noItemsText, label, showConfirmAssociatedModal = false }) => {
		const ref = useRef();
		const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
		const allActiveRecords = useSelector((state) => _.get(state, pathToAllRecords)?.getActiveRecords(!showSampleData));
		const roleName = useSelector((s) => s.projects?.currentUser?.roleName)?.toLowerCase();

		const isViewer = roleName === "viewer";
		const isOwner = roleName === "owner";

		const linkedItems = getLinkedItems(project?.[linkedAttribute], allActiveRecords, idAttribute).filter(Boolean);

		return (
			<Col xs={6}>
				<RelatedSectionTitle className="mb-3">{t(title)}</RelatedSectionTitle>
				<CardsContainer>
					{linkedItems?.map((entity, i) => (
						<ProjectRelatedItemCard
							key={i}
							entity={entity}
							entityType={entityType}
							unlink={handleUnlinkRecord}
							linkedAttribute={linkedAttribute}
							idAttribute={idAttribute}
							showConfirmAssociatedModal={showConfirmAssociatedModal}
							isRemoveAllowed={isOwner}
						/>
					))}
				</CardsContainer>
				{!hideForPdf && (
					<AddButton variant="light-link" onClick={(e) => ref.current.toggle(e)} disabled={isViewer} aria-haspopup aria-controls="overlay_panel" icon="SQUARE_PLUS" iconClassName="me-1">
						{t(addText)}
					</AddButton>
				)}
				<SearchPickList
					ref={ref}
					currentItem={project}
					allItems={allActiveRecords}
					linkedAttribute={linkedAttribute}
					idAttribute={idAttribute}
					entityType={entityType}
					handleCreate={handleCreate}
					handleLinkRecord={handleLinkRecord}
					noItemsText={t(noItemsText)}
					titleAttribute={titleAttribute}
					label={label}
					showConfirmAssociatedModal={showConfirmAssociatedModal}
				/>
			</Col>
		);
	};

	const relatedItemsSectionsDic = [
		{
			title: "OPPORTUNITIES",
			linkedAttribute: "linkedOpps",
			titleAttribute: "subject",
			pathToAllRecords: "opportunity.all_Opportunities",
			entityType: EntityList.Opportunity,
			addText: "OPPORTUNITY",
			noItemsText: "EMPTY_OPPORTUNITY_LIST",
			label: "NEW_OPPORTUNITY_NAME",
		},
		{
			title: "SOLUTIONS",
			linkedAttribute: "linkedSols",
			titleAttribute: "subject",
			pathToAllRecords: "solutions.all_solutions",
			entityType: EntityList.Solution,
			addText: "SOLUTION",
			noItemsText: "EMPTY_SOLUTION_LIST",
			label: "NEW_SOLUTION_NAME",
		},
		{
			title: "JS_74",
			linkedAttribute: "linkedActions",
			titleAttribute: "subject",
			pathToAllRecords: "actions.all_actions",
			entityType: EntityList.Action,
			addText: "ACTION",
			noItemsText: "EMPTY_ACTION_LIST",
			label: "NEW_OPPORTUNITY_NAME",
		},
		{
			title: "JS_71",
			linkedAttribute: "linkedCjms",
			titleAttribute: "name",
			idAttribute: "cjmId",
			pathToAllRecords: "maps.all_maps",
			entityType: EntityList.CustomerJourney,
			addText: "JOURNEY_MAP",
			noItemsText: "ASPX_212",
			label: "NEW_OPPORTUNITY_NAME",
			showConfirmAssociatedModal: true,
		},
		{
			title: "JS_70",
			linkedAttribute: "linkedPersonas",
			titleAttribute: "name",
			idAttribute: "personaId",
			pathToAllRecords: "personas.all_personas",
			entityType: EntityList.Persona,
			addText: "ASPX_74",
			noItemsText: "ASPX_213",
			label: "NEW_SOLUTION_NAME",
		},
		{
			title: "CX_PROGRAMS",
			linkedAttribute: "linkedCxProgram",
			titleAttribute: "name",
			pathToAllRecords: "cxProgram.all_cxPrograms",
			entityType: EntityList.CXProgram,
			addText: "CX-PROGRAM",
			noItemsText: "EMPTY_CX_PROGRAM_LIST",
			label: "NEW_SOLUTION_NAME",
		},
	];
	const relatedItemsHasData = relatedItemsSectionsDic.some((s) => project?.[s.linkedAttribute]?.length > 0);
	if (!relatedItemsHasData && hideForPdf) return null;
	return (
		<Style.Section id="projectRelatedItems" ref={inViewRef}>
			<Style.CustomRow>
				<Col xs={12}>
					<Style.SectionTitle>{t("RELATED_ITEMS")}</Style.SectionTitle>
				</Col>
			</Style.CustomRow>
			<Style.CustomRow className="mb-5">
				{relatedItemsSectionsDic.slice(0, 2).map((s, index) => (
					<RelatedItemSection
						key={index}
						title={s.title}
						linkedAttribute={s.linkedAttribute}
						titleAttribute={s.titleAttribute}
						idAttribute={s.idAttribute}
						pathToAllRecords={s.pathToAllRecords}
						entityType={s.entityType}
						handleCreate={s.handleCreate}
						addText={s.addText}
						noItemsText={s.noItemsText}
						label={s.label}
						showConfirmAssociatedModal={s.showConfirmAssociatedModal}
					/>
				))}
			</Style.CustomRow>
			<Style.CustomRow className="mb-5">
				{relatedItemsSectionsDic.slice(2, 4).map((s, index) => (
					<RelatedItemSection
						key={index}
						title={s.title}
						linkedAttribute={s.linkedAttribute}
						titleAttribute={s.titleAttribute}
						idAttribute={s.idAttribute}
						pathToAllRecords={s.pathToAllRecords}
						entityType={s.entityType}
						handleCreate={s.handleCreate}
						addText={s.addText}
						noItemsText={s.noItemsText}
						label={s.label}
						showConfirmAssociatedModal={s.showConfirmAssociatedModal}
					/>
				))}
			</Style.CustomRow>
			<Style.CustomRow>
				{relatedItemsSectionsDic.slice(4, 7).map((s, index) => (
					<RelatedItemSection
						key={index}
						title={s.title}
						linkedAttribute={s.linkedAttribute}
						titleAttribute={s.titleAttribute}
						idAttribute={s.idAttribute}
						pathToAllRecords={s.pathToAllRecords}
						entityType={s.entityType}
						handleCreate={s.handleCreate}
						addText={s.addText}
						noItemsText={s.noItemsText}
						label={s.label}
						showConfirmAssociatedModal={s.showConfirmAssociatedModal}
					/>
				))}
			</Style.CustomRow>
		</Style.Section>
	);
};

const CardsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const AddButton = styled(GenericButton)`
	color: #605f60;
	font-size: 14px;
`;

const RelatedSectionTitle = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
