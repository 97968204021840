import { TrainingCard } from "features/training/components/TrainingCard";
import { useAsyncs } from "shared/hooks/index";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { getAllTrainings, getOrganizationSettings } from "@redux/index";
import { importAll } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

export default function Training() {
	const { t } = useTranslation();
	const trainings = useSelector((state) => state?.settings?.trainings);
	const images = importAll(require.context("@Assets/images/training", false, /\.(png|jpe?g|svg)$/));

	useAsyncs({
		asyncFns: [{ asyncFn: getAllTrainings }, { asyncFn: getOrganizationSettings }],
	});

    const getImage = (training) => {
		if (images[training.imgName + ".jpg"]) return images[training.imgName + ".jpg"].default;
		return images["default.png"].default;
	};

	const renderView = () => {
		return (
			<Wrap>
				{trainings?.map((training) => (
					<TrainingCard key={training.id} training={training} image={getImage(training)} />
				))}
			</Wrap>
		);
	};

	return <PageTemplate pageTitle={t("YOUR_ON_DEMAND_TRAINING_PROGRAM")}>{renderView()}</PageTemplate>;
}

const Wrap = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
