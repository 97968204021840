import { createAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import { toast } from "react-toastify";
import { store } from "../../..";
import {
	deleteAdvancedInteractionIcon,
	deleteIconSimpleInteraction,
	deleteIconsSwimlane,
	deleteStageAttribute,
	linkIconSwimlane,
	unLinkIconSwimlane,
	updateAdvancedInteractionIcon,
	updateIconSimpleInteraction,
	updateIconsSwimlane,
	updateStageAttribute,
} from "features/journey-map/reducer/CJMActions";

export const deleteIconCustomSwimlane = async ({ swimlaneKey, stageId, iconId, type, cjmIdStr, personaIdStr, cardId, specificKey }) => {
	const key = specificKey ? specificKey : type === 5 || type === 14 ? swimlaneKey + "_" + stageId : stageId;
	switch (type) {
		case 14:
			store.dispatch(deleteIconsSwimlane(swimlaneKey, stageId, iconId));
			break;
		case 5:
			store.dispatch(deleteIconsSwimlane(swimlaneKey, stageId, iconId));
			break;
		case 7:
			store.dispatch(deleteIconSimpleInteraction(personaIdStr, stageId, iconId, "consIcons"));
			break;
		case 8:
			store.dispatch(deleteIconSimpleInteraction(personaIdStr, stageId, iconId, "prosIcons"));
			break;
		case 15:
			store.dispatch(deleteAdvancedInteractionIcon(cardId, "icons", iconId));
			break;
		case 1:
			store.dispatch(deleteAdvancedInteractionIcon(cardId, "interactionIcons", iconId));
			break;
		case 26:
			store.dispatch(deleteAdvancedInteractionIcon(cardId, "ActionsIcons", iconId));
			break;
		case 9:
			store.dispatch(deleteStageAttribute(stageId, "globalInsightsIcons", iconId));
			break;
		case 10:
			store.dispatch(deleteStageAttribute(stageId, "descriptionIcons", iconId));
			break;
		case 12:
			store.dispatch(deleteStageAttribute(stageId, "issuesIcons", iconId));
			break;
		case 11:
			store.dispatch(deleteStageAttribute(stageId, "kpIsIcons", iconId));
			break;
		case 13:
			store.dispatch(deleteStageAttribute(stageId, "processesIcons", iconId));
			break;
		case 4:
			store.dispatch(deleteAdvancedInteractionIcon(cardId, "prosIcons", iconId));
			break;
		case 2:
			store.dispatch(deleteAdvancedInteractionIcon(cardId, "consIcons", iconId));
			break;
		case 3:
			store.dispatch(deleteAdvancedInteractionIcon(cardId, "insightsIcons", iconId));
			break;
		case 6:
			store.dispatch(deleteIconSimpleInteraction(personaIdStr, stageId, iconId, "actionsIcons"));
			break;
		case 19: {
			const deleteIconCxGoals = createAction("DELETE_ICON_SWIMLANE_CX_GOALS");
			store.dispatch(deleteIconCxGoals({ stageId, iconId, cardId }));
			break;
		}
		case 29:
		case 25:
		case 16:{
			const itemDELETE = createAction("DELETE_ICON_SWIMLANE_ITEMS");
			store.dispatch(itemDELETE({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 20: {
			const deleteIconOpportunity = createAction("DELETE_ICON_OPPORTUNITY");
			store.dispatch(deleteIconOpportunity({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 27:{
			const deleteIconSolution = createAction("DELETE_ICON_SOLUTION");
			store.dispatch(deleteIconSolution({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 21:{
			const deleteIconCxAction = createAction("DELETE_ICON_CXACTION");
			store.dispatch(deleteIconCxAction({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 24:{
			const deleteTimeLineIcon = createAction("DELETE_ICON_TIMELINE");
			store.dispatch(deleteTimeLineIcon({ stageId, iconId, cardId }));
			break;
		}
		case 28: {
			const deleteCustomerFeedbackIcon = createAction("DELETE_ICON_CUSTOMER_FEEDBACK");
			store.dispatch(deleteCustomerFeedbackIcon({ stageId, iconId, cardId, personaId: personaIdStr }));
			break;
		}
		default:
			break;
	}
	unLinkIconSwimlane({iconIdStr: iconId, objectIdStr:key, IconType:type, cjmIdStr, personaIdStr})

};
export const addIconCustomSwimlane = async ({ swimlaneKey, stageId, iconId, type, cjmIdStr, personaIdStr, cardId, specificKey }) => {
	const key = specificKey ? specificKey : [5, 14].includes(type) ? swimlaneKey + "_" + stageId : stageId;

	switch (type) {
		case 14:
			store.dispatch(updateIconsSwimlane(swimlaneKey, stageId, iconId));
			break;
		case 5:
			store.dispatch(updateIconsSwimlane(swimlaneKey, stageId, iconId));
			break;
		case 7:
			store.dispatch(updateIconSimpleInteraction(personaIdStr, stageId, iconId, "consIcons"));
			break;
		case 8:
			store.dispatch(updateIconSimpleInteraction(personaIdStr, stageId, iconId, "prosIcons"));
			break;
		case 15:
			store.dispatch(updateAdvancedInteractionIcon(cardId, "icons", iconId));
			break;
		case 1:
			store.dispatch(updateAdvancedInteractionIcon(cardId, "interactionIcons", iconId));
			break;
		case 26:
			store.dispatch(updateAdvancedInteractionIcon(cardId, "actionsIcons", iconId));
			break;
		case 9:
			store.dispatch(updateStageAttribute(stageId, "globalInsightsIcons", iconId));
			break;
		case 10:
			store.dispatch(updateStageAttribute(stageId, "descriptionIcons", iconId));
			break;
		case 12:
			store.dispatch(updateStageAttribute(stageId, "issuesIcons", iconId));
			break;
		case 11:
			store.dispatch(updateStageAttribute(stageId, "kpIsIcons", iconId));
			break;
		case 13:
			store.dispatch(updateStageAttribute(stageId, "processesIcons", iconId));
			break;
		case 4:
			store.dispatch(updateAdvancedInteractionIcon(cardId, "prosIcons", iconId));
			break;
		case 2:
			store.dispatch(updateAdvancedInteractionIcon(cardId, "consIcons", iconId));
			break;
		case 3:
			store.dispatch(updateAdvancedInteractionIcon(cardId, "insightsIcons", iconId));
			break;
		case 6:
			store.dispatch(updateIconSimpleInteraction(personaIdStr, stageId, iconId, "actionsIcons"));
			break;

		case 19:{
			const itemUpdateCxGoals = createAction("ADD_ICON_SWIMLANE_CX_GOALS");
			store.dispatch(itemUpdateCxGoals({ stageId, iconId, cardId }));
			break
		}
		case 29:
		case 25:
		case 16:{
			const itemUpdate = createAction("ADD_ICON_SWIMLANE_ITEMS");
			store.dispatch(itemUpdate({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 20:{
			const addIconOpp = createAction("ADD_ICON_OPPORTUNITY");
			store.dispatch(addIconOpp({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 27:{
			const addIconSol = createAction("ADD_ICON_SOLUTION");
			store.dispatch(addIconSol({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 21: {
			const addIconCxActions = createAction("ADD_ICON_CXACTION");
			store.dispatch(addIconCxActions({ swimlaneKey, stageId, iconId, cardId }));
			break;
		}
		case 24: {
			const updateTimeLineIcon = createAction("ADD_ICON_TIMELINE");
			store.dispatch(updateTimeLineIcon({ stageId, iconId, cardId }));

			break;
		}
		case 28: {
			const updateCustomerFeedbackIcon = createAction("ADD_ICON_CUSTOMER_FEEDBACK");
			store.dispatch(updateCustomerFeedbackIcon({ stageId, iconId, cardId, personaId: personaIdStr }));

			break;
		}
		default:
	}
	linkIconSwimlane({iconIdStr: iconId, objectIdStr:key, IconType:type, cjmIdStr, personaIdStr})

};
