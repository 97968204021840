import { upsertConnector } from "features/integrations/reducer/ConnectorAction";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";
import GenericSwitch, { GenericButton } from "shared/ui-basics/buttons";
import { GenericDropdown, Input, Label } from "shared/ui-basics/index";
import styled from "styled-components";


export const dataFrequency = [{ id: '100000000', value: "Single Time" },
{ id: '100000001', value: "Daily" },
{ id: '100000002', value: "Weekly" },
{ id: '100000003', value: "Monthly" },
{ id: '100000004', value: "Yearly" }]

export const FormConnector = ({ currentConnector, setSelectConnector, source, onClose, ...props }) => {
    const { t } = useTranslation();
    const connectors = useSelector((state) => state.settings.connector)?.filter(connector => connector.source === source) || [];
    const [toggle, setToggle] = useState(false);
    const [validated, setValidated] = useState(true);

    useEffect(()=>{
        setToggle(currentConnector?.enableConnection)
    },[currentConnector?.enableConnection])

    const handleChange = (e, type) => {
        let { name, value } = e.target;
        if (name === 'enableConnection') {
            setToggle(!toggle);
            value = !toggle;
        }
        setSelectConnector({ ...currentConnector, [type ?? name]: value });
    };
    const validation = () => {
        return currentConnector?.name !== "" && currentConnector?.token !== "" && currentConnector?.host;
    }

    const submit = (event) => {
        event.preventDefault();
        if (validation()) {
            upsertConnector({ connector: currentConnector, displayToast: "UPDATE" })
            onClose();
        }
        else {
            setValidated(false);
        }
    }

    return <WrapperForm id={currentConnector?.id} className="" onSubmit={submit}>


        <div className="d-flex flex-column gap-4 p-4">
            <div className="">
                <Label className="pb-2">{t("NAME")}</Label>
                <Input variant="default"
                    invalid={!currentConnector?.name && !validated}
                    name="name"
                    type="text"
                    value={currentConnector?.name }
                    onChange={handleChange}
                    placeholder={t("ENTER_NAME")} />
            </div>
            <div className="">
                <Label className="pb-2">{t("TOKEN")}</Label>
                <Input variant="default"
                    invalid={!currentConnector?.token && !validated}
                    name="token"
                    type="text"
                    value={currentConnector?.token || ""}
                    onChange={handleChange}
                    placeholder={t("ENTER_TITLE")} />
            </div>
            <div className="">
                <Label className="pb-2">{t("HOST")}</Label>
                <Input variant="default"
                    invalid={!currentConnector?.host && !validated}
                    name="host"
                    type="text"
                    value={currentConnector?.host || ""}
                    onChange={handleChange}
                    placeholder={t("ENTER_TITLE")} />
            </div>
            <div className="">
                <Label className="pb-2">{t("RETRIEVE_DATA_FREQUENCY")}</Label>

                <GenericDropdown icon border options={dataFrequency} value={currentConnector.retrieveDataFrequency ?? '100000000'} onClick={(e) => { handleChange(e, "retrieveDataFrequency") }} />
            </div>

            <div className="d-flex ">
                <Label className="pe-4">{t("ENABLE_CONNECTION")}</Label>
                <GenericSwitch  isChecked={toggle} name='enableConnection' handleToggle={handleChange} />
            </div>
        </div>
        <WrapperFooter className=" p-4">
            <div>
                {connectors?.length > 0 && <GenericButton className="me-2 " variant="light-link" icon={"ARROW_LEFT"} onClick={props.previousStep} >
                    {t("JS_155")}
                </GenericButton>}
            </div>

            <GenericButton variant="primary" type="submit" >
                {t('CONNECT')}
            </GenericButton>
        </WrapperFooter>
    </WrapperForm>

}
export const WrapperFooter = styled.div`
    border-top:1px solid #c4c4c4;
    display:flex;
     justify-content:space-between;

`
const WrapperForm = styled(Form)`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:532px !important;
`