import { handleAddRenderElement } from "@utils/helpers";
import ActionListCard from "features/analytics/Components/Cards/ActionListCard";
import MapListCard from "features/analytics/Components/Cards/MapListCard";
import OpportunityListCard from "features/analytics/Components/Cards/OpportunityListCard";
import SolutionListCard from "features/analytics/Components/Cards/SolutionListCard";
import CustomWordCloud from "features/analytics/Components/CustomWordCloud";
import Export from "features/analytics/Components/Export";
import FiltersManager from "features/analytics/Components/Filters/FiltersManager";
import HorizontalBarsChart from "features/analytics/Components/HorizontalBarsChart";
import Section from "features/analytics/Components/Section";
import { DashboardContainer, TopSection } from "features/analytics/Style";
import { applyFilterJourneyTrend } from "features/analytics/reducer/analyticActions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Counters from "./Reports/Counters";
import MapsPerStatus from "./Reports/MapsPerStatus";
import OpportunityAnalysisMatrix from "./Reports/OpportunityAnalysisMatrix";
import QuadrantScatterChart from "./Reports/QuadrantScatterChart";
import TopNTouchpoints from "./Reports/TopNTouchpoints";
import TopTenWithLabels from "./Reports/TopTenWithLabels";
import { entitiesByJourneyDataSelector, insightsHorizontalBarDataSelector, wordCloudDataSelector } from "./journeyTrendDataSelectors";

function JourneyTrendsReports() {
	const { t } = useTranslation();
	const hideForPdf = useLocation().search.hideForPdf();

	const getFiltersToSave = ({ currentEntityData }) => {
		return {
			programs: currentEntityData?.programs?.map((p) => p?.id),
			journeys: currentEntityData?.journeys?.map((j) => j?.id),
			personas: currentEntityData?.personas?.map((p) => p?.id),
		};
	};

	return (
		<DashboardContainer className="container my-4">
			<TopSection className="p-4" xs={12}>
				<FiltersManager filterEntities={["programs", "journeys", "personas"]} applyAction={applyFilterJourneyTrend} />
				<Export dashboardName="journeytrends" fileName={t("JOURNEY_TRENDS")} nSections="12" getFiltersToSave={getFiltersToSave} />
			</TopSection>
			<Counters />
			<Section titles={[t("MAPS_PER_STATUS")]}>
				<MapsPerStatus />
			</Section>
			<Section titles={[t("TOP_TEN_TOUCHPOINTS")]}>
				<TopNTouchpoints />
			</Section>
			<Section titles={[t("TOP_FIVE_GAIN_POINTS"), t("TOP_FIVE_PAIN_POINTS")]}>
				<HorizontalBarsChart
					dataSelector={(state) => insightsHorizontalBarDataSelector(state, { currentDataAttribute: "journeys", linkedIdsAttribute: "insightsGainPointIds" })}
					barColor="#18AEAD"
					relatedItemsTitle={t("ASPX_97")}
					listItemComponent={MapListCard}
					itemAttributeId="cjmId"
					emptyDataText="TOP_GAIN_REPORT_DESCRIPTION"
				/>
				<HorizontalBarsChart
					dataSelector={(state) => insightsHorizontalBarDataSelector(state, { currentDataAttribute: "journeys", linkedIdsAttribute: "insightsPainPointIds" })}
					barColor="#FC5454"
					relatedItemsTitle={t("ASPX_97")}
					listItemComponent={MapListCard}
					itemAttributeId="cjmId"
					emptyDataText="TOP_PAIN_REPORT_DESCRIPTION"
				/>
			</Section>
			<Section titles={[t("GAIN_POINTS_WORD_CLOUD"), t("PAIN_POINTS_WORD_CLOUD")]}>
				<CustomWordCloud
					dataSelector={(state) => wordCloudDataSelector(state, { dataAttribute: "journeys", linkedIdsAttribute: "insightsGainPointWordCloud" })}
					width={200}
					height={60}
					colorPalette={["#138A8A", "#18AEAD", "#9DF2F2"]}
					emptyDataText="GAIN_WORD_CLOUD_REPORT_DESCRIPTION"
				/>
				<CustomWordCloud
					dataSelector={(state) => wordCloudDataSelector(state, { dataAttribute: "journeys", linkedIdsAttribute: "insightsPainPointWordCloud" })}
					width={200}
					height={60}
					colorPalette={["#CC5858", "#F26A6A", "#FFC3C3"]}
					emptyDataText="PAIN_WORD_CLOUD_REPORT_DESCRIPTION"
				/>
			</Section>
			<Section titles={[`${t("OPPORTUNITIES_BY_JOURNEY")} (${t("TOP_FIVE")})`, `${t("SOLUTIONS_BY_JOURNEY")} (${t("TOP_FIVE")})`]}>
				<HorizontalBarsChart
					dataSelector={(state) => entitiesByJourneyDataSelector(state, { linkedIdsAttribute: "opportunityIds", allItemsAttribute: "allOpportunities" })}
					barColor="#18AEAD"
					relatedItemsTitle={t("OPPORTUNITIES")}
					listItemComponent={OpportunityListCard}
					emptyDataText="ITEMS_BY_JOURNEY_REPORT_DESCRIPTION"
				/>
				<HorizontalBarsChart
					dataSelector={(state) => entitiesByJourneyDataSelector(state, { linkedIdsAttribute: "solutionIds", allItemsAttribute: "allSolutions" })}
					barColor="#F48E09"
					relatedItemsTitle={t("SOLUTIONS")}
					listItemComponent={SolutionListCard}
					emptyDataText="ITEMS_BY_JOURNEY_REPORT_DESCRIPTION"
				/>
			</Section>
			<Section titles={[`${t("ACTIONS_BY_JOURNEY")} (${t("TOP_FIVE")})`]}>
				<HorizontalBarsChart
					dataSelector={(state) => entitiesByJourneyDataSelector(state, { linkedIdsAttribute: "actionIds", allItemsAttribute: "allActions" })}
					barColor="#0078E7"
					relatedItemsTitle={t("JS_80")}
					listItemComponent={ActionListCard}
					emptyDataText="ITEMS_BY_JOURNEY_REPORT_DESCRIPTION"
				/>
			</Section>
			<Section titles={[`${t("OPPORTUNITY_ANALYSIS_MATRIX")}`]}>
				<OpportunityAnalysisMatrix />
			</Section>
			<Section titles={[`${t("SOLUTION_ANALYSIS_MATRIX")}`]}>
				<QuadrantScatterChart />
			</Section>
			<Section titles={[t("TOP_TEN_TAGS")]}>
				<TopTenWithLabels currentDataAttributes={["journeys", "personas", "programs"]} linkedIdsAttribute="tagIds" barFill="#605F60" emptyDataText="TOP_TAGS_REPORT_DESCRIPTION" />
			</Section>
			<Section titles={[t("TOP_TEN_GROUPS")]}>
				<TopTenWithLabels currentDataAttributes={["journeys"]} linkedIdsAttribute="groupIds" barFill="#18AEAD" emptyDataText="TOP_GROUPS_REPORT_DESCRIPTION" />
			</Section>
			{hideForPdf && <div id="addRenderElement">{handleAddRenderElement(3000)} </div>}
		</DashboardContainer>
	);
}

JourneyTrendsReports.displayName = "JourneyTrendsReports";
export default JourneyTrendsReports;
