import { IconDispatcher } from "shared/Icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function EmptyData({ text, icon }) {
	const { t } = useTranslation();

	return (
		<Container>
			<IconContainer>{IconDispatcher(icon, "", { size: "4x" })}</IconContainer>
			<Text>{t(text)}</Text>
		</Container>
	);
}

EmptyData.displayName = "EmptyData";
export default EmptyData;

const Container = styled.div`
	display: flex;
	flex-direction: column;
    align-items: center;
	gap: 20px;
    width: 370px;
`;

const Text = styled.div`
	color: #605f60;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
`;
