import { success, error } from "@redux-requests/core";
import { appendListsAndRemoveDuplicates } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets";

const initialState = {
	all_actions: [],
	loading: false,
};

const ActionReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_ALL_CX_ACTIONS":
			return {
				...state,
				loading: state.all_actions.length === 0,
			};

		case error("GET_ALL_CX_ACTIONS"):
			return {
				...state,
				loading: false,
			};
		case success("GET_ALL_CX_ACTIONS"):
			return {
				...state,
				all_actions: action.response.data || [],
				loading: false,
			};
		case success("CREATE_CX_ACTION"):
			return {
				...state,
				all_actions: [...state.all_actions, action?.response?.data],
			};
		case success("UPDATE_CX_ACTION"):
			return {
				...state,
				all_actions: state.all_actions?.map((task) => {
					if (task.id === action.meta.requestAction.request.data.modifiedCxAction.id) {
						task = action.response.data;
					}
					return task;
				}),
			};
		case success("UPDATE_MULTI_CX_ACTION"): {
			const modifiedCxActions = action.meta.requestAction.request.data.modifiedCxActions;
			const ids = action.meta.requestAction.request.data.modifiedCxActions?.map((x) => x.id);
			const modifiedAttribNames = action.meta.requestAction.request.data.modifiedAttribNames;

			return {
				...state,
				all_actions: state.all_actions.map((action) => {
					if (ids.includes(action.id)) {
						return {
							...action,
							[modifiedAttribNames]: modifiedCxActions?.find((x) => x.id === action?.id)?.[modifiedAttribNames],
						};
					}
					return action;
				}),
			};
		}
		case success("COPY_CX_ACTION"):
			return {
				...state,
				all_actions: [...state.all_actions, action?.response?.data],
			};
		case success("ARCHIVE_CX_ACTION"):
			return {
				...state,
				all_actions: state?.all_actions?.map((cxAction) => {
					if (cxAction.id === action.meta.requestAction.request.data.id) {
						return { ...cxAction, statusCode: StatusCode.Archived };
					}
					return cxAction;
				}),
			};
		case success("ARCHIVE_MANY_CX_ACTIONS"):
			return {
				...state,
				all_actions: state?.all_actions?.map((cxAction) => {
					if (action.response.data.includes(cxAction.id)) {
						return { ...cxAction, statusCode: StatusCode.Archived };
					}
					return cxAction;
				}),
			};
		case success("DELETE_CX_ACTION"): {
			const actionId = action.meta.requestAction.request?.data?.id;
			return {
				...state,
				all_actions: state?.all_actions?.map((cxAction) => {
					if (cxAction.id === actionId) {
						return { ...cxAction, statusCode: StatusCode.Trashed };
					}
					return cxAction;
				}),
			};
		}
		case success("DELETE_MANY_CX_ACTIONS"):
			return {
				...state,
				all_actions: state?.all_actions?.map((cxAction) => {
					if (action.response.data.includes(cxAction.id)) {
						return { ...cxAction, statusCode: StatusCode.Trashed };
					}
					return cxAction;
				}),
			};
		case success("DELETE_CX_ACTION_PERMANENT"):
			return {
				...state,
				all_actions: state.all_actions?.filter((task) => task.id !== action.meta.requestAction.request.data.id),
			};
		case success("DELETE_MANY_CX_ACTIONS_PERMANENT"):
			return {
				...state,
				all_actions: state.all_actions?.filter((cxAction) => !action.response.data.includes(cxAction.id)),
			};
		case success("RESTORE_CX_ACTION"):
			return {
				...state,
				all_actions: state.all_actions.map((cxAction) => {
					if (cxAction.id === action.meta.requestAction.request.data.id) {
						return { ...cxAction, statusCode: StatusCode.Active };
					}
					return cxAction;
				}),
			};
		case success("RESTORE_MANY_CX_ACTIONS"):
			return {
				...state,
				all_actions: state.all_actions.map((cxAction) => {
					if (action.response.data.includes(cxAction.id)) {
						return { ...cxAction, statusCode: StatusCode.Active };
					}
					return cxAction;
				}),
			};
		case success("LINK_TAGS"): {
			const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_cxactiontask") return state;
			return {
				...state,
				all_actions: state.all_actions?.map((cxAction) => {
					if (recordIds.includes(cxAction.id)) {
						cxAction.tags = [...appendListsAndRemoveDuplicates(cxAction.tags, tagIds)];
					}
					return cxAction;
				}),
			};
		}
		case success("LINK_GROUPS"): {
			const { groupIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_cxactiontask") return state;

			return {
				...state,
				all_actions: state.all_actions?.map((cxAction) => {
					if (recordIds.includes(cxAction.id)) {
						cxAction.groups = [...appendListsAndRemoveDuplicates(cxAction.groups, groupIds)];
					}
					return cxAction;
				}),
			};
		}
		default:
			return state;
	}
};
export default ActionReducer;
