import { dismissNotifs, getNotifs, updateStatusNotifs } from "@redux/index";
import { genericService } from "shared/GenericService";

export { default as JourneyMapList } from "./journey-map/view/journey-map-list";
export { default as JourneyMapCreation } from "features/journey-map/view/journey-map-creation";
export { default as Authentication } from "features/auth/view/authenticate-cemantica";
export { default as InvalidPage } from "features/auth/view/inavalid-page";
export { default as Authenticate } from "features/auth/view/authenticate-microsoft";
export { default as Logout } from "features/auth/view/log-out-page";
export { default as LoginPage } from "features/auth/view/login-page";
export { default as CxActions } from "features/cx-actions/view/cx-actions-list";
export { default as CxActionPage } from "features/cx-actions/view/cx-actions-page";
export { default as Solutions } from "features/solutions/view/solutions-list";
export { default as SolutionPage } from "features/solutions/view/solution-page";
export { default as CxAssessmentPage } from "features/cx-assessements/view/cx-assessements-page";
export { default as OpportunityPage } from "features/opportunity/view/opportunity-page";
export { default as Opportunity } from "features/opportunity/view/opportunity-list";
export { default as MapHierarchyView } from "features/journey-map/view/journey-map-hierarchy-view";
export { default as CXProgram } from "features/forms/cx-programs/view/cxprogram-list";
export { default as PersonaCreationWizard } from "features/forms/personas/view/persona-creation";
export { default as Personas } from "features/forms/personas/view/persona-list";
export { default as Home } from "features/home/view/home-page.jsx";
export { default as Insights } from "features/insights/view/Insights";

export { default as ProjectPage } from "features/projects/view/project-page";
export { default as Projects } from "features/projects/view/project-list";
export { Search } from "features/search/view/search-page";
export { default as BrandValues } from "features/library/view/library-brand-value";
export { default as CustomLibrary } from "features/library/view/library-custom";
export { Measures } from "features/library/view/library-measure";
export { default as Stickers } from "features/library/view/library-stickers";
export { default as TouchPoints } from "features/library/view/library-touchpoints";
export { default as Libraries } from "features/library/view/library-list";
export { default as CustomizationPage } from "features/customization/view/customization-page";
export { default as Users } from "features/settings/manage-users/view/users";
export { default as EditUser } from "features/settings/manage-users/view/edit-user";
export { default as ManageTeams } from "features/settings/manage-users/view/manage-teams";
export { default as ManageOwnership } from "features/settings/manage-users/view/manage-ownership";
export { default as Organization } from "features/settings/view/settings-organization";
export { default as Configuration } from "features/settings/view/settings-configurations";
export { default as Templates } from "features/settings/view/settings-template";
export { default as WorkspacesList } from "features/workspace/view/workspaces-list";
export { default as WorkspaceInvalidPage } from "features/workspace/view/workspace-invalid-page";
export { default as WorkspaceForm } from "features/workspace/view/workspace-form/workspace-form";
export { Integration } from "features/integrations/view/Integration";

export { default as Training } from "features/training/view/Training";
export { Archive, TrashBin } from "features/inactive/view/inactive";
export { default as Analytics } from "features/analytics/view/analytics";

export * from "./journey-map/modals/MapVersions/MapVersionsHistory";
export * from "./journey-map/modals/SwimlaneDetails/SwimlaneDetailsCanvas";
//Interaction Modal
export { default as CopyInteractionModal } from "./journey-map/modals/PersonaInteractions/CopyInteractionModal.jsx"; // jsx files
export { default as EditInteraction } from "./journey-map/modals/PersonaInteractions/EditInteraction.jsx"; // jsx files
export { default as EditInsights } from "./insights/modals/EditInsights.jsx"; // jsx files

// those ones stays here
export * from "./journey-map/reducer/CJMActions";
export * from "./journey-map/reducer/IconService";
export * from "./search/reducer/QueryAction";

// left one to merge with actions
export * from "./forms/cx-strategy/reducer/StrategyService";

export const getNotifications = (props) => genericService({ ...props, action: getNotifs });

export const dismissNotifications = (props) => genericService({ ...props, action: dismissNotifs });

export const updateStatusNotification = (props) => genericService({ ...props, action: updateStatusNotifs });
