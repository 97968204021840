import { GenericButton, Input } from "shared/ui-basics/index";
import { submitReportRequest } from "@redux/index";
import { encode, toastWrapper } from "@utils/helpers";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, FormFeedback, FormGroup, Label } from "reactstrap";
import styled from "styled-components/macro";

export const SupportForm = ({ isOpen, onClose,subject}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [request, setRequest] = useState({ subject: subject ||  "", message: "" });
	const [invalidSub, setInvalidSub] = useState(false);
	const [invalidDes, setInvalidDes] = useState(false);

	const validation = () => {
		let check = false;
		if (request.subject.length < 1) {
			setInvalidSub(true);
		}
		if (request.message.length < 1) {
			setInvalidDes(true);
		}
		if (request.subject.length > 0 && request.message.length > 0) {
			setInvalidSub(false);
			setInvalidDes(false);
			check = true;
		}
		return check;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validation()) {
			onClose();
			const promise = async () => {
				const { error } = await dispatch(submitReportRequest(encode(request.subject), encode(request.message)));
				if (error) throw error;
				setRequest({ subject: "", message: "" });
			};

			toastWrapper(promise, "SEND");
		}
	};

	const handleChange = ({ target: input }) => {
		setRequest({ ...request, [input.name]: input.value.truncate(input.name === "subject" ? 200 : 2000) });
		if (input.name === "subject") setInvalidSub(false);
		if (input.name === "message") setInvalidDes(false);
	};

	return (
		<OffCanvasWrap show={isOpen} onHide={onClose} placement={"end"}>
			<OffCanvasHeader closeButton>
				<Offcanvas.Title>{t("CONTACT_US")}</Offcanvas.Title>
			</OffCanvasHeader>
			<Offcanvas.Body>
				<Form onSubmit={handleSubmit}>
					<FormGroup className="position-relative my-3 ">
						<Label for="forSubject">{t("ASPX_8")}</Label>
						<SubjectInput variant="default" id="forSubject" type="text" name="subject" value={request.subject} onChange={handleChange} invalid={invalidSub} />
						{invalidSub && <FormFeedback tooltip>{t("support_subject_error")}</FormFeedback>}
					</FormGroup>

					<FormGroup className="position-relative my-3">
						<Label for="forDescr">{t("YOUR_MESSAGE")}</Label>
						<CustomTextArea variant="default" id="forDescr" name="message" value={request.message} onChange={handleChange} type="textarea" invalid={invalidDes} />
						{invalidDes && <FormFeedback tooltip>{t("support_description_error")}</FormFeedback>}
					</FormGroup>

					<GenericButton type="submit" variant="primary">{t("ASPX_7")}</GenericButton>
				</Form>
			</Offcanvas.Body>
		</OffCanvasWrap>
	);
};
export const TextArea = styled(InputTextarea)`
	margin-top: 1rem;
	width: 100%;
	:focus {
		border: 1px solid #18aead !important;
		-webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important;
		box-shadow: 0 0 3px #9df2f2 !important;
	}
	&:hover {
		border-color: #18aead !important;
	}
`;

const OffCanvasWrap = styled(Offcanvas)`
	margin-top: 40px;
`;

const OffCanvasHeader = styled(Offcanvas.Header)`
	border-bottom: 1px solid grey;
`;

const SubjectInput = styled(Input)`
	margin-top: 6px;
	margin-bottom: 50px;
`;

const CustomTextArea = styled(Input)`
	resize: none !important;
	height: 300px;
	margin-top: 6px;
	margin-bottom: 50px;
`;
