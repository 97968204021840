import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { dateBodyTemplate } from "features/grid-table/components/TableSettings";
import { RedirectText, Score } from "../../columns-components/ReusableListComponents";

export const GetAssessmentColumns = (props) => {
    return {
        contactName: {
            field: "name",
            header: t("CONTACT_NAME"),
            sortable: true,
            style: checkStyle(undefined, "contactName", "xxxl"), //{ width: "40rem" },
            body: (item) => RedirectText(item, props?.contextData, props?.contextData?.attribute),
        },
        date: {
            field: "date",
            header: t("DATE"),
            sortable: true,
            dataType: "date",
            style: checkStyle(undefined, "date", "xxl"), // { width: "15rem" },
            body: (options) => dateBodyTemplate(options?.date),
        },
        score: {
            field: "score",
            header: t("SCORE"),
            sortable: true,
            style: checkStyle(undefined, "score", "xxl"), //{ width: "30rem" },
            dataType: "numeric",
            body: (option) => Score(option.score),
        },
    };
};
