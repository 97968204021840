/* eslint-disable no-extend-native */
import { ReactComponent as CelebrationIcon } from "@Assets/All/2hand-celebration-emoji.svg";
import { ReactComponent as WavingHand } from "@Assets/homePage/emoji _waving hand.svg";
import { ReactComponent as HornsIcon } from "@Assets/homePage/horns-emoji.svg";
import { ReactComponent as VictoryHandICon } from "@Assets/homePage/victory-hand-emoji.svg";
import { isShowElearningModal } from "@utils/helpers.js";
import { TrainingModal } from "features/training/modals/TrainingModal.jsx";
import { useEffect } from "react";
import { Translation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import { Maps } from "../components/Map";
import { Personas } from "../components/Personas.js";
import * as Style from "../components/Style.js";
import Widgets from "../components/Widgets.jsx";
import { CxActionsData } from "../components/cxActions.js";

export default function Home() {
	const { userInfo, loginStatus } = useSelector((state) => state.auth);
	const settings = useSelector((state) => state?.settings);

	const contracts = settings?.subscriptionDetails?.subscriptionContracts;
	const trainings = settings?.trainings;
	const subscriptionPlanName = userInfo?.UserSubscriptionPlanName?.toLowerCase();
	const isStandard = subscriptionPlanName === "standard";
	const userType = userInfo.UserType;
	const userName = userInfo?.UserFullName;
	const isReadOnly = userType?.isReadOnly();

	const [showTrainingModal] = useModal(TrainingModal);

	useEffect(() => {
		if (trainings?.length > 0 && !isStandard && loginStatus && isShowElearningModal(contracts)) showTrainingModal();
	}, [contracts]);

	return (
		<MainContainer className="container">
			<Body>
				<Translation>
					{(t) => (
						<Style.Title className="mb-5">
							{t(getCorrectTime())}
							{userName?.split(" ")[0]}
							{IconHomeValidation(getCorrectTime())}
						</Style.Title>
					)}
				</Translation>
				{!isReadOnly && !isStandard && <Widgets />}
				<Maps />
				<Personas />
				{!isStandard && <CxActionsData isStandard={isStandard} />}
			</Body>
		</MainContainer>
	);
}

const getCorrectTime = () => {
	let now = new Date();
	if (now.getHours() > 5 && now.getHours() < 12) return "GOOD_MORNING";
	if (now.getHours() >= 12 && now.getHours() <= 18) return "GOOD_AFTERNOON";
	if (now.getHours() > 18 && now.getHours() <= 22) return "GOOD_EVENING";
	if (now.getHours() > 22 || now.getHours() <= 5) return "GOOD_NIGHT";
};

const IconHomeValidation = (type) => {
	switch (type) {
		case "GOOD_MORNING":
			return <WavingHand className="ms-2" />;
		case "GOOD_NIGHT":
			return <HornsIcon className="ms-2" />;
		case "GOOD_AFTERNOON":
			return <CelebrationIcon className="ms-2" />;
		case "GOOD_EVENING":
			return <VictoryHandICon className="ms-2" />;
		default:
			return <WavingHand className="ms-2" />;
	}
};

const MainContainer = styled.div`
	overflow: scroll;
	padding: 0 3rem;
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	gap: 48px;
	height: inherit;
	margin-top: 80px;
	margin-bottom: 48px;
`;
