import { cxGoalsUpsert } from "@redux/index";
import { getMeasureById, getSessionId } from "@redux/memoizedSelectors";
import { getCxGoalValue, pictureValidation } from "@utils/helpers";
import { t } from "i18next";
import { ProgressBar } from "primereact/progressbar";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomTooltip from "shared/components/CustomTooltip";
import { EditCxGoalModal } from "shared/modals/index";
import { Flex, FlexAlignCenter, FlexBetweenAlignCenter, FlexColumn, FlexColumnGap } from "shared/ui-basics/index";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";
import { ColorTextCard } from "../../../../features/forms/personas/cards/ColorTextCard";

const CxGoalCard = memo(({ cxGoal, createCardActions, isEditable, noBorder }) => {
	const refOut = useRef();

	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";
	const getMeasure = getMeasureById(cxGoal?.measureId);
	const measure = useSelector(getMeasure);
	const isDragging = useSelector((state) => state?.comments?.isDragging);

	const relatedSystem = useSelector((state) => state.libraries.customLibraries)
		?.find((library) => library.name === "Systems" && !library.isCustom)
		?.values.find((system) => system.id === measure?.systemSourceId);

	const touchpoint = useSelector((state) => state.libraries.touchPoint)?.getById(cxGoal?.touchpointId);
	const sessionId = useSelector(getSessionId);



	const picture = pictureValidation(touchpoint, sessionId);

	const [showEditModal] = useModal(EditCxGoalModal, {
		cxGoal,
		handleAction: (updatedCxGoal) => cxGoalsUpsert({ CxGoals: [updatedCxGoal] }),
	});
	const newFinancialImpact = parseFloat((cxGoal?.financialImpact || 0).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2 });

	return (
		<GoalCard className="rounded" ref={refOut} isclickable={isEditable && !isDragging} onClick={isEditable && !isDragging ? showEditModal : () => {}} noborder={`${noBorder}`}>
			<Wrapper>
				<Header>
					<FlexColumn maxWidth={100}>
							<Title title={measure?.name}>{`${measure?.name}`}</Title>
						<FlexAlignCenter className="w-100 ellipsis">
							<BarLabel>{t("IMPACT")?.toUpperCase() + ": "}</BarLabel>
							<Impact className="ms-1">
								{newFinancialImpact}
								{currencySymbol}
							</Impact>
						</FlexAlignCenter>
					</FlexColumn>
					{createCardActions && <Flex>{createCardActions && createCardActions()}</Flex>}
				</Header>
				<Body gap="5">
					<LabelActualAndGoals cxGoal={cxGoal} />
					<ProgressBar value={getCxGoalValue({ cxGoal })} displayValueTemplate={() => {}} />
					<FlexBetweenAlignCenter>
						<BarLabel>{t("ACTUAL")?.toUpperCase()}</BarLabel>
						<BarLabel>{t("GOAL")?.toUpperCase()}</BarLabel>
					</FlexBetweenAlignCenter>
				</Body>
				{(relatedSystem || touchpoint) && (
					<Footer>
						{relatedSystem && (
							<FlexBetweenAlignCenter>
								<div className="w-100">
									<ColorTextCard text={relatedSystem?.name} color={relatedSystem?.colorCode} showBorder={false} />
								</div>
							</FlexBetweenAlignCenter>
						)}
						{touchpoint && (
							<Touchpoint>
								<ImageIcon src={picture} loading="lazy" alt="" />
								<TPName className="ms-2">{touchpoint?.name}</TPName>
							</Touchpoint>
						)}
					</Footer>
				)}
			</Wrapper>
		</GoalCard>
	);
});

const LabelActualAndGoals = memo(({ cxGoal }) => {
	return (
		<FlexBetweenAlignCenter gap="15">
			<FlexAlignCenter>
				<ScoreLabel color="#605F60" className="me-1">
					{cxGoal?.actual?.toLocaleString()}
				</ScoreLabel>
			</FlexAlignCenter>
			<FlexAlignCenter>
				<ScoreLabel color="#605F60" className="me-1">
					{cxGoal?.goal?.toLocaleString()}
				</ScoreLabel>
			</FlexAlignCenter>
		</FlexBetweenAlignCenter>
	);
});

CxGoalCard.displayName = "CxGoalCard";
LabelActualAndGoals.displayName = "LabelActualAndGoals";
export { CxGoalCard };

const GoalCard = styled.div`
	position: relative;
	width: 100%;
	min-height: ${(props) => (props.expand ? "297px" : "109px")};
	background-color: #ffffff;
	${(props) => (props.noborder === "true" ? "" : "border: 1px solid #c4c4c4")};
	border-radius: 2px;
	:hover #actions-list {
		display: flex;
	}
	${(props) =>
		props.isclickable &&
		css`
			:hover {
				cursor: pointer;
			}
		`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Touchpoint = styled.div`
	display: flex;
	border-radius: 4px;
	background: rgba(51, 51, 51, 0.1);
	width: fit-content;
	padding: 4px;
`;

const ImageIcon = styled.img`
	width: 1.12rem;
	height: 1.12rem;
	align-self: center !important;
`;

const TPName = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const Title = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Body = styled(FlexColumnGap)`
	.p-progressbar {
		height: 6px !important;
	}

	.p-progressbar .p-progressbar-value {
		background: #0078e7;
	}
`;

const BarLabel = styled.div`
	color: #605f60;
	font-size: 12px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	white-space: nowrap;
`;

const ScoreLabel = styled.div`
	color: #605f60;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	font-family: "Oswald", sans-serif;
	${(props) => props.color && `color: ${props.color};`}
	${(props) => props.bold && `font-weight: 600;`}
    white-space: nowrap;
`;

const Impact = styled.div`
	color: #605f60;
	font-family: "Oswald";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
