import { deleteSavedView, upsertSettingUser } from "@redux/index";
import { EMPTY_GUID, hasAttribute } from "@utils/helpers";
import { loadTableProps } from "features/grid-table/reducer/TableSlice";
import { t } from "i18next";
import { currentWorkspaceId } from "index";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import CustomTooltip from "shared/components/CustomTooltip";
import { EditFilterView } from "shared/modals/index";
import { ActionPopOver } from "shared/popovers/ActionsPopOver";
import { FlexAlignCenter, GenericButton, IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components";
import { useModal } from "use-modal-hook";

const ViewPopover = ({ entityType, context, handleFilter ,readonly }) => {
	const { t } = useTranslation();

	const refOver = useRef();
	const listView = useSelector((state) => state.settings.userSetting)?.savedViewsByEntity?.[entityType];
	const savedDefaultViews = useSelector((state) => state.settings.userSetting)?.savedDefaultViews?.[currentWorkspaceId] || EMPTY_GUID;
	const defaultView = hasAttribute(savedDefaultViews, entityType) ? savedDefaultViews[entityType] : EMPTY_GUID;
    const selectedViewId = useSelector((state) => state.ui.table.entity)?.[entityType]?.[context]?.selectedViewId;

	const nameDefault = listView?.find((x) => x.id === selectedViewId)?.name || t("DEFAULT_VIEW");
	const cemanticaDefaultView = {
		id: EMPTY_GUID,
		name: t("DEFAULT_VIEW"),
		filterSetting: undefined,
		columnSetting: undefined,
		sortSetting: undefined,
		type: entityType,
		level: 0,
	};
	const onToggle = (e) => {
		refOver.current.toggle(e);
		// setShow(e)
	};
	return (
		<>
			<WrapperButton className="mx-2" variant={"dark-outline"} onClick={onToggle} icon={"ARROW_DOWN"} iconPosition="after" tooltip={nameDefault.length < 12} tooltipText={nameDefault}>
				<div className="text-truncate">{nameDefault}</div>
			</WrapperButton>
			<WrapperOverlayPanel className="p-1" ref={refOver} id="overlay_panel">
				<DivTop className="pb-3">
					<Label className="ps-2 pb-3">{t("PRIVATE_VIEWS")}</Label>
					{listView
						?.filter((x) => !x.level)
						?.sortAsc("name")
						?.map((view) => (
							<ViewCard view={view} context={context} selected={view.id === defaultView} key={view?.id} refOver={refOver} entityType={entityType} handleFilter={handleFilter}  readonly={readonly}/>
						))}
				</DivTop>
				<div>
					<Label className="ps-2 py-3">{t("PUBLIC_VIEWS")}</Label>
					<ViewCard view={cemanticaDefaultView} context={context} refOver={refOver} entityType={entityType} handleFilter={handleFilter} cemanticaDefault />
					{listView
						?.filter((x) => x.level)
						?.sortAsc("name")
						?.map((view) => (
							<ViewCard view={view} context={context} selected={view.id === defaultView} key={view?.id} refOver={refOver} entityType={entityType} handleFilter={handleFilter} readonly={readonly}/>
						))}
				</div>
			</WrapperOverlayPanel>
		</>
	);
};

const ViewCard = ({ view, selected, entityType, context, refOver, handleFilter, cemanticaDefault,readonly = false }) => {
	const userId = useSelector((state) => state.auth.userInfo.UserId);
	const userSetting = useSelector((state) => state.settings.userSetting);

	const [showModalView] = useModal(EditFilterView, { context: context, viewFilter: view, propView: view?.columnSetting, entityType: entityType });
	const columnFilterView = view?.columnSetting ? JSON.parse(view?.columnSetting)?.map((x) => x["name"]) : [];
	const filterSetting = view?.filterSetting ? JSON.parse(view?.filterSetting) : {};
	const sortSetting = view?.sortSetting ? JSON.parse(view?.sortSetting) : {};

	const selectedView = (e, id) => {
		loadTableProps({ columns: columnFilterView, selectedViewId: id, entityType: view?.type, filter: filterSetting, sort: sortSetting, context: context });
		handleFilter(filterSetting);
		refOver.current.toggle(e);
	};
	const edit = (e) => {
		refOver.current.toggle(e);
		showModalView();
	};
	const deleteView = () => {
		deleteSavedView({ savedViewToDelete: view });
	};
	const saveAsDefault = () => {
		let savedDefaultViews = userSetting?.savedDefaultViews;
		if (savedDefaultViews[currentWorkspaceId]) {
			savedDefaultViews[currentWorkspaceId][entityType] = selected ? EMPTY_GUID : view.id;
		} else savedDefaultViews[currentWorkspaceId] = { [entityType]: selected ? EMPTY_GUID : view.id };
		upsertSettingUser({ userSetting: { id: userSetting?.id, savedDefaultViews: JSON.stringify(savedDefaultViews) }, modifiedAttribNames: ["savedDefaultViews"] });
	};
	const getActions = () => {
		const actions = [];
		if (userId === view?.ownerId) {
			actions.push({ handleClick: edit, text: t("EDIT"), itemClasses: "d-flex align-items-center" });
			actions.push({ handleClick: deleteView, text: t("ASPX_13"), itemClasses: "d-flex align-items-center" });
		}
		actions.push({ handleClick: saveAsDefault, text: t(selected ? "REMOVE_FROM_DEFAULT" : "SAVE_AS_DEFAULT"), itemClasses: "d-flex align-items-center" });

		return actions;
	};
	const actions = getActions();
	//
	return (
		<DivWrapper className="my-1 px-2 d-flex justify-content-between align-items-center">
			<SelectedViewWrapper onClick={(e) => selectedView(e, view?.id)} className="text-truncate">
				<FlexAlignCenter>
					<CustomTooltip id={view?.id} className="text-truncate w-90" text={view?.name} disabled={!view?.name || view?.name.length < 31}>
						{view?.name}
					</CustomTooltip>
					{selected && <CustomTooltip text={t("DEFAULT_VIEW")}>{IconDispatcher("thumb-tack", "ms-2")}</CustomTooltip>}
				</FlexAlignCenter>
			</SelectedViewWrapper>
			<ActionPopOverWrapper>{!cemanticaDefault && !readonly && <ActionPopOver id="action" actions={actions} variant="light-link" label={""} icon="ACTION-DROPDOWN-HORIZONTAL" />} </ActionPopOverWrapper>
		</DivWrapper>
	);
};
export default ViewPopover;
const WrapperOverlayPanel = styled(OverlayPanel)`
	max-height: 480px;
	overflow-y: auto;
`;
const SelectedViewWrapper = styled.div`
	flex-grow: 3;
`;
const DivTop = styled.div`
	border-bottom: 1px solid #c4c4c4;
`;
export const WrapperButton = styled(GenericButton)`
	width: 180px;
	min-width: 180px;
	display: flex;
	justify-content: space-between;
`;
const ActionPopOverWrapper = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	/* width: 25%; */
	& > button {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		right: 30px;
	}
`;
const DivWrapper = styled.div`
	height: 40px;
	width: 320px;
	border-radius: 2px;
	border: 1px solid #c4c4c4;
	cursor: pointer;

	:hover ${ActionPopOverWrapper} {
		& > button {
			opacity: 1;
			visibility: visible;
		}
	}
`;
