import { error, success } from "@redux-requests/core";
import { EMPTY_GUID, decode, firstLetterUppercase, toastWrapper } from "@utils/helpers";

const initialState = {
	current_strategy: {},
	all_Strategies: [],
	current_linked_users: [],
	loading: false,
};

const StrategyReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const responseData = action?.response?.data;
	switch (action.type) {
		case "STRATEGY_GET_ALL":
			return {
				...state,
				loading: state.all_Strategies.length === 0,
			};

		case error("STRATEGY_GET_ALL"):
			return {
				...state,
				loading: false,
			};
		case success("STRATEGY_GET_ALL"):
			return {
				...state,
				all_Strategies: action.response.data || [],
				loading: false,
			};
		case success("CREATE_STRATEGY"):
			return {
				...state,
				all_Strategies: [...state.all_Strategies, action.response.data],
			};
		case success("ADD_STRATEGY_ATTRIBUTE_TYPE"): {

			const strategyAttributes = [
				...state?.current_strategy.strategy.stdAttributesDic.map((x) => {
					return {
						...x,
						setFocus: false,
					};
				}),
			];
			strategyAttributes?.push({ ...action.response?.data, setFocus: true });

			const newPosition = {
				w: 1,
				h: 220,
				x: 0,
				y: Infinity,
				i: action.response?.data.id,
				minH: 220,
				// maxH: 220,
				moved: false,
				static: false,
			};
			if (action.response?.data?.id === EMPTY_GUID) {
				return state;
			}

			return {
				...state,
				current_strategy: {
					...state.current_strategy,

					strategy: {
						...state?.current_strategy.strategy,
						attributesPositions: [...state.current_strategy.strategy.attributesPositions, newPosition],

						stdAttributesDic: strategyAttributes,
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("GET_STRATEGY_FORM_DATA"):{
			const data = action.response.data;
			const strategyBrandValue = Object.entries(action.response.data?.strategy.strategyBrandValueDic).map((x) => x[1]);
			const sortablePosition = eval(data?.strategy.attributesPositions);

			data.strategy.strategyBrandValueDic = strategyBrandValue;
			data.strategy.attributesPositions = sortablePosition;
			data.strategy.stdAttributesDic = data?.strategy.stdAttributesDic === null ? [] : Object.entries(data?.strategy.stdAttributesDic)?.map((att) => att[1]);
			return {
				...state,
				current_strategy:
					{
						...state.current_strategy,
						...data,
					} || {},
			};
		}
		case success("DELETE_CX_STRATEGY"):{
			return {
				...state,
				all_Strategies: state?.all_Strategies?.map((strategy) => {
					if (strategy.id === action.meta.requestAction.request.data.strategyId) {
						strategy.statusCode = 2;
					}
					return strategy;
				}),
			};
		}
		case success("DELETE_MANY_CX_STRATEGIES"):
			return {
				...state,
				all_Strategies: state?.all_Strategies?.map((strategy) => {
					if (action.response.data.includes(strategy.id)) {
						strategy.statusCode = 2;
					}
					return strategy;
				}),
			};
		case success("DELETE_CX_STRATEGY_PERMANENT"):
			return {
				...state,
				all_Strategies: state.all_Strategies.filter((strategy) => strategy.id !== action.meta.requestAction.request.data.strategyId),
			};
		case success("DELETE_MANY_CX_STRATEGIES_PERMANENT"):{
			const successIdDeleted = action.response.data;
			return {
				...state,
				all_Strategies: state.all_Strategies.filter((strategy) => !successIdDeleted.includes(strategy.id)),
			};
		}
		case success("COPY_CX_STRATEGY"):
			return {
				...state,
				all_Strategies: [...state.all_Strategies, action?.response?.data],
			};

		case success("UPDATE_STRATEGY"):{
			const dataUpdate = action.response.data;
			const strategyBrandValue1 = Object.entries(dataUpdate?.strategyBrandValueDic)?.map((x) => x[1]);
			dataUpdate.strategyBrandValueDic = strategyBrandValue1;
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: { ...dataUpdate },
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("UPDATE_STRATEGY_ATTRIBUTE"):{
			const customAttributeIdStr = action.meta.requestAction.request.data.modifiedStrategyAttribute.id;
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						stdAttributesDic: state.current_strategy.strategy?.stdAttributesDic?.map((section) => {
							if (section.id === customAttributeIdStr) {
								section = { ...action.response.data, items: section.items };
							}
							return section;
						}),
					},

					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("DELETE_STRATEGY_ATTRIBUTE"):{
			const customAttributeIdStrToDelete = action.meta.requestAction.request.data.strategyAttributeId;
			return {
				...state,
				current_strategy: {
					...state.current_strategy,

					strategy: {
						...state?.current_strategy.strategy,
						stdAttributesDic: state.current_strategy.strategy?.stdAttributesDic?.filter((section) => {
							return section.id !== customAttributeIdStrToDelete;
						}),
					},

					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("ADD_STRATEGY_BRAND_VALUE"):{
			const newStrategyBrandValueDic = [...state.current_strategy.strategy.strategyBrandValueDic];
			newStrategyBrandValueDic?.push(action.response.data);
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						strategyBrandValueDic: newStrategyBrandValueDic,
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("DELETE_STRATEGY_BRAND_VALUE"):
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						strategyBrandValueDic: state.current_strategy.strategy.strategyBrandValueDic.filter((x) => x.id !== action.meta.requestAction.request.data.strategyBrandValue.id),
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_STRATEGY_BRAND_VALUE"):
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						strategyBrandValueDic: state.current_strategy.strategy.strategyBrandValueDic.map((strategyBrandValue) => {
							if (strategyBrandValue.id === action.meta.requestAction.request.data.modifiedStrategyBrandValue.id) return action.response.data;
							return strategyBrandValue;
						}),
					},

					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPSERT_STRATEGY_BRAND_VALUE"):{
			if (requestData.strategyBrandValue.id === EMPTY_GUID) {
				return {
					...state,
					current_strategy: {
						...state.current_strategy,
						strategy: {
							...state?.current_strategy.strategy,
							strategyBrandValueDic: [...state.current_strategy.strategy.strategyBrandValueDic, responseData],
						},
						lastModifiedOn: new Date().toISOString(),
					},
				};
			} else {
				return {
					...state,
					current_strategy: {
						...state.current_strategy,
						strategy: {
							...state?.current_strategy.strategy,
							strategyBrandValueDic: state.current_strategy.strategy.strategyBrandValueDic.map((strategyBrandValue) => {
								if (strategyBrandValue.id === responseData.id) return action.response.data;
								return strategyBrandValue;
							}),
						},

						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
		}
		case success("UPDATE_STRATEGY_ATTRIBUTE_IMAGE"):
			return {
				...state,
				current_strategy: { ...state.current_strategy, lastModifiedOn: new Date().toISOString() },
			};
		case success("DELETE_IMAGE_ON_BOX"):
			return {
				...state,
				current_strategy: { ...state.current_strategy, lastModifiedOn: new Date().toISOString() },
			};
		case "UPDATE_STRATEGY_ATTRIB_ORDER":{
			const newConfig = JSON.parse(action.request.data.newOrderConfig);
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						attributesPositions: newConfig,
					},
				},
			};
		}
		case success("UPDATE_STRATEGY_ATTRIB_ORDER"):
			return {
				...state,
				current_strategy: {
					...state.current_strategy,

					lastModifiedOn: new Date().toISOString(),
				},
			};

		case success("SHARE_USERS_SINGLE_STRATEGY"):{
			const linkedUsers1 = Object.entries(action.response.data).map((user) => user[1]) || [];
			return {
				...state,
				all_Strategies: state.all_Strategies?.map((strategy) => {
					if (strategy.id === action.meta.requestAction.request.data.strategyId) {
						strategy.authorizedUsers = linkedUsers1;
					}
					return strategy;
				}),
				current_linked_users: linkedUsers1,
			};
		}
		case success("SHARE_USERS_MULTIPLE_STRATEGY"):
			return {
				...state,
				all_Strategies: action.response.data || [],
			};
		case success("UPDATE_SHARE"):{
			const existUser = state.current_linked_users.find((user) => user.id === action.meta.requestAction.request.data.userId);

			const newLinkUsers =
				existUser === undefined
					? [
						...state.current_linked_users,
						{
							id: action.meta.requestAction.request.data.userId,
							roleName: action.meta.requestAction.request.data.roleDetails.split("_")[0],
							roleId: action.meta.requestAction.request.data.roleDetails.split("_")[1],
							fullName: action.meta.requestAction.request.param.fullName,
						},
					]
					: state.current_linked_users?.map((user) => {
						if (user.id === action.meta.requestAction.request.data.userId) {
							return {
								...user,
								roleName: action.meta.requestAction.request.data.roleDetails.split("_")[0],
								roleId: action.meta.requestAction.request.data.roleDetails.split("_")[1],
							};
						}
						return user;
					});

			return {
				...state,
				all_Strategies: state.all_Strategies?.map((strategy) => {
					if (strategy.id === action.meta.requestAction.request.data.strategyIdStr) {
						return {
							...strategy,
							authorizedUsers: newLinkUsers,
						};
					}
					return strategy;
				}),
				current_linked_users: newLinkUsers,
			};
		}
		case success("UPDATE_UNSHARE"):
			return {
				...state,
				all_Strategies: state.all_Strategies?.map((strategy) => {
					if (strategy.id === action.meta.requestAction.request.data.strategyIdStr) {
						return {
							...strategy,
							authorizedUsers: strategy.authorizedUsers.filter((user) => user.id !== action.meta.requestAction.request.data.userId),
						};
					}
					return strategy;
				}),
				current_linked_users: state.current_linked_users.filter((user) => user.id !== action.meta.requestAction.request.data.userId),
			};
		case "UPDATE_ORDER_LOCALLY":
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategyData: action.payload,
				},
			};
		case "RESET_CURRENT_STRATEGY":
			return {
				...state,
				current_strategy: {},
			};
		case success("ARCHIVE_CX_STRATEGY"):
			return {
				...state,
				all_Strategies: state?.all_Strategies?.map((strategy) => {
					if (strategy.id === action.meta.requestAction.request.data.strategyId) {
						strategy.statusCode = 100000000;
					}
					return strategy;
				}),
			};
		case success("ARCHIVE_MANY_CX_STRATEGIES"):
			return {
				...state,
				all_Strategies: state?.all_Strategies?.map((strategy) => {
					if (action.response.data.includes(strategy.id)) {
						strategy.statusCode = 100000000;
					}
					return strategy;
				}),
			};
		case success("RESTORE_CX_STRATEGY"):
			return {
				...state,
				all_Strategies: state.all_Strategies.map((strategy) => {
					if (strategy.id === action.meta.requestAction.request.data.strategyId) {
						strategy.statusCode = 1;
					}
					return strategy;
				}),
			};
		case success("RESTORE_MANY_CX_STRATEGIES"):
			return {
				...state,
				all_Strategies: state.all_Strategies.map((strategy) => {
					if (action.response.data.includes(strategy.id)) {
						strategy.statusCode = 1;
					}
					return strategy;
				}),
			};
		case success("UPDATE_CXSTRATEGY_LIST_ITEM"):{
			const attributeIdStr = action.meta.requestAction.request.data.attributeIdStr;
			const attributeItemIdStr = action.meta.requestAction.request.data.attributeItemIdStr;
			const description = action.meta.requestAction.request.data.description;

			if (attributeItemIdStr !== EMPTY_GUID) {
				return {
					...state,
					current_strategy: {
						...state.current_strategy,

						strategy: {
							...state?.current_strategy.strategy,
							stdAttributesDic: state.current_strategy.strategy?.stdAttributesDic?.map((section) => {
								if (section.id === attributeIdStr) {
									section.items = section.items.map((item) => {
										if (item.id === attributeItemIdStr) {
											item.description = decode(description);
										}
										return item;
									});
								}
								return section;
							}),
						},

						lastModifiedOn: new Date().toISOString(),
					},
				};
			} else {
				//create one
				const newObject = {
					id: action.response.data,
					description: decode(description),
				};
				return {
					...state,
					current_strategy: {
						...state.current_strategy,
						strategy: {
							...state?.current_strategy.strategy,
							stdAttributesDic: state.current_strategy.strategy?.stdAttributesDic?.map((section) => {
								if (section.id === attributeIdStr) {
									section.items = [...section.items, newObject];
								}

								return section;
							}),
						},
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
		}
		case success("REORDER_CXSTRATEGY_LIST_ITEM"):{
			if (action.response?.data === true) {
				return {
					...state,
					current_strategy: { ...state.current_strategy, lastModifiedOn: new Date().toISOString() },
				};
			}
			return {
				...state,
			};
		}
		case success("DELETE_CXSTRATEGY_LIST_ITEM"):{
			const attributeIdStr_delete = action.meta.requestAction.request.data.attributeIdStr;
			const attributeItemIdStr_delete = action.meta.requestAction.request.data.attributeItemIdStr;
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						stdAttributesDic: state.current_strategy.strategy?.stdAttributesDic?.map((section) => {
							if (section.id === attributeIdStr_delete) {
								section.items = section.items.filter((item) => item.id !== attributeItemIdStr_delete);
							}
							return section;
						}),
					},
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "ORDER_LIST_ITEM_STRATEGY":{
			const itemsIds = action.payload.newOrder?.split("|");
			return {
				...state,
				current_strategy: {
					...state.current_strategy,
					strategy: {
						...state?.current_strategy.strategy,
						stdAttributesDic: state.current_strategy.strategy?.stdAttributesDic?.map((section) => {
							if (section.id === action.payload.attributeIdStr) {
								section.items = itemsIds.map((id) => section.items.find((i) => i.id === id)).flat();
							}
							return section;
						}),
					},
				},
			};
		}

		default:
			return state;
	}
};
export default StrategyReducer;
