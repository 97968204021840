import { BasicDrillDownModal } from "features/analytics/modals/BasicDrillDownModal";
import { displayBenefitCard } from "features/grid-table/columns-components/ReusableListComponents";
import { AnalyticCustomTooltip } from "features/analytics/Components/Tooltips/AnalyticCustomTooltip";
import SolutionCard from "features/analytics/Components/Cards/SolutionListCard";
import EmptyData from "features/analytics/Components/EmptyData";
import SolutionLabel from "features/analytics/Components/SolutionLabel";
import { getPointColors } from "features/analytics/analyticsHelper";
import { calculateXScore, calculateYScore, solutionTypeByCoordinates } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CartesianGrid, LabelList, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import { solutionAnalysisDataSelector } from "../journeyTrendDataSelectors";
import { CustomResponsiveContainer } from "features/analytics/Style";

function QuadrantScatterChart() {
	const { t } = useTranslation();
	const data = useSelector((state) => solutionAnalysisDataSelector(state));

	const [drillDownModal] = useModal(BasicDrillDownModal);

	const renderTooltipContent = ({ active, payload }) => {
		if (active && payload && payload.length) {
			const dataPoint = payload[0].payload;
			return <AnalyticCustomTooltip data={dataPoint?.solutions} entity={t("SOLUTIONS")} />;
		}
		return null;
	};

	const renderDrillDownIllustration = ({ type }) => {
		return <>{displayBenefitCard("", type, "xl")}</>;
	};

	const CustomScatterPoint = ({ cx, cy, payload }) => {
		const { x, y, solutions } = payload;
		const type = solutionTypeByCoordinates(Number(x), Number(y));
		const { background, border } = getPointColors(type);
		const handleClick = () => {
			drillDownModal({
                title: t("SOLUTIONS"),
                relatedItemsTitle: t("SOLUTIONS"),
				renderIllustration: () => renderDrillDownIllustration({ type }),
				ListItemComponent: SolutionCard,
				items: solutions,
			});
		};

		return (
			<a onClick={handleClick} style={{ cursor: "pointer" }}>
				<circle cx={cx} cy={cy} r={solutions.length > 1 ? 16 : 10} stroke={border} fill={background} />
			</a>
		);
	};

	const CustomLabel = ({ cx, cy, value }) => {
		const type = solutionTypeByCoordinates(Number(calculateXScore(value[0])), Number(calculateYScore(value[0])));
		const { border } = getPointColors(type);

		return (
			<text x={cx} y={cy + 4} fill={border} textAnchor="middle" fontSize={12} fontFamily="Inter" style={{ pointerEvents: "none" }}>
				{value.length > 1 ? `${value.length}` : ""}
			</text>
		);
    };

    const tickStyle = {
		fontSize: "14px",
		fontFamilly: "Inter",
		color: "#605F60",
	};

	return (
		<>
			{data?.length > 0 ? (
				<CustomResponsiveRelativeContainer width="100%" height="100%">
					<SolutionLabel variant="quickWin" icon="LIKE" text="QUICK_WIN" />
					<SolutionLabel variant="niceToHave" icon="STAR-LIFE" text="NICE_TO_HAVE" />
					<SolutionLabel variant="complexProj" icon="PUZZLE" text="COMPLEX_PROJ" />
					<SolutionLabel variant="toAvoid" icon="ROAD-BARRIER" text="TO_AVOID" />

					<ScatterChart
						margin={{
							top: 17,
							left: 10,
							right: 30,
						}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis type="number" dataKey="x" domain={[0, 20]} axisLine={false} tick={{ ...tickStyle }}/>
						<YAxis type="number" dataKey="y" domain={[0, 20]} axisLine={false} tick={{ ...tickStyle }}/>
						<ReferenceLine y={10} stroke="#000000" />
						<ReferenceLine x={10} stroke="#000000" />
						<Tooltip content={renderTooltipContent} isAnimationActive={false} />
						<Scatter data={data} shape={<CustomScatterPoint />}>
							<LabelList dataKey="solutions" content={<CustomLabel />} />
						</Scatter>
					</ScatterChart>
				</CustomResponsiveRelativeContainer>
			) : (
				<CustomResponsiveContainer width="100%" height="100%">
					<EmptyData text="MAP_PER_STATUS_EMPTY" icon="CHART-SCATTER" />
				</CustomResponsiveContainer>
			)}
		</>
	);
}

QuadrantScatterChart.displayName = "QuadrantScatterChart";
export default QuadrantScatterChart;

const CustomResponsiveRelativeContainer = styled(ResponsiveContainer)`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
`;
