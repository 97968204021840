import { getAllPersonas } from "@redux/index.js";
import { resort } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets.js";
import PersonaProfileCard from "features/forms/personas/cards/persona-sections-cards/PersonaProfileCard.js";
import PersonasLoadingCard from "features/home/components/loading-components/PersonasLoadingCard.js";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAsync } from "shared/hooks/index";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index.js";
import styled from "styled-components";
import * as Style from "./Style.js";
import { SectionHeader } from "./section-header.jsx";
import DataLoader from "shared/components/data-loader.jsx";

export const Personas = () => {
	const all_personas = useSelector((state) => state.personas.all_personas);
	const personas = useMemo(
		() =>
			resort(
				all_personas?.filter((persona) => personaValidation(persona)),
				"lastModifiedOn",
				"down"
			)?.slice(0, 3),
		[all_personas]
	);
	const history = useHistory();

	return (
		<Style.Section>
			<SectionHeader entityText="ASPX_98" onClick={() => history.push("/personas")} />
			<DataLoader reduxActions={[getAllPersonas]} disabledSpinner={personas?.length > 0} CustomLoading={<LoadingPersonas />} >
				<FlexAlignJustifyCenter> <PersonasCardDisplay personas={personas} /></FlexAlignJustifyCenter>
			</DataLoader>
		</Style.Section>
	);
};

const personaValidation = (persona) => {
	return persona.statusCode === StatusCode.Active && persona.isSample === false && persona.isTemplate === false;
};

const LoadingPersonas = () => {
	return (
		<LoadingContainer className="mt-3">
			<PersonasLoadingCard />
			<PersonasLoadingCard />
			<PersonasLoadingCard />
		</LoadingContainer>
	);
};

const PersonasCardDisplay = ({ personas }) => {
	const { t } = useTranslation();

	if (personas?.length === 0) {
		return (
			<Style.NoElementContainer>
				<img src={require("@Assets/images/EmptyListImages/no-personas.svg").default} alt="no personas" />
				<div id="no-data">{t("NO_PERSONAS")}</div>
			</Style.NoElementContainer>
		);
	}

	return (
		<PersonaContainer>
			{personas.map((personaObject) => (
				<PersonaProfileCard key={personaObject.personaId} personaId={personaObject.personaId} />
			))}
		</PersonaContainer>
	);
};

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 15px;
`;

const PersonaContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 32px;
	border-radius: 4px;
	border: 1px solid var(--color-Brand-Purple-200, #c5c2d4);
	background: #fcfbff;
	padding-top: 32px;
	padding-bottom: 32px;
`;
