import { FlexAlign, FlexAlignCenter, GenericButton, IconDispatcher } from "shared/ui-basics/index";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Labels } from "../../../features/journey-map/modals/PersonaInteractions/personaInteractions_style";
import PopoverEditMode from "shared/popovers/PopoverEditMode";

function Property({ icon, elementIds,mode, RenderElement, label, ...props }) {

	const { t } = useTranslation();

	const Label = () => (
		<WrapperLabel className="ps-1" minHeight={40} width={200} allowBackgroundHover={props?.onRemove}>
			<FlexAlignCenter gap={8}>
				{IconDispatcher(icon, "", { size: "sm" , style: "duotone"})}
				<Labels fs="14px">{t(label)}</Labels>
			</FlexAlignCenter>
			{props?.onRemove && !mode.IsViewer() && <GenericButton variant="light-link" size="m" icon="HIDE_EYE" iconClassName="me-1 generic-button-delete" onClick={() => props?.onRemove(props.id, true)} />}
		</WrapperLabel>
	);

	return (
		<FlexAlign align="start" className={props.className} minHeight={32} gap={4}>
			<Label />
			{!props.popoverParams ? RenderElement() : <PopoverEditMode elementIds={elementIds} RenderElement={RenderElement} {...props}  forceClose={true}/>}
		</FlexAlign>
	);
}

Property.displayName = "Property";
Property.propTypes = {
	icon: PropTypes.string,
	elementIds: PropTypes.array,
	label: PropTypes.string,
	popoverParams: PropTypes.object,
};
export default Property;

const WrapperLabel = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius:4px;
	${(props) => props.minHeight && `min-height: ${props.minHeight}px`};
	${(props) => props.width && `width: ${props.width}px; min-width: ${props.width}px; min-width: ${props.width}px;`};
	:hover {
		${(props) => props?.allowBackgroundHover && `background-color:#EFEFEF`};
	}

`