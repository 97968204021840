import { getEntityCurrentState } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets";
import PickListData from "@utils/optionsSets/PickListData";
import { updateLinksCjms, updatePhaseLinksWithCjms, updateStageLinksWithCjms } from "features/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { ColorCheckState } from "shared/cx-components/style";
import { FlexAlignCenter, GenericButton, IconDispatcher } from "shared/ui-basics/index";
import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import styled from "styled-components/macro";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import Personas from "../../grid-table/columns-components/Personas";
import { BoxContainer } from "../../grid-table/columns-components/Styles";


export default function MapBoxCard({ map, type, label, dataForUnlink }) {
	const { t } = useTranslation();
	const allStatus = useSelector((state) => state.libraries?.status);
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();


	const states = PickListData("state");
	const state = getEntityCurrentState(map.isFuture);

	const currentState = states?.find((s) => s.value === state);
	const statuses = allStatus && allStatus.customerJourney?.filter((status) => !status.name.isEmptyString());
	const currentStatus = statuses?.find((s) => s.id === map.cxStatus);

	const unlink = async () => {
		if (type === "stage") {
			updateStageLinksWithCjms({
				stageIdStr: dataForUnlink.stageId,
				cjmIdStr: dataForUnlink.cjmIdStr,
				cjmIdsToLink: "",
				cjmIdsToUnlink: map.cjmId,
				personaIdStr: dataForUnlink.personaIdStr,
				displayToast: "UNLINK",
			});
		} else if (type === "phase") {
			updatePhaseLinksWithCjms({
				phaseIdStr: dataForUnlink.phaseId,
				cjmIdStr: dataForUnlink.cjmIdStr,
				cjmIdsToLink: "",
				cjmIdsToUnlink: map.cjmId,
				personaIdStr: dataForUnlink.personaIdStr,
				displayToast: "UNLINK",
			});
		} else updateLinksCjms({ cjmIdStr: dataForUnlink.cjmIdStr, cjmIdsToLink: "", cjmIdsToUnlink: map.cjmId, personaIdStr: dataForUnlink.personaIdStr });
	};

	const displayText = () => {
		if (type === "direct") return "Direct Link";
		if (type === "phase") return `Phase : ${label}`;
		if (type === "stage") return `Stage : ${label}`;
		return "";
	};

	const FooterComponent = ({ label, value, color }) => {
		return (
			<FooterComponentStyle>
				<CustomLabel>{t(label) + ": "}</CustomLabel>
				<CustomValue className="ms-2" color={color}>
					{value}
				</CustomValue>
			</FooterComponentStyle>
		);
	};

	return (
		<BoxContainer id={map.cjmId}>
			<CustomCard className="p-3" type={type}>
				<Header>
					<Description>
						<BulletPoint type={type} />
						<Text>{displayText()}</Text>
					</Description>
					<CardAction id="action">
						{type && !isReadOnly && (
							<GenericButton variant="light-link" onClick={unlink}>
								<CustomTooltip text={t("UNLINK_MAP")}>{IconDispatcher("UNLINK")}</CustomTooltip>
							</GenericButton>
						)}
					</CardAction>
				</Header>
				<PersonasWrapper>{Personas(map, 3, 35, false)}</PersonasWrapper>
				<MapNameContainer>
					<CustomTooltip id={"cjm-name-" + map?.cjmId} text={map?.name} disabled={!map?.name || map?.name.length <= 72}>
						<Redirect to={map?.statusCode === StatusCode.Trashed || map?.statusCode === StatusCode.Archived ? "#" : `/EditMap?cjmid=${map?.cjmId}&personaId=${map?.linkedPersonas[0]?.personaId || "00000000-0000-0000-0000-000000000000"}`}>
							<MapName id={"cjm-name-" + map?.cjmId}>{map?.name}</MapName>
						</Redirect>
					</CustomTooltip>
				</MapNameContainer>
				<FlexAlignCenter gap="10">
					<EmotionLevel variant="smiley" size="s" level={Math.round(map?.score)} showTooltip={false} />
				</FlexAlignCenter>

				<Footer>
					<FooterComponent label="JS_77" value={currentState?.name} color={ColorCheckState(currentState?.value)} />
					{" | "}
					<FooterComponent label="ASPX_222" value={currentStatus?.name} color={currentStatus?.colorCode} />
				</Footer>
			</CustomCard>
		</BoxContainer>
	);
}

const CustomCard = styled(Card)`
	height: 250px;
	width: 22rem;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border: 1px solid ${(props) => (!props.type || props.type === "direct" ? "#F96209" : "#c4c4c4")};
	box-shadow: none;
	:hover {
		box-shadow: 1px 1px 3px rgba(33, 33, 33, 0.3);
	}

	#action {
		visibility: hidden;
	}
	:hover #action {
		visibility: inherit;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
    height: 36px;
`;

const Description = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const BulletPoint = styled.div`
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 12px;
	border: 1px solid #c4c4c4;
	background: ${(props) => (!props.type || props.type === "direct" ? "#F96209" : "#FFFFFF")};
`;

const Text = styled.div`
	color: #c4c4c4;
	text-align: center;
	font-size: 0.75rem;
	font-family: Inter;
`;

const MapNameContainer = styled.div`
    height: 60px;
`;

const MapName = styled.div`
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	white-space: pre-wrap;
	text-align: left;
	cursor: pointer;
	color: #605f60;
	font-family: Inter;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400 !important;
	line-height: normal;
`;

const Redirect = styled(Link)`
	text-decoration: none;
`;

const PersonasWrapper = styled.div`
    height: 35px;
`;

const Footer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
`;

const CardAction = styled.div`
	display: flex;
	justify-content: center;
`;

const FooterComponentStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CustomLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 0.75rem;
	font-style: italic;
	font-weight: 300;
	line-height: normal;
`;

const CustomValue = styled.div`
	color: ${(props) => props.color};
	font-family: Inter;
	font-size: 0.75rem;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
`;
