import React from "react";
import styled, { css } from "styled-components/macro";
import { IconDispatcher } from "shared/Icons";
import { useTranslation } from "react-i18next";

export function BenefitCard({ classes, isActive, variant, icon, text, isQuadrant, baseUnit, size }) {
	const { t } = useTranslation();

	return (
		<Card className={classes} isActive={isActive} variant={variant} isQuadrant={isQuadrant} baseUnit={baseUnit} size={size}>
			<>{IconDispatcher(icon)}</>
			<CardText className="text-truncate">{t(text)}</CardText>
		</Card>
	);
}

const Card = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	width: fit-content;
	height: 30px;
	border-radius: 6px;
	background: #f5f5f5;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #c4c4c4;

	${(props) =>
		props.isActive &&
		props.variant === "quickWin" &&
		css`
			background: #dcffff;
			color: #18aead;
			${(props) =>
				props.isQuadrant &&
				css`
					transform: rotate(-90deg);
					position: absolute;
					left: calc(-1.55 * ${(props) => props.baseUnit}px);
					top: calc(2.7 * ${(props) => props.baseUnit}px);
				`}
		`}
	${(props) =>
		props.isActive &&
		props.variant === "niceToHave" &&
		css`
			background: #d2eefb;
			color: #20a9e9;
			${(props) =>
				props.isQuadrant &&
				css`
					transform: rotate(-90deg);
					position: absolute;
					left: calc(-1.75 * ${(props) => props.baseUnit}px);
					bottom: calc(2.2 * ${(props) => props.baseUnit}px);
				`}
		`}
    ${(props) =>
		props.isActive &&
		props.variant === "complexProj" &&
		css`
			background: #c5c2d4;
			color: #333044;
			${(props) =>
				props.isQuadrant &&
				css`
					transform: rotate(-90deg);
					position: absolute;
					right: calc(-1.92 * ${(props) => props.baseUnit}px);
					top: calc(2.8 * ${(props) => props.baseUnit}px);
				`}
		`}
    ${(props) =>
		props.isActive &&
		props.variant === "toAvoid" &&
		css`
			background: #fde7cb;
			color: #f48e09;

			${(props) =>
				props.isQuadrant &&
				css`
					transform: rotate(-90deg);
					position: absolute;
					right: calc(-1.52 * ${(props) => props.baseUnit}px);
					bottom: calc(2.3 * ${(props) => props.baseUnit}px);
				`}
		`}

    ${(props) =>
		props.size === "xl" &&
		css`
			height: 90px;
			font-weight: 500;
			font-size: 24px;
		`}
`;

const CardText = styled.div`
	display: flex;
    align-items: center;
	white-space: nowrap;
    height: 100%;
`;
