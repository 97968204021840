import { copyCustomTouchpoint, deleteCustomTouchpoint, deleteCustomTouchpointImage, getCurrentMap, recomputeMapSentimentLevel, recomputeSentimentLevels, updateCustomTouchpoint } from "@redux/index";
import { getSessionId } from "@redux/memoizedSelectors";
import { EMPTY_GUID, touchPointIconValidation } from "@utils/helpers";
import { LibraryItemCard } from "features/library/components/LibraryItemCard";
import { currentWorkspaceId } from "index";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActionConfirmModal, EditLibrariesModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";

const TouchpointCard = memo(({ touchPoint, setFocus }) => {

	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const ref = useRef(null);

	const sessionId = useSelector(getSessionId);
	const category = [{ name: "ALL", id: EMPTY_GUID }, ...useSelector((state) => state.libraries.touchPointCategories) || []];

	const [picture, setPicture] = useState(pictureValidation(touchPoint, sessionId));

	const query = new URLSearchParams(location.search.toLowerCase());


	const deleteCustom = async () => {
		hideModalDelete();
		deleteCustomTouchpoint({ id: touchPoint?.id });
	};

	const RecomputeSentimentLevels = async () => {
		const cjmId = query.get("cjmid");
		const personaId = query.get("personaid");
		const onSuccess = () => {
			if (cjmId && personaId) {
				recomputeMapSentimentLevel({ channelIdStr: touchPoint.id, cjmIdStr: cjmId, personaIdStr: personaId, onSuccess: getCurrentMap });
			}
		}
		recomputeSentimentLevels({ channelIdStr: touchPoint.id, onSuccess, displayToast: "UPDATE", toastParams: { success: t("RECALCULAT_SENTIMENT_LEVELS") } })
	};
	const [showEditModal] = useModal(EditLibrariesModal);

	const [showModalDelete, hideModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: "DELETE_TOUCHPOINT",
		Action: deleteCustom,
		actionBtnText: "ASPX_13",
	});

	const DeleteCustomTouchpointImage = (channelIdStr) => {

		const onSuccess = () => {
			setPicture(`api/touchpoint/getCustomImage?channelIdStr=${touchPoint.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}&${Math.random()}=true`);
		}
		deleteCustomTouchpointImage({ channelIdStr, onSuccess })
	};

	const updateCustom = (index, name, category, weight, FileExtension, DocumentBody) => {
		UpdateCustomChannel(index, name, category, weight, FileExtension ? FileExtension : "", DocumentBody ? DocumentBody : "");
		if (FileExtension === "") DeleteCustomTouchpointImage(index);
	};

	const UpdateCustomChannel = async (id, newValue, category, weight, FileExtension, DocumentBody) => {

		const onSuccess = () => {
			if (weight !== touchPoint.weight) RecomputeSentimentLevels();
			if (touchPoint.isCustom) setPicture(`api/touchpoint/getCustomImage?channelIdStr=${touchPoint.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}&${Math.random()}=true`);
		}
		updateCustomTouchpoint({ channelIdStr: id, newChannelName: newValue, category, weight, imageExtension: FileExtension, imageBody64: DocumentBody, onSuccess });

	};
	const openEdit = () => {
		showEditModal({
			onDelete: showModalDelete,
			object: touchPoint,
			context: "touchpoint",
			entityName: "touchpoint",
			title: "TOUCHPOINT",
			displayCjmTable: true,
			pictureValidation,
			deleteCustom,
			updateCustom,
			category,
		});
	};

	useEffect(() => {
		setPicture(pictureValidation(touchPoint, sessionId));
	}, [touchPoint.category]);

	const onCopy = ({ id, name, workspaceIds }) => {
		copyCustomTouchpoint({
			channelIdStr: id,
			newChannelName: name,
			workspaceIds: workspaceIds
		});
	};
	return touchPoint && <LibraryItemCard
		item={touchPoint}
		imageSrc={picture}
		handleEdit={openEdit}
		deleteFunction={deleteCustomTouchpoint}
		deleteText="DELETE_TOUCHPOINT"
		setFocus={setFocus}
		ref={ref}
		actionType='DELETE_CUSTOM_TOUCHPOINT'
		copyFunction={onCopy}
		label={'TOUCHPOINT'}
	/>;
});
TouchpointCard.displayName = "TouchpointCard";
export { TouchpointCard };
const pictureValidation = (icon, sessionId) => {
	if (icon.isCustom) return `api/touchpoint/getCustomImage?channelIdStr=${icon.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}`;
	return touchPointIconValidation(icon.name);
};
