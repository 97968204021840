import { ConceptSearchBar } from "shared/ui-basics/index";
import { Spinner } from "shared/components/spinners/Spinner";
import { useAsyncs } from "shared/hooks/index";
import {
    getAllAuthorizedUsers,
    getAllCxAction,
    getAllCxProgram,
    getAllMapsListData,
    getAllOpportunity,
    getAllPersonas,
    getAllSolutions,
    getFolders,
    groupInit,
    priorityInit,
    statusInit,
    tagInit,
} from "@redux/index";
import { getAllProjects } from "features/projects/reducer/ProjectsActions";
import { FilterItemsByStatus, isUserAllowed } from "@utils/helpers";
import { useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components/macro";
import { PageContainer, PageTitle } from "shared/ui-basics/index";
import { FolderValidation, MapValidation, PersonaValidation } from "../../grid-table/contexts";
import DataTable from "../../grid-table/DataTable";
import { getAllInsights } from "features/insights/reducer/InsightActions";


export default function Records({ recordTitle }) {
	const { t } = useTranslation();
	const tab = useLocation().search.getTab();
	const childFolder = useRef(null);
	const childPersonas = useRef(null);
	const childMaps = useRef(null);
	const childMapTemplates = useRef(null);
	const childPersonaTemplate = useRef(null);
	const childOpportunities = useRef(null);
	const childSolutions = useRef(null);
	const childActions = useRef(null);
	const childCxPrograms = useRef(null);
    const childProjects = useRef(null);
    const childInsights = useRef(null);
	const pathname = useLocation().pathname;
	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [key, setKey] = useState(tab ? tab : "Maps");

	const folders = useSelector((state) => state.folders?.filter((folder) => FolderValidation(folder, pathname)));
	const maps = useSelector((state) => state.maps.all_maps.filter((map) => MapValidation(map, "", pathname)));
	const mapTemplates = useSelector((state) => state.maps.all_maps).filter((map) => MapValidation(map, "template", pathname));
	const personas = useSelector((state) => state.personas.all_personas.filter((persona) => PersonaValidation(persona, pathname)));
	const personaTemplates = useSelector((state) => state.personas.all_personas).filter((persona) => PersonaValidation(persona, pathname, "template"));
	const solutions = useSelector((state) => state.solutions.all_solutions.filter((solution) => FilterItemsByStatus(solution, pathname, "")));
	const opportunities = useSelector((state) => state.opportunity.all_Opportunities.filter((opp) => FilterItemsByStatus(opp, pathname, "")));
	const actions = useSelector((state) => state.actions.all_actions?.filter((action) => FilterItemsByStatus(action, pathname, "")));
	const cxPrograms = useSelector((state) => state.cxProgram.all_cxPrograms?.filter((cxProgram) => FilterItemsByStatus(cxProgram, pathname, "")));
    const projects = useSelector((state) => state.projects.all_projects?.filter((project) => FilterItemsByStatus(project, pathname, "")));
    const insights = useSelector((state) => state.insights.all_insights.filter((insight) => FilterItemsByStatus(insight, pathname, "")));

	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);
	const [loading, setLoading] = useState(false);

	useAsyncs({
		asyncFns: [
			{ asyncFn: getAllSolutions },
			{ asyncFn: getAllOpportunity },
			{ asyncFn: getAllCxAction },
			{ asyncFn: getAllCxProgram },
			{ asyncFn: getAllProjects },
			{ asyncFn: getFolders },
			{ asyncFn: getAllMapsListData },
			{ asyncFn: getAllPersonas },
			{ asyncFn: getAllAuthorizedUsers },
			{ asyncFn: statusInit },
			{ asyncFn: groupInit },
			{ asyncFn: tagInit },
            { asyncFn: priorityInit },
            { asyncFn: getAllInsights },
		],
		setLoading,
	});
	const isTemplateAllowed = () => isUserAllowed(userType, "template");

	const handleChange = (e) => {
		setGlobalFilterValue(e.target.value);
		if (key === "folder") childFolder.current.updateFilter(e.target.value);
		if (key === "Persona") childPersonas.current.updateFilter(e.target.value);
		if (key === "Maps") childMaps.current.updateFilter(e.target.value);
		if (key === "MapTemplates") childMapTemplates.current.updateFilter(e.target.value);
		if (key === "Opportunities") childOpportunities.current.updateFilter(e.target.value);
		if (key === "Solutions") childSolutions.current?.updateFilter(e.target.value);
		if (key === "Actions") childActions.current.updateFilter(e.target.value);
		if (key === "cxPrograms") childCxPrograms.current.updateFilter(e.target.value);
        if (key === "projects") childProjects.current.updateFilter(e.target.value);
        if (key === "insights") childInsights.current.updateFilter(e.target.value);
	};

	const Search = ConceptSearchBar({ globalFilterValue, handleChange });
	const handleChangeTab = (k) => setKey(k);

	if (loading)
		return (
			<div className="position-relative h-100">
				<Spinner />
			</div>
		);
	return (
		<PageContainer classNames="container-fluid p-5">
			<div className="p-4 d-flex justify-content-between ">
				<PageTitle>{t(recordTitle)}</PageTitle>
				<div className="p-4">{Search}</div>
			</div>
			<CustomTabs id="controlled-tab-example" activeKey={key} onSelect={handleChangeTab} className="mb-3" mountOnEnter={true} unmountOnExit={true}>
				<Tab eventKey="folder" title={t("JS_67") + ` (${folders.length})`}>
					<ScrollContainer>
						<div className="">
							<DataTable context={pathname === "/archive" ? "folders.archived" : "folders.deleted"} />
						</div>
					</ScrollContainer>
				</Tab>

				{isSubscriptionAllowed && (
					<Tab eventKey="cxPrograms" title={t("CX_PROGRAMS") + ` (${cxPrograms.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "cxPrograms.archived" : "cxPrograms.deleted"} ref={childCxPrograms} />
							</div>
						</ScrollContainer>
					</Tab>
				)}
				<Tab eventKey="Persona" title={t("ASPX_98") + ` (${personas.length})`}>
					<ScrollContainer>
						<div className="">
							<DataTable context={pathname === "/archive" ? "personas.archived" : "personas.deleted"} ref={childPersonas} />
						</div>
					</ScrollContainer>
				</Tab>

				<Tab eventKey="Maps" title={t("JS_71") + ` (${maps.length})`}>
					<ScrollContainer>
						<div className="">
							<DataTable context={pathname === "/archive" ? "map.archived" : "map.deleted"} ref={childMaps} />
						</div>
					</ScrollContainer>
                </Tab>

                {isSubscriptionAllowed && (
					<Tab eventKey="insights" title={t("ASPX_33") + ` (${insights.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "insights.archived" : "insights.deleted"} ref={childProjects} />
							</div>
						</ScrollContainer>
					</Tab>
				)}

				{isSubscriptionAllowed && (
					<Tab eventKey="Opportunities" title={t("OPPORTUNITIES") + ` (${opportunities.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "opportunity.archived" : "opportunity.deleted"} ref={childOpportunities} />
							</div>
						</ScrollContainer>
					</Tab>
				)}

				{isSubscriptionAllowed && (
					<Tab eventKey="Solutions" title={t("SOLUTIONS") + ` (${solutions.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "solutions.archived" : "solutions.deleted"} ref={childSolutions} />
							</div>
						</ScrollContainer>
					</Tab>
				)}

				{isSubscriptionAllowed && (
					<Tab eventKey="Actions" title={t("JS_80") + ` (${actions.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "cxActions.archived" : "cxActions.deleted"} ref={childActions} />
							</div>
						</ScrollContainer>
					</Tab>
				)}

				{isSubscriptionAllowed && (
					<Tab eventKey="Projects" title={t("PROJECTS") + ` (${projects.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "projects.archived" : "projects.deleted"} ref={childProjects} />
							</div>
						</ScrollContainer>
					</Tab>
				)}

				{isTemplateAllowed() && isSubscriptionAllowed && (
					<Tab eventKey="MapTemplates" title={t("JOURNEY_TEMPLATES") + ` (${mapTemplates.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "map.archivedTemplates" : "map.deletedTemplates"} tab="template" ref={childMapTemplates} />
							</div>
						</ScrollContainer>
					</Tab>
				)}

				{isTemplateAllowed() && isSubscriptionAllowed && (
					<Tab eventKey="PersonaTemplates" title={t("PERSONA_TEMPLATES") + ` (${personaTemplates.length})`}>
						<ScrollContainer>
							<div className="">
								<DataTable context={pathname === "/archive" ? "personas.archivedTemplate" : "personas.deletedTemplate"} tab="template" ref={childPersonaTemplate} />
							</div>
						</ScrollContainer>
					</Tab>
				)}
			</CustomTabs>
		</PageContainer>
	);
}

const ScrollContainer = styled.div`
	width: 100%;
	height: calc(100vh - 360px);
`;

const CustomTabs = styled(Tabs)`
	button {
		font-size: 14px !important;
	}
`;
