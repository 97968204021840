import { getPhaseByStageId, getStage, makeGetCjmDataInteraction } from "@redux/memoizedSelectors";
import { PermissionManager } from "@utils/permissionManager";
import { linkInteraction, unlinkInteraction, updateInteraction } from "features/journey-map/reducer/CJMActions";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { EditorProvider } from "shared/dynamic-imports";
import { Borders } from "shared/ui-basics/index";
import { Descriptions, HeaderTouchpoints, PropertyManager, RelationManager, RoutesButtons } from "./components/index";
import { Body, Header, OffcanvasLayout } from "./personaInteractions_style";
import { includes } from "lodash";

function EditInteraction({ isOpen, onClose, ...props }) {
	const { stageId } = props;
	const { cjmIdStr, personaIdStr } = useLocation().search.getAllMapsParams();
	const { t } = useTranslation();
	const interaction = useSelector(makeGetCjmDataInteraction(stageId, props?.interaction?.id));
	const { name: stageName } = useSelector(getStage(stageId)) || {};
	const { name: phaseName } = useSelector(getPhaseByStageId(stageId)) || {};
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const userAccess = useSelector((state) => state?.maps?.currentUser?.roleName);
	const { editAllowed } = PermissionManager({ userAccess });
	const isEditable = !isReadOnly && editAllowed;
	const mode = isEditable ? "edit" : "viewer";
	const { touchPointId } = interaction;
	const isEnterprise = useSelector(({ auth }) => auth?.userInfo?.UserSubscriptionPlanName === "Enterprise");
	// const interaction = { ...object, [attribute]: descriptions.truncate(19999) };

	const updateFunction = (props) => {
		updateInteraction({
			...props,
			cjmIdStr, personaIdStr
		})
	};
	const updateFunctionGlobal = (props) => {
		updateFunction(	{...props,
			interaction: { ...props?.interaction, actions: interaction?.actions?.truncate(19999) }})
		
	};
	useEffect(() => {
		if (!cjmIdStr || !personaIdStr) {
			// means we got redirected to another page
			return () => {
				onClose();
			};
		}
	}, [cjmIdStr, personaIdStr]);

	const defaultRows = interaction?.jsonProperty
		? JSON.parse(interaction.jsonProperty)
		: {
			displayed: ["persona", "sentiment"],
			extra: ["systems", "departments", "linkedTouchpoints"],
		};

	const updateRows = (rows) => {
		const newInteraction = { ...interaction, jsonProperty: JSON.stringify(rows) };
		updateFunction({ interaction: newInteraction });
	};
	return (
		<OffcanvasLayout show={isOpen} onHide={onClose} scroll={true} backdrop={true} placement={"end"} container={document.getElementById("modal-root")}>
			<Header closeButton>
				{phaseName.defaultPlaceholder("NEW_PHASE")} / {stageName?.defaultPlaceholder("NEW_STAGE")}
			</Header>
			<Body>
				{touchPointId && <HeaderTouchpoints touchPointId={touchPointId} />}
				{isEnterprise && (
					<Borders top bottom className={"p-1 my-3"}>
						<RoutesButtons {...props} mode={mode} />
					</Borders>
				)}
				<EditorProvider>
					<Descriptions object={interaction} update={updateFunction} attribute="actions" placeholder={t("ACTION_TOUCHPOINT_PLACEHOLDER")} mode={mode} />
				</EditorProvider>
				<PropertyManager
					item={interaction}
					updateFunction={updateFunctionGlobal}
					mode={mode}
					recordType="cem_customerexperience"
					defaultRows={defaultRows}
					linkFunction={linkInteraction}
					unlinkFunction={unlinkInteraction}
					updateRows={updateRows}
				/>
				<RelationManager stageId={stageId} item={interaction} mode={mode} onClose={onClose} defaultKey="ASPX_33" dataLoading={cjmIdStr && personaIdStr ? false : true} />
			</Body>
		</OffcanvasLayout>
	);
}

EditInteraction.displayName = "EditInteraction";

EditInteraction.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	interaction: PropTypes.object,
	stageId: PropTypes.string,
};

export default React.memo(EditInteraction);
