import _ from "lodash";
import {
	GetActionContext,
	GetAssessmentContext,
	GetFolderContext,
	GetInsightContext,
	GetJourneyMapContext,
	GetLibraryContext,
	GetOpportunityContext,
	GetPersonaContext,
	GetProgramContext,
	GetProjectContext,
	GetSolutionContext,
	GetUserContext,
} from "./contexts";

export default function DataContext(props) {
	const route = props.context.split(".")[0];
	let data;
	switch (route) {
		case "map":
			data = GetJourneyMapContext(props);
			break;
		case "cxActions":
			data = GetActionContext(props);
			break;
		case "cxPrograms":
			data = GetProgramContext(props);
			break;
		case "personas":
			data = GetPersonaContext(props);
			break;
		case "opportunity":
			data = GetOpportunityContext(props);
			break;
		case "solutions":
			data = GetSolutionContext(props);
			break;
		case "folders":
			data = GetFolderContext(props);
			break;
		case "users":
			data = GetUserContext(props);
			break;
		case "assessments":
			data = GetAssessmentContext(props);
			break;
		case "libraries":
			data = GetLibraryContext(props);
			break;
		case "projects":
			data = GetProjectContext(props);
			break;
		case "insights":
			data = GetInsightContext(props);
			break;
		default:
			break;
	}

	return data;
}
