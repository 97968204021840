
import Form from 'react-bootstrap/Form';
import styled from 'styled-components/macro';

export const Container = styled.div`
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    `
export const SVG = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        transform: scale(1.07);
        z-index: 2;

    `
export const Logo = styled.img`
    position: absolute;
    top: 30px;
    left:32px;
    z-index: 3;

`
export const LoginModal = styled.div`
border-radius: 10px;
background: #FFF;
box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
width: 540px;
height: 410px;
flex-shrink: 0;
z-index: 3;
display: flex;
flex-direction: column;
gap: 20px;
padding: 2rem;
`
export const Forms = styled(Form)`
display: flex;
flex-direction: column;
gap: 20px;
`
export const Title = styled.div`
color: #3F3D56;

font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
`
export const Description = styled.div`
color: #3F3D56;

font-family:Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
`
export const Label = styled(Form.Label)`
color: #3F3D56;

font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
`
export const LayoutAuth = styled.div`
display: flex;
flex-direction: column;
gap:  ${props => props.gap}px;
`
export const ButtonForgetPassword = styled.div`
color: #3F3D56;
text-decoration: underline;
text-underline-offset: 6px;
text-decoration-thickness: 1px; /* Thickness of the underline */
font-size: 14px;
line-height: 24px;
font-weight: 400;
cursor: pointer;
`
export const DescResetPassword = styled.span`
color: #3F3D56;
font-family:Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
`
export const ResetModal = styled.div`
border-radius: 10px;
background: #FFF;
box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px -1px 4px 0px rgba(0, 0, 0, 0.25);
width: 540px;
height: 354px;
flex-shrink: 0;
z-index: 3;
display: flex;
flex-direction: column;
gap: 24px;
padding: 2rem;
`
export const Contact = styled.a`
text-decoration: underline;
color: #3F3D56;
`