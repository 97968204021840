import { GenericButton } from "shared/ui-basics/index";
import { EditorProvider } from "shared/dynamic-imports";
import { t } from "i18next";
import { Offcanvas } from "react-bootstrap";
import styled from "styled-components";
import React,{ useState } from "react";
import EditCxGoalCard from "shared/components/cards/cx-goals/EditCxGoalCard";

export const EditCxGoalModal = ({ cxGoal, handleAction, isOpen, onClose }) => {
	const [cxGoals, setCxGoals] = useState([cxGoal]);

	const handleClick = () => {
		onClose();
		handleAction(cxGoals[0]);
	};

	return (
		<OffcanvasLayout show={isOpen} onHide={onClose} scroll={true} backdrop={true} placement="end">
			<CloseButton>
                <GenericButton variant="light-link" icon="X" onClick={onClose} />
			</CloseButton>
			<EditorProvider>
				<Body className="px-4">
					<OffCanvasTitle>{t("SELECTED_MEASURE")}</OffCanvasTitle>
					<EditCxGoalCard cxGoal={cxGoals[0]} setCxGoals={setCxGoals} />
				</Body>
			</EditorProvider>
			<Footer className="p-4">
				<GenericButton variant="primary" onClick={handleClick}>
					{t("JS_88")}
				</GenericButton>
			</Footer>
		</OffcanvasLayout>
	);
};

const OffcanvasLayout = styled(Offcanvas)`
	width: 480px;
`;

const Body = styled.div`
	height: 92vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: scroll;
`;

const CloseButton = styled.div`
	align-self: flex-end;
`;

const OffCanvasTitle = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 32px;
    align-self: flex-start;
    margin-left: 60px;
`;

const Footer = styled.div`
	border-top: 1px solid #c4c4c4;
`;
