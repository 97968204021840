import styled from "styled-components/macro";
import { ColorCircle } from "../Style";

function CustomLegend({ data, customClassNames="" }) {
	return (
        <Legends className={customClassNames}>
			{data.map((element, index) => {
				return (
					<Container key={element.name + index} className="p-1">
						<ColorCircle color={element.color} />
						<Name>{element.name}</Name>
					</Container>
				);
			})}
		</Legends>
	);
}

CustomLegend.displayName = "CustomLegend";
export default CustomLegend;

const Legends = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 200px;
    max-height: 350px;
    overflow: scroll;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;


const Name = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem;
`;
