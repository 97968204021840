// actions
export const setDragItem = (item) => ({
    type: 'SET_DRAG_ITEM',
    payload: item,
  });
  
  export const clearDragItem = () => ({
    type: 'CLEAR_DRAG_ITEM',
  });
  
  export const setDragging = () => ({
    type: 'SET_DRAGGING',
  });
  
  export const clearDragging = () => ({
    type: 'CLEAR_DRAGGING',
  });
  
  // reducer
  export default function commentsReducer(state = { dragItem: null }, action) {
    switch (action.type) {
        case 'SET_DRAG_ITEM':
            return { ...state, dragItem: action.payload };
          case 'CLEAR_DRAG_ITEM':
            return { ...state, dragItem: null };
          case 'SET_DRAGGING':
            return { ...state, isDragging: true };
          case 'CLEAR_DRAGGING':
            return { ...state, isDragging: false };
      default:
        return state;
    }
  }