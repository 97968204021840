import loginPage from "@Assets/images/login-page-background.png";
import CEM from "@Assets/images/logo-final-cem.svg";

import StepWizard from "react-step-wizard";
import LoginStep from "../components/LoginStep";
import ResetStep from "../components/ResetStep";
import { Container, SVG ,Logo} from "./auth-style";

export default function LoginPage() {

  let custom = { enterRight: "", enterLeft: "", exitRight: "", exitLeft: "", intro: "", };

  return <Container>
    <Logo src={CEM} alt="CEM" />
    <SVG src={loginPage}  alt='background-login'/>
    <StepWizard transitions={custom} totalSteps={2} isHashEnabled={false} isLazyMount={true} className="zI-20">
      <LoginStep />
      <ResetStep />
    </StepWizard>
  </Container>
}
