import { Skeleton } from 'primereact/skeleton'
import React from 'react'
import * as Style from './Style'


export default function CxActionLoadingCard() {
  return (
    <Style.CardLoading>
        <Style.Center>
            <div>
            <Skeleton height="1rem" width='7vw' className="my-1"></Skeleton>
            <Skeleton height="1rem" width='9vw' className="mb-2"></Skeleton>
         </div>
        </Style.Center>
        <Style.Center>
            <div className='me-2'>
            <Skeleton height="1vw" width='1vw' shape="circle" className="my-1"></Skeleton>
         </div>
         <Skeleton height="1rem" width='4vw' className="my-1"></Skeleton>
        </Style.Center>
        <Style.Center>
            <div>
            <Skeleton height="2vw" width='5vw' className="my-1"></Skeleton>
         </div>
        </Style.Center>

        <Style.Center>
            <div>
            <Skeleton height="1.5vw" width='1.5vw' shape="circle" className="my-1"></Skeleton>

         </div>
        </Style.Center>

    </Style.CardLoading>
  )
}
