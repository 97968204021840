import { getSessionId } from '@redux/memoizedSelectors';
import { getterApis } from '@utils/optionsSets/getterApis';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Personas from '../../../../features/grid-table/columns-components/Personas';
import CustomTooltip from '../../CustomTooltip';


export function GenericCard({ item, filterParams, filterAttribute, handleFilterChange }) {
    const tooltipRef = useRef(null);
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    useEffect(() => {
        if (tooltipRef?.current?.offsetWidth < tooltipRef?.current?.scrollWidth) {
            setIsEllipsisActive(true);
        }
    }, [tooltipRef?.current]);

    return (
        <CustomTooltip text={item?.name} disabled={!isEllipsisActive}>
            <GenericCardWrapper className='p-2' onClick={() => handleFilterChange(filterAttribute, item?.id)}>
                <InputWrapper className='me-2'>
                    <input type="checkbox" checked={filterParams[filterAttribute].includes(item?.id)} onChange={() => { }} />
                </InputWrapper>
                {filterAttribute === "statusIds" && <ColoredBox color={item?.colorCode} />}
                <ItemName ref={tooltipRef}>{item?.name}</ItemName>
            </GenericCardWrapper>
        </CustomTooltip>
    )
}

const GenericCardWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 38px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    :hover {
        cursor: pointer;
    }
    input[type="checkbox"] {
        accent-color: #333333;
        width: 15px !important;
        height: 15px !important;
    }
`

const InputWrapper = styled.div`
    width: 5%;
`

const ColoredBox = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: ${props => props.color};
`

const ItemName = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export function MapCard({ map, filterParams, handleFilterChange }) {
    const tooltipRef = useRef(null);
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);
	const sessionId = useSelector(getSessionId);

    const mapCustomImageUrl = getterApis['GET_MAP_CUSTOM_IMG'](map.cjmId, sessionId)
    const [mapImage, setMapImage] = useState(mapCustomImageUrl + `&${Math.random()}=true`)

    useEffect(() => {
        if (tooltipRef?.current?.offsetWidth < tooltipRef?.current?.scrollWidth) {
            setIsEllipsisActive(true);
        }
    }, [tooltipRef?.current]);

    return (
        <CustomTooltip text={map?.name} disabled={!isEllipsisActive}>
            <GenericCardWrapper className='p-2' onClick={() => handleFilterChange("mapIds", map?.cjmId)}>
                <InputWrapper className='me-2'>
                    <input type="checkbox" checked={filterParams["mapIds"].includes(map?.cjmId)} onChange={() => { }} />
                </InputWrapper>
                <Image src={mapImage} loading='lazy' alt="" />
                <PersonaWrapper>
                    {Personas(map, 1, 26)}
                </PersonaWrapper>
                <ItemName ref={tooltipRef}>{map?.name}</ItemName>
            </GenericCardWrapper>
        </CustomTooltip>
    )
}

const PersonaWrapper = styled.div`
`

const Image = styled.img`
    max-height: 30px;
    max-width: 30px;
`
