import { useClickOutside } from "shared/hooks/index";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components/macro";

const EditTitle = ({ setRename, obj, obj_id, obj_name, updateName, updateObject, setName, nameAttribute }) => {
	const refwrapper = React.useRef(null);
	const refFocus = React.useRef(null);
	const dispatch = useDispatch();
	const [originalName, setOrginalName] = useState(obj_name);

	useEffect(() => {
		refFocus.current.focus();
	}, []);

	const handleClickOutside = () => {
		updateServer();
		setRename("");
	};

	useClickOutside(refwrapper, handleClickOutside);

	const update = (e) => {
		setName(e.target.value.truncate(100));
	};

	const updateServer = async () => {
		if (originalName === obj_name) return;
		const newName = obj_name === "" ? "[Name]" : obj_name;
		setName(newName);

		if (updateName) {
			const { error } = await dispatch(updateName({ id: obj_id, name: newName }));
			if (error) {
				toast.error(error);
			}
		}

		if (updateObject) {
			obj[nameAttribute] = newName;
			updateObject(obj, ["name"]);
		}
	};

	return (
		<div ref={refwrapper} className="w-100">
			<EditTextWrapper type="text" ref={refFocus} onChange={update} value={obj_name} placeholder="Enter Title" />
		</div>
	);
};

const EditTextWrapper = styled.input`
	height: 100%;
	width: 100%;
	padding: 5px;
	border-radius: 4px;
	border: 1px solid #f96209;
	background: rgba(249, 98, 9, 0.04);
`;

export default EditTitle;
