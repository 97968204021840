import { encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/folder/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/folder/", method: "GET" }); // api actions type get

export const getFolders = () => createGetAction({ type: 'GET_FOLDERS', endPoint: "getAll", meta: { joinRequest: 'GET_FOLDERS' } })
export const getAllFoldersFn = () => genericService({ action: getFolders });
export const updateFolderName = (props) => createPostAction({
	type: 'UPDATE_FOLDER_NAME', endPoint: "updateFolderName",
	data: {
		folderId: props?.id,
		newValue: window.btoa(unescape(encodeURIComponent(props.name))),
	}
})
export const deleteFolder = (props) => genericService({
	...props, action: createPostAction({
		type: 'DELETE_FOLDER', endPoint: "deleteFolder",
		data: {
			folderId: props?.id,
		}
	})
});
export const archiveFolder = (props) => genericService({
	...props, action: createPostAction({
		type: 'ARCHIVE_FOLDER', endPoint: "archiveFolder",
		data: {
			folderId: props?.id,
		}
	})
});

export const deleteFolderPermanently = (props) => genericService({
	...props, action: createPostAction({
		type: 'DELETE_FOLDER_PERMANENTLY', endPoint: "deletePermanentlyFolder",
		data: {
			folderId: props?.id,
		}
	})
});
export const restoreFolder = (props) => genericService({
	...props, action: createPostAction({
		type: 'RESTORE_FOLDER', endPoint: "restoreFolder",
		data: {
			folderId: props?.id,
		}
	})
});
export const createFolder = (props) => genericService({
	...props, action: createPostAction({
		type: 'CREATE_FOLDER', endPoint: "createFolder",
		data: {
			name: encode(props.name),
			parentId: props.parentId,
		}
	}),
	displayToast: "CREATE",
});
export const updateFolderLinks = (props) => genericService({
	...props, action: createPostAction({
		type: 'UPDATE_LINKED_FOLDERS', endPoint: "updateFolderLinks",
		data: {
			folderIds: props?.folderIds,
			idsToLink: props?.idsToLink || [],
			idsToUnlink: props?.idsToUnlink || [],
			entityName: props?.entityName,
		}
	}),
	// displayToast: "UPDATE", toastParams: {success: t("RECORDS_MOVED_TO_FOLDER")}
});
