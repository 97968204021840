import { widthValidation } from "@utils/helpers";

export const TypeValidation = (type,hideForPdf) => {
    switch (type) {
        case 'X':
            return { id: type, type: 'number', label: 'x' }
        default:
            if (hideForPdf)
            return [{ id: type, type: 'number' }, { id: type, type: 'string', role: 'annotation' }];
        else
            return [{ id: type, type: 'number' }, { id: type, role: 'tooltip', type: "string", p: { html: true } }];
    }
}

export const dataValidation = (chart, stage, cjmPersonasData) => {
    if (Object.keys(chart).length > 0 && chart.feelingPoints_Global !== null) {
        const chartData = [
            chart?.feelingPoints_Global,
            chart?.feelingPoints_Target,
            chart?.feelingPoints_Contact,
            ...cjmPersonasData?.map((persona) => chart?.feelingPoints_Perso[persona?.personaId]),
            ...cjmPersonasData?.map((persona) => chart?.feelingPoints_PersoVOC[persona?.personaId]),

        ];
        const data = [];
        let temp = 0;
        for (let i = 0; i < stage.length; i++) {
            const newData = chartData?.map((value, index) => {
                return value?.filter((v) => v[0] === stage[i].id).length > 0 ? [value?.filter((v) => v[0] === stage[i].id)[0][1], value?.filter((v) => v[0] === stage[i].id)[0][2]].flat() : [0, 0];
            });
            const stageMidpoint = Math.ceil(widthValidation(stage[i].width) / 2);

            const newDatas = [temp + stageMidpoint, ...newData.flat()];
            temp += widthValidation(stage[i].width) + 8;

            data.push(newDatas);
        }
        return data;
    } else return [];
};


export const getChartOptions = (colorLine, totalWidth, mapLegend, hideForPdf) => {
    const options = {
       // pointSize: 18,
        lineWidth: 3,
        ...(!hideForPdf ? {
            animation: {
                duration: 500,
                easing: "out",
                startup: true,
            }
        } : {})
        ,
        series: getChartLineTypes(colorLine,mapLegend),
        hAxis: {
            textPosition: "none",
            baselineColor: "white",
            minValue: 0,
            maxValue: totalWidth,
            ticks: []
        },
        vAxis: {
            maxValue: 4,
            ticks: [{ v: 0, f: "" }, { v: 1, f: "" }, { v: 2, f: "" }, { v: 3, f: "" }, { v: 4, f: "" }],
            textPosition: "none",
            baselineColor: "#CCCCCC"
        },
        chartArea: {
            top: 15,
            bottom: 10,
            right: 10,
            left: 10
        },
        legend: {
            position: "none"
        },
        pointSize: 12,
        width: totalWidth ,
        height: 300,
        backgroundColor: "#ffffff",
        ...(hideForPdf ? {
            annotations: {
                textStyle: {
                  fontName: 'Inter',
                  fontSize: 12,
                  bold: true,
                //   italic: true,
                  // The color of the text.
                  color: '#333333',
                  // The color of the text outline.
                //   auraColor: '#d799ae',
                  // The transparency of the text.
                  opacity: 0.8
                }
            }
            } : {}),
            displayAnnotations: true,
        colors: getChartLineColors(colorLine, mapLegend),
        tooltip: { isHtml: true }
    };
    return options
}

const GLOBAL_LINE = { id: 'Global', color: '#8da0b3' };
const TARGET_LINE = { id: 'Target', color: '#F96209' };
const VOC_LINE = { id: 'VoC', color: '#8da0b3' };

const PERSONA_COLORS = [
  '#FF008E', '#006BA6', '#18AEAD', '#8A39E1', '#548CFF', '#FC9918',
  '#DF2935', '#474973', '#750550', '#6F732F', '#4ECF71', '#B91646', '#895737'
];

export const getChartLinesColors = (cjmPersonasData) => {
  const personaLines = cjmPersonasData?.flatMap((item, index) => [
    { id: item.personaId, color: PERSONA_COLORS[index] },
  ]) || [];
  const personaLinesVOC = cjmPersonasData?.flatMap((item, index) => [
    { id: `${item.personaId}_VOC`, color: PERSONA_COLORS[index] }
]) || [];

  return [GLOBAL_LINE, TARGET_LINE, VOC_LINE, ...personaLines, ...personaLinesVOC];
};
const getChartLineColors = (colorLine, mapLegend) => {
    const includedLineIds = new Set(mapLegend);
    const includedLines = colorLine.filter(item => includedLineIds.has(item.id));
    const includedColors = includedLines.map(item => item.color);
    return includedColors;
  };
  const getChartLineTypes = (colorLine, mapLegend) => {
    const includedLineIds = new Set(mapLegend);
    const includedLines = colorLine.filter(item => includedLineIds.has(item.id));

    const series =  includedLines?.reduce((acc, item, index) => {
        if (item?.id?.includes("_VOC") ||item?.id?.includes("VoC")) {
            acc[index] = { lineDashStyle: [6, 6] };
        } else {
            acc[index] = { lineWidth: 2 };
        }
        return acc;
    }, {})
    return series;
  };