import { createFolder } from "@redux/index";
import { setNavigationSidebar } from "@redux/pageReducer";
import { isReadOnly, isUserAllowed } from "@utils/helpers";
import { UserType } from "@utils/optionsSets/OptionSets";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NewModal } from "shared/modals/index";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import FoldersManager from "../../folders/FoldersManager";
import { NavigationElement } from "../Components/navigation-element";
import { TopActions } from "../Components/top-actions";
import { allRoutes, editRoutes, inactiveTrashRoutes, settingsfooterRoutes } from "../../../shared/Routes/routes";
import * as Style from "./style";
import { getterApis } from "@utils/optionsSets/getterApis";
import { getSessionId } from "@redux/memoizedSelectors";

export const NavigationSidebar = ({ hideForPdf, isPreview }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const [openTrash, setOpenTrash] = React.useState(false);
	const [openSettings, setOpenSettings] = React.useState(false);

	const isNavigationBarOpen = useSelector(({ ui }) => ui.page.sidebar.isOpen);
	const userInfo = useSelector(({ auth }) => auth?.userInfo || {});
	const isOverflowAllowed = useSelector(({ ui }) => ui.page.sidebar.isOverflowAllowed);
	const isCurrentWorkspaceOwner = useSelector((state) => state.workspaces.currentWorkspaceRole) === "owner";
	const ownedWorkspaces = useSelector((state) => state.workspaces.all_workspaces)?.filter((ws) => ws.authorizedUsers.find((au) => au?.id === userInfo?.UserId)?.roleName === "owner");
	const isAWorkspaceOwner = ownedWorkspaces.length > 0;
	const logoRefreshValue = useSelector((state) => state.settings.organization?.logoRefreshValue);

	const userType = Number(userInfo?.UserType);
	const isCompanyAdmin = userType === UserType.companyAdmin;
	const isAdmin = userType === UserType.admin;
	const isConfigAllowed = ["Expert", "Enterprise"].includes(userInfo?.UserSubscriptionPlanName);
	const sessionId = useSelector(getSessionId);

	const hideSidebar = hideForPdf || isPreview;
	const [newModal] = useModal(NewModal);

	const getSideBarStatus = () => {
		if (["/templates", "/integration"].includes(location.pathname)) return true;
		if (editRoutes.includes(location.pathname)) return false;
		return isNavigationBarOpen;
	};
	useEffect(() => {
		setNavigationSidebar(getSideBarStatus());
		handleClickSidebar();
	}, [location.pathname]);

	const inactiveProps = { ...allRoutes.footer[0], icon: openTrash ? "ARROW_LEFT" : allRoutes.footer[0].icon };
	const settingsProps = { ...allRoutes.footer[1], icon: openSettings ? "ARROW_LEFT" : allRoutes.footer[1].icon };
	const showManageUsers = isCompanyAdmin || isAdmin;
	const showSettings = (isCompanyAdmin || isAdmin || isAWorkspaceOwner) && !openTrash;
	const showWorkspaces = isCompanyAdmin || isAWorkspaceOwner;
	const showCustomizations = isCompanyAdmin || isAdmin || isCurrentWorkspaceOwner;

	const showFooterElement = (route) => {
		let show = true;
		if (route === "/organization" && (!isCompanyAdmin && !isAdmin)) show = false;
		if (route === "/configuration" && (!isConfigAllowed || !isCompanyAdmin)) show = false;
		if (route === "/workspaces" && !showWorkspaces) show = false;

		return show;
	};

	const createNewFolder = (e) => {
		e.stopPropagation();
		newModal({
			title: "JS_84",
			closeBtnLabel: "ASPX_135",
			inputTitle: "JS_69",
			placeholder: "JS_68",
			action: (name) => {
				createFolder({ name });
			},
		});
	};

	const handleOpenTrash = () => {
		setOpenSettings(false);
		setOpenTrash(!openTrash);
	};

	const handleOpenSettings = () => {
		setOpenTrash(false);
		setOpenSettings(!openSettings);
	};

	const handleClickSidebar = () => {
		const settingsRoutes = settingsfooterRoutes.map((route) => (route?.activeRoutes ? route?.activeRoutes : route?.route)).flat();
		const settingsRoutesUpdated = [...settingsRoutes, ...["/library-touchpoints", "/library-stickers", "/library-custom", "/library-brandvalues", "/library-measures"]];
		if (!openSettings && settingsRoutesUpdated.includes(location.pathname)) return setOpenSettings(true);

		if (!settingsRoutesUpdated.includes(location.pathname) && openSettings) return setOpenSettings(false);

		if (openTrash && !["/archive", "/trash"].includes(location.pathname)) {
			return setOpenTrash(false);
		}
	};

	return (
		<React.Fragment>
			<Style.SideBar variant={isNavigationBarOpen ? "open" : "close"} hideSidebar={hideSidebar}>
				<Style.SidebarTop className="mt-2 px-2" disableoverflow={`${isOverflowAllowed}`} id="sidebar-top">
				{sessionId && isNavigationBarOpen&& <Style.LogoHeader className={hideForPdf && "ms-2"} src={getterApis["GET_ACCOUNT_IMG"](sessionId, logoRefreshValue)} alt="logo" loading="lazy" />}

					<TopActions setNavigationSidebar={setNavigationSidebar} isNavigationBarOpen={isNavigationBarOpen} />
					<NavigationElement {...allRoutes.top[0]} />
					{isNavigationBarOpen && (
						<>
							{allRoutes.top.slice(1, Infinity).map((route, index) => (
								<NavigationElement key={index} {...route} isWorkspace={true} />
							))}
							{!isReadOnly(userType) && (
								<div>
									<GenericButton className="m-2" variant="light-link" icon="SQUARE_PLUS" size="s" onClick={createNewFolder} iconClassName="me-1">
										{t("ADD_FOLDER")}
									</GenericButton>
								</div>
							)}
							<FoldersManager />
						</>
					)}
				</Style.SidebarTop>
				<Style.SidebarBottom>
				{!openSettings &&!openTrash &&  <Style.MenuDivider />}
					{isUserAllowed(userType, "user") && !openSettings && (
						<Style.Flip open={openTrash}>
							 {!openTrash && <div onClick={handleOpenTrash} className="mt-2">
								<NavigationElement {...inactiveProps} />
							</div>}
							{openTrash && (<>
									<div onClick={handleOpenTrash} className="my-2">
									<Style.SubSidebarLabel className="c-pointer" >
										{IconDispatcher("ARROW_LEFT","me-2",{style:'regular',family:'sharp',size:"md"})}{t("DISABLED_RECORDS")}</Style.SubSidebarLabel>
								</div>
								<div className="ms-2">
									{inactiveTrashRoutes.map((route, index) => (
										<NavigationElement key={index} {...route} />
									))}
								</div>
								</>
							)}
						</Style.Flip>
					)}
					{showSettings && (
						<Style.Flip open={openSettings}>
							{!openSettings && (
								<div onClick={handleOpenSettings} className="mt-2">
									<NavigationElement {...settingsProps} />
								</div>
							)}
							{openSettings && (
								<div className="ms-2 overflow-hidden">
									<div onClick={handleOpenSettings} className="my-2">
										<Style.SubSidebarLabel className="c-pointer" >
											{IconDispatcher("ARROW_LEFT","me-2",{style:'regular',family:'sharp',size:"md"})}{t("ASPX_207")}</Style.SubSidebarLabel>

									</div>
									<div>
										{settingsfooterRoutes.slice(0, 3)?.map((route, index) => {
											if (showFooterElement(route.route)) {
												return <NavigationElement key={index} {...route} />;
											}
										})}
									</div>
									{showManageUsers && (
										<>
											<Style.SubSidebarLabel>{t("ASPX_227")}</Style.SubSidebarLabel>
											<div>
												{settingsfooterRoutes.slice(3, 6)?.map((route, index) => {
													if (showFooterElement(route.route)) {
														return <NavigationElement key={index} {...route} />;
													}
												})}
											</div>
										</>
									)}
									{showCustomizations && !isReadOnly(userType) && (
										<>
											<Style.SubSidebarLabel>{t("WORKSPACE_CUSTOMIZATION")}</Style.SubSidebarLabel>
											<div>
												{settingsfooterRoutes.slice(6, 10)?.map((route, index) => {
													if (showFooterElement(route.route)) {
														return <NavigationElement key={index} {...route} />;
													}
												})}
											</div>
										</>
									)}
								</div>
							)}
						</Style.Flip>
					)}
				</Style.SidebarBottom>
			</Style.SideBar>
		</React.Fragment>
	);
};
