import { EMPTY_GUID, extractTextFromHtml } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import * as Style from "./image-card-style";
import { GenericButton } from "shared/ui-basics/buttons";
import React from "react";
function ImageCard({ object, add, preview }) {
	const { t } = useTranslation();

	const header = (
		<Style.ImageContainer wizard={`${object?.wizard || false}`}>
			<object.image />
		</Style.ImageContainer>
	);

	const title = (
		<CustomTooltip text={object.title} disabled={false}>
			<TitleWrapper>{object.title}</TitleWrapper>
		</CustomTooltip>
	);

	const footer = (
		<>
			{object?.multi !== undefined && <span className="d-flex">{!object.multi && <Style.MultiLabel multi={object.multi}>{object.multi ? "" : t("SINGLE")}</Style.MultiLabel>}</span>}
			{object?.useButton && (
				<div className="d-flex justify-content-end mt-4">
					<GenericButton variant="primary-outline" className={"me-2 no-border"} size='s' onClick={preview ? () => preview(object) : undefined}>
						{t("PREVIEW")}
					</GenericButton>
					<GenericButton variant="primary-outline"  size='s'onClick={() => add(object.id, object?.libraryId ?? EMPTY_GUID)}>
						{t("USE")}
					</GenericButton>
				</div>
			)}
		</>
	);

	return (
		<Style.LaneCard
			key={object?.id}
			wizard={`${object?.wizard || false}`}
			header={header}
			footer={footer}
			title={title}
			className="m-2"
			disabled={object?.disable}
			onClick={object?.wizard ? () => {} : () => add(object.id)}>
			<Style.Description wizard={`${object?.wizard || false}`}>{extractTextFromHtml(object?.description)}</Style.Description>
		</Style.LaneCard>
	);
}
ImageCard.displayName = "ImageCard";
export default ImageCard;
const TitleWrapper = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.5;
`;
