import { updateUser, upsertSettingUser } from "@redux/index";
import { MAX_SIZE_INPUT, emailValidation, supportedLanguages } from "@utils/helpers";
import i18n from "@utils/i18n";
import { SubscriptionPlan } from "@utils/optionsSets/OptionSets";
import UserDetails from "features/settings/manage-users/components/user-details";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FlexAlignCenter, Input, Label } from "shared/ui-basics";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components/macro";
import { PasswordManager } from "./components/password-manager";

const UserProfileModal = memo(({ isOpen, onClose, user }) => {
	const { t } = useTranslation();
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const defaultLanguageLcid = useSelector((state) => state.settings.organization.defaultDisplayLanguage);
	const userSetting = useSelector((state) => state.settings.userSetting);
	const contracts = useSelector((state) => state.settings?.subscriptionDetails?.subscriptionContracts);
	const defaultLanguage = supportedLanguages.find((sl) => sl.lcid === defaultLanguageLcid);
	const isSingleSubscription = contracts?.length === 1;

	const [modifiedUser, setModifiedUser] = useState(user);
	const [languageValue, setLanguageValue] = useState(supportedLanguages.find((sl) => sl.value === userSetting?.language)?.value || defaultLanguage?.value);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [modifiedUserAttributes, setModifiedUserAttributes] = useState([]);
	const [isSaveDisabled, setIsSaveDisabled] = useState(false);

	const subscriptionPlanCode = Number(userInfo?.UserSubscriptionPlan);
	const isSSO = user?.isMember === false;
	const isStandard = subscriptionPlanCode === SubscriptionPlan.Standard;
	const isSharedExpert = subscriptionPlanCode === SubscriptionPlan.SharedExpert;
	const hideLoginName = !isSSO || isStandard || isSharedExpert;
	const isSharedEnvironment = (isSingleSubscription && isStandard) || isSharedExpert;

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const onSave = () => {
		i18n.changeLanguage(languageValue);
		localStorage.setItem("language", languageValue || "en");

		if (modifiedUser.firstName === "") return toast.info(t("JS_110"));
		if (modifiedUser.lastName === "") return toast.info(t("JS_111"));
		if (modifiedUser.email === "") return toast.info(t("COM_EMAIL_MISSING_WARNING"));
		if (!hideLoginName && modifiedUser.loginName === "") return toast.info(t("EMAIL_MISSING_WARNING"));
		if (!hideLoginName && !emailValidation(modifiedUser.loginName)) return toast.info(t("WRONG_EMAIL_FORMAT"));
		if (!emailValidation(modifiedUser.email)) return toast.info(t("WRONG_EMAIL_FORMAT"));
		if (modifiedUser.loginName.length > MAX_SIZE_INPUT || modifiedUser.firstName.length > MAX_SIZE_INPUT || modifiedUser.lastName.length > MAX_SIZE_INPUT)
			return toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));

		setIsSaveDisabled(true);
		updateUser({ modifiedUser, modifiedAttribNames: modifiedUserAttributes, displayToast: "UPDATE", onSuccess: () => setIsSaveDisabled(false) });
		upsertSettingUser({ userSetting: { id: userSetting?.id, language: languageValue }, modifiedAttribNames: ["language"] });
		onClose();
	};

	const handleUserChange = (e) => {
		const attribute = e.target.name;
		const value = e.target.value;
		if (e?.preventDefault) e?.preventDefault();
		if (e?.charCode === 13) {
			return;
		}

		if (!modifiedUserAttributes?.includes(attribute)) setModifiedUserAttributes([...modifiedUserAttributes, attribute]);
		setModifiedUser({ ...modifiedUser, [attribute]: value });
	};

	const RenderEmailsFields = () => {
		if (!isStandard && !isSharedExpert && isSSO)
			return (
				<>
					<CustomGroup>
						<Label>{t("CS_56")}</Label>
						<Input variant="greyed-disable" type="text" name="loginName" value={modifiedUser?.loginName || ""} disabled />
					</CustomGroup>
					<CustomGroup>
						<Label>{t("COMMUNICATION_EMAIL")}</Label>
						<Input variant="greyed-disable" type="text" name="email" value={modifiedUser?.email || ""} onChange={handleUserChange} />
					</CustomGroup>
				</>
			);
		return (
			<>
				<CustomGroup>
					<Label>{t("CS_56")}</Label>
					<Input variant="greyed-disable" type="text" name="email" value={modifiedUser?.email || ""} disabled />
				</CustomGroup>
				<CustomGroup></CustomGroup>
			</>
		);
	};

	return (
		<ModalContainer fade={false} isOpen={isOpen} toggle={onClose} size="sm" centered={true}>
			<ModalHeader toggle={onClose}>{t("PROFILE")}</ModalHeader>
			<ModalBody className="p-4">
				<DetailsContainer>
					<UserDetails user={modifiedUser} desactivateClick={true} size="l" />
				</DetailsContainer>
				<InputsSection>
					<FlexAlignCenter gap="28">
						<CustomGroup>
							<Label>{t("CS_57") + " *"}</Label>
							<Input variant="default" type="text" name="firstName" value={modifiedUser?.firstName || ""} onChange={handleUserChange} />
						</CustomGroup>
						<CustomGroup>
							<Label>{t("CS_58") + " *"}</Label>
							<Input variant="default" type="text" name="lastName" value={modifiedUser?.lastName || ""} onChange={handleUserChange} />
						</CustomGroup>
					</FlexAlignCenter>
					<FlexAlignCenter gap="28">{RenderEmailsFields()}</FlexAlignCenter>
					<FlexAlignCenter gap="28">
						<CustomGroup>
							<Label>{t("ROLE")}</Label>
							<Input variant="default" type="text" name="role" value={modifiedUser?.role || ""} onChange={handleUserChange} />
						</CustomGroup>
						<CustomGroup>
							<Label>{t("JS_141")}</Label>
							<CustomDropdown isOpen={dropdownOpen} toggle={toggle}>
								<DropdownToggle caret>{supportedLanguages.find((sl) => sl.value === languageValue)?.name}</DropdownToggle>
								<DropdownMenu>
									{supportedLanguages.map((sl, i) => (
										<DropdownItem key={i} onClick={() => setLanguageValue(sl?.value)}>
											<div>{sl?.name}</div>
										</DropdownItem>
									))}
								</DropdownMenu>
							</CustomDropdown>
						</CustomGroup>
					</FlexAlignCenter>
				</InputsSection>
				<GenericButton variant="primary" className="text-capitalized mt-3" onClick={onSave} disabled={isSaveDisabled}>
					{t("SAVE")}
				</GenericButton>
				{isSharedEnvironment && (
					<div className="w-50">
						<PasswordManager onClose={onClose} />
					</div>
				)}
			</ModalBody>
		</ModalContainer>
	);
});

UserProfileModal.displayName = "UserProfileModal";
export { UserProfileModal };

const ModalContainer = styled(Modal)`
	max-width: 800px;
`;

const DetailsContainer = styled.div`
	margin-bottom: 32px;
`;

const InputsSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const CustomGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const CustomDropdown = styled(Dropdown)`
	width: 100%;
	height: 100%;
	border: 1px solid #ced4da !important;
	border-radius: 4px;
	.dropdown-toggle::after {
		margin-left: 274px !important;
	}
`;
