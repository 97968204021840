import { libraryInit, brandValueInit,  iconsInit, measureInit, touchPointInit } from "@redux/index";
import { findWorkspaceById } from "@redux/memoizedSelectors";
import DataTable from "features/grid-table/DataTable";
import { currentWorkspaceId } from "index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";

const Libraries = () => {
	const { t } = useTranslation();
	const currentWorkspace = useSelector(findWorkspaceById(currentWorkspaceId));

	return (
		<DataLoader reduxActions={[touchPointInit,libraryInit,brandValueInit,iconsInit,measureInit]}>
			<PageTemplate classNames="container pt-5" pageTitle={`${currentWorkspace?.name} ${t("LIBRARIES")}`}>
				<DataTable context="libraries.libraryList" />
			</PageTemplate>
		</DataLoader>
	);
};

Libraries.displayName = "Libraries";
export default Libraries;
