import { useCallback } from "react"
import { EntityList } from "@utils/optionsSets/OptionSets"
import { EntityListItem } from "shared/ui-basics/ui-components/EntityListItem"
import { useTranslation } from "react-i18next";
import { Label } from "shared/ui-basics/index";

export const SearchResultComponent = ({ result, resetFunction, size = 14 }) => {
    const { t } = useTranslation();
    const RenderValidation = ({ entityType, list }) => {
        const currentEntity = parseInt(entityType)
        let label = ""
        let disableClick = "false"
        switch (currentEntity) {
            case EntityList.Solution:
                label = "SOLUTIONS";
                break;

            case EntityList.Opportunity:
                label = "OPPORTUNITIES";
                break;

            case EntityList.Action:
                label = "JS_80";
                break;

            case EntityList.CXProgram:
                label = "CX_PROGRAMS";
                break;

            case EntityList.Persona:
                label = "JS_159";
                break;
            case EntityList.CustomerJourney:
                label = "ASPX_97";
                break;
            case EntityList.Project:
                label = "PROJECTS";
                break;
            case EntityList.Insight:
                label = "ASPX_33";
                break;
            case EntityList.Global://folders
                label = "JS_67";
                break;
        }
        return <div className="py-2">
            <Label size={size} className="pb-3">{t(label)}</Label>
            {list?.map((cjm, index) => <EntityListItem key={index} entity={cjm} entityType={currentEntity} disableClick={disableClick} onClick={resetFunction} />)}
        </div>
    }
    return Object.keys(result)?.map((key, index) => <RenderValidation key={index} entityType={key} list={result[key]} />)
}