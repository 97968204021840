import { useTranslation } from "react-i18next";
import PickListData from "../../utils/optionsSets/PickListData";
import * as Style from "./style";
import { Dropdown, DropdownItem } from "reactstrap";
import { IconDispatcher } from "../ui-basics";
import React from "react";
import { useLocation } from "react-router-dom";
import { getEntityCurrentState } from "@utils/helpers";

export default function DisplayState({ object, setObject, style, disable = false, updateFunction, size = "m", attKey = "isFuture" }) {
	const { t } = useTranslation();

	const [toggleState, setToggleState] = React.useState(false);
	const state = PickListData("state");
	const choiceFuture = typeof object?.[attKey] === "number" ?  object?.[attKey] : getEntityCurrentState(object?.[attKey]);
	const selectedState = getSelected(state, choiceFuture);
	const showPlaceHolder = Object.keys(selectedState)?.length === 0;
	const toggle = () => setToggleState(!toggleState);
	const pathname = useLocation().pathname;
	const borderVisible = ["/home", "/journey-map", "/opportunity", "/cx-actions", "/solutions", "/personas", "/cx-program"].includes(pathname) ? "false" : "true"; //border not visible on table
	const handleClick = (value) => {
		const updatedObject = { ...object, [attKey]: value };
		setObject && setObject(updatedObject);
		if (updateFunction) updateFunction(updatedObject, [attKey]);
	};
	return (
		<>
			{state && (
				<Dropdown isOpen={toggleState} toggle={toggle} direction={"down"} disabled={disable}>
					<Style.ToggleCaret disabled={disable} size={size} bordervisible={borderVisible} className={`w-100 ${style?.dropdownToggle}`}>
						{showPlaceHolder ? (
							<Style.NotData>{t("SELECT_VALUE")}</Style.NotData>
						) : (
							<>
								<Style.StateTypes toggle="true" type={choiceFuture} activeoption={true}>
									{selectedState.name}
								</Style.StateTypes>
								<div className="caret">{IconDispatcher(toggleState ? "ANGLE-UP" : "ANGLE-DOWN")}</div>
							</>
						)}
					</Style.ToggleCaret>
					<Style.MenuDrop className="p-3">
						{state?.map((stateValue) => (
							<DropdownItem key={stateValue?.value} onClick={() => handleClick(stateValue.value)}>
								<Style.StateTypes className="w-100" type={stateValue?.value} activeoption={true}>
									{stateValue.name}
								</Style.StateTypes>
							</DropdownItem>
						))}
					</Style.MenuDrop>
				</Dropdown>
			)}
		</>
	);
}
// === true ? StateTypeOption.FutureState : StateTypeOption.CurrentState
const getSelected = (state, choiceFuture) => {
	if (state?.length > 0) {
		const found = state?.find((prio) => prio.value === choiceFuture);
		if (found) return found;
		return {};
	}
	return {};
};
