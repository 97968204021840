import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components";

export default function InvalidPage() {
	const { t } = useTranslation();
	const {pathname,search} = useLocation();
	const errorMessage = search.getErrorMessages()
	const label = pathname === "/invalid-url" ? "INVALID_URL" : "AUTHENTICATION_ERROR";

	return (
		<div className="full-screen d-flex flex-column">
			<WrapperImg>{IconDispatcher("LOG-OUT", "m-3 mt-0 mb-5", { style: "light" })}</WrapperImg>
			<CustomContainer>{errorMessage ? errorMessage : t(label) }</CustomContainer>
		</div>
	);
}
const WrapperImg = styled.div`
	svg {
		height: 150px;
	}
`;
export const CustomContainer = styled.div`
	width: 50% !important;
	text-align: center;
	font-size: 20px;
`;
