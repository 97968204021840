import { EMPTY_GUID } from "@utils/helpers";
import BrandValueLibraryCard from "features/library/components/BrandValues/BrandValueLibraryCard";
import { EditLibrariesModal } from "features/library/modals/EditLibrariesModal";
import { useSelector } from "react-redux";
import { CreateLibraryItemCanvas } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import BrandValueForm from "../components/BrandValues/BrandValueForm";
import { LibraryTemplate } from "../components/LibraryTemplate";
import { brandValueInit, deleteBrandValue } from "../reducer/LibraryActions";
import DataLoader from "shared/components/data-loader";
import { getAllCxProgram } from "@redux/index";

const BrandValues = ({ srcBrandValueId, callbackCreateBrandValue, onClose }) => {
	const categories = [
		{ name: "ALL", id: EMPTY_GUID },
		{ name: "Functional", id: "100000001" },
		{ name: "Emotional", id: "100000000" },
	];

	const brandValues = useSelector((state) => state.libraries.brandValues);

	const onDelete = (brandValueId) => {
		deleteBrandValue({ id: brandValueId });
	};

	const [showCreateModal, hideCreateModal] = useModal(CreateLibraryItemCanvas, {
		title: "NEW_BRAND_VALUE",
		displayForm: () => (
			<BrandValueForm type={categories} data={brandValues} mood="create" onClose={onClose} callbackCreateBrandValue={callbackCreateBrandValue} hideCreateModal={hideCreateModal} />
		),
	});

	const [showEditModal] = useModal(EditLibrariesModal, {
		onDelete: onDelete,
		entityName: "brandvalue",
	});

	const displayLibraryItem = ({ item }) => {
		return <BrandValueLibraryCard key={item.id} context="library" onDelete={onDelete} brandValueId={item.id} showModal={showEditModal} setFocus={item?.setFocus || srcBrandValueId === item.id} />;
	};

	return (
		<DataLoader reduxActions={[brandValueInit, getAllCxProgram]}>
			<LibraryTemplate items={brandValues} title="BRAND_VALUES" addBtnText="NEW_BRAND_VALUE" showCreateModal={showCreateModal} categories={categories} displayLibraryItem={displayLibraryItem} />
		</DataLoader>
	);
};
export default BrandValues;
