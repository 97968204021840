import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { t } from "i18next";
import { GenericButton } from "shared/ui-basics/index";
import { getterApis } from "@utils/optionsSets/getterApis";
import { UploadImageModal } from "shared/components/UploadImage";

export const EditLogoModal = ({ isOpen, onClose, updateImg, cjmIdStr, sessionId, deleteImage }) => {
	const isFromAccountSettings = sessionId && !cjmIdStr ? true : false;
	const isFromCJM = sessionId && cjmIdStr ? true : false;

	const getLogo = () => {
		if (isFromCJM) return getterApis["GET_MAP_CUSTOM_IMG"](cjmIdStr, sessionId);
		else if (isFromAccountSettings) return getterApis["GET_ACCOUNT_IMG"](sessionId);
		else return null;
	};

	const [companyLogo, setCompanyLogo] = useState(getLogo() + `&${Math.random()}=true`);

	const save = () => {
		updateImg(companyLogo);
		onClose();
	};
	const onDelete = () => {
		if (deleteImage) deleteImage(setCompanyLogo);
	};
	return (
		<>
			<Modal isOpen={isOpen} centered={true} toggle={onClose} animation="false" size='xs'>
				<ModalHeader toggle={onClose}>{t("ASPX_236")}</ModalHeader>
				<ModalBody className="d-flex justify-content-center">
					<UploadImageModal
						variantImg={"EditImage"}
						keyFor={"companyLogo"}
						id={"companyLogo"}
						deleteImage={true}
						onDelete={onDelete}
						disabled={true}
						customImage={companyLogo}
						setCustomImage={setCompanyLogo}
					/>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-center">
					{
						<GenericButton className="text-uppercase" variant="primary" disabled={companyLogo === "error"} onClick={save}>
							{t("ASPX_12")}
						</GenericButton>
					}
				</ModalFooter>
			</Modal>
		</>
	);
};
