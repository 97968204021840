import UserSubscriptionPlanSelector from "features/grid-table/components/UserSubscriptionPlanSelector";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import UserTypeDropdown from "shared/dropdowns/UserTypeDropdown";
import { FlexAlignCenter, FlexBetweenAlignCenter, Label, RadioButton } from "shared/ui-basics";
import styled from "styled-components";
import UserDetails from "../user-details";
import { CustomGroup, SectionTitle } from "./form-styles";

const FormSettings = ({ user, handleChange, isStandard, isSharedExpert, isMultiSub, modeType }) => {
	const { t } = useTranslation();

    const showPackageSelection = !isStandard && !isSharedExpert && isMultiSub;
    const isCreateMode = modeType === "create"
    const isUpdateMode = modeType === "update"

	const handleUserTypeChange = (user, userTypeCode) => handleChange({ attribute: "userType", value: Number(userTypeCode) });

	const handleCreationTypeChange = (value) => {
		handleChange({ attribute: "isMember", value });
	};

	const renderPlanAndUserTypeFields = () => {
		if (!isStandard)
			return (
				<FlexBetweenAlignCenter gap="24">
					{!isSharedExpert && (
						<CustomGroup className="m-0">
							<Label size="14">{t("PLAN_TYPE")}</Label>
							{UserSubscriptionPlanSelector({ user, handleChange, isDisabled: !showPackageSelection })}
						</CustomGroup>
					)}
					<CustomGroup className="m-0">
						<Label size="14">{t("CS_59")}</Label>
						{UserTypeDropdown({ user, handleUserTypeChange })}
					</CustomGroup>
				</FlexBetweenAlignCenter>
			);
    };
    
    if (isCreateMode && isStandard) return <></>

	return (
		<Settings>
			{isCreateMode && (
				<>
					<SectionTitle>{t("ASPX_207")}</SectionTitle>
					<ButtonsContainer>
						{ !isSharedExpert && (
							<FlexAlignCenter gap="50">
								<FlexAlignCenter gap="10">
									<RadioButton selected={!user?.isMember} showpointer onClick={() => handleCreationTypeChange(false)} />
									<div>{t("NEW_SSO_USER")}</div>
								</FlexAlignCenter>
								<FlexAlignCenter gap="10">
									<RadioButton selected={user?.isMember} showpointer onClick={() => handleCreationTypeChange(true)} />
									<div>{t("NEW_USER")}</div>
								</FlexAlignCenter>
							</FlexAlignCenter>
						)}
					</ButtonsContainer>
				</>
			)}
			{isUpdateMode && (
				<UserDetailsContainer>
					<UserDetails user={user} desactivateClick={true} size="l" />
				</UserDetailsContainer>
			)}
			<div>{renderPlanAndUserTypeFields()}</div>
		</Settings>
	);
};

FormSettings.displayName = "FormSettings";
export default FormSettings;

const Settings = styled.div`
	border-radius: 4px;
	border: 1px solid #efefef;
	padding: 24px 14px;
`;

const ButtonsContainer = styled.div`
	margin-bottom: 24px;
`;

const UserDetailsContainer = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #efefef;
	padding-bottom: 20px;
	margin-bottom: 24px;
`;
