import { EntityList } from "@utils/optionsSets/OptionSets";
import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { PickListMultipleTemplate, PickListSingleFilterTemplate, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import HistoricalData from "../../columns-components/HistoricalData";
import Occupation from "../../columns-components/Occupation";
import Owners from "../../columns-components/Owner";
import PersonaName from "../../columns-components/PersonaName";
import State from "../../columns-components/State";
import Tags from "../../columns-components/Tags";
import Template from "../../columns-components/Template";
import PickListData from "@utils/optionsSets/PickListData";
import { selectQueryView, selectSelectedViewId, selectTag } from "@redux/memoizedSelectors";

export const GetPersonaColumns = (props) => {
    const context = props.context.split(".")[1];
    const { dataKey } = props?.contextData;

    const tags = useSelector(selectTag);

    const statesTypes = PickListData("isFutureLabel");

    const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.Persona, context));
    const queryView = useSelector(state => selectQueryView(state, EntityList.Persona))?.getById(selectedViewId)?.columnSetting;
	const isInPersona = context === "editPersona";
    const isInCxProgram = context === "cxProgram";

    return {
        personaName: {
            field: "name",
            header: <div id="name">{t("JS_82")}</div>,
            body: (options) => PersonaName(options, props.rename, props.setRename, props.contextData.clickToOpen),
            style: checkStyle(queryView, "personaName","xxl", "250px"),
            sortable: true,
        },
        occupation: {
            field: "occupation",
            header: t("OCCUPATION"),
            sortable: true,
            showFilterOperator: false,
            className: "py-0",
            style: checkStyle(queryView, "occupation", isInPersona || isInCxProgram ? "xxl":"xxxl"),
            body: Occupation,
        },
        associatedContactsCount: {
            field: "associatedContactsCount",
            filterField: "associatedContactsCount",
            header: t("CS_41"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            dataType: "numeric",
            style: checkStyle(queryView, "associatedContactsCount", "s"),
            className: "py-0 text-center",
        },
        cjmCount: {
            field: "cjmCount",
            filterField: "cjmCount",
            header: t("ASPX_97"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            dataType: "numeric",
            style: checkStyle(queryView, "cjmCount", "l", "150px"),
            className: "py-0 text-center",
        },
        tagIds: {
            header: t("TAGS"),
            body: (action) => Tags(action, EntityList.Persona, context),
            field: "tagIds",
            filterField: "tagIds",
            filterElement: (options) => PickListSingleFilterTemplate(options, tags, t("SELECT_TAG")),
            filter: true,
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            style: checkStyle(queryView, "tagIds", "l", "140px"),
        },
        templateStatus: {
            field: "templateStatus",
            header: t("JS_170"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            dataType: "boolean",
            filterField: "templateStatus",
            className: "py-0 ",
            body: (options) => Template({ options, templateStatus: true, context: props.context }),
            style: checkStyle(queryView, "templateStatus", "s", "160px"),
        },
        lastModifiedOn: {
            field: "lastModifiedOn",
            header: t("JS_72"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            filterField: "lastModifiedOn",
            dataType: "date",
            style: checkStyle(queryView, "lastModifiedOn", "l", "160px"),
            filterElement: dateFilterTemplate,
            body: (options) => dateBodyTemplate(options?.lastModifiedOn),
        },
        isLocked: {
            field: "isLocked",
            header: t("JS_169"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            dataType: "boolean",
            filterField: "isLocked",
            className: "py-0 ",
            body: (options) => Template({ options, templateStatus: false, context: props.context }),
            style: checkStyle(queryView, "isLocked", "s", "160px"),
        },
        owner: {
            field: "authorizedUsers",
            header: t("CS_35"),
            body: Owners,
            style: checkStyle(queryView, "authorizedUsers", "xs", "96px"),
        },
        isFutureLabel: {
            field: "isFutureLabel",
            header: t("JS_77"),
            sortable: true,
            showFilterMatchModes: false,
            showFilterOperator: false,
            filter: true,
            filterField: "isFutureValue",
            style: checkStyle(queryView, "isFutureLabel", "m", "120px"),
            filterElement: (options) => PickListMultipleTemplate(options, statesTypes),
            body: (entity) => State({ entity, dataKey, path: props?.contextData?.reducerPath, onChangeState: props?.contextData?.onChangeState }),
        },
        historicalData: {
            header: t("DATA_ON_CURRENT_MAP"),
            body: HistoricalData,
            style: checkStyle(undefined, "", "xxl"),
        },
    };
};