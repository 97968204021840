import { createCxAction, updateFolderLinks, updateMultiCxActions } from "@redux/index";
import { FilterItemsByStatus, createDefaultAction, tableDefaultColumn } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { BulkActions } from "features/cx-actions/actions/BulkActions";
import { getExcelExportListServicecxAction } from "features/cx-actions/reducer/CxActionActions";
import { t } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { useModal } from "use-modal-hook";
import BaseActions from "../../../cx-actions/actions/BaseActions";
import { ActionLink } from "../../columns-components/All_actions/ActionLink";
import { getAllMapsList } from "@redux/memoizedSelectors";

export const GetActionContext = (props) => {
	const { linked, showAllActiveRecord, disableBulkActions, cjmId } = props;
	const { pathname, search } = useLocation();
	const history = useHistory();
	const cxProgramId = search.getCxProgramID();
	const folderId = search.getFolderId();

	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const all_maps = useSelector(getAllMapsList);
	const all_actions = useSelector((state) => state.actions.all_actions || [], shallowEqual);

	const actions = useMemo(() => {
		return all_actions?.filter((opp) => FilterItemsByStatus(opp, pathname, "/cx-actions", showSampleData, all_maps))
	?.filter((action) => (cjmId !== undefined ? action.mapId === cjmId : true))}, [all_actions, showSampleData, all_maps]);

	const specificContext = props.context.split(".")[1];

	const linkedObjects = linked ? actions.filter((map) => linked?.includes(map.id)) : undefined;
	const ActionsData = useMemo(() => formatActionsData(actions, all_maps), [actions, all_maps]);
	const loading = useSelector((state) => state.actions.loading);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.Action]?.[specificContext]?.columns || tableDefaultColumn(EntityList.Action);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Action]?.[specificContext]?.filters;
	const userInfo = useSelector((state) => state.auth.userInfo);


	const handleRedirect = ({ setIsCreateBtnDisabled }) => {
		setIsCreateBtnDisabled && setIsCreateBtnDisabled(true);
		const onSuccess = (data) => {
			setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
			if (folderId) {
				updateFolderLinks({ folderIds: [folderId], idsToLink: [data.id], entityName: "cem_cxactiontask" });
			} else {
				history.push(`/edit-cxactions?id=${data.id}`);
			}
		};

		const onFailed = () => setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
		const cxActions = createDefaultAction(userInfo);
		createCxAction({ item: cxActions, onSuccess, onFailed, cxProgramId, displayToast: "CREATE_NO_SUCCESS_TOAST" });
	};
	const [showListLinkedModal] = useModal(ListLinkedModal);

	const data = useMemo(() => {
		const newData = {
			home: {
				showCheckbox: false,
				columns: ["select", "subject", "statusId", "priorityId", "dueDateNew","actions"],
				emptyListText: "ACTION_EMPTY_LIST",
				bulkActions: false,
				linked: linked,
				clickToOpen: true,
				showOnlyLinked: true,
				disableFixHeader: false,
				noColumnHeader: false,
				showPagination: false,
				listEditable: true,
			},
			cxActionsList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "ACTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				listEditable: true,
				showPagination: true,
				visibleHeader: true,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
			},
			cxActionsListGroup: {
				showCheckbox: true,
				columns: ["subject", "statusId", "priorityId", "dueDateNew", "groupIds", "actions"],
				emptyListText: "ACTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				noColumnHeader: false,
                showPagination: false,
                listEditable: true,
				showOnlyLinked: linked?.length > 0,
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "subject", "statusId", "priorityId", "dueDateNew", "groupIds", "actions"],
				emptyListText: "ACTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "subject", "statusId", "priorityId", "dueDateNew", "groupIds", "actions"],
				emptyListText: "ACTION_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			cxProgram: {
				showCheckbox: false, // modals of cxProgram that link/unlink
				columns: ["subject", "mapId", "stageName", "statusId", "priorityId", "ownerId", "actions"],
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				showPagination: linked?.length > 10,
				nonDropDown: true,
				emptyListText: "ACTIONS_PLACEHOLDER_PROGRAM",
			},
		};
		const contextData = _.get(newData, specificContext);

		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;

		contextData.Actions = (action) => CreateActionsDropdown({ actions: BaseActions({ action }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = formatActionsData(linkedObjects, all_maps);
		contextData.listData = ActionsData;
		contextData.dataKey = "id";
		contextData.reducerPath = "actions.all_actions";
		contextData.route = "edit-cxactions";
		contextData.entityType = EntityList.Action;
		contextData.exportExcel = getExcelExportListServicecxAction;
		contextData.loading = loading;
		contextData.onChangeObject = contextData?.listEditable ? (modifiedCxActions, modifiedAttribNames, onSuccess) => updateMultiCxActions({ modifiedCxActions, modifiedAttribNames, onSuccess }) : null;
		contextData.buttonCreate = handleRedirect;
		contextData.buttonCreateLabel = "NEW_ACTION";
		contextData.filter = filters;

		if (specificContext === "cxProgram") {
			contextData.Actions = props?.disableAction ? () => { } : (options) => ActionLink(options, "cem_cxactiontask", cxProgramId);
		}
		// folder case
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.Action, saveButtonLabel: 'ASPX_44' });
			contextData.LinkActionLabel = "LINK_UNLINK_ACTIONS";
			contextData.displayExport = false;
		}
		return contextData;
	}, [isReadOnly, linked, linkedObjects,columnsArray, ActionsData, loading, disableBulkActions, showAllActiveRecord, cxProgramId, filters]);
	return data;
};

const formatActionsData = (actions, allMap) => {
	return actions?.map((action) => {
		const dueDateNew = new Date(action.dueDate);
		const newDate = new Date(action?.lastUpdated);

		const StageName = allMap.find((map) => map.cjmId === action?.mapId)?.stages?.filter((stage) => stage.id === action?.stageId)[0]?.name;
		const map = allMap.find((map) => map.cjmId === action?.mapId);
		return {
			...action,
			dueDateNew: dueDateNew,
			lastUpdated: newDate,

			stageName: StageName === "" || !StageName ? t("NEW_STAGE") : StageName,
			mapName: map?.name,
			authorizedUsers: map?.authorizedUsers,
			groupIds: action?.groups?.join("|"),
			tagIds: action?.tags?.join("|"),
		};
	});
};
