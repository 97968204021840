import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";
import { compareLists } from "@utils/helpers";
import { SubscriptionPlan } from "@utils/optionsSets/OptionSets";
import WorkspaceSelectorCard from "features/workspace/components/cards/workspace-selector-card";
import { useImperativeHandle, useState } from "react";
import { TypeaheadInputMulti } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomToken } from "shared/components/typeahead/users-selector-typeahead";
import { TypeaheadWrapper } from "shared/modals/manage-access/ManageAccessStyle";
import { FlexColumn, Label } from "shared/ui-basics";
import styled from "styled-components";
import { CustomGroup, SectionTitle } from "./form-styles";
import { useEffect } from "react";

const FormMemberType = ({ authorizedUserId, modeType, subscriptionPlan, innerRef, isStandard, disabled }) => {
	const { t } = useTranslation();
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces).filter((ws) => (disabled ? ws?.isDefault : true));
	const allTeamsObj = useSelector((state) => state.settings?.teams);
	const isMultiSubscription = useSelector((state) => state.settings?.subscriptionDetails?.subscriptionContracts)?.length > 1;

	const defaultWs = allActivesWorkspaces.find((ws) => ws?.isDefault);
	const allTeams = allTeamsObj ? Object.values(allTeamsObj) : [];
	const userTeams = allTeams?.filter((t) => t?.memberUserIds?.includes(authorizedUserId));

	const [modifiedWorkspaces, setModifiedWorkspaces] = useState(modeType === "create" ? [{ id: defaultWs?.id, roleName: "viewer" }] : []);
	const [currentWorkspaces, setCurrentWorkspaces] = useState(
		allActivesWorkspaces?.map((ws) => {
			return { id: ws.id, roleName: getRoleName(ws) };
		})
	);
	const [currentTeams, setCurrentTeams] = useState(modeType === "create" ? [] : userTeams);

	useEffect(() => {
		setModifiedWorkspaces(modeType === "create" ? [{ id: defaultWs?.id, roleName: "viewer" }] : []);
	}, [subscriptionPlan]);

	const handleWorkspaceChange = ({ workspaceId, roleName }) => {
		const selectedWorkspace = modifiedWorkspaces?.find((ws) => ws?.id === workspaceId);
		setCurrentWorkspaces(
			currentWorkspaces?.map((ws) => {
				if (ws?.id === workspaceId) ws.roleName = roleName;
				return ws;
			})
		);
		if (selectedWorkspace) {
			setModifiedWorkspaces(
				modifiedWorkspaces?.map((ws) => {
					if (ws?.id === workspaceId) ws.roleName = roleName;
					return ws;
				})
			);
		} else setModifiedWorkspaces([...modifiedWorkspaces, { id: workspaceId, roleName }]);
	};

	function getRoleName(workspace) {
		const isSelected = modifiedWorkspaces?.find((selectedWs) => selectedWs?.id === workspace?.id);
		const authorizedUser = workspace?.authorizedUsers.find((user) => user?.id === authorizedUserId);

		if (isSelected) return isSelected?.roleName;
		if (authorizedUser) return authorizedUser?.roleName;

		return "none";
	}

	const checkIfNoneDisabled = () => {
		const selectedWs = currentWorkspaces?.filter((ws) => ws.roleName !== "none");
		if (selectedWs?.length <= 1) return true;
		return false;
	};

	const isWorkspaceCardDisabled = (workspace) => {
		if (disabled) return true;
		if (isMultiSubscription && subscriptionPlan !== SubscriptionPlan.Enterprise && !workspace?.isDefault) return true;
		return false;
	};

	useImperativeHandle(innerRef, () => ({
		getModifiedWorkspaces: () => {
			return modifiedWorkspaces;
		},
		getModifiedTeams: () => {
			const { added, removed } = compareLists({ originalIds: userTeams.map((ut) => ut.id), modifiedIds: currentTeams?.map((ct) => ct.id) });
			return { teamIdsToAdd: added, teamIdsToRemove: removed };
		},
	}));

	const renderSelectedTeams = (inputProps, props) => {
		return (
			<TypeaheadInputMulti {...inputProps} selected={props.selected}>
				{props.selected?.map((option, idx) => (
					<CustomToken index={idx} key={option?.id} onRemove={props.onRemove} option={option}>
						{option?.name}
					</CustomToken>
				))}
			</TypeaheadInputMulti>
		);
	};

	return (
		<Container>
			<SectionTitle>{t("MEMBER_TYPE")}</SectionTitle>
			<FlexColumn gap="16">
				<CustomGroup>
					<Label size="14">{t("WORKSPACES")}</Label>
					<Workspaces>
						{allActivesWorkspaces?.map((workspace) => (
							<WorkspaceSelectorCard
								key={workspace?.id}
								workspace={workspace}
								roleName={getRoleName(workspace)}
								isNoneDisabled={checkIfNoneDisabled()}
								handleChange={handleWorkspaceChange}
								isDisabled={isWorkspaceCardDisabled(workspace)}
							/>
						))}
					</Workspaces>
				</CustomGroup>
				<CustomGroup>
					<Label size="14">{t("TEAMS")}</Label>
					<TypeaheadWrapper
						id="public-methods-example"
						labelKey="name"
						placeholder={t("SELECT_TEAMS")}
						multiple
						onChange={setCurrentTeams}
						options={allTeams}
						selected={currentTeams}
						renderInput={renderSelectedTeams}
						showborder={true}
						width="100%"
						disabled={isStandard}
					/>
				</CustomGroup>
			</FlexColumn>
		</Container>
	);
};

FormMemberType.displayName = "FormMemberType";
export default FormMemberType;

const Container = styled.div`
	border-radius: 4px;
	border: 1px solid #efefef;
	background: #ffffff;
	padding: 24px 14px;
`;

const Workspaces = styled.div``;
