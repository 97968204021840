import React from "react";
import styled, { css } from "styled-components/macro";
import { IconDispatcher } from 'shared/ui-basics/index';
import { useTranslation } from "react-i18next";

function SolutionLabel({ variant, icon, text }) {
	const { t } = useTranslation();

	return (
		<Card className="px-1" variant={variant}>
			<>{IconDispatcher(icon)}</>
			<CardText>{t(text)}</CardText>
		</Card>
	);
}

SolutionLabel.displayName = "SolutionLabel";
export default SolutionLabel;

const Card = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	gap: 5px;
	height: 30px;
	border-radius: 6px;
	background: #f5f5f5;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #c4c4c4;
	transform: rotate(-90deg);

	${(props) =>
		props.variant === "quickWin" &&
		css`
			background: #dcffff;
			color: #18aead;
			left: -20px;
			top: 80px;
		`}
	${(props) =>
		props.variant === "niceToHave" &&
		css`
			background: #d2eefb;
			color: #20a9e9;
            left: -30px;
			bottom: 100px;
		`}
    ${(props) =>
		props.variant === "complexProj" &&
		css`
			background: #c5c2d4;
			color: #333044;
            right: -65px;
			top: 80px;
		`}
    ${(props) =>
		props.variant === "toAvoid" &&
		css`
			background: #fde7cb;
			color: #f48e09;
            right: -40px;
			bottom: 100px;
		`}
`;

const CardText = styled.div`
	display: flex;
	white-space: nowrap;
`;
