import GenericPopover from "shared/popovers/GenericPopover";
import styled from "styled-components";
import { GenericButton } from "shared/ui-basics/buttons";


import EditableItem from "../../../shared/ui-basics/ui-components/EditableItem";


export const Groups = (object) => {
    let groupIds = object?.groups;
    const custom = <div>
        {groupIds?.slice(2, Infinity).map((group) => (
            <div key={group} className="mb-1">
                <EditableItem objectId={group} itemSelectorPath="libraries.group" mode={'display'} />
            </div>
        ))}
    </div>
    return (<WrapperDiv className="d-flex position-relative">
        {groupIds?.slice(0, 2).map((group) => (
            <div key={group} className="mb-1 ms-1">
                <EditableItem objectId={group} itemSelectorPath="libraries.group" mode={'display'} />
            </div>
        ))}
        {groupIds?.length > 2 && <div className='ms-1'>
            <GenericPopover custom={custom} >
                <GenericButton variant="light" iconClassName="ms-2" size={"xs"} className="p-1"  >
                    +{groupIds?.length - 2}
                </GenericButton>
            </GenericPopover>
        </div>}

    </WrapperDiv>
    );
};
const WrapperDiv = styled.div`
	display: -webkit-box;
	overflow: hidden;`

const Counter = styled.div`
height: 24px;
padding: 4px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 4px;
border: 1px solid #605F60;
display: flex;

background: #FFF;

`;
export default Groups