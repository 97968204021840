import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";

const BasicDrillDownModal = memo(({ isOpen, onClose, title, relatedItemsTitle, renderIllustration, items, ListItemComponent, itemAttributeId = "id" }) => {
	const { t } = useTranslation();
	return (
		<ModalContainer fade={false} isOpen={isOpen} toggle={onClose} centered={true} size="xl">
			<ModalHeader toggle={onClose}>{title}</ModalHeader>
			<Body>
				{renderIllustration && <ChartContainer className="p-4">{renderIllustration()}</ChartContainer>}
				<ListContainer className="p-4">
					{relatedItemsTitle && <ListTitle className="mb-3">{`${t("RELATIONSHIP")} ${relatedItemsTitle}`}</ListTitle>}
					{items?.map((item, i) => (
						<ListItemComponent key={item?.[itemAttributeId]} item={item} isLastItem={items?.length === i + 1} onClose={onClose} />
					))}
				</ListContainer>
			</Body>
		</ModalContainer>
	);
});

BasicDrillDownModal.displayName = "BasicDrillDownModal";
export { BasicDrillDownModal };

const ModalContainer = styled(Modal)``;

const Body = styled(ModalBody)`
	display: flex;
	align-items: center;
	height: 600px;
`;

const ChartContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70%;
	height: 600px;
`;

const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-left: 1px solid #c4c4c4;
	width: 100%;
	height: 600px;
	overflow: scroll;
`;

const ListTitle = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
