import { createUser, getAllRegisteredUsers, getAllTeams, updateUser, updateUsersTeams } from "@redux/index";
import { MAX_SIZE_INPUT, createDefaultUser, emailValidation, getAuthorizedUserFromRegisteredUser, getModeType, isFullUsersLicenseAvailable } from "@utils/helpers";
import { SubscriptionPlan, UserType } from "@utils/optionsSets/OptionSets";
import { getAllWorkspaces, shareWorkspace } from "features/workspace/reducer/WorkspaceActions";
import { useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataLoader from "shared/components/data-loader";
import { ContainerPage } from "shared/cx-components/style";
import { EditorProvider } from "shared/dynamic-imports";
import { GenericButton } from "shared/ui-basics";
import { CustomBreadCrumb } from "shared/ui-basics/ui-components/CustomBreadCrumb";
import styled from "styled-components";
import FormMemberType from "../components/form/form-member-type";
import FormPersonalDetails from "../components/form/form-personal-details";
import FormSettings from "../components/form/form-settings";

const EditUser = (props) => {
	return (
		<DataLoader reduxActions={[getAllRegisteredUsers, getAllWorkspaces, getAllTeams]}>
			<EditUserForm {...props} />
		</DataLoader>
	);
};

const EditUserForm = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();
	const memberTypeRef = useRef(null);

	const { mode, selectedItemId } = search.getWorkspaceProps(props.query);
	const modeType = getModeType(mode);

	const settings = useSelector(({ settings }) => settings);
	const contracts = useSelector((state) => state.settings?.subscriptionDetails?.subscriptionContracts);
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const authorizedUsers = useSelector((state) => state.settings?.authorizedUsers);

	const isMultiSub = contracts?.length > 1;
	const subscriptionPlanCode = Number(userInfo?.UserSubscriptionPlan);
	const isStandard = subscriptionPlanCode === SubscriptionPlan.Standard;
	const isSharedExpert = subscriptionPlanCode === SubscriptionPlan.SharedExpert;
	const isConfigAllowed = ["Enterprise"].includes(userInfo?.UserSubscriptionPlanName);
	const isCreateMode = modeType === "create";
	const isUpdateMode = modeType === "update";

	const getUserType = () => {
		if (isStandard) {
			if (isMultiSub) return UserType.user;
			return UserType.companyAdmin;
		}
		return -1;
	};
	const getUser = (users) => {
		if (modeType === "create") return createDefaultUser({ isMultiSub, subPlan: userInfo?.UserSubscriptionPlan, userType: getUserType() });
		return users?.find((u) => u?.id === selectedItemId);
	};

	const [user, setUser] = useState(getUser(settings.users));
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
	const [modifiedAttributes, setModifiedAttributes] = useState([]);

	const authorizedUserId = getAuthorizedUserFromRegisteredUser({ authorizedUsers, registeredUser: user })?.id;
	const isSSO = user?.isMember === false;
	const hideLoginName = !isSSO || isStandard || isSharedExpert;

	const handleUserChange = ({ attribute, value }) => {
		if (!modifiedAttributes?.includes(attribute)) setModifiedAttributes([...modifiedAttributes, attribute]);
		if (attribute === "subscriptionPlan") {
			user.userType = -1;
		}
		setUser({ ...user, [attribute]: value });
	};

	const redirectToList = () => {
		setIsActionButtonDisabled(false);
		history.push(`/users`);
	};

	const isUserAlreadyRegistered = (attribute) => {
		let res = false;
		settings.users.forEach((userToCompare) => {
			if (userToCompare[attribute] === user[attribute]) {
				res = true;
			}
		});
		return res;
	};

	const isSeatLeftForStandard = () => {
		if (!isFullUsersLicenseAvailable(settings.users, contracts, user.subscriptionPlan).answer) {
			return false;
		}

		return true;
	};

	const handleAction = () => {
		const workspaces = memberTypeRef?.current?.getModifiedWorkspaces();
		const { teamIdsToAdd, teamIdsToRemove } = memberTypeRef?.current?.getModifiedTeams();
		const workspaceIds = workspaces?.map((ws) => ws.id);
		const roleNames = workspaces?.map((ws) => ws.roleName);

		if (user.firstName === "") return toast.info(t("JS_110"));
		if (user.lastName === "") return toast.info(t("JS_111"));
		if (user.email === "") return toast.info(t("COM_EMAIL_MISSING_WARNING"));
		if (!hideLoginName && user.loginName === "") return toast.info(t("EMAIL_MISSING_WARNING"));
		if (!hideLoginName && !emailValidation(user.loginName)) return toast.info(t("WRONG_EMAIL_FORMAT"));
		if (!emailValidation(user.email)) return toast.info(t("WRONG_EMAIL_FORMAT"));
		if (user.userType === -1) return toast.info(t("USER_TYPE_MISSING"));
		if (user.subscriptionPlan === -1) return toast.info(t("SELECT_PLAN_TYPE"));
		if (user.loginName.length > MAX_SIZE_INPUT || user.firstName.length > MAX_SIZE_INPUT || user.lastName.length > MAX_SIZE_INPUT) return toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
		if (isStandard && !isSeatLeftForStandard()) return toast.info(t("MAXIMUM_USERS_WARNING"));

		setIsActionButtonDisabled(true);

		if (isCreateMode) {
			if (!(isSharedExpert || isStandard) && isUserAlreadyRegistered("loginName")) return toast.info(t("USER_DUPLICATE_WARNING"));
			if ((isSharedExpert || isStandard) && isUserAlreadyRegistered("email")) return toast.info(t("USER_DUPLICATE_WARNING"));
			createUser({ user, workspaceIds, roleNames, teamIds: teamIdsToAdd });
			toast.success(t("NOTIFICATION_BY_EMAIL"));
			redirectToList();
		}

		if (isUpdateMode) {
			const isShare = workspaceIds?.length > 0;
			const isUpdateTeams = teamIdsToAdd.length > 0 || teamIdsToRemove.length > 0;
			const onSuccess = () => {
				if (isUpdateTeams) updateUsersTeams({ userIds: [authorizedUserId], idsToAdd: teamIdsToAdd, idsToRemove: teamIdsToRemove });
				if (isShare) shareWorkspace({ userId: authorizedUserId, workspaceIds, roleNames, isUnshare: true, displayToast: "UPDATE", onSuccess: () => redirectToList() });
				else redirectToList();
			};
			updateUser({ modifiedUser: user, modifiedAttribNames: modifiedAttributes, displayToast: isShare ? "" : "UPDATE", onSuccess });
		}
	};

	const displayButtonText = () => {
		if (isCreateMode) return t("INVITE");
		if (isUpdateMode) return t("ASPX_165");
	};

	return (
		<EditorProvider>
			<Scroll>
				<ContainerPage>
					<div>
						<CustomBreadCrumb paths={[t("ASPX_207"), t("ASPX_210"), isCreateMode ? t("NEW_USER") : user?.fullName]} backFunction={redirectToList} />
					</div>
					<Container>
						<FormContainer className="mt-5">
							<FormSettings user={user} handleChange={handleUserChange} isStandard={isStandard} isSharedExpert={isSharedExpert} isMultiSub={isMultiSub} modeType={modeType} />
							<FormPersonalDetails
								user={user}
								modifiedAttributes={modifiedAttributes}
								setModifiedAttributes={setModifiedAttributes}
								setUser={setUser}
								isStandard={isStandard}
								isSharedExpert={isSharedExpert}
								isSSO={isSSO}
								modeType={modeType}
							/>
							<FormMemberType
								authorizedUserId={authorizedUserId}
								modeType={modeType}
								subscriptionPlan={user?.subscriptionPlan}
								innerRef={memberTypeRef}
								isStandard={isStandard}
								disabled={!isConfigAllowed}
							/>
							<ButtonContainer>
								<GenericButton variant="primary" onClick={handleAction} disabled={isActionButtonDisabled}>
									{displayButtonText()}
								</GenericButton>
							</ButtonContainer>
						</FormContainer>
					</Container>
				</ContainerPage>
			</Scroll>
		</EditorProvider>
	);
};

EditUser.displayName = "EditUser";
export default EditUser;

const Scroll = styled.div`
	width: 100%;
	height: 100%;
	overflow: scroll;
`;

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`;

const FormContainer = styled.div`
	width: 520px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ButtonContainer = styled.div`
	align-self: center;
	margin-top: 24px;
	margin-bottom: 24px;
	width: 100%;
`;
