import { CheckBoxWrapper } from "shared/modals/library-links/Style";
import { IconDispatcher } from "shared/Icons";
import { getSessionId } from "@redux/memoizedSelectors";
import { touchPointIconValidation } from "@utils/helpers";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { currentWorkspaceId } from "index";

export const TouchpointSelectCard = (props) => {
	const { object, handleRemove, showBorders, disabled, isEdit } = props;
	const sessionId = useSelector(getSessionId);
	const picture = pictureValidation(object, sessionId);
	const isRemovable = handleRemove !== undefined;
	const selectableComponent = () => {
		const { selected } = props;
		return <CheckBoxWrapper type="checkbox" className="my-1 me-0" checked={selected.includes(object.id)} onChange={() => {}} />;
	};
	const handleDelete = (e) => {
		e.stopPropagation();
		handleRemove(object);
	};
	return (
		<Card className="mb-1" showborders={`${showBorders}`} isremovable={`${isRemovable}`} disabled={disabled}>
			{props.selectable && <div className="p-0 d-flex align-items-center justify-content-center">{selectableComponent()} </div>}
			<div className="d-flex  align-items-center justify-content-center">
				<ImageIcon src={picture} loading="lazy" alt="" />
			</div>

			<Name className="ellipsis" showborders={showBorders}>
				{object?.name}
			</Name>

			<div>{isRemovable && isEdit && <RemoveButton onClick={handleDelete}>{IconDispatcher("X", "", { size: "xs" })}</RemoveButton>}</div>
		</Card>
	);
};

const pictureValidation = (icon, sessionId) => {
	if (icon.isCustom) return `api/touchpoint/getCustomImage?channelIdStr=${icon.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}`;
	return touchPointIconValidation(icon.name);
};

const RemoveButton = styled.div`
	justify-self: end;
	cursor: pointer;
`;

const Card = styled.div`
	height: 32px;
	display: flex;
	margin: 0px;
	border-radius: 0.25rem;
	gap: 8px;
	padding: 4px;
	${(props) =>
		!props.isremovable === "true" &&
		css`
			:hover .row {
				background-color: #efefef;
			}
		`}

	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}

	${(props) =>
		props.showborders === "true" &&
		css`
			border: 1px solid #c4c4c4;
		`}
`;

const ImageIcon = styled.img`
	width: 1.12rem;
	height: 1.12rem;
	align-self: center !important;
`;

const Name = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
    align-self: center;

`;
