import { selectLibraryData } from "@redux/memoizedSelectors";
import NoDataComponent from "features/auth/view/no-data-component";
import { LibraryItemCard } from "features/library/components/LibraryItemCard";
import { copyValueLibrary, deleteValueLibrary, libraryInit, upsertValueLibrary } from "features/library/reducer/LibraryActions";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DataLoader from "shared/components/data-loader";
import { ActionConfirmModal, CreateLibraryItemCanvas, EditLibrariesModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { LibraryTemplate } from "../components/LibraryTemplate";
import LibraryValueForm from "../components/LibraryValue/LibraryValueForm";

const CustomLibrary = () => {
	const { search } = useLocation();
	const id = search.getId();
	const libraries = useSelector((state) => state.libraries.customLibraries);
	const libraryIndex = getLibraryIndex(id, libraries);
	const { filteredLibraries } = useSelector((state) => selectLibraryData(state, libraryIndex, ""));
	const filteredItems = filteredLibraries?.sortAsc("name");
	const library = libraries?.[libraryIndex];
	const libraryId = library?.id;
	const type = getLibraryType(library);
	const data = getData(type);
	const [showEditModal, hideEditModal] = useModal(EditLibrariesModal);
	const [showDeleteModal] = useModal(ActionConfirmModal);

	const [showCreateModal] = useModal(CreateLibraryItemCanvas, {
		title: t("New ") + t(data?.title),
		displayForm: (onClose) => <LibraryValueForm items={library?.values || []} onCreate={onCreate} type={type} libraryId={libraryId} onClose={onClose} />,
	});

	const onCreate = (item, callBack) => upsertValueLibrary({ libraryValue: item, onSuccess: callBack, libraryId: library });

	const displayLibraryItem = ({ item }) => {
		const handleDelete = ({ onSuccess }) => {
			deleteValueLibrary({ libraryId, libraryValueId: item?.id, onSuccess });
			hideEditModal();
		};

		const onDelete = () => {
			showDeleteModal({
				title: "ASPX_145",
				bodyContent: data?.deleteText,
				Action: handleDelete,
				actionBtnText: "ASPX_13",
			});
		};
		const onCopy = ({id,name,workspaceIds}) => {
			copyValueLibrary({
				libraryId: libraryId,
				libraryValueId:id,
				name:name,
				workspaceIds: workspaceIds
			});
		};
		const openEdit = () => {
			showEditModal({
				onDelete,
				object: item,
				context: "libraryValue",
				entityName: "libraryvalue",
				title: data?.title,
				displayCjmTable: true,
				type,
				indexLibrary: libraryIndex,
				libraryId,
			});
		};

		return (
			<LibraryItemCard
				key={item.id}
				item={item}
				itemType={type}
				handleEdit={openEdit}
				deleteFunction={handleDelete}
				deleteText={data?.deleteText}
				setFocus={item.setFocus}
				actionType="DELETE_VALUE_LIBRARY"
				copyFunction={onCopy}
				label={library?.name}

			/>
		);
	};

	return (
		<DataLoader reduxActions={[libraryInit]}>
			{libraryIndex === undefined || libraryIndex === -1 ? (
				<NoDataComponent label={"NO_DATA_AVAILABLE"} icon="CIRCLE-EXCLAMATION" fixed={false} />
			) : (
				<LibraryTemplate items={filteredItems} title={library?.name} addBtnText={t("New ") + t(data?.title)} showCreateModal={showCreateModal} displayLibraryItem={displayLibraryItem} />
			)}
		</DataLoader>
	);
};

const getData = (type) => {
	switch (type) {
		case "system":
			return { title: "SYSTEM", deleteText: "DELETE_SYSTEM" };
		case "department":
			return { title: "DEPARTMENT", deleteText: "DELETE_DEPARTMENT" };
		case "library":
			return { title: "LIBRARY_VALUE", deleteText: "DELETE_LIBRARY_VALUE" };
		default:
			return "";
	}
};

const getLibraryIndex = (id, libraries) => {
	if (["systems", "departments", "kpis"].includes(id)) return libraries?.findIndex((library) => library?.name?.toLowerCase() === id);
	return libraries?.findIndex((library) => library?.id === id && library?.isCustom);
};

const getLibraryType = (library) => {
	if (library?.name === "Systems" && !library.isCustom) return "system";
	if (library?.name === "Departments" && !library.isCustom) return "department";
	return "library";
};

export default CustomLibrary;
