import { GenericButton, Input } from "shared/ui-basics/index";
import { useAutoFocus } from "shared/hooks/index";
import React,{ memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};
 const NewModal = memo(({ isOpen, onClose, title, inputTitle, placeholder, value, closeBtnLabel='ASPX_135', action, haveCancel = false }) => {
	const { t } = useTranslation();
	const ref = useAutoFocus();

	const [name, setName] = useState(value || "");

	const create = () => {
		action(name);
		onClose();
	};
	const handleChange = (e) => {
		e.preventDefault();
		if (e.charCode === 13) {
			return;
		}
		setName(e.target.value.truncate(100));
	};
	return (
		<>
			<Modal fade={false} isOpen={isOpen} centered={true} style={customStyles} backdrop="static" toggle={onClose}>
				<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label for="name" className="m-2">
							{t(inputTitle)}
						</Label>
						<Input variant="default" type="text" name="name" onChange={handleChange}  placeholder={t(placeholder)} value={name} innerRef={ref} autoComplete="off"/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<GenericButton variant="primary" onClick={create} disabled={name.length === 0}>
						{t(closeBtnLabel)}
					</GenericButton>
					{haveCancel ? (
						<GenericButton variant="primary-outline" className="text-uppercase" onClick={onClose}>
							cancel
						</GenericButton>
					) : (
						""
					)}
				</ModalFooter>
			</Modal>
		</>
	);
});
NewModal.displayName = "NewModal";
export {NewModal}