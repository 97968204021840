import { EMPTY_GUID, isReadOnly } from "@utils/helpers";
import BaseActions from "features/folders/actions/BaseActions";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import { CollapseButton, NavLinkComponent } from "../navigation/SideBar/NavComponents.jsx";
import { allRoutes } from "shared/Routes/routes.js";

function FoldersManager() {
	const folders = useSelector(({ folders }) => folders?.getActiveRecords().getMainFolders());

	return (
		<React.Fragment>
			{folders.map((folder) => (
				<FolderItem key={folder.id} folderId={folder.id} />
			))}
		</React.Fragment>
	);
}

const FolderItem = memo(({ folderId }) => {
	const folderIdByUrl = useLocation().search.getFolderId();
	const folders = useSelector(({ folders }) => folders);
	const { UserType = "" } = useSelector(({ auth }) => auth?.userInfo || {});

	const folder = folders.find((folder) => folder.id === folderId);
	const [openFolder, setOpenFolder] = useState(folderIdByUrl === folderId ? true : false);
	const [rename, setRename] = useState(false);
	const userType = Number(UserType);
	const allEntities = allRoutes.top.filter((entity) => entity.route !== "/home" && entity.readOnlyMode === undefined);
	const allLinkedCountReducer =  allEntities.reduce((acc, entity) => {
		const linked = folder[entity?.linkedAttribute];
		if (linked?.length > 0) {
			acc += linked.length;
		}
		return acc;
	}, 0);
	const [allLinkedCount, setAllLinkedCount] = useState(allLinkedCountReducer+folder?.linkedFolders?.length);
	useEffect(() => {
		const count =allLinkedCountReducer + folder?.linkedFolders?.length;
		if(!openFolder && count  !== allLinkedCount){
			setOpenFolder(true)
			setAllLinkedCount(count)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[allLinkedCountReducer,allLinkedCount,folder?.linkedFolders])


	useEffect(() => {
		if (folderIdByUrl === folderId) {
			setOpenFolder(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const RenderLinkElement = useCallback(() => {
		const navLinkComponents = [];

		allEntities.forEach((entity) => {
			const linked = folder[entity?.linkedAttribute];
			if (linked?.length > 0) {
				const route = entity.route;
				const newRoute = { ...entity, route: `${route}?folderid=${folderId}` };

				navLinkComponents.push(<NavLinkComponent key={entity?.linkedAttribute} {...newRoute} />);
			}
		});

		if (folder?.linkedFolders?.length > 0) {
			folder?.linkedFolders?.forEach((foldId) => {
				const gotIt = folders.find((folder) => folder.id === foldId);
				if (gotIt) {
					navLinkComponents.push(<FolderItem key={foldId} folderId={foldId} />);
				}
			});
		}
		return navLinkComponents;
	}, [folder, folderId, folders,allEntities]);

    const hanldeClick = () => {
		setOpenFolder(!openFolder);
	};

	return (
		<div id={`folderId=${folderId}`}>
			<CollapseButton
				icon={openFolder ? "CHEVRON-DOWN" : "ANGLE-RIGHT"}
				text={folder?.name}
				onClick={hanldeClick}
				id={folder?.id}
				setRename={setRename}
				rename={rename}
				isReadOnly={isReadOnly(userType)}
				actions={{ type: "dropdown", icon: "SQUARE_PLUS", actions: BaseActions({ folder, setRename, context: folder?.parentId !== EMPTY_GUID ? "sidebar.subfolder" : "sidebar.folder" }) }}
			/>
			<Collapse isOpen={openFolder} className="px-3">
				<RenderLinkElement />
			</Collapse>
		</div>
	);
});

FoldersManager.displayName = "FoldersManager";
FolderItem.displayName = "FolderItem";
export default memo(FoldersManager);
