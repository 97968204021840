import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const DropdownWrapper = styled(Dropdown)`
	margin: 0 !important;
	display: flex;

	.dropdown-toggle:after {
		display: none;
	}

	.dropdown-item:hover {
		visibility: visible;
	}

	.dropdown-menu.show {
		padding: 0px;
		padding: 16px;
		box-shadow: 1px 1px 3px rgba(33, 33, 33, 0.3);
	}
`;
