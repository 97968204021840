import { FilterMatchMode, FilterOperator } from "primereact/api";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DataContext from "./DataContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { EMPTY_GUID, getTableScrollHeight, hasAttribute, tableDefaultColumn } from "@utils/helpers";
import { loadTableProps } from "./reducer/TableSlice";
import { currentWorkspaceId } from "index";

export const GlobalAuthorizedFilters = [
	"name",
	"mapType",
	"score",
	"subject",
	"mapName",
	"tagNames",
	"lastModifiedOn",
	"associatedContactsCount",
	"cjmCount",
	"loginName",
	"contactName",
	"insightName",
    "contactName",
    "fullName"
];

export const useDataTableState = (props) => {
	const [disableBulkActions, setDisableBulkActions] = useState(false);
	const [rename, setRename] = useState("");

	const contextData = DataContext({ ...props, disableBulkActions, setRename });
	return { contextData, rename, setRename, disableBulkActions, setDisableBulkActions };
};

export const useSavedViews = () => {
	return useSelector((state) => state.settings.userSetting)?.savedViewsByEntity;
};

export const useDefaultViews = () => {
	return useSelector((state) => state.settings.userSetting)?.savedDefaultViews?.[currentWorkspaceId] || EMPTY_GUID;
};
export function extractGlobalFilters(columns) {
	let globalFilter = columns
		?.map((col) => (["personaName", "cxProgramName", "folderName", "projectName", "insightName", "contactName"].includes(col) ? "name" : GlobalAuthorizedFilters.includes(col) ? col : false))
		.filter(Boolean);
	if (globalFilter?.length === 0) globalFilter = undefined;
	return globalFilter || undefined;
}

export const getInitialStates = (props, data) => {
	const visibleItems = data?.showOnlyLinked ? data.linkedObjects : data.listData;
	const selectedInitialState = props.showAllActiveRecord ? data.linkedObjects : null;
	return { visibleItems, selectedInitialState };
};

export const filterInit = (columns, tableProps) => {
	const filterProp = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
		fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
		subject: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
		projectName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
		stageName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
		lastModifiedOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
		lastUpdated: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
		dueDateNew: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
		isFutureValue: { value: null, matchMode: FilterMatchMode.IN },
		score: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.BETWEEN }] },
		mapType: { value: null, matchMode: FilterMatchMode.IN },
		concatPersona: { value: null, matchMode: FilterMatchMode.CONTAINS },
		templateStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
		cjmId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		priorityId: { value: null, matchMode: FilterMatchMode.IN },
		mapId: { value: null, matchMode: FilterMatchMode.IN },
		ownerId: { value: null, matchMode: FilterMatchMode.CONTAINS },
		statusId: { value: null, matchMode: FilterMatchMode.IN },
		groupIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
		teamIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
		workspaceIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
		customerValue: { value: null, matchMode: FilterMatchMode.IN },
		businessValue: { value: null, matchMode: FilterMatchMode.IN },
		win: { value: null, matchMode: FilterMatchMode.EQUALS },
		truth: { value: null, matchMode: FilterMatchMode.EQUALS },
		associatedContactsCount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		cjmCount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		age: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		tagIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
		totalNumCjms: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		totalNumPersonas: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		totalNumOpps: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		totalNumSols: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		totalNumProjects: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		totalNumActions: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		"linkedCjms.length": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		modifiedOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
		loginName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
		userType: { value: null, matchMode: FilterMatchMode.CONTAINS },
		subscriptionPlan: { value: null, matchMode: FilterMatchMode.IN },
		solutionType: { value: null, matchMode: FilterMatchMode.IN },
		occurance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
		typeId: { value: null, matchMode: FilterMatchMode.IN },
		cjmIdsStr: { value: null, matchMode: FilterMatchMode.CONTAINS },
	};
	const fields = Object.values(tableProps).map((props) => props?.filterField);
	const filterProps = Object.keys(filterProp).reduce((acc, key) => {
		if (key === "global" || fields.includes(key)) {
			acc[key] = filterProp[key];
		}
		return acc;
	}, {});
	return filterProps;
};

export function useGetContext(context) {
	return useCallback(
		(index = 1) => {
			return context.split(".")[index];
		},
		[context]
	);
}

export function useGetDefaultView(data, context) {
	const getContext = useGetContext(context);
	const savedDefaultViews = useDefaultViews();

	return useCallback(() => {
		const hasDefaultView = ["journeyMap", "cxActionsList", "cxProgramList", "OpportunitiesList", "solutionsList", "personasList", "projectsList", "insightList", "manageUsers"];

		return hasAttribute(savedDefaultViews, data?.entityType) && hasDefaultView.includes(getContext()) ? savedDefaultViews?.[data?.entityType] : EMPTY_GUID;
	}, [getContext, savedDefaultViews, data]);
}

export function useLoadTableWithSettings(data, context) {
	const getContext = useGetContext(context);
	const getDefaultView = useGetDefaultView(data, context);
	const savedViews = useSavedViews();
	const folderId = useLocation().search.getFolderId();

	return useCallback(() => {
		const currentContext = getContext();
		const defaultView = getDefaultView();
		if (defaultView !== EMPTY_GUID && folderId === undefined) {
			const view = savedViews?.[data?.entityType]?.find((x) => x.id === defaultView);
			if (view) {
				const columnFilterView = view?.columnSetting ? JSON.parse(view?.columnSetting).map((x) => x["name"]) : [];
				const filterSetting = view?.filterSetting ? JSON.parse(view?.filterSetting) : {};
				const sortSetting = view?.sortSetting ? JSON.parse(view?.sortSetting) : {};
				loadTableProps({
					context: currentContext,
					columns: columnFilterView,
					selectedViewId: defaultView,
					entityType: view?.type,
					filter: filterSetting,
					sort: sortSetting,
				});
			} else {
				loadTableProps({
					context: currentContext,
					columns: tableDefaultColumn(data?.entityType),
					entityType: data?.entityType,
					selectedViewId: defaultView,
				});
			}
		} else {
			loadTableProps({
				context: currentContext,
				columns: tableDefaultColumn(data?.entityType),
				entityType: data?.entityType,
				selectedViewId: EMPTY_GUID, // Using EMPTY_GUID directly since it's the else case for defaultView
			});
		}
	}, [getContext, getDefaultView, savedViews, data, folderId]);
}

export const getVirtualScroller = (context, visibleItems) => {
	const isFirefox = /firefox/i.test(navigator.userAgent);
	return (
		// !["home"].includes(context) &&
		!isFirefox && {
			scrollable: true,
			scrollHeight: getTableScrollHeight({ items: visibleItems, context }),
			virtualScrollerOptions: { itemSize: 56 },
		}
	);
};
