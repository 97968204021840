import { SolutionType } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID } from "@utils/helpers";
import styled, { css } from "styled-components";

export const getTopXItems = ({ parentData, allItems = [], linkedIdsAttribute, nameAttribute, maxItems }) => {
	const memory = {};

	// Create a memory object, id: {item, parentItemIds, value}
	parentData?.forEach((data) => {
		data[linkedIdsAttribute]?.forEach((linkedId) => {
			const currentItem = allItems?.find((tp) => tp.id === linkedId);
			if (!memory[linkedId]) memory[linkedId] = { item: { ...currentItem }, parentItemIds: new Set([data.id]), name: currentItem?.[nameAttribute], value: 1 };
			else memory[linkedId] = { ...memory[linkedId], parentItemIds: memory[linkedId]?.parentItemIds.add(data.id), value: memory[linkedId].value + 1 };
		});
	});

	//Transform the memory into a list
	const itemsList = Object.values(memory);

	//Sort the list
	const sortedList = itemsList?.sort((a, b) => b.value - a.value);
	const slicedList = sortedList?.slice(0, maxItems);

	const formatedList = slicedList?.map((item) => {
		return { ...item, parentItemIds: Array.from(item.parentItemIds) };
	});

	return formatedList;
};

export const CardContainer = styled.div`
	border-top: 1px solid #c4c4c4;
	${(props) =>
		props.islast &&
		css`
			border-bottom: 1px solid #c4c4c4;
		`}
`;

export const getPointColors = (type) => {
	switch (type) {
		case SolutionType.QuickWin:
			return { background: "#dcffff", border: "#18aead" };
		case SolutionType.ComplexProject:
			return { background: "#c5c2d4", border: "#333044" };
		case SolutionType.NiceToHave:
			return { background: "#d2eefb", border: "#20a9e9" };
		case SolutionType.ToAvoid:
			return { background: "#fde7cb", border: "#f48e09" };
		default:
			return { background: "#c3c3c3", border: "#000" };
	}
};

export const getMonthNumber = (inputDate) => {
	if (!(inputDate instanceof Date) || isNaN(inputDate)) {
		return "";
	}

	return inputDate.getMonth();
};

export const getAverageScore = ({ data, scoreAttribute }) => {
	if (data.length === 0) return 0;
	let score = 0;
	data.forEach((item) => (score += item[scoreAttribute]));
	return score / data.length;
};

export function sortByName(objects) {
	objects.sort((a, b) => {
		const nameA = a.name?.toLowerCase();
		const nameB = b.name?.toLowerCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});

	return objects;
}

export const isBlanksSelected = (data) => data?.find((item) => item?.id === EMPTY_GUID && item?.name === "Blanks");
export const isBlanks = (data) => data?.id === EMPTY_GUID && data?.name === "Blanks";

export const bigNumberFormatter = ({ number, currencySymbol }) => {
    let formatted = ""
	if (number >= 1000000) {
		formatted = `${(number / 1000000).toFixed(1)}M`;
	} else if (number >= 1000) {
		formatted = `${(number / 1000).toFixed(1)}K`;
    }
    if (currencySymbol) formatted += `${ currencySymbol } `
	return formatted;
};
