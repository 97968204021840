import { FlexBetween, FlexBetweenAlignCenter, FlexColumnGap, FlexGap, GenericButton, Input, Label } from "shared/ui-basics/index";
import { CxGoalDirection } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, calculateFinancialImpact, getCxGoalValue, validateStringNumberInput } from "@utils/helpers";
import { ProgressBar } from "primereact/progressbar";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Collapse } from "reactstrap";
import styled from "styled-components/macro";
import { CustomEditor } from "../../CustomEditor";
import { ColorTextCard } from "../../../../features/forms/personas/cards/ColorTextCard";
import { FinancialImpact } from "../../../ui-basics/ui-components/FinancialImpact";
import { SelectionTouchPoint } from "./SelectionTouchPoint";

const EditCxGoalCard = memo(({ cxGoal, setCxGoals, isEditable = true, width }) => {
	const { t } = useTranslation();
	const isFromMap = useLocation().pathname === "/EditMap";

	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";
	const measure = useSelector((state) => state.libraries.measures)?.find((measure) => measure.id === cxGoal?.measureId);
	const relatedSystem = useSelector((state) => state.libraries.customLibraries)
		?.find((library) => library.name === "Systems" && !library.isCustom)
		.values?.find((system) => system.id === measure?.systemSourceId);

	const [showDetails, setShowDetails] = useState(false);

	useEffect(() => {
		if (measure?.name) {
			setCxGoals((prevState) => {
				const measureName = measure.name;
				return prevState.map((goal) => {
					if (goal.measureId === cxGoal?.measureId) {
						goal = { ...goal, measureName };
					}
					return goal;
				});
			});
		}
	}, [measure?.name]);

	const handleChange = (e) => {
		let { value, name } = e.target;
		let n = value;
		if (name !== "date") {
			n = validateStringNumberInput({ n: value, isPositive: name === "financialImpact" ? true : false });
		}

		if (n === undefined) return;

		setCxGoals((prevState) => {
			return prevState.map((goal) => {
				if (goal.measureId === cxGoal?.measureId) {
                    goal = { ...goal, [name]: n };
					goal.achieved=getCxGoalValue({ cxGoal:goal }),
                    goal.financialImpact = calculateFinancialImpact({ cxGoal: goal, measure });
				}
				return goal;
			});
		});
	};

	const handleChangeEditor = (value) => {
		setCxGoals((prevState) => {
			return prevState.map((goal) => {
				if (goal.measureId === cxGoal?.measureId) {
					goal = { ...goal, description: value };
				}
				return goal;
			});
		});
	};

	const handleIncrease = () => {
		setCxGoals((prevState) => {
			return prevState.map((goal) => {
				if (goal.measureId === cxGoal?.measureId) {
                    goal = { ...goal, direction: CxGoalDirection.Increase };
                    goal.financialImpact = calculateFinancialImpact({ cxGoal: goal, measure });
				}
				return goal;
			});
		});
	};

    const handleDecrease = () => {
        setCxGoals((prevState) => {
            return prevState.map((goal) => {
                if (goal.measureId === cxGoal?.measureId) {
                    goal = { ...goal, direction: CxGoalDirection.Decrease };
                    goal.financialImpact = calculateFinancialImpact({ cxGoal: goal, measure });
                }
                return goal;
            });
        });
    };

	const handleLinkTouchpoint = (touchpoint) => {
		setCxGoals((prevState) => {
			return prevState.map((goal) => {
				if (goal.measureId === cxGoal?.measureId) {
					goal = { ...goal, touchpointId: touchpoint.id };
				}
				return goal;
			});
		});
	};

	const handleUnlinkTouchpoint = () => {
		setCxGoals((prevState) => {
			return prevState.map((goal) => {
				if (goal.measureId === cxGoal?.measureId) {
					goal = { ...goal, touchpointId: EMPTY_GUID };
				}
				return goal;
			});
		});
	};

	return (
		<Container width={width}>
			<FlexColumnGap className="p-4" gap="6">
				<Title>
					{`${measure?.name}`}
					{measure?.unit && ` (${measure?.unit})`}
				</Title>
				<FlexColumnGap gap="16">
					<FlexGap gap={10} className="m-2">
						<RadioButton type="radio" onChange={handleIncrease} checked={cxGoal?.direction === CxGoalDirection.Increase} />
						<div>{t("INCREASE")}</div>
						<RadioButton type="radio" onChange={handleDecrease} checked={cxGoal?.direction === CxGoalDirection.Decrease} />
						<div>{t("DECREASE")}</div>
					</FlexGap>
					<FlexBetween>
						<CustomInput className="me-4" name="actual" variant="default" type="text" value={(cxGoal?.actual).toLocaleString()} onChange={handleChange} disabled={!isEditable} />
						<CustomInput name="goal" variant="default" type="text" value={cxGoal?.goal?.toLocaleString()} onChange={handleChange} disabled={!isEditable} />
					</FlexBetween>
					<ProgressBarWrapper gap="5">
						<ProgressBar value={getCxGoalValue({ cxGoal })} displayValueTemplate={() => {}} />
						<FlexBetweenAlignCenter>
							<BarLabel>{t("ACTUAL")}</BarLabel>
							<BarLabel>{t("GOAL")}</BarLabel>
						</FlexBetweenAlignCenter>
					</ProgressBarWrapper>
					<FinancialImpact financialImpact={cxGoal?.financialImpact.toFixed(2)} currencySymbol={currencySymbol} readonly />
					<FlexColumnGap>
						<CustomLabel>{t("SOURCE")}</CustomLabel>
						<ColorTextCard text={relatedSystem?.name} color={relatedSystem?.colorCode} className="form-control" />
					</FlexColumnGap>

					{isFromMap && (
						<FlexColumnGap>
							<CustomLabel>{t("TOUCHPOINT")}</CustomLabel>
							{isFromMap  && (
								<SelectionTouchPoint selectedTouchpointId={cxGoal?.touchpointId} handleLinkTouchpoint={handleLinkTouchpoint} handleUnlinkTouchpoint={handleUnlinkTouchpoint} isEditable={isEditable}/>
							)}
						</FlexColumnGap>
					)}
				</FlexColumnGap>
				<GenericButton variant="light-link" icon={showDetails ? "ARROW_UP" : "ARROW_DOWN"} onClick={() => setShowDetails(!showDetails)} iconClassName="me-1">
					{t("MORE_DETAILS")}
				</GenericButton>
			</FlexColumnGap>
			<Collapse isOpen={showDetails}>
				<Details className="p-4" gap="6">
					<FlexColumnGap gap="3">
						<Label size="14">{t("DATE")}</Label>
						<Input
							variant="default"
							id="Date"
							name="date"
							placeholder="date placeholder"
							disabled={!isEditable}
							value={cxGoal?.date?.substring(0, 10)}
							type="date"
							onChange={handleChange}
						/>
					</FlexColumnGap>
					<FlexColumnGap gap="3">
						<Label size="14">{t("ASPX_9")}</Label>
						<CustomEditor variant={"description"} html={cxGoal?.description} sethtml={handleChangeEditor} disabled={!isEditable} />{" "}
					</FlexColumnGap>
				</Details>
			</Collapse>
		</Container>
	);
});
EditCxGoalCard.displayName = "EditCxGoalCard";
export default EditCxGoalCard;

const Container = styled.div`
	width: ${(props) => (props.width ? props.width : "303px")};
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	background: #fff;
`;

const ProgressBarWrapper = styled(FlexColumnGap)`
	.p-progressbar {
		height: 5px !important;
	}

	.p-progressbar .p-progressbar-value {
		background: #f96209;
	}
`;

const BarLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Title = styled.div`
	color: #333;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const CustomInput = styled(Input)`
	color: #f96209;
	font-family: "Oswald";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 36px;
	border-radius: 0;
	border-radius: ${(props) => (props.palette ? "0px 4px 4px 0px;" : "4px;")};

	:disabled {
		background-color: transparent !important;
		color: #c4c4c4 !important;
	}
`;

const Details = styled(FlexColumnGap)`
	border-top: 1px dashed #c4c4c4;
`;

const RadioButton = styled(Input)`
	width: 16px;
	height: 16px;

	:checked {
		background-color: #333333;
		border-color: #333333;
	}
	:focus {
		box-shadow: none;
	}
`;

const CustomLabel = styled.div`
	gap: 20px;
	color: #605f60;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	white-space: nowrap;
`;
