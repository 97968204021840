import { handleAddRenderElement } from "@utils/helpers";
import Export from "features/analytics/Components/Export";
import FiltersManager from "features/analytics/Components/Filters/FiltersManager";
import HorizontalBarsChart from "features/analytics/Components/HorizontalBarsChart";
import Section from "features/analytics/Components/Section";
import { DashboardContainer, TopSection } from "features/analytics/Style";
import { applyFilterRoiAnalysis } from "features/analytics/reducer/analyticActions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FinancialImpactPerMeasure from "./Reports/FinancialImpactPerMeasure";
import KpiByActual from "./Reports/KpiByActual";
import { countMeasuresSelector, ownerByFinancialImpactSelector, projectAndKpiSelector } from "./roiAnalysisDataSelectors";

function ROIAnalysisReports() {
	const { t } = useTranslation();
	const filterBy = useSelector((state) => state.analytics?.filterBy);
	const hideForPdf = useLocation().search.hideForPdf();

	const getFiltersToSave = ({ currentEntityData }) => {
		return {
			journeys: currentEntityData?.journeys.map((j) => j?.id),
			projects: currentEntityData?.projects.map((p) => p?.id),
			date: currentEntityData?.date,
			currentFilterBy: currentEntityData?.currentFilterBy,
		};
	};

	return (
		<DashboardContainer className="container my-4">
			<TopSection className="p-4" xs={12}>
				<FiltersManager filterEntities={filterBy} applyAction={applyFilterRoiAnalysis} />
				<Export dashboardName="roianalysis" fileName={t("ROI_ANALYSIS")} nSections="6" getFiltersToSave={getFiltersToSave} />
			</TopSection>
			<Section titles={[t("KPI_ACTUAL_ACHIEVEMENT")]}>
				<KpiByActual />
			</Section>
			<Section titles={[t("TOP_FIVE") + " " + t("MEASURES")]}>
				<HorizontalBarsChart dataSelector={countMeasuresSelector} barColor="#F96209" relatedItemsTitle={t("ASPX_97")} itemAttributeId="id" emptyDataText="TOP_MEASURES_REPORT_DESCRIPTION" />
			</Section>
			<Section titles={[t("FINACICAL_IMPACT_MEASURE")]}>
				<FinancialImpactPerMeasure />
			</Section>
			<Section titles={[t("FINACICAL_IMPACT_PROJECT")]}>
				<HorizontalBarsChart dataSelector={projectAndKpiSelector} barColor="#18AEAD" relatedItemsTitle={t("ASPX_97")} itemAttributeId="id" emptyDataText="ROI_BY_PROJECT_REPORT_DESCRIPTION" />
			</Section>
			<Section titles={[t("FINACICAL_IMPACT_PROJECT_OWNER")]}>
				<HorizontalBarsChart
					dataSelector={ownerByFinancialImpactSelector}
					barColor="#18AEAD"
					relatedItemsTitle={t("ASPX_97")}
					// listItemComponent={MapListCard}
					itemAttributeId="id"
					emptyDataText="REVENUE_BY_PROJECT_REPORT_DESCRIPTION"
				/>
			</Section>
			{hideForPdf && <div id="addRenderElement">{handleAddRenderElement(3000)} </div>}
		</DashboardContainer>
	);
}

ROIAnalysisReports.displayName = "ROIAnalysisReports";
export default ROIAnalysisReports;
