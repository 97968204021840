import React, { useState } from "react";
import StepWizard from "react-step-wizard";
import * as Style from "../creation/Style.js";
import { useAsync } from "shared/hooks/index";
import { getAllMapsListData, getAllPersonas } from "@redux/index.js";
import MapType from "../creation/MapType.js";
import MapName from "../creation/MapName.js";
import MapPersonas from "../creation/MapPersonas.js";
import { createDefaultMap } from "@utils/helpers.js";

let custom = {
	enterRight: "your custom css transition classes",
	enterLeft: "your custom css transition classes",
	exitRight: "your custom css transition classes",
	exitLeft: "your custom css transition classes",
	intro: "your custom css transition classes",
};

export default function JourneyMapCreation() {
	const query = new URLSearchParams(window.location.search.toLowerCase());
	const isTemplate = query.get("istemplate") === "true";
	const folderId = query.get("folderid");

	const [newMap, setNewMap] = useState(createDefaultMap(isTemplate));
	useAsync({ asyncFn: getAllMapsListData });
	useAsync({ asyncFn: getAllPersonas });

	return (
		<Style.MainContainer className=" position-relative">
			<StepWizard transitions={custom} isLazyMount={true} totalSteps={3}>
				<MapType newMap={newMap} setNewMap={setNewMap} folderId={folderId} />
				<MapName newMap={newMap} setNewMap={setNewMap} />
				<MapPersonas newMap={newMap} setNewMap={setNewMap} />
			</StepWizard>
		</Style.MainContainer>
	);
}
