import { GenericButton } from "shared/ui-basics/buttons";
import { IconDispatcher } from "shared/Icons";
import { Flex } from "shared/ui-basics/index";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";

const SyncWithModal = memo(({ isOpen, onClose, title, bodyText, actionBtnText, action, alertType = "info" }) => {
	const { t } = useTranslation();
	const confirm = () => {
		action();
		onClose();
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<ModalContainer fade={false} isOpen={isOpen} toggle={onClose} centered={true}>
			<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
			<Body>
				<div className={`alert alert-${alertType}`}>
					<Flex gap="10">
						<div>{IconDispatcher("INFO")}</div>
						<div>{bodyText}</div>
					</Flex>
				</div>
				<div>{t("CONTINUE_VALIDATION")}</div>
			</Body>
			<ModalFooter>
				<GenericButton variant="primary-outline" className="text-capitalized" onClick={handleClose}>
					{t("ASPX_86")}
				</GenericButton>
				<GenericButton variant="primary" className="text-capitalized" onClick={confirm}>
					{actionBtnText}
				</GenericButton>
			</ModalFooter>
		</ModalContainer>
	);
});

SyncWithModal.displayName = "SyncWithModal";
export { SyncWithModal };

const ModalContainer = styled(Modal)`
	max-width: 450px;
`;

const Body = styled(ModalBody)`
	color: #76838f;
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
`;
