import { getAllFoldersFn, getOrganizationFn } from "@redux/index";
import { encode, isGUID } from "@utils/helpers";
import { base } from "index";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "shared/components/spinners/Spinner";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { authenticate } from "../reducer/AuthActions";

export default function Authentication(props) {
	const history = useHistory();
	const { t } = useTranslation();
	const targetUrl = props.location.search?.getTargetUrl();
	const authProps = props.location.search?.getAuthenticationProperties();
	const [loading, setLoading] = useState(false);
	const error = useSelector((state) => state.auth?.error);
	const hasRun = useRef(false);

	useEffect(() => {
		if (!hasRun.current) {

			Cookies.remove("Authorization-cemantica");
			if (authProps.userName && authProps.orgName && authProps.secret) {
				setLoading(true);
				authenticate({
					body: authProps,
					onSuccess: (data) => {

						const baseUrl = base.split("/").filter(Boolean);
						const target  = targetUrl ?  targetUrl.startsWith("/") ? targetUrl : `/${targetUrl}` : null;
						if (!isGUID(baseUrl[1])) {
							window.location.href = `/${baseUrl[0]}/${data.userInfo.defaultWorkspaceId}${target || "/home"}`;
						} else {
							getOrganizationFn();
							getAllFoldersFn();

							history.push(target ||  "/home");
						}
					},
					onFailed: (error) => {
						const message = error?.data;
						const status = error?.status || error?.response?.status;
						if (status === 403) {
							return history.push("/invalid-workspace?statusCode=1");
						}
						return history.push(`/invalid-url?type=${encode(message)}`);
					},
					setLoading,
				});
			} else {
				history.push("/logout?force=true");
			}
			hasRun.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authProps, history, targetUrl]);

	const displayError = () => {
		if (error === "Your subscription plan has expired")
			return (
				<CustomContainer>
					{IconDispatcher("SUBSCRIPTION-END", "m-3 mt-0 mb-5")}
					<ExpirationEndedText>{t("CS_19") + "... "}</ExpirationEndedText>
					<GenericButton variant="primary" onClick={handleSubscrClick} target="_blank">
						{t("EXTEND_NOW")}
					</GenericButton>
				</CustomContainer>
			);

		return (
			<CustomContainer>
				{IconDispatcher("EXCLAMATION", "me-1")}
				{t(error)}
			</CustomContainer>
		);
	};

	const handleSubscrClick = (e) => {
		e.preventDefault();
		return window.open("https://www.cemantica.com/Pricing");
	};

	return (
		<div>
			{loading && <Spinner full={true} placeholder={t("CONNECTING")} />}
			{error && <div className="full-screen">{displayError()}</div>}
		</div>
	);
}

export const CustomContainer = styled.h1`
	width: 50% !important;
	text-align: center;
`;

const ExpirationEndedText = styled.div`
	color: #333333 !important;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-self: center;
	font-family: "Inter";
	font-style: normal;
	text-align: center;
	color: #014f76;
	margin-bottom: 2rem;
`;
