import { DuplicateModal, NewModal } from "shared/modals/index";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { FlexBetweenAlignCenter, GenericButton, SearchBar } from "shared/ui-basics/index";
import { CustomBreadCrumb } from "shared/ui-basics/ui-components/CustomBreadCrumb";
import { EmptyList } from "shared/ui-basics/ui-components/EmptyList";
import { copyTouchpointCategory, deleteTouchpointCategory, upsertTouchpointCategory } from "@redux/index";
import { EMPTY_GUID, emptyListData } from "@utils/helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";

const getAttributeForCategory = (item) => {
	if (item?.categoryId) return "categoryId";
	else if (item?.category) return "category";
	else if (item?.type) return "type";
	return "";
};

const newTouchpointCategory = {
	name: "",
	id: EMPTY_GUID,
};

export const LibraryTemplate = ({ items, title, addBtnText, showCreateModal, categories, displayLibraryItem }) => {
	const { t } = useTranslation();
	const [filterValue, setFilterValue] = useState("");
	const categoryAttId = getAttributeForCategory(items?.[0]);
	const All = EMPTY_GUID;
	const [category, setCategory] = useState(EMPTY_GUID);
	const displayMenu = categories ? true : false;
	const subscriptionPlanName = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const shownCategory = (categories && Object.fromEntries(categories?.filter((x) => x?.isShown === undefined || x.isShown)?.map((x) => [x.id, x?.name]))) || {};
	const isEnterprise = subscriptionPlanName === "Enterprise";

	const handleChange = (e) => {
		setFilterValue(e.target.value);
	};

	const getItemsCount = (category) => {
		if (category && category !== All) {
			return items?.filter((obj) => obj[categoryAttId]?.toString() === category)?.length;
		}
		return items?.filter((item) => categoryAttId !== "categoryId" || shownCategory[item[categoryAttId]?.toString()])?.length;
	};

	const selectCategory = (e) => {
		setCategory(e.currentTarget.id);
	};

	const Search = SearchBar({ filterValue, handleChange, size: "small", label: `SEARCH`, floatLabel: false });

	const getFilteredItems = () => {
		let filteredItems = items;
		if (categories && category !== EMPTY_GUID) {
			filteredItems = items?.filter((item) => category === item?.[categoryAttId]?.toString());
		}
		filteredItems = filteredItems
			?.filter((item) => item?.name?.toUpperCase().includes(filterValue?.toUpperCase()) && (categoryAttId !== "categoryId" || shownCategory[item[categoryAttId]?.toString()]))
			?.sortAsc("name");
		return filteredItems;
	};

	const { icon, color } = emptyListData.libraries;

	const menuDisplay = (categoryObj) => {
		if (categoryObj.isShown === undefined) {
			return (
				<MenuItem key={categoryObj?.name} id={categoryObj?.id} onClick={selectCategory} active={categoryObj?.id === category}>
					{t(categoryObj?.name)} ({getItemsCount(categoryObj?.id)})
				</MenuItem>
			);
		}
		return (
			<MenuItem
				key={categoryObj?.name}
				id={categoryObj?.id}
				onClick={categoryObj?.isShown && selectCategory}
				active={categoryObj?.id === category}
				disable={!categoryObj?.isShown}
				isActionBox={"true"}>
				<div>
					{t(categoryObj?.name)} ({getItemsCount(categoryObj?.id)})
				</div>
				<ActionsBox id="actions">{displayActions(categoryObj)}</ActionsBox>
			</MenuItem>
		);
	};

	const displayActions = (categoryObj) => {
		const isDeleteAllowed = getItemsCount(categoryObj?.id) > 0;
		const isUpdatedAllowed = isEnterprise || categoryObj?.isCustom ? true : false;

		if (!isUpdatedAllowed) {
			return <div></div>;
		}

		return CreateActionsDropdown({
			actions: [
				{
					id: "show",
					handleClick: () => {
						upsertTouchpointCategory({ touchpointCategory: { ...categoryObj, isShown: !categoryObj?.isShown } });
					},
					icon: categoryObj?.isShown ? "HIDE_EYE" : "VIEW",
					text: t(categoryObj?.isShown ? "HIDE" : "SHOW"),
				},
				{
					id: "edit",
					handleClick: () => {
						showCreateCategoryModal({
							title: "JS_89",
							closeBtnLabel: "ASPX_135",
							inputTitle: "CATEGORY_NAME",
							placeholder: "NEW_CATEGORY",
							action: (name) => upsertTouchpointCategory({ touchpointCategory: { ...categoryObj, name } }),
						})
					},
					icon: "EDIT",
					text: t("JS_89"),
					disabled: !isUpdatedAllowed,
				},
				{
					id: "copy",
					handleClick: () => {
						showCopyModal({
							title: `${t("JS_61")} ${t('CATEGORY')}`,
							inputTitle: "NAME",
							closeBtnLabel: "COPY",
							inputValue: categoryObj?.name,
							action: ({ name, selectedWorkspaces }) => copyTouchpointCategory({ id: categoryObj?.id, name, workspaceIds: selectedWorkspaces.map((w) => w?.id) }),
						});
					},
					icon: "COPY",
					text: t("COPY"),
				},
				{
					id: "delete",
					handleClick: () => {
						deleteTouchpointCategory({ id: categoryObj?.id });
					},
					icon: "DELETE",
					text: t("CS_45"),
					tooltipText: isDeleteAllowed && t("ERROR_DELETE_CATEGORY"),
					disabled: isDeleteAllowed,
				},
			],
			variant: "default",
			dropdownClasses: "",
		});
	};
	const handleCreate = () => {
		showCreateCategoryModal({
			title: "CREATE_CATEGORY",
			closeBtnLabel: "ASPX_135",
			inputTitle: "CATEGORY_NAME",
			placeholder: "NEW_CATEGORY",
			action: (name) => upsertTouchpointCategory({ touchpointCategory: { ...newTouchpointCategory, name } }),
		})

	}
	const [showCopyModal] = useModal(DuplicateModal);
	const [showCreateCategoryModal] = useModal(NewModal);

	return (
		<LibraryContainer>
			<LibraryHeader className="pt-4 pb-4">
				<LibraryTitle className="mb-4">{t(title)}</LibraryTitle>
				<CustomBreadCrumb paths={[t("LIBRARIES"), t(title)]} />
				<FlexBetweenAlignCenter className="mt-4">
					<GenericButton variant="primary" icon="SQUARE_PLUS" iconClassName="me-1" onClick={showCreateModal}>
						{t(addBtnText)}
					</GenericButton>
					<SearchContainer>{Search}</SearchContainer>
				</FlexBetweenAlignCenter>
			</LibraryHeader>

			{items?.length > 0 ? (
				<LibraryBody className="mt-2">
					{displayMenu && (
						<Menu>
							{categories?.map((categoryObj, i) => {
								return menuDisplay(categoryObj);
							})}
							{categoryAttId === "categoryId" && (
								<GenericButton variant="light-link" icon="SQUARE_PLUS" iconClassName="me-1" onClick={handleCreate} className="ps-1 justify-content-start">
									{t("NEW_CATEGORY")}
								</GenericButton>
							)}
						</Menu>
					)}

					<LibraryItems className="p-4" displaymenu={displayMenu}>

						{getFilteredItems()?.length > 0 ?getFilteredItems()?.map((item) => {
							return displayLibraryItem({ item });
						}) :<div className="mt-5 d-flex justify-content-center align-items-center w-100 h-75">
						<EmptyList data={{ emptyListIcon: icon, emptyListColor: color, emptyListText: "EMPTY_LIBRARY" }} />
					</div> }
					</LibraryItems>
				</LibraryBody>
			) : (
				<div className="mt-5">
					<EmptyList data={{ emptyListIcon: icon, emptyListColor: color, emptyListText: "EMPTY_LIBRARY" }} />
				</div>
			)}
		</LibraryContainer>
	);
};

const LibraryContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-right: 3rem;
	padding-left: 3rem;
`;

const SearchContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	& svg {
		left: 15px;
	}
`;

const LibraryBody = styled.div`
	display: flex;
	width: 100%;
	height: calc(100vh - 350px);

	@media screen and (max-height: 900px) {
		max-height: calc(100vh - 180px);
	}
	@media screen and (max-height: 768px) {
		max-height: calc(100vh - 180px);
	}
	@media screen and (max-height: 640px) {
		max-height: calc(100vh - 180px);
	}
`;

const LibraryHeader = styled.div`
	width: 100%;
	color: #605f60;
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-bottom: 1px solid #c4c4c4;
`;

const LibraryTitle = styled.div`
	display: flex;
	flex-direction: column;
`;

const Menu = styled.div`
	flex-basis: 20%;
	height: 100%;
	max-width: 240px;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	gap: 15px;
	padding: 0.5rem;
	border-right: 1px solid #c4c4c4;
	overflow: scroll;
`;

const MenuItem = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.disable &&
		css`
			color: #c4c4c4;
		`}

	${(props) =>
		props.active &&
		css`
			color: #f96209;
		`}
			${(props) =>
		props.isActionBox &&
		css`
			display: flex;
			justify-content: space-between;
			align-items: center;
		`}

	&:hover #actions {
		visibility: visible;
		cursor: pointer;
	}
`;
const ActionsBox = styled.div`
	visibility: hidden;
`;
const LibraryItems = styled.div`
	height: 100%;
	flex-basis: 80%;
	display: flex;
	align-content: flex-start;
	flex-wrap: wrap;
	gap: 30px;
	overflow: scroll;
	${(props) =>
		!props.displaymenu &&
		css`
			flex-basis: 100%;
		`}
`;
