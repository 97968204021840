import DataTable from "features/grid-table/DataTable";
import { useAsyncs } from "shared/hooks/index";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { groupInit, priorityInit, statusInit, tagInit } from "@redux/index";
import { getAllProjects } from "features/projects/reducer/ProjectsActions";
import { getFolderById } from "@redux/memoizedSelectors";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Projects() {
	const { t } = useTranslation();

	const folderId = useLocation().search.getFolderId();
	const { linkedProjects } = useSelector((state) => getFolderById(state, folderId), shallowEqual);

	useAsyncs({
		asyncFns: [{ asyncFn: getAllProjects }, { asyncFn: statusInit }, { asyncFn: groupInit }, { asyncFn: tagInit },{ asyncFn: priorityInit }],
	});

	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("PROJECTS")}`}>
			<DataTable context="projects.projectsList" linked={linkedProjects} />
		</PageTemplate>
	);
}
