import { EMPTY_GUID, findById, isEmptyObject, isNullOrEmptyObject } from "@utils/helpers";

export const getSelectableIds = ({ updatedData, allItems, currentItemidAttribute = "id", linkedAttribute, linkedAttributeIdAttribute }) => {
	const selectableIds = [];
	updatedData?.forEach((data) => {
		const currentItem = findById(allItems, data?.id || data, currentItemidAttribute);
		if (!isEmptyObject(currentItem))
			currentItem[linkedAttribute]?.forEach((linkedItem) => {
				if (linkedAttributeIdAttribute) selectableIds.push(linkedItem[linkedAttributeIdAttribute]);
				else selectableIds.push(linkedItem);
			});
	});
	return selectableIds;
};

export const getInteractionsRelatedItems = ({ interactions, state }) => {
	const formatedData = { journeys: [], personas: [], touchpoints: [{ id: EMPTY_GUID, name: "Blanks" }], metrics: [{ id: EMPTY_GUID, name: "Blanks" }], date: "" };
	const cjmMemory = {};
	const personaMemory = {};
	const touchpointMemory = {};
	const metricMemory = {};

	interactions.map((interaction) => {
		const currentMap = findById(state.all_maps, interaction.mapId, "cjmId");
		const currentPersona = findById(state.all_personas, interaction.personaId, "personaId");
		const currentTouchpoint = findById(state.all_touchpoints, interaction.touchpointId, "id");
		const currentMetric = findById(state.all_metrics, interaction.metricId, "id");

		if (!isNullOrEmptyObject(currentMap) && !(currentMap.cjmId in cjmMemory)) {
			formatedData.journeys.push({ id: currentMap.cjmId, name: currentMap.name });
			cjmMemory[currentMap.cjmId] = true;
		}
		if (!isNullOrEmptyObject(currentPersona) && !(currentPersona.personaId in personaMemory)) {
			formatedData.personas.push({ id: currentPersona.personaId, name: currentPersona.name });
			personaMemory[currentPersona.personaId] = true;
		}
		if (!isNullOrEmptyObject(currentTouchpoint) && !(currentTouchpoint.id in touchpointMemory)) {
			formatedData.touchpoints.push({ id: currentTouchpoint.id, name: currentTouchpoint.name, weight: currentTouchpoint?.weight });
			touchpointMemory[currentTouchpoint.id] = true;
		}
		if (!isNullOrEmptyObject(currentMetric) && !(currentMetric.id in metricMemory)) {
			formatedData.metrics.push({ id: currentMetric.id, name: currentMetric.name });
			metricMemory[currentMetric.id] = true;
		}
	});

	return formatedData;
};

export const formatJourneyTrendsData = ({ state, data }) => {
	data.journeys = data.journeys.map((journey) => {
		const currentMap = findById(state.all_maps, journey.id, "cjmId");
		return { ...journey, name: currentMap?.name };
	});
	data.personas = data.personas.map((persona) => {
		const currentPersona = findById(state.all_personas, persona.id, "personaId");
		return { ...persona, name: currentPersona?.name };
	});
	data.programs = data.programs.map((program) => {
		const currentProgram = findById(state.all_programs, program.id, "id");
		return { ...program, name: currentProgram?.name };
	});
};
export const formatRoiData = ({ state, data }) => {
	data.projects = data.projects.map((project) => {
		const currentProject = findById(state.all_projects, project.id, "id");
		return { ...project, name: currentProject?.name };
	});
};

export const getAllProjectRelatedMaps = ({ projects, allMaps }) => {
	const mapIds = [];
	projects.forEach((project) => {
		project.cjmIds.forEach((id) => {
			if (!mapIds?.includes(id)) mapIds.push(id);
		});
    });
    
    return allMaps?.filter(m => mapIds.includes(m?.cjmId))?.map((cjm) => ({ id: cjm?.cjmId, name: cjm?.name }));
};
