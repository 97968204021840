import { EMPTY_GUID } from "@utils/helpers";
import { useSelector } from "react-redux";
import DataLoader from "shared/components/data-loader";
import { CreateLibraryItemCanvas } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { LibraryTemplate } from "../components/LibraryTemplate";
import TouchPointForm from "../components/TouchPoints/TouchPointForm";
import { TouchpointCard } from "../components/TouchPoints/TouchpointCard";
import { createCustomChannel, touchPointCategoriesInit, touchPointInit } from "../reducer/LibraryActions";

const TouchPoints = () => {
	const touchPoints = useSelector((state) => state.libraries.touchPoint);
	const touchpointCategories = [{ name: "ALL", id: EMPTY_GUID }, ...(useSelector((state) => state.libraries.touchPointCategories) || [])];
	const createCustom = (newChannelName, category, weight, imageExtension, imageBody64) =>
		createCustomChannel({ newChannelName, category, weight, imageExtension, imageBody64, onSuccess: () => hideCreateModal() });

	const [showCreateModal, hideCreateModal] = useModal(CreateLibraryItemCanvas, {
		title: "ADD_NEW_TOUCHPOINT",
		displayForm: () => <TouchPointForm type="touchPoint" data={touchPoints} mode="create" onCreate={createCustom} />,
	});

	const displayLibraryItem = ({ item }) => {
		return <TouchpointCard key={item.id} touchPoint={item} setFocus={item.setFocus} />;
	};

	return (
		<DataLoader reduxActions={[touchPointInit, touchPointCategoriesInit]}>
			<LibraryTemplate
				title="TOUCHPOINTS"
				addBtnText="NEW_TOUCHPOINT"
				items={touchPoints}
				showCreateModal={showCreateModal}
				categories={touchpointCategories}
				displayLibraryItem={displayLibraryItem}
			/>
		</DataLoader>
	);
};

TouchPoints.displayName = "TouchPoints";
export default TouchPoints;
