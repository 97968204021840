import styled from "styled-components";
import "./spinner.scss";

export function Spinner({ full, isFixed = true }) {
	return (
		<Loader isFixed={isFixed} full={full}>
			<div className="d-flex justify-content-center align-items-center flex-column">
				<section className="dots-container d-flex">
					<div className="dot "></div>
					<div className="dot dot2"></div>
					<div className="dot dot3"></div>
				</section>
			</div>
		</Loader>
	);
}

export function SmallSpinner() {
	return (
		<div className="spinner-border spinner-border-sm text-light" role="status">
			<span className="sr-only"></span>
		</div>
	);
}
export function Saving({ isSaving }) {
	return (
		<>
			{isSaving ? (
				<span className="saving">
					Saving<span>.</span>
					<span>.</span>
					<span>.</span>
				</span>
			) : (
				<div className="saved">Saved</div>
			)}
		</>
	);
}

export function DotSpinner() {
	return (
		<section className="dots-container d-flex">
			<div className="dot"></div>
			<div className="dot"></div>
			<div className="dot"></div>
		</section>
	);
}
export const JumpDotSpinner = () => {
	return (
		<div className="position-relative">
			<div className="loader-dots">
				<div className="box-dots"></div>
				<div className="box-dots"></div>
				<div className="box-dots"></div>
			</div>
		</div>
	);
};

const Loader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	${(props) =>
		props.full &&
		`
      background-color: #FFFFFF;
    `}
	#placeholder {
		font-size: 20px;
		font-family: "Inter";
	}
	${(props) =>
		props.isFixed === false &&
		`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

export const ProgressBarSpinner = ({ delay = 18, placeholder1, placeholder2 }) => {
	return (
		<ProgressBar delay={delay}>
			<div className="loader-progress"></div>
			<div className="placeholder-ai">
				<div>{placeholder1}</div>
				<div>{placeholder2}</div>
			</div>
		</ProgressBar>
	);
};

const ProgressBar = styled.div`
	display: flex;
	justify-content: center;

	.loader-progress {
		width: 60%;
		height: 4px;
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.2);
		position: absolute;
	}

	.loader-progress::before {
		content: "";
		position: absolute;
		background: linear-gradient(45deg, #f96209, #f8ad48, #18aead, #60c1ef, #3f3d56);
		width: 0%;
		height: 100%;
		border-radius: 2px;
		animation: load ${(props) => props.delay}s ease-in-out forwards;
		box-shadow: #f96209 0px 2px 29px 0px;
	}

	.placeholder-ai {
		position: absolute;
		transform: translate(10px, 10px);
		padding: 1rem;
		text-align: center;

		div {
			font-size: 12px;
			font-family: "Inter";
		}
	}
	@keyframes load {
		10% {
			width: 10%;
		}
		20% {
			width: 20%;
		}
		30% {
			width: 30%;
		}
		40% {
			width: 40%;
		}
		50% {
			width: 50%;
		}
		60% {
			width: 60%;
		}
		70% {
			width: 70%;
		}
		80% {
			width: 80%;
		}
		90% {
			width: 90%;
		}
		100% {
			width: 100%;
		}
	}
`;
