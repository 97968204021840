import { Col } from "reactstrap";
import { ResponsiveContainer } from "recharts";
import styled from "styled-components";

export const DashboardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: scroll;
`;

export const CustomResponsiveContainer = styled(ResponsiveContainer)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TopSection = styled(Col)`
	background-color: #fff;
	background: #fff;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	display: flex;
	justify-content: space-between;
`;

export const LegendText = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.125rem */
`;

export const CustomTooltip = styled.div`
	display: flex;
	justify-content: center;
	border: 1px solid #ccc;
	min-width: 120px;
	font-family: "Inter";
	font-size: 14px;
`;

export const ColorCircle = styled.div`
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 6.25rem;
	border: 1px solid ${(props) => props.color};
	background: ${(props) => props.color};
`;

export const TickStyle = {
	fontSize: "14px",
	fontFamilly: "Inter",
	color: "#605F60",
};

export const RelativeWrapper = styled.div`
	position: relative;
	min-height: 800px;
`;
