import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index.js";
import styled from "styled-components";
import * as Style from "../Style.js";

export default function AnalyticsWidget() {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Style.WidgetContainer className="shadow-sm" background="#f4f3ff">
			<IconContainer>{IconDispatcher("WIDGET_ANALYTIC")}</IconContainer>
			<Body>
				<Style.WidgetTitle>{t("REPORTS")}</Style.WidgetTitle>
				<Style.WidgetDescription>{t("ALL_DATA_IN_ONE_PLACE")}</Style.WidgetDescription>
				<Footer>
					<GenericButton variant="dark-outline" size="s" onClick={() => history.push(`/analytics?tab=journeytrends`)}>
						{t("EXPLORE")}
                    </GenericButton>
				</Footer>
			</Body>
		</Style.WidgetContainer>
	);
}

const IconContainer = styled.div`
	width: 65px;
	height: 56px;
	position: absolute;
	top: -27px;
	left: 20px;
	display: flex;
`;

const Body = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
`;

const Footer = styled.div`
	margin-top: 4px;
`;
