import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro'
import { IconDispatcher } from 'shared/ui-basics/index';
import { Logout as ActionLogout } from '../reducer/AuthActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Logout = (props) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const query = new URLSearchParams(props.location.search)
    const force = query.get('force')=== 'true'
    const auto = query.get('auto')=== 'true'
    useEffect(()=>{
        dispatch(ActionLogout())
    },[dispatch])
    return (
        <div className='full-screen d-flex flex-column'>
            <WrapperImg>
                {IconDispatcher("LOG-OUT", 'm-3 mt-0 mb-5')}
            </WrapperImg>
            <CustomContainer>
            {t(auto ? "LOGOUT_AUTO": force ? "LOGOUT_FORCE" :  "LOGOUT_SUCCESS")}
            </CustomContainer>
        </div>)
}
const WrapperImg=styled.div`
svg {
    height:150px;
}`
export const CustomContainer =  styled.div `
    width: 50% !important;
    text-align: center;
    font-size: 20px;
`
export default Logout;
