import { getAllCxMetrics, getAllMapsListData, getAllPersonas, touchPointInit } from "@redux/index";
import { getDashboardData } from "features/analytics/reducer/analyticActions";
import { useLocation } from "react-router-dom";
import DataLoader from "shared/components/data-loader";
import VoCAnalysisReports from "./VoCAnalysisReports";

function VoCAnalysisDashboard() {
	const isExportMode = useLocation().search.hideForPdf();

	return (
		<DataLoader
			asyncFns={[
				{ asyncFn: getAllMapsListData, parameter: { redirectReducer: "analytics" } },
				{ asyncFn: getAllPersonas, parameter: { redirectReducer: "analytics" } },
				{ asyncFn: touchPointInit, parameter: { redirectReducer: "analytics" } },
				{ asyncFn: getAllCxMetrics, parameter: { redirectReducer: "analytics" } },
			]}>
			<DataLoader asyncFns={[{ asyncFn: getDashboardData, parameter: { dashboardCode: "vocAnalysis", isExportMode } }]}>
				<VoCAnalysisReports />
			</DataLoader>
		</DataLoader>
	);
}

VoCAnalysisDashboard.displayName = "VoCAnalysisDashboard";
export default VoCAnalysisDashboard;
