import { getDate } from "@utils/helpers";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import { FlexBetweenAlignCenter, FlexColumn, Input, Label } from "shared/ui-basics/index";
import styled from "styled-components/macro";

function DateRangeFilter({ title, icon, apply }) {
	const { t } = useTranslation();
	const op = useRef();
	const inputRef = useRef(null);
	const selected = useSelector((state) => state.analytics?.currentData?.date) || { startDate: "", endDate: "" };

	const today = new Date();

	const getDefaultDates = (selectedNew) => {
		const newObj = { ...selectedNew };
		if (newObj.startDate === "") {
			const startOfYear = new Date(today.getFullYear(), 0, 1);
			newObj.startDate = startOfYear;
		}
		if (newObj.endDate === "") {
			newObj.endDate = today;
		}
		return newObj;
	};

	const [selectedDates, setSelectedDates] = useState(getDefaultDates(selected));
	const [currentField, setCurrentField] = useState("startDate");

	const handleClear = (e) => {
		apply({ dataAttribute: "date", selectedItems: { startDate: "", endDate: "" } });

		op.current.toggle(e);
	};

	const handleApply = (e) => {
		op.current.toggle(e);
		setSelectedDates(selectedDates);
		apply({ dataAttribute: "date", selectedItems: selectedDates });
	};

	const handleOpen = (e) => {
		op.current.toggle(e);
		setTimeout(() => {
			inputRef?.current?.focus();
		}, 100);
	};

	const formatDate = (originalDate) => {
		const dateObj = getDate(originalDate);

		return `${dateObj?.month} ${dateObj?.day} ${dateObj?.year}`;
	};

	const displayFilterTitle = () => {
		let result = "";
		if (selected?.startDate !== "") {
			const dateObj = getDate(selected?.startDate);
			result = `${dateObj?.month} ${dateObj?.day} ${dateObj?.year}`;
		} else return t(title);

		if (selected?.endDate) {
			const dateObj = getDate(selected?.endDate);
			result += ` - ${dateObj?.month} ${dateObj?.day} ${dateObj?.year}`;
		}

		return result;
	};

	return (
		<Col>
			<GenericButton variant="light" icon={icon} iconClassName="me-2" onClick={handleOpen}>
				<div>{displayFilterTitle()}</div>
			</GenericButton>
			<CustomOverlayPanel ref={op}>
				<DropdownContainer>
					<Body className="p-2">
						<FlexBetweenAlignCenter className="mb-2" gap="20">
							<FlexColumn>
								<Label size="14">{t("START_DATE")}</Label>
								<Input innerRef={inputRef} variant="default" value={formatDate(selectedDates?.startDate)} onClick={() => setCurrentField("startDate")} onChange={() => {}} readOnly />
							</FlexColumn>
							<FlexColumn>
								<Label size="14">{t("END_DATE")}</Label>
								<Input variant="default" value={formatDate(selectedDates?.endDate)} onClick={() => setCurrentField("endDate")} onChange={() => {}} readOnly />
							</FlexColumn>
						</FlexBetweenAlignCenter>
						{currentField === "startDate" && (
							<Calendar value={selectedDates?.startDate} onChange={(e) => setSelectedDates({ ...selectedDates, startDate: e.value })} dateFormat="dd/mm/yy" inline maxDate={today} />
						)}
						{currentField === "endDate" && (
							<Calendar
								value={selectedDates?.endDate}
								onChange={(e) => setSelectedDates({ ...selectedDates, endDate: e.value })}
								dateFormat="dd/mm/yy"
								inline
								minDate={selectedDates?.startDate}
							/>
						)}
					</Body>
					<Footer>
						<FlexBetweenAlignCenter className="p-2">
							<GenericButton variant="light-link" onClick={handleClear}>
								{t("CLEAR_FILTERS")}
							</GenericButton>
							<GenericButton variant="primary" onClick={handleApply}>
								{t("APPLY")}
							</GenericButton>
						</FlexBetweenAlignCenter>
					</Footer>
				</DropdownContainer>
			</CustomOverlayPanel>
		</Col>
	);
}

DateRangeFilter.displayName = "DateRangeFilter";
export default DateRangeFilter;

const CustomOverlayPanel = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0px;
	}

	.p-overlaypanel-close {
		background: #f96209;
		color: #ffffff;
		width: 1.5rem;
		height: 1.5rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		position: absolute;
		top: -12px;
		right: -12px;
	}
`;

const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
`;

const Footer = styled.div`
	border-top: 1px solid #c4c4c4;
`;
