import { StatusCode } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, encode, getAllVersionsSorted, getMostFrequentColorCode, hasAttribute, widthValidation } from "@utils/helpers";
import { get } from "lodash";
import { createSelector } from "reselect";
import { currentWorkspaceId } from "index";
const getWidthValidation = (item) => widthValidation(item.width);

export const getAllPersonas = (state) => state.personas.all_personas;
export const getCjmPersonasInteractions = (state) => state.maps.current_map?.cjmPersonasInteractions;
export const getCjmContactData = (state) => state.maps.current_map?.cjmContactData;

export const getAllTouchpoints = (state) => state.libraries.touchPoint;
const getCjmComments = (state) => state.maps.current_map.cjmComments;
const getStageData = (state) => state.maps.current_map?.cjmStagesData;
export const getPhasesData = (state) => state.maps.current_map?.cjmPhasesData;

export const personaDataSelector = (personaId) => {
	return createSelector([getAllPersonas], (allPersonas) => allPersonas.find((p) => p.personaId === personaId));
};
export const getInteractionSelector = (personaId, stageId, touchpointId) => {
	return createSelector([getCjmPersonasInteractions], (cjmPersonasInteractions) => cjmPersonasInteractions?.[personaId]?.[stageId]?.[touchpointId]);
};
export const getCurrentInteractionSelector = (personaId, stageId, touchpointId, interactionId) => {
	return createSelector([getCjmPersonasInteractions], (cjmPersonasInteractions) => cjmPersonasInteractions?.[personaId]?.[stageId]?.[touchpointId]?.find((int) => int.id === interactionId));
};
export const makeGetCjmContactData = (personaIdStr, stageId, touchpointId) => {
	if (touchpointId !== EMPTY_GUID) {
		return createSelector([getCjmContactData], (cjmContactData) => cjmContactData?.[personaIdStr]?.[stageId]?.[touchpointId] ?? []);
	}
	return createSelector([getCjmContactData], (cjmContactData) => cjmContactData?.[personaIdStr]?.[stageId] ?? []);
};

export const makeGetTouchpoint = (touchpointId) => {
	return createSelector([getAllTouchpoints], (touchPoints) => touchPoints?.find((touchPoint) => touchPoint.id === touchpointId));
};
export const makeFindStageIndex = (stageId) => {
	return createSelector([getStageData], (stages) => stages?.findIndex((stage) => stage.id === stageId));
};
export const getStage = (stageId) => {
	return createSelector([getStageData], (stages) => stages?.find((stage) => stage.id === stageId));
};
export const getPhase = (phaseId) => {
	return createSelector([getPhasesData], (phases) => phases?.find((phase) => phase.id === phaseId));
};
export const getPhaseByStageId = (stageId) => {
	return createSelector([getStageData, getPhasesData], (stages, phases) => {
		const stage = stages?.find((stage) => stage.id === stageId);
		return phases?.find((phase) => phase.id === stage?.phase.id);
	});
};

export const getShowContactsSentimentLevel = (state) => state.maps.current_map.showContactsSentimentLevel;
export const getShowPersonaSentimentLevel = (state) => state.maps.current_map.showPersonaSentimentLevel;
export const cjmPersonasData = (state) => state.maps.current_map?.cjmPersonasData;
export const getToken = (state) => state.auth.token;
export const mapCurrentUser = (state) => state.maps.currentUser;
export const getMapLegend = (state) => state.maps.current_map?.mapLegend || [];
export const getCjmStagesData = (state) => state.maps.current_map?.cjmStagesData;
export const getCjmPersonasData = (state) => state.maps.current_map?.cjmPersonasData;
export const getChart = (state) => state.maps.current_map?.chart || {};
export const getTargetChart = (state) => state.maps.current_map.chart?.feelingPoints_Target || [];
export const getShowTargetValues = (state) => state.maps.current_map.showTargetValues;
export const getCjmData = (state) => state.maps.current_map?.cjmData;
export const getCjmCxGoals = (state) => state.maps.current_map?.cjmCxGoals || {};
export const getMeasures = (state) => state.libraries?.measures || [];
export const getTimeLines = (state) => state.maps.current_map?.cjmTimeLines || {};
export const getCjmSwimlanes = (state) => state.maps.current_map?.cjmSwimLanes || [];
export const getCjmCustomerFeedback = (state) => state.maps.current_map?.cjmCustomerFeedback || [];
export const getSessionId = (state) => encode(state?.auth?.userInfo?.SessionId || "");
export const gelAllTouchpoints = (state) => state.libraries.touchPoint;

export const allMemoizedTouchpoints = createSelector([gelAllTouchpoints], (touchPoints) => touchPoints);

export const getStageTimeLine = (stageId) => {
	return createSelector([getTimeLines], (timeLines) => timeLines?.[stageId]?.[0] || false);
};
export const makeGetShowCommentBySwimlane = (swimlaneId) => {
	return createSelector([getCjmComments], (cjmComments) => cjmComments?.[swimlaneId] || {});
};
export const memoizedSwimlanes = createSelector([getCjmSwimlanes], (cjmSwimLanes) => cjmSwimLanes);
export const memoizedSwimlanesRelevantCount = createSelector([getCjmSwimlanes], (cjmSwimLanes) => cjmSwimLanes?.filter(swimlane => !["StageHeaders", "PhaseHeaders"].includes(swimlane.id))?.length || 0);

export const getSwimlane = (swimlanekey) => {
	return createSelector([getCjmSwimlanes], (getCjmSwimlanes) => getCjmSwimlanes?.find((swimlane) => swimlane.key === swimlanekey));
};
export const getStageCustomerFeedback = (stageId, personaId) => {
	return createSelector([getCjmCustomerFeedback], (customerFeedback) => customerFeedback?.[personaId]?.[stageId] || false);
};
export const getRelevantPersonas = createSelector([getCjmPersonasData, getAllPersonas], (cjmPersonasData, allPersonas) =>
	cjmPersonasData?.map((person) => allPersonas?.find((persona) => persona.personaId === person.personaId))
);
export const getCjmDataInteractions = createSelector([getCjmData], (cjmData) => cjmData);

export const getTotalWidth = createSelector([getCjmStagesData], (cjmStagesData) => cjmStagesData?.map(getWidthValidation)?.reduce((a, b) => a + b, 0) || 0);

export const makeGetShowComment = (swimlaneKey, stageId, id) => {
	return createSelector([getCjmComments], (cjmComments) => cjmComments?.[swimlaneKey]?.[stageId]?.[id] || false);
};
export const makeGetCjmDataInteraction = (stageId, interactionId) => {
	return createSelector([getCjmData, getCjmStagesData], (cjmData, cjmStagesData) => {
		const stageIndex = cjmStagesData?.findIndex((stage) => stage.id === stageId);
		return cjmData?.[stageIndex]?.find((int) => int.id === interactionId);
	});
};
export const makeGetCjmGoalsFormated = createSelector([getCjmCxGoals, getCjmStagesData], (cjmCxGoals, cjmStagesData) => {
	return cjmStagesData?.map((stage, index) => (!hasAttribute(cjmCxGoals, stage.id) ? [] : cjmCxGoals[stage.id]));
});
export const getMeasureById = (measureId) => {
	return createSelector([getMeasures], (measures) => measures?.find((measure) => measure.id === measureId));
};
export const findCxGoal = (stageId, cxGoalId) => {
	return createSelector([getCjmCxGoals], (cjmCxGoals) => cjmCxGoals?.[stageId]?.find((cxGoal) => cxGoal.id === cxGoalId));
};
export const makeGetCjmDataStageInteractions = (stageId) => {
	return createSelector([getCjmData, getCjmStagesData], (cjmData, cjmStagesData) => {
		const stageIndex = cjmStagesData?.findIndex((stage) => stage.id === stageId);
		return cjmData?.[stageIndex];
	});
};

export const getCurrentMapPersonaIds = createSelector([getCjmPersonasData], (personas) => personas?.map((p) => p.personaId));

// cxPages
export const getAllSolutions = (state) => state.solutions.all_solutions;
export const getAllOpportunities = (state) => state?.opportunity?.all_Opportunities;
export const getAllCxActions = (state) => state.actions.all_actions;

export const getAllMaps = (state) => state.maps.all_maps;
export const getAllPriorities = (state) => state.libraries?.priority;
export const getAllStatus = (state) => state.libraries?.status;
export const getAllLibraries = (state) => state.libraries;
export const getAllProgramsSelector = (state) => state.cxProgram.all_cxPrograms;

export const getAllCxProgramAssessments = (state) => state.cxProgram?.current_program?.cxAssessments;

export const getLibraries = createSelector([getAllLibraries], (libraries) => libraries);

export const FindSolutionById = (solutionId) => {
	return createSelector([getAllSolutions], (allSolutions) => allSolutions?.find((solution) => solution.id === solutionId));
};
export const FindSolutionIndexById = (solutionId) => {
	return createSelector([retrieveAllActiveSolutions], (allSolutions) => allSolutions?.findIndex((solution) => solution.id === solutionId));
};
export const retrieveAllActiveSolutions = createSelector([getAllSolutions], (allSolutions) =>
	allSolutions?.filter((solution) => solution.statusCode === StatusCode.Active && solution.isSample === false)
);
export const FindMapById = (mapId) => {
	return createSelector([getAllMaps], (allMaps) => allMaps?.find((map) => map.cjmId === mapId));
};
export const getAllMapsList = createSelector([getAllMaps], (allMaps) => allMaps);
export const findPriority = (attribute, priorityId) => {
	return createSelector([getAllPriorities], (all_priority) => all_priority?.[attribute]?.find((pt) => pt.id === priorityId));
};

export const FindOpportunityById = (opportunityId) => {
	return createSelector([getAllOpportunities], (allOpportunities) => allOpportunities?.find((opportunity) => opportunity.id === opportunityId));
};
export const FindOpportunityIndexById = (opportunityId) => {
	return createSelector([retrieveAllActiveOpportunities], (allOpportunities) => allOpportunities?.findIndex((opportunity) => opportunity.id === opportunityId));
};
export const retrieveAllActiveOpportunities = createSelector([getAllOpportunities], (allOpportunities) =>
	allOpportunities?.filter((opportunity) => opportunity.statusCode === StatusCode.Active && opportunity.isSample === false)
);
export const FindActionById = (actionId) => {
	return createSelector([getAllCxActions], (allActions) => allActions?.find((action) => action.id === actionId));
};
export const FindActionIndexById = (actionId) => {
	return createSelector([retrieveAllActiveActions], (allActions) => allActions?.findIndex((action) => action.id === actionId));
};
export const retrieveAllActiveActions = createSelector([getAllCxActions], (allActions) => allActions?.filter((action) => action.statusCode === StatusCode.Active && action.isSample === false));
export const FindStatusById = (attribute, statusId) => {
	return createSelector([getAllStatus], (all_status) => all_status?.[attribute]?.find((pt) => pt.id === statusId));
};
export const FindTheMostUseColorCodePhases = createSelector([getPhasesData], (all_phases) => getMostFrequentColorCode(all_phases));
export const FindTheMostUseColorCodeStages = createSelector([getStageData], (all_stages) => getMostFrequentColorCode(all_stages));

export const FindAssessmentById = (id) => {
	return createSelector([getAllCxProgramAssessments], (assessments) => assessments?.find((assessment) => assessment.id === id));
};

// solutiosn

export const getCjmSolutions = (state) => state.maps.current_map.cjmSolutions;
export const getFolders = (state) => state.folders;

export const getFolderById = createSelector(
	(state) => state.folders,
	(_, folderId) => folderId,
	(folders, folderId) => folders?.find((folder) => folder.id === folderId) || {}
);

export const getCurrentProject = (state) => state.projects.current_project || {};

export const projectBusinessCase = createSelector([getCurrentProject], (current_project) => ({
	id: current_project.id,
	authorizedUsers: current_project.authorizedUsers,
	attributesList: current_project.attributesList,
}));

export const projectDetails = createSelector([getCurrentProject], (current_project) => ({
	id: current_project.id,
	projectManagementToolLink: current_project.projectManagementToolLink,
	projectManagementTool: current_project.projectManagementTool,
}));

export const projectFinancialImpact = createSelector([getCurrentProject], (current_project) => ({
	id: current_project.id,
	financialImpact: current_project.financialImpact,
	attributesList: current_project.attributesList,
	currencySymbol: current_project.currencySymbol,
	userProvided: current_project.userProvided,
}));

export const selectLibraryData = createSelector(
	(state) => state.libraries?.customLibraries,
	(_, libraryIndex, globalFilterValue) => ({
		libraryIndex,
		globalFilterValue,
	}),
	(libraries, { libraryIndex, globalFilterValue }) => {
		if (libraries && libraryIndex !== undefined) {
			if (globalFilterValue.trim() === "") {
				// Return all data if globalFilterValue is empty
				return { filteredLibraries: libraries[libraryIndex]?.values };
			}
			// Filter the data based on globalFilterValue
			const filteredLibraries = libraries[libraryIndex]?.values
				.filter((value) => {
					return value.name?.toUpperCase()?.includes(globalFilterValue?.toUpperCase());
				})
				.sortAsc("name")
				?.sort((a, b) => Number(b?.isCustom) - Number(a?.isCustom));
			return { filteredLibraries };
		}

		return { filteredLibraries: [] };
	}
);
//projects

export const getAllProjects = (state) => state.projects.all_projects;
export const getMapVersionsSorted = ({ currentMapId }) => {
	return createSelector([getAllMaps], (allMaps) => {
		const currentMap = allMaps.find((map) => map.cjmId === currentMapId);
		const parentMapId = currentMap?.parentMapId;
		const allVersionsSorted = getAllVersionsSorted({ allMaps, currentMapId, parentMapId });
		return allVersionsSorted;
	});
};
export const getTeamsUsers = createSelector(
	(state) => ({ authorizedUsers: state.settings?.authorizedUsers, users: state.settings?.users }),
	(_, teamUsers) => teamUsers,
	({ authorizedUsers, users }, teamUsers) => {
		return authorizedUsers?.filter((user) => teamUsers?.includes(user.id) && users.find((u) => u.loginName.toLowerCase() === user?.domainName.toLowerCase())?.isActive) || [];
	}
);

export const getAllTags = (state) => state.libraries.tags;
export const getAllGroups = (state) => state.libraries.group;
const selectLibraries = (state) => state.libraries;
const selectUiTableEntity = (state, entity, context) => get(state, ["ui", "table", "entity", entity, context]);
const selectUserSetting = (state, entity) => get(state, ["settings", "userSetting", "savedViewsByEntity", entity]);

export const selectGroup = createSelector([selectLibraries], (libraries) => libraries?.group?.FormatFilterArray());

export const selectTag = createSelector([selectLibraries], (libraries) => libraries?.tags?.FormatFilterArray());
export const selectPriorities = ({ entity }) => createSelector(selectLibraries, (libraries) => libraries?.priority?.[entity]?.FormatFilterArray());
export const selectStatus = ({ entity }) => createSelector(selectLibraries, (libraries) => libraries?.status?.[entity]?.FormatFilterArray());
export const selectSelectedViewId = createSelector([selectUiTableEntity], (uiTableEntity) => uiTableEntity?.selectedViewId);

export const selectQueryView = createSelector([selectUserSetting], (userSetting) => userSetting);

export const getSwimlaneDetailsItems = createSelector(
	(state) => ({
		cjmSwimLaneDetails: state.maps.current_map?.cjmSwimLaneDetails,
		stages: state.maps.current_map?.cjmStagesData,
	}),
	(_, swimlaneKey, att) => ({ swimlaneKey, att }),
	({ cjmSwimLaneDetails, stages }, { swimlaneKey, att }) => {
		const laneDetails = cjmSwimLaneDetails?.[swimlaneKey];
		const stageDetails = stages?.map((stage) => {
			return !hasAttribute(laneDetails, stage.id)
				? []
				: laneDetails[stage.id]?.items.map((item) => {
					return {
						id: item.id,
						stageId: stage.id,
						swimlaneKey: swimlaneKey,
						description: item.description,
						customSwimlaneId: laneDetails[stage.id]?.id,
						[att]: item[att],
						icons: item.icons,
					};
				});
		});
		return stageDetails;
	}
);

export const getInsightById = (id) => {
	return createSelector([getAllInsights], (allInsights) => {
		const insight = allInsights?.find((insight) => insight.id === id);
		return insight;
	});
};

//Insights
export const getAllInsights = (state) => state.insights?.all_insights || [];

export const getInsightPersonaIds = (id) => {
	return createSelector([getAllInsights], (allInsights) => allInsights?.find((insight) => insight.id === id)?.linkedPersonas);
};

//Analytics
export const analyticsOriginalDataSelector = (state) => state.analytics.originalData;

// requests
export const getAllMutations = (state) => state.requests.mutations;
export const getRequestStatus = ({ type }) => createSelector([getAllMutations], (requests) => requests?.[type] || {});

export const getAllQueries = (state) => state.requests.queries;
export const getQueriesStatus = ({ type }) => createSelector([getAllQueries], (requests) => requests?.[type] || {});

//Workspaces
export const getAllWorkspaces = (state) => state?.workspaces?.all_workspaces || [];
export const getAllActiveWorkspaces = (state) => state?.workspaces?.all_workspaces.filter((ws) => ws.statusCode === 1) || [];
export const findWorkspaceById = (id) => {
	return createSelector([getAllWorkspaces], (allWorkspaces) => allWorkspaces?.find((workspace) => workspace.id == id));
};
export const getAllActiveWorkspacesMemoized = createSelector([getAllWorkspaces], (workspaces) => workspaces?.filter((ws) => ws.statusCode === 1))

export const getCurrentWorkspace = createSelector([getAllWorkspaces], (workspaces) => workspaces.find((ws) => ws?.id === currentWorkspaceId))
export const isExistingWorkspaces = createSelector([getAllActiveWorkspaces], (workspaces) => workspaces?.length > 0)

export const getTeamsObject = (state) => state.settings?.teams || {};
export const getTeams = createSelector([getTeamsObject], (teamsDic) => Object.values(teamsDic)?.map((team) => ({ ...team, fullName: team.name })) || []);

export const getCjmInteraction = (id) => {
	return createSelector([getCjmData], (cjmData) => cjmData.flat()?.find((interaction) => interaction.id === id));
};
export const getMemoizedCjmData = createSelector([getCjmData], (cjmData) => cjmData);
export const getMemoizedCjmStagesData = createSelector([getCjmStagesData], (cjmStagesData) => cjmStagesData);

const getMainRoute = (state) => state.maps.current_map.showMainRoute;
const getAlternativeRoute = (state) => state.maps.current_map.showAlternativeRoute;

export const getMainAlternativeRoutes = createSelector([getMainRoute, getAlternativeRoute], (showMainRoute, showAlternativeRoute) => ({ showMainRoute, showAlternativeRoute }));


export const getPendingGetRequestsCount = (state) => {
	const queries = state.requests.queries;
	let pendingCount = 0;

	for (const key in queries) {
		if (queries[key].networkStatus === 'loading' && queries[key].request.method === 'GET') {
			pendingCount++;
		}
	}

	return pendingCount;
};
export const getBottomSwimlanesPosition = (swimlaneKey) => {
	return createSelector([getCjmSwimlanes], (cjmSwimLanes) => {
		const index = cjmSwimLanes?.findIndex((swimlane) => swimlane.key === swimlaneKey)
		return index === (cjmSwimLanes?.length - 3) || index === (cjmSwimLanes?.length - 2) || index === (cjmSwimLanes?.length - 1)

	})
}