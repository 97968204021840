import Moment from "react-moment";
import React from "react";

export const DateComponent = ({
	date,
	format = {
		sameDay: "LT",
		lastDay: "[Yesterday] LT",
		lastWeek: "MMM D LT",
		sameElse: "MMM D LT",
	},
}) => {
	return <Moment calendar={format}>{date}</Moment>;
};
