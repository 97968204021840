/* eslint-disable no-extend-native */
import {
	addGroup,
	addPriority,
	addStatus,
	createInsightType,
	deleteInsightType,
	deleteTag,
	getAllInsightTypes,
	groupInit,
	priorityInit,
	removeGroup,
	removePriority,
	removeStatus,
	saveOrderGroup,
	saveOrderInsightType,
	saveOrderPriority,
	saveOrderStatus,
	saveOrderTags,
	statusInit,
	tagInit,
	updateGroupAttribute,
	updateInsightType,
	updatePriorityAttribute,
	updateStatusAttribute,
	upsertTag,
} from "@redux/index";
import { findWorkspaceById } from "@redux/memoizedSelectors";
import { CxValidation, EMPTY_GUID, encode } from "@utils/helpers";
import Category from "features/customization/components/Category";
import { ColorListItem } from "features/customization/components/ColorListItem";
import { currentWorkspaceId } from "index";
import { useCallback, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { ActionConfirmModal } from "shared/modals/index";
import { PageHeader, PageTitle } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";

export default function CustomizationPage() {
	const { t } = useTranslation();
	const [key, setKey] = useState("status");

	const isStandard = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName) === "Standard";
	const group = useSelector((state) => state.libraries.group);
	const currentWorkspace = useSelector(findWorkspaceById(currentWorkspaceId));

	const handleDeleteGroup = (item) => {
		showModalDelete({
			bodyContent: "GROUP_WARNING_DELETE",
			Action: () => removeGroup({ id: item.id }),
		});
	};
	const [showModalDelete] = useModal(ActionConfirmModal);

	return (
		<DataLoader asyncFns={[{ asyncFn: statusInit, parameter: { showUsedData: true } }]} reduxActions={[priorityInit, groupInit, tagInit, getAllInsightTypes]}>
			<PageTemplate classNames="container pt-5">
				<PageHeader>
					<PageTitle className="col">{`${currentWorkspace?.name} ${t("CUSTOMIZATION")}`}</PageTitle>
				</PageHeader>
				<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="m-3" mountOnEnter={true} unmountOnExit={true}>
						<Tab eventKey="status" title={t("ASPX_222")}>
							<StatusHandler showModalDelete={showModalDelete} />
						</Tab>

					{!isStandard && (
						<Tab eventKey="priority" title={t("PRIORITY")}>
							<PriorityHandler showModalDelete={showModalDelete} />
						</Tab>
					)}

						<Tab eventKey="group" title={t("GROUP")}>
							<ColorListItem
								data={group}
								addFunction={() => addGroup({ newGroup: { name: "" } })}
								deleteFunction={handleDeleteGroup}
								updateFunction={(item) => updateGroupAttribute({ modifiedGroup: { ...item, name: encode(item.name ?? "") }, modifiedAttribNames: [item.update] })}
								params={{
									inputLabel: "GROUP_NAME",
									buttonLabel: "ADD_GROUP",
									deleteTooltip: "ASPX_13",
									fromCustomization: true,
									drag: true,
									updateOrderFunction: (newList) => saveOrderGroup({ ids: newList?.map((el) => el.id)?.join("|") }),
								}}
							/>
						</Tab>

						<Tab eventKey="tag" title={t("TAG")}>
							<TagHandler showModalDelete={showModalDelete} />
						</Tab>

						<Tab eventKey="insight" title={t("INSIGHT_TYPE")}>
							<InsightHandler showModalDelete={showModalDelete} />
						</Tab>
				</Tabs>
			</PageTemplate>
		</DataLoader>
	);
}

const StatusHandler = ({ showModalDelete }) => {
	const [category, setCategory] = useState("JOURNEY_MAP");
	const status = useSelector((state) => state.libraries.status);
	const isStandard = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName) === "Standard";
	const allCategory = ["JOURNEY_MAP", "JS_103", "OPPORTUNITY", "SOLUTION", "PROJECT"].filter((el) => (isStandard ? el === "JOURNEY_MAP" : el));

	const statusDataValidation = useCallback(
		(category) => {
			switch (category) {
				case "JS_103":
					return "cxActions";
				case "SOLUTION":
					return "solutions";
				case "OPPORTUNITY":
					return "opportunity";
				case "JOURNEY_MAP":
					return "customerJourney";
				case "PROJECT":
					return "project";

				default:
					return;
			}
		},
		[]
	);

	const handleDelete = (item) => {
		showModalDelete({
			bodyContent: "STATUS_WARNING_DELETE",
			Action: () => removeStatus({ id: item.id, type: statusDataValidation(category) }),
		});
	};

	return (
		<div className="d-flex ">
			<div>
				<Category category={allCategory} filter={category} setFilter={setCategory} />
			</div>
			<div style={{ paddingLeft: "10px", borderLeft: "1px solid #c4c4c4" }}>
				{status && (
					<ColorListItem
						data={status[statusDataValidation(category)]}
						addFunction={() =>
							addStatus({ newStatus: { name: "", colorCode: "#465b65", CXEntity: CxValidation(statusDataValidation(category)) }, type: statusDataValidation(category) })
						}
						deleteFunction={handleDelete}
						updateFunction={(item) =>
							updateStatusAttribute({ modifiedStatus: { ...item, name: encode(item.name) }, modifiedAttribNames: [item.update], type: statusDataValidation(category) })
						}
						params={{
							isColorOnInput: true,
							isColorEditable: true,
							inputLabel: "STATUS_NAME",
							deleteTooltip: "ASPX_13",
							buttonLabel: "ADD_STATUS",
							drag: true,
							colorsType: "status",
							updateOrderFunction: (newList) => saveOrderStatus({ ids: newList?.map((el) => el.id)?.join("|"), params: statusDataValidation(category) }),
						}}
					/>
				)}
			</div>
		</div>
	);
};

const PriorityHandler = ({ showModalDelete }) => {
	const [category, setCategory] = useState("JS_103");
	const priority = useSelector((state) => state.libraries.priority);

	const priorityDataValidation = useCallback(
		(category) => {
			switch (category) {
				case "JS_103":
					return "cxActions";
				case "SOLUTIONS":
					return "solutions";
				case "OPPORTUNITY":
					return "opportunity";
				case "PROJECT":
					return "project";
				default:
					return;
			}
		},
		[]
	);

	const handleDelete = (item) => {
		showModalDelete({
			bodyContent: "PRIORITY_WARNING_DELETE",
			Action: () => removePriority({ id: item.id, type: priorityDataValidation(category) }),
		});
	};

	return (
		<div className="d-flex ">
			<div>
				<Category category={["JS_103", "OPPORTUNITY", "PROJECT"]} filter={category} setFilter={setCategory} />
			</div>
			<div style={{ paddingLeft: "10px", borderLeft: "1px solid #c4c4c4" }}>
				{priority && (
					<ColorListItem
						data={priority[priorityDataValidation(category)]}
						addFunction={() =>
							addPriority({ newPriority: { name: "", colorCode: "#465b65", CXEntity: CxValidation(priorityDataValidation(category)) }, type: priorityDataValidation(category) })
						}
						deleteFunction={handleDelete}
						updateFunction={(item) =>
							updatePriorityAttribute({ modifiedPriority: { ...item, name: encode(item.name) }, modifiedAttribNames: [item.update], type: priorityDataValidation(category) })
						}
						params={{
							colorsType: "priority",
							isColorEditable: true,
							inputLabel: "PRIORITY_NAME",
							deleteTooltip: "ASPX_13",
							buttonLabel: "ADD_PRIORITY",
							drag: true,
							updateOrderFunction: (newList) => saveOrderPriority({ ids: newList.map((el) => el.id).join("|"), params: priorityDataValidation(category) }),
						}}
					/>
				)}
			</div>
		</div>
	);
};

const TagHandler = ({ showModalDelete }) => {
	const tags = useSelector((state) => state.libraries.tags);

	const handleDelete = (item) => {
		showModalDelete({
			bodyContent: "TAG_WARNING_DELETE",
			Action: () => deleteTag({ tagId: item.id }),
		});
	};

	return (
		<div className="d-flex ">
			<div style={{ paddingLeft: "10px" }}>
				{tags && (
					<ColorListItem
						data={tags}
						addFunction={() => upsertTag({ tag: { id: EMPTY_GUID, name: "", colorCode: "#20A9E9" } })}
						deleteFunction={handleDelete}
						updateFunction={(item) => upsertTag({ tag: item })}
						palette={"palette-priority"}
						params={{
							isColorEditable: true, inputLabel: "TAG_NAME", deleteTooltip: "ASPX_13", buttonLabel: "ADD_TAG", drag: true,
							updateOrderFunction: (newList) => saveOrderTags({ ids: newList.map((el) => el.id).join("|") }),

						}}
					/>
				)}
			</div>
		</div>
	);
};

const InsightHandler = ({ showModalDelete }) => {
	const insightTypes = useSelector((state) => state.libraries.insightTypes);

	const handleDelete = (item) => {
		showModalDelete({
			bodyContent: "TAG_WARNING_DELETE",
			Action: () => {
				const  typeId = localStorage.getItem("lastUsedInsightTypeId");
				if(typeId === item.id) {
					localStorage.removeItem("lastUsedInsightTypeId");
				}
				deleteInsightType({ id: item.id })
			},
		});
	};

	return (
		<div className="d-flex ">
			<div style={{ paddingLeft: "10px" }}>
				{insightTypes && (
					<ColorListItem
						data={insightTypes}
						addFunction={() => createInsightType({ newInsightType: { id: EMPTY_GUID, name: "", colorCode: "#f8c8ae" } })}
						deleteFunction={handleDelete}
						updateFunction={(item) => updateInsightType({ item: item })}
						params={{
							isColorEditable: true,
							inputLabel: "INSIGHT_TYPE_NAME",
							deleteTooltip: "ASPX_13",
							buttonLabel: "ADD_INSIGHT_TYPE",
							drag: true,
							colorsType: "status",
							onlyCustom: true,
							maxChar: 24,
							updateOrderFunction: (newList) => saveOrderInsightType({ ids: newList.map((el) => el.id).join("|") }),
						}}
					/>
				)}
			</div>
		</div>
	);
};
