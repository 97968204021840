import { copyLibrary, deleteLibrary, upsertLibrary } from "@redux/index";
import { getLibraries } from "@redux/memoizedSelectors";
import { EMPTY_GUID, encode } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { PermissionManager } from "@utils/permissionManager";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { NameDescriptionModal } from "shared/modals/basic/NameDescriptionModal";
import { ActionConfirmModal, DuplicateModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";

export const GetLibraryContext = (props) => {
	const { t } = useTranslation();
	const allLibraries = useSelector(getLibraries);
	const libraries = useSelector((state) => state.libraries.customLibraries);
	const loading = useSelector((state) => state.settings.loading);
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const customLibraries = useMemo(() => libraries?.filter((lib) => lib.isCustom)?.sortAsc("name") || [], [libraries]);
	const isStandard = subscriptionPlan === "Standard";
	const librariesData = useMemo(() => getListData(allLibraries, customLibraries, isStandard), [allLibraries, customLibraries, isStandard]);

	const { editAllowed, deleteAllowed } = PermissionManager({});

	const handleAction = ({ item, onClose }) => {
		if (item?.name === "") return toast.info(t("NAME_EMPTY"));

		const isNameExist = libraries?.filter((it) => it?.name?.toUpperCase() === item?.name?.toUpperCase()).length > 0;
		if (isNameExist) {
			return toast.info(t("NAME_EXISTS"));
		}

		item.name = encode(item?.name);
		item.description = encode(item?.description);
		const toastMode = item.id === EMPTY_GUID ? "CREATE" : "UPDATE";
		upsertLibrary({ library: item, onSuccess: onClose, displayToast: toastMode });
	};

	const [showModalComponent] = useModal(NameDescriptionModal, {
		handleAction,
	});

	const showModalEdit = (item) => {
		showModalComponent({
			title: "EDIT_LIBRARY",
			selectedItem: item?.library,
			buttonText: "OK",
		});
	};

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const handleDelete = (lib) => {
		actionConfirmModal({
			bodyContent: "DELETE_LIBRARY",
			Action: () => deleteLibrary({ libraryId: lib?.id }),
			actionBtnText: "ASPX_13",
		});
	};
	const [showCopyModal] = useModal(DuplicateModal);
	const handlecopy = (lib) => {
		showCopyModal({
			title: `${t("JS_61")} ${t("LIBRARY")}`,
			inputTitle: "NAME",
			closeBtnLabel: "COPY",
			inputValue: lib?.name,
			action: ({ name, selectedWorkspaces }) => copyLibrary({ libraryId: lib?.id, name:name, workspaceIds: selectedWorkspaces.map((w) => w?.id) })
		});
	};


	const displayActions = (lib) => {
		if (lib?.isCustom)
			return CreateActionsDropdown({
				actions: [
					editAllowed && {
						handleClick: () => showModalEdit(lib),
						icon: "EDIT",
						text: t("EDIT"),
					},
					editAllowed && {
						handleClick: () => {
							handlecopy(lib);
						},
						icon: "COPY",
						text: t("COPY"),
					},
					deleteAllowed && {
						handleClick: () => handleDelete(lib),
						icon: "DELETE",
						text: t("CS_45"),
					}
				],
				variant: "default",
				dropdownClasses: "",
			});
		else return CreateActionsDropdown({ actions: [], dropdownClasses: "d-none" });
	};

	const data = useMemo(() => {
		const newData = {
			libraryList: {
				visibleHeader: true,
				visibleButtonCreate: !isStandard,
				columns: ["icon", "name", "numberOfRecords", "description", "actions"],
				showCheckbox: false,
				emptyListText: "ASPX_214",
				bulkActions: false,
				disableFixHeader: true,
				showPagination: true,
			},
		};
		const specificContext = props.context.split(".")[1];
		const contextData = _.get(newData, specificContext);
		contextData.listData = librariesData;
		contextData.Actions = (lib) => displayActions(lib);
		contextData.loading = loading;
		contextData.buttonCreateLabel = "NEW_LIBRARY";
		contextData.buttonCreate = () =>
			showModalComponent({
				title: "NEW_LIBRARY",
				buttonText: "OK",
			});
		contextData.displayView = false;
		contextData.displayExport = false;
		contextData.entityType = EntityList.Global;

		return contextData;
	}, [loading, customLibraries]);

	return data;
};

const getListData = (allLibraries, customLibraries, isStandard) => {
	const data = [
		{
			name: "Touchpoints",
			id: EMPTY_GUID,
			description: "TOUCHPOINTS_SWIMLANES_DESCRIPTION",
			reducerAtt: "touchPoint",
			url: "touchpoints",
			icon: "LIB-TOUCHPOINTS",
			nRecords: allLibraries?.touchPoint?.length,
		},
		{ name: "Stickers", id: EMPTY_GUID, description: "STICKERS_DESCRIPTION", reducerAtt: "icons", url: "stickers", icon: "LIB-STICKERS", nRecords: allLibraries?.icons?.length },
		{
			name: "Systems",
			description: "SYSTEMS_SWIMLANES_DESCRIPTION",
			url: "custom",
			id: "systems",
			icon: "LIB-SYSTEMS",
			nRecords: allLibraries?.customLibraries?.find((lib) => lib?.name === "Systems" && !lib?.isCustom)?.values?.length,
		},
		{
			name: "Departments",
			description: "DEPARTMENTS_SWIMLANES_DESCRIPTION",
			url: "custom",
			id: "departments",
			icon: "LIB-DEPARTMENTS",
			nRecords: allLibraries?.customLibraries?.find((lib) => lib?.name === "Departments" && !lib?.isCustom)?.values?.length,
		},
	];

	if (!isStandard) {
		data.push({
			name: "Brand Values",
			id: EMPTY_GUID,
			description: "BRAND_VALUES_DESCRIPTION",
			reducerAtt: "brandValues",
			url: "brandvalues",
			icon: "LIB-BRANDVALUES",
			nRecords: allLibraries?.brandValues?.length,
		});
		data.push({ name: "Measures", id: EMPTY_GUID, description: "MEASURES_DESCRIPTION", reducerAtt: "measures", url: "measures", icon: "MEASURE", nRecords: allLibraries?.measures?.length });
	}

	customLibraries?.forEach((library) => {
		data.push({ library, name: library.name, url: "custom", id: library.id, icon: "LIB-CUSTOM", description: library?.description, isCustom: true, nRecords: library?.values?.length });
	});
	return data;
};
