import { GenericButton } from "shared/ui-basics/buttons";
import { FlexAlignCenter, FlexBetweenAlignCenter } from "shared/ui-basics/index";
import DisplayStatus from "shared/cx-components/DisplayStatus";
import { Badge,RemoveButton ,BadgeWrapper } from "./PicklistCard_style";

export const ProjectItemCard = ({ index, entity, unlink, openRoute, length, isRemovable }) => {

	return (
		<Badge key={entity?.id} id={entity?.id}>
			<BadgeWrapper index={index} length={length}>
				<div className="ellipsis obj-name px-2" onClick={() => openRoute(entity?.id,"/EditProject")}>
					{entity?.name}
				</div>
				<FlexBetweenAlignCenter className="px-2" minWidth='170px'>
				<DisplayStatus name='project' object={entity} disable size={"sm"} style={{Dropdown:" c-default",border:"false" }}/>
				{isRemovable && (
				<RemoveButton>
					<GenericButton variant="light-link" id={entity?.id} icon="X" iconClassName="p-0" onClick={unlink} />
				</RemoveButton>
			)}
				</FlexBetweenAlignCenter>
			</BadgeWrapper>

		</Badge>
	);
};

