import { copyBrandValue, deleteBrandValue } from "@redux/index";
import { getBrandValueIcon } from "@utils/helpers";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { LibraryItemCard } from "../LibraryItemCard";

///
//Brand Value Card can be on library and also on link components
//on library we can delete and edit
//on link comp we can remove and edit
//list brand value we can create a new link (view mood)
//on click  the card can open 2 option of modal or create a new link!!
//on click->add the brand value, showmodal->show modal strategy brand value, openEdit->library edit brand value
///
const BrandValueLibraryCard = ({ context, strategybrandValue, brandValueId, onClick, onDelete, showModal, setFocus}) => {
	const ref = useRef(null);

	const brandValue = useSelector((state) => state.libraries.brandValues)?.find((bv) => bv.id === brandValueId);
    const addEvent = onClick ? true : false;

	const buildModal = () => {
		showModal({
			strategyBrandValue: strategybrandValue ?? undefined,
			accessDelete: onDelete,
			brandValueId: brandValueId,
		});

		switch (context) {
			case "strategy":
				showModal({
					strategyBrandValue: strategybrandValue ?? undefined,
					accessDelete: onDelete,
                    brandValueId: brandValueId,
                    context: "brandValue",
				});
				break;
			case "library":
				showModal({
					object: brandValue,
					context: "brandValue",
					title: "BRAND_VALUE",
					onDelete,
				});
				break;
			default:
				break;
		}
	};


	useEffect(() => {
	}, [brandValue?.name]);

	//on click->add the brand value, showmodal->show modal strategy brand value, openEdit->library edit brand value
    const eventOnClickBrandValue = addEvent ? onClick : showModal ? buildModal : undefined;


	const onCopy = ({id,name,workspaceIds}) => {
		copyBrandValue({
			brandValueId:id,
			name:name,
			workspaceIds: workspaceIds
		});
	};
	return (
		<LibraryItemCard
			item={{...brandValue,isCustom:true}}
			icon={getBrandValueIcon(brandValue?.number)}
			handleEdit={eventOnClickBrandValue}
			deleteFunction={deleteBrandValue}
			deleteText="DELETE_BRAND_VALUE"
			ref={ref}
			setFocus={setFocus}
			copyFunction={onCopy}

		/>
	);
};
export default BrandValueLibraryCard;
