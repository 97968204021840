import { constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";
import { createAction } from "@reduxjs/toolkit";
import { encryptPassword } from "../helpers";

export const AUTHENTICATE = "AUTHENTICATE";
export const LOGIN = "LOGIN";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};

const createPostAction = InitActionCreator({ baseUrl: "api/session/", method: "POST" }); // api actions type post
const createPostAdminAction = InitActionCreator({ baseUrl: "api/admin/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/session/", method: "GET" }); // api actions type get

export const Logout = () => createPostAction({ type: "LOGOUT", endPoint: "logout" });
export const force401 = () => createPostAdminAction({ type: "FORCE_401", endPoint: "checkUnauthorized" });
export const setToken = () => createAction("SET_TOKEN");
export const authenticate = (props) => genericService({ ...props, action: createPostAction({ type: AUTHENTICATE, endPoint: "login", data: props?.body }) });
export const getLoginUrl = (props) =>
	genericService({
		...props,
		action: createGetAction({
			type: "GET_LOGIN_URL",
			endPoint: constructUrl("getLoginUrl", {
				envName: props?.envName,
				targetPage: encode(props?.targetPage),
			}),
		}),
	});
export const login = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: LOGIN,
			endPoint: "userLogin",
			data: {
				email: props?.email,
				password: props?.password,
				tgtUrl: props?.tgtUrl,
			},
		}),
	});
export const resetPassword = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESET_PASSWORD",
			endPoint: "userResetPassword",
			data: {
				email: props?.email,
			},
		}),
	});

export const changePassword = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CHANGE_PASSWORD",
			endPoint: "changePassword",
			data: {
				encryptedPassword: encryptPassword(props.password),
			},
		}),
		displayToast: "UPDATE",
	});
