import { constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta, ...props }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
		...props,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/solution/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/solution/", method: "GET" }); // api actions type get

export const getAllSolutions = () => createGetAction({ type: "GET_ALL_SOLUTIONS", endPoint: "getAll" });

export const createSolution = ({ item, cxProgramId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_SOLUTION",
			endPoint: "create",
			data: {
				newSolutionParams: item,
				linkToCxProgramIdStr: cxProgramId,
			},
		}),
	});

export const copySolution = ({ id, name, workspaceIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_SOLUTION",
			endPoint: "copy",
			data: {
				srcSolutionId: id,
				newSubject: encode(name),
				workspaceIds,
			},
		}),
		displayToast: "COPY",
	});

export const updateSolution = ({ modifiedSolution, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_SOLUTION",
			endPoint: "update",
			data: {
				modifiedSolution,
				modifiedAttribNames,
			},
		}),
	});

export const updateMultiSolutions = ({ modifiedSolutions, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_MULTI_SOLUTION",
			endPoint: "updateMulti",
			data: {
				modifiedSolutions: modifiedSolutions,
				modifiedAttribNames: modifiedAttribNames,
			},
		}),
		displayToast: "UPDATE",
	});

export const archiveSolution = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_SOLUTION",
			endPoint: "archive",
			data: {
				id,
			},
		}),
		displayToast: "ARCHIVE",
	});

export const archiveManySolutions = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_MANY_SOLUTIONS",
			endPoint: "archiveMany",
			data: {
				ids,
			},
		}),
	});

export const deleteSolution = ({ id, solution, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_SOLUTION",
			endPoint: "delete",
			data: {
				id,
			},
			params: solution,
		}),
		displayToast: "DELETE",
	});

export const deleteManySolutions = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_SOLUTIONS",
			endPoint: "deleteMany",
			data: {
				ids,
			},
		}),
	});

export const deleteSolutionPermanently = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_SOLUTION_PERMANENT",
			endPoint: "deletePermanently",
			data: {
				id,
			},
		}),
		displayToast: "DELETE",
	});

export const deleteManySolutionsPermanently = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_SOLUTIONS_PERMANENT",
			endPoint: "deletePermanentlyMany",
			data: {
				ids,
			},
		}),
	});

export const restoreSolution = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_SOLUTION",
			endPoint: "restore",
			data: {
				id,
			},
		}),
		displayToast: "RESTORE",
	});

export const restoreManySolutions = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_MANY_SOLUTIONS",
			endPoint: "restoreMany",
			data: {
				ids,
			},
		}),
		displayToast: "RESTORE",
	});

export const getExcelExportListSol = (ids, columns) =>
	createPostAction({
		type: "GET_EXCEL_SOL",
		endPoint: "getExcelExport",
		data: {
			ids: ids,
			columns: columns?.join("|"),
		},
		responseType: "blob",
	});
export const getPdfExportSol = (width, height, mapIds, statusIds, groupIds) =>
	createGetAction({
		type: "GET_PDF_SOL",
		endPoint: constructUrl("getPdfExport", {
			width,
			height,
			mapIds,
			statusIds,
			groupIds,
		}),
		responseType: "blob",
	});

export const updateOrderSolution = (newOrder) => ({
	type: "UPDATE_ORDER_SOLUTION",
	payload: {
		newOrder,
	},
});

export const updateStageSolutionOrder = ({ stageId, cjmId, newOrder, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_STAGE_SOLUTION_ORDER",
			endPoint: "updateStageSolutionsOrder",
			data: {
				stageId: stageId,
				cjmId: cjmId,
				newSolutionsOrder: newOrder,
			},
		}),
	});
