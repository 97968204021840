import { GenericButton } from "shared/ui-basics/index";
import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { addInteraction } from "features/index";

 const CopyInteractionModal = ({ isOpen, onClose, interaction }) => {
	const { t } = useTranslation();

	const { cjmIdStr, personaIdStr } = useLocation().search.getAllMapsParams();
	const stages = useSelector((state) => state.maps.current_map.cjmStagesData);

	const [selectedStage, setSelectedStage] = useState([]);

	const handleClick = (e) => {
		const id = e.currentTarget.id;
		if (selectedStage.includes(id)) {
			setSelectedStage(selectedStage.filter((item) => item !== id));
		} else {
			setSelectedStage([...selectedStage, id]);
		}
	};

	const Save = () => {
		const id = toast("Saving...", { autoClose: false });

		selectedStage.forEach((stageId, i) => {
			const copyInteraction = JSON.parse(JSON.stringify(interaction));
			copyInteraction.stageId = stageId;
			const lastElement = i == selectedStage.length - 1;
			addInteraction({ interaction: copyInteraction, cjmIdStr, personaIdStr, lastElement, toastId: id });
		});

		onClose();
	};

	return (
		<ModalWindowMedium isOpen={isOpen} fade={false} centered={true} toggle={onClose} size="lg">
			<ModalHeaderSmallTitle toggle={onClose}>
				<div>{t("COPY_INTERACTION")}</div>
			</ModalHeaderSmallTitle>
			<ModalBody className="d-flex justify-content-center flex-column px-5">
				<div className="text-muted mb-4">{t("COPY_INTERACTION_TITLE")}</div>
				{stages.map((stage, index) => (
					<StageButton key={stage.id} active={selectedStage.includes(stage.id)} id={stage.id} className="ellipsis" onClick={handleClick}>
						<Input type="checkbox" className="mx-2" checked={selectedStage.includes(stage.id)} readOnly />
						{stage.name === "" ? "New Stage" : stage.name}
					</StageButton>
				))}
			</ModalBody>
			<ModalFooter>
				<GenericButton variant="primary" className="h-25" onClick={Save}>
					{t("ASPX_83")}
				</GenericButton>
			</ModalFooter>
		</ModalWindowMedium>
	);
};

export default CopyInteractionModal;
const ModalWindowMedium = styled(Modal)`
	width: 40%;
`;
const ModalHeaderSmallTitle = styled(ModalHeader)`
	font-weight: normal;
`;
const StageButton = styled.div`
	margin: 5px;
	min-height: 40px;
	width: 75%;
	align-self: center;
	border-radius: 2px;
	font-weight: 500;
	text-align: start;
	padding: 0px 10px;
	display: flex;
	align-items: center;
	justify-content: start;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	.form-check-input:checked {
		background-color: #333333;
		border-color: #333333;
	}
	.form-check-input:focus {
		box-shadow: none;
	}
	.form-check-input {
		width: 16px;
		height: 16px;
		margin: 0;
		padding: 0;
		border-radius: 4px;
		background-color: #fff;
		border: 1px solid #c4c4c4;
		cursor: pointer;
	}
	&:hover {
		opacity: 0.8;
	}
	${(props) =>
		props.active &&
		css`
			border: 1px solid #f96209;
			background: #fff;
		`}
	${(props) => (props.disabled ? "background-color: #f1f1f1; opacity:0.5; cursor: not-allowed" : "cursor: pointer")}
`;
