import { importAll } from "@utils/helpers.js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FlexAlignCenter, GenericButton, IconDispatcher } from "shared/ui-basics/index.js";
import styled from "styled-components";
import * as Style from "../Style.js";

export default function IntegrationWidget() {
	const { t } = useTranslation();
	const history = useHistory();

	const icons = ["sandsiv.png", "monday.png", "survey-monkey.png"];
	const all_integrations = importAll(require.context("@Assets/images/integration", false, /\.(png|jpe?g|svg)$/));

	return (
		<Style.WidgetContainer className="shadow-sm" background="#fff7ed">
			<IconContainer>{IconDispatcher("WIDGET_INTEGRATION")}</IconContainer>
			<Body>
				<Style.WidgetTitle>{t("NEW_CONNECTORS")}</Style.WidgetTitle>
				<FlexAlignCenter>
					{icons.map((icon) => (
						<Style.ConnectorImg key={icon} className="me-3" src={all_integrations[icon]?.default} />
					))}
				</FlexAlignCenter>
				<Footer>
					<GenericButton variant="dark-outline" size="s" onClick={() => history.push(`/integration`)}>
						{t("EXPLORE")}
					</GenericButton>
				</Footer>
			</Body>
		</Style.WidgetContainer>
	);
}

const IconContainer = styled.div`
	width: 65px;
	height: 56px;
	position: absolute;
	top: -27px;
	left: 20px;
	display: flex;
`;

const Body = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
`;

const Footer = styled.div`
	margin-top: 4px;
`;
