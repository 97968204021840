import { createPersona, updateFolderLinks } from '@redux/index';
import { getLanguagebyCode } from '@utils/helpers';
import { StateTypeOption } from '@utils/optionsSets/OptionSets';
import { getPrompt } from 'features/ai/prompts/AI_prompt';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import TextareaAutosize from "react-textarea-autosize";
import { toast } from 'react-toastify';
import { InputGroup, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IconDispatcher } from 'shared/Icons';
import { GenericButton } from 'shared/ui-basics/buttons';
import styled from 'styled-components/macro';
import { askAlex } from '../reducer/ai-actions';

const creationData = ({ pathname, systemLanguage }) => {
    const data = {}
    switch (pathname) {
        case '/personas':
            data.prompt_placeholder = "DESCRIBE_PERSONA_PLACHOLDER";
            data.aiPrompt = getPrompt.PERSONA_CREATION_EXTRACT_DETAILS;
            data.error_message = "ERROR_CREATING_PERSONA";
            data.pendingRoute = '/edit-persona-pending';
            data.createRoute = '/EditPersona?personaIdStr=';
            data.regularRoute = '/personas';
            data.completeFunction = completePersonaDetails;
            data.createFunction = createPersona;
            data.defaultCreationObject = ({ name, profession, age, gender, language = systemLanguage, location, context }) => ({
                name: name,
                occupation: isNullOrUndefined(profession, ""),
                age: isNullOrUndefined(age, ""),
                gender: isNullOrUndefined(gender, 'Other'),
                isFuture: StateTypeOption.CurrentState,
                standardImage: "",
                customImage: "upload",
                imageExtension: "",
                isTemplate: false,
                aiGeneration: true,
                language: language,
                location: isNullOrUndefined(location, ""),
                context: isNullOrUndefined(context, "")
            })

            break;
        default:
            break;

    }
    return data;
}
function PromptAICreation({ isOpen, onClose }) {

    const { t, i18n } = useTranslation();
    const { search, pathname } = useLocation();

    const history = useHistory();
    const [persona_data, setDetails] = useState();
    const folderId = search.getFolderId();
    const [prompt, setPrompt] = useState("");
    const contextData = creationData({ pathname, systemLanguage: getLanguagebyCode(i18n.language)})

    const handleChange = (event) => setPrompt(event.target.value);

    const handleCreate = (details) => {

        const object = contextData?.defaultCreationObject({ ...details });

        const onSuccess = (data) => {
            if (folderId) {
                updateFolderLinks({ folderIds: [folderId], idsToLink: [data.personaId], entityName: "cem_persona", onSuccess: () => history.push(`${contextData?.regularRoute}?folderId=${folderId}`) })

            } else {
                history.push(`${contextData?.createRoute}${data?.personaId}`);
            }
        }
        const onFailed = () => {
            toast.error(t(contextData?.error_message));
            history.push(contextData?.regularRoute);
        }
        contextData?.createFunction({ item: { ...object }, onSuccess, onFailed });

        history.push(contextData?.pendingRoute);
        onClose()

    }
    const textAnalysis = ({ callbackvalidation }) => {
        if (prompt.isEmptyString()) return
        const alexPrompt = contextData?.aiPrompt({ prompt })
        const onSuccess = (data) => {
            const rs = typeof data === 'object' ? data :  extractInfo(data)

            setDetails({ prompt, details: rs })
            if(callbackvalidation) {
                callbackvalidation(rs)
            }

        }
        askAlex({ prompt: alexPrompt, max_tokens: 100, onSuccess })
    }

    const handleEmptyFields = (rs) => {
        const generatedDetails = rs ? rs : persona_data?.details
        const newDetails = contextData?.completeFunction({...generatedDetails, language: getLanguagebyCode(i18n.language)})
        setDetails({ prompt, details: newDetails })

        if (!newDetails?.name?.isEmptyString()) {
            handleCreate({ ...newDetails ,language:getLanguagebyCode(i18n.language)})
        }
    }

    const onSubmit = () => {
        if (prompt.isEmptyString()) return toast.info(t("EMPTY_PROMPT"))
            textAnalysis({ callbackvalidation: handleEmptyFields })
        onClose()
    }


    return (
        <CustomModal isOpen={isOpen} fade={false} centered={true} toggle={onClose}>
                <Header toggle={onClose} tag="h5" >{t('GENERATE_AI_PERSONA')}</Header>
                <ModalBody className='m-3 p-0'>
                    <IconGroup icon='SPARKLES'>
                        <TextareaAutosize
                            className='align-content-center'
                            minRows={2}
                            maxRows={3}
                            value={prompt}
                            onChange={handleChange}
                            placeholder={t("DESCRIBE_PERSONA_PLACHOLDER")}
                            onBlur={() => textAnalysis({ prompt })}

                        />
                    </IconGroup>
                </ModalBody>
                <ModalFooter className='mt-2'>

                        <GenericButton variant='primary' icon={"SPARKLES"} iconClassName='me-1' onClick={onSubmit}>
                            {t("GENERATE")}
                        </GenericButton>

                </ModalFooter>
                <Text>{IconDispatcher('INFO', 'me-1')}{t("AI_DATA_SECURE")}</Text>
        </CustomModal>
    )

}

const IconGroup = ({ icon, children }) => {
    return (
        <InputGroup>
            <InputGroupText style={{ color: '#c4c4c4' }}>
                {IconDispatcher(icon)}
            </InputGroupText>
            {children}
        </InputGroup>
    )
}

function extractInfo(response) {

    const res1 =  response?.split("{")[1]
    const res = res1?.split("}")?.[0]
    if (res?.isEmptyString()) return
    if(typeof res === 'string') {
        try {
            return JSON.parse("{" + res+ "}")
        }catch
        {
            return res
        }
    }
    return res
}

const isNullOrUndefined = (value, returnValue) => {
    if (value === 'Unknown' || value === null || value === undefined) return returnValue
    return value
}


const completePersonaDetails = ({ name, gender, age, profession, location, language,context }) => {
    const names = [
        "Alex Johnson", "Jamie Smith", "Pat Taylor", "Jordan Lee", "Casey Kim",
        "Morgan Bailey", "Taylor Morgan", "Chris Parker", "Dana Reynolds", "Riley Brooks",
        "Sam Jordan", "Cameron Diaz", "Robin Avery", "Drew Austin", "Jesse Carson",
        "Quinn Harper", "Skyler Grey", "Avery Quinn", "Reese Kendall", "Peyton Sawyer",
        "Charlie Lane", "Emerson Blake", "Jordan Casey", "Kai Rivera", "Dakota Ellis",
        "Sage Finley", "Rowan Murphy", "Harper Lee", "Finley Sage", "Alexis Stone"
    ];

    const professions = [
        "Accountant", "Software Engineer", "Designer", "Customer Success Manager", "Environmental Scientist",
        "Marketing Specialist", "Data Analyst", "Nurse", "Architect", "Teacher",
        "Content Writer", "HR Manager", "Electrical Engineer", "Chef", "Photographer",
        "Civil Engineer", "Sales Executive", "Graphic Designer", "Pharmacist", "Mechanical Engineer",
        "Journalist", "Web Developer", "Psychologist", "Real Estate Agent", "Dietitian",
        "Biologist", "Veterinarian", "Physiotherapist", "Financial Advisor", "UI/UX Designer",
        "Public Relations Specialist", "Social Worker", "Project Manager", "Art Director", "Event Planner",
        "SEO Specialist", "Aerospace Engineer", "Fashion Designer", "Film Director", "Game Developer"
    ];

    const ages = [
        "18", "20", "22", "25", "28",
        "30", "33", "35", "38", "40",
        "42", "45", "48", "50", "55", "60", "65", "70", "75", "80"
    ];
    const genders = ["Man", "Woman", "Other"];
    const getRandomItem = (array) => array[Math.floor(Math.random() * array.length)];
    const randomName = name === "Unknown" ? getRandomItem(names) : name;
    const randomProfession = profession === "Unknown" ? getRandomItem(professions) : profession;
    const randomAge = age === "Unknown" ? getRandomItem(ages) : age;
    const randomGender = gender === "Unknown" ? getRandomItem(genders) : gender;
    const randomLanguage = language === "Unknown" ? "English" : language;
    const randomContext = context === 'Unknown' ? 'Global' : context;
    return {
        name: randomName,
        profession: randomProfession,
        age: randomAge,
        gender: randomGender,
        language: randomLanguage,
        location: location,
        context: randomContext
    };
}



PromptAICreation.displayName = "PromptAICreation";
export default PromptAICreation;

const CustomModal = styled(Modal)`
	width: 650px !important;
    max-width: 650px !important;
    textarea {
		background-color: transparent;
		width:  calc(100% - 42px);
		color: #333333;
		resize: none;
		overflow: hidden;
		border: none;
		padding: 0.5rem;
        border: 1px solid #ced4da;
        border-radius: 5px;
        border-left: 0;
        ::placeholder {
            color: #ced4da;
            font-size: 14px;
            font-style: italic;
        }
	}
.modal-header {
    border: none;
}
.modal-footer {
    border:none;
}
.input-group-text {
    background-color: #ffffff !important;


}
.modal-content {

}

.form-text {
    margin-left: 4px;
    font-size:12px;
    font-style: italic; // Added italic style

    }
`;
const Text = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    border-radius: 4px;
background: #EFEFEF;
color: #605F60;
padding: 8px;
width: 100%;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%;

`
const Header = styled(ModalHeader)`
	background: var(--AI-gradient-bg, linear-gradient(104deg, #FFEEE3 0.73%, #FFF8EE 32.71%, #E8F7FF 66.16%, #F1F0FF 97.16%));

    `