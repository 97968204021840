import { getRequestStatus } from "@redux/memoizedSelectors";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import CustomTooltip from "shared/components/CustomTooltip";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { IconDispatcher } from "shared/Icons";
import { ActionConfirmModal, DuplicateModal } from "shared/modals/index";
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";

const LibraryItemCard = React.forwardRef(({ item, itemType, imageSrc, icon, handleEdit, deleteFunction, deleteText = "DELETE_PERMANENT_GENERIC", setFocus, allowDeleteWithoutWarning, actionType, copyFunction ,label}, ref) => {
	const tooltipRef = useRef(null);
	const { t } = useTranslation();

	const [isEllipsisActive, setIsEllipsisActive] = useState(false);
	const { pending } = useSelector(getRequestStatus({ type: actionType }), shallowEqual);
	const [isUpdating, setIsUpdating] = useState(false);
	const isDeleting = pending === 1 && isUpdating;

	useEffect(() => {
		if (tooltipRef?.current?.offsetWidth < tooltipRef?.current?.scrollWidth) {
			setIsEllipsisActive(true);
		}
	}, [tooltipRef]);

	useEffect(() => {
		if (setFocus) ref?.current?.scrollIntoView();
	}, [setFocus]);
	useEffect(() => {
		if (pending === 0) {
			setIsUpdating(false);
		}
	}, [pending]);

	const checkIsDeleteAllowed = () => {
		if (Object.prototype.hasOwnProperty.call(item, "isCustom")) return item?.isCustom;
		return true;
	};

	const onSuccess = () => {
		hideModalDelete();
	};

	const deleteFunctionWrapper = (e) => {
		if (e instanceof Event) e?.stopPropagation();
		setIsUpdating(true);
		deleteFunction({ id: item?.id, onSuccess });
	};

	const displayImage = () => {
		if (imageSrc) return <img src={imageSrc} loading="lazy" alt="" />;
		if (icon) return IconDispatcher(icon);
		return <CustomShape type={itemType} color={item?.colorCode ? item?.colorCode : "#c3c3c3"} />;
	};

	const [showModalDelete, hideModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: deleteText,
		Action: deleteFunctionWrapper,
		actionBtnText: "ASPX_13",
	});

	const [showCopyModal] = useModal(DuplicateModal, {
		title: `${t("JS_61")} ${t(label)}`,
		inputTitle: "NAME",
		closeBtnLabel: "COPY",
		inputValue: item?.name,
		action: ({ name, selectedWorkspaces }) => copyFunction({ id:item?.id, name, workspaceIds: selectedWorkspaces.map((w) => w?.id) }),
	});
	const displayActions = () => {

		return CreateActionsDropdown({
			actions: [
				{
					id: "copy",
					handleClick: () => {
						showCopyModal();
					},
					icon: "COPY",
					text: t("COPY"),
				},
				{
					id: "delete",
					handleClick: () => {
						allowDeleteWithoutWarning ? deleteFunctionWrapper() : showModalDelete();
					},
					icon: "TRASH",
					text: t("DELETE")
				},
			],
			id: item?.id,
			variant: "default",
			dropdownClasses: "",
			stopPropagation: false,

		});
	};

	return (
		<Card key={item?.id} ref={ref} isdeleting={isDeleting} disabled={false} setfocus={setFocus}>
			<ImageContainer className="p-1"  onClick={handleEdit} >{displayImage()}</ImageContainer>
			<div className="w-100">
				<FlexBetweenAlignCenter>
					<CustomTooltip text={item?.name} disabled={!isEllipsisActive}>
						<NameContainer  onClick={handleEdit} >
							<Name ref={tooltipRef} className="p-1">
								{item?.name}
							</Name>
						</NameContainer>
					</CustomTooltip>
					{checkIsDeleteAllowed() && (
						<ActionsBox id="actions">{displayActions()}</ActionsBox>
					)}
				</FlexBetweenAlignCenter>
			</div>
		</Card>
	);
});
LibraryItemCard.displayName = "LibraryItemCard";
export { LibraryItemCard };

const ActionsBox = styled.div`
display: none;
`;

const Card = styled.div`
	width: 240px;
	height: 36px;
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
	border: 1px solid #c4c4c4;
	cursor: pointer;

	${(props) =>
		(props.disabled || props.isdeleting) &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}

	:hover ${ActionsBox} {
		display: flex;
	}
	${(props) => props.setfocus && `animation: name 2s ease-out;`}
	@keyframes name {
		100% {
			border: 1px solid #f96209;
		}
	}
`;

const ImageContainer = styled.div`
	height: 100%;
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		max-width: 24px;
		max-height: 24px;
	}
`;

const CustomShape = styled.div`
	${(props) =>
		props.type === "system" &&
		css`
			background: ${(props) => props.color};
			width: 24px;
			height: 24px;
			border-radius: 2px;
		`}
	${(props) =>
		props.type === "department" &&
		css`
			background: ${(props) => props.color};
			width: 24px;
			height: 24px;
			border-radius: 14px;
		`}
    ${(props) =>
		props.type === "library" &&
		css`
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: calc(2 * 10px * 0.866) solid ${(props) => props.color};
			border-top: 10px solid transparent;
			display: inline-block;
			margin-bottom: 10px;
		`}
`;

const NameContainer = styled.div`
	display: flex;
	align-items: center;
	border-left: 1px solid #c4c4c4;
	width: 168px;
`;

const Name = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
