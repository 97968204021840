import { calculateXScore, calculateYScore, FilterItemsByStatus, solutionTypeByCoordinates } from "@utils/helpers";
import { SolutionType } from "@utils/optionsSets/OptionSets";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Chart } from "shared/components/charts-components/Chart";
import { Spinner } from "shared/components/spinners/Spinner";
import styled, { css } from "styled-components/macro";
import { BenefitCard } from "../solutionsComponents/BenefitCard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getAllMapsList } from "@redux/memoizedSelectors";

export const SolutionsQuadrant = ({ selectedSolutions, pdfRef, loading, filterParams, baseUnit }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const all_maps = useSelector(getAllMapsList);

	const solutionsData = useSelector(({ solutions }) => solutions.all_solutions.filter((opp) => FilterItemsByStatus(opp, pathname, "/solutions",showSampleData,all_maps)));

	const getFilteredSolutions = () => {
		return solutionsData.filter((solution) => {
			let statusRes = false;
			let mapRes = false;
			let groupRes = false;
			if (filterParams?.statusIds.length === 0 || filterParams.statusIds.includes(solution.statusId)) statusRes = true;
			if (filterParams?.mapIds.length === 0 || filterParams.mapIds.includes(solution.mapId)) mapRes = true;
			if (filterParams?.groupIds.length === 0 || filterParams.groupIds.filter((groupId) => solution?.groups?.includes(groupId))?.length > 0) groupRes = true;

			return statusRes && mapRes && groupRes;
		});
	};

	const actualSolutions = selectedSolutions ? selectedSolutions : getFilteredSolutions();

	const getPositionning = (actualSolutions) => {
		const obj = {};
		actualSolutions.forEach((solution) => {
			const strCoordinates = calculateXScore(solution) + "|" + calculateYScore(solution);
			if (strCoordinates in obj) obj[strCoordinates].push(solution);
			else obj[strCoordinates] = [solution];
		});
		return obj;
	};

	const solutionsByPositionCalc = getPositionning(actualSolutions);
	const solutionsByPosition = Object.keys(solutionsByPositionCalc)
		?.sort((a, b) => (Number(a.split("|")[1]) < Number(b.split("|")[1]) ? 1 : -1))
		.reduce((obj, key) => {
			obj[key] = solutionsByPositionCalc[key];
			return obj;
		}, {});

	const SolutionLabels = () => {
		return (
			<React.Fragment>
				<BenefitCard classes={"px-2"} isQuadrant={true} isActive={true} variant={"quickWin"} icon={"LIKE"} text={"QUICK_WIN"} baseUnit={baseUnit} />
				<BenefitCard classes={"px-2"} isQuadrant={true} isActive={true} variant={"niceToHave"} icon={"STAR-LIFE"} text={"NICE_TO_HAVE"} baseUnit={baseUnit} />
				<BenefitCard classes={"px-2"} isQuadrant={true} isActive={true} variant={"complexProj"} icon={"PUZZLE"} text={"COMPLEX_PROJ"} baseUnit={baseUnit} />
				<BenefitCard classes={"px-2"} isQuadrant={true} isActive={true} variant={"toAvoid"} icon={"ROAD-BARRIER"} text={"TO_AVOID"} baseUnit={baseUnit} />
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			{loading ? (
				<Spinner placeholder={t("LOADING_DATA")} />
			) : (
				solutionsData?.length > 0 && (
					<Chart
						itemsByPosition={solutionsByPosition}
						CustomPoint={SolutionChartPoint}
						CustomLabels={SolutionLabels}
						route={"edit-solution"}
						xTitle={"COST"}
						yTitle={"BENEFIT"}
						scale={[0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20]}
						baseUnit={baseUnit}
						pdfRef={pdfRef}
					/>
				)
			)}
		</React.Fragment>
	);
};

const SolutionChartPoint = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: all 1.5s ease;
	background-color: #c4c4c4;

	:hover {
		cursor: pointer;
	}

	${(props) =>
		solutionTypeByCoordinates(props.xCoordinate, props.yCoordinate) === SolutionType.QuickWin &&
		css`
			background: #dcffff;
			border: 1px solid #18aead;
			color: #18aead;
		`}
	${(props) =>
		solutionTypeByCoordinates(props.xCoordinate, props.yCoordinate) === SolutionType.ComplexProject &&
		css`
			background: #c5c2d4;
			border: 1px solid #333044;
			color: #333044;
		`}
    ${(props) =>
		solutionTypeByCoordinates(props.xCoordinate, props.yCoordinate) === SolutionType.NiceToHave &&
		css`
			background: #d2eefb;
			border: 1px solid #20a9e9;
			color: #20a9e9;
		`}
    ${(props) =>
		solutionTypeByCoordinates(props.xCoordinate, props.yCoordinate) === SolutionType.ToAvoid &&
		css`
			background: #fde7cb;
			border: 1px solid #f48e09;
			color: #f48e09;
		`}

    ${(props) =>
		props.multiItems &&
		css`
			width: 40px;
			height: 40px;
		`}
`;
