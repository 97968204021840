import { GenericButton } from "shared/ui-basics/buttons";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import React from "react";

export const TrainingCard = ({ training, image }) => {
	const { t } = useTranslation();

	function openTab() {
		window.open(training?.url);
    }

    const isDisplayTag = () => [10, 25, 22, 26, 27, 28, 29].includes(training?.id)

	return (
		<CardContainer className="shadow-sm p-0 m-3">
			<Header>
				<ImageContainer>
					<img src={image} loading="lazy" alt="" />
				</ImageContainer>
                {isDisplayTag() && <Tag>{t("ADMIN")}</Tag>}
			</Header>
			<Body className="px-3 pt-3">
				<Title>{training?.name}</Title>
				<Description className="mt-2">{training?.description}</Description>
			</Body>
			<Footer className="p-3">
				<GenericButton variant="primary" onClick={openTab}>
					{t("START_COURSE")}
				</GenericButton>
			</Footer>
		</CardContainer>
	);
};

const CardContainer = styled.div`
	text-decoration: none;
	width: 360px;
	background: #ffffff;
	border-radius: 4px;
`;

const Header = styled.div`
	height: 160px;
	margin: 0px;
	padding: 0px;
    position: relative;
`;

const ImageContainer = styled.div`
	height: 160px;
	img {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		object-fit: cover;
		max-width: 100%;
		width: 100%;
		height: 160px;
	}
`;

const Body = styled.div`
	height: 224px;
	overflow: scroll;
`;

const Footer = styled.div``;

const Title = styled.div`
	min-height: 40px;
	color: #333;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	max-width: max-content;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
    margin-bottom: 1rem;
`;

const Description = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
    line-height: 1.2rem;
`;

const Tag = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 66px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 4px;
	background: #60c1ef;
	color: #fff;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
    position: absolute;
    bottom: 5px;
    left: 5px;
`;
