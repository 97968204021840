import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { updateSwimlaneProps } from "features/index";
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { Switch } from "shared/ui-basics/buttons";
import { filterEmotionMap } from "features/journey-map/reducer/CJMActions";
import { PopoverSubscription } from "../../../shared/popovers/PopoverSubscription";
import ManagePersonaCard from "./ManagePersonaCard";

export function ManageSentimentCard({ personaId, displayLine, isEditable = true, swimlaneKey, lastElement }) {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { cjmIdStr } = useLocation().search.getAllMapsParams();
	const isSubscriptionAllowed =  useSelector(({ auth }) => auth?.userInfo?.UserSubscriptionPlanName.isEnterpriseSubscriptionAllowed());
	const swimLane = useSelector((state) => state.maps.current_map.cjmSwimLanes.find((swimLane) => swimLane.key === swimlaneKey));
	const mapLegend = useSelector((state) => state.maps.current_map?.mapLegend) || [];

	const showPersonaSentiment = swimLane?.sentimentsConfig?.showPersonaSentiment?.[personaId];
	const showCustomerSentiment = isSubscriptionAllowed ? swimLane?.sentimentsConfig?.showCustomerSentiment?.[personaId] : false;
	const showPersona = swimLane?.sentimentsConfig?.showPersona?.[personaId];
	const handleClick = (e) => {
		const name = e.target.name
		let newConfig;
		let type = 'add'// add or remove

		if (name === 'Sentiment') {
			if (showPersonaSentiment) {
				type = 'remove'
			}
			const update = !showPersonaSentiment
			newConfig = { ...swimLane?.sentimentsConfig, showPersonaSentiment: { ...swimLane?.sentimentsConfig?.showPersonaSentiment, [personaId]: update } }
		}
		if (name === 'VOC') {
			if (showCustomerSentiment) {
				type = 'remove'
			}
			const updateVOC = !showCustomerSentiment

			newConfig = { ...swimLane?.sentimentsConfig, showCustomerSentiment: { ...swimLane?.sentimentsConfig?.showCustomerSentiment, [personaId]: updateVOC } }
		}

		const modifiedSwimLane = { ...swimLane, sentimentsConfig: JSON.stringify(newConfig) }
		if (swimLane.type === 100000012) {
			const limitFilter = type === 'add' ? 1 : 2

			mapLegend.length > limitFilter && updateSwimlaneProps({ cjmId: cjmIdStr, modifiedSwimLane, modifiedAttribNames: ["sentimentsconfig"] })

		} else {
			updateSwimlaneProps({ cjmId: cjmIdStr, modifiedSwimLane, modifiedAttribNames: ["sentimentsconfig"] })

		}
		if (swimLane.type === 100000012) {
			dispatch(filterEmotionMap());
		}
	}


	return (
		<RouteCard className="p-2" disabled={!showPersona}>
			<ManagePersonaCard cjmIdStr={cjmIdStr} personaId={personaId} isSelectable={false} isRemovable={false} isViewable={false} displayLine={displayLine} />
			<Wrapper className="p-2">
				<FlexBetweenAlignCenter>
					<Label>{t("JS_148")}</Label>
					<Switch defaultChecked={showPersonaSentiment} name='Sentiment' onChange={handleClick} />
				</FlexBetweenAlignCenter>
				<PopoverSubscription disabled={isSubscriptionAllowed} placement={lastElement ? "top" : "bottom"} access={["Enterprise"]}>
					<FlexBetweenAlignCenter disabled={!isSubscriptionAllowed}>
						<Label className="d-flex">{t("CUSTOMER_SENTIMENT")}</Label>
						<Switch defaultChecked={showCustomerSentiment} name='VOC' onChange={handleClick} />
					</FlexBetweenAlignCenter>
				</PopoverSubscription>

			</Wrapper>
		</RouteCard>
	);
}

const RouteCard = styled.div`
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	${(props) => props.disabled && "opacity: 0.5; pointer-events: none;"}
`;

const Label = styled.span`
	font-weight: 400;
	font-size: 14px;
	color: #605f60;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-top: 1px solid #c4c4c4;
	gap: 10px;
`;
