import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";
import { modes } from "@utils/optionsSets/OptionSets";
import { base } from "index";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GenericButton } from "shared/ui-basics";
import styled from "styled-components";
import WorkspaceLogoAndName from "../others/workspace-logo-and-name";
import { upsertSettingUser } from "@redux/index";

const WorkspaceDropdown = ({ toggleComponent: ToggleComponent, size = "m", isMenuItemDisabled = false,displayMenu=true, specificWorkspaces, nameWidth, getIcon, iconTooltip, showCreateButton }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const envName = useSelector((state) => state?.auth?.userInfo?.EnvName || "");
	const userSetting = useSelector((state) => state.settings.userSetting);
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);

	const currentWorkspaceId = base.split("/")[2];
	const currentWorkspace = allActivesWorkspaces.find((ws) => ws?.id === currentWorkspaceId);
	const filteredWorkspaces = allActivesWorkspaces.filter((ws) => ws.id !== currentWorkspaceId);
	const workspacesToDisplay = specificWorkspaces ? specificWorkspaces?.sortAsc("name") : filteredWorkspaces?.sortAsc("name");

	const [show, setShow] = useState(false);

	const switchWorkspace = (id) => {
		setShow(false);
		upsertSettingUser({ userSetting: { id: userSetting?.id, workspaceId: id }, modifiedAttribNames: ["workspaceId"] });
		window.location.href = `${envName}/${id}/home`;
	};

	const onCreate = () => {
		setShow(false);
		history.push(`/edit-workspace?mode=1`);
	};

	const redirect = ({ workspace }) => {
		setShow(false);
		history.push(`/edit-workspace?id=${workspace.id}&mode=${modes.update}`);
	};
	if(!displayMenu || workspacesToDisplay.length === 0) {
		return <ToggleComponent workspace={currentWorkspace} />;
	}
	return (
		<DropdownWrapper drop="bottom" show={show} onToggle={setShow} autoClose={true}>
			<ToggleComponent workspace={currentWorkspace} />
			<DropdownMenu>
				<Content className="p-2">
					{workspacesToDisplay?.map((workspace) => (
						<WorkspaceLogoAndName
							key={workspace?.id}
							workspace={workspace}
							primaryAction={() => switchWorkspace(workspace?.id)}
							secondaryAction={() => redirect({ workspace })}
							size={size}
							icon={getIcon ? getIcon(workspace) : undefined}
							nameWidth={nameWidth}
							iconTooltip={iconTooltip}
							isDisabled={isMenuItemDisabled}
						/>
					))}
					{showCreateButton && <GenericButton variant="light-link" icon="PLUS" iconClassName="me-1" onClick={onCreate}>{`${t("ASPX_108")} ${t("ASPX_242")}`}</GenericButton>}
				</Content>
			</DropdownMenu>
		</DropdownWrapper>
	);
};

WorkspaceDropdown.displayName = "WorkspaceDropdown";
export default WorkspaceDropdown;

const DropdownWrapper = styled(Dropdown)`
	width: 100%;
	height: 100%;
	margin: 0 !important;
	display: flex;

	.dropdown-toggle:after {
		display: none;
	}

	.dropdown-item:hover {
		visibility: visible;
	}

	.dropdown-menu.show {
		padding: 0 !important;
		box-shadow: 1px 1px 3px rgba(33, 33, 33, 0.3);
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		background-color: #e4e7f1;
	}
`;

const DropdownMenu = styled(Dropdown.Menu)`
	position: absolute;
	top: 54px;
	z-index: 1001;
    max-height: 320px;
    overflow: scroll;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #ffff;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	width: 236px;
`;
