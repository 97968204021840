import { ReactComponent as Unexpected } from '@Assets/All/unexpected.svg';
import { Component } from "react";
import { Col, Container, Row } from 'reactstrap';
import styled from "styled-components/macro";
import { base } from "../..";

const ErrorView = ({ error, errorInfo }) => {

  const redirect = () => {
    window.location.href = `${base}home`;
  }
  return <Wrapper>
    <Row >
      <Col xs={12} className="text-large text-center">Oops... That was unexpected :(</Col>
    </Row>
    <Row className=" d-flex justify-content-center">
      <Col xs={6} className=" py-5 d-flex flex-column justify-content-center align-items-center">
        <Unexpected style={{ width: "200px" }} />
      </Col>
    </Row>

    <Row>
      <Col xs={12} className=" text-large text-center">
        <LinkToHomePage onClick={redirect}  >Go back</LinkToHomePage>
        <span className="ms-1">one minute in time</span>
      </Col>
    </Row>
  </Wrapper>
}
const LinkToHomePage = styled.div`
 text-decoration: underline;
 color:#333333;
 cursor: pointer;
`
const Wrapper = styled(Container)`
min-height:800px;
height:100%;
width:100%;
display:flex;
justify-content:center;
align-self:center;
flex-direction:column;
`
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      // Error path
      return <ErrorView {...{ error, errorInfo }} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export function errorBoundary(WrappedComponent) {
  return class extends ErrorBoundary {
    render() {
      const { error, errorInfo } = this.state;
      if (errorInfo) {
        // Error path
        return <ErrorView {...{ error, errorInfo }} />;
      }
      //Normally, just render wrapped component
      return <WrappedComponent {...this.props} />;
    }
  };
}