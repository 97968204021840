import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components/macro";
import { GenericButton } from "shared/ui-basics/buttons";
import { ModalHeader } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Colors } from "shared/ui-basics/index";
import { FlexAlignCenter } from "shared/ui-basics/index";
import { useDispatch } from "react-redux";

const TrainingModal = memo(({ isOpen, onClose }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [hideTrainingModal, setHideTrainingModal] = useState(false);

	const close = () => {
		dispatch({
			type: "UPDATE_LOGIN_STATUS",
		});
		localStorage.setItem("hideTrainingModal", hideTrainingModal);
		onClose();
	};

	const redirect = () => {
		close();
		history.push(`training`);
	};

	return (
		<>
			<ModalContainer fade={false} isOpen={isOpen} toggle={close} size="sm" centered={true}>
				<Header>
					<GenericButton variant="light-link" icon="X" onClick={close} />
				</Header>
				<Body>
					<Title className="mb-5">{t("ON_DEMAND_TRAINING_NOW_AVALIABLE")}</Title>
					<Description className="mb-2">{t("CHECK_NEW_ELEARNING_SPACE")}</Description>
					<ImageContainer>
						<img src={require(`@Assets/images/training/19.jpg`).default} loading="lazy" alt="" />
					</ImageContainer>
				</Body>
				<Footer>
					<CheckboxWrapper className="me-2">
						<input type="checkbox" defaultChecked={hideTrainingModal} onClick={() => setHideTrainingModal(!hideTrainingModal)} />
						<div className="ms-2">{t("DONT_SHOW_AGAIN")}</div>
					</CheckboxWrapper>
					<FlexAlignCenter gap="10">
						<GenericButton variant="primary-outline" onClick={close}>
							{t("SKIP")}
						</GenericButton>
						<GenericButton variant="primary" onClick={redirect}>
							{t("SEE_ALL_COURSES")}
						</GenericButton>
					</FlexAlignCenter>
				</Footer>
			</ModalContainer>
		</>
	);
});

TrainingModal.displayName = "TrainingModal";

export {TrainingModal}
const ModalContainer = styled(Modal)`
	max-width: 800px;
`;

const Header = styled(ModalHeader)`
	border: none;
	display: flex;
	align-self: flex-end;
	margin: 0px;
	padding: 0px;
`;

const Body = styled(ModalBody)``;

const Title = styled.div`
	color: #000;
	font-family: "Inter";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
`;

const Description = styled.div`
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const ImageContainer = styled.div`
	height: 312px;
	img {
		object-fit: cover;
		max-width: 100%;
		width: 100%;
		height: 312px;
	}
`;

const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;

	input[type="checkbox"] {
		accent-color: #333333;
		width: 15px !important;
		height: 15px !important;
		:hover {
			cursor: pointer;
		}
	}
`;

const Footer = styled(ModalFooter)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
