import { Container } from "reactstrap";
import styled from "styled-components/macro";
import { Spinner } from "./Spinner";

export default function CascadeLoader({ words }) {
	return (
		<Container>
			<Middle>{<Loading words={words} />}</Middle>
		</Container>
	);
}

const Middle = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

const Loading = ({ words }) => {
	return (
		<div className="spinnerContainer">
			{/* <div className="spinner-persona"></div> */}
			<div className="loader-persona">
				<p className="ps-3">Generating</p>
				<div className="words">
					{words.map((word,i) => (
						<span key={word} className={"word " + "word"+i}>
							{word}
						</span>
					))}
				</div>
			</div>
			<div className="position-relative">
				<Spinner  isFixed={false}/>
			</div>
		</div>
	);
};
