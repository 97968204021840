import { Token, Typeahead } from "react-bootstrap-typeahead";
import { Col, ModalBody, Row } from "reactstrap";
import styled, { css } from "styled-components/macro";

export const InputWrapper = styled.select`
	border: none;
	border-bottom: 1px solid #d9dbde;
	padding: 5px;
	padding-right: 0;
	line-height: 18px;
	width: 100%;
	height: 38px;
	${(props) =>
		props.locked &&
		`pointer-events: none; 
    appearance:none;
    border-bottom:none;
    padding-left:0.5rem;`}
`;
export const ColWrapper = styled(Col)`
	border: 1px solid #ced4da;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	align-items: center;
	justify-content: start;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`;
export const InputTypeahead = styled.select`
	border: none;
	padding: 5px;
	padding-right: 0;
	line-height: 18px;
	width: 20%;
	height: 100%;
	/* border-radius: 4px; */
	/* border: 1px solid #ced4da;
border:0; */
`;
export const AllUsers = styled.div`
	display: inline-block;
	margin: 5px 0px 5px 0px;
	width: 30px;
	height: 30px;
	text-align: center;
	transition: background 0.5s ease-in;
	font-size: 14px;
	line-height: 29px;
	border-radius: 50%;
	background-color: #f3f2f1;
`;
export const TypeaheadWrapper = styled(Typeahead)`
	width: ${(props) => props.width};
	.form-control {
		${(props) =>
			!props.showborder &&
			css`
				border: 0;
			`}
	}
	.rbt-token {
		border-radius: 4px;
		font-weight: 600;
		align-self: center;
		align-items: center;
	}
	.rbt-token .rbt-token-remove-button {
		align-items: center;
	}
	.rbt-input-multi {
		display: flex;
		min-height: 36px;
	}
	.rbt-input-multi.focus {
		border: 1px solid #333;
		margin: -1px;
		box-shadow: none;
	}
	.dropdown-item:hover {
		background-color: transparent;
	}
	div[style*="height: 100%"][style*="position: relative"] {
		min-height: 36px;
		display: flex;
		flex: 1 1 0%;
		height: auto !important;
	}
	.dropdown-menu::-webkit-scrollbar {
	}

	.dropdown-menu::-webkit-scrollbar-thumb {
		background-color: #ffffff; /* Set the color of the scrollbar thumb */
	}

	.dropdown-menu::-webkit-scrollbar-track {
		background-color: #ffffff; /* Set the color of the scrollbar thumb */
	}
`;
export const Link = styled.a`
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const LinkContainer = styled.div`
	display: flex;
	width: 80%;
	border: 1px solid #ced4da;
	border-radius: 4px;
	display: flex;
	align-items: center;
`;
export const ButtonContainer = styled.div`
	display: flex;
	width: 20%;
	font-size: 14px;
	display: flex;
	align-items: center;
`;
export const SubLabel = styled.div`
	font-family: "Inter";
	font-style: italic;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #c4c4c4;
	line-height: 40px;
`;
export const Label = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: capitalize;
`;
export const CustomToken = styled(Token)`
	border-radius: 4px;
	background: #efefef;
	color: #333333;
	align-items: center;
	.rbt-token-label {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.rbt-token-remove-button {
		align-items: center;
	}
`;
export const IconWrapper = styled.div`
	background-color: #bde8fa;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
`;
export const Separator = styled.div`
	width: 100%;
	height: 32px;
	${(props) => props.border && `border-top: 1px solid #C4C4C4;`}
	margin: 10px 0px;
`;
export const UserWrapper = styled.div`
	display: flex;
	:hover {
		background-color: #e9ecef;
	}
`;
export const Body = styled(ModalBody)`
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
		`}
`;
export const RegularTypehead = styled(Typeahead)`
	div[style*="height: 100%"][style*="position: relative"] {
		min-height: 44px;
		display: flex;
		flex: 1 1 0%;
		height: auto !important;
	}
	.dropdown-menu::-webkit-scrollbar-thumb {
		background-color: #ffffff; /* Set the color of the scrollbar thumb */
	}

	.dropdown-menu::-webkit-scrollbar-track {
		background-color: #ffffff; /* Set the color of the scrollbar thumb */
	}
`;
export const UserContainer = styled.div`
	${(props) =>
		props.isbulk
			? css``
			: css`
					min-height: 400px;
					max-height: 400px;
					overflow-y: auto;
					overflow-x: hidden;
			  `}
`;
