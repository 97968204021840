import styled, { css } from "styled-components";

const WorkspaceLogo = ({ letter, colorCode, size = "m" }) => {
	return (
		<Container color={colorCode} size={size}>
			{letter?.toUpperCase()}
		</Container>
	);
};

WorkspaceLogo.displayName = "WorkspaceLogo";
export default WorkspaceLogo;

const Container = styled.div`
	width: 100%;
	height: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props?.color};
	border-radius: 4px;
	font-weight: 600;

	${(props) =>
		props.size === "xs" &&
		css`
			width: 20px;
			height: 20px;
			font-size: 12px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			width: 36px;
			height: 36px;
			font-size: 24px;
		`}
    ${(props) =>
		props.size === "l" &&
		css`
			width: 60px;
			height: 60px;
			font-size: 40px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			width: 80px;
			height: 80px;
			font-size: 48px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			width: 120px;
			height: 120px;
			font-size: 68px;
		`}
`;
