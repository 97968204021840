import { getAllAuthorizedUsers, getAllTeams } from "@redux/index";
import { findWorkspaceById } from "@redux/memoizedSelectors";
import { EMPTY_GUID, createDefaultWorkspace, findFirstAlphabeticalCharacter, getModeType } from "@utils/helpers";
import { copyWorkspace, createWorkspace, getAllWorkspaces, updateWorkspace } from "features/workspace/reducer/WorkspaceActions";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { CustomEditor } from "shared/components/CustomEditor";
import DataLoader from "shared/components/data-loader";
import { Labels, Title } from "shared/cx-components/style";
import { EditorProvider } from "shared/dynamic-imports";
import { useAttributeHandler, useGetIsCompanyAdmin, useGetIsLoggedUserWorkspaceOwner } from "shared/hooks/index";
import { FlexAlignCenter, GenericButton } from "shared/ui-basics";
import { CustomBreadCrumb } from "shared/ui-basics/ui-components/CustomBreadCrumb";
import styled, { css } from "styled-components";
import ColorBubblePicker from "../../components/others/color-bubble-picker";
import WorkspaceLogo from "../../components/others/workspace-logo";
import WorkspaceManageUsers from "../../components/others/workspace-manage-users";
import DuplicateWorkspaceAddOn from "./duplicate-workspace-add-on";
import Swal from "sweetalert2";

const WorkspaceForm = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();
	const duplicateRef = useRef(null);
	const ownersRef = useRef(null);
	const usersRef = useRef(null);

	const { mode, selectedItemId } = search.getWorkspaceProps(props.query);
	const workspaces = useSelector((state) => state.workspaces.all_workspaces);
	const currentWorkspace = useSelector(findWorkspaceById(selectedItemId));
	const isCompanyAdmin = useGetIsCompanyAdmin();
	const isWorkspaceOWner = useGetIsLoggedUserWorkspaceOwner(currentWorkspace);
	const displayForm = isCompanyAdmin || isWorkspaceOWner;
	const isDefault = currentWorkspace?.isDefault;

	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
	const modeType = getModeType(mode);
	const { object: workspace, setObject: setWorkspace, handleChange, modifiedAttribNames,setModifiedAttribNames } = useAttributeHandler(modeType === "create" ? createDefaultWorkspace() : currentWorkspace);

	useEffect(() => {
		if (modeType === "update" && currentWorkspace) {
			setWorkspace(currentWorkspace);
		}
		if (modeType === "duplicate" && currentWorkspace) {
			setWorkspace({ ...currentWorkspace, name: `[${t("DUPLICATE")?.toUpperCase()}]- ${currentWorkspace?.name}` });
		}

	}, [currentWorkspace]);

	const redirectToList = useCallback(() => {
		if (modeType === "update" && modifiedAttribNames.size > 0) {
			Swal.fire({
				text: t("SAVE_CHANGES"),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#F96209",
				cancelButtonColor: "#EFEFEF",
				confirmButtonText: t("JS_55"),
			}).then((result) => {
				if (result.isConfirmed) {
					handleAction();
				}
				if (result.isDismissed) {
					setIsActionButtonDisabled(false);
					history.push(`/workspaces`);
				}
			});
		}else {
			setIsActionButtonDisabled(false);
			history.push(`/workspaces`);
		}

	}, [modifiedAttribNames]);

	function removeDuplicates(array) {
		return [...new Set(array)];
	}

	const handleAction = () => {
		const modifiedAttributes = Array.from(modifiedAttribNames);
		const owners = ownersRef?.current?.getWorkspaceUsers();
		const users = usersRef?.current?.getWorkspaceUsers();

		if (owners?.users?.length === 0 && owners?.team?.length === 0) {
			return toast.info(t("SELECT_USERS"));
		}

		if (owners?.isModified || users?.isModified) modifiedAttributes.push("authorizedUsers");

		const updatedUsers = {
			owners: owners?.users,
			teamsOwners: owners?.teams,
			users: users?.users,
			teamsUsers: users?.teams,
		};

		setIsActionButtonDisabled(true);

		if (modeType === "create") {
			createWorkspace({
				newWorkspace: workspace,
				updatedUsers,
				imageExtension: "",
				imageBody64: "",
				onSuccess: redirectToList,
				onFailed: () => setIsActionButtonDisabled(false),
			});
		}

		if (modeType === "update") {
			const onSuccess = () => {
				if(modifiedAttributes?.includes("authorizedUsers")){
					toast.info(t("UPDATE_USER"))
				}
				setIsActionButtonDisabled(false);
				history.push(`/workspaces`);
			};
			updateWorkspace({ modifiedWorkspace: workspace, updatedUsers, modifiedAttribNames: removeDuplicates(modifiedAttributes), onSuccess: onSuccess, onFailed: () => setIsActionButtonDisabled(false) });
		}

		if (modeType === "duplicate") {
			const options = duplicateRef?.current?.getDuplicateOptions();
			const params = {
				srcWorkspaceId: selectedItemId,
				newWorkspace: { ...workspace, id: EMPTY_GUID },
				selectedCustomization: options,
				updatedUsers,
				imageExtension: "",
				imageBody64: "",
				onSuccess: redirectToList,
				onFailed: () => setIsActionButtonDisabled(false),
			};
			copyWorkspace(params);
		}
	};

	const isBoxDisabled = () => {
		const activesWorkspaces = workspaces.filter((ws) => ws.statusCode === 1);
		return isDefault || activesWorkspaces?.length === 1;
	};

	const displayButtonText = () => {
		if (modeType === "create") return t("ASPX_12");
		if (modeType === "update") return t("ASPX_165");
		if (modeType === "duplicate") return t("ASPX_83");
	};

	return (
		<>
			{displayForm && (
				<DataLoader reduxActions={[getAllWorkspaces, getAllTeams, getAllAuthorizedUsers]}>
					<EditorProvider>
						<ContainerPage>
							<div className="my-2">
								<CustomBreadCrumb paths={[t("ASPX_207"), t("WORKSPACES"), modeType === "create" ? t("NEW_WORKSPACE") : currentWorkspace?.name]} backFunction={redirectToList} />
							</div>
							<FormContainer>
								<Section>
									<ImageContainer>
										<WorkspaceLogo letter={findFirstAlphabeticalCharacter(workspace?.name)} colorCode={workspace?.colorCode} size="xl" />
									</ImageContainer>
								</Section>
								<Section>
									<Labels>{t("ASPX_8")}</Labels>
									<Title variant="default" value={workspace?.name} onChange={(e) => handleChange("name", e.target.value)} disabled={false} />
								</Section>
								<Section>
									<Labels>{t("SELECT")}</Labels>
									<ColorBubblePicker
										colors={[
											{ color: "#18AEAD", name: t("GREEN") },
											{ color: "#20A9E9", name: t("BLUE") },
											{ color: "#5A549F", name: t("PURPLE") },
											{ color: "#D40080", name: t("PINK") },
											{ color: "#DA1B1B", name: t("RED") },
											{ color: "#F96209", name: t("ORANGE") },
											{ color: "#FDD03E", name: t("YELLOW") },
											{ color: "#333333", name: t("BLACK") },
										]}
										selected={workspace?.colorCode}
										onSelect={({ color }) => handleChange("colorCode", color)}
									/>
								</Section>
								<Section>
									<Labels>{t("ASPX_9")}</Labels>
									<CustomEditor variant="description" html={workspace?.description || ""} sethtml={(value) => handleChange("description", value)} disabled={false} />
								</Section>
								<FlexAlignCenter className="mb-4">
									<CheckBoxContainer className="me-2">
										<input type="checkbox" checked={workspace?.isDefault} onChange={() => handleChange("isDefault", !workspace?.isDefault)} disabled={isBoxDisabled()} />
									</CheckBoxContainer>
									<Labels>{t("SAVE_AS_DEFAULT")}</Labels>
								</FlexAlignCenter>
								<Section>
									<Labels>{t("WORKSPACE_OWNERS")}</Labels>
									<WorkspaceManageUsers innerRef={ownersRef} workspace={workspace} modeType={modeType} userType="owner" setModifiedAttribNames={setModifiedAttribNames} />
								</Section>
								<Section>
									<Labels>{t("INVITE_USERS_OR_TEAMS")}</Labels>
									<WorkspaceManageUsers innerRef={usersRef} workspace={workspace} modeType={modeType} userType="viewer" setModifiedAttribNames={setModifiedAttribNames} />
								</Section>
								{modeType === "duplicate" && <DuplicateWorkspaceAddOn innerRef={duplicateRef} />}
								<Section>
									<ButtonContainer>
										<GenericButton variant="primary" onClick={handleAction} disabled={isActionButtonDisabled}>
											{displayButtonText()}
										</GenericButton>
									</ButtonContainer>
								</Section>
							</FormContainer>
						</ContainerPage>
					</EditorProvider>
				</DataLoader>
			)}
		</>
	);
};

WorkspaceForm.displayName = "WorkspaceForm";
export default WorkspaceForm;

const ContainerPage = styled(Container)`
	display: flex;
	flex-direction: column;
	height: calc(100vh - 76px);
	${(props) =>
		props.query &&
		css`
			background-color: #f8f9fa;
			padding: 0;
			height: calc(100vh - 224px);
		`}
`;

const FormContainer = styled.div`
	width: 520px;
	display: flex;
	flex-direction: column;
	align-self: center;
`;

const Section = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const ImageContainer = styled.div`
	width: 80px;
	height: 80px;
	align-self: center;
`;

const ButtonContainer = styled.div`
	align-self: flex-start;
`;

const CheckBoxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	:hover {
		cursor: pointer;
	}
	input[type="checkbox"] {
		accent-color: #333333;
		width: 15px !important;
		height: 15px !important;
		:hover {
			cursor: pointer;
		}
	}
`;
