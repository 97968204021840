import { success } from "@redux-requests/core";
import { decode, EMPTY_GUID } from "@utils/helpers";

const initialState = {
	error: null,
	loading: false,
};

const LibrariesReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const responseData = action?.response?.data;

	switch (action.type) {
		case success("TOUCHPOINT_INIT"): {
			const TouchpointArray = Object.entries(action.response.data).map((touch) => touch[1]);
			const Generic = TouchpointArray.find((tp) => tp.number === "00032" && !tp.isCustom);
			return {
				...state,
				touchPoint: TouchpointArray || [],
				generic: Generic,
			};
		}
		case success("GET_ALL_CX_METRICS"):
			return {
				...state,
				cxMetrics: Object.entries(action.response.data).map((metric) => metric[1]) || [],
			};
		case success("GET_ALL_STAKEHOLDER_ROLES"):
			return {
				...state,
				stakeholderRoles: Object.entries(action.response.data).map((role) => role[1]) || [],
			};
		case success("SYSTEM_INIT"):
			return {
				...state,
				system: Object.entries(action.response.data).map((sys) => sys[1]) || [],
			};
		case success("DEPARTMENT_INIT"):
			return {
				...state,
				department: Object.entries(action.response.data).map((dep) => dep[1]) || [],
			};

		case success("ICONS_INIT"):
			return {
				...state,
				icons: Object.entries(action.response.data).map((icon) => icon[1]) || [],
			};
		case success("BRAND_VALUE_INIT"):
			return {
				...state,
				brandValues: Object.entries(action.response.data).map((x) => x[1]) || [],
			};

		case success("TAG_INIT"):
			return {
				...state,
				tags: Object.entries(action.response.data).map((x) => x[1]) || [],
			};
		case success("MEASURE_INIT"):
			return {
				...state,
				measures: Object.entries(action.response.data).map((x) => x[1]) || [],
			};
		case success("CX_GOALS_INIT"):
			return {
				...state,
				cxGoals: Object.entries(action.response.data).map((x) => x[1]) || [],
			};
		case success("GET_ALL_INSIGHT_TYPES"):
			return {
				...state,
				insightTypes: Object.entries(action.response.data).map((x) => x[1]) || [],
			};
		case success("TOUCHPOINT_CATEGORIES_INIT"):
			return {
				...state,
				touchPointCategories: Object.entries(action.response.data).map((x) => x[1]) || [],
			};
		case success("CREATE_INSIGHT_TYPE"):
			return {
				...state,
				insightTypes: [...state.insightTypes, action.response.data],
			};
		case success("UPDATE_INSIGHT_TYPE"):
			return {
				...state,
				insightTypes: state.insightTypes?.map((insightType) => {
					if (insightType.id === action.meta.requestAction.request.data.modifiedInsightType.id) {
						insightType.name = decode(action.meta.requestAction.request.data.modifiedInsightType.name);
						insightType.colorCode = action.meta.requestAction.request.data.modifiedInsightType.colorCode;
					}
					return insightType;
				}),
			};
		case success("DELETE_INSIGHT_TYPE"):
			return {
				...state,
				insightTypes: state.insightTypes?.filter((insightType) => insightType.id !== action.meta.requestAction.request.data.id),
			};
		case success("UPSERT_TAG"): {
			const tag = action.meta.requestAction.request.data.tag;
			if (tag.id !== EMPTY_GUID) {
				return {
					...state,
					tags: state.tags?.map((att) => {
						if (att.id === tag.id) return { ...tag, name: decode(tag.name) };
						return att;
					}),
				};
			} else {
				const newObject = { ...tag, id: action.response.data, name: decode(tag.name) };

				return {
					...state,
					tags: [...state.tags, newObject],
				};
			}
		}
		case success("TAG_GET_RELATED_RECORDS"):
			return {
				...state,
				tags: state.tags.map((tag) => {
					if (tag.id === responseData.id) {
						tag = responseData;
					}
					return tag;
				}),
			};

		case success("DELETE_TAG"):
			return {
				...state,
				tags: state.tags?.filter((tag) => tag.id !== action.meta.requestAction.request.data.tagId),
			};
		case success("DELETE_MEASURE"):
			return {
				...state,
				measures: state.measures?.filter((measure) => measure.id !== action.meta.requestAction.request.data.measureId),
			};

		case success("DELETE_CUSTOM_ICON"):
			return {
				...state,
				icons: state.icons.filter((icon) => icon.id !== action.meta.requestAction.request.data.iconIdStr),
			};
		case success("CREATE_ICON"):
		case success("COPY_CUSTOM_ICON"):
			{
				if (action.response.data && action.response.data?.id) {
					const newIcon = { ...action.response.data, setFocus: true };
					return {
						...state,
						icons: [
							...state.icons.map((icon) => {
								return {
									...icon,
									setFocus: false,
								};
							}),
							newIcon,
						],
					};
				}
				return { ...state }
			}
		case success("UPDATE_CUSTOM_ICON"):
			return {
				...state,
				icons: state.icons.map((icon) => {
					if (icon.id === action.meta.requestAction.request.data.iconIdStr) {
						return {
							...icon,
							name: decode(action.meta.requestAction.request.data.newIconName),
							category: action.meta.requestAction.request.data.category,
						};
					}
					return icon;
				}),
			};
		case success("ICONS_GET_RELATED_RECORDS"):
			return {
				...state,
				icons: state.icons.map((icon) => {
					if (icon.id === responseData.id) {
						icon = responseData;
					}
					return icon;
				}),
			};
		case success("ICON_GET_RELATED_RECORDS"):
			return {
				...state,
				icons: state.icons.map((icon) => {
					if (icon.id === responseData.id) {
						icon = responseData;
					}
					return icon;
				}),
			};
		case success("CREATE_TOUCHPOINT"): {
			const newTouchPoint = {
				name: decode(action.meta.requestAction.request.data.newChannelName),
				categoryId: action.meta.requestAction.request.data.categoryId,
				isCustom: true,
				id: action.response.data,
				weight: action.meta.requestAction.request.data.weight,
				setFocus: true,
			};
			return {
				...state,
				touchPoint: [
					...state.touchPoint.map((touchpoint) => {
						return {
							...touchpoint,
							setFocus: false,
						};
					}),
					newTouchPoint,
				],
			};
		}
		case success("DELETE_CUSTOM_TOUCHPOINT"):
			return {
				...state,
				touchPoint: state.touchPoint.filter((tp) => tp.id !== action.meta.requestAction.request.data.channelIdStr),
			};

		case success("TOUCHPOINT_GET_RELATED_RECORDS"):
			return {
				...state,
				touchPoint: state.touchPoint.map((tp) => {
					if (tp.id === responseData.id) {
						tp = responseData;
					}
					return tp;
				}),
			};
		case success("UPDATE_CUSTOM_TOUCHPOINT"):
			return {
				...state,
				touchPoint: state.touchPoint.map((tp) => {
					if (tp.id === requestData.channelIdStr) {
						return {
							...tp,
							name: decode(requestData.newChannelName),
							categoryId: requestData.categoryId,
							weight: requestData.weight,
						};
					}
					return tp;
				}),
			};
		case success("COPY_CUSTOM_TOUCHPOINT"): {
			if (action.response.data && action.response.data?.id) {
				const newTouchPoint = {
					...action.response.data,
					setFocus: true,
				};
				return {
					...state,
					touchPoint: [
						...state.touchPoint.map((touchpoint) => {
							return {
								...touchpoint,
								setFocus: false,
							};
						}),
						newTouchPoint,
					],
				};
			}
			return { ...state }
		}
		case success("PRIORITY_INIT"):
			return {
				...state,
				priority: {
					cxActions: Object.entries(action.response.data?.Action)?.map((Actions) => Actions[1]),
					opportunity: Object.entries(action.response.data.Opportunity)?.map((opportunity) => opportunity[1]),
					project: Object.entries(action.response.data.Project)?.map((project) => project[1]),
				},
			};
		case success("STATUS_INIT"):
			return {
				...state,
				status: {
					cxActions: Object.entries(action.response.data.Action)?.map((Actions) => Actions[1]),
					solutions: Object.entries(action.response.data.Solution)?.map((Solution) => Solution[1]),
					opportunity: Object.entries(action.response.data.Opportunity)?.map((opportunity) => opportunity[1]),
					customerJourney: Object.entries(action.response.data?.CustomerJourney)?.map((cjm) => cjm[1]),
					project: Object.entries(action.response.data?.Project)?.map((project) => project[1]),
				},
			};

		case success("GROUP_INIT"):
			return {
				...state,
				group: Object.entries(action.response.data)?.map((group) => group[1]),
			};
		case success("UPDATE_STATUS"): {
			const statusTypeUpdate = action.meta.requestAction.params.type;
			const modifiedAttribute = action.meta.requestAction.request.data.modifiedAttribNames[0];
			const updatedObject = action.meta.requestAction.request.data.modifiedStatus;

			const updatedStatusArray = state.status[statusTypeUpdate].map((status) => {
				if (status.id === updatedObject.id) {
					status[modifiedAttribute] = modifiedAttribute === "name" ? decode(updatedObject[modifiedAttribute]) : updatedObject[modifiedAttribute];
				}
				return status;
			});
			return {
				...state,
				status: { ...state.status, [statusTypeUpdate]: updatedStatusArray },
			};
		}
		case success("ADD_STATUS"): {
			const statusType = action.meta.requestAction.params.type;
			const relevantStatusArray = state.status[statusType];
			const updatedStatusAr = [...relevantStatusArray, action.response.data];
			return {
				...state,
				status: { ...state.status, [statusType]: updatedStatusAr },
			};
		}
		case success("REMOVE_STATUS"): {
			const category = action.meta.requestAction.params.type;
			const idToRemove = action.meta.requestAction.request.data.StatusId;
			return {
				...state,
				status: { ...state.status, [category]: state.status[category].filter((st) => st.id !== idToRemove) },
			};
		}
		case success("UPDATE_PRIORITY"): {
			const priorityTypeUpdate = action.meta.requestAction.params.type;
			const modifiedAttribute1 = action.meta.requestAction.request.data.modifiedAttribNames[0];
			const modifiedPriority = action.meta.requestAction.request.data.modifiedPriority;

			const updatedPriorityArray = state.priority[priorityTypeUpdate].map((pt) => {
				if (pt.id === modifiedPriority.id) {
					pt[modifiedAttribute1] = modifiedAttribute1 === "name" ? decode(modifiedPriority[modifiedAttribute1]) : modifiedPriority[modifiedAttribute1];
				}
				return pt;
			});
			return {
				...state,
				priority: { ...state.priority, [priorityTypeUpdate]: updatedPriorityArray },
			};
		}
		case success("ADD_PRIORITY"): {
			const ptType = action.meta.requestAction.params.type;
			const relevantPriorityArray = state.priority[ptType];
			const updatedptAr = [...relevantPriorityArray, action.response.data];
			return {
				...state,
				priority: { ...state.priority, [ptType]: updatedptAr },
			};
		}
		case success("REMOVE_PRIORITY"): {
			const categoryRelevant = action.meta.requestAction.params.type;
			const idToDelete = action.meta.requestAction.request.data.priorityId;
			return {
				...state,
				priority: { ...state.priority, [categoryRelevant]: state.priority[categoryRelevant].filter((st) => st.id !== idToDelete) },
			};
		}
		case success("UPDATE_GROUP"): {
			const modifiedGroup = action.meta.requestAction.request.data.modifiedGroup;
			return {
				...state,
				group: state.group.map((pt) => {
					if (pt.id === modifiedGroup.id) {
						pt.name = decode(modifiedGroup.name);
					}
					return pt;
				}),
			};
		}
		case success("ADD_GROUP"): {
			const updatedGroupAr = [...state.group, action.response.data];
			return {
				...state,
				group: updatedGroupAr,
			};
		}
		case success("REMOVE_GROUP"): {
			const groupIdToDelete = action.meta.requestAction.request.data.groupId;
			return {
				...state,
				group: state.group.filter((st) => st.id !== groupIdToDelete),
			};
		}
		case success("ADD_BRAND_VALUE"): {
			const newbrandValue = { ...action.response.data, setFocus: true };

			return {
				...state,
				brandValues: [
					...state.brandValues.map((bv) => {
						return {
							...bv,
							setFocus: false,
						};
					}),
					newbrandValue,
				],
			};
		}
		case success("DELETE_BRAND_VALUE"):
			return {
				...state,
				brandValues: state.brandValues.filter((bv) => bv.id !== action.meta.requestAction.request.data.brandValueId),
			};
		case success("UPDATE_BRAND_VALUE"):
			return {
				...state,
				brandValues: state.brandValues?.map((bv) => {
					if (bv.id === action.meta.requestAction.request.data.modifiedBrandValue.id) {
						bv = action.response.data;
					}
					return bv;
				}),
			};
		case success("BRAND_VALUE_GET_RELATED_RECORDS"):
			return {
				...state,
				brandValues: state.brandValues.map((bv) => {
					if (bv.id === responseData.id) {
						bv = responseData;
					}
					return bv;
				}),
			};

		case success("CREATE_CX_METRIC"):
			return {
				...state,
				cxMetrics: [...state.cxMetrics, action?.response?.data],
			};
		case success("UPDATE_CX_METRIC"):
			return {
				...state,
				cxMetrics: state.cxMetrics?.map((cxMetric) => {
					if (cxMetric.id === action.meta.requestAction.request.data.cxMeasureId) {
						cxMetric.name = decode(action.meta.requestAction.request.data.newName);
					}
					return cxMetric;
				}),
			};
		case success("DELETE_CX_METRIC"):
			return {
				...state,
				cxMetrics: state?.cxMetrics?.filter((cxMetric) => cxMetric.id !== action.meta.requestAction.request.data.cxMeasureId),
			};
		case success("CREATE_STAKEHOLDER_ROLE"):
			return {
				...state,
				stakeholderRoles: [...state.stakeholderRoles, action?.response?.data],
			};
		case success("UPDATE_STAKEHOLDER_ROLE"):
			return {
				...state,
				stakeholderRoles: state.stakeholderRoles?.map((stakeholderRole) => {
					if (stakeholderRole.id === action.meta.requestAction.request.data.roleId) {
						stakeholderRole.name = decode(action.meta.requestAction.request.data.newName);
					}
					return stakeholderRole;
				}),
			};
		case success("DELETE_STAKEHOLDER_ROLE"):
			return {
				...state,
				stakeholderRoles: state?.stakeholderRoles?.filter((stakeholderRole) => stakeholderRole.id !== action.meta.requestAction.request.data.roleId),
			};
		case success("LIBRARY_INIT"):
			return {
				...state,
				customLibraries: action.response.data || [],
			};
		case success("UPSERT_LIBRARY"): {
			const library = action.meta.requestAction.request.data.library;
			if (library.id !== EMPTY_GUID) {
				return {
					...state,
					customLibraries: state.customLibraries?.map((att) => {
						if (att.id === library.id) return { ...action.response.data };
						return att;
					}),
				};
			} else {
				const newObject = { ...action.response.data };
				return {
					...state,
					customLibraries: [...state.customLibraries, newObject],
				};
			}
		}
		case success("COPY_LIBRARY"): {
			if (action.response.data && action.response.data?.id) {
				const newObject = { ...action.response.data };
				return {
					...state,
					customLibraries: [...state.customLibraries, newObject],
				};
			}
			return { ...state }
		}
		case success("DELETE_LIBRARY"):
			return {
				...state,
				customLibraries: state.customLibraries.filter((x) => x.id !== action.meta.requestAction.request.data.libraryId),
			};
		case success("COPY_VALUE_LIBRARY"): {
			const libraryId = action.meta.requestAction.request.data.libraryId;
			if (action.response.data && action.response.data?.id) {
				const newObject = { ...action.response.data, setFocus: true };

				return {
					...state,
					customLibraries: state.customLibraries?.map((att) => {
						if (att.id === libraryId) {
							att.values = att.values.map((x) => {
								return {
									...x,
									setFocus: false,
								};
							});
							att.values.push(newObject);
							return { ...att };
						}
						return att;
					}),
				};
			}
			else
				return { ...state }
		}
		case success("UPSERT_VALUE_LIBRARY"): {
			const libraryValue = action.meta.requestAction.request.data.libraryValue;
			const libraryId = action.meta.requestAction.request.data.libraryId;

			if (libraryValue.id !== EMPTY_GUID) {
				return {
					...state,
					customLibraries: state.customLibraries?.map((att) => {
						if (att.id === libraryId) {
							att.values = att?.values.map((x) => {
								if (x.id === libraryValue.id) {
									const linkedCjms = x.linkedCjms;
									x = { ...action.response.data, linkedCjms: linkedCjms };
								}
								return x;
							});
							return att;
						}
						return att;
					}),
				};
			} else {
				const newObject = { ...action.response.data, setFocus: true };
				return {
					...state,
					customLibraries: state.customLibraries?.map((att) => {
						if (att.id === libraryId) {
							att.values = att.values.map((x) => {
								return {
									...x,
									setFocus: false,
								};
							});
							att.values.push(newObject);
							return { ...att };
						}
						return att;
					}),
				};
			}
		}
		case success("DELETE_VALUE_LIBRARY"):
			return {
				...state,
				customLibraries: state.customLibraries?.map((att) => {
					if (att.id === action.meta.requestAction.request.data.libraryId) {
						att.values = att.values.filter((x) => x.id !== action.meta.requestAction.request.data.libraryValueId);
						return { ...att };
					}
					return att;
				}),
			};
		case success("CUSTOM_LIBRARY_GET_RELATED_RECORDS"): {
			const { libraryId } = action.meta.requestAction.params;
			return {
				...state,
				customLibraries: state.customLibraries.map((library) => {
					if (library.id === libraryId) {
						library.values = library.values.map((value) => {
							if (value.id === responseData.id) {
								return responseData;
							}
							return value;
						});
					}
					return library;
				}),
			};
		}
		case success("GET_NOTIFICATIONS"): {
			return {
				...state,
				notifications: action.response.data,
			};
		}

		case success("DISMISS_NOTIFICATIONS"): {
			const { notificationIds } = requestData;
			return {
				...state,
				notifications: state.notifications.filter((x) => !notificationIds.includes(x.id)),
			};
		}
		case success("UPDATE_STATUS_NOTIFICATION"): {
			const { notificationId, seen } = requestData;
			return {
				...state,
				notifications: state.notifications.map((x) => {
					if (x.id === notificationId) {
						x.seen = seen;
					}
					return x;
				}),
			};
		}
		case success("UPSERT_MEASURE"): {
			const measure = action.meta.requestAction.request.data.measure;
			if (measure.id !== EMPTY_GUID) {
				return {
					...state,
					measures: state.measures?.map((att) => {
						if (att.id === measure.id) att = action.response.data;
						return att;
					}),
				};
			} else {
				return {
					...state,
					measures: [...state.measures.map((m) => ({ ...m, setFocus: false })), { ...action.response.data, setFocus: true }],
				};
			}
		}

		case success("COPY_MEASURE"): {
			if (action?.response?.data && action?.response?.data?.id)
				return {
					...state,
					measures: [...state.measures.map((m) => ({ ...m, setFocus: false })), { ...action.response.data, setFocus: true }],
				};
			return { ...state }
		}
		case success("MEASURE_GET_RELATED_RECORDS"): {
			return {
				...state,
				measures: state.measures.map((measure) => {
					if (measure.id === responseData.id) {
						measure = responseData;
					}
					return measure;
				}),
			};
		}
		case success("PROJECT_UPSERT_ATTRIBUTE_ITEM"): {
			const cxGoal = { ...requestData?.cxGoal, id: responseData?.cxGoalId };

			if (requestData?.cxGoal?.id === EMPTY_GUID) {
				return {
					...state,
					cxGoals: [cxGoal, ...state.cxGoals],
				};
			}
			return {
				...state,
				cxGoals: state?.cxGoals?.map((cxg) => {
					if (cxg.id === cxGoal.id) return { ...cxGoal };
					return cxg;
				}),
			};
		}
		case "UPSERT_CXPROGRAM_ATTRIBUTE_ITEM": {
			const { cxGoal } = action.request.data;
			if (cxGoal) {
				if (state?.cxGoals.find((item) => item.id === cxGoal.id)) {
					return {
						...state,
						cxGoals: state?.cxGoals?.map((cxg) => {
							if (cxg.id === cxGoal.id) return { ...cxGoal };
							return cxg;
						}),
					};
				}
				else {
					return {
						...state,
						cxGoals: [cxGoal, ...state.cxGoals],
					};
				}
			}
			return {
				...state
			}
		}
		case success("PROJECT_DELETE_ATTRIBUTE_ITEM"): {
			return {
				...state,
				cxGoals: state.cxGoals.filter((cxGoal) => cxGoal.id !== requestData.cXGoalId),
			};
		}
		case success("CX_GOALS_UPSERT"): {
			const cxGoal = requestData.CxGoals[0];

			if (cxGoal.id !== EMPTY_GUID) {
				return {
					...state,
					cxGoals: state.cxGoals?.map((cxg) => {
						if (cxg.id === cxGoal.id) cxg = cxGoal;
						return cxg;
					}),
				};
			}
			return {
				...state,
				cxGoals: [...(state?.cxGoals || []), cxGoal],
			};
		}
		case success("UPSERT_TOUCHPOINT_CATEGORY"): {
			const touchpointCategory = action.meta.requestAction.request.data.touchpointCategory;
			if (touchpointCategory.id !== EMPTY_GUID) {
				return {
					...state,
					touchPointCategories: state.touchPointCategories?.map((att) => {
						if (att.id === touchpointCategory.id) return { ...touchpointCategory, name: decode(touchpointCategory.name) };
						return att;
					}),
				};
			} else {
				const newObject = { ...action.response.data, name: decode(touchpointCategory.name) };
				return {
					...state,
					touchPointCategories: [...state.touchPointCategories, newObject],
				};
			}
		}

		case success("COPY_TOUCHPOINT_CATEGORY"): {
			if (action.response.data && action.response.data?.id) {
				const newObject = { ...action.response.data };
				return {
					...state,
					touchPointCategories: [...state.touchPointCategories, newObject],
				};
			}
			return { ...state }
		}
		case success("DELETE_TOUCHPOINT_CATEGORY"):
			return {
				...state,
				touchPointCategories: state.touchPointCategories?.filter((touchPointCategory) => touchPointCategory.id !== action.meta.requestAction.request.data.id),
			};
		case success("ORDER_STATUS"): {
			const newStatusOrder = requestData.newStatusOrder.split("|");
			const statusType = action.meta.requestAction?.params;
			return {
				...state,
				status: {
					...state.status,
					[statusType]: state.status[statusType]?.sort((a, b) => newStatusOrder.indexOf(a.id) - newStatusOrder.indexOf(b.id))
				}

			}
		}
		case success("ORDER_PRIORITY"): {
			const newPriorityOrder = requestData.newPriorityOrder.split("|");
			const priorityType = action.meta.requestAction?.params;
			return {
				...state,
				priority: {
					...state.priority,
					[priorityType]: state.priority[priorityType]?.sort((a, b) => newPriorityOrder.indexOf(a.id) - newPriorityOrder.indexOf(b.id))
				}

			}
		}
		case success("ORDER_GROUP"): {
			const newGroupOrder = requestData.newGroupOrder.split("|");
			return {
				...state,
				group: state.group?.sort((a, b) => newGroupOrder.indexOf(a.id) - newGroupOrder.indexOf(b.id))
			}
		}
		case success("ORDER_TAG"): {
			const newTagOrder = requestData.newTagsOrder.split("|");
			return {
				...state,
				tags: state.tags?.sort((a, b) => newTagOrder.indexOf(a.id) - newTagOrder.indexOf(b.id)),
			};
		}
		case success("ORDER_INSIGHT_TYPE"): {
			const newInsightTypeOrder = requestData.newInsightTypeOrder.split("|");
			return {
				...state,
				insightTypes: state.insightTypes?.sort((a, b) => newInsightTypeOrder.indexOf(a.id) - newInsightTypeOrder.indexOf(b.id)),
			};
		}
		default:
			return state;
	}
};
export default LibrariesReducer;
