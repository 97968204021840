import { FlexAlignEnd, GenericButton, IconDispatcher, Input } from "shared/ui-basics/index";
import { t } from "i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import React, { useState } from "react";

export default function DeleteWarningModal({ isOpen, onClose, title, warningLabel, label, onClick, secondBtnText, actionBtnText, isDeleteConfirm = false, textColor = "#977f04", boxColor = "#fff8d6" }) {
	const [deleteConfirm, setDeleteConfirm] = useState("")

	const handleClick = () => {
		if (isDeleteConfirm && deleteConfirm.toLowerCase() === "delete") {
			onClick();
			onClose();
		}else if (!isDeleteConfirm){
			onClick();
			onClose();
		}
	};
	return (
		<Modal fade={false} isOpen={isOpen} toggle={onClose} size="s" centered={true}>
			<ModalHeader>{t(title)}</ModalHeader>
			<ModalBody>
				<WarningBox className="p-3" textcolor={textColor} boxcolor={boxColor}>
					<FlexAlignEnd>
						<div>{IconDispatcher("EXCLAMATION", "me-2", { size: "xl" })}</div>
						<div>{  t("WARNING")}</div>
					</FlexAlignEnd>
					<div className="pt-2">{t(warningLabel)}</div>
				</WarningBox>
				<div className="p-3">{t(label)}</div>
				{isDeleteConfirm && <Input required className='p-2' variant='default' type="text" placeholder={t('TYPE_DELETE')} value={deleteConfirm} onChange={(e) => setDeleteConfirm(e.target.value)} />}
			</ModalBody>
			<ModalFooter>
				<GenericButton variant="light-outline" onClick={onClose}>
					{t(secondBtnText)}
				</GenericButton>
				<GenericButton variant="danger" disabled={isDeleteConfirm && deleteConfirm.toLowerCase() !== "delete"} onClick={handleClick}>
					{t(actionBtnText)}
				</GenericButton>
			</ModalFooter>
		</Modal>
	);
}
const WarningBox = styled.div`
	border-radius: 4px;
	color: ${(props) => props.textcolor};
	background: ${(props) => props.boxcolor};
	.svg {
		fill: ${(props) => props.textcolor};
	}
`;
