import { updateFolderLinks } from "@redux/index";
import { FilterItemsByStatus, tableDefaultColumn, toastWrapper } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import BaseActions from "features/projects/actions/BaseActions";
import { BulkActions } from "features/projects/actions/BulkActions";
import { createProject, getProjectExcelExportList, updateMultiProject } from "features/projects/reducer/ProjectsActions";
import _ from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { useModal } from "use-modal-hook";
import { ActionLink } from "../../columns-components/All_actions/ActionLink";

export const GetProjectContext = (props) => {
	const { linked, setRename, disableBulkActions } = props;
	const { pathname, search } = useLocation();
	const history = useHistory();
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const folderId = search.getFolderId();
	const cxProgramId = search.getCxProgramID();
	const dispatch = useDispatch();

	const projects = projectTransfromData(useSelector((state) => state.projects.all_projects)?.filter((project) => FilterItemsByStatus(project, pathname, "/projects", showSampleData, folderId)));

	const specificContext = props.context.split(".")[1];
	const linkedObjects = linked ? projects.filter((cx) => linked?.includes(cx.id)) : undefined;

	const loading = useSelector((state) => state.projects.loading);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.Project]?.[specificContext]?.columns || tableDefaultColumn(EntityList.Project);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Project]?.[specificContext]?.filters;

	const createNewProject = ({ setIsCreateBtnDisabled }) => {
		if (setIsCreateBtnDisabled) setIsCreateBtnDisabled(true);
		const onSuccess = (data) => {
			if (setIsCreateBtnDisabled) setIsCreateBtnDisabled(false);
			if (folderId) {
				updateFolderLinks({ folderIds: [folderId], idsToLink: [data.id], entityName: "cem_cxproject" });
			} else {
				history.push(`/EditProject?id=${data.id}`);
			}
		};
		const onFailed = () => setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
		createProject({ item: { name: "" }, onSuccess, onFailed });
	};

	const getExcelExportListProject = (ids, columns) => {
		const promise = async () => {
			const { data, error } = await dispatch(getProjectExcelExportList(ids, columns));
			var fileDownload = require("js-file-download");
			fileDownload(data, "Project List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
			if (error) throw error;
		};
		toastWrapper(promise, "EXPORT");
	};
	const [showListLinkedModal] = useModal(ListLinkedModal);

	const data = useMemo(() => {
		const newData = {
			projectsList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "PROJECT_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
				visibleHeader: true,
				buttonCreate: createNewProject,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
				listEditable: true,
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "projectName", "lastUpdated", "tagIds", "statusId", "actions"],
				emptyListText: "PROJECT_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "projectName", "lastUpdated", "tagIds", "statusId", "actions"],
				emptyListText: "PROJECT_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			cxProgram: {
				showCheckbox: false, // modals of cxProgram that link/unlink
				columns: ["projectName", "lastUpdated", "createdOn", "ownerId", "statusId", "actions"],
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				showPagination: linked?.length > 10,
				nonDropDown: true,
				emptyListText: "PROJECTS_PLACEHOLDER_PROGRAM",
			},
		};

		const contextData = _.get(newData, specificContext);

		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;

		contextData.exportExcel = getExcelExportListProject;
		contextData.Actions = (project) => CreateActionsDropdown({ actions: BaseActions({ project, setRename }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = linkedObjects;
		contextData.onChangeObject = contextData?.listEditable ? (modifiedProjects, modifiedAttribNames) => updateMultiProject({ modifiedProjects, modifiedAttribNames }) : null;
		contextData.listData = projects;
		contextData.dataKey = "id";
		contextData.entityType = EntityList.Project;
		contextData.loading = loading;
		contextData.buttonCreate = createNewProject;
		contextData.buttonCreateLabel = "NEW_PROJECT";
		contextData.filter = filters;
		// folder case
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.Project, saveButtonLabel: 'ASPX_44' });
			contextData.LinkActionLabel = "LINK_UNLINK_PROJECTS";
			contextData.displayExport = false;

		}
		if (specificContext === "cxProgram") {
			contextData.Actions = props?.disableAction ? () => { } : (options) => ActionLink(options, "cem_cxproject", cxProgramId);
		}
		return contextData;
	}, [isReadOnly, linked, linkedObjects, projects, loading, disableBulkActions, setRename, filters]);
	return data;
};

const projectTransfromData = (project) => {
	return project?.map((project) => {
		return {
			...project,
			groupIds: project?.groups?.join("|"),
			tagIds: project?.tags?.join("|"),
		};
	});
};
