import { addBrandValue, updateBrandValue } from "@redux/index";
import { encode, isUserAllowed } from "@utils/helpers";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, FormFeedback } from "reactstrap";
import { ActionConfirmModal } from "shared/modals/index";
import { GenericButton, IconDispatcher, Input } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import { CustomEditor } from "../../../../shared/components/CustomEditor";
import * as Styles from "./Styles";
import { EditorProvider } from "shared/dynamic-imports";

const BrandValueForm = ({ index, selectedBrandValue, mood = "update", callbackCreateBrandValue, onClose, onDelete, hideCreateModal }) => {
	const { t } = useTranslation();
	const brandValueType = [
		{ name: "Functional", type: 100000001 },
		{ name: "Emotional", type: 100000000 },
	];

	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const [currentBrandValue, setBrandValue] = useState(selectedBrandValue || { type: brandValueType[0].type, number: Number(1) });
	const [attributeToUpdate, setAttributeToUpdate] = useState([]);
	const isDeleteAllowed = isUserAllowed(userType, "delete-library");

	const handleChangeDescription = (value) => {
		setBrandValue((prevState) => ({ ...prevState, description: value }));
		if (!attributeToUpdate.find((x) => x === "description")) attributeToUpdate.push("description");
	};

	const onBrandValueTypeChange = (e) => {
		setBrandValue({ ...currentBrandValue, type: e.value?.type });
		if (!attributeToUpdate.find((x) => x === "type")) attributeToUpdate.push("type");
	};

	const selectBrand = (n) => {
		setBrandValue({ ...currentBrandValue, number: Number(n) });
		if (!attributeToUpdate.find((x) => x === "number")) attributeToUpdate.push("number");
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setBrandValue({ ...currentBrandValue, [name]: value });
		if (!attributeToUpdate.find((x) => x === name)) attributeToUpdate.push(name);
	};

	const selectedbrandValueType = (option, props) => {
		if (option) {
			return (
				<Styles.WrapperBox className="p-1" type={option?.name.toLowerCase()} active={true}>
					<div className="">
						{IconDispatcher(getTypeIcon(option?.name), "me-1", { size: "lg" })}
						{t(option?.name.toUpperCase())}
					</div>
				</Styles.WrapperBox>
			);
		}
		return <span>{props.placeholder}</span>;
	};

	const brandValueTypeOptionTemplate = (option) => {
		return (
			<Styles.WrapperBox className="p-1 d-flex fit-content" type={option?.name.toLowerCase()} active={true}>
				<div className="">{IconDispatcher(getTypeIcon(option?.name), "me-1", { size: "lg" })}</div>
				<div>{t(option?.name.toUpperCase())}</div>
			</Styles.WrapperBox>
		);
	};

	const getTypeIcon = (name) => {
		switch (name.toLowerCase()) {
			case "functional":
				return "BRAIN";
			case "emotional":
				return "HEART";
			default:
				break;
		}
	};

	const create = () => {
		if (currentBrandValue?.name && !currentBrandValue?.name.isEmptyString()) {
			addBrandValue({ brandValue: currentBrandValue, onSuccess: (responseData) => callbackCreateBrandValue && callbackCreateBrandValue(responseData, onClose) });
			const newBrand = { type: brandValueType[0].type, number: Number(1) };
			setBrandValue(newBrand);
		} else {
			setBrandValue({ ...currentBrandValue, name: "" });
		}
		hideCreateModal && hideCreateModal();
	};

	const update = () => {
        onClose()
		if (currentBrandValue?.name !== undefined && currentBrandValue?.name !== "" && attributeToUpdate.length > 0) {
			const copy = { ...currentBrandValue };
			copy.description = copy.description ? encode(copy.description) : "";
			copy.name = copy.name ? encode(copy.name) : "";
			updateBrandValue({ modifiedBrandValue: copy, modifiedAttribNames: attributeToUpdate });
        }
	};

	const deleteCustom = () => {
		onDelete(selectedBrandValue.id);
		onClose();
	};

	const [showModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: "DELETE_BRAND_VALUE",
		Action: deleteCustom,
		actionBtnText: "ASPX_13",
	});

	const getIconNumber = () => {
		const number = Number(currentBrandValue?.number);
		if (!number || number === "" || number > 16) return 1;
		return number;
	};

	return (
		<EditorProvider>
			<div className="overflow-auto h-100">
				<div className="mb-3 ">
					<Form onSubmit={onClose}>
						<Styles.LabelWrapper for="brandValue-name">{t("JS_53")}</Styles.LabelWrapper>
						<Input
							variant="default"
							invalid={currentBrandValue?.name === ""}
							name="name"
							type="text"
							id="brandValue-name"
							value={currentBrandValue?.name || ""}
							onChange={handleChange}
							placeholder={t("ENTER_TITLE")}
						/>
						<FormFeedback>{t("BRAND_VALUE_NAME_MISSING")}</FormFeedback>
					</Form>
				</div>
				<div className="mb-3 ">
					<Styles.LabelWrapper>{t("ASPX_9")}</Styles.LabelWrapper>
					<CustomEditor html={currentBrandValue?.description ?? ""} sethtml={handleChangeDescription} style={{ minHeight: "inherit" }} variant="description" />
				</div>
				<div className="mb-3 ">
					<Styles.DivDropdown>
						<Styles.LabelWrapper>{t("SELECTCATEGORY")}</Styles.LabelWrapper>
						<Dropdown
							className="w-100"
							value={currentBrandValue?.type ? brandValueType.find((x) => x.type === currentBrandValue?.type) : brandValueType[0]}
							options={brandValueType}
							onChange={onBrandValueTypeChange}
							optionLabel="name"
							placeholder="Select a Brand Value Type"
							valueTemplate={selectedbrandValueType}
							itemTemplate={brandValueTypeOptionTemplate}
						/>
					</Styles.DivDropdown>
				</div>
				<div className="mb-3 ">
					<Styles.LabelWrapper>{t("LUCKY_GEM_STONE")}</Styles.LabelWrapper>
					<Styles.DisplayFlexWrap>
						{Array.from(Array(16).keys())?.map((n) => (
							<Styles.ImgWrapper key={n} className="p-2" selected={getIconNumber() === Number(n + 1)} onClick={() => selectBrand(n + 1)}>
								{IconDispatcher("BV-" + (n + 1), "", { width: "35px", height: "35px" })}
							</Styles.ImgWrapper>
						))}
					</Styles.DisplayFlexWrap>
				</div>
				{mood !== "create" ? (
					<>
						<Styles.ButtonWrapper>
							<Styles.FooterDiv IsDeleteAllowed={isDeleteAllowed}>
								{isDeleteAllowed && (
									<GenericButton id={index} className="ms-1 d-flex align-items-center" variant="outline-danger" onClick={showModalDelete}>
										{IconDispatcher("TRASH", "me-1")}
										{t("ASPX_13")}
									</GenericButton>
								)}
								<div>
									<GenericButton className=" me-2 align-self-end" variant="primary" onClick={update}>
										{t("ASPX_12")}
									</GenericButton>
								</div>
							</Styles.FooterDiv>
						</Styles.ButtonWrapper>
					</>
				) : (
					<GenericButton className="btn-block w-100" variant="primary-outline" onClick={create}>
						{t("ASPX_108")}
					</GenericButton>
				)}
			</div>
		</EditorProvider>
	);
};
export default BrandValueForm;
