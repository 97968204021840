import { upsertTag } from "@redux/index";
import { EMPTY_GUID, isUserAllowed } from "@utils/helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Label } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import { Input } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { SystemDepartmentColorSelector } from "../../../customization/components/ColorListItem";

const getDefaultItem = () => {
	return {
		id: EMPTY_GUID,
		name: "",
		colorCode: "#E9ECEF",
		isCustom: true,
	};
};

export default function TagForm({ selectedItem, onUpdate, onDelete, onClose }) {
	const { t } = useTranslation();
	const items = useSelector((state) => state.libraries.tags);
	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const [item, setItem] = useState(selectedItem ? selectedItem : getDefaultItem());
	const [modifiedAttribNames, setModifiedAttribNames] = useState(new Set());

	const isDeleteAllowed = isUserAllowed(userType, "delete-library");

	const handleChange = (attribute, value) => {
		modifiedAttribNames.add(attribute);
		setItem((prevState) => ({
			...prevState,
			[attribute]: value,
		}));
	};

	const callBackCreate = () => {
		setItem(getDefaultItem());
	};

	// Handle create / update actions
	const handleAction = () => {
		if (item.name === "") return toast.info(t("NAME_EMPTY"));

		const isNameExist = items?.filter((it) => it.name.toUpperCase() === item.name.toUpperCase()).length > 0;
		if (isNameExist && selectedItem.name.toUpperCase() !== item.name.toUpperCase()) {
			return toast.info(t("NAME_EXISTS"));
		}

		//item.name = encode(item.name);
		selectedItem ? onUpdate(item, onClose) : upsertTag({tag:item, onSuccess:callBackCreate});
	};

	return (
		<div>
			<Wrapper>
				<LabelWrapper for="icon-name">{t("NAME")}</LabelWrapper>
				<Input variant="default" type="text" id="icon-name" value={item?.name} onChange={(e) => handleChange("name", e.target.value.truncate(100))} placeholder={t("ENTER_NAME")} />
			</Wrapper>
			<Wrapper>
				<LabelWrapper>{t("ASPX_137")}</LabelWrapper>
				<SystemDepartmentColorSelector item={item} handleChange={handleChange} palette={"palette-library"} />
			</Wrapper>

			<>
				{onUpdate ? (
					<ButtonWrapper>
						<FooterDiv IsDeleteAllowed={isDeleteAllowed}>
							{isDeleteAllowed && (
								<GenericButton variant="outline-danger" className="ms-1 d-flex align-items-center" icon="TRASH" onClick={onDelete} iconClassName="me-1">
									{t("ASPX_13")}
								</GenericButton>
							)}
							<div>
								{item.isCustom === undefined || item?.isCustom ? (
									<GenericButton variant="primary" className=" me-2 align-self-end" onClick={handleAction}>
										{t("ASPX_12")}
									</GenericButton>
								) : (
									""
								)}
							</div>
						</FooterDiv>
					</ButtonWrapper>
				) : (
					<GenericButton variant="primary-outline" className="btn-block w-100" disabled={false} onClick={handleAction}>
						{t("ASPX_108")}
					</GenericButton>
				)}
			</>
		</div>
	);
}

const Wrapper = styled.div`
	margin-bottom: 24px;
`;

const FooterDiv = styled.div`
	padding-top: 1rem;
	display: flex;
	justify-content: ${(props) => (props.IsDeleteAllowed ? "space-between" : "flex-end")};
	width: 100%;
`;

const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;
