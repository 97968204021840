import { t } from "i18next";
import { toast } from "react-toastify";
import { store } from "../../../..";
import { addStrategyAttribute, addStrategyBrandValue, archiveCxStrategy, archiveManyCxStrategies, copyCxStrategy, createStrategy, deleteCxStrategy, deleteCxStrategyCustomListItem, deleteCxStrategyPermanent, deleteManyCxStrategies, deleteManyCxStrategiesPermanent, deleteStrategyAttribute, deleteStrategyBrandValue, getExcelExportList, reorderCxStrategyListItem, restoreManyStrategies, restoreStrategy, shareStrategy, shareUsersMulipleStrategy, shareUsersSingleStrategy, updateCxStrategyListItem, updateStrategyAttribOrder, updateStrategyAttribute, updateStrategyBrandValue, upsertStrategyBrandValue } from "features/forms/cx-strategy/reducer/StrategyAction";
import { EMPTY_GUID, toastWrapper } from "@utils/helpers";
import { genericService } from "../../../../shared/GenericService";

export const createStrategyService = (strategyName,history) =>{
  const promise = async () => {
    const { error,action, data } = await store.dispatch(createStrategy(strategyName));
    if (error) throw error
    if (action?.response?.status === 200) {
        history.push(`/editStrategy?strategyid=${action.response.data.id}`)
    }
}
toastWrapper(promise, "CREATE")

}// genericService({strategyName, action: createStrategy, displayToast: "CREATE"})
// export const createStrategyService = (strategyName) => genericService({strategyName, action: createStrategy, displayToast: "CREATE"})
export const copyStrategyService = (strategyId, newSubject,onSuccess) =>{
    genericService({ strategyId, newSubject, action: copyCxStrategy, displayToast: "COPY",onSuccess})
}
export const archiveStrategyService = (strategyId) => genericService({strategyId, action: archiveCxStrategy, displayToast: "ARCHIVE"})
export const archiveManyStrategiesService = (strategyIds, toast, onSuccess) => genericService({strategyIds, action: archiveManyCxStrategies, displayToast: toast, onSuccess})
export const deleteStrategyService = (strategyId) => genericService({strategyId, action: deleteCxStrategy, displayToast: "DELETE"})
export const deleteManyStrategiesService = (strategyIds, toast, onSuccess) => genericService({strategyIds, action: deleteManyCxStrategies, displayToast: toast, onSuccess})
export const deleteStrategyPermanentService = (strategyId) => genericService({strategyId, action: deleteCxStrategyPermanent, displayToast: "DELETE"})
export const deleteManyStrategiesPermanentService = (strategyIds, toast, onSuccess) => genericService({strategyIds, action: deleteManyCxStrategiesPermanent, displayToast: toast, onSuccess})
export const restoreStrategyService = (strategyId) => genericService({strategyId, action: restoreStrategy, displayToast: "RESTORE"})
export const restoreManyStrategiesService = (strategyIds, onSuccess) => genericService({strategyIds, action: restoreManyStrategies, displayToast: "RESTORE", onSuccess})
export const shareManyStrategiesService = (roleDetails, usersId, strategyIds) => genericService({roleDetails, usersId, strategyIds, action: shareUsersMulipleStrategy, displayToast: "UPDATE"})
export const shareManyUsersSingleStrategyService = (roleDetails, usersId, strategyId) => genericService({roleDetails, usersId, strategyId, action: shareUsersSingleStrategy, displayToast: "UPDATE"})
export const shareUserStrategyService = (roleDetails, userId, userName, strategyId) => genericService({roleDetails, userId, userName, strategyId, action: shareStrategy, displayToast: "UPDATE"})

export const addStrategyAttributeService=(newFieldID, strategyId, attributeType, newObject)=>genericService({newFieldID, strategyId, attributeType, newObject,action:addStrategyAttribute})
export const deleteStrategyAttributeService = (strategyId,attributeId) => genericService({strategyId,attributeId, action: deleteStrategyAttribute, displayToast: "DELETE"})
export const updateStrategyAttribOrderService=(strategyId, newOrderConfig,cxProgramId)=>genericService({strategyId, cxProgramId,newOrderConfig,action:updateStrategyAttribOrder})
export const updateCxStrategyAttributeService=(modifiedStrategy, modifiedAttribNames)=>genericService({modifiedStrategy, modifiedAttribNames,action:updateStrategyAttribute})
export const getExcelExportListService = (ids) => {
    const promise = async () =>  {
        const { data, error } = await store.dispatch(getExcelExportList(ids));
        var fileDownload = require('js-file-download');
        fileDownload(data, 'Strategies List',"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        if (error) throw error
      }
      toastWrapper(promise, "EXPORT")
}

export const updateCxStrategyAttribute = async (modifiedStrategy, modifiedAttribNames) => {
    const { error } = await store.dispatch(updateStrategyAttribute(modifiedStrategy, modifiedAttribNames))
    if (error) {
        toast.error(t('JS_13'));
    }

}

export const  updateListItemCxStrategy = async({strategyIdStr,attributeIdStr,value ,attributeItemIdStr ,onSuccess}) => {
    const {error} =  await store.dispatch(updateCxStrategyListItem({strategyIdStr, attributeIdStr, description:value,attributeItemIdStr}))
    if (error) toast.error(t('JS_13'))
    onSuccess&& onSuccess();

}
export const createListItemcxStrategy = async ({strategyIdStr,attributeIdStr,value,onSuccess}) => {
        updateListItemCxStrategy({strategyIdStr,attributeIdStr,value,attributeItemIdStr: EMPTY_GUID,onSuccess})
}
export const reorderListItemsStrategy = async ({newOrder, strategyIdStr ,attributeIdStr}) => {

    const {error} =  await store.dispatch(reorderCxStrategyListItem({strategyIdStr, attributeIdStr,newOrder}))
    if (error) toast.error(t('JS_13'))


}
export const deleteListItemStrategy = async({strategyIdStr,attributeIdStr ,attributeItemIdStr,onSuccess}) =>
 genericService({strategyIdStr,attributeIdStr,attributeItemIdStr, action: deleteCxStrategyCustomListItem,onSuccess})


export const addStrategyBrandValueService = (props) => genericService({ ...props, action: addStrategyBrandValue })
export const updateStrategyBrandValueService = (props) => genericService({ ...props, action: updateStrategyBrandValue })
export const deleteStrategyBrandValueService = (props) => genericService({ ...props, action: deleteStrategyBrandValue })
export const upsertStrategyBrandValueService = (props) => genericService({...props, action: upsertStrategyBrandValue})
