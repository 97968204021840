import { Input } from "shared/ui-basics/input";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";
import React from "react";
export const KPICard = ({ item, setItem, mode, title ,...props}) => {
	const { t } = useTranslation();
	const eventEnterLimit = (e) => {
		const keyCode = e.which || e.keyCode;
		if (keyCode === 13 && !e.shiftKey) {
			// Don't generate a new line
			e.preventDefault();
		}
	};
	const handleChange = (e) => {
		if (e.target.value.length > 190) {
			toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
		}
		setItem((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value.truncate(190),
		}));
	};
	return (
		<DivWrapper className="ms-2">
			<TextareaWithIcon
				className="item-text-area"
				rows={1}
				disabled={mode === "view"}
				mode={mode}
				onKeyDown={eventEnterLimit}
				placeholder={t("NEW") + " " + title + " ..."}
				id={"textarea_" + item.id}
				value={item?.name}
				name="name"
				onChange={handleChange}
				></TextareaWithIcon>
		</DivWrapper>
	);
};

const DivWrapper = styled.div`
	position: relative;
	width: 100%;
	align-items: center;
	display: flex;
	min-height: 40px;
`;

export const TextareaWithIcon = styled(Input)`
padding:0.3rem;
width: 100%;
min-height:1px;
opacity:1 !important;
font-family: 'Inter';
display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;

	${(props) =>
		props.mode === "create" &&
		css`
			color: #c4c4c4;
			font-size: 14px;
			font-style: italic;
		`}
	:hover {
		border-color: transparent !important;
		box-shadow: none !important;
	}
	:focus {
		border-color: transparent !important;
		box-shadow: none !important;
	}
	::placeholder {
		color: #c4c4c4;
		font-size: 14px;
		font-style: italic;
	}
`;

export const IconWrapper = styled.div`
	min-width: 30px;
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
`;
