import { UserType } from "@utils/optionsSets/OptionSets.js";
import AICreation from "features/journey-map/modals/AI/ai-creation.jsx";
import { SupportForm } from "features/navigation/Header/SupportForm.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PromptAICreation } from "shared/modals/index.js";
import { FlexAlignCenter, GenericButton, IconDispatcher } from "shared/ui-basics/index.js";
import styled, { css } from "styled-components";
import { useModal } from "use-modal-hook";
import * as Style from "../Style.js";

export default function AIWidget({ isEnterprise }) {
	const { t } = useTranslation();
	const history = useHistory();

	const aiPurchased = useSelector(({ settings }) => settings.subscriptionDetails.aiPurchased);
	const aiEnabled = useSelector(({ settings }) => settings.subscriptionDetails.aiEnabled);
	const userInfo = useSelector((state) => state.auth?.userInfo);

	const userType = Number(userInfo.UserType);
	const isUser = userType === UserType.user;

	const [showPersonaAiModal] = useModal(PromptAICreation);
	const [showJourneyAiModal] = useModal(AICreation);
	const [showSupportFormModal] = useModal(SupportForm, {
		subject: "AI subscription request",
	});

	const handleRedirectAI = (entity) => {
		if (entity === "persona") {
			history.push(`/personas`);
			showPersonaAiModal();
		}
		if (entity === "journey") {
			history.push(`/journey-map`);
			showJourneyAiModal();
		}
    };

	const displayButtons = () => {
		if (aiPurchased) {
			if (aiEnabled)
				return (
					<FlexAlignCenter gap="16">
						<GenericButton variant="primary" size="s"  onClick={() => handleRedirectAI("persona")}>
							{t("ASPX_74")}
						</GenericButton>
						<GenericButton variant="secondary" size="s"  onClick={() => handleRedirectAI("journey")}>
							{t("JOURNEY")}
						</GenericButton>
					</FlexAlignCenter>
				);
			if (!aiEnabled) {
				if (isUser) return <TextInfo>{t("CONTACT_ADMINISTRATOR")}</TextInfo>;

				return (
					<GenericButton variant="primary-outline" size="s" icon="sparkles" iconClassName="me-1" onClick={() => history.push(`/organization`)}>
						{t("AI_ENABLE")}
					</GenericButton>
				);
			}
		}
		if (!aiPurchased) {
			return (
				<GenericButton variant="primary-outline" size="s" onClick={showSupportFormModal}>
					{t("CONTACT_US")}
				</GenericButton>
			);
		}
	};

	return (
		<Style.WidgetContainer className="shadow-sm" background="linear-gradient(104deg, #ffeee3 0.73%, #fff8ee 32.71%, #e8f7ff 66.16%, #f1f0ff 97.16%)" isenterprise={isEnterprise}>
			<IconContainer>{IconDispatcher("WIDGET_AI")}</IconContainer>
			<Body>
				<Style.WidgetTitle>{t("ALEX_IS_HERE")}</Style.WidgetTitle>
				<Style.WidgetDescription>{t("GENERATE_CONTENT_WITH_AI")}</Style.WidgetDescription>
				<Footer isenterprise={isEnterprise}>{displayButtons()}</Footer>
			</Body>
		</Style.WidgetContainer>
	);
}

const IconContainer = styled.div`
	width: 65px;
	height: 56px;
	position: absolute;
	top: -27px;
	left: 20px;
	display: flex;
`;

const Body = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
`;

const Footer = styled.div`
	margin-top: 4px;

	${(props) =>
		!props.isenterprise &&
		css`
			align-self: flex-end;
		`}
`;

const TextInfo = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: 150%;
`;
