import { upsertValueLibrary } from "@redux/index";
import { createDefaultCustomLibraryItem, encode, isUserAllowed } from "@utils/helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Label } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import { Input } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { CustomEditor } from "../../../../shared/components/CustomEditor";
import { EditorProvider } from "../../../../shared/dynamic-imports";
import { SystemDepartmentColorSelector } from "../../../customization/components/ColorListItem";


const infoLibrary = (type) => {

    const setting = {
        colorCode: "",
        palette: "",
        title: "",
        textModalDelete: ""
    }
    switch (type) {
        case "system":
            setting.colorCode = "#FCA8CE";
            setting.palette = "palette-system";
            break;
        case "department":
            setting.colorCode = "#FFAC81";
            setting.palette = "palette-department";
            break;
        case "library":
            setting.colorCode = "#E9ECEF";
            setting.palette = "palette-library";
            break;
        default:
            break;
    }
    return setting;
}
export default function LibraryValueForm({ items, libraryId, selectedItem, type, onDelete, onCreate, onClose }) {
    const { t } = useTranslation();

    const userType = useSelector((state) => state.auth.userInfo.UserType);
    const info = infoLibrary(type);
    const [item, setItem] = useState(selectedItem ? selectedItem : createDefaultCustomLibraryItem(info.colorCode));

    const isDeleteAllowed = item?.isCustom && isUserAllowed(userType, "delete-library");

    const handleChange = (attribute, value) => {
        setItem((prevState) => ({
            ...prevState,
            [attribute]: value,
        }));
    };
    const callBackCreate = () => {
        setItem(createDefaultCustomLibraryItem(info.colorCode))
        onClose()
    }
    const onUpdate = ({ item, callBack, toast }) => {
        upsertValueLibrary({ libraryId, libraryValue: item, onSuccess: callBack, displayToast: toast });

    }
    // Handle create / update actions
    const handleAction = () => {
        if (item?.name === "") return toast.info(t("NAME_EMPTY"));

        const isNameExist = items?.filter((it) => it.name.toUpperCase() === item?.name.toUpperCase()).length > 0;
        if (isNameExist) {
            return toast.info(t("NAME_EXISTS"));
        }
        const copyItem = { ...item }

        copyItem.name = encode(item.name);
        copyItem.description = encode(item?.description);
        selectedItem ? onUpdate({ item: copyItem, toast: "UPDATE", callBack: onClose }) : onUpdate({ item: copyItem, callBack: callBackCreate });
    };

    return (
        <div>
            <Wrapper>
                <LabelWrapper for="icon-name">{t("NAME")}</LabelWrapper>
                <Input
                    variant="default"
                    type="text"
                    id="icon-name"
                    value={item?.name}
                    onChange={(e) => handleChange("name", e.target.value.truncate(100))}
                    placeholder={t("ENTER_NAME")}
                    disabled={!item?.isCustom}
                />
            </Wrapper>
            <EditorProvider>
                <Wrapper>
                    <LabelWrapper>{t("ASPX_9")}</LabelWrapper>
                    <CustomEditor html={item?.description || ""} sethtml={(value) => handleChange("description", value)} disabled={!item?.isCustom} variant={"description"} />
                </Wrapper>
            </EditorProvider>
            <Wrapper>
                <LabelWrapper>{t("ASPX_137")}</LabelWrapper>
                <SystemDepartmentColorSelector item={item} handleChange={handleChange} palette={info.palette} disabled={!item?.isCustom} />
            </Wrapper>

            {item.isCustom === undefined || item?.isCustom && (
                <>
                    {onCreate ? (
                        <GenericButton variant="primary-outline" className="btn-block w-100" disabled={false} onClick={handleAction}>
                            {t("ASPX_108")}
                        </GenericButton>
                    ) : (
                        <ButtonWrapper>
                            <FooterDiv IsDeleteAllowed={isDeleteAllowed}>
                                {isDeleteAllowed && (
                                    <GenericButton variant="outline-danger" className="ms-1 d-flex align-items-center" icon="TRASH" iconClassName="me-1 mb-1" onClick={onDelete}>
                                        {t("ASPX_13")}
                                    </GenericButton>
                                )}
                                <div>
                                    {item.isCustom === undefined || item?.isCustom ? (
                                        <GenericButton variant="primary" className=" me-2 align-self-end" onClick={handleAction}>
                                            {t("ASPX_12")}
                                        </GenericButton>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </FooterDiv>
                        </ButtonWrapper>
                    )}

                </>
            )}
        </div>
    );
}

const Wrapper = styled.div`
	margin-bottom: 24px;
`;

const FooterDiv = styled.div`
	padding-top: 1rem;
	display: flex;
	justify-content: ${(props) => (props.IsDeleteAllowed ? "space-between" : "flex-end")};
	width: 100%;
`;

const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;
