import { setShowHeaderDetails } from "@redux/pageReducer";
import { GetDefaultSwimlaneName, toastWrapper } from "@utils/helpers";
import { getExcelExportMap, getIMGExportMap, getPDFExportMap, setIsExporting } from "features/journey-map/reducer/CJMActions";
import $ from 'jquery';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IconDispatcher } from "shared/Icons";
import CustomTooltip from "shared/components/CustomTooltip";
import { Button, GenericButton } from "shared/ui-basics/buttons";
import styled from 'styled-components/macro';

const calculatedHeight = (swimLaneIds, selectedSW, width) => {
    let heightX = 0;

    selectedSW.forEach(element => {

        const draggableElem = $('*[data-rbd-draggable-id="' + element + '"]');
        const swimlaneElem = $('*[id="swimlaneKey:' + element + '"]');
        if (draggableElem.length > 0) {
            if (swimlaneElem?.[0]?.style) {
                swimlaneElem[0].style.display = 'inherit';
            }
            const elemHeight = draggableElem[0]?.clientHeight || 60;
            heightX += elemHeight
            if (swimlaneElem?.[0]?.style) {

                swimlaneElem[0].style.display = '';
            }
        }
    });

    const headerDetailsElem = $('#headerDetails_PDF_height');
    if (headerDetailsElem.length > 0) {
        headerDetailsElem[0].style.width = width + 'px'; // set the new width ->then the des change the height
        heightX += headerDetailsElem[0]?.clientHeight;
    }

    return heightX;
}
const calculatedWidth = () => {
    let width = 0;
    if ($('#export_PDF_width').length > 0) {
        width += $('#export_PDF_width')[0]?.clientWidth;
    }
    return width;
}
export const ExportModal = ({ isOpen, onClose }) => {
    const { cjmIdStr, personaIdStr } = useLocation().search.getAllMapsParams();
    const cjmSwimLanes = useSelector((state) => state.maps.current_map?.cjmSwimLanes);
    const cjmName = useSelector((state) => state.maps.current_map.cjmName);

    const { t } = useTranslation();
    const [exportBy, setExportBy] = useState('pdf');
    const [selectedSwimlanes, setSelectedSwimlanes] = useState(cjmSwimLanes?.extractKey() || [])
    const dispatch = useDispatch();
    //const token = useSelector(state => state.auth.token)
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const setExportingQueryParam = (exporting) => {
            const currentSearchParams = new URLSearchParams(location.search);

            if (exporting) {
                dispatch(setIsExporting(true));
            } else {
                dispatch(setIsExporting(false));
            }

            // Create the new search string
            const newSearchString = currentSearchParams.toString();

            history.push({
                pathname: location.pathname,
                search: newSearchString ? `?${newSearchString}` : '',
            });
        };
        setExportingQueryParam(isOpen);

        return () => {
            setExportingQueryParam(false);
        };
    }, []);

    const exportMap = () => {
        switch (exportBy) {
            case "excel": {


                const promise = async () => {
                    const { data, error } = await dispatch(getExcelExportMap({ cjmIdStr: cjmIdStr }));
                    var fileDownload = require('js-file-download');
                    fileDownload(data, cjmName + '.xlsx');

                    if (error) throw error
                }
                toastWrapper(promise, "EXPORT")
                break;
            }
            case "pdf":
            case "image": {

                const swimLaneIds = selectedSwimlanes.length > 0 ? selectedSwimlanes.join('|') : ' ';
                const width = calculatedWidth();
                const height = calculatedHeight(cjmSwimLanes.map((sw) => sw.key) || [], selectedSwimlanes, width)+200;
                const promise1 = async () => {
                    if (exportBy === "pdf") {
                        const { data, error } = await dispatch(getPDFExportMap({ cjmIdStr, personaIdStr, swimLaneIds, width, height }));
                        //const url = `/EditMap?isPrint=1&cjmId=${cjmIdStr}&personaId=${personaIdStr}&swimLaneIds=${swimLaneIds}&token=${token}`;
                        //  console.log(url);
                        var fileDownload = require('js-file-download');
                        fileDownload(data, cjmName + '.pdf');
                        if (error) throw error
                    }
                    else {
                        const { data, error } = await dispatch(getIMGExportMap({ cjmIdStr, personaIdStr, swimLaneIds, width, height }));
                        var fileDownloads = require('js-file-download');
                        fileDownloads(data, cjmName + '.png');


                        if (error) throw error
                    }
                }
                toastWrapper(promise1, "EXPORT")

                break;
            }
            default:
                break;
        }
        setShowHeaderDetails(false);
        onClose();
    }
    const handleClick = (e) => {
        setExportBy(e.currentTarget.id);
    }
    const changeSelect = (e) => {

        if (selectedSwimlanes.includes(e.currentTarget.id))
            setSelectedSwimlanes(selectedSwimlanes.filter((sw) => sw !== e.currentTarget.id))
        else
            setSelectedSwimlanes([...selectedSwimlanes, e.currentTarget.id])
    }
    const eventClose = () => {
        setShowHeaderDetails(false);
        onClose();
    }
    return <Modal fade={false} isOpen={isOpen} centered={true} toggle={eventClose} size='lg'>
        <ModalHeader className="fs2 listModal-bg  " toggle={eventClose}> {t('JS_172')}</ModalHeader>
        <ModalBody className="mt-2 pb-0 text-muted fs3">
            {t('JS_173')}
            <CheckWrapper>
                {cjmSwimLanes?.filter(sw => !['StageHeaders', 'PhaseHeaders'].includes(sw.id)).map((sw) =>
                    <DivSelectedWrapper key={sw.key} checked={selectedSwimlanes?.includes(sw.key)} >
                        <InputWrapper type="checkbox" key={sw.key} id={sw.key} checked={selectedSwimlanes?.includes(sw.key)} onChange={changeSelect} disabled={'excel' === exportBy} />
                        <CustomTooltip text={sw?.name} placement={'bottom'} disabled={sw?.name?.length < 26}>
                            <LabelWrapper>
                                {sw.name?.isEmptyString() ?
                                    t(GetDefaultSwimlaneName(sw.type))
                                    : sw.name}</LabelWrapper>
                        </CustomTooltip>
                    </DivSelectedWrapper>)
                }
            </CheckWrapper>
        </ModalBody>
        <FooterWrapper>
            <DivButton>
                <div className="w-100 mb-4">{t('JS_174')}</div>
                <ButtonWrapper>
                    <ButtonExport id='pdf' variant='Purple' className={'pdf' === exportBy ? "active" : ""} onClick={handleClick}><div>{IconDispatcher("PDF", "me-1")}{t('JS_175')}</div></ButtonExport>
                    <ButtonExport id='excel' variant='Purple' className={'excel' === exportBy ? "active" : ""} onClick={handleClick}><div>{IconDispatcher("EXCEL", "me-1")}{t('JS_176')}</div></ButtonExport>
                    <ButtonExport id='image' variant='Purple' className={'image' === exportBy ? "active" : ""} onClick={handleClick}><div>{IconDispatcher("IMAGE", "me-1")}{t('JS_177')}</div></ButtonExport>
                </ButtonWrapper>
            </DivButton>
            <div className="m-3">
                <GenericButton variant='primary' className='text-capitalized h-25' onClick={exportMap}>{t('JS_172')}</GenericButton>
            </div>
        </FooterWrapper>
    </Modal>
}

const LabelWrapper = styled(Label)`
    max-width: 200px;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
      text-transform: capitalize;

`

const FooterWrapper = styled(ModalFooter)`
  border-top: 1px solid #dee2e6 !important;
  padding:0;

`
const DivButton = styled.div`
  width:100%;
  border-bottom: 1px solid #dee2e6;
  display:flex;
  justify-content: center;
    flex-direction: column;
    align-content: space-around;
    padding-bottom: 1.5rem!important;
    padding:0.75rem;
`
const ButtonExport = styled(Button)`
    margin-right: 1rem;
    color: #333333;
    border: 1px solid #C4C4C4;
    background:#FFFFFF;
      &.active{
        color:#F96209;
        background: #FFE5D6;
        border: 1px solid #F96209;

      }
      & div{
        display:flex;
        align-items: center
      }

`
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`
const DivSelectedWrapper = styled.div`
display: flex;
justify-content:flex-start;
align-items:center;
margin-top:20px;
width: 33.3%;

`
const InputWrapper = styled(Input)`
margin:10px;
   &:checked{

    background-color: #333333;
    border-color: #333333;

   }
`
const CheckWrapper = styled.div`
 display:flex;
 flex-wrap: wrap;
 margin-bottom:1.5rem;
`
