import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/team/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/team/", method: "GET" }); // api actions type get

export const getAllTeams = () => createGetAction({ type: "GET_ALL_TEAMS", endPoint: "getAll" });

export const upsertTeam = ({ team, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPSERT_TEAM",
			endPoint: "upsert",
			data: {
				team,
			},
		}),
		displayToast: "UPDATE",
	});

export const deleteTeam = ({ teamId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_TEAM",
			endPoint: "delete",
			data: {
				teamId,
			},
		}),
	});

export const addMembers = ({ teamIds, userIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ADD_MEMBERS_TO_TEAM",
			endPoint: "addMembers",
			data: { teamIds, userIds },
		}),
	});

export const removeMember = ({ teamId, userId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "REMOVE_MEMBER_FROM_TEAM",
			endPoint: "removeMember",
			data: { teamId, userId },
		}),
	});

export const updateUsersTeams = ({ userIds, idsToAdd, idsToRemove, ...props }) =>
	genericService({
		...props,
		displayToast: "UPDATE",
		action: createPostAction({
			type: "UPDATE_USERS_TEAMS",
			endPoint: "updateMembers",
			data: { userIds, idsToAdd, idsToRemove },
		}),
	});
