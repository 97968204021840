/* eslint-disable no-extend-native */
import { getAllCxAction, priorityInit, statusInit } from "@redux/index.js";
import { FilterItemsByStatus, resort } from "@utils/helpers";
import DataTable from "features/grid-table/DataTable.js";
import CxActionLoadingCard from "features/home/components/loading-components/CxActionLoadingCard.js";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import DataLoader from "shared/components/data-loader.jsx";
import * as Style from "./Style.js";
import { SectionHeader } from "./section-header.jsx";

export const CxActionsData = ({ isStandard }) => {
	const pathname = useLocation().pathname;
	const allActions = useSelector((state) => state.actions.all_actions);
	const actions = useMemo(
		() =>
			resort(
				allActions?.filter((action) => FilterItemsByStatus(action, pathname, "/home")),
				"dueDate",
				"down"
			)?.slice(0, 5),
		[allActions]
	);
	const history = useHistory();

	return (
		<Style.Section>
			<SectionHeader entityText="JS_80" onClick={() => history.push("/cx-actions")} />
			<DataLoader reduxActions={[getAllCxAction, statusInit, priorityInit]} disabledSpinner={isStandard || actions?.length > 0} CustomLoading={<LoadingCxActions />} >
				<CxActionsTable actions={actions} />
			</DataLoader>
		</Style.Section>
	);
};

const LoadingCxActions = () => {
	return (
		<Style.ContainerLoading>
			<CxActionLoadingCard />
			<CxActionLoadingCard />
			<CxActionLoadingCard />
			<CxActionLoadingCard />
			<CxActionLoadingCard />
		</Style.ContainerLoading>
	);
};

const CxActionsTable = ({ actions }) => {
	const { t } = useTranslation();
	if (actions?.length === 0) {
		return (
			<Style.NoElementContainer>
				<img src={require("@Assets/images/EmptyListImages/no-action-items.svg").default} alt="no cxactions" />
				<div id="no-data">{t("NO_CXACTION")}</div>
			</Style.NoElementContainer>
		);
	}

	return (
		<Style.TableContainer hasData={actions?.length > 0} >
			<DataTable context="cxActions.home" linked={actions?.map((a) => a.id)} />
		</Style.TableContainer>
	);
};
