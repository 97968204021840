import { EntityList, SubscriptionPlan, UserType, modes } from "@utils/optionsSets/OptionSets";
import { t } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { tableDefaultColumn } from "@utils/helpers";
import { useHistory } from "react-router-dom";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import BaseActions from "features/settings/manage-users/actions/BaseActions";
import { getUsersExcelExport } from "@redux/index";
import { BulkActions } from "features/settings/manage-users/actions/bulkActions/BulkActions";
import PickListData from "@utils/optionsSets/PickListData";
import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";

export const GetUserContext = (props) => {
    const history = useHistory();
	const userTypes = PickListData("user-type");

	const specificContext = props.context.split(".")[1];
	const users = useSelector((state) => state.settings.users);
	const loading = useSelector((state) => state.settings.loading);
	const subscriptionDetails = useSelector((state) => state.settings?.subscriptionDetails);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.User]?.[props.context.split(".")[1]]?.filters;
	const userInfo = useSelector((state) => state.auth.userInfo);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.User]?.[specificContext]?.columns || tableDefaultColumn(EntityList.User);
	const allTeamsObj = useSelector((state) => state.settings?.teams);
	const allAuthorizedUsers = useSelector((state) => state.settings?.authorizedUsers);
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);

	const allTeams = allTeamsObj ? Object.values(allTeamsObj) : [];
	const isReadOnly = userInfo?.UserType?.isReadOnly();
	const loggedUserType = Number(userInfo?.UserType);
	const isLoggedUserExpert = Number(userInfo?.UserSubscriptionPlan) === SubscriptionPlan.Expert;
    const contracts = subscriptionDetails?.subscriptionContracts?.sort((a, b) => b.subscriptionPlanValue - a.subscriptionPlanValue);
	const isMultiSubscription = contracts?.length > 1;

	const formatedUsers = useMemo(() => formatData({ users, allAuthorizedUsers, allTeams, allActivesWorkspaces, userTypes }), [users, allAuthorizedUsers, allTeams, allActivesWorkspaces]);

	const displayCreateButton =
		(!isReadOnly && !isMultiSubscription) || (isMultiSubscription && !isLoggedUserExpert && (loggedUserType === UserType.admin || loggedUserType === UserType.companyAdmin));

	const createNewUser = () => history.push(`/edit-user?mode=${modes.create}`);

	const data = useMemo(() => {
		const contextData = {};
		contextData.listData = formatedUsers;
		contextData.dataKey = "id";
		contextData.entityType = EntityList.User;
		contextData.columns = columnsArray;
		contextData.exportExcel = getUsersExcelExport;
		contextData.Actions = (user) => CreateActionsDropdown({ actions: BaseActions({ user }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.buttonCreate = createNewUser;
		contextData.buttonCreateLabel = "INVITE";
		contextData.emptyListText = "ASPX_203";
		contextData.showCheckbox = true;
		contextData.bulkActions = true;
		contextData.clickToOpen = true;
		contextData.disableFixHeader = true;
		contextData.showPagination = true;
		contextData.visibleHeader = true;
		contextData.visibleButtonCreate = displayCreateButton;
		contextData.displayView = true;
		contextData.listEditable = true;
		contextData.loading = loading;
		contextData.subscriptionDetails = subscriptionDetails;
		contextData.filter = filters;

		return contextData;
	}, [contracts, subscriptionDetails, loading, users, filters, columnsArray]);

	return data;
};

const formatData = ({ users, allAuthorizedUsers, allTeams, allActivesWorkspaces, userTypes }) => {
	const newUsers =  JSON.parse(JSON.stringify(users));
    newUsers.map((user) => {
        const authorizedUserId = allAuthorizedUsers.find((authUser) => authUser?.domainName.toLowerCase() === user?.loginName?.toLowerCase())?.id;
		const userTeams = allTeams?.filter((t) => t?.memberUserIds?.includes(authorizedUserId));
		const userWorkspaces = allActivesWorkspaces?.filter((ws) => ws.authorizedUsers.find((au) => au.id === authorizedUserId));
        
		user.teamIds = userTeams.map((t) => t?.id);
		user["userTypeName"] = userTypes[user.userType];
        user.workspaceIds = userWorkspaces.map((ws) => ws?.id);
        return user
    });
	return newUsers;
};
