import React from 'react'
import { GenericButton } from 'shared/ui-basics/index';
import * as Style from './Style'
import {useTranslation} from 'react-i18next'

export default function Category({ filter, setFilter, category }) {
	const { t } = useTranslation();
	return (
		<Style.CategoryWrapper>
			{category.map((cat) => (
				<GenericButton key={cat} variant="light-link" className="m-2 mx-3" onClick={() => setFilter(cat)} active={filter === cat}>
					{t(cat)}
				</GenericButton>
			))}
		</Style.CategoryWrapper>
	);
}
