import React, { forwardRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { List } from "react-virtualized";
import { DefaultPickListSelectCard } from "shared/components/cards/cx-cards/DefaultPickListSelectCard";
import { useClickOutside } from "shared/hooks";
import { SearchBar } from "shared/ui-basics/index";
import * as Style from "./style";

const SearchPickList = forwardRef(
	(
		{
			currentItem,
			specificItems,
			allItems,
			linkedAttribute,
			idAttribute = "id",
			handleCreate,
			entityType,
			handleLinkRecord,
			noItemsText = "",
			titleAttribute = "subject",
			showConfirmAssociatedModal = false,
			isPickListCardDisabledFunction,
			specificItemsLabel,
			SelectCardComponent = DefaultPickListSelectCard,
		},
		ref
	) => {
		const { t } = useTranslation();
		const innerRef = React.useRef();
		useClickOutside(innerRef, () => ref.current.hide(), ["insight-pick", "searchPickList", "p-inputtext", "buttons__Button", "ellipsis", "Description","DefaultPickListSelectCard","ui-basics__FlexBetweenAlignCenter"]);
		const [globalFilterValue, setGlobalFilterValue] = React.useState("");
		const handleChange = (e) => setGlobalFilterValue(e.target.value);
		const isSearchOnly = !handleCreate;

		const createRecord = () => {
			ref.current.hide();
			handleCreate({ entityType, globalFilterValue });
			setGlobalFilterValue("");
		};

		const filterList = allItems?.filter((data) => {
			const value = data?.[titleAttribute]?.toLowerCase();
			return value?.includes(globalFilterValue?.toLowerCase());
		});

		filterList?.sortAsc(titleAttribute)

		const showCreationButton = !isSearchOnly && filterList?.length === 0 && globalFilterValue?.length > 0;
		const Search = SearchBar({
			globalFilterValue,
			handleChange,
			size: "small",
			label: isSearchOnly ? `SEARCH_GENERIC` : `SEARCH_CREATE_GENERIC`,
			createAction: showCreationButton && createRecord,
			disabledCreation: currentItem?.stageId?.length === 0 || currentItem?.ownerId?.length === 0,
		});

		const linkRecord = (recordId, linkRelated = false) => {
			ref.current.hide();
			handleLinkRecord({ recordId, linkedAttribute, linkRelated });
		};

		const isPickListCardDisabled = ({ pickListCardData }) => {
			if (isPickListCardDisabledFunction) return isPickListCardDisabledFunction({ pickListCardData });
			return currentItem?.[linkedAttribute]?.some((linkedItem) => {
				const id = linkedItem?.id || linkedItem;
				return id === pickListCardData.id;
			});
		};
		const list = specificItems || filterList;

		return (
			<div className="position-relative" ref={innerRef} >
				<Style.WrapperOverlayPanel showCloseIcon ref={ref} id="overlay_panel">
					<div className="p-2 mt-3 position-relative">{Search}</div>
					{specificItemsLabel && <Style.CustomLabel className="p-2">{t(specificItemsLabel)}</Style.CustomLabel>}
					<Style.ItemsContainer minHeight="80" maxHeight="200">
						{list?.length > 0 ? (
							<List
								width={503}
								height={200}
								rowCount={list.length}
								rowHeight={66}
								rowRenderer={({ key, index, style }) => (
									<div key={list[index].id} style={style} id={`searchPickList${list[index].id}`}>
										<SelectCardComponent
											key={index}
											obj={list[index]}
											link={linkRecord}
											refOver={ref}
											entity={list[index]}
											label={t(specificItemsLabel)}
											idAttribute={idAttribute}
											titleAttribute={titleAttribute}
											showConfirmAssociatedModal={showConfirmAssociatedModal}
											disabled={isPickListCardDisabled({ pickListCardData: list[index] })}
											entityType={entityType}
										/>
									</div>
								)}
							/>
						) : (
							<div className="ms-2">{noItemsText}</div>
						)}
					</Style.ItemsContainer>
				</Style.WrapperOverlayPanel>
			</div>
		);
	}
);

SearchPickList.displayName = "SearchPickList";
export default memo(SearchPickList);
