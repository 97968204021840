import React from "react";
import { Col } from "reactstrap";
import styled from "styled-components/macro";

function Section({ children, titles, height = "500" }) {
	return (
		<CustomSection xs={12} height={height}>
			{React.Children.map(children, (child, index) => (
				<InnerSection key={index} className="p-4">
					{titles?.[index] && <Title className="mb-5">{titles[index]}</Title>}
					{child}
				</InnerSection>
			))}
		</CustomSection>
	);
}

Section.displayName = "Section";
export default Section;

const CustomSection = styled(Col)`
	display: flex;
	gap: 10px;
	background: transparent;
	height: ${(props) => props.height}px;
`;

const InnerSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 4px;
	height: 100%;
	border-radius: 4px;
	background: #fff;
	border: 1px solid #c4c4c4;
`;

const Title = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
`;
