import { useLocation } from "react-router-dom";
import { Input, SearchBar, SettingPageContainer } from "shared/ui-basics/index";
import React,{ useEffect, useState } from "react";
import { getResultService, useAdvancedSearch } from "../reducer/QueryAction";
import { SearchResultComponent } from "../components/search-result-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tab, Tabs } from "react-bootstrap";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { EntityListItem } from "shared/ui-basics/ui-components/EntityListItem";
import { useSelector } from "react-redux";
import { Spinner } from "shared/components/spinners/Spinner";
import { useDebounce } from "shared/hooks/index";

export const Search = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const query = search.getQuery();
    const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);

    const [key, setKey] = useState("Maps");
    const [value, setValue] = useState(query)
    const [result, setResult] = useState(undefined)
    const [count, SetCount] = useState(0);
    const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);
    const debouncedSearchTerm = useDebounce(value, 600);


    const callbackData = (data) => {
        if (data) {
            SetCount(0);
            Object.keys(data)?.map((key, index) => SetCount((prevState) => prevState + (data[key]?.length)))
            setResult(data);
        }
    }

    const [loading] = useAdvancedSearch(value, true, callbackData,debouncedSearchTerm);

    const handleChange = (e) => {
        setValue(e.target.value)
        if (e.target.value === "") {
            SetCount(0);
            setResult(undefined);
        }
    }
    const sentRequest = (e) => {
        if (e.key === 'Enter' && e.target.value !== "") {
            setResult(undefined);
        }
    }
    const Search = SearchBar({ globalFilterValue: value, handleChange, size: "small", floatLabel: false, onKeyDown: sentRequest });

    return <SettingPageContainer className="p-5">
        <h4>{t("SEARCH_RESULTS")}</h4>
        {value && Search}
        {loading && <Spinner />}
        {!loading && result &&
            <div>
                <div className="py-3 mb-3">{count} {t("RESULT_CONTAINS")} ”{value}”</div>
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3" mountOnEnter={true} unmountOnExit={true}>

                    <Tab eventKey="Folders" title={t("JS_67") + ` (${result[EntityList.Global]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Global]?.map((action, index) => <EntityListItem key={index} entity={action} entityType={EntityList.Global} disableClick={"true"} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>
                    {isSubscriptionAllowed && <Tab eventKey="cxPrograms" title={t("CX_PROGRAMS") + ` (${result[EntityList.CXProgram]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.CXProgram]?.map((program, index) => <EntityListItem key={index} entity={program} entityType={EntityList.CXProgram} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>
                    }

                    <Tab eventKey="Persona" title={t("ASPX_98") + ` (${result[EntityList.Persona]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Persona]?.map((persona, index) => <EntityListItem key={index} entity={persona} entityType={EntityList.Persona} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>

                    <Tab eventKey="Maps" title={t("JS_71") + ` (${result[EntityList.CustomerJourney]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.CustomerJourney]?.map((cjm, index) => <EntityListItem key={index} entity={cjm} entityType={EntityList.CustomerJourney} />)}
                            </div>

                        </ScrollContainer>
                    </Tab>

                    <Tab eventKey="Insights" title={t("INSIGHT")+"s" + ` (${result[EntityList.Insight]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Insight]?.map((insight, index) => <EntityListItem key={index} entity={insight} entityType={EntityList.Insight} />)}
                            </div>

                        </ScrollContainer>
                    </Tab>

                    {isSubscriptionAllowed && <Tab eventKey="Opportunities" title={t("OPPORTUNITIES") + ` (${result[EntityList.Opportunity]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Opportunity]?.map((opp, index) => <EntityListItem key={index} entity={opp} entityType={EntityList.Opportunity} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>}



                    {isSubscriptionAllowed && <Tab eventKey="Solutions" title={t("SOLUTIONS") + ` (${result[EntityList.Solution]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Solution]?.map((sol, index) => <EntityListItem key={index} entity={sol} entityType={EntityList.Solution} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>
                    }


                    {isSubscriptionAllowed && <Tab eventKey="Actions" title={t("JS_80") + ` (${result[EntityList.Action]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Action]?.map((action, index) => <EntityListItem key={index} entity={action} entityType={EntityList.Action} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>
                    }
                    <Tab eventKey="Projects" title={t("PROJECTS") + ` (${result[EntityList.Project]?.length || 0})`}>
                        <ScrollContainer>
                            <div className="mt-4 px-3">
                                {result[EntityList.Project]?.map((action, index) => <EntityListItem key={index} entity={action} entityType={EntityList.Project} />)}
                            </div>
                        </ScrollContainer>
                    </Tab>




                </Tabs>
            </div>
        }
    </SettingPageContainer>
}
const ScrollContainer = styled.div`
	width: 100%;
	height: calc(100vh - 280px);
`;