import { error, success } from "@redux-requests/core";
import { v4 as uuidv4 } from "uuid";
import { EMPTY_GUID, hasAttribute } from "@utils/helpers";
import { currentWorkspaceId } from "index";

const initialState = {
	subscriptionDetails: {},
	userSetting: {},
	users: [],
	configurations: {},
	organization: {},
	authorizedUsers: [],
	trainings: [],
	loading: false,
	connector: [],
	allWorkspaces: [],
	currentWorkspaceAuthorizedUsers: [],
	currentWorkspaceTeams: [],
};

const settingsReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
    const responseData = action?.response?.data;
	switch (action.type) {
		case success("GET_SUBSCRIPTION_DETAILS"):
			if (responseData.aiPurchased === false) {
				return {
					...state,
					subscriptionDetails: { ...responseData, aiEnabled: false, aiConsentGiven: false } || {},
				};
			}
			return {
				...state,
				subscriptionDetails: responseData || {},
			};
		case success("GET_ALL_REGISTERED_USERS"):
			return {
				...state,
				users: responseData.map((user) => ({ ...user, fullName: `${user.firstName} ${user.lastName}` })) || {},
			};
		case success("GET_CONFIGURATIONS"):
			return {
				...state,
				configurations: responseData || {},
			};
		case success("GET_ORGANIZATION_SETTINGS"): {
			const { url } = action?.meta?.requestAction?.request;
			if (url.includes("redirectReducer")) return state;
			return {
				...state,
				organization: { ...responseData, logoRefreshValue: null } || {},
			};
		}
		case success("UPDATE_ACCOUNT_SETTINGS"): {
			return {
				...state,
				organization: {
					...state.organization,
					defaultDisplayLanguage: Number(requestData?.newLangValue),
					showSampleData: requestData?.newShowSampleValue,
				},
			};
		}
		case success("UPDATE_ACCOUNT_IMAGE"):
			return {
				...state,
				organization: {
					...state.organization,
					logoRefreshValue: uuidv4(),
				},
			};
		case success("DELETE_ACCOUNT_IMAGE"):
			return {
				...state,
				organization: {
					...state.organization,
					logoRefreshValue: uuidv4(),
				},
			};
		case success("UPDATE_INTEGRATION_CREDENTIALS"):
			return {
				...state,
			};
		case success("CREATE_USER"): {
			const data = action?.response?.data;
			return {
				...state,
				users: data.id !== "00000000-0000-0000-0000-000000000000" ? [...state.users, data] : [...state.users],
			};
		}
		case "DELETE_USER":
			return {
				...state,
				loading: true,
			};
		case success("DELETE_USER"):
			return {
				...state,
				users: state?.users?.filter((user) => user.id !== requestData.registeredUserId),
				loading: false,
			};
		case error("DELETE_USER"):
			return {
				...state,
				loading: false,
			};
		case "UPDATE_USER":
			return {
				...state,
				loading: true,
			};
		case success("UPDATE_USER"):
			return {
				...state,
				users: state.users?.map((user) => {
					if (user.id === requestData.userToUpdate.id) {
						user = responseData;
					}
					return user;
				}),
				loading: false,
			};
		case "UPDATE_USER_TYPE":
			return {
				...state,
				users: state.users?.map((user) => {
					if (user.id === action?.request?.data?.params?.updatedUser.id) {
						user = action?.request?.data?.params?.updatedUser;
					}
					return user;
				}),
				loading: false,
			};
		case error("UPDATE_USER"):
			return {
				...state,
				loading: false,
			};
		case "ACTIVATE_USERS":
			return {
				...state,
				loading: true,
			};
		case success("ACTIVATE_USERS"):
			return {
				...state,
				loading: false,
				users: state?.users?.map((user) => {
					const newUser = { ...user };
					if (responseData?.includes(user.id)) {
						newUser.isActive = true;
					}
					return newUser;
				}),
			};
		case error("ACTIVATE_USERS"):
			return {
				...state,
				loading: false,
			};
		case "DEACTIVATE_USER":
			return {
				...state,
				loading: true,
			};
		case success("DEACTIVATE_USERS"):
			return {
				...state,
				loading: false,
				users: state?.users?.map((user) => {
					const newUser = { ...user };
					if (responseData?.includes(user.id)) {
						newUser.isActive = false;
					}
					return newUser;
				}),
			};
		case error("DEACTIVATE_USER"):
			return {
				...state,
				loading: false,
			};
		case success("GET_AUTHORIZED_USERS"): {
			const currentWorkspaceUserIds = state?.allWorkspaces?.find((ws) => ws.id === currentWorkspaceId)?.authorizedUsers.map((au) => au.id);
			return {
				...state,
				authorizedUsers: responseData || [],
				currentWorkspaceAuthorizedUsers: responseData?.filter((data) => currentWorkspaceUserIds?.includes(data?.id)),
			};
		}
		case success("GET_ALL_ROLES"):
			return {
				...state,
				roles: responseData || [],
			};

		case success("GET_SETTING_USER"): {
			const savedViewsArr = Object.entries(responseData?.savedViews).map((x) => x[1]);
			const savedDefaultViews = responseData?.savedDefaultViews ? JSON.parse(responseData?.savedDefaultViews) : {};
			return {
				...state,
				userSetting: { ...responseData, savedViews: savedViewsArr, savedDefaultViews: savedDefaultViews } || [],
			};
		}
		case success("UPDATE_SETTING_USER"): {
			const savedViewsArr = Object.entries(responseData?.savedViews).map((x) => x[1]);
			const savedDefaultViews = responseData?.savedDefaultViews ? JSON.parse(responseData?.savedDefaultViews) : {};
			return {
				...state,
				userSetting: { ...responseData, savedViews: savedViewsArr, savedDefaultViews: savedDefaultViews } || [],
			};
		}
		case success("UPDATE_SAVED_VIEW"): {
			const savedView = requestData.savedView;
			const savedViews = JSON.parse(JSON.stringify(state.userSetting.savedViewsByEntity));
			if (!hasAttribute(savedViews, savedView.type)) {
				savedViews[savedView.type] = [responseData];
			} else if (savedView.id !== EMPTY_GUID) {
				savedViews[savedView.type] = savedViews?.[savedView.type]?.map((att) => {
					if (att.id === savedView.id) return { ...responseData };
					return att;
				});
			} else {
				savedViews[savedView.type] = [...savedViews[savedView.type], responseData];
			}
			return {
				...state,
				userSetting: { ...state.userSetting, savedViewsByEntity: savedViews },
			};
		}
		case success("DELETE_SAVED_VIEW"): {
			const savedViewsArr = Object.entries(responseData?.savedViews).map((x) => x[1]);
			const savedDefaultViews = responseData?.savedDefaultViews ? JSON.parse(responseData?.savedDefaultViews) : {};
			return {
				...state,
				userSetting: { ...responseData, savedViews: savedViewsArr, savedDefaultViews: savedDefaultViews } || [],
			};
		}
		case "UPDATE_QUERY": {
			const { type, queryInfo } = action.payload;
			return {
				...state,
				userSetting: { ...state.userSetting, [type]: queryInfo },
			};
		}
		case success("CONNECTOR_INIT"): {
			return {
				...state,
				connector: action.response.data || [],
			};
		}
		case success("UPSERT_CONNECTOR"): {
			const { connector } = state;

			const { id } = requestData?.connector;
			const newConnector = action.response.data;
			if (id === EMPTY_GUID) {
				return {
					...state,
					connector: [...connector, newConnector],
				};
			} else {
				return {
					...state,
					connector: state?.connector?.map((currentConnector) => {
						if (currentConnector.id === id) {
							currentConnector = newConnector;
						}
						return currentConnector;
					}),
				};
			}
		}
		case success("GET_ALL_TEAMS"): {
			const currentWorkspaceTeamIds = state?.allWorkspaces
				?.find((ws) => ws.id === currentWorkspaceId)
				?.authorizedUsers?.map((au) => {
					if (au.accessType === "team") return au.id;
				});
			return {
				...state,
				teams: responseData || {},
				currentWorkspaceTeams: Object.values(responseData)
					?.map((team) => ({ ...team, fullName: team.name }))
					.filter((t) => currentWorkspaceTeamIds?.includes(t.id)),
			};
		}
		case success("UPSERT_TEAM"): {
			return {
				...state,
				teams: {
					...state.teams,
					[responseData.id]: responseData,
				},
			};
		}

		case success("DELETE_TEAM"): {
			const { [requestData.teamId]: _, ...remainingTeams } = state.teams;
			return {
				...state,
				teams: remainingTeams,
			};
		}

		case success("ADD_MEMBERS_TO_TEAM"): {
			const updatedTeams = requestData.teamIds.reduce((acc, teamId) => {
				return {
					...acc,
					[teamId]: {
						...state.teams[teamId],
						memberUserIds: [...state.teams[teamId].memberUserIds, ...requestData.userIds],
					},
				};
			}, {});
			return {
				...state,
				teams: { ...state.teams, ...updatedTeams },
			};
		}

		case success("REMOVE_MEMBER_FROM_TEAM"): {
			const { userId, teamId } = requestData;
			const updatedTeam = state.teams[teamId];
			const updatedMembers = updatedTeam.memberUserIds.filter((id) => id !== userId);
			return {
				...state,
				teams: {
					...state.teams,
					[teamId]: {
						...state.teams[teamId],
						memberUserIds: updatedMembers,
					},
				},
			};
		}
		case success("GET_ALL_TRAININGS"):
			return {
				...state,
				trainings: responseData || {},
			};
		case "AGREEMENT_POLICY": {
			return {
				...state,
				subscriptionDetails: {
					...state.subscriptionDetails,
					aiConsentGiven: action?.request?.data?.newValue,
				},
			};
		}
		case "ENABLE_ALEX": {
			return {
				...state,
				subscriptionDetails: {
					...state.subscriptionDetails,
					aiEnabled: action?.request?.data?.newValue,
				},
			};
		}
		case success("UPDATE_USERS_TEAMS"): {
			const { userIds, idsToAdd } = requestData;
			const teams = Object.values(state.teams);
			const updatedTeams = teams?.map((team) => {
				userIds.forEach((userId) => {
					if (idsToAdd?.includes(team.id)) {
						if (!team.memberUserIds.find((id) => id === userId)) {
							team.memberUserIds.push(userId);
						}
					}
				});
				return team;
			});
			return {
				...state,
				teams: updatedTeams?.reduce((acc, item) => {
					acc[item.id] = item;
					return acc;
				}, {}),
			};
		}
		case success("GET_ALL_WORKSPACES"): {
			return {
				...state,
				allWorkspaces: responseData,
			};
		}
		default:
			return state;
	}
};
export default settingsReducer;
