import { Container } from "reactstrap";
import styled, { css, keyframes } from "styled-components/macro";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button, Colors } from "shared/ui-basics/index";
import { NavLink } from "react-router-dom";
import { ReactComponent as CookWizard } from "@Assets/All/Potion-Wizard.svg";
import { ReactComponent as Wand } from "@Assets/All/wand-Wizard.svg";

export const Potion = styled(CookWizard)`
	width: 120px;
	height: fit-content;
`;
const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }

`;
const upDown = keyframes`
   from {
        transform: translateY(-5%);
    }
    to {
        transform: translateY(5%);
    }
`;
const wave = keyframes`
  from {
    transform: rotate(25deg);
  }
  to {
    transform: rotate(-25deg);
  }

`;
const appearBottom = keyframes`
  0% {
    transform: translateY(+500%);

    }
    50% {
      transform: translateY(-50%);
      transform: rotate(5deg);
    }
    100% {
      transform: translateY(0);
      transform: rotate(-5deg);

    }

  from {
    transform: translateY(+500%);
  }
  to {
    transform: translateY(0)
  }

`;

const fallAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-50%);
  }
  75% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(0%);
  }

`;
export const BackButton = styled(Button)`
	position: absolute;
	top: 25px;
	left: 0;
	border: 0 !important;
	z-index: 50;
`;
export const MainContainer = styled(Container)`
	position: relative;
	overflow: auto;
	height: 100%;
	display: grid;
	> div {
		height: 100%;
	}
	.rsw_2Y {
		height: 100%;
	}
	.rsw_2f {
		height: 100%;
	}
`;
export const MainImage1 = styled.img`
	width: 150px;
	height: 150px;
	${(props) =>
		props.isAnimating &&
		css`
			animation: ${fadeIn} 2s ease-in;
		`} align-self: center;
	/* :hover {
         animation: ${upDown} 0.4s ease-in-out infinite alternate;

  } */
`;
export const ImageContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
`;
export const MainImage2 = styled.img`
	width: 150px;
	height: 150px;
	${(props) =>
		props.isAnimating &&
		css`
			animation: ${fadeIn} 2s ease-in;
		`}
	align-self: center;
	/* :hover {
         animation: ${upDown} 0.4s ease-in-out infinite alternate; */
`;
export const Title = styled.div`
	font-size: 24px;
	align-self: center;
`;
export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
	align-self: center;
`;
export const CookingImg = styled.img`
	object-fit: scale-down;
	width: 220px;
`;
export const NameContainer = styled.div`
	width: 50%;
`;
export const TextArea = styled(InputTextarea)`
	margin-top: 1rem;
	width: 100%;
	:focus {
		border: 1px solid ${Colors.primaryText} !important;
		/* -webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important; */
		box-shadow: none !important;
	}
	&:hover {
		border-color: ${Colors.primaryText} !important;
	}
	::placeholder {
		color: #c4c4c4;
		font-style: italic;
	}
`;

export const StateLabel = styled.div`
	width: 68px;
	text-align: center;
	border-radius: 5px;
	height: 26px;
	background: ${(props) => (props.isFuture ? "#FDE7CB" : "#D2EEFB")};
	color: ${(props) => (props.isFuture ? "#F48E09" : "#20A9E9")};
	${(props) =>
		props.variant === "small" &&
		css`
			font-size: 14px !important;
		`}
`;
export const State = styled(Dropdown)`
	margin-top: 1rem;
	box-shadow: none !important;

	.p-dropdown:not(.p-disabled) {
		border: 1px solid #18aead !important;
		/* -webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important; */
		box-shadow: none !important;
	}
	:focus {
		border: 1px solid #18aead !important;
		/* -webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important; */
		box-shadow: none !important;
	}
	:hover {
		border-color: #18aead !important;
	}
	:active {
		border-color: #18aead !important;
	}
`;
export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-height: 330px;
	overflow: auto;
	gap: 30px;
	justify-content: center;
	padding-top: 20px;
	min-height: 330px;
`;
export const WandButton = styled(Button)`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #f96209;
	border: none !important;
	box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
	position: absolute;
	left: 47%;
	top: -55%;
	svg {
		width: 28px;
		height: 28px;
	}
	${(props) =>
		props.showwave === "true" &&
		css`
			animation: ${wave} 0.8s ease-in-out infinite alternate !important;
		`}
	:hover {
		background: #f96209;
		color: #ffffff;
	}
	:focus {
		background: #f96209;
		color: #ffffff;
	}
`;

export const StepWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	height: 100%;
	//${(props) => props.vh && `min-height:${props.vh}vh`}
	${WandButton} {
		${(props) =>
			props.showAnimation &&
			css`
				animation: ${fallAnimation} 1s linear;
			`}
	}
`;
export const WandContainer = styled.div`
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const MagicWand = styled(Wand)`
	width: 150px;
	/* height: 120px;
    svg {
      height: 120px;
    } */

	/* #objects {
    ${(props) =>
		props.showAnimation &&
		css`
			animation: ${wave} 0.3s ease-in-out infinite alternate;

			z-index: 500;
		`}
    } */
	/* #wandStars {
    ${(props) =>
		props.showAnimation &&
		css`
			animation: ${fadeIn} 0.3s ease-in-out infinite alternate;

			z-index: 500;
		`}

  } */
`;
export const GOTemplate = styled(NavLink)`
	color: #605f60 !important;

	:hover {
		text-decoration: underline;
	}
`;
export const Mandatory = styled.div`
	color: #c4c4c4;
	font-style: italic;
`;
