import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useSelector } from "react-redux";
import CustomTooltip from "../CustomTooltip";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { getDefaultName } from "@utils/helpers";

function GroupCard({ item, route }) {
	const history = useHistory();
	const location = useLocation();
	const pathname = location.pathname;

	const allStatus = useSelector((state) => state.libraries?.status);
	const solutionsStatus = allStatus?.solutions;
	const statusColor = solutionsStatus?.filter((status) => status.id === item?.statusId).colorCode;

	const labelRef = useRef(null);
	const [isEllipsisActive, setIsEllipsisActive] = useState(false);

	useEffect(() => {
		if (labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth) {
			setIsEllipsisActive(true);
		}
	}, [labelRef?.current]);

	const handleClick = () => {
		history.push(`${pathname}?tab=chart`)
		history.push(`/${route}?id=${item.id}&origin=1`)
	};

	return (
		<CustomTooltip text={item?.subject} disabled={!isEllipsisActive}>
			<CardWrapper className="p-1 me-2" statusColor={statusColor} onClick={handleClick}>
				<Title ref={labelRef} className="ms-2">
					{item?.subject.defaultPlaceholder(getDefaultName(pathname))}
				</Title>
			</CardWrapper>
		</CustomTooltip>
	);
}

export const GroupPointSelector = ({ isOpen, onClose, items, route }) => {
	const location = useLocation();
	const hideForPdf = location.search.hideForPdf();

	return (
		<GroupPoint fade={false} isOpen={isOpen} toggle={onClose} backdrop="static" ispdf={hideForPdf}>
			{items.map((item) => (
				<GroupCard key={item.id} item={item} route={route} />
			))}
		</GroupPoint>
	);
};

const GroupPoint = styled.div`
	z-index: 5000;
	min-height: 38px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	${(props) =>
		!props.ispdf &&
		css`
			max-height: 200px;
			overflow: scroll;
		`}
`;

const CardWrapper = styled.div`
	width: 400px;
	border: 1px solid #c4c4c4;
	border-left: 6px solid ${(props) => props.statusColor};
	border-radius: 4px;
	display: flex;
	align-items: center;

	background-color: white;
	:hover {
		cursor: pointer;
		box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
	}
`;

const Title = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #333333;
	align-self: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
