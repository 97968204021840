import { isReadOnly } from "@utils/helpers";
import { IconDispatcher } from "shared/ui-basics/index";
import { PopoverSubscription } from "../../../shared/popovers/PopoverSubscription";
import { useSelector } from "react-redux";
import { NavLinkComponent } from "../SideBar/NavComponents";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

export const NavigationElement = (props) => {
	const { t } = useTranslation();
	const { allowPopover, readOnlyMode, access } = props;

	const isNavigationBarOpen = useSelector(({ ui }) => ui.page.sidebar.isOpen);
	const userInfo = useSelector(({ auth }) => auth?.userInfo || {});
	const userType = Number(userInfo?.UserType);
	const isSubscriptionAllowed = access?.includes(userInfo?.UserSubscriptionPlanName);

	if (readOnlyMode)
		return (
			<>
				{isReadOnly(userType) && (
					<ReadOnlyWarning variant={isNavigationBarOpen ? "open" : "close"}>
						<EyeWrapper variant={isNavigationBarOpen ? "open" : "close"}>{IconDispatcher("VIEW")}</EyeWrapper>
						<ReadOnlyWarningText>{t("NAV_READ_ONLY_WARNING")}</ReadOnlyWarningText>
					</ReadOnlyWarning>
				)}
			</>
		);
	if (!isNavigationBarOpen) return null;

	return allowPopover ? (
		<PopoverSubscription disabled={isSubscriptionAllowed} access={access}>
			<NavLinkComponent {...props} />
		</PopoverSubscription>
	) : (
		<NavLinkComponent {...props} />
	);
};

const ReadOnlyWarning = styled.div`
	background-color: #f8c8ae;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 10px;
	border-radius: 4px;
	display: flex;

	${(props) =>
		props.variant === "open" &&
		css`
			justify-content: top;
			align-items: flex-start;
			width: fit-content;
			height: fit-content;
			padding: 0.5rem;
			margin-left: 7px;
		`}

	${(props) =>
		props.variant === "close" &&
		css`
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 15px;
			padding: 1rem;
			margin-left: 10px;
		`}
`;

const ReadOnlyWarningText = styled.div`
	color: #605f60;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	height: 100%;
	text-align: left;
`;

const EyeWrapper = styled.div`
	${(props) =>
		props.variant === "open" &&
		css`
			margin-right: 10px;
			height: 100%;
			align-self: flex-start;
			transform: translate(0px, -4px);
		`}

	${(props) =>
		props.variant === "close" &&
		css`
			transform: translate(0px, 0px);
		`}
`;
