import viewIcon from "@Assets/All/viewIcon.svg";
import { getSessionId } from "@redux/memoizedSelectors";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { pictureValidationPersona } from "../../../../utils/helpers";


const PersonaBubble = ({ persona, size, onClick, className, border, forceRender, displayCountBubble, count, isActive, isInactive, viewHover = false, tooltip }) => {
	const sessionId = useSelector(getSessionId);
	const hideForPdf = useLocation().search.hideForPdf();
	return (
		<>
			{!displayCountBubble && (
				<ImageWrapper size={size} viewHover={viewHover}>
					{isInactive && <InactiveBackground size={size} onClick={onClick} />}
					<PersonaImage
						alt="persona-image"
						className={className}
						src={pictureValidationPersona(persona, sessionId, forceRender)}
						size={size}
						onClick={onClick}
						border={border}
						isActive={isActive}
						loading={hideForPdf ? "eager" : "lazy"}
						title={tooltip && persona?.name}
					/>
				</ImageWrapper>
			)}

			{displayCountBubble && (
				<div className="d-flex">
					<CountBubble className={className} size={size}>
						{"+"}
						{count}
					</CountBubble>
				</div>
			)}
		</>
	);
};

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	border-radius: 50%;

	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			width: 24px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 40px;
			width: 40px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 48px;
			width: 48px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 60px;
			width: 60px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 80px;
			width: 80px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 120px;
			width: 120px;
		`}

	${(props) =>
		props.viewHover &&
		css`
			:hover {
				cursor: pointer;
				background-image: url(${viewIcon});
				background-repeat: no-repeat;
				background-position: center;
				background-size: 50%;
				background-color: rgba(255, 255, 255, 0.5);
				opacity: 0.8;
				z-index: 1;
				img {
					opacity: 0.4;
				}
			}
		`};
`;

const PersonaImage = styled.img`
	opacity: 1;
	border-radius: 50%;
	object-fit: cover;
	box-sizing: border-box;
	transform: translate3d(0, 0, 1px);

	width: ${(props) => props.size}px;
	min-width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	${(props) =>
		props.isActive &&
		`
            border: 2px solid #FF2B77;
        `}
	${(props) => props.border && `border: 2px solid ${props.border};`}
    ${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			width: 24px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 40px;
			width: 40px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 48px;
			width: 48px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 60px;
			width: 60px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 80px;
			width: 80px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 120px;
			width: 120px;
		`}
`;

const CountBubble = styled.div`
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1 !important;
	background-color: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 50%;
	object-fit: cover;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	:hover {
		cursor: default;
	}
	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			width: 24px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 40px;
			width: 40px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 48px;
			width: 48px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 60px;
			width: 60px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 80px;
			width: 80px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 120px;
			width: 120px;
		`}
`;
const InactiveBackground = styled.div`
	position: absolute;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 15;
	cursor: pointer;
	border-radius: 50%;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;

	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			width: 24px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 40px;
			width: 40px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 48px;
			width: 48px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 60px;
			width: 60px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 80px;
			width: 80px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 120px;
			width: 120px;
		`}
`;

export default PersonaBubble;
