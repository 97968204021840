import { Input } from "shared/ui-basics/input";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

function EditableTitle({ item, attribute, update, placeholder, classNames, disabled }) {
	const [title, setTitle] = React.useState(item?.[attribute] || "");

	const handleBlur = () => {
		const updatedItem = { ...item, [attribute]: title?.truncate(100) };
		if (title !== item[attribute]) update({ item: updatedItem, modifiedAttribNames: [attribute] });
	};

	return (
		<CustomInput
			className={classNames}
			variant="default"
			name={attribute}
			placeholder={placeholder}
			value={title}
			onBlur={() => handleBlur("subject")}
			onChange={(e) => setTitle(e.target.value?.truncate(100))}
			disabled={disabled}
		/>
	);
}

EditableTitle.displayName = "EditableTitle";

EditableTitle.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	item: PropTypes.object,
	attribute: PropTypes.string,
	update: PropTypes.func,
	placeholder: PropTypes.string,
	stageId: PropTypes.string,
};
export default EditableTitle;

export const CustomInput = styled(Input)`
	width: 100%;
	resize: none;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	color: #333333;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	border-radius: 4px;
	width: 100%;
	min-height: 60px;
	padding: 4px;
	border: 1px solid transparent;

	::before {
		font-style: italic !important;
		color: #c4c4c4 !important;
		font-weight: 300;
		line-height: 150%; /* 21px */
		font-size: 14px;
	}

	&.form-control:focus {
		background-color: transparent;
		border: 1px solid #605f60 !important;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	&.form-control:hover {
		border: 1px solid #605f60 !important;
	}

	&.form-control:disabled,
	.form-control[readonly] {
		background-color: #ffffff;
		color: #333333;
		opacity: 1;
		cursor: no-drop;
	}
`;
