import { constructUrl, detectImageExtension, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";
import { t } from "i18next";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};

const BASE_URL = "api/cxProgram/";

const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" });
const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" });

export const getAllCxProgram = (props) =>
	createGetAction({
		type: "CX_PROGRAM_GET_ALL",
		endPoint: constructUrl("getAll", {
			redirectReducer: props?.redirectReducer,
		}),
	});

export const GetCxProgram = ({ CxProgramIdStr }) =>
	createGetAction({
		type: "GET_CX_PROGRAM",
		endPoint: constructUrl("get", {
			cxProgramId: CxProgramIdStr,
		}),
	});

export const createCxProgram = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_CX_PROGRAM",
			endPoint: "create",
			data: {
				newCxProgramParams: { name: encode(props?.name) },
				linkedFolderId: props?.folderId,
			},
		}),
		displayToast: "CREATE_NO_SUCCESS_TOAST",
	});

export const updateCxProgram = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CX_PROGRAM",
			endPoint: "update",
			data: {
				modifiedCxProgram: { ...props?.modifiedCxProgram, name: encode(props?.modifiedCxProgram.name.truncate(100)) },
				modifiedAttribNames: props?.modifiedAttribNames,
			},
		}),
	});

export const copyCxProgram = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_CX_PROGRAM",
			endPoint: "copy",
			data: {
				srcCxProgramId: props?.id,
				newName: encode(props?.name),
				workspaceIds: props?.workspaceIds,
			},
		}),
		displayToast: "COPY",
	});

export const archiveCxProgram = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_CX_PROGRAM",
			endPoint: "archive",
			data: {
				id: props?.id,
			},
		}),
		displayToast: "ARCHIVE",
	});

export const restoreCxProgram = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_CX_PROGRAM",
			endPoint: "restore",
			data: {
				id: props?.id,
			},
		}),
		displayToast: "RESTORE",
	});

export const deleteCxProgram = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CX_PROGRAM",
			endPoint: "delete",
			data: {
				id: props?.id,
			},
		}),
		displayToast: "DELETE",
	});

export const deleteCxProgramPermanent = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CX_PROGRAM_PERMANENT",
			endPoint: "deletePermanently",
			data: {
				id: props?.id,
			},
		}),
		displayToast: "DELETE",
	});

export const getCxProgramUsers = ({ cxProgramId, forceViewer, isPrint, userId, from }) =>
	createGetAction({
		type: "GET_CXPROGRAM_USERS",
		endPoint: constructUrl("getAuthorizedUsers", {
			cxProgramId,
			forceViewer,
		}),
		params: { isPrint, userId, from },
	});

export const shareCxProgram = ({ recId, userId, roleDetails, teamId, userIdName, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_USERS_SINGLE_CX_PROGRAM",
			endPoint: "share",
			data: {
				recId,
				userId,
				roleDetails,
				teamId,
			},
			param: {
				fullName: userIdName,
			},
		}),
		displayToast: "UPDATE",
	});

export const unshareCxProgram = ({ recId, userId, teamId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UNSHARE_CX_PROGRAM",
			endPoint: "unShare",
			data: {
				recId,
				userId,
				teamId,
			},
		}),
	});

export const shareManyCxPrograms = ({ recIds, usersIds, roleDetails, teamIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_USERS_MANY_CX_PROGRAMS",
			endPoint: "shareMany",
			data: {
				recIds: recIds ? recIds : [],
				userIds: usersIds ? usersIds?.split("|") : [],
				roleDetails,
				teamIds: teamIds ? teamIds?.split("|") : [],
			},
		}),
	});

export const archiveManyCxPrograms = ({ ids, toast, onSuccess }) =>
	genericService({
		ids,
		action: createPostAction({
			type: "ARCHIVE_MANY_CX_PROGRAMS",
			endPoint: "archiveMany",
			data: {
				ids,
			},
		}),
		displayToast: toast,
		onSuccess,
	});

export const deleteManyCxPrograms = ({ ids, toast, onSuccess }) =>
	genericService({
		ids,
		action: createPostAction({
			type: "DELETE_MANY_CX_PROGRAMS",
			endPoint: "deleteMany",
			data: {
				ids,
			},
		}),
		displayToast: toast,
		onSuccess,
	});

export const deleteManyCxProgramsPermanent = ({ ids, toast, onSuccess }) =>
	genericService({
		ids,
		action: createPostAction({
			type: "DELETE_MANY_CX_PROGRAMS_PERMANENT",
			endPoint: "deletePermanentlyMany",
			data: {
				ids,
			},
		}),
		displayToast: toast,
		onSuccess,
	});

export const restoreManyCxPrograms = ({ ids, onSuccess }) =>
	genericService({
		ids,
		action: createPostAction({
			type: "RESTORE_MANY_CX_PROGRAMS",
			endPoint: "restoreMany",
			data: {
				ids,
			},
		}),
		displayToast: "RESTORE",
		onSuccess,
	});

export const updateCxProgramAttribOrder = ({ cxProgramId, newLayout, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CX_PROGRAM_ORDER",
			endPoint: "updateAttributesPositions",
			data: {
				cxProgramId,
				newPositions: newLayout,
			},
		}),
	});

export const createCxProgramAttribute = ({ cxProgramId, attributeType, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ADD_ATTRIBUTE",
			endPoint: "addAttribute",
			data: {
				cxProgramId,
				attributeType,
			},
		}),
	});

export const deleteCxProgramAttribute = ({ cxProgramId, attributeId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_ATTRIBUTE",
			endPoint: "deleteAttribute",
			data: {
				cxProgramId,
				attributeId,
			},
		}),
	});

export const updateCxProgramAttributeColor = ({ cxProgramId, attributeId, newColor, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CXPROGRAM_ATTRIBUTE_COLOR",
			endPoint: "updateAttribute",
			data: {
				cxProgramId,
				attributeId,
				newColor,
				modifiedAttribNames: ["color"],
			},
		}),
	});

export const updateCxProgramAttributeTitle = ({ cxProgramId, attributeId, newName, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CXPROGRAM_ATTRIBUTE_NAME",
			endPoint: "updateAttribute",
			data: {
				cxProgramId,
				attributeId,
				newName: encode(newName),
				modifiedAttribNames: ["name"],
			},
		}),
	});

export const updateCxAttributeDescription = ({ cxProgramId, attributeId, newDescription, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CXPROGRAM_ATTRIBUTE_DESC",
			endPoint: "updateAttribute",
			data: {
				cxProgramId,
				attributeId,
				newDescription: encode(newDescription),
				modifiedAttribNames: ["description"],
			},
		}),
	});

export const upsertCxAttributeItem = ({ attributeItem, attribute, cxGoal, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPSERT_CXPROGRAM_ATTRIBUTE_ITEM",
			endPoint: "upsertAttributeItem",
			data: {
				attributeItem: { ...attributeItem, description: encode(attributeItem?.description), name: encode(attributeItem?.name) },
				attribute,
				cxGoal,
			},
		}),
	});

export const deleteCxAttributeItem = ({ attributeItemId, attributeId, cxProgramId, cxGoalId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CXPROGRAM_ATTRIBUTE_ITEM",
			endPoint: "deleteAttributeItem",
			data: {
				attributeItemId,
				attributeId,
				cxProgramId,
				cxGoalId,
			},
		}),
	});

export const updateCxAttributeItemsOrder = ({ cxProgramId, newItemsOrder, attributeId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CXPROGRAM_ATTRIBUTE_ITEM_ORDER",
			endPoint: "updateAttribute",
			data: {
				cxProgramId,
				newItemsOrder,
				attributeId,
				modifiedAttribNames: ["itemsorder"],
			},
		}),
	});

export const linkRecords = ({ cxProgramId, entityName, ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "LINK_RECORD",
			endPoint: "linkRecords",
			data: {
				cxProgramId,
				recToLinkEntityName: entityName,
				recToLinkIds: ids,
			},
		}),
		displayToast: props?.displayToast || "UPDATE",
		toastParams: { success: t("RECORDS_LINKED_SUCCESS") },
	});

export const unlinkRecords = ({ cxProgramId, entityName, ids, unlinkRelated, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UNLINK_RECORD",
			endPoint: "unlinkRecords",
			data: {
				cxProgramId,
				recToUnlinkEntityName: entityName,
				recToUnlinkIds: ids,
				unlinkRelated,
			},
		}),
		displayToast: props?.displayToast || "UPDATE",
		toastParams: { success: t("RECORDS_UNLINKED_SUCCESS") },
	});

export const updateCxAttributeImage = ({ cxProgramId, attributeId, image, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CXPROGRAM_ATTRIBUTE_IMAGE",
			endPoint: "uploadAttributeImage",
			data: {
				cxProgramId,
				attributeId,
				imageExtension: detectImageExtension(image.split(",")[0]),
				imageBody64: image.split(",")[1],
			},
		}),
	});

export const deleteCxAttributeImage = ({ cxProgramId, attributeId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CXPROGRAM_ATTRIBUTE_IMAGE",
			endPoint: "deleteAttributeImage",
			data: {
				cxProgramId,
				attributeId,
			},
		}),
	});

export const GetStrategyFromCxProgram = ({ CxProgramIdStr }) => {
	return {
		type: "GET_STRATEGY_CX_PROGRAM",
		request: {
			url: `api/cxProgram/getStrategy?cxProgramId=${CxProgramIdStr}`,
			method: "GET",
		},
	};
};

export const getCxProgramExcelExportList = (ids, columns) =>
	createPostAction({
		type: "GET_CX_PROGRAM_EXCEL",
		endPoint: "getExcelExport",
		data: {
			ids,
			columns: columns?.join("|"),
		},
		responseType: "blob",
	});

export const getCxProgramLinkedExcelExportList = ({ entityName, id }) =>
	createGetAction({
		type: "GET_CX_PROGRAM_EXCEL",
		endPoint: constructUrl("getLinkedExcelExport", {
			entityName,
			id,
		}),
		responseType: "blob",
	});

export const resetCxProgram = () => {
	return {
		type: "RESET_PROGRAM",
	};
};

export const getPdfCxProgram = ({ cxProgramId, width, height, renderDelay }) =>
	createGetAction({
		type: "GET_PDF_EXPORT_CX_PROGRAM",
		endPoint: constructUrl("getPdfExport", {
			cxProgramId,
			width,
			height,
			renderDelay,
		}),
		responseType: "blob",
	});

export const resetCxProgramUsers = () => {
	return {
		type: "RESET_CXPROGRAMS_USERS",
	};
};

export const updateCxProgramImage = ({ cxProgramId, imageExtension, imageBody64, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CX_PROGRAM_IMAGE",
			endPoint: "updateImage",
			data: {
				cxProgramId,
				imageExtension,
				imageBody64,
			},
		}),
	});

export const deleteCxProgramImage = ({ cxProgramId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CX_PROGRAM_IMAGE",
			endPoint: "deleteImage",
			data: {
				cxProgramId,
			},
		}),
	});
