import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import EmptyData from "features/analytics/Components/EmptyData";
import { useSelector } from "react-redux";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { globalSentimentOverTimeDataSelector } from "../vocAnalysisDataSelectors";
import TextAndValueTooltip from "features/analytics/Components/Tooltips/TextAndValueTooltip";
import { TickStyle } from "features/analytics/Style";

function GlobalSentimentOverTime() {
    const data = useSelector(globalSentimentOverTimeDataSelector);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return <TextAndValueTooltip text={label} value={payload[0].value} />;
		}

		return null;
	};

	// ticksCount: number of numbers displayed on Y axis
	return (
		<>
			{data.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart
						data={data}
						margin={{
							top: 25,
							left: -30,
						}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }} tick={{ ...TickStyle }} />
						<YAxis domain={[1, 5]} tickCount={5} axisLine={false} tick={{ ...TickStyle }} />
						<Tooltip content={<CustomTooltip />} isAnimationActive={false} />
						<Area type="natural" dataKey="Value" stroke="#0078E7" strokeWidth="2" fill="#0078E7" fillOpacity={0.3} />
					</AreaChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="GLOBAL_SENTIMENT_OVER_TIME_REPORT_DESCRIPTION" icon="CHART-AREA" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

GlobalSentimentOverTime.displayName = "GlobalSentimentOverTime";
export default GlobalSentimentOverTime;
