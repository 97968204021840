import InitialsBubble from "features/settings/manage-users/components/initials-bubble";
import { forwardRef } from "react";
import { Token, TypeaheadInputMulti } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { IconDispatcher } from "shared/Icons";
import { TypeaheadWrapper } from "shared/modals/manage-access/ManageAccessStyle";
import { Label } from "shared/ui-basics";
import styled from "styled-components";

const UsersSelectorTypeahead = forwardRef(({ options, selected = [], placeholder, onChange = () => {}, showBorder = false, width = "100%" }, ref) => {
	const { t } = useTranslation();

	const renderListUsers = (option) => {
		const index = options?.findIndex((user) => user.id === option.id);
		const firstUserIndex = options?.findIndex((user) => Object.prototype.hasOwnProperty.call(user, "memberUserIds") === false && user.fullName !== "All");
		const isTeam = Object.prototype.hasOwnProperty.call(option, "memberUserIds") || option.fullName === "All";

		const icon = <IconWrapper className="me-1">{IconDispatcher("USER-GROUP")}</IconWrapper>;
		const fullName = option.fullName === "All" ? t("ALL_TEAM_MEMBERS") : option?.fullName;

		return (
			<div className="position-relative">
				{[firstUserIndex, 0].includes(index) && <Separator border={index === firstUserIndex} />}
				<UserWrapper className="mt-2">
					{index === 0 && <Label className="position-absolute top-0 pt-2">{t("TEAMS")}</Label>}
					{index === firstUserIndex && <Label className="position-absolute top-0 pt-2">{t("ASPX_210")}</Label>}
					{isTeam ? icon : <InitialsBubble initials={option?.fullName?.getInitials()?.slice(0, 3)} size="s" />}
					<div className="p-1">{fullName}</div>
				</UserWrapper>
			</div>
		);
	};

	const renderSelectedUsers = (inputProps, props) => {
		const icon = <IconWrapper className="me-1">{IconDispatcher("USER-GROUP")}</IconWrapper>;
		return (
			<TypeaheadInputMulti {...inputProps} selected={props.selected}>
				{props.selected?.map((option, idx) => (
					<CustomToken index={idx} key={option?.id} onRemove={props.onRemove} option={option} disabled={option?.isNotRemovable}>
						{Object.prototype.hasOwnProperty.call(option, "memberUserIds") || option.fullName === "All" ? (
							icon
						) : (
							<InitialsBubble initials={option.fullName?.getInitials()?.slice(0, 3)} size="s" />
						)}
						<>{option.fullName === "All" ? t("ALL_TEAM_MEMBERS") : option.fullName}</>
					</CustomToken>
				))}
			</TypeaheadInputMulti>
		);
	};

	const render = () => {
		if (ref) {
			return (
				<TypeaheadWrapper
					id="public-methods-example"
					ref={ref}
					labelKey="fullName"
					placeholder={t("SELECT_USERS")}
					multiple
					onBlur={() => ref.current?.blur()}
					options={options}
					renderMenuItemChildren={renderListUsers}
					renderInput={renderSelectedUsers}
					width={width}
				/>
			);
		}
		return (
			<TypeaheadWrapper
				id="public-methods-example"
				labelKey="fullName"
				placeholder={placeholder}
				multiple
				onChange={onChange}
				options={options}
				selected={selected}
				renderMenuItemChildren={renderListUsers}
				renderInput={renderSelectedUsers}
				showborder={showBorder}
				width={width}
			/>
		);
	};

	return render();
});

UsersSelectorTypeahead.displayName = "UsersSelectorTypeahead";
export default UsersSelectorTypeahead;

const IconWrapper = styled.div`
	background-color: #efefef;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
`;

const Separator = styled.div`
	width: 100%;
	height: 32px;
	${(props) => props.border && `border-top: 1px solid #C4C4C4;`}
	margin: 10px 0px;
`;

const UserWrapper = styled.div`
	display: flex;
    align-items: center;
	:hover {
		background-color: #efefef;
	}
`;

export const CustomToken = styled(Token)`
	border-radius: 4px;
	background: #efefef;
	align-items: center;
	color: #333;
	text-align: center;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	.rbt-token-label {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.rbt-token-remove-button {
		align-items: center;
	}
`;
