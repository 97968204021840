import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexAlignCenter, IconDispatcher } from 'shared/ui-basics/index';
import * as Style from './style'
import { FlexBetween } from 'shared/ui-basics/index'

export default function Switches({ object, setObject , disable,updateFunction}) {
    const { t } = useTranslation()
    const handleChange = (e) => {
        const name = e.target.name
        const updatedObject = { ...object, [name]: !object[name] }
        setObject(updatedObject)
        if (updateFunction) {
            updateFunction(updatedObject, [name])
        }
    }

    return (
        <FlexAlignCenter gap='50' className='w-100'>
            <Style.WrapperBox  type='win' active={object.win} >
                <div>{IconDispatcher("LIKE", "", { size: 'medium' })}</div>
                <div>{t('QUICK_WIN')}</div>
                <Style.Switch checked={object.win} disabled={disable} name='win' onChange={handleChange} />
            </Style.WrapperBox>
            <Style.WrapperBox  type='truth' active={object.truth}>
                <div>{IconDispatcher("HEART", "", { size: 'medium' })}</div>
                <div>{t('MOMENT_TRUTH')}</div>
                <Style.Switch checked={object.truth} disabled={disable} name='truth' onChange={handleChange} />
            </Style.WrapperBox>
        </FlexAlignCenter>
    )
}
