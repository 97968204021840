import { GenericButton, IconDispatcher } from 'shared/ui-basics/index';
import { CustomEditor } from 'shared/components/CustomEditor';
import { CustomRating } from 'shared/ui-basics/ui-components/CustomRating';
import { encode } from '@utils/helpers';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

export function RatingSection({solution, setSolution, modifiedAttribNames, path, title, disabled,updateFunction,calculateWrapper}) {
    const { t } = useTranslation();
    const [comment, setComment] = useState(_.get(solution, path + ".comment"))
    const [showComment, setShowComment] = useState(false)
    const toggle = () => setShowComment(!showComment)

    const onHTMLChange = (value) => {
        setComment(value)
        modifiedAttribNames.add((path + ".comment"))
        _.set(solution, path + ".comment", value)
    }
    const handleBlur = () => {
        if (modifiedAttribNames.has(path + ".comment")) {
            const sol = { ...solution }
            _.set(sol, path + ".comment", encode(comment))
            updateFunction(sol,[path + ".comment"])
            modifiedAttribNames.delete(path + ".comment")

        }
    }
    const displayButtonText = (comment) => {
        if (comment && comment !== "") return <>{IconDispatcher("VIEW", "mx-1")} {t("SHOW_COMMENT")}</>
        else return <>{IconDispatcher("PLUS", "mx-1")} {t("ADD_COMMENT")}</>
    }


    return (
        <RatingSectionWrapper>
            <SectionTitle className='mb-3'>{t(title)}</SectionTitle>
            <CustomRating classes={"mb-3"} object={solution} setObject={setSolution} modifiedAttribNames={modifiedAttribNames} attribute={path + ".rating"} disabled={disabled} updateFunction={updateFunction} calculateWrapper={calculateWrapper}/>
            {
                showComment && <TextAreaWrapper className='mx-2'>
                                    <CustomEditor html={comment} sethtml={onHTMLChange}  onBlur={handleBlur} className="w-100" disabled={disabled} variant='description'/>
                                    <GenericButton variant="light-link" icon="VIEW" iconClassName='mx-1' onClick={toggle}>
                                        <>{t("HIDE_COMMENT")}</>
                                    </GenericButton>
                                </TextAreaWrapper>
            }
            {
                !showComment && <GenericButton variant="light-link" onClick={toggle} style={{'width' : '145px'}}>
                                    {displayButtonText(comment)}
                                </GenericButton>
            }
        </RatingSectionWrapper>
    )
}

const RatingSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .p-rating-item {
        box-shadow: none !important;
    }

`

const SectionTitle = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
`

const TextAreaWrapper = styled.div`
    /* border: 1px solid #d9dbde; */
    border-radius: 4px;
`


