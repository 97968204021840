export const filterRecords = ({ records, accessType, roleName }) => {
	if (accessType && roleName) return records?.filter((user) => user.accessType === accessType && user.roleName === roleName);
	if (accessType) return records?.filter((user) => user.accessType === accessType);
	if (roleName) return records?.filter((user) => user.roleName === roleName);
};

export const isTeam = ({ record }) => {
	if (record["memberUserIds"]) return true;
	return false;
};

export const isActive = ({ workspace }) => workspace?.statusCode === 1;
