import { getPdfExportDashboard } from "features/analytics/reducer/analyticActions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components/macro";

function Export({ dashboardName, fileName, nSections, getFiltersToSave }) {
	const { t } = useTranslation();
	const { search } = useLocation();
	const hideForPdf = search.hideForPdf();
	const currentEntityData = useSelector((state) => state.analytics?.currentData);

	const onExport = () => {
		getPdfExportDashboard({ dashboardName, filters: JSON.stringify(getFiltersToSave({ currentEntityData })), fileName, width: "1400", height: nSections * 500 });
	};

	return <ActionsContainer>{!hideForPdf ? <GenericButton variant="light-link" icon="EXPORT" tooltip tooltipText={t("ASPX_15")} onClick={onExport} /> : <div></div>}</ActionsContainer>;
}

Export.displayName = "Export";
export default Export;

const ActionsContainer = styled.div``;
