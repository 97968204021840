import { getAllAuthorizedUsers, groupInit, priorityInit, statusInit, tagInit } from "@redux/index.js";
import { FindOpportunityById, retrieveAllActiveOpportunities } from "@redux/memoizedSelectors.js";
import { EMPTY_GUID, RestrictUserAccess, createDefaultOpp, createDefaultSol, decode, encode, extractTextFromHtml, findById, getFromLocalStorage } from "@utils/helpers.js";
import { urlOriginStatus } from "@utils/optionsSets/OptionSets.js";
import { SolutionPage } from "features/index.js";
import { CxComponents } from "features/journey-map/modals/CxComponents.js";
import { getAllMapsListData, getMapUsers } from "features/journey-map/reducer/CJMActions.js";
import BaseActions from "features/opportunity/actions/BaseActions.js";
import HeaderActions from "features/opportunity/actions/HeaderActions.js";
import { createOpportunity, getAllOpportunity, updateOpportunity } from "features/opportunity/reducer/OpportunityActions.js";
import { createSolution, getAllSolutions } from "features/solutions/reducer/SolutionsActions.js";
import { t } from "i18next";
import { base } from "index.js";
import { forwardRef, memo, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbItem } from "reactstrap";
import { CustomEditor } from "shared/components/CustomEditor.js";
import { SolutionItemCard } from "shared/components/cards/cx-cards/SolutionCard.jsx";
import DataLoader from "shared/components/data-loader.jsx";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown.js";
import { useAttributeHandler } from "shared/hooks/index";
import { Flex, FlexBetween, GenericButton, Input, SearchBar } from "shared/ui-basics/index";
import { CustomRating } from "shared/ui-basics/ui-components/CustomRating.js";
import { useModal } from "use-modal-hook";
import DisplayStatus from "../../../shared/cx-components/DisplayStatus.js";
import Group from "../../../shared/cx-components/Group.js";
import MapAndStagePicker from "../../../shared/cx-components/MapAndStagePicker.js";
import OwnerPickList from "../../../shared/cx-components/OwnerPickList.js";
import Priority from "../../../shared/cx-components/Priority.js";
import SearchPickList from "../../../shared/cx-components/SearchPickList.js";
import Switches from "../../../shared/cx-components/Switches.js";
import Tags from "../../../shared/cx-components/Tags.js";
import * as Style from "../../../shared/cx-components/style.js";
import NoDataComponent from "../../auth/view/no-data-component.jsx";

function OpportunityPage(props) {
	const { search } = useLocation();
	const dispatch = useDispatch();
	const { selectedItemId } = search.getCxProps(props.query);
	const userInfo = useSelector((state) => state.auth.userInfo);
	const selectedOpportunity = useSelector(FindOpportunityById(selectedItemId));
	const mapUserParameters = {
		cjmId: selectedOpportunity?.mapId,
		userId: userInfo?.UserId,
		cxOwnerEntity: selectedOpportunity?.ownerId,
	};
	const asyncFunctions = [getAllOpportunity, getAllMapsListData, getAllAuthorizedUsers, getAllSolutions, statusInit, groupInit, priorityInit, tagInit];

	useEffect(() => {
		if (selectedOpportunity?.mapId) {
			dispatch(getMapUsers(mapUserParameters));
		}
	}, [selectedOpportunity?.mapId]);
	return (
		<Style.ContainerPage query={props.query}>
			<DataLoader reduxActions={asyncFunctions} disabledSpinner={selectedOpportunity !== undefined} >
				{!selectedOpportunity ? <NoDataComponent label={"OPPORTUNITY_NOT_FOUND"} icon="CIRCLE-EXCLAMATION" /> : <Opportunity {...props} />}
			</DataLoader>
		</Style.ContainerPage>
	);
}

const Opportunity = ({ query, setQuery, openBlankForm ,onClose}) => {
	const { search ,pathname} = useLocation();
	const { t } = useTranslation();
	const history = useHistory();
	const ref = useRef(null);
	const searchPickListRef = useRef(null);

	const { selectedItemId, origin, displayList, cjmId, personaId } = search.getCxProps(query);
	const backQuery = search.getBackQuery(); // cxProgram
	const folderId = search.getFolderId();

	const backQueryDecripted = getFromLocalStorage(backQuery); // return cxProgramId

	const userInfo = useSelector((state) => state.auth.userInfo);
	const currentUser = useSelector((state) => state.maps.currentUser);
	const selectedOpportunity = useSelector(FindOpportunityById(selectedItemId));
	const mapAuthorizedUsers = useSelector(({ maps }) => findById(maps.all_maps, selectedOpportunity?.mapId))?.authorizedUsers || [];
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allSolutions = useSelector((state) => state.solutions.all_solutions?.getActiveRecords(!showSampleData));

	const [counter, setCounter] = useState(0);
	const [showOpportunity, setShowOpportunity] = useState(displayList ? true : false);
	const [openModal]= useModal(CxComponents)

	const updateOpp = (item, modifiedAttribNames, beforeModifiedOpportunity, onSuccess) => updateOpportunity({ item, modifiedAttribNames, beforeModifiedOpportunity, onSuccess });
	const { object, setObject, handleChange, handleBlur, setModifiedAttribNames, modifiedAttribNames } = useAttributeHandler(selectedOpportunity, updateOpp);
	const objectRef = useRef(object);
	const disableViewer = isUserViewerOfMaps({ currentUser, mapAuthorizedUsers, selectedItemId, userInfo, opportunity: object });

	const linkedSolutions = object.linkedSolutions?.map((item) => allSolutions?.find((it) => it?.id === item?.id));
	useEffect(() => {
		if (selectedOpportunity) {
			setObject(selectedOpportunity);
			setModifiedAttribNames(new Set());
			setCounter(counter + 1);
		}

	}, [selectedItemId]);
	useEffect(() => {
		objectRef.current = object;
	}, [object.mapId, object.stageId]);
	useEffect(() => {
		return () => {
			// Use `objectRef.current` to access the latest value of `object`
			if (objectRef.current?.mapId !== EMPTY_GUID && objectRef.current?.stageId === EMPTY_GUID) {
				toast.info(t("JOURNEY_MAP_NOT_SAVED"));
			}
		};
	}, []);

	const handleRedirect = (response) => {
		if (object.mapId !== EMPTY_GUID && object.stageId === EMPTY_GUID) {
			// case we select a map without a stage
			return toast.info(t("PLEASE_SELECT_A_STAGE"));
		}
		if (modifiedAttribNames.size > 0) {
			updateOpp({ ...object, description: encode(object.description), subject: encode(object.subject) }, [...modifiedAttribNames]);
		}

		if (origin == urlOriginStatus.DefaultReturn) return history.goBack();

		if (backQueryDecripted) return history.push(`EditCxProgram?programid=${backQueryDecripted}&tab=relationship`);
		if (response.id) {

			if (query) return setQuery({ ...query, id: response.id });
			history.push(`/edit-opportunity?id=${response.id}`);
		} else {
			if (cjmId && personaId) {

				return history.push(`/EditMap?cjmId=${cjmId}&personaId=${personaId}`);
			}
			const nextId = ref.current?.getNextId();
			if (query && nextId) return setQuery({ ...query, id: nextId });
			if (showOpportunity && nextId && response?.id) return history.push(`/edit-opportunity?id=${nextId}`);
			// if I delete or archive in the navigationView from the form
			// change to the next one in the list
			history.push(`/opportunity${folderId ? `?folderid=${folderId}` : ""}`);
		}

	};

	const redirectToForm = () => {
		if (openBlankForm) {
			window.open(`${base}edit-opportunity?id=${object.id}`, '_blank');
		} else {
			history.push(`/edit-opportunity?id=${object.id}`);
			query && setQuery(false);
		}

	};

	const handleCreateSolution = ({ globalFilterValue }) => {
		const newSolution = createDefaultSol(userInfo);
		newSolution.subject = encode(globalFilterValue);
		newSolution.stageId = object.stageId || EMPTY_GUID;
		newSolution.mapId = object.mapId || EMPTY_GUID;
		newSolution.ownerId = object.ownerId || userInfo?.UserId || EMPTY_GUID;

		createSolution({ item: newSolution, displayToast: "CREATE", onSuccess: (response) => handleLinkRecord({ recordId: response.id, globalFilterValue }) });
	};

	const handleLinkRecord = ({ recordId, globalFilterValue }) => {
		let item;
		if (globalFilterValue) {
			item = {
				id: recordId,
				subject: globalFilterValue,
			};
		} else {
			item = allSolutions?.find((obj) => obj.id === recordId);
		}
		const updatedObject = { ...object, linkedSolutions: [...object.linkedSolutions, item] };
		setObject(updatedObject);
		updateOpp(updatedObject, ["linkedSolutions"]);
	};

	const handleUnlinkRecord = (e) => {
		const updatedObject = { ...object, linkedSolutions: object?.linkedSolutions?.filter((obj) => obj.id !== e.currentTarget.id) };
		setObject(updatedObject);
		updateOpp(updatedObject, ["linkedSolutions"]);
	};


	const openRoute = (id) => {
		if (origin) {
			return toast.info(t("WARNING_NOT_ALLOWED_MULTIPLE_OPEN"));
		}
		if(onClose){
			openModal({
				Component:SolutionPage,
				id:id
			})
			onClose();
		}else {
			history.push(`/edit-solution?id=${id}&origin=${encode(object?.subject)}`);
		}
	};
	const Dates = memo(() => {
		const [localDate, setLocalDate] = useState(object?.dueDate?.formatDate());

		const handleBlur = () => {
			if(localDate)
			handleChange("dueDate", new Date(localDate).toISOString(), true);
		};

		return (
			<div>
				<Style.Labels className="my-2"> {t("DUE_DATE")}</Style.Labels>
				<Input
					variant="default"
					id="Date"
					name="dueDate"
					className="no-border"
					placeholder="date placeholder"
					disabled={disableViewer}
					value={localDate}
					type="date"
					onChange={(e) => setLocalDate(e.target.value)}
					onBlur={handleBlur}
				/>
			</div>
		);
	});
	Dates.displayName = "Dates_OpportunityPage";

	return (
		<Style.ContainerCxPage disableViewer={disableViewer}>
			{showOpportunity && <ListOpportunityComponent currentOpportunity={object} disabled={disableViewer} query={query} ref={ref} setQuery={setQuery} />}
			<Style.ContainerItem key={counter} query={query}>
			<Style.Header query={query}>
				{!query && (
					<FlexBetween className="w-100">
						<Style.PathContainer>
							<GenericButton variant="light-link" className="m-2" icon={"ARROW_LEFT"} onMouseDown={handleRedirect} />
							<Style.CustomBreadcrumb className="m-0">
								<BreadcrumbItem>
									<GenericButton
										variant="light-link"
										className="me-2"
										onClick={() => setShowOpportunity(!showOpportunity)}
										tooltip
										tooltipText={t("SHOW_ALL_OPPORTUNITIES")}
										icon={"SIDEBAR"}
									/>
									<Style.Path>{origin && origin !== '1' ? origin : t("OPPORTUNITY")}</Style.Path>
								</BreadcrumbItem>
								<BreadcrumbItem>
									<Style.Path className="fw-bold">{object?.subject.defaultPlaceholder("NEW_OPPORTUNITY_NAME")}</Style.Path>
								</BreadcrumbItem>
							</Style.CustomBreadcrumb>
						</Style.PathContainer>

						<Style.ButtonContainer>
							<HeaderActions opportunity={object} handleRedirect={handleRedirect} />
						</Style.ButtonContainer>
					</FlexBetween>
				)}
				<Style.MainTitle query={query}>
					<FlexBetween>
						<Style.Title
								variant="default"
								name="subject"
								placeholder={t("ENTER_TITLE")}
								value={object.subject}
								onBlur={() => handleBlur("subject")}
								onChange={(e) => handleChange("subject", e.target.value)}
								disabled={disableViewer}
								title={object?.subject?.length > 0 && object.subject}


							/>
						{query && (
							<Flex>
								<GenericButton variant="light-link" className="me-2" icon={"ARROW_UP_RIGHT"} onClick={redirectToForm} />
								{CreateActionsDropdown({ actions: BaseActions({ opportunity: object, handleRedirect ,onClose  }), variant: "none", dropdownClasses: "px-0" })}
							</Flex>
						)}
					</FlexBetween>

					<FlexBetween className="pt-2">
						<Tags object={object} setObject={setObject} recordType="cem_cxopportunity" disabled={disableViewer} />
					</FlexBetween>
				</Style.MainTitle>
			</Style.Header>
				{query && <Style.Divider className="ms-3" />}
				<Style.MainContainer query={query}>
					<Style.Left>
						<div className="mt-2">
							<Switches object={object} setObject={setObject} disable={disableViewer} updateFunction={updateOpp} />
						</div>

						<div>
							<Style.Labels className='my-2'>{t("ASPX_9")}</Style.Labels>
							<CustomEditor
								html={object.description}
								sethtml={(value) => handleChange("description", value)}
								onBlur={() => handleBlur("description")}
								disabled={disableViewer}
								variant={"description"}
							/>
						</div>
						<div>
							<Style.Labels className='my-2'>
								{t("JOURNEY")} & {t("ASPX_172")}
							</Style.Labels>
							<Style.MapStageContainer>
								<MapAndStagePicker object={object} attribute="mapId" setObject={setObject} disable={disableViewer} updateFunction={updateOpp} />
								<MapAndStagePicker object={object} attribute="stageId" setObject={setObject} disable={disableViewer} updateFunction={updateOpp} />
							</Style.MapStageContainer>
						</div>

						<div>
							<Style.Labels className='my-2'>{t("SOLUTION")}</Style.Labels>
							<Style.BorderWrapper>
								<GenericButton
									variant="black-link"
									onClick={(e) => searchPickListRef.current.toggle(e)}
									disabled={disableViewer}
									aria-haspopup
									aria-controls="overlay_panel"
									iconClassName="me-1"
									icon="SQUARE_PLUS">
									{t("ADD_SOLUTION")}
								</GenericButton>
								<Style.RelatedItemsContainer maxHeight="220">
								{linkedSolutions?.map((sol, i) => (
									<SolutionItemCard
										key={sol?.id || i}
										index={i}
										entity={sol}
										unlink={handleUnlinkRecord}
										openRoute={openRoute}
										length={linkedSolutions.length}
										isRemovable={!disableViewer}
									/>
								))}
							</Style.RelatedItemsContainer>
							</Style.BorderWrapper>
							<SearchPickList
								ref={searchPickListRef}
								currentItem={object}
								allItems={allSolutions}
								linkedAttribute="linkedSolutions"
								handleCreate={handleCreateSolution}
								handleLinkRecord={handleLinkRecord}
								noItemsText={t("EMPTY_SOLUTION_LIST")}
								entityType="cem_cxsolution"
							/>

						</div>
					</Style.Left>
					<Style.Right className={"mt-3"}>
						<div>
							<Style.Labels className='my-2'>{t("ASPX_222")}</Style.Labels>
							<DisplayStatus
								object={object}
								type="status"
								name="opportunity"
								setObject={setObject}
								modifiedAttribNames={modifiedAttribNames}
								style={{ dropdownToggle: "p-2" }}
								disable={disableViewer}
								updateFunction={updateOpp}
							/>
						</div>
						<div>
							<Style.Labels className='my-2'>{t("CS_35")}</Style.Labels>
							<OwnerPickList object={object} setObject={setObject} modifiedAttribNames={modifiedAttribNames} disable={disableViewer} updateFunction={updateOpp} />
						</div>
						<div>
							<Style.Labels className='my-2'>{t("PRIORITY")}</Style.Labels>
							<Priority object={object} setObject={setObject} name="opportunity" disable={disableViewer} updateFunction={updateOpp} />
						</div>
						<div>
							<Style.Labels className='my-2'>{t("GROUP")}</Style.Labels>
							<Group object={object} setObject={setObject} disabled={disableViewer} recordType="cem_cxopportunity" />
						</div>
						<Dates />

						<div >
							<Style.Labels className='my-2'>
								{t("CUSTOMER_VALUE")}
							</Style.Labels>
							<CustomRating
								object={object}
								setObject={setObject}
								attribute="customerValue"
								modifiedAttribNames={modifiedAttribNames}
								disabled={disableViewer}
								updateFunction={updateOpp}
							/>
						</div>
						<div>
							<Style.Labels className='my-2'>
								{t("BUSINESS_VALUE")}
							</Style.Labels>
							<CustomRating
								object={object}
								setObject={setObject}
								attribute="businessValue"
								modifiedAttribNames={modifiedAttribNames}
								disabled={disableViewer}
								updateFunction={updateOpp}
							/>
						</div>

					</Style.Right>
				</Style.MainContainer>
			</Style.ContainerItem>
		</Style.ContainerCxPage>
	);
};

const ListOpportunityComponent = forwardRef(({ currentOpportunity, disabled, query, setQuery }, ref) => {
	const { search } = useLocation();
	const { selectedItemId, queryNb, cjmId, origin, personaId } = search.getCxProps(query);
	const {cjmIdStr} = search.getAllMapsParams();
	const selectedOpportunityRef = useRef(null);
	const history = useHistory();
	const encodedIds = localStorage.getItem(`/edit-opportunity-${queryNb}`);
	const ids = queryNb ? decode(encodedIds)?.split("|") : [];
	const userInfo = useSelector((state) => state.auth.userInfo);
	const selectedOpportunity = useSelector(FindOpportunityById(selectedItemId));

	const oppMap = new Map(useSelector(retrieveAllActiveOpportunities).map((opp) => [opp.id, opp]));
	const all_Opportunities =
		ids?.length > 0 ? ids.map((id) => oppMap.get(id)).filter(Boolean) : cjmIdStr ? Array.from(oppMap.values()).filter((op) => op.stageId === selectedOpportunity.stageId) : [...oppMap.values()];

	const [searchValue, setSearchValue] = useState("");

	const filterOpportunity = all_Opportunities?.filter((op) => op.subject.toUpperCase().includes(searchValue.toUpperCase()));
	const handleChange = (e) => setSearchValue(e.target.value);

	const Search = SearchBar({ globalFilterValue: searchValue, handleChange })

	const OpportunityCard = ({ opportunity, index }) => {
		const selectedOpportunity = opportunity.id === selectedItemId ? currentOpportunity : opportunity;
		const history = useHistory();

		const handleClick = () => {
			if (selectedOpportunity.id !== selectedItemId) {
				if (query) return setQuery({ ...query, id: selectedOpportunity.id });
				history.push(`/edit-opportunity?id=${selectedOpportunity.id}${cjmId ? `&origin=${encode([origin, cjmId, personaId].join("|"))}` : ""}${ids?.length > 0 ? `&q=${queryNb}` : ""}`);
			}
		};

		return (
			<Style.QuickSelectionCard key={opportunity?.id} onClick={handleClick} active={selectedItemId === selectedOpportunity.id} index={index} query={query}>
				<Style.QuickSelectionCardTitle 	title={selectedOpportunity?.subject?.length > 0 && selectedOpportunity.subject}
				>{selectedOpportunity?.subject.defaultPlaceholder("NEW_OPPORTUNITY_NAME")}</Style.QuickSelectionCardTitle>
				<Style.QuickSelectionCardDescription>{extractTextFromHtml(selectedOpportunity.description)}</Style.QuickSelectionCardDescription>
			</Style.QuickSelectionCard>
		);
	};
	const handleRedirect = () => {
		const onSuccess = (data) => {
			history.push(`/edit-opportunity?id=${data.id}`);
		};
		const newOpp = createDefaultOpp(userInfo);
		createOpportunity({ item: newOpp, displayToast: "CREATE_NO_SUCCESS_TOAST", onSuccess });
	};
	useLayoutEffect(() => {
		if (selectedOpportunityRef.current) {
			selectedOpportunityRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [selectedItemId]);
	useImperativeHandle(ref, () => ({
		getNextId: () => {
			const indexOfNextId = all_Opportunities.findIndex((opp) => opp.id === selectedItemId) + 1;
			const nextId = all_Opportunities[indexOfNextId]?.id;
			return nextId;
		},
	}));
	return (
		<Style.ListComponentWrapper query={query} className={query ? "" : "py-3"}>
			{!query && (
				<Style.Labels fs={18} className="m-2 ps-3">
					{t("OPPORTUNITIES")}
				</Style.Labels>
			)}
			<Style.HeaderListComponent query={query}>
				{!disabled && !queryNb && !cjmIdStr ? (
					<GenericButton variant="primary-link" className="me-2" onClick={handleRedirect} icon={"SQUARE_PLUS"} iconClassName="me-1">
						{t("NEW_OPPORTUNITY")}
					</GenericButton>
				) : (
					<div></div>
				)}
				{Search}
			</Style.HeaderListComponent>
			<Style.WrapperItems disableScroll={filterOpportunity?.length <7}>
				{filterOpportunity.map((opportunity, index) => (
					<div key={opportunity.id} ref={opportunity.id === selectedItemId ? selectedOpportunityRef : null} className="w-100">
						<OpportunityCard key={index} opportunity={opportunity} index={index} />
					</div>
				))}
			</Style.WrapperItems>
		</Style.ListComponentWrapper>
	);
});
const isUserViewerOfMaps = ({ currentUser, mapAuthorizedUsers, selectedItemId, userInfo, opportunity }) => {
	const readOnlyCode = "100000003";
	if (userInfo.UserType === readOnlyCode) return true;
	if (opportunity.ownerId === userInfo.UserId) {
		return false;
	}
	if (currentUser) {
		return currentUser?.roleName === "Viewer" ? true : false;
	}
	if (selectedItemId) {
		const userAccessPrivilege = mapAuthorizedUsers?.find((user) => user.id === userInfo.UserId)?.roleName || "";
		return RestrictUserAccess(userAccessPrivilege?.toLowerCase(), ["viewer"]);
	}
};
OpportunityPage.displayName = "OpportunityPage";
Opportunity.displayName = "Opportunity";
ListOpportunityComponent.displayName = "ListOpportunityComponent";
export default OpportunityPage;
