import { GenericButton } from "shared/ui-basics/buttons";
import CardViewPersona from "features/grid-table/components/CardViewPersona";
import DataTable from "features/grid-table/DataTable";
import { useAsyncs } from "shared/hooks/index";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { getAllMapsListData, getAllPersonas, tagInit } from "@redux/index";
import { getFolderById } from "@redux/memoizedSelectors";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Personas() {
	const { t } = useTranslation();

	const { search } = useLocation();
	const folderId = search.getFolderId();
	const hideForPdf = search.hideForPdf();
	const folderView = folderId === undefined ? false : true;
	const [viewCard, setViewCard] = React.useState(false);

	const { linkedPersonas } = useSelector((state) => getFolderById(state, folderId), shallowEqual);

	useAsyncs({
		asyncFns: [{ asyncFn: getAllMapsListData }, { asyncFn: getAllPersonas }, { asyncFn: tagInit }],
	});

	useEffect(() => {
		if (folderView && viewCard) {
			setViewCard(false);
		}
	}, [folderView]);

	const isShowViewButtons = () => {
		if (hideForPdf || folderView) return false;
		return true;
	};

	const renderViewButtons = (t) => {
		if (!isShowViewButtons()) return <></>;
		return (
			<>
				<GenericButton variant="light-link" className="ms-2" active={viewCard} icon="GRID-VIEW" tooltip tooltipText={t("VIEW_CARD")} onClick={() => setViewCard(true)} />
				<GenericButton variant="light-link" className="ms-2" active={!viewCard} icon="LIST-VIEW" tooltip tooltipText={t("LIST_VIEW")} onClick={() => setViewCard(false)} />
			</>
		);
	};

	const renderView = () => {
		if (viewCard) return <CardViewPersona />;
		return <DataTable context="personas.personasList" linked={linkedPersonas} />;
	};

	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("ASPX_98")}`} renderViewButtons={renderViewButtons}>
			{renderView()}
		</PageTemplate>
	);
}
