import { upsertSettingUser } from "@redux/index";
import { setAllowSectionsOverFlow } from "@redux/pageReducer";
import { getColorsByType } from "@utils/helpers";
import { t } from "i18next";
import { memo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Collapse } from "reactstrap";
import { useClickOutside } from "shared/hooks/index";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components";
import { useColorPicker } from "../dynamic-imports";

function ReactColor({
	color,
	updateColor,
	text = null,
	buttonClassName = "",
	ButtonComponent,
	placement,
	disabled = false,
	openWithBtn = true,
	autoSave = false,
	withPopover = true,
	width = "260",
	autoClose = false,
	buttonSize = "m",
	colorsType,
}) {
	const userSettings = useSelector(({ settings }) => settings?.userSetting);
	const { pathname } = useLocation();
	const allowSectionUpdate = ["/editpersona", "/editcxprogram"].includes(pathname?.toLowerCase());

	const [showPickerColor, setShowPickerColode] = useState(openWithBtn ? false : true);
	const [open, setOpen] = useState(openWithBtn ? false : true);
	const cemanticaColors = getColorsByType({ type: colorsType });
	const containerRef = useRef(null);

	const ChromePicker = useColorPicker()?.ChromePicker;
	const refClickOutisde = useRef(null);

	useClickOutside(refClickOutisde, () => {
		if (autoClose) {
			handleClose();
		}
	});

	const handleClick = () => {
		if (disabled) return;
		setShowPickerColode((prevState) => !prevState);
		allowSectionUpdate && setAllowSectionsOverFlow(true);
	};

	const handleClose = () => {
		setShowPickerColode(false);
		allowSectionUpdate && setAllowSectionsOverFlow(false);
	};

	const handleSubmit = (color) => {
        updateColor(color);
		if (!userSettings?.colors?.includes(color) && !cemanticaColors.includes(color)) {
			userSettings.colors = [...(userSettings?.colors || []), color];
			userSettings.colors = userSettings?.colors?.slice(-5);
			upsertSettingUser({
				userSetting: {
					id: userSettings?.id,
					colors: userSettings.colors,
				},
				modifiedAttribNames: ["colors"],
			});
		}

		withPopover && handleClose();
	};
	const presetColors = [...cemanticaColors, ...(userSettings?.colors || [])];

	const ColorPickers = () => {
		const [currentColor, setColor] = useState(color);
		const chromColor = presetColors.includes(currentColor) ? "linear-gradient(to bottom, red, blue)" : currentColor;
		const handleChange = (color) => {
			setColor(color?.hex);
		};

		const handleClick = (color) => {
			setColor(color?.hex);
			if (autoSave) handleSubmit(color?.hex);
		};
		const SaveButton = () => {
			if (autoSave && !open) return null;

			return (
				<div className={!autoSave ? "d-flex justify-content-end" : "d-flex justify-content-start"}>
					{!autoSave && (
						<GenericButton onClick={() => handleSubmit(currentColor)} variant="primary-outline" size={buttonSize}>
							{t("JS_55")}
						</GenericButton>
					)}
					{autoSave && (
						<GenericButton onClick={() => handleSubmit(currentColor)} variant="light-outline" size={buttonSize}>
							{IconDispatcher("CHECK", "me-2")}
							{t("SAVE_CUSTOM_COLOR")}
						</GenericButton>
					)}
				</div>
			);
		};

		return (
			<Container width={width} ref={containerRef}>
				<Cover onClick={handleClose} />
				<SwatchContainer>
					{presetColors.map((c) => {
						return (
							<ColorSwatch key={c} color={c} onClick={() => handleClick({ hex: c })}>
								<div className={c !== currentColor ? "d-none" :""}>{IconDispatcher("CHECK")} </div>
							</ColorSwatch>
						);
					})}
					<ColorSwatch color={chromColor} onClick={() => setOpen(!open)}>
						#
					</ColorSwatch>
				</SwatchContainer>
				<Collapse isOpen={open}>
					{ChromePicker && <ChromePicker color={currentColor} className="custom-chromePicker" onChange={handleChange} presetColors={presetColors} disableAlpha={true} />}
				</Collapse>
				{SaveButton()}
			</Container>
		);
	};

	const DisplayButton = () => {
		if (!openWithBtn) return null;
		if (ButtonComponent) return <div onClick={handleClick}> {ButtonComponent}</div>;

		return (
			<GenericButton onClick={handleClick} icon="DRIP" variant="light-link" size={buttonSize} isicon={text ? "false" : "true"} iconClassName={text ? "me-1" : ""} className={buttonClassName}>
				{text}
			</GenericButton>
		);
	};

	return (
		<div key={color}>
			<DisplayButton />
			{showPickerColor &&
				(withPopover ? (
					<Popover showRight={text} placement={placement} ref={refClickOutisde}>
						<ColorPickers />
					</Popover>
				) : (
					<ColorPickers />
				))}
		</div>
	);
}
export default memo(ReactColor);

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: ${(props) => props.width}px;
`;

const SwatchContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	z-index: 60;
	position: relative;
`;

const ColorSwatch = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 4px;
	margin: 5px;
	margin-left: 0px;
	cursor: pointer;
	background: ${({ color }) => `${color}`};
	${({ color }) => {
		if (color?.toLowerCase() === "#ffffff" || color?.isEmptyString() || color === null) {
			return "border: 1px solid #D9D9D9;";
		}
	}}
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Popover = styled.div`
	background-color: #ffffff;
	border-radius: 4px;
	padding: 0.5rem;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	position: absolute;

	${({ placement }) => {
		switch (placement) {
			case "top":
				return "bottom: 100%";
			case "bottom":
				return "top:100%";
			case "left":
				return "right: 0%";
			case "right":
				return "left: 100%";
			default:
				return "";
		}
	}};
	//${({ top }) => (top ? `top: ${top}px;` : "")}
	z-index: 6;
	.chrome-picker {
		box-shadow: none !important;
	}
	@media screen and (max-height: 825px) {
		transform: translateY(-60%);
	}
`;

const Cover = styled.div`
	/* position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; */
`;
