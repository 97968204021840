import { Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, Col } from "reactstrap";
import React, { useState, memo } from "react";
import { GenericButton } from "shared/ui-basics/index";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { getterApis } from "@utils/optionsSets/getterApis";
import CustomTooltip from "shared/components/CustomTooltip";
import { EMPTY_GUID, mapModeValidation } from "@utils/helpers";
import { MapIconContainer, MapCardContainer } from "./CjmListStyle";
import { useHistory } from "react-router";
import { SearchBar } from "shared/ui-basics/index";
import { base } from "../../..";
import { getSessionId } from "@redux/memoizedSelectors";


 const CjmListModal = memo(
	({ allMaps, haveCreateNew, isOpen, onClose, title, closeBtnLabel, Action, createBtnLabel, data, IconAdd, IconRemove, IconAddToolTip, IconRemoveToolTip, folderId }) => {
		const { t } = useTranslation();
		const history = useHistory();

		const [selectedMaps, setSelectedMaps] = useState(data || []);
		const [unSelectedMaps, setUnSelectedMaps] = useState([]);
		const [searchValue, setSearchValue] = useState("");
		const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
        const sessionId = useSelector(getSessionId);
        const maps = allMaps?.getActiveRecords(!showSampleData)
		const [filteredMaps, setFilteredMaps] = useState(maps);

		const GoToMapCreation = () => {
			onClose();
			history.push(`/map-creation?folderId=${folderId}`);
		};

		const updateAction = () => {
			Action(selectedMaps.filter((item) => item !== ""  && !data.includes(item)).join("|"), unSelectedMaps.filter((item) => item !== "").join("|"));
			setSelectedMaps([]);
			onClose();
		};

		const addToList = (e) => {
			const id = e.currentTarget.id;
			const index = unSelectedMaps.indexOf(id);
			if (index > -1) {
				const newSelectedMaps = unSelectedMaps.filter((item) => item !== id).filter((item) => item !== "");
				setUnSelectedMaps(newSelectedMaps);
			}
			setSelectedMaps([...selectedMaps, id]);
		};

		const removeFromList = (e) => {
			const id = e.currentTarget.id;
			const index = selectedMaps.indexOf(id);
			if (index > -1) {
				const newSelectedMaps = selectedMaps.filter((item) => item !== id).filter((item) => item !== "");
				setSelectedMaps(newSelectedMaps);
			}
			const indexLink = data?.indexOf(id);
			if (indexLink > -1) {
				setUnSelectedMaps([...unSelectedMaps, id]);
			}
		};

		const handleSearchChange = (e) => {
			const value = e.target.value;
			setSearchValue(value);
			if (!value) setFilteredMaps(maps);
			else setFilteredMaps(maps.filter((map) => map?.name?.toLowerCase().includes(value.toLowerCase())));
		};

		return (
			<>
				<Modal size="lg" isOpen={isOpen} fade={false} centered={true} toggle={onClose}>
					<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
					<ModalBody>
						<BodyHeader className="my-2" displayButton={haveCreateNew}>
							{haveCreateNew ? (
								<GenericButton onClick={GoToMapCreation} variant="primary-outline" icon="PLUS" iconClassName="me-1">
									{t(createBtnLabel)}
								</GenericButton>
							) : (
								""
							)}
							<div className="me-4">
								<SearchBar globalFilterValue={searchValue} handleChange={handleSearchChange} />
							</div>
						</BodyHeader>
						<ListWrapper flush className="scollable-list-normal mt-3">
							{filteredMaps?.length > 0 &&
								filteredMaps?.map((map, index) => {
									return (
										<MapCardContainer id={map.cjmId} key={index} className="mb-3 me-3" IsSelected={selectedMaps?.includes(map.cjmId)}>
											<Col xs={8} className="d-flex">
												<MapIconContainer>
													<img src={getterApis["GET_MAP_CUSTOM_IMG"](map.cjmId, sessionId)} loading="lazy" alt="" />
												</MapIconContainer>
												<CustomTooltip id={map.cjmId} text={map.name} className='align-self-center' >
													<HrefWrapper
														className="text-truncate"
														href={`${base}EditMap?cjmid=${map.cjmId}&personaId=${map.linkedPersonas[0]?.personaId || EMPTY_GUID}`}
														target="_blank">
														<StyledMapName>{map.name}</StyledMapName>
													</HrefWrapper>
												</CustomTooltip>
											</Col>
											<Col xs={3}>
												<span>{t(mapModeValidation(map.mapMode))}</span>
											</Col>
											<Col xs={1} className="d-flex pe-1">
												{selectedMaps?.includes(map.cjmId) ? (
													<GenericButton id={map.cjmId} className="me-2" variant="light-link" tooltip={true} tooltipText={t(IconRemoveToolTip)} onClick={removeFromList}>
														{IconRemove}
													</GenericButton>
												) : (
													<GenericButton id={map.cjmId} className="me-2" variant="light-link" tooltip={true} tooltipText={t(IconAddToolTip)} onClick={addToList}>
														{IconAdd}
													</GenericButton>
												)}
											</Col>
										</MapCardContainer>
									);
								})}
							{maps.length === 0 && <div>{t("NO_AVAILABLE_MAPS")}</div>}
						</ListWrapper>
					</ModalBody>
					<ModalFooter>
						{maps?.length > 0 && (
							<GenericButton variant="primary" onClick={updateAction}>
								{t(closeBtnLabel)}
							</GenericButton>
						)}
					</ModalFooter>
				</Modal>
			</>
		);
	}
);
CjmListModal.displayName = "CjmListModal";
export {CjmListModal};
const ListWrapper = styled(ListGroup)`
	min-height: 500px;
	max-height: 500px;
	height: auto;
`;

const HrefWrapper = styled.a`
	display: block;
	float: right;
	color: #92919d;
	text-decoration: none;
	:hover {
		color: #605f60;
		text-decoration: underline;
	}
`;

const BodyHeader = styled.div`
	display: flex;
	justify-content: space-between;

	${(props) =>
		props.displayButton === false &&
		`display: flex;
        justify-content: flex-end;`}
`;

const StyledMapName = styled.div`
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 350px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: pointer;
`;
