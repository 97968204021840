import { filterMapsByUserRole } from "@utils/helpers";
import { archiveManyMaps, deleteManyMaps, deletePermanentlyManyMaps, restoreManyMaps } from "features/journey-map/reducer/CJMActions";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { BulkActionsGenerator } from "shared/components/BulkActionsGenerator";
import BulkModalLinkComponent from "shared/modals/bulk-links/BulkModalLinkComponent";
import { ActionConfirmModal } from "shared/modals/index";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import { useModal } from "use-modal-hook";
export const BulkActions = (selectedMaps, setSelected, onSuccess, setDisableBulkActions) => {
	const pathname = useLocation().pathname;
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";
	const isTemplate = pathname === "/templates";
	const actions = [];

	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const selectedItemsIdsStr = selectedMaps.map((item) => item?.cjmId).join("|");
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(subscriptionPlan);
	const someIdAreSample = selectedMaps.some((map) => map.isSample);
	const isManageAccessAllowed = !isTemplate && isSubscriptionAllowed;
	const filteredItemsIdsStr = filterMapsByUserRole({ selectedMaps, currentUserId, isSubscriptionStandard: !isSubscriptionAllowed });

	const filteredItemsByOwnersAndContrib = filterMapsByUserRole({
		selectedMaps,
		currentUserId,
		isSubscriptionStandard: !isSubscriptionAllowed,
		userRoles: ["owner", "contributor"],
	});

	const areAllActionsValid = selectedItemsIdsStr.length === filteredItemsIdsStr.length;

	const [showAccessModal] = useModal(GenericManageAccess);
	const manageAccessMany = () => showAccessModal({ ids: filteredItemsIdsStr, bulk: true, onSuccess });

	const [showModalArchivePopup] = useModal(ActionConfirmModal, {
		bodyContent: "ARCHIVE_MANY",
		Action: () => {
			setDisableBulkActions(true);
			archiveManyMaps({ids: filteredItemsIdsStr, displayToast : areAllActionsValid ? "BULK_ARCHIVE_SUCCESS" : "BULK_ARCHIVE_PARTIAL", onSuccess});
		},
		actionBtnText: "ARCHIVE",
	});

	const [showModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY",
		Action: () => {
			setDisableBulkActions(true);
			deleteManyMaps({ids: filteredItemsIdsStr, displayToast : areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess});
		},
		actionBtnText: "ASPX_13",
	});

	const [showModalDeletePermanentPopup] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_MANY_PERMANENT",
		Action: () => {
			setDisableBulkActions(true);
			deletePermanentlyManyMaps({ids:filteredItemsIdsStr, displayToast :areAllActionsValid ? "BULK_DELETE_SUCCESS" : "BULK_DELETE_PARTIAL", onSuccess});
		},
		actionBtnText: "ASPX_13",
	});

	const [showBulkModalLinkComponent] = useModal(BulkModalLinkComponent);

	if (isTrash || isArchive) {
		if (filteredItemsIdsStr) actions.push({ handleClick: () => restoreManyMaps({ids:filteredItemsIdsStr, onSuccess}), icon: "RESTORE", description: "JS_96" });
		if (filteredItemsIdsStr) actions.push({ handleClick: isArchive ? showModalDeletePopup : showModalDeletePermanentPopup, icon: "DELETE", description: isArchive ? "ASPX_13" : "JS_97" });
	} else {
		if (filteredItemsIdsStr) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "folder", idAttribute: "cjmId" }), icon: "FOLDER-OPEN", description: "MOVE_TO_FOLDER" });
			if (isSubscriptionAllowed && !someIdAreSample) actions.push({ handleClick: showModalArchivePopup, icon: "ARCHIVE", description: "ARCHIVE" });
			actions.push({ handleClick: showModalDeletePopup, icon: "DELETE", description: "ASPX_13" });
			if (isManageAccessAllowed) actions.push({ handleClick: manageAccessMany, icon: "ACCESS", description: "ASPX_17" });
		}
		if (filteredItemsByOwnersAndContrib) {
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "tag", idAttribute: "cjmId" }), icon: "TAG", description: "TAG" });
			actions.push({ handleClick: () => showBulkModalLinkComponent({ componentName: "group", idAttribute: "cjmId" }), icon: "SHAPES", description: "GROUP" });
		}

	}

	return <BulkActionsGenerator actions={actions} setSelected={setSelected} warningText={"BULK_ACTIONS_MAP_OWNER_ONLY"} />;
};
