import { getAllCxAction, getAllCxProgram, getAllInsights, getAllMapsListData, getAllOpportunity, getAllPersonas, getAllSolutions, linkRecords, unlinkRecords, updateFolderLinks, updatePersonaLinksWithPersonas } from "@redux/index";
import { EMPTY_GUID, getEntityCurrentState } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import PickListData from "@utils/optionsSets/PickListData";
import { getAllProjects } from "features/projects/reducer/ProjectsActions";
import { t } from "i18next";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { shallowEqual, useSelector } from "react-redux";
import { List } from "react-virtualized";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ColorCheckState } from "shared/cx-components/style";
import { useAsyncs } from "shared/hooks/index";
import { ConceptSearchBar, FlexAlignCenter, FlexEndAlignCenter, GenericButton } from "shared/ui-basics/index";
import { Checkbox } from "shared/ui-basics/ui-components/Checkbox";
import { EntityListItem } from "shared/ui-basics/ui-components/EntityListItem";
import styled from "styled-components";

const getEntityTypeData = (props) => {
	const { folderId, entityType, cxProgramId, personaId } = props;
	let params = {};
	switch (entityType) {
		case EntityList.CustomerJourney:
			params = {
				entityName: "cem_customerjourney",
				attributeId: "cjmId",
				getAllRequest: getAllMapsListData,
				path: "maps.all_maps",
				title: t("ASPX_44"),
				statusPath: "customerJourney",
			};
			break;
		case EntityList.Opportunity:
			params = {
				entityName: "cem_cxopportunity",
				attributeId: "id",
				getAllRequest: getAllOpportunity,
				path: "opportunity.all_Opportunities",
				title: t("ASPX_44"),
				statusPath: "opportunity",
			};
			break;

		case EntityList.Action:
			params = {
				entityName: "cem_cxactiontask",
				attributeId: "id",
				getAllRequest: getAllCxAction,
				path: "actions.all_actions",
				title: t("ASPX_44"),
				statusPath: "cxActions",
			};
			break;
		case EntityList.Solution:
			params = {
				entityName: "cem_cxsolution",
				attributeId: "id",
				getAllRequest: getAllSolutions,
				path: "solutions.all_solutions",
				title: t("ASPX_44"),
				statusPath: "solutions",
			};
			break;
		case EntityList.CXProgram:
			params = {
				entityName: "cem_cxprogram",
				attributeId: "id",
				getAllRequest: getAllCxProgram,
				path: "cxProgram.all_cxPrograms",
				title: t("ASPX_44"),
			};
			break;
		case EntityList.Persona:
			params = {
				entityName: "cem_persona",
				attributeId: "personaId",
				getAllRequest: getAllPersonas,
				path: "personas.all_personas",
				title: t("ASPX_44"),
			};
			break;
		case EntityList.Project:
			params = {
				entityName: "cem_cxproject",
				attributeId: "id",
				getAllRequest: getAllProjects,
				path: "projects.all_projects",
				title: t("ASPX_44"),
			};
			break;
		case EntityList.Insight:
			params = {
				entityName: "cem_insight",
				attributeId: "id",
				getAllRequest: getAllInsights,
				path: "insights.all_insights",
				title: t("ASPX_44"),
			};
			break;

		default:
			break;
	}

	const LinkFunction = (linkElements, unlinkElements) => {
		if (folderId) {
			updateFolderLinks({ folderIds: [folderId], idsToLink: linkElements, idsToUnlink: unlinkElements, entityName: params.entityName });
		}
		if (cxProgramId) {
			if (linkElements.length > 0) linkRecords({ cxProgramId, entityName: params.entityName, ids: linkElements });
			if (unlinkElements.length > 0) unlinkRecords({ cxProgramId, entityName: params.entityName, ids: unlinkElements });
		}
		if (personaId) {
			if (linkElements.length > 0) updatePersonaLinksWithPersonas({ personaIdsToLink: linkElements.join("|"), personaIdsToUnlink: "", personaIdStr: personaId });
			if (unlinkElements.length > 0) updatePersonaLinksWithPersonas({ personaIdsToLink: "", personaIdsToUnlink: unlinkElements.join("|"), personaIdStr: personaId });
		}
	};

	return { ...params, linkFunction: LinkFunction };
};

function ListLinkedModal(props) {
	const { isOpen, onClose, linked = [], useGetAll = true, saveButtonLabel = "LINK" } = props;
	const { attributeId, getAllRequest, path, title, linkFunction, statusPath } = getEntityTypeData(props);
	const { t } = useTranslation();

	const [search, setSearchQuery] = useState("");
	const [selectedItems, setSelectedItems] = useState(linked);
	useAsyncs({asyncFns:[ {asyncFn: useGetAll ? getAllRequest : null }]});

	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const allRecords = useSelector((state) => _.get(state, path), shallowEqual)
		?.getActiveRecords(!showSampleData)
		?.removeParentEntity(props.entityType, props?.personaId || props?.cxProgramId)
		?.removeNoAccessRecord(props.entityType)
		?.searchFilter(search);
	const allStatus = useSelector((state) => state.libraries?.status);
	const states = PickListData("state");

	const handleChange = (e) => setSearchQuery(e.target.value);

	const handleCheckboxChange = (id) => {
		if (selectedItems.includes(id) === false) {
			setSelectedItems([...selectedItems, id]);
		} else {
			setSelectedItems(selectedItems.filter((item) => item !== id));
		}
	};
	const rowRender = ({ key, index, style }) => {
		const { entityType } = props;
		const record = allRecords[index];
		const id = record?.[attributeId];
		const status = allStatus?.[statusPath]?.find((s) => s.id === record.cxStatus);
		const state = getEntityCurrentState(record?.isFuture);
		const currentState = states.find((s) => s.value === state);
		return (
			<RecordContainer key={key} style={style} onClick={() => handleCheckboxChange(id)}>
				<Row className="my-2">
					<FlexAlignCenter>
						<Checkbox sze="md" type="checkbox" className="m-1" checked={selectedItems.includes(id)} onChange={() => { }} />
						<EntityListItem entity={record} entityType={entityType} disableClick={"true"} customClassName={"d-flex ms-3"} titleVariant="black" iconParams={{ size: "lg" }} />
					</FlexAlignCenter>
				</Row>
				<Row className="my-2">
					<DateContainer className="ms-5">
						<FlexAlignCenter>
							{record.lastModifiedOn && (
								<>
									{t("UPDATED_ON")}
									<Moment format="DD MMM, YYYY" date={record?.lastModifiedOn} className="ms-1" /> <div className="mx-2">{" | "}</div>
								</>
							)}
							{record?.isFuture && <LabelComponent label="JS_77" value={currentState?.name} color={ColorCheckState(currentState?.value)} />}
							{status && (
								<>
									<div className="mx-2">{" | "}</div>
									<LabelComponent label="ASPX_222" value={status?.name} color={status?.colorCode} />
								</>
							)}
						</FlexAlignCenter>
					</DateContainer>
				</Row>
			</RecordContainer>
		);
	};
	const submit = () => {
		const linkElements = selectedItems.filter((item) => linked.includes(item) === false);
		const unlinkElements = linked.filter((item) => selectedItems.includes(item) === false);
		linkFunction(linkElements, unlinkElements);
		onClose();
	};

	return (
		<Modal size="lg" isOpen={isOpen} fade={false} centered={true} toggle={onClose}>
			<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
			<ModalBody className="p-4">
				<FlexEndAlignCenter>
					<ConceptSearchBar globalFilterValue={search} handleChange={handleChange} />
				</FlexEndAlignCenter>
				<List width={764} height={486} rowCount={allRecords?.length} rowHeight={80} rowRenderer={rowRender} />
			</ModalBody>
			<ModalFooter>
				<GenericButton variant="outline-secondary" onClick={onClose}>
					{t("ASPX_86")}
				</GenericButton>
				<GenericButton variant="primary" onClick={submit}>
					{t(saveButtonLabel)}
				</GenericButton>
			</ModalFooter>
		</Modal>
	);
}

const LabelComponent = ({ label, value, color }) => {
	return (
		<FooterComponentStyle className="mx-2">
			<CustomLabel>{t(label) + ": "}</CustomLabel>
			<CustomValue className="ms-2" color={color}>
				{value}
			</CustomValue>
		</FooterComponentStyle>
	);
};
Array.prototype.removeParentEntity = function (entity, id) {
	return this.filter((item) => {
		switch (entity) {
			case EntityList.Persona:
				return item.personaId !== id;
			case EntityList.CXProgram:
				return item.id !== id;
			default:
				return item;
		}
	});
};

Array.prototype.removeNoAccessRecord = function (entity) {
	return this.filter((item) => {
		switch (entity) {
			case EntityList.Persona:{
				const roleName = item?.authorizedUsers?.find((user) => user.roleId === EMPTY_GUID)?.roleName;
				return roleName?.toLowerCase() !== 'viewer';
			}
			default:
				return item;
		}
	});
};
const RecordContainer = styled.div`
	border-bottom: 1px solid #e5e5e5;
`;
const FooterComponentStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const CustomLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 0.75rem;
	font-style: italic;
	font-weight: 300;
	line-height: normal;
`;
const CustomValue = styled.div`
	color: ${(props) => props.color};
	font-family: Inter;
	font-size: 0.75rem;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
`;
const DateContainer = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 12px !important;
	font-style: italic;
	font-weight: 400;
	line-height: 150%;
`;
export default React.memo(ListLinkedModal);
