import { LibraryItemCard } from "features/library/components/LibraryItemCard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreateLibraryItemCanvas, EditLibrariesModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { LibraryTemplate } from "../components/LibraryTemplate";
import MeasureForm from "../components/Measures/MeasureForm";
import { copyMeasure, deleteMeasure, measureInit } from "../reducer/LibraryActions";
import DataLoader from "shared/components/data-loader";

export const Measures = () => {
	const { t } = useTranslation();
	const measures = useSelector((state) => state.libraries.measures);

	const [showEditModal] = useModal(EditLibrariesModal);

	const [showCreateModal, hideCreateModal] = useModal(CreateLibraryItemCanvas, {
		title: "NEW_MEASURE",
		displayForm: () => <MeasureForm items={measures} mode="create" hideCreateModal={hideCreateModal} />,
	});

	const onFailed = (e) => {
		if (e?.response?.data === "measure_used") toast.info(t("USED_MEASURE"));
	};

	const displayLibraryItem = ({ item }) => {
		const deleteMeasures = ({ onSuccess }) => deleteMeasure({ measureId: item?.id, onSuccess, onFailed });
		const openEdit = () => showEditModal({ onDelete: deleteMeasure, object: item, context: "measure", title: "MEASURE", entityName: "measure" });
		const onCopy = ({id,name,workspaceIds}) => {
			copyMeasure({
				measureId:id,
				name:name,
				workspaceIds: workspaceIds
			});
		};
		return (
			<LibraryItemCard
				key={item?.id}
				item={item}
				handleEdit={openEdit}
				icon="CHART"
				deleteFunction={deleteMeasures}
				setFocus={item.setFocus}
				allowDeleteWithoutWarning
				actionType="DELETE_MEASURE"
				copyFunction={onCopy}
				label={"MEASURE"}
			/>
		);
	};

	return (
		<DataLoader reduxActions={[measureInit]}>
			<LibraryTemplate items={measures} title="MEASURES" addBtnText="NEW_MEASURE" showCreateModal={showCreateModal} displayLibraryItem={displayLibraryItem} />
		</DataLoader>
	);
};
