import { CustomEditor } from "shared/components/CustomEditor";
import React from "react";

function Descriptions({ object, update, attribute, placeholder, mode }) {
	const [descriptions, setDescriptions] = React.useState(object?.[attribute]);

	const handleBlur = () => {
		const interaction = { ...object, [attribute]: descriptions.truncate(19999) };
		if (descriptions !== object[attribute]) update({ interaction, modifiedAttribNames: [attribute] });
	};

	return <CustomEditor html={descriptions} sethtml={setDescriptions} onBlur={handleBlur} placeholder={placeholder} variant="outlined" disabled={mode.IsViewer()} />;
}

Descriptions.displayName = "Descriptions";
export default Descriptions;
