import Tags from "features/grid-table/columns-components/Tags";
import { CardContainer } from "features/analytics/analyticsHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components/macro";

function ActionListCard({ item, isLastItem, onClose }) {
	const history = useHistory();
	const redirect = () => {
		onClose();
		history.push(`/cx-actions?id=${item?.id}&origin=1`);
	};
	return (
		<CardContainer className="p-4" islast={isLastItem}>
			<Body>
				<Name onClick={redirect}>{item?.subject}</Name>
				{Tags(item)}
			</Body>
		</CardContainer>
	);
}

ActionListCard.displayName = "ActionListCard";
export default ActionListCard;

const Body = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.div`
	cursor: pointer;
`;
