import { GenericButton } from "shared/ui-basics/buttons";
import { FlexAlignCenter } from "shared/ui-basics/index";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Badge, BadgeWrapper, RemoveButton } from "./PicklistCard_style";

export const KpiRelationCard = ({ index, entity, unlink, length, disable }) => {
	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";

	return (
		<Badge key={entity?.id} id={entity?.id}>
			<BadgeWrapper index={index} length={length}>
				<div className="ellipsis obj-name px-2">{entity?.measureName?.defaultPlaceholder("KPI_NAME")}</div>
				<FlexAlignCenter className="px-4">
					<Impact className="mx-2">
						{Number(entity?.financialImpact).toFixed(2)}
						{currencySymbol}
					</Impact>
					{!disable && (
						<RemoveButton>
							<GenericButton variant="light-link" id={entity?.id} icon="X" iconClassName="p-0" onClick={unlink} />
						</RemoveButton>
					)}
				</FlexAlignCenter>
			</BadgeWrapper>
		</Badge>
	);
};

const Impact = styled.div`
	color: #f96209;
	font-family: "Oswald", sans-serif;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
