import { getBrandValueLinkedRecords, getCustomLibraryLinkedRecords, getIconsLinkedRecords, getMeasuresLinkedRecords, getTagLinkedRecords, getTouchpointLinkedRecords, upsertTag } from "@redux/index";
import DataTable from "features/grid-table/DataTable";
import BrandValueForm from "features/library/components/BrandValues/BrandValueForm";
import LibraryValueForm from "features/library/components/LibraryValue/LibraryValueForm";
import MeasureForm from "features/library/components/Measures/MeasureForm";
import StickerForm from "features/library/components/Stickers/StickerForm";
import TagForm from "features/library/components/Tags/TagForm";
import TouchPointForm from "features/library/components/TouchPoints/TouchPointForm";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Spinner } from "shared/components/spinners/Spinner";
import { useAsync } from "shared/hooks/index";
import { FlexBetweenAlignCenter, GenericButton, SearchBar } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import "./ModalStyle.scss";

const buildModalByContext = (props, childRef) => {
	const data = {
		recordId: props?.object.id,
	};
	switch (props?.context) {
		case "brandValue":
			data.displayForm = (onClose) => <BrandValueForm index={props?.object} selectedBrandValue={props?.object} onDelete={props?.onDelete} onClose={onClose} />;
			data.pathToAllRecords = "libraries.brandValues";
			data.linkedRecordsAttribute = "linkedCxProgram";
			data.getLinkedRecords = getBrandValueLinkedRecords;
			data.linkedRecordsTitle = "RELATED_CX_PROGRAMS";
			data.parameter = { brandValueId: props?.object.id };
			data.pathContext = "cxPrograms.library";
			break;
		case "libraryValue":
			data.displayForm = (onClose) => <LibraryValueForm onDelete={props?.onDelete} selectedItem={props?.object} type={props?.type} onClose={onClose} libraryId={props?.libraryId} />;
			data.pathToAllRecords = `libraries.customLibraries[${props?.indexLibrary}].values`;
			data.linkedRecordsAttribute = "linkedCjms";
			data.getLinkedRecords = getCustomLibraryLinkedRecords;
			data.linkedRecordsTitle = "CS_37";
			data.parameter = { libraryId: props?.libraryId, libraryValueId: props?.object.id };
			data.pathContext = "map.library";
			break;
		case "tag":
			data.displayForm = (onClose) => <TagForm selectedItem={props?.object} onUpdate={(tag, onSuccess) => upsertTag({ tag, onSuccess })} onDelete={props?.onDelete} onClose={onClose} />;
			data.pathToAllRecords = "libraries.tags";
			data.linkedRecordsAttribute = "linkedPersonas";
			data.getLinkedRecords = getTagLinkedRecords;
			data.linkedRecordsTitle = "RELATED_PERSONAS";
			data.parameter = { tagId: props?.object.id };
			data.pathContext = "personas.library";
			break;
		case "measure":
			data.displayForm = (onClose) => <MeasureForm mode={"update"} onDelete={props?.onDelete} selectedItem={props?.object} onClose={onClose} libraryId={props?.libraryId} />;
			data.pathToAllRecords = "libraries.measures";
			data.linkedRecordsAttribute = "linkedCjms";
			data.getLinkedRecords = getMeasuresLinkedRecords;
			data.linkedRecordsTitle = "CS_37";
			data.parameter = { measureId: props?.object.id };
			data.pathContext = "map.library";
			data.isDataFormatable = true;
			break;
		case "touchpoint":
			data.displayForm = (onClose) => (
				<TouchPointForm
					index={props?.object.id}
					srcImg={props?.pictureValidation}
					onDelete={props?.deleteCustom}
					onUpdate={props?.updateCustom}
					selectedItem={props?.object}
					category={props?.category}
					onClose={onClose}
				/>
			);
			data.pathToAllRecords = `libraries.touchPoint`;
			data.linkedRecordsAttribute = "linkedCjms";
			data.getLinkedRecords = getTouchpointLinkedRecords;
			data.linkedRecordsTitle = "CS_37";
			data.parameter = { touchpointId: props?.object.id };
			data.pathContext = "map.library";
			break;
		case "icon":
			data.displayForm = (onClose) => (
				<StickerForm
					index={props?.object?.id}
					srcImg={props?.pictureValidation}
					onDelete={props?.deleteCustom}
					onUpdate={props?.updateCustom}
					selectedItem={props?.object}
					category={props?.category}
					onClose={onClose}
				/>
			);
			data.pathToAllRecords = `libraries.icons`;
			data.linkedRecordsAttribute = "linkedCjms";
			data.getLinkedRecords = getIconsLinkedRecords;
			data.linkedRecordsTitle = "CS_37";
			data.parameter = { iconNumber: props?.object.number };
			data.pathContext = "map.library";
			break;
		default:
			break;
	}
	return data;
};

export const EditLibrariesModal = ({ isOpen, onClose, showlinkedRecordsMaps = true, displayCjmTable, entityName, title, ...props }) => {
	const { t } = useTranslation();
	const childRef = useRef(null);
	const { displayForm, pathToAllRecords, linkedRecordsAttribute, linkedRecordsTitle, recordId, getLinkedRecords, parameter, pathContext, isDataFormatable } = buildModalByContext(props, childRef);

	const maps = useSelector((state) => state.maps.all_maps);
	const cxPrograms = useSelector((state) => state.cxProgram.all_cxPrograms);
	const linkedData = useSelector((state) => _.get(state, pathToAllRecords)?.find((rec) => rec.id === recordId))?.[linkedRecordsAttribute];
	const [linkedItems, setLinkedItems] = useState(displayCjmTable ? {} : []);
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState(false);
	useAsync({ asyncFn: getLinkedRecords, parameter, loading: setLoading });

	useEffect(() => {
		setLinkedItems(getLinkedItems());
	}, [linkedData]);

	const transformLinkedData = () => {
		// Get a linkedItems object from Libraries (Touchpoints / icons / Custom Lib)
		// and return an array of maps with the related stageName for each map

		const items = [];
		if (linkedData) {
			for (const [mapId, stages] of Object.entries(linkedData)) {
				const map = maps.find((map) => map.cjmId === mapId);
				if (map) {
					for (const [stageId, stageName] of Object.entries(stages)) {
						items.push({ ...map, linkedStageName: stageName });
					}
				}
			}
		}
		return items;
	};

	const getLinkedItems = () => {
		if (displayCjmTable || isDataFormatable) return transformLinkedData();
		if (pathContext === "cxPrograms.library") {
			return linkedData?.map((data) => {
				const item = cxPrograms?.find((p) => p.id === data);
				if (item) return { id: item?.id, name: item?.name };
			});
		}
		return linkedData;
	};

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchValue(value);
		if (!value) setLinkedItems(getLinkedItems());
		else setLinkedItems(linkedItems.filter((map) => map?.name?.toLowerCase().includes(value.toLowerCase())));
	};

	const handleCheckLength = (data) => {
		if (!data) return false;
		if (Array.isArray(data)) return data.length > 0;
		return Object.keys(data)?.length > 0;
	};

	return (
		<Modal className={`${showlinkedRecordsMaps ? "modal-xl" : ""}`} fade={false} isOpen={isOpen} toggle={onClose} centered={true}>
			<Header toggle={onClose}>
				{!recordId ? t("NEW") : t("EDIT") + " "}
				{t(title)}
			</Header>
			<ModalBody>
				{showlinkedRecordsMaps && (
					<Row>
						<LeftCol className="p-5 col-4">{displayForm(onClose)}</LeftCol>
						<RightCol className="p-4 col-8 position-relative">
							{loading && <Spinner placeholder={t("LOADING_DATA")} isFixed={false} />}
							<RelatedContainer>
								{!loading && (
									<>
										<LabelWrapper className="mt-4 ms-3">{t(linkedRecordsTitle)}</LabelWrapper>
										<Number className="ms-2">{"(" + linkedItems?.length + ")"}</Number>
										{handleCheckLength(linkedData) && (
											<FlexBetweenAlignCenter className="mb-4 me-4">
												<div className="ms-3">
													<SearchBar globalFilterValue={searchValue} handleChange={handleSearchChange} placeholder={t("SEARCH") + " ..."} floatLabel={false} />
												</div>
												<GenericButton
													variant="outline-secondary"
													icon={"EXCEL"}
													iconClassName="me-1"
													onClick={() => childRef.current.exportRelatedExcelClick(recordId, entityName)}>
													{t("ASPX_14")}
												</GenericButton>
											</FlexBetweenAlignCenter>
										)}
									</>
								)}
							</RelatedContainer>
							<TableContainer>
								{!loading && <DataTable context={pathContext} ref={childRef} linked={pathContext === "cxPrograms.library" ? linkedItems?.map((li) => li?.id) : linkedItems} />}
							</TableContainer>
						</RightCol>
					</Row>
				)}
				{!showlinkedRecordsMaps && displayForm(onClose)}
			</ModalBody>
		</Modal>
	);
};

const LeftCol = styled(Col)``;

const RightCol = styled(Col)`
	border-left: 1px dashed #c4c4c4;
`;

const Header = styled(ModalHeader)`
	h5 {
		font-family: "Segoe UI";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 27px;
	}
`;

const TableContainer = styled.div`
	/* height: 555px; */
	overflow: scroll;
	padding: 1rem;
	margin: 0;
	/* min-height: 555px;     */
	/* overflow: hidden; */
`;

const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

const Number = styled.span`
	color: #92919d;
`;

const RelatedContainer = styled.div`
	min-height: 80px;
`;
