import React, { memo, useCallback, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";
import { v4 as uuid } from "uuid";
import { addMultiSwimlanes } from "features/index";
import { GenericButton } from "shared/ui-basics/index";
import SelectionCard from "features/journey-map/modals/components/SelectionCard";
import { InformationType } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, StripHtml, isSubscriptionAllowed } from "@utils/helpers";
import { useEffect } from "react";

const NewSwimlanesModal = memo(({ isOpen, onClose, title, endOfPageRef }) => {
	const { t } = useTranslation();
	const location = useLocation().search;

	const cjmIdStr = location.getCjmId();
	const personaIdStr = location.getPersonaId();

	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const cjmSwimlanes = useSelector((state) => state.maps.current_map?.cjmSwimLanes);
	const libraries = useSelector((state) => state.libraries.customLibraries) || [];

	const [selectedSwimlanes, setSelectedSwimlanes] = useState([]);
	const [key, setKey] = useState("basic");
	useEffect(() => {
		if (!isOpen) {
			setSelectedSwimlanes([]);
		}
	}, [isOpen]);

	const swimlanes = InitSwimlanes(subscriptionPlan, cjmSwimlanes);
	const basicSwimlanes = swimlanes.slice(0, 5);
	const advancedSwimlanes = swimlanes.slice(5, swimlanes.length);
	const addSelected = () => {
		onClose();
		const onSuccess = () => {
			endOfPageRef.current?.scrollIntoView({ behavior: "smooth" });
		};
		addMultiSwimlanes({ swimlanes: selectedSwimlanes, cjmId: cjmIdStr, personaIdStr, order: cjmSwimlanes.length, onSuccess });
	};

	const handleSwimlaneSelection = useCallback(
		(object) => {
			const isAlreadySelected = selectedSwimlanes.find((selected) => (selected.type === InformationType.CustomLibrary ? object.libraryId === selected.libraryId : selected.type === object.type));

			if (isAlreadySelected) {
				setSelectedSwimlanes(
					selectedSwimlanes.filter((selected) => (selected.type === InformationType.CustomLibrary ? object.libraryId !== selected.libraryId : selected.type !== object.type))
				);
			} else {
				let libraryId = object?.libraryId || EMPTY_GUID;

				switch (object.type) {
					case InformationType.Systems:
						libraryId = libraries?.filter((x) => x?.isCustom === false && x.name?.toLowerCase() === "systems")?.[0]?.id;
						break;
					case InformationType.Departments:
						libraryId = libraries?.filter((x) => x?.isCustom === false && x.name?.toLowerCase() === "departments")?.[0]?.id;
						break;
					case InformationType.KPIs:
						libraryId = libraries?.filter((x) => x?.isCustom === false && x.name?.toLowerCase() === "kpis")?.[0]?.id;
						break;
					default:
						break;
				}
				setSelectedSwimlanes([...selectedSwimlanes, { id: uuid(), libraryId, type: object.type, name: "" }]);
			}
		},
		[selectedSwimlanes, libraries]
	);

	const checkIfDisabled = useCallback(
		(lane) => {
			const haveInteractions = selectedSwimlanes.find((selected) => selected.type === InformationType.AdvancedInteractions);
			const haveFellings = selectedSwimlanes.find((selected) => selected.type === InformationType.Feelings);
			const haveTouchPoints = selectedSwimlanes.find((selected) => selected.type === InformationType.Touchpoints);
			if ((haveInteractions && lane.type === InformationType.Feelings) || (haveInteractions && lane.type === InformationType.Touchpoints)) return true;

			if ((haveFellings || haveTouchPoints) && lane.type === InformationType.AdvancedInteractions) return true;

			if (lane.isExpert && subscriptionPlan === "Standard") return true;

			return false;
		},
		[selectedSwimlanes, subscriptionPlan]
	);

	return (
		<CustomModal isOpen={isOpen} fade={false} centered={true} toggle={onClose} container={document.getElementById("modal-root")}>
			<ModalHeader toggle={onClose} tag="h5">
				{title ? t(title) : t("SELECT_SWIMLANES")}
			</ModalHeader>
			<ModalBody className="px-0">
				<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3" mountOnEnter={true} unmountOnExit={true}>
					<Tab eventKey="basic" title={"Basic"}>
						<CardsContainer className="p-4">
							{basicSwimlanes?.map((lane) => (
								<SelectionCard
									key={lane.type}
									object={lane}
									selected={selectedSwimlanes.find((selected) => selected.type === lane.type) && true}
									handleClick={handleSwimlaneSelection}
									showLabel={subscriptionPlan === "Standard"}
									disabled={checkIfDisabled(lane)}
								/>
							))}
						</CardsContainer>
					</Tab>
					<Tab eventKey="advanced" title={"Advanced"}>
						<CardsContainer className="p-4">
							{advancedSwimlanes?.map((lane) => {
								const selected = selectedSwimlanes.find((selected) =>
									selected.type === InformationType.CustomLibrary ? lane.libraryId === selected.libraryId : selected.type === lane.type
								);
								const isCustomLibrary = lane.type === InformationType.CustomLibrary;

								return (
									<SelectionCard
										key={isCustomLibrary ? lane.libraryId : lane.type}
										object={lane}
										selected={selected}
										handleClick={handleSwimlaneSelection}
										showLabel={subscriptionPlan !== "Standard"}
										disabled={checkIfDisabled(lane)}
									/>
								);
							})}
						</CardsContainer>
					</Tab>
				</Tabs>
			</ModalBody>
			<ModalFooter>
				<GenericButton variant="primary" onClick={addSelected} disabled={selectedSwimlanes.length === 0}>
					{t("ADD_SELECTED")}
				</GenericButton>
			</ModalFooter>
		</CustomModal>
	);
});

const InitSwimlanes = (subscriptionPlan, cjmSwimlanes) => {
	const swimlanes = [];
	const { t } = useTranslation();

	const libraries = useSelector((state) => state.libraries.customLibraries)?.filter((x) => x?.isCustom === true) || [];
	const { Timing, Systems, Departments, Feelings, AdvancedInteractions, Touchpoints, KPIs, ActionsList, Opportunity, CustomLibrary, Solution, CustomerFeedback, Insights } = InformationType;

	swimlanes.push({ type: InformationType.Text, image: "SWIMLANE-TEXT", title: t("TEXT"), description: t("TEXT_SWIMLANES_DESCRIPTION"), multi: true, disable: false });
	swimlanes.push({ type: InformationType.Image, image: "SWIMLANE-IMAGE", title: t("IMAGE"), description: t("IMAGE_SWIMLANES_DESCRIPTION"), multi: true, disable: false });
	swimlanes.push({ type: InformationType.ListItems, image: "SWIMLANE-LI", title: t("LIST_ITEMS"), description: t("LIST_ITEMS_SWIMLANES_DESCRIPTION"), multi: true, disable: false });
	swimlanes.push({
		type: Feelings,
		image: "SWIMLANE-FEELINGS",
		title: t("ASPX_31"),
		description: t("FEELINGS_SWIMLANES_DESCRIPTION"),
		multi: false,
		disable: cjmSwimlanes?.find((lane) => [Feelings, AdvancedInteractions].includes(lane?.type)),
	});
	swimlanes.push({ type: InformationType.Divider, image: "SWIMLANE-DIVIDER", title: t("DIVIDER_LINE"), description: t("DIVIDER_SWIMLANES_DESCRIPTION"), multi: true, disable: false });

	swimlanes.push({
		type: AdvancedInteractions,
		image: "SWIMLANE-TOUCHPOINTS",
		title: t("ASPX_27"),
		description: t("ADVANCED_TOUCHPOINTS_DESCRIPTION"),
		multi: false,
		disable: cjmSwimlanes?.find((lane) => [Feelings, Touchpoints, AdvancedInteractions].includes(lane?.type)),
	});
	isSubscriptionAllowed(subscriptionPlan, "regular") &&
		swimlanes.push({
			type: Opportunity,
			image: "SWIMLANE-OPPT",
			title: t("OPPORTUNITIES"),
			description: t("OPPORTUNITY_SWIMLANES_DESCRIPTION"),
			multi: false,
			isExpert: true,
			disable: cjmSwimlanes?.find((lane) => lane?.type === Opportunity) !== undefined,
		});
	isSubscriptionAllowed(subscriptionPlan, "regular") &&
		swimlanes.push({
			type: Solution,
			image: "SWIMLANE-SOLUTION",
			title: t("SOLUTIONS"),
			description: t("SOLUTION_SWIMLANES_DESCRIPTION"),
			multi: false,
			isExpert: true,
			disable: cjmSwimlanes?.find((lane) => lane?.type === Solution) !== undefined,
		});

	isSubscriptionAllowed(subscriptionPlan, "regular") &&
		swimlanes.push({
			type: ActionsList,
			image: "SWIMLANE-ACTIONS",
			title: t("ACTION_SWIMLANE"),
			description: t("ACTION_ITEMS_SWIMLANES_DESCRIPTION"),
			multi: false,
			isExpert: true,
			disable: cjmSwimlanes?.find((lane) => lane?.type === ActionsList) !== undefined,
		});
	swimlanes.push({
		type: Timing,
		image: "SWIMLANE-TIMING",
		title: t("TIMING"),
		description: t("TIMING_SWIMLANES_DESCRIPTION"),
		multi: false,
		disable: cjmSwimlanes?.find((lane) => lane?.type === Timing) !== undefined,
	});
	isSubscriptionAllowed(subscriptionPlan, "regular") &&
		swimlanes.push({
			type: KPIs,
			image: "CHART-LINE-UP",
			size: "2xl",
			title: t("KPIS"),
			description: t("KPIS_SWIMLANES_DESCRIPTION"),
			multi: false,
			isExpert: true,
			disable: cjmSwimlanes?.find((lane) => lane?.type === KPIs) !== undefined,
		});
	swimlanes.push({
		type: Touchpoints,
		image: "SWIMLANE-TOUCHPOINTS",
		title: t("TOUCHPOINTS"),
		description: t("TOUCHPOINTS_SWIMLANES_DESCRIPTION"),
		multi: false,
		disable: cjmSwimlanes?.find((lane) => [Touchpoints, AdvancedInteractions].includes(lane?.type)),
	});
	swimlanes.push({
		type: Insights,
		image: "GEM",
		size: "2xl",
		title: t("ASPX_33"),
		description: t("INSIGHTS_DESCRIPTION"),
		multi: true,
		disable: false,
	});
	isSubscriptionAllowed(subscriptionPlan, "limited") &&
		swimlanes.push({
			type: CustomerFeedback,
			image: "SWIMLANE-FEEDBACK-DATA",
			title: t("FEEDBACK_DATA"),
			description: t("CUSTOMER_FEEDBACK_SWIMLANES_DESCRIPTION"),
			multi: false,
			isExpert: true,
			disable: cjmSwimlanes?.find((lane) => lane?.type === CustomerFeedback) !== undefined,
		});
	swimlanes.push({
		type: Systems,
		image: "SWIMLANE-SYSTEM",
		title: t("SYSTEMS"),
		description: t("SYSTEMS_SWIMLANES_DESCRIPTION"),
		multi: false,
		disable: cjmSwimlanes?.find((lane) => lane?.type === Systems) !== undefined,
	});
	swimlanes.push({
		type: Departments,
		image: "SWIMLANE-DEPARTMENT",
		title: t("DEPARTMENTS"),
		description: t("DEPARTMENTS_SWIMLANES_DESCRIPTION"),
		multi: false,
		disable: cjmSwimlanes?.find((lane) => lane?.type === Departments) !== undefined,
	});

	isSubscriptionAllowed(subscriptionPlan, "regular");
	libraries?.map((x) => {
		return swimlanes.push({
			type: CustomLibrary,
			libraryId: x?.id,
			image: "SWIMLANE-CUSTOM",
			title: x?.name,
			description: StripHtml(x?.description),
			multi: false,
			disable: cjmSwimlanes?.find((lane) => lane?.type === CustomLibrary && lane?.libraryId === x?.id) !== undefined,
		});
	});
	return swimlanes;
};

NewSwimlanesModal.displayName = "NewSwimlanesModal";
export { NewSwimlanesModal };
const CustomModal = styled(Modal)`
	height: 700px !important;
	width: 500px !important;
`;

const CardsContainer = styled.div`
	max-height: 500px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 40px;
`;
