import React from "react";
import { FlexAlignCenter, FlexAlignJustifyCenter, FlexBetweenAlignCenter, GenericButton } from "shared/ui-basics/index";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import { setSelectedIdTable } from "features/grid-table/reducer/TableSlice";

export function BulkActionsGenerator({ actions, setSelected, warningText }) {
	const { t } = useTranslation();
	const selectedIds = useSelector((state) => state.ui.table.selectedIds);

	return (
		<>
			{actions?.length > 0 ? (
				<FlexBetweenAlignCenter className="w-100">
					<div className="d-flex ps-4 ">
						<NumberWrapper className="p-1">{selectedIds?.length}</NumberWrapper>
						<Label className="px-2">{t("SELECTED")}</Label>
					</div>
					<FlexAlignCenter>
						{actions?.map((action, index) => {
							if (action?.renderCustomComponent) {
								return <div key={index}>{action?.renderCustomComponent()}</div>;
							}
							return (
								<GenericButton key={index} variant="light-bulk" className="mx-2" size="m" icon={action.icon} onClick={action.handleClick}>
									<span className="ms-2">{t(action.description)}</span>
								</GenericButton>
							);
						})}
						<DivWrapper>
							<GenericButton
								variant="light-bulk"
								className="mx-2"
								size="m"
								icon="X"
								onClick={() => {
									setSelectedIdTable();
									setSelected(null);
								}}
							/>
						</DivWrapper>
					</FlexAlignCenter>
				</FlexBetweenAlignCenter>
			) : (
				<FlexAlignJustifyCenter className="w-100">
					<Text className="p-2">{t(warningText)}</Text>
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}
const DivWrapper = styled.div`
	border-left: 1px solid #c4c4c4;
`;
const NumberWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 23px;
	height: 26px;
	background: #f96209;
	color: white;
	border-radius: 4px;
`;

const Text = styled.div``;
