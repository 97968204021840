import { createCustomIcon, iconsInit } from "@redux/index";
import { EMPTY_GUID } from "@utils/helpers";
import { useSelector } from "react-redux";
import DataLoader from "shared/components/data-loader";
import { CreateLibraryItemCanvas } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { LibraryTemplate } from "../components/LibraryTemplate";
import { StickerCard } from "../components/Stickers/StickerCard";
import StickerForm from "../components/Stickers/StickerForm";

const Stickers = () => {
	const iconsDB = useSelector((state) => state.libraries.icons);
	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const categories = [
		{ name: "ALL", id: EMPTY_GUID },
		{ name: "EMOTIONS", id: "100000001" },
		{ name: "ASPX_147", id: "100000000" },
	];

	const createCustom = async (newIconName, category, weight, imageExtension, imageBody64) => {
		createCustomIcon({ newIconName, category, imageExtension, imageBody64, displayToast: "CREATE", onSuccess: hideCreateModal });
	};

	const [showCreateModal, hideCreateModal] = useModal(CreateLibraryItemCanvas, {
		title: "NEW_STICKER",
		displayForm: () => <StickerForm data={iconsDB} mode="create" onCreate={createCustom} category={categories} />,
	});

	const displayLibraryItem = ({ item }) => {
		return <StickerCard key={item.id} setFocus={item.setFocus} category={categories} icon={item} userType={userType} />;
	};

	return (
		<DataLoader reduxActions={[iconsInit]}>
			<LibraryTemplate items={iconsDB} title="STICKERS" addBtnText="NEW_STICKER" showCreateModal={showCreateModal} categories={categories} displayLibraryItem={displayLibraryItem} />
		</DataLoader>
	);
};

export default Stickers;
