import React, { useRef } from "react";
import styled, { css } from "styled-components/macro";
import CustomTooltip from "../CustomTooltip";
import { IconDispatcher } from "shared/ui-basics/index";
import { OverlayPanel } from "primereact/overlaypanel";
import { Badge } from "react-bootstrap";
import { GroupPointSelector } from "./GroupPointSelector";
import { useLocation, useHistory } from "react-router-dom";
import { getDefaultName } from "@utils/helpers";

export function ChartPoint({ items, index, xCoordinate, yCoordinate, CustomPoint, route, baseUnit, isDoubleWidth }) {
	const location = useLocation();
	const pathname = location?.pathname;
	const hideForPdf = location.search.hideForPdf();
	const refwrapper = useRef(null);
	const op = useRef(null);
	const history = useHistory();
	const isQuadrant = pathname === "/solutions";
	const multiplier = isDoubleWidth ? 2 : 1;

	const handleClick = () =>{
		history.push(`${pathname}?tab=chart`)
		history.push(`/${route}?id=${items[0].id}&origin=1`)
	}

	const calculateXAxis = () => {
        if (xCoordinate) {
			if (isQuadrant) return xCoordinate * (baseUnit / 2);
			else return xCoordinate * baseUnit * multiplier;
		} else return 0;
	};

	const calculateYAxis = () => {
		if (yCoordinate) {
			if (isQuadrant) return yCoordinate * (baseUnit / 2);
			else return yCoordinate * baseUnit;
		} else return 0;
    };

	const xAxis = calculateXAxis();
    const yAxis = calculateYAxis();

	return (
		<>
			{items?.length === 1 ? (
				<ChartPointContainer xAxis={xAxis} yAxis={yAxis}>
					<CustomTooltip text={items[0]?.subject.defaultPlaceholder(getDefaultName(pathname))}>
						<CustomPoint key={items[0].id} onClick={handleClick} xCoordinate={xCoordinate} yCoordinate={yCoordinate}>
							{hideForPdf && (
								<BadgeWrapper pill size={1} bg="secondary">
									{index + 1}
								</BadgeWrapper>
							)}
						</CustomPoint>
					</CustomTooltip>
				</ChartPointContainer>
			) : (
				<ChartPointContainer xAxis={xAxis} yAxis={yAxis} multiItems>
					<CustomPoint key={items[0].id} ref={refwrapper} onClick={(e) => op.current.toggle(e)} xCoordinate={xCoordinate} yCoordinate={yCoordinate} multiItems>
						<MultiItemsPoint className="mt-2">
							{IconDispatcher("PLUS")}
							{items?.length}
							{hideForPdf && (
								<BadgeWrapper pill size={items?.length} bg="secondary">
									{index + 1}
								</BadgeWrapper>
							)}
						</MultiItemsPoint>
						<OverlayPanel ref={op}>
							<GroupPointSelector items={items} route={route} />
						</OverlayPanel>
					</CustomPoint>
				</ChartPointContainer>
			)}
		</>
	);
}

const BadgeWrapper = styled(Badge)`
	position: absolute;
	bottom: 25px;
	right: -5px;
`;

const ChartPointContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 42px;
	position: absolute;
	z-index: 999;
	bottom: calc(-21px + ${(props) => props.yAxis}px + ${(props) => (props.multiItems ? 0 : 0)}px);
	left: calc(-21px + ${(props) => props.xAxis}px + ${(props) => (props.multiItems ? 2 : 0)}px);
`;

const MultiItemsPoint = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;
