import { EMPTY_GUID } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled, { css } from "styled-components";
import { IconDispatcher } from "../../Icons";

const contextData = (entityType, entity) => {
	const data = {};
	switch (entityType) {
		case EntityList.Action:
			data.icon = { name: "MENU-ACTION", iconStyle: { color: "#FDD03E" } };
			data.targetUrl = `/edit-cxactions?id=${entity?.Id || entity?.id}`;
			data.name = entity?.Subject || entity?.subject;
			break;
		case EntityList.Opportunity:
			data.icon = { name: "OPPORTUNITY", iconStyle: { color: "#20A9E9" } };
			data.targetUrl = `/edit-opportunity?id=${entity?.Id || entity?.id}`;
			data.name = entity?.Subject || entity?.subject;
			break;
		case EntityList.Solution:
			data.icon = { name: "SOLUTION", iconStyle: { color: "#FDD03E" } };
			data.targetUrl = `edit-solution?id=${entity?.Id || entity?.id}`;
			data.name = entity?.Subject || entity?.subject;
			break;
		case EntityList.CXProgram:
			data.icon = { name: "CX-PROGRAM", iconStyle: { color: "#F96209" } };
			data.targetUrl = `/EditCxProgram?programid=${entity?.Id || entity?.id}`;
			data.name = entity?.Name || entity?.name;
			break;
		case EntityList.CustomerJourney:
			data.icon = { name: "CJM", iconStyle: { color: "#18AEAD" } };
			data.targetUrl = `/EditMap?cjmid=${entity?.CjmId || entity?.cjmId}&personaId=${entity?.LinkedPersonas?.[0]?.PersonaId || entity?.linkedPersonas?.[0]?.personaId || EMPTY_GUID}`;
			data.name = entity?.Name || entity?.name;
			break;
		case EntityList.Persona:
			data.icon = { name: "PERSONA", iconStyle: { color: "#F48E09" } };
			data.targetUrl = `/EditPersona?personaIdStr=${entity?.PersonaId || entity?.personaId}`;
			data.name = entity?.Name || entity?.name;
			break;
		case EntityList.Global: //folders
			data.icon = { name: "FOLDER-OPEN", iconStyle: { color: "#FDD03E" } };
			data.targetUrl = null;
			data.name = entity?.Name || entity?.name;
			break;
		case EntityList.Project:
			data.icon = { name: "PROJECT", iconStyle: { color: "#655CB1" } };
			data.targetUrl = `/EditProject?id=${entity?.Id || entity?.id}`;
			data.name = entity.Name || entity.name;
			break;
		case EntityList.Insight:
			data.icon = { name: "GEM", iconStyle: { color: "#655CB1" } };
			data.targetUrl = `/insights?id=${entity?.Id || entity?.id}`;
			data.name = entity.Name || entity.name;
			break;
		default:
			break;
	}
	return data;
};

export const EntityListItem = ({ entityType, entity, onClick, disableClick = "false", customClassName = "d-flex pb-4", type, iconParams, titleVariant }) => {
	const history = useHistory();
	const data = contextData(entityType, entity);

	if (data.name === "") data.name = "Default name";

	const redirectTo = () => {
		onClick && onClick();
		if (data?.targetUrl) {
			history.push(data?.targetUrl + "&origin=1");
		}
	};

	return (
		<ListItemContainer className={customClassName} type={type}>
			<IconContainer>{IconDispatcher(data?.icon?.name, "me-2", iconParams && iconParams)}</IconContainer>
			<Redirect disableClick={disableClick} onClick={redirectTo} className="d-flex align-items-center" variant={titleVariant}>
				<div className="text-truncate w-100">{data?.name}</div>
			</Redirect>
		</ListItemContainer>
	);
};

const ListItemContainer = styled.div`
	${(props) =>
		props.type === "project" &&
		css`
			max-width: 300px;
		`}
`;

export const Redirect = styled.div`
	width: 100%;
	color: #605f60;

	text-decoration: none;
	${(props) => props.disableClick === "true" && "pointer-events:none;"}
	:hover {
		color: #333333;
		cursor: pointer;
	}
	${(props) =>
		props.variant === "black" &&
		css`
			color: #333;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
		`}
`;

const IconContainer = styled.div`
	color: #605f60;
`;
