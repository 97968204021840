import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from 'shared/components/spinners/Spinner';
import { getLoginUrl } from '../reducer/AuthActions';

export default function Authenticate() {

  const location = useLocation();
  const history = useHistory();
  const envName = window.location.pathname.split('/')[1];
  const targetPage =  location.search?.getTargetUrl() || '';
  console.log(targetPage);
  useEffect(() => {
        if (envName.length === 0) {
          return history.push('/invalid-url')
        }
        getLoginUrl({ envName, targetPage , onSuccess: (response) => {
          if (response) {
            window.location.href = response
          }
        }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <><Spinner full={true} placeholder={"We’re connecting you"} /></>
  )
}
