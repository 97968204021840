import { upsertSavedView } from "@redux/index";
import { EMPTY_GUID } from "@utils/helpers";
import { loadTableProps } from "features/grid-table/reducer/TableSlice";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { GenericButton, Input } from "shared/ui-basics/index";

const newObject = {
    name: "",
    level: false,
    id: EMPTY_GUID

}
export const EditFilterView = ({ isOpen, onClose, propView, viewFilter, context, entityType }) => {
    const { t } = useTranslation();
    const filters = useSelector(state => state.ui.table.entity)?.[entityType]?.[context]?.filters || ""
    const sort = useSelector(state => state.ui.table.entity)?.[entityType]?.[context]?.sort || {}
    const mode = viewFilter ? 'update' : 'create';
    const [object, setNewObject] = useState(viewFilter ?? newObject)

    const [typeView, setTypeView] = useState(object?.level);

    const handleChange = (e) => {
        setNewObject({ ...object, name: e.target.value })
    }
    const updateType = (e) => {
        setTypeView(e.value)
        setNewObject({ ...object, level: e.value })

    }
    const updateCurrentView = (att) => {
        const columnFilterView = JSON.parse(propView)?.map(x => x["name"]) || [];
        loadTableProps({ context: context, columns: columnFilterView, filter: filters, sort: sort, selectedViewId: att?.id, entityType: entityType })
    }
    const update = () => {
        const copyObject = { ...object }
        copyObject.columnSetting = (propView)
        copyObject.filterSetting = (JSON.stringify(filters))
        copyObject.sortSetting = (JSON.stringify(sort))
        if (entityType)
            copyObject.type = entityType;
        upsertSavedView({savedView :copyObject, onSuccess: mode === 'create' ? updateCurrentView : null})
        onClose();
    }
    return <Modal isOpen={isOpen} toggle={onClose} size='m'>
        <ModalHeader className="fs2 listModal-bg  " toggle={onClose}> {t(mode === "update" ? "EDIT_VIEW" :"ADD_VIEW")}</ModalHeader>
        <ModalBody className="mt-2 pb-0 text-muted fs3">
            <Label for="name" className="m-2">{t("NAME")}</Label>
            <Input variant="default" type="text" name="name" onChange={handleChange} value={object?.name} />
            <div className="d-flex py-4">
                <div className="d-flex align-items-center">
                    <RadioButton name="PRIVATE" value={false} onChange={updateType} checked={!typeView} />
                    <Label for="PRIVATE" className="m-2">{t("PRIVATE")}</Label>
                </div>
                <div className="d-flex align-items-center">
                    <RadioButton name="PUBLIC" value={true} onChange={updateType} checked={typeView} />
                    <Label for="PUBLIC" className="m-2">{t("PUBLIC")}</Label>
                </div>
            </div>
        </ModalBody>
        <ModalFooter>

            <div className="m-3">
                <GenericButton variant='primary' className='text-capitalized h-25' onClick={update}>{t('ASPX_12')}</GenericButton>
            </div>
        </ModalFooter>
    </Modal>
}
export default EditFilterView;