import { getSessionId } from "@redux/memoizedSelectors";
import { EMPTY_GUID } from "@utils/helpers";
import { getterApis } from "@utils/optionsSets/getterApis";
import Personas from "features/grid-table/columns-components/Personas";
import { t } from "i18next";
import { memo, useLayoutEffect, useRef, useState } from "react";
import { Token } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useClickOutside } from "shared/hooks/index";
import { FlexAlignCenter, GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { base } from "../../index";
import * as Style from "./style";

const MapAndStagePicker = memo(({ object, attribute, setObject, disable, updateFunction }) => {
	const { t } = useTranslation();
	const ref = useRef();
	const refOut = useRef();
	const sessionId = useSelector(getSessionId);
	const isMapContext = attribute === "mapId";
	const all_maps = useSelector((state) => state.maps.all_maps);
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
    const currentUser = useSelector((state) => state.maps?.currentUser);
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType.isReadOnly())
    
	const [mapId, setMapId] = useState(object?.mapId);
	const [selected, setSelected] = useState(GetSelected(all_maps, object, attribute));
	const [options, setOptions] = useState(OptionsValidation(all_maps, showSampleData, attribute, object?.mapId));
	const [showMenu, setShowMenu] = useState(false);
	const disabled = object?.ownerId !== currentUser?.id && disable ? true : false;
	const showPlaceHolder = selected?.length < 1 ? true : false;
	const showButtonAddJourney = selected?.length < 1 && !showMenu && !ref.current?.state?.showMenu; //attribute === 'mapId' &&

	useLayoutEffect(() => {
		if (attribute === "stageId" && mapId !== object.mapId) {
			const newSelected = GetSelected(all_maps, object, attribute);
			setSelected(newSelected);
			setOptions(OptionsValidation(all_maps, showSampleData, attribute, object.mapId));
			ref.current.state.selected = newSelected;
			setMapId(object.mapId);
		} else if (attribute === "mapId" && object.mapId === EMPTY_GUID) {
			// ref.current.state.selected = object.mapId ;

			ref.current.state.selected = [];
		}
	}, [object?.mapId]);

	const handleClickOut = () => {
		ref?.current?.blur();
		setShowMenu(false);
	};
	useClickOutside(refOut, handleClickOut);

	const handleChange = (select) => {
		const lastSelected = select.length > 0 ? [select[select.length - 1]] : [];
		ref.current.state.selected = lastSelected;
		setSelected(lastSelected);
		const key = attribute === "stageId" ? "id" : "cjmId";
		const newIdSelected = lastSelected?.[0]?.[key] || EMPTY_GUID;
		if (newIdSelected === object[attribute]) return;
		const newObject = attribute === "mapId" ? { ...object, [attribute]: newIdSelected, stageId: EMPTY_GUID } : { ...object, [attribute]: newIdSelected, mapId: object.mapId };

		setObject(newObject);
		if (attribute === "stageId" && newObject.stageId !== EMPTY_GUID) {
			updateFunction(newObject, ["mapId", "stageId"]);
		} else if (attribute === "mapId" && newObject.mapId === EMPTY_GUID && newObject.stageId === EMPTY_GUID) {
			updateFunction(newObject, ["mapId", "stageId"]);
		}
		setShowMenu(!showMenu);
	};

	const handleToggle = (e) => {
		ref?.current?.toggleMenu();
		setShowMenu(!showMenu);
	};

	const tokenValidation = ({ index, onRemove, option }) => {
		switch (attribute) {
			case "mapId":
				return <MapToken key={index} disabled={disabled || isReadOnly} onRemove={onRemove} option={option} sessionId={sessionId} />;
			case "stageId":
				return <StageToken key={index} disabled={disabled || isReadOnly} onRemove={onRemove} option={option} />;
			default:
				return null;
		}
	};
	const listItems = (option) => {
		return <div key={option.id}>{option?.name === "" && attribute === "stageId" ? "new stage" : option?.name}</div>;
	};
	return (
		<div ref={refOut}>
			{showButtonAddJourney && (
				<Style.PositionButtonADD className="h-100 align-self-center w-100" disabled={(!isMapContext && object.mapId === EMPTY_GUID) || isReadOnly}>
					<div style={{ width: "100%", position: "absolute", minHeight: "44px", zIndex: "1" }}></div>
					<GenericButton variant="light-link " icon="SQUARE_PLUS" iconClassName="me-1" style={{ zIndex: "2" }} onClick={handleToggle}>
						{t(isMapContext ? "SELECT_JOURNEY_MAP" : "SELECT_STAGE")}
					</GenericButton>
				</Style.PositionButtonADD>
			)}
			<Style.Search
				checked={true}
				defaultSelected={selected}
				showPlaceholder={showPlaceHolder}
				id="public-methods-example"
				labelKey="name"
				options={options || []}
				disabled={disabled || isReadOnly}
				type={attribute.toString() || ""}
				onChange={handleChange}
				ref={ref}
				multiple={true}
				renderMenuItemChildren={listItems}
				renderToken={(option, { onRemove }, index) => tokenValidation({ index, onRemove, option })}
			/>
		</div>
	);
});
MapAndStagePicker.displayName = "MapAndStagePicker";
export default MapAndStagePicker;

const OptionsValidation = (all_maps, showSampleData, attribute, mapId) => {
	const mapArray = all_maps?.getActiveRecords(!showSampleData);
	const stages = mapId?.length !== 0 ? mapArray.find((map) => map.cjmId === mapId)?.stages : [];

	return attribute === "mapId" ? mapArray : attribute === "stageId" ? stages : [];
};

const GetSelected = (all_maps, opportunity, attribute) => {
	const selectedMap = all_maps.filter((cjm) => cjm.cjmId === opportunity?.mapId);
	const selectedStage = selectedMap[0]?.stages.find((stage) => stage.id === opportunity?.stageId);
	const result = attribute === "mapId" ? selectedMap : attribute === "stageId" ? (selectedStage === undefined ? [] : [selectedStage]) : [];
	return result;
};

const MapToken = ({ option, onRemove, disabled, index, sessionId }) => {
	const mapImage = getterApis["GET_MAP_CUSTOM_IMG"](option.cjmId, sessionId);
	const redirectLink = `${base}EditMap?cjmId=${option?.cjmId}&personaId=${option?.linkedPersonas[0]?.personaId || EMPTY_GUID}`;
	const isFuturelabel = option.isFuture ? t("FUTURE") : t("CURRENT");
	return (
		<Token key={index} onRemove={onRemove} disabled={disabled} option={option}>
			<FlexAlignCenter gap={15}>
				<Style.Image className="me-2" src={mapImage} loading="lazy" alt="" />
				<Style.PersonaContainer>{Personas(option, undefined, undefined, false)}</Style.PersonaContainer>
				<Style.GoMap className="ms-2" href={redirectLink}>
					{option?.name}
				</Style.GoMap>
				<Style.IsFutureLabel type={option.isFuture.toString()}>{isFuturelabel}</Style.IsFutureLabel>
			</FlexAlignCenter>
		</Token>
	);
};
const StageToken = ({ option, onRemove, index, disabled }) => {
	const stageLabel = option?.name === "" ? "new stage" : option?.name;

	return (
		<Token key={index} disabled={disabled} onRemove={onRemove} option={option}>
			<Style.StagesContainer>
				<div>{stageLabel}</div>
				{IconDispatcher("ANGLE-RIGHT")}
			</Style.StagesContainer>
		</Token>
	);
};
