import { getAllRegisteredUsers, transfertOwnerShip } from "@redux/index";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { ActionConfirmModal } from "shared/modals/index";
import { GenericButton } from "shared/ui-basics/buttons";
import { FlexAlignCenter, IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import InitialsBubble from "../components/initials-bubble";

export default function ManageOwnership() {
	const { t } = useTranslation();
	const userFromRef = React.useRef(null);
	const userToRef = React.useRef(null);
	const [loading, setLoading] = React.useState(false);
	const [isSuccess, setIsSuccess] = React.useState(false);

	const users = useSelector(({ settings }) => settings?.users || []);
	const authorizedUsers = users.filter((user) => user?.isActive === true);
	const [confirmModal] = useModal(ActionConfirmModal);

	const renderListUsers = (option) => {
		const fullName = `${option?.firstName} ${option?.lastName}`;
		return (
			<FlexAlignCenter>
				<InitialsBubble fullName={fullName} size="s" />
				<div className="ms-2">{fullName}</div>
			</FlexAlignCenter>
		);
	};
	const submit = () => {
		const from = userFromRef.current?.state?.selected;
		const to = userToRef.current?.state?.selected;

		const callbacks = {
			onSuccess: () => {
				setLoading(false);
				setIsSuccess(true);
				setTimeout(() => setIsSuccess(false), 3000);
				userFromRef.current?.clear();
				userToRef.current?.clear();
			},
			onFailed: () => setLoading(false),
		};

		if (from?.[0] && to?.[0]) {
			if (from?.[0]?.id === to?.[0]?.id) {
				return toast.info(t("SAME_USER"));
			}
			confirmModal({
				bodyContent: "TRANSFER_OWNERSHIP_CONFIRMATION",
				Action: () => {
					setLoading(true);

					transfertOwnerShip({
						userContactIdFrom: from?.[0]?.id,
						userContactIdTo: to?.[0]?.id,
						...callbacks,
					});
				},
				actionBtnText: "TRANSFER",
			});
		}
	};

	return (
		<DataLoader reduxActions={[getAllRegisteredUsers]}>
			<PageTemplate classNames="container pt-5" pageTitle={t("TRANSFERT_OWNERSHIP")}>
				<Label className={"my-4"}>{t("TRANSFERT_ALL_RECORD")} </Label>
				<Label className={"my-3"}>{t("CURRENT_USER")} </Label>
				<Typeahead
					id="public-methods-example"
					labelKey={(option) => `${option.firstName} ${option.lastName}`}
					className="w-25 mt-1 "
					options={authorizedUsers}
					placeholder={t("SELECT_USER")}
					ref={userFromRef}
					renderMenuItemChildren={renderListUsers}
				/>
				<Label className={"my-3"}>{t("NEW_USER")} </Label>
				<Typeahead
					id="public-methods-example"
					labelKey={(option) => `${option.firstName} ${option.lastName}`}
					className="w-25 mt-1"
					options={users}
					placeholder={t("SELECT_USER")}
					ref={userToRef}
					renderMenuItemChildren={renderListUsers}
				/>
				<GenericButton variant="primary" className="my-3" onClick={submit} disabled={loading}>
					{loading && <Spinner type="grow" size="sm" className="me-2" />}
					{isSuccess && !loading && IconDispatcher("CHECK", "me-2")}
					{t("TRANSFER")}
				</GenericButton>
			</PageTemplate>
		</DataLoader>
	);
}
const Label = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
