import { FlexAlignCenter, Input } from "shared/ui-basics/index";
import { RestrictUserAccess } from "@utils/helpers";
import { Col, Row } from "react-bootstrap";
import { Breadcrumb, Label } from "reactstrap";
import styled, { css } from "styled-components/macro";

export const Path = styled.div`
	white-space: nowrap;
	color: #605F60 ;
	font-size: 14px;
	display: flex;
	align-items: center;
	  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
`;
export const HeaderInput = styled(Input)`
	padding-left: 0;
	font-size: 28px;
	line-height: 150%;
	color: #333333;
	--bs-bg-opacity: 1;
	text-overflow: ellipsis;
	background-color: transparent !important;
	font-weight: 500;
	border: 0px;
	min-height: 38px;
	max-height: 100px; /* Adjust this for the desired number of lines */
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Adjust this for the desired number of lines */
	-webkit-box-orient: vertical;
	resize: none;
	word-break: break-all;

	:focus {
		font-family: "Inter";
		letter-spacing: 0em;
		text-align: left;
		--bs-bg-opacity: 1;
		text-overflow: ellipsis;
		background-color: transparent !important;
		width: 100%;
		padding: 0 0.25rem;
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 1.5;
	}
	${(props) => (RestrictUserAccess(props.access?.toLowerCase(), ["viewer", "contributor"]) ? "  pointer-events: none;" : "")}
`;
export const Container = styled.div`
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	background-color: #ffffff;

`;
export const RowWrapper = styled(Row)`
	/* border-top: 1px dashed #c4c4c4; */
	/* display: flex;
justify-content: space-between; */
	min-height: ${(props) => props.height};
`;
export const ColWrapper = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	border-left: 1px dashed #c4c4c4;
`;
export const PersonaDetails = styled.div`
	width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 52px;
`

export const LabelWrapper = styled(Label)`
	display: flex;
	align-items: center;
	color: #92919d;
	margin-bottom: 0.55rem;
	white-space: nowrap;
	${(props) => props.small && "font-size:10px;"}
`;

export const DivWrapperCol4 = styled.div`
	display: flex;
	flex-direction: column;
	${(props) => (props.first ? "" : "border-left: 1px solid #C4C4C4")};
	justify-content: space-around;
`;

export const LastSaveWrapper = styled.span`
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border-radius: 0.25rem;
	align-self: center;
	color: #333333;
	white-space: nowrap;

	${(props) => (props.updated ? `animation: name 1.5s ease-out;` : "")};
	@keyframes name {
		100% {
			background-color: #d8fafa;
		}
	}
	@media screen and (max-width: 1440px) {
		font-size: 14px;
	}
`;
export const RowDash = styled(Row)`
	border-bottom: 1px dashed #c4c4c4;
    ${(props) =>
    props.disabled &&
    css`
        pointer-events: none;
        opacity: 0.5;
    `}
`;
export const CenterCol = styled(Col)`
	display: flex;
	align-items: center;
	/* @media (max-width: 1550px) {
		${(props) =>
		props.smallscreenupdate === "true"
			? css`
					width: 60% !important;
			  `
			: ""}
	}
	@media (max-width: 1300px) {
		${(props) =>
		props.smallscreenupdate === "true"
			? css`
					width: 50% !important;
			  `
			: ""}
	}
	@media (max-width: 1100px) {
		${(props) =>
		props.smallscreenupdate === "true"
			? css`
					width: 40% !important;
			  `
			: ""}
	} */
`;
export const Number = styled.div`
	display: flex;
	flex-direction: row-reverse;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	color: ${(props) => props.color};
	justify-content: center;
`;


export const ColEnd = styled(Col)`
	display: flex;
	justify-content: flex-end;
	/* border-left: 1px solid #c4c4c4; */
	align-items: center;
    position: relative;
`;
export const StretchCol = styled(Col)`
	display: flex;
	align-items: stretch;

	justify-content: flex-end;
`;
export const End = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
`;

export const CrumbWrapper = styled.div`
    display: flex;
    align-items: center;
    pointer-events: auto;
`
export const CustomBreadcrumb = styled(Breadcrumb)`
	ol {
		margin: 0 !important;
		display: flex;
		align-items: center;
	}
	li {
		display: flex;
		align-items: center;
	}
	color:#605F60 !important;
`;

export const ItemNameWrapper = styled.div`
	font-weight: 500;
	font-size: 28px;
	line-height: 150%;
	color: #333333;
	width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const UserConnectedWrapper=styled(FlexAlignCenter)`
border-right:1px solid #c4c4c4;
padding-right:1rem;
`