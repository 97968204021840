import { addMembers, removeMember, upsertTeam } from "@redux/index";
import { EMPTY_GUID, User, encode, isNullOrEmptyObject } from "@utils/helpers";
import React from "react";
import { TypeaheadInputMulti } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import { Flex } from "shared/ui-basics/index";
import { Input } from "shared/ui-basics/input";
import * as S from "./ManageAccessStyle";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";

export default function TeamMembers({ isOpen, onClose, ...props }) {
	const { t } = useTranslation();
	const { teamId } = props;
	const ref = React.useRef();
	const refName = React.useRef();

	const team = useSelector(({ settings }) => settings?.teams?.[teamId] || {});
	const users = useSelector(({ settings }) => settings?.authorizedUsers || []);
	const backOfficeUsers = useSelector(({ settings }) => settings?.users || []);
	const authorizedUsers = users.filter((user) => backOfficeUsers.find((u) => u.loginName.toLowerCase() === user?.domainName.toLowerCase())?.isActive);

	const handleSubmit = (e) => {
		e.preventDefault();
		const name = refName?.current?.value;
		if (name?.isEmptyString()) return toast.info(t("TEAM_NAME_REQUIRED"));
		const memberUserIds = ref.current?.state?.selected?.map((user) => user.id);
		if (team.id) {
			const membersToAdd = memberUserIds.filter((id) => !team.memberUserIds.includes(id));
			const membersToRemove = team.memberUserIds.filter((id) => !memberUserIds.includes(id));
			membersToAdd.length > 0 && addMembers({ teamIds: [team.id], userIds: membersToAdd });
			membersToRemove.length > 0 && membersToRemove?.forEach((userId) => removeMember({ teamId: team.id, userId }));
			if (name !== team.name) upsertTeam({ team: { ...team, name: encode(name), memberUserIds } });
		} else {
			const newTeam = { id: EMPTY_GUID, name: encode(name), memberUserIds };
			upsertTeam({ team: newTeam });
		}
		ref.current.clear();
		onClose();
	};
	const renderListUsers = (option) => {
		return (
			<Flex>
				<InitialsBubble fullName={option?.fullName} size='s' />
				<div className="p-1">{option?.fullName}</div>
			</Flex>
		);
	};
	const renderSelectedUsers = (inputProps, props) => {
		return (
			<TypeaheadInputMulti {...inputProps} selected={props.selected}>
				{props.selected?.map((option, idx) => (
					<S.CustomToken index={idx} key={option?.id} onRemove={props.onRemove} option={option}>
						<InitialsBubble fullName={option?.fullName} size='s' />
						<>{option?.fullName}</>
					</S.CustomToken>
				))}
			</TypeaheadInputMulti>
		);
	};

	const displayTitle = () => {
		if (isNullOrEmptyObject(team)) return t("CREATE_NEW_TEAM");
		return t("MANAGE_TEAM");
	};

	return (
		<Modal fade={false} isOpen={isOpen} centered={true} size="lg" toggle={onClose}>
			<ModalHeader toggle={onClose}>{displayTitle()}</ModalHeader>
			<ModalBody>
				<div className="my-2">
					<Label className="my-1">{t("TEAM_NAME")}</Label>
					<Input type="text" name="name" id="name" defaultValue={team.name || ""} innerRef={refName} />
				</div>
				<div className="my-2">
					<Label className="my-1">{t("ASPX_244")}</Label>
					<S.RegularTypehead
						defaultSelected={authorizedUsers.filter((user) => team?.memberUserIds?.includes(user.id))}
						id="public-methods-example"
						labelKey="fullName"
						multiple
						placeholder={t("SELECT_USERS")}
						options={authorizedUsers}
						ref={ref}
						renderMenuItemChildren={renderListUsers}
						renderInput={renderSelectedUsers}
						onBlur={() => ref.current?.blur()}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className="d-flex justify-content-end">
					<GenericButton variant="primary" onClick={handleSubmit}>
						{" "}
						{t(teamId ? "ASPX_165" : "ASPX_135")}
					</GenericButton>
				</div>
			</ModalFooter>
		</Modal>
	);
}
