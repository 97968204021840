import { Button, IconDispatcher } from "shared/ui-basics/index";
import { Card, Col, Container, Row } from "reactstrap";
import styled from "styled-components/macro";
import { TableTemplate } from "./TableTemplate";

export const SubjectWrapper = styled.div`
	cursor: pointer;
	max-width: 20rem;
	min-width: 15rem;
	overflow: hidden;
	color: #333333;
	display: -webkit-box;

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: ${(props) => (props.desactivateclick ? "default" : "pointer")};
	font-weight: 500 !important;
`;

export const TextInfo = styled.div`
	max-width: ${(props) => (props.stage === "true" ? "10rem" : "18rem")};
	overflow: hidden;
	display: -webkit-box;
	color: #333333;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: ${(props) => (props.desactivateclick ? "default" : "pointer")};
	@media screen and (max-width: 1290px) {
		max-width: ${(props) => (props.stage === "true" ? "3rem" : "8rem")};
		min-width: ${(props) => (props.stage === "true" ? "4rem" : "8rem")};
	}
`;
export const Status = styled.div`
	height: 100%;
	width: 110px;
	line-height: 54px;
	border-radius: 4px;
	text-align: center;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: ${(props) => props.colorcode};
	/* color:#5c5858; */
`;
export const DropDownPosition = styled.div`
	position: absolute;
	right: ${(props) => (props.right ? props.right : 10)}px;
	top: -16px;
	display: none;
`;

export const DataTableCustom = styled(TableTemplate)`
	td {
		height: 60px;
	}
	tr {
		:hover ${DropDownPosition} {
			display: block;
		}
	}
`;
export const ChangeColor = styled.div`
	color: ${(props) => props.color};
`;

export const Ranking = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 25px;
	max-width: 30px;
	max-height: 25px;
	background: #d8fafa;
	border: 1px solid #18aead;
	border-radius: 4px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #18aead;
`;
export const RatingContainer = styled.div`
	/* zoom: 0.5; */
	position: relative; // new

	.inner {
		position: absolute;
		transform: scale(0.5);
		transform-origin: 0 0;
	}
	.p-disabled {
		opacity: 1;
	}
`;
export const Center = styled.div`
	display: flex;
	justify-content: center;
`;
export const CenterCol = styled(Col)`
	display: flex;
	justify-content: center;
`;
export const AddNew = styled(Row)`
	background-color: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 4px;
	display: flex;
	cursor: pointer;

	svg {
		align-self: center;
		width: auto;
		max-width: none;
	}
`;
export const EllipsisText = styled.div`
	max-width: ${(props) => props.maxWidth}px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
export const AddNewPersona = styled(Card)`
	width: 220px;
	height: 280px;
	background: rgba(255, 255, 255, 0.4);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	position: relative;
	border: 1px solid #d9d9d9;
	display: flex;
	justify-content: center;
	text-align: center;
	color: #92919d;
	cursor: pointer;
	svg {
		align-self: center;
		zoom: 3;
	}
`;
export const PersonaHeaderViewContainer = styled.div`
	width: 100%;
	margin-bottom: 1.5rem;
	display: flex;
	justify-content: center;
`;
export const SortIcon = styled.div`
	transform: ${(props) => (props.updown === "down" && props.type === props.reference ? "rotate(180deg)" : "")};
`;
export const SortButton = styled(Button)`
	${(props) => (props.active ? "font-weight:700 !important;" : "")}
	text-transform: unset;
`;

export const CardsContainer = styled(Container)`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 1.5rem;
`;
