import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { FlexColumnGap, Input } from "shared/ui-basics/index";
import { InputNumber } from "primereact/inputnumber";

export const FinancialImpact = ({ financialImpact, currencySymbol, handleFinancialImpactChange, readonly = false, label = "FINANCIAL_IMPACT", noLabel = false }) => {
	const { t } = useTranslation();
	const newFinancialImpact = parseFloat(financialImpact)//.toLocaleString(undefined, { minimumFractionDigits: 2 });
	return (
		<FlexColumnGap>
			{!noLabel && <CustomLabel>{t(label)}</CustomLabel>
			}			<FinancialImpactNumber>
				<FinancialInput value={newFinancialImpact} onValueChange={(e) => handleFinancialImpactChange(e.value)} readOnly={readonly} minFractionDigits={2} maxFractionDigits={5}  min={0}/>
				<CurrencySymbol className="px-2">{currencySymbol}</CurrencySymbol>
			</FinancialImpactNumber>
		</FlexColumnGap>
	);
};

const CustomLabel = styled.div`
	gap: 20px;
	color: #605f60;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	white-space: nowrap;
`;
const FinancialInput = styled(InputNumber)`
width: 100%;
.p-inputtext {
	color: #f96209 !important;
	border-radius: 4px;
	font-family: "Oswald";
		font-size: 14px;
}
${(props) => (props.readOnly ? "pointer-events:none;" : "")}

`
const FinancialImpactNumber = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #f96209;
	text-align: right;
	font-family: "Oswald";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	overflow: hidden;
`;

const CurrencySymbol = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 96%;
	width: 32px;
	background: #fff;
	color: #f96209;
	font-family: "Oswald", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	top: 1px;
	right: 10px;
	border-left: 1px solid #c4c4c4;
	border-radius: 0;
`;
