import { ConnectorSource } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, getKeyByValue } from "@utils/helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StepWizard from "react-step-wizard";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";
import { FormConnector } from "./FormConnector";
import { ListConnector } from "./ListConnector";

export const newConnector = {
	id: EMPTY_GUID,
	retrieveDataFrequency: "100000004",
};

export const IntegrationModal = ({ isOpen, onClose, source }) => {
	const { t } = useTranslation();
	const connectors = useSelector((state) => state.settings.connector)?.filter((connector) => connector.source === source) || [];
	const connectorName = getKeyByValue(ConnectorSource, source);
	const name =t(connectorName.toUpperCase())+" "+t("INTEGRATION");// connectorName + " Integration";
	const [selectConnector, setSelectConnector] = useState({ ...newConnector, source, name});

	return (
		<Modal fade={false} isOpen={isOpen} centered={true} toggle={onClose}>
			<ModalHeader toggle={onClose}> {name}</ModalHeader>
			<WrapperModalBody>
				<StepWizard transitions="nothing" className="">
					{connectors?.length > 0 && <ListConnector setSelectConnector={setSelectConnector} source={source} />}
					<FormConnector key={selectConnector?.id} currentConnector={selectConnector} setSelectConnector={setSelectConnector} onClose={onClose} source={source} />
				</StepWizard>
			</WrapperModalBody>
		</Modal>
	);
};

export const WrapperModalBody = styled(ModalBody)`
	padding: 0px;
	/* height:500px; */
`;
