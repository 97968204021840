export const StatusCode = { Active: 1, Archived: 100000000, Trashed: 2, Revised: 100000002 };

export const InformationType = {
	Text: 100000000,
	Image: 100000001,
	ListItems: 100000002,
	ActionsList: 100000003,
	Opportunity: 100000004,
	BrandValue: 100000005,
	Feelings: 100000006,
	CustomerActions: 100000007,
	Touchpoints: 100000008,
	Systems: 100000009,
	Departments: 100000010,
	Timing: 100000011,
	SentimentChart: 100000012,
	Rating: 100000013,
	Slider: 100000014,
	Demographic: 100000015,
	Tags: 100000016,
	AdvancedInteractions: 100000017,
	Quote: 100000018,
	Target: 100000019,
	StakeHolder: 100000020,
	KPIs: 100000021,
	AdvancedPros: 100000022,
	AdvancedCons: 100000023,
	AdvancedInsights: 100000024,
	CustomLibrary: 100000025,
	Divider: 100000026,
	AdvancedActions: 100000027,
	Solution: 100000028,
	CustomerFeedback: 100000029,
	Insights: 100000030,
};

export const BrandValueType = { Emotional: 100000000, Functional: 100000001 };

export const SolutionType = { QuickWin: 100000000, ComplexProject: 100000001, NiceToHave: 100000002, ToAvoid: 100000003 };

export const UserType = { user: 100000000, admin: 100000001, companyAdmin: 100000002, readOnly: 100000003 };

export const UserTypeByValue = { 100000000: "user", 100000001: "admin", 100000002: "companyAdmin", 100000003: "readOnly" };

export const StateTypeOption = { CurrentState: 100000000, FutureState: 100000001 };

export const AttributeItemType = { Text: 100000000, Gender: 100000001, Number: 100000002 };

export const GenderType = { Male: 100000000, Female: 100000001, Other: 100000002 };

export const SubscriptionPlan = { Basic: 100000000, Standard: 100000001, Expert: 100000002, Enterprise: 100000003, SharedExpert: 100000004 };

export const TemplateStatus = { draft: 100000000, published: 100000001, cemantica: 100000002 };

export const EntityList = {
	Global: 1,
	CustomerJourney: 100000000,
	Persona: 100000001,
	CXProgram: 100000002,
	Opportunity: 100000003,
	Solution: 100000004,
	Action: 100000005,
	Project: 100000006,
    Insight: 100000007,
    User: 100000008
};

export const DividerPatternType = { Solid: 100000000, Lines: 100000001, ZigZag: 100000002, Diagonal: 100000003, Rectangles: 100000004 };

export const CxGoalDirection = { Increase: 100000000, Decrease: 100000001 };
export const ConnectorSource = { Qualtrics: 100000000, Manual: 100000001, SurveyMonkey: 100000002, Sandsiv: 100000003, ImportFile: 100000004, Monday: 100000005 };
export const ConnectorType = { VOC: 100000000, ProjectManagement: 100000001 };

export const AuditOperation = { ADDED: 0, UPDATED: 1, DELETED: 2, LINKED: 3, UNLINKED: 4, COPIED: 5 };

export const DalleSizeImg = { regular: "1024x1024", horizontalRec: "1792x1024", verticalRec: "1024x1792" };

export const urlOriginStatus = { DefaultReturn: "1" };

export const modes = { create: 1, update: 2, duplicate: 3 };
export const  MapMode =  { Simple : 100000000, Advanced : 100000001, Custom : 100000002, ServiceBluePrint : 100000003 };
export const  StatusAI =  { None : 100000000, InProcess : 100000001, Ready : 100000002, Fails : 100000003 };
export const invalidWorkspaceStatus = { Disabled: 1, Removed: 2, DisabledSwitch: 3, RemovedSwitch: 4 };