import { Row } from "react-bootstrap";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

export const PageTemplate = ({ pageTitle, renderViewButtons, renderOtherButtons, variant, children, classNames = "container" }) => {
	const { t } = useTranslation();
	return (
		<PageContainer className={classNames}>
			<Header>
				<Title className="col">{pageTitle}</Title>
				{renderViewButtons ? <CustomCol>{renderViewButtons(t)}</CustomCol> : <></>}
			</Header>
			{renderOtherButtons && renderOtherButtons()}
			<ScrollContainer className="mt-4" variant={variant}>
				{children}
			</ScrollContainer>
		</PageContainer>
	);
};

const PageContainer = styled.div`
    height: 100%;
`;

const Header = styled(Row)`
	display: flex !important;
	justify-content: space-between !important;
`;

const CustomCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const Title = styled.h1``;

const ScrollContainer = styled.div`
	width: 100%;
	${(props) =>
		props.variant === "graph" &&
		css`
			overflow-y: auto;
			height: 75%;
		`}
`;
