import { getCurrentWorkspace, getQueriesStatus, isExistingWorkspaces } from "@redux/memoizedSelectors";
import { StatusCode, invalidWorkspaceStatus } from "@utils/optionsSets/OptionSets";
import { WorkspaceInvalidPage } from "features";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// TODO Switch to workspace not working - routing issue
export const WorkspaceWatcher = (props) => {
	const history = useHistory();

	const { pending } = useSelector(getQueriesStatus({ type: "GET_ALL_WORKSPACES" }), shallowEqual);
	const currentWorkspace = useSelector(getCurrentWorkspace, shallowEqual);
	const isOtherWorkspaces = useSelector(isExistingWorkspaces, shallowEqual);
	const hideForPdf = useLocation().search.hideForPdf();

	const isActive = currentWorkspace?.statusCode === StatusCode.Active;
	const isDataLoaded = pending === 0;

    if (isDataLoaded && !hideForPdf) {
        if (!currentWorkspace) {
			if (isOtherWorkspaces) {
				return <WorkspaceInvalidPage statusCode={invalidWorkspaceStatus.RemovedSwitch} />;
			}
			history.push(`/invalid-workspace?statusCode=${invalidWorkspaceStatus.Removed}`);
		}
		if (!isActive) {
			if (isOtherWorkspaces) {
				return <WorkspaceInvalidPage statusCode={invalidWorkspaceStatus.DisabledSwitch} />;
			}
			history.push(`/invalid-workspace?statusCode=${invalidWorkspaceStatus.Disabled}`);
		}
	}

	return <>{props.children}</>;
};
