import { upsertSavedView } from "@redux/index";
import { getRequestStatus } from "@redux/memoizedSelectors";
import { EMPTY_GUID, isUserAllowed } from "@utils/helpers";
import { HeaderWrapper, PopoverItem } from "features/grid-table/columns-components/Styles";
import { setFiltersService } from "features/grid-table/reducer/TableSlice";
import { t } from "i18next";
import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ColumnSettingsModal, EditFilterView } from "shared/modals/index";
import { ActionPopOver } from "shared/popovers/ActionsPopOver";
import GenericPopover from "shared/popovers/GenericPopover";
import { FlexAlignCenter, FlexColumnGap, GenericButton, IconDispatcher, SearchBar } from "shared/ui-basics/index";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import { useGetContext } from "../TableHelpers";
import ViewPopover from "./ViewPopover";
import { debounce } from "lodash";

export const HeaderTable = ({ contextData, props, dataByFilter, selected, filters, handleFilter, visibleItems, updateFilter }) => {
	const context = useGetContext(props.context)(1);
	const hideForPdf = useLocation().search.hideForPdf();
	const { displayExport = true, displayView = true, readonly = false, showLinkFolderButton = false, LinkFolderAction, LinkActionLabel, aiGeneration, aiModal } = contextData;
	const aiAllowed = useSelector(({ settings }) => settings.subscriptionDetails.aiEnabled);
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const selectState = useSelector(({ ui }) => ui.table.entity);
    const userSettings = useSelector(({ settings }) => settings.userSetting);
    const isReadOnly = useSelector((state) => state.auth.userInfo.UserType).isReadOnly();
	const innerRef = useRef(null);
	const selectedViewId = selectState?.[contextData?.entityType]?.[context]?.selectedViewId;
	const columnView = selectState?.[contextData?.entityType]?.[context]?.columns;
	const sort = selectState?.[contextData?.entityType]?.[context]?.sort || {};
	const listView = userSettings?.savedViewsByEntity?.[contextData?.entityType];
	const filtersIds = selectState?.[contextData?.entityType]?.[context]?.filteredIds;
	const { pending } = useSelector(getRequestStatus({ type: "UPDATE_LINKED_FOLDERS" }), shallowEqual);
	const [isLinking, setIsLinking] = useState(false);

	useEffect(() => {
		// this is to update the counters after linking folders
		if (pending === 1) setIsLinking(true);
		if (isLinking && pending === 0) {
			setIsLinking(false);
			updateFilter(visibleItems)
		}

	}, [pending]);
	// access control
	const userType = userInfo?.UserType;
	const isUserAllowedToViewTable = isUserAllowed(userType, "table");

	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(false);

	const updateGlobalFilter = (searchValue) => {
		const updatedFilters = { ...filters, global: { ...filters.global, value: searchValue } };

		setFiltersService({
			context,
			value: updatedFilters,
			entityType: contextData?.entityType,
		});
	};

	const handleChange = (e) => {
		const searchValue = e.target.value;
		setGlobalFilterValue(searchValue);
		debouncedUpdateGlobalFilter(searchValue);
	};
	const debouncedUpdateGlobalFilter = debounce(updateGlobalFilter, 300); // Adjust 300ms to your needs


	const getColumnWidths = () => {
		const widthArray = [];
		$(".p-datatable-thead tr")
			.children()
			.each(function () {
				widthArray.push(Math.round($(this).css("width").replace("px", "")));
			});
		return contextData.columns?.map((col, i) => ({ name: col, width: widthArray[i] }));
	};

	const saveView = () => {
		const view = listView?.find((x) => x.id === selectedViewId);
		if (!view) return;

		const updatedView = {
			...view,
			columnSetting: JSON.stringify(getColumnWidths()),
			filterSetting: JSON.stringify(filters),
			sortSetting: JSON.stringify(sort),
		};
		upsertSavedView({ savedView: updatedView });
	};

	const exportData = () => {
		const result = selected ? selected.ArrayToIdsStr(contextData.dataKey) : dataByFilter || contextData.listData.ArrayToIdsStr(contextData.dataKey);
		contextData.exportExcel(result, columnView);
	};

	const onCreate = () => {
		contextData?.buttonCreate({ setIsCreateBtnDisabled });
	};
	const handleClick = (e) => {
		const id = e.currentTarget.id;
		innerRef?.current?.hide_popover();
		if (id === "ai") showAiModal();
		else if (id === "create") onCreate();
	};
	const handleCreate = () => {
		if (!aiGeneration || !aiAllowed) onCreate();
	};
	const actions = [
		...(selectedViewId !== EMPTY_GUID
			? [
				{
					handleClick: saveView,
					text: t("ASPX_12"),
					itemClasses: "d-flex align-items-center",
				},
			]
			: []),
		{
			handleClick: () =>
				showModalView({
					context,
					propView: JSON.stringify(getColumnWidths()),
					entityType: contextData?.entityType,
				}),
			text: t("SAVE_AS"),
			itemClasses: "d-flex align-items-center",
		},
	];

	const [showEditModal] = useModal(ColumnSettingsModal, {
		context,
		entityType: contextData?.entityType,
	});

	const [showModalView] = useModal(EditFilterView);
	const [showAiModal] = useModal(aiModal);

	const custom = (
		<div>
			<PopoverItem ai onClick={handleClick} id="ai">
				{IconDispatcher("SPARKLES-LIGHT", "me-1")}<span> {t("GENERATE_AI")}</span>
			</PopoverItem>
			<PopoverItem onClick={handleClick} id="create">
				{IconDispatcher(context === 'journeyMap' ? "MAP-LIGHT" : "SQUARE_PLUS", "me-1")} <span> {t(contextData?.buttonCreateLabel)}</span>
			</PopoverItem>
		</div>
	);

	const ButtonCreation = () => {
		if (aiGeneration && aiAllowed) {
			return (
				<GenericPopover custom={custom} innerRef={innerRef}>
					<GenericButton variant="primary" onClick={handleCreate} iconClassName="me-1" icon="SQUARE_PLUS" disabled={isCreateBtnDisabled}>
						{t(contextData?.buttonCreateLabel)}
					</GenericButton>
				</GenericPopover>
			);
		}
		return (
			<GenericButton variant="primary" onClick={handleCreate} iconClassName="me-1" icon="SQUARE_PLUS" disabled={isCreateBtnDisabled}>
				{t(contextData?.buttonCreateLabel)}
			</GenericButton>
		);
	};
	return (
		<FlexColumnGap gap="10">
			<div className={`d-flex  justify-content-between`}>
				{contextData.columns?.length > 0 && (
					<>
						<FlexAlignCenter>
							{contextData?.visibleButtonCreate && <ButtonCreation />}
							{showLinkFolderButton  && !readonly&& (
								<GenericButton variant="primary-outline" onClick={LinkFolderAction} className="ms-1" iconClassName="me-2" icon="LINK">
									{t(LinkActionLabel)}
								</GenericButton>
							)}
							{displayView && (
								<>
									<ViewPopover context={context} entityType={contextData?.entityType} handleFilter={handleFilter} readonly={readonly} />
									<GenericButton className='me-2' variant="dark-outline" icon="sliders" iconClassName='me-1' onClick={showEditModal} disabled={readonly} >{t("CONFIGURE_COLUMNS")}</GenericButton>
									<ActionPopOver noIcon  actions={actions} variant="dark-outline" label={t("SAVE_VIEW")} disabled={readonly}/>
								</>
							)}
						</FlexAlignCenter>
						<HeaderWrapper>
							<CountContainer className="me-2">
								{`${filtersIds ? filtersIds?.length : visibleItems?.length} ${t("ITEMS")}`}
							</CountContainer>
							{!hideForPdf && <SearchBar globalFilterValue={globalFilterValue} handleChange={handleChange} />}
							{displayExport && !isReadOnly && <GenericButton className="ms-2" variant="dark-outline" icon="file-export" iconClassName='me-1'onClick={exportData} > {t("ASPX_14")}</GenericButton>}
						</HeaderWrapper>
					</>
				)}
			</div>
		</FlexColumnGap>
	);
};

const CountContainer = styled.div`
	color: #605f60 !important;
	text-align: end;
	font-family: "Inter";
	font-size: 14px;
	width: max-content;
	white-space: nowrap;
`;
