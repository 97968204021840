import { projectFinancialImpact } from "@redux/memoizedSelectors";
import { calculateTotalFinancialImpact, validateStringNumberInput } from "@utils/helpers";
import { InformationType } from "@utils/optionsSets/OptionSets";
import { updateProject } from "features/projects/reducer/ProjectsActions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { FlexAlignCenter, Input } from "shared/ui-basics/index";
import styled, { css } from "styled-components/macro";
import * as Style from "../SectionsStyling";

const FinancialImpact = ({ isViewer }) => {
	const { t } = useTranslation();

	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";
	const { id, attributesList, userProvided, financialImpact } = useSelector(projectFinancialImpact, shallowEqual);
	const kpiAttribute = attributesList?.find((attr) => attr.type === InformationType.KPIs);
	const allCxGoals = useSelector((state) => state.libraries.cxGoals);
	const allMeasures = useSelector((state) => state.libraries.measures);

	const [showInput, setShowInput] = useState(false);
	const [up, setUserProvided] = useState(userProvided);
	const [fi, setFinancialImpact] = useState(financialImpact);

	const update = (attr, newValue) => {
		updateProject({ modifiedProject: { id, [attr]: newValue }, modifiedAttribNames: [attr] });
	};

	const updateFinancialImpact = () => {
		setShowInput(false);
		update("financialImpact", fi);
	};

	const handleButtonClick = (type) => {
		let newValue = true;
		if (type === "calculated") newValue = false;

		setUserProvided(newValue);
		update("userProvided", newValue);
	};

	const handleInputChange = (e) => {
		let value = e.target.value;
		value = validateStringNumberInput({ n: value, isPositive: true });

		if (value === undefined) return 0;

		setFinancialImpact(Number(value));
	};

	return (
		<Col xs={6}>
			<Style.CustomLabel>{t("FINANCIAL_IMPACT")}</Style.CustomLabel>
			<FlexAlignCenter gap="50">
				<RadioButtonContainer onClick={() => handleButtonClick("calculated")} disabled={isViewer}>
					<RadioButton selected={!userProvided} />
					<CustomLabel>{t("CALCULATED")}</CustomLabel>
				</RadioButtonContainer>
				<RadioButtonContainer onClick={() => handleButtonClick("user")} disabled={isViewer}>
					<RadioButton selected={userProvided} />
					<CustomLabel>{t("USER_PROVIDED")}</CustomLabel>
				</RadioButtonContainer>
			</FlexAlignCenter>
			{showInput && userProvided ? (
				<FlexAlignCenter>
					<CustomInput variant="default" type="text" autoFocus onChange={handleInputChange} onBlur={updateFinancialImpact} value={fi || ""} disabled={!up || isViewer} />
					<CurrencySymbol className="px-2" bordercolor="#333333">
						{currencySymbol}
					</CurrencySymbol>
				</FlexAlignCenter>
			) : (
				<FlexAlignCenter>
					<CustomContainer onClick={() => setShowInput(true)}>{up ? fi.toLocaleString() : calculateTotalFinancialImpact(kpiAttribute, allCxGoals, allMeasures)}</CustomContainer>
					<CurrencySymbol className="px-2" bordercolor="#c4c4c4">
						{currencySymbol}
					</CurrencySymbol>
				</FlexAlignCenter>
			)}
		</Col>
	);
};

export default React.memo(FinancialImpact);

const CustomLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	${(props) => props.fs && `font-size: ${props.fs}px;`}
`;

const RadioButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.disabled === true &&
		css`
			opacity: 0.7 !important;
			pointer-events: none !important;
			cursor: not-allowed;
		`}
`;

const RadioButton = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 100px;
	border: ${(props) => (props.selected ? "4px solid #333333" : "1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15))")};
	background: var(--gray-white, #fff);
`;

const CustomContainer = styled.div`
	width: 100%;
	min-height: 55px;
	margin-top: 16px;
	border: 1px solid #c4c4c4;
	background: #fff;
	color: #f96209;
	font-family: "Oswald", sans-serif;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	flex-shrink: 0;
	padding-left: 5px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

const CustomInput = styled(Input)`
	width: 100%;
	min-height: 55px;
	font-size: 36px;
	margin-top: 16px;
	height: 36px;
	color: #f96209;
	font-family: "Oswald", sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;

	:disabled {
		background-color: transparent !important;
		color: #c4c4c4 !important;
	}
`;

const CurrencySymbol = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 36px;
	margin-top: 16px;
	background: #fff;
	color: #f96209;
	font-family: "Oswald", sans-serif;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border: 1px solid ${(props) => props.bordercolor};
	border-left: none;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`;
