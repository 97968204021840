import { useSelector } from "react-redux";
import {
    GetActionColumns,
    GetAssessmentColumns,
    GetFolderColumns,
    GetInsightColumns,
    GetJourneyMapColumns,
    GetLibraryColumns,
    GetOpportunityColumns,
    GetPersonaColumns,
    GetProgramColumns,
    GetProjectColumns,
    GetSolutionColumns,
    GetUserColumns,
} from "./contexts";
import { EMPTY_GUID } from "@utils/helpers";

export function TableColumnsManager(props) {
    const context = props.context.split(".")[1];

    const selectedViewId = useSelector(state => state.ui.table.entity)?.[props?.contextData?.entityType]?.[context]?.selectedViewId

    const tablePropsDictionaryRelevantColumns = () => {
        const route = props.context.split(".")[0];
        const isFirefox = /firefox/i.test(navigator.userAgent);
        const isHome = props.context.split(".")[1] === "home";
        const isDefaultView = selectedViewId === EMPTY_GUID

        const headerCheckboxStyle = isFirefox
            ? { width: "5%", minWidth: "60px", minHeight: " 64px", padding: "5px", display: "flex", justifyContent: "center", alignItems: "center" }
            : { width: "5%", minWidth: "5%", padding: "5px", display: "flex", justifyContent: "center", alignItems: "center" , maxWidth:'96px'};

        let columns = {
            select: {
                selectionMode: props.contextData.showRadioButton ? "single" : "multiple",
                headerStyle: headerCheckboxStyle,
                style: { left: "0" },
                className: "action-box-table",
            },
            actions: {
                field: "actions",
                header: "",
                body: (option) => <div id={`dataTable-rowId_${option[props.contextData.dataKey]}`}>{props.contextData?.Actions(option)}</div>,
                style: { right: "0" },
                headerStyle: { width: "5%", minWidth: "5%", padding: "5px", display: "flex", justifyContent: "center", alignItems: "center" },
                className: `action-box-table ${isHome ? "table-no-border": " table-left"}`,
            },
        };

        switch (route) {
            case "map": {
                const mapColumns = GetJourneyMapColumns(props);
                columns = { ...columns, ...mapColumns };
                break;
            }
            case "cxActions": {
                const cxActionsColumns = GetActionColumns(props);
                columns = { ...columns, ...cxActionsColumns };
                break;
            }
            case "opportunity": {
                const opportunityColumns = GetOpportunityColumns(props);
                columns = { ...columns, ...opportunityColumns };
                break;
            }
            case "solutions":{
                const solutionsColumns = GetSolutionColumns(props);
                columns = { ...columns, ...solutionsColumns };
                break;
            }
            case "projects":{
                const projectsColumns = GetProjectColumns(props);
                columns = { ...columns, ...projectsColumns };
                break;
            }
            case "personas": {
                const personasColumns = GetPersonaColumns(props);
                columns = { ...columns, ...personasColumns };
                break;
            }
            case "cxPrograms":{
                const cxProgramsColumns = GetProgramColumns(props);
                columns = { ...columns, ...cxProgramsColumns };
                break;
            }
            case "users":{
                const usersColumns = GetUserColumns(props);
                columns = { ...columns, ...usersColumns };
                break;
            }
            case "folders":{
                const foldersColumns = GetFolderColumns(props);
                columns = { ...columns, ...foldersColumns };
                break;
            }
            case "assessments":{
                const assessmentsColumns = GetAssessmentColumns(props);
                columns = { ...columns, ...assessmentsColumns };
                break;
            }
            case "libraries": {
                const librariesColumns = GetLibraryColumns(props);
                columns = { ...columns, ...librariesColumns };
                break;
            }
            case "insights": {
                const insightColumns = GetInsightColumns(props);
                columns = { ...columns, ...insightColumns };
                break;
            }
            default:
                break;
        }
        return columns;
    };
    return tablePropsDictionaryRelevantColumns();
}

