import { useSelector } from "react-redux";
import PersonaBubble from "../PersonaBubble";
import { useState } from "react";
import { useImperativeHandle } from "react";
import { FlexAlignCenter } from "shared/ui-basics";
import { Dropdown as DropdownPrimeReact } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useClickOutsides } from "shared/hooks";
import { useRef } from "react";
import { EMPTY_GUID } from "@utils/helpers";



const PersonaSelection = ({ innerRef ,className='w-100',...props}) => {

    const {t} = useTranslation()
    const dropdownRef = useRef(null);
    const authorizedClickOuteClasses = ["p-dropdown-item","ellipsis ms-2","PersonaBubble__PersonaImage-sc-bui15p-1 jaEAuo","ui-basics__FlexAlignCenter-sc-zh9sy2-3 dWpHJU"];
    const personas = useSelector(({ personas }) => personas?.all_personas.getActiveRecords() || []);
    const [selected, setSelected] = useState(props?.defaultselected ? personas?.[0]?.personaId || EMPTY_GUID : null);
    const hasDefault = selected === EMPTY_GUID;
    const ref = useClickOutsides(() => {
        if (dropdownRef.current) {
            dropdownRef.current?.hide();
          }
    },authorizedClickOuteClasses); // due to primereact menu outisde the root element



    const options = [...personas?.map((persona) => ({
        name: persona.name,
        value: persona.personaId
    })), hasDefault ? { name: t("DEFAULT"), value: EMPTY_GUID } : null].filter(Boolean);

    const onChange = (e) =>  setSelected(e.value);


    // to retrieve from parent component
    useImperativeHandle(innerRef, () => ({
        getSelectedPersona: () => selected
    }))

    // template component for the dropdown
    const Personalabel = (option, props) => {
        if (option) {
            const persona = personas?.find((persona) => persona.personaId === option.value);
            return (
                <FlexAlignCenter >
                    <PersonaBubble persona={persona} size="xs" />
                    <div className='ellipsis ms-2'>{option?.name}</div>
                </FlexAlignCenter>
            );
        }

        return props && <span>{props.placeholder}</span>;
    };
    return (
        <div ref={ref}>
        <Dropdown
            value={selected}
            onChange={onChange}
            options={options}
            optionLabel="name"
            placeholder={t("SELECT_PERSONA")}
            filter={props.search ? true : false}
            valueTemplate={Personalabel}
            itemTemplate={Personalabel}
            className={className}
            ref={dropdownRef}

           />
        </div>


    )
}

const Dropdown = styled(DropdownPrimeReact)`
.p-inputtext {
    padding: 0.4rem 0.75rem;
}

`

export default PersonaSelection;