import "@utils/helpers";
import { encode } from "@utils/helpers";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { base } from "../../index";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const location = useLocation();

	const token = useSelector((state) => state.auth.token);
	const incorrectEnvName = useSelector((state) => state.auth?.userInfo?.EnvName !== base.split("/")[1]);
	const targetPage = encode(location.pathname + location.search);
	const allowsComponent = !incorrectEnvName && token !== undefined && token !== null;

	return <Route  {...rest} render={(props) => (allowsComponent ? <Component key={props.location.pathname + props.location.search?.split("&tab=")?.[0]} {...props} /> : <Redirect to={`/login?tgtUrl=${targetPage}`} />)} />;
};

export default React.memo(PrivateRoute);
