import { Offcanvas } from "react-bootstrap";
import styled from "styled-components";

export   const OffcanvasLayout = styled(Offcanvas)`
	width: 480px;
	background-color: #FFFFFF !important;

	.offcanvas-body {
		overflow: hidden;
		padding-left: 0px;
		border-radius: 0px;
		padding: 0px;
	}
	.btn-close:focus {
		box-shadow: none !important;
	}
`;
export const HeaderContainer = styled(Offcanvas.Header)`
	font-weight: 400;
	font-size: 16px;
	padding: 10px;
	margin: 0;
	background-color: #FFFFFF !important;
`;

export const DivWrapper=styled.div`
height:48px;
background: #F9FBFC;

`