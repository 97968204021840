import InitialsBubble from "features/settings/manage-users/components/initials-bubble";
import { AvatarGroup } from "primereact/avatargroup";
import styled from "styled-components";
import * as Style from "./Styles";

export default function Owners(item) {
    const owners = item?.authorizedUsers?.filter((user) => user.roleName.toLowerCase() === "owner");
	return (
		<GroupWrapper>
			{owners?.slice(0, 1).map((owner) => (
				<InitialsBubble key={owner?.id} fullName={owner?.fullName} size='s' />
			))}
			{owners?.length > 1 && <Style.BubbleCount bubbleSize={35}>+{owners.length - 1}</Style.BubbleCount>}
		</GroupWrapper>
	);
}

const GroupWrapper = styled(AvatarGroup)`

	display: -webkit-box;
	overflow: hidden;`;