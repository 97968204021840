import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListPopover } from "shared/popovers/ListPopover";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components/macro";

const PopoverEditMode = ({ elementIds, RenderElement, forceClose, ...props }) => {
	const { t } = useTranslation();
	const target = useRef(null);

	const [isEditMode, setIsEditMode] = useState(false);
	const [show, setShow] = useState(false);


	if (!props.popoverParams) return null;

	const {
		popoverParams: { disabled, addLabel, addFunction, type },
	} = props;

	const handleToggle = () => {
		setShow(true);
		setIsEditMode(true);
	};
	const handleShow = (isShow) => {
			setShow(isShow);
			setIsEditMode(isShow);

	};

	const Elements = () => {
		return (
			<Wrapper disabled={disabled} className="d-flex flex-wrap">
				{elementIds?.map((id) => (
					<div key={id}>{RenderElement(id, isEditMode)}</div>
				))}
			</Wrapper>
		);
	};
	if (disabled) return <Elements />;

	return (
		<Wrapper iseditmode={isEditMode.toString()} ref={target} onClick={handleToggle} >
			{elementIds?.length === 0 ? (
				<GenericButton variant="light-link" size="s" icon="SQUARE_PLUS" iconClassName="me-1" disabled={disabled}>
					{t(addLabel)}
				</GenericButton>
			) : (
				<Elements />
			)}

			<ListPopover
				ref={target}
				type={type}
				linkFunction={addFunction}
				show={show}
				setShow={handleShow}
				relatedItems={props.popoverParams.relatedItems}
				specificItems={props.popoverParams.specificItems}
				forceClose={forceClose}
			/>
		</Wrapper>
	);
};
PopoverEditMode.displayName = "PopoverEditMode";
PopoverEditMode.propTypes = {
	elementIds: PropTypes.array,
	RenderElement: PropTypes.func,
};
export default PopoverEditMode;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	/* flex-wrap: wrap; */
	width: 100%;
	min-height: 40px;
	border-radius: 0.25rem;

	${(props) => props?.iseditmode === "true" && `background:#EFEFEF;`}
	&:hover,
&:focus {
		${(props) =>
			!props?.disabled &&
			`background:#EFEFEF;
     cursor: pointer;`}
	}
`;
