import { createCJM, createPersona } from '@redux/index';
import { createAction } from '@reduxjs/toolkit';
import { EMPTY_GUID, encode, getLanguagebyCode } from '@utils/helpers';
import { InformationType, MapMode, StateTypeOption } from '@utils/optionsSets/OptionSets';
import { alexAssistantCjm } from 'features/ai/reducer/ai-actions';
import PersonaSelection from 'features/forms/personas/components/dropdown/PersonaSelection';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import TextareaAutosize from "react-textarea-autosize";
import { toast } from 'react-toastify';
import { Col, InputGroup, InputGroupText, Row } from 'reactstrap';
import { GenericButton, IconDispatcher, Input, Label } from 'shared/ui-basics';
import SelectButton from 'shared/ui-basics/ui-components/SelectButtons';
import { Body, Footer, Header, Info, Modal } from './ai-creation-style';

export default function AICreation({ isOpen, onClose }) {
    const { t, i18n } = useTranslation()

    const folderId = useLocation().search.getFolderId();
    const history = useHistory()
    const dispatch = useDispatch()
    const systemLanguage = getLanguagebyCode(i18n.language)

    const industryRef = useRef(null);
    const swimlaneRef = useRef(null);
    const personaRef = useRef(null);

    const mapCreation = (params) => {
        const alexPrompt = `
     Generate a name and a summary for the following customer journey description: "${params.name}"
Respond in the following JSON format only name and summary are required

      `;

        const setAIGeneration = createAction("SET_AI_GENERATION")
        dispatch(setAIGeneration(true))

        alexAssistantCjm({
            prompt: alexPrompt, max_tokens: 300, onSuccess: (response) => {
                if (response.name && response.summary) {
                    params.name = response.name
                    params.description = response.summary
                    const SetCreationCJMParams = createAction("SET_CREATION_CJM_PARAMS")
                    dispatch(SetCreationCJMParams(params))
                    createCJM({
                        newCjmParams: params,

                        onSuccess: (data) => {
                            history.push(`/EditMap?cjmid=${data.cjmId}&personaId=${params.personaId}`)
                            localStorage.setItem('recentlyGeneratedByAI', data.cjmId)
                            dispatch(SetCreationCJMParams(null))
                            dispatch(setAIGeneration(false))


                        },
                        onFailed: () => toast.error('FAILED_TO_CREATE_JOURNEY_MAP')
                    })
                }
            }
        })

        history.push('/map-creation-loading')
        onClose()

    }

    const onSubmit = () => {
        const prompt = industryRef.current?.getPrompt();
        const swimlanes = swimlaneRef.current?.getSelectedSwimlane();
        const persona = personaRef.current?.getSelectedPersona();
        if (!prompt) return toast.info(t('PLEASE_CJM_DESCRIPTION'))
        const params = {
            name: prompt,
            description: prompt,
            personaId: persona,
            aiGeneration: true,
            selectedLanes: swimlanes,
            mapMode: MapMode.Advanced
        }
        if (persona === EMPTY_GUID) {
            createNewPersona(params)
        } else {
            mapCreation(params);
        }

    }
    const createNewPersona = (params) => {
        const prompt = industryRef.current?.getPrompt();

        const alexPrompt = `
        Generate a  persona relevant for the journey map description'${prompt}'.
        Response format (JSON):
        {
            "name": "Persona Name(ex: John Doe)",
            "occupation": "Persona Occupation",
            "age": "Persona Age",
            "gender": "Persona gender(Man,Woman,Other)",
            "location": "Persona Location",
            "context": "Persona Context"
        }
      `;
        history.push("/edit-persona-pending");


        alexAssistantCjm({
            prompt: alexPrompt, max_tokens: 300, onSuccess: (response) => {
                if (response.name && response.occupation) {
                    const newPersona = createDefaultPersona({
                        name: response.name,
                        occupation: response.occupation,
                        age: response.age,
                        gender: response.gender,
                        language: systemLanguage,
                        location: response.location,
                        context: response.context
                    })
                    createPersona({
                        item: newPersona,
                        onSuccess: (data) => {
                            params.personaId = data.personaId
                            mapCreation(params)
                        },
                        onFailed: () => {
                            toast.error(t('FAILED_TO_CREATE_PERSONA_AI'))
                            history.push("/journey-map")
                        }

                    })
                }

            },
            onFailed: () => {
                toast.error(t('FAILED_TO_CREATE_PERSONA_AI'))
                history.push("/journey-map")
            }
        })
        onClose()

    }
    return (
        <Modal isOpen={isOpen} fade={false} centered={true} toggle={onClose}>
            {/* <div className='white-container overflow-hidden '> */}
            <Header toggle={onClose} tag="h5" >{t("GENERATE_CJM_HEADER")}</Header>
            <Body className='m-3 p-0'>
                <Label className='mt-4 mb-3' size='14'>{t("MAP_DESCRIPTION_CJM_AI")}</Label>
                <IndustryDescription innerRef={industryRef} />
                <Label className='mt-4 mb-3' size='14'>{t("SWIMLANE_LABEL_AI_CREATION")}</Label>
                <SwimlaneSelection innerRef={swimlaneRef} />
                <Row>
                    <Col xs={12}>
                        <Label className='mt-4 mb-3' size='14'>{t("PERSONA")}</Label>
                        <PersonaSelection innerRef={personaRef} defaultselected />
                    </Col>
                    {/* <Col xs={6}>
                            <Label className='my-3' size='14'>{t("NUMBER_OF_STAGES")}</Label>
                            <Input type="number" name="number" id="exampleNumber" defaultValue={7} disabled={true} />
                        </Col> */}
                </Row>
            </Body>
            <Footer>
                <GenericButton variant='primary' icon={"SPARKLES"} iconClassName='me-1' onClick={onSubmit}>
                    {t("GENERATE")}
                </GenericButton>
            </Footer>
            <Info>{IconDispatcher('INFO', 'me-1')}{t("AI_DATA_MAY_BE_INACURATE")}</Info>
            {/* </div> */}
        </Modal>
    )
}

const SwimlaneSelection = ({ innerRef }) => {

    const [value, setValue] = useState([`Touchpoints`]);

    const options = [
        { key: 'Touchpoints', label: 'TOUCHPOINTS', value: InformationType.AdvancedInteractions },
        { key: 'Pains', label: 'ADVANCED_CONS_SWIMLANE', value: InformationType.Insights },
        { key: 'Gains', label: 'JS_57', value: InformationType.Insights },
        { key: 'Findings', label: 'FINDINGS', value: InformationType.Insights },
        { key: 'Opportunities', label: 'OPPORTUNITIES', value: InformationType.Opportunity },
        { key: 'Solutions', label: 'SOLUTIONS', value: InformationType.Solution },


    ]

    const handleChange = (selectedValue) => {
        if (selectedValue === 'Touchpoints') return
        setValue((prevValue) =>
            prevValue?.includes(selectedValue)
                ? prevValue.filter((value) => value !== selectedValue)
                : [...prevValue, selectedValue]
        );
    };

    useImperativeHandle(innerRef, () => ({
        getSelectedSwimlane: () => value
    }))

    return (
        <SelectButton selected={value} onChange={handleChange} options={options} />
    )
}
const IndustryDescription = ({ innerRef }) => {
    const { t } = useTranslation()

    const [prompt, setPrompt] = React.useState('')

    const handleChange = (e) => {
        setPrompt(e.target.value)
    }
    useImperativeHandle(innerRef, () => ({
        getPrompt: () => prompt
    }))

    return <IconGroup icon='SPARKLES'>
        <TextareaAutosize
            className='align-content-center'
            minRows={2}
            maxRows={4}
            value={prompt}
            onChange={handleChange}
            placeholder={t("DESCRIBE_CJM_PLACEHOLDER")}

        />
    </IconGroup>
}
const IconGroup = ({ icon, children }) => {
    return (
        <InputGroup>
            <InputGroupText style={{ color: '#c4c4c4' }}>
                {IconDispatcher(icon)}
            </InputGroupText>
            {children}
        </InputGroup>
    )
}

const createDefaultPersona = ({ name, occupation, age, gender, language, location, context }) => ({
    name: name,
    occupation: occupation ?? "",
    age: age ?? "",
    gender: gender?.replace("Female", "Woman").replace("Male", "Man"),
    isFuture: StateTypeOption.CurrentState,
    standardImage: "",
    customImage: "upload",
    imageExtension: "",
    isTemplate: false,
    aiGeneration: true,
    language: language,
    location: location ?? "",
    context: context ?? "",
})