import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { FlexColumn, Input, Label } from "shared/ui-basics";
import styled from "styled-components";
import { CustomGroup, SectionTitle } from "./form-styles";
import { useState } from "react";

const FormPersonalDetails = ({ user, modifiedAttributes, setModifiedAttributes, setUser, isStandard, isSharedExpert, isSSO, modeType }) => {
	const { t } = useTranslation();
	const isCreateMode = modeType === "create";
	const isUpdateMode = modeType === "update";
	const showAllEmailsFields = !isStandard && !isSharedExpert && isSSO;

	const [isSync, setIsSynch] = useState(showAllEmailsFields && isCreateMode);

	const handleInputChange = (e) => {
		const attribute = e.target.name;
		const value = e.target.value;
		e.preventDefault();
		if (e.charCode === 13) {
			return;
		}
        if (!modifiedAttributes?.includes(attribute)) setModifiedAttributes([...modifiedAttributes, attribute]);

        if (attribute === "email") setIsSynch(false)
        if (isSync && attribute === "loginName") {
			if (!modifiedAttributes?.includes("email")) setModifiedAttributes([...modifiedAttributes, "email"]);
			setUser({ ...user, loginName: value, email: value });
		} else {
			setUser({ ...user, [attribute]: value });
		}
	};

	// Standard and SharedExpert have only email field
	const RenderEmailsFields = () => {
		if (showAllEmailsFields)
			return (
				<>
					<CustomGroup>
						<Label size="14">{t("CS_56")}</Label>
						<Input variant="greyed-disable" type="text" name="loginName" value={user?.loginName || ""} onChange={handleInputChange} disabled={isUpdateMode} />
					</CustomGroup>
					<CustomGroup>
						<Label size="14">{t("COMMUNICATION_EMAIL")}</Label>
						<Input variant="greyed-disable" type="text" name="email" value={user?.email || ""} onChange={handleInputChange} />
					</CustomGroup>
				</>
			);
		return (
			<>
				<CustomGroup>
					<Label size="14">{t("CS_56")}</Label>
					<Input variant="greyed-disable" type="text" name="email" value={user?.email || ""} onChange={handleInputChange} disabled={isUpdateMode} />
				</CustomGroup>
			</>
		);
	};

	return (
		<Settings>
			<SectionTitle>{t("PERSONAL_DETAILS")}</SectionTitle>
			<FlexColumn>
				<CustomGroup>
					<Label size="14">{t("CS_57") + " *"}</Label>
					<Input variant="default" type="text" name="firstName" value={user?.firstName || ""} onChange={handleInputChange} />
				</CustomGroup>
				<CustomGroup>
					<Label size="14">{t("CS_58") + " *"}</Label>
					<Input variant="default" type="text" name="lastName" value={user?.lastName || ""} onChange={handleInputChange} />
				</CustomGroup>
				<CustomGroup>
					<Label size="14">{t("ROLE")}</Label>
					<Input variant="default" type="text" name="role" value={user?.role || ""} onChange={handleInputChange} />
				</CustomGroup>
				{RenderEmailsFields()}
			</FlexColumn>
		</Settings>
	);
};

FormPersonalDetails.displayName = "FormPersonalDetails";
export default FormPersonalDetails;

const Settings = styled.div`
	border-radius: 4px;
	border: 1px solid #efefef;
	background: #ffffff;
	padding: 24px 14px;
`;
