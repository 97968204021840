import { StateTypeOption } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";
import { t } from "i18next";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta, ...props }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
		...props,
	});
};

const createPostAction = InitActionCreator({ baseUrl: "api/persona/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/persona/", method: "GET" });

export const getAllPersonas = (props) =>
	createGetAction({
		type: "GET_ALL_PERSONAS",
		endPoint: constructUrl("getAll", {
			redirectReducer: props?.redirectReducer,
		}),
		meta: { joinRequest: "GET_ALL_PERSONAS" },
	});

export const copyPersona = ({ id, name, workspaceIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_PERSONA",
			endPoint: "copyPersona",
			data: {
				personaIdStr: id,
				copiedPersonaName: encode(name),
				workspaceIds,
			},
		}),
		displayToast: props.displayToast || "COPY",
    });

export const deletePersona = ({ persona_id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PERSONA",
			endPoint: "deletePersona",
			data: {
				personaIdStr: persona_id,
			},
		}),
		displayToast: props.displayToast || "DELETE",
	});
export const archivePersona = ({ persona_id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_PERSONA",
			endPoint: "archivePersona",
			data: {
				personaIdStr: persona_id,
			},
		}),
		displayToast: props.displayToast || "ARCHIVE",
	});
export const restorePersona = ({ persona_id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_PERSONA",
			endPoint: "restorePersona",
			data: {
				personaIdStr: persona_id,
			},
		}),
		displayToast: props.displayToast || "RESTORE",
	});
export const deletePermanentlyPersona = ({ persona_id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PERMANENTLY_PERSONA",
			endPoint: "deletePermanentlyPersona",
			data: {
				personaIdStr: persona_id,
			},
		}),
		displayToast: props.displayToast || "DELETE",
	});
export const createPersona = ({ item, cxProgramId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_PERSONA",
			endPoint: "createPersona",
			data: {
				persona: {
					personaId: EMPTY_GUID,
					name: item?.name ? encode(item?.name) : item?.name,
					occupation: item?.occupation ? encode(item?.occupation) : item?.occupation,
					isFuture: item?.isFuture === StateTypeOption.FutureState ? true : false,
					standardImageNumber: item?.standardImage ? Number(item?.standardImage) : -1,
					isTemplate: item?.isTemplate,
					fromTemplateId: item?.fromTemplateIdStr,
				},
				customImage: item?.customImage?.split(",")[1] || "",
				imageExtension: item?.imageExtension,
				aiGeneration: item?.aiGeneration || false,
				language: item?.language || "",
				linkToCxProgramIdStr: cxProgramId,
				age: item?.age,
				gender: item?.gender,
				location: encode(item?.location),
				context: encode(item?.context),
			},
		}),
	});
export const setCurrentPersona = (props) =>
	createGetAction({
		type: "SET_CURRENT_PERSONA",
		endPoint: constructUrl("getPersona", {
			personaIdStr: props?.personaIdStr,
		}),
	});
export const updatePersonaSingleTxt = ({ persona_id, attribute, newValue, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_SINGLE_TXT",
			endPoint: "updateStdPersonaSingleTxt",
			data: {
				personaIdStr: persona_id,
				attributeName: attribute,
				newValue: encode(newValue),
			},
		}),
	});
export const updateCustomPersonaAttributeDesc = ({ persona_id, attributeID, newValue, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CUSTOM_PERSONA_ATTRIBUTE_DESC",
			endPoint: "updateCustomPersonaAttributeDesc",
			data: {
				personaIdStr: persona_id,
				attributeIdStr: attributeID,
				newDescription: encode(newValue),
			},
		}),
	});
export const resetCurrentPersona = () => ({ type: "RESET_CURRENT_PERSONA" });

export const getPersona = (props) =>
	createGetAction({
		type: "GET_PERSONA",
		endPoint: constructUrl("getPersona", {
			personaIdStr: props?.personaId,
		}),
	});
export const refreshPersona = (props) => genericService({ ...props, action: getPersona, displayToast: "REFRESH", toastParams: { success: t("PERSONA_REFRESHED") } });

export const updatePersonaAttribOrder = ({ persona_id, newLayout, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_ATTRIB_ORDER",
			endPoint: "updatePersonaAttribOrder",
			data: {
				personaIdStr: persona_id,
				newOrderConfig: newLayout,
			},
		}),
	});
export const updateAttributeName = ({ personaIdStr, attributeIdStr, newName, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_ATTRIBUTE_NAME",
			endPoint: "updateCustomPersonaAttributeName",
			data: {
				personaIdStr: personaIdStr,
				attributeIdStr: attributeIdStr,
				newName: encode(newName),
			},
		}),
	});
export const updateAttributeColor = ({ personaIdStr, attributeIdStr, newColor, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_ATTRIBUTE_COLOR",
			endPoint: "updateCustomAttributeProperties",
			data: {
				personaIdStr: personaIdStr,
				customAttributeIdStr: attributeIdStr,
				newColorCodeParam: newColor,
			},
		}),
	});
export const addPersonaAttribute = ({ newObject, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ADD_PERSONA_ATTRIBUTE",
			endPoint: "addPersonaAttribute",
			data: {
				personaIdStr: newObject.personaID,
				attributeType: newObject.type,
			},
			params: newObject,
		}),
	});
export const deletePersonaCustomField = ({ personaId, attributeId, AttributeName, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PERSONA_CUSTOM_FIELD",
			endPoint: "deletePersonaCustomField",
			data: {
				personaIdStr: personaId,
				attributeIdStr: attributeId,
				attributeNameStr: AttributeName,
			},
		}),
	});
export const updatePersonaImage = ({ personaId, standardImageNumber, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_STD_IMAGE",
			endPoint: "updatePersonaStdImage",
			data: {
				personaIdStr: personaId,
				standardImageNumber: standardImageNumber,
			},
		}),
	});
export const updatePersonaImageCustom = ({ personaId, imageExtension, imageBody64, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_CUSTOM_IMAGE",
			endPoint: "updatePersonaCustomImage",
			data: {
				personaIdStr: personaId,
				imageExtension,
				imageBody64,
			},
		}),
	});
export const deleteCustomPersonaImage = ({ personaId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PERSONA_CUSTOM_IMAGE",
			endPoint: "deleteCustomPersonaImage",
			data: {
				personaId: personaId,
			},
		}),
	});
export const updateCustomPersonaAttributeImage = ({ personaId, attributeId, imageExtension, imageBody64, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CUSTOM_PERSONA_ATTRIBUTE_IMAGE",
			endPoint: "uploadCustomPersonaAttributeImage",
			data: {
				personaIdStr: personaId,
				attributeIdStr: attributeId,
				imageExtension: imageExtension,
				imageBody64: imageBody64,
			},
		}),
	});
export const deleteImageOnBox = ({ personaId, customImageId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_IMAGE_ON_BOX",
			endPoint: "deleteCustomPersonaAttributeImage",
			data: {
				personaIdStr: personaId,
				personaCustomImageIdStr: customImageId,
			},
		}),
	});
export const updatePersonaName = ({ id, name, ...props }) =>
	createPostAction({
		type: "UPDATE_PERSONA_NAME",
		endPoint: "updateStdPersonaSingleTxt",
		data: {
			personaIdStr: id,
			attributeName: "cem_name",
			newValue: window.btoa(unescape(name)),
		},
	});
export const updatePersonaType = ({ newValue, personaIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_TYPE",
			endPoint: "updatePersonaType",
			data: {
				personaIds,
				newValue,
			},
		}),
	});
export const linkPersona = ({ cjmId, personaId, fromPersonaId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "LINK_PERSONA",
			endPoint: "linkPersona",
			data: {
				cjmIdStr: cjmId,
				personaIdStr: personaId,
				fromPersonaIdStr: fromPersonaId,
			},
		}),
	});
export const relinkPersona = ({ cjmIdStr, personaIdStr, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RELINK_PERSONA",
			endPoint: "reLinkPersona",
			data: {
				cjmIdStr,
				personaIdStr,
			},
		}),
	});
export const unlinkPersona = ({ cjmIdStr, personaIdStr, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UNLINK_PERSONA",
			endPoint: "unlinkPersona",
			data: {
				cjmIdStr,
				personaIdStr,
			},
		}),
		displayToast: props.displayToast || "UNLINK",
		toastParams: { success: t("REMOVE_PERSONA_FROM_MAP_SUCCESS") },
	});
export const getPreviousLinkedPersonas = ({ cjmId }) =>
	createGetAction({
		type: "RETRIEVE_PERVIOUS_LINK_PERSONA",
		endPoint: constructUrl("retrievePreviousLinkedPersonas", {
			cjmIdStr: cjmId,
		}),
	});
export const getPdfExportPersona = (props) =>
	createGetAction({
		type: "GET_PDF_EXPORT_PERSONA",
		endPoint: constructUrl("getPdfExport", {
			personaId: props?.personaId,
			width: props?.width,
			height: props?.height,
			renderDelay: props?.renderDelay,
		}),
		responseType: "blob",
	});
export const retrieveLinkedMaps = (props) =>
	createGetAction({
		type: "RETRIEVE_LINKS_MAPS",
		endPoint: constructUrl("retrieveLinkedMaps", {
			personaIdStr: props?.personaId,
		}),
	});
export const deleteManyPersonas = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_PERSONAS",
			endPoint: "deleteMany",
			data: {
				personaIds: ids,
			},
		}),
	});
export const deletePermanentlyManyPersonas = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PERMANENTLY_MANY_PERSONAS",
			endPoint: "deletePermanentlyMany",
			data: {
				personaIds: ids,
			},
		}),
	});
export const archiveManyPersonas = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_MANY_PERSONAS",
			endPoint: "archiveMany",
			data: {
				personaIds: ids,
			},
		}),
	});
export const restoreManyPersonas = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_MANY_PERSONAS",
			endPoint: "restoreMany",
			data: {
				personaIds: ids,
			},
		}),
		displayToast: props.displayToast || "RESTORE",
	});
export const getExcelExportPersona = (ids, columns) =>
	createPostAction({
		type: "GET_EXCEL_PERSONA",
		endPoint: "getExcelExport",
		data: {
			ids,
			columns: columns?.join("|"),
		},
		responseType: "blob",
	});
export const getLinkedExcelExportPersona = (ids) =>
	createPostAction({
		type: "GET_LINKED_EXCEL_PERSONA",
		endPoint: "getLinkedExcelExport",
		data: {
			ids: ids,
		},
		responseType: "blob",
	});
export const deletePersonaAttributeItem = ({ attributeItemId, attributeId, personaId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PERSONA_ATTRIBUTE_ITEM",
			endPoint: "deletePersonaAttributeItem",
			data: {
				attributeItemId,
				attributeId,
				personaId,
				cXGoalId: EMPTY_GUID,
			},
		}),
	});
export const upsertPersonaAttributeItem = ({ personaAttributeItem, personaAttribute, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPSERT_PERSONA_ATTRIBUTE_ITEM",
			endPoint: "upsertPersonaAttributeItem",
			data: {
				personaAttributeItem: {
					...personaAttributeItem,
					name: encode(personaAttributeItem?.name ?? ""),
					description: encode(personaAttributeItem?.description ?? ""),
					sliderMaxTitle: encode(personaAttributeItem?.sliderMaxTitle ?? ""),
					sliderMinTitle: encode(personaAttributeItem?.sliderMinTitle ?? ""),
				},
				personaAttribute,
			},
		}),
	});
export const updatePersonaAttributeItemsOrder = ({ newItemsOrder, attributeIdStr, personaIdStr, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_ATTRIBUTE_ITEM_ORDER",
			endPoint: "updatePersonaAttributeItemsOrder",
			data: {
				newItemsOrder,
				attributeId: attributeIdStr,
				personaId: personaIdStr,
			},
		}),
	});
export const getPersonaUsers = ({ objectId, isPrint, userId, forceViewer }) =>
	createGetAction({
		type: "GET_PERSONA_USERS",
		endPoint: constructUrl("getPersonaUsers", {
			personaId: objectId,
			forceViewer: forceViewer,
		}),
		params: { isPrint: isPrint, userId: userId },
	});
export const sharePersona = ({ roleDetails, userId, userName, personaId, teamId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_SHARE",
			endPoint: "share",
			data: {
				roleDetails: roleDetails,
				userId: userId,
				personaId,
				teamId,
			},
			params: {
				fullName: userName,
			},
		}),
	});
export const unSharePersona = ({ userId, personaId, teamId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_UNSHARE",
			endPoint: "unShare",
			data: {
				userId: userId,
				personaId,
				teamId,
			},
		}),
	});
export const shareUsersSinglePersona = ({ roleDetails, usersIds = "", personaId, teamIds = "", ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_USERS_SINGLE_PERSONA",
			endPoint: "shareMany",
			data: {
				ids: usersIds,
				personaId,
				roleDetails,
				teamIds,
			},
		}),
	});
export const shareUsersMuliplePersona = ({ roleDetails, usersIds, personaIds, teamIds = "", ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_USERS_MULTIPLE_PERSONA",
			endPoint: "shareManyPersona",
			data: {
				ids: usersIds,
				personaIds,
				roleDetails: roleDetails,
				teamIds,
			},
		}),
		displayToast: props.displayToast || "UPDATE",
	});
export const updatePersonaLinksWithPersonas = ({ personaIdsToLink, personaIdsToUnlink, personaIdStr, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PERSONA_LINKS_PERSONA",
			endPoint: "updatePersonaLinksWithPersonas",
			data: {
				personaIdsToLink: personaIdsToLink,
				personaIdStr: personaIdStr,
				personaIdsToUnlink: personaIdsToUnlink,
			},
		}),
		displayToast: props.displayToast || "UPDATE",
	});
export const updateLockPersonaTemplate = ({ modifiedPersona, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_LOCK_PERSONA_TEMPLATE",
			endPoint: "update",
			data: {
				modifiedPersona,
				modifiedAttribNames,
			},
		}),
	});
export const updateStatusPersonaTemplate = ({ modifiedPersona, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_STATUS_PERSONA_TEMPLATE",
			endPoint: "update",
			data: {
				modifiedPersona,
				modifiedAttribNames,
			},
		}),
	});
export const convertPersonaToTemplate = ({ personaId, newName, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CONVERT_PERSONA_TO_TEMPLATE",
			endPoint: "convertPersonaToTemplate",
			data: {
				personaId,
				newName: encode(newName),
			},
		}),
		displayToast: "SAVE_PERSONA_AS_TEMPLATE_SUCCESS",
	});

export const resetPersonasUsers = () => {
	return {
		type: "RESET_PERSONAS_USERS",
	};
};
