import React from "react";
import styled, { css } from "styled-components/macro";
import { IconDispatcher } from "shared/ui-basics/index";
import { useTranslation } from "react-i18next";

const SelectionCard = React.memo(({ object, selected, handleClick, showLabel, disabled }) => {
	const { t } = useTranslation();
	const checked = selected === undefined ? false : selected;
	const click = (e) => {
		e.preventDefault();
		handleClick(object);
	};
	return (
		<Card disabled={object.disable || disabled} onClick={click}>
			<input type="checkbox" checked={checked} disabled={object.disable || disabled} readOnly={true} />
			<ImageWrapper selected={selected}>{IconDispatcher(object?.image, "", { size: object?.size , style:'duotone' })}</ImageWrapper>
			<TextWrapper>
				<div className="d-flex align-items-center">
					<Title selected={selected}>{object?.title}</Title>
					{showLabel && object?.isEnterprise && <SubscriptionLabel className="ms-2">{t("EXPERT")}</SubscriptionLabel>}
				</div>
				<Description>{object?.description}</Description>
				{showLabel && object?.isEnterprise && <Upgrade onClick={() => window.open("https://www.cemantica.com/pricing")}>{t("CS_4")}</Upgrade>}
			</TextWrapper>
		</Card>
	);
});
SelectionCard.displayName = "SelectionCard";
export default SelectionCard;

const Card = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	cursor: pointer;

	input[type="checkbox"] {
		min-width: 16px;
		min-height: 16px;
		accent-color: #333333;
		pointer-events: none;
		:hover {
			cursor: pointer;
		}
	}
	input[type="checkbox" i]:disabled {
		background-color: #c4c4c4;

		:hover {
			cursor: not-allowed;
		}
	}

	${(props) =>
		props.disabled &&
		css`
			opacity: 0.7;
			pointer-events: none;
			custor: default;
		`}
`;

const ImageWrapper = styled.div`
	min-width: 50px;
	${(props) =>
		props.selected &&
		css`
			svg:not(:host).svg-inline--fa {
				color: #f96209;
			}
			svg {
				path {
					fill: #f96209;
				}
			}
		`};
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	color: ${(props) => (props.selected ? "#f96209" : "#333")};
	font-size: 16px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const Description = styled.div`
	color: #605f60;
	font-size: 14px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const SubscriptionLabel = styled.div`
	display: flex;
	padding: 2px 6px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: #f8c8ae;
	color: #f96209;
	text-align: center;
	font-size: 10px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
`;

const Upgrade = styled.div`
	color: #f96209;
	font-size: 12px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	:hover {
		cursor: pointer;
	}
`;
