import { getAllAuthorizedUsers, getAllRegisteredUsers, getAllTrainings, getFolders, getOrganizationSettings, getSettingUserFn, getSubscriptionDetails } from "@redux/index";
import { getAllWorkspaces } from "features/workspace/reducer/WorkspaceActions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataLoader from "shared/components/data-loader";
import { ModalProvider } from "use-modal-hook";
import NavMenuHeader from "./Header/NavMenuHeader/NavMenuHeader";
import { NavigationSidebar } from "./SideBar/NavigationSidebar";
import { useTranslation } from "react-i18next";

export default function NavMenu() {
	const search = useLocation().search;
	const hideForPdf = search.hideForPdf();
	const isPreview = search.isPreview();
	const { i18n } = useTranslation();

	useEffect(() => {
		getSettingUserFn({
			onSuccess: (data) => {
				if (data?.language) i18n?.changeLanguage(data?.language || "en");
				localStorage.setItem("language", data?.language || "en");
			},
		});
	}, []);

	return (
		<DataLoader reduxActions={[getSubscriptionDetails, getAllAuthorizedUsers, getAllRegisteredUsers, getOrganizationSettings, getFolders, getAllTrainings, getAllWorkspaces]} disabledSpinner>
			<NavMenuHeader hideForPdf={hideForPdf} isPreview={isPreview} />
			<ModalProvider>
				<NavigationSidebar hideForPdf={hideForPdf} isPreview={isPreview} />
			</ModalProvider>
		</DataLoader>
	);
}
