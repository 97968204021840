import { IconDispatcher } from "shared/Icons";
import { firstLetterUppercase, isUserAllowed, resort } from "@utils/helpers.js";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PersonaProfileCard from "../../forms/personas/cards/persona-sections-cards/PersonaProfileCard";
import { PersonaValidation } from "../contexts";
import * as Style from "./Style.js";

const CardViewPersona = () => {
	const { t } = useTranslation();
	const pathname = useLocation().pathname;
	const history = useHistory();
	const isInTemplate = pathname.includes("/templates");
	const [upDown, setUpDown] = useState("up");
	const [type, setType] = useState("name");

	const userType = useSelector((state) => state.auth.userInfo.UserType);
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const personas = useSelector((state) => state.personas.all_personas.filter((persona) => PersonaValidation(persona, pathname, null, showSampleData)));

	const PersonaHeaderViewCard = useCallback(() => {
		return (
			<Style.PersonaHeaderViewContainer>
				<div className="d-flex">
					<Style.SortButton variant="light-link" id="name" onClick={sort} active={upDown === "down" && type == "name"}>
						{t("ALPHABETICAL")} <Style.SortIcon className="ms-2" updown={upDown} type={type} reference={"name"} >{IconDispatcher("arrow-up-arrow-down")}</Style.SortIcon>
					</Style.SortButton>
					<Style.SortButton variant="light-link" id="lastModifiedOn" onClick={sort} active={upDown === "down" && type == "lastModifiedOn"}>
						{t("JS_72")}
						<Style.SortIcon className="ms-2" updown={upDown} type={type} reference={"lastModifiedOn"} >{IconDispatcher("arrow-up-arrow-down")}</Style.SortIcon>
					</Style.SortButton>
					<Style.SortButton variant="light-link" id="linkedCjms" onClick={sort} active={upDown === "down" && type == "linkedCjms"}>
						{t("NUMBER_RELATED_CJM")} <Style.SortIcon className="ms-2" updown={upDown} type={type} reference={"linkedCjms"} >{IconDispatcher("arrow-up-arrow-down")} </Style.SortIcon>
					</Style.SortButton>
				</div>
			</Style.PersonaHeaderViewContainer>
		);
	}, [upDown, type]);

	const sort = useCallback(
		(e) => {
			const currentType = e.currentTarget.id;
			if (currentType !== type) {
				setUpDown("down");
			} else {
				if (upDown === "down") {
					setUpDown("up");
				} else {
					setUpDown("down");
				}
			}
			setType(currentType);
		},
		[upDown, type]
	);

	const goToPersonaCreation = () => {
		history.push(!isInTemplate ? "/persona-creation" : "/persona-creation?isTemplate=true");
	};

	return (
		<Style.CardsContainer>
			<PersonaHeaderViewCard />
			{resort(personas, type, upDown).map((personaObject) => (
				<div className="m-2" key={personaObject.personaId}>
					<PersonaProfileCard key={personaObject.personaId} personaId={personaObject.personaId} />
				</div>
			))}
			{isUserAllowed(userType, "actions-dropdown") && (
				<Style.AddNewPersona className="m-2" onClick={goToPersonaCreation}>
					{IconDispatcher("CIRCLE-PLUS")}
					{firstLetterUppercase(t("ASPX_112"))}
				</Style.AddNewPersona>
			)}
		</Style.CardsContainer>
	);
};

export default CardViewPersona;
