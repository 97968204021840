
export * from '../features/cx-actions/reducer/CxActionActions';
export * from "../features/cx-assessements/reducer/CxAssessementsActions";
export * from "../features/folders/reducer/FoldersActions";
export * from "../features/forms/cx-programs/reducer/CxProgramActions";
export * from "../features/forms/personas/reducer/PersonaActions";
export * from "../features/insights/reducer/InsightActions";
export * from '../features/journey-map/reducer/CJMActions';
export * from "../features/library/reducer/LibraryActions";
export * from "../features/opportunity/reducer/OpportunityActions";
export * from "../features/settings/reducer/SettingsActions";
export * from "../features/settings/reducer/TeamActions";
export * from "../features/settings/reducer/UserActions";
export * from "../features/solutions/reducer/SolutionsActions";


export const updateLanguage = (language) => {
	return {
		type: "UPDATE_LANGUAGE",
		payload: language,
	};
};

export const submitReportRequest = (Subject, Message) => {
	return {
		type: "SUBMIT_REPORT_REQUEST",
		request: {
			url: "api/bo/submitReportRequest",
			method: "post",
			data: {
				Subject,
				Message,
			},
		},
	};
};



export const getAllTrainings = () => {
	return {
		type: "GET_ALL_TRAININGS",
		request: {
			url: "api/training/getAll",
			method: "GET",
		},
	};
};

export const getNotifs = () => {
    return {
        type: 'GET_NOTIFICATIONS',
        request: {
            url: 'api/notif/getMyLast30',
            method: 'GET',

        },
        meta: { joinRequest: 'GET_NOTIFICATIONS' },

    }
}
export const dismissNotifs = ({ notificationIds }) => {
    return {
        type: 'DISMISS_NOTIFICATIONS',
        request: {
            url: 'api/notif/dismiss',
            method: 'POST',
            data: {
                notificationIds: notificationIds
            }

        },

    }
}
export const updateStatusNotifs = ({ seen, notificationId }) => {
    return {
        type: 'UPDATE_STATUS_NOTIFICATION',
        request: {
            url: 'api/notif/updateStatus',
            method: 'POST',
            data: {
                seen: seen,
                notificationId: notificationId
            }

        }
    }
}

export const createNotif = ({ mentionedUsersId, notificationType, referencedObjectId, referenceObjectUrl }) => {
    return {
        type: 'CREATE_NOTIFICATION',
        request: {
            url: 'api/notif/create',
            method: 'POST',
            data: {
                mentionedUsersId: mentionedUsersId,
                notificationType: notificationType,
                referencedObjectId: referencedObjectId,
                referenceObjectUrl: referenceObjectUrl
            }

        }
    }
}