import { getDate } from "@utils/helpers";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import styled from "styled-components/macro";

function DateFilter({ title, icon, apply }) {
	const { t } = useTranslation();
	const op = useRef();
	const inputRef = useRef(null);

    const currentDate = useSelector((state) => state.analytics?.currentData?.date);
	const [selectedDate, setSelectedDate] = useState(currentDate);

	useEffect(() => {
		setSelectedDate(currentDate);
	}, [currentDate]);

	const handleApply = (e) => {
		op.current.toggle(e);
		apply({ dataAttribute: "date", selectedItems: selectedDate });
	};

	const handleOpen = (e) => {
		op.current.toggle(e);
		setTimeout(() => {
			inputRef?.current?.focus();
		}, 100);
	};

	const displayFilterTitle = () => {
		if (!selectedDate) return t(title);
		const dateObj = getDate(selectedDate);
		return `${dateObj?.year}`;
	};

	return (
		<Col>
			<GenericButton variant="light" icon={icon} iconClassName="me-2" onClick={handleOpen}>
				<div>{displayFilterTitle()}</div>
			</GenericButton>
			<CustomOverlayPanel ref={op} onHide={() => setSelectedDate(currentDate)}>
				<DropdownContainer>
					<Calendar className="p-2" value={selectedDate} onChange={(e) => setSelectedDate(e.value)} view="year" dateFormat="yy" inline minDate={new Date(2000, 0, 1)} maxDate={new Date()} />
					<Footer>
						<FlexBetweenAlignCenter className="p-2">
							<GenericButton variant="light-link" onClick={() => setSelectedDate("")}>
								{t("CLEAR_FILTERS")}
							</GenericButton>
							<GenericButton variant="primary" onClick={handleApply}>
								{t("APPLY")}
							</GenericButton>
						</FlexBetweenAlignCenter>
					</Footer>
				</DropdownContainer>
			</CustomOverlayPanel>
		</Col>
	);
}

DateFilter.displayName = "DateFilter";
export default DateFilter;


const CustomOverlayPanel = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0px;
	}

	.p-overlaypanel-close {
		background: #f96209;
		color: #ffffff;
		width: 1.5rem;
		height: 1.5rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		position: absolute;
		top: -12px;
		right: -12px;
	}
`;

const DropdownContainer = styled.div`
	width: 300px;
	display: flex;
	flex-direction: column;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const Footer = styled.div`
	border-top: 1px solid #c4c4c4;
`;
