import { FlexColumnGap, GenericButton } from "shared/ui-basics/index";
import EditCxGoalCard from "shared/components/cards/cx-goals/EditCxGoalCard";
import { EditorProvider } from "shared/dynamic-imports";
import { EMPTY_GUID, createDefaultCxGoal } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components/macro";
import { v4 as uuid } from "uuid";

export default function SelectedCxGoals({ cjmId, stageId, selectedMeasures, setSelected, addSelected, onClose }) {
	const { t } = useTranslation();
	const { pathname, search } = useLocation();
	const [cxGoals, setCxGoals] = useState([]);

	useEffect(() => {
		// Add cxGoal if new measure selected
		selectedMeasures.forEach((measureId) => {
			if (!cxGoals.find((cxGoal) => cxGoal.measureId === measureId)) {
				const cxGoalId= pathname==="/EditCxProgram" ? uuid() : EMPTY_GUID;
				setCxGoals([...cxGoals, createDefaultCxGoal({ mapId: cjmId, stageId, measureId ,cxGoalId})]);
			}
		});

		// remove cxGoal if a measure was removed
		cxGoals.forEach((cxGoal) => {
			if (!selectedMeasures.find((measureId) => cxGoal.measureId === measureId)) {
				setCxGoals(cxGoals.filter((goal) => goal.measureId !== cxGoal.measureId));
			}
		});
    }, [selectedMeasures]);

    const handleAddSelected = () => {
        cxGoals.financialImpact =
		setSelected([]);
		addSelected({ CxGoals: cxGoals, stageId, measures: cxGoals?.map((cxGoal) => ({ id: cxGoal.measureId, measureName: cxGoal.measureName })) });
		onClose();
	};

	return (
		<Container>
			<EditorProvider>
				<Body className="p-4">
					<Title>{t("SELECTED_MEASURES")}</Title>
					<FlexColumnGap gap="16">
						{cxGoals.map((cxGoal, index) => (
							<EditCxGoalCard key={index} cxGoal={cxGoal} setCxGoals={setCxGoals} />
						))}
					</FlexColumnGap>
				</Body>
			</EditorProvider>
			<Footer className="p-4">
				<GenericButton variant="primary" disabled={cxGoals.length === 0} onClick={handleAddSelected}>
					{t("ADD_SELECTED")}
				</GenericButton>
			</Footer>
		</Container>
	);
}

const Container = styled.div`
	width: 360px;
	height: 100%;
	position: absolute;
	right: 484px;
	background-color: #ffffff;
	border: 1px solid #c4c4c4;
`;

const Body = styled.div`
	height: 92vh;
	display: flex;
	flex-direction: column;
	overflow: scroll;
`;

const Title = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 32px;
`;

const Footer = styled.div`
	border-top: 1px solid #c4c4c4;
`;
