import { PermissionManager } from "@utils/permissionManager";
import AuthorizedUsers from "features/grid-table/columns-components/AuthorizedUsers";
import { updateCjmDescription, updateCjmStatus, updateStateCJM } from 'features/index';
import { Sidebar } from "primereact/sidebar";
import { memo, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CustomEditor } from "shared/components/CustomEditor";
import DisplayState from "shared/cx-components/DisplayState";
import DisplayStatus from "shared/cx-components/DisplayStatus";
import Group from "shared/cx-components/Group";
import { EditorProvider } from "shared/dynamic-imports";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import { GenericButton } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";

function HeaderDetails({ isOpen, onClose }) {
	const { t } = useTranslation();
	const { cjmIdStr } = useLocation().search.getAllMapsParams();

	const mapUsers = useSelector((state) => state.maps.current_linked_users)
	const map = useSelector((state) => state.maps.current_map);

	const groupIds = useSelector((state) => state.maps.current_map.groups);
	const userAccess = useSelector((state) => state?.maps?.currentUser?.roleName);
	const cjmDescription = useSelector((state) => state.maps.current_map.cjmDescription);
	const [description, setDescription] = useState(cjmDescription);
	const [groups, setGroups] = useState({ id: cjmIdStr, groups: groupIds });
	const isStandard = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName === "Standard");

	const { editAllowed } = PermissionManager({
		userAccess,
	});

	const isDisabled = !editAllowed;

	const updateState = (object) => {
		updateStateCJM({ id: cjmIdStr, value: object?.isFuture });
	};
	const updateStatus = (object) => {
		updateCjmStatus({ cjmIdsStr: cjmIdStr, newValueId: object?.statusId });
	};

	const handleBlur = (html) => {
		updateCjmDescription({ id: cjmIdStr, value: html });
	};

	const [showAccessModal] = useModal(GenericManageAccess, {
		itemId: map.cjmId,
	});

	const manageAccessAction = { id: "manageAccess", handleClick: showAccessModal, icon: "ACCESS", text: t("ASPX_17") };

	const handleManageAccess = () => {
		manageAccessAction.handleClick();
	};

	const handleClose = () => {
		if (description !== cjmDescription) updateCjmDescription({ id: cjmIdStr, value: description });

		onClose();
	};
	return (
		<CustomSidebar visible={isOpen} onHide={handleClose} baseZIndex={900} position={"right"}>
			<Header>
				<Title>{t("MAP_DETAILS")}</Title>
			</Header>
			<Body className="d-flex flex-column justify-content-between">
				<Details>
					<DetailContainer>
						<Label>{t("MAP_MEMBERS")}</Label>
						<div className="d-flex ">
							{AuthorizedUsers({cjmUsersData:mapUsers}, true)}
							{!isStandard && <GenericButton
								variant="light-link"
								tooltip
								tooltipText={manageAccessAction?.text}
								icon={manageAccessAction?.icon}
								onClick={handleManageAccess}
								tooltipProps={{ style: { alignSelf: "start" } }}
							/>}
						</div>
					</DetailContainer>
					<EditorProvider>
						<DetailContainer>
							<Label>{t("ASPX_9")}</Label>
							<CustomEditor variant="description" html={description} sethtml={setDescription} onBlur={handleBlur} disabled={isDisabled} />
						</DetailContainer>
					</EditorProvider>
					<div className="d-flex">
						<DetailContainer className="me-2">
							<Label>{t("JS_77")}</Label>
							<DisplayState object={map} updateFunction={updateState} disable={isDisabled} />
						</DetailContainer>
						<DetailContainer >
							<Label>{t("ASPX_222")}</Label>
							<DisplayStatus object={map} attKey={"cxStatus"} type="status" name={"customerJourney"} disable={isDisabled} updateFunction={updateStatus} />
						</DetailContainer>
					</div>
					<DetailContainer >
						<Label>{t("GROUPS")}</Label>
						<Group object={groups} setObject={setGroups} disabled={isDisabled} recordType="cem_customerjourney" />
					</DetailContainer>
				</Details>
			</Body>
			<Footer>
				<div>
					{t("JS_56")} <Moment format="D MMMM, HH:mm">{map?.lastModifiedOn}</Moment>
				</div>
			</Footer>
		</CustomSidebar>
	);
}
export default memo(HeaderDetails);

const CustomSidebar = styled(Sidebar)`
	width: 480px !important;
	.p-sidebar-header {
		position: absolute;
		right: 5px;
		top: -7px;
	}
	.p-sidebar-icon {
		border-radius: 4px !important;
	}
	.p-sidebar-icon:focus {
		box-shadow: none !important;
	}
	.p-sidebar-content {
		display: flex;
		flex-direction: column;
	}
`;
const Body = styled(Offcanvas.Body)`
	overflow-x: hidden;
`;
const Title = styled.div`
	font-size: 20px;
	margin-bottom: 1rem;
`;
const Header = styled.div`
	border-bottom: 1px solid #c4c4c4;
	margin: 1rem;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const DetailContainer = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Label = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: capitalize;
`;



const Footer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	color: #605f60;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
