import styled from "styled-components/macro";
import { Offcanvas } from "react-bootstrap";
import { Row } from "reactstrap";

export const OffcanvasLayout = styled(Offcanvas)`
	min-width: 800px;
	box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.25);

	.btn-close:focus {
		box-shadow: none !important;
	}
`;

export const Header = styled(Offcanvas.Header)`
color: #8C8C8C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
`
export const Body = styled(Offcanvas.Body)`
padding: 2rem;
padding-top: 0;
`
export const Labels = styled.div`
color: #605F60;

font-family: Inter;
font-size: ${props => props.fs ? props.fs : '12px'};
font-style: normal;
font-weight: 400;
line-height: normal;
`
export const TouchPointLayout = styled.div`
display: flex;
align-items: center;

.name {
	color: #333;

font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

`
export const PictureContainer = styled.div`
width: 40px;
height: 40px;
flex-shrink: 0;
display: flex;
align-items: center;
img {
	width: auto;
	height: 32px;
	flex-shrink: 0;
}
`
export const RoutesWrapper = styled.div`
display: flex;
align-items: center;
gap: 8px;
`
export const Wrapper = styled.div`
display: flex;
align-items: center;
gap: 8px;
/* flex-wrap: wrap; */
width:100%;
min-height:40px;
border-radius:0.25rem;
${(props)=>props?.iseditmode==="true" &&`background:#EFEFEF;`}
&:hover,
&:focus
{
	background:#EFEFEF;
}

`
