import { GenericButton, Input } from "shared/ui-basics/index";
import { CustomEditor } from "shared/components/CustomEditor";
import { EditorProvider } from "shared/dynamic-imports";
import { EMPTY_GUID } from "@utils/helpers";
import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

const getDefaultItem = () => {
	return {
		name: "",
		description: "",
		id: EMPTY_GUID,
	};
};
export const NameDescriptionModal = ({ selectedItem, title, buttonText, handleAction, isOpen, onClose }) => {
	const { t } = useTranslation();

	const [item, setItem] = useState(selectedItem ?? getDefaultItem());

    const handleChange = (attribute, value) => {
		setItem((prevState) => ({
			...prevState,
			[attribute]: value,
		}));
	};

	return (
		<>
			<Modal fade={false} isOpen={isOpen} centered={true} backdrop="static" toggle={onClose}>
				<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
				<ModalBody>
					<EditorProvider>
						<Form>
							<Wrapper>
								<LabelWrapper for="icon-name">{t("NAME")}</LabelWrapper>
								<Input
									variant="default"
									type="text"
									id="icon-name"
									value={item?.name}
									onChange={(e) => handleChange("name", e.target.value.truncate(100))}
									placeholder={t("ENTER_NAME")}
								/>
							</Wrapper>
							<Wrapper>
								<LabelWrapper>{t("ASPX_9")}</LabelWrapper>
								<CustomEditor html={item?.description || ""} sethtml={(value) => handleChange("description", value)} variant={"description"} />
							</Wrapper>
						</Form>
					</EditorProvider>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-end">
					<GenericButton variant="primary" onClick={() => handleAction({ item, onClose })}>
						{t(buttonText)}
					</GenericButton>
				</ModalFooter>
			</Modal>
		</>
	);
};

const Wrapper = styled.div`
	margin-bottom: 24px;
`;
const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;
