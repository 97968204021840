import { getInsightRelatedRecords, linkInsightRecords, unLinkInsightRecords, updateInsight } from "@redux/index";
import { getInsightById } from "@redux/memoizedSelectors";
import { PermissionManager } from "@utils/permissionManager";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DataLoader from "shared/components/data-loader";
import { EditorProvider } from "shared/dynamic-imports";
import { Descriptions, PropertyManager, RelationManager } from "../../journey-map/modals/PersonaInteractions/components";
import { Body, Header, OffcanvasLayout } from "../../journey-map/modals/PersonaInteractions/personaInteractions_style";
import EditableTitle from "./components/EditableTitle";

function EditInsights({ isOpen, onClose, ...props }) {
	const { t } = useTranslation();
	const { pathname, search } = useLocation();
	const { cjmIdStr, personaIdStr } = search.getAllMapsParams();
	const { stageId } = props;
	const insight = useSelector(getInsightById(props?.insightId));
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const userAccess = useSelector((state) => state?.maps?.currentUser?.roleName);
	const { editAllowed } = PermissionManager({ userAccess });
	const isEditable = !isReadOnly && editAllowed;
	const mode = isEditable ? "edit" : "viewer";

	const isStandard = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName === "Standard");

	useEffect(() => {
		if (["/edit-opportunity", "/edit-solution", "/edit-cxactions"].includes(pathname)) {
			return onClose();
		}
	}, [pathname]);

	const defaultRows = insight?.jsonProperty
		? JSON.parse(insight.jsonProperty)
		: {
				displayed: ["insightType", "persona", "linkedTouchpoints", "tags"],
				extra: ["groups", "systems", "departments"],
		};

	const updateFunction = ({ interaction, modifiedAttribNames }) => updateInsight({ modifiedInsight: interaction, modifiedAttribNames });
	const updateRows = (rows) => updateFunction({ interaction: { ...insight, jsonProperty: JSON.stringify(rows) }, modifiedAttribNames: ["json_property"] });

	return (
		<OffcanvasLayout show={isOpen} onHide={onClose} scroll={true} backdrop={true} placement="end" container={document.getElementById("modal-root")}>
			<DataLoader asyncFns={[{ asyncFn: getInsightRelatedRecords, parameter: { insightId: props?.insightId } }]}>
				<Header closeButton>
					<Offcanvas.Title />
				</Header>
				<Body>
					<EditableTitle
						classNames="mb-2"
						item={insight}
						attribute="name"
						placeholder={t("INSIGHT_NAME")}
						update={({ item, modifiedAttribNames }) => updateFunction({ interaction: item, modifiedAttribNames })}
						disabled={!isEditable}
					/>
					<EditorProvider>
						<Descriptions object={insight} attribute="description" placeholder={t("INSIGHT_DESCRIPTION")} update={updateFunction} mode={mode} />
					</EditorProvider>

					<PropertyManager
						variant="insight"
						item={{ ...insight, stageId: stageId }}
						updateFunction={updateFunction}
						linkFunction={linkInsightRecords}
						unlinkFunction={unLinkInsightRecords}
						updateRows={updateRows}
						defaultRows={defaultRows}
						mode={mode}
						recordType="cem_insight"
						pathToPersonas="insights.current_.cjmPersonasData"
					/>
					<RelationManager
						stageId={stageId}
						item={insight}
						updateFunction={updateFunction}
						mode={mode}
						variant="insight"
						defaultKey={isStandard ? "JS_71" : "OPPORTUNITIES"}
						dataLoading={cjmIdStr && personaIdStr ? false : true}
						onClose={onClose}
					/>
				</Body>
			</DataLoader>
		</OffcanvasLayout>
	);
}

EditInsights.displayName = "EditInsights";
EditInsights.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};
export default EditInsights;
