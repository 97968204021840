import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Flex, FlexAlignCenter, FlexBetween, FlexBetweenAlignCenter, FlexColumnGap, Input } from "shared/ui-basics/index";
import { Spinner } from "shared/components/spinners/Spinner.js";
import { useAsyncs } from "shared/hooks/index";
import { encode } from "@utils/helpers.js";
import * as Style from "../../../shared/cx-components/style.js";
import styled, { css } from "styled-components/macro";
import { v4 as uuid } from "uuid";
import { FindAssessmentById } from "@redux/memoizedSelectors.js";
import { GetCxProgram, getAllCxAssessments, getCxProgramUsers, updateCxAssessment } from "@redux/index.js";
import { CustomBreadCrumb } from "shared/ui-basics/ui-components/CustomBreadCrumb.js";

export default function CxAssessmentPage() {
	const { search } = useLocation();
	const cxProgramId = search.getCxProgramID();

    const userId = useSelector((state) => state.auth.userInfo.UserId);
	const [loading, setLoading] = useState(true);

    const asyncFns = [
        { asyncFn: getCxProgramUsers, parameter: { cxProgramId, userId: userId } },
		{ asyncFn: getAllCxAssessments, parameter: { cxProgramId: cxProgramId } },
		{ asyncFn: GetCxProgram, parameter: { CxProgramIdStr: cxProgramId } },
	];

	useAsyncs({
		asyncFns,
		setLoading,
	});

	return <>{loading ? <Spinner /> : <CxAssessment />}</>;
}

const CxAssessment = () => {
	const { t } = useTranslation();

	const { search } = useLocation();

	const assessmentId = search.getId();
	const cxProgramId = search.getCxProgramID();

	const selectedAssessment = useSelector(FindAssessmentById(assessmentId));
	const roleName = useSelector((state) => state.cxProgram?.currentUser?.roleName)?.toLowerCase();
	const [assessment, setAssessment] = useState(selectedAssessment);
	const isViewer = roleName === "viewer";

	const competencies = ["proposition", "readiness", "journey", "effort", "functionality", "capability", "emotion", "advocacy", "measurement", "roi"];

	const getMaturity = (score) => {
		if (score < 21) return "MATURITY_1";
		if (score < 36) return "MATURITY_2";
		if (score < 46) return "MATURITY_3";
		if (score < 61) return "MATURITY_4";
		if (score < 81) return "MATURITY_5";
		if (score <= 100) return "MATURITY_6";
	};

	const handleSelect = (competence, value) => {
		if (assessment[competence] === value) value = 0;
		const newScore = assessment.score - assessment[competence] + value;
		setAssessment((prevState) => ({
			...prevState,
			[competence]: value,
			score: newScore,
		}));
		updateCxAssessment({ modifiedAttribNames: [competence, "score"], modifiedCxAssessment: { ...assessment, [competence]: value, score: newScore }, cxProgramId });
	};

	const handleChange = (e) => {
		const { value, name } = e.target;
		if (name === "date") {
			const updatedAssessment = { ...assessment, [name]: new Date(value).toISOString() };
			updateCxAssessment({ cxProgramId, modifiedCxAssessment: updatedAssessment, modifiedAttribNames: [name] });
			return setAssessment(updatedAssessment);
		}
		setAssessment({ ...assessment, contactName: value.truncate(100) });
	};

	const handleBlur = (modifiedAttribNames) => {
		updateCxAssessment({ modifiedAttribNames, modifiedCxAssessment: { ...assessment, contactName: encode(assessment.contactName) }, cxProgramId });
	};

	return (
		<Style.ContainerPage fluid>
			<Flex>
				<div className="w-100">
					<Style.Header>
						<FlexBetween className="w-100">
							<CustomBreadCrumb paths={[t("CX-PROGRAM"), assessment?.contactName]} />
						</FlexBetween>
						<Style.MainTitle>
							<FlexBetweenAlignCenter>
								<FlexAlignCenter>
									<FlexColumnGap gap="16">
										<Labels>{t("CONTACT_NAME")}</Labels>
										<Wrapper>
											<Input variant="default" value={assessment?.contactName || ""} onChange={handleChange} onBlur={() => handleBlur(["contactName"])} disabled={isViewer} />
										</Wrapper>
									</FlexColumnGap>
									<FlexColumnGap className="ms-2" gap="16">
										<Labels> {t("DUE_DATE")}</Labels>
										<Input
											variant="default"
											name="date"
											id="Date"
											placeholder="date placeholder"
											value={assessment?.date?.formatDate()}
											type="date"
											onChange={handleChange}
											onBlur={() => handleBlur(["date"])}
											disabled={isViewer}
										/>
									</FlexColumnGap>
								</FlexAlignCenter>
								<FlexColumnGap gap="16">
									<Labels>{t("SCORE")}</Labels>
									<Wrapper>
										<FlexAlignCenter>
											<Score>{assessment?.score}</Score>
											<Maturity className="ms-2">{t(getMaturity(assessment?.score))}</Maturity>
										</FlexAlignCenter>
									</Wrapper>
								</FlexColumnGap>
							</FlexBetweenAlignCenter>
						</Style.MainTitle>
					</Style.Header>
					<MainContainer className="border">
						<Instructions>{t("RATE_ASSESSMENT_INSTRUCTION")}</Instructions>
						{competencies?.map((competence, i) => (
							<CompetenceCard key={uuid()} index={i + 1} assessment={assessment} competence={competence} handleSelect={handleSelect} isViewer={isViewer} />
						))}
					</MainContainer>
				</div>
			</Flex>
		</Style.ContainerPage>
	);
};

const CompetenceCard = ({ index, assessment, competence, handleSelect, isViewer }) => {
	return (
		<CardContainer className="p-5">
			<CardTitle>{index + ". " + t(competence?.toUpperCase())?.toUpperCase()}</CardTitle>
			<FlexBetweenAlignCenter className="w-100">
				{Array(10)
					.fill(1)
					.map((el, i) => (
						<Selector key={uuid()} selected={assessment?.[competence] === i + 1} onClick={() => handleSelect(competence, i + 1)} disabled={isViewer}>
							{i + 1}
						</Selector>
					))}
			</FlexBetweenAlignCenter>
			<FlexBetweenAlignCenter className="w-100">
				<RatingLabel>{t(competence?.toUpperCase() + "_LOW")}</RatingLabel>
				<RatingLabel>{t(competence?.toUpperCase() + "_HIGH")}</RatingLabel>
			</FlexBetweenAlignCenter>
		</CardContainer>
	);
};

const Wrapper = styled.div`
	max-height: 36px !important;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: calc(100vh - 220px);
	overflow: auto;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	padding: 1rem 2rem;
	background-color: #ffffff;
`;

const Instructions = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const CardContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-bottom: 1px solid #c4c4c4;
`;

const CardTitle = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
`;

const RatingLabel = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const Selector = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: #efefef;
	color: #605f60;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.selected === true &&
		css`
			background-color: #f96209;
			color: #ffffff;
		`}
	${(props) =>
		props.disabled === true &&
		css`
			cursor: not-allowed;
			pointer-events: none;
		`}
`;

const Labels = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const Score = styled.div`
	color: var(--primary, #f96209);
	font-family: 'Oswald', sans-serif;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
`;

const Maturity = styled(Labels)`
	width: 367px;
`;
