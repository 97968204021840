import { Translation } from "react-i18next";
import { GenericButton } from "shared/ui-basics/index";
import { ActionConfirmModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";
import { type } from "jquery";
import { unlinkRecords } from "@redux/index";

export const ActionLink = (options, entityName, entityId) => {
	let id;
	let unLinkMessage = "UNLINK";
	const isJourneyMapUpdate = entityName === "cem_customerjourney";
	switch (entityName) {
		case "cem_customerjourney":
			id = options?.cjmId;
			unLinkMessage = "UNLINK_MAP";

			break;
		case "cem_persona":
			id = options?.personaId;
			unLinkMessage = "UNLINK_PERSONA";
			break;
		case "cem_cxopportunity":
			id = options?.id;
			unLinkMessage = "UNLINK_OPPORTUNITY";

			break;
		case "cem_cxsolution":
			id = options?.id;
			unLinkMessage = "UNLINK_SOLUTION";
			break;
		case "cem_cxactiontask":
			id = options?.id;
			unLinkMessage = "UNLINK_CXACTION";

			break;
		case "cem_cxproject":
			id = options?.id;
			unLinkMessage = "UNLINK_PROJECT";
			break;

		default:
	}

	const unlink = (bool = false) => {
		const isBool = typeof bool === "boolean" ? bool : false;
		unlinkRecords({ cxProgramId: entityId, entityName, ids: [id], unlinkRelated: isBool });
	};

	const [showConfirmAssociatedObjects] = useModal(ActionConfirmModal, {
		bodyContent: "UNLINKED_ASSOCIATED_OBJECTS",
		Action: unlink,
		actionBtnText: "UNLINK",
		secondBtnText: "NO",
		beforeClose: unlink,
	});

	return (
		<div className="d-flex justify-content-end">
			<Translation>
				{(t) => (
					<GenericButton
						variant="light-link"
						disableIfEllipsis={false}
						tooltip
						tooltipText={t(unLinkMessage)}
						onClick={isJourneyMapUpdate ? showConfirmAssociatedObjects : unlink}
						icon={"UNLINK"}
					/>
				)}
			</Translation>
		</div>
	);
};
