import { updateUsersTeams } from "@redux/index";
import { RecordsSelector } from "features/workspace/components/others/records-selector";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, GenericButton, IconDispatcher, Label } from "shared/ui-basics";
import styled from "styled-components/macro";

export const BulkUpdateTeams = ({ ids, onSuccess, setDisableBulkActions, successToast }) => {
	const { t } = useTranslation();
	const users = useSelector((state) => state.settings.users);
	const authorizedUsers = useSelector((state) => state.settings?.authorizedUsers);
	const allTeamsObj = useSelector((state) => state.settings?.teams);
	const allTeams = allTeamsObj ? Object.values(allTeamsObj) : [];

	const [selected, setSelected] = useState([]);
	const [show, setShow] = useState(false);

	const selectedUsers = users.filter((u) => ids.includes(u.id));
	const selectedAuthorizedUsersIds = authorizedUsers.filter((au) => selectedUsers.find((u) => au?.domainName?.toLowerCase() === u?.loginName?.toLowerCase())).map((au) => au?.id);

	const onSave = () => {
		setShow(false);
		setDisableBulkActions(true);
		updateUsersTeams({ userIds: selectedAuthorizedUsersIds, idsToAdd: selected.map((s) => s.id), idsToRemove: [], onSuccess, displayToast: "UPDATE", toastParams: { success: successToast } });
	};

	const handleToggle = (isOpen) => {
		setShow(isOpen);
	};

	const handleSelect = ({ record }) => {
		setSelected([...selected, record]);
	};

	const handleRemove = ({ record }) => {
		setSelected(selected?.filter((s) => s.id !== record.id));
	};

	const renderCustomComponent = ({ record }) => <Team>{record?.name}</Team>;

	return (
		<DropDownContainer drop="up" show={show} onToggle={handleToggle}>
			<Dropdown.Toggle id="dropdown-custom-components" as={Button} variant="light-bulk">
				<ActionContainer>
					<div className="me-2">{IconDispatcher("users")}</div>
					<div>{t("TEAM")}</div>
				</ActionContainer>
			</Dropdown.Toggle>
			<MenuContainer>
				<WorspacesContainer className="p-3">
					<Label className="mb-2">{t("SELECT_TEAMS")}</Label>
					<RecordsSelector allRecords={allTeams} selectedRecords={selected} renderCustomComponent={renderCustomComponent} onSelect={handleSelect} onRemove={handleRemove} />
				</WorspacesContainer>
				<ButtonContainer>
					<GenericButton variant="primary" onClick={onSave}>
						{t("SAVE")}
					</GenericButton>
				</ButtonContainer>
			</MenuContainer>
		</DropDownContainer>
	);
};

BulkUpdateTeams.displayName = "BulkUpdateTeams";

const DropDownContainer = styled(Dropdown)`
	.dropdown-toggle::after {
		display: none;
	}
	.dropdown-menu {
		padding: 0px;
		margin: 0px;
	}
`;

const MenuContainer = styled(Dropdown.Menu)`
	width: 392px;
	transform: translate3d(0px, -54px, 0px) !important;
`;
const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`;

const WorspacesContainer = styled.div`
	border-bottom: 1px solid #efefef;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 16px;
`;

const Team = styled.div``;
