import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { IconDispatcher } from "shared/Icons";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import { useSelector } from "react-redux";

export default function ProjectMenu({ activeLink }) {
	const { t } = useTranslation();
	const currentProject = useSelector((state) => state.projects.current_project);

	const relatedItems = [
		{ icon: "OPPORTUNITY", text: t("OPPORTUNITIES"), n: currentProject?.linkedOpps?.length },
		{ icon: "SOLUTION", text: t("SOLUTIONS"), n: currentProject?.linkedSols?.length },
		{ icon: "ACTION", text: t("JS_74"), n: currentProject?.linkedActions?.length },
		{ icon: "CX-PROGRAM", text: t("CX_PROGRAMS"), n: currentProject?.linkedCxProgram?.length },
		{ icon: "CJM", text: t("ASPX_97"), n: currentProject?.linkedCjms?.length },
		{ icon: "PERSONA", text: t("ASPX_98"), n: currentProject?.linkedPersonas?.length },
	];

	//create a component, put in map
	return (
		<MenuContainer>
			<CustomLinkComponent activeLink={activeLink} currentLink="projectGeneral" icon="NOTEBOOK" label="ASPX_185" />
			<CustomLinkComponent activeLink={activeLink} currentLink="projectBusinessCase" icon="BRIEFCASE" label="BUSINESS_CASE" />
			<>
				<CustomLinkComponent activeLink={activeLink} currentLink="projectRelatedItems" icon="PACKAGE" label="RELATED_ITEMS" />
				<RelatedItemsContainer>
					{relatedItems?.map((item, index) => (
						<ItemCard key={index} icon={item?.icon} text={item?.text} n={item?.n} />
					))}
				</RelatedItemsContainer>
			</>
		</MenuContainer>
	);
}

const CustomLinkComponent = ({ activeLink, currentLink, icon, label }) => {
	const { t } = useTranslation();

	const handleClick = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<CustomLink active={activeLink === currentLink} onClick={() => handleClick(currentLink)}>
			<FlexAlignJustifyCenter>
				<div className="ms-4 me-2">{IconDispatcher(icon)}</div>
				<div>{t(label)}</div>
			</FlexAlignJustifyCenter>
		</CustomLink>
	);
};

const ItemCard = ({ icon, text, n }) => {
	return (
		<RelatedItemContainer>
			<FlexAlignJustifyCenter gap="10">
				<IconContainer>{IconDispatcher(icon, "", {style:'light'})}</IconContainer>
				<div>{text}</div>
			</FlexAlignJustifyCenter>
			<CustomBadge pill>
				<div>{n}</div>
			</CustomBadge>
		</RelatedItemContainer>
	);
};

const MenuContainer = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const CustomLink = styled.div`
	display: flex;
	align-items: center;
	height: 2rem;
	border-radius: 4px;

	margin-bottom: 1rem;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.active === true &&
		css`
			background: #efefef;
			color: #333;
			font-weight: 500;
		`}
`;

const RelatedItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-left: 4rem;
`;

const RelatedItemContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
`;

const CustomBadge = styled.div`
	min-width: 20px;
	height: 20px;
	border-radius: 20px;
	background: #d2eefb !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #605f60;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	padding: 2px 4px;
`;

const IconContainer = styled.div`
	font-size: 16px;
`;
