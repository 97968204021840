import styled, { css } from 'styled-components/macro'
import { ActionBox } from './input'
import img from '@Assets/images/quote-bg.svg';
import { InformationType } from '@utils/optionsSets/OptionSets';


export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;

    /* border-radius: 0.25rem; */
    /* box-shadow: 1px 1px 3px rgba(33,33,33,.3);  */
   /* overflow: hidden;  DO NOT ADD THIS LINE EVER - it cut the toolbar of the editor*/
    overflow: ${props => props.allowSectionsOverFlow ? 'none' : 'hidden'} ; // this hide the custom Editor but also allow the cursor to be hidden if we add many text. Simon.B

    min-height: calc(220px - 0.5rem);
    width:100%;
    border-radius:4px;
    border:1px solid transparent;

    ${props => props.focus && `animation: name 2s ease-out;`}
    @keyframes name {
     100%{border: 1px solid #F96209;};
    }
    background: ${props => props?.background?.length === 0 ? '#FFFFFF' : props.background};

    ${props => props.type === InformationType.Quote && ` background-image:url(${img}) ;
    background-repeat:no-repeat;
    background-size:20%;
    background-position:50% 20%;
    `}
    &:hover ${ActionBox}{
    	display: flex;
    };
    input{
        background: ${props => props.background};

    }
    #img {
        background-color: #fff;
        width: 100%;
        max-height: 266px;
        object-fit:contains;
    }
    :hover{
        border: 1px solid #C4C4C4;
        #addTargetButton {
            visibility: visible;
        }
    }
    ${props => props.strategy && css`
    padding:0.8rem;`}
`

export const SettingPageContainer = styled.div`
width: 100%;
height: 100%;
overflow: auto;
padding: 1rem;

`

export const Handle = styled.div`
position:absolute;
top:25%;
left:-10px;

`
export const SwimlanesSidebar = styled.div`
display: flex;
/* justify-content: space-evenly; */
align-items: start;
flex-direction: column;
background: #F9FBFC;
min-width: 212px;
max-width: 212px;
display: ${props => props.open ? 'flex' : 'none'};
transition: width 0.5s ease-in-out;
position: sticky;
left: 0;
z-index: 2;
:hover {
    z-index: 3;
}
.expand {
    visibility: hidden !important;
}
:hover .expand {
    visibility: inherit  !important;
}
${props => props.stage === 'true' && css`
height: 100%;`}
border-right: 1px solid #C4C4C4;

`