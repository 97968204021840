import { getInitials } from "@utils/helpers";
import { modes } from "@utils/optionsSets/OptionSets";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomTooltip from "shared/components/CustomTooltip";
import { FlexAlignCenter } from "shared/ui-basics";
import styled, { css } from "styled-components";
import InitialsBubble from "./initials-bubble";

export const UserDetails = ({ user, desactivateClick = false, tooltipText, size = "m" }) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/edit-user?id=${user?.id}&mode=${modes.update}`);
	};
	const name = user?.firstName + " " + user?.lastName;

	const isTooltipDisabled = () => {
		if (tooltipText) return false;
		if (user?.subject?.length <= 21) return true;
		if (!user?.subject) return true;

		return false;
	};

	const getTooltipText = () => {
		if (tooltipText) return tooltipText;
		return user?.subject;
	};

	return (
		<CustomTooltip text={getTooltipText()} disabled={isTooltipDisabled()}>
			<Container disabled={desactivateClick} className="w-100" onClick={desactivateClick ? () => {} : handleClick}>
				<FlexAlignCenter gap="10">
					<InitialsBubble initials={getInitials({ name })?.slice(0, 2)} size={size} />
					<Details size={size}>
						<FullName size={size}>{name}</FullName>
						<Email size={size}>{user?.loginName}</Email>
					</Details>
				</FlexAlignCenter>
			</Container>
		</CustomTooltip>
	);
};

UserDetails.displayName = "FormSettings";
export default UserDetails;

const Container = styled.div`
	white-space: nowrap;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	:hover {
		cursor: pointer;
	}
	${(props) =>
		props.disabled &&
		css`
			cursor: default;
			:hover {
				cursor: default;
			}
		`}
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	${(props) =>
		props.size === "l" &&
		css`
			gap: 8px;
		`}
`;

const FullName = styled.div`
	color: #333;
	font-weight: 400;
	${(props) =>
		props.size === "m" &&
		css`
			font-size: 14px;
		`}
	${(props) =>
		props.size === "l" &&
		css`
			font-size: 24px;
		`}
`;

const Email = styled.div`
	color: #605f60;
	${(props) =>
		props.size === "m" &&
		css`
			font-size: 12px;
		`}
	${(props) =>
		props.size === "l" &&
		css`
			font-size: 14px;
		`}
`;
