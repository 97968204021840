import { loadMainData } from "@redux/pageReducer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import EditTitle from "../../../shared/components/EditableText";
import { updateCxProgram } from "@redux/index";

export const CxProgramName = (cxProgram, rename, setRename, openToClick) => {
	const [cxProgramName, setName] = useState(cxProgram?.name);
	const { pathname, search } = useLocation();
	const folderId = search.getFolderId();
	const desactivateClick = !openToClick;

	useEffect(() => {
		setName(cxProgram?.name);
	}, [cxProgram.name]);

	const loadData = () => {
		loadMainData({ strategyId: cxProgram?.strategyId });
	};

	const updateFunction = (modifiedCxProgram, modifiedAttribNames) => updateCxProgram({ modifiedCxProgram, modifiedAttribNames });

	return (
		<div className="d-flex">
			{rename !== cxProgram.id && (
				<Redirect
					className="d-flex align-items-center"
					target={pathname === "/libraries" ? "_blank" : ""}
					onClick={loadData}
					desactivateclick={`${desactivateClick}`}
					to={`/EditCxProgram?programid=${cxProgram.id}${folderId ? `folderid=${folderId}` : ""}&origin=1`}
					title={cxProgramName}>
					<StyledName id={"input" + cxProgram.id}>{cxProgramName.defaultPlaceholder("CX_PROGRAM_NAME")}</StyledName>
				</Redirect>
			)}
			{rename === cxProgram.id && (
				<EditTitle obj={cxProgram} obj_id={cxProgram.id} obj_name={cxProgramName} updateObject={updateFunction} setName={setName} setRename={setRename} nameAttribute={"name"} />
			)}
		</div>
	);
};

const Redirect = styled(Link)`
	text-decoration: none;
	${(props) => props.desactivateclick === "true" && "pointer-events: none; cursor:default;"}
`;

const StyledName = styled.div`
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 100%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: pointer;
	font-weight: 400 !important;
	text-overflow: ellipsis;
	white-space: normal;
	/* @media screen and (max-width: 1290px) {
		max-width: 8rem;
	} */
`;
