import PickListData from '@utils/optionsSets/PickListData';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Label, Row } from 'reactstrap';
import { GenderDropdownSelector, GenericButton, IconDispatcher, Input } from 'shared/ui-basics/index';
import DisplayState from '../../../../shared/cx-components/DisplayState';
import * as S from './PersonaCreationStyles';



export default function PersonaDetails({ previousStep, nextStep, newPersona, setNewPersona }) {
    const genderOptions = PickListData('gender')

    const { t } = useTranslation()

    const handleChange = (attribute, value) => {
        setNewPersona({ ...newPersona, [attribute]: value })
    }

    const handleNextClick = () => {
        if (newPersona?.name === '') return toast.info(t('NAME_EMPTY'))
        nextStep()
    }

    const checkAge = (age) => {
        if (age < 0) return String(0)
        else if (age > 120) return String(120)
        else return String(age)
    }
    const updateState = (object) => {
        handleChange("isFuture", object?.isFuture?.setEntityCurrentState())
	};
    return (
        <React.Fragment>
            <S.BackButton variant='primary-link' onClick={previousStep}>{IconDispatcher('ARROW_LEFT', "me-1")}{t('ASPX_132')}</S.BackButton>
            <Row className='py-5'>
                <Col>
                    <S.DetailsWrapper>
                        <S.Title>{t("PERSONA_GENERAL_DETAILS")}</S.Title>
                        <S.InputWrapper>
                            <Label className='d-flex'>
                                {t('NAME')}
                                <S.Mandatory className='ms-2'>{"(" + t("MANDATORY") + ")"}</S.Mandatory>
                            </Label>
                            <Input variant='default' value={newPersona?.name} onChange={(e) => handleChange("name", e.target.value.truncate(100))} autoFocus />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <Label>{t('ASPX_55')}</Label>
                            <Input variant='default' value={newPersona?.occupation} onChange={(e) => handleChange("occupation", e.target.value.truncate(100))} />
                        </S.InputWrapper>
                        <div className='d-flex justify-content-between'>
                            <S.InputWrapper>
                                <Label>{t('ASPX_56')}</Label>
                                <Input type="number" variant='default' value={newPersona?.age} onChange={(e) => handleChange("age", checkAge(e.target.value))} />
                            </S.InputWrapper>
                            <S.InputWrapper>
                                <Label>{t('ASPX_58')}</Label>
                                <S.Test><GenderDropdownSelector value={newPersona?.gender} genderOptions={genderOptions} handleChange={(e) => handleChange("gender", e.value)} /></S.Test>
                            </S.InputWrapper>
                        </div>
                        <S.InputWrapper>
                            <Label>{t('JS_77')}</Label>
                            <DisplayState  object={newPersona}  updateFunction={updateState} disable={false}/>
                        </S.InputWrapper>
                    </S.DetailsWrapper>
                </Col>
                <Col className='align-self-center'>
                    <S.WizardBag>{IconDispatcher("WIZARD-BAG", "", { width: '280px' , height:'250px' })}</S.WizardBag>
                </Col>
            </Row>
            <Row>
                <div className='d-flex justify-content-center'>
                    <GenericButton variant="primary" size="m" className="" onClick={handleNextClick}>
                        {t("ASPX_127")}{IconDispatcher("ANGLE-RIGHT", "ms-2")}
                    </GenericButton>
                </div>
            </Row>
        </React.Fragment>

    )
}

