import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GenericButton, PrimeInput } from 'shared/ui-basics/index'
import { GenericCard, MapCard } from './ChartFilterCards'


export function ChartFilter({items, statuses, filterParams, handleFilterChange, handleResetFilter}){
    const {t} = useTranslation()

    const groups = useSelector(state => state.libraries.group)
    const allMaps = useSelector((state) => state.maps.all_maps)

    // We use only the map related to a solution/opportunity
    const mapsIdsRelatedToItem = [...new Set(items.map(item => item.mapId))]
    const mapsRelatedToItem = allMaps.filter(map => mapsIdsRelatedToItem.includes(map.cjmId))

    const [searchValue, setSearchValue] = useState('');
    const [filteredMaps, setFilteredMaps] = useState(mapsRelatedToItem);

    const handleSearchChange = e => {
        const value = e.target.value
        setSearchValue(value)
        if (!value) setFilteredMaps(mapsRelatedToItem)
        else setFilteredMaps(mapsRelatedToItem.filter(map => map?.name?.toLowerCase().includes(value.toLowerCase())))
    }

    return (
        <FilterWrapper className='px-4 py-2'>
            <Header className='mb-3'>{t('FILTER_BY')}</Header>
            <StatusAndGroup>
                <Status>
                    <Label>{t('ASPX_222')}</Label>
                    <GenericList className='pe-2'>
                        {statuses?.map(status => <GenericCard key={status.id} item={status} filterParams={filterParams} filterAttribute={"statusIds"} handleFilterChange={handleFilterChange}/>)}
                    </GenericList>

                </Status>
                <Group>
                    <Label>{t('GROUP')}</Label>
                    <GenericList className='pe-2'>
                        {groups?.map(group => <GenericCard key={group.id} item={group} filterParams={filterParams} filterAttribute={"groupIds"} handleFilterChange={handleFilterChange}/>)}
                    </GenericList>

                </Group>
            </StatusAndGroup>
            <JourneyMaps className='mt-2'>
                <Label>{t('JOURNEY_MAP')}</Label>
                    <Search className="mb-2 pe-3 p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <PrimeInput id="search-bar" value={searchValue} onChange={handleSearchChange} placeholder={t('SEARCH')+" ..."} height={'small'}  />
                    </Search>
                <GenericList className='pe-2'>
                    {filteredMaps?.map(map => <MapCard key={map.cjmId} map={map} filterParams={filterParams} handleFilterChange={handleFilterChange}/>)}
                </GenericList>
            </JourneyMaps>
            <Footer>
                <GenericButton variant="clean" onClick={handleResetFilter}>Clear All</GenericButton>
            </Footer>
        </FilterWrapper>
    )
}


const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    height: 600px;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
`

const Header = styled.div`
    font-weight: 400;
    font-size: 20px;
    color: #605F60;
`

const StatusAndGroup = styled.div`
    display: flex;
    gap: 20px;
`

const JourneyMaps = styled.div`
`

const Status = styled.div`
    flex: 1 1 0;
    width: 0;
`

const GenericList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 180px;
    overflow: scroll;
`

const Group = styled.div`
    flex: 1 1 0;
    width: 0;
`

const Label = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #605F60;
    margin-bottom: 16px;
`

const Search = styled.div`
    width: 100%;
`

const Footer = styled.div`

`

