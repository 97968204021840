import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IconDispatcher } from 'shared/ui-basics/index';
import * as S from "./Style";
import Tags from "shared/cx-components/Tags";
import { t } from "i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const CXNumbers = ({ cxProgramId }) => {
	const progFromList = useSelector((s) => s.cxProgram.all_cxPrograms);
	const prog = useSelector((s) => s.cxProgram.current_program);
	let CxData;

	if (cxProgramId) {
		CxData = progFromList.find((cx) => cx.id === cxProgramId);
	} else {
		CxData = prog;
	}

	const { t } = useTranslation();

	return (
		<>
			{CxData.CxNumbers && (
				<S.StretchCol xs={cxProgramId ? 12 : 7} xxl={cxProgramId ? 12 : 4} className=" py-3">
					{CxData.CxNumbers.map((cx, i) => {
						const linkedItems = CxData[cx.linkName];
						return (
							<S.DivWrapperCol4 key={i} first={i === 0} className="px-3">
								<S.LabelWrapper small="true">
									{IconDispatcher(cx.icon, "me-1")}
									{t(cx.title)}
								</S.LabelWrapper>
								<S.Number color={cx?.color}>{linkedItems?.length}</S.Number>
							</S.DivWrapperCol4>
						);
					})}
				</S.StretchCol>
			)}
		</>
	);
};

export const Title = ({ path, updateFunction, itemId, icon, isEditable, isContributor, recordType }) => {
	const hideForPdf = useLocation().search.hideForPdf();

	const item = useSelector((state) => _.get(state, path));
	const [showInput, setShowInput] = useState(false);

	const [inputValue, setInputValue] = useState(item?.name || "");
	const [object, setObject] = useState(item);
	const maxLines = 2;
	const defaultName = "cem_cxproject" === recordType ? t("PROJECT_NAME") : t("CX_PROGRAM_NAME");
	const colSize = recordType === "cem_cxprogram" ? { xs: 5, xxl: 8 } : { xs: 6, xxl: 8 };
	const updateName = () => {
		setShowInput(false);
		if (inputValue !== item) {
			const name = inputValue === "" ? `${defaultName}` : inputValue;
			setInputValue(name);
			updateFunction({ id: itemId, name: name }, ["name"]);
		}
	};

	return (
		<S.CenterCol xs={colSize.xs} xxl={colSize.xxl} smallscreenupdate="true">
			<S.PersonaDetails className="ps-4">
				<S.TitleContainer>
					{icon && IconDispatcher(icon, "me-4")}
					{showInput ? (
						<S.HeaderInput
							variant="default"
							type={"text"}
							rows={maxLines}
							onChange={(e) => setInputValue(e.target.value)}
							onBlur={updateName}
							disabled={!isEditable}
							value={inputValue}
							autoFocus
							placeholder={defaultName}
						/>
					) : (
						<S.ItemNameWrapper onClick={() => setShowInput(true)}>{inputValue.defaultPlaceholder(defaultName)}</S.ItemNameWrapper>
					)}
				</S.TitleContainer>
				<Tags object={object} setObject={setObject} recordType={recordType} disabled={hideForPdf || !isEditable} isContributor={isContributor} />
			</S.PersonaDetails>
		</S.CenterCol>
	);
};
