import { updateShowRoute } from "features/index";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GenericSwitch from "shared/ui-basics/buttons";
import { IconDispatcher } from "shared/ui-basics/index";
import styled from "styled-components/macro";

export function SwitchRouteCard({ cjmIdStr, text, icon, itemSelectorPath, isMain, isEditable = true }) {
	const { t } = useTranslation();

	const showRoute = useSelector((state) => _.get(state, itemSelectorPath));
	const [toggle, setToggle] = useState(showRoute);

	const handleToggle = () => {
		updateShowRoute({ cjmIdStr, showRoute: !toggle, isMain });
		setToggle(!toggle);
	};

	return (
		<RouteCard className="p-2">
			<RouteIcon className="p-3">{IconDispatcher(icon)}</RouteIcon>
			<SwitchWrapper className="p-3">
				<Text>{t(text)}</Text>
				<GenericSwitch isChecked={toggle} handleToggle={handleToggle} isdisabled={!isEditable} />
			</SwitchWrapper>
		</RouteCard>
	);
}

const RouteCard = styled.div`
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const SwitchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Text = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const RouteIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #c4c4c4;
`;
