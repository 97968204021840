import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import NavMenu from "../../features/navigation/navigation-component";
import React from "react";

function GlobalLayout({ children }) {
	var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
	return (
		<div style={{ display: "flex", overflow: "hidden" }}>
			<NavMenu />
			<Container isFirefox={isFirefox} id="GeneralLayout">
				{children}
			</Container>
		</div>
	);
}
export default GlobalLayout;

const Container = styled.div`
	margin-top: 40px;
	width: 100%;
	background-color: #FFFFFF;
	height: calc(100vh - 40px);
	overflow: ${(props) => (props.isFirefox ? "scroll" : "overlay")};
	z-index: 100;
	//${(props) => (props.displayFlex ? "display:flex;" : "")};
`;
