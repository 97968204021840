import { Skeleton } from 'primereact/skeleton'
import React from 'react'
import * as Style from './Style'
import { FlexAlignCenter } from 'shared/ui-basics/index'

export default function MapLoadingCard() {
  return (
    <Style.CardLoading>
    <Style.Center >
    <Skeleton size="2.5rem" className="mr-2"></Skeleton>
    </Style.Center>
    <Style.Center>
        <div>
        <Skeleton height="1rem" width='200px' className="my-1"></Skeleton>
        <Skeleton height="1rem" width='250px' className="mb-2"></Skeleton>
     </div>
    </Style.Center>
    <Style.Center>
        <div>
        <Skeleton height="30px" width='30px' shape="circle" className="my-1"></Skeleton>
     </div>
    </Style.Center>
    <Style.Center>
        <div>
        <Skeleton height="1.5vw" width='4vw' className="my-1"></Skeleton>
     </div>
    </Style.Center>
    <Style.Center>
        <div>
        <Skeleton height="1vw" width='100px' className="my-1"></Skeleton>
     </div>
    </Style.Center>
    <Style.Center>
        <div>
        <Skeleton height="1rem" width='170px' className="my-1"></Skeleton>
        <Skeleton height="1rem" width='250px' className="mb-2"></Skeleton>
     </div>
    </Style.Center>
    <Style.Center>
        <div>
        <Skeleton height="1.5vw" width='1.5vw' shape="circle" className="my-1"></Skeleton>

     </div>
    </Style.Center>
    <Style.Center>
        <FlexAlignCenter gap={1}>
        <Skeleton height="5px" width='5px' className="my-1"></Skeleton>
        <Skeleton height="5px" width='5px' className="my-1"></Skeleton>
        <Skeleton height="5px" width='5px' className="my-1"></Skeleton>

     </FlexAlignCenter>
    </Style.Center>
</Style.CardLoading>
  )
}
