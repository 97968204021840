import { addMultiSwimlanes } from "features/index";
import { GenericButton } from "shared/ui-basics/buttons";
import { Input } from "shared/ui-basics/input";
import { FlexAlignCenter, FlexColumn } from "shared/ui-basics/index";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";
import { CheckBoxWrapper } from "../../../shared/modals/library-links/Style";
import { encode, StripHtml } from "@utils/helpers";

const MoveToSwimlaneModal = memo(({ isOpen, onClose, newDefaultLane, lanes, defaultPlaceholder, moveToFunction }) => {
	const { t } = useTranslation();
	const isHaveLanes = lanes?.length > 0;
	const [selectedStep, setSelectedStep] = useState(isHaveLanes ? "select" : "new");
	const [selectedLane, setSelectedLane] = useState("");

	const moveTo = () => {
		onClose();
		moveToFunction({ newCustomSwimLaneId: selectedLane });
	};

	const SelectLaneStep = () => (
		<>
			<ModalHeader toggle={onClose}>{t("SELECT_SWIMLANES")}</ModalHeader>
			<MoveToExisting className="p-4">
				{!isHaveLanes ? <Label className="mb-2">{t("NEED_CREATE_LANE")}</Label> :
					<><Label className="mb-2">{t("MOVE_TO_EXISTING")}</Label>
						{lanes?.map((lane) => {
							const description = StripHtml(lane?.description);
							return (
								<FlexAlignCenter key={lane?.key} className="mb-4" gap="10">
									<CheckBoxWrapper type="checkbox" sizecheckbox="s" checked={selectedLane === lane?.key} onClick={(e) => e.stopPropagation()} onChange={() => setSelectedLane(lane?.key)} />
									<FlexColumn>
										<LaneName>{lane?.name ? lane?.name : defaultPlaceholder}</LaneName>
										<LaneDescription title={description}>{description}</LaneDescription>
									</FlexColumn>
								</FlexAlignCenter>
							);
						})}</>
				}
			</MoveToExisting>
			<CustomFooter>
				<GenericButton variant="light-link" icon="SQUARE_PLUS" iconClassName="me-2" onClick={() => setSelectedStep("new")}>
					{t("ASPX_113")}
				</GenericButton>
				<GenericButton variant="primary" className="text-capitalized" onClick={moveTo}>
					{t("MOVE_SELECTED")}
				</GenericButton>
			</CustomFooter>
		</>
	);

	return (
		<ModalContainer fade={false} isOpen={isOpen} toggle={onClose} size="sm" centered={true}>
			{selectedStep === "select" && <SelectLaneStep />}
			{selectedStep === "new" && <NewLaneStep setSelectedStep={setSelectedStep} newDefaultLane={newDefaultLane} moveToFunction={moveToFunction} onClose={onClose} />}
		</ModalContainer>
	);
});

const NewLaneStep = ({ setSelectedStep, newDefaultLane, moveToFunction, onClose }) => {
	const { t } = useTranslation();
	const { personaIdStr, cjmIdStr } = useLocation().search.getAllMapsParams();
	const cjmSwimlanes = useSelector((state) => state.maps?.current_map?.cjmSwimLanes);

	const [newLaneName, setNewLaneName] = useState("");

	const createNewAndMove = () => {
		onClose();
		const onSuccess = () => moveToFunction({ newCustomSwimLaneId: newDefaultLane.id });
		addMultiSwimlanes({ swimlanes: [{ ...newDefaultLane, name: encode(newLaneName) }], cjmId: cjmIdStr, personaIdStr, order: cjmSwimlanes.length, onSuccess });
	};

	return (
		<>
			<ModalHeader toggle={onClose}>{t("ASPX_113")}</ModalHeader>
			<NewSwimlane className="p-4">
				<Label className="mb-2">{t("ASPX_136")}</Label>
				<Input variant="default" name="name" value={newLaneName} onChange={(e) => setNewLaneName(e.target.value)} />
			</NewSwimlane>
			<CustomFooter>
				<GenericButton variant="light-link" icon="ARROW_LEFT" iconClassName="me-2" onClick={() => setSelectedStep("select")}>
					{t("ASPX_132")}
				</GenericButton>
				<GenericButton variant="primary" className="text-capitalized" onClick={() => createNewAndMove(onClose)}>
					{t("MOVE")}
				</GenericButton>
			</CustomFooter>
		</>
	);
};

MoveToSwimlaneModal.displayName = "MoveToSwimlaneModal";
export { MoveToSwimlaneModal };

const ModalContainer = styled(Modal)`
	max-width: 400px;
`;

const NewSwimlane = styled.div`
	border-bottom: 1px solid #c4c4c4;
`;

const MoveToExisting = styled.div`
	border-bottom: 1px solid #c4c4c4;
	max-height: 500px;
	overflow: scroll;
`;

const Label = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const LaneName = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
`;

const LaneDescription = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	display: flow;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px;
  max-width: 300px;

`;

const CustomFooter = styled(ModalFooter)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
