import { useState } from 'react';

import { encode } from "@utils/helpers";
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from 'reactstrap';
import { GenericButton } from 'shared/ui-basics/buttons';
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { encryptPassword, validateEmail, validatePassword } from '../helpers';
import { login } from "../reducer/AuthActions";
import { ButtonForgetPassword, Description, Forms, Label, LayoutAuth, LoginModal, Title } from '../view/auth-style';

const LoginStep = (props) => {

  const { t } = useTranslation()

  const [errors, setErrors] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const targetPage = useLocation().search.getTargetUrl();

  const onSubmit = (e) => {
    e.preventDefault();

    const formValues = new FormData(e.currentTarget);
    const { email, password } = Object.fromEntries(formValues.entries())

    const isValidEmail = validateEmail(email);
    const isValidPassword = validatePassword(password);

    setErrors({
      email: isValidEmail ? '' : 'Invalid email',
      password: isValidPassword ? '' : 'Password should be at least 6 characters long',
    });

    if (isValidEmail && isValidPassword) {
      const callbacks = {
        onSuccess: (response) => {
          if (response?.startsWith('https://')) {
            window.location.href = response;
          }
          setLoading(false);
        },
        onFailed: (response) =>{
          toast.info(response?.data)
          setLoading(false);
        }
      }
      const tgtUrl = targetPage ? encode(targetPage) : null;
      setLoading(true);
      login({ email, password: encryptPassword(password), tgtUrl, ...callbacks });


    }
  };

  return <LoginModal>
    <LayoutAuth gap={20}>
      <Title>{t('LOGIN')}</Title>
      <Description className='m-1'>{t('LOGIN_DESCRIPTION')}</Description>
      <Forms onSubmit={onSubmit}>
        <Form.Group md="4" controlId="validationEmail">
          <Label >{t('EMAIL')}</Label>
          <InputGroup hasValidation>
            <Form.Control type="text" name="email" isInvalid={!!errors.email} />
            <Form.Control.Feedback style={{ fontSize: '12px' }} type="invalid" tooltip>{errors.email} </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group md="4" controlId="validationPassword">
          <Label >{t('PASSWORD')}</Label>
          <InputGroup hasValidation>
            <Form.Control type="password" name="password" isInvalid={!!errors.password} />
            <Form.Control.Feedback style={{ fontSize: '12px' }} type="invalid" tooltip>{errors.password} </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <FlexBetweenAlignCenter className='mt-4'>
          <ButtonForgetPassword onClick={() => props.nextStep()}>{t('FORGET_PASSWORD')}</ButtonForgetPassword>
          <GenericButton variant='primary' className='px-5' type="submit" disabled={loading} >
          {loading && <Spinner animation="grow" size="sm" className="me-2" />}
          {t('SUBMIT')}</GenericButton>
        </FlexBetweenAlignCenter>
      </Forms>
    </LayoutAuth>
  </LoginModal>
}
export default LoginStep;