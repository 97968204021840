import { Input } from "shared/ui-basics/input";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Ripple } from "primereact/ripple";
import { Toolbar } from "primereact/toolbar";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { Range } from "react-range";
import { Resizable } from "react-resizable";
import styled from "styled-components";

export const dateFilterTemplate = (options) => {
	const handleChange = (e) => {
		options.filterCallback(e.value, options.index);
	};
	return <Calendar value={options.value} onChange={handleChange} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
};
const formatDate = (value, type, overflow = "overflow-hidden") => {
	if (!value) return null;
	if (type === "action") {
		const date = new Date(value);
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "2-digit",
			year: "numeric",
			hour12: false,
		});
	}
	const date = new Date(value);
	const displayedDate = date.toLocaleDateString("en-US", {
		month: "short",
		day: "2-digit",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: false,
	});
	return <div className={overflow}>{displayedDate}</div>;
};

export const dateBodyTemplate = (date, type, overflow) => {
	return formatDate(date, type, overflow);
};
export const PaginatorTemplate1 = ({ setFirst, currentPage, setCurrentPage }) => {
	const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

	const onPageInputKeyDown = (event, options) => {
		if (event.key === "Enter") {
			const page = parseInt(currentPage);
			if (page < 1 || page > options.totalPages) {
				setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
			} else {
				const first = currentPage ? options.rows * (page - 1) : 0;

				setFirst(first);
				setPageInputTooltip("Press 'Enter' key to go to this page.");
			}
		}
	};
	const onPageInputChange = (event) => {
		setCurrentPage(event.target.value);
	};

	return {
		layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
		PrevPageLink: (options) => {
			return (
				<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
					<span className="p-3">Previous</span>
					<Ripple />
				</button>
			);
		},
		NextPageLink: (options) => {
			return (
				<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
					<span className="p-3">Next</span>
					<Ripple />
				</button>
			);
		},
		PageLinks: (options) => {
			if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
				const className = classNames(options.className, { "p-disabled": true });

				return (
					<span className={className} style={{ userSelect: "none" }}>
						...
					</span>
				);
			}

			return (
				<button type="button" className={options.className} onClick={options.onClick}>
					{options.page + 1}
					<Ripple />
				</button>
			);
		},
		RowsPerPageDropdown: (options) => {
			const dropdownOptions = [
				{ label: 10, value: 10 },
				{ label: 20, value: 20 },
				{ label: 50, value: 50 },
				{ label: "All", value: options.totalRecords },
			];

			return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
		},
		CurrentPageReport: (options) => {
			return (
				<span className="mx-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
					Go to <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange} />
				</span>
			);
		},
	};
};
export const onCustomPage = (event, setCurrentPage, setRows, setFirst) => {
	setFirst(event.first);
	setRows(event.rows);
	setCurrentPage(event.page + 1);
};

export const PickListMultipleTemplate = (options, list) => {
	const update = (e) => {
		options.filterCallback(e.value);
	};
	return (
		<DropDownContainer>
			<MultiSelect value={options.value} options={list} itemTemplate={dropDownItemTemplate} onChange={update} optionLabel="name" placeholder="Any" className="p-column-filter" />
		</DropDownContainer>
	);
};

export const SolutionTypeFilter = (options, list) => {
	return (
		<DropDownContainer>
			<MultiSelect
				value={options.value}
				options={list}
				itemTemplate={dropDownItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				optionLabel="name"
				placeholder="Any"
				className="p-column-filter"
			/>
		</DropDownContainer>
	);
};

export const GenericFilter = (options, list) => {
	return (
		<DropDownContainer>
			<MultiSelect
				value={options.value}
				options={list}
				itemTemplate={dropDownItemTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				optionLabel="name"
				placeholder="Any"
				className="p-column-filter"
			/>
		</DropDownContainer>
	);
};

export const ResizableColumn = (props) => {
	const { onResize, ...restProps } = props;

	return (
		<Resizable width={restProps.style?.width || 0} height={0} handle={<div className="p-column-resizer" />} onResize={onResize} draggableOpts={{ enableUserSelectHack: false }}>
			<div {...restProps} />
		</Resizable>
	);
};
export const PickListSingleFilterTemplate = (options, list, placeholder) => {
	const handleChange = (e) => {
		options.filterCallback(e.value);
	};

	return (
		<DropDownContainer>
			<Dropdown optionLabel="name" value={options.value} options={list} onChange={handleChange} placeholder={placeholder} />
		</DropDownContainer>
	);
};
const dropDownItemTemplate = (option) => {
	return <NameItem>{option.name}</NameItem>;
};
const DropDownContainer = styled.div`
	max-width: 300px;
`;
const NameItem = styled.div`
	white-space: normal;

	color: #333333;
	max-width: 200px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	overflow: hidden;
	width: 150px;
`;

export const FloatingToolbar = (props) => {
	return <FloatingToolbars {...props}> </FloatingToolbars>;
};
const FloatingToolbars = styled(Toolbar)`
	position: fixed !important;
	top: 87%;
	z-index: 500;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 6px;
	padding: 0rem;
	background: #333333;
	border: 1px solid #c4c4c4;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	height: 72px;
	width: 960px;
	color: #ffffff;
	.p-toolbar-group-right {
		width: inherit;
		height: inherit;
		align-items: center;
	}
`;

export const ActivityFilterTemplate = (options, range, setRange) => {
	return (
		<div className="position-relative pt-3">
			<div className="px-2">
				<Range
					step={1}
					min={0}
					max={5}
					values={range}
					onChange={(values) => {
						setRange(values);
						options.filterCallback(values);
					}}
					renderTrack={({ props, children }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "6px",
								width: "100%",
								backgroundColor: "#F8C8AE",
							}}>
							{children}
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "15px",
								width: "15px",
								borderRadius: "50%",
								backgroundColor: "#F96209",
							}}
						/>
					)}
				/>
			</div>
			<div className="d-flex align-items-center justify-content-between px-1 pt-2 ">
				<span>{range ? range[0] : 0}</span>
				<span>{range ? range[1] : 5}</span>
			</div>
		</div>
	);
};
export const balanceFilterTemplate = (options) => {
	return <Input variant="default" defaultValue={options.value} onChange={(e) => options.filterCallback(e.target.value, options.index)} />;
};
