import { getAllActiveWorkspacesMemoized } from "@redux/memoizedSelectors";
import WorkspaceDropdown from "features/workspace/components/dropdowns/workspace-dropdown";
import WorkspaceLogoAndName from "features/workspace/components/others/workspace-logo-and-name";
import { base } from "index";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetIsCompanyAdmin } from "shared/hooks";
import { Button, COLORS, IconDispatcher } from "shared/ui-basics";
import styled from "styled-components";

export const TopActions = ({ setNavigationSidebar, isNavigationBarOpen }) => {
	const { t } = useTranslation();
	const isCompanyAdmin = useGetIsCompanyAdmin();

	const userInfo = useSelector((state) => state?.auth?.userInfo);
	const allActivesWorkspaces = useSelector(getAllActiveWorkspacesMemoized);
	const isSubscriptionAuthorized = ["Enterprise"].includes(userInfo?.UserSubscriptionPlanName);
	const userId = userInfo?.UserId;
	const currentWorkspaceId = base.split("/")[2];
	const filteredWorkspaces = allActivesWorkspaces.filter((ws) => ws.id !== currentWorkspaceId);

	const handleSideBarClick = () => setNavigationSidebar(!isNavigationBarOpen);

	const getIcon = (workspace) => {
		const isWorkspaceOwner = workspace?.authorizedUsers.find((u) => u?.id === userId)?.roleName === "owner";
		if (isCompanyAdmin || isWorkspaceOwner) return "gear";
		return undefined;
	};

	const ToggleComponent = ({ workspace }) => {
		return (
			<Dropdown.Toggle as={Button} variant="clean" className="no-caret ms-1" disabled={!allActivesWorkspaces || allActivesWorkspaces?.length === 0 }>
				<WorkspaceLogoAndName workspace={workspace} secondaryAction={handleSideBarClick} icon="angle-left" nameWidth="120" size="xs" isDisabled={filteredWorkspaces?.length === 0 && !isCompanyAdmin} />
			</Dropdown.Toggle>
		);
	};

	return (
		<Container className="my-2">
			{isNavigationBarOpen && <WorkspaceDropdown toggleComponent={ToggleComponent} getIcon={getIcon} displayMenu={isSubscriptionAuthorized} isMenuItemDisabled={!isSubscriptionAuthorized} nameWidth="124" iconTooltip={t("EDIT_WORKSPACE")} showCreateButton={isCompanyAdmin && isSubscriptionAuthorized} />}
			{!isNavigationBarOpen && <SidebarButton onClick={handleSideBarClick}>{IconDispatcher("bars-sort")}</SidebarButton>}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 44px;
`;

const SidebarButton = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	font-size: 16px;
	border-radius: 4px;
	:hover {
		cursor: pointer;
	}
	background-color: ${COLORS.lightHover} !important;
	color: ${COLORS.label} !important;
	svg {
		width: 18px;
		height: 18px;
	}
`;
