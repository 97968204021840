import { createCxMetric, deleteCxMetric, updateCxMetric } from "@redux/index";
import { MAX_SIZE_TEXT_BOX } from "@utils/helpers";
import { UserType } from "@utils/optionsSets/OptionSets";
import { useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { useAutoFocus } from "shared/hooks/index";
import { Colors, GenericButton } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { CustomEditor } from "../../../../../shared/components/CustomEditor";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import ManageGenericItems from "../ManageGenericItems";

const TargetForm = ({ saveTarget, selectedTarget, panelRef, isViewer }) => {
	const { t } = useTranslation();
	const ref = useAutoFocus();

	const allMetricsData = useSelector((state) => state.libraries.cxMetrics);
	const loggerUserInfo = useSelector((state) => state.auth.userInfo);

	const [allMetrics, setAllMetrics] = useState([]);
	const [name, setName] = useState(selectedTarget?.name || "");
	const [description, setDescription] = useState(selectedTarget?.description || "");
	const [metrics, setMetrics] = useState(selectedTarget?.metrics || []);
	const [showManageMetric, setShowManageMetric] = useState(false);

	const loggerUserType = Number(loggerUserInfo.UserType);
	const isUserAllowed = loggerUserType === UserType.companyAdmin || loggerUserType === UserType.admin;
	const isShowManageAllowed = !showManageMetric && !isViewer && isUserAllowed;
	const isReadOnly = useSelector((state) => state.auth.userInfo?.UserType)?.isReadOnly();

	useEffect(() => {
		setAllMetrics(allMetricsData);
	}, [allMetricsData]);

	const handleEditorChange = (value) => {
		if (value.length > MAX_SIZE_TEXT_BOX) {
			value = value.truncate(MAX_SIZE_TEXT_BOX, false);
		}

		setDescription(value);
	};

	const closeForm = (e) => panelRef.current.toggle(e);

	const handleSave = (e) => {
		if (name === "") return toast.info(t("WARNING_WRITE_SUBJECT"));
		saveTarget({ selectedTarget, name, description, metrics });
		closeForm(e);
	};

	return (
		<FormContainer onClick={(e) => e.stopPropagation()} isReadOnly={isReadOnly}>
			<Body>
				{!showManageMetric && (
					<Form>
						<div>
							<Labels className="mb-3">{t("JS_53")}</Labels>
							<CustomInput value={name} onChange={(e) => setName(e.target.value)} disabled={isViewer} innerRef={ref} />
						</div>
						<div>
							<Labels className="mb-3">{t("ASPX_9")}</Labels>
							<EditorContainer>
								<CustomEditor variant="description" html={description} sethtml={(value) => handleEditorChange(value)} disabled={isViewer} />
							</EditorContainer>
						</div>
						<div>
							<Wrapper className="mb-3">
								<Labels>{t("JS_130")}</Labels>
								{isShowManageAllowed && (
									<GenericButton className="py-2" variant="light-link" size="s" icon={"SETTINGS"} iconClassName="me-1" onClick={() => setShowManageMetric(!showManageMetric)}>
										{t("MANAGE_METRICS")}
									</GenericButton>
								)}
							</Wrapper>

							<MetricsContainer>
								{allMetrics?.map((metric, index) => (
									<Metric key={index} metric={metric} metrics={metrics} setMetrics={setMetrics} isEditable={!isViewer} />
								))}
							</MetricsContainer>
						</div>
					</Form>
				)}
				{showManageMetric && (
					<ManageGenericItems
						title="MANAGE_METRICS"
						allItems={allMetricsData}
						createItem={(name, onSuccess) => createCxMetric({ name, onSuccess })}
						updateItem={(cxMeasureId, newName, onSuccess) => updateCxMetric({ cxMeasureId, newName, onSuccess })}
						deleteItem={(cxMeasureId, onSuccess) => deleteCxMetric({ cxMeasureId, onSuccess })}
						maxHeight={350}
						placeholder="ADD_METRIC"
					/>
				)}
			</Body>
			<Footer className="py-2">
				{showManageMetric && (
					<GenericButton className="py-2" variant="light-link" size="s" icon={"ANGLE-LEFT"} iconClassName="me-1" onClick={() => setShowManageMetric(!showManageMetric)}>
						{t("BACK_TO_TARGET")}
					</GenericButton>
				)}
				{!showManageMetric && (
					<FooterButtons>
						<GenericButton variant="outline-secondary" className="me-2 align-self-end" onClick={closeForm}>
							{t("ASPX_86")}
						</GenericButton>
						{!isViewer && (
							<GenericButton variant="primary" className="me-2 align-self-end" onClick={handleSave}>
								{t("ASPX_12")}
							</GenericButton>
						)}
					</FooterButtons>
				)}
			</Footer>
		</FormContainer>
	);
};

export default TargetForm;

const Metric = ({ metric, metrics, setMetrics, isEditable }) => {
	const labelRef = useRef(null);
	const [isEllipsisActive, setIsEllipsisActive] = useState(false);

	useEffect(() => {
		if (labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth) {
			setIsEllipsisActive(true);
		}
	}, [labelRef?.current]);

	const handleCheckBoxClick = () => {
		// If the id of the metric is already in newItem.metrics then remove it, else add it to the list
		if (metrics.includes(metric.id)) metrics = metrics.filter((metricId) => metricId !== metric.id);
		else metrics.push(metric.id);
		setMetrics([...metrics]);
	};

	return (
		<MetricContainer className="my-2 me-2" onClick={handleCheckBoxClick}>
			<CheckboxWrapper className="me-2">
				<input type="checkbox" defaultChecked={metrics.includes(metric.id)} disabled={!isEditable} />
			</CheckboxWrapper>
			<CustomTooltip text={metric.name} placement={"bottom"} disabled={!isEllipsisActive}>
				<MetricName ref={labelRef}>{metric.name}</MetricName>
			</CustomTooltip>
		</MetricContainer>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const FormContainer = styled.div`
	width: 462px;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${(props) => props.isReadOnly && "pointer-events: none;"}
`;

const Body = styled.div`
	width: 100%;
	max-height: 500px;
`;

const Form = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const Labels = styled(Label)`
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: ${Colors.label};
`;

const CustomInput = styled(Input)`
	width: ${(props) => (props.width ? props.width : "100%")}px;
	resize: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
	background-color: #ffffff;
	display: flex;
	align-items: center;

	&.form-control:focus {
		background-color: transparent;
		border: 1px solid ${Colors.primaryText} !important;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	&.form-control:disabled,
	.form-control[readonly] {
		background-color: #e5e4e5;
		color: #92919d;
		opacity: 1;
	}
`;

const MetricsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-height: 125px;
	overflow: scroll;
`;

const MetricContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	:hover {
		cursor: pointer;
	}
	input[type="checkbox"] {
		accent-color: #333333;
		width: 15px !important;
		height: 15px !important;
		:hover {
			cursor: pointer;
		}
	}
`;

const CheckboxWrapper = styled.div`
	width: 5%;
`;

const MetricName = styled.div`
	max-width: 100px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Footer = styled.div``;

const FooterButtons = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const EditorContainer = styled.div`
	max-height: 160px;
	overflow: scroll;
`;
