import { createContext, useContext, useEffect, useState } from 'react';

const ColorPickerContext = createContext();
const EditorContext = createContext();

// provider
export const ColorPickerProvider = ({ children }) => {
  const [ColorPicker, setColorPicker] = useState(null);

  useEffect(() => {
    import('react-color').then(colorModule => {
        setColorPicker(colorModule);
      });
    }, []);

  return (
    <ColorPickerContext.Provider value={ColorPicker}>
      {children}
    </ColorPickerContext.Provider>
  );
};

export const EditorProvider = ({ children }) => {
    const [Editor, setEditor] = useState(null);

    useEffect(() => {
      import('primereact/editor').then(editorModule => {
        editorModule && setEditor(editorModule.Editor);
      });
    }, []);

    return (
      <EditorContext.Provider value={Editor}>
        {children}
      </EditorContext.Provider>
    );
  };

  // context hooks
  export const useEditor = () => {
    return useContext(EditorContext);
  };
  export const useColorPicker = () => {
    return useContext(ColorPickerContext);
  };