import { createPersona, updateFolderLinks } from "@redux/index";
import { detectImageExtension, getEntityCurrentState } from "@utils/helpers";
import { Carousel } from "primereact/carousel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { UploadImageModal } from "shared/components/UploadImage";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import * as S from "./PersonaCreationStyles";

export default function PersonaPicture({ previousStep, newPersona, setNewPersona }) {
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation()
	const cxProgramId = search.getCxProgramID();
	const folderId = search.getFolderId()

	const [isDisabled, setIsDisabled] = useState(false);

	const onSuccess = (data) => {
		if(folderId) {
			updateFolderLinks({ folderIds: [folderId], idsToLink: [data.personaId], entityName: "cem_persona" })
			setTimeout(() => {
				history.push(`/personas?folderId=${folderId}`);
			}, 500);


		}else {
			history.push(`/EditPersona?personaIdStr=${data?.personaId}${cxProgramId ? `&cxProgramId=${cxProgramId}` : ''}`);
		}
	};

    const handleDoneClick = () => {
        setIsDisabled(true)
        createPersona({item:{...newPersona,isFuture:getEntityCurrentState(newPersona.isFuture)}, displayToast:"CREATE_NO_SUCCESS_TOAST", onSuccess, cxProgramId});
    }

	return (
		<React.Fragment>
			<S.BackButton variant="primary-link" onClick={previousStep}>
				{IconDispatcher("ARROW_LEFT", "me-1")}
				{t("ASPX_132")}
			</S.BackButton>
			<Row>
				<S.Header className="mb-5">
					<S.Bird>{IconDispatcher("WIZARD-BIRD")}</S.Bird>
					<S.DefinePersonaText>{t("ALMOST_DONE")}</S.DefinePersonaText>
					<S.SelectPersonaText>{t("SELECT_PERSONA_PICTURE")}</S.SelectPersonaText>
				</S.Header>
			</Row>
			<PersonaPictureSelector newPersona={newPersona} setNewPersona={setNewPersona} />
			<Row>
				<div className="d-flex justify-content-center">
					<GenericButton variant="primary" size="m" className="" onClick={handleDoneClick} disabled={isDisabled}>
						{t("ASPX_187")}
					</GenericButton>
				</div>
			</Row>
		</React.Fragment>
	);
}

export const PersonaPictureSelector = ({ newPersona, setNewPersona ,generateUsingAI ,innerRef }) => {
	let pictureGrids = "";
	if (newPersona?.gender === "Man") {
		pictureGrids = [
			["22", "23", "24", "25", "26", "27", "28", "29", "30"],
			["1", "3", "10", "11", "12"],
		];
	} else if (newPersona?.gender === "Woman") {
		pictureGrids = [
			["13", "14", "15", "16", "17", "18", "19", "20", "21"],
			["2", "4", "5", "6", "7", "8", "9"],
		];
	} else {
		pictureGrids = [
			["22", "13", "23", "14", "24", "15", "25", "16", "26"],
			["17", "27", "18", "28", "19", "29", "20", "30", "21"],
			["1", "2", "3", "4", "10", "5", "11", "6", "12"],
			["7", "8", "9"],
		];
	}


	const handleStandardImageClick = (e) => {
		const id = e.target.id;
		if (newPersona.standardImage === id) setNewPersona({ ...newPersona, standardImage: "", customImage: "" });
		else setNewPersona({ ...newPersona, standardImage: id, customImage: "upload" });
	};

	const handlePictureUpload = (result) => {
		if (result) {
			setNewPersona({ ...newPersona, standardImage: "", customImage: result, imageExtension: detectImageExtension(result.split(",")[0]) });
		}
	};

	const handleDelete = () => {
		setNewPersona({ ...newPersona, customImage: "delete" });
	};

	const GridTemplate = (grid) => {
		return (
			<S.PicturesContainer>
				{grid.map((pictureNumber, index) => (
					<S.PersonaPic key={index}>
						<img
							className={` img-bubble my-2 ${newPersona?.standardImage === pictureNumber ? "selected" : ""}`}
							key={index}
							id={pictureNumber}
							onClick={handleStandardImageClick}
							src={require(`@Assets/images/PersImg${pictureNumber}.jpg`).default}
							alt=""
						/>
						<S.Check checked={newPersona?.standardImage === pictureNumber}>{IconDispatcher("PRIMARY-CHECK")}</S.Check>
					</S.PersonaPic>
				))}
			</S.PicturesContainer>
		);
	};

	return (
		<Row className="py-4">
			<Col className="d-flex justify-content-center" xs={6}>
				<S.CarouselWrapper singleGrid={pictureGrids?.length === 1}>
					<Carousel value={pictureGrids} numVisible={1} numScroll={1} itemTemplate={GridTemplate} />
				</S.CarouselWrapper>
			</Col>
			<Col className="d-flex justify-content-center" xs={6}>
				<S.UploadImgWrapper>
					<UploadImageModal
						variantImg={"PersonaImg"}
						keyFor={"file"}
						id={"file"}
						deleteImage={true}
						disabled={true}
						customImage={newPersona?.customImage !== "" && newPersona?.customImage !== null ? newPersona?.customImage : undefined}
						setCustomImage={handlePictureUpload}
						onDelete={handleDelete}
						generateUsingAI={generateUsingAI}
						innerRef={innerRef}
					/>
				</S.UploadImgWrapper>
			</Col>
		</Row>
	);
};
