import Tags from "features/grid-table/columns-components/Tags";
import { CardContainer } from "features/analytics/analyticsHelper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components/macro";

function SolutionListCard({ item, isLastItem, onClose }) {
	const { t } = useTranslation();
	const history = useHistory();
	const redirect = () => {
		onClose();
		history.push(`/edit-solution?id=${item?.id}&origin=1`);
    };

	return (
		<CardContainer className="p-4" islast={isLastItem}>
			<Body>
				<Name onClick={redirect}>{item?.subject ? item?.subject : t("NEW_SOLUTION_NAME")}</Name>
				{Tags(item)}
			</Body>
		</CardContainer>
	);
}

SolutionListCard.displayName = "SolutionListCard";
export default SolutionListCard;

const Body = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Name = styled.div`
	cursor: pointer;
`;
