import { Col, Label, Row } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { Input } from "shared/ui-basics/index";

export const Section = styled.div`
	margin-bottom: 6rem;
`;

export const SectionTitle = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 2rem;
`;

export const CustomLabel = styled(Label)`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	${(props) => props.fs && `font-size: ${props.fs}px;`}
	margin-bottom: 1rem;
`;

export const CustomRow = styled(Row)``;

export const CustomInput = styled(Input)`
	padding: 0;
    padding-left: 5px;
	font-size: ${props => props.fontSize ? props.fontSize : 14}px;
	color: #333333;
	--bs-bg-opacity: 1;
	text-overflow: ellipsis;
	background-color: transparent !important;
	font-weight: 500;
	border: 1px solid #c4c4c4;
	min-height: 44px;
	max-height: 100px; /* Adjust this for the desired number of lines */
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Adjust this for the desired number of lines */
	-webkit-box-orient: vertical;
	resize: none;
	word-break: break-all;
	user-select:text;

	:focus {
		font-family: "Inter";
		letter-spacing: 0em;
		text-align: left;
		--bs-bg-opacity: 1;
		text-overflow: ellipsis;
		background-color: transparent !important;
	}

	${(props) => (props.isDisabled ? "pointer-events: none;" : "")}
`;

export const CustomCol = styled(Col)`
#copy_button {
	visibility: hidden;
}
&:hover #copy_button {
	visibility: visible;
}
`;
