import { deleteTeam, getAllTeams, removeMember } from "@redux/index";
import { getTeamsUsers } from "@redux/memoizedSelectors";
import { userTypesLabels } from "@utils/helpers";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { ActionConfirmModal } from "shared/modals/index";
import TeamMembers from "shared/modals/manage-access/TeamMembers";
import { GenericButton } from "shared/ui-basics/buttons";
import { ActionsDropdown, FlexAlignCenter, FlexBetweenAlignCenter, IconDispatcher } from "shared/ui-basics/index";
import { SearchBar } from "shared/ui-basics/input";
import { CustomBreadCrumb } from "shared/ui-basics/ui-components/CustomBreadCrumb";
import { EmptyList } from "shared/ui-basics/ui-components/EmptyList";
import styled, { css } from "styled-components";
import { useModal } from "use-modal-hook";
import InitialsBubble from "../components/initials-bubble";
const MembersRow = ({ member, setSelectedTeam, showTeamModal }) => {
	const { t } = useTranslation();

	const users = useSelector((state) => getTeamsUsers(state, member?.memberUserIds), shallowEqual);
	const [ActionConfirm] = useModal(ActionConfirmModal);

	const handleTeamClick = (team) => setSelectedTeam(team);

	const createActions = () => {
		const actions = [];
		actions.push({ handleClick: () => showTeamModal({ teamId: member?.id }), icon: "EDIT", text: t("EDIT") });
		actions.push({
			handleClick: () =>
				ActionConfirm({
					bodyContent: "DELETE_TEAM_CONFIRMATION",
					Action: () => deleteTeam({ teamId: member.id }),
					actionBtnText: "ASPX_13",
				}),
			icon: "DELETE",
			text: t("ASPX_13"),
		});

		return actions;
	};
	const icon = <IconWrapper className="me-2">{IconDispatcher("USER-GROUP")}</IconWrapper>;
	return (
		<TeamRow key={member.id}>
			<TeamColumn onClick={() => handleTeamClick(member)} className="pointer">
				<FlexAlignCenter>
					{icon}
					<div>{member.name}</div>
				</FlexAlignCenter>
			</TeamColumn>
			<TeamColumn className="text-center">{users?.length}</TeamColumn>
			<TeamColumn className="text-end">
				<ActionsDropdown id={member.id} actions={createActions()} toggleButton={IconDispatcher("ACTION-DROPDOWN-HORIZONTAL")} autoClose="outside" dropdownClasses="justify-content-end" />
			</TeamColumn>
		</TeamRow>
	);
};
export default function Teams() {
	const { t } = useTranslation();
	const [search, setSearch] = React.useState("");
	const [selectedTeam, setSelectedTeam] = React.useState(null);

	const teamsDic = useSelector(({ settings }) => settings?.teams || {});
	const teams = Object.values(teamsDic);
	const users = useSelector((state) => getTeamsUsers(state, teamsDic?.[selectedTeam?.id]?.memberUserIds), shallowEqual);
	const backOfficeUsers = useSelector(({ settings }) => settings?.users || []);
	const handleCreate = () => showTeamModal({ teamId: selectedTeam?.id });

	const handleBackClick = () => setSelectedTeam(null);
	const [showTeamModal] = useModal(TeamMembers);


	const UsersRow = (user) => {
		const { domainName, fullName } = user;
		const userType = backOfficeUsers.find((u) => u.loginName.toLowerCase() === domainName.toLowerCase())?.userType;
		const createActions = () => {
			const actions = [];
			actions.push({ handleClick: () => removeMember({ teamId: selectedTeam.id, userId: user.id }), icon: "DELETE", text: t("REMOVE") });
			return actions;
		};
		const icon = <InitialsBubble fullName={fullName} size='s' />


		return (
			<TeamRow key={user.id}>
				<TeamColumn xs={4}>
					<FlexAlignCenter>
						{icon}
						<span className="ms-2">{fullName}</span>
					</FlexAlignCenter>
				</TeamColumn>
				<TeamColumn xs={4} className="text-center text-truncate">{domainName}</TeamColumn>
				<TeamColumn xs={3} className="text-center">{t(userTypesLabels[userType])}</TeamColumn>
				<TeamColumn xs={1} className="text-end">
					<ActionsDropdown id={user.id} actions={createActions()} toggleButton={IconDispatcher("ACTION-DROPDOWN-HORIZONTAL")} autoClose="outside" dropdownClasses="justify-content-end" />
				</TeamColumn>
			</TeamRow>
		);
	};

	const renderTable = () => {
		switch (selectedTeam) {
			case null:
				// means we are on the first page
				return (
					<TableWrapper className="my-5">
						<TeamRow>
							<TeamColumn label="true">{t("ASPX_136")}</TeamColumn>
							<TeamColumn label="true" className="text-center">
								{t("ASPX_225")}
							</TeamColumn>
							<TeamColumn label="true" className="text-end">
								{" "}
							</TeamColumn>
							<TeamColumn label="true" className="text-end">
								{" "}
							</TeamColumn>
						</TeamRow>
						{teams.searchFilter(search).map(team => <MembersRow key={team?.id} member={team} setSelectedTeam={setSelectedTeam} showTeamModal={showTeamModal} />)}
					</TableWrapper>
				);
			default:
				// means we are on the second page
				return (
					<TableWrapper className="my-5">
						<TeamRow>
							<TeamColumn xs={4} label="true">{t("ASPX_136")}</TeamColumn>
							<TeamColumn xs={4} label="true" className="text-center">
								{t("EMAIL")}
							</TeamColumn>
							<TeamColumn xs={3} label="true" className="text-center">
								{t("CS_59")}
							</TeamColumn>
							<TeamColumn xs={1} label="true" className="text-end"></TeamColumn>
						</TeamRow>
						{users.searchFilter(search, "fullName").map(UsersRow)}
					</TableWrapper>
				);
		}
	};

	return (
		<DataLoader reduxActions={[getAllTeams]}>
			<PageTemplate classNames="container pt-5" pageTitle={t("TEAMS")}>
				<PageBody>
					<FlexBetweenAlignCenter className="my-5">
						{teams?.length > 0 && (
							<GenericButton variant="primary" onClick={handleCreate} icon="SQUARE_PLUS" iconClassName="me-1">
								{selectedTeam ? t("NEW_MEMBER") : t("NEW_TEAM")}
							</GenericButton>
						)}
						<div>
							<SearchBar placeholder={t("SEARCH")} globalFilterValue={search} handleChange={(e) => setSearch(e.target.value)} />
						</div>
					</FlexBetweenAlignCenter>
					{selectedTeam && (
						<div className="mb-5">
							<CustomBreadCrumb paths={["TEAM", selectedTeam?.name]} backFunction={handleBackClick} />
						</div>
					)}
					{teams?.length > 0 ? (
						renderTable()
					) : (
						<EmptyList
							data={{
								emptyListIcon: IconDispatcher("USERS_LIGHT", "", { size: "3x" }),
								emptyListIconColor: "#BDE8FA",
								emptyListText: t("NO_TEAMS"),
								buttonCreateLabel: t("NEW_TEAM"),
								buttonCreate: handleCreate,
								visibleButtonCreate: true,
							}}
						/>
					)}
				</PageBody>
			</PageTemplate>
		</DataLoader>
	);
}

const PageBody = styled(Container)``;
const TableWrapper = styled.div`
	width: 100%;
	border-radius: 4px;
	border: 1px solid #e5e5e5;
	overflow: hidden;
`;
const TeamRow = styled(Row)`
	border-bottom: 1px solid #e5e5e5;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TeamColumn = styled(Col)`
	${(props) =>
		props.label === "true"
			? css`
					color: #605f60;
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
			  `
			: css`
					color: #333;
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
			  `}
`;
const IconWrapper = styled.div`
	background-color: #bde8fa;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
`;
