import { encode } from "@utils/helpers";
import StakeHolderCard from "features/forms/components/sections/StakeHolderSection/StakeHolderCard";
import StakeHolderForm from "features/forms/components/sections/StakeHolderSection/StakeHolderForm";
import { OverlayPanel } from "primereact/overlaypanel";
import { Slider } from "primereact/slider";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Col } from "reactstrap";
import { CustomEditor } from "shared/components/CustomEditor";
import Group from "shared/cx-components/Group";
import OwnerPickList from "shared/cx-components/OwnerPickList";
import Priority from "shared/cx-components/Priority";
import { FlexAlignJustifyCenter, FlexBetweenAlignCenter, GenericButton } from "shared/ui-basics/index";
import { Input } from "shared/ui-basics/input";
import styled from "styled-components";
import { updateProject } from "../reducer/ProjectsActions";
import { ProjectDetails } from "./ProjectDetails";
import * as Style from "./SectionsStyling";

//Use Hook for From - See with Simon

export const ProjectGeneral = ({ setActiveLink, innerRef }) => {
	const { t } = useTranslation();
	const panelRef = useRef(null);

	const roleName = useSelector((s) => s.projects?.currentUser?.roleName)?.toLowerCase();
	const currentProject = useSelector((state) => state.projects.current_project);

	const [project, setProject] = useState(currentProject);
	const isViewer = roleName === "viewer";
	const isOwner = roleName === "owner";
	const hideForPdf = useLocation().search.hideForPdf();
	const [readyToUpdate, setReadyToUpdate] = useState(false);

	useEffect(() => {
		if (readyToUpdate) {
			update({ ...project, projectProgress: project?.projectProgress }, "projectProgress");
			setReadyToUpdate(false);
		}
	}, [project?.projectProgress, readyToUpdate]);

	const update = (project, attr) => {
		updateProject({ modifiedProject: project, modifiedAttribNames: [attr] });
	};

	const { ref: inViewRef } = useInView({
		onChange: (inView) => {
			if (inView) setActiveLink("projectGeneral");
		},
		threshold: 0.5,
		initialInView: true,
		root: innerRef.current,
	});

	const handleProgressChange = (e) => {
		setProject((prevState) => ({
			...prevState,
			projectProgress: e.value,
		}));
		//update({ ...project, projectProgress: e.value }, "projectProgress");
	};
	const updateProgressBar = () => {
		setTimeout(() => {
			setReadyToUpdate(true);
		}, 500);
	};
	const handleEditorChange = (value) => {
		setProject((prevState) => ({
			...prevState,
			description: value,
		}));
	};

	const handleBlur = (attribute) => {
		update({ ...project, description: encode(project?.description) }, attribute);
	};

	const handleDateChange = (e) => {
		const { value, name } = e.target;
		const date = new Date(value).toISOString();
		setProject({ ...project, [name]: date });
		update({ ...project, [name]: date }, name);
	};

	return (
		<Style.Section id="projectGeneral" ref={inViewRef}>
			<Style.CustomRow>
				<Col xs={12}>
					<Style.SectionTitle>{t("ASPX_185")}</Style.SectionTitle>
				</Col>
			</Style.CustomRow>
			<Style.CustomRow className="mb-5">
				<Col xs={12}>
					<Style.CustomLabel>{t("ASPX_9")}</Style.CustomLabel>
					<CustomEditor html={project?.description || ""} sethtml={handleEditorChange} onBlur={() => handleBlur("description")} variant="description" disabled={isViewer} />
				</Col>
			</Style.CustomRow>
			<Style.CustomRow className="mb-5 gx-5">
				<Col xs={6}>
					<FlexBetweenAlignCenter>
						<div>
							<Style.CustomLabel for="Date"> {t("START_DATE")}</Style.CustomLabel>
							<Input
								variant="default"
								id="startDate"
								name="startDate"
								placeholder="date placeholder"
								type="date"
								value={project?.startDate?.formatDate() || ""}
								onChange={handleDateChange}
								disabled={isViewer}
							/>
						</div>
						<div>
							<Style.CustomLabel for="Date"> {t("END_DATE")}</Style.CustomLabel>
							<Input
								variant="default"
								id="endDate"
								name="endDate"
								type="date"
								placeholder="date placeholder"
								value={project?.endDate?.formatDate() || ""}
								onChange={handleDateChange}
								disabled={isViewer}
							/>
						</div>
					</FlexBetweenAlignCenter>
				</Col>
				<Col xs={6}>
					<Style.CustomLabel>{t("PROGRESS")}</Style.CustomLabel>
					<FlexAlignJustifyCenter className="w-100" gap="15">
						<ProgressScore>
							<Score>{project?.projectProgress}</Score>
							<Score>{"%"}</Score>
						</ProgressScore>
						<SliderContainer className="w-100">
							<Slider value={project?.projectProgress} step={1} onChange={handleProgressChange} name="projectProgress" disabled={isViewer} onMouseUp={updateProgressBar} />
						</SliderContainer>
					</FlexAlignJustifyCenter>
				</Col>
			</Style.CustomRow>
			<Style.CustomRow className="mb-5 gx-5">
				<Col xs={6}>
					<div>
						<Style.CustomLabel>{t("PROJECT_OWNER")}</Style.CustomLabel>
						<OwnerPickList object={project} setObject={setProject} updateFunction={(project, attrList) => update(project, attrList[0])} disable={isViewer} />
					</div>
				</Col>
				<Col xs={6}>
					<div>
						<Style.CustomLabel>{t("STAKEHOLDERS")}</Style.CustomLabel>
						<>
							<CardsContainerStyle className="ms-3">
								{currentProject?.stakeholders?.map((stakeholder, index) => (
									<StakeHolderCard
										key={index}
										recordId={project?.id}
										item={stakeholder}
										actionType="PROJECT_STAKEHOLDER"
										entityName="cem_cxproject"
										stakeHoldersPath="projects.current_project?.stakeholders"
										roleName={roleName}
									/>
								))}
							</CardsContainerStyle>
							{!hideForPdf && (
								<GenericButton className="mt-2" variant="light-link" icon="SQUARE_PLUS" iconClassName="me-1" onClick={(e) => panelRef.current.toggle(e)} disabled={isViewer}>
									{t("STAKEHOLDER")}
								</GenericButton>
							)}
							<OverlayPanel ref={panelRef}>
								<StakeHolderForm
									recId={project?.id}
									entityName="cem_cxproject"
									actionType="PROJECT_STAKEHOLDER"
									stakeHoldersPath="projects.current_project?.stakeholders"
									roleName={roleName}
									panelRef={panelRef}
								/>
							</OverlayPanel>
						</>
					</div>
				</Col>
			</Style.CustomRow>
			<Style.CustomRow className="mb-5 gx-5">
				<Col xs={6}>
					<div>
						<Style.CustomLabel>{t("GROUP")}</Style.CustomLabel>
						<Group object={project} setObject={setProject} recordType="cem_cxproject" disabled={hideForPdf || isViewer} mode={!isOwner && "none"} />
					</div>
				</Col>
				<Col xs={6}>
					<div>
						<Style.CustomLabel>{t("PRIORITY")}</Style.CustomLabel>
						<Priority object={project} setObject={setProject} name="project" updateFunction={(project, attrList) => update(project, attrList[0])} disable={isViewer} />
					</div>
				</Col>
			</Style.CustomRow>
			<ProjectDetails />
		</Style.Section>
	);
};

const SliderContainer = styled.div`
	height: 1rem !important;
	.p-slider.p-slider-horizontal {
		height: 100%;
	}
	.p-slider .p-slider-range {
		border-radius: 4px;
	}
`;

const CardsContainerStyle = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
`;

const ProgressScore = styled.div`
	color: #f96209;
	font-family: "Oswald";
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Score = styled.div`
	font-family: "Oswald", sans-serif;
`;
