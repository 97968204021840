import {
	createSolution,
	getAllAuthorizedUsers,
	getAllMapsListData,
	getAllOpportunity,
	getAllPersonas,
	getAllSolutions,
	getPdfExportSol,
	groupInit,
	priorityInit,
	statusInit,
	tagInit,
} from "@redux/index.js";
import { getAllMapsList, getFolderById, retrieveAllActiveSolutions } from "@redux/memoizedSelectors";
import { FilterItemsByStatus, createDefaultSol, emptyListData, handleRedirectEntityList, isUserAllowed, toastWrapper } from "@utils/helpers";
import DataTable from "features/grid-table/DataTable.js";
import SolutionPage from "features/solutions/view/solution-page";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ChartFilter } from "shared/components/charts-components/ChartFilter/ChartFilter.js";
import { EditorProvider } from "shared/dynamic-imports/index.js";
import { PageTemplate } from "shared/global-layouts/PageTemplate.js";
import { useAsyncs } from "shared/hooks/index";
import { GenericButton } from "shared/ui-basics/index";
import { EmptyList } from "shared/ui-basics/ui-components/EmptyList.jsx";
import styled from "styled-components/macro";
import { SolutionsQuadrant } from "./solutions-quadriant.jsx";

export default function Solutions() {
	const { t } = useTranslation();

	const child = useRef(null);
	const op = useRef(null);
	const pdfRef = useRef();
	const { pathname, search } = useLocation();
	const history = useHistory();

	const { statusIds, mapIds, groupIds, hideForPdf } = search.extractIdsChart();
	const folderId = search.getFolderId();
	const folderView = folderId === undefined ? false : true;
	const tab = search?.getTab();

	const all_maps = useSelector(getAllMapsList);
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);

	const solutions = useSelector(({ solutions }) => solutions.all_solutions.filter((opp) => FilterItemsByStatus(opp, pathname, "/solutions", showSampleData, all_maps)));
	const userInfo = useSelector((state) => state.auth.userInfo);
	const table = useSelector(({ ui }) => ui.table);
	const idRedirect = useSelector(retrieveAllActiveSolutions)?.[0]?.id;
	const { linkedSols } = useSelector((state) => getFolderById(state, folderId), shallowEqual);

	const [key, setKey] = React.useState(hideForPdf ? "chart" : "list");
	const [query, setQuery] = useState(false);
	const [filterParams, setFilterParams] = useState({ statusIds, mapIds, groupIds });

	const { icon, color } = emptyListData.solutions;

	useEffect(() => {
		if (folderView) {
			setKey("list");
		}
		if (tab) setKey(tab);
	}, [folderView, tab]);

	const handleRedirect = () => {
		const onSuccess = (data) => {
			history.push(`/edit-solution?id=${data.id}`);
		};
		const newSolution = createDefaultSol(userInfo);
		createSolution({ item: newSolution, displayToast: "CREATE", onSuccess });
	};

	useAsyncs({
		asyncFns: [
			{ asyncFn: getAllMapsListData },
			{ asyncFn: getAllAuthorizedUsers },
			{ asyncFn: getAllOpportunity },
			{ asyncFn: getAllSolutions },
			{ asyncFn: statusInit },
			{ asyncFn: groupInit },
			{ asyncFn: tagInit },
			{ asyncFn: priorityInit },
			{ asyncFn: getAllPersonas },
		],
	});

	const handleClick = () => {
		const entityType = "100000004";
		const context = "solutionsList";
		const route = "/edit-solution";

		if (idRedirect) {
			const response = handleRedirectEntityList({ entityType, context, route, idRedirect, history, table, displayList: true, workspaceView: true });
			setQuery(response);
			setKey("grid-view");
		}
	};

	const ChartButtons = () => {
		const { t } = useTranslation();
		const dispatch = useDispatch();
		const statuses = useSelector((state) => state.libraries?.status)?.solutions;
		const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
		const isAllowed = isUserAllowed(userInfo?.UserType, "create-new-solution");
		const nFiltersApplied = filterParams.statusIds.length + filterParams.mapIds.length + filterParams.groupIds.length;

		const toggleFilter = () => setFilterDropdownOpen((prevState) => !prevState);

		const handleFilterChange = (attribute, newId) => {
			if (filterParams[attribute].includes(newId)) filterParams[attribute] = filterParams[attribute].filter((id) => id !== newId);
			else filterParams[attribute].push(newId);

			setFilterParams({ ...filterParams });
		};

		const handleResetFilter = () => {
			setFilterParams({
				statusIds: [],
				mapIds: [],
				groupIds: [],
			});
		};
		const getPdfExportServiceSolutionChart = (width, height, mapIds, statusIds, groupIds) => {
			const promise = async () => {
				const { data, error } = await dispatch(getPdfExportSol(width, height + 230, mapIds, statusIds, groupIds));
				var fileDownload = require("js-file-download");
				fileDownload(data, "Solutions Chart" + ".pdf");
				if (error) {
					throw error;
				}
			};
			toastWrapper(promise, "EXPORT");
		};
		const exportPdf = () => {
			let height = pdfRef.current.offsetHeight + 150;
			height = height > 1200 ? height : 1200;
			const mapIds = filterParams?.mapIds?.join("|") || "";
			const statusIds = filterParams?.statusIds?.join("|") || "";
			const groupIds = filterParams?.groupIds?.join("|") || "";
			getPdfExportServiceSolutionChart(1800, height, mapIds, statusIds, groupIds);
		};

		return (
			<SolutonDisplayWrapper className="mt-4">
				<div>
					{isAllowed && (
						<GenericButton variant="primary" onClick={handleRedirect} icon="SQUARE_PLUS" iconClassName="me-1">
							{t("NEW_SOLUTION")}
						</GenericButton>
					)}
				</div>
				<div className="d-flex">
					<GenericButton variant="outline-secondary" className="me-2" icon="PDF" iconClassName="me-1" tooltip={true} tooltipText={t("ASPX_15")} onClick={exportPdf}>
						<div>{t("ASPX_14")}</div>
					</GenericButton>
					<Filter isOpen={filterDropdownOpen} toggle={toggleFilter} direction={"left"}>
						<GenericButton variant="outline-secondary" className="w-100" icon="FILTER" iconClassName="me-1" onClick={(e) => op.current.toggle(e)}>
							{t("FILTER")}
							{nFiltersApplied > 0 && " / " + nFiltersApplied}
						</GenericButton>
						<CustomOverlayPanel ref={op}>
							<ChartFilter items={solutions} statuses={statuses} filterParams={filterParams} handleFilterChange={handleFilterChange} handleResetFilter={handleResetFilter} />
						</CustomOverlayPanel>
					</Filter>
				</div>
			</SolutonDisplayWrapper>
		);
	};

	const handleKeys = (name) => {
		if (name !== "grid-view") setQuery(false);
		setKey(name);
	};

	const renderChartButtons = () => {
		if (!hideForPdf && key === "chart") {
			if (solutions.length > 0) return <ChartButtons />;

			return (
				<div className="mt-5 pt-5">
					<EmptyList
						data={{
							visibleButtonCreate: true,
							buttonCreate: handleRedirect,
							buttonCreateLabel: "NEW_SOLUTION",
							emptyListIconColor: color,
							emptyListIcon: icon,
							emptyListText: "SOLUTION_EMPTY_LIST",
						}}
					/>
				</div>
			);
		}
	};

	const isShowViewButtons = () => {
		if (hideForPdf || folderView) return false;
		return true;
	};

	const renderViewButtons = (t) => {
		if (!isShowViewButtons()) return <></>;
		return (
			<>
				<GenericButton variant="light-link" className="ms-2" active={key === "grid-view"} icon="SIDEBAR" tooltip tooltipText={t("SHOW_ALL_SOLUTIONS")} onClick={handleClick} />
				<GenericButton variant="light-link" className="ms-2" active={key === "list"} icon="LIST-VIEW" tooltip tooltipText={t("LIST_VIEW")} onClick={() => handleKeys("list")} />
				<GenericButton variant="light-link" className="ms-2" active={key === "chart"} icon="CHART-SCATTER-BUBBLE" tooltip tooltipText={t("CHART_VIEW")} onClick={() => handleKeys("chart")} />
			</>
		);
	};

	const renderView = () => {
		switch (key) {
			case "list":
				return <DataTable context={"solutions.solutionsList"} ref={child} linked={linkedSols} />;
			case "chart":
				return <SolutionsQuadrant pdfRef={pdfRef} filterParams={filterParams} baseUnit="60" />;
			case "grid-view":
				return (
					<EditorProvider>
						<SolutionPage query={query} setQuery={setQuery} />
					</EditorProvider>
				);
			default:
				return <></>;
		}
	};

	return (
		<PageTemplate
			classNames="container-fluid p-5"
			pageTitle={`${t("YOUR")} ${t("SOLUTIONS")}`}
			renderViewButtons={renderViewButtons}
			renderOtherButtons={renderChartButtons}
			variant={key === "chart" && solutions?.length > 0 ? "graph" : ""}>
			{renderView()}
		</PageTemplate>
	);
}

const SolutonDisplayWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem !important;
`;

const Filter = styled.div`
	width: 120px;
`;

const CustomOverlayPanel = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0px;
	}
`;
