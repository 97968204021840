import { addWatcher, removeWatcher, resetRequests } from "@redux-requests/core";
import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";
import { encode } from "@utils/helpers";
import { UserType } from "@utils/optionsSets/OptionSets";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import $ from "jquery";

export const useClickOutside = (ref, handler, authorizedClasses = [], unauthorizedClasses = []) => {
	useEffect(() => {
		const listener = (event) => {
			//console.log("event", event.target.className);
			// Check if the click is outside the ref element
			if (!ref.current) {
				//  console.log("Ref is not defined",ref);
				return;
			}

			if (ref?.current?.contains(event.target)) {
				//  console.log("Clicked inside the ref element");
				return;
			}

			if (authorizedClasses?.some((name) => typeof event.target.className === "string" && event.target.className.startsWith(name))) {
				// console.log("Clicked inside an authorized class");
				return;
			}

			handler(event);
		};
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler]);
};

export const useClickOutsides = (handler, authorizedClasses) => {
	const ref = useRef(null);
	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current?.contains(event.target) || (typeof event.target.className === "string" && authorizedClasses?.some((name) => event.target.className?.startsWith(name)))) {
				return;
			}
			handler(event);
		};
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler]);
	return ref;
};

export const useClickOutsideForComment = (ref, handler, isDragging) => {
	useEffect(() => {
		if (!isDragging) return;
		const listener = (event) => {
			if (!ref.current || ref.current?.contains(event.target)) {
				return;
			}
			handler(event);
		};
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler, isDragging]);
};

export const useDoubleClickOutside = (callback) => {
	const ref = React.useRef();

	React.useEffect(() => {
		const handleClick = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(event);
			}
		};

		document.addEventListener("click", handleClick, true);

		return () => {
			document.removeEventListener("click", handleClick, true);
		};
	}, [ref]);

	return ref;
};

export function useAsync({ asyncFn, onSuccess = null, loading = null, parameter = null, reset = null }) {
	const dispatch = useDispatch();
	useEffect(() => {
		let isActive = true;
		loading !== null && loading(true);
		asyncFn !== null &&
			dispatch(asyncFn(parameter))
				?.then((data) => {
					if (isActive) {
						onSuccess !== null && onSuccess(data);
						loading !== null && loading(false);
						//  toast.success("Successfully mounted")
					} else {
						//console.log("aborted setState on unmounted component")
					}
				})
				.catch((error) => {
					loading !== null && isActive && loading(false);
					toast.error(error.response);
				});
		return () => {
			isActive = false;
			resetRequests();
			reset !== null && dispatch(reset());
		};
	}, [asyncFn, onSuccess]);
}

export function useAsyncs({ asyncFns = [], setLoading, handleSuccess, resets = null }) {
	const dispatch = useDispatch();

	useEffect(() => {
		let isMounted = true;

		if (asyncFns?.length > 0) {
			setLoading && setLoading(true);

			const promises = asyncFns?.map(({ asyncFn, parameter, Fn }) => (Fn !== undefined ? Fn(parameter) : asyncFn && dispatch(asyncFn(parameter))));

			Promise.all(promises)
				.then((data) => {
					if (isMounted) {
						setLoading && setLoading(false);
						handleSuccess && handleSuccess();
					}
				})
				.catch((error) => {
					if (isMounted) {
						setLoading && setLoading(false);
						toast.error(error.response);
					}
				});
		}

		return () => {
			isMounted = false;
			if (resets !== null) {
				const promisesReset = resets?.map((fn) => fn && dispatch(fn()));
				Promise.all(promisesReset);
			}
		};
	}, []);

	return null;
}
export const useContainerDimensions = (myRef) => {
	const getDimensions = () => ({
		width: myRef.current.offsetWidth,
		height: myRef.current.offsetHeight,
	});

	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = () => {
			setDimensions(getDimensions());
		};

		if (myRef.current) {
			setDimensions(getDimensions());
		}

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [myRef]);

	return dimensions;
};

export const useAutoFocus = () => {
	const inputRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			inputRef.current?.focus();
		}, 1);
	}, []);

	return inputRef;
};

export const useNetworkStatus = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		window.addEventListener("online", handleStatusChange);
		window.addEventListener("offline", handleStatusChange);

		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener("online", handleStatusChange);
			window.removeEventListener("offline", handleStatusChange);
		};
	}, [isOnline]);

	return { isOnline };
};

export const useIsEllipsis = (disableIfEllipsis) => {
	const ref = useRef();
	const [isEllipsis, setIsEllipsis] = useState(false);

	useEffect(() => {
		if (disableIfEllipsis && ref.current) {
			const { scrollWidth, clientWidth } = ref.current;
			setIsEllipsis(scrollWidth > clientWidth);
		}
	}, [disableIfEllipsis]);

	return [ref, isEllipsis];
};

export function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}
export function useIsMounted() {
	const isMountedRef = useRef(true);

	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	return isMountedRef; // Return the ref, not just its current value
}

export const useAttributeHandler = (initialAction, updateService) => {
	const [object, setObject] = useState(initialAction);
	const [modifiedAttribNames, setModifiedAttribNames] = useState(new Set());

	const handleChange = (name, value, allowDirectServiceUpdate) => {
		setObject((prevState) => ({
			...prevState,
			[name]: value,
		}));
		if (allowDirectServiceUpdate) return updateService({ ...object, [name]: value }, [name]);

		setModifiedAttribNames((prevSet) => new Set(prevSet).add(name));
	};

	const handleBlur = (attribute) => {
		if (modifiedAttribNames.has(attribute)) {
			// Add attribute-specific logic here if needed
			// For example, encoding description and subject
			let description = object.description || "";
			let subject = object.subject || "";
			if (attribute === "description") description = encode(object.description);
			if (attribute === "subject") subject = encode(object.subject);

			// Update the service with the modified attributes using the provided function
			updateService({ ...object, description, subject }, [attribute]);

			// Remove the attribute from the modified set
			setModifiedAttribNames((prevSet) => {
				const updatedSet = new Set(prevSet);
				updatedSet.delete(attribute);
				return updatedSet;
			});
		}
	};

	return { object, setObject, handleChange, handleBlur, setModifiedAttribNames, modifiedAttribNames };
};

export const useSubscriptionCheck = (UserSubscriptionPlanName) => {
	const history = useHistory();
	const { pathname, search } = useLocation();

	useEffect(() => {
		if (!UserSubscriptionPlanName) {
			const targetPage = encode(pathname + search);
			history.push(`/login?tgtUrl=${targetPage}`);
		}
	}, [UserSubscriptionPlanName, pathname, search, history]);
};

export const useModalHook = () => {
	const [isOpen, setOpen] = useState(false);
	const openModal = useCallback(() => {
		if (isOpen) return;
		setOpen(true);
	}, [isOpen]);
	const closeModal = useCallback(() => setOpen(false), []);
	return [openModal, closeModal, isOpen];
};
export const useGetUserTeams = (user) => {
	const allTeamsObj = useSelector((state) => state.settings?.teams);
	const allAuthorizedUsers = useSelector((state) => state.settings?.authorizedUsers);

	const allTeams = allTeamsObj ? Object.values(allTeamsObj) : [];
	const authorizedUserId = allAuthorizedUsers.find((authUser) => authUser?.domainName.toLowerCase() === user?.loginName?.toLowerCase())?.id;
	const userTeams = allTeams?.filter((t) => t?.memberUserIds?.includes(authorizedUserId));

	return userTeams;
};

export const useGetUserWorkspaces = (loginName) => {
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);
	const allAuthorizedUsers = useSelector((state) => state.settings?.authorizedUsers);

	const authorizedUserId = allAuthorizedUsers.find((authUser) => authUser?.domainName.toLowerCase() === loginName)?.id;
	const currentUserWorkspaces = allActivesWorkspaces?.filter((ws) => ws.authorizedUsers.find((au) => au.id === authorizedUserId));

	return currentUserWorkspaces;
};

export const useGetCurrentAuthorizedUser = () => {
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const authorizedUsers = useSelector((state) => state.settings.authorizedUsers);
	return authorizedUsers?.filter((user) => user?.id === currentUserId);
};

export const useGetCurrentRegisteredUser = () => {
	const currentUserId = useSelector((state) => state.auth.userInfo.UserId);
	const authorizedUsers = useSelector((state) => state.settings.authorizedUsers);
	const registeredUsers = useSelector((state) => state.settings.user);

	const authUser = authorizedUsers?.filter((user) => user?.id === currentUserId);
	return registeredUsers?.filter((ru) => ru?.loginName?.toLowerCase() === authUser?.domainName.toLowerCase());
};

export const useGetIsCompanyAdmin = () => {
	const userInfo = useSelector((state) => state?.auth?.userInfo);
	const userType = Number(userInfo?.UserType);
	return userType === UserType.companyAdmin;
};

export const useGetIsLoggedUserWorkspaceOwner = (workspace) => {
	const userId = useSelector((state) => state?.auth?.userInfo?.UserId);
	return workspace?.authorizedUsers.find((u) => u?.id === userId)?.roleName === "owner";
};

export const useActionOnScroll = ({ pathname, authorizedPathnames, targetId, action }) => {
	useEffect(() => {
		if (authorizedPathnames?.includes(pathname)) {
			const target = $(targetId);
			target.on("scroll", action);

			return () => {
				target.off("scroll", action);
			};
		}
	}, []);
};
