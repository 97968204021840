import { Input } from "shared/ui-basics/input";
import { colorCheck } from "@utils/helpers";
import { InputSwitch } from "primereact/inputswitch";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import styled, { css } from "styled-components/macro";

export const StatusWrapper=styled.div`
.p-dropdown
{
	height:fit-content !important;

}
.p-dropdown-label
{
	padding:0;
}
`
export const SubjectWrapper = styled.div`

	cursor: ${(props) => (props.desactivateclick === "true" ? "default" : "pointer")};
	font-weight: 500 !important;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: ${(props) => (props.desactivateclick === "true" ? "default" : "pointer")};
	overflow: hidden;
	font-weight: 500 !important;
	white-space: normal;
`;

export const PriorityWrapper = styled.div`
	min-width: 10%;
	align-self: center;
	display: flex;

`;
export const Switch = styled(InputSwitch)`
	.p-inputswitch-slider {
		height: 25px;
		width: 48px;
	}
`;
export const SwitchWrapper = styled.div`
	display: -webkit-box;
	overflow: hidden;
	.switch-win-truth {
		font-size: 12px;
		line-height: 19px;
		bottom: 13px;
		left: 28px;
		svg {
			--fa-animation-iteration-count: 2;
		}
	}
	.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
		background: ${(props) => (props.active ? (props.type === "win" ? "#138A8A" : "#BD3DBF") : "#92919D")};
	}
	.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
		background: ${(props) => (props.active ? (props.type === "win" ? "#138A8A" : "#BD3DBF") : "#92919D")};

		:hover {
			background: ${(props) => (props.active ? (props.type === "win" ? "#138A8A" : "#BD3DBF") : "#92919D")};
		}
		:focus {
			box-shadow: none !important;
		}
	}
	/* .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
		background: ${(props) => colorCheck(props.type)};
		border: none !important;
	} */
	.p-inputswitch.p-focus .p-inputswitch-slider {
		box-shadow: none !important;
	}
`;
export const TextInfo = styled.div`
	max-width: ${(props) => (props.stage === "true" ? "10rem" : "18rem")};
	cursor: ${(props) => (props.desactivateclick === "true" ? "default" : "pointer")};
	@media screen and (max-width: 1290px) {
		max-width: ${(props) => (props.stage === "true" ? "3rem" : "8rem")};
		min-width: ${(props) => (props.stage === "true" ? "4rem" : "8rem")};
	}
		font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: pointer;
	overflow: hidden;
	font-weight: 500 !important;
	white-space: normal;
`;
export const Status = styled.div`
	height: 100%;
	width: 110px;
	line-height: 54px;
	border-radius: 4px;
	text-align: center;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: ${(props) => props.colorcode};
	/* color:#5c5858; */
`;

export const ChangeColor = styled.div`
	color: ${(props) => props.color};
`;

export const Ranking = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 25px;
	max-width: 30px;
	max-height: 25px;
	background: #d8fafa;
	border: 1px solid #18aead;
	border-radius: 4px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #18aead;
`;
export const RatingContainer = styled.div`
	/* zoom: 0.5; */
	position: relative; // new
	/* display: -webkit-box;
	overflow: hidden; */
	.inner {
		position: absolute;
		transform: scale(0.8);
		transform-origin: 0 0;
	}
	.p-disabled {
		opacity: 1;
	}
`;
export const Center = styled.div`
	display: flex;
	justify-content: center;
`;
export const CenterCol = styled(Col)`
	display: flex;
	justify-content: center;
`;
export const AddNew = styled(Row)`
	background-color: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 4px;
	display: flex;
	cursor: pointer;

	svg {
		align-self: center;
		width: auto;
		max-width: none;
	}
`;
export const EllipsisText = styled.div`
	max-width: ${(props) => props.maxWidth}px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

`;
export const Redirect = styled(Link)`
	text-decoration: none;
	${(props) => props.isinactive === "true" && `pointer-events: none`}
`;
export const StyledName = styled.div`
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width:  calc(100% - 40px);
	overflow: hidden;
	display: inherit;

-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
align-self: center !important;
cursor: pointer;
/* @media screen and (max-width: 1290px) {
    max-width: 8rem;
} */

`
export const PersonaPosition = styled.div`
	position: absolute;
	bottom: -5px;
	right: -4px;
`;
export const FolderNameStyle = styled.div`
	line-height: 20px;
	color: #333333;
	max-width: 25rem;
	overflow: hidden;
	display: table-column;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	font-weight: 600 !important;
`;
export const Total = styled.div`
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #605f60;
`;
export const Number = styled.div`
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: rgb(51, 51, 51);
`;

export const Score = styled.div`
	max-width: 2rem;
	overflow: hidden;
	color: #f96209;
	text-align: right;
	font-family: Koulen;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const LibraryName = styled.div`
	cursor: pointer;

	overflow: hidden;
	color: #333333;
	display: -webkit-box;

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: ${(props) => (props.desactivateclick === "true" ? "default" : "pointer")};

`;

export const DescriptionWrapper = styled.div`
	overflow: hidden;
	color: #333333;
	text-overflow: ellipsis;
	align-self: center !important;

`;
export const Date = styled(Input)`
	display:${props => props.isundefined === 'true' ? 'none' : 'flex'};
	:focus {
		outline : none;
	}


`
export const DateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	${props => props.isundefined === 'true' && css`
	:hover ${Date} {
		display: flex;
	}
`}
`
