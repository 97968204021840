import { FlexAlignCenter, FlexAlignJustifyCenter, FlexColumn } from "shared/ui-basics/index";
import CustomLegend from "features/analytics/Components/CustomLegend";
import EmptyData from "features/analytics/Components/EmptyData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
import { topXSentimentByTouchpointDataSelector } from "../vocAnalysisDataSelectors";
import { ColorCircle, CustomTooltip } from "features/analytics/Style";

function SentimentByTouchpoints() {
	const { t } = useTranslation();
    const data = useSelector(topXSentimentByTouchpointDataSelector);

	const CustomRadialAxisTick = (props) => {
		const { payload, x, y, textAnchor } = props;
		// Format the axis numbers as needed
		const formattedValue = payload.value;

		return (
			<text x={x + 3} y={y} dy={7} textAnchor={textAnchor}>
				{formattedValue}
			</text>
		);
	};

	const RenderLegend = () => {
		const legendData = [
			{ color: "#0078E7", name: t("JS_148") },
			{ color: "#4BC766", name: t("CUSTOMER_SENTIMENT") },
		];
		return <CustomLegend data={legendData} />;
	};

	const RenderTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<CustomTooltip className="custom-tooltip p-2 " style={{ background: "white" }}>
					<FlexColumn>
						{payload[0]?.payload?.name}
						<FlexAlignCenter>
							<ColorCircle className="me-2" color="#0078E7" />
							{`${t("JS_148")}: ${payload[0]?.value?.toFixed(2)}`}
						</FlexAlignCenter>
						<FlexAlignCenter>
							<ColorCircle className="me-2" color="#4BC766" />
							{`${t("CUSTOMER_SENTIMENT")}: ${payload[1]?.value?.toFixed(2)}`}
						</FlexAlignCenter>
					</FlexColumn>
				</CustomTooltip>
			);
		}

		return null;
	};

	// PolarAngleAxis: display names of the touchpoints
	return (
		<>
			{data.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<RadarChart cx="50%" cy="50%" outerRadius="90%" data={data}>
						<PolarGrid />
						<PolarAngleAxis dataKey="name" />
						<PolarRadiusAxis angle={90} domain={[0, 5]} tickCount={6} tick={<CustomRadialAxisTick />} />
						<Radar dataKey="personaSentiment" stroke="#0078E7" fill="#0078E7" fillOpacity={0.4} />
						<Radar dataKey="contactSentiment" stroke="##4BC766" fill="##4BC766" fillOpacity={0.4} />
						<Legend
							layout="vertical"
							align="right"
							verticalAlign="top"
							wrapperStyle={{
								paddingLeft: "30px",
							}}
							content={<RenderLegend />}
						/>
						<Tooltip isAnimationActive={false} content={RenderTooltip} />
					</RadarChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="SENTIMENT_BY_TOUCHPOINT_REPORT_DESCRIPTION" icon="CHART-RADAR" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

SentimentByTouchpoints.displayName = "SentimentByTouchpoints";
export default SentimentByTouchpoints;
