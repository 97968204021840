import { isUserActionValid } from "@utils/helpers";
import { useSelector } from "react-redux";
import { BulkActionsGenerator } from "shared/components/BulkActionsGenerator";
import { BulkUpdateStatus } from "./components/bulk-update-status";
import { BulkUpdateTeams } from "./components/bulk-update-teams";
import { BulkUpdateWorkspaces } from "./components/bulk-update-workspaces";
import { t } from "i18next";

export const BulkActions = (selected, setSelected, onSuccess, setDisableBulkActions) => {
	const userInfo = useSelector((state) => state.auth.userInfo);
	const isStandard = userInfo?.UserSubscriptionPlanName.toLowerCase() === "standard";

	const actions = [];

	const selectedItemsIdsStr = selected.map((item) => item?.id).join("|");
	const filteredItems = selected?.filter((user) => isUserActionValid({ user, userInfo }));
	const filteredItemsIdsStr = filteredItems?.map((item) => item?.id)?.join("|");

	const areAllActionsValid = selectedItemsIdsStr.length === filteredItemsIdsStr.length;

	const params = {
		ids: filteredItemsIdsStr.split("|"),
		areAllActionsValid,
		onSuccess,
		setDisableBulkActions,
		successToast: areAllActionsValid ? t("USERS_BULK_ACTIONS_SUCCESS") : t("USERS_BULK_ACTIONS_PARTIAL_SUCCESS"),
	};

	if (filteredItemsIdsStr) {
		actions.push({
			renderCustomComponent: () => <BulkUpdateStatus {...params} />,
		});
		actions.push({
			renderCustomComponent: () => <BulkUpdateWorkspaces nselected={selectedItemsIdsStr.length} items={filteredItems} onSuccess={onSuccess} setDisableBulkActions={setDisableBulkActions} />,
		});
		if (!isStandard)
			actions.push({
				renderCustomComponent: () => <BulkUpdateTeams {...params} />,
			});
	}

	return <BulkActionsGenerator actions={actions} setSelected={setSelected} warningText="USER_ACTION_WARNING" />;
};
