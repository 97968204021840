import { updateUserType } from "@redux/index";
import { getAllActiveWorkspaces, selectQueryView, selectSelectedViewId } from "@redux/memoizedSelectors";
import { checkStyle } from "@utils/helpers";
import { EntityList, SubscriptionPlan, UserType } from "@utils/optionsSets/OptionSets";
import { TeamsColumn } from "features/grid-table/columns-components/teams-column";
import { WorkspacesColumn } from "features/grid-table/columns-components/workspaces-column";
import { GenericFilter, PickListSingleFilterTemplate } from "features/grid-table/components/TableSettings";
import UserDetails from "features/settings/manage-users/components/user-details";
import { t } from "i18next";
import { useSelector } from "react-redux";
import UserTypeDropdown from "shared/dropdowns/UserTypeDropdown";

export const GetUserColumns = (props) => {
	const context = props.context.split(".")[1];
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);
	const allTeamsObj = useSelector((state) => state.settings?.teams);
	const selectedViewId = useSelector((state) => selectSelectedViewId(state, EntityList.User, context));
	const queryView = useSelector((state) => selectQueryView(state, EntityList.User))?.getById(selectedViewId)?.columnSetting;
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const isLoggerAdmin = Number(userInfo.UserType) === UserType.admin;

	const teams = allTeamsObj ? Object.values(allTeamsObj)?.FormatFilterArray() : [];
    const workspaces = allActivesWorkspaces?.FormatFilterArray();

	const subPlans = [
		{ value: SubscriptionPlan.Enterprise, name: t("ENTERPRISE") },
		{ value: SubscriptionPlan.Expert, name: t("EXPERT") },
		{ value: SubscriptionPlan.Standard, name: t("STANDARD") },
	];
	const userTypes = [
		{ value: UserType.companyAdmin, name: t("SUPER_ADMIN") },
		{ value: UserType.admin, name: t("CS_53") },
		{ value: UserType.user, name: t("CS_52") },
		{ value: UserType.readOnly, name: t("READ_ONLY") },
	];
	const activationStatuses = [
		{ value: true, name: t("CS_48") },
		{ value: false, name: t("INACTIVE") },
	];

	const displaySubPlan = (planCode) => {
		if (planCode === 100000000) return t("BASIC");
		if (planCode === 100000001) return t("STANDARD");
		if (planCode === 100000002) return t("EXPERT");
		if (planCode === 100000003) return t("ENTERPRISE");
		if (planCode === 100000004) return t("EXPERT");
	};

	const getUserDetailsParams = (user) => {
		const params = {};
        if ((isLoggerAdmin && user?.userType === UserType.companyAdmin) || (userInfo.UserEmail.toLowerCase() === user?.loginName.toLowerCase())) {
            params.desactivateClick = true
            params.tooltipText = t("USER_ACTION_WARNING")
        }
        return params
	};

	return {
		userDetails: {
			header: t("CS_52"),
			field: "fullName",
			body: (user) => <UserDetails user={user} {...getUserDetailsParams(user)} />,
            sortable: true,
            filter: true,
            filterField: "fullName",
            showFilterOperator: false,
			style: checkStyle(queryView, "userDetails", "xxl"),
			className: "py-0",
		},
		role: {
			header: t("ROLE"),
			body: (user) => (user?.role ? user.role : "-"),
			field: "role",
			filterField: "role",
			style: checkStyle(queryView, "role", "m"),
			className: "py-0",
			filter: false,
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
		},
		userTypeName: {
			header: t("CS_59"),
			body: (user) =>
				UserTypeDropdown({
					user,
					handleUserTypeChange: ({ ...user }, userTypeCode) => {
						user.userType = Number(userTypeCode);
						updateUserType({ modifiedUser: user });
					},
				}),
			field: "userType",
			filterField: "userType",
			filterElement: (options) => GenericFilter(options, userTypes),
			filter: true,
			sortable: true,
			showFilterMatchModes: false,
			style: checkStyle(queryView, "userTypeName", "m"),
			className: "py-0",
		},
		teams: {
			header: t("TEAMS"),
			body: (user) => TeamsColumn({ user }),
			field: "teamIds",
			filterField: "teamIds",
			filterElement: (options) => PickListSingleFilterTemplate(options, teams, t("SELECT_TEAM")),
			filter: true,
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			style: checkStyle(queryView, "teams", "l"),
		},
		isActive: {
			header: t("ASPX_222"),
			body: (user) => (user.isActive ? "Active" : "Inactive"),
			field: "isActive",
			filterField: "isActive",
			filterElement:  (options) => PickListSingleFilterTemplate(options, activationStatuses, t("SELECT_STATUS")),
			filter: true,
			showFilterMatchModes: false,
			sortable: true,
			className: "py-0",
			style: checkStyle(queryView, "isActive", "s", "80px"),
		},
		subscriptionPlan: {
			header: t("PLAN_TYPE"),
			body: (user) => displaySubPlan(user.subscriptionPlan),
			field: "subscriptionPlan",
			filterField: "subscriptionPlan",
			filterElement: (options) => GenericFilter(options, subPlans),
			filter: true,
			sortable: true,
			style: checkStyle(queryView, "subscriptionPlan", "m"),
			className: "py-0",
			showFilterOperator: false,
			showFilterMatchModes: false,
		},
		workspaces: {
			header: t("WORKSPACES"),
			body: (user) => WorkspacesColumn({ user }),
			field: "workspaceIds",
			filterField: "workspaceIds",
			filterElement: (options) => PickListSingleFilterTemplate(options, workspaces, t("SELECT_WORKSPACES")),
			filter: true,
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			style: checkStyle(queryView, "workspaces", "s"),
		},
	};
};
