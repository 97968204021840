import { upsertMeasure, upsertValueLibrary } from "@redux/index";
import { EMPTY_GUID, createDefaultCustomLibraryItem, encode } from "@utils/helpers";
import { UserType } from "@utils/optionsSets/OptionSets";
import { ColorTextCard } from "features/forms/personas/cards/ColorTextCard";
import { upsertMeasureService } from "features/index";
import { InputTextarea } from "primereact/inputtextarea";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Label } from "reactstrap";
import SearchPickList from "shared/cx-components/SearchPickList";
import { GenericButton, IconDispatcher, Input } from "shared/ui-basics/index";
import { FinancialImpact } from "shared/ui-basics/ui-components/FinancialImpact";
import styled from "styled-components/macro";

const getDefaultItem = () => {
	return {
		id: EMPTY_GUID,
		systemSourceId: EMPTY_GUID,
		name: "",
		unit: "",
		source: "",
		financialValue: 0.00,
	};
};

export default function MeasureForm({ selectedItem, mode, onDelete, onClose, hideCreateModal }) {
	const { t } = useTranslation();
	const refOver = useRef();

	const userType = useSelector((state) => state.auth.userInfo.UserType);
	const systemsLibrary = useSelector((state) => state.libraries.customLibraries)?.find((library) => library.name === "Systems" && !library.isCustom);
	const systems = systemsLibrary?.values;
	const currencySymbol = useSelector((state) => state?.settings?.organization?.defaultCurrency?.symbol) || "$";

	const [measure, setMeasure] = useState(selectedItem ? selectedItem : getDefaultItem());
	const relatedSystem = systems?.find((system) => system.id === measure.systemSourceId);
	const isDisabled = userType === UserType.user;

	const eventEnterLimit = (e) => {
		const keyCode = e.which || e.keyCode;

		// 13 r. the Enter key
		if (keyCode === 13 && !e.shiftKey) {
			// Don't generate a new line
			e.preventDefault();
		}
	};

	const handleChange = (e) => {
		if (e.target.value.length > 190) {
			toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
		}
		setMeasure((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value.truncate(190),
		}));
	};

	// Handle create / update actions
	const handleAction = () => {
		if (measure.name === "") return toast.info(t("NAME_EMPTY"));
		const copyMeasure = { ...measure };

		upsertMeasure({
			measure: {
				...copyMeasure,
				name: encode(copyMeasure.name),
				unit: encode(copyMeasure.unit),
				source: encode(copyMeasure.source),
				financialValue: Number(copyMeasure.financialValue),
				baseUnit:  copyMeasure?.baseUnit ? Number(copyMeasure.baseUnit)  : 0,
			},
		});
		onClose ? onClose() : setMeasure(getDefaultItem());
		hideCreateModal && hideCreateModal();
	};

	const CustomAddButton = ({ handleClick, disabled }) => {
		return (
			<GenericButton className="" variant="dashed-light-link" size="xl" icon="SQUARE_PLUS" iconClassName="me-1" onClick={handleClick} disabled={disabled}>
				{t("SOURCE")}
			</GenericButton>
		);
	};

	const handleCreateSystem = ({ globalFilterValue }) => {
		const system = createDefaultCustomLibraryItem("#FCA8CE");
		system.name = encode(globalFilterValue);
		upsertValueLibrary({ libraryId: systemsLibrary?.id, libraryValue: system, onSuccess: (data) => setMeasure({ ...measure, systemSourceId: data.id }) });
		return;
	};
	const handleChangeFinancialImpact = (value) => {
		setMeasure((prevState) => ({
			...prevState,
			financialValue: value,
		}));
	};


	const handleDelete = () => {
		onClose();
		onDelete({ onSuccess: () => {} });

	}

	return (
		<div>
			<Wrapper>
				<LabelWrapper>{t("JS_53")}</LabelWrapper>
				<TextareaWithIcon
					onKeyDown={eventEnterLimit}
					placeholder={t("NEW") + " " + t("MEASURE") + "..."}
					id={"textarea_" + measure.id}
					value={measure?.name || ""}
					name="name"
					onChange={handleChange}
					autoResize
					disabled={isDisabled}
				/>
				<IconWrapper>{IconDispatcher("CHART", "", { style: { color: mode === "update" ? "#F96209" : "#C4C4C4" } })}</IconWrapper>
			</Wrapper>
			<Wrapper>
				<LabelWrapper>{t("UNIT_OF_MEASURE")}</LabelWrapper>
				<Input variant="default" value={measure?.baseUnit} name="baseUnit" onChange={handleChange} disabled={isDisabled} type="number" step="0.01" />
			</Wrapper>
			<Wrapper>
				<LabelWrapper>{t("UNIT")}</LabelWrapper>
				<TextareaWithIcon
					onKeyDown={eventEnterLimit}
					placeholder={t("NEW") + " " + t("UNIT") + "..."}
					id={"textarea_" + measure.id}
					value={measure?.unit || ""}
					name="unit"
					onChange={handleChange}
					autoResize
					disabled={isDisabled}
				/>
				<IconWrapper>{IconDispatcher("CHART", "", { style: { color: mode === "update" ? "#F96209" : "#C4C4C4" } })}</IconWrapper>
			</Wrapper>
			<Wrapper>
				<LabelWrapper>{t("FINANCIAL_VALUE")}</LabelWrapper>

				<FinancialImpact financialImpact={measure?.financialValue} currencySymbol={currencySymbol} handleFinancialImpactChange={handleChangeFinancialImpact} noLabel />
			</Wrapper>
			<Wrapper>
				<LabelWrapper>{t("SOURCE")}</LabelWrapper>
				{!relatedSystem && (
					<GenericButton className="" variant="dashed-light-link" size="xl" icon="SQUARE_PLUS" iconClassName="me-1" onClick={(e) => refOver.current.toggle(e)} disabled={isDisabled}>
						{t("SOURCE")}
					</GenericButton>
				)}

				<SearchPickList
					ref={refOver}
					currentItem={measure}
					allItems={systems}
					idAttribute="id"
					handleCreate={handleCreateSystem}
					handleLinkRecord={({ recordId }) => setMeasure({ ...measure, systemSourceId: recordId })}
					isPickListCardDisabledFunction={({ pickListCardData }) => pickListCardData.id === measure.systemSourceId}
					CustomAddButton={CustomAddButton}
					noItemsText={t("NO_SYSTEMS_WARNING")}
					titleAttribute="name"
					disable={isDisabled}
				/>
				{relatedSystem && (
					<ColorTextCard
						text={relatedSystem.name}
						color={relatedSystem.colorCode}
						handleClick={(e) => refOver.current.toggle(e)}
						width="100%"
						height="2.25rem"
						fontSize="14px"
						className="form-control"
					/>
				)}
			</Wrapper>

			{mode === "update" ? (
				<ButtonWrapper>
					<FooterDiv IsDeleteAllowed={!isDisabled}>
						{!isDisabled && (
							<GenericButton variant="outline-danger" className="ms-1 d-flex align-items-center" icon="TRASH" onClick={handleDelete} iconClassName="me-1">
								{t("ASPX_13")}
							</GenericButton>
						)}
						<div>
							<GenericButton variant="primary" className=" me-2 align-self-end" onClick={handleAction} disabled={isDisabled}>
								{t("ASPX_12")}
							</GenericButton>
						</div>
					</FooterDiv>
				</ButtonWrapper>
			) : (
				<GenericButton variant="primary-outline" className="btn-block w-100" disabled={false} onClick={handleAction}>
					{t("ASPX_108")}
				</GenericButton>
			)}
		</div>
	);
}

const Wrapper = styled.div`
	margin-bottom: 24px;
	position: relative;
`;

const FooterDiv = styled.div`
	padding-top: 1rem;
	display: flex;
	justify-content: ${(props) => (props.IsDeleteAllowed ? "space-between" : "flex-end")};
	width: 100%;
`;

const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;
export const TextareaWithIcon = styled(InputTextarea)`
	padding-left: 2rem !important;
	width: 100%;
	min-height: 50px;
	/* height:auto !important; */
`;
export const IconWrapper = styled.div`
	position: absolute;
	top: 53px;
	left: 10px;
`;
