import { IconDispatcher } from "shared/Icons";
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { getBrandValueIcon } from "@utils/helpers";
import styled, { css } from "styled-components/macro";
import React from "react";

export const BrandValueSelectCard = ({ object, handleRemove, isClickable = true, showBorders, disabled }) => {
	const isRemovable = handleRemove !== undefined;
	const handleDelete = (e) => {
		e.stopPropagation();
		handleRemove(object);
	};
	return (
		<Card showborders={showBorders} isremovable={isRemovable} isclickable={isClickable} disabled={disabled}>
			<ImageIcon className="ms-2">{IconDispatcher(getBrandValueIcon(object?.number))}</ImageIcon>
			<FlexBetweenAlignCenter className="w-100">
				<Name className="ms-2 p-2" showborders={showBorders}>
					{object?.name}
				</Name>
				{isRemovable && (
					<RemoveButton className="me-2" onClick={handleDelete}>
						{IconDispatcher("X")}
					</RemoveButton>
				)}
			</FlexBetweenAlignCenter>
		</Card>
	);
};

const RemoveButton = styled.div`
	justify-self: end;
	cursor: pointer;
	display: none;
`;

const Card = styled.div`
	display: flex;
	align-items: center;
	overflow-x: hidden;
	${(props) =>
		!props.isremovable &&
		!props.disabled &&
		props.isclickable &&
		css`
			:hover {
				background-color: #d9d9d9;
			}
		`}

	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}

	${(props) =>
		props.showborders &&
		css`
			border-radius: 0.25rem;
			border: 1px solid #c4c4c4;
		`}

    :hover ${RemoveButton} {
		display: flex;
	}
`;

const ImageIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.2rem;
	height: 1.2rem;
`;

const Name = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	${(props) =>
		props.showborders &&
		css`
			border-radius: 0.25rem;
			border-left: 1px solid #c4c4c4;
		`}
`;
