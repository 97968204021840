import BookWizard from "@Assets/All/Book-Wizard.svg";
import { ReactComponent as Detailed } from "@Assets/All/Detailed.svg";
import HatWizard from "@Assets/All/Hat-Wizard.svg";
import { ReactComponent as HighLevel } from "@Assets/All/HighLevel.svg";
import { ReactComponent as BluePrint } from "@Assets/All/ServiceBlueprint.svg";
import { ReactComponent as Template } from "@Assets/All/template-map-creation.svg";
import { FlexAlignJustifyCenter, FlexColumnGap, GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Style from "./Style.js";

import { IframeModal } from "shared/modals/index";
import { CreateMapWithTemplate } from "features/journey-map/reducer/CJMActions.js";
import { EmptyListContainer, EmptyListImg, EmptyListText } from 'shared/ui-basics/index';
import ImageCard from "features/journey-map/creation/card-image-template.jsx";
import { isSubscriptionAllowed, isUserAllowed } from "@utils/helpers";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useModal } from "use-modal-hook";
import { base } from "../../../index.js";
import { StatusCode } from "@utils/optionsSets/OptionSets.js";
import { createAction } from "@reduxjs/toolkit";

export default function MapType(props) {
	const history = useHistory();
	const { t } = useTranslation();

	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const [key, setKey] = useState("cemantica");
	const [isAnimating, setIsAnimating] = useState(false);

	const selectType = (id) => {
		props.setNewMap({ ...props.newMap, mapMode: id ? id : "100000002" });
		props.nextStep();
	};
	const CemTemplate = GetCemTemplate(props.newMap, selectType);
	const OrgTemplate = GetOrgTemplate(props.newMap, history, props?.folderId);

	return (
		<Style.StepWrapper>
			<Style.BackButton variant="primary-link" onClick={history.goBack}>
				{IconDispatcher("ARROW_LEFT", "me-1")}
				{t("ASPX_132")}
			</Style.BackButton>
			<Style.ImageContainer>
				{key === "cemantica" ? (
					<Style.MainImage1 isAnimating={isAnimating} src={HatWizard} onAnimationEnd={() => setIsAnimating(false)} />
				) : (
					<Style.MainImage2 src={BookWizard} isAnimating={isAnimating} onAnimationEnd={() => setIsAnimating(false)} />
				)}
			</Style.ImageContainer>
			<FlexColumnGap gap="24">
				<Style.Title>{t("MAP_CREATION_TITLE")}</Style.Title>
				<Style.SubTitle>{t("MAP_CREATION_SUBTITLE")}</Style.SubTitle>
				<FlexAlignJustifyCenter>
					<GenericButton variant="primary" icon="WAND" iconClassName="me-1" size="m" onClick={props.nextStep}>
						{t("QUICK_START")}
					</GenericButton>
				</FlexAlignJustifyCenter>
			</FlexColumnGap>

			<Tabs
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
					setIsAnimating(true);
				}}
				className="m-3"
				mountOnEnter={true}
				unmountOnExit={true}>
				<Tab eventKey="cemantica" title={t("CEMANTICA")}>
					{CemTemplate}
				</Tab>
				{isSubscriptionAllowed(subscriptionPlan, "regular") && !props.newMap.isTemplate && (
					<Tab eventKey="organization" title={t("YOUR_ORGANIZATION")}>
						{OrgTemplate}
					</Tab>
				)}
			</Tabs>
		</Style.StepWrapper>
	);
}

const GetCemTemplate = (newMap, updateType) => {
	const { t } = useTranslation();
	const [showModal] = useModal(IframeModal);

	const template = [
		{ id: "100000001", image: Detailed, title: t("ASPX_117"), description: t("ASPX_131"), wizard: true, useButton: true, active: newMap.type === "100000001",img: require("@Assets/preview-images/detailed.png").default },
		{ id: "100000003", image: BluePrint, title: t("ASPX_256"), description: t("ASPX_257"), wizard: true, useButton: true, active: newMap.type === "100000003" ,img: require("@Assets/preview-images/blueprint.png").default},
		{ id: "100000000", image: HighLevel, title: t("ASPX_116"), description: t("ASPX_130"), wizard: true, useButton: true, active: newMap.type === "100000000",img: require("@Assets/preview-images/high-level.jpg").default},
	];

	const preview = (temp) => {
			showModal({
				title: "PREVIEW",
				add: updateType,
				objectId: temp.id,
				img:temp.img
			});
	};
	return (
		<div className="d-flex justify-content-center mt-4 ">
			{template.map((types) => (
				<ImageCard key={types.id} object={types} add={updateType} preview={preview} />
			))}
		</div>
	);
};

const GetOrgTemplate = (newMap, history, folderId) => {
	const template = useSelector((state) => state.maps.all_maps).filter((map) => map.isTemplate === true && map.statusCode===StatusCode.Active && map.templateStatus === 100000001);
	const userType = Number(useSelector((state) => state.auth?.userInfo?.UserType));
	const subscriptionPlan = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName);
	const isSubscriptionAllowed = ["Expert", "Enterprise"].includes(subscriptionPlan);
	const dispatch = useDispatch();
	const [showModal] = useModal(IframeModal);
	const templateTransform = template?.map((temp) => {
		return { id: temp.cjmId, image: Template, title: temp.name, description: temp?.description || "", useButton: true, wizard: true };
	});
	const handleCreationTemplate = (id) => {
		const templateRelevant = template.find((tmp) => tmp.cjmId === id);
		const SetCreationCJMParams = createAction("SET_CREATION_CJM_PARAMS")
		dispatch(SetCreationCJMParams({cjmId: id, name: templateRelevant.name, description:"",personaId:templateRelevant?.linkedPersonas[0]?.personaId } ))

		CreateMapWithTemplate({ cjmId: id, name: templateRelevant.name, history: history, folderId });
	}

	const preview = (temp) => {
		const templateRelevant = template.find((tmp) => tmp.cjmId === temp.id);

		showModal({
			title: "PREVIEW",
			url: `${base}EditMap?cjmid=${templateRelevant.cjmId}&personaId=${templateRelevant?.linkedPersonas[0]?.personaId || "00000000-0000-0000-0000-000000000000"}&ispreview=true`,
			add:handleCreationTemplate,
			objectId: temp.id,
		});
	};
	return (
		<div className="d-flex flex-wrap justify-content-center  mt-4 ">
			{templateTransform?.map((temp) => (
				<ImageCard key={temp.id} object={temp} add={handleCreationTemplate} preview={preview} />
			))}
			{templateTransform.length === 0 && (
				<EmptyListContainer className="p-0">
					{<EmptyListImg src={require("@Assets/images/EmptyListImages/no-maps.svg").default} alt="no maps" />}
					<EmptyListText>{t("NO_ORG_TEMPLATE")}</EmptyListText>
					{isSubscriptionAllowed && isUserAllowed(userType, "template") && (
						<Style.GOTemplate tag={Link} to="/templates">
							{t("GO_TEMPLATE")}
						</Style.GOTemplate>
					)}
				</EmptyListContainer>
			)}
		</div>
	);
};
