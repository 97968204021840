import { FlexAlignCenter } from "shared/ui-basics/index";
import { ColorCircle } from "features/analytics/Style";
import styled from "styled-components";

function CustomTooltipById({ payload, allItems }) {
	return (
		<TooltipContainer className="p-2">
			{payload.map((entry) => {
				const entity = allItems.find((item) => item.id === entry.dataKey);
				if (entity)
					return (
						<FlexAlignCenter className="mb-1" key={entry.dataKey}>
							<ColorCircle className="me-2" color={entry.stroke} />
							<Name>{`${entity?.name}: ${entry?.value?.toFixed(2)}`}</Name>
						</FlexAlignCenter>
					);
			})}
		</TooltipContainer>
	);
}

CustomTooltipById.displayName = "CustomTooltipById";
export default CustomTooltipById;

const TooltipContainer = styled.div`
	max-width: 400px;
	background: #fff;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
`;

const Name = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem;
`;
