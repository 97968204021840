import { getSessionId, makeGetTouchpoint } from '@redux/memoizedSelectors';
import { pictureValidation } from '@utils/helpers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Labels, PictureContainer, TouchPointLayout } from '../personaInteractions_style';


function HeaderTouchpoints({ touchPointId }) {

    const { t } = useTranslation();

    const touchpoints = useSelector(makeGetTouchpoint(touchPointId))
    const sessionId = useSelector(getSessionId);

    const picture = pictureValidation(touchpoints, sessionId);
    const { name } = touchpoints || {};

    return <div>
        <Labels>{t("TOUCHPOINT")}:</Labels>
        <TouchPointLayout className='pt-3'>
            <PictureContainer>
                <img src={picture} alt={name.defaultPlaceholder("NEW_TOUCHPOINT")} />
            </PictureContainer>
            <div className='name ellipsis'>{name}</div>
        </TouchPointLayout>
    </div>
}


HeaderTouchpoints.propTypes = {
    touchpointId: PropTypes.string
};

HeaderTouchpoints.displayName = "HeaderTouchpoints";
export default HeaderTouchpoints;