export const editRoutes = ["/EditMap", "/EditPersona", "/EditCxProgram", "/edit-solution", "/edit-opportunity", "/edit-cxactions", "/EditProject","/map-creation-loading", "/analytics"];

export const librariesRoutes = [
	"/library-touchpoints",
	"/library-stickers",
	"/library-custom",
	"/library-brandvalues",
	"/library-measures",
];

export const inactiveTrashRoutes = [
	{
		route: "/archive",
		text: "ARCHIVE_NAV",
		allowPopover: true,
		access: ["SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/trash",
		text: "ASPX_206",
		allowPopover: false,
		access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
	},
];


export const settingsfooterRoutes = [
	{
		route: "/organization",
		text: "ASPX_209",
		allowPopover: true,
		access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/configuration",
		text: "ASPX_211",
		allowPopover: true,
		access: ["SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/workspaces",
		activeRoutes: ["/workspaces", "/edit-workspace"],
		text: "WORKSPACES",
		allowPopover: true,
		access: ["Enterprise"],
	},
	{
        route: "/users",
        activeRoutes: ["/users", "/edit-user"],
		text: "ASPX_210",
		allowPopover: false,
		access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/teams",
		text: "TEAMS",
		allowPopover: true,
		access: ["SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/transfer-ownership",
		text: "TRANSFERT_OWNERSHIP",
		allowPopover: true,
		access: ["SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/libraries",
		text: "LIBRARIES",
		allowPopover: false,
		access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/templates",
		text: "TEMPLATES",
		allowPopover: true,
		access: ["SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/customization",
		text: "CUSTOMIZATION",
		allowPopover: false,
		access: ["Standard","SharedExpert", "Expert", "Enterprise"],
	},
	{
		route: "/integration",
		text: "INTERGRATION_CENTER",
		allowPopover: true,
		access: ["Enterprise"],
	},
];
export const allRoutes = {
	top: [
		{
			route: "/home",
			text: "HOME",
			icon: "house",
			allowPopover: false,
			iconcolor: "#262626",
			access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
        },
        {
			route: "/analytics?tab=journeytrends",
			text: "REPORTS",
			icon: "CHART-SIMPLE",
			allowPopover: true,
			iconcolor: "#262626",
			access: ["Enterprise"],
		},
		{
			route: "/cx-program",
			text: "CX_PROGRAMS",
			icon: "CX-PROGRAM",
			allowPopover: true,
			linkedAttribute: "linkedCxPrograms",
			activeRoutes: ["/cx-program", "/EditCxProgram"],
			iconcolor: "#262626",
			access: ["SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/personas",
			text: "ASPX_98",
			icon: "PERSONA",
			allowPopover: false,
			linkedAttribute: "linkedPersonas",
			activeRoutes: ["/personas", "/EditPersona"],
			iconcolor: "#262626",
			access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/journey-map",
			text: "JS_71",
			icon: "CJM",
			allowPopover: false,
			linkedAttribute: "linkedCjms",
			activeRoutes: ["/journey-map", "/EditMap"],
			iconcolor: "#262626",
			access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/insights",
			text: "ASPX_33",
			icon: "GEM",
			allowPopover: false,
			linkedAttribute: "linkedInsights",
			activeRoutes: ["/insights", "/EditInsight"],
			iconcolor: "#262626",
			access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/opportunity",
			text: "OPPORTUNITIES",
			icon: "OPPORTUNITY",
			allowPopover: true,
			linkedAttribute: "linkedOpps",
			activeRoutes: ["/opportunity", "/edit-opportunity"],
			iconcolor: "#262626",
			access: ["SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/solutions",
			text: "SOLUTIONS",
			icon: "SOLUTION",
			allowPopover: true,
			linkedAttribute: "linkedSols",
			activeRoutes: ["/solutions", "/edit-solution"],
			iconcolor: "#262626",
			access: ["SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/cx-actions",
			text: "JS_74",
			icon: "MENU-ACTION",
			allowPopover: true,
			linkedAttribute: "linkedActions",
			activeRoutes: ["/cx-actions", "/edit-cxactions"],
			iconcolor: "#262626",
			access: ["SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: "/projects",
			text: "PROJECTS",
			icon: "PROJECT",
			allowPopover: true,
			linkedAttribute: "linkedProjects",
			activeRoutes: ["/projects", "/EditProject"],
			iconcolor: "#262626",
			access: ["SharedExpert", "Expert", "Enterprise"],
		},
		{
			readOnlyMode: true,
		},
	],
	footer: [
		{
			route: null,
			text: "DISABLED_RECORDS",
			icon: "SIDEBAR_TRASH",
			allowPopover: false,
			iconcolor: "#262626",
			access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
		},
		{
			route: null,
			text: "ASPX_207",
			icon: "SETTINGS",
			allowPopover: false,
			iconcolor: "#262626",
			access: ["Standard", "SharedExpert", "Expert", "Enterprise"],
		},
	],
};
export const publicRoutes = ["login-page", "authenticate", "login", "logout", "invalid-url", "authenticationError"];
export const existingRoutes = [
	"journey-map",
	"home",
	"EditMap",
	"sample-data",
	"personas",
	"folders",
	"map-hierarchy",
	"trash",
	"templates",
	"archive",
	"cx-actions",
	"organization",
	"users",
	"edit-user",
	"teams",
	"transfer-ownership",
	"configuration",
	"libraries",
	"library-touchpoints",
	"library-stickers",
	"library-custom",
	"library-brandvalues",
	"library-measures",
	"customizations",
	"integration",
	"edit-workspace",
	"customization",
	"workspaces",
	"search",
	"EditPersona",
	"edit-opportunity",
	"edit-solution",
	"edit-cxactions",
	"EditCxProgram",
	"EditProject",
	"edit-cx-assessment",
	"map-creation",
	"persona-creation",
	"training",
	"cx-program",
	"opportunity",
	"solutions",
	"projects",
	"insights",
	"analytics",
	"edit-persona-pending",
	"map-creation-loading",

];

export const allRoutesCombines = [...allRoutes.top, ...allRoutes.footer, ...inactiveTrashRoutes, ...settingsfooterRoutes];
