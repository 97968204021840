import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import { IconDispatcher } from "shared/Icons";
import { GenericButton } from "shared/ui-basics";
import { CustomOverlayPanel, DropdownContainer, ItemSelectorCard } from "./filters_style";

const FilterBy = ({ apply }) => {
	const { t } = useTranslation();
	const op = useRef();
	const listFilter = ["maps", "projects"];
	const currentFilterBy = useSelector((state) => state.analytics?.currentData?.currentFilterBy);
	const data = filterDetails(listFilter);
	const currentItem = data?.find((x) => x.filterName === currentFilterBy) || {};
	const onClickSelect = (e, filterName) => {
		op.current.toggle(e);
		const currentItem = data?.find((x) => x.filterName === filterName) || {};
		apply({ dataAttribute: "filterBy", selectedItems: currentItem?.filter, currentFilterBy: filterName });
	};

	return (
		<Col>
			<GenericButton variant="light" icon={currentItem?.icon} iconClassName="me-2" onClick={(e) => op.current.toggle(e)}>
				<div>{t(currentItem?.title)}</div>
			</GenericButton>
			<CustomOverlayPanel ref={op}>
				<DropdownContainer>
					{data?.map((filter) => {
						return (
							<ItemSelectorCard key={filter?.filter} className="my-2" onClick={(e) => onClickSelect(e, filter?.filterName)}>
								<div>{IconDispatcher(filter?.icon, "ms-2 me-2")}</div>
								<div>{t(filter?.title)} </div>
							</ItemSelectorCard>
						);
					})}
				</DropdownContainer>
			</CustomOverlayPanel>
		</Col>
	);
};

const filterDetails = (listFilter) => {
	return listFilter?.map((filter) => {
		const info = { filterName: filter };
		switch (filter) {
			case "maps":
				info.title = "FILTER_BY_JOURNEY_MAPS";
				info.icon = "CJM";
				info.filter = ["filterBy", "journeys"];
				break;
			case "projects":
				info.title = "FILTER_BY_PROJECTS";
				info.icon = "PROJECT-LIGHT";
				info.filter = ["filterBy", "projects", "dateRange"];

				break;
		}
		return info;
	});
};
export default FilterBy;
