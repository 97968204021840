import { useState } from 'react';

import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { GenericButton } from 'shared/ui-basics/buttons';
import { validateEmail } from '../helpers';
import { resetPassword } from "../reducer/AuthActions";
import { ButtonForgetPassword, Contact, DescResetPassword, Forms, Label, LayoutAuth, ResetModal, Title } from '../view/auth-style';
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";

const ResetStep = (props) => {

    const { t } = useTranslation()
    const [errors, setErrors] = useState({ email: '' });
    const [loading, setLoading] = useState(false);

    const onSuccess = (response) => {
        if (response) {
            toast.info(t("RESET_PASSWORD_EMAIL_SENT"))
            props.previousStep()
        } else if (response === false) {
            setErrors({ email: t("RESET_PASSWORD_EMAIL_NOT_SENT") });
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const formValues = new FormData(e.currentTarget);
        const { email } = Object.fromEntries(formValues.entries())
        const isValidEmail = validateEmail(email);

        if (!isValidEmail) return setErrors({ email: 'Invalid email' });

        setErrors({ email: '' });
        resetPassword({ email, setLoading, onSuccess });
    };

    return <ResetModal>
        <LayoutAuth gap={20}>
            <Title>{t('FORGET_PASSWORD')}</Title>
            <Forms onSubmit={onSubmit} >
                <Form.Group md="4" controlId="validationEmail">
                    <Label className='mt-3'>{t('EMAIL')}</Label>
                    <InputGroup hasValidation>
                        <Form.Control type="text" name="email" className="position-relative" isInvalid={!!errors.email} aria-describedby="email-v" />
                        <Form.Control.Feedback style={{ fontSize: '12px' }} type="invalid" tooltip>{errors.email} </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <div className='mt-2' id='email-v'>
                    <div>
                        <DescResetPassword>{t("DESC_RESET_PASSWORD")}</DescResetPassword>
                    </div>
                    <div>
                        <DescResetPassword>
                            {t("DESC_RESET_PASSWORD_2")}
                            <Contact href="mailto:support@cemantica.com">support@cemantica.com</Contact>
                        </DescResetPassword>
                    </div>
                </div>
                <FlexBetweenAlignCenter className='mt-4' >
                    <ButtonForgetPassword onClick={() => props.previousStep()}>{t('LOGIN')}</ButtonForgetPassword>
                    <GenericButton variant='primary' type='submit' loading={loading} className='px-3'>{t('RESET_PASSWORD')}</GenericButton>
                </FlexBetweenAlignCenter>
            </Forms>
        </LayoutAuth>
    </ResetModal>
}
export default ResetStep;