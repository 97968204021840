export const getPrompt = {
  PERSONA_SECTION: ({ cardName, personaName, occupation, age, gender, originalContent }) => `Base on the persona ${personaName} and the section ${cardName}, as a CX expert I want to generate a text that will help me to fill the section ${cardName} with relevant content. Details on Persona: Name:${personaName} , profession: ${occupation}, age: ${age} Gender:${gender}. you're response should be to the point and not more than 200 words. Do not mention that you're a CX expert. if the ${cardName} is for example Interest, provide straight the interest of the persona. if the ${cardName} is for example Frustrations, provide straight the frustrations of the persona. ${originalContent ? `Here is the original content you can be inspired about ${originalContent}` : ''} [End].`,
  PERSONA_CREATION_EXTRACT_DETAILS: ({prompt}) => {
    return `Analyze the given text: "${prompt}".
    Your task is to identify and extract specific details for the creation of persona as a CX professional, including the person's name, age, profession, gender, language, context, and location.
    Use the provided text to determine the "Language" field, and infer the gender based on the name, defaulting to "Man", "Woman", or "Other" if the gender is not explicitly mentioned or cannot be inferred.
    For any missing information, use "Unknown". Structure your response in JSON format.

    Note: The "Language" field should represent the language in which the text is written.
    The "Context" field should represent the situation or activity the person is involved in.

    Example input:
    "Robert Makerson is a Marketing manager taking a bank loan. He is 35 years old, he lives in New York."

    Expected output in a variable:
    {
      "name": "Robert Makerson",
      "age": "35",
      "profession": "Marketing Manager",
      "gender": "Man",
      "language": "English",
      "context": "Taking a Bank Loan",
      "location": "New York"
    };

    Example input:
    "Sarah is a hospital purchase manager buying medical equipment. She is 28 years old and lives in Paris."

    Expected output in a variable:
    {
      "name": "Sarah",
      "age": "28",
      "profession": "Hospital Purchase Manager",
      "gender": "Woman",
      "language": "English",
      "context": "Buying Medical Equipment",
      "location": "Paris"
    };

    Example input:
    "Jennifer london 26 product manager"
    Expected output in a variable:
    {
      "name": "Jennifer",
      "age": "26",
      "profession": "Product manager",
      "gender": "Woman",
      "language": "English",
      "context": "Unknown",
      "location": "London (UK)"
    };


    Ensure each field is accurately captured in the JSON structure: name, age, profession, gender, language, context, and location. If any detail is not mentioned, represent it with "Unknown".`
  },

  PERSONA_SECTION_TEXT: ({ cardName, personaName, occupation, age, gender, originalContent, language = 'English' }) => `Given the persona details and a section title, generate concise, relevant content tailored to the specified section for the persona. The response should be direct and under 200 words, closely fitting the theme of the section.

    **Persona Details:**
    - Name: ${personaName}
    - Profession: ${occupation}
    - Age: ${age}
    - Gender: ${gender}

    **Section Title:** ${cardName}

    **Original Content for Inspiration (if available):** ${originalContent ? `"${originalContent}"` : "Not provided"}
    **Your response has to be in ${language}:**

    **Guidance with Examples:**
    - Tailor your response to directly fit the section's theme. For instance:
      - If the section is "Interests", directly list the persona's interests.
      - If the section is "Frustrations", directly outline the persona's frustrations.
    - The aim is to fill the section with content that is immediately relevant and useful, avoiding unnecessary introductions or conclusions.

    Please craft your response with the above persona details and guidance in mind, ensuring it adheres to the word limit and directly addresses the section's theme.

    `,
  PERSONA_SECTION_TEXT_GROUP: ({ cardName, personaName, occupation, age, originalContent, language = 'English' }) => `Given the persona details and a section title, generate concise, relevant content tailored to the specified section for the persona. The response should be direct and under 200 words, closely fitting the theme of the section.The Persona represent a group of people.

    **Persona Details:**
    - Title: ${personaName}
    - Profession: ${occupation}
    - Age: ${age}

    **Section Title:** ${cardName}

    **Original Content for Inspiration (if available):** ${originalContent ? `"${originalContent}"` : "Not provided"}
    **Your response has to be in ${language}:**

    **Guidance with Examples:**
    - Tailor your response to directly fit the section's theme. For instance:
      - If the section is "Interests", directly list the persona's interests.
      - If the section is "Frustrations", directly outline the persona's frustrations.
    - The aim is to fill the section with content that is immediately relevant and useful, avoiding unnecessary introductions or conclusions.

    Please craft your response with the above persona details and guidance in mind, ensuring it adheres to the word limit and directly addresses the section's theme.

    `,
  PERSONA_SECTION_LIST_ITEM: ({ cardName, personaName, occupation,age,gender, language = 'English' ,existingItems}) => `Given the persona details, generate a single, concise section of content tailored specifically for the persona. This content should be relevant, direct, and under 50 words per item, aligning closely with the section's theme. Provide 3 to 5 items for this section.

    ${cardName === "" && " suggest a random section title that relates to the Persona Details. This could be professional (related to their profession) or personal (like hobbies), formatted as **Section Title: [title]**, with items pertinent to the suggested title."}
    ${cardName !== "" && ` Determine whether ${cardName} leans more towards a professional or personal theme. If personal, base items more on age and gender; if professional, focus items on the profession.
    But return all the items without mentioning if personal or professional in the following format: - [Description Idea]:[Description] \n - [Description Idea]:[Description]\n -[Description Idea]:[Description]\n etc.`}
    ${existingItems && `Here are the existing items for the section: \n ${existingItems} \n do not repeat the existing items. use them as inspiration to generate new items.`}
    **Persona Details:**
    - Name: ${personaName}
    - Profession: ${occupation}
    - Age: ${age}
    - Gender: ${gender}

    **Section Title:** ${cardName ? cardName : 'Suggest a section title based on the details provided.'}

    Your response must be in ${language}.

    **Guidance with Examples:**
    - Your response should be directly in line with the section's theme. For instance, if suggesting a "List of Interests," list the persona's interests like so:
      - [Interest 1]
      - [Interest 2]
      - [Interest 3]

    The objective is to fill the section with immediately relevant and useful content, avoiding superfluous introductions or conclusions. With the persona details and this guidance in mind, craft your response to adhere to the word limit while directly addressing the section's theme.

    `,
  PERSONA_SECTION_RATING: ({ cardName, personaName, occupation, age, gender, language = 'English' ,existingItems}) =>
    `Please generate a unique and insightful Rating section for the given persona, focusing on both common and uncommon professional qualities. Include 3 to 5 ratings, each named creatively with a numerical rating from 0 to 5.

    ${cardName.isEmptyString() && "generate a random creative section title that relates to the Persona Details. This could be professional (related to their profession) or personal (like Personal Interests), formatted as **Section Title: [title]**, with items pertinent to the suggested title."}
    ${!cardName.isEmptyString()&&  `Determine whether ${cardName} leans more towards a professional or personal theme. Based on ${cardName}, generate rating items that are directly related to the theme, emphasizing aspects relevant to ${occupation}, age, or gender. Format the items as: - [rating label]:[numerical rating]\n` }

    -The response must be written in ${language ?? 'English'}, not surpass 200 words, and follow this structure: '- [rating label]:[numerical rating]'. Do not return any description or details.
    -Ensure a new line for each  item.
    ${existingItems ? `Here are the existing items for the section: \n ${existingItems} \n Do not repeat the existing items. Use them as inspiration to generate new items.` : ""}

    **Persona Details:**

    - Name: ${personaName}
    - Profession: ${occupation}
    - Age: ${age}
    - Gender: ${gender}
    - Language: ${language}

    **Section Title:** ${cardName || 'Inventive'}

    Base the ratings on unique insights derived from the persona's profile and the section's theme, ensuring that the ratings are both relevant and insightful.
    ** Format:**
    ${cardName.isEmptyString()  &&  `**Section Title: [Title]**`}
    - [rating label]:[numerical rating]

    ${!cardName.isEmptyString()  &&  `Example Rating for "Lifestyle and Values":
    - Health and Wellness Concern: 4
    - Environmental Consciousness: 3
    - Value on Work-Life Balance: 3
    - Interest in Continuous Learning: 5`}

    ${cardName.isEmptyString() && `Example rating with Section title Not provided:
     **Section Title:  Digital Experience**
    - Website Navigation Ease : 4
    - Online Support Accessibility: 3
    - Digital Engagement: 3
    - App Usability: 2 `}


    Focus on revealing the persona's distinctive professional strengths and areas for development, omitting generic introductions or conclusions.
    `,
  PERSONA_SECTION_SLIDER : ({ cardName, personaName, occupation,age,gender,language ,existingItems}) => `
    Please generate a focused and pertinent Slider section for the specified persona, highlighting essential qualities within their professional sphere. Include 3 to 5 slider items, each with a minLabel, maxLabel, and a numerical rating from 0 to 10.

    ${cardName.isEmptyString() && "generate a random creative section title that relates to the Persona Details. This could be professional (related to their profession) or personal (like Personal Interests), formatted as **Section Title: [title]**, with items pertinent to the suggested title."}

    -The response must be written in ${language ?? 'English'}, not surpass 200 words, and follow this structure: '- [minLabel]:[numerical rating]:[maxLabel]'. Do not return any description or details.
    -Ensure a new line for each slider item.

    ${!cardName.isEmptyString()&&  `Determine whether ${cardName} leans more towards a professional or personal theme. Based on ${cardName}, generate slider items that are directly related to the theme, emphasizing aspects relevant to ${occupation}, age, or gender. Format the items as: - [minLabel]:[numerical rating]:[maxLabel]\n` }

    ${existingItems ? `Here are the existing items for the section: \n ${existingItems} \n Do not repeat the existing items. Use them as inspiration to generate new items.` : ""}

    Persona Details:

    -Name: ${personaName}
    -Profession: ${occupation}
    -Age: ${age}
    -Gender: ${gender}
    -Language: ${language}

    **Section Title: ${cardName || 'Not provided'}**

    The slider items should be closely related to the theme of the Section Title provided.

    **Guidance with Examples:**
    - If the section is "Personality," generate items that reflect the persona's personality traits.
    - If the section is "Brand Engagement," generate items that reflect the persona's brand engagement levels.
    - If the section is "Value Proposition," generate items that reflect the persona's value proposition preferences.
    - If the section is "Customer Engagement," generate items that reflect the persona's customer engagement levels.
    ** Format:**
    ${cardName.isEmptyString()  &&  `**Section Title: [Title]**`}
    - [minLabel]:[numerical rating]:[maxLabel]


    ${!cardName.isEmptyString()  &&  `Example Slider for "Brand Engagement":
    - Brand Indifferent: 3 : Brand Advocate
    - Low Brand Awareness: 2 : High Brand Awareness
    - Price Driven: 6 : Value Driven
    - Sporadic Engagement: 5 : Consistent Engagement`}

    ${cardName.isEmptyString() && `Example Slider for  with Section title Not provided:
     **Section Title: Personality**
    - Introvert: 3 : Extrovert
    - Pessimist: 2 : Optimist
    - Negative: 3 : Positive
    - Emotional: 4 : Rational`}


`,
  PERSONA_SECTION_IMAGE: ({ cardName }) => `
  Generate a realistic and detailed image that embodies the concept of '${cardName}' in its most quintessential form. This image should focus on a single scene or object that best represents the essence and direct interpretation of the title, without abstract elements or symbolic interpretations. Aim for clarity and precision in the depiction, highlighting the key features, environment, or aspects that are universally associated with the '${cardName}.' The visual should provide an immediate and clear understanding of the title, serving as a definitive visual representation that can be easily recognized and appreciated for its direct connection to the subject matter
    `,

  CJM_STAGE_DESCRITION: ({ stageName, mapName , language }) => `Generate a description for the ${stageName} stage for the journey map named ${mapName}. response in ${language} language. response in  JSON Format  {"description": "Your concise examples of ${stageName} description in HTML format"} `,
  CJM_STAGE_DESCRIPTION_IMAGE : ({ stageName }) => `create a very simple image of a real person that represent the ${stageName} stage.  The image should be simple, iconic  and easy to understand. and should be with a white theme fitable with different step.
  `,
   CJM_TEXT_SWIMLANES: ({ stageName, mapName, language, swimlanesName }) => `
  Generate examples or insights for a customer experience (CX) journey map. Focus on a specific stage and swimlane. Provide the information in bullet points or a paragraph, as expected by a CX professional.
  The response should be labeled "description" and begin directly with relevant content, without introductory sentences.
   Use concise language and ensure the response is under 200 words, contained within the "description" attribute.

  - Target subject : ${swimlanesName}
  - Stage name: ${stageName}
  - Map name: ${mapName}
  - Language for the response:  ${language}

 ** Requirement:**
 -  Do not repeat the Stage name and the Map name in the response.
 - response in  JSON Format  as follows: {"description": "Your concise examples of ${swimlanesName} or insights here in a bullet point or paragraph format using in HTML format."}

  `,
  CJM_TOUCHPOINTS: ({ stageName, mapName, existingTouchpoints }) => `Generate a list of touchpoints for the '${stageName}' stage in the journey map called '${mapName}'.
  pick between 2 - 5 the relevant one in the following list ${existingTouchpoints}.it has to relevant to the stage and the map. if none of the touchpoints are relevant, generate new ones.
  return straight {touchpoints: [your response]}.`,

}

