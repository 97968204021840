import { HubConnection } from "features/signalR/provider/hub-connection";
import { WorkspaceWatcher } from "features/workspace/components/watcher/workspace-watcher";
import { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "shared/components/PrivateRoute";
import { Spinner } from "shared/components/spinners/Spinner";
import { ColorPickerProvider, EditorProvider } from "shared/dynamic-imports";
import GlobalLayout from "shared/global-layouts/app-layouts";
import { useSubscriptionCheck } from "shared/hooks";
import { ModalProvider } from "use-modal-hook";
import { configHeaderAxios, filterRoutesAccess, updateDictionnaryAccess } from "./route-validation";

export default function PrivateRoutesValidation() {
	const { userInfo, token } = useSelector(({ auth }) => auth || {});
	const workspaces = useSelector((state) => state.workspaces.all_workspaces);

	const { UserSubscriptionPlanName = "", UserType = "", UserId } = userInfo || {};

	configHeaderAxios(token);

	useSubscriptionCheck(UserSubscriptionPlanName);
	const AccessDictionary = useMemo(() => updateDictionnaryAccess(UserId, UserType, workspaces), [UserId, UserType, workspaces]);
	const { lazyPages, nonLazyPages } = useMemo(() => filterRoutesAccess(AccessDictionary, UserSubscriptionPlanName), [AccessDictionary, UserSubscriptionPlanName]);
	return (
		<Switch>
			<Providers>
				<Suspense fallback={<Spinner />}>
					{lazyPages?.map((route, index) => (
						<PrivateRoute key={index} path={route.path} component={route.component} />
					))}
				</Suspense>
				{nonLazyPages.map((route, index) => (
					<PrivateRoute key={index} path={route.path} component={route.component} />
				))}
				<Route exact path="/" render={() => <Redirect to="/home" />} />
			</Providers>
		</Switch>
	);
}

const Providers = ({ children }) => (
	<ModalProvider>
		<HubConnection>
			<WorkspaceWatcher>
				<GlobalLayout>
					<EditorProvider>
						<ColorPickerProvider>{children}</ColorPickerProvider>
					</EditorProvider>
				</GlobalLayout>
			</WorkspaceWatcher>
		</HubConnection>
	</ModalProvider>
);
