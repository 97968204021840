import { GenericButton } from "shared/ui-basics/index";
import { GroupByMap } from "features/grid-table/components/GroupByMap.js";
import DataTable from "features/grid-table/DataTable";
import { EditorProvider } from "shared/dynamic-imports";
import { useAsyncs } from "shared/hooks/index";
import CxActionPage from "features/cx-actions/view/cx-actions-page";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { getAllAuthorizedUsers, getAllCxAction, getAllMapsListData, groupInit, priorityInit, statusInit, tagInit } from "@redux/index";
import { getFolderById, retrieveAllActiveActions } from "@redux/memoizedSelectors";
import { handleRedirectEntityList } from "@utils/helpers";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

export default function CxActions() {
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();
	const hideForPdf = search.hideForPdf();
	const folderId = search.getFolderId();
	const folderView = folderId === undefined ? false : true;
	const { linkedActions } = useSelector((state) => getFolderById(state, folderId), shallowEqual);
	const [key, setKey] = React.useState("list");
	const [query, setQuery] = React.useState(false);

	useEffect(() => {
		if (folderView) {
			setKey("list");
		}
	}, [folderView]);

	useAsyncs({
		asyncFns: [
			{ asyncFn: getAllMapsListData },
			{ asyncFn: getAllAuthorizedUsers },
			{ asyncFn: getAllCxAction },
			{ asyncFn: statusInit },
			{ asyncFn: groupInit },
			{ asyncFn: tagInit },
			{ asyncFn: priorityInit },
		],
	});

	const table = useSelector(({ ui }) => ui.table);
	const idRedirect = useSelector(retrieveAllActiveActions)?.[0]?.id;

	const handleClick = () => {
		const entityType = "100000005";
		const context = "cxActionsList";
		const route = "/edit-cxactions";

		if (idRedirect) {
			const response = handleRedirectEntityList({ entityType, context, route, idRedirect, history, table, displayList: true, workspaceView: true });
			setQuery(response);
			setKey("grid-view");
		}
	};

	const isShowViewButtons = () => {
		if (hideForPdf || folderView) return false;
		return true;
	};

	const renderViewButtons = (t) => {
		if (!isShowViewButtons()) return <></>;
		return (
			<>
				<GenericButton variant="light-link" className="ms-2" tooltip tooltipText={t("SHOW_ALL_CX_ACTIONS")} icon="SIDEBAR" active={key === "grid-view"} onClick={handleClick} />
				<GenericButton variant="light-link" className="ms-2" tooltip tooltipText={t("LIST_VIEW")} icon="LIST-VIEW" active={key === "list"} onClick={() => handleKeys("list")} />
				<GenericButton variant="light-link" className="ms-2" tooltip tooltipText={t("GROUP_BY_MAP")} icon="GROUP-BY" active={key === "byMap"} onClick={() => handleKeys("byMap")} />
			</>
		);
	};

	const renderView = () => {
		switch (key) {
			case "list":
				return <DataTable context="cxActions.cxActionsList" linked={linkedActions} />;
			case "byMap":
				return <GroupByMap />;
			case "grid-view":
				return (
					<EditorProvider>
						<CxActionPage query={query} setQuery={setQuery} />
					</EditorProvider>
				);
			default:
				return <></>;
		}
	};

	const handleKeys = (name) => {
		if (name !== "grid-view") setQuery(false);
		setKey(name);
	};

	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("JS_80")}`} renderViewButtons={renderViewButtons}>
			{renderView()}
		</PageTemplate>
	);
}
