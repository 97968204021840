import { getSessionId } from "@redux/memoizedSelectors";
import { EMPTY_GUID, isMapHasLinkedMaps, isUserAllowed } from "@utils/helpers";
import { StatusCode, urlOriginStatus } from "@utils/optionsSets/OptionSets";
import { getterApis } from "@utils/optionsSets/getterApis";
import { deleteMapImage, replaceMapLogo, updateCjmName } from "features/journey-map/reducer/CJMActions";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { EditLogoModal } from "shared/modals/index";
import { IconDispatcher } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import EditTitle from "../../../shared/components/EditableText";
import * as Style from "./Styles";

const MapName = ({ map, rename, setRename, isIconDisplayed = true, clickToOpen }) => {
	const nameRef = useRef();
	const { pathname, search } = useLocation();
	const history = useHistory();
	const folderId = search.getFolderId();
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const userType = Number(userInfo.UserType);
	const userAccessPrivilege = map?.authorizedUsers?.filter((user) => user.id === userInfo.UserId)[0]?.roleName;
	const isEditable = isUserAllowed(userType, "edit-cjm") && userAccessPrivilege !== "viewer";
	const [mapName, setName] = useState(map?.name);
	const sessionId = useSelector(getSessionId);
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allActiveMaps = useSelector((state) => state?.maps?.all_maps)?.getActiveRecords(!showSampleData);

	const mapCustomImageUrl = map?.isCustomImage ? getterApis["GET_MAP_CUSTOM_IMG"](map.cjmId, sessionId) : "";
    const [mapImage, setMapImage] = useState(mapCustomImageUrl);

	useEffect(() => {
		map.name !== mapName && setName(map.name);
    }, [map.name]);
    
	const ellipsis = nameRef.current?.offsetWidth < nameRef.current?.scrollWidth;
	const [isTruncated, setIsTruncated] = useState(false);
	const { cxProgramId } = search.getCxProps();

	const displayHierarchyIcon = isMapHasLinkedMaps(map.linkedCjms, map?.phases, map?.stages, allActiveMaps);
	const blank = pathname === "/libraries" ? "_blank" : "";
	const Link = `/EditMap?cjmid=${map.cjmId}&personaId=${map.linkedPersonas[0]?.personaId || EMPTY_GUID}&origin=1${folderId ? `&folderid=${folderId}` : ""}`;

	const updateMapIcon = async (icon) => {
		if (icon === "delete") {
			removeMapIcon();
		} else {
			const FileExtension = icon.split(";")[0].split("/")[1];
			const DocumentBody = icon.split(",").pop();

			if (FileExtension === "map") return;
			replaceMapLogo({ cjmId: map.cjmId, FileExtension, DocumentBody, onSuccess: () => setMapImage(getterApis["GET_MAP_CUSTOM_IMG"](map.cjmId, sessionId) + `&${Math.random()}=true`) });
		}
    };
    
	const removeMapIcon = async () => deleteMapImage({ cjmId: map.cjmId });
    
	const handleMouseEnter = () => {
		if (nameRef.current) {
			setIsTruncated(nameRef.current.scrollHeight > nameRef.current.clientHeight);
		}
	};
	const handleRedirect = (e) => {
		if (pathname === "/EditCxProgram") {
			e.preventDefault(); // Prevents the default Link behavior
			history.push(
				`/EditMap?cjmid=${map.cjmId}&personaId=${map.linkedPersonas[0]?.personaId || EMPTY_GUID}${cxProgramId ? `&origin=${urlOriginStatus.DefaultReturn}` : ""}${
					folderId ? `&folderid=${folderId}` : ""
				}`
			);
		}
	};
	const [showModalIcon] = useModal(EditLogoModal, {
		// setImage: updateMapIcon,
		deleteImage: removeMapIcon,
		updateImg: updateMapIcon,
		cjmIdStr: map.cjmId,
		sessionId: sessionId,
	});

	return (
		<div className="d-flex overflow-hidden align-items-center">
			{isIconDisplayed && (
				<CustomTooltip id={map.cjmId} text={t("ASPX_236")} disabled={map?.statusCode !== StatusCode.Active || !isEditable}>
					<Style.MapIconContainer className="me-2" disabled={map?.statusCode !== StatusCode.Active || !isEditable}>
						{map?.isCustomImage ? <img src={mapImage} loading={"lazy"} alt="" /> : IconDispatcher("MAP-DEFAULT")}
						{isEditable && (
							<Style.ChangeIconButton id="icon-map" onClick={showModalIcon}>
								{IconDispatcher("upload")}
							</Style.ChangeIconButton>
						)}
						{displayHierarchyIcon && <Style.HierarchyIcon>{IconDispatcher("MAP-HIERARCHY")}</Style.HierarchyIcon>}
					</Style.MapIconContainer>
				</CustomTooltip>
			)}
			{rename !== map.cjmId && (
				<CustomTooltip id={map.cjmId} text={mapName} disabled={!ellipsis ? true : false}>
					<Style.Redirect className="d-flex align-items-center" disableclick={`${!clickToOpen}`} onClick={handleRedirect} target={blank} to={Link}>
						<Style.StyledMapName ref={nameRef} title={isTruncated ? mapName : ""} onMouseEnter={handleMouseEnter}>
							{mapName}
						</Style.StyledMapName>
					</Style.Redirect>
				</CustomTooltip>
			)}
			{rename === map.cjmId && <EditTitle obj_id={map.cjmId} obj_name={mapName} updateName={updateCjmName} setName={setName} setRename={setRename} />}
		</div>
	);
};

export default MapName;
