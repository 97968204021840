import { BackgroundLabel } from "shared/ui-basics/ui-components/BackgroundLabel";
import { GenericButton } from "shared/ui-basics/buttons";
import React from "react";
import { useSelector } from "react-redux";

export default function InsightType({ object, typeId, className, isEdit, ...props }) {
	const type = useSelector(({ libraries }) => libraries?.insightTypes?.getById(typeId || object?.id));
	if (!type) return null;
	const handleRemove = (e) => {
		e.stopPropagation()
		props?.handleRemove(e);
	}

	return (
		<div className={"d-flex " + props?.classNames}>
			<BackgroundLabel id={typeId} size="xs" color={type?.colorCode} className={className} maxWidth={props.maxWidth} title={type?.name}>
				<div className="insight-name-creator ellipsis ">{type?.name}</div>
			</BackgroundLabel>
			{isEdit && props?.handleRemove && (
				<div>
					<GenericButton variant="light-link" className="remove-button" size="xs" icon="X" iconClassName="ms-1" onClick={handleRemove} />
				</div>
			)}
		</div>
	);
}
