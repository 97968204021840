import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";
import { Token } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TypeaheadWrapper } from "shared/modals/manage-access/ManageAccessStyle";
import styled from "styled-components/macro";
import WorkspaceLogoAndName from "./workspace-logo-and-name";

export const WorkspaceTypehead = ({ selected, onChange, nameWidth, disabled = false }) => {
	const { t } = useTranslation();
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);

	const MenuItem = (workspace) => (
		<div key={workspace?.id}>
			<WorkspaceLogoAndName workspace={workspace} size="xs" nameWidth={nameWidth} />
		</div>
	);

	const SelectedItem = (workspace, props) => (
		<div key={workspace?.id} className="me-2">
			<CustomToken key={workspace?.id} onRemove={props.onRemove} option={workspace} disabled={disabled}>
				<WorkspaceLogoAndName workspace={workspace} size="xs" />
			</CustomToken>
		</div>
	);

	return (
		<TypeaheadWrapper
			id="public-methods-example"
			labelKey="name"
			multiple
			options={allActivesWorkspaces}
			selected={selected}
			onChange={onChange}
			placeholder={t("SELECT")}
			renderMenuItemChildren={MenuItem}
			renderToken={SelectedItem}
			showborder={true}
			width="100%"
			disabled={disabled}
		/>
	);
};

WorkspaceTypehead.displayName = "WorkspaceTypehead";

const CustomToken = styled(Token)`
	display: flex;
	align-items: center;
	border-radius: 4px;
	background: #efefef;
	.rbt-token-label {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.rbt-token-remove-button {
		align-items: center;
		background: #efefef;
		color: #605f60;
	}
`;
