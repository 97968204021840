import { createDriver } from "@redux-requests/axios";
import { handleRequests } from "@redux-requests/core";
import axios from "axios";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { LOGIN, AUTHENTICATE } from "features/auth/reducer/AuthActions";

//reducers
import { enableMapSet } from "immer";
import ActionReducer from "../features/cx-actions/reducer/ActionReducer";
import AuthReducer from "../features/auth/reducer/AuthReducer";
import CJMReducer from "../features/journey-map/reducer/CJMReducer";
import commentsReducer from "../features/comments/reducer/CommentsReducers";
import CxProgramReducer from "../features/forms/cx-programs/reducer/CxProgramReducer";
import FolderReducer from "../features/folders/reducer/FoldersReducer";
import LibrariesReducer from "../features/library/reducer/LibrariesReducer";
import OpportunityReducer from "../features/opportunity/reducer/OpportunityReducer";
import PersonaReducer from "../features/forms/personas/reducer/PersonaReducer";
import settingsReducer from "../features/settings/reducer/SettingsReducer";
import SolutionsReducer from "../features/solutions/reducer/SolutionsReducer";
import StrategyReducer from "../features/forms/cx-strategy/reducer/StrategyReducer";
import ProjectReducer from "../features/projects/reducer/ProjectReducer";
import InsightReducer from "../features/insights/reducer/InsightReducer";
import AnalyticReducer from "../features/analytics/reducer/AnalyticReducer";
import WorkspaceReducer from "features/workspace/reducer/WorkspaceReducer";
import { Table } from "features/grid-table/reducer/TableSlice.js";
import { Page } from "./pageReducer";

const uiReducers = combineReducers({
	page: Page.reducer,
	table: Table.reducer,
});

enableMapSet();

const customMiddleWare = (store) => (next) => async (action) => {
	try {
		if (action.type === LOGIN || action.type === AUTHENTICATE) {
			return next(action);
		}

		//TODO Refactoring token should be anywhere and not only at the end
		const pdfToken = window.location?.toString().split("token=")[1];

		axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				if (error?.response?.status === 401 && pdfToken === undefined) {
					// store.dispatch({ type: 'LOGOUT' });

					if (error?.response?.data === "Refused") {
						const apiCall = error.response?.request?.responseURL?.split("/api/")[1]?.split("/")[0];

						store.dispatch({ type: `NO_ACCESS_${apiCall}` });
					} else {
						store.dispatch({ type: "LOGOUT" });
						process.env.NODE_ENV === "development" && console.log("LOG OUT From Middleware");
					}
					return Promise.reject(error.response);
				} else {
					return Promise.reject(error);
				}
			}
		);

		return next(action);
	} catch (e) {
		console.error(e);
	}
};

const preventDuplicateRequestsMiddleware = store => next => action => {
	if (action.request && action.request.method === 'GET') {
		const state = store.getState();
		const requestType = action.type;
		const query = state.requests.queries[requestType];
		if (query && query.pending > 0) {

			// console.log(`Request ${requestType} is already pending`);
			return; // Skip dispatching the action
		}
	}
	return next(action);
};

const cancelableRequestsMiddleware = store => next => action => {
	if (action.request && action.request.method === 'GET') {
		const state = store.getState();
		const requestType = action.type;

		// Use a static variable to store the cancellation tokens
		if (!cancelableRequestsMiddleware.cancellations) {
			cancelableRequestsMiddleware.cancellations = {};
		}

		const previousCancelTokenSource = cancelableRequestsMiddleware.cancellations[requestType];
		if (previousCancelTokenSource) {
			// console.log(`Cancelling previous request of type ${requestType}`);
			previousCancelTokenSource.cancel(`Cancelled previous request of type ${requestType}`);
		}

		// Create a new cancel token source for the new request
		const cancelTokenSource = axios.CancelToken.source();
		action.request.cancelToken = cancelTokenSource.token; // Attach the cancel token to the request
		cancelableRequestsMiddleware.cancellations[requestType] = cancelTokenSource;

		// Clean up after the request is done
		const cleanup = () => {
			delete cancelableRequestsMiddleware.cancellations[requestType];
		};

		if (action.promise && typeof action.promise.then === 'function') {
			action.promise.then(cleanup).catch(cleanup);
		}
	}
	return next(action);
};

export const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};
const actionSanitizer = action => {
	const uiRouterActions = /@ui-router.+/g;
	return uiRouterActions.test(action.type)
		? { type: action.type, transition: sanitizeUIRouterTransition(action.transition) }
		: action;
};
const stateSanitizer = (state) => {
	if (state.router && state.router.last && state.router.last) {
		return {
			...state,
			router: sanitizeUIRouterTransition(state.router.last)
		};
	}
	return state;
};
const sanitizeUIRouterTransition = (transition) => ({
	params: transition.router && transition.router.globals && transition.router.globals.params,
	current: transition.router && transition.router.globals && transition.router.globals.current,
	targetState: transition.targetState && transition.targetState().state(),
	from: transition.from && transition.from(),
	to: transition.to && transition.to()
});

export function ConfigureStore(history, initialState) {
	const { requestsReducer, requestsMiddleware } = handleRequests({
		driver: createDriver(axios),
		takeLatest: false,
	});

	const rootReducer = combineReducers({
		router: connectRouter(history),
		auth: AuthReducer,
		settings: settingsReducer,
		libraries: LibrariesReducer,
		folders: FolderReducer,
		maps: CJMReducer,
		insights: InsightReducer,
		personas: PersonaReducer,
		actions: ActionReducer,
		opportunity: OpportunityReducer,
		solutions: SolutionsReducer,
		projects: ProjectReducer,
		strategies: StrategyReducer,
		cxProgram: CxProgramReducer,
		comments: commentsReducer,
		ui: uiReducers,
		requests: requestsReducer,
		analytics: AnalyticReducer,
		workspaces: WorkspaceReducer,
	});
	const enhancers = applyMiddleware(routerMiddleware(history), cancelableRequestsMiddleware, customMiddleWare, ...requestsMiddleware, thunk);

	if (process.env.NODE_ENV === "development") {
		return createStore(rootReducer, initialState, composeWithDevTools({
			trace: true, // if you want to enable tracing
			traceLimit: 25, // limit the trace steps
			serialize: {
				options: true
			},
			actionSanitizer, // Include your actionSanitizer
			stateSanitizer, //
		})(enhancers));
	}

	const store = createStore(rootReducer, initialState, compose(enhancers));

	return store;
}
