import { BasicDrillDownModal } from "features/analytics/modals/BasicDrillDownModal";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import MapListCard from "features/analytics/Components/Cards/MapListCard";
import CustomLegend from "features/analytics/Components/CustomLegend";
import EmptyData from "features/analytics/Components/EmptyData";
import TextAndValueTooltip from "features/analytics/Components/Tooltips/TextAndValueTooltip";
import { mapPerStatusDataSelector } from "features/analytics/view/JourneyTrends/journeyTrendDataSelectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import styled from "styled-components";
import { useModal } from "use-modal-hook";

function MapsPerStatus() {
	const { t } = useTranslation();
	const [drillDownModal] = useModal(BasicDrillDownModal);
	const data = useSelector((state) => mapPerStatusDataSelector(state, { t }));

	const renderDrillDownIllustration = ({ data, color, statusName }) => {
		return (
			<ChartContainer>
				<ResponsiveContainer width="100%" height={300}>
					<PieChart height="100%">
						<Pie data={data} dataKey="value" innerRadius={80} outerRadius={100} fill="#8884d8" paddingAngle={5}>
							{data?.map((item, index) => (
								<Cell key={`cell-${index}`} fill={item.color} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
				<Percent>{`${data[0].value.toFixed(2)}%`}</Percent>
				<Status color={color}>{statusName}</Status>
			</ChartContainer>
		);
	};

	const handlePieClick = ({ percent, color, statusName, maps }) => {
		const data = [
			{ value: percent * 100, color },
			{ value: (1 - percent) * 100, color: " #EFEFEF" },
		];

		drillDownModal({
			title: t("MAPS_PER_STATUS"),
			relatedItemsTitle: t("ASPX_97"),
			renderIllustration: () => renderDrillDownIllustration({ data, color, statusName }),
			ListItemComponent: MapListCard,
			items: maps,
			itemAttributeId: "cjmId",
		});
	};

	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return <TextAndValueTooltip text={t("NUMBER_OF_RECORDS")} value={payload[0]?.payload?.maps?.length} />;
		}

		return null;
	};

	const RenderLegend = (props) => {
		const { payload } = props;
		const legendData = [];
		payload.map((entry) => {
			legendData.push({ color: entry.color, name: `${entry.payload?.statusName} (${entry.payload?.value.toFixed(2)}%)` });
		});

		return <CustomLegend data={legendData} />;
	};

	return (
		<>
			{data.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<PieChart>
						<Pie data={data} dataKey="value" innerRadius={90} outerRadius={120} paddingAngle={2} onClick={handlePieClick}>
							{data?.map((item, index) => (
								<Cell key={`cell-${index}`} fill={item.color} style={{ outline: "none" }} cursor="pointer" />
							))}
						</Pie>
						<Legend
							layout="vertical"
							align="right"
							verticalAlign="middle"
							wrapperStyle={{
								right: "15%",
								top: "35%",
							}}
							content={<RenderLegend />}
						/>
						<Tooltip content={<CustomTooltip />} isAnimationActive={false} />
					</PieChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text="STATUS_MAP_REPORT_DESCRIPTION" icon="PIE-CHART" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

MapsPerStatus.displayName = "MapsPerStatus";
export default MapsPerStatus;

const ChartContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	gap: 40px;
`;

const Percent = styled.div`
	color: #605f60;
	font-family: "Oswald";
	font-size: 48px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

const Status = styled.div`
	color: ${(props) => props.color};
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
`;
