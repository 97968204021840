import { encode, toastWrapper } from "@utils/helpers";
import { store } from "index";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/cxaction/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/cxaction/", method: "GET" }); // api actions type get

export const getAllCxAction = () => createGetAction({ type: "GET_ALL_CX_ACTIONS", endPoint: "getAll" });

export const createCxAction = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_CX_ACTION",
			endPoint: "create",
			data: {
				newCxActionParams: props?.item,
				linkToCxProgramIdStr: props?.cxProgramId || "",
			},
		}),
	});
export const updateMultiCxActions = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_MULTI_CX_ACTION",
			endPoint: "updateMulti",
			data: {
				modifiedCxActions: props.modifiedCxActions,
				modifiedAttribNames: props.modifiedAttribNames,
			},
		}),
		displayToast: "UPDATE",
    });

export const updateCxAction = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_CX_ACTION",
			endPoint: "update",
			data: {
				modifiedCxAction: props?.modifiedCxAction,
				modifiedAttribNames: props?.modifiedAttribNames,
			},
			params: {
				beforeModifiedCxAction: props?.beforeModifiedCxAction,
			},
		}),
    });

export const copyCxAction = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_CX_ACTION",
			endPoint: "copy",
			data: {
				srcCxActionId: props?.id,
				newSubject: encode(props?.name),
				workspaceIds: props?.workspaceIds,
			},
		}),
		displayToast: "COPY",
	});

export const archiveCxAction = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_CX_ACTION",
			endPoint: "archive",
			data: {
				id: props.id,
			},
		}),
		displayToast: "ARCHIVE",
	});

export const archiveManyCxActions = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_MANY_CX_ACTIONS",
			endPoint: "archiveMany",
			data: {
				ids: props.ids,
			},
		}),
	});

export const deleteCxAction = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CX_ACTION",
			endPoint: "delete",
			data: {
				id: props.id,
			},
			params: { taskToDelete: props.taskToDelete },
		}),
		displayToast: "DELETE",
	});

export const deleteManyCxActions = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_CX_ACTIONS",
			endPoint: "deleteMany",
			data: {
				ids: props.ids,
			},
			params: { taskToDelete: props.taskToDelete },
		}),
		displayToast: "DELETE",
	});

export const deleteCxActionPermanently = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_CX_ACTION_PERMANENT",
			endPoint: "deletePermanently",
			data: {
				id: props.id,
			},
		}),
		displayToast: "DELETE",
	});

export const deleteManyCxActionsPermanently = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_CX_ACTIONS_PERMANENT",
			endPoint: "deletePermanentlyMany",
			data: {
				ids: props.ids,
			},
		}),
	});

export const restoreCxAction = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_CX_ACTION",
			endPoint: "restore",
			data: {
				id: props.id,
			},
		}),
		displayToast: "RESTORE",
	});

export const restoreManyCxActions = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_MANY_CX_ACTIONS",
			endPoint: "restoreMany",
			data: {
				ids: props.ids,
			},
		}),
		displayToast: "RESTORE",
	});

export const updateStageActionsOrder = (props) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_STAGE_ACTIONS_ORDER",
			endPoint: "updateStageActionsOrder",
			data: {
				stageId: props.stageId,
				cjmId: props.cjmId,
				newActionsOrder: props.newOrder,
			},
		}),
	});

export const getExcelExportList = (props) =>
	createPostAction({
		type: "GET_EXCEL",
		endPoint: "getExcelExport",
		data: {
			ids: props.ids,
			columns: props.columnView?.join("|"),
		},
		responseType: "blob",
	});

export const getExcelExportListServicecxAction = (ids, columnView) => {
	const promise = async () => {
		const { data, error } = await store.dispatch(getExcelExportList({ids, columnView}));
		var fileDownload = require("js-file-download");
		fileDownload(data, "Actions List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		if (error) throw error;
	};
	toastWrapper(promise, "EXPORT");
};
