import { constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta, ...props }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
		...props,
	});
};

const BASE_URL = "api/workspace/";
const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" });
const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" });

export const getAllWorkspaces = () => createGetAction({ type: "GET_ALL_WORKSPACES", endPoint: "getAll", meta: { joinRequest: "GET_ALL_WORKSPACES" } });

export const createWorkspace = ({ newWorkspace, updatedUsers, imageExtension, imageBody64, ...props }) =>
	genericService({
		...props,
		displayToast: "CREATE",
		action: createPostAction({
			type: "CREATE_WORKSPACE",
			endPoint: "create",
			data: {
				newWorkspace: { ...newWorkspace, name: encode(newWorkspace?.name), description: encode(newWorkspace?.description) },
				updatedUsers,
				imageExtension,
				imageBody64,
			},
		}),
	});

export const shareWorkspace = ({ userId, workspaceIds, roleNames, isUnshare, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_WORKSPACE",
			endPoint: "share",
			data: {
				userId,
				workspaceIds,
				roleNames,
				isUnshare,
			},
		}),
	});

export const shareManyWorkspaces = ({ userIds, workspaceIds, authorizedUsers, ...props }) =>
	genericService({
		...props,
		displayToast: "UPDATE",
		action: createPostAction({
			type: "SHARE_MANY_WORKSPACES",
			endPoint: "shareMany",
			data: {
				userIds,
				workspaceIds,
			},
			params: { authorizedUsers },
		}),
	});

export const updateWorkspace = ({
	modifiedWorkspace,
	updatedUsers = {
		owners: [],
		teamsOwners: [],
		viewers: [],
		teamsViewers: [],
	},
	modifiedAttribNames,
	...props
}) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_WORKSPACE",
			endPoint: "update",
			data: {
				modifiedWorkspace: { ...modifiedWorkspace, name: encode(modifiedWorkspace?.name), description: encode(modifiedWorkspace?.description) },
				updatedUsers,
				modifiedAttribNames,
			},
		}),
	});

export const copyWorkspace = ({ ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_WORKSPACE",
			endPoint: "copy",
			data: {
				srcWorkspaceId: props.srcWorkspaceId,
				newWorkspace: { ...props.newWorkspace, name: encode(props.newWorkspace?.name), description: encode(props.newWorkspace?.description) },
				selectedCustomization: props.selectedCustomization,
				updatedUsers: props.updatedUsers,
				imageExtension: props.imageExtension,
				imageBody64: props.imageBody64,
			},
		}),
		displayToast: "COPY",
	});

export const deleteWorkspace = ({ workspaceId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_WORKSPACE",
			endPoint: "delete",
			data: {
				workspaceId,
			},
		}),
		displayToast: "DELETE",
	});

export const uploadWorkspaceImage = ({ workspaceId, imageExtension, imageBody64, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPLOAD_WORKSPACE_IMAGE",
			endPoint: "uploadImage",
			data: {
				workspaceId,
				imageExtension,
				imageBody64,
			},
		}),
	});

export const deleteWorkspaceImage = ({ workspaceId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_WORKSPACE_IMAGE",
			endPoint: "deleteWorkspaceImage",
			data: {
				workspaceId,
			},
		}),
	});
