import { activateUsers, deactivateUsers, deleteUser } from "@redux/index";
import { isUserActionValid } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ActionConfirmModal } from "shared/modals";
import { useModal } from "use-modal-hook";

export default function BaseActions({ user }) {
	const { t } = useTranslation();
	const userInfo = useSelector((state) => state.auth.userInfo);

	const handleDeactivate = () => deactivateUsers({ ids: [user.id] });
	const handleActivate = () => activateUsers({ ids: [user.id] });

	const [showModalDelete] = useModal(ActionConfirmModal, {
		bodyContent: "DELETE_USER_WARNING",
		Action: () => {
			deleteUser({ id: user.id });
		},
		actionBtnText: t("ASPX_13"),
	});

	const createActions = () => {
		let actions = [];
		if (isUserActionValid({ user, userInfo })) {
			if (user?.isActive) {
				actions.push({
					id: "disable",
					handleClick: handleDeactivate,
					icon: "circle-pause",
					text: t("JS_118"),
				});
			} else {
				actions.push({ id: "enable", handleClick: handleActivate, icon: "circle-check", text: t("JS_119") });
				actions.push({ id: "delete", handleClick: showModalDelete, icon: "DELETE", text: t("CS_45"), itemStyle: "warning-delete" });
			}
		}

		return actions;
	};

	return createActions();
}
