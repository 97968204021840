import { useState } from "react";
import { useAsyncs } from "shared/hooks";
import { Spinner } from "./spinners/Spinner";


const DataLoader = ({ asyncFns = [], reduxActions = [], resets = [], disabledSpinner = false, children, spinnerFixed = false, full ,CustomLoading}) => {
	const [loading, setLoading] = useState(disabledSpinner ? false : true);
	useAsyncs({
		asyncFns: [...asyncFns, ...reduxActions.map((action) => ({ asyncFn: action }))],
		setLoading,
		resets,
	});
	if (disabledSpinner) return <>{children}</>;
	if(CustomLoading) return<> {loading ? CustomLoading : children}</>;

	return <> {loading ? <Spinner full={full} isFixed={spinnerFixed} /> : children}</>;
};

DataLoader.displayName = "DataLoader";
export default DataLoader;
