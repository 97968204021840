import { EntityList } from "@utils/optionsSets/OptionSets";
import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { PickListSingleFilterTemplate, balanceFilterTemplate, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { CxProgramName } from "../../columns-components/CxProgramName";
import Owners from "../../columns-components/Owner";
import Tags from "../../columns-components/Tags";
import { selectQueryView, selectSelectedViewId, selectTag } from "@redux/memoizedSelectors";

export const GetProgramColumns = (props) => {
    const context = props.context.split(".")[1];
    const tags = useSelector(selectTag);
    const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.CXProgram, context));
    const queryView = useSelector(state => selectQueryView(state, EntityList.CXProgram))?.getById(selectedViewId)?.columnSetting;

    return {
        totalNumCjms: {
            field: "totalNumCjms",
            filterField: "totalNumCjms",
            header: t("ASPX_97"),
            sortable: true,
            filter: true,
            showFilterOperator: false,
            filterElement: balanceFilterTemplate,
            dataType: "numeric",
            className: "py-0 text-center",
            style: checkStyle(queryView, "totalNumCjms", "s"),
        },
        totalNumPersonas: {
            field: "totalNumPersonas",
            filterField: "totalNumPersonas",
            header: t("ASPX_98"),
            sortable: true,
            filter: true,
            showFilterOperator: false,
            filterElement: balanceFilterTemplate,
            dataType: "numeric",
            className: "py-0 text-center",
            style: checkStyle(queryView, "totalNumPersonas", "s"),
        },
        totalNumOpps: {
            field: "totalNumOpps",
            filterField: "totalNumOpps",
            header: t("OPPORTUNITIES"),
            sortable: true,
            filter: true,
            showFilterOperator: false,
            filterElement: balanceFilterTemplate,
            dataType: "numeric",
            className: "py-0 text-center",
            style: checkStyle(queryView, "totalNumOpps", "s"),
        },
        totalNumActions: {
            field: "totalNumActions",
            filterField: "totalNumActions",
            header: t("JS_80"),
            sortable: true,
            filter: true,
            showFilterOperator: false,
            filterElement: balanceFilterTemplate,
            dataType: "numeric",
            className: "py-0 text-center",
            style: checkStyle(queryView, "totalNumActions", "s"),
        },
        totalNumSols: {
            field: "totalNumSols",
            filterField: "totalNumSols",
            header: t("SOLUTIONS"),
            sortable: true,
            filter: true,
            showFilterOperator: false,
            filterElement: balanceFilterTemplate,
            dataType: "numeric",
            className: "py-0 text-center",
            style: checkStyle(queryView, "totalNumSols", "s"),
        },
        totalNumProjects: {
            field: "totalNumProjects",
            filterField: "totalNumProjects",
            header: t("PROJECTS"),
            sortable: true,
            filter: true,
            showFilterOperator: false,
            filterElement: balanceFilterTemplate,
            dataType: "numeric",
            className: "py-0 text-center",
            style: checkStyle(queryView, "totalNumProjects", "s"),
        },
        cxProgramName: {
            field: "name",
            filterField: "name",
            filter: true,
            showFilterOperator: false,
            header: t("CX_PROGRAM_NAME"),
            sortable: true,
            style: checkStyle(queryView, "name","l" , '300px'),
            body: (cxProgram) => CxProgramName(cxProgram, props.rename, props.setRename, props.contextData.clickToOpen),
        },
        authorizedUsers: {
            field: "authorizedUsers",
            header: t("CS_35"),
            body: Owners,
            style: checkStyle(queryView, "authorizedUsers", "s", "96px"),
        },
        lastUpdated: {
            field: "lastModifiedOn",
			header: t("JS_72"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			filterField: "lastModifiedOn",
			dataType: "date",
			style: checkStyle(queryView, "lastModifiedOn", "s"),
			filterElement: dateFilterTemplate,
			body: (options) => dateBodyTemplate(options?.lastModifiedOn),
        },
        tagIds: {
            field: "tagIds",
            filterField: "tagIds",
            header: t("TAGS"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            filter: true,
            body: (action) => Tags(action, EntityList.CXProgram, context),
            style: checkStyle(queryView, "tagIds", "m"),
            filterElement: (options) => PickListSingleFilterTemplate(options, tags, t("SELECT_TAG")),
        },
    };
};