import { calculateXScore, calculateYScore } from "@utils/helpers";
import { isEmptyObject } from "jquery";
import { createSelector } from "reselect";
import { getTopXItems } from "../../analyticsHelper";

const currentjourneysData = (state) => state.analytics?.currentData.journeys;
const allSolutions = (state) => state.solutions.all_solutions;
const allOpportunities = (state) => state.opportunity.all_Opportunities;

export const insightsHorizontalBarDataSelector = createSelector(
	(state) => ({
		currentData: state.analytics?.currentData,
		allMaps: state.analytics?.all_maps,
		allInsights: state.insights?.all_insights,
	}),
	(state, props) => props,
	({ currentData, allMaps, allInsights }, props) => {
		const { currentDataAttribute, linkedIdsAttribute } = props;
		const parentData = currentData?.[currentDataAttribute];
        const topXItems = getTopXItems({ parentData, allItems: allInsights, linkedIdsAttribute, nameAttribute: "name", maxItems: 5 });

        topXItems?.forEach((item) => {
            if (!item.name) item.name = "Insight Name"
			item.relatedItems = [];
			item.parentItemIds?.forEach((parentId) => {
				const currentMap = allMaps?.find((m) => m.cjmId === parentId);
				if (currentMap) item.relatedItems.push(currentMap);
			});
		});

		return topXItems;
	}
);

export const entitiesByJourneyDataSelector = createSelector(
	(state) => ({
		currentjourneysData: state.analytics?.currentData.journeys,
		allOpportunities: state.opportunity.all_Opportunities,
		allSolutions: state.solutions.all_solutions,
		allActions: state.actions.all_actions,
	}),
	(state, props) => props,
	({ currentjourneysData, ...allData }, props) => {
		const { linkedIdsAttribute, allItemsAttribute } = props;
		const formatedData = [];

		currentjourneysData?.forEach((journey) => {
			if (journey[linkedIdsAttribute]?.length > 0) formatedData.push({ name: journey?.name, value: journey[linkedIdsAttribute]?.length, relatedItemsIds: journey[linkedIdsAttribute] });
		});

		const sortedData = formatedData?.sort((a, b) => b.value - a.value);

		return sortedData.slice(0, 5).map((data) => {
			const relatedItems = [];
			data.relatedItemsIds?.forEach((relatedItemId) => {
				const relatedItem = allData[allItemsAttribute]?.find((item) => item.id === relatedItemId);
				if (!isEmptyObject(relatedItem)) relatedItems.push(relatedItem);
			});
			return { ...data, relatedItems: [...relatedItems] };
		});
	}
);

export const mapPerStatusDataSelector = createSelector(
	(state) => ({
		currentjourneysData: state.analytics?.currentData.journeys,
		allMaps: state.analytics?.all_maps,
		statuses: state.libraries?.status?.customerJourney,
	}),
	(state, props) => props,
	({ currentjourneysData, allMaps, statuses }, props) => {
		const memory = {};
		const formatedData = [];

		currentjourneysData?.forEach((journey) => {
			const currentMap = allMaps?.find((m) => m.cjmId === journey?.id);
			if (currentMap) {
				if (!memory[currentMap.cxStatus]) memory[currentMap.cxStatus] = [currentMap];
				else memory[currentMap.cxStatus].push(currentMap);
			}
		});

		//Transform the counter into %
		Object.keys(memory)?.forEach((cxStatus) => {
			let currentStatus = {};
			const status = statuses?.find((s) => s.id === cxStatus);
			if (status) {
				currentStatus = status;
			} else currentStatus = { name: props.t("NO_VALUE"), colorCode: "#c3c3c3" };

			formatedData?.push({
				cxStatus,
				statusName: currentStatus?.name,
				color: currentStatus?.colorCode,
				value: (memory[cxStatus].length / currentjourneysData?.length) * 100,
				maps: memory[cxStatus],
			});
		});

		return formatedData;
	}
);

export const opportunityAnalysisDataSelector = createSelector([currentjourneysData, allOpportunities], (currentjourneysData, allOpportunities) => {
	const memory = {};
	const formatedData = [];

	currentjourneysData?.forEach((journey) => {
		journey["opportunityIds"]?.forEach((id) => {
			const currentOpp = allOpportunities?.find((opp) => opp.id === id);
            if (currentOpp) {
                const x = currentOpp.businessValue ?? 0
                const y = currentOpp.customerValue ?? 0
                if (x !== 0 || y !== 0) {
                    let strCoordinates = x + "|" + y;
                    if (strCoordinates in memory) memory[strCoordinates].push(currentOpp);
                    else memory[strCoordinates] = [currentOpp];
                }
			}
		});
	});

	Object.keys(memory)?.forEach((strCoordinate) => {
		const coordinates = strCoordinate.split("|");
		formatedData.push({ x: coordinates[0], y: coordinates[1], opportunities: memory[strCoordinate] });
	});

	return formatedData;
});

export const solutionAnalysisDataSelector = createSelector([currentjourneysData, allSolutions], (currentjourneysData, allSolutions) => {
	const memory = {};
	const finalData = [];

	currentjourneysData?.forEach((journey) => {
		journey["solutionIds"]?.forEach((id) => {
			const solution = allSolutions?.find((sol) => sol.id === id);
			if (solution) {
				const strCoordinates = calculateXScore(solution) + "|" + calculateYScore(solution);
				if (strCoordinates in memory) memory[strCoordinates].push(solution);
				else memory[strCoordinates] = [solution];
			}
		});
	});

	Object.keys(memory)?.forEach((strCoordinate) => {
		const coordinates = strCoordinate.split("|");
		finalData.push({ x: coordinates[0], y: coordinates[1], solutions: memory[strCoordinate] });
	});

	return finalData;
});

export const topTenWithLabelsDataSelector = createSelector(
	(state) => ({
		currentData: state.analytics?.currentData,
		allTags: state.libraries.tags,
		allGroups: state.libraries.group,
	}),
	(state, props) => props,
	({ currentData, allTags, allGroups }, props) => {
		const { currentDataAttributes, linkedIdsAttribute } = props;
		const counter = {};
		const allItems = linkedIdsAttribute === "tagIds" ? allTags : allGroups;

		// Create a Counter object, itemId: { item, numberOfOccurances}
		currentDataAttributes?.forEach((dataAttribute) => {
			currentData?.[dataAttribute]?.forEach((data) => {
				data[linkedIdsAttribute]?.forEach((id) => {
					const currentItem = allItems?.find((tp) => tp.id === id);
					if (currentItem) {
						if (!counter[id]) counter[id] = { ...currentItem, value: 1, journeys: [], personas: [], programs: [], [dataAttribute]: [data.id] };
						else counter[id] = { ...counter[id], value: counter[id].value + 1 };
					}
				});
			});
		});

		//Transform the counter into a list
		const items = Object?.values(counter);

		//Sort the list
		const sortedItems = items?.sort((a, b) => b.value - a.value);

		//Select the 10 first elements
		return sortedItems?.slice(0, 10);
	}
);

export const topNTouchpointsDataSelector = createSelector(
	(state) => ({
		currentjourneysData: state.analytics?.currentData.journeys,
		allTouchpoints: state.libraries.touchPoint,
	}),
	(state, props) => props,
	({ currentjourneysData, allTouchpoints }, props) => {
		const counter = {};

		// Create a Counter object, tpId: numberOfOccurances
		currentjourneysData?.forEach((map) => {
			map.touchpointIds?.forEach((touchpointId) => {
				const currentTp = allTouchpoints?.find((tp) => tp.id === touchpointId);
				if (currentTp) {
					if (!counter[touchpointId]) counter[touchpointId] = { ...currentTp, value: 1, mapIds: new Set([map?.id]) };
					else counter[touchpointId] = { ...counter[touchpointId], value: counter[touchpointId].value + 1, mapIds: counter[touchpointId].mapIds.add(map?.id) };
				}
			});
		});

		//Transform the counter into a list
		const touchpoints = Object.values(counter);

		//Sort the list
		const sortedTouchpoints = touchpoints?.sort((a, b) => b.value - a.value);

		//Select the N first elements
		return sortedTouchpoints?.slice(0, props.n);
	}
);

export const wordCloudDataSelector = createSelector(
	(state) => ({
		currentData: state.analytics?.currentData,
	}),
	(state, props) => props,
	({ currentData }, props) => {
		const { dataAttribute, linkedIdsAttribute } = props;
		const memory = {};
		const maxValue = 200;
		const minValue = 20;

		currentData[dataAttribute]?.forEach((journey) => {
			journey[linkedIdsAttribute]?.map((wordCloud) => {
				const value = wordCloud?.value;
				const text = wordCloud?.text?.toLowerCase();
				if (!memory[text]) memory[text] = value;
				else memory[text] = memory[text] + value;
			});
		});

		Object.keys(memory)?.forEach((text) => {
			const value = memory[text] * 15;
			if (value < minValue) memory[text] = minValue;
			if (value > maxValue) memory[text] = maxValue;
			else memory[text] = value;
		});

		// Transform the counter into a list
		const wordCloud = Object?.entries(memory)?.map(([text, value]) => ({
			text,
			value,
		}));

		// Sort the list
		const sortedWordCloud = wordCloud?.sort((a, b) => b.value - a.value);

		return sortedWordCloud?.slice(0, 50);
	}
);
