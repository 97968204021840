import { getExcelExportPersona, getLinkedExcelExportPersona, updatePersonaType } from "@redux/index";
import { getRelevantPersonas } from "@redux/memoizedSelectors";
import { GetActiveCJM, tableDefaultColumn, toastWrapper } from "@utils/helpers";
import { EntityList, StateTypeOption, StatusCode, TemplateStatus } from "@utils/optionsSets/OptionSets";
import { BulkActions } from "features/forms/personas/actions/BulkActions";
import _ from "lodash";
import { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { useModal } from "use-modal-hook";
import BaseActions from "../../../forms/personas/actions/BaseActions";
import { ActionLink } from "../../columns-components/All_actions/ActionLink";
import ActionLinkPersona from "../../columns-components/All_actions/ActionLinkPersona";
import { PromptAICreation } from "shared/modals";

export const GetPersonaContext = (props) => {
	const loading = useSelector((state) => state.personas.loading);
	const { linked, showAllActiveRecord, setRename, disableBulkActions, tab } = props;
	const specificContext = props.context.split(".")[1];

	const { pathname, search } = useLocation();
	const cxProgramId = search.getCxProgramID();
	const hideForPdf = search.hideForPdf();
	const personaIdOnPage = search.getPersonaId();
	const folderId = search.getFolderId();
	const history = useHistory();
	const dispatch = useDispatch();

	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData, shallowEqual);
	const personas = useSelector((state) => state.personas.all_personas);
	const tags = useSelector((state) => state.libraries.tags);
	const all_maps = useSelector((state) => state.maps.all_maps);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.Persona]?.[specificContext]?.columns || tableDefaultColumn(EntityList.Persona);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Persona]?.[specificContext]?.filters;
	const personaId = useSelector((state) => state.personas.current_persona.personaId, shallowEqual);
	const personasLinkCJMIds = useSelector(getRelevantPersonas)?.map((persona) => persona.personaId);

	const PersonasData = useMemo(() => {
		const relevantPersonas = personas?.filter((persona) => PersonaValidation(persona, pathname, tab, showSampleData, folderId));
		return transformPersonaData(relevantPersonas, all_maps, tags);
	}, [pathname, tab, showSampleData, personas, all_maps, tags, folderId]);

	const goToPersonaCreation = () => {
		history.push(`/persona-creation${folderId ? `?folderId=${folderId}` : ""}`);
	};
	const updatePersonaTypes = (value, ids) => {
		updatePersonaType({ newValue: value, personaIds: ids });
	};
	const getExcelExportListPersona = (ids, columnView) => {
		const promise = async () => {
			const { data, error } = await dispatch(getExcelExportPersona(ids, columnView));
			var fileDownload = require("js-file-download");
			fileDownload(data, "Personas List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
			if (error) throw error;
		};
		toastWrapper(promise, "EXPORT");
	};
	const getLinkedExcelExportPersonaFn = (ids) => {
		const promise = async () => {
			const { data, error } = await dispatch(getLinkedExcelExportPersona(ids));
			var fileDownload = require("js-file-download");
			fileDownload(data, "Personas List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
			if (error) throw error;
		};
		toastWrapper(promise, "EXPORT");
	};

	const [showListLinkedModal] = useModal(ListLinkedModal);

	const data = useMemo(() => {
		const newData = {
			personasList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "PERSONA_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				visibleHeader: true,
				stateEditable: true,
				showPagination: true,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined,
				aiGeneration: true
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "personaName", "lastModifiedOn", "cjmCount", "tagIds", "actions"],
				emptyListText: "PERSONA_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			archivedTemplate: {
				showCheckbox: true,
				columns: ["select", "personaName", "lastModifiedOn", "cjmCount", "tagIds", "actions"],
				emptyListText: "NO_PERSONA_TEMPLATE_REGULAR",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "personaName", "lastModifiedOn", "cjmCount", "tagIds", "actions"],
				emptyListText: "PERSONA_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deletedTemplate: {
				showCheckbox: true,
				columns: ["select", "personaName", "lastModifiedOn", "cjmCount", "tagIds", "actions"],
				emptyListText: "NO_PERSONA_TEMPLATE_REGULAR",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			templates: {
				showCheckbox: true,
				columns: ["select", "personaName", "lastModifiedOn", "cjmCount", "tagIds", "templateStatus", "isLocked", "actions"],
				emptyListText: "NO_PERSONA_TEMPLATE_REGULAR",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			editPersona: {
				showCheckbox:  false,
				columns:  ["personaName", "occupation", "isFutureLabel", "lastModifiedOn", "actions"],
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				emptyListText: "NO_PERSONAS",
			},
			cxProgram: {
				showCheckbox: false, // modals of cxProgram that link/unlink
				columns: ["personaName", "occupation", "isFutureLabel", "lastModifiedOn", "actions"],
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				showPagination: linked?.length > 10,
				nonDropDown: true,
				emptyListText: "PERSONA_PLACEHOLDER_PROGRAM",
			},
			library: {
				showCheckbox: false,
				bulkActions: false,
				columns: ["personaName", "occupation", "authorizedUsers"],
				emptyListText: "PERSONA_EMPTY_LIST",
				clickToOpen: true,
				showOnlyLinked: true,
				linked: linked,
				disableFixHeader: true,
				showPagination: false,
			},
			managePersonas: {
				showRadioButton: true,
				showCheckbox: false,
				bulkActions: false,
				columns: ["select", "personaName", "occupation", "historicalData"],
				emptyListText: "PERSONA_EMPTY_LIST",
				linked: linked,
				disableFixHeader: true,
				showPagination: false,
			},
		};

		const contextData = _.get(newData, specificContext);
		const linkedObjects = linked ? PersonasData.filter((map) => linked?.includes(map.personaId)) : undefined;
		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;

		contextData.Actions = (persona) => CreateActionsDropdown({ actions: BaseActions({ persona, setRename }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = linkedObjects;
		contextData.listData = PersonasData;
		contextData.tags = tags;
		contextData.dataKey = "personaId";
		contextData.reducerPath = "personas.all_personas";
		contextData.entityType = EntityList.Persona;
		contextData.exportExcel = getExcelExportListPersona;
		contextData.getLinkedDataExportExcel = getLinkedExcelExportPersonaFn;
		contextData.loading = loading;
		contextData.buttonCreate = goToPersonaCreation;
		contextData.buttonCreateLabel = "JS_81";
		contextData.onChangeState = contextData?.stateEditable ? updatePersonaTypes : null;
		contextData.filter = filters;
		if(contextData.aiGeneration) {
			contextData.aiModal = PromptAICreation;
		}

		if (specificContext === "editPersona") {
			contextData.listData = PersonasData?.filter((persona) => persona.personaId !== personaIdOnPage) || [];
			contextData.Actions = (options) => !hideForPdf && ActionLinkPersona(options, linked, personaId);
		}
		if (specificContext === "cxProgram") {
			contextData.Actions = props?.disableAction ? () => { } : (options) => ActionLink(options, "cem_persona", cxProgramId);
		}
		if (specificContext === "managePersonas") {
			contextData.listData = PersonasData?.filter((persona) => !personasLinkCJMIds?.includes(persona?.personaId)) || [];
		}
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.Persona, saveButtonLabel: 'ASPX_44' });
			contextData.LinkActionLabel = "LINK_UNLINK_PERSONAS";
			contextData.displayExport = false;
		}
		return contextData;
	}, [isReadOnly, linked, PersonasData, columnsArray, loading, disableBulkActions, showAllActiveRecord, setRename, cxProgramId, filters]);

	return data;
};

const transformPersonaData = (personas, all_maps) => {
	return personas?.map((persona) => {
		const newDate = new Date(persona.lastModifiedOn);
		const linkedJourneyMapActive = GetActiveCJM(persona.linkedCjms, all_maps);
		return {
			...persona,
			id: persona.personaId,
			lastModifiedOn: newDate,
			cjmCount: linkedJourneyMapActive?.length || 0,
			checked: true,
			tagIds: persona?.tags?.join("|"),
			isFutureValue: persona?.isFuture ? StateTypeOption.FutureState : StateTypeOption.CurrentState,
		};
	});
};


export const PersonaValidation = (persona, pathname, tab, showSampleData, folderId) => {
	const isTrash = pathname === "/trash";
	const isArchive = pathname === "/archive";
	const isPersonas = ["/personas", "/EditPersona", "/EditMap"].includes(pathname);
	const InCxProgram = pathname === "/EditCxProgram";
	const isTemplate = pathname === "/templates";
	const isNotCemanticaTemplate = persona.templateStatus !== TemplateStatus.cemantica;
	let res = "";
	if (InCxProgram || isPersonas) {
		if (showSampleData) {
			return persona.statusCode === StatusCode.Active && persona.isTemplate === false;
		} else {
			return persona.statusCode === StatusCode.Active && persona.isTemplate === false && persona.isSample === false;
		}
	}
	if (isTrash) {
		if (tab === "template") {
			res = persona.statusCode === StatusCode.Trashed && persona.isTemplate === true && isNotCemanticaTemplate;

			return res;
		} else {
			res = persona.statusCode === StatusCode.Trashed && persona.isTemplate === false;
			return res;
		}
	}

	if (isArchive) {
		if (tab === "template") return persona.statusCode === StatusCode.Archived && persona.isTemplate === true && isNotCemanticaTemplate;
		return persona.statusCode === StatusCode.Archived && persona.isTemplate === false;
	}

	if (isTemplate) {
		return persona.isTemplate && persona.statusCode === StatusCode.Active && isNotCemanticaTemplate;
	}

	return persona;
};
