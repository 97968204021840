import { GenericButton } from "shared/ui-basics/index";
import { t } from "i18next";
import { Offcanvas } from "react-bootstrap";
import styled from "styled-components";
import React from "react";
export const CreateLibraryItemCanvas = ({ title, displayForm, isOpen, onClose }) => {
	return (
		<OffcanvasLayout show={isOpen} onHide={onClose} scroll={true} backdrop={true} placement="end">
			<Header className="px-4 py-2">
				<OffCanvasTitle>{t(title)}</OffCanvasTitle>
				<CloseButton>
					<GenericButton variant="light-link" icon="X" onClick={onClose} />
				</CloseButton>
			</Header>
			<Body className="p-4">{displayForm(onClose)}</Body>
		</OffcanvasLayout>
	);
};

const OffcanvasLayout = styled(Offcanvas)`
	width: 480px;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
    justify-content: space-between;
	border-bottom: 1px solid #c4c4c4;
`;

const OffCanvasTitle = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	align-self: flex-start;
`;

const Body = styled.div`
	height: 92vh;
	display: flex;
	flex-direction: column;
`;

const CloseButton = styled.div`
`;
