import { getSubscriptionConfig, updateIntegrationCredentials } from "@redux/index";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import CustomTooltip from "shared/components/CustomTooltip";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";


const Configuration = () => {
	const { t } = useTranslation();

	const key = useSelector((state) => state.settings.configurations.integrationUserName);
	const integrationMode = useSelector((state) => state.settings.configurations.integrationMode);
	const url = useSelector((state) => `https://${state.settings.configurations.organizationPrefix}.${state.settings.configurations.organizationZone}.dynamics.com`);

	const [intMode, setIntMode] = useState(integrationMode);
	const [password, setPassword] = useState("");

	const handleChange = (e) => {
		const { value, name } = e.target;
		if (name === "mode") setIntMode(value);
		if (name === "password") setPassword(value);
	};
	const update = () => updateIntegrationCredentials({ id: key, mode: intMode, password, url });

	return (
		<DataLoader reduxActions={[getSubscriptionConfig]}>
			<PageTemplate classNames="container pt-5" pageTitle={t("ASPX_211")}>
				<Card className="p-4 w-100">
					<CardBody>
						<Row>
							<div className="d-flex align-items-center my-2">
								<div>{t("INTEGRATION_MODE")}</div>
								<CustomTooltip id={"integration-map"} text={t("INTEGRATION_MODE_TIP")} placement={"top"}>
									{IconDispatcher("CIRCLE-QUESTION", "ms-1")}
								</CustomTooltip>
							</div>
                            <Form.Select type="select" name="mode" value={intMode} onChange={() => { }} className="w-25 mx-2" style={{ boxShadow: "none" }} disabled>
								<option value="100000000">{t("CLIENT_ID_SECRET")}</option>
								<option value="100000001">{t("USER_CREDENTIALS")}</option>
							</Form.Select>
						</Row>

						<Row className="mt-5">
							<Col>
								<div className="d-flex align-items-center my-2">
									<div>{t("ASPX_230")}</div>
									<CustomTooltip id={"integration-map"} text={t("CONFIG_USERNAME_TIP")} placement={"top"}>
										{IconDispatcher("CIRCLE-QUESTION", "ms-1")}
									</CustomTooltip>
								</div>
								<Input type="text" defaultValue={key} className="border-bottom" disabled/>
							</Col>
							<Col>
								<div className="my-2">{t("ASPX_231")}</div>
								<Input type="password" name="password" value={password} onChange={() => { }} placeholder="********" className="border-bottom" disabled />
							</Col>
						</Row>
						<Row className="mt-5">
							<div className="d-flex align-items-center my-2">
								<div>{t("ASPX_233")}</div>
								<CustomTooltip id={"integration-map"} text={t("CONFIG_ENV_URL_TIP")} placement={"top"}>
									<i className="icon-help pointer mx-1"></i>
								</CustomTooltip>
							</div>
							<Input type="text" defaultValue={url} className="mx-2 border-bottom" disabled />
						</Row>
					</CardBody>
					{/* <div>
						<GenericButton className="m-3" variant="primary" onClick={update}>
							{t("ASPX_165")}
						</GenericButton>
					</div> */}
				</Card>
			</PageTemplate>
		</DataLoader>
	);
};

Configuration.displayName = "Configuration";
export default Configuration;
