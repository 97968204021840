import { libraryInit, getAllMapsListData, getAllPersonas, getCjmCustomerFeedbackFn, getCjmCxActionsFn, getCjmCxGoalsFn, getCjmDataFn, getCjmHeaderFieldsFn, getCjmOpportunitiesFn, getCjmPersonasDataFn, getCjmSolutionsFn, getCjmStagesCustomerExperienceDataFn, getCjmSwimLaneDetailsFn, getCjmSwimLaneFn, getCjmTimeLinesFn, getPersonaMapDetails, getStagesPhasesFn, groupInit, tagInit, touchPointInit } from "@redux/index";
import { createAction } from "@reduxjs/toolkit";
import { getNotifications } from "features";
import { getAllInsightOfMapFu, getCjmContactDataFn, getCurrentMap, getMapChartData, getMapUsers, getPersonaInteractionByStageFn, loadChart } from "../../journey-map/reducer/CJMActions";

export const methodMapFun = (methodName, args, dispatch, cjmIdStr, personaIdStr, userId, history, forceReload) => {
    // console.log(methodName, args)
    const updateAction = createAction(methodName);
    switch (methodName) {
        case "GET_CJM_HEADER_FIELDS":
            getCjmHeaderFieldsFn({ cjmIdStr });
            break;
        case "GET_STAGES_PHASES":

            getStagesPhasesFn({ cjmIdStr, params: args });
            break;
        case "ONLINE_USER_REGISTRY":
        case "ONLINE_USER_REGISTRY_LOGOUT":
        case "ONLINE_USER_REGISTRY_LOGOUT_CLOSE":
            dispatch(updateAction({ userIds: args }));
            break;
        case "GET_ALL_TAG":
            dispatch(tagInit());
            break;
        case "GET_ALL_GROUP":
            dispatch(groupInit());
            break;
        case "GET_ALL_LIBRARY":
            dispatch(libraryInit());
            break;
        case "GET_SWIMLANE":
            getCjmSwimLaneFn({ cjmIdStr, personaIdStr });
            break;
        case "GET_SWIMLANEDETAILS":
            getCjmSwimLaneDetailsFn({ cjmIdStr, personaIdStr, collab: true });
            break;
        case "GET_SWIMLANEDETAILS_INSIGHTS":
            getCjmSwimLaneDetailsFn({ cjmIdStr, personaIdStr });
            getAllInsightOfMapFu({ cjmId: cjmIdStr })
            break;
        case "GET_PERSONA_DATA":
            getCjmPersonasDataFn({ cjmIdStr });
            break;
        case "GET_CJM_DATA":
            getCjmDataFn({ cjmIdStr, personaIdStr });
            if (args === "loadMapChart") {
                loadChart({ cjmIdStr, personaIdStr, forceRecalculate:true });
            }
            break;
        case "GET_SOLUTIONS":
            getCjmSolutionsFn({ cjmIdStr });
            break;
        case "GET_CXACTIONS":
            getCjmCxActionsFn({ cjmIdStr });
            break;
        case "GET_OPPORTUNITIES":
            getCjmOpportunitiesFn({ cjmIdStr });
            break;
        case "GET_TIME_LINES":
            getCjmTimeLinesFn({ cjmIdStr });
            break;
        case "GET_CXGOALS":
            getCjmCxGoalsFn({ cjmIdStr });
            break;
        case "GET_CUSTOMER_FEEDBACK":
            getCjmCustomerFeedbackFn({ cjmIdStr });
            break;
        case "GET_CONTACT_DATA":
            getCjmCustomerFeedbackFn({ cjmIdStr });
            getCjmContactDataFn({ cjmIdStr ,personaIdStr});
            loadChart({ cjmIdStr, personaIdStr,forceRecalculate:true  });

            break;
        case "GET_STAGES_CUSTOMER_EXPRIENCE":
            getCjmStagesCustomerExperienceDataFn({ cjmIdStr, personaIdStr });
            break;
        case "GET_STAGES_CUSTOMER_EXPRIENCE_SENTIMENT": {
            const onSuccess = () => {
                getCjmStagesCustomerExperienceDataFn({ cjmIdStr, personaIdStr });
            }
            getCjmDataFn({ cjmIdStr, personaIdStr, onSuccess: onSuccess });
            loadChart({ cjmIdStr, personaIdStr ,forceRecalculate:true });
            getCjmHeaderFieldsFn({ cjmIdStr });
            // getCurrentMap({ cjmIdStr, personaIdStr, onlySentimentData: true });

            break;
        }
        case "UPDATE_CHART": {
            loadChart({ cjmIdStr, personaIdStr ,forceRecalculate:true});

            break;
        }
        case "LINK_ICON": {
            const type = args || 0;
            switch (type) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 15:
                case 26:
                    getCjmDataFn({ cjmIdStr, personaIdStr });
                    break;
                case 5:
                case 14:
                    getCjmSwimLaneDetailsFn({ cjmIdStr, personaIdStr });
                    break;
                case 6:
                case 7:
                case 8:
                    getCjmStagesCustomerExperienceDataFn({ cjmIdStr, personaIdStr });
                    break;
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 23:
                    getStagesPhasesFn({ cjmIdStr });
                    break
                case 19:
                    getCjmCxGoalsFn({ cjmIdStr });
                    break;
                case 25:
                case 16:
                case 29:
                    getCjmSwimLaneDetailsFn({ cjmIdStr, personaIdStr });
                    break;
                case 20:
                    getCjmOpportunitiesFn({ cjmIdStr });
                    break;
                case 21:
                    getCjmCxActionsFn({ cjmIdStr });
                    break;
                case 24:
                    getCjmTimeLinesFn({ cjmIdStr });
                    break;
                case 27:
                    getCjmSolutionsFn({ cjmIdStr });
                    break;
                case 28:
                    getCjmCustomerFeedbackFn({ cjmIdStr });
                    break;
            }
        }
            break;
        case "REFRESH_MAP": {
            dispatch(getPersonaMapDetails({ cjmIdStr, personaIdStr }));
            dispatch(getMapChartData({ cjmIdStr, personaIdStr }));
        }
            break;
        case "GET_COMMENTS":
            getNotifications();
            dispatch(getPersonaMapDetails({ cjmIdStr, personaIdStr }));
            break;
        case "GET_ALL_CJMS":
            getCjmHeaderFieldsFn({ cjmIdStr });
            dispatch(getAllMapsListData());
            break;
        case "GET_CJM_PERSONAS":

            getCjmPersonasDataFn({ cjmIdStr });
            dispatch(getAllPersonas());
            break;

        case "UPLOAD_IMG":
        case "UPLOAD_STAGE_IMG":
            dispatch(updateAction({ ...args }));

            // getCjmSwimLaneDetailsFn({ cjmIdStr, personaIdStr });
            //cjmSwimLanes
            break;
        case "UNLINK_PERSONA":
            getCjmPersonasDataFn({ cjmIdStr });
            break;
        case "GET_ALL_TOUCHPOINTS":
            dispatch(touchPointInit());
            break;
        case "GET_PERSONA_INTERACTION_BY_STAGE":
            getPersonaInteractionByStageFn({ cjmId: cjmIdStr, personaId: personaIdStr, ...args });
            break;
        case "GET_MAP_USERS": {
            const mapUserParameters = { cjmId: cjmIdStr, forceViewer: false, userId: userId };
            dispatch(getMapUsers(mapUserParameters)).then(() => {
                forceReload();

            });
            break;
        }
        case "CREATE_NEW_VERSION": {
            history.push(`/EditMap?cjmid=${args}&personaId=${personaIdStr}`);

            break;
        }
    }
    return null;
}