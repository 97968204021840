import { getInitials } from "@utils/helpers";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";
import UserDetails from "features/settings/manage-users/components/user-details";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, GenericButton } from "shared/ui-basics";
import { useModal } from "use-modal-hook";
import { UserProfileModal } from "../modals/user-profile-modal";
import { DropdownWrapper } from "./nav-menu-header-style";
import styled from "styled-components";

export default function InitialsOptions({ innerRef }) {
	const { t } = useTranslation();
	const history = useHistory();

	const [isShow, setIsShow] = useState(false);

	const userInfo = useSelector((state) => state.auth?.userInfo);
	const users = useSelector((state) => state.settings?.users);

	const currentUser = users.find((u) => u?.loginName?.toLowerCase() === userInfo?.UserEmail?.toLowerCase());
	const fullName = userInfo?.UserFullName;

	const logout = () => history.push(`/logout`);

	const [showUserProfileModal] = useModal(UserProfileModal);
	const handleClickProfile = () => {
		showUserProfileModal({
			user: currentUser,
		});
	};

	const createActions = () => {
		const actions = [];
		actions.push({ customItem: <UserDetails user={currentUser} desactivateClick={true} /> });
		actions.push({ icon: "circle-user", handleClick: handleClickProfile, text: t("PROFILE") });
		actions.push({ icon: "right-from-bracket", handleClick: logout, text: t("JS_142") });

		return actions;
	};

	const actions = createActions();

	const handleClickItem = (action, e) => {
		e.stopPropagation();
		if (action?.handleClick) action?.handleClick();
		if (action.customItem) return;
		setIsShow(false);
	};

	const handleToggle = (isOpen) => {
		if (isOpen) innerRef.current?.hide();
		setIsShow(isOpen);
	};

	return (
		<DropdownWrapper show={isShow} onToggle={handleToggle} autoClose={true}>
			<Dropdown.Toggle className="px-1" as={Button} variant="clean" isicon={false} size="l">
				<InitialsBubble initials={getInitials({ name: fullName })?.slice(0, 2)} size="s" />
			</Dropdown.Toggle>
			<DropdownMenu>
				{actions?.map((action, index) => (
					<div key={index}>
						{action?.customItem ? (
							action.customItem
						) : (
							<div key={index}>
								<GenericButton
									variant="light-link"
									fullWidth={true}
									justifyStart={true}
									onClick={(e) => handleClickItem(action, e)}
									icon={action?.icon && action?.icon}
									disabled={action?.disabled}>
									<div className="ms-1">{action?.text}</div>
								</GenericButton>
							</div>
						)}
					</div>
				))}
			</DropdownMenu>
		</DropdownWrapper>
	);
}

const DropdownMenu = styled(Dropdown.Menu)`
	z-index: 1001;
	transform: translate(-16px, 36px) !important;
`;
