import { OrganizationChart } from "primereact/organizationchart";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { PageHeader, PageTitle } from "shared/ui-basics/index";
import { getHierachyPdf } from "features/index";
import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { useAsyncs } from "shared/hooks/index";
import { getAllMapsListData, getAllPersonas, statusInit } from "@redux/index";
import { EMPTY_GUID, handleAddRenderElement, toastWrapper } from "@utils/helpers";
import CustomTooltip from "../../../shared/components/CustomTooltip";
import MapBoxCard from "../cards/MapBoxCard";

export default function MapHierarchyView(props) {
	const { t } = useTranslation();
	const query = new URLSearchParams(props.location.search.toLowerCase());
	const cjmIdStr = query.get("cjmid");
	const personaIdStr = query.get("personaid");
	const isPrint = query.get("isprint") || false;
	const hideForPdf = isPrint === "1";
	const metaData = {
		nNodes: 0,
		maxDepth: 0,
		memory: [],
	};
	let calculatedWidth = 400;
	let calculatedHeight = 400;

	useAsyncs({
		asyncFns: [{ asyncFn: getAllMapsListData }, { asyncFn: getAllPersonas }, { asyncFn: statusInit }],
	});

	const [disabledExport, setDisableExport] = useState(false);
	const [zoomStatus, setZoomStatus] = useState(1);
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allMaps = useSelector((state) => state.maps.all_maps)?.getActiveRecords(!showSampleData);

	const currentMap = allMaps?.filter((map) => map.cjmId === cjmIdStr)[0];

	useEffect(() => {
		const element = document.getElementById(cjmIdStr);
		if (element) {
			element.scrollIntoView({ behavior: "auto", block: "nearest", inline: "center" });
		}
	}, [query, zoomStatus]);

	const createNodes = (currentMap, depth) => {
		const nodes = [];
		createNodesRec({ nodes, parentMap: { cjmId: EMPTY_GUID }, currentMap, depth });
		return nodes;
	};

	const createNodesRec = ({ nodes, parentMap, currentMap, depth, type, label, cjmIdStr, phaseId, stageId }) => {
		if (!currentMap) return;

		if (depth >= metaData.maxDepth) metaData.maxDepth = depth;
		metaData.nNodes += 1;
		const children = [];
		nodes.push({
			key: currentMap.cjmId,
			expanded: hideForPdf,
			type,
			label,
			map: currentMap,
			dataForUnlink: { cjmIdStr, personaIdStr, phaseId, stageId },
			children,
		});

		if (metaData.memory.includes(currentMap.cjmId)) return;
		metaData.memory.push(currentMap.cjmId);

		currentMap.linkedCjms.map((linkedMapId) => {
			const newCurrentMap = allMaps.find((map) => linkedMapId === map.cjmId);
			if (parentMap?.cjmId !== newCurrentMap?.cjmId)
				createNodesRec({ nodes: children, parentMap: currentMap, currentMap: newCurrentMap, depth: depth + 1, type: "direct", label: "Direct Link", cjmIdStr: currentMap.cjmId });
		});

		currentMap?.phases?.map((phase) =>
			phase.linkedCjms.map((linkedMapId) => {
				const newCurrentMap = allMaps.find((map) => linkedMapId === map.cjmId);
				if (parentMap?.cjmId !== newCurrentMap?.cjmId)
					createNodesRec({ nodes: children, parentMap: currentMap, currentMap: newCurrentMap, depth: depth + 1, type: "phase", label: phase.name, cjmIdStr: currentMap.cjmId, phaseId: phase.id });
			})
		);

		currentMap?.stages?.map((stage) =>
			stage.linkedCjms.map((linkedMapId) => {
				const newCurrentMap = allMaps.find((map) => linkedMapId === map.cjmId);
				if (parentMap?.cjmId !== newCurrentMap?.cjmId)
					createNodesRec({ nodes: children, parentMap: currentMap, currentMap: newCurrentMap, depth: depth + 1, type: "stage", label: stage.name, cjmIdStr: currentMap.cjmId, stageId: stage.id });
			})
		);
	};

	const nodes = createNodes(currentMap, 0);

	const exportView = () => {
		calculatedWidth += metaData.nNodes * 400;
		calculatedHeight += metaData.maxDepth * 500;
		const onSuccess = (data) => {
			const fileDownload = require("js-file-download");
			fileDownload(data, currentMap.name + " - Relationships.pdf");
			setDisableExport(false);
		}

		getHierachyPdf({
			cjmIdStr,
			personaIdStr,
			width: calculatedWidth,
			height: calculatedHeight + 100,
			onSuccess,
			setLoading: setDisableExport,
			displayToast: "EXPORT",
			toastParams: { success: t("JOURNEY_MAP_EXPORT_SUCCESS") }

		});

	};

	const handleZoomOut = () => zoomStatus > 0.1 && setZoomStatus(Math.round((zoomStatus - 0.1) * 10) / 10);
	const handleZoomIn = () => zoomStatus < 1 && setZoomStatus(Math.round((zoomStatus + 0.1) * 10) / 10);

	return (
		<PageContainer>
			{!hideForPdf && (
				<React.Fragment>
					<PageHeader>
						<PageTitle>{t("MAP_HIERARCHY")}</PageTitle>
					</PageHeader>
					<div className="d-flex justify-content-between mt-3 mb-4 mx-5">
						<div>
							<GenericButton
								variant="primary-outline"
								onClick={() => {
									props.history.goBack();
								}}>
								{IconDispatcher("LEFT-ARROW", "me-2")}
								{t("ASPX_132")}
							</GenericButton>
						</div>
						<ActionsContainer>
							<ZoomActions className="p-1">
								<GenericButton variant="light-link" onClick={handleZoomOut} disabled={disabledExport}>
									<div>-</div>
								</GenericButton>
								<ZoomStatus>{zoomStatus * 100}%</ZoomStatus>
								<GenericButton variant="light-link" onClick={handleZoomIn} disabled={disabledExport}>
									<div>+</div>
								</GenericButton>
								<GenericButton variant="light-link" onClick={() => setZoomStatus(1)} disabled={disabledExport}>
									<Reset>Reset</Reset>
								</GenericButton>
							</ZoomActions>
							<div className="p-1">
								<CustomTooltip id={"excel"} text={t("ASPX_15")} placement="bottom">
									<GenericButton variant="light-link" onClick={exportView} disabled={disabledExport}>
										<div>{IconDispatcher("EXPORT")}</div>
									</GenericButton>
								</CustomTooltip>
							</div>
						</ActionsContainer>
					</div>
				</React.Fragment>
			)}
			<ScrollContainer isPrint={isPrint}>
				{currentMap && (
					<ChartContainer className="mt-5" zoom={zoomStatus}>
						<OrgChartTemplate value={nodes} nodeTemplate={MapBoxCard} className="org-chart" />
						{hideForPdf && <div id="addRenderElement">{handleAddRenderElement()} </div>}
					</ChartContainer>
				)}
			</ScrollContainer>
		</PageContainer>
	);
}

const ActionsContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
`;

const ZoomActions = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-right: 1px solid #c4c4c4;
`;

const ZoomStatus = styled.div`
	display: flex;
	justify-content: center;
	width: 48px;
	color: #333;
	font-size: 0.875rem;
	font-family: Inter;
	line-height: 150%;
`;

const Reset = styled.div`
	color: #605f60;
	font-size: 1rem;
	font-family: Inter;
	line-height: 150%;
`;

const PageContainer = styled.div`
	padding-top: 0.5rem !important;
	padding-left: 3rem !important;
`;

const ScrollContainer = styled.div`
	overflow: auto;
	width: 100%;
	height: calc(100vh - 250px);
`;

const ChartContainer = styled.div`
	transform: scale(${(props) => props.zoom});
`;

const OrgChartTemplate = styled(OrganizationChart)`
	padding-top: 0 !important;
	border: none !important;

	.p-organizationchart-lines {
		& td {
			border-color: #c4c4c4;
		}
	}

	.p-organizationchart-line-down {
		background: #c4c4c4;
	}

	.p-organizationchart-node-content {
		border: none !important;
		padding: 0;
	}
`;
