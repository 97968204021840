import styled, { css } from "styled-components/macro";
import { Col } from "reactstrap";
import { Row } from "react-bootstrap";

export * from "./buttons";
export * from "./input.js";
export * from "./containers";
export * from "../Icons";
export * from "./colors";
export * from "./date";
export * from "../dropdowns/ActionsDropdown";
export * from "../dropdowns/GenericDropdown";
export * from "../popovers/GenericPopover";

export const Flex = styled.div`
	display: flex;
	${(props) => props.gap && `gap: ${props.gap}px`};
`;
export const FlexWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	${(props) => props.scroll && `overflow-y: scroll;max-height: ${props.maxHeight}px;`}
	gap: ${(props) => props.gap}px;

`;

export const FlexGap = styled.div`
	display: flex;
	gap: ${(props) => props.gap}px;
`;

export const FlexAlignCenter = styled.div`
	display: flex;
	align-items: center;
	${(props) => props.gap && `gap: ${props.gap}px`};
	${(props) => props.minHeight && `min-height: ${props.minHeight}px`};
`;
export const FlexAlign = styled.div`
	display: flex;
	align-items: center;
	${(props) => props.gap && `gap: ${props.gap}px`};
	${(props) => props.minHeight && `min-height: ${props.minHeight}px`};
	${(props) => props.align && `align-items: ${props.align}`};
`;
export const ColFlexAlignCenter = styled(Col)`
	display: flex;
	align-items: center;
	${(props) => props.gap && `gap: ${props.gap}px`};
`;
export const ColFlexAlignCenterBetween = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	${(props) => props.gap && `gap: ${props.gap}px`};
	.generic-button-delete {
		display: none;
	}
	:hover {
		background-color: #efefef;
	}
	&:hover .generic-button-delete {
		display: block;
	}
`;

export const FlexAlignEnd = styled.div`
	display: flex;
	align-items: flex-end;
`;
export const FlexAlignAndJustifyEnd = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content:end;
`;
export const FlexJustifyStart = styled.div`
	display: flex;
	justify-content: start;
	${(props) => props.gap && `gap: ${props.gap}px`};
`;
export const FlexAlignJustifyCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${(props) => props.gap && `gap: ${props.gap}px`};
`;

export const FlexBetween = styled.div`
	display: flex;
	justify-content: space-between;
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`};
	${(props) => props.gap && `gap: ${props.gap}px;`}
`;

export const FlexBetweenAlignEnd = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
`

export const FlexBetweenAlignCenter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${(props) => props.gap && `gap: ${props.gap}px`};
	${(props) => props.disabled && `pointer-events:none;opacity:0.5;`}
	${(props) => props.width && `width: ${props.width}px; min-width: ${props.width}px; min-width: ${props.width}px;`};
	${(props) => props.minHeight && `min-height: ${props.minHeight}px`};
	${props => props.minWidth && `min-width: ${props.minWidth}`};
	.generic-button-delete {
		display: none;
	}
	:hover {
		${(props) => props.allowBackgroundHover && `background-color:#EFEFEF`};
	}
	&:hover .generic-button-delete {
		display: block;
	}
`;
export const FlexEndAlignCenter = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	${(props) => props.gap && `gap: ${props.gap}px`};
	${(props) => props.disabled && `pointer-events:none;opacity:0.5;`}
`;

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.gap}px;
	${(props) => props.maxWidth && `max-width: ${props.maxWidth}%`};

`;

export const FlexColumnGap = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.gap}px;
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}
`;
export const FlexColumnAlignCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const FlexColumnJustifyBetween = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const FlexColumnAlignJustifyCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Label = styled.div`
	color: ${props => props.color? props.color :"#605f60" };
	font-family: "Inter";
	font-size: ${(props) => props.size}px;
	font-style: ${props => props.fs ? props.fs : "normal"};;
	font-weight:  ${props => props.fw ? props.fw : 400};
`;
export const FlexAlignStart = styled.div`
	display: flex;
	align-items: self-start;
	${(props) => props.gap && `gap: ${props.gap}px`};
	${(props) => props.minWidth && `min-width: ${props.minWidth}`};
`;

export const Borders = ({ children, top, bottom, left, right, color = "#EFEFEF", className, ...props }) => {
	// Styles based on the provided props
	const borderStyle = {
		borderTop: top ? `1px solid ${color}` : "none",
		borderBottom: bottom ? `1px solid ${color}` : "none",
		borderLeft: left ? `1px solid ${color}` : "none",
		borderRight: right ? `1px solid ${color}` : "none",
		minHeight: "30px",
	};

	return (
        <div style={borderStyle} className={className} {...props}>
			{children}
		</div>
	);
};


export const PageContainer = styled.div`
 padding: 3rem;

`;

export const PageHeader = styled(Row)`
	/* margin: 1.5rem !important; */
	/* margin-bottom: 0rem !important; */
	justify-content: space-between !important;
	display: flex !important;
`;

export const PageTitle = styled.h1`
	/* margin: 1.5rem !important;
	 padding: 0;
	color: #333333; */
`;

export const ScrollContainer = styled.div`
	//overflow-y: auto;
	width: 100%;


`;


export const EmptyListContainer = styled.div`
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${(props) => (props.small === "true" ? "" : "height: 24vh;   padding-top: 6rem;")};
	overflow: hidden;
	${(props) => (props.inNotification ? "height: 100%; padding-top:0rem;" : "")}
`;

export const EmptyListImg = styled.img`
	width: 150px;
`;

export const EmptyListText = styled.div`
	margin-top: 1rem;
`;
