import styled, { css } from "styled-components/macro";

export const Section = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const CustomCol = styled.div`
	min-height: 330px;
	height: 381px;
	padding: 0;
	#seeMore {
		display: flex;
	}
	:hover #seeMore {
		display: block;
	}
`;

export const TableContainer = styled.div`
	${props => props.hasData ?'' :'border: 1px solid #d9d9d9'};
	border-radius: 4px;
`;

export const Title = styled.h1`
	align-self: center;
	display: flex;
	align-items: center;
	color: #333;
	font-family: "Inter";
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-align: center;
`;

export const Description = styled.div`
	color: #605f60;
	display: flex;
	align-items: center;
	justify-content: space-between;
	a {
		color: #605f60;
		text-decoration: none;
	}
`;

export const NoElementContainer = styled.div`
	border: 1px solid #e1e1df;
	border-radius: 4px;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 273px;
	#no-data {
		color: #858585;
		text-align: center;
		margin-top: 20px;
	}
	img {
		align-self: center;
		width: auto;
		height: 96px;
	}
`;

export const PersonaContainer = styled.div`
	display: flex;
	justify-content: start;
	gap: 15px;
	max-width: 100%;
`;

export const MainContainer = styled.div`
	display: flex;
	height: inherit;
`;

export const ContainerLoading = styled.div`
	border: 1px solid #c4c4c4;
	max-width: 100%;
`;

export const WidgetContainer = styled.div`
	flex: 1 1 0;
	width: 0;
	position: relative;
	border-radius: 4px;
	background: ${(props) => props?.background};
	${(props) =>
		!props.isenterprise &&
		css`
			width: 100%;
		`}
`;

export const WidgetTitle = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 20px;
	font-weight: 500;
	font-style: normal;
	line-height: normal;
`;

export const WidgetDescription = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

export const ConnectorImg = styled.img`
	width: 24px;
`;
