import { BackgroundLabel } from "shared/ui-basics/ui-components/BackgroundLabel";
import { FlexAlignJustifyCenter } from "shared/ui-basics/index";
import EmptyData from "features/analytics/Components/EmptyData";
import TextAndValueTooltip from "features/analytics/Components/Tooltips/TextAndValueTooltip";
import { topTenWithLabelsDataSelector } from "features/analytics/view/JourneyTrends/journeyTrendDataSelectors";
import { TickStyle } from "features/analytics/Style";
import { useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";

function TopTenWithLabels({ currentDataAttributes, linkedIdsAttribute, barFill, emptyDataText }) {
	const data = useSelector((state) => topTenWithLabelsDataSelector(state, { currentDataAttributes, linkedIdsAttribute }));
	const maxValue = data?.length > 0 ? data[0]?.value : 0;

	const renderLabel = ({ item, x, y }) => {
		const xPosition = String(x - ((item?.name?.length || 2) / 2) * 5);
		return (
			<foreignObject x={xPosition} y={y - 30} width="140" height="40">
				<BackgroundLabel id={item?.id} className="d-flex me-1" color={item?.colorCode}>
					<LabelName>{item?.name}</LabelName>
				</BackgroundLabel>
			</foreignObject>
		);
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return <TextAndValueTooltip text={label} value={payload[0].value} />;
		}

		return null;
	};

	return (
		<>
			{data?.length > 0 ? (
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						data={data}
						margin={{
							top: 10,
							left: -30,
						}}>
						<CartesianGrid strokeDasharray="3 3" vertical={false} layer="back" />
						<XAxis dataKey="name" tick={false} axisLine={{ stroke: "#c4c4c4", strokeWidth: 2 }} />
						<YAxis domain={[0, maxValue + 1]} tickCount={maxValue + 2} axisLine={false} tick={{ ...TickStyle }} />
						<Tooltip cursor={{ fill: "#efefef" }} isAnimationActive={false} content={CustomTooltip} />
						<Bar dataKey="value" fill={barFill} barSize={20}>
							<LabelList position="top" content={(props) => renderLabel({ ...props, item: data[props.index] })} />
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			) : (
				<FlexAlignJustifyCenter className="w-100 h-100">
					<EmptyData text={emptyDataText} icon="CHART-COLUMN" />
				</FlexAlignJustifyCenter>
			)}
		</>
	);
}

TopTenWithLabels.displayName = "TopTenWithLabels";
export default TopTenWithLabels;

const LabelName = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
