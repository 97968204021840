import { createCxAction, getAllAuthorizedUsers, getAllCxAction, getAllMapsListData, getMapUsers, groupInit, priorityInit, statusInit, tagInit, updateCxAction } from "@redux/index.js";
import { FindActionById, retrieveAllActiveActions } from "@redux/memoizedSelectors.js";
import { EMPTY_GUID, createDefaultAction, decode, encode, extractTextFromHtml, getFromLocalStorage } from "@utils/helpers.js";
import { urlOriginStatus } from "@utils/optionsSets/OptionSets.js";
import BaseActions from "features/cx-actions/actions/BaseActions.js";
import HeaderActions from "features/cx-actions/actions/HeaderActions.js";
import { base } from "index.js";
import { forwardRef, memo, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { BreadcrumbItem } from "reactstrap";
import { CustomEditor } from "shared/components/CustomEditor.js";
import DataLoader from "shared/components/data-loader.jsx";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown.js";
import { useAttributeHandler } from "shared/hooks/index";
import { Flex, FlexBetween, GenericButton, Input, SearchBar } from "shared/ui-basics/index.js";
import DisplayStatus from "../../../shared/cx-components/DisplayStatus.js";
import Group from "../../../shared/cx-components/Group.js";
import MapAndStagePicker from "../../../shared/cx-components/MapAndStagePicker.js";
import OwnerPickList from "../../../shared/cx-components/OwnerPickList.js";
import Priority from "../../../shared/cx-components/Priority.js";
import Tags from "../../../shared/cx-components/Tags.js";
import * as Style from "../../../shared/cx-components/style.js";
import NoDataComponent from "../../auth/view/no-data-component.jsx";

function CxActionPage(props) {
	const { search } = useLocation();
	const dispatch = useDispatch();
	const { selectedItemId } = search.getCxProps(props.query);

	const userInfo = useSelector((state) => state.auth.userInfo);
	const selectedAction = useSelector(FindActionById(selectedItemId));

	const mapUserParameters = {
		cjmId: selectedAction?.mapId,
		userId: userInfo?.UserId,
		cxOwnerEntity: selectedAction?.ownerId,
	};
	const asyncFunctions = [getAllCxAction, getAllMapsListData, getAllAuthorizedUsers, statusInit, groupInit, priorityInit, tagInit];

	useEffect(() => {
		if (selectedAction?.mapId) {
			dispatch(getMapUsers(mapUserParameters));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAction?.mapId]);

	return (
		<Style.ContainerPage query={props.query}>
			<DataLoader reduxActions={asyncFunctions} disabledSpinner={selectedAction !== undefined}  >
				{selectedAction ? <CxActions {...props} /> : <NoDataComponent label={"CX_ACTION_NOT_FOUND"} icon="CIRCLE-EXCLAMATION" />}
			</DataLoader>
		</Style.ContainerPage>
	);
}

const CxActions = ({ query, setQuery, openBlankForm, onClose }) => {
	const { search } = useLocation();
	const { selectedItemId, displayList, origin, cjmId, personaId } = search.getCxProps(query);
	const backQuery = search.getBackQuery(); // cxProgram
	const backQueryDecripted = getFromLocalStorage(backQuery); // return cxProgramId
	const folderId = search.getFolderId();
	const { t } = useTranslation();
	const history = useHistory();
	const ref = useRef(null);
	const userInfo = useSelector((state) => state.auth.userInfo);
	const currentUser = useSelector((state) => state.maps.currentUser);
	const selectedAction = useSelector(FindActionById(selectedItemId));
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType).isReadOnly();
	const [showAllCxActions, setShowAllCxActions] = useState(displayList ? true : false);
	const [counter, setCounter] = useState(0);
	const updatecxAction = (modifiedCxAction, modifiedAttribNames, beforeModifiedCxAction, onSuccess) => updateCxAction({ modifiedCxAction, modifiedAttribNames, beforeModifiedCxAction, onSuccess });

	const { object, setObject, handleChange, handleBlur, setModifiedAttribNames, modifiedAttribNames } = useAttributeHandler(selectedAction, updatecxAction);
	const disableViewer = isReadOnly || (currentUser?.roleName === "Viewer" && object.ownerId !== userInfo.UserId) ? true : false;
	const objectRef = useRef(object);
	useEffect(() => {
		if (selectedAction) {
			setObject(selectedAction);
			setModifiedAttribNames(new Set());
			setCounter(counter + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItemId]);
	useEffect(() => {
		objectRef.current = object;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [object.mapId, object.stageId]);
	useEffect(() => {
		return () => {
			// Use `objectRef.current` to access the latest value of `object`
			if (objectRef.current?.mapId !== EMPTY_GUID && objectRef.current?.stageId === EMPTY_GUID) {
				toast.info(t("JOURNEY_MAP_NOT_SAVED"));
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRedirect = (response) => {
		if (object.mapId !== EMPTY_GUID && object.stageId === EMPTY_GUID) {
			// case we select a map without a stage
			return toast.info(t("PLEASE_SELECT_A_STAGE"));
		}
		if (modifiedAttribNames.size > 0) {
			updatecxAction({ ...object, description: encode(object.description), subject: encode(object.subject) }, [...modifiedAttribNames]);
		}

		if (origin === urlOriginStatus.DefaultReturn) return history.goBack();

		if (backQueryDecripted) return history.push(`EditCxProgram?programid=${backQueryDecripted}&tab=relationship`);

		if (response.id) {
			if (query) return setQuery({ ...query, id: response.id });
			history.push(`/edit-cxactions?id=${response.id}`);
		} else {
			if (cjmId && personaId) {
				return history.push(`/EditMap?cjmId=${cjmId}&personaId=${personaId}`);
			}
			const nextId = ref.current?.getNextId();
			if (query && nextId) return setQuery({ ...query, id: nextId }); // change to the next one in the list
			if (showAllCxActions && nextId && response?.id) return history.push(`/edit-cxactions?id=${nextId}`); // if I delete or archive in the navigationView from the form

			history.push(`/cx-actions${folderId ? `?folderid=${folderId}` : ""}`);

		}
	};
	const redirectToForm = () => {
		if (openBlankForm) {
			window.open(`${base}edit-cxactions?id=${object.id}`, '_blank');
		} else {
			history.push(`/edit-cxactions?id=${object.id}`);
			if (query)
				setQuery(false);
		}
	};


	const Dates = memo(() => {
		const [localDate, setLocalDate] = useState(object?.dueDate?.formatDate());

		const handleBlur = () => {
			if (localDate)
				handleChange("dueDate", new Date(localDate).toISOString(), true);
		};

		return (
			<div>
				<Style.Labels> {t("DUE_DATE")}</Style.Labels>
				<Input
					variant="default"
					id="Date"
					name="dueDate"
					className="no-border"
					placeholder="date placeholder"
					disabled={disableViewer}
					value={localDate}
					type="date"
					onChange={(e) => setLocalDate(e.target.value)}
					onBlur={handleBlur}
				/>
			</div>
		);
	});
	Dates.displayName = "Dates_CxActionPage";

	return (
		<Style.ContainerCxPage query={query} disableViewer={disableViewer}>
			{showAllCxActions && <ListCxActionsComponent currentcxAction={object} disabled={disableViewer} query={query} setQuery={setQuery} ref={ref} />}
			<Style.ContainerItem key={counter} query={query}>
				<Style.Header query={query}>
					{!query && (
						<FlexBetween className="w-100">
							<Style.PathContainer>
								<GenericButton variant="light-link" className="m-2" icon={"ARROW_LEFT"} onMouseDown={handleRedirect} />
								<Style.CustomBreadcrumb className="m-0">
									<BreadcrumbItem>
										<GenericButton
											variant="light-link"
											className="me-2"
											onClick={() => setShowAllCxActions(!showAllCxActions)}
											tooltip
											tooltipText={t("SHOW_ALL_CX_ACTIONS")}
											icon={"SIDEBAR"}
										/>
										<Style.Path>{origin ? origin : t("ACTION_ITEM")}</Style.Path>
									</BreadcrumbItem>
									<BreadcrumbItem>
										<Style.Path className="fw-bold">{object?.subject?.defaultPlaceholder("NEW_CX_ACTION_NAME")}</Style.Path>
									</BreadcrumbItem>
								</Style.CustomBreadcrumb>
							</Style.PathContainer>

							<Style.ButtonContainer>
								<HeaderActions action={object} handleRedirect={handleRedirect} />
							</Style.ButtonContainer>
						</FlexBetween>
					)}
					<Style.MainTitle query={query}>
						<FlexBetween>
							<Style.Title
								variant="default"
								value={object.subject}
								onChange={(e) => handleChange("subject", e.target.value)}
								onBlur={() => handleBlur("subject")}
								disabled={disableViewer}
								title={object?.subject?.length > 0 && object.subject}
								placeholder={t("ENTER_TITLE")}

							/>
							{query && (
								<Flex>
									<GenericButton variant="light-link" className="me-2" icon={"ARROW_UP_RIGHT"} onClick={redirectToForm} />
									{CreateActionsDropdown({ actions: BaseActions({ action: object, handleRedirect, onClose }), variant: "none", dropdownClasses: "px-0" })}
								</Flex>
							)}
						</FlexBetween>
						<FlexBetween className="pt-2">
							<Tags object={object} setObject={setObject} recordType="cem_cxactiontask" disabled={disableViewer} />
						</FlexBetween>
					</Style.MainTitle>
				</Style.Header>
				{query && <Style.Divider className="ms-3" />}

				<Style.MainContainer query={query}>
					<Style.Left>
						<div>
							<Style.Labels className='my-2'>{t("ASPX_9")}</Style.Labels>
							<CustomEditor
								id={object.id}
								html={object.description}
								sethtml={(value) => handleChange("description", value)}
								disabled={disableViewer}
								variant="description"
								onBlur={() => handleBlur("description")}
							/>
						</div>
						<div>
							<Style.Labels className='my-2'>
								{t("JOURNEY")} & {t("ASPX_172")}
							</Style.Labels>
							<Style.MapStageContainer>
								<MapAndStagePicker object={object} attribute="mapId" setObject={setObject} disable={disableViewer} updateFunction={updatecxAction} />
								<MapAndStagePicker object={object} attribute="stageId" setObject={setObject} disable={disableViewer} updateFunction={updatecxAction} />

							</Style.MapStageContainer>
						</div>
					</Style.Left>
					<Style.Right className={query && "mt-3"}>
						<div>
							<Style.Labels className='my-2'>{t("ASPX_222")}</Style.Labels>
							<DisplayStatus object={object} type="status" name="cxActions" setObject={setObject} disable={disableViewer} updateFunction={updatecxAction} />
						</div>
						<div>
							<Style.Labels className='my-2'>{t("CS_35")}</Style.Labels>
							<OwnerPickList object={object} setObject={setObject} updateFunction={updatecxAction} disable={disableViewer} />
						</div>
						<div>
							<Style.Labels className='my-2'>{t("PRIORITY")}</Style.Labels>
							<Priority object={object} setObject={setObject} name="cxActions" updateFunction={updatecxAction} disable={disableViewer} />
						</div>
						<div>
							<Style.Labels className='my-2'>{t("GROUP")}</Style.Labels>
							<Group object={object} setObject={setObject} disabled={disableViewer} recordType="cem_cxactiontask" />
						</div>
						<Dates />
					</Style.Right>
				</Style.MainContainer>
			</Style.ContainerItem>
		</Style.ContainerCxPage>
	);
};

const ListCxActionsComponent = forwardRef(({ currentcxAction, disabled, query, setQuery }, ref) => {
	const { search } = useLocation();
	const { selectedItemId, queryNb, origin, cjmId, personaId } = search.getCxProps(query);
	const {cjmIdStr} = search.getAllMapsParams();

	const selectedActionRef = useRef(null);
	const history = useHistory();
	const { t } = useTranslation();

	const userInfo = useSelector((state) => state.auth.userInfo);
	const [searchValue, setSearchValue] = useState("");

	const encodedIds = localStorage.getItem(`/edit-cxactions-${queryNb}`);
	const ids = queryNb ? decode(encodedIds)?.split("|") : [];
	const selectedAction = useSelector(FindActionById(selectedItemId));

	const cxActionsMap = new Map(useSelector(retrieveAllActiveActions).map((cxAction) => [cxAction.id, cxAction]));
	const all_CxActions =
		ids?.length > 0
			? ids.map((id) => cxActionsMap.get(id)).filter(Boolean)
			: cjmIdStr
				? Array.from(cxActionsMap.values()).filter((cx) => cx.stageId === selectedAction.stageId)
				: [...cxActionsMap.values()];

	const filterCxAction = all_CxActions?.filter((cxAction) => cxAction.subject.toUpperCase().includes(searchValue.toUpperCase()));
	const handleChange = (e) => setSearchValue(e.target.value);


	const Search = SearchBar({ globalFilterValue: searchValue, handleChange })
	const CxActionCard = ({ cxaction, index }) => {
		const selectedCxAction = cxaction.id === selectedItemId ? currentcxAction : cxaction;
		const history = useHistory();

		const handleClick = () => {
			if (selectedCxAction.id !== selectedItemId) {
				if (query) return setQuery({ ...query, id: selectedCxAction.id });
				history.push(`/edit-cxactions?id=${selectedCxAction.id}${cjmId ? `&origin=${encode([origin, cjmId, personaId].join("|"))}` : ""}${ids?.length > 0 ? `&q=${queryNb}` : ""}`);
			}
		};

		return (
			<Style.QuickSelectionCard onClick={handleClick} active={selectedItemId === selectedCxAction.id} index={index} query={query}>
				<Style.QuickSelectionCardTitle title={selectedCxAction?.subject?.length > 5 && selectedCxAction.subject}>{selectedCxAction.subject.defaultPlaceholder("NEW_CX_ACTION_NAME")}</Style.QuickSelectionCardTitle>
				<Style.QuickSelectionCardDescription>{extractTextFromHtml(selectedCxAction.description)}</Style.QuickSelectionCardDescription>
			</Style.QuickSelectionCard>
		);
	};
	const handleRedirect = () => {
		const onSuccess = (data) => {
			history.push(`/edit-cxactions?id=${data.id}`);
		};
		const cxaction = createDefaultAction(userInfo);
		createCxAction({ item: cxaction, onSuccess, displayToast: "CREATE" });
	};
	useLayoutEffect(() => {
		if (selectedActionRef.current) {
			selectedActionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [selectedItemId]);
	useImperativeHandle(ref, () => ({
		getNextId: () => {
			const indexOfNextId = all_CxActions.findIndex((cxAction) => cxAction.id === selectedItemId) + 1;
			const nextId = all_CxActions[indexOfNextId]?.id;
			return nextId;
		},
	}));
	return (
		<Style.ListComponentWrapper query={query} className={query ? "" : "py-3"}>
			{!query && (
				<Style.Labels fs={18} className="m-2 ps-3">
					{" "}
					{t("ACTIONS_LIST")}
				</Style.Labels>
			)}
			<Style.HeaderListComponent query={query}>
				{!disabled && !queryNb && !cjmIdStr ? (
					<GenericButton variant="primary-link" className="me-2" onClick={handleRedirect} icon="SQUARE_PLUS" iconClassName="me-1">
						{t("NEW_ACTION")}
					</GenericButton>
				) : (
					<div></div>
				)}
				{Search}
			</Style.HeaderListComponent>
			<Style.WrapperItems disableScroll={filterCxAction?.length < 7}>

				{filterCxAction?.map((cxaction, index) => (
					<div key={cxaction.id} ref={cxaction.id === selectedItemId ? selectedActionRef : null} className="w-100">
						<CxActionCard key={index} cxaction={cxaction} index={index} />
					</div>
				))}
			</Style.WrapperItems>
		</Style.ListComponentWrapper>
	);
});
CxActions.displayName = "CxActions";
ListCxActionsComponent.displayName = "ListCxActionsComponent";
CxActionPage.displayName = "CxActionPage";
export default CxActionPage;
