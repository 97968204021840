import { EMPTY_GUID, GetActiveCJM, checkLinkMapBeforeAction } from "@utils/helpers";
import { PermissionManager } from "@utils/permissionManager";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ActionConfirmModal, DuplicateModal, LinkListCjmModel } from "shared/modals/index";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import { useModal } from "use-modal-hook";
import { archivePersona, copyPersona, deletePermanentlyPersona, deletePersona, restorePersona } from "../reducer/PersonaActions";

export default function BaseActions({ persona, context, setRename, handleRedirect }) {
	const { t } = useTranslation();
	const history = useHistory();
	const allMaps = useSelector((state) => state.maps.all_maps);
	const userId = useSelector((state) => state.auth?.userInfo?.UserId);
	const isInEdit = useLocation().pathname === "/EditPersona";
	const isInHome = useLocation().pathname === "/home";
	const isTemplate = persona.isTemplate;

	if (isInHome) context = "/personas";

	const userAccess = persona?.authorizedUsers?.find((user) => user.id === userId && user.roleId === EMPTY_GUID)?.roleName;
	const id = persona.personaId;

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const handleArchive = () => {
		actionConfirmModal({
			bodyContent: "ARCHIVE_PERSONA_WARNING",
			Action: () => archivePersona({ persona_id: id, onSuccess: handleRedirect }),
			actionBtnText: "ARCHIVE",
		});
	};

	const handleDelete = () => {
		actionConfirmModal({
			bodyContent: "DELETE_PERSONNA_NOT_PERMANENT",
			Action: () => deletePersona({ persona_id: id, onSuccess: handleRedirect }),
			actionBtnText: "ASPX_13",
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deletePermanentlyPersona({ persona_id: id }),
			actionBtnText: "ASPX_13",
		});
	};

	const callBackCopy = (response) => {
        if (typeof response === "object") {
			history.push(`/EditPersona?personaIdStr=${response?.personaId}`);
		}
	};

	const [showCopyModal] = useModal(DuplicateModal, {
		title: `${t("JS_61")} ${t("ASPX_74")}`,
		inputTitle: "JS_82",
		closeBtnLabel: "COPY",
		inputValue: `[${t("COPY_NOUN").toUpperCase()}]- ` + persona?.name,
		action: ({ name, selectedWorkspaces }) => copyPersona({ id, name, workspaceIds: selectedWorkspaces.map((w) => w?.id), onSuccess: isInEdit && callBackCopy }),
	});

	const [showAccessModal] = useModal(GenericManageAccess, {
		itemId: id,
		context: "/personas",
	});

	const [showLinkListCjm] = useModal(LinkListCjmModel);

	const CheckLinkedItems = (actionPopup, title, description_1) =>
		checkLinkMapBeforeAction({
			allMaps,
			personaObject: persona,
			linkedJourneyMap: GetActiveCJM(persona?.linkedCjms, allMaps),
			showActionPopup: actionPopup,
			showLinkListCjm,
			title,
			description_1,
		});

	const { renameAllowed, copyAllowed, archiveAllowed, deleteAllowed, manageAccessAllowed, restoreAllowed, deletePermanentAllowed } = PermissionManager({
		userAccess,
		context,
	});

	const createActions = () => {
		let actions = [];
		if (renameAllowed && setRename) actions.push({ id: "rename", handleClick: () => setRename(id), icon: "EDIT", text: t("JS_89") });
		if (copyAllowed) actions.push({ id: "copy", handleClick: showCopyModal, icon: "COPY", text: t("JS_61") });
		if (!isTemplate && manageAccessAllowed) actions.push({ id: "manageAccess", handleClick: showAccessModal, icon: "ACCESS", text: t("ASPX_17") });
		if (archiveAllowed || deleteAllowed) actions.push({ id: "divider", divider: true });
		if (archiveAllowed) {
			actions.push({
				id: "archive",
				handleClick: () => CheckLinkedItems(handleArchive, "PERSONA_ARCHIVE_IMPOSSIBLE_TITLE", "PERSONA_ARCHIVE_IMPOSSIBLE_DESC1"),
				icon: "ARCHIVE",
				text: t("ARCHIVE"),
			});
		}
		if (deleteAllowed) {
			actions.push({
				id: "delete",
				handleClick: () => CheckLinkedItems(handleDelete, "PERSONA_DELETE_IMPOSSIBLE_TITLE", "PERSONA_DELETE_IMPOSSIBLE_DESC1"),
				icon: "DELETE",
				text: t("CS_45"),
			});
		}
		if (restoreAllowed) actions.push({ id: "restore", handleClick: () => restorePersona({ persona_id: id }), icon: "RESTORE", text: t("JS_96") });
		if (deletePermanentAllowed) actions.push({ id: "deletePermanent", handleClick: handleDeletePermanent, itemStyle: "warning-delete", icon: "DELETE", text: t("JS_97") });
		if (persona?.isSample) {
			actions = actions.filter((action) => action.id === "copy");
		}
		return actions;
	};

	return createActions();
}
