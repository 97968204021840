import styled from "styled-components";

function TextAndValueTooltip({ text, value }) {
	return (
		<TooltipContainer className="custom-tooltip p-2" style={{ background: "white" }}>
			<div>{`${text}: ${value}`}</div>
		</TooltipContainer>
	);
}

TextAndValueTooltip.displayName = "TextAndValueTooltip";
export default TextAndValueTooltip;

const TooltipContainer = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	display: flex;
	justify-content: center;
	border: 1px solid #ccc;
	min-width: 120px;
`;
