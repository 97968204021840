import styled from "styled-components"

export const ContainerLoading = styled.div`
border: 1px solid #c4c4c4;

`
export const CardLoading = styled.div`
display: flex;
border-bottom: 1px solid #c4c4c4;
height: 60px;
width: 100%;
justify-content: space-evenly;
`
export const Center = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 0.5rem;
`
export const CardPersonas = styled.div`
    width: 220px;
    height: 280px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
`
