import { EMPTY_GUID } from "@utils/helpers";
import { PermissionManager } from "@utils/permissionManager";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ActionConfirmModal, DuplicateModal } from "shared/modals/index";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import { useModal } from "use-modal-hook";
import { archiveProjects, copyProject, deleteProjects, deleteProjectsPermanent, restoreProjects } from "../reducer/ProjectsActions";

export default function BaseActions({ project, context, setRename, handleRedirect }) {
	const { t } = useTranslation();
	const history = useHistory();
	const userId = useSelector((state) => state.auth?.userInfo?.UserId);
	const isInWorkspace = useLocation().pathname === "/projects";

	const userAccess = project?.authorizedUsers?.find((user) => user.id === userId && user.roleId === EMPTY_GUID)?.roleName;
	const id = project.id;

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const handleArchive = () => {
		actionConfirmModal({
			bodyContent: "ARCHIVE_GENERIC",
			Action: () => archiveProjects({ ids: [id], onSuccess: handleRedirect }),
			actionBtnText: "ARCHIVE",
		});
	};

	const handleDelete = () => {
		actionConfirmModal({
			bodyContent: "DELETE_GENERIC",
			Action: () => deleteProjects({ ids: [id], onSuccess: handleRedirect }),
			actionBtnText: "ASPX_13",
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deleteProjectsPermanent({ ids: [id] }),
			actionBtnText: "ASPX_13",
		});
	};

	const callBackCopy = (response) => {
		if (typeof response === "object") {
			history.push(`/EditProject?id=${response?.id}`);

		}
	};

	const [showCopyModal] = useModal(DuplicateModal, {
		title: `${t("JS_61")} ${t("PROJECT")}`,
		inputTitle: "PROJECT_NAME",
		closeBtnLabel: "COPY",
		inputValue: `[${t("COPY_NOUN").toUpperCase()}]- ` + project?.name,
		action: ({ name, selectedWorkspaces }) => copyProject({ id, name, workspaceIds: selectedWorkspaces.map((w) => w?.id), onSuccess: !isInWorkspace && callBackCopy }),
	});

	const [showAccessModal] = useModal(GenericManageAccess, {
		itemId: id,
	});

	const { renameAllowed, copyAllowed, archiveAllowed, deleteAllowed, manageAccessAllowed, restoreAllowed, deletePermanentAllowed } = PermissionManager({
		userAccess,
		context,
	});

	const createActions = () => {
		let actions = [];
		if (renameAllowed && setRename) actions.push({ id: "rename", handleClick: () => setRename(id), icon: "EDIT", text: t("JS_89") });
		if (copyAllowed) actions.push({ id: "copy", handleClick: showCopyModal, icon: "COPY", text: t("JS_61") });
		if (manageAccessAllowed) actions.push({ id: "manageAccess", handleClick: showAccessModal, icon: "ACCESS", text: t("ASPX_17") });
		if (archiveAllowed || deleteAllowed) actions.push({ id: "divider", divider: true });
		if (archiveAllowed) {
			actions.push({
				id: "archive",
				handleClick: handleArchive,
				icon: "ARCHIVE",
				text: t("ARCHIVE"),
			});
		}
		if (deleteAllowed) {
			actions.push({
				id: "delete",
				handleClick: handleDelete,
				icon: "DELETE",
				text: t("CS_45"),
			});
		}
		if (restoreAllowed) actions.push({ id: "restore", handleClick: () => restoreProjects({ ids: [id] }), icon: "RESTORE", text: t("JS_96") });
		if (deletePermanentAllowed) actions.push({ id: "deletePermanent", handleClick: handleDeletePermanent, itemStyle: "warning-delete", icon: "DELETE", text: t("JS_97") });
		if (project?.isSample) {
			actions = actions.filter((action) => action.id === "copy");
		}
		return actions;
	};

	return createActions();
}
