import { deleteMeasure, deleteValueLibrary, upsertMeasure, upsertValueLibrary } from "@redux/index";
import { EMPTY_GUID, encode, isUserAllowed } from "@utils/helpers";
import PickListData from "@utils/optionsSets/PickListData";
import $ from "jquery";
import _ from "lodash";
import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { List } from "react-virtualized";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useClickOutside } from "shared/hooks/index";
import { GenericButton, SearchBar } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import { ActionConfirmModal } from "../basic/ActionConfirmModal";
import SelectedCxGoals from "./KPI/SelectedCxGoals";
import { KPICard } from "./KPICard";
import * as Style from "./Style";
import { LibraryCardModal } from "./libraryCard";

const LibrariesLinksComponent = memo((props) => {
	const { isOpen, onClose, indexLibrary, stageId, selectedItems = [], linkFunctionToEntity, cjmId } = props;
	const { t } = useTranslation();
	const myRef = React.createRef();

	const contextData = useContextData({ props, indexLibrary, t });
	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const { libraryId } = contextData;
	const [item, setItem] = useState(contextData.getDefaultItem);
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [selected, setSelected] = useState([]);
	const listData = contextData?.listData?.sortAsc("name")?.searchFilter(globalFilterValue, "name");

	const handleChange = (e) => setGlobalFilterValue(e.target.value);

	const handleCustomCreate = (e) => {
		if (item?.name === "") return;
		const isNameExist = contextData?.listData?.filter((it) => it.name.toLowerCase() === item?.name.toLowerCase())?.length > 0;
		if (isNameExist) {
			return toast.info(t("NAME_EXISTS"));
		}

		const copyItem = { ...item };
		copyItem.name = encode(item?.name);

		const onSuccess = (value) => {
			setItem(contextData.getDefaultItem);
			setSelected([...selected, value.id]);

			setTimeout(() => {
				const newLibraryValue = $(`#libraryItem_${value.id}`)[0];
				if (newLibraryValue) {
					newLibraryValue?.scrollIntoView({ block: "center", behavior: "smooth", inline: "start" });
				}
			}, 500);

		};
		contextData.upsertFunction({ libraryValue: copyItem, libraryId, onSuccess, displayToast: "CREATE_NO_SUCCESS_TOAST", measure: copyItem });
	};

	const handleAddButton = () => {
		const objectSelected = [];
		selected.forEach((id, index) => {
			const item = listData?.find((x) => x.id === id);
			objectSelected.push(item);
		});
		linkFunctionToEntity(objectSelected);
		onClose();
	};

	const Search = SearchBar({ globalFilterValue, handleChange, size: "small", label: `SEARCH`, floatLabel: false, height: 42 });

	return (
		<Style.OffcanvasLayout show={isOpen} onHide={onClose} scroll backdrop placement="end">
			{contextData.SelectedView !== undefined && selected.length > 0 && (
				<contextData.SelectedView cjmId={cjmId} stageId={stageId} selectedMeasures={selected} setSelected={setSelected} addSelected={contextData.addSelectedItems} onClose={onClose} />
			)}
			<ModalHeader toggle={onClose}>{t("SELECT") + " "}{contextData.title}</ModalHeader>
			<ModalBody>
				<Style.FirstRow className="pe-4">
					<Style.SearchWrapper className="ms-2 mt-2 mb-4">{Search}</Style.SearchWrapper>
					{isUserAllowed(userType, "delete-library") && (
						<Style.DivWrapper mode="create" className="d-flex m-2 mt-1 mb-4">
							<Style.CheckBoxContainer></Style.CheckBoxContainer>
							<contextData.CardComponent mode="create" item={item} setItem={setItem} title={contextData.title} libraryId={libraryId} />
							<Style.ButtonWrapper variant="primary" onClick={handleCustomCreate}>
								{t("JS_40")}
							</Style.ButtonWrapper>
						</Style.DivWrapper>
					)}
				</Style.FirstRow>
				{contextData.listDescription && <Style.ListDescription className="ms-2">{contextData.listDescription}</Style.ListDescription>}
				<Style.ModalBox className="mt-2 mb-4">
					{listData?.length > 0 && <List
						width={437}
						height={700}
						rowCount={listData?.length}
						rowHeight={60}
						rowRenderer={({ key, index, style }) => (
							<div key={listData[index].id} style={style} ref={myRef} id={`libraryItem_${listData?.[index].id}`}>
								<Item libraryId={libraryId} selectedItems={selectedItems} item={listData?.[index]} setSelected={setSelected} selected={selected} contextData={contextData} />
							</div>
						)}
					/>}
				</Style.ModalBox>
			</ModalBody>
			{!contextData.SelectedView && (
				<ModalFooter className="d-flex justify-content-end">
					<GenericButton variant="outline-secondary" className=" me-2 align-self-end" onClick={onClose}>
						{t("ASPX_86")}
					</GenericButton>
					<GenericButton variant="primary" disabled={selected.length === 0} onClick={handleAddButton}>
						{t("ASPX_12")}
					</GenericButton>
				</ModalFooter>
			)}
		</Style.OffcanvasLayout>
	);
});

const Item = ({ libraryId, item, selected, selectedItems, contextData, setSelected }) => {
	const { t } = useTranslation();
	const ref = useRef();

	const userType = useSelector((state) => state.auth.userInfo.UserType);
	const isItemSelected = selectedItems?.includes(item.id);
	const isUserAllowedToDelete = isUserAllowed(userType, "delete-library");

	const [_item, setItem] = useState(item);
	const [mode, setMode] = useState({
		update: false,
		focus: item.setFocus,
		delete: false,
	});

	const toolTipTextDelete = isItemSelected ? t(contextData.toolTipIsUsed) : isUserAllowedToDelete ? t("ASPX_13") : t("NO_PRIVILEGE_RECORD");

	const tooltipEditButton = isUserAllowedToDelete ? t("ASPX_10") : t("NO_PRIVILEGE_RECORD");
	const disabledDelete = isItemSelected || !isUserAllowedToDelete;
	const isChecked = selected?.includes(item.id) || selectedItems?.includes(item.id);
	const { CardComponent } = contextData;

	useLayoutEffect(() => {
		if (item.setFocus && ref.current) {
			ref.current.scrollIntoView();
		}
	}, []);

	const handleClickOutside = () => {
		if ((item?.name !== _item.name || _item.codeCode !== item.colorCode) && mode.update) {
			if (_item.name.isEmptyString()) return toast.info(t("NAME_EMPTY"));
			const copyObject = { ..._item };
			copyObject.name = encode(_item.name);
			copyObject.update = "name";
			contextData.upsertFunction({ libraryValue: copyObject, libraryId, displayToast: "UPDATE", measure: copyObject });
		}
		setMode({ update: false, focus: false, delete: false });
	};
	const handleDelete = () => {
		const onSuccess = () => setMode({ ...mode, delete: false });
		contextData.deleteFunction({ libraryValueId: item.id, libraryId, measureId: item.id, onSuccess });
		hideModalDeletePopup();
	};
	const handleCheckboxChange = (e) => {
		const id = e.currentTarget.id;
		if (selectedItems?.includes(id)) return;
		if (selected.includes(id)) {
			setSelected(selected.filter((item) => item !== id));
		} else {
			setSelected([...selected, id]);
		}
	};

	const [showModalDeletePopup, hideModalDeletePopup] = useModal(ActionConfirmModal, {
		bodyContent: t(contextData.textBodyDelete),
		Action: handleDelete,
		actionBtnText: "ASPX_13",
	});
	useClickOutside(ref, handleClickOutside);
	return (
		<Style.ListObjectWrapper ref={ref}>
			<Style.DivWrapper
				focus={mode.focus}
				id={item.id}
				mode={mode.update ? "update" : "view"}
				className="d-flex m-2"
				onClick={mode.update || selectedItems.includes(item.id) ? null : handleCheckboxChange}>
				<Style.CheckBoxContainer>
					<Style.CheckBoxWrapper
						type="checkbox"
						disabled={selectedItems.includes(item.id) ? "disabled" : ""}
						className="m-2"
						id={item.id}
						checked={isChecked}
						onChange={handleCheckboxChange}
					/>
				</Style.CheckBoxContainer>
				<CardComponent mode={mode.update ? "update" : "view"} item={_item} setItem={setItem} libraryId={libraryId} title={contextData.title} />
				{item.isCustom && (
					<Style.ListObjectActions>
						<GenericButton variant="light-link" icon="EDIT" size={"xs"} tooltip tooltipText={tooltipEditButton} onClick={() => setMode({ ...mode, update: true })} />
						{!mode.delete && <GenericButton variant="light-link" icon="X" size={"xs"} tooltip tooltipText={toolTipTextDelete} disabled={disabledDelete} onClick={showModalDeletePopup} />}
					</Style.ListObjectActions>
				)}
			</Style.DivWrapper>
		</Style.ListObjectWrapper>
	);
};
const getDefaultItem = (colors) => {
	return {
		name: "",
		id: EMPTY_GUID,
		colorCode: colors,
	};
};

const useContextData = ({ props, indexLibrary, t }) => {
	const defaultColors = props.context === "kpis" ? "#ffffff" : PickListData(props.context === "custom" ? "palette-library" : "palette-" + props.context.slice(0, -1))[0];
	const contextData = {
		toolTipIsUsed: "SYSTEM_USE",
		textBodyDelete: "ASPX_104",
		getDefaultItem: getDefaultItem(defaultColors),
	};
	switch (props.context) {
		case "kpis":
			contextData.title = t("MEASURE");
			contextData.toolTipIsUsed = "KPI_USE";
			contextData.textBodyDelete = "DELETE_KPI";
			contextData.listDescription = t("MEASURES_LIBRARY");
			contextData.upsertFunction = (props) => upsertMeasure({ ...props });
			contextData.deleteFunction = (props) => deleteMeasure({ ...props });
			contextData.CardComponent = KPICard;
			contextData.addSelectedItems = props?.addSelected;
			contextData.listReducerPath = "libraries.measures";
			contextData.SelectedView = SelectedCxGoals;
			break;
		case "systems":
			contextData.title = t("SYSTEMS");
			contextData.toolTipIsUsed = "SYSTEM_USE";
			contextData.textBodyDelete = "DELETE_SYSTEM";
			contextData.upsertFunction = (props) => upsertValueLibrary({ ...props });
			contextData.deleteFunction = (props) => deleteValueLibrary({ ...props });
			contextData.CardComponent = LibraryCardModal;
			contextData.listReducerPath = ["libraries", "customLibraries", indexLibrary];

			break;
		case "departments":
			contextData.title = t("DEPARTMENTS");
			contextData.toolTipIsUsed = "DEPARTMENT_USE";
			contextData.textBodyDelete = "DELETE_DEPARTMENT";
			contextData.upsertFunction = (props) => upsertValueLibrary({ ...props });
			contextData.deleteFunction = (props) => deleteValueLibrary({ ...props });
			contextData.CardComponent = LibraryCardModal;
			contextData.listReducerPath = ["libraries", "customLibraries", indexLibrary];

			break;
		case "custom":
			contextData.toolTipIsUsed = "LIBRARY_USE";
			contextData.textBodyDelete = "DELETE_LIBRARY_VALUE";
			contextData.upsertFunction = (props) => upsertValueLibrary({ ...props });
			contextData.deleteFunction = (props) => deleteValueLibrary({ ...props });
			contextData.CardComponent = LibraryCardModal;
			contextData.listReducerPath = ["libraries", "customLibraries", indexLibrary];

			break;
		default:
			break;
	}
	const originalBaseList = useSelector((s) => _.get(s, contextData.listReducerPath));
	contextData.listData = props.context === "kpis" ? originalBaseList : originalBaseList?.values;
	contextData.title = contextData?.title || originalBaseList?.name;
	contextData.libraryId = useSelector((s) => _.get(s, ["libraries", "customLibraries", indexLibrary]))?.id;

	return contextData;
};
LibrariesLinksComponent.displayName = "LibrariesLinks";

export const LibrariesLinks = LibrariesLinksComponent;
