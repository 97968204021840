import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const MapIconContainer = styled.div`
	height: 55px;
	width: 55px;
	margin-right: 1rem;

	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
	& img {
		width: 55px;
		object-fit: cover;
		position: relative;
		border-bottom-left-radius: 0.25rem;
		border-top-left-radius: 0.25rem;
		margin-right: 0.5rem;
	}
`;
export const MapCardContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 0.25rem;
	box-shadow: 1px 1px 3px rgb(33 33 33 / 30%);
	border: 1px solid #c4c4c4;
	line-height: 40px;
	height: 55px;
	margin-top: 0 !important;
	padding-right: 0 !important;
	margin-bottom: 5px !important;
	font-size: 16px !important;
	${(props) =>
		props.IsSelected &&
		`
   background-color: #E2DFEE;
   `}
	${(props) =>
		props.haveHover &&
		`
    cursor:pointer;
    &:hover 
    {
      background-color:#e9ecef;
    }
  `}
`;
export const Redirect = styled(Link)`
	text-decoration: none;

`;
export const WrapperImgPersona = styled.div`
	margin-top: 5px;
	display: flex;
	justify-content: center;
	position: relative;
`;
export const PersonaImage = styled.img`
	border: 2px solid #92919d;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	object-fit: cover;
	box-sizing: border-box;
	margin-top: 15px;
`;
export const StyledMapName = styled.div`
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 230px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: pointer;
`;
