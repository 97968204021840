import { getAllActiveWorkspaces } from "@redux/memoizedSelectors";
import { SubscriptionPlan } from "@utils/optionsSets/OptionSets";
import { RecordsSelector } from "features/workspace/components/others/records-selector";
import WorkspaceLogoAndName from "features/workspace/components/others/workspace-logo-and-name";
import { shareManyWorkspaces } from "features/workspace/reducer/WorkspaceActions";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, GenericButton, IconDispatcher, Label } from "shared/ui-basics";
import styled from "styled-components/macro";

export const BulkUpdateWorkspaces = ({ nselected, items, onSuccess, setDisableBulkActions }) => {
	const { t } = useTranslation();
	const users = useSelector((state) => state.settings.users);
	const authorizedUsers = useSelector((state) => state.settings?.authorizedUsers);
	const allActivesWorkspaces = useSelector(getAllActiveWorkspaces);
	const isMultiSubscription = useSelector((state) => state.settings?.subscriptionDetails?.subscriptionContracts)?.length > 1;
	const userInfo = useSelector((state) => state.auth?.userInfo);
	const isConfigAllowed = ["Enterprise"].includes(userInfo?.UserSubscriptionPlanName);

	const [selected, setSelected] = useState([]);
	const [show, setShow] = useState(false);

	const ids = items
		.filter((i) => {
			if (isMultiSubscription && Number(i.subscriptionPlan) !== SubscriptionPlan.Enterprise) return false;
			return true;
		})
		?.map((i) => i.id);

	const selectedUsers = users.filter((u) => ids.includes(u.id));
	const selectedAuthorizedUsersIds = authorizedUsers.filter((au) => selectedUsers.find((u) => au?.domainName.toLowerCase() === u.loginName?.toLowerCase())).map((au) => au?.id);
	const areAllActionsValid = nselected === ids.length;

	const onSave = () => {
		setShow(false);
		setDisableBulkActions(true);
		shareManyWorkspaces({
			userIds: selectedAuthorizedUsersIds,
			workspaceIds: selected?.map((s) => s.id),
			authorizedUsers,
			onSuccess,
			displayToast: "UPDATE",
			toastParams: { success: areAllActionsValid ? t("USERS_BULK_ACTIONS_SUCCESS") : t("USERS_BULK_ACTIONS_PARTIAL_SUCCESS") },
		});
	};

	const handleToggle = (isOpen) => {
		setShow(isOpen);
	};

	const handleSelect = ({ record }) => {
		setSelected([...selected, record]);
	};

	const handleRemove = ({ record }) => {
		setSelected(selected?.filter((s) => s.id !== record.id));
	};

	const renderCustomComponent = ({ record }) => <WorkspaceLogoAndName workspace={record} size="xs" isDisabled={true} />;

	if(!isConfigAllowed) return null;
	return (
		<DropDownContainer drop="up" show={show} onToggle={handleToggle}>
			<Dropdown.Toggle id="dropdown-custom-components" as={Button} variant="light-bulk">
				<ActionContainer>
					<div className="me-2">{IconDispatcher("home")}</div>
					<div>{t("ASPX_242")}</div>
				</ActionContainer>
			</Dropdown.Toggle>
			<MenuContainer>
				<WorspacesContainer className="p-3">
					<Label className="mb-2">{t("ASSIGN_AS_WORKSPACE_MEMBER")}</Label>
					<RecordsSelector allRecords={allActivesWorkspaces} selectedRecords={selected} renderCustomComponent={renderCustomComponent} onSelect={handleSelect} onRemove={handleRemove} />
				</WorspacesContainer>
				<ButtonContainer className="p-3">
					<GenericButton variant="primary" onClick={onSave}>
						{t("SAVE")}
					</GenericButton>
				</ButtonContainer>
			</MenuContainer>
		</DropDownContainer>
	);
};

BulkUpdateWorkspaces.displayName = "BulkUpdateWorkspaces";

const DropDownContainer = styled(Dropdown)`
	.dropdown-toggle::after {
		display: none;
	}
	.dropdown-menu {
		padding: 0px;
		margin: 0px;
	}
`;

const MenuContainer = styled(Dropdown.Menu)`
	width: 392px;
	transform: translate3d(0px, -54px, 0px) !important;
`;
const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`;

const WorspacesContainer = styled.div`
	border-bottom: 1px solid #efefef;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;
