import { error, success } from "@redux-requests/core";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, appendListsAndRemoveDuplicates, decode, firstLetterUppercase, hasAttribute, toastWrapper } from "@utils/helpers";
import { forEach } from "lodash";

const initialState = {
	current_project: {},
	all_projects: [],
	current_linked_users: [],
	loading: false,
};

const ProjectReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const responseData = action?.response?.data;
	switch (action.type) {
		case "RESET_PROJECT": {
			return {
				...state,
				current_project: {},
				loading: false,
			};
		}
		case "GET_ALL_PROJECTS": {
			return {
				...state,
				loading: state.all_projects.length === 0,
			};
		}
		case error("GET_ALL_PROJECTS"):
			return {
				...state,
				loading: false,
			};
		case success("GET_ALL_PROJECTS"): {
			const { url } = action?.meta?.requestAction?.request;
			if (url.includes("redirectReducer")) return state;
			return {
				...state,
				all_projects: responseData,
				loading: false,
			};
		}
		case "GET_PROJECT":
			return {
				...state,
				loading: true,
			};
		case success("GET_PROJECT"): {
			const data = responseData;
			data.attributesList = data.attributesDic === null ? [] : Object.entries(data.attributesDic)?.map((att) => att[1]);

			return {
				...state,
				current_project: { ...state.current_project, ...data },
				loading: false,
			};
		}
		case "NO_ACCESS_project": {
			return {
				...state,
				current_project: { noAccess: true },
				loading: false,
			};
		}
		case error("GET_PROJECT"):
			return {
				...state,
				loading: false,
			};
		case success("GET_PROJECT_USERS"): {
			const isPrint = action.meta.requestAction.params.isPrint;
			const userId = action.meta.requestAction.params.userId;

			const linkedUsers = Object.entries(action.response.data).map((user) => user[1]) || [];
			const user = linkedUsers.find((users) => users.id === userId && users.roleId === EMPTY_GUID);
			if (hasAttribute(user, "roleName")) user.roleName = isPrint ? "Viewer" : firstLetterUppercase(user?.roleName); // only to export pdf
			if (user?.roleName?.toLowerCase() === "viewer" && isPrint === undefined) toastWrapper("", "CX_PROGRAM_VIEWER_WARNING", { autoClose: 10000 });
			return {
				...state,
				current_linked_users: linkedUsers,
				currentUser: user,
			};
		}
		case success("CREATE_PROJECT"):
			return {
				...state,
				all_projects: [...state.all_projects, action.response.data],
			};
		case success("COPY_PROJECT"):
			return {
				...state,
				all_projects: [...state.all_projects, responseData],
			};
		case "UPDATE_PROJECT": {
			const { modifiedProject, modifiedAttribNames } = action?.request?.data;
			const currentProject = { ...state.current_project };

			forEach(modifiedAttribNames, (attr) => {
				if (attr === "name") currentProject.name = decode(modifiedProject?.name);
				else currentProject[attr] = modifiedProject[attr];
			});
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (project.id === requestData?.modifiedProject.id) {
						forEach(modifiedAttribNames, (attr) => {
							if (attr === "name") {
								project.name = decode(modifiedProject?.name);
							} else project[attr] = modifiedProject[attr];
						});
					}
					return project;
				}),
				current_project: {
					...state.current_project,
					...currentProject,
				},
			};
		}
		case success("RENAME_PROJECT"):
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (project.id === requestData?.id) {
						return {
							...project,
							name: decode(requestData?.newValue),
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return project;
				}),
				current_project: {
					...state.current_project,
					name: decode(requestData?.newValue),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("ARCHIVE_PROJECTS"): {
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (requestData?.ids.includes(project.id)) {
						return { ...project, statusCode: 100000000 };
					}
					return project;
				}),
			};
		}
		case success("RESTORE_PROJECTS"): {
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (requestData?.ids.includes(project.id)) {
						return { ...project, statusCode: 1 };
					}
					return project;
				}),
			};
		}
		case success("DELETE_PROJECTS"): {
			return {
				...state,
				all_projects: state?.all_projects?.map((project) => {
					if (requestData.ids.includes(project.id)) {
						return { ...project, statusCode: 2 };
					}
					return project;
				}),
			};
		}
		case success("DELETE_PROJECTS_PERMANENT"):
			return {
				...state,
				all_projects: state.all_projects.filter((project) => !requestData.ids.includes(project.id)),
			};
		case success("UPSERT_PROJECT_STAKEHOLDER"): {
			if (requestData.stakeholder.id === EMPTY_GUID) {
				return {
					...state,
					current_project: {
						...state.current_project,
						stakeholders: [...state.current_project.stakeholders, { ...action.response.data }],
						lastModifiedOn: new Date().toISOString(),
					},
				};
			} else {
				return {
					...state,
					current_project: {
						...state.current_project,
						stakeholders: state.current_project.stakeholders.map((stakeholder) => {
							if (stakeholder.id === requestData.stakeholder.id) stakeholder = responseData;
							return stakeholder;
						}),
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
		}
		case success("DELETE_PROJECT_STAKEHOLDER"):
			return {
				...state,
				current_project: {
					...state.current_project,
					stakeholders: state.current_project.stakeholders.filter((stakeholder) => stakeholder.id !== requestData.stakeholderId),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("DELETE_STAKEHOLDER_ROLE"):
			return {
				...state,
				current_project: {
					...state.current_project,
					stakeholders: state.current_project?.stakeholders?.filter((stakeholder) => stakeholder.roleId !== requestData.roleId),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_STAKEHOLDER_ROLE"):
			return {
				...state,
				current_project: {
					...state.current_project,
					stakeholders: state.current_project?.stakeholders?.map((stakeholder) => {
						if (stakeholder.roleId === requestData.roleId) {
							stakeholder.roleName = decode(requestData.newName);
						}
						return stakeholder;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("LINK_UNLINK_RECORDS_TO_PROJECT"):
			return {
				...state,
				current_project: {
					...state.current_project,
					linkedCjms: responseData[EntityList.CustomerJourney],
					linkedPersonas: responseData[EntityList.Persona],
					linkedCxProgram: responseData[EntityList.CXProgram],
					linkedOpps: responseData[EntityList.Opportunity],
					linkedSols: responseData[EntityList.Solution],
					linkedActions: responseData[EntityList.Action],
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("PROJECT_UPSERT_ATTRIBUTE_ITEM"): {
			if (requestData.attributeItem.id === EMPTY_GUID) {
				return {
					...state,
					current_project: {
						...state.current_project,
						attributesList: state.current_project?.attributesList?.map((attribute) => {
							if (attribute.id === requestData.attribute.id) {
								attribute.items = [...attribute.items, { ...responseData }];
							}
							return attribute;
						}),
						lastModifiedOn: new Date().toISOString(),
					},
				};
			} else {
				return {
					...state,
					current_project: {
						...state.current_project,
						attributesList: state.current_project?.attributesList?.map((att) => {
							if (att.id === requestData.attribute.id) {
								att.items = att.items.map((item) => {
									if (item.id === requestData.attributeItem.id) {
										item = { ...action.response.data, id: requestData.attributeItem.id };
									}
									return item;
								});
							}

							return att;
						}),
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
		}
		case success("PROJECT_DELETE_ATTRIBUTE_ITEM"): {
			return {
				...state,
				current_project: {
					...state.current_project,
					attributesList: state.current_project?.attributesList?.map((section) => {
						if (section.id === requestData.attributeId) {
							section.items = section.items.filter((item) => item.id !== requestData.attributeItemId);
						}
						return section;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("SHARE_PROJECT"): {
			const userId = state.current_linked_users.find((user) => user.id === requestData.userId);
			const teamId = state.current_linked_users.find((user) => user.id === requestData.teamId);
			const roleName = requestData.roleDetails.split("_")[0];
			const roleId = requestData.roleDetails.split("_")[1];
			const fullName = action.meta.requestAction.params?.fullName;

			const newLinkUsers =
				userId === undefined && teamId === undefined
					? [...state.current_linked_users, { id: userId, roleName, roleId, fullName }]
					: state.current_linked_users.map((user) => (user.id === userId || user.id === teamId ? { ...user, roleName, roleId } : user));

			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (project.id === requestData.recId) {
						return {
							...project,
							authorizedUsers: newLinkUsers,
						};
					}
					return project;
				}),
				current_linked_users: newLinkUsers,
			};
		}
		case success("SHARE_MANY_PROJECTS"): {
			const recIds = requestData.recIds;
			let linkUsers = [...state.current_linked_users];
			if (recIds.length === 1) {
				linkUsers = action.response.data.find((item) => item.id === recIds[0]).authorizedUsers;
			}
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					const getProjectFromResponse = action.response.data.find((item) => item.id === project.id);
					if (getProjectFromResponse) {
						return {
							...getProjectFromResponse,
						};
					}
					return project;
				}),
				current_linked_users: linkUsers,
			};
		}
		case success("UNSHARE_PROJECT"): {
			const { recId, userId, teamId } = requestData;
			const id = userId || teamId;
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (project.id === recId) {
						return {
							...project,
							authorizedUsers: project.authorizedUsers.filter((user) => user.id !== id),
						};
					}
					return project;
				}),
				current_linked_users: state.current_linked_users.filter((user) => user.id !== id),
			};
		}
		case success("LINK_TAGS"): {
			const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_cxproject") return state;
			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (recordIds.includes(project.id)) {
						project.tags = [...appendListsAndRemoveDuplicates(project.tags, tagIds)];
					}
					return project;
				}),
			};
		}
		case success("LINK_GROUPS"): {
			const { groupIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_cxproject") return state;

			return {
				...state,
				all_projects: state.all_projects?.map((project) => {
					if (recordIds.includes(project.id)) {
						project.groups = [...appendListsAndRemoveDuplicates(project.groups, groupIds)];
					}
					return project;
				}),
			};
		}
		case "RESET_PROJECT_USERS": {
			return {
				...state,
				current_linked_users: null,
			};
		}
		case success("UPDATE_MULTI_PROJECT"): {
			const { modifiedProjects, modifiedAttribNames } = requestData;
			const ids = modifiedProjects?.map((x) => x.id);

			return {
				...state,
				all_projects: state.all_projects?.map((action) => {
					if (ids.includes(action.id)) {
						return {
							...action,
							[modifiedAttribNames]: modifiedProjects?.find((x) => x.id === action?.id)?.[modifiedAttribNames],
						};
					}
					return action;
				}),
			};
		}
		case success("SYNCHRONIZE_PROJECT"): {
			return {
				...state,
				current_project: { ...responseData },
				loading: false,
			};
		}
		case success("STOP_SYNCHRONIZATION_PROJECT"): {
			return {
				...state,
				current_project: { ...responseData },
				loading: false,
			};
		}
		default:
			return state;
	}
};
export default ProjectReducer;
