import styled, { css } from "styled-components/macro";

export const BackgroundLabel = ({ id, size, color, children, disabled, className ,...props }) => {
	return (
		<Container key={id} id={id} className={className} size={size} color={color} disabled={disabled} {...props}>
			{children}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;

	${(props) => props.color && `
	color: #ffffff;
	background-color:${props.color};
	`};
	${(props) =>
		(props.color === undefined || props.color?.toLowerCase() === "#ffffff") &&
		css`
            color: #333333;
            border: 1px solid #efefef !important;
        `};
	border-radius: 0.25rem;
	padding: 0 0.25rem;
	width: fit-content;
	max-width: 100%;
	height: 24px;
	border: none;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	${(props) => props.maxWidth && `max-width:${props.maxWidth};`};

	${(props) =>
		props.size === "xs" &&
		css`
			height: 24px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "s" &&
		css`
			height: 32px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "m" &&
		css`
			height: 36px;
			font-size: 14px;
		`}
	${(props) =>
		props.size === "lg" &&
		css`
			height: 40px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xl" &&
		css`
			height: 48px;
			font-size: 16px;
		`}
    ${(props) =>
		props.size === "xxl" &&
		css`
			height: 60px;
			font-size: 24px;
		`}
	#editable {
		opacity: 0;
		visibility: hidden;
	}
	:hover #editable {
		opacity: 1;
		visibility: visible;
	}
	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}
`;
