import { selectGroup, selectQueryView, selectSelectedViewId, selectStatus, selectTag } from "@redux/memoizedSelectors";
import { checkStyle, sortType } from "@utils/helpers";
import { EntityList, TemplateStatus } from "@utils/optionsSets/OptionSets";
import PickListData from "@utils/optionsSets/PickListData";
import {
	ActivityFilterTemplate,
	PickListMultipleTemplate,
	PickListSingleFilterTemplate,
	dateBodyTemplate,
	dateFilterTemplate
} from "features/grid-table/components/TableSettings";
import { t } from "i18next";
import { useSelector } from "react-redux";
import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import AuthorizedUsers from "../../columns-components/AuthorizedUsers";
import Groups from "../../columns-components/Groups";
import MapName from "../../columns-components/MapName";
import Owners from "../../columns-components/Owner";
import Personas from "../../columns-components/Personas";
import {
	Status
} from "../../columns-components/ReusableListComponents";
import State from "../../columns-components/State";
import { EllipsisText } from "../../columns-components/Style";
import Tags from "../../columns-components/Tags";

export const GetJourneyMapColumns = (props) => {

	const { mapType, state, templateStatus } = PickListData('multiple', ["templateStatus", "state", "mapType"]);

	const { dataKey } = props?.contextData;
	const context = props.context.split(".")[1];
	const group = useSelector(selectGroup);
	const tag = useSelector(selectTag);
	const statusTypes = useSelector(selectStatus({ entity: 'customerJourney' }));
	const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.CustomerJourney, context));
	const queryView = useSelector(state => selectQueryView(state, EntityList.CustomerJourney))?.getById(selectedViewId)?.columnSetting;
	const isInCxProgram = context === "cxProgram";
	const isInPersona = context === "editPersona";
	return {
		name: {
			field: "name",
			header: t("JOURNEY_MAP_NAME"),
			body: (map) => MapName({ map: map, rename: props.rename, setRename: props.setRename, clickToOpen: props.contextData.clickToOpen }),
			sortable: true,
			className: "py-0 ps-0",
			resizable: true,
			filterField: "name",
			style: checkStyle(queryView, "name", isInCxProgram ? "xxxl": "xxl", "160px"),
		},
		linkedPersonas: {
			field: "linkedPersonas",
			header: t("RELATED_PERSONAS"),
			sortable: true,
			// filter: true,
			// filterField: "concatPersona",
			//showFilterMatchModes: false,
			className: "py-0 ",
			body: Personas,
			style: checkStyle(queryView, "linkedPersonas", "m", "120px"),
		},
		isFutureLabel: {
			field: "isFutureLabel",

			header: t("JS_77"),
			sortable: true,
			showFilterMatchModes: false,
			showFilterOperator: false,
			filter: true,
			filterField: "isFutureValue",
			style: checkStyle(queryView, "isFutureLabel", "m", "120px"),

			filterElement: (options) => PickListMultipleTemplate(options, state),
			body: (entity) => State({ entity, dataKey, path: props?.contextData?.reducerPath, onChangeState: props?.contextData?.onChangeState }),
		},
		lastModifiedOn: {
			field: "lastModifiedOn",
			header: t("JS_72"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			filterField: "lastModifiedOn",
			dataType: "date",
			style: checkStyle(queryView, "lastModifiedOn", "m", "140px"),
			filterElement: dateFilterTemplate,
			body: (options) => dateBodyTemplate(options?.lastModifiedOn),
		},
		mapType: {
			field: "mapType",
			header: t("JS_78"),
			filterField: "mapType",
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			sortFunction: sortType,
			filter: true,
			style: checkStyle(queryView, "mapType", "s", "120px"),

			filterElement: (options) => PickListMultipleTemplate(options, mapType),
		},
		authorizedUsers: {
			field: "authorizedUsers",
			header: "AuthorizedUsers",
			body: AuthorizedUsers,
			style: checkStyle(queryView, "authorizedUsers", "xs", "96px"),
		},
		owner: {
			field: "authorizedUsers",
			header: t("CS_35"),
			body: Owners,
			style: checkStyle(queryView, "authorizedUsers",isInCxProgram ? "m":  "s", "112px"),
		},
		stage: {
			field: "Stage",
			header: t("ASPX_172"),
			dataType: "text",
			body: (options) => (
				<EllipsisText title={options?.linkedStageName}>
					{options?.linkedStageName}
				</EllipsisText>
			),
			style: checkStyle(queryView, "Stage", "xxl", "120px"),
		},
		templateStatus: {
			field: "templateStatus",
			filterField: "templateStatus",
			dataType: "text",
			header: t("ASPX_222"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			sortFunction: sortType,
			filter: true,
			body: (options) => t(options.templateStatus === TemplateStatus.published ? "PUBLISHED" : "DRAFT"),
			filterElement: (options) => PickListMultipleTemplate(options, templateStatus),
			style: checkStyle(queryView, "templateStatus", "s", "120px"),
		},
		score: {
			field: "score",
			filterField: "score",
			dataType: "numeric",
			header: t("JS_79"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			filter: true,
			body: (map) => (map?.score > 0 ? <EmotionLevel variant="smiley" level={map?.score} size="xs" /> : <></>),
			style: checkStyle(queryView, "score", isInPersona ?"s":"m", "120px"),

			filterElement: (options) => ActivityFilterTemplate(options, props.range, props.setRange),
		},
		groupIds: {
			field: "groupIds",
			filterField: "groupIds",
			header: t("GROUPS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Groups(action, EntityList.CustomerJourney, context),

			style: checkStyle(queryView, "groupIds", "m", "120px"),

			filterElement: (options) => PickListSingleFilterTemplate(options, group, t("SELECT_GROUP")),
		},
		tagIds: {
			field: "tagIds",
			filterField: "tagIds",
			header: t("TAGS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Tags(action, EntityList.CustomerJourney, context),

			style: checkStyle(queryView, "tagIds", "m", "120px"),

			filterElement: (options) => PickListSingleFilterTemplate(options, tag, t("SELECT_TAG")),
		},
		statusId: {
			field: "statusId",
			filterField: "statusId",
			header: t("ASPX_222"),
			sortable: true,
			showFilterMatchModes: false,
			filter: true,
			style: checkStyle(queryView, "statusId", "s", "160px"),

			body: (action) => Status(action, dataKey, "customerJourney", props?.contextData?.listEditable, props?.contextData?.onChangeStatus, "cxStatus"),
			filterElement: (options) => PickListMultipleTemplate(options, statusTypes),
		},
	};
};


