import { getSessionId } from "@redux/memoizedSelectors";
import { createDefaultPersona, pictureValidationPersona } from "@utils/helpers";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import { t } from "i18next";
import { useState } from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GenericButton, Input } from "shared/ui-basics/index";
import styled from "styled-components";
import { createPersona } from "../reducer/PersonaActions";

const PersonaQuickCreate = ({ useMediaScreen }) => {
	const [newPersona, setNewPersona] = useState(createDefaultPersona());
	const [disabled, setDisable] = useState(false);
	const sessionId = useSelector(getSessionId);

	const callbackCreate = () => {
		setDisable(false);
		setNewPersona(createDefaultPersona());
	};

	const createPersonas = () => {
		setDisable(true);
		createPersona({item: newPersona, displayToast:"CREATE",onSuccess:callbackCreate});
	};

	const setNamePersona = (e) => {
		const { value } = e.target;
		if (value.length > 90) {
			toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
		}
		setNewPersona((prevState) => ({ ...prevState, name: value.truncate(90) }));
	};

	return (
		<WrapperDiv useMediaScreen={useMediaScreen}>
			{useMediaScreen ? (
				<ImageWrapper alt="Card image cap" src={pictureValidationPersona(newPersona, sessionId)} />
			) : (
				<PersonaBubble persona={newPersona} size={"120"} border={true} className="mt-3" />
			)}
			<Input placeholder={t("ASPX_112")} variant="default" type="text" onChange={setNamePersona} value={newPersona.name}></Input>
			<GenericButton className="mb-2" variant="primary" disabled={newPersona.name === "" || disabled} onClick={createPersonas}>
				{t("JS_87")}
			</GenericButton>
		</WrapperDiv>
	);
};
export default PersonaQuickCreate;

const WrapperDiv = styled.div`
	${(props) => (props?.useMediaScreen ? "padding-top:1rem;" : "margin-top:5px;")}
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const ImageWrapper = styled(Image)`
	width: 120px;
	height: 120px;
	border-radius: 50%;
	object-fit: cover;
	border: 3px solid #c9c9c9;
	@media screen and (max-width: 1280px) {
		width: 90px;
		height: 90px;
	}
`;
