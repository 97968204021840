export const CustomZigZagTick = ({ x, y, payload, index }) => {
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={index % 2 ? 15 : 40} textAnchor="middle" style={{ fontSize: "14px" }} fill="##605F60">
				{payload.value}
			</text>
		</g>
	);
};

export default CustomZigZagTick;
