export const updateCxProgramState = (state, key, newData, secondKey, secondData) => {
	const current_program = {
		...state.current_program,
		[key]: newData,
		lastModifiedOn: new Date().toISOString(),
	};
	if (secondKey !== undefined) {
		current_program[secondKey] = secondData;
	}
	return {
		...state,
		current_program,
	};
};