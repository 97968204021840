import { archiveFolder, createCxAction, createCxProgram, createFolder, createInsight, createOpportunity, createSolution, deleteFolder, deleteFolderPermanently, restoreFolder, updateFolderLinks } from "@redux/index";
import { EMPTY_GUID, createDefaultAction, createDefaultInsight, createDefaultOpp, createDefaultSol } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { PermissionManager } from "@utils/permissionManager";
import { allRoutes } from "shared/Routes/routes";
import { createProject } from "features/projects/reducer/ProjectsActions";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IconDispatcher } from "shared/Icons";
import { ActionsDropdown } from "shared/dropdowns/ActionsDropdown";
import BulkModalLinkComponent from "shared/modals/bulk-links/BulkModalLinkComponent";
import { ActionConfirmModal, NewModal } from "shared/modals/index";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { Flex } from "shared/ui-basics/index";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";

export default function BaseActions({ folder, setRename, context }) {
	const { t } = useTranslation();

	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const foldersLength = useSelector(({ folders }) => folders.filter((f) => f.statusCode === 1 && f.parentId === EMPTY_GUID)?.length);
	const id = folder?.id;
	const isFolderCreatedByCurrentUser = Number(userInfo?.UserType) === 100000002 || folder?.ownerId === userInfo?.UserId;
	const [actionConfirmModal] = useModal(ActionConfirmModal);
	const [showFolderLinkComponent] = useModal(BulkModalLinkComponent);

	const { renameAllowed, archiveAllowed, deleteAllowed, restoreAllowed, deletePermanentAllowed, moveToFolderAllowed, createInFolderAllowed, linkExistingItemsAllowed } = PermissionManager({
		context,
	});
	if (!folder?.id) return null;

	const handleArchive = () => {
		actionConfirmModal({
			bodyContent: "ARCHIVE_GENERIC",
			Action: () => archiveFolder({ id }),
			actionBtnText: "ARCHIVE",
		});
	};

	const handleDelete = () => {
		actionConfirmModal({
			bodyContent: "DELETE_GENERIC",
			Action: () => deleteFolder({ id }),
			actionBtnText: "ASPX_13",
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deleteFolderPermanently({ id }),
			actionBtnText: "ASPX_13",
		});
	};
	const handleToggleMain = () => {
		// close dropdown menu when clicking on nested dropdown
		var $dropdownMenu = $(`#dropdown-menu-${folder?.id}_Main`);
		var $dropdownToggle = $(`#dropdown-toggle-${folder?.id}_Main`);
		if ($dropdownMenu.is(":visible")) {
			$dropdownToggle.click();
		}
	};

	const createActions = () => {
		const actions = [];
		if (renameAllowed && setRename) actions.push({ id: "rename", handleClick: () => setRename(id), icon: "EDIT", text: t("JS_89"), iconStyle: "me-3" });

		if (moveToFolderAllowed && folder?.linkedFolders?.length === 0 && foldersLength > 1) {
			actions.push({
				id: "moveToFolder",
				icon: "ARROW_RIGHT",
				text: t("MOVE_TO_FOLDER"),
				handleClick: () => showFolderLinkComponent({ componentName: "subFolder", idAttribute: "cjmId", folderId: folder.id }),
				iconStyle: "me-3",
			});

			actions.push({ id: "separator", customItem: <Separator />, disabled: true });
		}
		if (createInFolderAllowed) {
			actions.push({ id: "createInFolder", customItem: CreateOrLink({ linkExisting: false, folder, handleToggleMain }) });
		}
		if (linkExistingItemsAllowed) {
			actions.push({ id: "linkExistimItems", customItem: CreateOrLink({ linkExisting: true, folder, handleToggleMain }) });
		}
		if (archiveAllowed && isFolderCreatedByCurrentUser) {
			actions.push({ id: "separator1", customItem: <Separator />, disabled: true });

			actions.push({
				id: "archive",
				handleClick: handleArchive,
				icon: "ARCHIVE",
				text: t("ARCHIVE"),
				iconStyle: "me-3",
			});
		}
		if (deleteAllowed && isFolderCreatedByCurrentUser) {
			actions.push({
				id: "delete",
				handleClick: handleDelete,
				icon: "DELETE",
				text: t("CS_45"),
				iconStyle: "me-3",
			});
		}
		if (restoreAllowed) actions.push({ id: "restore", handleClick: () => restoreFolder({ id }), icon: "RESTORE", text: t("JS_96"), iconStyle: "me-3" });
		if (deletePermanentAllowed) actions.push({ id: "deletePermanent", handleClick: handleDeletePermanent, itemStyle: "warning-delete", icon: "DELETE", text: t("JS_97"), iconStyle: "me-3" });

		return actions;
	};

	return createActions();
}
const CreateOrLink = ({ linkExisting = false, folder, handleToggleMain }) => {
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const { t } = useTranslation();
	const icon = !linkExisting ? "SQUARE_PLUS" : "LINK";
	const label = linkExisting ? "LINK_EXISTING_ITEMS" : "CREATE_IN_FOLDER";
	const entities = allRoutes.top.slice(2, 10);
	const history = useHistory();
	const [newModal] = useModal(NewModal);
	const [showListLinkedModal] = useModal(ListLinkedModal);
	const { UserSubscriptionPlanName = "" } = useSelector(({ auth }) => auth?.userInfo || {});
	const isSubscriptionAllowed = ["SharedExpert", "Expert", "Enterprise"].includes(UserSubscriptionPlanName);

	const handleCreate = (context) => {
		switch (context) {
			case "/cx-program": {
				const onSuccess = (data) => {
					updateFolderLinks({ folderIds: [folder.id], idsToLink: [data.id], entityName: "cem_cxprogram" });
					setTimeout(() => {
						history.push(`/cx-program?folderId=${folder.id}`);
					}, 500);
				};
				createCxProgram({ name: "", onSuccess, folderId: folder.id });

				break;
			}
			case "/journey-map":
				history.push(`/map-creation?folderId=${folder.id}`);
				break;
			case "/personas":
				history.push(`/persona-creation?folderId=${folder.id}`);
				break;
			case "/opportunity": {
				const onSuccess = (data) => {
					updateFolderLinks({ folderIds: [folder.id], idsToLink: [data.id], entityName: "cem_cxopportunity" });
					setTimeout(() => {
						history.push(`/opportunity?folderId=${folder.id}`);
					}, 500);
				};

				createOpportunity({
					item: createDefaultOpp(userInfo),
					displayToast: "CREATE",
					onSuccess,
					folderId: folder.id,
				});

				break;
			}
			case "/cx-actions": {
				const onSuccess = (data) => {
					updateFolderLinks({ folderIds: [folder.id], idsToLink: [data.id], entityName: "cem_cxactiontask" });
					setTimeout(() => {
						history.push(`/cx-actions?folderId=${folder.id}`);
					}, 500);
				};

				createCxAction({
					item: createDefaultAction(userInfo),
					displayToast: "CREATE",
					onSuccess,
					folderId: folder.id,
				});

				break;
			}
			case "/solutions": {
				const onSuccess = (data) => {
					updateFolderLinks({ folderIds: [folder.id], idsToLink: [data.id], entityName: "cem_cxsolution" });
					setTimeout(() => {
						history.push(`/solutions?folderId=${folder.id}`);
					}, 500);
				};

				createSolution({
					item: createDefaultSol(userInfo),
					displayToast: "CREATE",
					onSuccess,
					folderId: folder.id,
				});

				break;
			}
			case "/folder":
				newModal({
					title: "JS_84",
					closeBtnLabel: "ASPX_135",
					inputTitle: "JS_69",
					placeholder: "JS_68",
					action: (name) => {
						createFolder({ name, parentId: folder.id });
					},
				});
				break;
			case "/projects": {
				const onSuccess = (data) => {
					updateFolderLinks({ folderIds: [folder.id], idsToLink: [data.id], entityName: "cem_cxproject" });
					setTimeout(() => {
						history.push(`/projects?folderId=${folder.id}`);
					}, 500);
				};

				createProject({ item: { name: "" }, onSuccess: onSuccess });

				break;
			}
			case "/insights": {
				const onSuccess = (data) => {
					updateFolderLinks({ folderIds: [folder.id], idsToLink: [data.id], entityName: "cem_insight" });
					setTimeout(() => {
						history.push(`/insights?folderId=${folder.id}`);
					}, 500);
				};
				const item = createDefaultInsight({ currentUser: userInfo });
				createInsight({ item, onSuccess });
				break;
			}
			default:
				break;
		}
		handleToggleMain();
	};
	const handleLinking = (context) => {
		switch (context) {
			case "/cx-program":
				showListLinkedModal({ folderId: folder.id, linked: folder.LinkedCxPrograms, entityType: EntityList.CXProgram });
				break;
			case "/journey-map":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedCjms, entityType: EntityList.CustomerJourney });
				break;
			case "/opportunity":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedOpps, entityType: EntityList.Opportunity });
				break;
			case "/cx-actions":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedActions, entityType: EntityList.Action });
				break;
			case "/solutions":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedSols, entityType: EntityList.Solution });
				break;
			case "/projects":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedProjects, entityType: EntityList.Project });
				break;
			case "/personas":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedPersonas, entityType: EntityList.Persona });
				break;
			case "/insights":
				showListLinkedModal({ folderId: folder.id, linked: folder.linkedInsights, entityType: EntityList.Insight });
				break;
			default:
				break;
		}
		handleToggleMain();
	};
	const handleClickFunction = linkExisting ? handleLinking : handleCreate;

	const toggleButton = (
		<ToggleButton>
			<Flex>
				<div className="ms-2 me-3">{IconDispatcher(icon)}</div>
				<div className="ms-1">{t(label)}</div>
			</Flex>
			<div className="ms-3">{IconDispatcher("ANGLE-RIGHT")}</div>
		</ToggleButton>
	);
	const actions = entities
		.map((entity) => ({ id: entity.route, text: t(entity.text), icon: entity.icon, handleClick: () => handleClickFunction(entity.route), iconStyle: "me-3" }))
		.filter((entity) => {
			if (!isSubscriptionAllowed) {
				// means user is standard
				return ["/journey-map", "/personas", "/insights", "/folder"].includes(entity.id);
			}
			return true;
		});

	if (!linkExisting) {
		const addFolder = { id: "folder", text: t("FOLDER"), icon: "FOLDER-PLUS", handleClick: () => handleClickFunction("/folder"), iconStyle: "me-3" };
		actions.push({ id: "separator2", customItem: <Separator />, disabled: true });
		actions.push(addFolder);
	}
	const handleToggle = () => {
		var $dropdownMenu = $(`#dropdown-menu-${folder?.id}_${!linkExisting}`);
		var $dropdownToggle = $(`#dropdown-toggle-${folder?.id}_${!linkExisting}`);
		if ($dropdownMenu.is(":visible")) {
			$dropdownToggle.click();
		}
	};

	return (
		<DropdownPosition>
			<ActionsDropdown
				actions={actions}
				toggleButton={toggleButton}
				handleToggle={handleToggle}
				drop="end"
				id={`${folder?.id}_${linkExisting}`}
				buttonVariant={"folder-dropdown"}
				menuVariant="sidebar"
			/>
		</DropdownPosition>
	);
};
const DropdownPosition = styled.div`
	width: 100%;
	.dropdown-menu {
		left: 14px !important;
		top: -6px !important;
	}
	.btn :hover {
		//	all: unset;
	}
	.dropdown-toggle {
		width: 100%;
	}
`;
const ToggleButton = styled.div`
	all: unset;
	display: flex;
	align-items: center;
	justify-content: left;
	line-height: 1.5rem;
	width: 100%;
	clear: both;
	font-weight: 400;
	color: #605f60;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-size: 14px;
	width: 100%;
	justify-content: space-between;
`;
const Separator = styled.div`
	height: 1px;
	background-color: #c4c4c4;
	width: 100%;
	pointer-events: none;
`;
