import { currentWorkspaceId } from "index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteWarningModal from "shared/modals/basic/DeleteWarningModal";
import { useModal } from "use-modal-hook";
import { deleteWorkspace, updateWorkspace } from "../reducer/WorkspaceActions";
import { modes } from "@utils/optionsSets/OptionSets";


export default function BaseActions({ workspace }) {
	const { t } = useTranslation();
	const history = useHistory();
	const workspaces = useSelector((state) => state.workspaces.all_workspaces);
	const isWorkspaceActive = workspace?.statusCode === 1;
	const isCurrent = workspace?.id === currentWorkspaceId;

	const id = workspace?.id;

	const [deleteConfirmModal] = useModal(DeleteWarningModal);

	const handleDisable = () =>
		updateWorkspace({
			modifiedWorkspace: { ...workspace, statusCode: 2 },
			modifiedAttribNames: ["statusCode"],
		});

	const handleEnable = () => updateWorkspace({ modifiedWorkspace: { ...workspace, statusCode: 1 }, modifiedAttribNames: ["statusCode"] });

	const handleDelete = () => {
		deleteConfirmModal({
			onClick: () => deleteWorkspace({ workspaceId: id }),
			title: "DELETE_WORKSPACE",
			warningLabel: "DELETE_WORKSPACE_WARNING",
			Label: "DELETE_WORKSPACE_CONFIRMATION",
			actionBtnText: "ASPX_13",
			secondBtnText: "ASPX_86",
			textColor: "#CA6A6A",
			boxColor: "#F9D9D9",
			isDeleteConfirm:true
		});
	};

	const isWorkspaceCanBeDisabled = () => {
		// Only workspace that is active, not default and if more than one workspace
		// Current workspace cannot be disabled, need to show tooltip on action.
		const activesWorkspaces = workspaces.filter((ws) => ws.statusCode === 1);
		return activesWorkspaces?.length > 1 && !workspace.isDefault;
	};

	const redirect = (e) => {
		history.push(`/edit-workspace?id=${workspace.id}&mode=${modes?.duplicate}`);
	};

	const createActions = () => {
		let actions = [];

		if (isWorkspaceActive) {
			if (isWorkspaceCanBeDisabled())
				actions.push({
					id: "disable",
					handleClick: handleDisable,
					icon: "HIDE_EYE",
					text: t("DISABLE"),
					disabled: isCurrent,
					tooltipText: isCurrent ? t("DISABLE_WORKSPACE_WARNING") : undefined,
				});
			actions.push({ id: "copy", handleClick: redirect, icon: "COPY", text: t("JS_61") });
		} else {
			actions.push({ id: "enable", handleClick: handleEnable, icon: "VIEW", text: t("ENABLE") });
			if (!workspace?.originalDefaultWorkspace)
				actions.push({
					id: "delete",
					handleClick: handleDelete,
					icon: "DELETE",
					text: t("CS_45"),
				});
		}

		return actions;
	};

	return createActions();
}
