import { checkStyle, onSortFunction } from "@utils/helpers";
import { t } from "i18next";
import { dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { FolderName, OwnerFolder } from "../../columns-components/ReusableListComponents";


export const GetFolderColumns = (props) => {
    return {
        folderName: {
            field: "name",
            header: t("JS_69"),
            style: checkStyle(undefined, "name", "xxxl"),
            sortable: true,
            filterField: "name",
            body: (folder) => FolderName(folder, props.rename, props.setRename),
        },
        linkedCjms: {
            field: "linkedCjms",
            dataType: "numeric",
            filter: true,
            filterField: "linkedCjms.length",
            header: t("ASPX_97"),
            sortable: true,
            style: checkStyle(undefined, "name", "l"),
            sortFunction: onSortFunction,
            body: (folder) => folder.linkedCjms?.length || 0,
            showFilterOperator: false,
        },
        modifiedOn: {
            field: "modifiedOn",
            showFilterOperator: false,
            filter: true,
            header: t("JS_72"),
            dataType: "date",
            sortable: true,
            style: checkStyle(undefined, "name", "l"),
            filterField: "modifiedOn",
            filterElement: dateFilterTemplate,
            body: (options) => dateBodyTemplate(options.modifiedOn),
        },
        createdByName: {
            field: "createdByName",
            header: t("JS_73"),
            sortable: true,
            style: checkStyle(undefined, "name", "l"),
            body: OwnerFolder,
        },
    };
};
