import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import DateFilter from "./DateFilter";
import DateRangeFilter from "./DateRangeFilter";
import Filter from "./Filter";
import FilterBy from "./FilterBy";

function FiltersManager({ filterEntities, applyAction }) {
	const dispatch = useDispatch();

	const getFilterParams = (type) => {
		const params = {};
		params.apply = (props) => dispatch(applyAction(props));
		params.filterComponent = Filter;
		params.idAttribute = "id";
		switch (type) {
			case "programs":
				params.title = "CX_PROGRAMS";
				params.icon = "solar-system";
				params.allItemsSelector = (state) => state.analytics.all_programs;
				params.dataAttribute = "programs";
				break;
			case "journeys":
				params.title = "JOURNEYS";
				params.icon = "CJM";
				params.allItemsSelector = (state) => state.analytics.all_maps;
				params.idAttribute = "cjmId";
				params.dataAttribute = "journeys";
				break;
			case "personas":
				params.title = "ASPX_98";
				params.icon = "user-hair";
				params.allItemsSelector = (state) => state.analytics.all_personas;
				params.idAttribute = "personaId";
				params.dataAttribute = "personas";
				break;
			case "projects":
				params.title = "PROJECTS";
				params.icon = "PROJECT-LIGHT";
				params.allItemsSelector = (state) => state.analytics.all_projects;
				params.dataAttribute = "projects";
				break;
			case "touchpoints":
				params.title = "TOUCHPOINTS";
				params.icon = "BULLSEYES-POINTER";
				params.allItemsSelector = (state) => state.analytics.all_touchpoints;
				params.dataAttribute = "touchpoints";
				break;
			case "metrics":
				params.title = "JS_130";
				params.icon = "CHART-SCATTER";
				params.allItemsSelector = (state) => state.analytics.all_metrics;
				params.dataAttribute = "metrics";
				break;
			case "date":
				params.title = "DATE";
				params.icon = "CALENDAR";
				params.filterComponent = DateFilter;
				params.dataAttribute = "date";
				break;
			case "dateRange":
				params.title = "DATE";
				params.icon = "CALENDAR";
				params.filterComponent = DateRangeFilter;
				params.dataAttribute = "dateRange";
				break;
			case "filterBy":
				params.title = "JS_130";
				params.icon = "CHART-SCATTER";
				params.filterComponent = FilterBy;
				params.dataAttribute = "filterBy";
				break;
			default:
				break;
		}

		return params;
	};

	return (
		<FiltersSection>
			{filterEntities?.map((entityType) => {
				const params = getFilterParams(entityType);
				return (
					<params.filterComponent
						key={entityType}
						title={params?.title}
						icon={params?.icon}
						idAttribute={params?.idAttribute}
						allItemsSelector={params?.allItemsSelector}
						dataAttribute={entityType}
						apply={params?.apply}
					/>
				);
			})}
		</FiltersSection>
	);
}

FiltersManager.displayName = "FiltersManager";
export default FiltersManager;

const FiltersSection = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;
