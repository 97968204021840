import React from 'react';
import { Rating } from 'primereact/rating';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro';
import CustomTooltip from '../../components/CustomTooltip';
import { v4 as uuid } from 'uuid';


export function CustomRating({ classes, attribute, object, setObject, modifiedAttribNames, disabled,updateFunction ,calculateWrapper}) {
    const { t } = useTranslation();
    let count = 1
    const _ = require("lodash");
    const calcCount = () => count++ % 6
    const color = attribute === "customerValue" ? "#6A91F7" : "#F8AD48"

    const displayTooltipText = () => {
        if (count === 2) return "VERY_LOW"
        if (count === 3) return "LOW"
        if (count === 4) return "MEDIUM"
        if (count === 5) return "HIGH"
        if (count === 6) return "VERY_HIGH"
    }

    const handleChange = (e) => {
        let value = e.value

        if (_.get(object, attribute) == value) value--

        _.set(object, attribute, value)
        setObject({ ...object })
        modifiedAttribNames?.add(attribute)
        if(updateFunction) {
            updateFunction(object,[attribute])
            modifiedAttribNames?.delete(attribute)
        }
        if(calculateWrapper) calculateWrapper()
    }

    const IconComponent = (type) => {
        return (
            <CustomTooltip text={calcCount() + "- " + t(displayTooltipText())} placement={'bottom'}>
                {type === "ON" ? <OnIcon  color={color}/> : <OffIcon  color={color} />}
            </CustomTooltip>
        )
    }

    return <StyledRating className={classes} id={uuid()} value={_.get(object, attribute)} onIcon={() => IconComponent("ON")} offIcon={() => IconComponent("OFF")} onChange={handleChange} stars={5} cancel={false} disabled={disabled} />
}

export function CustomRatingNew({ classes, attribute, object, update, disabled }) {
    const { t } = useTranslation();
    let count = 1
    const _ = require("lodash");
    const calcCount = () => count++ % 6
    const color = attribute === "customerValue" ? "#6A91F7" : "#F8AD48"

    const displayTooltipText = () => {
        if (count === 2) return "VERY_LOW"
        if (count === 3) return "LOW"
        if (count === 4) return "MEDIUM"
        if (count === 5) return "HIGH"
        if (count === 6) return "VERY_HIGH"
    }

    const handleChange = (e) => {
        let value = e.value
        // if (_.get(object, attribute) == value) value--

        // _.set(object, attribute, value)
        const copy = { ...object }
        copy[attribute] = value;
        update(copy)
        // modifiedAttribNames?.add(attribute)
    }

    const IconComponent = (type) => {
        return (
            <CustomTooltip text={calcCount() + "- " + t(displayTooltipText())} placement={'bottom'}>
                {type === "ON" ? <OnIcon  color={color} /> : <OffIcon  color={color}/>}
            </CustomTooltip>
        )
    }
    return <StyledRating className={classes} id={uuid()} value={_.get(object, attribute)} onIcon={() => IconComponent("ON")} offIcon={() => IconComponent("OFF")} onChange={handleChange} stars={5} cancel={false} disabled={disabled} />
}
const OffIcon = styled.div`
    width: 12px;
    height: 12px;
    margin-right: 4px;
    background: #D9D9D9;
    /* border: 1px solid #F96209; */
    border-radius: 50%;

`

const OnIcon = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background:  ${props => props.color};
    /* border: 1px solid #F96209; */
    border-radius: 50%;
`

const StyledRating = styled(Rating)`
    .p-rating-item:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
`
