import PickListData from "@utils/optionsSets/PickListData";
import { updateSwimlaneProps } from "features/index";
import { ManageSentimentCard } from "features/journey-map/cards/ManageSentimentCard";
import { SwitchRouteCard } from "features/journey-map/cards/SwitchRouteCard";
import { filterEmotionMap, toggleTargetValues } from "features/journey-map/reducer/CJMActions";
import { getChartLinesColors } from "features/journey-map/swimlanes/sentiment-map/chartUtils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CustomEditor } from "shared/components/CustomEditor";
import { EditorProvider } from "shared/dynamic-imports";
import { PopoverSubscription } from "shared/popovers/PopoverSubscription";
import GenericSwitch, { GenericButton } from "shared/ui-basics/buttons";
import { CustomDropdown, FlexBetween, FlexColumnGap, IconDispatcher, Input } from "shared/ui-basics/index";
import styled from "styled-components/macro";
//TODO SEPARATE each component in separate file (for Ruben)

export function GeneralDetails({ cjmId, swimlaneKey, isEditable = true }) {
	const { t } = useTranslation();

	const swimLane = useSelector((state) => state.maps.current_map.cjmSwimLanes.find((swimLane) => swimLane.key === swimlaneKey));
	const swimlaneTitle = swimLane?.name?.length === 0 ? t("ASPX_113") : swimLane?.name;
	const modifiedSwimLaneRef = useRef(swimLane);

	const handleNameChange = (e) => {
		modifiedSwimLaneRef.current = { ...modifiedSwimLaneRef.current, name: e.target.value.truncate(100) };
	};

	const handleDescriptionChange = (value) => modifiedSwimLaneRef.current = { ...modifiedSwimLaneRef.current, description: value };
	useEffect(() => {
		return () => {
			if(swimLane.name !== modifiedSwimLaneRef.current?.name) {
				handleBlur("name", modifiedSwimLaneRef.current?.name);
			}
			if(swimLane.description !== modifiedSwimLaneRef.current?.description) {
				handleBlur("description", modifiedSwimLaneRef.current?.description);
			}
		}
	},[])

	const handleBlur = (type, value) => {
		if (type === "name" && swimlaneTitle !== value){
			updateSwimlaneProps({ cjmId, modifiedSwimLane:{...swimLane,name:modifiedSwimLaneRef.current?.name , description:  modifiedSwimLaneRef.current?.description,sentimentsConfig:JSON.stringify(modifiedSwimLaneRef.current?.sentimentsConfig)}, modifiedAttribNames: ["name"] });
		}
		if (type === "description") {
			updateSwimlaneProps({ cjmId, modifiedSwimLane:{...swimLane,name:modifiedSwimLaneRef.current?.name , description:  modifiedSwimLaneRef.current?.description,sentimentsConfig:JSON.stringify(modifiedSwimLaneRef.current?.sentimentsConfig)}, modifiedAttribNames: ["description"] });

		}
	};

	const handleToggle = () => {
		const newValue = !modifiedSwimLaneRef.current?.showHideDescription;
		modifiedSwimLaneRef.current = { ...modifiedSwimLaneRef.current || {}, showHideDescription: newValue };
		updateSwimlaneProps({ cjmId, modifiedSwimLane: { ...modifiedSwimLaneRef.current, showHideDescription: newValue ,sentimentsConfig:JSON.stringify(modifiedSwimLaneRef.current?.sentimentsConfig)}, modifiedAttribNames: ["showhidedescription"] });
	};

	return (
		<FlexColumnGap gap="32">
			<DetailContainer>
				<Label>{t("ASPX_136")}</Label>
				<Input
					variant="default"
					defaultValue={modifiedSwimLaneRef.current?.name}
					placeholder={t("ASPX_113")}
					onChange={handleNameChange}
					onBlur={(e) => handleBlur("name", e.target.value)}
					disabled={!isEditable}
				/>
			</DetailContainer>
			<EditorProvider>
				<DetailContainer>
					<Label>{t("ASPX_9")}</Label>
					<CustomEditor
						variant="description"
						html={modifiedSwimLaneRef.current?.description}
						sethtml={handleDescriptionChange}
						onBlur={(html) => handleBlur("description", html)}
						disabled={!isEditable}
					/>
				</DetailContainer>
			</EditorProvider>
			<SwitchWrapper>
				<GenericSwitch id={"lock"} isChecked={modifiedSwimLaneRef.current?.showHideDescription} handleToggle={handleToggle} />
				<Text>{t("SHOW_SWIMLANE_DESCR")}</Text>
			</SwitchWrapper>
		</FlexColumnGap>
	);
}

export function CustomerRoutesDetails({ cjmId, isEditable = true }) {
	const { t } = useTranslation();
	const isSubscriptionAllowed = useSelector(({ auth }) => auth?.userInfo?.UserSubscriptionPlanName.isEnterpriseSubscriptionAllowed());
	return (
		<FlexColumnGap gap="32">
			<Text>{t("SHOW_MAIN_OR_ALTERNATIVE")}</Text>
			<PopoverSubscription disabled={isSubscriptionAllowed} placement="bottom">
				<SwitchRouteCard
					cjmIdStr={cjmId}
					itemSelectorPath="maps.current_map.showMainRoute"
					text="MAIN_CUSTOMER_ROUTE"
					icon="MAIN-CUSTOMER-ROUTE"
					isMain={true}
					isEditable={isEditable && isSubscriptionAllowed}
				/>
			</PopoverSubscription>

			<SwitchRouteCard
				cjmIdStr={cjmId}
				itemSelectorPath="maps.current_map.showAlternativeRoute"
				text="ALTERNATIVE_CUSTOMER_ROUTE"
				icon="ALTERNATIVE-CUSTOMER-ROUTE"
				isMain={false}
				isEditable={isEditable && isSubscriptionAllowed}
			/>
		</FlexColumnGap>
	);
}

export function SentimentLevelDetails({ isEditable = true, swimlaneKey }) {
	const { t } = useTranslation();

	const personasData = useSelector((state) => state.maps.current_map.cjmPersonasData);

	return (
		<FlexColumnGap gap="32">
			<Text>{t("SHOW_AND_HIDE_SENTIMENT_LEVEL")}</Text>
			{personasData.map((data) => (
				<ManageSentimentCard key={data.personaId} personaId={data.personaId} isEditable={isEditable} swimlaneKey={swimlaneKey} />
			))}
		</FlexColumnGap>
	);
}

export function DividerDetails({ cjmId, swimlaneKey, isEditable = true }) {
	const { t } = useTranslation();
	const dividerOptions = PickListData("divider-pattern");

	const swimLane = useSelector((state) => state.maps.current_map.cjmSwimLanes.find((swimLane) => swimLane.key === swimlaneKey));
	const [modifiedSwimLane, setModifiedSwimLane] = useState(swimLane);

	const handleHeightChange = (e) => {
		setModifiedSwimLane((prevState) => ({ ...prevState, dividerLineHeight: parseFloat(e.target.value) }));
		updateSwimlaneProps({ cjmId, modifiedSwimLane: { ...swimLane, dividerLineHeight: parseFloat(e.target.value) }, modifiedAttribNames: ["dividerlineheight"] });
	};

	const handlePatternChange = (e) => {
		setModifiedSwimLane((prevState) => ({ ...prevState, dividerPattern: e.value }));
		updateSwimlaneProps({ cjmId, modifiedSwimLane: { ...swimLane, dividerPattern: e.target.value }, modifiedAttribNames: ["dividerpattern"] });
	};

	const Template = (option, props) => {
		if (option) {
			return <Text>{option?.name}</Text>;
		}

		return <>{props.placeholder}</>;
	};

	return (
		<FlexBetween>
			<FlexColumnGap gap="16">
				<Text>{t("LINE_HEIGHT")}</Text>
				<Input type="number" name="number" variant="default" value={modifiedSwimLane?.dividerLineHeight} onChange={handleHeightChange} disabled={!isEditable} />
			</FlexColumnGap>
			<FlexColumnGap gap="16">
				<Text>{t("PATTERN")}</Text>
				<DropDownDivider
					className={"w-100"}
					value={modifiedSwimLane?.dividerPattern}
					valueTemplate={Template}
					itemTemplate={Template}
					options={dividerOptions}
					onChange={handlePatternChange}
					optionLabel="name"
				/>
			</FlexColumnGap>
		</FlexBetween>
	);
}

export function GeneralSentimentLineDetails({ isEditable = true, swimlaneKey }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { cjmIdStr } = useLocation().search.getAllMapsParams();
	const swimLane = useSelector((state) => state.maps.current_map.cjmSwimLanes.find((swimLane) => swimLane.key === swimlaneKey));
	const personasData = useSelector((state) => state.maps.current_map.cjmPersonasData);
	const mapLegend = useSelector((state) => state.maps.current_map?.mapLegend) || [];
	const colorLine = getChartLinesColors(personasData);
	const isSubscriptionAllowed = useSelector(({ auth }) => auth?.userInfo?.UserSubscriptionPlanName.isEnterpriseSubscriptionAllowed());
	const showTargetSentiment = swimLane?.sentimentsConfig?.showTargetSentiment;
	const showGlobalPersonaSentiment = swimLane?.sentimentsConfig?.showGlobalPersonaSentiment;
	const showGlobalCustomerSentiment =isSubscriptionAllowed? swimLane?.sentimentsConfig?.showGlobalCustomerSentiment:false;

	const handleClick = (e) => {
		const name = e.target.name;
		let newConfig;
		let type = "add"; // add or remove

		if (name === "Global") {
			if (showGlobalPersonaSentiment) {
				type = "remove";
			}
			newConfig = { ...swimLane?.sentimentsConfig, showGlobalPersonaSentiment: !showGlobalPersonaSentiment };
		}
		if (name === "VoC") {
			if (showGlobalCustomerSentiment) {
				type = "remove";
			}
			newConfig = { ...swimLane?.sentimentsConfig, showGlobalCustomerSentiment: !showGlobalCustomerSentiment };
		}
		if (name === "Target") {
			if (showTargetSentiment) {
				type = "remove";
			}
			newConfig = { ...swimLane?.sentimentsConfig, showTargetSentiment: !showTargetSentiment };
		}

		const modifiedSwimLane = { ...swimLane, sentimentsConfig: JSON.stringify(newConfig) };
		if (swimLane.type === 100000012) {
			const limitFilter = type === "add" ? 1 : 2;
			mapLegend.length > limitFilter && updateSwimlaneProps({ cjmId: cjmIdStr, modifiedSwimLane, modifiedAttribNames: ["sentimentsconfig"] });
		} else {
			updateSwimlaneProps({ cjmId: cjmIdStr, modifiedSwimLane, modifiedAttribNames: ["sentimentsconfig"] });
		}
		if (swimLane.type === 100000012) {
			dispatch(filterEmotionMap());
		}
	};

	return (
		<FlexColumnGap gap="16">
			<FlexColumnGapWrapper className="p-2" gap="16">
				<LineIcon color={colorLine.find((d) => d.id === "Global").color}>{IconDispatcher("LINE-GRAPH")}</LineIcon>
				<FlexBetween>
					<Text>{t("GLOBAL_PERSONA_SENTIMENT")}</Text>
					<GenericSwitch isChecked={showGlobalPersonaSentiment} name="Global" handleToggle={handleClick} />
				</FlexBetween>
				<PopoverSubscription disabled={isSubscriptionAllowed} placement="bottom" access={["Enterprise"]}>
					<FlexBetween disabled={!isSubscriptionAllowed}>
						<Text>{t("GLOBAL_CUSTOMER_SENTIMENT")}</Text>
						<GenericSwitch isChecked={showGlobalCustomerSentiment} name="VoC" handleToggle={handleClick} />
					</FlexBetween>
				</PopoverSubscription>
			</FlexColumnGapWrapper>
			<FlexColumnGapWrapper className="p-2" gap="16">
				<LineIcon color={colorLine.find((d) => d.id === "Target").color}>{IconDispatcher("LINE-GRAPH")}</LineIcon>
				<FlexBetween>
					<Text>{t("TARGET_LINE")}</Text>
					<GenericSwitch isChecked={showTargetSentiment} name="Target" handleToggle={handleClick} />
				</FlexBetween>
				<div className="d-flex align-self-start">
					<GenericButton variant="light-link" onClick={() => dispatch(toggleTargetValues())}>
						{t("SET_TARGET_VALUES")}
					</GenericButton>
				</div>
			</FlexColumnGapWrapper>
		</FlexColumnGap>
	);
}

export function PersonaSentimentLinesDetails({ isEditable = true, swimlaneKey,lastElement }) {
	const personasData = useSelector((state) => state.maps.current_map.cjmPersonasData);
	const colorLine = getChartLinesColors(personasData);

	return (
		<FlexColumnGap gap="16">
			{personasData.map((data) => (
				<ManageSentimentCard
					key={data.personaId}
					personaId={data.personaId}
					displayLine={() => <LineIcon color={colorLine.find((d) => d.id === data.personaId).color}>{IconDispatcher("LINE-GRAPH")}</LineIcon>}
					isEditable={isEditable}
					swimlaneKey={swimlaneKey}
					lastElement={lastElement}
				/>
			))}
		</FlexColumnGap>
	);
}

const DropDownDivider = styled(CustomDropdown)`
	.p-inputtext {
		padding: 0;
	}
`;
const DetailContainer = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Label = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: capitalize;
`;

const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Text = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 6px;
`;

const LineIcon = styled.div`
	path {
		fill: ${(props) => props.color};
	}
	margin: 0.5rem;
`;

const FlexColumnGapWrapper = styled(FlexColumnGap)`
	border-radius: 4px;
	border: 1px solid #c4c4c4;
`;
