import React,{ memo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components/macro";

 const ShowComponentModal = memo(({ isOpen, onClose, title, Component, ...props }) => {
	const { t } = useTranslation();
	return (
		<BigModal fade={false} isOpen={isOpen} centered={true} toggle={onClose} size="lg">
			<ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
			<ModalBodyCustom key={props.id}>
				<Component {...props}  onClose={onClose} />
			</ModalBodyCustom>
		</BigModal>
	);
});

ShowComponentModal.displayName = "ShowComponentModal";
export { ShowComponentModal };
const BigModal = styled(Modal)`
	width: 100%;
	min-width: 1000px;
	max-width: 1450px;
`;
const ModalBodyCustom = styled(ModalBody)`
	width: 100%;
	height: 100%;
	min-height: 500px;
	max-height: 800px;
	overflow: auto;
`;
