import { createOpportunity, getAllAuthorizedUsers, getAllMapsListData, getAllOpportunity, getAllSolutions, getPdfExportOpp, groupInit, priorityInit, statusInit, tagInit } from "@redux/index";
import { getAllMapsList, getFolderById, retrieveAllActiveOpportunities } from "@redux/memoizedSelectors";
import { FilterItemsByStatus, createDefaultOpp, emptyListData, handleRedirectEntityList, isUserAllowed, toastWrapper } from "@utils/helpers";
import DataTable from "features/grid-table/DataTable";
import OpportunityPage from "features/opportunity/view/opportunity-page";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ChartFilter } from "shared/components/charts-components/ChartFilter/ChartFilter";
import { EditorProvider } from "shared/dynamic-imports";
import { useAsyncs } from "shared/hooks/index";
import { PageTemplate } from "shared/global-layouts/PageTemplate.js";
import { GenericButton } from "shared/ui-basics/index";
import { EmptyList } from "shared/ui-basics/ui-components/EmptyList";
import * as Style from "../Style.js";
import { OpportunityChart } from "./opportunity-chart";
import DataLoader from "shared/components/data-loader.jsx";

export default function Opportunity() {
	const { t } = useTranslation();

	const { pathname, search } = useLocation();
	const history = useHistory();
	const pdfRef = useRef();
	const folderId = search.getFolderId();
	const tab = search.getTab();
	const  dispatch  = useDispatch();

	const folderView = folderId === undefined ? false : true;
	const { statusIds, mapIds, groupIds, hideForPdf } = search.extractIdsChart();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const all_maps = useSelector(getAllMapsList);

	const opportunities = useSelector(({ opportunity }) => opportunity.all_Opportunities.filter((opp) => FilterItemsByStatus(opp, pathname, "/opportunity",showSampleData,all_maps)));
	const table = useSelector(({ ui }) => ui.table);
	const idRedirect = useSelector(retrieveAllActiveOpportunities)?.[0]?.id;
	const userInfo = useSelector((state) => state.auth.userInfo);

	const [key, setKey] = useState(hideForPdf ? "chart" : "list");
	const [query, setQuery] = useState(false);
	const [filterParams, setFilterParams] = useState({ statusIds, mapIds, groupIds });
	const { linkedOpps } = useSelector((state) => getFolderById(state, folderId), shallowEqual);

	const { icon, color } = emptyListData.opportunity;

	useEffect(() => {
		if (folderView) {
			setKey("list");
		}
		if(tab) setKey(tab);
	}, [folderView, tab]);

	const handleRedirect = () => {
		const onSuccess = (data) => {
			history.push(`/edit-opportunity?id=${data.id}`);
		};
		const newOp = createDefaultOpp(userInfo);
		createOpportunity({ item: newOp, displayToast: "CREATE", onSuccess });
	};

	const handleClick = () => {
		const entityType = "100000003";
		const context = "OpportunitiesList";
		const route = "/edit-opportunity";

		if (idRedirect) {
			const response = handleRedirectEntityList({ entityType, context, route, idRedirect, history, table, displayList: true, workspaceView: true });
			setQuery(response);
			setKey("grid-view");
		}
	};

	const ChartButtons = () => {
		const { t } = useTranslation();
		const op = useRef(null);

		const isAllowed = isUserAllowed(userInfo?.UserType, "create-new-solution");
		const statuses = useSelector((state) => state.libraries?.status)?.opportunity;

		const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
		const nFiltersApplied = filterParams.statusIds.length + filterParams.mapIds.length + filterParams.groupIds.length;


		const getPdfExportServiceOpportunityChart = (width, height, mapIds, statusIds, groupIds) => {
			const promise = async () => {
				const { data, error } = await dispatch(getPdfExportOpp(width, height + 230, mapIds, statusIds, groupIds));
				var fileDownload = require("js-file-download");
				fileDownload(data, "Opportunity Chart" + ".pdf");
				if (error) {
					throw error;
				}
			};
			toastWrapper(promise, "EXPORT");
		};

		const exportPdf = () => {
			const height = pdfRef.current.offsetHeight + 150;
			getPdfExportServiceOpportunityChart(
				1800,
				height > 1200 ? height : 1200,
				filterParams["mapIds"]?.join("|") || "",
				filterParams["statusIds"].join("|") || "",
				filterParams["groupIds"].join("|") || ""
			);
		};
		const handleResetFilter = () => {
			setFilterParams({
				statusIds: [],
				mapIds: [],
				groupIds: [],
			});
		};
		const toggleFilter = () => setFilterDropdownOpen((prevState) => !prevState);

		const handleFilterChange = (attribute, newId) => {
			if (filterParams[attribute].includes(newId)) filterParams[attribute] = filterParams[attribute].filter((id) => id !== newId);
			else filterParams[attribute].push(newId);

			setFilterParams({ ...filterParams });
		};

		return (
			<Style.ChartDisplayContainer className="mt-3 mx-5">
				<div>
					{isAllowed && (
						<GenericButton variant="primary" onClick={handleRedirect}>
							{t("NEW_OPPORTUNITY")}
						</GenericButton>
					)}
				</div>
				<div className="d-flex">
					<GenericButton variant="outline-secondary" className="me-2" icon="PDF" iconClassName="me-1" tooltip={true} tooltipText={t("ASPX_15")} onClick={exportPdf}>
						<div>{t("ASPX_14")}</div>
					</GenericButton>
					<Style.Filter isOpen={filterDropdownOpen} toggle={toggleFilter} direction={"left"}>
						<GenericButton variant="outline-secondary" className="w-100" icon="FILTER" iconClassName="me-1" onClick={(e) => op.current.toggle(e)}>
							{t("FILTER")}
							{nFiltersApplied > 0 && " / " + nFiltersApplied}
						</GenericButton>
						<Style.CustomOverlayPanel ref={op}>
							<ChartFilter items={opportunities} statuses={statuses} filterParams={filterParams} handleFilterChange={handleFilterChange} handleResetFilter={handleResetFilter} />
						</Style.CustomOverlayPanel>
					</Style.Filter>
				</div>
			</Style.ChartDisplayContainer>
		);
	};

	const handleKeys = (name) => {
		if (name !== "grid-view") setQuery(false);
		setKey(name);
	};

	const renderChartButtons = () => {
		if (!hideForPdf && key === "chart") {
			if (opportunities.length > 0) return <ChartButtons />;

			return (
				<div className="mt-5 pt-5">
					<EmptyList
						data={{
							visibleButtonCreate: true,
							buttonCreate: handleRedirect,
							buttonCreateLabel: "NEW_OPPORTUNITY",
							emptyListIconColor: color,
							emptyListIcon: icon,
							emptyListText: "OPPORTUNITY_EMPTY_LIST",
						}}
					/>
				</div>
			);
		}
	};

	const isShowViewButtons = () => {
		if (hideForPdf || folderView) return false;
		return true;
	};

	const renderViewButtons = (t) => {
		if (!isShowViewButtons()) return <></>;
		return (
			<>
				<GenericButton variant="light-link" className="ms-2" icon="SIDEBAR" active={key === "grid-view"} tooltip tooltipText={t("SHOW_ALL_OPPORTUNITIES")} onClick={handleClick} />
				<GenericButton variant="light-link" className="ms-2" active={key === "list"} icon="LIST-VIEW" tooltip tooltipText={t("LIST_VIEW")} onClick={() => handleKeys("list")} />
				<GenericButton variant="light-link" className="ms-2" active={key === "chart"} icon="CHART-SCATTER-BUBBLE" tooltip tooltipText={t("CHART_VIEW")} onClick={() => handleKeys("chart")} />
			</>
		);
	};

	const renderView = () => {
		switch (key) {
			case "list":
				return <DataTable context="opportunity.OpportunitiesList" linked={linkedOpps} />;
			case "chart":
				return <OpportunityChart pdfRef={pdfRef} filterParams={filterParams} baseUnit="90" />;
			case "grid-view":
				return (
					<EditorProvider>
						<OpportunityPage query={query} setQuery={setQuery} />
					</EditorProvider>
				);
			default:
				return <></>;
		}
	};

	return (
		<PageTemplate classNames="container-fluid p-5" pageTitle={`${t("YOUR")} ${t("OPPORTUNITIES")}`} renderViewButtons={renderViewButtons} renderOtherButtons={renderChartButtons}>
			<DataLoader reduxActions={[getAllMapsListData,getAllAuthorizedUsers,getAllOpportunity,getAllSolutions,statusInit,groupInit,tagInit,priorityInit]}>
			{renderView()}
			</DataLoader>
		</PageTemplate>
	);
}
