import { PickListMultipleTemplate, PickListSingleFilterTemplate, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { selectGroup, selectPriorities, selectQueryView, selectSelectedViewId, selectStatus, selectTag } from "@redux/memoizedSelectors";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Groups from "../../columns-components/Groups";
import { DatePicker, Description, Map, Owner, Stage, Status, Subject, TablePriority } from "../../columns-components/ReusableListComponents";
import Tags from "../../columns-components/Tags";

export const GetActionColumns = (props) => {

	const context = props.context.split(".")[1];
	const { dataKey } = props?.contextData;
	const priorityTypes = useSelector(selectPriorities({entity:'cxActions'}));
	const statusTypes = useSelector(selectStatus({entity:'cxActions'}));
	const group = useSelector(selectGroup);
	const tag = useSelector(selectTag);

	const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.Action, context));
	const queryView = useSelector(state => selectQueryView(state, EntityList.Action))?.getById(selectedViewId)?.columnSetting;
	const isGroup = context === 'cxActionsListGroup'
	const list = props?.contextData?.listData

	const journeyMapActionsFilters = list
	?.filter((obj, index) => list?.findIndex((item) => item?.mapId === obj?.mapId) === index)
	?.FormatFilterArray("mapId", "mapName");
	return {
		subject: {
			field: "subject",
			header: t("ACTION_NAME"),
			body: (ac) => Subject(ac, { route: props?.contextData?.route, allowClick: props.contextData.clickToOpen }),
			sortable: true,
			filterField: "subject",
			filter: true,
			showFilterOperator: false,
			style: checkStyle(queryView, "subject",isGroup || context === "home" ? 'xxxl': "xxl", "120px"),
			className: "py-0",
		},
		mapId: {
			field: "mapId",
			header: t("JOURNEY_MAP"),
			filterField: "mapId",
			sortable: true,
			showAddButton: false,
			showFilterOperator: false,
			body: (ac) => Map(ac, { route: props?.contextData?.route, allowClick: props.contextData.clickToOpen }),
			showFilterMatchModes: false,
			filter: true,
			style: checkStyle(queryView, "mapId", "m"),
			filterElement: (options) => PickListMultipleTemplate(options, journeyMapActionsFilters),
		},
		priorityId: {
			field: "priorityId",
			header: t("PRIORITY"),
			sortable: true,
			showFilterMatchModes: false,
			filter: true,
			filterField: "priorityId",
			style: checkStyle(queryView, "priorityId",context === "home" ?"l":  "m"),
			body: (opp) => TablePriority(opp, dataKey, "cxActions", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
			filterElement: (options) => PickListMultipleTemplate(options, priorityTypes),
		},
		stageName: {
			field: "stageName",
			header: t("ASPX_172"),
			sortable: true,
			filterField: "stageName",
			filter: true,
			showFilterOperator: false,
			style: checkStyle(queryView, "stageName", "m"),
			body: (ac) => Stage(ac, { route: props?.contextData?.route, allowClick: props.contextData.clickToOpen }),
		},
		ownerId: {
			field: "ownerId",
			header: t("CS_35"),
			body: Owner,
			style: checkStyle(queryView, "ownerId", "xs", "80px"),
		},
		statusId: {
			field: "statusId",
			filterField: "statusId",
			header: t("ASPX_222"),
			sortable: true,
			showFilterMatchModes: false,
			filter: true,
			style: checkStyle(queryView, "statusId", "m"),
			body: (action) => Status(action, dataKey, "cxActions", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
			filterElement: (options) => PickListMultipleTemplate(options, statusTypes),
		},
		dueDateNew: {
			field: "dueDateNew",
			filterField: "dueDateNew",
			header: t("DUE_DATE"),
			sortable: true,
			showFilterOperator: false,
			dataType: "date",
			filter: true,
			style: checkStyle(queryView, "dueDateNew", context === "home" ? "l" :"m"),
			body: (action) => DatePicker(action, dataKey, "dueDate", props?.contextData?.onChangeObject),
			filterElement: dateFilterTemplate,
		},
		groupIds: {
			field: "groupIds",
			filterField: "groupIds",
			header: t("GROUPS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Groups(action, EntityList.Action, context),
			style: checkStyle(queryView, "groupIds", "m"),
			filterElement: (options) => PickListSingleFilterTemplate(options, group, t("SELECT_GROUP")),
		},
		tagIds: {
			field: "tagIds",
			filterField: "tagIds",
			header: t("TAGS"),
			sortable: true,
			showFilterOperator: false,
			showFilterMatchModes: false,
			showAddButton: false,
			filter: true,
			body: (action) => Tags(action, EntityList.Action, context),
			style: checkStyle(queryView, "tagIds", "m"),
			filterElement: (options) => PickListSingleFilterTemplate(options, tag, t("SELECT_TAG")),
		},
		lastUpdated: {
			field: "lastUpdated",
			filterField: "lastUpdated",
			header: t("JS_72"),
			sortable: true,
			showFilterOperator: false,
			filter: true,
			style: checkStyle(queryView, "lastUpdated", "m"),
			dataType: "date",
			body: (solution) => dateBodyTemplate(solution.lastUpdated, "action"),
			filterElement: dateFilterTemplate,
		},
		description: {
			field: "description",
			header: t("ASPX_9"),
			sortable: true,
			style: checkStyle(queryView, "description", "m"),
			body: (ac) => Description(ac)
		},
	};
};
