import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IconDispatcher } from 'shared/ui-basics/index';

export default function NoDataComponent({label,icon, fixed=true}) {
    const {t} = useTranslation()

    return (
        <div className='full-screen d-flex flex-column' style={{position: fixed ? 'fixed' : 'relative'}}>
            <WrapperImg>
                {IconDispatcher(icon, 'm-3 mt-0 mb-5')}
            </WrapperImg>
            <CustomContainer>
            {t(label)}
            </CustomContainer>
        </div>)
}
const WrapperImg=styled.div`
svg {
    height:150px;
}`
export const CustomContainer =  styled.div `
    width: 50% !important;
    text-align: center;
    font-size: 20px;
`