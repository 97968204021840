import { dismissNotifications, getNotifications, updateStatusNotification } from 'features/index';
import { DateComponent, GenericButton } from 'shared/ui-basics/index';
import { User } from '@utils/helpers';
import { Badge } from 'primereact/badge';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { EmptyListContainer, EmptyListImg, EmptyListText } from 'shared/ui-basics/index';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import InitialsBubble from 'features/settings/manage-users/components/initials-bubble';

export default function Notifications({ hideForPdf ,innerRef}) {

    const { t } = useTranslation()
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const notifications = useSelector(state => state.libraries?.notifications)
    const token = useSelector(state => state.auth.token)

    const hasNewNote = notifications?.find(n => n.seen === false)
    const hide = () => setShow(false)

    //useEffect(() => {
    //    const intervalDuration = process.env.NODE_ENV === 'development' ? 500000 : 30000;
    //    const fetchNotifications = () => {
    //        //if (token) getNotifications();
    //    };
    //    fetchNotifications();
    //    const intervalId = setInterval(fetchNotifications, intervalDuration);
    //    return () => {
    //        clearInterval(intervalId);
    //    };
    //}, []);

    const dismissAll = () => {
        // send the request as read to the server
        const notificationIds = notifications.map(n => n.id)
        dismissNotifications({ notificationIds })
        hide()
    }
    const openPopover = (visible) => {
        getNotifications();
        setShow(visible)
    }
    useImperativeHandle(innerRef, () => ({
        hide : () => setShow(false)
    }), [show])

    const pop = ( <PopOverCustom >
        <Title className='p-3'>{t('NOTIFICATIONS')}</Title>
        {notifications?.length > 0 && <div className='d-flex justify-content-end p-2'>
            <GenericButton variant='light-link' onClick={dismissAll} >{t('DISMISS_ALL')}</GenericButton>
        </div>}
        <Wrapper>
            {notifications?.length > 0 ? notifications.map((notif, index) => <NotificationPost key={notif.id} notif={notif} hide={hide} />) :
                <EmptyListContainer inNotification>
                    <EmptyListImg src={require('@Assets/All/notifications.svg').default} alt='no-notification' />
                    <EmptyListText>{t('NO_NOTIFICATIONS')}</EmptyListText>
                </EmptyListContainer>}
        </Wrapper>
    </PopOverCustom>)

    return (
        <div>
            {!hideForPdf &&   <OverlayTrigger  show={show} onToggle={openPopover} placement='bottom'  overlay={pop} trigger={["click"]} rootClose>
                <NotificationContainer>
                    <GenericButton  ref={target} variant='light-link' onClick={openPopover} icon="NOTIFICATION" tooltip tooltipText={t("NOTIFICATION_CENTER")} >
                        {hasNewNote && <Badge severity="danger"></Badge>}
                    </GenericButton>
                </NotificationContainer>
            </OverlayTrigger>}
        </div>
    )
}


const NotificationPost = ({ notif, hide }) => {
    const authorizedUsers = useSelector(state => state.settings.authorizedUsers)
    const user = authorizedUsers?.find(u => u.id === notif?.originatingUserId)
    const initials = user?.fullName?.getInitials()
    const { t } = useTranslation()
    const history = useHistory()

    const handleClick = () => {
        updateStatusNotification({ notificationId: notif.id, seen: true })
        history.push(notif?.referencedObjectURL)
        hide()
    }

    const getLabel = () => {
        switch (notif.notificationType) {
            case 100000000: //mentions
                return t('MENTIONED_YOU')
            // case 100000001: //commented
            //     return t('COMMENTED')
            // case 100000002: //liked
            //     return t('LIKED')
            default:
                return ''
        }
    }

    return <ContainerPost seen={notif.seen} className='my-2 p-3'>
        <div className='d-flex justify-content-start align-items-center' onClick={handleClick} >
            <InitialsBubble  fullName={user?.fullName} size='s' />
            <div className='ms-2'>{user?.fullName}  {getLabel()}</div>
        </div>
        <Message onClick={handleClick}>
            {notif?.referencedObjectName}
        </Message>
        <div className='d-flex justify-content-between'>
            <Date>
                <DateComponent date={notif.createdOn} />
            </Date>
            <GenericButton variant='light-link' icon="CIRCLE-X" onClick={() => dismissNotifications({ notificationIds: [notif.id] })} tooltip tooltipText={t('DISMISS')} />
        </div>

    </ContainerPost>
}
const PopOverCustom = styled(Popover)`
    padding: 1rem;
	min-width: 400px;

	.popover-header {
		padding: 0.2rem 0.3rem;
		background-color: #ffffff;
	}
	.popover-body {
		max-height: 300px;
		overflow: auto;
	}
	@media screen and (max-height: 768px) {
		.popover-body {
			max-height: 200px;
			overflow: auto;
		}
	}
`;
const NotificationContainer = styled.div`
  position:relative;

  .p-badge {
    position:absolute;
    top: 5px;
    right: 5px;
    width: 0.5rem;
    height:0.5rem;
    line-height: 0.5rem;
    min-width: 0.5rem;
  }
`
const Message = styled.div`
font-weight: 600;
`
const ContainerPost = styled.div`
height: 121px;
background-color: #F8F8F8;
color:#333333;
cursor: pointer;

${props => props.seen && css`
color: #8C8C8C  ;
background-color:transparent;
${Message} {
    font-weight: 400;
}

` }
display: flex;
flex-direction: column;
justify-content: space-between;
border-radius: 4px;
    :hover {
        background-color: #EAEAEA;
    }
`
const Wrapper = styled.div`
width: 400px;
max-height: 280px;
overflow: scroll;
`

const Date = styled.div`
font-size: 14px;
color: #8C8C8C;

`
const Title = styled.div`
font-size: 20px;
color: #605F60;
`