import CustomTooltip from "shared/components/CustomTooltip";
import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import { getSessionId } from "@redux/memoizedSelectors";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import { getterApis } from "@utils/optionsSets/getterApis";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { MapCardContainer, MapIconContainer, Redirect, StyledMapName, WrapperImgPersona } from "../../../journey-map/modals/CjmListStyle";
import { StatusCode } from "@utils/optionsSets/OptionSets";

export const LinkListCjmModel = ({ isOpen, onClose, title, listData, description_1, personaObject }) => {

    const { t } = useTranslation();
    const { name } = personaObject;


    return <>
        <Modal isOpen={isOpen} centered={true} toggle={onClose}>
            <ModalHeader className="" toggle={onClose}> {t(title)}</ModalHeader>
            <ModalBody className="d-flex flex-column">
                <WrapperImgPersona>
                    <PersonaBubble persona={personaObject} border={true} size={'120'} />
                </WrapperImgPersona>
                <div className="text-center py-4 px-5">{name + " " + t(description_1)} </div>
                <div className="d-flex flex-column  justify-content-between w-100 px-2 mb-5">
                    {listData.map((cjm, index) => (<MapRow key={index} map={cjm} onClose={onClose} />))}
                </div>
            </ModalBody>

        </Modal>
    </>
}
const MapRow = ({ map, onClose }) => {

    const { t } = useTranslation();
    const PersonaIds = map?.linkedPersonas?.map(persona => persona.personaId) || [];

    const sessionId = useSelector(getSessionId);
    const personaData = useSelector((state) => state.personas.all_personas.filter(persona => PersonaIds?.includes(persona?.personaId)) || []);

    const mapCustomImageUrl = getterApis['GET_MAP_CUSTOM_IMG'](map.cjmId, sessionId)

    const [mapImage, setMapImage] = useState(mapCustomImageUrl);



    const listPersonas = (index) => {
        const personas = [] // no capital letter for variable (react convention)
        for (let i = 0; i < index && i < personaData?.length; i++) {
            personas.push(personaData[i])
        }

        return personas?.map(persona => {
            const sentimentLevel = map?.linkedPersonas.find(lnk => lnk.personaId === persona.personaId)?.sentimentLevel

            return <CustomTooltip key={persona.personaId} id={persona.personaId} text={persona.name}>
                <div key={persona.personaId} className='position-relative'>
                    <PersonaBubble persona={persona} size={'35'} className="me-0" />
                        <EmotionContainer>

                        <EmotionLevel variant="smiley" size="xxs" level={Math.round(sentimentLevel)} showTooltip={false} />
                        </EmotionContainer>
                </div>
            </CustomTooltip>
        })
    }
    return <MapCardContainer>
        <Col xs={8} className='d-flex align-items-center'>
            <MapIconContainer>
                <img src={mapImage} loading='lazy' alt="" />
            </MapIconContainer>
            <CustomTooltip id={map.cjmId} text={map.name}>
                <Redirect onClick={onClose} to={map.statusCode!==StatusCode.Active ? '#' : `/EditMap?cjmid=${map.cjmId}&personaId=${map.linkedPersonas[0]?.personaId || '00000000-0000-0000-0000-000000000000'}`}> <StyledMapName>{map.name}</StyledMapName></Redirect>
            </CustomTooltip>
        </Col>
        <Col xs={54} className='d-flex pe-1'>
            {listPersonas(3)}
        </Col>

    </MapCardContainer>
}
const EmotionContainer = styled.div`
position: absolute;
    right: -8px;
    top: 22px;
    z-index: 2;
`