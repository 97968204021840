import { error, success } from "@redux-requests/core";
import { appendListsAndRemoveDuplicates, decode } from "@utils/helpers";
import * as Helpers from "./helpers";

const initialState = {
	all_insights: [],
	loading: false,
};

const InsightReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const requestParams = action?.meta?.requestAction?.params;
	const responseData = action?.response?.data;

	switch (action.type) {
		case "RESET_INSIGHT": {
			return Helpers.updateInsightState({ state, otherState: { loading: false } });
		}
		case "GET_ALL_INSIGHTS":
			return Helpers.updateInsightState({ state, otherState: { loading: state.all_insights.length === 0 } });
		case error("GET_ALL_INSIGHTS"):
			return Helpers.updateInsightState({ state, otherState: { loading: false } });
		case success("GET_ALL_INSIGHTS_MAP"):
		case success("GET_ALL_INSIGHTS"): {
			return Helpers.updateInsightState({ state, otherState: { all_insights: Helpers.fetchArraysAndMerge(state.all_insights, responseData), loading: false } });
		}
		case success("GET_INSIGHT"): {
			const data = responseData;
			const all_insights = state.all_insights?.map((insight) => {
				if (insight.id === requestParams?.insightId) {
					insight = { ...insight, ...data };
				}
				return insight;
			});

			return Helpers.updateInsightState({ state, otherState: { all_insights: all_insights } });
		}
		case "CREATE_INSIGHT": {
			const { newInsight } = action.request.data;
			const insight = {
				...newInsight,
				name: decode(newInsight?.name ?? ""),
			};
			return Helpers.updateInsightState({ state, otherState: { all_insights: [...state.all_insights, insight], loading: false } });
		}
		case success("UPDATE_INSIGHT"): {
			const newAllInsights = state.all_insights?.map((insight) => {
				if (insight.id === requestData?.modifiedInsight.id) {
					insight = responseData;
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: newAllInsights } });
		}
		case 'LINK_INSIGHT_ON_MAP': {
			const { personaId, insightIds } = action.request.data;
			const newAllInsights = state.all_insights?.map((insight) => {
				if (insightIds.includes(insight.id) && personaId && !insight?.["linkedPersonas"].includes(personaId)) {
					insight = { ...insight, ["linkedPersonas"]: insight?.["linkedPersonas"].add(personaId) };
					return insight;
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: newAllInsights } });
		}
		case success("LINK_INSIGHT_RECORDS"): {
			const { attribute } = requestParams
			const { insightId, recIds } = requestData
			const newAllInsights = state.all_insights?.map((insight) => {
				if (insight.id === insightId && !(insight?.[attribute]?.includes(recIds))) {
					insight = { ...insight, [attribute]: insight?.[attribute].add(...recIds) };
					return insight;
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: newAllInsights } });
		}
		case success("UNLINK_INSIGHT_RECORDS"): {
			const { attribute } = requestParams
			const { insightId, recIds } = requestData
			const newAllInsights = state.all_insights?.map((insight) => {
				if (insight.id === insightId) {
					insight = { ...insight, [attribute]: insight?.[attribute].remove(recIds) };
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: newAllInsights } });
		}
		case success("ARCHIVE_INSIGHTS"): {
			const updatedInsight = state.all_insights?.map((insight) => {
				if (requestData?.ids.includes(insight.id)) {
					return { ...insight, statusCode: 100000000 };
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: updatedInsight } });
		}
		case success("RESTORE_INSIGHTS"): {
			const updatedInsight = state.all_insights?.map((insight) => {
				if (requestData?.ids.includes(insight.id)) {
					return { ...insight, statusCode: 1 };
				}
				return insight;
			});

			return Helpers.updateInsightState({ state, otherState: { all_insights: updatedInsight } });
		}
		case success("DELETE_INSIGHTS"): {
			const updatedInsight = state.all_insights?.map((insight) => {
				if (requestData?.ids.includes(insight.id)) {
					return { ...insight, statusCode: 2 };
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: updatedInsight } });
		}
		case success("DELETE_INSIGHTS_PERMANENT"): {
			const updatedInsight = state.all_insights.filter((insight) => !requestData.ids.includes(insight.id));
			return Helpers.updateInsightState({ state, otherState: { all_insights: updatedInsight } });
		}
		case success("LINK_TAGS"): {
			const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_insight") return state;
			const updatedInsight = state.all_insights?.map((insight) => {
				if (recordIds.includes(insight.id)) {
					return { ...insight, tags: [...appendListsAndRemoveDuplicates(insight.tags, tagIds)] };
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: updatedInsight } });
		}
		case success("LINK_GROUPS"): {
			const { groupIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_insight") return state;
			const updatedInsight = state.all_insights?.map((insight) => {
				if (recordIds.includes(insight.id)) {
					return { ...insight, groups: [...appendListsAndRemoveDuplicates(insight.groups, groupIds)] };
				}
				return insight;
			});
			return Helpers.updateInsightState({ state, otherState: { all_insights: updatedInsight } });
		}
		default:
			return state;
	}
};
export default InsightReducer;
