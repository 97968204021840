import { Skeleton } from 'primereact/skeleton'
import React from 'react'
import * as Style from './Style'

export default function PersonasLoadingCard({className}) {
  return (
    <Style.CardPersonas className={className}>
    <Style.Center className='mt-2'>
    <Skeleton width='120px' shape='circle' height='120px' borderRadius='50%'></Skeleton>
    </Style.Center>
    <Style.Center className='my-2'>
        <div>
        <Skeleton height="1rem" width='50px' ></Skeleton>
        <Skeleton height="0.5rem" width='150px' className="mt-1"></Skeleton>
        </div>
    </Style.Center>

    <Style.Center>
    <Skeleton height="25px" width='70px' className="m-1"></Skeleton>
    <Skeleton height="25px" width='70px' className="m-1"></Skeleton>

    </Style.Center>
</Style.CardPersonas>
  )
}
