import { t } from "i18next";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { GenericButton } from "../ui-basics/buttons";
import { IconDispatcher } from "../Icons";

export const ActionPopOver = ({ actions, isOnlyIcon = false, ...props }) => {
	const refOver = useRef();
	const onHandleClick = (e, action) => {
		refOver.current.toggle(e);
		action.handleClick(e);
	};
	return (
		<>
			<GenericButton
				variant={props.variant ?? "primary-link"}
				tooltip
				tooltipText={props?.tooltipText}
				icon={props?.noIcon ? undefined : props.icon ?? "SQUARE_PLUS"}
				iconClassName={props.label ? "me-1" : ""}
				onClick={(e) => refOver.current.toggle(e)}disabled={props.disabled}>
				{t(props.label ?? "ADD_SECTION")}
			</GenericButton>
			<OverlayPanelWrapper ref={refOver} id="overlay_panel" className="p-0">
				{actions?.map((action, index) => (
					<DropdownWrapper
						key={index}
						variant={action.itemStyle}
						className={action.itemClasses}
						onClick={(e) => {
							onHandleClick(e, action);
						}}
						disabled={action.disabled ? action.disabled : false}>
						{action.customItem ? (
							action.customItem
						) : (
							<ActionWrapper>
								{action.icon && <ActionIcon>{IconDispatcher(action.icon, action.iconStyle)}</ActionIcon>}
								<ActionTextWrapper>{action.text}</ActionTextWrapper>
							</ActionWrapper>
						)}
					</DropdownWrapper>
				))}
			</OverlayPanelWrapper>
		</>
	);
};

const DropdownWrapper = styled.div`
	padding: 0.25rem 1rem;
	font-size: 14px;
	:hover {
		background-color: #e4e7f1;
	}
	${(props) =>
		props.disabled
			? css`
					pointer-events: none;
					opacity: 0.8;
			  `
			: ""}
`;

const ActionTextWrapper = styled.div`
	white-space: nowrap;
`;

const ActionWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	:hover {
		cursor: pointer;
	}
`;

const ActionIcon = styled.div`
	min-width: 30px;
	padding: 4px;
	text-align: center;
	align-self: center;
	justify-content: center;
	margin-right: 0.5rem !important;
`;

const OverlayPanelWrapper = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0;
	}
`;
