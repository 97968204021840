import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container } from "reactstrap";
import { Spinner } from "shared/components/spinners/Spinner";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { GenericButton } from "shared/ui-basics";
import styled from "styled-components/macro";
import { WorkspaceCard } from "../components/cards/workspace-card";
import { UserType, modes } from "@utils/optionsSets/OptionSets";

export default function WorkspacesList() {
	const { t } = useTranslation();
	const history = useHistory();

	const userInfo = useSelector(({ auth }) => auth?.userInfo || {});
	const { all_workspaces: workspaces, currentWorkspaceRole } = useSelector((state) => state.workspaces);

	const userType = Number(userInfo?.UserType);
	const isCompanyAdmin = userType === UserType.companyAdmin;

	const onCreate = () => {
		history.push(`/edit-workspace?mode=${modes.create}`);
    };
    
    const filterWorkspaces = (workspaces) => {
        if (isCompanyAdmin) return workspaces
        return workspaces?.filter(ws => ws.authorizedUsers.find(au => au?.id === userInfo?.UserId)?.roleName === "owner")
    }

	return (
		<PageTemplate classNames="container p-5" pageTitle={`${t("YOUR")} ${t("WORKSPACES")}`}>
			{isCompanyAdmin && (
				<GenericButton className="mb-5" variant="primary" icon="SQUARE_PLUS" iconClassName="me-1" onClick={onCreate}>
					{t("NEW_WORKSPACE")}
				</GenericButton>
			)}
			<Cards workspaces={filterWorkspaces(workspaces)?.sortAsc("name")} isCompanyAdmin={isCompanyAdmin} />
		</PageTemplate>
	);
}

const Cards = ({ workspaces, isCompanyAdmin }) => {
	const loading = useSelector((state) => state.workspaces.loading);

	const render = () => {
		if (loading) return <Spinner />;

		return (
			<CardContainer>
				{workspaces?.map((workspace) => (
					<div key={workspace.id} className="mb-3">
						<WorkspaceCard workspace={workspace} isCompanyAdmin={isCompanyAdmin}/>
					</div>
				))}
			</CardContainer>
		);
	};

	return <Col>{render()}</Col>;
};

export const CardContainer = styled(Container)`
	margin-left: 0px;
	padding-left: 0px;
`;
