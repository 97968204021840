import { UserType } from "@utils/optionsSets/OptionSets";
import axios from "axios";
import {
    Analytics,
    Archive,
    BrandValues,
    CXProgram,
    Configuration,
    CustomLibrary,
    CustomizationPage,
    CxActionPage,
    CxActions,
    CxAssessmentPage,
    EditUser,
    Home,
    Insights,
    Integration,
    JourneyMapCreation,
    JourneyMapList,
    Libraries,
    ManageOwnership,
    ManageTeams,
    MapHierarchyView,
    Measures,
    Opportunity,
    OpportunityPage,
    Organization,
    PersonaCreationWizard,
    Personas,
    Projects,
    Search,
    SolutionPage,
    Solutions,
    Stickers,
    Templates,
    TouchPoints,
    Training,
    TrashBin,
    Users,
    WorkspaceForm,
    WorkspacesList
} from "features/index";
import JourneyMapLoading from "features/journey-map/components/journey-map-loading";
import { base } from "index";
import { lazy } from "react";
import { Redirect } from "react-router-dom";
import { allRoutesCombines, settingsfooterRoutes } from "shared/Routes/routes";
import CascadeLoader from "shared/components/spinners/CascadeLoader";

const JourneyMapPage = lazy(() => import("features/journey-map/view/journey-map-page"));
const PersonaPage = lazy(() => import("features/forms/personas/view/persona-page"));
const CXProgramPage = lazy(() => import("features/forms/cx-programs/view/cxprogram-page.jsx"));
const ProjectPage = lazy(() => import("features/projects/view/project-page.jsx"));

const routes = {
	lazyPages: [
		{ path: "/EditMap", component: JourneyMapPage },
		{ path: "/EditPersona", component: PersonaPage },
		{ path: "/EditCxProgram", component: CXProgramPage },
		{ path: "/EditProject", component: ProjectPage },
	],
	nonLazyPages: [
		{ path: "/customization", component: CustomizationPage },
		{ path: "/edit-opportunity", component: OpportunityPage },
		{ path: "/edit-solution", component: SolutionPage },
		{ path: "/edit-cxactions", component: CxActionPage },
		{ path: "/map-creation", component: JourneyMapCreation },
		{ path: "/persona-creation", component: PersonaCreationWizard },
		{ path: "/edit-cx-assessment", component: CxAssessmentPage },
		{ path: "/home", component: Home },
		{ path: "/journey-map", component: JourneyMapList },
		{ path: "/sample-data", component: () => <Redirect to="/journey-map" /> },
		{ path: "/personas", component: Personas },
		{ path: "/map-hierarchy", component: MapHierarchyView },
		{ path: "/trash", component: TrashBin },
		{ path: "/archive", component: Archive },
		{ path: "/cx-actions", component: CxActions },
		{ path: "/users", component: Users },
		{ path: "/edit-user", component: EditUser },
		{ path: "/teams", component: ManageTeams },
		{ path: "/transfer-ownership", component: ManageOwnership },
		{ path: "/organization", component: Organization },
		{ path: "/configuration", component: Configuration },
		{ path: "/workspaces", component: WorkspacesList },
		{ path: "/edit-workspace", component: WorkspaceForm },
		{ path: "/libraries", component: Libraries },
		{ path: "/library-touchpoints", component: TouchPoints },
		{ path: "/library-stickers", component: Stickers },
		{ path: "/library-brandvalues", component: BrandValues },
		{ path: "/library-measures", component: Measures },
		{ path: "/library-custom", component: CustomLibrary },
		{ path: "/templates", component: Templates },
		{ path: "/integration", component: Integration },
		{ path: "/opportunity", component: Opportunity },
		{ path: "/solutions", component: Solutions },
		{ path: "/projects", component: Projects },
		{ path: "/cx-program", component: CXProgram },
		{ path: "/search", component: Search },
		{ path: "/training", component: Training },
        { path: "/insights", component: Insights },
        { path: "/analytics", component: Analytics },
		{ path: "/edit-persona-pending", component: () => CascadeLoader({ words: ["backgrounds", "needs", "frustrations", "expectations", "quote"] }) },
		{ path: "/map-creation-loading", component: JourneyMapLoading },
	],
};

export function updateDictionnaryAccess(userId, userType, workspaces) {
	const routeAccess = {};
	const isUserReadyOnlyOrUser = [100000000, 100000003].includes(Number(userType));
    const isCompanyAdmin = Number(userType) === UserType.companyAdmin;
    const isWorkspaceOwner = workspaces.filter((ws) => ws.authorizedUsers.find((au) => au?.id === userId)?.roleName === "owner")
    const showWorkspaces = isCompanyAdmin || isWorkspaceOwner;
	const fullAccess = ["Standard", "SharedExpert", "Expert", "Enterprise"];
	const mediumAccess = ["SharedExpert", "Expert", "Enterprise"];
	const settingsRoutes = settingsfooterRoutes.map((route) => route.route);
	allRoutesCombines.forEach((item) => {
		//Add the main route if access is not null or undefined
		if (isUserReadyOnlyOrUser && item.route && settingsRoutes.includes(item.route)) {
			return;
		}
		if (item.access && item.route) {
			routeAccess[item.route] = item.access;
		}

		// If there are active routes, add them as well
		if (item.activeRoutes) {
			item.activeRoutes.forEach((activeRoute) => {
				// Only add the active route if access is not null or undefined
				if (item.access && activeRoute) {
					routeAccess[activeRoute] = item.access;
				}
			});
		}
	});
	routeAccess["/map-creation"] = fullAccess;
	routeAccess["/persona-creation"] = fullAccess;
	routeAccess["/edit-cx-assessment"] = mediumAccess;
	routeAccess["/map-hierarchy"] = fullAccess;
	routeAccess["/search"] = fullAccess;
	routeAccess["/training"] = fullAccess;
    routeAccess["/edit-workspace"] = fullAccess;
	if (showWorkspaces) {
        routeAccess["/workspaces"] = fullAccess;
        routeAccess["/libraries"] = fullAccess;
        routeAccess["/templates"] = fullAccess;
        routeAccess["/customization"] = fullAccess;
        routeAccess["/integration"] = fullAccess;
    }
	if (!isUserReadyOnlyOrUser) {
		routeAccess["/library-touchpoints"] = fullAccess;
		routeAccess["/library-stickers"] = fullAccess;
		routeAccess["/library-brandvalues"] = mediumAccess;
		routeAccess["/library-measures"] = mediumAccess;
		routeAccess["/library-custom"] = fullAccess;
		routeAccess["/users"] = fullAccess;
		routeAccess["/edit-user"] = fullAccess;
		routeAccess["/teams"] = mediumAccess;
		routeAccess["/transfer-ownership"] = mediumAccess;
    }
    routeAccess["/analytics"] = mediumAccess;
	routeAccess["/edit-persona-pending"] = fullAccess;
	routeAccess["/sample-data"] = fullAccess;
	routeAccess["/map-creation-loading"] = fullAccess;

	return routeAccess;
}
export function filterRoutesAccess(AccessDictionary, currentUserSubscriptionPlanName) {
	const lazyPages = routes.lazyPages?.filter((route) => AccessDictionary[route.path]?.includes(currentUserSubscriptionPlanName));
	const nonLazyPages = routes.nonLazyPages?.filter((route) => AccessDictionary[route.path]?.includes(currentUserSubscriptionPlanName));
	return { lazyPages, nonLazyPages };
}

export const configHeaderAxios = (token) => {
	const workspaceId = base.split("/")[2];
	axios.interceptors.request.use((config) => {
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
			config.headers.WorkspaceId = workspaceId;
		}
		return config;
	});
};
