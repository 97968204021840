import { GenericButton } from "shared/ui-basics/buttons";
import { FlexAlignCenter, FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { Badge, BadgeWrapper, MaxWidth, PriorityTypes, RemoveButton } from "./PicklistCard_style";
import { useSelector } from "react-redux";
import { findPriority } from "@redux/memoizedSelectors";

export const ActionItemCard = ({ index, entity, unlink, openRoute, length, disable }) => {
	const priority = useSelector(findPriority("cxActions", entity?.priorityId));
	return (
		<Badge key={entity?.id} id={entity?.id} >
			<BadgeWrapper index={index} length={length}>
				<div className="ellipsis obj-name px-2" onClick={() => openRoute(entity?.id,"/edit-cxactions")}>
					{entity?.subject.defaultPlaceholder("ACTION_NAME")}
				</div>
				<FlexBetweenAlignCenter className="px-2" minWidth='170px'>
				<PriorityTypes colorcode={priority?.colorCode} className="mx-2">
					<div id="point"></div>
					<MaxWidth width="90" oppLabel="true" className="text-truncate">
						{priority?.name}
					</MaxWidth>
				</PriorityTypes>
				{!disable && (
				<RemoveButton>
					<GenericButton variant="light-link" id={entity?.id} icon="X" iconClassName="p-0" onClick={unlink} />
				</RemoveButton>
			)}
				</FlexBetweenAlignCenter>
			</BadgeWrapper>

		</Badge>
	);
};

