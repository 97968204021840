import { linkLibraryValues } from "@redux/index";
import { getInsightPersonaIds, makeGetCjmDataStageInteractions, memoizedSwimlanes } from "@redux/memoizedSelectors";
import { EMPTY_GUID } from "@utils/helpers";
import { InformationType } from "@utils/optionsSets/OptionSets";
import { createMultipleInteractions, linkInteraction } from "features";
import { ColorTextCard } from "features/forms/personas/cards/ColorTextCard";
import PersonaBadge from "features/journey-map/cards/PersonaBadge";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TouchpointSelectCard } from "shared/components/cards/cx-cards/TouchpointSelectCard";
import Group from "shared/cx-components/Group";
import Tags from "shared/cx-components/Tags";
import { ActionPopOver } from "shared/popovers/ActionsPopOver";
import { Borders } from "shared/ui-basics/index";
import InsightType from "shared/ui-basics/ui-components/InsightType";
import { v4 as uuidv4 } from "uuid";
import { Property, Score } from "../../../features/journey-map/modals/PersonaInteractions/components";

function PropertyManager({ item, updateFunction, mode, defaultRows, ...props }) {
	const [currentItem, setCurrentItem] = useState(item);
	const [rows, setRows] = useState(defaultRows);
	const fixedRows = ["persona", "sentiment", "insightType"];

	const handleChangeRows = (id, remove) => {
		let update;
		if (remove) {
			const updatedDisplayed = rows.displayed.filter((col) => col !== id);
			const updatedExtra = [...rows.extra, id];
			update = { ...rows, displayed: updatedDisplayed, extra: updatedExtra };
		} else {
			const updatedDisplayed = [...rows.displayed, id];
			const updatedExtra = rows.extra.filter((col) => col !== id);
			update = { ...rows, displayed: updatedDisplayed, extra: updatedExtra };
		}
		setRows(update);
		props?.updateRows(update);
	};

	const propertyDetails = GetPropertyDetails({ currentItem, setCurrentItem, mode, updateFunction, ...props });

	const hidedRows = GetHidedProperties(handleChangeRows, rows);

	return (
		<React.Fragment>
			{rows.displayed.map((row) => {
				return <Property key={row} id={row} className="mt-3" {...propertyDetails[row]} mode={mode} onRemove={!fixedRows.includes(row) && handleChangeRows} />;
			})}
			<Borders top={!mode.IsViewer()} bottom={!mode.IsViewer()} className="w-50 p-1 my-3">
				{!mode.IsViewer() && hidedRows?.length > 0 && <ActionPopOver label="ADD_PROPERTY" variant="light-link" actions={hidedRows} />}
			</Borders>
		</React.Fragment>
	);
}

const GetPropertyDetails = ({ currentItem, setCurrentItem, recordType, mode, updateFunction, stageId, linkFunction, unlinkFunction }) => {
	const libraries = useSelector((s) => _.get(s, "libraries.customLibraries", []));
	const allTouchpoints = useSelector((s) => _.get(s, "libraries.touchPoint", []));
	const allPersonas = useSelector((s) => _.get(s, "personas.all_personas", []));
	const linkedPersonas = useSelector((s) => _.get(s, "maps.current_map.cjmPersonasData", []));
	const personasIds = useSelector(getInsightPersonaIds(currentItem?.id));
	const { personaIdStr, cjmIdStr } = useLocation().search.getAllMapsParams();
	const swAdvancedSys = useSelector(memoizedSwimlanes, shallowEqual)?.filter((swimlane) => swimlane?.type === InformationType.Systems);
	const swAdvancedDep = useSelector(memoizedSwimlanes, shallowEqual)?.filter((swimlane) => swimlane?.type === InformationType.Departments);
	const swAdvancedInteractions = useSelector(memoizedSwimlanes, shallowEqual)?.filter((swimlane) => swimlane?.type === InformationType.AdvancedInteractions);
	const interactions = useSelector(makeGetCjmDataStageInteractions(currentItem?.stageId));
	const all_insightsTypes = useSelector((s) => _.get(s, "libraries.insightTypes", []));
	const currentInsightType = all_insightsTypes?.find((type) => type.id === currentItem?.typeId);

	const handleChangeType = ({ id }) => {
		const newInteraction = { ...currentItem, typeId: id };
		setCurrentItem(newInteraction);
		updateFunction({ interaction: newInteraction, modifiedAttribNames: ["type"] });
	};

	const linkInsightToInteraction = (id) => {
		const getInteraction = interactions?.filter((inter) => inter?.touchPointId === id);
		if (getInteraction && getInteraction?.length > 0) {
			getInteraction?.forEach((inter) => {
				linkInteraction({
					recIds: [currentItem.id],
					stageId,
					itemId: inter.id,
					attribute: "linkedInsights",
					recEntityName: "cem_insight",
				});
			});
		} else {
			//create persona interaction
			const newGuid = uuidv4();
			createMultipleInteractions({ personaInteractionsIds: { [id]: newGuid }, personaIdStr, cjmIdStr, ownerId: EMPTY_GUID, stageId:currentItem?.stageId});
			linkInteraction({
				recIds: [currentItem.id],
				stageId:currentItem?.stageId,
				itemId: newGuid,
				attribute: "linkedInsights",
				recEntityName: "cem_insight",
			});
		}
	};
	const handleChangeElement = ({ id, attribute, mode, entityType, prefix }) => {
		let update;
		if (mode === "delete") {
			update = currentItem?.[attribute].filter((s) => s !== id);
		} else {
			update = [...(currentItem?.[attribute] || []), id];
		}

		const updatedItem = { ...currentItem, [attribute]: update };
		setCurrentItem(updatedItem);

		if (mode === "delete") {
			unlinkFunction({ itemId: currentItem.id, recIds: [id], stageId: currentItem?.stageId, attribute, recEntityName: entityType, prefixRelationships: prefix });
		} else {

			linkFunction({ itemId: currentItem.id, recIds: [id], stageId: currentItem?.stageId, attribute, recEntityName: entityType, prefixRelationships: prefix });
			if (attribute === "linkedTouchpoints" && recordType === "cem_insight" && swAdvancedInteractions) {
				//link TP on Insight
				linkInsightToInteraction(id);
			}
			if (attribute == "linkedSystems" && swAdvancedSys) {
				if (swAdvancedSys?.[0]) {
					const newId = uuidv4();
					linkLibraryValues({ swimLaneItemIds: [newId], libraryValueIds: [id], customSwimLaneId: swAdvancedSys?.[0]?.key, stageId: currentItem?.stageId, cjmId: cjmIdStr });
				}
			}
			if (attribute == "linkedDepartments" && swAdvancedDep) {
				if (swAdvancedDep?.[0]) {
					const newId = uuidv4();
					linkLibraryValues({ swimLaneItemIds: [newId], libraryValueIds: [id], customSwimLaneId: swAdvancedDep?.[0]?.key, stageId: currentItem?.stageId, cjmId: cjmIdStr });
				}
			}
		}
	};


	const handleChangeTagsGroups = (updated) => {
		const newInteraction = { ...currentItem, tags: updated.tags, groups: updated.groups };
		setCurrentItem(newInteraction);
		updateFunction({ interaction: newInteraction, modifiedAttribNames: ["tags"] });
	};
	const renderPersonaElement = (id, isEditMode) => {
		if (recordType === "cem_customerexperience") {
			const currentPersona = allPersonas?.find((p) => p.personaId === personaIdStr);
			return <PersonaBadge object={currentPersona} classNames="" />;
		}
		const object = allPersonas?.find((p) => p.personaId === id);
		return (
			object && (
				<PersonaBadge
					key={id}
					object={object}
					disabled={mode?.IsViewer()}
					unlink={() => handleChangeElement({ id, attribute: "linkedPersonas", entityType: "cem_persona", mode: "delete" })}
					info={cjmIdStr && !linkedPersonas.find((p) => p.personaId === id)}
                    isEdit={isEditMode}
                    classNames=""
				/>
			)
		);
	};
	const properties = {
		persona: {
			icon: "PERSONA_DUOTONE",
			label: "ASPX_98",
			elementIds: recordType === "cem_insight" ? personasIds : null,
			RenderElement: renderPersonaElement,
			popoverParams:
				recordType === "cem_insight"
					? {
						type: "persona",
						addLabel: "JS_151",
						addFunction: (item) => handleChangeElement({ id: item?.personaId, attribute: "linkedPersonas", entityType: "cem_persona" }),
						relatedItems: currentItem?.linkedPersonas || [],
						disabled: mode?.IsViewer(),
					}
					: null,
		},
		sentiment: {
			label: "SENTIMENT_LEVEL",
			icon: "SENTIMENT_DUOTONE",
			RenderElement: () => <Score interaction={currentItem} setInteraction={setCurrentItem} updateFunction={updateFunction} disabled={mode.IsViewer()} />,
		},
		tags: {
			label: "TAG",
			icon: "TAG_DUOTONE",
			RenderElement: () => <Tags object={currentItem} setObject={handleChangeTagsGroups} recordType={recordType} disabled={mode?.IsViewer()} size="xs" scroll={false} />,
		},
		groups: {
			label: "GROUP",
			icon: "GROUP_DUOTONE",
			RenderElement: () => <Group object={currentItem} setObject={handleChangeTagsGroups} recordType={recordType} disabled={mode?.IsViewer()} size="xs" />,
		},
		departments: {
			label: "DEPARTMENTS",
			icon: "DEPARTMENT_DUOTONE",
			elementIds: currentItem.linkedDepartments || [],
			RenderElement: (id, isEdit) =>
				LinkedCardsComponent({
					id,
					libraryType: "Departments",
					onRemove: () => handleChangeElement({ id, attribute: "linkedDepartments", mode: "delete", entityType: "cem_libraryvalue", prefix: "department" }),
					libraries,
					isEdit,
				}),
			popoverParams: {
				disabled: mode?.IsViewer(),
				addLabel: "ADD_DEPARTMENT",
				addFunction: ({ id }) => handleChangeElement({ id, attribute: "linkedDepartments", entityType: "cem_libraryvalue", prefix: "department" }),
				type: "department",
				relatedItems: currentItem.linkedDepartments || [],
			},
		},
		systems: {
			label: "SYSTEMS",
			icon: "SYSTEM_DUOTONE",
			elementIds: currentItem.linkedSystems || [],
			RenderElement: (id, isEdit) =>
				LinkedCardsComponent({
					id,
					libraryType: "Systems",
					onRemove: () => handleChangeElement({ id, attribute: "linkedSystems", mode: "delete", entityType: "cem_libraryvalue", prefix: "system" }),
					libraries,
					isEdit,
				}),
			popoverParams: {
				disabled: mode?.IsViewer(),
				addLabel: "ADD_SYSTEM",
				addFunction: ({ id }) => handleChangeElement({ id, attribute: "linkedSystems", entityType: "cem_libraryvalue", prefix: "system" }),
				type: "system",
				relatedItems: currentItem.linkedSystems || [],
			},
		},
		linkedTouchpoints: {
			label: "LINKED_TOUCHPOINTS",
			icon: "LINK_DUOTONE",
			elementIds: currentItem.linkedTouchpoints || [],
			RenderElement: (id, isEdit) => {
				const object = allTouchpoints.find((o) => o?.id === id);
				return (
					<>
						{object && (
							<TouchpointSelectCard
								key={object.id}
								object={object}
								handleRemove={() => handleChangeElement({ id, attribute: "linkedTouchpoints", mode: "delete", entityType: "cem_touchpoints" })}
								showBorders={false}
								isEdit={isEdit}
							/>
						)}
					</>
				);
			},
			popoverParams: {
				disabled: mode?.IsViewer(),
				addLabel: "ADD_TOUCHPOINT",
				addFunction: ({ id }) => handleChangeElement({ id, attribute: "linkedTouchpoints", entityType: "cem_touchpoints" }),
				type: "touchpoint",
				relatedItems: currentItem.linkedTouchpoints || [],
			},
		},
		insightType: {
			icon: "HASHTAG",
			label: "JS_78",
			elementIds: currentInsightType?.id ? [currentInsightType?.id] : [],
			RenderElement: (id, isEdit) => {
				return (
					<InsightType
						key={id}
						isEdit={isEdit}
						typeId={id}
						handleRemove={() => {
							handleChangeType({ id: EMPTY_GUID });
						}}
					/>
				);
			},
			popoverParams: {
				disabled: mode?.IsViewer(),
				addLabel: "ADD_INSIGHT_TYPE",
				addFunction: handleChangeType,
				type: "insightTypes",
			},
		},
	};

	return properties;
};

const GetHidedProperties = (handleChangeRows, rows) => {
	const { t } = useTranslation();

	const actions = [];
	actions.push({ id: "groups", handleClick: () => handleChangeRows("groups"), icon: "GROUP_DUOTONE", text: t("GROUPS"), itemClasses: "d-flex align-items-center" });
	actions.push({ id: "systems", handleClick: () => handleChangeRows("systems"), icon: "SYSTEM_DUOTONE", text: t("SYSTEMS"), itemClasses: "d-flex align-items-center" });
	actions.push({ id: "departments", handleClick: () => handleChangeRows("departments"), icon: "DEPARTMENT_DUOTONE", text: t("DEPARTMENTS"), itemClasses: "d-flex align-items-center" });
	actions.push({ id: "linkedTouchpoints", handleClick: () => handleChangeRows("linkedTouchpoints"), icon: "LINK_DUOTONE", text: t("LINKED_TOUCHPOINTS"), itemClasses: "d-flex align-items-center" });
	actions.push({ id: "tags", handleClick: () => handleChangeRows("tags"), icon: "TAG", text: t("TAGS"), itemClasses: "d-flex align-items-center" });

	return actions.filter((action) => rows.extra.includes(action.id));
};

const LinkedCardsComponent = ({ id, onRemove, libraryType, libraries, isEdit }) => {
	const item = useMemo(() => {
		const library = libraries?.find((s) => s.name === libraryType && s.isCustom === false);
		const libraryValue = library?.values?.find((s) => s.id === id);
		return libraryValue;
	}, [id, libraryType, libraries]);

	return <ColorTextCard key={item?.id} text={item?.name} color={item?.colorCode} className="p-1" height="2.25rem" fontSize="14px" onRemove={onRemove} isEdit={isEdit} showBorder={false} />;
};

PropertyManager.displayName = "PropertyManager";
PropertyManager.propTypes = {
	item: PropTypes.object,
	updateFunction: PropTypes.func,
	mode: PropTypes.string,
	linkFunction: PropTypes.func,
	unlinkFunction: PropTypes.func,
	recordType: PropTypes.string,
	defaultRows: PropTypes.object,
};
export default PropertyManager;
