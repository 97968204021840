import { ActionConfirmModal } from "shared/modals/index";
import { GenericButton, Input } from "shared/ui-basics/index";
import { EMPTY_GUID, detectImageExtension, isUserAllowed } from "@utils/helpers";
import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, FormFeedback, Label } from "reactstrap";
import styled from "styled-components/macro";
import { useModal } from 'use-modal-hook';
import { UploadImageModal } from "../../../../shared/components/UploadImage";
import { getSessionId } from "@redux/memoizedSelectors";

export default function StickerForm({ index, selectedItem, category, srcImg, onClose, onCreate, onUpdate, onDelete, mode = "update", defaultCategory, data }) {
	const { t } = useTranslation();
	const sessionId = useSelector(getSessionId);
	const userType = useSelector((state) => state.auth.userInfo.UserType);

	const [customImage, setCustomImage] = useState(index !== undefined && selectedItem !== null ? srcImg(selectedItem, sessionId) : mode === "create" ? "upload" : "default");
	const [selectCategory, setSelectCategory] = useState(index ? selectedItem.category.toString() : defaultCategory ? defaultCategory : '100000000');
	const [name, setName] = useState(index ? selectedItem.name : undefined);
	const [nameExist, setNameExist] = useState(false);
	const [weight, setWeight] = useState(index ? (selectedItem.weight !== "" && selectedItem.weight > 0 ? selectedItem.weight : 1) : 1);
	const [fileSize, setFileSize] = useState(null);

	const isDeleteAllowed = selectedItem?.isCustom && isUserAllowed(userType, "delete-library");

	const handleDelete = (e) => {
		onDelete(e);
		hideModalDelete();
		onClose();
	};

	const [showModalDelete, hideModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: "DELETE_TOUCHPOINT",
		Action: handleDelete,
		actionBtnText: "ASPX_13",
	});

	const create = (e) => {
		if (data) {
			if (name !== "" && name !== undefined && weight !== "" && weight >= 1) {
				const dataFiltering = data?.filter((type) => type.name.toUpperCase() === name.toUpperCase()) || [];
				if (customImage !== "upload" && customImage !== "error") {
					if (dataFiltering.length > 0) {
						setNameExist(true);
					} else {
						const FileExtension = detectImageExtension(customImage.split(",")[0]);
						const DocumentBody = customImage !== undefined && customImage !== -1 ? customImage.split(",").pop() : "";
						onCreate(name, selectCategory, weight, FileExtension === "svg+xml" ? "svg" : FileExtension, DocumentBody);
						setName(undefined);
						setNameExist(false);
						setCustomImage("upload");
						setWeight(1);
					}
				} else {
					setFileSize(-1);
				}
			} else {
				if (name === undefined) setName("");
			}
		}
	};

	const update = (e) => {
		if (customImage === "" || customImage === undefined) {
			setFileSize(-1);
			return;
		}
		if (name !== "" && name !== undefined && weight !== "" && weight >= 1) {
			if (customImage === "delete") {
				onUpdate(index, name, selectCategory, weight, "", ""); //update +remove img
			} else {
				const FileExtension = customImage.split(";").length > 1 ? detectImageExtension(customImage.split(",")[0]) : undefined;
				const DocumentBody = customImage.split(";").length > 1 ? customImage.split(",")[1] : undefined;
				onUpdate(index, name, selectCategory, weight, FileExtension, DocumentBody);
			}
		}
	};

	const handleChange = (e) => {
		setName(e.target.value.truncate(100));
		setNameExist(false);
	};

	return (
		<div>
			<NameWrapper>
				<Form onSubmit={onClose}>
					<LabelWrapper for="icon-name">{t("NAME")}</LabelWrapper>
					<Input
						variant="default"
						invalid={name === "" || nameExist}
						type="text"
						id="icon-name"
						value={name ? name : ""}
						onChange={handleChange}
						disabled={index && !selectedItem?.isCustom}
						placeholder={t("ENTER_NAME")}
					/>
					<FormFeedback>{nameExist ? t("NAME_EXISTS") : t("ICON_NAME_MISSING")}</FormFeedback>
				</Form>
			</NameWrapper>
			<ImgBox disabled={index && selectedItem?.isCustom}>
				<UploadImageModal
					variantImg={"librariesImage"}
					keyFor={mode + "icon"}
					id={mode + "icon"}
					disabled={index && selectedItem?.isCustom}
					deleteImage={false}
					customImage={customImage}
					setCustomImage={setCustomImage}
					fileSizeParent={fileSize}
				/>
			</ImgBox>
			<InputWrapper>
				<LabelWrapper>{t("SELECTCATEGORY")}</LabelWrapper>
				<SelectWrapper
					disabled={index && !selectedItem?.isCustom}
					onChange={(e) => {
						setSelectCategory(e.target.value);
					}}
					value={selectCategory}>
					{category?.map((item, index) =>
						item.id !== EMPTY_GUID ? (
							<option value={item.id} key={index}>
								{t(item.name)}
							</option>
						) : (
							""
						)
					)}
				</SelectWrapper>
			</InputWrapper>
			{mode !== "create" ? (
				<ButtonWrapper>
					<FooterDiv IsDeleteAllowed={isDeleteAllowed}>
						{isDeleteAllowed && (
							<GenericButton id={index} variant="outline-danger" className="ms-1 d-flex align-items-center" icon="TRASH" onClick={showModalDelete} iconClassName="me-1">
								{t("ASPX_13")}
							</GenericButton>
						)}
						<div>
							{selectedItem?.isCustom ? (
								<GenericButton variant="primary" disabled={customImage === "error"} className=" me-2 align-self-end" onClick={update}>
									{t("ASPX_12")}
								</GenericButton>
							) : (
								""
							)}
						</div>
					</FooterDiv>
				</ButtonWrapper>
			) : (
				<GenericButton variant="primary-outline" className="btn-block w-100" disabled={customImage === "error"} onClick={create}>
					{t("ASPX_108")}
				</GenericButton>
			)}
		</div>
	);
}

const NameWrapper = styled.div`
	margin-bottom: 24px;
`;

const ImgBox = styled.div`
	background-color: #ffffff;
	border: 1px dashed #9e9292;
	border-radius: 0.5rem;
	color: #7676d2;
	max-height: 250px;
	min-height: 140px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.75rem;
	${(props) => !props.disabled && props.disabled !== undefined && `    background-color: #e9ecef !important; `};
	margin-bottom: 24px;
`;

const FooterDiv = styled.div`
	padding-top: 1rem;
	display: flex;
	justify-content: ${(props) => (props.IsDeleteAllowed ? "space-between" : "flex-end")};
	width: 100%;
`;
const SelectWrapper = styled.select`
	border: 1px solid #adacb5 !important;
	border-radius: 4px !important;
	width: 100%;
	height: 38px;
	margin-bottom: 16px;
	:disabled {
		background-color: #e9ecef;
	}
`;

const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

const InputWrapper = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;
