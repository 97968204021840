import { EditInsights } from "features/index";
import { GenericButton } from "shared/ui-basics/buttons";
import { FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import InsightType from "../../../ui-basics/ui-components/InsightType";
import { Badge, BadgeWrapper, RemoveButton } from "./PicklistCard_style";

export const InsightCard = ({ index, entity, unlink, length, disable }) => {
	const [insightModal] = useModal(EditInsights, { insightId:entity?.id });
	const handleDelete = (e) => {
		e.stopPropagation();
		unlink(entity?.id);
	};
	return (
		<Badge key={entity?.id} id={entity?.id} onClick={insightModal}>
			<BadgeWrapper index={index} length={length}>
				<div className="ellipsis obj-name px-2" >
					{entity?.name.defaultPlaceholder("INSIGHT_NAME")}
				</div>
				<FlexBetweenAlignCenter className="px-2" minWidth='170px'>
					<InsightType typeId={entity?.typeId} />
					{!disable && (
						<RemoveButton>
							<GenericButton variant="light-link" id={entity?.id} icon="X" iconClassName="p-0" onClick={handleDelete} />
						</RemoveButton>
					)}
				</FlexBetweenAlignCenter>

			</BadgeWrapper>
		</Badge>
	);
};
