import { GenericButton, IconDispatcher } from "shared/ui-basics/index";
import { relinkPersona } from '@redux/index';
import { getSessionId } from "@redux/memoizedSelectors";
import { pictureValidationPersona, toastWrapper } from "@utils/helpers";
import { useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components/macro";

export const PersonaModalCard = ({ persona, variant, onClose, selected, unlinkPersona, clickPersona, cjmId, ...props }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const sessionId = useSelector(getSessionId);
	const [isShown, setIsShown] = useState(false);

	const viewJourney = () => {
		history.push(`/EditMap?cjmid=${cjmId}&personaId=${persona.personaId}`);

	};
	const reLinkPersonaAction = () => {
		const onSuccess = () => {
			onClose();
			history.push(`/EditMap?cjmid=${cjmId}&personaId=${persona.personaId}`);

		}

		relinkPersona({ personaIdStr: persona.personaId, cjmIdStr: cjmId , onSuccess ,displayToast: "RELINK"});
	};

	return (
		<CardModelWrapper selected={selected} className={"text-truncate "} onMouseEnter={() => (variant !== "new" ? setIsShown(true) : "")} onMouseLeave={() => setIsShown(false)}>
			<div onClick={variant !== "add" && variant !== "linkData" ? clickPersona : null} className="d-flex justify-content-center p-3 pb-1">
				<ImageWrapper alt="Card image cap" src={pictureValidationPersona(persona, sessionId)} />
			</div>
			{!isShown && (
				<CardBodyWrapper variant={variant} isshown={isShown.toString()}>
					{
						<>
							<DivLabelWrapper variant={"title"} className="text-truncate px-3">
								{persona?.name}
							</DivLabelWrapper>
							<DivLabelWrapper className="text-truncate px-3 sub-title">{persona?.occupation}</DivLabelWrapper>
						</>
					}
					{variant === "previousData" && (
						<WarningWrapper className="mt-2">
							{IconDispatcher("INFO","me-1")}
							<WarningLabel className="">{t("DESCRIPTION_PERSONA_LINK")}</WarningLabel>
						</WarningWrapper>
					)}
				</CardBodyWrapper>
			)}
			{isShown && (
				<CardBodyWrapper isshown={isShown.toString()} variant={variant}>
					{variant === "linkCjm" && (
						<>
							<GenericButton variant="tertiary-outline" onClick={viewJourney}>
								<div>{t("JS_179")}</div>
							</GenericButton>
							<GenericButton variant="tertiary-ghost" iconimported={"true"} onClick={unlinkPersona}>
								<div>{t("JS_180")}</div>
							</GenericButton>
						</>
					)}
					{variant === "previousData" && (
						<div className="d-flex flex-column">
							<GenericButton className="mb-2" variant="primary-outline" id={persona?.personaId} onClick={reLinkPersonaAction}>
								{t("USE_DATA")}
							</GenericButton>
							<GenericButton variant="primary" id={persona?.personaId} onClick={clickPersona}>
								{t("START_NEW_JOURNEY")}
							</GenericButton>
						</div>
					)}

					{variant === "add" && (
						<GenericButton variant="primary-outline" id={persona.personaId} onClick={clickPersona}>
							{t("JS_181")}
						</GenericButton>
					)}
					{variant === "currentPersona" && (
						<GenericButton variant="tertiary-outline" onClick={viewJourney}>
							<div>{t("JS_179")}</div>
						</GenericButton>
					)}
				</CardBodyWrapper>
			)}

			{/* } */}
		</CardModelWrapper>
	);
};

const ImageWrapper = styled(Image)`
	width: 120px;
	height: 120px;
	border-radius: 50%;
	object-fit: cover;
	border: 3px solid #c9c9c9;
	@media screen and (max-width: 1280px) {
		width: 90px;
		height: 90px;
	}
`;
const WarningWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		path {
			color: #7b2cbf;
		}
	}
`;
const WarningLabel = styled.div`
	white-space: pre-wrap;
	width: 80%;
	line-height: 1rem;
	font-size: 12px;
`;
const CardBodyWrapper = styled(CardBody)`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	/* padding-top:0rem; */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${(props) =>
		props?.variant === "previousData" &&
		`
    padding: 0.5rem;
    `}
	${(props) =>
		(props?.variant === "linkCjm" || props?.variant === "currentPersona") &&
		`
    align-items:flex-center;
    padding: 0;
    width:230px;
    `}
    ${(props) =>
		props?.variant === "add" &&
		`
    padding: 0;
    justify-content:start;
    `}
    ${(props) =>
		props?.isshown === "true" &&
		`
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    justify-content:center;
    `}
    ${(props) =>
		props?.isshown === "false" &&
		`
    padding-top:0;
`}

max-width:230px;
`;
const DivLabelWrapper = styled.div`
	max-width: 100%;
	/* font-size: 28px; */
	${(props) => props.variant === "title" && `font-size: 20px`}
`;
export const CardModelWrapper = styled(Card)`
	max-width: 230px;
	width: 230px;
	height: 260px;
	border: 1px solid #d9d9d9;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	padding: 0;
	margin-bottom: 1.5rem;
	${(props) =>
		props.selected &&
		`
    border:3px solid #18AEAD;
    `}
	position:relative;
	:hover {
		box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
	}
`;
