import { projectDetails } from "@redux/memoizedSelectors";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components";
import { updateProject } from "../reducer/ProjectsActions";
import * as Style from "./SectionsStyling";
import { useCallback } from "react";

export const ProjectDetails = () => {
	const { t } = useTranslation();

	const { id, projectManagementTool, projectManagementToolLink } = useSelector(projectDetails, shallowEqual);
	const roleName = useSelector((s) => s.projects?.currentUser?.roleName)?.toLowerCase();

	const [pmt, setPmt] = useState(projectManagementTool);
	const [pmtl, setPmtl] = useState(projectManagementToolLink);
	const [showEditLink, setShowEditLink] = useState(false);
	const isViewer = roleName === "viewer";

	const handleBlur = useCallback(async (attribute, value) => {
		setShowEditLink(false);
		try {
			updateProject({ modifiedProject: { id, [attribute]: value }, modifiedAttribNames: [attribute] });
		} catch (error) {
			// console.error("Failed to update project:", error);
			// Handle error (e.g., show a notification)
		}
	}, [ id]);

	const handleCopy = useCallback(async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			toast.success(t("COPY_LINK_SUCCESS"));
		} catch (error) {
			// console.error("Copy to clipboard failed:", error);
			// Handle error (e.g., show a notification)
		}
	}, [t]);
	return (
		<Style.CustomRow className="mb-5 gx-5">
			<Style.CustomCol xs={6} className="position-relative">
				<Style.CustomLabel>{t("PROJECT_MANAGEMENT_TOOL")}</Style.CustomLabel>
				<Style.CustomInput
					variant="default"
					type="text"
					name="projectManagementTool"
					onChange={(e) => setPmt(e.target.value)}
					onBlur={() => handleBlur("projectManagementTool", pmt)}
					value={pmt || ""}
					disabled={isViewer}
				/>

				<GenericButton variant='light' id='copy_button'
				className='position-absolute '
				style={{ right: '30px', top: '50%' }}
				onClick={() => handleCopy(pmt)}
				>
					{t("ASPX_85")}
				</GenericButton>
			</Style.CustomCol>

			<Col xs={6}>
				<Style.CustomLabel>{t("PROJECT_MANAGEMENT_TOOL_LINK")}</Style.CustomLabel>
				<LinkContainer>
					{showEditLink && (
						<Style.CustomInput
							variant="default"
							type="text"
							name="projectManagementToolLink"
							onChange={(e) => setPmtl(e.target.value)}
							onBlur={() => handleBlur("projectManagementToolLink", pmtl)}
							value={pmtl || ""}
							autoFocus
							disabled={isViewer}
						/>
					)}

					{!showEditLink && (
						<Link className="ps-1" onClick={() => handleCopy(pmtl)}>
							{pmtl || ""}
						</Link>
					)}
					{!isViewer && (
						<EditAction className="ms-2 me-4">
							<GenericButton variant="light-link" icon={"EDIT"} onClick={() => setShowEditLink(true)} />
						</EditAction>
					)}
				</LinkContainer>
			</Col>
		</Style.CustomRow>
	);
};

const LinkContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 44px;
	:hover {
		cursor: pointer;
	}
`;

const Link = styled.a`
	display: flex;
	align-items: center;
	width: 80%;
	min-height: 44px;
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EditAction = styled.div`
	visibility: hidden;
	${LinkContainer}:hover & {
		visibility: visible;
	}
`;
