import { EMPTY_GUID, isMapHasLinkedMaps } from "@utils/helpers";
import { PermissionManager } from "@utils/permissionManager";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ActionConfirmModal, DuplicateModal } from "shared/modals/index";
import GenericManageAccess from "shared/modals/manage-access/GenericManageAccess";
import { useModal } from "use-modal-hook";
import { archiveCJM, copyCJM, deleteCJM, deletePermanentlyMap, restoreCJM } from "../reducer/CJMActions";


export default function BaseActions({ map, context, setRename, personaIdStr }) {
	const { t } = useTranslation();
	const history = useHistory();
	const { pathname } = useLocation();
	const isInEdit = pathname === "/EditMap";
	const isInHome = pathname === "/home";
	const isSample = map?.isSample;
	const isTemplate = map?.isTemplate;

	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allActiveMaps = useSelector((state) => state?.maps?.all_maps)?.getActiveRecords(!showSampleData);
	const userId = useSelector((state) => state.auth?.userInfo?.UserId);
    let userAccess = useSelector((state) => state?.maps?.currentUser?.roleName);
	const isStandard = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName.toLowerCase() === "standard");


	if (isInHome) context = "/journey-map";
    if (!isInEdit) userAccess = map?.authorizedUsers?.find((user) => user.id === userId && user.roleId === EMPTY_GUID)?.roleName;
    if (isStandard) userAccess = "owner"

	const id = map.cjmId;
	const mapName = isInEdit ? map?.cjmName : map?.name;

    const backToOrigin = () => {
        if (isTemplate) {
            history.push(`/templates?tab=maps`);
        }
        else history.push(`/journey-map`);
    }

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const handleArchive = () => {
		actionConfirmModal({
			bodyContent: "ARCHIVE_GENERIC",
			Action: () => archiveCJM({ id, onSuccess: isInEdit && backToOrigin }),
			actionBtnText: "ARCHIVE",
		});
	};

	const handleDelete = () => {
		actionConfirmModal({
			bodyContent: "DELETE_GENERIC",
			Action: () => deleteCJM({ id, onSuccess: isInEdit && backToOrigin }),
			actionBtnText: "ASPX_13",
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deletePermanentlyMap({ id, deleteAllVersion: true }),
			actionBtnText: "ASPX_13",
		});
	};

	const callBackCopy = (response) => {
		if (typeof response === "object") {
			history.push(`/EditMap?cjmid=${response?.cjmId}&personaId=${personaIdStr}`);
		}
	};
	const [showCopyModal] = useModal(DuplicateModal, {
		title: `${t("JS_61")} ${t("JOURNEY")}`,
		inputTitle: "JOURNEY_MAP_NAME",
		closeBtnLabel: "COPY",
		inputValue: `[${t("COPY_NOUN").toUpperCase()}]- ` + mapName,
		action: ({ name, selectedWorkspaces }) => copyCJM({ id, name, workspaceIds: selectedWorkspaces.map((w) => w?.id), onSuccess: isInEdit && callBackCopy }),
	});

	const [showAccessModal] = useModal(GenericManageAccess, {
		itemId: id,
		context: "/journey-map",
	});

	const { renameAllowed, copyAllowed, archiveAllowed, deleteAllowed, manageAccessAllowed, showHierarchyAllowed, restoreAllowed, deletePermanentAllowed } = PermissionManager({
		userAccess,
		context,
    });


	const createActions = () => {
		let actions = [];
		const showMapHierarchy = isMapHasLinkedMaps(map?.linkedCjms, map?.[isInEdit ? "cjmPhasesData" : "phases"], map?.[isInEdit ? "cjmStagesData" : "stages"], allActiveMaps);
		if (renameAllowed && setRename) actions.push({ id: "rename", handleClick: () => setRename(id), icon: "EDIT", text: t("JS_89") });
		if (copyAllowed) actions.push({ id: "copy", handleClick: showCopyModal, icon: "COPY", text: t("JS_61") });
		if (manageAccessAllowed) actions.push({ id: "manageAccess", handleClick: showAccessModal, icon: "ACCESS", text: t("ASPX_17") });
		if (showHierarchyAllowed && showMapHierarchy) {
			actions.push({
				id: "mapHierarchy",
				handleClick: () => history.push(`/map-hierarchy?cjmid=${id}&personaId=${personaIdStr || map?.linkedPersonas[0]?.personaId}`),
				icon: "MAP-HIERARCHY",
				text: t("VIEW_HIERARCHY"),
			});
		}
		if (archiveAllowed || deleteAllowed) actions.push({ id: "divider2", divider: true });
		if (archiveAllowed) {
			actions.push({
				id: "archive",
				handleClick: handleArchive,
				icon: "ARCHIVE",
				text: t("ARCHIVE"),
			});
		}
		if (deleteAllowed) {
			actions.push({
				id: "delete",
				handleClick: handleDelete,
				icon: "DELETE",
				text: t("CS_45"),
			});
		}
		if (restoreAllowed) actions.push({ id: "restore", handleClick: () => restoreCJM({ id }), icon: "RESTORE", text: t("JS_96") });
		if (deletePermanentAllowed) actions.push({ id: "deletePermanent", handleClick: handleDeletePermanent, itemStyle: "warning-delete", icon: "DELETE", text: t("JS_97") });
		if (isSample) {
			actions = actions.filter((action) => action.id === "copy" || action.id === "mapHierarchy");
		}

		return actions;
	};

	return createActions();
}
