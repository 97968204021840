import {
	getAllAuthorizedUsers,
	getAllRegisteredUsers,
	getAllRoles,
	getAllTeams,
	getCxProgramUsers,
	getMapUsers,
	getPersonaUsers,
	getSubscriptionDetails,
	resetCxProgramUsers,
	resetMapUsers,
	resetPersonasUsers,
	shareCxProgram,
	shareManyCxPrograms,
	sharePersona,
	shareUsersManyMaps,
	shareUsersMuliplePersona,
	shareUsersSinglePersona,
	unSharePersona,
	unshareCxProgram,
} from "@redux/index";
import { EMPTY_GUID, GetPickListUsers, User } from "@utils/helpers";
import { shareCjm, shareManyCjm, unShareCjm } from "features/index";
import { getProjectUsers, resetProjectUsers, shareManyProjects, shareProject, unShareProject } from "features/projects/reducer/ProjectsActions";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Label, Modal, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomTooltip from "shared/components/CustomTooltip";
import { Spinner } from "shared/components/spinners/Spinner";
import UsersSelectorTypeahead from "shared/components/typeahead/users-selector-typeahead";
import { useAsyncs } from "shared/hooks/index";
import { GenericButton, GenericDropdown, IconDispatcher } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import { base } from "../../..";
import { ActionConfirmModal } from "../basic/ActionConfirmModal";
import * as Style from "./ManageAccessStyle";
import { UserType } from "@utils/optionsSets/OptionSets";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";

const paths = {
	mapUsers: "maps.current_linked_users",
	strategyUsers: "strategies.current_linked_users",
	personaUsers: "personas.current_linked_users",
	cxProgramUsers: "cxProgram.current_linked_users",
	projectUsers: "projects.current_linked_users",
};

const Manager = ({ props, pathname, userId, personaIdStr, hideForPdf, onClose }) => {
	const settings = {
		path: "",
		getUsers: null,
		share: () => {},
		unShare: () => {},
		shareMany: () => {},
	};
	const path = props.context ? props.context : pathname;
	/* eslint-disable no-fallthrough */
	const isTemplate = useSelector((state) => state?.maps?.all_maps?.find((x) => x?.cjmId === props?.itemId)?.isTemplate);

	switch (path) {
		case "/personas":
			settings.reset = pathname !== "/EditPersona" && resetPersonasUsers;
		case "/EditPersona":
			settings.path = paths.personaUsers;
			settings.getUsers = getPersonaUsers;
			settings.getUsersParams = { objectId: props.itemId, isPrint: hideForPdf, userId: userId };
			settings.share = (params) => sharePersona({ ...params, personaId: props.itemId });
			settings.unShare = (params) => unSharePersona({ ...params, personaId: props.itemId });
			settings.shareMany = (params) => shareUsersSinglePersona({ ...params, personaId: props.itemId });
			if (props.bulk) {
				const objectIds = props.ids;
				const onSuccess = () => {
					props.onSuccess && props.onSuccess();
					onClose();
				};
				settings.shareMany = (params) => shareUsersMuliplePersona({ ...params, personaIds: objectIds, onSuccess });
			}
			break;
		case "/cx-program":
			settings.reset = pathname !== "/EditCxProgram" && resetCxProgramUsers;
		case "/EditCxProgram":
			settings.path = paths.cxProgramUsers;
			settings.getUsers = getCxProgramUsers;
			settings.getUsersParams = { cxProgramId: props.itemId, userId: userId };
			settings.share = (params) => shareCxProgram({ ...params, recId: props.itemId });
			settings.unShare = (params) => unshareCxProgram({ ...params, recId: props.itemId });
			settings.shareMany = (params) => shareManyCxPrograms({ ...params, recIds: [props.itemId] });
			if (props.bulk) {
				const recIds = props.ids.split("|");
				const onSuccess = () => {
					props.onSuccess && props.onSuccess();
					onClose();
				};
				settings.shareMany = (params) => shareManyCxPrograms({ ...params, recIds, onSuccess });
			}
			break;
		case "/journey-map":
			settings.reset = pathname !== "/EditMap" && resetMapUsers;
		case "/EditMap":
			settings.path = paths.mapUsers;
			settings.getUsers = getMapUsers;
			settings.getUsersParams = { cjmId: props.itemId, userId: userId, isPrint: hideForPdf, forceViewer: personaIdStr?.trim() === EMPTY_GUID };
			settings.share = (params) => shareCjm({ ...params, cjmIdStr: props.itemId });
			settings.unShare = (params) => unShareCjm({ ...params, cjmIdStr: props.itemId });
			settings.shareMany = (params) => shareManyCjm({ ...params, cjmIdStr: props.itemId });
			settings.isTemplate = isTemplate;
			if (props.bulk) {
				const cjmIds = props.ids;
				const onSuccess = () => {
					props.onSuccess && props.onSuccess();
					onClose();
				};
				settings.shareMany = (params) => shareUsersManyMaps({ ...params, cjmIds, onSuccess });
			}
			break;
		case "/projects":
			settings.reset = pathname !== "/EditProject" && resetProjectUsers;
		case "/EditProject":
			settings.path = paths.projectUsers;
			settings.getUsers = getProjectUsers;
			settings.getUsersParams = { projectId: props.itemId, userId };
			settings.share = (params) => shareProject({ ...params, recId: props.itemId });
			settings.unShare = (params) => unShareProject({ ...params, recId: props.itemId });
			settings.shareMany = (params) => shareManyProjects({ ...params, recIds: [props.itemId] });
			if (props.bulk) {
				const recIds = props.ids.split("|");
				const onSuccess = () => {
					props.onSuccess && props.onSuccess();
					onClose();
				};
				settings.shareMany = (params) => shareManyProjects({ ...params, recIds, onSuccess });
			}
			break;
		default:
	}
	/* eslint-disable no-fallthrough */

	return settings;
};

export default function GenericManageAccess({ isOpen, onClose, ...props }) {
	const { t } = useTranslation();
	const ref = useRef();
	const { search, pathname } = useLocation();
	const userId = useSelector((state) => state.auth.userInfo.UserId);
	const settings = Manager({
		props,
		userId,
		pathname,
		personaIdStr: search.getPersonaId(),
		hideForPdf: search.hideForPdf(),
		onClose,
	});
	const copyLink = getCopyLink(pathname, search);
	const [loading, setLoading] = useState(false);
	useAsyncs({
		asyncFns: [{ asyncFn: settings?.getUsers, parameter: settings?.getUsersParams }],
		resets: [settings?.reset],
		setLoading,
	});
	useAsyncs({
		asyncFns: [{ asyncFn: getAllRoles }, { asyncFn: getAllRegisteredUsers }, { asyncFn: getAllAuthorizedUsers }, { asyncFn: getAllTeams }, { asyncFn: getSubscriptionDetails }],
	});

	const authorizedUsers = useSelector((state) => state.settings.currentWorkspaceAuthorizedUsers);
	const users = useSelector((state) => state.settings.users);

	const filteredUsers = authorizedUsers.filter((user) => {
		const userDetails = users?.find((u) => u?.loginName?.toLowerCase() === user?.domainName?.toLowerCase());

		if (settings.isTemplate) {
			return [UserType.companyAdmin, UserType.admin].includes(userDetails?.userType) && userDetails?.isActive;
		}

        return userDetails?.isActive 
	});

	const teams = useSelector((state) => state.settings?.currentWorkspaceTeams || []);
	const records = useSelector((state) => _.get(state, settings.path));
	const selectedRecords = records?.filter((user) => user.roleId !== EMPTY_GUID && (filteredUsers.map((au) => au?.id)?.includes(user?.id) || teams.map((au) => au?.id)?.includes(user?.id)));
	const currentUser = records?.find((user) => user.roleId === EMPTY_GUID);
	const otherRecords = GetPickListUsers([...filteredUsers, ...(settings.isTemplate ? [] : teams)], selectedRecords);
	const rolesOptions = useSelector((state) => createRoleOptions(state.settings.roles));
	const restrictAccess = currentUser === undefined ? false : currentUser?.roleName?.toLowerCase() !== "owner";
	const nonSelectedRecords = [{ id: "", fullName: "All" }, ...otherRecords];
	const teamItems = getTeamItems(selectedRecords);
	const userItems = getUsersItems(selectedRecords);
	const [selectedRole, setSelectedRole] = useState(rolesOptions?.[0]?.id);
	const [isUpdating, setIsUpdating] = useState(false);

	useEffect(() => {
		if (selectedRole === undefined) {
			setSelectedRole(rolesOptions?.[0]?.id);
		}
	}, [rolesOptions]);

	const callbacks = {
		onFailed: () => setIsUpdating(false),
		onSuccess: () => {
			setIsUpdating(false);
			ref.current.clear();
		},
	};

	const update = async (e) => {
		const selectedItems = ref.current?.state?.selected;
		if (otherRecords.length === 0) {
			ref.current.clear();
			return toast.info(t("NO_USERS_AVAILABLE_TO_SHARE"));
		}
		if (selectedItems?.length === 0 || selectedItems === undefined) {
			return toast.info(t("SELECT_USERS"));
		}
		const allMembersSelected = selectedItems?.find((user) => user.fullName === "All");

		const allSelectedUsers = getUsersItems(selectedItems, true).ArrayToIdsStr();
		const allteams = getTeamItems(selectedItems, true).ArrayToIdsStr();
		const allUsers = allMembersSelected ? getUsersItems(otherRecords).ArrayToIdsStr() : allSelectedUsers;
		setIsUpdating(true);
		settings.shareMany({ roleDetails: selectedRole, usersIds: allUsers, teamIds: allteams, ...callbacks });
	};

	const copyFeature = () => {
		const handleCopy = () => {
			navigator.clipboard.writeText(copyLink);
			toast.success(t("COPY_LINK_SUCCESS"));
		};
		return (
			<ModalFooter className="d-block">
				<Label className="mb-2 d-flex align-items-center ps-1">
					{t("SHARE_LINK")} <Style.SubLabel className="ms-2">({t("LOGIN_REQUIRED")})</Style.SubLabel>
				</Label>
				<Row className="mb-4 ">
					<Style.LinkContainer>
						<Style.Link href={copyLink} onClick={handleCopy} target="_blank" rel="noreferrer">
							{copyLink}
						</Style.Link>
					</Style.LinkContainer>
					<Style.ButtonContainer className="flex-end">
						<GenericButton variant="primary-outline" onClick={handleCopy} icon={"LINK"} iconClassName="me-1">
							{t("COPY_LINK")}
						</GenericButton>
					</Style.ButtonContainer>
				</Row>
			</ModalFooter>
		);
	};

	return (
		<Modal fade={false} isOpen={isOpen} centered={true} size="lg" toggle={onClose}>
			<ModalHeader toggle={onClose}>{t("MANAGE_ACCESS")}</ModalHeader>
			<Style.Body disabled={restrictAccess}>
				<Label className="mb-2">{t("TEAM_MEMBERS")}</Label>
				<Row className="mb-4 ps-3">
					<Style.ColWrapper xs={10} className="justify-content-between">
						{nonSelectedRecords && <UsersSelectorTypeahead options={nonSelectedRecords} placeholder={t("SELECT_USERS")} ref={ref} width="80%" />}
						<GenericDropdown options={rolesOptions} value={selectedRole} onClick={(e) => setSelectedRole(e.target.value)} icon />
					</Style.ColWrapper>
					<Col xs={2} className="d-flex justify-content-center align-items-center">
						<GenericButton variant="primary" onClick={update} disabled={isUpdating}>
							{t("ASPX_165")}
						</GenericButton>
					</Col>
				</Row>
				<Style.UserContainer className="mt-4" isbulk={props.bulk}>
					{loading ? (
						<Spinner />
					) : (
						<>
							{teamItems?.length > 0 && !props.bulk && (
								<>
									<Style.Label>{t("TEAMS")}</Style.Label>
									{teamItems?.map((team) => (
										<UserRow user={team} key={team.id} share={settings.share} unShare={settings.unShare} isTeam={true} settings={settings} />
									))}
								</>
							)}
							{userItems?.length > 0 && !props.bulk && (
								<>
									<Style.Label>{t("ASPX_210")}</Style.Label>
									{userItems
										?.sort((a, b) => customSort(a, b, currentUser?.id))
										?.map((user) => (
											<UserRow user={user} key={user.id} share={settings.share} unShare={settings.unShare} userDomain={currentUser} settings={settings} />
										))}
								</>
							)}
						</>
					)}
				</Style.UserContainer>
			</Style.Body>
			{["/EditMap", "/EditPersona", "/EditCxProgram", "/EditProject"].includes(pathname) && copyFeature()}
		</Modal>
	);
}

const UserRow = ({ user, share, unShare, userDomain, isTeam, settings }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const rolesOptions = useSelector((state) => createRoleOptions(state.settings.roles));

	const isCurrentUser = user?.id === userDomain?.id;
	const [role, setSelectRole] = useState(user.roleName?.toLowerCase() + "_" + user?.roleId);
	const [processDelete, setProcessDelete] = useState(false);
	const initials = user.fullName?.getInitials();
	const [confirmModal] = useModal(ActionConfirmModal);
	const callbacks = {
		onFailed: () => setProcessDelete(false),
		onSuccess: () => {
			setProcessDelete(false);
			dispatch(settings.getUsers(settings.getUsersParams));
		},
	};
	const deleteUserOrTeam = async () => {
		confirmModal({
			title: "ASPX_145",
			bodyContent: t("UNSHARE_USER_1") + user?.fullName + t("UNSHARE_USER_2"),
			Action: () => {
				setProcessDelete(true);
				unShare({ [isTeam ? "teamId" : "userId"]: user.id, ...callbacks });
			},
			actionBtnText: "ASPX_122",
		});
	};
	const handleChangeRole = (e) => {
		setSelectRole(e.target.value);
		share({ roleDetails: e.target.value, [isTeam ? "teamId" : "userId"]: user.id, userIdName: user.fullName, ...callbacks });
	};

	return (
		<Row id={user?.id} key={user?.id} className="px-3 my-3">
			<Col xs={7} className="d-flex ">
				{isTeam ? <Style.IconWrapper className="me-1">{IconDispatcher("USER-GROUP")}</Style.IconWrapper> : <InitialsBubble fullName={user.fullName} />}
				<div className=" p-1 ">{user.fullName} </div>
			</Col>
			<Col xs={4}>
				<GenericDropdown options={rolesOptions} value={role} onClick={handleChangeRole} className="border-bottom" icon={!isCurrentUser} disabled={isCurrentUser} />
			</Col>
			{!isCurrentUser && (
				<Col className="px-1 d-flex" xs={1}>
					{processDelete ? (
						<Spinner animation="border" variant="primary" />
					) : (
						<CustomTooltip id={user.id} text={t("ASPX_13")}>
							<GenericButton className="me-2" variant="light-link" icon="X" onClick={deleteUserOrTeam} />
						</CustomTooltip>
					)}
				</Col>
			)}
		</Row>
	);
};

const getCopyLink = (pathname, search) => {
	return `${window.location.origin}${base}${pathname.replace("/", "")}${search}`;
};
const getTeamItems = (array, checkwithIsMemberIds) => {
	if (checkwithIsMemberIds) {
		return array?.filter((user) => Object.prototype.hasOwnProperty.call(user, "memberUserIds") === true);
	}
	return array?.filter((user) => user.accessType === "team");
};
const getUsersItems = (array, checkwithIsMemberIds) => {
	if (checkwithIsMemberIds) {
		return array?.filter((user) => Object.prototype.hasOwnProperty.call(user, "memberUserIds") === false);
	}
	return array?.filter((user) => user.accessType === "user");
};
const customSort = (a, b, id) => {
	if (a.id === id) return -1;
	if (b.id === id) return 1;
	return 0;
};
function createRoleOptions(roles) {
	return roles?.map((role) => ({
		id: `${role.name.toLowerCase()}_${role.id}`,
		value: role.name,
	}));
}
