import { error, success } from "@redux-requests/core";
import { t } from "i18next";
import { toast } from "react-toastify";
import { StateTypeOption, StatusCode } from "@utils/optionsSets/OptionSets";
import { EMPTY_GUID, appendListsAndRemoveDuplicates, decode, firstLetterUppercase, getNextPosition, hasAttribute, toastWrapper } from "@utils/helpers";

const initialState = {
	current_persona: {},
	all_personas: [],
	current_persona_map: {},
	current_linked_users: [],
	loading: false,
};

const PersonaReducer = (state = initialState, action) => {
	const requestData = action?.meta?.requestAction?.request?.data;
	const responseData = action?.response?.data;

	switch (action.type) {
		case "GET_ALL_PERSONAS":
			return {
				...state,
				loading: state.all_personas?.length === 0,
			};

		case error("GET_ALL_PERSONAS"):
			return {
				...state,
				loading: false,
			};
		case success("GET_ALL_PERSONAS"): {
			const { url } = action?.meta?.requestAction?.request;
			if (url.includes("redirectReducer")) return state;

			const all_personas =
				responseData?.map((persona) => {
					const personaAtt = Object.entries(persona.personaAttributes)?.map((x) => x[1]) || [];
					return {
						...persona,
						personaAttributes: personaAtt,
					};
				}) || [];
			return {
				...state,
				all_personas: all_personas,
				loading: false,
			};
		}
		case success("COPY_PERSONA"):
			return {
				...state,
				all_personas: [...state.all_personas, responseData],
			};
		case success("DELETE_PERSONA"):
			return {
				...state,
				all_personas: state?.all_personas?.map((persona) => {
					if (persona.personaId === requestData.personaIdStr) {
						return {
							...persona,
							statusCode: StatusCode.Trashed
						};
					}
					return persona;
				}),
			};
		case success("ARCHIVE_PERSONA"):
			return {
				...state,
				all_personas: state?.all_personas?.map((persona) => {
					if (persona.personaId === requestData.personaIdStr) {
						return {
							...persona,
							statusCode: StatusCode.Archived
						};
					}
					return persona;
				}),
			};
		case success("GET_PERSONA_CJM_DATA"): // not implemented yet
			return {
				...state,
			};
		case success("RESTORE_PERSONA"):
			return {
				...state,
				all_personas: state.all_personas.map((persona) => {
					if (persona.personaId === requestData.personaIdStr) {
						return {
							...persona,
							statusCode: StatusCode.Active
						};
					}
					return persona;
				}),
			};
		case success("DELETE_PERMANENTLY_PERSONA"):
			return {
				...state,
				all_personas: state.all_personas.filter((persona) => persona.personaId !== requestData.personaIdStr),
			};
		case success("CREATE_PERSONA"):
			return {
				...state,
				all_personas: [...state.all_personas, responseData],
			};
		case "SET_CURRENT_PERSONA":
			return {
				...state,
				loading: true,
			};
		case success("SET_CURRENT_PERSONA"): {
			const dataPersona = responseData;
			if (dataPersona.personaId === "00000000-0000-0000-0000-000000000000") toast.info(t("JS_134"));

			return {
				...state,
				current_persona_map: dataPersona || {},
				loading: false,
			};
		}
		case error("SET_CURRENT_PERSONA"):
			return {
				...state,
				current_persona_map: {},
				loading: false,
			};
		case success("UPDATE_PERSONA_SINGLE_TXT"):
			if (responseData === true) {
				const attributeName = requestData.attributeName.split("cem_")[1];
				const newValue = decode(requestData.newValue);
				return {
					...state,
					current_persona: {
						...state.current_persona,
						[attributeName]: newValue,
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return {
				...state,
			};
		case success("UPDATE_PERSONA_TYPE"): {
			if (responseData === true) {
				const ids = requestData.personaIds.split("|") || [];

				const newValue = requestData.newValue;
				return {
					...state,
					current_persona: {
						...state.current_persona,
						isFuture: newValue === StateTypeOption.FutureState,
						isFutureState: newValue === StateTypeOption.FutureState,
						lastModifiedOn: new Date().toISOString(),
					},
					all_personas: state.all_personas?.map((persona) => {
						if (ids.includes(persona.personaId)) {
							return {
								...persona,
								isFuture: newValue === StateTypeOption.FutureState,
								lastModifiedOn: new Date().toISOString(),
							};
						}
						return persona;
					}),
				};
			}
			return {
				...state,
			};
		}
		case success("UPDATE_PERSONA_NAME"): {
			if (responseData === true) {
				return {
					...state,

					all_personas: state.all_personas.map((persona) => {
						if (persona.personaId === requestData.personaIdStr) {
							return {
								...persona,
								name: decode(requestData.newValue),
								lastModifiedOn: new Date().toISOString(),
							};
						}
						return persona;
					}),
				};
			}
			return {
				...state,
			};
		}
		case "RESET_CURRENT_PERSONA":
			return {
				...state,
				current_persona: {},
				current_persona_map: {},
			};
		case "GET_PERSONA":
			return {
				...state,
				loading: true,
			};
		case error("GET_PERSONA"):
			return {
				...state,
				loading: false,
			};
		case "NO_ACCESS_persona": {
			return {
				...state,
				loading: false,
				current_persona: { ...state.current_persona, noAccess: true },
			};
		}
		case success("GET_PERSONA"): {
			const personaForm = responseData;
			const personaAtt = Object.entries(personaForm.personaAttributes).map((x) => x[1]);
			const sortablePosition = eval(personaForm.personaAttributePositions); // here is your object Good luck
			personaForm.personaAttributes = personaAtt;
			personaForm.personaAttributePositions = sortablePosition;
			return {
				...state,
				current_persona: { ...state.current_persona, ...personaForm } || {},
			};
		}

		case success("UPDATE_CUSTOM_PERSONA_ATTRIBUTE_DESC"): {
			if (responseData === true) {
				const attributeIdStr = requestData.attributeIdStr;
				const newValueCustom = decode(requestData.newDescription);
				return {
					...state,
					current_persona: {
						...state.current_persona,
						personaAttributes: state.current_persona?.personaAttributes?.map((att) => {
							if (att.id === attributeIdStr) {
								att.descriptionHtml = newValueCustom;
							}
							return att;
						}),
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return {
				...state,
			};
		}
		case "UPDATE_ORDER_LOCALLY": {
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaData: action.payload,
				},
			};
		}
		case "UPDATE_PERSONA_ATTRIB_ORDER": {
			const newConfig = JSON.parse(action.request.data.newOrderConfig);
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributePositions: newConfig,
				},
			};
		}
		case success("UPDATE_PERSONA_ATTRIB_ORDER"): {
			return {
				...state,
				current_persona: {
					...state.current_persona,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_LAYOUT":
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributePositions: action.payload,
				},
			};

		case "UPDATE_ATTRIBUTE_NAME": {
			const { attributeIdStr, newName } = action?.request?.data;
			const newNameUpdated = decode(newName);
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: state.current_persona?.personaAttributes?.map((att) => {
						if (att.id === attributeIdStr) {
							att.name = newNameUpdated;
						}
						return att;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("UPDATE_ATTRIBUTE_COLOR"): {
			if (action.response?.data === true) {
				const customAttributeIdStr = requestData.customAttributeIdStr;
				const newColorCodeParam = requestData.newColorCodeParam;
				return {
					...state,
					current_persona: {
						...state.current_persona,
						personaAttributes: state.current_persona?.personaAttributes?.map((att) => {
							if (att.id === customAttributeIdStr) {
								att.colorCode = newColorCodeParam;
							}
							return att;
						}),
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return {
				...state,
			};
		}
		case success("ADD_PERSONA_ATTRIBUTE"): {
			const newPersonaAtt = [
				...state.current_persona.personaAttributes.map((x) => {
					return {
						...x,
						setFocus: false,
					};
				}),
			];
			newPersonaAtt?.push({ ...action.response?.data, setFocus: true });
			const newPosition = {
				w: 1,
				h: 240,
				x: 0,
				y: Infinity,
				i: action.response?.data.id,
				minH: 240,
				maxH: 240,
				moved: false,
				static: false,
			};

			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: newPersonaAtt,
					personaAttributePositions: [...state.current_persona.personaAttributePositions, newPosition],
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("ADD_PERSONA_ATTRIBUTE_TYPE"): {
			if (action.response?.data === true) {
				const newPersonaData = [...state.current_persona.personaData];
				newPersonaData[2]?.push(action.meta.requestAction.params);
				return {
					...state,
					current_persona: {
						...state.current_persona,
						personaData: newPersonaData,
						lastModifiedOn: new Date().toISOString(),
					},
				};
			}
			return {
				...state,
			};
		}
		case success("DELETE_PERSONA_CUSTOM_FIELD"): {
			const customAttributeIdStrToDelete = requestData.attributeIdStr;
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: state.current_persona?.personaAttributes?.filter((att) => {
						return att.id !== customAttributeIdStrToDelete;
					}),
					personaAttributePositions: state.current_persona?.personaAttributePositions?.filter((att) => {
						return att.i !== customAttributeIdStrToDelete;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("UPDATE_PERSONA_STD_IMAGE"):
			return {
				...state,
				current_persona: {
					...state.current_persona,
					standardImageNumber: requestData.standardImageNumber,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("UPDATE_PERSONA_CUSTOM_IMAGE"):
			return {
				...state,
				current_persona: {
					...state.current_persona,
					standardImageNumber: 0,
					customImageBody: requestData.imageExtension,
					lastModifiedOn: new Date().toISOString(),
				},
			};
		case success("DELETE_PERSONA_CUSTOM_IMAGE"):
			return {
				...state,
				current_persona: { ...state.current_persona, lastModifiedOn: new Date().toISOString() },
			};
		case success("UPDATE_CUSTOM_PERSONA_ATTRIBUTE_IMAGE"):
			return {
				...state,
				current_persona: { ...state.current_persona, lastModifiedOn: new Date().toISOString() },
			};
		case success("DELETE_IMAGE_ON_BOX"):
			return {
				...state,
				current_persona: { ...state.current_persona, lastModifiedOn: new Date().toISOString() },
			};
		case success("PERVIOUS_PERSONA"):
			return {
				...state,

				all_personas: state.all_personas.map((persona) => {
					if (persona.personaId === requestData.personaIdStr) {
						return {
							...persona,
							isPervios: responseData ? true : false,
						};
					}
					return persona;
				}),
			};
		case success("RETRIEVE_LINKS_MAPS"):
			return {
				...state,
				current_persona: {
					...state.current_persona,
					linkedCjms: responseData,
				},
			};
		case success("DELETE_MANY_PERSONAS"): {
			const successIdDeleted = responseData;
			return {
				...state,
				all_personas: state.all_personas?.map((personas) => {
					if (successIdDeleted.includes(personas.personaId)) {
						return { ...personas, statusCode: StatusCode.Trashed };
					}
					return personas;
				}),
			};
		}
		case success("ARCHIVE_MANY_PERSONAS"): {
			const successIdArchived = responseData;

			return {
				...state,
				all_personas: state.all_personas?.map((personas) => {
					if (successIdArchived.includes(personas.personaId)) {
						return { ...personas, statusCode: StatusCode.Archived };
					}
					return personas;
				}),
			};
		}
		case success("RESTORE_MANY_PERSONAS"): {
			const successIdRestore = responseData;

			return {
				...state,
				all_personas: state.all_personas?.map((personas) => {
					if (successIdRestore.includes(personas.personaId)) {
						return { ...personas, statusCode: StatusCode.Active };
					}
					return personas;
				}),
			};
		}
		case success("DELETE_PERMANENTLY_MANY_PERSONAS"): {
			const successIdDeletePerma = responseData;

			return {
				...state,
				all_personas: state.all_personas.filter((personas) => !successIdDeletePerma.includes(personas.personaId)),
			};
		}
		case "UPSERT_PERSONA_ATTRIBUTE_ITEM": {
			const { personaAttributeItem, personaAttribute } = action.request.data;
			const upsertItem = {
				...personaAttributeItem,
				name: decode(personaAttributeItem?.name ?? ""),
				description: decode(personaAttributeItem?.description ?? ""),
				sliderMaxTitle: decode(personaAttributeItem?.sliderMaxTitle ?? ""),
				sliderMinTitle: decode(personaAttributeItem?.sliderMinTitle ?? ""),
			};

			const validateItems = (items) => {
				if (items.find((item) => item.id === upsertItem.id)) {
					return items.map((item) => {
						if (item.id === upsertItem.id) {
							item = { ...upsertItem };
						}
						return item;
					});
				} else {
					return [...items, upsertItem];
				}

			}
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: state.current_persona?.personaAttributes?.map((att) => {
						if (att.id === personaAttribute.id) {
							att.items = validateItems(att.items);
						}

						return att;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};

		}
		case "DELETE_PERSONA_ATTRIBUTE_ITEM": {
			const { attributeId, attributeItemId } = action.request.data;
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: state.current_persona?.personaAttributes?.map((section) => {
						if (section.id === attributeId) {
							section.items = section.items.filter((item) => item.id !== attributeItemId);
						}
						return section;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case "UPDATE_PERSONA_ATTRIBUTE_ITEM_ORDER": {
			const itemsIds = action.request.data.newItemsOrder.split("|");
			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: state.current_persona?.personaAttributes?.map((section) => {
						if (section.id === action.request.data.attributeId) {
							section.items = itemsIds.map((id) => section.items.find((i) => i.id === id)).flat();
						}
						return section;
					}),
				},
			};
		}
		case success("UPDATE_PERSONA_ATTRIBUTE_ITEM_ORDER"):
			return {
				...state,
				current_persona: {
					...state.current_persona,

					lastModifiedOn: new Date().toISOString(),
				},
			};


		case "ORDER_LIST_ITEM": {
			const attributeIdStr_order = action.payload.attributeIdStr;
			const newOrder = action.payload.newOrder;

			return {
				...state,
				current_persona: {
					...state.current_persona,
					personaAttributes: state.current_persona?.personaAttributes?.map((section) => {
						if (section.id === attributeIdStr_order) {
							section.items = newOrder;
						}
						return section;
					}),
					lastModifiedOn: new Date().toISOString(),
				},
			};
		}
		case success("GET_PERSONA_USERS"): {
			const isPrint = action.meta.requestAction.params.isPrint;
			const userId = action.meta.requestAction.params.userId;

			const linkedUsers = Object.entries(responseData).map((user) => user[1]) || [];
			const user = linkedUsers.find((users) => users.id === userId && users.roleId === EMPTY_GUID) || {};
			if (hasAttribute(user, "roleName")) user.roleName = isPrint ? "viewer" : firstLetterUppercase(user?.roleName); // only to export pdf
			if (user?.roleName?.toLowerCase() === "viewer" && isPrint === false) toastWrapper("", "PERSONA_VIEWER_WARNING", { autoClose: 10000 });

			return {
				...state,
				current_linked_users: linkedUsers,
				currentUser: user,
			};
		}
		case success("SHARE_USERS_SINGLE_PERSONA"): {
			const linkedUsers1 = Object.entries(responseData).map((user) => user[1]) || [];

			return {
				...state,
				all_personas: state.all_personas?.map((persona) => {
					if (persona.id === requestData.personaId) {
						persona.authorizedUsers = linkedUsers1;
					}
					return persona;
				}),
				current_linked_users: linkedUsers1,
			};
		}
		case success("SHARE_USERS_MULTIPLE_PERSONA"):
			return {
				...state,
				all_personas: responseData || [],
			};
		case success("UPDATE_SHARE"): {
			const userId = state.current_linked_users.find((user) => user.id === requestData.userId);
			const teamId = state.current_linked_users.find((user) => user.id === requestData.teamId);
			const roleName = requestData.roleDetails.split("_")[0];
			const roleId = requestData.roleDetails.split("_")[1];
			const fullName = action.meta.requestAction.request?.params?.fullName || action.meta.requestAction.params?.fullName

			const newLinkUsers =
				userId === undefined && teamId === undefined
					? [...state.current_linked_users, { id: userId, roleName, roleId, fullName }]
					: state.current_linked_users.map((user) => (user.id === userId || user.id === teamId ? { ...user, roleName, roleId } : user));

			return {
				...state,
				all_personas: state.all_personas?.map((persona) => {
					if (persona.personaId === requestData.personaId) {
						return {
							...persona,
							authorizedUsers: newLinkUsers,
						};
					}
					return persona;
				}),
				current_linked_users: newLinkUsers,
			};
		}
		case success("UPDATE_UNSHARE"): {
			const { personaId, userId, teamId } = requestData;
			const id = userId || teamId;
			return {
				...state,
				all_personas: state.all_personas?.map((persona) => {
					if (persona.personaId === personaId) {
						return {
							...persona,
							authorizedUsers: persona.authorizedUsers.filter((user) => user.id !== id),
						};
					}
					return persona;
				}),
				current_linked_users: state.current_linked_users.filter((user) => user.id !== id),
			};
		}
		case success("UPDATE_PERSONA_LINKS_PERSONA"): {
			const { personaIdsToUnlink, personaIdsToLink } = requestData;
			let LinkedPersonas = JSON.parse(JSON.stringify(state.current_persona.linkedPersonas)) || [];
			const personaIdsToUnlinkArray = personaIdsToUnlink.split("|") || [];
			const personaIdsToLinkArray = personaIdsToLink.split("|") || [];
			if (personaIdsToUnlinkArray.length > 0) {
				LinkedPersonas = LinkedPersonas.filter((linkedPersona) => !personaIdsToUnlinkArray.includes(linkedPersona));
			}
			if (personaIdsToLinkArray.length > 0) {
				LinkedPersonas = [...new Set([...LinkedPersonas, ...personaIdsToLinkArray])];
			}

			return {
				...state,
				current_persona: {
					...state.current_persona,
					linkedPersonas: LinkedPersonas,
					lastModifiedOn: new Date().toISOString(),
				},
				all_personas: state.all_personas?.map((persona) => {
					if (persona.personaId === requestData.personaIdStr) {
						return {
							...persona,
							linkedPersonas: LinkedPersonas,
							lastModifiedOn: new Date().toISOString(),
						};
					}
					return persona;
				}),
			};
		}
		case "UPDATE_LOCK_PERSONA_TEMPLATE":
			return {
				...state,
				all_personas: state.all_personas?.map((persona) => {
					if (persona.personaId === action.request.data.modifiedPersona.personaId) {
						persona.isLocked = action.request.data.modifiedPersona.isLocked;
					}
					return persona;
				}),
			};
		case success("UPDATE_STATUS_PERSONA_TEMPLATE"):
			return {
				...state,
				current_persona: {
					...state.current_persona,
					templateStatus: requestData.modifiedPersona.templateStatus,
				},
				all_personas: state.all_personas?.map((persona) => {
					if (persona.personaId === requestData.modifiedPersona.personaId) {
						persona.templateStatus = requestData.modifiedPersona.templateStatus;
					}
					return persona;
				}),
			};
		case success("LINK_TAGS"): {
			const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
			if (recordType !== "cem_persona") return state;

			return {
				...state,
				all_personas: state.all_personas?.map((persona) => {
					if (recordIds.includes(persona.personaId)) {
						persona.tags = [...appendListsAndRemoveDuplicates(persona.tags, tagIds)];
					}
					return persona;
				}),
			};
		}
		case "RESET_PERSONAS_USERS": {
			return {
				...state,
				current_linked_users: null,
				currentUser: null,
			};
		}
		case success("GENERATE_PERSONA_SECTION_AI"): {
			const cardId = action.meta.requestAction.params.cardId;
			return {
				...state,
				ai: {
					...state.ai,
					[cardId]: responseData,
				},
			};
		}
		case success("ASK_ALEX"): {
			const cardId = action.meta.requestAction.params.cardId;

			return {
				...state,
				ai: {
					...state.ai,
					[cardId]: responseData,
				},
			};
		}
		case "RESET_ALEX_GENERATION": {
			const id = action.payload;
			return {
				...state,
				ai: {
					...state.ai,
					[id]: null,
				},
			};
		}
		default:
			return state;
	}
};

export default PersonaReducer;
