import { PickListMultipleTemplate, PickListSingleFilterTemplate, SolutionTypeFilter, dateBodyTemplate, dateFilterTemplate } from "features/grid-table/components/TableSettings";
import { selectGroup, selectQueryView, selectSelectedViewId, selectStatus, selectTag } from "@redux/memoizedSelectors";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { checkStyle } from "@utils/helpers";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Groups from "../../columns-components/Groups";
import { DatePicker, Description, Map, Owner, Stage, Status, Subject, displayBenefitCard } from "../../columns-components/ReusableListComponents";
import Tags from "../../columns-components/Tags";

export const GetSolutionColumns = (props) => {

    const context = props.context.split(".")[1];
    const { dataKey } = props?.contextData;

    const group = useSelector(selectGroup);
    const tag = useSelector(selectTag);
    const statusTypes = useSelector(selectStatus({entity:'solutions'}));
    const selectedViewId = useSelector(state => selectSelectedViewId(state, EntityList.Solution, context));
    const queryView = useSelector(state => selectQueryView(state, EntityList.Solution))?.getById(selectedViewId)?.columnSetting;
    const isSample = context === 'sampleData'

	const list = props?.contextData?.listData

	const journeyMapActionsFilters = list
	?.filter((obj, index) => list?.findIndex((item) => item?.mapId === obj?.mapId) === index)
	?.FormatFilterArray("mapId", "mapName");
	const solutionTypes = [
		{ value: 100000000, name: t("QUICK_WIN") },
		{ value: 100000002, name: t("NICE_TO_HAVE") },
		{ value: 100000001, name: t("COMPLEX_PROJ") },
		{ value: 100000003, name: t("TO_AVOID") },
    ];


    return {
        subject: {
            field: "subject",
            header: t("SOLUTION_NAME"),
            body: (ac) => Subject(ac, { route: props?.contextData?.route, allowClick: props?.contextData?.clickToOpen }),
            sortable: true,
            filterField: "subject",
            filter: true,
            showFilterOperator: false,
            style: checkStyle(queryView, "subject",isSample ? 'xxxl': "xxl"),
            className: "py-0",
        },
        mapId: {
            field: "mapId",
            filterField: "mapId",
            header: t("JOURNEY_MAP"),
            sortable: true,
            showAddButton: false,
            showFilterOperator: false,
            body: (ac) => Map(ac, { route: props?.contextData?.route, allowClick: props?.contextData?.clickToOpen }),
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "mapId", "m"),
            filterElement: (options) => PickListMultipleTemplate(options, journeyMapActionsFilters),
        },

        stageName: {
            field: "stageName",
            header: t("ASPX_172"),
            sortable: true,
            style: checkStyle(queryView, "stageName", "m"),
            filterField: "stageName",
            filter: true,
            showFilterOperator: false,
            body: (ac) => Stage(ac, { route: props.route, allowClick: props.contextData.clickToOpen }),
        },
        ownerId: {
            field: "ownerId",
            header: t("CS_35"),
            body: Owner,
            style: checkStyle(queryView, "ownerId", "s"),
        },
        statusId: {
            field: "statusId",
            filterField: "statusId",
            header: t("ASPX_222"),
            sortable: true,
            showFilterMatchModes: false,
            filter: true,
            style: checkStyle(queryView, "statusId", "l", "160px"),
            body: (action) => Status(action, dataKey, "solutions", props?.contextData?.listEditable, props?.contextData?.onChangeObject),
            filterElement: (options) => PickListMultipleTemplate(options, statusTypes),
        },
        solutionType: {
            field: "solutionType",
            header: t("JS_78"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            filter: true,
            filterField: "solutionType",
            style: checkStyle(queryView, "solutionType", "m"),

            body: (solution) => displayBenefitCard(solution),
            filterElement: (options) => SolutionTypeFilter(options, solutionTypes),
        },
        lastUpdated: {
            field: "lastUpdated",
            filterField: "lastUpdated",
            header: t("JS_72"),
            sortable: true,
            showFilterOperator: false,
            filter: true,
            style: checkStyle(queryView, "lastUpdated", "m"),
            dataType: "date",
            body: (solution) => dateBodyTemplate(solution.lastUpdated, "action"),
            filterElement: dateFilterTemplate,
        },
        groupIds: {
            field: "groupIds",
            filterField: "groupIds",
            header: t("GROUPS"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            filter: true,
            body: (action) => Groups(action, EntityList.Solution, context),

            style: checkStyle(queryView, "groupIds", "m"),

            filterElement: (options) => PickListSingleFilterTemplate(options, group, t("SELECT_GROUP")),
        },
        tagIds: {
            field: "tagIds",
            filterField: "tagIds",
            header: t("TAGS"),
            sortable: true,
            showFilterOperator: false,
            showFilterMatchModes: false,
            showAddButton: false,
            filter: true,
            body: (action) => Tags(action, EntityList.Solution, context),

            style: checkStyle(queryView, "tagIds", "xl"),

            filterElement: (options) => PickListSingleFilterTemplate(options, tag, t("SELECT_TAG")),
        },
        dueDateNew: {
            field: "dueDateNew",
            filterField: "dueDateNew",
            header: t("DUE_DATE"),
            sortable: true,
            showFilterOperator: false,
            dataType: "date",
            filter: true,
            style: checkStyle(queryView, "dueDateNew", "m"),
            body: (action) => DatePicker(action, dataKey, "dueDate", props?.contextData?.onChangeObject),
            filterElement: dateFilterTemplate,
        },
        description: {
			field: "description",
			sortable: true,
			header: t("ASPX_9"),
			dataType: "text",
			style: checkStyle(queryView, "description", "m"),
			body: (ac) => Description(ac),

		},
    };
};
