/* eslint-disable no-extend-native */
import assessments from "@Assets/images/EmptyListImages/no-assessments.svg";
import i18n from "@utils/i18n";
import { BenefitCard } from "features/solutions/solutionsComponents/BenefitCard.js";
import { t } from "i18next";
import { currentWorkspaceId } from "index";
import $ from "jquery";
import { toast } from "react-toastify";
import Xarrow from "react-xarrows";
import { IconDispatcher } from "shared/ui-basics/index";
import { v4 as uuid } from "uuid";
import AllIcons from "../Assets/images/IconsEmotionDictionaryKey.json";
import { CxGoalDirection, EntityList, InformationType, MapMode, SolutionType, StateTypeOption, StatusCode, UserType, urlOriginStatus } from "./optionsSets/OptionSets";
import "./prototypes.js";
import "./utils.scss";

const grid = 8;

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const MAX_SIZE_INPUT = 90;
export const MAX_SIZE_TEXT_BOX = 19999;

export const IconValidationEmotionLevel = (sentimentLevel) => {
	const roundedEmotion = Math.round(sentimentLevel);
	let type = "NOSENTIMENT";
	switch (roundedEmotion) {
		case 1:
			type = "TERRIBLE";
			break;
		case 2:
			type = "POOR";
			break;
		case 3:
			type = "GENERIC";
			break;
		case 4:
			type = "GOOD";
			break;
		case 5:
			type = "EXCELLENT";
			break;
		default:
			type = "NOSENTIMENT";
			break;
	}
	return type;
};
export const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	margin: "0 0 8px 0",

	...draggableStyle,
});
export const getItemStyleStage = (isDragging, draggableStyle, width) => ({
	userSelect: "none",
	position: "relative",
	height: "100%",

	...draggableStyle,
});
export const getItemStyleSwimlanes = (isDragging, draggableStyle, inMap) => ({
	userSelect: "none",
	// margin: "0 0px 4px 0",
	position: "relative",
	background: isDragging ? "white !important" : "transparent",
	borderTop: isDragging && inMap ? "1px solid #C4c4c4" : "1px solid transparent",
	...draggableStyle,
});
export const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "lightgrey" : "",
	padding: grid,
	width: "28%",
});
export const getListStyleStrategy = (isDraggingOver) => ({
	background: isDraggingOver ? "lightgrey" : "",
	padding: "4px",
	width: "50%",
});
export const getListInteractions = (isDraggingOver, width) => ({
	background: isDraggingOver ? "lightgrey" : "",
	// padding: grid,
	marginRight: "8px",
	width: widthValidation(width),
	// marginRight: "5px",
});
export const getListStyleStage = () => ({
	minHeight: "56px",
	alignItems: "center",
	height: "100%",
	paddingTop: "0.5rem",
	paddingBottom: "0.5rem",
});
export const getListStyleSwimLanes = () => ({
	width: "max-content",
	minWidth: "100%",
});
export const mapModeValidation = (mapMode) => {
	switch (mapMode) {
		case 100000000:
			return "ASPX_116";
		case 100000001:
			return "ASPX_117";
		case 100000002:
			return "ASPX_128";
		case 100000003:
			return "ASPX_256";
		default:
			return;
	}
};
export const StatusActionValidation = (status, color) => {
	switch (status) {
		case 100000000:
			return color ? "background-color:#F5F5F5; color:#92919D;" : "NEW_ACTION_STATUS";
		case 100000001:
			return color ? "background-color:#FFF8B9;border:1px solid #FBF5BD; color:#B4A200;" : "IN_PROGRESS";
		case 100000002:
			return color ? "background-color:#FD7171; color:#C20202;" : "STUCK";
		case 100000003:
			return color ? "background-color:#92919D; color:#333333;" : "DEFERRED";
		case 100000004:
			return color ? "background-color:#57FFD6; color:#028969;" : "DONE";
		default:
			return color ? "background-color:#57FFD6; color:#028969;" : "DONE";
	}
};
export const PriorityValidation = (priority, color) => {
	switch (priority) {
		case 0:
			return color ? "background-color:#CFFF47; border:1px solid #E6E6E6;" : "LOW";
		case 1:
			return color ? "background-color:#FCFF5C; border:1px solid #E6E6E6;" : "NORMAL";
		case 2:
			return color ? "background-color:#FF71E0; border:1px solid #E6E6E6;" : "HIGH";
		default:
			return color ? "background-color:#CFFF47; border:1px solid #E6E6E6;" : "LOW";
	}
};
export const isFutureValidation = (isFuture) => {
	if (isFuture) return "FUTURE";
	return "CURRENT";
};
export const isPrivateValidation = (isPrivate) => {
	if (isPrivate) return "Private";
	return "Public";
};
export const resort = (list, type, value) => {
	const result = Array.from(list);
	if (type === "mapMode") {
		value === "up"
			? result.sort((a, b) => {
				if (t(mapModeValidation(a[type])) < t(mapModeValidation(b[type]))) return -1;
				if (t(mapModeValidation(a[type])) > t(mapModeValidation(b[type]))) return 1;
				return 0;
			})
			: result.sort((a, b) => {
				if (t(mapModeValidation(a[type])) > t(mapModeValidation(b[type]))) return -1;
				if (t(mapModeValidation(a[type])) < t(mapModeValidation(b[type]))) return 1;
				return 0;
			});
	} else if (type === "isFuture") {
		value === "up"
			? result.sort((a, b) => {
				if (t(isFutureValidation(a[type])) < t(isFutureValidation(b[type]))) return -1;
				if (t(isFutureValidation(a[type])) > t(isFutureValidation(b[type]))) return 1;
				return 0;
			})
			: result.sort((a, b) => {
				if (t(isFutureValidation(a[type])) > t(isFutureValidation(b[type]))) return -1;
				if (t(isFutureValidation(a[type])) < t(isFutureValidation(b[type]))) return 1;
				return 0;
			});
	}
	switch (result?.length && typeof result[0][type]) {
		case "string":
			value === "up"
				? result?.sort((a, b) => {
					if (a[type]?.toLowerCase() < b[type]?.toLowerCase()) return -1;
					if (a[type]?.toLowerCase() > b[type]?.toLowerCase()) return 1;
					return 0;
				})
				: result?.sort((a, b) => {
					if (a[type]?.toLowerCase() > b[type]?.toLowerCase()) return -1;
					if (a[type]?.toLowerCase() < b[type]?.toLowerCase()) return 1;
					return 0;
				});
			break;
		case "number":
			value === "up"
				? result?.sort((a, b) => {
					return a[type] - b[type];
				})
				: result?.sort((a, b) => {
					return b[type] - a[type];
				});
			break;
		case "object":
			value === "up"
				? result?.sort((a, b) => {
					return a.linkedCjms?.length - b.linkedCjms?.length;
				})
				: result?.sort((a, b) => {
					return b.linkedCjms?.length - a.linkedCjms?.length;
				});
			break;
		default:
	}

	return result;
};
export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
export const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};
export const isToday = (someDate) => {
	const today = new Date();
	return someDate?.split("T")[0] === today.toISOString().split("T")[0];
};
export const encode = (str) => {
	try {
		return str && window.btoa(unescape(encodeURIComponent(str)));
	} catch (e) {
		return str;
	}
};
export const decode = (str) => {
	try {
		return str && decodeURIComponent(escape(window.atob(str)));
	} catch (e) {
		return str;
	}
};
export const widthValidation = (width) => {
	if (width <= 0) return 272;
	return width;
};

export function importAll(r) {
	let images = {};
	r.keys().forEach((item, index) => {
		images[item.replace("./", "")] = r(item);
	});
	return images;
}
export const IconValidation = (sentimentLevel) => {
	const roundedEmotion = Math.round(sentimentLevel);
	switch (roundedEmotion) {
		case 1:
			return "icon-face-terrible";
		case 2:
			return "icon-face-poor";
		case 3:
			return "icon-face-generic";
		case 4:
			return "icon-face-good";
		case 5:
			return "icon-face-excellent";
		default:
			return "icon-face-noSentiment";
	}
};
export const firstLetterUppercase = (str) => {
	if (!str) return "";
	return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const GetChannelPredefinedImage = (channelName) => {
	var imageName = "";
	switch (channelName) {
		case "Store":
		case "Point of sale":
			imageName += "point-of-sale";
			break;
		case "Phone":
			imageName += "phone";
			break;
		case "Email":
			imageName += "email";
			break;
		case "Twitter":
			imageName += "twitter";
			break;
		case "Web":
		case "Website":
			imageName += "website";
			break;
		case "Friends":
		case "Word of mouth":
			imageName += "word-of-mouth";
			break;
		case "Mobile":
		case "Mobile device":
			imageName += "mobile-device";
			break;
		case "Chat":
			imageName += "chat";
			break;
		case "Direct Mail":
		case "Letter":
			imageName += "letter";
			break;
		case "Broadcast":
		case "Broadcast media":
			imageName += "broadcast-media";
			break;
		case "Fax":
			imageName += "fax";
			break;
		case "WhatsApp":
			imageName += "whatsapp";
			break;
		case "Self Service":
		case "Kiosk (self-service)":
			imageName += "self-service";
			break;
		case "Print":
			imageName += "print";
			break;
		case "SMS":
			imageName += "sms";
			break;
		case "Share":
			imageName += "social-media";
			break;
		case "Facebook":
			imageName += "facebook";
			break;
		case "LinkedIn":
			imageName += "linkedin";
			break;
		case "Portal":
		case "Customer portal":
			imageName += "portal";
			break;
		case "Ads":
		case "Web ads":
			imageName += "web-ads";
			break;
		case "Advertisement":
			imageName += "advertisement";
			break;
		case "Instagram":
			imageName += "instagram";
			break;
		case "Video":
		case "Video conference":
			imageName += "video-conference";
			break;
		case "Calendar":
		case "Event":
			imageName += "event";
			break;
		case "File":
			imageName += "file";
			break;
		case "Social Media":
		case "Social Network":
			imageName += "social-media";
			break;
		case "IVR":
			imageName += "phone-volume";
			break;
		case "Chatbot":
			imageName += "comment-alt-dots";
			break;
		case "Agent":
			imageName += "briefcase-alt";
			break;
		case "User Guide":
			imageName += "book-open";
			break;
		case "Delivery":
			imageName += "truck";
			break;
		case "Generic":
			imageName += "circle";
			break;
		case "YouTube":
			imageName += "youtube";
			break;
		case "TikTok":
			imageName += "tiktok";
			break;
		case "Tablet":
			imageName += "tablet";
			break;
		case "Search Engine":
			imageName += "search";
			break;
		case "3rd Party":
		case "Third Party":
			imageName += "third-party";
			break;
		case "Article":
			imageName += "article";
			break;
		case "Blog":
			imageName += "blog";
			break;
		case "ATM":
			imageName += "atm";
			break;
		case "Branch":
			imageName += "branch";
			break;
		case "Call Center":
			imageName += "call-center";
			break;
		case "Wallet":
			imageName += "wallet";
			break;
		case "Doctor":
			imageName += "doctor";
			break;
		case "Nurse":
			imageName += "nurse";
			break;
		case "Medical center":
			imageName += "medical-center";
			break;
		case "Laboratory":
			imageName += "laboratory";
			break;
		case "Patient":
			imageName += "patient";
			break;
		case "Pharmacy":
			imageName += "pharmacy";
			break;
		case "Medical examination":
			imageName += "medical-examination";
			break;
		case "Shop":
			imageName += "shop";
			break;
		case "Shopkeeper":
			imageName += "shopkeeper";
			break;
		case "Cashier":
			imageName += "cashier";
			break;
		case "Brochure":
			imageName += "brochure";
			break;
		case "Teams":
			imageName += "teams";
			break;
		case "Skype":
			imageName += "skype";
			break;
		case "Messenger":
			imageName += "facebook-messenger";
			break;
		case "Cart":
			imageName += "shopping-cart";
			break;

		case "Payment Gateway":
			imageName += "payment-gateway";
			break;
		case "PayPal":
			imageName += "paypal";
			break;
		case "Application":
			imageName += "application";
			break;
		case "Face to Face":
			imageName += "face-to-face";
			break;
		case "Medical device":
			imageName += "medical-device";
			break;
		case "HR":
			imageName += "hr";
			break;
		case "IT":
			imageName += "it";
			break;
		case "Work Station":
			imageName += "work-station";
			break;
		case "Manager":
			imageName += "manager";
			break;
		case "Reception":
			imageName += "reception";
			break;
		case "Office":
			imageName += "office";
			break;
		case "Meeting Room":
			imageName += "meeting-room";
			break;
		case "Paycheck":
			imageName += "paycheck";
			break;
		case "Intranet":
			imageName += "intranet";
			break;
		case "Training":
			imageName += "training";
			break;
		case "Employee Portal":
			imageName += "employee-portal";
			break;
		case "Press Release":
			imageName += "press-release";
			break;
		case "Comparison sites":
			imageName += "comparison-sites";
			break;
		case "Reviews":
			imageName += "star-half-stroke";
			break;
		case "Forum":
			imageName += "message-captions";
			break;
		default:
			break;
	}
	if (imageName === "") imageName = "question-circle";

	return imageName + ".svg";
};

export const daysDifferenceCalculator = (date1, date2) => {
	const timeDiff = date2?.getTime() - date1?.getTime();
	const daysDiff = timeDiff / (1000 * 3600 * 24);
	return daysDiff;
};

export const InteractionArrayValidations = (stages, personaIdStr, cjmData) => {
	const Interaction = stages?.map((stage) =>
		Object.entries(cjmData?.[personaIdStr]?.[stage.id] || {}).flatMap(([key, personasInteractions]) => personasInteractions?.map((int) => ({ ...int, stageId: stage.id, touchPointId: key })))
	);

	const sortedInteraction = Interaction?.map((group) => group?.sort((a, b) => a.order - b.order));

	return sortedInteraction || [];
};
export const pictureValidationPersona = (persona, sessionId, forceRender = false) => {
	const stdImage = persona?.standardImageNumber !== 0 && persona?.standardImageNumber !== -1 ? persona?.standardImageNumber : null;
	if (stdImage) return require(`../Assets/images/PersImg${persona?.standardImageNumber}.jpg`).default;
	if (persona?.personaId !== undefined) {
		return `api/persona/getCustomImage?personaIdStr=${persona?.personaId}&sid=${sessionId}&workspaceId=${currentWorkspaceId}` + (forceRender ? `&${Math.random()}=true` : "");
	}

	return require(`../Assets/images/persona-plshldr.jpg`).default;
};
export const pictureValidation = (icon, sessionId) => {
	if (icon?.isCustom) return `api/touchpoint/getCustomImage?channelIdStr=${icon?.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}`;
	return touchPointIconValidation(icon?.name);
};
export const touchPointIconValidation = (name) => {
	const touchPoint = importAll(require.context("@Assets/images/map-touchpoints/", false, /\.(png|jpe?g|svg)$/));
	const keyIcon = GetChannelPredefinedImage(name);
	const icon = touchPoint[keyIcon]?.default;
	return icon;
};
export const StickerIconValidation = ({ number, type }) => {
	const emotions = importAll(require.context("../Assets/images/map-emotions", false, /\.(png|jpe?g|svg)$/));
	const srcIcons = importAll(require.context("../Assets/images/map-icons", false, /\.(png|jpe?g|svg)$/));

	if (type === 100000001) {
		return emotions[AllIcons[Number(number)]]?.default;
	} else return srcIcons[AllIcons[Number(number)]]?.default;
};

export const isSubscriptionAllowed = (subscription, accessAuthorized) => {
	const full = ["Standard", "SharedExpert", "Expert", "Enterprise"];
	const regular = ["SharedExpert", "Expert", "Enterprise"];
	const limited = ["Enterprise"];

	switch (accessAuthorized) {
		case "full":
			return full.includes(subscription);
		case "regular":
			return regular.includes(subscription);
		case "limited":
			return limited.includes(subscription);
		default:
			return true;
	}
};

export const isUserAllowed = (userType, action) => {
	const userCode = 100000000;
	const adminCode = 100000001;
	const companyAdminCode = 100000002;
	let res = false;
	const usertype = Number(userType);

	if (usertype === companyAdminCode) return true;
	switch (action) {
		case "manage-access":
			if (usertype === adminCode) res = true;
			break;
		case "template":
			if (usertype === adminCode) res = true;
			break;
		case "user":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "trash":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "archive":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "settings":
			if (usertype === adminCode) res = true;
			break;
		case "admin":
			if (usertype === adminCode) res = true;
			break;
		case "delete-library":
			if (usertype === adminCode) res = true;
			break;
		case "actions-dropdown":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "edit-persona":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "edit-folder":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "edit-cjm":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "edit-action-list":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "create-new-solution":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "edit-strategy":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "edit-program":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;
		case "table":
			if ([adminCode, userCode].includes(usertype)) res = true;
			break;

		default:
			return res;
	}

	return res;
};

export const RestrictUserAccess = (role, types) => {
	let PrivilegesAllowed = types;
	if (typeof types === "object") {
		PrivilegesAllowed = types?.map((type) => type.toLowerCase());
	}
	if (role) return PrivilegesAllowed?.includes(role?.toLowerCase());
	return false;
};

export const isReadOnly = (userType) => userType === 100000003;

export const SwimlaneTypeValidation = (type) => {
	switch (type) {
		case "Text":
			return 100000000;
		case "Image":
			return 100000001;
		case "ListItems":
			return 100000002;
		case "ActionsList":
			return 100000003;
		case "Opportunity":
			return 100000004;
		case "Feelings":
			return 100000006;
		case "Touchpoints":
			return 100000008;
		case "Systems":
			return 100000009;
		case "Departments":
			return 100000010;
		case "Timing":
			return 100000011;
		default:
			return 100000000;
	}
};

export const emailValidation = (email) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(String(email).toLowerCase());
};

export const toastWrapper = async (promise, action, aParams = {}) => {
	const params = {
		hideProgressBar: true,
		autoClose: 4000,
		...aParams,
	};

	switch (action) {
		case "CREATE_NO_SUCCESS_TOAST":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("CREATING"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "CREATE":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("CREATING"),
					success: params.success ? params.success : t("SUCCESS_CREATE"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "UPDATE":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("UPDATING") + "...",
					success: params.success ? params.success : t("RECORD_UPDATE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "SAVE":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("SAVING") + "...",
					success: params.success ? params.success : t("SAVE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "COPY":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("COPYING") + "...",
					success: params.success ? params.success : t("COPY_SUCCESS"),
					error: {
						render() {
							return t("JS_60");
						},
					},
				},
				params
			);
		case "DELETE":
			return await toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("DELETE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_DELETE_SUCCESS":
			return await toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("BULK_ACTIONS_DELETE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_DELETE_PARTIAL":
			return await toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("BULK_ACTIONS_DELETE_PARTIAL"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "RESTORE":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("RESTORING") + "...",
					success: params.success ? params.success : t("RESTORE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "ARCHIVE":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("ARCHIVING") + "...",
					success: params.success ? params.success : t("ARCHIVE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_ARCHIVE_SUCCESS":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("ARCHIVING") + "...",
					success: params.success ? params.success : t("BULK_ACTIONS_ARCHIVE_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_ARCHIVE_PARTIAL":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("ARCHIVING") + "...",
					success: params.success ? params.success : t("BULK_ACTIONS_ARCHIVE_PARTIAL"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "LINK":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("LINKING") + "...",
					success: params.success ? params.success : t("LINK_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "LINK_RECORD":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("LINKING_RECORD") + "...",
					success: params.success ? params.success : t("LINK_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "RELINK":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("RELINKING") + "...",
					success: params.success ? params.success : t("LINK_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "UNLINK":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("UNLINKING_RECORD"),
					success: params.success ? params.success : t("UNLINK_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "EXPORT":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("JS_178"),
					success: params.success ? params.success : t("EXPORT_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "EXPORT_START":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("JS_178"),
				},
				params
			);
		case "EXPORT_PDF":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("JS_178"),
					success: params.success ? params.success : t("EXPORT_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "REFRESH":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("REFRESHING"),
					success: params.success ? params.success : t("DATA_REFRESH_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "SEND":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("SENDING"),
					success: params.success ? params.success : t("MESSAGE_SENT_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "MAP_VIEWER_WARNING":
			return toast.info(t("MAP_VIEWER_WARNING"), params);
		case "ACTION_VIEWER_WARNING":
			return toast.info(t("ACTION_VIEWER_WARNING"), params);
		case "OPPORTUNITY_VIEWER_WARNING":
			return toast.info(t("OPPORTUNITY_VIEWER_WARNING"), params);
		case "SOLUTION_VIEWER_WARNING":
            return toast.info(t("SOLUTION_VIEWER_WARNING"), params);
        case "PERSONA_VIEWER_WARNING":
			return toast.info(t("PERSONA_VIEWER_WARNING"), params);
		case "ERROR":
			return toast.promise(
				promise,
				{
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "CX_STRATEGY_VIEWER_WARNING":
			return toast.info(t("CX_STRATEGY_VIEWER_WARNING"), params);
		case "SAVE_MAP_AS_TEMPLATE_SUCCESS":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("SAVING") + "...",
					success: params.success ? params.success : t("SAVE_MAP_AS_TEMPLATE_SUCCESS"), // t('COPY_SUCCESS'),
					error: {
						renfer() {
							return t("JS_60");
						},
					},
				},
				params
			);
		case "SAVE_PERSONA_AS_TEMPLATE_SUCCESS":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("SAVING") + "...",
					success: params.success ? params.success : t("SAVE_PERSONA_AS_TEMPLATE_SUCCESS"), // t('COPY_SUCCESS'),
					error: {
						renfer() {
							return t("JS_60");
						},
					},
				},
				params
			);
		case "DELETE-MANY-PERSONA":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("DELETE_MANY_PERSONA_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_DELETE_PERSONAS_SUCCESS":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("DELETE_MANY_PERSONA_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_ARCHIVE_PERSONAS_SUCCESS":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("ARCHIVING") + "...",
					success: params.success ? params.success : t("ARCHIVE_MANY_PERSONA_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_ARCHIVE_PERSONAS_PARTIAL":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("ARCHIVING") + "...",
					success: params.success ? params.success : t("BULK_ARCHIVE_PERSONAS_PARTIAL"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "BULK_DELETE_PERSONAS_PARTIAL":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("BULK_DELETE_PERSONAS_PARTIAL"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "DELETE-SWIMLANE":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DELETING") + "...",
					success: params.success ? params.success : t("DELETE_SWIMLANE_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "ACTIVATE_USER":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("ACTIVATING") + "...",
					success: params.success ? params.success : t("ACTIVATE_USER_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "CX_PROGRAM_VIEWER_WARNING":
			return toast.info(t("CX_PROGRAM_VIEWER_WARNING"), params);
		case "WARNING_SELECT_PERSONA":
			return toast.info(t("WARNING_PERSONA"), params);

		case "DEACTIVATE_USER":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("DEACTIVATING") + "...",
					success: params.success ? params.success : t("DEACTIVATE_USER_SUCCESS"),
					error: {
						renfer() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "RESTORE_VERSION":
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t("RESTORE_VERSION") + "...",
					success: params.success ? params.success : t("RESTORE_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
		case "NOTIFICATION_BY_EMAIL":
			return toast.success(t("NOTIFICATION_BY_EMAIL"), params);

		default:
			return toast.promise(
				promise,
				{
					pending: params.pending ? params.pending + "..." : t(action) + "...",
					success: params.success ? params.success : t(action+"_SUCCESS"),
					error: {
						render() {
							return t("JS_13");
						},
					},
				},
				params
			);
	}
};


export const isEllipsisActiveFun = (id) => {
	const e = $("#input" + id);
	return e[0]?.offsetWidth < e[0]?.scrollWidth;
};
export const GetUserColor = (userFullName) => {
	if (userFullName === null || userFullName.length === 0) return "rgb(255, 242, 179)";
	var lettersTotal = 0;
	for (var i = 0; i < userFullName.length; i++) {
		lettersTotal += userFullName.charCodeAt(i);
	}
	return "hsl(" + (lettersTotal % 360) + ", 100%, 85%)";
};
const palette = ["#F96209", "#18AEAD", "#0078E7", "#60C1EF", "#FDD03E", "#3F3D56", "#465B65"];
export const stringToColor = (str, index) => {
	if (index < palette.length) {
		return palette[index];
	}
	var lettersTotal = 0;
	for (var i = 0; i < str.length; i++) {
		lettersTotal += str.charCodeAt(i);
	}
	return "hsl(" + (lettersTotal % 360) + ", 80%, 40%)";
};
export const hexToHSL = (hex, saturation, lightness) => {
	// Remove the '#' if it's included
	hex = hex.replace("#", "");

	// Convert hex to RGB
	var r = parseInt(hex.substring(0, 2), 16) / 255;
	var g = parseInt(hex.substring(2, 4), 16) / 255;
	var b = parseInt(hex.substring(4, 6), 16) / 255;

	// Find the minimum and maximum values of RGB
	var max = Math.max(r, g, b);
	var min = Math.min(r, g, b);

	// Check for grayscale
	if (max === min) {
		return [0, 0, lightness];
	}

	// Calculate the hue and saturation
	var delta = max - min;
	var hue;

	if (max === r) {
		hue = (g - b) / delta + (g < b ? 6 : 0);
	} else if (max === g) {
		hue = (b - r) / delta + 2;
	} else {
		hue = (r - g) / delta + 4;
	}

	hue *= 60;

	// Ensure hue is within the range [0, 360]
	if (hue < 0) {
		hue += 360;
	}
	return `hsl(${Math.round(hue)}, ${saturation}%, ${lightness}%)`;
};

export const defaultTypeColorValidation = (type) => {
	switch (type) {
		case "issues":
			return "#51b6ff";
		case "kpIs":
			return "#449ad9";
		case "processes":
			return "#a1d4f9";
		default:
	}
};
export const IconTypeValidation = (type) => {
	switch (type) {
		case "issues":
			return 12;
		case "kpIs":
			return 11;
		case "processes":
			return 13;
		case "pros":
			return 4;
		case "cons":
			return 2;
		case "insights":
			return 3;
		case "actions":
			return 26;
		default:
	}
};
export const GetActiveCJM = (cjmIds, all_maps) => {
	return cjmIds?.filter((cjmId) => {
		const relevantMap = all_maps?.find((cjm) => cjm.cjmId === cjmId);
		return relevantMap?.statusCode === StatusCode.Active;
	});
};
export const getActivePersona = (personas, showSampleData) => {
	return personas.filter((persona) => {
		if (showSampleData) return persona.statusCode === StatusCode.Active && !persona.isTemplate;
		else return !persona.isSample && persona.statusCode === StatusCode.Active && !persona.isTemplate;
	});
};
export const StripHtml = (html) => {
	var tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
};

export const colorCheck = (colorCode) => {
	const customColor = colorCode?.charAt(0) === "#" ? colorCode : colorCode?.split(":")[1];
	return customColor !== undefined ? customColor.toUpperCase().split(";")[0] : "";
};

export const FilterItemsByStatus = (item, currentRoute, activeRoute, showSampleData, all_maps) => {
	const isTrash = currentRoute === "/trash";
	const isArchive = currentRoute === "/archive";
	const isPage = ["/EditCxProgram"].includes(currentRoute);
	const isActive = currentRoute === activeRoute;
	if (isPage) {
		if (showSampleData !== undefined) {
			if (showSampleData) return item?.statusCode === StatusCode.Active;

			return item?.isSample === false && item?.statusCode === StatusCode.Active;
		}
	}
	if (isActive) {
		if (showSampleData) return item?.statusCode === StatusCode.Active;
		if (["/opportunity", "/cx-actions", "/solutions"].includes(currentRoute) && all_maps) return item?.statusCode === StatusCode.Active && !item?.isSample && attachedToSampleCheck(item, showSampleData, all_maps)

		return item?.statusCode === StatusCode.Active && !item?.isSample;
	}
	if (isTrash) return item?.statusCode === StatusCode.Trashed;
	if (isArchive) return item?.statusCode === StatusCode.Archived;
	return item;
};
const attachedToSampleCheck = (opp, showSampleData, all_maps) => {
	if (showSampleData) return true;
	const map = all_maps?.find((m) => m.cjmId === opp.mapId);
	if (map && !showSampleData) {
		return !map.isSample;
	}
	return true;
};

export const isMapHasLinkedMaps = (directLinks, phases, stages, allActiveMaps) => {
	let result = false;
	const filteredDirectLinks = allActiveMaps?.filter((map) => directLinks?.includes(map?.cjmId));
	if (filteredDirectLinks?.length > 0) return true;

	phases?.forEach((phase) => {
		const filteredPhaseLinks = allActiveMaps?.filter((map) => phase?.linkedCjms?.includes(map?.cjmId));
		if (filteredPhaseLinks?.length > 0) result = true;
	});

	if (result) return result;

	stages?.forEach((stage) => {
		const filteredStageLinks = allActiveMaps?.filter((map) => stage?.linkedCjms?.includes(map?.cjmId));
		if (filteredStageLinks?.length > 0) result = true;
	});

	return result;
};

export const calculateYScore = (solution) => {
	if (solution?.ratings)
		return solution.ratings["impactCustomer"]?.rating + solution.ratings["customerBenefit"]?.rating + solution.ratings["revenueIncrease"]?.rating + solution.ratings["impactBrand"]?.rating;
	return 0;
};

export const calculateXScore = (solution) => {
	if (solution?.ratings)
		return solution.ratings["cost"]?.rating + solution.ratings["implComplexity"]?.rating + solution.ratings["engagementEffortRanking"]?.rating + solution.ratings["implDuration"]?.rating;
	return 0;
};

export const calculatesolutionType = (solution) => {
	// Rating Rules
	// If Sum left column >  10 and Sum Right column <= 10 then Quick Win
	// If Sum 0 < left column <= 10 and Sum Right column <= 10 then Nice to Have
	// If Sum left column >  10 and Sum Right column >  10 || left column ===  0 && Right column > 0 then Complex Project
	// If Sum left column <= 10 and Sum Right column >  10 then To Avoid
	return solutionTypeByCoordinates(calculateXScore(solution), calculateYScore(solution));
};

export const solutionTypeByCoordinates = (x, y) => {
	if (y === 0 && x === 0) return -1;
	if (y > 10 && x <= 10) return SolutionType.QuickWin;
	if (y > 0 && y <= 10 && x <= 10) return SolutionType.NiceToHave;
	if (y >= 10 && x > 10) return SolutionType.ComplexProject;
	if ((y <= 10 && x > 10) || (y === 0 && x > 0)) return SolutionType.ToAvoid;
};

export const oppPointColorByCoordinates = (x, y) => {
	if (y === 0 && x === 0) return "#c4c4c4";
	return "#0078E7"
};

export const CxValidation = (type) => {
	switch (type) {
		case "opportunity":
			return 100000000;
		case "cxActions":
			return 100000001;
		case "solutions":
			return 100000002;
		case "customerJourney":
			return 100000003;
		case "project":
			return 100000004;
		default:
			return 0;
	}
};

export const filterMapsByUserRole = ({ selectedMaps, currentUserId, isSubscriptionStandard, userRoles = ["owner"] }) => {
	const filteredIds = [];

	selectedMaps?.forEach((map) => {
		const currentUserRole = map?.authorizedUsers?.find((user) => user.id === currentUserId && user.roleId === EMPTY_GUID)?.roleName;
		if (isSubscriptionStandard || (userRoles.includes(currentUserRole?.toLowerCase()) && !map?.isSample)) {
			filteredIds.push(map.cjmId);
		}
	});

	return filteredIds.map((id) => id).join("|");
};

export const filterPersonasByLinkedMaps = ({ allMaps, selectedPersonas, currentUserId, isSubscriptionStandard }) => {
	const filteredIds = [];
	selectedPersonas?.forEach((persona) => {
		const currentUserRole = persona?.authorizedUsers?.find((user) => user.id === currentUserId && user.roleId === EMPTY_GUID)?.roleName;
		const linkedJourneyMapActive = GetActiveCJM(persona.linkedCjms, allMaps);
		if (linkedJourneyMapActive?.length === 0 && (isSubscriptionStandard || currentUserRole?.toLowerCase() === "owner" || persona?.isTemplate)) filteredIds.push(persona.personaId);
	});

	return filteredIds.map((id) => id).join("|");
};

export const filterPersonasByUserRole = ({ selectedPersonas, currentUserId }) => {
	const filteredIds = [];
	selectedPersonas?.forEach((selectedItem) => {
		const currentUserRole = selectedItem?.authorizedUsers?.find((user) => user.id === currentUserId && user.roleId === EMPTY_GUID)?.roleName;
		if (currentUserRole?.toLowerCase() === "owner") filteredIds.push(selectedItem.personaId);
	});

	return filteredIds.map((id) => id).join("|");
};

export const filterItemsByUserRole = ({ selectedItems, currentUserId }) => {
	const filteredIds = [];
	selectedItems?.forEach((selectedItem) => {
		const currentUserRole = selectedItem?.authorizedUsers?.find((user) => user.id === currentUserId && user.roleId === EMPTY_GUID).roleName;
		if (currentUserRole?.toLowerCase() === "owner") filteredIds.push(selectedItem.id);
	});

	return filteredIds.map((id) => id).join("|");
};

export const filterStrategiesByUserRole = (allStrategies, selectedStrategies, currentUserId) => {
	const filteredIds = [];
	selectedStrategies?.forEach((selectedStrategy) => {
		const currentStrategy = allStrategies?.filter((strategy) => strategy.id === selectedStrategy.id)[0];
		const currentUserRole = currentStrategy?.authorizedUsers?.find((user) => user.id === currentUserId && user.roleId === EMPTY_GUID).roleName;
		if (currentUserRole?.toLowerCase() === "owner") filteredIds.push(selectedStrategy.id);
	});

	return filteredIds.map((id) => id).join("|");
};

export const filterItemsByUserMapRole = ({ allItems, selectedItems, currentUserId, userRoles = ["owner"] }) => {
	const filteredIds = [];
	selectedItems?.forEach((selectedItem) => {
		const relatedMap = allItems?.find((map) => map.cjmId === selectedItem.mapId);
		let currentUserRole = relatedMap?.authorizedUsers?.find((user) => user.id === currentUserId && user.roleId === EMPTY_GUID)?.roleName;
		if (!relatedMap && selectedItem.mapId === EMPTY_GUID) currentUserRole = "owner";
		if (userRoles.includes(currentUserRole?.toLowerCase())) filteredIds.push(selectedItem.id);
	});

	return filteredIds?.map((id) => id).join("|");
};

export function ChangeLanguages(lcid) {
	switch (lcid) {
		case "1033":
			i18n?.changeLanguage("en");
			break;
		case "1036":
			i18n?.changeLanguage("fr");
			break;
		case "1031":
			i18n?.changeLanguage("de");
			break;
		case "1043":
			i18n?.changeLanguage("nl");
			break;
		case "3082":
			i18n?.changeLanguage("es");
			break;
		default:
	}
}

export const supportedLanguages = [
	{ name: "English", value: "en", lcid: 1033 },
	{ name: "Français", value: "fr", lcid: 1036 },
	{ name: "Español", value: "es", lcid: 3082 },
	{ name: "Deutsch", value: "de", lcid: 1031 },
	{ name: "Nederlands", value: "nl", lcid: 1043 },
];

export const CustomXarrow = ({ color, startId, endId }) => {
	return (
		<Xarrow
			headSize={0}
			lineColor={color}
			headColor={color}
			path="grid"
			strokeWidth={2}
			startAnchor={"right"}
			endAnchor={"left"}
			start={startId} //can be react ref
			end={endId} //or an id
			style={{ zIndex: 0 }}
		//zIndex={3}
		//zIndex={3}
		/>
	);
};

export const getNewPersonaObject = ({ personaID, type, sectionId }) => {
	return {
		colorCode: "",
		description: "",
		descriptionHtml: "",
		id: sectionId,
		image64: "",
		imageType: "",
		name: "",
		personaID: personaID,
		type: type,
		sectionId: sectionId,
		itemsOrder: "",
		items: [],
	};
};
export function getNextPosition(items, itemHeight) {
	let maxY = 0;

	items.forEach((item) => {
		maxY = Math.max(maxY, item.y + item.h);
	});

	const newRow = Math.ceil(maxY / itemHeight);
	const newX = newRow % 3;
	const newY = newRow * itemHeight;

	return { x: newX, y: newY };
}

export function findById(arr, id, attribute = "id") {
	return arr?.find((x) => x?.[attribute] === id) || {};
}

export const filterUnSelect = (select, linked, idName) => {
	const toLink = select?.map((x) => x[idName]);
	const unSelect = [];
	for (var i = 0; i < linked?.length; i += 1) {
		if (toLink?.indexOf(linked[i]) === -1) {
			unSelect.push(linked[i]);
		}
	}
	return unSelect;
};
export const sortType = (e) => {
	if (e.order === 1) {
		return e.data.sort((a, b) => (a.mapType > b.mapType ? -1 : 1));
	}
	if (e.order === -1) {
		return e.data.sort((a, b) => (b.mapType > a.mapType ? -1 : 1));
	}
};

export const isCurrentInteractionInRoute = (interaction, currentStageInteractions, calculatedRouteAttrib, forcedRouteAttrib) => {
	// Check if the current interaction should be part of the routes
	// If it is forced, then yes (because only 1 interaction per stage can be forced)
	// If it is calculated, then yes if no other interactions in the stage is forced
	if (interaction[forcedRouteAttrib]) return true;
	else if (interaction[calculatedRouteAttrib]) {
		let res = true;
		currentStageInteractions?.forEach((int) => {
			if (interaction[calculatedRouteAttrib] && int[forcedRouteAttrib]) {
				res = false;
			}
		});
		return res;
	}
	return false;
};

export const flattenDictionary = (dict, resultSet, parentKeys = []) => {
	for (let key in dict) {
		let currentKeys = [...parentKeys, key];
		if (Array.isArray(dict[key])) {
			dict[key].forEach((item) => {
				let swimlaneId = currentKeys[0];
				let stageId = currentKeys[1];
				let attributeId = currentKeys[2] === "comments" ? null : currentKeys[2];
				resultSet.add({ ...item, keys: { swimlaneId, stageId, attributeId } });
			});
		} else if (typeof dict[key] === "object") {
			flattenDictionary(dict[key], resultSet, currentKeys);
		}
	}
};

export const getCommentType = (type, id) => {
	if (id === "StageDescription") return 10;
	if (id === "Insights") return 9;
	if (id === "Processes") return 13;
	if (id === "KPIs") return 11;
	if (id === "Issues") return 12;
	if (id === "StageHeaders") return 22; //if phase it's 23

	switch (type) {
		case InformationType.Text:
			return 5;
		case InformationType.Image:
			return 14;
		case InformationType.ListItems:
			return 16;
		case InformationType.Opportunity:
			return 20;
		case InformationType.KPIs:
			return 19;
		case InformationType.AdvancedInteractions:
		case InformationType.Touchpoints:
			return 1;
		case InformationType.Feelings:
			return 8;
		case InformationType.ActionsList:
			return 21;
		case InformationType.AdvancedCons:
			return 2;
		case InformationType.AdvancedPros:
			return 4;
		case InformationType.AdvancedActions:
			return 26;
		case InformationType.AdvancedInsights:
			return 3;
		case InformationType.Timing:
			return 24;
		case InformationType.Departments:
			return 18;
		case InformationType.Systems:
			return 17;
		default:
			return 0;
	}
};
export function findMovedElement(oldArray, newArray) {
	for (let i = 0; i < oldArray.length; i++) {
		for (let j = 0; j < oldArray[i].length; j++) {
			if (!newArray[i][j] || oldArray[i][j].id !== newArray[i][j].id) {
				return oldArray[i][j];
			}
		}
	}
}
export const detectImageExtension = (image) => {
	const extensions = ["png", "svg", "jpg", "jpeg"];
	const extension = extensions.find((ext) => image.includes(ext));
	return extension || null;
};

export const isFullUsersLicenseAvailable = (users, contracts, subscriptionPlanValue) => {
	// Check number of usersNumber (user / admin / company admin) are left
	let count = 0;
	users?.forEach((user) => {
		if (user.subscriptionPlan === subscriptionPlanValue && isFullUser(user.userType) && user.isActive) count++;
	});

	const max = contracts?.find((contract) => contract.subscriptionPlanValue === subscriptionPlanValue)?.usersNumber;
	return { count, answer: count < max };
};

export const getFullUsersLicenseAvailable = (users, subscriptionPlanValue) => {
	// Check number of usersNumber (user / admin / company admin) are left
	let count = 0;
	users?.forEach((user) => {
		if (user.subscriptionPlan === subscriptionPlanValue && isFullUser(user.userType) && user.isActive) count++;
	});

	return count;
};

export const isFullUser = (userType) => [UserType.user, UserType.admin, UserType.companyAdmin].includes(userType);

export const getInactiveUsersCount = (users, subscriptionPlanValue) => {
	let count = 0;
	users?.forEach((user) => {
		if (user.subscriptionPlan === subscriptionPlanValue && !user.isActive) count++;
	});
	return count;
};
export const checkLinkMapBeforeAction = ({ allMaps, personaObject, linkedJourneyMap, showActionPopup, showLinkListCjm, title, description_1 }) => {
	if (linkedJourneyMap?.length === 0) showActionPopup();
	else {
		const linkedCjmsData = allMaps?.filter((map) => linkedJourneyMap?.includes(map.cjmId)) || [];
		showLinkListCjm({
			listData: linkedCjmsData,
			title,
			description_1,
			personaObject,
		});
	}
};

export const getMaxChars = (text, max_width) => {
	if (!text) return 0;
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	context.font = "16px sans-serif"; // Set the font size and family
	const width = context.measureText(text)?.width; // Measure the width of the text
	const maxWidth = max_width; // Set the maximum width
	const chars = Math.floor((text?.length * maxWidth) / width); // Calculate the number of characters
	return chars;
};

export const NotNullOrEmpty = (array) => {
	if (array !== null && array !== undefined && array.length > 0) {
		return true;
	}
	return false;
};
export const onSortFunction = (event) => {
	return event.data.sort((a, b) => {
		return event.order === -1 ? a.linkedCjms?.length - b.linkedCjms?.length : b.linkedCjms?.length - a.linkedCjms?.length;
	});
};
export const detectDesactivatedMaps = (map, userType, personaId) => {
	let label = "active";
	const isCompanyAdmin = userType === UserType.companyAdmin;
	const haveDataOnCjm = Object.keys(map)?.length > 0;
	if (map.noAccess) return { activated: false, label: "MAP_NO_ACCESS" };
	if (!haveDataOnCjm) return { activated: true, label: "ERROR_LOADING_MAP" };
	if (map.statusCode === StatusCode.Archived) label = "MAP_ARCHIVED";
	else if (map?.statusCode === StatusCode.Trashed) label = "MAP_DELETED";
	else if (map.cjmId === null) label = "MAP_NOT_FOUND";
	else if (map?.cjmPersonasData && !map?.cjmPersonasData?.find(x => x.personaId == personaId)) return { activated: false, personaId: personaId, label: "PERSONA_NOCONNECT" };
	const activated = haveDataOnCjm && !isCompanyAdmin && map.cjmId !== null && (map?.statusCode === StatusCode.Active || map?.statusCode === StatusCode.Revised);

	return { activated, label };
};
export const preventEnter = (event) => {
	if (event.key === "Enter") {
		event.preventDefault();
	}
};
export const getDate = (lastModified) => {
	if (lastModified) {
		const date = new Date(lastModified);
		return { year: date.getFullYear(), month: date.toLocaleString("default", { month: "long" }), day: date.getDate(), hour: date.getHours(), min: date.getMinutes() };
	}
	return `-`;
};

export const tableDefaultColumn = (type) => {
	switch (type) {
		case EntityList.Opportunity:
			return ["select", "subject", "statusId", "mapId", "groupIds", "win", "actions"];
		case EntityList.CustomerJourney:
			return ["select", "name", "linkedPersonas", "lastModifiedOn", "isFutureLabel", "score", "actions"];
		case EntityList.Action:
			return ["select", "subject", "statusId", "priorityId", "dueDateNew", "groupIds", "actions"];
		case EntityList.CXProgram:
			return ["select", "cxProgramName", "totalNumCjms", "totalNumPersonas", "totalNumOpps", "totalNumSols", "totalNumActions", "totalNumProjects", "lastUpdated", "actions"];
		case EntityList.Persona:
			return ["select", "personaName", "lastModifiedOn", "cjmCount", "tagIds", "actions"];
		case EntityList.Solution:
			return ["select", "subject", "statusId", "tagIds", "solutionType", "actions"];
		case EntityList.Project:
			return ["select", "projectName", "lastUpdated", "tagIds", "statusId", "actions"];
		case EntityList.Insight:
			return ["select", "insightName", "linkedPersonas", "occurance", "cjmIds", "insightType", "actions"];
		case EntityList.User:
			return ["select", "userDetails", "role", "userTypeName", "teams", "isActive", "actions"];
		default:
			return [];
	}
};
export const checkStyle = (queryView, id, size, minWidth = "120px") => {
	const sizeGlobal2 = { xs: "5%", s: "10%", m: "15%", l: "20%", xl: "25%", xxl: "30%", xxxl: "35%" };

	const queryViewParsed = queryView ? JSON.parse(queryView) : undefined;
	const viewWidth = queryView ? queryViewParsed?.find((x) => x.name === id)?.width : undefined;
	let newWidth;
	if (viewWidth) {
		const intWidth = Math.floor(viewWidth);
		const intMinWidth = Number(minWidth?.split("px")[0]);
		if (intWidth > intMinWidth) {
			newWidth = `${intWidth}px`;
		} else {
			newWidth = `${intMinWidth}px`;
		}
	} else {
		newWidth = sizeGlobal2[size];
	}

	return { width: newWidth, minWidth: minWidth };
};

export const getEmotionLevelData = ({ level }) => {
	if (level === 1) return { smiley: IconDispatcher("EMOTION-TERRIBLE"), description: t("ASPX_76"), backgroundColor: "#FF8B8B", textColor: "#CC5858", borderColor: "#CC5858" };
	if (level === 2) return { smiley: IconDispatcher("EMOTION-POOR"), description: t("ASPX_77"), backgroundColor: "#FCA57A", textColor: "#CA5215", borderColor: "#CA5215" };
	if (level === 3) return { smiley: IconDispatcher("EMOTION-GENERIC"), description: t("ASPX_78"), backgroundColor: "#FEE591", textColor: "#ED9E00", borderColor: "#ED9E00" };
	if (level === 4) return { smiley: IconDispatcher("EMOTION-GOOD"), description: t("ASPX_79"), backgroundColor: "#B9FBC0", textColor: "#59AD62", borderColor: "#59AD62" };
	if (level === 5) return { smiley: IconDispatcher("EMOTION-EXCELLENT"), description: t("ASPX_80"), backgroundColor: "#A3DFDE", textColor: "#049493", borderColor: "#049493" };

	return { smiley: undefined, description: t("NO_SENTIMENT"), backgroundColor: "#E5E4E5", textColor: "#A1A1A1", borderColor: "#A1A1A1" };
};

export function areObjectAttributesEmpty(obj, ...attributes) {
	if (!obj) return true;
	let isEmpty = true;
	for (const attribute of attributes) {
		if (!obj?.[attribute]?.isEmptyString()) {
			isEmpty = false;
			break;
		}
	}
	return isEmpty;
}
export const createDefaultPersonaInteraction = (interactionId, stageId, touchpointId, order) => {
	return {
		id: interactionId,
		createdOn: new Date().toISOString(),
		modifiedOn: new Date().toISOString(),
		modifiedBy: "",
		order: order,
		emotionLevel: -1,
		rating: 0,
		actions: "",
		pros: "",
		cons: "",
		insights: "",
		icons: null,
		actionsIcons: null,
		prosIcons: null,
		consIcons: null,
		insightsIcons: null,
		ownerId: EMPTY_GUID,
		ownerName: "",
		cxMetrics: null,
		sourcesOfData: null,
		mainRoute: false,
		alternativeRoute: false,
		forcedMainRoute: false,
		forcedAlternativeRoute: false,
		stageId: stageId,
		touchPointId: touchpointId,
	};
};

export const findNextRouteInteractionId = (cjmData, stageIndex, forcedAttribute, sysRouteAttribute) => {
	// Must take in account the following cases:
	// - when next stage does not have any interactions
	// - when next stage does not have a system  / forced (main or alternative) route, need to search next stages
	// - Search end when we reach last stage
	// - When stage has both system calculated and forced routes (prioritize forced)

	let index = 0;
	let systemCalculatedId = "";
	let forcedId = "";

	for (const stage of cjmData) {
		if (index > stageIndex && stage.length > 0) {
			for (const interaction of stage) {
				if (interaction[forcedAttribute]) {
					forcedId = interaction.id;
				}
				if (interaction[sysRouteAttribute]) {
					systemCalculatedId = interaction.id;
				}
			}
			if (forcedId !== "" || systemCalculatedId !== "") break;
		}
		index++;
	}

	return forcedId !== "" ? forcedId : systemCalculatedId;
};
export const getOutterBorderByRouteType = (routesProps, item) => {
	const { showMainRoute, showAlternativeRoute, isInteractionInMainRoute, isInteractionInAlternativeRoute } = routesProps;
	const { mainRoute, forcedMainRoute, alternativeRoute, forcedAlternativeRoute } = item || {};

	if (showMainRoute && (mainRoute || forcedMainRoute) && isInteractionInMainRoute) return "main";
	if (showAlternativeRoute && (alternativeRoute || forcedAlternativeRoute) && isInteractionInAlternativeRoute) return "alternative";
};
export const getInnerBorderByRouteType = (routesProps) => {
	const { showMainRoute, showAlternativeRoute, isInteractionInMainRoute, isInteractionInAlternativeRoute } = routesProps;

	if (showMainRoute && showAlternativeRoute && isInteractionInMainRoute && isInteractionInAlternativeRoute) return "alternative";
};
export function createCustomTimeout(seconds, action) {
	return new Promise((resolve) => {
		setTimeout(() => {
			action();
			resolve();
		}, seconds * 1000);
	});
}

export const extractGuidsFromArray = (arr) => {
	const guidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
	return arr
		.filter((str) => typeof str === "string")
		.map((str) => str.match(guidRegex))
		.filter((match) => match !== null)
		.map((match) => match[0]);
};
export function formatTime(value) {
	const cleanedValue = value.replace(/[^0-9:]/g, ""); // Remove non-numeric and non-colon characters

	if (cleanedValue.length === 1 && parseInt(cleanedValue, 10) > 2) {
		return `0${cleanedValue}:00:00`;
	} else if (cleanedValue.length === 2) {
		return `${cleanedValue}:00:00`;
	} else if (cleanedValue.length === 3) {
		const hours = cleanedValue.slice(0, 1);
		const minutes = cleanedValue.slice(1);
		return `0${hours}:${minutes}:00`;
	} else if (cleanedValue.length === 4) {
		const hours = cleanedValue.slice(0, 2);
		const minutes = cleanedValue.slice(2);
		return `${hours}:${minutes}:00`;
	} else if (cleanedValue.length === 5) {
		const hours = cleanedValue.slice(0, 1);
		const minutes = cleanedValue.slice(1, 3);
		const seconds = cleanedValue.slice(3);
		return `0${hours}:${minutes}:${seconds}`;
	} else if (cleanedValue.length === 6) {
		const hours = cleanedValue.slice(0, 2);
		const minutes = cleanedValue.slice(2, 4);
		const seconds = cleanedValue.slice(4);
		return `${hours}:${minutes}:${seconds}`;
	}

	return cleanedValue;
}
export function areObjectsEqual(obj1, obj2) {
	// Check if either object is null or undefined
	if (obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined) {
		return false;
	}

	// Check if the objects have the same number of attributes
	if (Object.keys(obj1).length !== Object.keys(obj2).length) {
		return false;
	}

	// Iterate over the attributes of obj1
	for (let attr in obj1) {
		// Check if the attribute exists in obj2
		if (!(attr in obj2)) {
			return false;
		}

		// Check if the values of the attributes are equal
		if (obj1[attr] !== null && obj2[attr] !== null && typeof obj1[attr] === "object" && typeof obj2[attr] === "object") {
			if (Array.isArray(obj1[attr]) && Array.isArray(obj2[attr])) {
				// If the attribute is an array, compare its length and values
				if (obj1[attr].length !== obj2[attr].length) {
					return false;
				}
				for (let i = 0; i < obj1[attr].length; i++) {
					if (obj1[attr][i] !== obj2[attr][i]) {
						return false;
					}
				}
			} else {
				// If the attribute is an object, recursively compare its attributes
				if (!areObjectsEqual(obj1[attr], obj2[attr])) {
					return false;
				}
			}
		} else if (obj1[attr] !== obj2[attr]) {
			// If the attribute is not an object or array, compare its value
			return false;
		}
	}

	// The objects are equal
	return true;
}

export function removeS(str) {
	if (str.endsWith("s")) {
		return str.slice(0, -1);
	}
	return str;
}

export const createDefaultMap = (isTemplate) => {
	return {
		mapMode: MapMode.Custom,
		name: "",
		description: "",
		personaId: EMPTY_GUID,
		mapType: 100000000,
		isTemplate,
	};
};

export const createDefaultPersona = () => {
	return {
		personaId: EMPTY_GUID,
		name: "",
		occupation: "",
		standardImageNumber: 0,
		age: "",
		gender: "",
		isFuture: 100000000,
		standardImage: "",
		customImage: "",
		imageExtension: "",
		isTemplate: false,
	};
};

export const createDefaultSol = (current_user, stageId = EMPTY_GUID, cjmIdStr = EMPTY_GUID) => {
	return {
		id: EMPTY_GUID,
		subject: "",
		description: "",
		statusId: EMPTY_GUID,
		ownerId: current_user?.UserId,
		mapId: cjmIdStr,
		stageId: stageId,
		linkedOpportunities: [],
		groups: [],
		tags: [],
		win: false,
		truth: false,
		score: -1,
		solutionType: -1,
		ratings: {
			impactCustomer: { rating: 0, comment: "" },
			customerBenefit: { rating: 0, comment: "" },
			revenueIncrease: { rating: 0, comment: "" },
			impactBrand: { rating: 0, comment: "" },
			cost: { rating: 0, comment: "" },
			implComplexity: { rating: 0, comment: "" },
			engagementEffortRanking: { rating: 0, comment: "" },
			implDuration: { rating: 0, comment: "" },
		},
		impacts: {
			people: { description: "", colorCode: "#FFFFFF" },
			process: { description: "", colorCode: "#FFFFFF" },
			technology: { description: "", colorCode: "#FFFFFF" },
			value: { description: "", colorCode: "#FFFFFF" },
			metric: { description: "", colorCode: "#FFFFFF" },
		},
	};
};

export const createDefaultOpp = (currentUser, stageId = EMPTY_GUID, cjmIdStr = EMPTY_GUID) => {
	return {
		id: EMPTY_GUID,
		priorityId: EMPTY_GUID,
		subject: "",
		description: "",
		dueDate: new Date().toISOString(),
		statusId: EMPTY_GUID,
		ownerId: currentUser?.UserId || EMPTY_GUID,
		mapId: cjmIdStr,
		stageId: stageId,
		linkedSolutions: [],
		groups: [],
		tags: [],
		win: false,
		truth: false,
		customerValue: 0,
		businessValue: 0,
		globalValue: 0,
	};
};
export const createDefaultAction = (currentUser, stageId = EMPTY_GUID, cjmIdStr = EMPTY_GUID) => {
	return {
		id: EMPTY_GUID,
		subject: "",
		description: "",
		priorityId: EMPTY_GUID,
		statusId: EMPTY_GUID,
		mapId: cjmIdStr,
		stageId: stageId,
		ownerId: currentUser?.UserId,
		dueDate: "",
		groups: [],
		tags: [],
	};
};

export const createDefaultAssessment = (currentUser) => {
	return {
		id: EMPTY_GUID,
		ownerId: currentUser?.UserId,
		contactName: currentUser.UserFullName,
		date: new Date().toISOString(),
		proposition: 0,
		readiness: 0,
		journey: 0,
		effort: 0,
		functionality: 0,
		capability: 0,
		emotion: 0,
		advocacy: 0,
		measurement: 0,
		roi: 0,
		score: 0,
	};
};

export const createDefaultCustomLibraryItem = (colorCode = "") => {
	return {
		id: EMPTY_GUID,
		name: "",
		description: "",
		colorCode: colorCode,
		isCustom: true,
	};
};

export const createDefaultBrandStrategy = (card) => {
	return {
		id: EMPTY_GUID,
		brandValueID: EMPTY_GUID,
		strategyAttributeID: card?.id,
		strategyID: card?.strategyID,
		do: "",
		dont: "",
	};
};

export const createDefaultInsight = ({ currentUser, name = "", typeId = EMPTY_GUID }) => {
	return {
		id: uuid(),
		name,
		description: "",
		typeId,
		ownerId: currentUser?.UserId,
		statusCode: 1,
		tags: [],
		groups: [],
		linkedTouchpoints: [],
		linkedSystems: [],
		linkedDepartments: [],
		linkedCjms: {},
		linkedPersonas: [],
		linkedOpps: [],
		linkedSols: [],
		linkedActions: [],
		linkedProjects: [],
		jsonProperty: "",
	};
};

export const extractTextFromHtml = (html) => {
	const htmlString = html;
	const div = document.createElement("div");
	div.innerHTML = htmlString;
	const text = div.innerText;
	return text;
};

export function getMostFrequentColorCode(arr) {
	const colorCodeCounts = {};
	let maxCount = 0;
	let mostFrequentColorCode = "";

	arr?.forEach((obj) => {
		const colorCode = obj.colorCode;
		colorCodeCounts[colorCode] = (colorCodeCounts[colorCode] || 0) + 1;
		if (colorCodeCounts[colorCode] > maxCount) {
			maxCount = colorCodeCounts[colorCode];
			mostFrequentColorCode = colorCode;
		}
	});

	return mostFrequentColorCode;
}

export const isNullOrUndefined = (obj) => {
	return obj === null || obj === undefined;
};

export const handleRedirectEntityList = ({ entityType, context, route, idRedirect, history, table, forceId, displayList = false, workspaceView = false, backQuery, folderId }) => {
	const ids = table?.entity?.[entityType]?.[context]?.filteredIds;

	if (!ids) {
		if (workspaceView) return { id: forceId || idRedirect };
		return history.push(`${route}?id=${forceId || idRedirect}${displayList ? `&displayList=true` : ""}${backQuery ? `&origin=${urlOriginStatus.DefaultReturn}` : ""}`);
	}
	const encodedIds = encode(ids?.join("|"));

	const lastRandomNumber = localStorage.getItem("lastRandomNumber");
	if (lastRandomNumber) {
		localStorage.removeItem(`/edit-opportunitY-${lastRandomNumber}`);
		localStorage.removeItem(`/edit-solution-${lastRandomNumber}`);
		localStorage.removeItem(`/edit-cxactions-${lastRandomNumber}`);
	}

	const randomNumber = Math.floor(Math.random() * 1000000000);
	localStorage.setItem(`${route}-${randomNumber}`, encodedIds);
	localStorage.setItem("lastRandomNumber", randomNumber.toString());
	if (workspaceView) return { id: forceId || ids?.[0] || idRedirect, q: randomNumber };

	const redirectLink = `${route}?id=${forceId || ids?.[0] || idRedirect}${ids ? `&q=${randomNumber}` : ""}${displayList ? `&displayList=true` : ""} ${folderId ? `&folderid=${folderId}` : ""}`;
	history.push(redirectLink);
};
export const getEntityAndContext = (route) => {
	switch (route) {
		case "edit-opportunity":
			return { entityType: "100000003", context: "OpportunitiesList" };
		case "edit-solution":
			return { entityType: "100000004", context: "solutionsList" };
		case "edit-cxactions":
			return { entityType: "100000005", context: "cxActionsList" };
		case "edit-insight":
			return { entityType: "100000007", context: "insightList" };
	}
};

export function appendListsAndRemoveDuplicates(list1, list2) {
	// Use Set to remove duplicates, then convert it back to an array
	const uniqueList1 = Array.from(new Set(list1));
	const uniqueList2 = Array.from(new Set(list2));

	// Use the concat method to append uniqueList2 to uniqueList1
	return Array.from(new Set(uniqueList1.concat(uniqueList2)));
}

function evaluateFilter(value, filterValue, matchMode) {
	if (filterValue === null) return true;

	switch (matchMode) {
		case "startsWith":
			return typeof value === "string" && value.startsWith(filterValue);
		case "contains":
			return typeof value === "string" && value.includes(filterValue);
		case "endsWith":
			return typeof value === "string" && value.endsWith(filterValue);
		case "equals":
			return value === filterValue;
		case "notEquals":
			return value !== filterValue;
		case "lt":
			return value < filterValue;
		case "lte":
			return value <= filterValue;
		case "gt":
			return value > filterValue;
		case "gte":
			return value >= filterValue;
		case "in":
			return Array.isArray(filterValue) && filterValue.includes(value);
		case "dateIs":
			return new Date(value).toDateString() === new Date(filterValue).toDateString();
		case "between":
			return Array.isArray(filterValue) && value >= filterValue[0] && value <= filterValue[1];
		default:
			throw new Error("Unknown filter matchMode: " + matchMode);
	}
}

function evaluateComplexFilter(item, field, filter) {
	const value = item[field];

	for (let constraint of filter.constraints) {
		if (constraint.value === null) continue; // Skip the current constraint if its value is null

		const meetsConstraint = evaluateFilter(value, constraint.value, constraint.matchMode);

		// If using AND logic and any filter fails, then this item fails the filter
		if (filter.operator === "and" && !meetsConstraint) {
			return false;
		}

		// If using OR logic and any filter passes, then this item passes the filter
		if (filter.operator === "or" && meetsConstraint) {
			return true;
		}
	}

	// For AND logic, reaching here means all filters passed. For OR logic, it means none did.
	return filter.operator === "and";
}

export function applyFilters(data, filters) {
	return data.filter((item) => {
		for (let [field, filter] of Object.entries(filters)) {
			if (field === "global") continue;
			if ("value" in filter && "matchMode" in filter) {
				// Simple filter
				if (!evaluateFilter(item[field], filter.value, filter.matchMode)) {
					return false; // This item doesn't match the simple filter
				}
			}

			if ("constraints" in filter && "operator" in filter) {
				// Complex filter
				if (!evaluateComplexFilter(item, field, filter)) {
					return false; // This item doesn't match the complex filter
				}
			}
		}

		return true; // If it made it through all filters
	});
}

export const validateStringNumberInput = ({ n, isPositive }) => {
	if (n === undefined || n === "") return "";

	// Check if the last character is a decimal point
	const endsWithDot = n.endsWith(".");

	// Check if the input is a valid number (excluding the trailing dot)
	const number = parseFloat(n);
	if (!isNaN(number) && number >= 0) {
		let output = number.toString();

		// Add the decimal point back if the input ended with it
		if (endsWithDot && !output.includes(".")) {
			output += ".";
		}

		return output;
	}
	return undefined;
};

export function getCxGoalValue({ cxGoal }) {
	const actual = Number(cxGoal?.actual || 0);
	const goal = Number(cxGoal?.goal || 0);

	if (actual === 0 && goal === 0) return 0;
	if (actual === goal) return 100;

	if (cxGoal.direction === CxGoalDirection.Increase) {
		if (goal === 0) return 0;
		if (actual >= goal) return 100;
		let res = (actual / goal) * 100;
		if (res < 0) res = 0;
		return res;
	}
	if (cxGoal.direction === CxGoalDirection.Decrease) {
		if (actual === 0) return 0;
		if (goal >= actual) return 100;
		let res = (goal / actual) * 100;
		if (res < 0) res = 0;
		return res;
	}
}

export const reorderActions = ({ actions, order }) => {
	const orderedList = [];
	let index = 0;
	order.forEach((element) => {
		const action = actions.find((action) => action.id === element);
		if (action) {
			orderedList[index] = action;
			index++;
		}
	});

	return orderedList;
};

export const handleAddRenderElement = (delay = 1000) => {
	setTimeout(() => {
		var parentDiv = document.getElementById("addRenderElement");
		var isExist = document.getElementById("RenderElement");
		if (isExist) {
			return;
		}

		var newElement = document.createElement("p");

		newElement.id = "RenderElement";
		if (parentDiv) parentDiv.appendChild(newElement);
		//console.log("RenderElement");
	}, delay);
};
export const SolutionTypeLabel = (solution, params) => {
	switch (solution?.solutionType) {
		case SolutionType.QuickWin:
			return <BenefitCard classes={"px-2"} isActive={true} variant={"quickWin"} icon={"LIKE"} text={"QUICK_WIN"} {...params} />;
		case SolutionType.NiceToHave:
			return <BenefitCard classes={"px-2"} isActive={true} variant={"niceToHave"} icon={"STAR-LIFE"} text={"NICE_TO_HAVE"} {...params} />;
		case SolutionType.ComplexProject:
			return <BenefitCard classes={"px-2"} isActive={true} variant={"complexProj"} icon={"PUZZLE"} text={"COMPLEX_PROJ"} {...params} />;
		case SolutionType.ToAvoid:
			return <BenefitCard classes={"px-2"} isActive={true} variant={"toAvoid"} icon={"ROAD-BARRIER"} text={"TO_AVOID"} {...params} />;
		default:
			return null;
	}
};
export const getEntityName = (attribute) => {
	switch (attribute) {
		case "linkedOpps":
			return "cem_cxopportunity";
		case "linkedSols":
			return "cem_cxsolution";
		case "linkedActions":
			return "cem_cxactiontask";
		case "linkedCjms":
			return "cem_customerjourney";
		case "linkedPersonas":
			return "cem_persona";
		case "linkedCxProgram":
			return "cem_cxprogram";
		default:
			break;
	}
};
export const getEntityCurrentState = (value = false) => {
	return value === true ? StateTypeOption.FutureState : StateTypeOption.CurrentState;
};

export const getAllVersionsSorted = ({ allMaps, currentMapId, parentMapId }) => {
	//The first version have a parentMapId === EMPTY_GUID
	const firstVersion = allMaps.find((map) => map.cjmId === parentMapId);

	//Current map is NOT considered a version
	const allVersionsSorted = allMaps
		.filter((map) => parentMapId !== EMPTY_GUID && map.cjmId !== currentMapId && map.parentMapId === parentMapId)
		.sort((a, b) => b.numberMapVersion - a.numberMapVersion);

	if (firstVersion) allVersionsSorted.push(firstVersion);

	return allVersionsSorted;
};

export const getLastVersionNumber = ({ allMaps, currentMapId, parentMapId }) => {
	if (parentMapId === EMPTY_GUID) return 0;
	const allVersionsSorted = getAllVersionsSorted({ allMaps, currentMapId, parentMapId });
	return allVersionsSorted[0].numberMapVersion;
};

export const lowercaseKeys = (obj) => {
	return Object.keys(obj).reduce((accumulator, key) => {
		accumulator[key.toLowerCase()] = obj[key];
		return accumulator;
	}, {});
};
export const getInitials = ({ name }) =>
	name
		?.split(" ")
		?.map((name) => name[0])
		.join("") || "";

export function getLengthByWidth(width, fontSize) {
	const ellipsisWidth = 0; // Width of "..." in pixels (adjust as needed)
	const charsPerWidth = Math.floor((width - ellipsisWidth) / fontSize);

	return charsPerWidth;
}

export const userTypesLabels = {
	[UserType.companyAdmin]: "SUPER_ADMIN",
	[UserType.admin]: "CS_53",
	[UserType.user]: "CS_52",
	[UserType.readOnly]: "READ_ONLY",
};

export const getDropdownPosition = (id = "#original-dropdown") => {
	const originalDropdown = document.querySelector(id);
	const rect = originalDropdown?.getBoundingClientRect();
	return {
		top: rect?.top,
		left: rect?.left,
	};
};

export const emptyListData = {
	map: { icon: IconDispatcher("CJM", "", { size: "3rem", style: "light" }), color: "#18AEAD" },
	cxPrograms: { icon: IconDispatcher("CX-PROGRAM", "", { size: "3rem", style: "light" }), color: "#F96209" },
	personas: { icon: IconDispatcher("PERSONA", "", { size: "3rem", style: "light" }), color: "" },
	opportunity: { icon: IconDispatcher("OPPORTUNITY", "", { size: "3rem", style: "light" }), color: "#20A9E9" },
	solutions: { icon: IconDispatcher("SOLUTION", "", { size: "3rem", style: "light" }), color: "#FDD03E" },
	cxActions: { icon: IconDispatcher("ACTION", "", { size: "3rem", style: "light" }), color: "#F48E09" },
	folders: { icon: IconDispatcher("FOLDER", "", { size: "3rem", style: "light" }), color: "#FDD03E" },
	assessments,
	projects: { icon: IconDispatcher("PROJECT", "", { size: "3rem", style: "light" }), color: "#3F3D56" },
	insights: { icon: IconDispatcher("GEM", "", { size: "3rem", style: "light" }), color: "#3F3D56" },
	libraries: { icon: IconDispatcher("BOOKS", "", { size: "3rem", style: "light" }), color: "#3F3D56" },
};

export const getFromLocalStorage = (queryNumber) => {
	const itemStr = localStorage.getItem(`queryView_${queryNumber}`);

	// If the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	// Compare the current time with the expiration time
	// If the item is expired, delete it from the storage and return null
	for (let i = localStorage.length - 1; i >= 0; i--) {
		const key = localStorage.key(i);
		if (key.startsWith("queryView") && key !== `queryView_${queryNumber}`) {
			localStorage.removeItem(key);
		}
	}
	//localStorage.removeItem(`queryView_${queryNumber}`);

	// Otherwise, return the value
	return item.value;
};

export const isShowElearningModal = (contracts) => {
	// Modal must be shown for the first 14 days following the first activation
	// for test: '2020-01-12T14:00:41+0000'
	if (localStorage.getItem("hideTrainingModal") === "true" || !contracts) return false;

	let oldestActivation = new Date();

	contracts?.forEach((contract) => {
		const date = new Date(contract?.activeOn);
		if (oldestActivation > date) oldestActivation = date;
	});

	const daysDifference = Math.round(daysDifferenceCalculator(oldestActivation, new Date()));

	if (daysDifference > 14) return false;
	return true;
};


export const getColorsByType = ({ type }) => {
	switch (type) {
		case "status":
			return ["#FC222F", "#138A8A", "#F48E09", "#20A9E9", "#F6BD02", "#465B65", "#FFFFFF"];
		case "priority":
			return ["#FC222F", "#138A8A", "#F48E09", "#20A9E9", "#F6BD02", "#465B65", "#FFFFFF"];
		default:
			return ["#F8C8AE", "#D8FAFA", "#FDE7CB", "#D2EEFB", "#FEF2C8", "#C5C2D4", "#FFFFFF"];
	}
};

export const getBrandValueIcon = (n) => {
	const number = Number(n);
	if (!number || number === "" || number > 16) return "BV-1";
	return "BV-" + number;
};
export const convertEnumToArray = (enumObject) => {
	return Object.keys(enumObject).map((key) => ({
		value: t(key),
		id: enumObject[key],
	}));
};

export const getDefaultName = (pathname) => {
	if (pathname === "/opportunity") return t("NEW_OPPORTUNITY_NAME");
	if (pathname === "/solutions") return t("NEW_SOLUTION_NAME");
};
export function isDarkColor(hex, returnColor = false) {
	// Convertir hex en RGB
	if (hex === undefined || hex === "" || hex === null) {
		if (returnColor) return "#333333";
		return false;
	}
	let r = parseInt(hex.slice(1, 3), 16);
	let g = parseInt(hex.slice(3, 5), 16);
	let b = parseInt(hex.slice(5, 7), 16);

	// Calculer la luminance
	const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
	if (returnColor) return luminance < 150 ? "#FFFFFF" : "#333333";

	return luminance < 150;
}

export const getTableScrollHeight = ({ items, context }) => {
    let height = items?.length * 56 + 100

	if (context === 'home') {
		return items?.length * 56 + 70;
	}
    if (context === "manageUsers" && items?.length > 4) return "calc(100vh - 450px)"

    if (context === "manageUsers" && items?.length < 4) height += 300
	if (!["editPersona", "cxProgram", "cxActionsListGroup"]?.includes(context) && items?.length > 4) return "calc(100vh - 280px)";
	if (items?.length < 4 && !["manageUsers", "cxProgram", "editPersona", "cxActionsListGroup"]?.includes(context)) {
		height += 200; // for dropdown when the list is short
	}
	if (context === "cxActionsListGroup") {
		height += 200;
	}

	return height + "px";
};

export const calculateFinancialImpact = ({ cxGoal, measure }) => {
	const actual = Number(cxGoal?.actual || 0);
	const goal = Number(cxGoal?.goal || 0);

	if (cxGoal.direction === CxGoalDirection.Increase) {
		if (goal - actual > 0) return measure?.baseUnit === 0 ? 0 : ((cxGoal?.goal - cxGoal?.actual) / measure?.baseUnit) * measure?.financialValue;
		return 0;
	}
	if (cxGoal.direction === CxGoalDirection.Decrease) {
		if (actual - goal > 0) return measure?.baseUnit === 0 ? 0 : ((cxGoal?.actual - cxGoal?.goal) / measure?.baseUnit) * measure?.financialValue;
		return 0;
	}
	return 0;
};

export const calculateTotalFinancialImpact = (kpiAttr, allGoals, allMeasures) => {
	let sum = 0;
	kpiAttr?.items?.forEach((item) => {
		const cxGoal = allGoals?.find((cxGoal) => cxGoal.id === item?.cxGoalId);
		const measure = allMeasures?.find((measure) => measure.id === cxGoal?.measureId);
		if (measure) {
			sum += calculateFinancialImpact({ cxGoal, measure });
		}
	});
	if (isNaN(sum)) sum = "";
	const newSum = parseFloat(sum?.toFixed(2))?.toLocaleString(undefined, { minimumFractionDigits: 2 });
	return newSum;
};
export function joinByProperty(items, property, delimiter = "|") {
	if (delimiter === "none") return items?.map((item) => item[property]);
	return items.map((item) => item[property]).join(delimiter);
}

export function hasAttribute(obj, att) {
	if (obj !== undefined && obj !== null) {
		return Object.prototype.hasOwnProperty.call(obj, att);
	}
	return false;
}

export function constructUrl(baseUrl, params) {
	// Remove any undefined or empty values
	const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
		if (value !== undefined && value !== "") {
			acc[key] = value;
		}
		return acc;
	}, {});

	// Construct the query string
	const queryString = new URLSearchParams(filteredParams).toString();

	// Return the full URL
	return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}
export function getVeryLightShade(hexColor, opacity = 1.0) {
	// Remove the '#' character if it exists
	hexColor = hexColor.replace("#", "");

	// Convert the HEX color to RGB
	let r = parseInt(hexColor.substring(0, 2), 16);
	let g = parseInt(hexColor.substring(2, 4), 16);
	let b = parseInt(hexColor.substring(4, 6), 16);

	// Lighten the color by adding a fixed amount to each RGB component
	let lightR = Math.min(r + 80, 255);
	let lightG = Math.min(g + 80, 255);
	let lightB = Math.min(b + 80, 255);

	// Calculate the new RGB values for a very light pastel shade
	let pastelR = Math.round((1 - opacity) * r + opacity * lightR);
	let pastelG = Math.round((1 - opacity) * g + opacity * lightG);
	let pastelB = Math.round((1 - opacity) * b + opacity * lightB);

	// Convert the new RGB values back to HEX
	let pastelHexR = pastelR.toString(16).padStart(2, "0");
	let pastelHexG = pastelG.toString(16).padStart(2, "0");
	let pastelHexB = pastelB.toString(16).padStart(2, "0");

	// Construct the very light pastel HEX color string
	let pastelHexColor = "#" + pastelHexR + pastelHexG + pastelHexB;

	return pastelHexColor.toUpperCase();
}
export function rgbaToHex(rgbaColor) {
	// Parse the RGBA color components
	let rgbaValues = rgbaColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/);
	let r = parseInt(rgbaValues[1]);
	let g = parseInt(rgbaValues[2]);
	let b = parseInt(rgbaValues[3]);
	let a = rgbaValues[4] ? parseFloat(rgbaValues[4]) : 1;

	// Convert the RGBA values to HEX
	let hexR = r.toString(16).padStart(2, "0");
	let hexG = g.toString(16).padStart(2, "0");
	let hexB = b.toString(16).padStart(2, "0");
	let hexA = Math.round(a * 255)
		.toString(16)
		.padStart(2, "0");

	// Construct the HEX color string
	let hexColor = "#" + hexR + hexG + hexB + hexA;

	return hexColor.toUpperCase(); // Return uppercase hex color
}
export function hexToRgb(hex = "#FFFFFF") {
	// Remove the hash at the start if it's there
	hex = hex?.replace(/^#/, "");

	// Parse the hex string
	let r, g, b;

	if (hex?.length === 3) {
		// If it's a shorthand hex code (e.g., #FFF)
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex?.length === 6) {
		// If it's a full hex code (e.g., #FFFFFF)
		r = parseInt(hex.substring(0, 2), 16);
		g = parseInt(hex.substring(2, 4), 16);
		b = parseInt(hex.substring(4, 6), 16);
	} else {
		throw new Error("Invalid HEX color.");
	}

	return { r, g, b };
}

export const createDefaultCxGoal = ({ mapId = EMPTY_GUID, stageId = EMPTY_GUID, measureId = EMPTY_GUID, cxGoalId = EMPTY_GUID }) => {
	return {
		id: cxGoalId,
		measureId,
		mapId,
		stageId,
		touchpointId: EMPTY_GUID,
		actual: 0,
		goal: 0,
		financialImpact: 0,
		achieved: 0,
		description: "",
		direction: CxGoalDirection.Increase,
		date: "",
	};
};

export const calculateOccurance = (insight) => {
	let sum = 0;
	sum += Object.keys(insight?.linkedCjms).length;
	sum += insight?.linkedPersonas?.length;
	sum += insight?.linkedTouchpoints?.length;
	sum += insight?.linkedOpps?.length;
	sum += insight?.linkedSols?.length;
	sum += insight?.linkedActions?.length;
	return sum;
};

export const createDefaultUser = ({ isMultiSub, subPlan, userType }) => {
	return {
		isMember: false,
		loginName: "",
		email: "",
		firstName: "",
		lastName: "",
		role: "",
		subscriptionPlan: isMultiSub ? -1 : Number(subPlan),
		userType,
		isActive: true,
	};
};
export const getKeyByValue = (object, value) => {
	if (!object) return "";
	return Object.keys(object)?.find((key) => object[key] === value);
};
export const typingEffectEditor = ({ text, setText, setEnd, speed = 30 }) => {
	let index = 0;
	const intervalId = setInterval(() => {
		if (index < text.length) {
			setText((prev) => prev + text[index]);
			index++;
		} else {
			if (setEnd) setEnd(true);
			clearInterval(intervalId); // Clear the interval to stop the typing effect
		}
	}, speed);
};

export const getLanguagebyCode = (code) => {
	switch (code) {
		case "en":
			return "English";
		case "es":
			return "Spanish";
		case "fr":
			return "French";
		case "de":
			return "German";
		case "it":
			return "Italian";
		case "nl":
			return "Dutch";
		default:
			return "English";
	}
};
export const getGenderByNumber = (genderNumber) => {
	switch (genderNumber) {
		case 100000000:
			return "Man";
		case 100000001:
			return "Woman";
		default:
			return "Other";
	}
};

export function truncateWithEllipsis(str, maxLength) {
	if (str.length > maxLength) {
		return str.substring(0, maxLength) + "...";
	}
	return str;
}

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const isNullOrEmptyObject = (obj) => {
	if (obj === null || obj === undefined) return true;
	if (typeof obj === "object" && Object.keys(obj).length === 0) return true;
	return false;
};

export const areYearsSimilar = (date1, date2) => {
	const year1 = new Date(date1).getFullYear();
	const year2 = new Date(date2).getFullYear();

	return year1 === year2;
};
export const checkStartEndDate = (object1, object2) => {
	if (object1?.startDate == "" || object1?.endDate == "") return true;
	const range1 = { start: new Date(object1?.startDate), end: new Date(object1?.endDate) };
	const range2 = { start: new Date(object2?.startDate), end: new Date(object2?.endDate) };
	if (range1.start <= range2.end && range2.start <= range1.end) {
		return true; // Ranges overlap
	} else {
		return false; // Ranges do not overlap
	}
};
export function extractAiGenerateListItem(prompt) {
	// Extract section title using a regex pattern
	const titleMatch = prompt?.match(/\**Section Title:\**\s*([^\n]+)/);
	const sectionTitle = titleMatch ? titleMatch[1].trim() : ""; // Trim to remove any leading/trailing whitespace
	// Split the prompt into lines and filter out those that contain designer info
	const lines = prompt?.split("\n").filter((line) => line.trim().startsWith("- "));

	// Map each line to an object with id and description
	const items = lines?.map((line, index) => {
		const description = line.replace(/- /, "").trim(); // Remove the dash and space at the start
		return {
			id: `${index + 1}`, // Assuming ID is a simple incremental value
			description,
		};
	});

	// Return the structured information
	return {
		sectionTitle,
		items,
	};
}

export const GetDefaultSwimlaneName = (type) => {
	switch (type) {
		case InformationType.Text:
			return "TEXT_SWIMLANE";
		case InformationType.ActionsList:
			return "ACTION_SWIMLANE";
		case InformationType.Timing:
			return "TIMING_SWIMLANE";
		case InformationType.Opportunity:
			return "OPPORTUNITY_SWIMLANE";
		case InformationType.Image:
			return "IMAGE_SWIMLANE";
		case InformationType.Divider:
			return "DIVIDER_SWIMLANE";
		case InformationType.Solution:
			return "SOLUTION_SWIMLANE";
		case InformationType.ListItems:
			return "LISTITEMS_SWIMLANE";
		case InformationType.CustomerFeedback:
			return "FEEDBACKDATA_SWIMLANE";
		case InformationType.SentimentChart:
			return "SENTIMENT_CHART_SWIMLANE";
		case InformationType.CustomerActions:
			return "CUSTOMER_ACTIONS_SWIMLANE";
		case InformationType.AdvancedInteractions:
			return "INTERACTION_SWIMLANE";
		case InformationType.AdvancedActions:
			return "ADVANCED_ACTIONS_SWIMLANE";
		case InformationType.AdvancedPros:
			return "ADVANCED_PROS_SWIMLANE";
		case InformationType.AdvancedCons:
			return "ADVANCED_CONS_SWIMLANE";
		case InformationType.AdvancedInsights:
			return "ADVANCED_INSIGHTS_SWIMLANE";
		case InformationType.Touchpoints:
			return "TOUCHPOINTS_SWIMLANE";
		case InformationType.Systems:
			return "SYSTEM_SWIMLANE";
		case InformationType.Departments:
			return "DEPARTMENT_SWIMLANE";
		case InformationType.Feelings:
			return "FEELINGS_SWIMLANE";
		case InformationType.Insights:
			return "ADVANCED_INSIGHTS_SWIMLANE";
		case InformationType.KPIs:
			return "NEW_CX_GOALS_SWIMLANE";
	}
	return null;
};
export function alphabeticSort(a, b) {
	// Regular expression to match non-alphabetic characters.
	const nonAlphaRegex = /[^a-zA-Z]/g;

	// Remove non-alphabetic characters for comparison
	let cleanA = a.name.replace(nonAlphaRegex, "").toLowerCase();
	let cleanB = b.name.replace(nonAlphaRegex, "").toLowerCase();

	// Compare the cleaned strings
	if (cleanA < cleanB) return -1;
	if (cleanA > cleanB) return 1;
	return 0;
}
export const createDefaultCustomerFeedback = (stageId, personaId, id) => {
	return {
		id: uuid(),
		stageId,
		personaId,
		connectorId: id,
		stopRetrieveData: false,
		retrieveData: false,
		icons: [],
		customerFeedbackDatas: [],
		surveyId: "",
		filterId: "",
		ratingQuestionId: "",
		jsonCondition: "",
	};
};

export const createDefaultPositionStrategy = (attribute = []) => {
	let layout = [];
	let row = 0;
	attribute.forEach((att, index) => {
		if (index != 0 && index % 2 == 0) {
			row++;
		}

		const newItem = {
			w: 1,
			h: 220,
			x: index % 2,
			y: row * 220,
			i: att.id,
		};
		layout = [...layout, newItem];
	});

	return layout;
};

export const GetPickListUsers = (authorizedUsers, selectedRecords) => {
	const mapUserIds = selectedRecords?.map((user) => user.id) || [];
	const allRelevantusersAndTeams = authorizedUsers?.filter((user) => !mapUserIds?.includes(user?.id)) || [];
	const teams = allRelevantusersAndTeams?.filter((user) => Object.prototype.hasOwnProperty.call(user, "memberUserIds") === true);
	const users = allRelevantusersAndTeams?.filter((user) => Object.prototype.hasOwnProperty.call(user, "memberUserIds") === false);
	return [...teams, ...users];
};

export const createDefaultWorkspace = () => {
	//statusCode: active:1, trashed: 2
	//accessType: user or team
	//roleName: owner or viewer
	return {
		name: "My Workspace",
		description: "",
		authorizedUsers: [],
		colorCode: "#18AEAD",
		isDefault: false,
		isCustomImage: false,
		statusCode: 1,
	};
};

export function isGUID(str) {
	const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
	return guidRegex.test(str);
}

export const getModeType = (mode) => {
	const m = Number(mode);
	if (m === 1) return "create";
	if (m === 2) return "update";
	if (m === 3) return "duplicate";
};


export function compareLists({ originalIds, modifiedIds }) {
	// Create sets from the lists to easily determine differences
	const originalSet = new Set(originalIds);
	const modifiedSet = new Set(modifiedIds);

	// Determine elements removed from the original list
	const removed = originalIds.filter(item => !modifiedSet.has(item)) || [];

	// Determine elements added to the modified list
	const added = modifiedIds.filter(item => !originalSet.has(item)) || [];

	return { removed, added };
}

export const getAuthorizedUserFromRegisteredUser = ({ authorizedUsers, registeredUser }) => authorizedUsers.find((authUser) => authUser?.domainName.toLowerCase() === registeredUser?.loginName?.toLowerCase());

export const findFirstAlphabeticalCharacter = (text) => {
	if (!text) return ""
	for (let i = 0; i < text?.length; i++) {
		const char = text[i];
		if (/[a-zA-Z]/.test(char)) {
			return char;
		}
	}

	return text.length > 0 ? text.charAt(0) : '';
}

export const isUserActionValid = ({ user, userInfo }) => {
	const userType = Number(userInfo.UserType);
	const isLoggerCompanyAdmin = userType === UserType.companyAdmin;
	const isLoggerAdmin = userType === UserType.admin;

	// Logged user actions are disabled
	if (userInfo.UserEmail.toLowerCase() === user?.loginName.toLowerCase()) return false;

	// If logged user is Admin, He can only modify roles / activate-deactivate User and Admin
	// He cannot modify / activate-deactivate a user with Company Admin role
	if (isLoggerAdmin && user.userType === UserType.companyAdmin) return false;
	if (isLoggerCompanyAdmin) return true;

	return true;
};

export const filterByActiveUsers = ({ authorizedUsers, registeredUsers }) => {
    return authorizedUsers.filter((au) => {
        const userDetails = registeredUsers?.find((ru) => ru?.loginName?.toLowerCase() === au?.domainName?.toLowerCase());
        if (userDetails) return userDetails?.isActive;
        return false
	});
}