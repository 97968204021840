import styled, { css } from "styled-components";
import { Card } from 'primereact/card';

export const TypeLabel = styled.div`
color: ${props => props.type === 'Standard' ? '#3F3D56' : '#20A9E9'};
background-color: ${props => props.type === 'Standard' ? '#E6E2F8' : '#D2EEFB'};
padding: 3px  7px;
border-radius: 6px;
margin-right: 0.5rem;
font-size: 14px;
font-weight: 400;
`
export const MultiLabel = styled.div`
color: ${props => props.multi ? '#138A8A' : '#F48E09'};
background-color: ${props => props.multi ? '#D8FAFA' : '#FDE7CB'};
padding: 3px  7px;
border-radius: 6px;
font-size: 14px;
font-weight: 400;
`
export const ImageContainer = styled.div`


${props => props.wizard === 'true' ? css`

svg {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 325px;
    height: 134px;

    zoom: 1.02;

}
`: css`
width: 100%;
height:200px;
overflow: hidden;
position: relative;
svg {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 325px;
    height: 200px;
    position: absolute;
    left: -5px;
    zoom: 1.02;

}

`

}

`
export const LaneCard = styled(Card)`
width: 320px;
height: ${props => props.wizard === 'true' ? '370px': '392px'};
padding: 0;
border: 1px solid #C4C4C4;
box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
.p-card-footer{
    padding: 5px 0px  !important;
}
.p-card-content{
    padding: 0 5px!important;
}
.p-card-title{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
cursor: pointer;
${props => props.disabled &&css`
    pointer-events: none;
    opacity: 0.5;

`}
${props => props.wizard === 'true' &&css`
    cursor: default;
    width:334px;
`}
`
export const Description = styled.p`
color:#333333;
font-weight: 400;
line-height: 24px;
padding: 0;
margin: 0;
cursor: pointer;
color: #333333;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
align-self: center !important;
cursor: pointer;
overflow: hidden;
font-weight: 500 !important;
height: 73px;
`