import { EMPTY_GUID } from "@utils/helpers";
import { isBlanks, sortByName } from "features/analytics/analyticsHelper";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { GenericButton } from "shared/ui-basics/buttons";
import { FlexBetweenAlignCenter, SearchBar } from "shared/ui-basics/index";
import styled from "styled-components/macro";

function Filter({ title, icon, allItemsSelector, idAttribute = "id", nameAttribute = "name", dataAttribute, apply }) {
	const { t } = useTranslation();
	const op = useRef();

	const currentEntityData = useSelector((state) => state.analytics?.currentData?.[dataAttribute]);
	const selectableEntityData = useSelector((state) => state.analytics?.selectableData?.[dataAttribute]);
	const allItems = useSelector(allItemsSelector);

	const [selectedItems, setSelectedItems] = useState(currentEntityData);
	const [searchedValue, setSearchedValue] = useState("");
	const [listItems, setListItems] = useState(selectableEntityData);
	const [selectAll, setSelectAll] = useState(currentEntityData?.length === selectableEntityData?.length);

	useEffect(() => {
		setSelectedItems(currentEntityData);
		setSelectAll(currentEntityData?.length === selectableEntityData?.length);
		setListItems(selectableEntityData);
	}, [currentEntityData, selectableEntityData]);

	const isItemSelected = (item) => selectedItems?.map((data) => data.id)?.includes(item?.id);
	const blanks = { id: EMPTY_GUID, name: "Blanks" };
	const hasBlank = listItems?.find((item) => item.id === EMPTY_GUID && item?.name === "Blanks");

	const handleClickItem = (clickedItem) => {
		if (isItemSelected(clickedItem)) {
			setSelectAll(false);
			return setSelectedItems(selectedItems?.filter((selectedItem) => selectedItem?.id !== clickedItem?.id));
		}
		if (selectableEntityData.length === selectedItems.length + 1) setSelectAll(true);
		setSelectedItems([...selectedItems, clickedItem]);
	};

	const handleClear = () => {
		setSelectAll(false);
		setSelectedItems([]);
	};

	const handleApply = (e) => {
		op.current.toggle(e);
		setTimeout(() => apply({ dataAttribute, selectedItems }), 100);
	};

	const handleSelectAll = () => {
		if (!selectAll) {
			setSelectAll(true);
			setSelectedItems(selectableEntityData);
		} else {
			setSelectAll(false);
			setSelectedItems([]);
		}
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchedValue(value);

		if (!value) return setListItems(selectableEntityData);

		setListItems(selectableEntityData?.filter((item) => item[nameAttribute]?.toLowerCase()?.includes(value?.toLowerCase())));
	};

	const Search = SearchBar({ globalFilterValue: searchedValue, handleChange: handleSearch });

	return (
		<div>
			<GenericButton variant="light" icon={icon} iconClassName="me-2" onClick={(e) => op.current.toggle(e)}>
				<div>
					{selectedItems?.length > 0 && `${selectedItems.filter((item) => !isBlanks(item))?.length} / `}
					{t(title)}
				</div>
			</GenericButton>
			<CustomOverlayPanel ref={op} onHide={() => setSelectedItems(currentEntityData)}>
				{selectableEntityData?.length > 0 ? (
					<DropdownContainer>
						<Body className="p-2">
							{Search}
							<ItemSelectorCard className="mt-1 mb-2" onClick={handleSelectAll}>
								<CheckBox className="m-0" type="checkbox" checked={selectAll} onChange={() => {}} />
								<ItemName>{t("ALL")}</ItemName>
							</ItemSelectorCard>
							<div className="ms-4">
								{hasBlank && (
									<ItemSelectorCard className="mb-2" onClick={() => handleClickItem(blanks)}>
										<CheckBox className="m-0" type="checkbox" checked={isItemSelected(blanks)} onChange={() => {}} />
										<ItemName>{"(" + t("BLANKS") + ")"}</ItemName>
									</ItemSelectorCard>
								)}
								{sortByName(listItems)?.map((selectableItem) => {
									const currentItem = allItems?.find((it) => it[idAttribute] === selectableItem?.id);
									if (currentItem)
										return (
											<ItemSelectorCard key={currentItem[idAttribute]} className="mb-2" onClick={() => handleClickItem(selectableItem)}>
												<CheckBox className="m-0" type="checkbox" checked={isItemSelected(selectableItem)} onChange={() => {}} />
												<ItemName>{currentItem[nameAttribute]}</ItemName>
											</ItemSelectorCard>
										);
								})}
							</div>
						</Body>
						<Footer>
							<FlexBetweenAlignCenter className="p-2">
								<GenericButton variant="light-link" onClick={handleClear}>
									{t("CLEAR_FILTERS")}
								</GenericButton>
								<GenericButton variant="primary" onClick={handleApply}>
									{t("APPLY")}
								</GenericButton>
							</FlexBetweenAlignCenter>
						</Footer>
					</DropdownContainer>
				) : (
					<NoDataContainer className="p-4">{t("NO_DATA_AVAILABLE")}</NoDataContainer>
				)}
			</CustomOverlayPanel>
		</div>
	);
}

Filter.displayName = "Filter";
export default Filter;

const CustomOverlayPanel = styled(OverlayPanel)`
	.p-overlaypanel-content {
		padding: 0px;
	}

	.p-overlaypanel-close {
		background: #f96209;
		color: #ffffff;
		width: 1.5rem;
		height: 1.5rem;
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		border-radius: 50%;
		position: absolute;
		top: -12px;
		right: -12px;
	}
`;

const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 450px;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const NoDataContainer = styled.div`
	display: flex;
	align-items: center;
	width: 200px;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	color: #333;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const Body = styled.div`
	max-height: 300px;
	overflow: scroll;
`;

const Footer = styled.div`
	border-top: 1px solid #c4c4c4;
`;

const ItemSelectorCard = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	:hover {
		cursor: pointer;
		background-color: #efefef;
	}
`;

const CheckBox = styled(Input)`
	width: 20px;
	height: 20px;

	:hover {
		cursor: pointer;
	}

	&:checked {
		background-color: #333333;
		border-color: #333333;
	}
	&:focus {
		box-shadow: none;
		border-color: ${(props) => (props.disabled === "" ? "#333333" : "#c4c4c4")};
	}
`;

const ItemName = styled.div`
	width: 100%;
	text-overflow: ellipsis;
`;
