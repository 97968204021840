import { forcePersonaInteractionRoute, updateShowRoute } from '@redux/index';
import { makeFindStageIndex, makeGetCjmDataInteraction } from '@redux/memoizedSelectors';
import { isCurrentInteractionInRoute } from '@utils/helpers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { IconDispatcher } from 'shared/Icons';
import CustomTooltip from 'shared/components/CustomTooltip';
import { Switch } from 'shared/ui-basics/buttons';
import { FlexAlignStart } from 'shared/ui-basics/index';
import { Labels, RoutesWrapper } from '../personaInteractions_style';

function RoutesButtons({ interaction, stageId ,mode}) {

    const { t } = useTranslation();
    const { cjmIdStr, personaIdStr } = useLocation().search.getAllMapsParams();

    const interact = useSelector(makeGetCjmDataInteraction(stageId, interaction.id))
    const stageIndex = useSelector(makeFindStageIndex(stageId));
    const stagesInteractions = useSelector(({ maps }) => maps.current_map.cjmData[stageIndex]);
    const isEnterprise = useSelector(({ auth }) => auth?.userInfo?.UserSubscriptionPlanName === "Enterprise");

    const { mainRoute, alternativeRoute } = interact || {};

    const isMainRoute = isCurrentInteractionInRoute(interact, stagesInteractions, "mainRoute", "forcedMainRoute");
    const isAlternativeRoute = isCurrentInteractionInRoute(interact, stagesInteractions, "alternativeRoute",
        "forcedAlternativeRoute");

    if (!isEnterprise) return null;

    const switches = {
        forcedMainRoute: {
            tooltip: 'MAIN_ROUTE_DISABLE_WARNING',
            disabled: mode.IsViewer() ||  (mainRoute && isMainRoute),
            checked: isMainRoute,
            systemCalculatedRoute: mainRoute,

        },
        forcedAlternativeRoute: {
            tooltip: 'ALTERNATIVE_ROUTE_DISABLE_WARNING',
            disabled:  mode.IsViewer()  || (alternativeRoute && isAlternativeRoute),
            checked: isAlternativeRoute,
            systemCalculatedRoute: alternativeRoute,
        },
    }
     const forceCustomerRoutes = ({ cjmIdStr, personaIdStr, interaction, value, type }) => {
        if (type === "forcedMainRoute") {
            forcePersonaInteractionRoute({ interaction, newValue: value, isMain: true, cjmIdStr, personaIdStr });
            if (value){
                updateShowRoute({ cjmIdStr, showRoute: true, isMain: true });
            }

        }
        if (type === "forcedAlternativeRoute") {
            forcePersonaInteractionRoute({ interaction, newValue: value, isMain: false, cjmIdStr, personaIdStr });
            if (value) {
                updateShowRoute({ cjmIdStr, showRoute: true, isMain: false });
            }
        }
    };
    const handleSwitchRoute = (id) => {
        forceCustomerRoutes({ cjmIdStr, personaIdStr, interaction, value: !switches[id].checked, type: id })
    }

    return (<FlexAlignStart gap={30} >
        {Routes.map(({ id, icon, label, tooltip }) => (
            <RoutesWrapper key={id}>

                <div  className='d-flex align-items-center'>
                    <Labels>{t(label)}</Labels>
                    {switches[id].systemCalculatedRoute && <CustomTooltip text={t(tooltip)}>
                    {IconDispatcher(icon, 'ms-2 routesClassIcon')}
                </CustomTooltip>}
                </div>
                <div >
                    <CustomTooltip text={t(switches[id].tooltip)} placement={"bottom"} disabled={switches[id].disabled}>
                        <Switch
                            checked={switches[id].checked}
                            onChange={() => handleSwitchRoute(id)}
                            disabled={switches[id].disabled}
                        />
                    </CustomTooltip>
                </div>
            </RoutesWrapper>
        ))}
    </FlexAlignStart>)
}

const Routes = [
    { id: 'forcedMainRoute', icon: 'MAIN-ROUTE', label: 'MAIN_ROUTE', tooltip: 'MAIN_ROUTE_DESCR' },
    { id: 'forcedAlternativeRoute', icon: 'ALTERNATIVE-ROUTE', label: 'ALTERNATIVE_ROUTE', tooltip: 'ALTERNATIVE_ROUTE_DESCR' },
]

RoutesButtons.displayName = "RoutesButtons";
RoutesButtons.propTypes = {
    interaction: PropTypes.object,
    stageId: PropTypes.string,
};
export default RoutesButtons;