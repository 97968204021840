import { createSolution, getAllAuthorizedUsers, getAllMapsListData, getAllOpportunity, getAllPersonas, getAllSolutions, getMapUsers, groupInit, priorityInit, statusInit, tagInit, updateSolution } from "@redux/index";
import { FindSolutionById, retrieveAllActiveSolutions } from "@redux/memoizedSelectors";
import { EMPTY_GUID, RestrictUserAccess, SolutionTypeLabel, createDefaultSol, decode, encode, extractTextFromHtml, getFromLocalStorage, isUserAllowed } from "@utils/helpers";
import { urlOriginStatus } from "@utils/optionsSets/OptionSets";
import { getAllProjects } from "features/projects/reducer/ProjectsActions";
import BaseActions from "features/solutions/actions/BaseActions";
import HeaderActions from "features/solutions/actions/HeaderActions";
import { t } from "i18next";
import { base } from "index";
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { Tab } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbItem } from "reactstrap";
import DataLoader from "shared/components/data-loader";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import { useAttributeHandler } from "shared/hooks/index";
import { Flex, FlexAlignCenter, FlexBetween, FlexBetweenAlignCenter, GenericButton, SearchBar } from "shared/ui-basics/index";
import Tags from "../../../shared/cx-components/Tags";
import * as Style from "../../../shared/cx-components/style";
import NoDataComponent from "../../auth/view/no-data-component";
import SolutionGeneral from "../solutionsComponents/SolutionGeneral";
import SolutionImpact from "../solutionsComponents/SolutionImpact";
import SolutionRating from "../solutionsComponents/SolutionRating";

function SolutionPage(props) {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const { selectedItemId } = search.getCxProps(props.query);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const selectedSolution = useSelector(FindSolutionById(selectedItemId));

    const mapUserParameters = {
        cjmId: selectedSolution?.mapId,
        userId: userInfo?.UserId,
        cxOwnerEntity: selectedSolution?.ownerId,
    };
    const asyncFunctions = [getAllSolutions, getAllMapsListData, getAllAuthorizedUsers, getAllOpportunity, statusInit, groupInit, priorityInit, getAllPersonas, tagInit, getAllProjects];

    useEffect(() => {
        if (selectedSolution?.mapId) {
            dispatch(getMapUsers(mapUserParameters));
        }
    }, [selectedSolution?.mapId]);


    return <Style.ContainerPage query={props.query}>
        <DataLoader reduxActions={asyncFunctions} disabledSpinner={selectedSolution !== undefined}>
            {selectedSolution ? <Solution {...props} /> : <NoDataComponent label={"SOLUTION_NOT_FOUND"} icon="CIRCLE-EXCLAMATION" />}
        </DataLoader>
    </Style.ContainerPage>;
}

const Solution = ({ query, setQuery, openBlankForm, onClose }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const ref = useRef(null);


    const { selectedItemId, cjmId, personaId, origin, displayList } = search.getCxProps(query);
    const backQuery = search.getBackQuery(); // cxProgram
    const folderId = search.getFolderId();
    const backQueryDecripted = getFromLocalStorage(backQuery)  // return cxProgramId

    const [tabKey, setTabKey] = React.useState("general");
    const currentUser = useSelector((state) => state.maps.currentUser);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const selectedSolution = useSelector(FindSolutionById(selectedItemId));
    const mapAuthorizedUsers = useSelector((state) => state.maps.all_maps?.find((map) => map.cjmId === selectedSolution?.mapId))?.authorizedUsers || [];
    const isSample = selectedSolution?.isSample;
    const [counter, setCounter] = useState(0);
    const disableContributor = isSample || isUserContributorOfMaps({ mapAuthorizedUsers, selectedItemId, userInfo });
    const [showAllSolutions, setShowAllSolution] = useState(displayList ? true : false);
    const updateSol = (modifiedSolution, modifiedAttribNames, onSuccess) => updateSolution({ modifiedSolution, modifiedAttribNames })
    const { object, setObject, handleChange, handleBlur, setModifiedAttribNames, modifiedAttribNames } = useAttributeHandler(selectedSolution, updateSol);
    const isEditable = !isUserViewerOfMaps({ currentUser, mapAuthorizedUsers, selectedItemId, userInfo, solution: object });
    const objectRef = useRef(object);
    useEffect(() => {
        if (selectedSolution) {
            setObject(selectedSolution);
            setModifiedAttribNames(new Set());
            setCounter(counter + 1);
        }
    }, [selectedItemId]);
    useEffect(() => {
        objectRef.current = object;
    }, [object.mapId, object.stageId]);
    useEffect(() => {
        return () => {
            // Use `objectRef.current` to access the latest value of `object`
            if (objectRef.current?.mapId !== EMPTY_GUID && objectRef.current?.stageId === EMPTY_GUID) {
                toast.info(t("JOURNEY_MAP_NOT_SAVED"));
            }
        };
    }, []);

    const handleRedirect = (response) => {
        if (object.mapId !== EMPTY_GUID && object.stageId === EMPTY_GUID) {
            // case we select a map without a stage
            return toast.info(t("PLEASE_SELECT_A_STAGE"));
        }
        if (modifiedAttribNames.size > 0) {
            updateSol({ ...object, description: encode(object.description), subject: encode(object.subject) }, [...modifiedAttribNames]);
        }
        if (origin == urlOriginStatus.DefaultReturn) return history.goBack();

        if (backQueryDecripted) return history.push(`EditCxProgram?programid=${backQueryDecripted}&tab=relationship`);

        if (response.id) {
            if (query) return setQuery({ ...query, id: response.id });
            history.push(`/edit-solution?id=${response.id}`);
        } else {
            if (cjmId && personaId) {
                return history.push(`/EditMap?cjmId=${cjmId}&personaId=${personaId}`);
            } else {
                const nextId = ref.current?.getNextId()
                if (query && nextId) return setQuery({ ...query, id: nextId }); // change to the next one in the list
                if (showAllSolutions && nextId && response?.id) return history.push(`/edit-solution?id==${nextId}`); // if I delete or archive in the navigationView from the form

                history.push(`/solutions${folderId ? `?folderid=${folderId}` : ""}`);

            }
        }
    };
    const redirectToForm = () => {
        if (openBlankForm) {
            window.open(`${base}edit-solution?id=${object.id}`, '_blank');
        } else {
            history.push(`/edit-solution?id=${object.id}`);
            query && setQuery(false);
        }

    }

    return (
        <Style.ContainerCxPage query={query} disableViewer={!isEditable}>
            {showAllSolutions && <ListSolutionComponent currentSolution={object} isEditable={isEditable} ref={ref} query={query} setQuery={setQuery} />}
            <Style.ContainerItem key={counter} query={query}>
                <Style.Header query={query}>
                    {!query && <FlexBetween className="w-100">
                        <Style.PathContainer>
                            <GenericButton variant="light-link" className="m-2" icon={"ARROW_LEFT"} onClick={handleRedirect} />
                            <Style.CustomBreadcrumb className="m-0">
                                <BreadcrumbItem>
                                    <GenericButton
                                        variant="light-link"
                                        className="me-2"
                                        onClick={() => setShowAllSolution(!showAllSolutions)}
                                        tooltip
                                        tooltipText={t("SHOW_ALL_SOLUTIONS")}
                                        icon={"SIDEBAR"}
                                    />
                                    <Style.Path>{origin && origin !== '1' ? origin : t("SOLUTIONS")}</Style.Path>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <Style.Path className="fw-bold">{object?.subject.defaultPlaceholder("NEW_SOLUTION_NAME")}</Style.Path>
                                </BreadcrumbItem>
                            </Style.CustomBreadcrumb>
                        </Style.PathContainer>
                        <Style.ButtonContainer>{!disableContributor && <HeaderActions solution={object} handleRedirect={handleRedirect} />}</Style.ButtonContainer>
                    </FlexBetween>
                    }
                    <Style.MainTitle query={query}>
                        <FlexBetween>
                            <Style.Title variant="default" value={object?.subject} onChange={(e) => handleChange("subject", e.target.value)} onBlur={() => handleBlur("subject")} disabled={!isEditable} title={object?.subject?.length > 0 && object.subject}
                                placeholder={t("ENTER_TITLE")}

                            />
                            {query && <Flex>
                                <GenericButton variant="light-link" className="me-2" icon={"ARROW_UP_RIGHT"} onClick={redirectToForm} />
                                {CreateActionsDropdown({ actions: BaseActions({ solution: object, handleRedirect, onClose }), variant: 'none', dropdownClasses: 'px-0' })}
                            </Flex>}
                        </FlexBetween>

                        <FlexBetweenAlignCenter gap={20} className="pt-2">
                            <Tags object={object} setObject={setObject} recordType="cem_cxsolution" disabled={!isEditable} />
                            <FlexAlignCenter >
                                {SolutionTypeLabel(object)}
                            </FlexAlignCenter>
                        </FlexBetweenAlignCenter>
                    </Style.MainTitle>
                </Style.Header>
                {query && <Style.Divider />}
                <Style.MainContainer solution="true" query={query}>
                    <Style.CustomTabs className="mt-0" activeKey={tabKey} onSelect={(k) => setTabKey(k)} mountOnEnter={true}>
                        <Tab eventKey="general" title={t("ASPX_185")}>
                            <SolutionGeneral solution={object} setSolution={setObject} isEditable={isEditable} handleChange={handleChange} handleBlur={handleBlur} query={query} setQuery={setQuery} onClose={onClose} />
                        </Tab>
                        <Tab eventKey="impact" title={t("IMPACT")}>
                            <SolutionImpact solution={object} modifiedAttribNames={modifiedAttribNames} isEditable={isEditable} />
                        </Tab>
                        <Tab eventKey="rating" title={t("RATING")}>
                            <SolutionRating solution={object} setSolution={setObject} modifiedAttribNames={modifiedAttribNames} disabled={!isEditable} />
                        </Tab>
                    </Style.CustomTabs>
                </Style.MainContainer>
            </Style.ContainerItem>
        </Style.ContainerCxPage>
    );
};

const ListSolutionComponent = forwardRef(({ currentSolution, isEditable, query, setQuery }, ref) => {

    const { search } = useLocation();
    const { selectedItemId, queryNb, origin, cjmId, personaId } = search.getCxProps(query);
    const {cjmIdStr} = search.getAllMapsParams();

    const selectedSolutionRef = useRef(null);
    const history = useHistory();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const encodedIds = localStorage.getItem(`/edit-solution-${queryNb}`);
    const ids = queryNb ? decode(encodedIds)?.split("|") : [];
    const selectedSolution = useSelector(FindSolutionById(selectedItemId));

    const SolMap = new Map(useSelector(retrieveAllActiveSolutions).map((sol) => [sol.id, sol]));
    const allSolutions = ids?.length > 0 ? ids.map((id) => SolMap.get(id)).filter(Boolean) : cjmIdStr
        ? Array.from(SolMap.values()).filter((cx) => cx.stageId === selectedSolution.stageId) : [...SolMap.values()];

    const filterSolutions = allSolutions?.filter((sol) => sol.subject.toUpperCase().includes(globalFilterValue.toUpperCase()));
    const handleChange = (e) => setGlobalFilterValue(e.target.value);

    const Search = SearchBar({ globalFilterValue, handleChange })
    const SolutionCard = ({ solution, index }) => {
        const selectedSolution = solution.id === selectedItemId ? currentSolution : solution;
        const history = useHistory();
        const selectedSolutionRef = useRef(null);

        const handleClick = () => {
            if (selectedSolution.id !== selectedItemId) {
                if (query) return setQuery({ ...query, id: selectedSolution.id });

                history.push(`/edit-solution?id=${selectedSolution.id}${cjmId ? `&origin=${encode([origin, cjmId, personaId].join("|"))}` : ""}${ids?.length > 0 ? `&q=${queryNb}` : ""}`);
            }
        };

        return (
            <Style.QuickSelectionCard onClick={handleClick} active={selectedItemId === selectedSolution.id} index={index} ref={selectedSolution.id === selectedItemId ? selectedSolutionRef : null} query={query}>
                <Style.QuickSelectionCardTitle title={selectedSolution?.subject?.length > 5 && selectedSolution.subject}>{selectedSolution.subject.defaultPlaceholder("NEW_SOLUTION_NAME")}</Style.QuickSelectionCardTitle>
                <Style.QuickSelectionCardDescription>{extractTextFromHtml(selectedSolution.description)}</Style.QuickSelectionCardDescription>
            </Style.QuickSelectionCard>
        );
    };
    const handleRedirect = () => {
        const onSuccess = (data) => {
            history.push(`/edit-solution?id=${data.id}`);
        };
        const newSolution = createDefaultSol(userInfo);
        createSolution({ item: newSolution, displayToast: "CREATE", onSuccess });
    };
    useLayoutEffect(() => {
        if (selectedSolutionRef.current) {
            selectedSolutionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selectedItemId]);
    useImperativeHandle(ref, () => ({
        getNextId: () => {
            const indexOfNextId = allSolutions.findIndex((sol) => sol.id === selectedItemId) + 1
            const nextId = allSolutions[indexOfNextId]?.id
            return nextId
        },
    }));
    return (
        <Style.ListComponentWrapper query={query} className={query ? '' : "py-3"}>
            {!query && <Style.Labels fs={18} className="m-2 ps-3">
                {t("SOLUTIONS")}
            </Style.Labels>}
            <Style.HeaderListComponent query={query}>
                {isEditable && !queryNb && !cjmIdStr ? (
                    <GenericButton variant="primary-link" className="me-2" onClick={handleRedirect} icon={"SQUARE_PLUS"} iconClassName='me-1'>
                        {t("NEW_SOLUTION")}
                    </GenericButton>
                ) : (
                    <div></div>
                )}
                {Search}
            </Style.HeaderListComponent>
            <Style.WrapperItems disableScroll={filterSolutions?.length < 7}>
                {filterSolutions?.map((solution, index) => (
                    <div key={solution.id} ref={solution.id === selectedItemId ? selectedSolutionRef : null} className="w-100">
                        <SolutionCard key={index} solution={solution} index={index} />
                    </div>
                ))}
            </Style.WrapperItems>
        </Style.ListComponentWrapper>
    );
});

const isUserViewerOfMaps = ({ currentUser, mapAuthorizedUsers, selectedItemId, userInfo, solution }) => {
    const readOnlyCode = "100000003";
    if (userInfo.UserType === readOnlyCode) return true;
    if (solution?.ownerId === userInfo?.UserId) {
        return false;
    }
    if (currentUser) {
        return currentUser?.roleName === "Viewer" ? true : false;
    }
    if (selectedItemId) {
        const userAccessPrivilege = mapAuthorizedUsers?.find((user) => user.id === userInfo.UserId)?.roleName || "";
        return RestrictUserAccess(userAccessPrivilege?.toLowerCase(), ["viewer"]);
    }
};

const isUserContributorOfMaps = ({ mapAuthorizedUsers, selectedItemId, userInfo }) => {
    const isEditable = isUserAllowed(userInfo.UserType, "edit-action-list");
    const userAccessPrivilege = mapAuthorizedUsers?.find((user) => user.id === userInfo.UserId)?.roleName || "";
    return RestrictUserAccess(userAccessPrivilege?.toLowerCase(), ["viewer", "contributor"]) && selectedItemId && isEditable;
};

SolutionPage.displayName = "SolutionPage";
Solution.displayName = "Solution";
ListSolutionComponent.displayName = "ListSolutionComponent";
export default SolutionPage;