import ZoomImageModal from "shared/modals/basic/ZoomImageModal";
import { GenericButton, IconDispatcher, UploadLabel } from "shared/ui-basics/index";
import { ProgressBarSpinner } from "shared/components/spinners/Spinner";
import { RestrictUserAccess } from "@utils/helpers";
import { useEffect, useImperativeHandle, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Input } from "reactstrap";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import CustomTooltip from "./CustomTooltip";
import { getRequestStatus } from "@redux/memoizedSelectors";
import { shallowEqual, useSelector } from "react-redux";


export const UploadImage = ({ keyFor, id, deleteImage, addImageAllowed = true, access, customImage, setCustomImage, onDelete, variantImg, zoom, generateUsingAI, innerRef, updateHeight,load }) => {
	const MAX_UPLOAD_SIZE = 5000000;
	const [loading, setLoading] = useState(false);
	const [fileSize, setFileSize] = useState(null);
	const { t } = useTranslation();
	const { hideForPdf } = useLocation().search.getAllMapsParams();

	const { pending } = useSelector(getRequestStatus({ type: "GENERATE_IMAGE" }), shallowEqual);
	const aiEnabled = useSelector(({ settings }) => settings.subscriptionDetails.aiEnabled);

	const imageLoadingAI = pending === 1;
	const [progressBar, setProgressBar] = useState(0);

	const pictureHandler = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		if (file) {
			setLoading(true);
			if (file?.size <= MAX_UPLOAD_SIZE) {
				setFileSize(null);
				reader.readAsDataURL(file);
			} else {
				setFileSize(Number(file.size / 1000000).toFixed(2));
			}
		}
		reader.onloadend = () => {
			setCustomImage(reader.result);
			if(!innerRef) setLoading(false);
			updateHeight && updateHeight();
		};
		reader.onerror = function (error) {};
	};

	const closeError = () => {
		setFileSize(null);
		setLoading(false);
	};

	const [openzoom] = useModal(ZoomImageModal, { image: customImage });
	useImperativeHandle(innerRef, () => ({
		setLoading: (value) => {
			if (value ) setProgressBar(25);
			if (!value ) setProgressBar(0);
			setLoading(value);
			if(!value) updateHeight && updateHeight();
		},

	}));

	return (
		<ImageContainer access={access}>
			{customImage === undefined || customImage === "" ? (
				<NoImg keyFor={keyFor} pictureHandler={pictureHandler} />
			) : fileSize > 0 || fileSize === -1 ? (
				<ErrorImg keyFor={keyFor} fileSize={fileSize} pictureHandler={pictureHandler} closeError={closeError} />
			) : load || loading ? (
				<div className="pt-5">
					{imageLoadingAI ? (
						<ProgressBarSpinner delay={progressBar} placeholder1={t("ALEX_CREATING_IMAGES_PART_1")} placeholder2={t("ALEX_CREATING_IMAGES_PART_2")} />
					) : (
						<Spinner animation="border" variant="secondary" />
					)}
				</div>
			) : (
				<>
					<ImgWrapper variantImg={variantImg} src={customImage} loading={hideForPdf ? "eager" : "lazy"} alt="" />
					<Actions>
						{addImageAllowed && (
							<CustomTooltip id={id} text={`${t("ASPX_108")} ${t("IMAGE")}`} placement={"bottom"}>
								<UploadLabel for={keyFor} className="mx-1 btn btn-second" disabled={access}>
									{IconDispatcher("UPLOAD", "pointer")}
								</UploadLabel>
							</CustomTooltip>
						)}
						<Input type="file" name="picture" id={keyFor} style={{ display: "none" }} accept=".jpg , .jpeg, .png, .bmp" onChange={pictureHandler} />

						{deleteImage && (
							<GenericButton id={id} variant="light" className="mx-1" icon="DELETE" iconClassName="pointer" tooltip={true} tooltipText={t("ASPX_13")} onClick={onDelete} />
						)}
						{zoom && <GenericButton id={id} variant="light" className="mx-1" icon="VIEW" iconClassName="pointer" tooltip={true} tooltipText={t("ZOOM")} onClick={openzoom} />}
						{generateUsingAI && aiEnabled && <GenericButton id={id} variant="light" className="mx-1" icon="SPARKLES" iconClassName="pointer" onClick={generateUsingAI} tooltip={true} tooltipText={t("ASK_ALEX")}/>}
					</Actions>
				</>
			)}
		</ImageContainer>
	);
};

//custom img
//we have some mode
//upload,delete,error,default pic
export const UploadImageModal = ({ keyFor, id, deleteImage, disabled, customImage, setCustomImage, onDelete, variantImg, fileSizeParent, generateUsingAI, innerRef }) => {
	const { t } = useTranslation();
	const MAX_UPLOAD_SIZE = 5000000;
	const [loading, setLoading] = useState(false);
	const [fileSize, setFileSize] = useState(fileSizeParent);
	const { pending } = useSelector(getRequestStatus({ type: "GENERATE_IMAGE" }), shallowEqual);
	const aiEnabled = useSelector(({ settings }) => settings.subscriptionDetails.aiEnabled);

	const imageLoadingAI = pending === 1;
	const [progressBar, setProgressBar] = useState(0);
	useEffect(() => {
		setFileSize(fileSizeParent);
	}, [fileSizeParent]);

	const pictureHandler = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		if (file) {
			setLoading(true);
			if (file?.size <= MAX_UPLOAD_SIZE) {
				setFileSize(null);
				reader.readAsDataURL(file);
			} else {
				setFileSize(Number(file.size / 1000000).toFixed(2));
				setCustomImage("error");
				setLoading(false);
			}
		}
		reader.onloadend = () => {
			setCustomImage(reader.result);
			setLoading(false);
		};
		reader.onerror = function (error) {};
	};
	const deleteImg = () => {
		setCustomImage("delete");
		if (onDelete) onDelete();
	};
	useImperativeHandle(innerRef, () => ({
		setLoading: (value) => {
			if (value) setProgressBar(18);
			if (!value) setProgressBar(0);
			setLoading(value);
		},
	}));
	return (
		<div className="position-relative w-100 h-100">
			<ImageContainer access={disabled}>
				{customImage === "upload" || customImage === "delete" || customImage === "error" ? (
					<NoImg keyFor={keyFor} pictureHandler={pictureHandler} />
				) : loading ? (
					imageLoadingAI ? (
						<ProgressBarSpinner delay={progressBar} placeholder1={t("ALEX_CREATING_IMAGES_PART_1")} placeholder2={t("ALEX_CREATING_IMAGES_PART_2")} />
					) : (
						<Spinner animation="border" variant="secondary" />
					)
				) : (
					<>
						<ImgWrapper variantImg={variantImg} src={customImage} loading="lazy" alt="" />
						<Actions>
						<CustomTooltip id={id} text={`${t("ASPX_108")} ${t("IMAGE")}`} placement={"bottom"}>
							<UploadLabel for={keyFor} className="mx-1 btn btn-second">
								{IconDispatcher("UPLOAD", "pointer")}
							</UploadLabel>
						</CustomTooltip>
							<Input type="file" name="picture" id={keyFor} style={{ display: "none" }} accept=".jpg , .jpeg, .png, .bmp" onChange={pictureHandler} />

							{deleteImage && <GenericButton id={id} variant="light" className="mx-1" icon="DELETE" iconClassName="pointer" onClick={deleteImg} tooltip={true} tooltipText={t("ASPX_13")} />}
							{generateUsingAI  && aiEnabled && <GenericButton id={id} variant="light" className="mx-1" icon="SPARKLES" iconClassName="pointer" onClick={generateUsingAI} tooltip={true} tooltipText={t("ASK_ALEX")}/>}
						</Actions>
					</>
				)}
			</ImageContainer>
			{fileSize !== null && fileSize > 0 && <SizeErrorMessage>{t("CURRENT_MAGE_SIZE") + ` ${fileSize} MB/MO. (${t("MAX_IMAGE_SIZE")})`}</SizeErrorMessage>}
			{fileSize === -1 && <SizeErrorMessage>{t("ICON_MISSING")}</SizeErrorMessage>}
		</div>
	);
};

const ErrorImg = ({ keyFor, pictureHandler, fileSize, closeError }) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column justify-content-center align-items-center">
			<SizeErrorMessage>{t("CURRENT_MAGE_SIZE") + ` ${fileSize} MB/MO. (${t("MAX_IMAGE_SIZE")})`}</SizeErrorMessage>

			<UploadLabel variant="tertiary-outline" for={keyFor} className="mx-1 my-2 btn">
				{t("ASPX_236")}
			</UploadLabel>
			<Input type="file" name="picture" id={keyFor} style={{ display: "none" }} accept=".jpg , .jpeg, .png,.gif, .bmp" onChange={pictureHandler} />
			<GenericButton variant="light" className="mx-1" icon="X" iconClassName="pointer" onClick={closeError} />
		</div>
	);
};

const NoImg = ({ keyFor, pictureHandler }) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column justify-content-center align-items-center">
			{IconDispatcher("UPLOAD-IMG", "zoom-15 ")}

			<UploadLabel variant="tertiary-outline" for={keyFor} className="mx-1 my-2 btn">
				{t("ASPX_236")}
			</UploadLabel>
			<Input type="file" name="picture" id={keyFor} style={{ display: "none" }} accept=".jpg , .jpeg, .png,.gif, .bmp" onChange={pictureHandler} />
		</div>
	);
};

const ImgWrapper = styled.img`
	${(props) =>
		props.variantImg == "StageImage" &&
		`
object-fit: cover;
    max-width: 250px;
    align-self: center;
    `}
	${(props) =>
		props.variantImg == "StageDescription" &&
		` object-fit: contain;
    max-width: 250px;
    min-height: 140px;
    max-height: 140px;

`}
	${(props) =>
		props.variantImg == "ImageSection" &&
		` object-fit: contain;
    max-width: 100%;
    min-height: 140px;
`}

${(props) =>
		props.variantImg == "personaImage" &&
		` width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer
`}
${(props) =>
		props.variantImg == "librariesImage" &&
		`   max-height:150px;
    width: 100%;
    object-fit: cover;
    cursor: pointer
`}
${(props) =>
		props.variantImg == "EditImage" &&
		`
      max-height: 150px;
      max-width: 100%;
  `}
  ${(props) =>
		props.variantImg == "PersonaImg" &&
		`
  width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer
  `}
`;

const Actions = styled.div`
	position: absolute;
	display: flex;
	/* width: 40px; */
	justify-content: space-between;
	visibility: hidden;
	align-items: center;
`;
const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-items: center;
	width: inherit;
	height: inherit;

	position: relative;
	/* padding:15px; */

	& img {
		/* max-height: auto;
  max-width: 100%; */
	}
	&:hover ${Actions} {
		${(props) =>
			(props.access === undefined || props.access === true) &&
			`
  visibility: visible;
  `}
	}
	&:hover img {
		${(props) =>
			(props.access === undefined || (typeof props.access === "string" && !RestrictUserAccess(props.access?.toLowerCase(), ["viewer"]))) &&
			`
    			opacity: 0.1;
  		`}
	}
`;

const SizeErrorMessage = styled.div`
	color: red;
	font-size: 12px;
	text-align: center;
`;

export const WrapperSpinner = styled(Spinner)`
	min-height: 140px;
`;
