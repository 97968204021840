import { daysDifferenceCalculator } from "@utils/helpers";
import { NavigationSearchBar } from "features/search/components/general-search-bar-nav";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import Notifications from "../Notifications";
import InitialsOptions from "./initials-options";
import NeedHelpOptions from "./need-help-options";

export default function NavMenuHeader({ hideForPdf, isPreview }) {
	const { t } = useTranslation();
	const innerRef = React.useRef();
	const EXP_WARNING_LIMIT = 14;

	const userInfo = useSelector((state) => state.auth?.userInfo);
	const subscriptionDetails = useSelector((state) => state.settings.subscriptionDetails);

	const isStandard = userInfo?.UserSubscriptionPlanName === "Standard";
	const isTrial = subscriptionDetails.isTrial;

	const getDaysDiff = () => {
		// for test: '2030-01-01T14:00:41+0000'
		const todayDate = new Date();
		const expirationDate = new Date(subscriptionDetails.validUntil);
		return Math.ceil(daysDifferenceCalculator(todayDate, expirationDate));
	};

	const isSubscriptionCloseToEnd = () => {
		if (getDaysDiff() <= EXP_WARNING_LIMIT) return true;
		return false;
	};

	const displayDaysDiff = () => {
		let message = "";
		if (isTrial) message = t("FREE_TRIAL_EXP_WARNING") + " ";
		else message = t("SUBSCRIPTION_EXP_WARNING") + " ";

		const daysDiff = getDaysDiff();

		if (daysDiff > 1) return message + t("IN") + " " + daysDiff + " " + t("DAYS");
		if (daysDiff == 1) return message + t("IN") + " " + daysDiff + " " + t("DAY");
		return message + t("TODAY");
	};

	const handleSubscrClick = (e) => {
		e.preventDefault();
		if (isTrial) return window.open("https://www.cemantica.com/Pricing");
		const url = subscriptionDetails.upgradeButtonLink.split("'")[1];
		return window.open(url);
	};

	return (
		<>
			{!isPreview && (
				<Header>
					<div className="d-flex ms-2">
						{/* {sessionId && <LogoHeader className={hideForPdf && "ms-2"} src={getterApis["GET_ACCOUNT_IMG"](sessionId, logoRefreshValue)} alt="logo" loading="lazy" />} */}
					</div>

					<div className="d-flex align-items-center me-2">
						{!hideForPdf && <NavigationSearchBar />}
						{subscriptionDetails && isSubscriptionCloseToEnd() && (
							<ExpirationWarningContainer isTrial={isTrial} className="pe-2 me-2">
								<ExpirationWarningText className="text-nowrap">{displayDaysDiff() + "..."}</ExpirationWarningText>
								<ExpirationWarningAction className="text-nowrap" onClick={handleSubscrClick} target="_blank">
									{isTrial ? t("ASPX_226") : t("EXTEND_NOW")}
								</ExpirationWarningAction>
							</ExpirationWarningContainer>
						)}
						<NeedHelpOptions innerRef={innerRef} hideForPdf={hideForPdf} />
						{!isStandard && <Notifications hideForPdf={hideForPdf} innerRef={innerRef} />}
						<InitialsOptions innerRef={innerRef} />
					</div>
				</Header>
			)}
		</>
	);
}

const Header = styled.div`
	background-color: #ffffff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: fixed;
	z-index: 150;
	height: 40px;
`;

const ExpirationWarningContainer = styled.div`
	display: flex;
	height: 30px;
	top: 0px;
	background: #bdecfb;
	border-radius: 4px;
	position: relative;
	${(props) =>
		props.isTrial &&
		`
        width: 380px;
   `}

	& ::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		border-width: 16px 0 0 16px;
		border-style: solid;
		border-color: #a1d7f1 #f8f9fa;
	}
`;

const ExpirationWarningText = styled.div`
	display: flex;
	justify-content: center;
	align-self: center;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #014f76;
	margin-left: 1.3rem;
`;

const ExpirationWarningAction = styled.button`
	display: flex;
	justify-content: center;
	align-self: center;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #014f76;
	cursor: pointer;
	border-bottom: 1px solid #014f76 !important;
`;
