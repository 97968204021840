import {
	getAllCxAction,
	getAllCxProgram,
	getAllInsightTypes,
	getAllInsights,
	getAllMapsListData,
	getAllOpportunity,
	getAllPersonas,
	getAllSolutions,
	groupInit,
	statusInit,
	tagInit,
	touchPointInit,
} from "@redux/index";
import { getDashboardData } from "features/analytics/reducer/analyticActions";
import { getAllProjects } from "features/projects/reducer/ProjectsActions";
import { useLocation } from "react-router-dom";
import DataLoader from "shared/components/data-loader";
import JourneyTrendsReports from "./JourneyTrendsReports";

function JourneyTrendsDashboard() {
	const isExportMode = useLocation().search.hideForPdf();

	return (
		<DataLoader
			asyncFns={[
				{ asyncFn: getAllMapsListData, parameter: { redirectReducer: "analytics" } },
				{ asyncFn: getAllPersonas, parameter: { redirectReducer: "analytics" } },
				{ asyncFn: getAllCxProgram, parameter: { redirectReducer: "analytics" } },
			]}
			reduxActions={[getAllOpportunity, getAllSolutions, getAllProjects, getAllCxAction, getAllInsights, getAllInsightTypes, touchPointInit, statusInit, groupInit, tagInit, getAllInsightTypes]}>
			<DataLoader asyncFns={[{ asyncFn: getDashboardData, parameter: { dashboardCode: "journeyTrends", isExportMode } }]}>
				<JourneyTrendsReports />
			</DataLoader>
		</DataLoader>
	);
}

JourneyTrendsDashboard.displayName = "JourneyTrendsDashboard";
export default JourneyTrendsDashboard;
