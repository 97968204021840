import { getMonthNumber, isBlanks } from "features/analytics/analyticsHelper";
import { getEmotionLevelData } from "@utils/helpers";
import { createSelector } from "reselect";

const currentData = (state) => state.analytics?.currentData || {};
const allTouchpoints = (state) => state.analytics?.all_touchpoints || {};
const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const topXSentimentByTouchpointDataSelector = createSelector([currentData], (currentData) => {
	const formatedData = [];
	currentData?.touchpoints?.forEach((touchpoint) => {
		if (!isBlanks(touchpoint)) {
			let totalContactInteractions = 0;
			const obj = { name: touchpoint.name, personaSentiment: 0, contactSentiment: 0 };
			const relatedPersonaInteractions = currentData?.personaInteractions.filter((interaction) => interaction.touchpointId === touchpoint.id);
			const relatedContactInteractions = currentData?.contactInteractions.filter((interaction) => interaction.touchpointId === touchpoint.id);

			relatedPersonaInteractions.forEach((interaction) => (obj.personaSentiment += interaction.sentimentLevel));
			relatedContactInteractions.forEach((interaction) => {
				const { sentimentLevel, numberOfInteractions } = interaction;

				obj.contactSentiment += sentimentLevel * numberOfInteractions;
				totalContactInteractions += numberOfInteractions;
			});

			obj.personaSentiment = obj.personaSentiment / relatedPersonaInteractions.length;
			obj.contactSentiment = obj.contactSentiment / totalContactInteractions;

			if (!obj.personaSentiment) obj.personaSentiment = 0;
			if (!obj.contactSentiment) obj.contactSentiment = 0;

			formatedData.push(obj);
		}
	});

	if (formatedData.length === 0) return [];
	// If 1 to 5 items, the chart looks weird so we fill it wih fake data
	if (formatedData.length < 5) {
		const toFill = 5 - formatedData.length;
		for (let i = 0; i < toFill; i++) formatedData.push({ name: "", personaSentiment: 0, contactSentiment: 0 });
	}

	return formatedData?.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
});

export const globalSentimentOverTimeDataSelector = createSelector([currentData, allTouchpoints], (currentData, allTouchpoints) => {
	const sentimentLevelByMonth = {};
	const formatedData = [];

	currentData?.contactInteractions?.forEach((interaction) => {
		const { sentimentLevel, numberOfInteractions, touchpointId, date } = interaction;
		const touchpoint = allTouchpoints?.find((tp) => tp.id === touchpointId);
		const month = getMonthNumber(new Date(date));

		if (month !== undefined && touchpoint) {
			if (!(month in sentimentLevelByMonth)) sentimentLevelByMonth[month] = { totalSentiment: sentimentLevel * numberOfInteractions, totalContactInteractions: numberOfInteractions };
			else {
				const { totalSentiment, totalContactInteractions } = sentimentLevelByMonth[month];
				sentimentLevelByMonth[month] = {
					totalSentiment: totalSentiment + sentimentLevel * numberOfInteractions,
					totalContactInteractions: totalContactInteractions + numberOfInteractions,
				};
			}
		}
	});

	const sentimentLevelByMonthSorted = Object.fromEntries(Object.entries(sentimentLevelByMonth)?.sort((a, b) => a[0] - b[0]));

	Object.keys(sentimentLevelByMonthSorted)?.forEach((monthNumber) => {
		formatedData.push({
			name: MONTHS[monthNumber],
			Value: (sentimentLevelByMonthSorted[monthNumber].totalSentiment / sentimentLevelByMonthSorted[monthNumber].totalContactInteractions).toFixed(2),
		});
	});

	return formatedData;
});

export const sentimentByEntityOverTimeDataSelector = createSelector(
	(state) => ({
		currentData: state.analytics?.currentData,
		allTouchpoints: state.analytics?.all_touchpoints,
	}),
	(state, props) => props,
	({ currentData, allTouchpoints }, props) => {
		// time : value
		const { entityName, relatedAttributeId } = props;
		const memory = {};
		const formatedData = [];

		// Select the contact interactions depending on the related selected (filtered) entities (personas/metrics)
		// Create a memory object in the following format :
		//{ "monthNumber1" : {
		//                      "relatedEntity1.id": { total: averageSentimentLevel, count: n },
		//                      "relatedEntity2.id": { total: averageSentimentLevel, count: n },
		//                  }
		//  "monthNumber2" : {...}
		//}
		currentData?.contactInteractions?.forEach((interaction) => {
			const { sentimentLevel, numberOfInteractions, touchpointId, date } = interaction;
			const touchpoint = allTouchpoints?.find((tp) => tp.id === touchpointId);
			const monthNumber = getMonthNumber(new Date(date));
			const relatedEntity = currentData[entityName]?.find((entity) => entity.id === interaction[relatedAttributeId]);

			if (touchpoint && relatedEntity && monthNumber !== undefined) {
				if (!(monthNumber in memory))
					memory[monthNumber] = { [relatedEntity.id]: { name: relatedEntity.name, totalSentiment: sentimentLevel * numberOfInteractions, totalContactInteractions: numberOfInteractions } };
				else {
					if (!(relatedEntity.id in memory[monthNumber]))
						memory[monthNumber] = {
							...memory[monthNumber],
							[relatedEntity.id]: { name: relatedEntity.name, totalSentiment: sentimentLevel * numberOfInteractions, totalContactInteractions: numberOfInteractions },
						};
					else {
						const { name, totalSentiment, totalContactInteractions } = memory[monthNumber][relatedEntity.id];
						memory[monthNumber] = {
							...memory[monthNumber],
							[relatedEntity.id]: {
								name,
								totalSentiment: totalSentiment + sentimentLevel * numberOfInteractions,
								totalContactInteractions: totalContactInteractions + numberOfInteractions,
							},
						};
					}
				}
			}
		});

		// Sort the object by months
		const sortedMemory = Object.fromEntries(Object.entries(memory)?.sort((a, b) => a[0] - b[0]));

		// Format the data for the report in the following format:
		// [{date: "Jan", entity.name1: sentimentLevel, entity.name2: sentimentLevel}, {date: "Feb", entity.name1: sentimentLevel, entity.name2: sentimentLevel}]
		Object.keys(sortedMemory)?.forEach((monthNumber) => {
			const obj = { date: MONTHS[monthNumber] };
			Object.keys(sortedMemory[monthNumber])?.forEach((entityId) => {
				const { totalSentiment, totalContactInteractions } = memory[monthNumber][entityId];
				obj[entityId] = totalSentiment / totalContactInteractions;
			});
			formatedData.push(obj);
		});

		return formatedData;
	}
);

export const SentimentGeolocalisationDataSelector = createSelector([currentData], (currentData) => {
	const formatedData = [];
	currentData?.contactInteractions?.forEach(({ id, sentimentLevel, gpsLatitude, gpsLongitude }) => {
		const { backgroundColor } = getEmotionLevelData({ level: Math.round(sentimentLevel) });
		if (gpsLatitude && gpsLongitude) formatedData.push({ id, latitude: gpsLatitude, longitude: gpsLongitude, sentimentLevel, fill: backgroundColor });
	});

	return formatedData;
});

export const wordCloudDataSelector = createSelector(
	(state) => ({
		currentData: state.analytics?.currentData,
	}),
	(state, props) => props,
	({ currentData }) => {
		const memory = {};
		const maxValue = 200;
		const minValue = 20;

		currentData?.contactInteractions?.forEach((interaction) => {
			interaction["descriptionWordCloud"]?.map((wordCloud) => {
				const value = wordCloud?.value;
				const text = wordCloud?.text?.toLowerCase();
				if (!memory[text]) memory[text] = value;
				else memory[text] = memory[text] + value;
			});
		});

		Object.keys(memory)?.forEach((text) => {
			const value = memory[text] * 15;
			if (value < minValue) memory[text] = minValue;
			if (value > maxValue) memory[text] = maxValue;
			else memory[text] = value;
		});

		// Transform the counter into a list
		const wordCloud = Object.entries(memory)?.map(([text, value]) => ({
			text,
			value,
		}));

		// Sort the list
		const sortedWordCloud = wordCloud?.sort((a, b) => b.value - a.value);

		return sortedWordCloud?.slice(0, 50);
	}
);
