import { NameDescriptionModal } from "shared/modals/basic/NameDescriptionModal";
import { ActionConfirmModal, IframeModal } from "shared/modals/index";
import { ActionsDropdown } from "shared/dropdowns/ActionsDropdown";
import { IconDispatcher } from "shared/Icons";
import { Flex, FlexAlignCenter, FlexBetweenAlignCenter } from "shared/ui-basics/index";
import { dateBodyTemplate } from "features/grid-table/components/TableSettings";
import { CustomEditor } from "shared/components/CustomEditor";
import { EditorProvider } from "shared/dynamic-imports";
import { EMPTY_GUID, User, encode, getInitials, getLastVersionNumber } from "@utils/helpers";
import { PermissionManager } from "@utils/permissionManager";
import { restoreMapVersion, updateMapVersion, deletePermanentlyMap } from "features/index";
import { base } from "index";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";

function VersionHistoryCard({ record, onClose }) {
	const { t } = useTranslation();

	const versionOwner = useSelector((state) => state.settings.authorizedUsers).find((user) => user.id === record?.createdByIdMapVersion);

	return (
		<CardContainer className="px-4 py-2">
			<div>
				<Flex>
					<Name>{record?.nameMapVersion}</Name>
					<VersionHistoryCardActions record={record} onClose={onClose} />
				</Flex>
				{record?.descriptionMapVersion && (
					<Description>
						<EditorProvider>
							<CustomEditor html={record?.descriptionMapVersion || ""} disabled={true} variant="font-12" />
						</EditorProvider>
					</Description>
				)}
			</div>

			{versionOwner && (
				<FlexAlignCenter gap="10">
					<InitialsBubble fullName={versionOwner?.fullName} size="s" />
					<UserName>{versionOwner?.fullName}</UserName>
				</FlexAlignCenter>
			)}

			<FlexBetweenAlignCenter>
				<Date className="p-1">{dateBodyTemplate(record?.createdOnMapVersion, "", "")}</Date>
				<Date className="p-1">{t("VERSION") + " ID: " + record?.numberMapVersion}</Date>
			</FlexBetweenAlignCenter>
		</CardContainer>
	);
}

const VersionHistoryCardActions = memo(({ record, onClose }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { cjmIdStr: cjmId, personaIdStr } = useLocation().search.getAllMapsParams();

	const allMaps = useSelector((state) => state.maps.all_maps);
	const userAccess = useSelector((state) => state?.maps?.currentUser?.roleName);

	const parentMapId = allMaps.find((map) => map.cjmId === cjmId)?.parentMapId;

	const { viewVersionAllowed, editVersionAllowed, restoreVersionAllowed, deleteVersionAllowed } = PermissionManager({
		userAccess,
		context: "editMap.header",
	});

	const [showIFrameModal] = useModal(IframeModal, {
		title: "PREVIEW",
		url: `${base}EditMap?cjmid=${record?.cjmId}&personaId=${personaIdStr}&isPreview=true`,
	});

	const [actionConfirmModal] = useModal(ActionConfirmModal);

	const [showUpdateVersionModal] = useModal(NameDescriptionModal, {
		title: "UPDATE_VERSION",
		buttonText: "APPLY",
		selectedItem: { name: record?.nameMapVersion, description: record?.descriptionMapVersion },
		handleAction: ({ item, onClose }) => {
			onClose();
			updateMapVersion({
				cjmId: record?.cjmId,
				name: encode(item.name),
				description: encode(item.description),
			});
		},
	});

	const redirect = (id) => {
		history.push(`/EditMap?cjmid=${id}&personaId=${personaIdStr}`);
	};

	const handleRestore = () => {
		onClose();
		restoreMapVersion({
			cjmId: record?.cjmId,
			versionNumber: getLastVersionNumber({ currentMapId: cjmId, allMaps, parentMapId }) + 1,
			onSuccess: (id) => redirect(id),
		});
	};

	const handleDeletePermanent = () => {
		actionConfirmModal({
			bodyContent: "ASPX_204",
			Action: () => deletePermanentlyMap({ id: record?.cjmId }),
			actionBtnText: "ASPX_13",
		});
	};

	const createActions = () => {
		const actions = [];
		if (viewVersionAllowed) actions.push({ handleClick: showIFrameModal, icon: "VIEW", text: t("VIEW") });
		if (editVersionAllowed) actions.push({ handleClick: showUpdateVersionModal, icon: "EDIT", text: t("EDIT") });
		if (restoreVersionAllowed) actions.push({ handleClick: handleRestore, icon: "RESTORE", text: t("JS_96") });
		// First version should not be deleted
		if (deleteVersionAllowed && record.parentMapId !== EMPTY_GUID) actions.push({ handleClick: handleDeletePermanent, icon: "DELETE", text: t("ASPX_13") });
		return actions;
	};

	return (
		<Actions id="actions-list">
			<ActionsDropdown id={cjmId} actions={createActions()} toggleButton={IconDispatcher("ACTION-DROPDOWN-HORIZONTAL")} autoClose="outside" />
		</Actions>
	);
});
VersionHistoryCard.displayName = "VersionHistoryCard";
VersionHistoryCardActions.displayName = "VersionHistoryCardActions";

export default VersionHistoryCard;

const Actions = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	z-index: 2;
	display: none;
`;

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: relative;
	padding: 0rem;
	background-color: #ffffff;
	border-bottom: 1px solid #c4c4c4;
	:hover ${Actions} {
		display: flex;
	}
`;

const Name = styled.div`
	color: #333;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
`;

const UserName = styled.div`
	color: #605f60;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Date = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
`;

const Description = styled.div`
	.p-editor-container .p-editor-content .ql-editor {
		padding: 0px;
	}
`;
