import * as signalR from "@microsoft/signalr";
import { createAction } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Bounce, toast } from "react-toastify";
import { methodMapFun } from "../methods/journey-map";
import { methodWorkspaceFun } from "../methods/workspace";
import { useTranslation } from "react-i18next";

export const useSignalR = ({ name, callback, message, methodFun }) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token)
    const userInfo = useSelector(({ auth }) => auth.userInfo);
    const { t } = useTranslation();

    useEffect(() => {

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`api/${name}`, {
                accessTokenFactory: () => {
                    return token;
                }
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Error)
            .build();
        if (message) {
            connection.on(message, methodFun);
        }
        const wrapperMethod = (methodName, args) => {
            methodWorkspaceFun(methodName, args, dispatch);
        }
        connection.on("OnWorkspaceUpdate", wrapperMethod);

        const setConnector = createAction("SET_CONNECTOR");
        connection.start().then(() => {
            if (callback) callback(connection);
            else {
                try {
                    if (connection && connection.state === signalR.HubConnectionState.Connected) {
                        connection?.invoke("UserLogin", userInfo?.UserId);
                    }
                } catch (error) {
                    console.error("Error sending message:", error);
                }
            }
            dispatch(setConnector({ connection }));

        })
        // // Handle the connection close event
        connection.onclose((error) => {
            try {
                const updateAction = createAction("ONLINE_USER_REGISTRY_LOGOUT_CLOSE");
                dispatch(updateAction({ userIds: [] }));
                toast(t("HUB_DISCONNECT"), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });

            } catch (err) {
                // console.error("Error sending message:", err);
            }
        });
        connection.onreconnecting((error) => {
            // console.error("Connection lost due to error. Reconnecting...", error);
            // Optionally, update UI to indicate reconnecting state
        });

        connection.onreconnected((connectionId) => {
            const setConnectorId = createAction("CONNECTION_RECONNECTED");
            dispatch(setConnectorId({ connectionId }));
            // Optionally, update UI to indicate reconnected state
        });
        const handleOffline = () => {
            if (connection.state === signalR.HubConnectionState.Connected) //the connection disable anyway-server (the user need to know that he noe connect)
            {
                connection.stop()
                    .catch(err => console.error('SignalR disconnection error: ', err));
            }
        };
        window.addEventListener('offline', handleOffline);
        return () => {
            window.removeEventListener('offline', handleOffline);

            connection.stop().then(() => {

            }).catch((err) => {
                console.error("Error disconnecting from SignalR:", err);
            });
        };

    }, []);
    return null;
}
//MyMethodAsync=OnMapUpdate
//eventWhenStart=UserLoginToMap
//eventWhenEnd=UserOutFromMap
// export const useHubConnection = ({ methodFun, myMethodAsync, eventWhenStart, eventWhenEnd, cjmIdStr, personaIdStr, ...props }) => {
//     const dispatch = useDispatch();
//     const connection = useSelector((state) => state.maps.connection) || null;

//     // console.log("methodFun", methodFun)
//     const help = (methodName, args) => {
//         methodFun(methodName, args, dispatch, cjmIdStr, personaIdStr)
//     }
//     console.log(myMethodAsync, eventWhenStart, eventWhenEnd, cjmIdStr)
//     useEffect(() => {
//         console.log("render user", connection?.state === signalR.HubConnectionState.Connected)
//         if (connection && connection.state === signalR.HubConnectionState.Connected) {
//             connection?.on(myMethodAsync, help);
//             connection?.invoke(eventWhenStart, cjmIdStr)
//         }
//         return () => {
//             connection?.invoke(eventWhenEnd, cjmIdStr);
//             connection?.off(myMethodAsync);
//         };
//     }, [connection]);
//     return null;

// }
export const useHubMapConnection = ({ forceReload }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { cjmIdStr, personaIdStr } = useLocation().search.getAllMapsParams();
    const connection = useSelector((state) => state.maps.connection) || null;
    const userInfo = useSelector((state) => state.auth.userInfo);
    const wrapperMethod = (methodName, args) => {
        methodMapFun(methodName, args, dispatch, cjmIdStr, personaIdStr, userInfo?.UserId, history, forceReload);
    }
    useEffect(() => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            connection?.on("OnMapUpdate", wrapperMethod);
            connection?.invoke("UserLoginToMap", cjmIdStr).catch(err => {
                // console.error("Error invoking UserLoginToMap:", err);
            });
        }
        return () => {
            if (connection && connection.invoke) {
                connection?.invoke("UserOutFromMap", cjmIdStr).catch(err => {
                    // console.error("Error invoking UserOutFromMap:", err);
                });
                connection?.off("OnMapUpdate");
            }
        };
    }, [connection]);
    return null;

}