
import styled, { css } from "styled-components/macro";
import { Button } from "shared/ui-basics/index";
import { NavLink } from "react-router-dom/cjs/react-router-dom";



export const BackButton = styled(Button)`
    position: absolute;
    top:0;
    left:0;
    border: 0 !important;
    z-index: 50;
`
export const Test = styled.div`
    width: 190px;
    height: 42px;
`

export const Title = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
`

export const InputWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const DetailsWrapper = styled.div`
    width: 80%;
`

export const WizardBag = styled.div`
    svg {
        width: 100%;
        height: 100%;
    }
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Mandatory = styled.div`
    color: #C4C4C4;
    font-style: italic;
`
export const Welcome = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`;

export const CrytalBall = styled.div`
	svg {
		width: 100%;
		height: 100%;
	}
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DefinePersonaText = styled.div`
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: #333333;
`;

export const SelectPersonaText = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	color: #605f60;
`;
export const TemplatesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const PersonaTemplateCard = styled.div`
	display: flex;
	flex-direction: column;
`;

export const GOTemplate = styled(NavLink)`
	color: #605f60 !important;

	:hover {
		text-decoration: underline;
	}
`;

export const EmptyContainer = styled.div`
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;
export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`;

export const Bird = styled.div`
	svg {
		width: 100%;
		height: 100%;
	}
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CarouselWrapper = styled.div`
	.p-carousel-indicators {
		display: none;
	}
	${(props) =>
        props.singleGrid === true &&
        css`
			.p-carousel-prev {
				display: none;
			}
			.p-carousel-next {
				display: none;
			}
		`}
`;

export const UploadImgWrapper = styled.div`
	border: 1px dashed #9e9292;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60%;
	height: 100%;
	max-height: 366px;

`;

export const PicturesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const PersonaPic = styled.span`
	flex-basis: 33.333333%;
	overflow: hidden;
	position: relative;
	margin: 0;
	background: none;
	border: 3px solid #fff;
	.img-bubble {
		z-index: 1;
		max-width: 100%;
		display: block;
		object-fit: cover;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		cursor: pointer;
		&.selected {
			border: 3px solid #f96209;
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		}
	}
`;

export const Check = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	display: none;
	${(props) =>
        props.checked === true &&
        css`
			display: block;
		`}
`;
