import React, { useRef, useState } from "react";
import { Token } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useClickOutside } from "shared/hooks/index";
import { EMPTY_GUID } from "@utils/helpers";
import * as Style from "./style";
import { FlexAlignCenter } from "shared/ui-basics/index";
import InitialsBubble from "features/settings/manage-users/components/initials-bubble";

export default function OwnerPickList({ object, setObject, disable, updateFunction, minHeight = "44" }) {
	const { t } = useTranslation();
	const ref = useRef();
	const refOut = useRef();

	const authorizedUsers = useSelector((state) => state.settings.authorizedUsers);
	const currentUser = useSelector((state) => state.maps.currentUser);
	const pathname = useLocation().pathname;

	const [selectedOwner, setSelectedOwner] = useState(GetSelectedOwner(object, authorizedUsers, currentUser, pathname));

	const handleChange = (select) => {
		const lastSelected = select.length > 0 ? [select[select.length - 1]] : [];
		ref.current.state.selected = lastSelected;
		setSelectedOwner(lastSelected);

		const newIdSelected = lastSelected?.[0]?.id || EMPTY_GUID;
		if (newIdSelected === object.ownerId) return;
		const updatedObject = { ...object, ownerId: newIdSelected };
		setObject(updatedObject);
		newIdSelected !== EMPTY_GUID && updateFunction(updatedObject, ["ownerId"]);
	};

	const handleClickOut = () => ref.current.blur();
	useClickOutside(refOut, handleClickOut);

	return (
		<Style.OwnerPickList id="OwnerPickList" ref={refOut}>
			<Style.Search
				minHeight={minHeight}
				checked={true}
				defaultSelected={selectedOwner}
				showPlaceholder={selectedOwner?.length < 1 ? true : false}
				id="public-methods-example"
				labelKey="fullName"
				options={authorizedUsers}
				onChange={handleChange}
				placeholder={selectedOwner?.length < 1 ? t("TAG_OWNER") : ""}
				ref={ref}
				multiple={true}
				owner="true"
				disabled={disable}
				renderMenuItemChildren={(option) => <div key={option.id}>{option?.fullName}</div>}
				renderToken={(option, { onRemove }, index) => (
					<CustomToken key={index} onRemove={onRemove} option={option} disabled={disable}>
						<FlexAlignCenter>
							<InitialsBubble fullName={option?.fullName} size="s" />
							<Style.OwnerName>{option?.fullName}</Style.OwnerName>
						</FlexAlignCenter>
					</CustomToken>
				)}
			/>
		</Style.OwnerPickList>
	);
}

const GetSelectedOwner = (opportunity, authorizedUsers, currentUser, pathname) => {
	const inMap = pathname === "/EditMap";
	const owner = opportunity?.ownerId !== "" ? authorizedUsers?.filter((user) => user.id === opportunity?.ownerId) : inMap ? [currentUser] : [];
	return owner;
};

const CustomToken = styled(Token)`
	align-content: center;
	background: var(--color-Grey-Background, #F5F5F5) !important;
	.rbt-token-label {
		background: var(--color-Grey-Background, #F5F5F5) !important;

	}

	.rbt-token-remove-button {
		visibility: hidden;
	}
	:hover {
		.rbt-token-remove-button {
			visibility: visible;
		}
	}
	input {
		max-width: 150px;
		min-width: 30px;
	}
`;
