import { constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta, ...props }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
		...props,
	});
};
const createPostAction = InitActionCreator({ baseUrl: "api/opportunity/", method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: "api/opportunity/", method: "GET" }); // api actions type get

export const getAllOpportunity = () => createGetAction({ type: "OPPORTUNITY_GET_ALL", endPoint: "getAll" });

export const createOpportunity = ({ item, cxProgramId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_OPPORTUNITY",
			endPoint: "create",
			data: {
				newCxActionParams: props?.item,
				newOpportunityParams: item,
				linkToCxProgramIdStr: cxProgramId || "",
			},
		}),
	});

export const copyOpportunity = ({ id, name, workspaceIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_OPPORTUNITY",
			endPoint: "copy",
			data: {
				srcOpportunityId: id,
				newSubject: encode(name),
				workspaceIds,
			},
		}),
		displayToast: "COPY",
	});

export const updateMultiOpportunity = ({ modifiedOpportunities, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_MULTI_OPPORTUNITY",
			endPoint: "updateMulti",
			data: {
				modifiedOpportunities: modifiedOpportunities,
				modifiedAttribNames: modifiedAttribNames,
			},
		}),
		displayToast: "UPDATE",
	});

export const updateOpportunity = ({ item, modifiedAttribNames, beforeModifiedOpportunity, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_OPPORTUNITY",
			endPoint: "update",
			data: {
				modifiedOpportunity: item,
				modifiedAttribNames: modifiedAttribNames,
			},
			params: item,
			beforeModifiedOpportunity: beforeModifiedOpportunity,
		}),
	});

export const archiveOpportunity = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_OPPORTUNITY",
			endPoint: "archive",
			data: {
				id,
			},
		}),
		displayToast: "ARCHIVE",
	});

export const archiveManyOpportunities = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_MANY_OPPORTUNITIES",
			endPoint: "archiveMany",
			data: {
				ids,
			},
		}),
	});

export const deleteOpportunity = ({ id, opp, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_OPPORTUNITY",
			endPoint: "delete",
			data: {
				id,
			},
			params: opp,
		}),
		displayToast: "ARCHIVE",
	});

export const deleteManyOpportunities = ({ ids, opp, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_OPPORTUNITIES",
			endPoint: "deleteMany",
			data: {
				ids,
			},
			params: opp,
		}),
	});

export const deleteOpportunityPermanently = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_OPPORTUNITY_PERMANENT",
			endPoint: "deletePermanently",
			data: {
				id,
			},
		}),
		displayToast: "DELETE",
	});

export const deleteManyOpportunitiesPermanently = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_MANY_OPPORTUNITIES_PERMANENT",
			endPoint: "deletePermanentlyMany",
			data: {
				ids,
			},
		}),
	});

export const restoreOpportunity = ({ id, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_OPPORTUNITY",
			endPoint: "restore",
			data: {
				id,
			},
		}),
		displayToast: "RESTORE",
	});

export const restoreManyOpportunities = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_MANY_OPPORTUNITIES",
			endPoint: "restoreMany",
			data: {
				ids,
			},
		}),
	});

export const updateOrderOpportunity = (newOrder) => ({
	type: "UPDATE_ORDER_OPPORTUNITY",
	payload: {
		newOrder: newOrder,
	},
});

export const updateStageOpportunityOrder = ({ stageId, cjmId, newOrder, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_STAGE_OPPORTUNITY_ORDER",
			endPoint: "updateStageOpportunitiesOrder",
			data: {
				stageId: stageId,
				cjmId: cjmId,
				newOpportunitiesOrder: newOrder,
			},
		}),
	});

export const getExcelExportListOpp = (ids, columns) =>
	createPostAction({
		type: "GET_EXCEL_OPP",
		endPoint: "getExcelExport",
		data: {
			ids: ids,
			columns: columns?.join("|"),
		},
		responseType: "blob",
	});

export const getPdfExportOpp = (width, height, mapIds, statusIds, groupIds) => createGetAction({
		type: "GET_OPP_PDF",
		endPoint: constructUrl("getPdfExport", {
			width,
			height,
			mapIds,
			statusIds,
			groupIds,
		}),
		responseType: "blob",
	});
