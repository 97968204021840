import CustomLegend from "features/analytics/Components/CustomLegend";
import { getAverageScore } from "features/analytics/analyticsHelper";
import { getEmotionLevelData } from "@utils/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SentimentGeolocalisationDataSelector } from "../vocAnalysisDataSelectors";

const minRadius = 12;

function SentimentGeolocalization() {
	const { t } = useTranslation();
	const data = useSelector(SentimentGeolocalisationDataSelector);
	const geoMapKey = useSelector((state) => state.settings.organization.geoMapKey);
	const [geoMap, setGeoMap] = useState("");

	const legendData = [
		{ color: "#A3DFDE", name: t("ASPX_80") },
		{ color: "#B9FBC0", name: t("ASPX_79") },
		{ color: "#FEE591", name: t("ASPX_78") },
		{ color: "#FCA57A", name: t("ASPX_77") },
		{ color: "#FF8B8B", name: t("ASPX_76") },
	];

	// A pushpin is a single point on the map
	const createPushpins = (data) => {
		return data.map((point) => {
			const customSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${minRadius * 2}" height="${minRadius * 2}">
                                    <circle cx="${minRadius}" cy="${minRadius}" r="${minRadius}" fill="${point.fill}" strokeWidth="0"/>
                                </svg>`;
			const location = new window.Microsoft.Maps.Location(point.latitude, point.longitude);
			const pushpin = new window.Microsoft.Maps.Pushpin(location, {
				icon: customSvg,
				anchor: new window.Microsoft.Maps.Point(minRadius, minRadius),
			});
			pushpin.sentimentLevel = point.sentimentLevel;
			return pushpin;
		});
	};

	// Here we manage the clustered (grouping) of multiple points
	function createCustomClusteredPin(cluster) {
		const outlineWidth = 7;

		const clusterText = cluster.getText();
		const clusterSize = cluster.containedPushpins.length;
		const averageSentimentLevel = getAverageScore({ data: cluster.containedPushpins, scoreAttribute: "sentimentLevel" });
		const { backgroundColor, textColor } = getEmotionLevelData({ level: Math.round(averageSentimentLevel) });

		//Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
		const radius = (Math.log(clusterSize) / Math.log(10)) * 5 + minRadius;
		let fillColor = backgroundColor;

		const getDiffValue = () => {
			if (clusterSize > 100) return 10;
			return 5;
		};

		//Create an SVG string of two circles, one on top of the other, with the specified radius and color.
		const customSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${radius * 2}" height="${radius * 2}">
                                <circle cx="${radius}" cy="${radius}" r="${radius}" fill="${fillColor}"/>
                                <circle cx="${radius}" cy="${radius}" r="${radius - outlineWidth}" fill="${fillColor}"/>
                                <text font-size="14" font-familly="Inter" fill="${textColor}" x="${radius - getDiffValue()}" y="${radius + 5}">${clusterText}</text>
                            </svg>`;

		//Customize the clustered pushpin using the generated SVG and anchor on its center.
		//Subtract 8 to compensate for height of text.
		cluster.setOptions({
			icon: customSvg,
			anchor: new window.Microsoft.Maps.Point(radius, radius),
			textOffset: new window.Microsoft.Maps.Point(0, radius - 8),
			text: "",
		});
	}

	const getInitialLocation = (data) => {
		let averageLatitude = 0;
		let averageLongitude = 0;
		data.forEach((item) => {
			averageLatitude += item.latitude;
			averageLongitude += item.longitude;
		});
		if (data?.length > 0) {
			averageLatitude /= data.length;
			averageLongitude /= data.length;
		}
		return { averageLatitude, averageLongitude };
	};

	const loadClusteringModule = (map, data) => {
		//Create a ClusterLayer and add it to the map.
		window.Microsoft.Maps.loadModule("Microsoft.Maps.Clustering", function () {
			const clusterLayer = new window.Microsoft.Maps.ClusterLayer(createPushpins(data), {
				clusteredPinCallback: createCustomClusteredPin,
			});
			map.layers.insert(clusterLayer);
		});
	};

	const loadMap = () => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = `https://www.bing.com/api/maps/mapcontrol?callback=initMap&key=${geoMapKey}`;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);

		window.initMap = () => {
			const { averageLatitude, averageLongitude } = getInitialLocation(data);
			const map = new window.Microsoft.Maps.Map("#SentimentGeolocalizationMap", {
				credentials: geoMapKey,
				center: new window.Microsoft.Maps.Location(averageLatitude, averageLongitude),
				mapTypeId: window.Microsoft.Maps.MapTypeId.road,
				showMapTypeSelector: false,
				zoom: 2,
				maxZoom: 8,
			});
			loadClusteringModule(map, data);
			setGeoMap(map);
		};
	};

	const updateMap = (data) => {
		geoMap.layers.clear();
		loadClusteringModule(geoMap, data);
	};

	useEffect(() => {
		loadMap();
		return () => {
			delete window.initMap;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoMapKey]);

	useEffect(() => {
		if (geoMap) updateMap(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Container>
			<div id="SentimentGeolocalizationMap" style={{ width: "90%", height: "100%" }}></div>
			<CustomLegend customClassNames="ms-4" data={legendData} />
		</Container>
	);
}

SentimentGeolocalization.displayName = "SentimentGeolocalization";
export default SentimentGeolocalization;

const Container = styled.div`
	display: flex;
	height: 100%;
`;
