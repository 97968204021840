import { addGroup, upsertTag } from "@redux/index";
import { EMPTY_GUID } from "@utils/helpers";
import TouchPoints from "features/library/view/library-touchpoints";
import _ from "lodash";
import { forwardRef, useLayoutEffect, useState } from "react";
import { Overlay } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ShowComponentModal } from "shared/modals/index";
import { IconDispatcher, SearchBar } from "shared/ui-basics/index";
import { useModal } from "use-modal-hook";
import { ColorTextCard } from "../../features/forms/personas/cards/ColorTextCard";
import PersonaBadge from "../../features/journey-map/cards/PersonaBadge";
import { BrandValueSelectCard } from "../components/cards/cx-cards/BrandValueSelectCard";
import { TouchpointSelectCard } from "../components/cards/cx-cards/TouchpointSelectCard";
import EditableItem from "../ui-basics/ui-components/EditableItem";
import InsightType from "../ui-basics/ui-components/InsightType";
import * as Style from "./ListPopover_style";
import { CreateGenericItem } from "./components/GenericItemCard";
import { useClickOutside } from "shared/hooks";
import { useRef } from "react";

const ListPopover = forwardRef((props, ref) => {
	const context = useListPopoverContext(props);
	const inneref = useRef(null);
	const { t } = useTranslation();

	const [search, setSearch] = useState("");
	const [placement, setPlacement] = useState("top");

	const [showModal] = useModal(ShowComponentModal, {
		title: context?.modalFooterTitle,
		Component: context?.modalFooter,
	});

	const { ListItemCard } = context;

	useLayoutEffect(() => {
		if (!ref.current) return;
		const targetPosition = ref.current?.getBoundingClientRect();
		const spaceBelow = window?.innerHeight - targetPosition?.bottom;
		const spaceAbove = targetPosition?.top;
		if (spaceBelow >= 300) {
			setPlacement("bottom");
		} else if (spaceAbove >= 300) {
			setPlacement("top");
		}
	}, [context.show]);

	const listData = context.listData;
	const filterAlsoByCategory = (item) => {
		const filteredCategoriesIds = context?.categories?.map((category) => category.name.toLowerCase().includes(search?.toLowerCase()) ? category.id : null)?.filter((item) => item !== null);
		return filteredCategoriesIds.includes(item.categoryId);
	}
	const filterbyName = (item) => item?.name?.toLowerCase()?.includes(search?.toLowerCase());

	const filteredList = listData?.filter(item => context?.categories ? filterAlsoByCategory(item)  || filterbyName(item): filterbyName(item));
	const showCreateComponent = !search.isEmptyString() && filteredList.length === 0;
	const handleChange = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setSearch(e.target.value);
	};

	const add = (e) => {
		const id = e.currentTarget.id;
		if (context?.relatedItems?.includes(id)) return;
		const item = listData?.find((item) => item[context?.idAttribute] === id);
		setSearch("");
		context.link(item);
	};

	const Search = SearchBar({
		globalFilterValue: search,
		handleChange,
		size: "small",
		label: context.labelSearch,
		createAction: showCreateComponent && context?.createFunction ? context?.createFunction : null,
	});

	const mapByCategory = (list) => {
		return context.categories?.map((category) => {
			const categoryList = list?.filter((l) => l.categoryId === category.id && category.isShown);
			if (categoryList?.length === 0) return null;
			return (
				<div key={category.name}>
					<Style.CustomLabel className="p-2">{t(category?.name)}</Style.CustomLabel>
					<div>
						{categoryList?.map((item) => (
							<div key={item[context?.idAttribute]} id={item[context?.idAttribute]} onClick={add} className="pointer">
								<ListItemCard
									objectId={item[context?.idAttribute]}
									object={item}
									itemSelectorPath={context.pathToItems}
									mode="display"
									disabled={context.relatedItems?.includes(item[context?.idAttribute])}
									selectable={context.selectable}
									selected={context?.selected}
								/>
							</div>
						))}
					</div>
				</div>
			);
		});
	};
	const onHide = () => {
		context?.setShow(false)
	}
	return (
		<div ref={inneref} onClick={(e) => e.stopPropagation()}>
			<Overlay show={context.show} target={ref.current} placement={placement} container={document.body} rootClose={true} onHide={onHide}>
				<Style.Pop id="popover-contained" variant={context.variant} style={{ position: "absolute", zIndex: 1000 }}>
					<Style.Body variant={context.variant}>
						<div className="p-2 position-relative">{Search}</div>
						<Style.ItemsContainer className="ms-2" variant={context.variant}>
							{context?.specificItems?.length > 0 && search.isEmptyString() && (
								<>
									<Style.CustomLabel className="p-2">{t("ALREADY_USED")}</Style.CustomLabel>
									<div>
										{context?.specificItems?.map((item) => (
											<div key={item[context?.idAttribute]} onClick={add} id={item[context?.idAttribute]} className="pointer">
												<ListItemCard
													objectId={item[context?.idAttribute]}
													object={item}
													itemSelectorPath={context.pathToItems}
													mode="display"
													disabled={context.relatedItems?.includes(item[context?.idAttribute])}
													classNames={context?.classNames}
												/>
											</div>
										))}
									</div>
								</>
							)}
							{context?.categories ? (
								mapByCategory(filteredList)
							) : (
								<>
									{search === "" && !context?.hideLabel && <Style.CustomLabel className="p-2">{t("ALL_ITEMS")}</Style.CustomLabel>}
									<div>
										{filteredList?.map((item) => (

											<div key={item[context?.idAttribute]} onClick={add} id={item[context?.idAttribute]} className="pointer">
												<ListItemCard
													objectId={item[context?.idAttribute]}
													object={item}
													itemSelectorPath={context.pathToItems}
													mode="display"
													disabled={context.relatedItems?.includes(item[context?.idAttribute])}
													selectable={context.selectable}
													selected={context?.selected}
													variant={context?.variant}
													link={context?.link}
													classNames={context?.classNames}
												/>
											</div>
										))}
									</div>
								</>
							)}
							{showCreateComponent && context?.createComponent && <div>{context.createComponent({ name: search, setGlobalFilterValue: setSearch })}</div>}
						</Style.ItemsContainer>
						{context?.variant === "interactions" && (
							<Style.Footer>
								<Style.ManageButton onClick={showModal}>
									{IconDispatcher("SETTINGS", "mx-1")} {t("ALL_TOUCHPOINTS")}
								</Style.ManageButton>
							</Style.Footer>
						)}
					</Style.Body>
				</Style.Pop>
			</Overlay>
		</div>
	);
});

const useListPopoverContext = (props) => {
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const touchpointCategories = [{ name: "ALL", id: EMPTY_GUID }, ...(useSelector((state) => state.libraries.touchPointCategories) || [])];

	const context = {
		addBtnText: "ADD_VALUE",
	};

	switch (props.type) {
		case "tag":
			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "libraries.tags";
			context.link = props.linkFunction;
			context.relatedItems = props.relatedItems;
			context.labelSearch = "SEARCH_CREATE_TAG_VALUE";
			context.ListItemCard = EditableItem;
			context.createComponent = ({ name, setGlobalFilterValue }) => (
				<CreateGenericItem title={name} upsertItem={(tag,onSuccess) => upsertTag({tag,onSuccess})} addItem={props.linkFunction} addBtnText="ADD_TAG" setGlobalFilterValue={setGlobalFilterValue} />
			);
			context.hideLabel = true;
			context.idAttribute = "id";
			context.classNames = "my-2";
			break;
		case "group":
			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "libraries.group";
			context.link = props.linkFunction;
			context.relatedItems = props.relatedItems;
			context.labelSearch = "SEARCH_CREATE_GROUP_VALUE";
			context.ListItemCard = EditableItem;
			context.createComponent = ({ name, setGlobalFilterValue }) => (
				<CreateGenericItem title={name} upsertItem={(newGroup,onSuccess) => addGroup({newGroup,onSuccess})} addItem={props.linkFunction} addBtnText="ADD_GROUP" setGlobalFilterValue={setGlobalFilterValue} />
			);
			context.hideLabel = true;
			context.idAttribute = "id";
			context.classNames = "my-2";
			break;
		case "touchpoint":
			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "libraries.touchPoint";
			context.specificItems = props.specificItems;
			context.link = props.linkFunction;
			context.relatedItems = props.relatedItems;
			context.labelSearch = "SEARCH_GENERIC";
			context.ListItemCard = TouchpointSelectCard;
			context.categories = touchpointCategories.slice(1, Infinity);
			context.selectable = props?.selectable || false;
			context.selected = props?.selected || [];
			context.variant = props?.variant;
			context.modalFooter = TouchPoints;
			context.modalFooterTitle = "ASPX_192";
			context.createFunction = props?.createFunction;
			context.idAttribute = "id";
			break;
		case "brandValue":
			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "libraries.brandValues";
			context.link = props.linkFunction;
			context.labelSearch = "SEARCH_GENERIC";
			context.ListItemCard = BrandValueSelectCard;
			context.idAttribute = "id";
			break;
		case "system":
		case "department":
			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "libraries.customLibraries";
			context.link = props.linkFunction;
			context.labelSearch = "SEARCH_GENERIC";
			context.ListItemCard = ColorCard;
			context.relatedItems = props.relatedItems;
			context.idAttribute = "id";
			break;
		case "persona":
			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "personas.all_personas";
			context.link = props.linkFunction;
			context.labelSearch = "SEARCH_GENERIC";
			context.ListItemCard = PersonaBadge;
			context.selectable = props?.selectable || false;
			context.selected = props?.selected || [];
			context.idAttribute = "personaId";
			context.relatedItems = props.relatedItems;
			break;
		case "insightTypes":

			context.show = props.show;
			context.setShow = props.setShow;
			context.pathToItems = "libraries.insightTypes";
			context.link = props.linkFunction;
			context.labelSearch = "SEARCH_GENERIC";
			context.ListItemCard = InsightType;
			context.selectable = props?.selectable || false;
			context.selected = props?.selected || [];
			context.idAttribute = "id";
			context.relatedItems = props.relatedItems;
			context.hideLabel = true;
			context.classNames = "my-2";


			break;
		default:
			break;
	}

	const listData = useSelector((state) => _.get(state, context.pathToItems));
	if (props.type === "department") {
		context.listData = listData.find((item) => item?.name === "Departments" && item.isCustom === false)?.values;
		return context;
	}
	if (props.type === "system") {
		context.listData = listData.find((item) => item?.name === "Systems" && item.isCustom === false)?.values;
		return context;
	}
	if (props.type === "persona") {
		context.listData = listData.getActiveRecords(!showSampleData);
		return context;
	}
	context.listData = listData;

	return context;
};

ListPopover.displayName = "ListPopover";
ColorTextCard.displayName = "ColorTextCard";
export { ListPopover };

const ColorCard = (props) => {
	const { object, disabled } = props;
	return (
		<ColorTextCard key={object?.id} text={object?.name} color={object?.colorCode} width="100%" height="2.25rem" fontSize="14px" className={"my-2 ps-1"} style={{ opacity: disabled ? "0.5" : "1" }} />
	);
};
