import { urlOriginStatus } from "@utils/optionsSets/OptionSets.js";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import { GenericButton } from "shared/ui-basics/index";
import styled from "styled-components";
import * as S from "./Style.js";

const GenericHeader = ({ createActions, children, backLabel, namePath, backPath, hide, disabled, userConnected }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { search, pathname } = useLocation();
	const hideForPdf = search.hideForPdf();
	const origin = search.getOrigin();
	const cxProgramId = search.getCxProgramID();
	const hideHeader = hideForPdf || hide;

	const name = useSelector((x) => _.get(x, namePath));

	const back = () => {
		if (cxProgramId !== "") {
			if (backPath) return history.push(backPath);

			return history.push(`/EditCxProgram?programid=${cxProgramId}&tab=relationship`);
		}
		if (origin == urlOriginStatus.DefaultReturn) {
			return history.goBack();
		}
		if (backPath) return history.push(backPath);

		history.goBack();
	};

	return (
		<S.Container>
			{!hideHeader && (
				<S.RowDash className=" mx-0" disabled={disabled}>
					<S.CenterCol xs={6} xxl={8}>
						<div className="d-flex align-items-center">
							<GenericButton className="m-2" variant="light-link" icon={"ARROW_LEFT"} onClick={back} />
							<S.CustomBreadcrumb>
								<BreadcrumbItem>
									<S.Path>{t(backLabel)}</S.Path>
								</BreadcrumbItem>
								<BreadcrumbItem>
									<S.Path title={name}>{name}</S.Path>
								</BreadcrumbItem>
							</S.CustomBreadcrumb>
						</div>
					</S.CenterCol>
					<S.ColEnd xs={6} xxl={4}>
						{userConnected && userConnected}
						{createActions && createActions()}
					</S.ColEnd>
				</S.RowDash>
			)}
			<S.RowWrapper className="mx-0 " height={["/editmap"].includes(pathname.toLowerCase()) ? "100px" : "122px"}>
				{children}
			</S.RowWrapper>
		</S.Container>
	);
};
export default GenericHeader;

const SamplePopupWrapper = styled.div`
	position: absolute;
	top: 4px;
	right: 0px;
	z-index: 9999 !important;
`;
