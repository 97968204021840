import EmotionLevel from "shared/ui-basics/ui-components/EmotionLevel";
import { Counter } from "features/analytics/Components/Counter";
import { isBlanks } from "features/analytics/analyticsHelper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import styled, { css } from "styled-components/macro";

function VOCAnalysisCounter() {
	const { t } = useTranslation();
	const data = useSelector((state) => state.analytics?.currentData);

    const getPersonaSentimentLevel = (interactions) => {
		if (!interactions || interactions?.length === 0) return 0;
		let total = 0;
		interactions?.forEach((interaction) => {
			total += interaction.sentimentLevel;
		});
		const res = (total / interactions?.length).toFixed(2);
		if (res === 0) return 0;
		return res;
    };

    const getContactSentimentLevel = (interactions) => {
        if (!interactions || interactions?.length === 0) return 0;

        let totalWeight = 0
        let total = 0;

        interactions?.forEach((interaction) => {
            total += (interaction.sentimentLevel * interaction?.numberOfInteractions);
            totalWeight += interaction?.numberOfInteractions
		});

		const res = (total / totalWeight).toFixed(2);
		if (res === 0) return 0;
		return res;
	};

	const createCounters = () => {
		const personasLevel = getPersonaSentimentLevel(data?.personaInteractions);
		const customerLevel = getContactSentimentLevel(data?.contactInteractions);

		return [
			{ icon: "BULLSEYES-POINTER", value: data?.touchpoints?.filter(item => !isBlanks(item))?.length || 0, nameComponent: () => <>{t("TOUCHPOINTS")}</> },
			{ icon: "CHART-LINE-UP-THIN", value: data?.metrics?.filter(item => !isBlanks(item))?.length || 0, nameComponent: () => <>{t("JS_130")}</> },
			{
				iconComponent: () => <EmotionLevel variant="smiley" level={Math.round(personasLevel)} />,
				value: personasLevel,
				nameComponent: () => <>{t("PERSONAS_INTERACTION")}</>,
			},
			{ iconComponent: () => <EmotionLevel variant="smiley" level={Math.round(customerLevel)} />, value: customerLevel, nameComponent: () => <>{t("CUSTOMER_FEEDBACK")}</> },
		];
	};

	const counters = createCounters();

	return (
		<CounterContainer className="p-4">
			<Row>
				<Inner border>
					{counters?.slice(0, 2).map((counterData, i) => (
						<Counter key={i} data={counterData} />
					))}
				</Inner>
				<Inner>
					{counters?.slice(2, 4).map((counterData, i) => (
						<Counter key={i} data={counterData} />
					))}
				</Inner>
			</Row>
		</CounterContainer>
	);
}

VOCAnalysisCounter.displayName = "VOCAnalysisCounter";
export default VOCAnalysisCounter;

const CounterContainer = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 4px;
	background: #fff;
	border: 1px solid #c4c4c4;
	${(props) =>
		props.delimiter &&
		css`
			border-right: 1px solid #efefef;
		`}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 50%;
	height: 100%;
	${(props) =>
		props.border &&
		css`
			border-right: 1px solid #efefef;
		`};
`;
