/* eslint-disable no-extend-native */

import { t } from "i18next";
import { toast } from "react-toastify";
import { EMPTY_GUID, decode, hasAttribute, widthValidation } from "./helpers";
import { StateTypeOption, StatusCode } from "./optionsSets/OptionSets";

String.prototype.truncate =
	String.prototype.truncate ||
	function (n, showToast = false) {
		if (this.length > n && showToast) {
			toast.info(t("EXCEEDED_MAX_SIZE_INPUT"));
		}
		return this?.slice(0, n);
	};
Array.prototype.sortAsc =
	String.prototype.sortAsc ||
	function (attribute) {
		return this.sort((a, b) => (a[attribute]?.toLowerCase() > b[attribute]?.toLowerCase() ? 1 : -1));
	};
Array.prototype.sortDesc = function (attribute) {
	return this.sort((a, b) => (a[attribute]?.toLowerCase() < b[attribute]?.toLowerCase() ? 1 : -1));
};
String.prototype.isInMap =
	String.prototype.isInMap ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const onMap = query.get("isinmap") || "0";
		const isInMap = onMap === "1";
		return isInMap;
	};
String.prototype.getUrl =
	String.prototype.isInMap ||
	function () {
		return new URLSearchParams(this.toLowerCase());
	};
String.prototype.hideForPdf =
	String.prototype.hideForPdf ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const isPrint = query.get("isprint") || "0";
		const hideForPdf = isPrint === "1";
		return hideForPdf;
	};
String.prototype.toPrint =
	String.prototype.toPrint ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const isPrint = query.get("toprint") || "0";
		const toPrint = isPrint === "1";
		return toPrint;
	};
String.prototype.getPersonaId =
	String.prototype.getPersonaId ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const personaId = query.get("personaid") || query.get("personaidstr");
		return personaId;
	};
String.prototype.getCjmId =
	String.prototype.getCjmId ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const cjmIdStr = query.get("cjmid");
		return cjmIdStr;
	};
String.prototype.getStrategyId =
	String.prototype.getStrategyId ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const strategyId = query.get("strategyid") || query.get("strategyidstr");
		return strategyId;
	};
Array.prototype.getSection =
	Array.prototype.getSection ||
	function (sectionId) {
		return this?.map((group) => group?.filter((attribute) => attribute.sectionId === sectionId)).flat()[0] || {};
	};
String.prototype.isReadOnly =
	String.prototype.isReadOnly ||
	function () {
		const readOnlyCode = "100000003";
		return this === readOnlyCode;
	};
String.prototype.getChartSelection =
	String.prototype.getChartSelection ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const chartselection = query.get("chartselection")?.split("|") || "null";
		return chartselection;
	};
//personaschartselection
String.prototype.getPersonaChartSelection =
	String.prototype.getPersonaChartSelection ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const personaschartselection = query.get("personaschartselection")?.split("|") || "null";
		return personaschartselection;
	};
String.prototype.isPrint =
	String.prototype.isPrint ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const isPrint = query.get("isprint") || "0";
		return isPrint;
	};
String.prototype.isInactive =
	String.prototype.isInactive ||
	function () {
		const isInactiveFromTrash = this === "/trash" || this === "/archive";

		return isInactiveFromTrash;
	};

String.prototype.getInitials =
	String.prototype.getInitials ||
	function () {
		const firstName = this?.split(" ")[0];
		const lastName = this?.split(" ")[1];
		const firstInitial = firstName?.[0];
		const lastInitial = lastName?.[0];
		const initials = (firstInitial ?? "") + (lastInitial ?? "");
		return initials;
	};

Boolean.prototype.getEntityCurrentState = function () {
	return this === true ? StateTypeOption.FutureState : StateTypeOption.CurrentState;
};
Number.prototype.setEntityCurrentState = function () {
	return this === StateTypeOption.FutureState ? true : false;
};
Array.prototype.Order =
	Array.prototype.Order ||
	function () {
		const ordered = this?.sort((a, b) => a?.order - b?.order);
		return ordered;
	};

String.prototype.isPreview =
	String.prototype.isPreview ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const ispreview = query.get("ispreview") === "true";
		return ispreview;
	};
String.prototype.getAllowedPath =
	String.prototype.getAllowedPath ||
	function (authorized) {
		return authorized.includes(this);
	};
String.prototype.getAuthenticationProperties =
	String.prototype.getAuthenticationProperties ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const userEmail = query.get("useremail");
		const userEmail2 = query.get("useremail2");
		const lcid = query.get("lcid");
		const envName = window.location.pathname.split("/")[1];

		const body = {
			userName: query.get("username"),
			orgName: query.get("orgname"),
			secret: query.get("secret"),
			envName: envName,
		};
		if (lcid) body.lcid = lcid;
		if (userEmail) body.userEmail = userEmail;
		if (userEmail2) body.userEmail2 = userEmail2;

		return body;
	};
String.prototype.getTargetUrl =
	String.prototype.getTargetUrl ||
	function () {
		const query = new URLSearchParams(this);
		const targetUrl = query.get("tgtUrl") || "";
		const decodedUrl = decode(targetUrl);
		return decodedUrl !== "" ? decodedUrl : null;
	};
String.prototype.getCxProgramID =
	String.prototype.getCxProgramID ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const id = query.get("programid") || query.get("programidstr") || query.get("cxprogramid") || "";
		return id;
	};
String.prototype.formatColor =
	String.prototype.formatColor ||
	function () {
		return this.split(";")[0]?.split(":")[1]?.trim();
	};
String.prototype.getId =
	String.prototype.getId ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const id = query.get("id");
		return id;
	};
String.prototype.getQuery =
	String.prototype.getQuery ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const queryStr = query.get("query");
		return queryStr;
	};
String.prototype.getCxActionsProps =
	String.prototype.getCxActionsProps ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const selectedItemId = query.get("id");
		const stageId = query.get("stageid");
		const cjmId = query.get("cjmid");
		const inMap = query.get("inmap");

		return { selectedItemId, stageId, cjmId, inMap };
	};
String.prototype.getOrigin =
	String.prototype.getOrigin ||
	function () {
		const query = new URLSearchParams(this);
		const origin = query.get("origin");
		if (origin) {
			const newOr = decode(origin) || "";
			return newOr;
		}
		return null;
	};
String.prototype.getTab =
	String.prototype.getTab ||
	function () {
		const query = new URLSearchParams(this);
		const tab = query.get("tab");
		return tab;
	};
// Array.prototype.defaultValuesLayout = String.prototype.defaultValuesLayout || function () {

//  this.map((item, index) => {

//     let w = 1, h = 60, x = 0, y = index * 60;
//     return { i: item, x: x, y: y, w: w, h: h }

// });
// }
String.prototype.getTabName = function () {
	const query = new URLSearchParams(this.toLowerCase());
	const tab = query.get("tab");
	return { tab };
};
String.prototype.getMapsParams =
	String.prototype.getMapsParams ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const cjmIdStr = query.get("cjmidstr") || query.get("cjmid");
		const personaIdStr = query.get("personaidstr") || query.get("personaid");

		return { cjmIdStr, personaIdStr };
	};
String.prototype.mapName =
	String.prototype.mapName ||
	function () {
		const query = new URLSearchParams(this);
		const mapName = query.get("origin") || "";
		return decode(mapName);
	};
Array.prototype.ArrayToIdsStr =
	Array.prototype.ArrayToIdsStr ||
	function (dataKey = "id") {
		return this?.map((x) => x[dataKey]).join("|");
	};

Array.prototype.FormatFilterArray =
	Array.prototype.FormatFilterArray ||
	function (attribute1, attribute2) {
		const att1 = attribute1 === undefined ? "id" : attribute1;
		const att2 = attribute2 === undefined ? "name" : attribute2;
		return this.map((gp) => {
			return { value: gp[att1], name: gp[att2] };
		});
	};
Array.prototype.FormatDropDownArray =
	Array.prototype.FormatDropDownArray ||
	function () {
		return JSON.parse(JSON.stringify(this).replaceAll('"name"', '"value"'));
	};
String.prototype.getAllMapsParams =
	String.prototype.getAllMapsParams ||
	function () {
		const query = new URLSearchParams(this.toLowerCase());
		const isLoading = query.get("loading") === "true";
		const cjmIdStr = query.get("cjmid");
		const isExporting = query.get("isexporting") === "true";

		const personaIdStr = query.get("personaid") || "";
		const swimLaneIds = query.get("swimlaneids")?.split("|") || "";
		const isPrint = query.get("isprint") || "0";
		const hideForPdf = isPrint === "1";
		const isPreview = query.get("ispreview") === "true";
		const origin = query.get("origin");

		return { cjmIdStr, personaIdStr, swimLaneIds, isLoading, hideForPdf, isPreview, isExporting, origin };
	};
String.prototype.getBackQuery = function () {
	const queryOriginal = new URLSearchParams(this);
	const queryNb = queryOriginal.get("b");
	return queryNb;
};
String.prototype.isEmpty = function () {
	return this.length === 0 || !this.trim();
};

Array.prototype.cumulateWidth =
	String.prototype.cumulateWidth ||
	function () {
		return this.reduce((acc, obj) => acc + widthValidation(obj.width), 0) + (this?.length - 1) * 8;
	};
String.prototype.isEmptyGuid =
	String.prototype.isEmptyGuid ||
	function () {
		return this === EMPTY_GUID;
	};
String.prototype.isEmptyString = function (also) {
	if (also) return this.trim().length === 0 || this.trim() === also;
	return this.trim().length === 0;
};
String.prototype.guidValidation = function () {
	return this.trim() === "" ? EMPTY_GUID : this;
};
String.prototype.getCxProps = function (localQuery) {
	const queryOriginal = new URLSearchParams(this);
	const query = new URLSearchParams(this.toLowerCase());
	const cxProgramId = query.get("programid") || query.get("programidstr") || query.get("cxprogramid") || "";
	let cjmId = query.get("cjmid");
	let personaId = query.get("personaid");
	const selectedItemId = query.get("id") || localQuery?.id;
	const originEncoded = queryOriginal.get("origin");

	const displayList = queryOriginal.get("displayList") === "true" || (localQuery?.id && "true");
	const queryNb = queryOriginal.get("q") || localQuery?.q;
	let origin = null;

	if (originEncoded) {
		const decodedParams = decode(originEncoded)?.split("|");
		origin = decodedParams[0] || "";
		cjmId = decodedParams?.[1];
		personaId = decodedParams?.[2];
	}
	return { selectedItemId, cxProgramId, origin, queryNb, displayList, cjmId, personaId };
};
String.prototype.formatDate =
	String.prototype.formatDate ||
	function () {
		return this?.split("T")[0] || new Date().toISOString().split("T")[0];
	};
Array.prototype.extractKey = function () {
	return this.map((swimlane) => swimlane.key);
};
String.prototype.isEnterpriseSubscriptionAllowed = function () {
	return ["Enterprise"].includes(this);
};
String.prototype.defaultPlaceholder = function (label) {
	if(this === null || this === undefined) return t(label);
	return this?.isEmptyString() ? t(label) : this;
};
String.prototype.extractIdsChart = function () {
	const query = new URLSearchParams(this.toLowerCase());
	const hideForPdf = this.hideForPdf();
	const mapIdsPrint = query.get("mapids") || "";
	const statusIdsPrint = query.get("statusids") || "";
	const groupIdsPrint = query.get("groupids") || "";
	return {
		statusIds: hideForPdf && statusIdsPrint !== "" ? [...statusIdsPrint?.split("|")] : [],
		mapIds: hideForPdf && mapIdsPrint !== "" ? [...mapIdsPrint?.split("|")] : [],
		groupIds: hideForPdf && groupIdsPrint !== "" ? [...groupIdsPrint?.split("|")] : [],
		hideForPdf,
	};
};
String.prototype.getFolderId = function () {
	const query = new URLSearchParams(this.toLowerCase());
	const folderId = query.get("folderid") || undefined;

	return folderId;
};
Array.prototype.getActiveRecords = function (checkSample = true, disabledFilter) {
	if (disabledFilter) return this;
	const hasStatusCode = hasAttribute(this?.[0], "statusCode");
	const hasSample = checkSample && hasAttribute(this?.[0], "isSample");
	const hasTemplate = hasAttribute(this?.[0], "isTemplate");
	if (hasStatusCode) return this.filter((record) => record.statusCode === StatusCode.Active && (hasSample ? record.isSample === false : true) && (hasTemplate ? record.isTemplate === false : true));

	return [];
	// this.filter((record) => {
	// 	if (hasSample && checkSample) return record.isSample === false && record.isInactive === false && record.isArchived === false && (hasTemplate ? record.isTemplate === false : true);
	// 	return record.isInactive === false && record.isArchived === false && (hasTemplate ? record.isTemplate === false : true);
	// });
};
Array.prototype.getMainFolders = function () {
	return this.filter((folder) => folder.parentId === EMPTY_GUID);
};
String.prototype.StringToArray = function () {
	return this.split("|")?.filter((element) => element !== "");
};
Array.prototype.searchFilter = function (searchValue, attribute1, attribute2) {
	const defaultAttribute = hasAttribute(this?.[0], "name") ? "name" : "subject";

	return this.filter((element) => {
		// If both attributes are provided, search in both
		if (attribute1 && attribute2) {
			return element?.[attribute1]?.toLowerCase().includes(searchValue?.toLowerCase()) || element?.[attribute2]?.toLowerCase().includes(searchValue?.toLowerCase());
		}
		// If only attribute1 is provided, search in attribute1
		else if (attribute1) {
			return element?.[attribute1]?.toLowerCase().includes(searchValue?.toLowerCase());
		}
		// If only attribute2 is provided, search in attribute2
		else if (attribute2) {
			return element?.[attribute2]?.toLowerCase().includes(searchValue?.toLowerCase());
		}
		// If no attributes are provided, search in the default attribute
		else {
			return element?.[defaultAttribute]?.toLowerCase()?.includes(searchValue?.toLowerCase());
		}
	});
};
Array.prototype.getById = function (userId, attribute = "id") {
	return this.find((element) => element?.[attribute] === userId);
};
String.prototype.IsViewer = function () {
	return this?.toLowerCase() === "viewer";
};
String.prototype.IsContributor = function () {
	return this?.toLowerCase() === "contributor";
};
Array.prototype.remove = function (filterItem, attribute = "id") {
	// 1- if filter item is a array, no need to pass attribute
	if (typeof filterItem === "object") return this.filter((element) => !filterItem.includes(element));
	// 2 - Classic filter
	return this.filter((element) => element?.[attribute] !== filterItem);
};

Array.prototype.add = function (value) {
	if (!this) return [value];
	return [...this, value];
};
String.prototype.getLoading = function () {
	const query = new URLSearchParams(this.toLowerCase());
	const isLoading = query.get("loading") === "true";
	return isLoading;
};

String.prototype.getWorkspaceProps = function (localQuery) {
	const query = new URLSearchParams(this.toLowerCase());
	const mode = query.get("mode");
	const selectedItemId = query.get("id") || localQuery?.id;
	return { mode, selectedItemId };
};

String.prototype.getInvalidWorkspaceProps = function () {
    const query = new URLSearchParams(this.toLowerCase());
    const statusCode = query.get("statuscode");

	return { statusCode };
};

String.prototype.getFiltersId = function () {
	const query = new URLSearchParams(this.toLowerCase());
	const id = query.get("filtersid") || undefined;

	return id;
};

String.prototype.getErrorMessages = function () {

		const query = new URLSearchParams(this);
		const messageEncoded = query.get("type")
		if(messageEncoded === null) return;
		const message = decode(messageEncoded);
		return message;
	};