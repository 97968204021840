import { EMPTY_GUID } from "@utils/helpers";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GenericButton, Input, RadioButton } from "shared/ui-basics";
import InsightType from "shared/ui-basics/ui-components/InsightType";
import styled from "styled-components/macro";

const InsightCreator = ({ searchValue, handleSearchChange, handleCreate }) => {
	const { t } = useTranslation();
	const textareaRef = useRef(null);

	const insightTypes = useSelector((state) => state?.libraries?.insightTypes);
	const [selectedTypeId, setSelectedTypeId] = useState(localStorage.getItem("lastUsedInsightTypeId") || null);

	useLayoutEffect(() => {
		if (textareaRef.current) {
			// Set focus to the textarea and move the cursor to the end of the text
			textareaRef.current.focus();
			textareaRef.current.selectionStart = textareaRef.current.selectionEnd = searchValue.length;
		}
	}, [searchValue]);

	const InsightTypeSelectionCard = ({ insightType }) => (
		<Card onClick={() => setSelectedTypeId(insightType.id)}>
            <RadioButton selected={selectedTypeId === insightType.id} />
            <InsightType typeId={insightType?.id}/>
		</Card>
	);

	return (
		<Container>
			<Body>
				<Top>
					<InputContainer>
						<Input innerRef={textareaRef} variant="default" type="textarea" name="name" onChange={handleSearchChange} value={searchValue} style={{ resize: "none" }} />
					</InputContainer>
					<ListContainer>
						{insightTypes?.map((it, i) => (
							<InsightTypeSelectionCard key={i} insightType={it} />
						))}
					</ListContainer>
				</Top>
				<GenericButton variant="dark" icon="PLUS" onClick={() => handleCreate({ searchValue, typeId: selectedTypeId })}>
					{t("ASPX_108")}
				</GenericButton>
			</Body>
		</Container>
	);
};

InsightCreator.displayName = "InsightCreator";
export default InsightCreator;

const Container = styled.div``;

const Body = styled.div`
	margin: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 740px;
	overflow: hidden;
`;

const InputContainer = styled.div`
`;

const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-height: 200px;
	overflow:scroll;
`;

const Card = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	:hover {
		cursor: pointer;
	}
`;
