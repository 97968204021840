import {
	Authenticate,
	Authentication,
	InvalidPage,
	LoginPage,
	Logout
} from "features/index";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

import WorkspaceInvalidPage from "features/workspace/view/workspace-invalid-page";
import PrivateRoutesValidation from "shared/Routes/private-routes-validation";

export default function App() {
	return (
		<>
			<Switch>
				<Route exact path="/authenticate" component={Authentication} />
				<Route exact path="/logout" component={Logout} />
                <Route exact path="/invalid-url" component={InvalidPage} />
                <Route exact path="/invalid-workspace" component={WorkspaceInvalidPage} />
				<Route exact path="/login" component={Authenticate} />
				<Route exact path="/authenticationError" component={InvalidPage} />
				<Route exact path="/login-page" component={LoginPage} />
				<PrivateRoutesValidation />
			</Switch>
			<ToastContainer theme="light" pauseOnFocusLoss={false} newestOnTop={true} autoClose={5000} hideProgressBar={true} closeOnClick position="top-right"
 />
		</>
	);
}

App.propTypes = {
	history: PropTypes.object,
};
