import cursor from "@Assets/All/cursorComments.svg";
import { Colors, IconDispatcher } from "shared/ui-basics/index";
import { setAllowSectionsOverFlow } from "@redux/pageReducer";
import { EMPTY_GUID, isDarkColor } from "@utils/helpers";
import $ from "jquery";
import debounce from "lodash.debounce";
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import "react-quill/dist/quill.bubble.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { useEditor } from "../dynamic-imports";
import { useClickOutside } from "shared/hooks";

 const CustomEditor = memo(
    ({
        id = "editor_" + uuid(),
        html,
        sethtml,
        disabled = false,
        autoSave,
        onBlur,
        style,
        placeholder = "",
        className,
        variant,
        onFocus,
        hideToolbar,
        allowMention = false,
        mentionFunction,
        autoFocus = false,
        background,
        suggestions

    }) => {
        const [showToolbar, setShowToolbar] = useState(false);
        const colors = ["#FF0000", "#0000FF", "#FFAA4D", "#FFF56D", "#99FFCD", "#99FEFF", "#9FB4FF", "#BC6FF1", "#FDB9FC", "#FF79CD", "#FFFFFF", "#333333"];
        const editorRef = useRef();
        const editorContainerRef = useRef();

        const pathname= useLocation().pathname;
        const users = useSelector((state) =>
            state.maps?.current_linked_users?.filter(u => u.roleId !== EMPTY_GUID) ?.map((user) => {
                return { id: user.id, value: user.fullName };
            })
        );

        const isDragging = useSelector((state) => state.comments?.isDragging);
        const Editor = useEditor(); // this is because the import takes to much memory and we use it in many places in the same page
        const modules = {
            history: {
                delay: 1000,
                userOnly: false,
            },


        };
        const mentionModule = {
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ["@", "#"],
                source: function (searchTerm, renderList, mentionChar) {
                    if (!allowMention) return;
                    let values;

                    if (mentionChar === "@") {
                        values = users;
                    } else {
                        values = users;
                    }

                    if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                    } else {
                        const matches = [];
                        for (let i = 0; i < values.length; i++) if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                        renderList(matches, searchTerm);
                    }
                },
            },
        }
        if(allowMention && modules["mention"] === undefined) modules["mention"] = mentionModule.mention;

        useLayoutEffect(() => {
            $(`#${id}`).on({
                copy: function (e) {
                    navigator.clipboard.readText().then((clipText) => {
                        window.copyGetText = clipText;
                    });
                },
                cut: function (event) {
                    navigator.clipboard.readText().then((clipText) => (window.copyGetText = clipText));
                },
            });
        }, []);

        const renderHeader = () => {
            return (
                <ToolbarContainer id="toolbar" className="ql-formats position-relative" mounted={editorRef.current !== undefined} >
                    <button className="ql-bold" aria-label="Bold" title="Bold"></button>
                    <button className="ql-italic" aria-label="Italic" title="Italic"></button>
                    <button className="ql-underline" aria-label="Underline" title="Underline"></button>
                    <button className="ql-link" aria-label="Insert Link" title="Insert Link"></button>
                    <button className="ql-clean" title="Clean" aria-label="clean"></button>
                    <button className="ql-list" value="ordered" title="Ordered List" aria-label="Ordered List"></button>
                    <button className="ql-list" value="bullet" title="Unordered List" aria-label="Unordered List"></button>
                    <button className="ql-direction" value="rtl" title="Right-to-Left"></button>
                    <button className="ql-indent" value="-1" title="Decrease Indent"></button>
                    <button className="ql-indent" value="+1" title="Increase Indent"></button>

                    <select className="ql-align position-relative" id="align" title="Alignment"></select>
                    <select className="ql-color position-relative" title="Text Color">
                        {colors.map((col) => {
                            return <option key={col} value={col} />;
                        })}
                    </select>
                    <select className="ql-background position-relative" title="Background Color">
                        {colors.map((col) => {
                            return <option key={col} value={col} />;
                        })}
                    </select>
                    <button onClick={() => setShowToolbar(false)} title="Close">
                        {IconDispatcher("X-MARK", " mx-1")}
                    </button>
                </ToolbarContainer>
            );
        };
        const handlePaste = (event) => {
            event.preventDefault();
            const html = event.clipboardData.getData("text/html");
            const text = event.clipboardData.getData("text");
            const quill = editorRef.current.getQuill();
            let range = quill.selection.savedRange;

            if (window.copyGetText === text) {
                if (range.length > 0) {
                    quill.deleteText(range.index, range.length);
                }
                setTimeout(() => {
                    quill.pasteHTML(range.index, html);
                }, 100);
            } else {
                if (range.length > 0) {
                    quill.deleteText(range.index, range.length);
                }
                setTimeout(() => {
                    quill.insertText(range.index, text);

                }, 100);
            }
        };
        const handleToolbar = (e) => {
            // e.preventDefault()
            const toolbarHidden = $(`#${id}`).find(".ql-hidden")?.[0]?.className?.includes("ql-hidden");
            const toolbarDisplay = toolbarHidden ? false : true;
            if (hideToolbar) return setShowToolbar(false);
            setShowToolbar(toolbarDisplay);
            ['/EditPersona','/EditCxProgram'].includes(pathname)  && setAllowSectionsOverFlow(toolbarDisplay);
        };
        const handleChange = (e) => {
            sethtml && sethtml(e?.htmlValue || "");
            onBlur && autoSave && saveContent(e?.htmlValue || "");
            if (editorRef.current && allowMention && mentionFunction) {
                const lastElementAdded = e.delta.ops.pop();

                if (lastElementAdded?.insert?.mention) {
                    // event mention
                    const mentionElement = lastElementAdded.insert.mention;
                    mentionFunction && mentionFunction(mentionElement);
                }
            }
        };
        const handleBlur = (e) => {
            onBlur && onBlur(html);
        };
        const disableEvent = (event) => {
            event.preventDefault();
            return false;
        };
        const header = renderHeader();

        const handleLoad = (quill) => {
            const toolbar = quill.getModule("toolbar");
            toolbar.addHandler("link", function (value) {
                const lastRange = this.quill.selection.lastRange;
                const alreadyLinked = this.quill.getContents(lastRange)?.ops[0]?.attributes?.link;

                Swal.fire({
                    title: "Enter a URL:",
                    input: "text",
                    inputAttributes: {
                        autocapitalize: "off",
                    },
                    didOpen: () => {
                        // Get the input element and focus on it
                        const inputElement = document.querySelector('input.swal2-input');
                        inputElement.focus();
                      },
                      modal: false,
                    inputValue: alreadyLinked ? alreadyLinked : "https://www.",
                    showCancelButton: true,
                    confirmButtonText: "Link",
                    confirmButtonColor: "#F96209",

                    showDenyButton: alreadyLinked ? true : false,
                    denyButtonText: '<span style="color:#F96209;">Unlink</span>',
                    denyButtonColor: "#FFFFFF",
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const url = result.value;
                        this.quill.format("link", url);

                        Swal.fire({
                            title: "Link Added",
                            text: "Your Link has been added.",
                            icon: "success",
                            confirmButtonColor: "#F96209",
                        });
                    }
                    if (result.isDenied) {
                        const url = result.value;
                        this.quill.format("link", false);

                        Swal.fire({
                            title: "Link removed",
                            text: "Your Link has been removed.",
                            icon: "success",
                            confirmButtonColor: "#F96209",
                        });
                    }
                });
            });

        };
        const handleLink = () => {
            $(document).ready(function () {
                $("a").hover(
                    function () {
                        $(this).attr("target", "_blank");
                        $(this).attr("contentEditable", false);
                    },
                    function () {
                        $(this).attr("contentEditable", true);
                    }
                );
            });
        };
        const handleOnFocus = () => {
            onFocus && onFocus(html);
        };

        const saveContent = useCallback(
            debounce((newContent) => {
                // Save logic goes here
                onBlur(newContent);
            }, 1000),
            []
        );

        const handleDeselect = () => {
            const selection = window.getSelection();
            selection.removeAllRanges();
          };
          useClickOutside(editorContainerRef,() =>{
            handleDeselect();
            const hasHiddenClass = $(`#${id}`).find(".ql-hidden")?.[0]?.className?.includes("ql-hidden");
                if(!hasHiddenClass) {
                    const hasTooltipclass = $(`#${id}`).find(".ql-tooltip")?.first();
                    hasTooltipclass?.addClass('ql-hidden')


                }
            if (showToolbar) setShowToolbar(false);
            });
        return (
            <>{Editor && (
                <EditorContainer
                ref={editorContainerRef}
                showToolbar={showToolbar}
                style={{ ...style }}
                disabled={disabled}
                variant={variant}
                isdragging={isDragging}
                background={background}>
                    <Editor
                        id={id}
                        onMouseEnter={handleLink}
                        onMouseLeave={handleLink}
                        className={"editable height-inherit " + className}
                        headerTemplate={header}
                        modules={{ ...modules }}
                        value={html}
                        onTextChange={handleChange}
                        onMouseUp={(e) => e.preventDefault()}
                        onBlur={handleBlur}
                        onSelectionChange={handleToolbar}
                        onPaste={handlePaste}
                        ref={editorRef}
                        disabled={disabled} // use true to disable edition
                        style={{ ...style }}
                        onLoad={handleLoad}
                        onDragEnter={disableEvent}
                        onDragOver={disableEvent}
                        onDrop={disableEvent}
                        placeholder={placeholder}
                        theme="bubble"
                        onFocus={handleOnFocus}
                        autoFocus={autoFocus}
                    />
                </EditorContainer>)}
            </>
        );
    }
);
CustomEditor.displayName = "CustomEditor";
export { CustomEditor };

const EditorContainer = styled.div`
    position: relative;

    .ql-editor {
        border-radius: 0;
        background-color: transparent !important;
        color: ${props => props.background  && isDarkColor(props.background) ? "#ffffff" : "#333333"} !important;
        padding: 8px;
        z-index: 0;
        overflow: visible;
        border-radius: 0;
        font-size: 14px;
        min-height: inherit;
        line-height: 1.58rem;
        ${props => props.variant === 'outlined' && css`
        border-radius: 4px;
        width: 100%;
        min-height: 60px;
        padding: 4px;
        border: 1px solid transparent;
        ::before {
            font-style: italic !important;
            color: #C4C4C4 !important;
            font-weight: 300;
            line-height: 150%; /* 21px */
            font-size: 14px;

        }
         :hover {

            border: 1px solid #605F60;
        }
        :focus {
            border: 1px solid #605F60;

        }

        `}

        ${(props) => props.disabled && "pointer-events:none;"}
        a {
            pointer-events: all;
        }
        ${(props) =>
            props.variant === "description" &&
            css`
                border: 1px solid #d9dbde !important;
                border-radius: 4px !important;
                min-height: 141px;
                border-radius: 4px;
                width: 100%;
                :focus {
                    border: 1px solid ${Colors.primaryText} !important;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            `}
            ${(props) =>
            props.variant === "description-small" &&
            css`
                border: 1px solid #C4C4C4 !important;
                border-radius: 4px !important;
                min-height: 62px;
                width: 100%;
                :focus {
                    border: 1px solid ${Colors.primaryText} !important;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            `}


        /* cursor: ${(props) => (props?.isdragging ? `url(${cursor}), auto !important` : "")}; */

        ${(props) =>
            props.variant === "bold" &&
            css`
                font-weight: 500;
            `}
        ${(props) =>
            props.variant === "font-14" &&
            css`
                font-size: 14px;
            `}
        ${(props) =>
            props.variant === "font-12" &&
            css`
                font-size: 12px;
            `}
    }
    .ql-editor > * {
        cursor: ${(props) => (props?.isdragging ? `url(${cursor}), auto !important` : "text")};
    }
    .ql-bubble .ql-tooltip {
        background-color: inherit;
    }
    .ql-container {
        font-size: 16px !important;
        font-family: "Inter" !important;
    }

    .ql-container.ql-bubble:not(.ql-disabled) a::before {
        top: -33px;
        transform: translateX(-36px) !important;
        z-index: 40;
    }

    .ql-container.ql-bubble:not(.ql-disabled) a {
        white-space: normal;
    }

    .p-editor-toolbar {
        background-color: #444;
        border-radius: 5px;
        z-index: 40;
        max-width: 220px;
    }
    .ql-tooltip {
        transform: translate(89px, 5px) !important;
        min-width: 220px;
        ${(props) => !props.showToolbar && "visibility:hidden;"};
    }
    .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom: 6px solid #444;
        top: -6px;
        transform: translateX(-89px);
    }
    .ql-bubble .ql-tooltip {
        z-index: 40;
    }
    .ql-toolbar.ql-bubble {
        padding: 0;
        border: 0;
        position: relative;
        z-index: 40;
    }
    .ql-mention-list-container {
        z-index: 50;
        background-color: #ffffff;
        min-width: 220px;
        width: max-content;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        padding: 15px;
        ul {
            padding: 0;
        }
        li {
            padding: 5px;
            list-style-type: none;
        }
        .selected {
            background-color: #efefef;
            border: none;
        }
    }
    .mention {
        /* color: #18AEAD ; */
        font-weight: 600;
        font-family: "Inter";
    }
    .ql-editor.ql-blank::before {
        font-style: normal;
        font-size: 14px;
        left: 10px;
    }
    .ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
        padding-left: 0em;
    }
`;

const ToolbarContainer = styled.div`
    ${(props) => !props.mounted && "display:none;"}
    z-index: 40;

    @media screen and (max-width: 1280px) {
        .ql-container {
            font-size: 14px !important;
            font-family: "Inter" !important;
        }
        .ql-editor {
            font-size: 14px !important;
        }
    }
`;



