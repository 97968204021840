import styled, { css } from "styled-components/macro";
import { DataTable } from "primereact/datatable";
export const TableTemplate = styled(DataTable)`
	td {
		overflow: visible !important;
		/* padding: 0.2rem 0rem 0.2rem 0.4rem !important; */
		height: 56px;
		 border-width: 0px 1px 1px 0px !important;
		border-color: #D9D9D9 !important;
		background: #fff !important;

	}
	td:first-child {
	border-left-width: 1px !important;
	overflow:hidden !important;
	${props => (["home"].includes(props.context) || props.readOnly) && css`
		.p-checkbox{
			display:none;

		}

		`}

	${props => !["cxProgram", "library", "editPersona"].includes(props.context) && 'border-right-width:0px !important;'}
	}
td:nth-last-child(2) {
  /* Your styles here */

  /* Example: Add a right border */
  	${props => ![ "managePersonas"].includes(props.context) && '  border-right-width:0px !important;'}

  /* border-right-width: 0px !important; */
}

td:last-child {

	border-left-width:0px !important;
	overflow:hidden !important;
	}
	${(props) =>
		props.isheadervisible === "true" ||
		(props.expandable === "true" &&
			css`
				min-height: calc(100vh - 450px) !important;
			`)}


    table {
		border-spacing: 0 !important;
		border-collapse: separate !important;
	}
	.p-datatable-header {
		background: #fff;
		border: none;
		padding: 0 0 1.5rem 0 !important;
	}
	thead {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		height:56px;
	}
	tr {
		/* border: 1px solid #c4c4c4; */
		background: transparent !important;
		min-height: 56px;
		:hover {
			td {
				background:  var(--color-Grey-Table-Header, #FAFAFA) !important;
			}

		}
	}
	.p-datatable-wrapper {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	/* .p-datatable-thead {
		position: relative;
		z-index: 0;

		background-color: #f8f9fa !important;
	} */

	td {
		overflow: visible !important;
		padding: 0.2rem 0rem 0.2rem 0.4rem;
		height: 56px;
		max-height: 56px;
		/* border-width: 0 0 0px 0; */
		border-color: #D9D9D9 !important;
	}
	.p-column-title {
		color: var(--color-Grey-Secondary-Text, #595959);

		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}


	th {
		position: relative;
		background: var(--color-Grey-Table-Header, #FAFAFA)!important;
		padding: 0.2rem 0rem 0.2rem 0.4rem;

		${(props) =>
		props.disablefixheader === "true"
			? ""
			: css`
						position: sticky;

						top: 0px;
						//z-index: 50;
				  `};
		:hover .p-sortable-column-icon {
			display: block;
		}
		:hover .pi-filter-icon {
			display: block;

		}
	}

	.p-column-filter-menu-button-open {
		.pi-filter-icon {
			display: block;
		}
		.p-sortable-column-icon {
			display: block;
		}
	}
	.p-column-filter-menu-button-active {
		.pi-filter-icon {
			display: block;
			color: #f96209;
		}
		.p-sortable-column-icon {
			display: block;
		}
	}
	.p-column-filter {
		order:1;

	}
	.p-sortable-column-icon {
		order:2;
	}
	.p-checkbox .p-checkbox-box.p-highlight {
		border-color: #333333;
		background: #333333;
		:hover {
			border-color: #333333;
			background: #333333;
		}
	}


	tr.p-highlight {
		color: #333333 !important;

	}
	tr {
		th {
			/* border-bottom: 1px solid #D9D9D9 !important ; */
		}
	}
	tr:hover {

		/* td {
			background-color:  var(--color-Grey-Table-Header, #FAFAFA);
		}
		td.action-box-table > div {
			background-color:  var(--color-Grey-Table-Header, #FAFAFA) ;
		} */
	}
	th {
		.p-checkbox {
		width: 16px;
		height: 16px;
	}
	}
	.p-checkbox .p-checkbox-box {
		width: 16px;
		height: 16px;
		border: 1px solid #333333;
		border-radius:2px;

	}
	.p-datatable .p-column-header-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
		box-shadow: none;
		border-color: #333333;
	}
	.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
		border-color: #333333;
	}
	.pi-filter-icon {
		position: absolute;
		top: 25%;
		right: 25%;
		display: none;
	}

	.p-sortable-column-icon {
		position: relative;
		/* right:2%; */
		display: none;
	}
	.p-column-filter-menu {
		display: inline-flex;
		margin-left: 2px;


	}

	.disable-checkbox {
		.p-checkbox {
			display: flex;
			pointer-events: none;
			opacity: 0.4;
		}
		.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
			box-shadow: none;
			border-color: #D9D9D9;
		}
	}



	.p-highlight:not(.p-sortable-disabled) {

		.p-sortable-column-icon {
			color: #f96209 !important;
		}
	}
	.p-highlight {
		color: #f96209 !important;
	}
	.p-datatable-thead {
		background-color: unset !important;


	}


	font-size: 14px;
	.p-column-filter-menu-button:focus {
		box-shadow: none !important;
	}
	.p-sortable-column:focus {
		box-shadow: none !important;
		outline: 0 none;
	}


	.p-row-toggler:focus {
		box-shadow: none !important;
	}

	/* width: min-content; */

	td {
		flex: none !important;
	}
	tr {
		flex: none !important;
	}
	th {
		flex: none !important;
		border-width:1px 1px 1px 0px !important;
		border-color: #D9D9D9 !important;
	}
	th:first-child {
	border-left-width: 1px !important;
	${props => (["home"].includes(props.context) || props.readOnly) && css`
			.p-checkbox{
				display:none;

			}


	`}
  ${props => !["cxProgram", "library", "editPersona"].includes(props.context) && 'border-right-width:0px !important;'}
  /* Add right border to the last cell */
}
	th:last-child {
		border-left-width:0px !important;
	}
	th:nth-last-child(2) {
		${props => ![ "managePersonas"].includes(props.context) && '  border-right-width:0px !important;'}
	}/* Add right border to the last cell */
	min-width: 100%;
	${(props) => (props.header ? "" : "min-width: 100%;")}
	.p-virtualscroller-content {
		/* position: static;  this affect the sticky header when scrolling until the*/
		/* width:max-content; */
		//: sticky;
		max-width: 100%;

	}
	.p-virtualscroller {
		contain: unset;
		//overflow-x:hidden;
		${(props) => (props.header ? "" : "overflow:auto;")};
	}
	@media screen and (min-height: 800px) {
		.p-virtualscroller {
			${(props) => props?.value?.length < 9  && `overflow-y: hidden;`}
		}
	}
	@media screen and (max-height: 800px) {
		.p-virtualscroller {
			${(props) => props?.value?.length < 7   && `overflow-y: hidden;`}
		}
	}
	@media screen and (max-height: 750px) {
		.p-virtualscroller {
			${(props) => props?.value?.length < 4   && `overflow-y: hidden;`}
		}
	}
	// checkbox
	/* th[style="width: 56px; min-width: 56px; padding: 5px; display: flex; justify-content: center; align-items: center;"] {
		border-right: none !important;
	}
	//actions
	th[style="width: 56px; min-width: 56px; padding: 5px;"] {
		border-right: none !important;
	} */

	.p-datatable-emptymessage {
		td{
			min-width: 100%;
		}
	}
	${(props) =>
		props.nocolumnheader === "true" &&
		css`
			.p-datatable-thead  {
				display: none;
			}
		`}

		/* Apply border-radius to the DataTable */
.p-datatable {
  border-collapse: separate;
  border-spacing: 0;
  border: none;

}

/* Apply border-radius to the table corners */
.p-datatable-thead > tr > th:first-child {
  border-top-left-radius: 4px;
}

.p-datatable-thead > tr > th:last-child {
  border-top-right-radius: 4px;
}

.p-datatable-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 4px;
}

.p-datatable-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius:4px;
}
.p-datatable-emptymessage > td{
		border:none !important;

}
`;
