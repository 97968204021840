import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import styled from "styled-components";
import JourneyTrendsDashboard from "./JourneyTrends/JourneyTrendsDashboard";
import ROIAnalysisDashboard from "./ROIAnalysis/ROIAnalysisDashboard";
import VoCAnalysisDashboard from "./VoCAnalysis/VoCAnalysisDashboard";
import DataLoader from "shared/components/data-loader";
import { getAnalyticsFilters } from "@redux/index";
import { RelativeWrapper } from "../Style";

function Analytics(props) {
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();
	const isExportMode = search.hideForPdf();
	const filtersId = search.getFiltersId();
	const tabName = props.tabName || search.getTabName()?.tab || "journeytrends";

	const [tabKey, setTabKey] = useState(tabName);

	const handleChangeTab = (k) => {
		setTabKey(k);
		history.push(`/analytics?tab=${k}`);
	};

	if (isExportMode) {
		const getDashboard = () => {
			switch (tabName) {
				case "vocanalysis":
					return <VoCAnalysisDashboard />;
				case "roianalysis":
					return <ROIAnalysisDashboard />;
				default:
					return <JourneyTrendsDashboard />;
			}
		};

		return <DataLoader asyncFns={[{ asyncFn: getAnalyticsFilters, parameter: { id: filtersId } }]}>{getDashboard()}</DataLoader>;
	}

	return (
		<PageContainer classNames="container-fluid ms-5 h-100">
			<CustomTabs id="controlled-tab-example" activeKey={tabKey} onSelect={handleChangeTab} unmountOnExit={true}>
				<Tab eventKey="journeytrends" title={t("JOURNEY_OVERVIEW")}>
					<RelativeWrapper>
						<JourneyTrendsDashboard />
					</RelativeWrapper>
				</Tab>
				<Tab eventKey="vocanalysis" title={t("VOC_ANALYSIS")}>
					<RelativeWrapper>
						<VoCAnalysisDashboard />
					</RelativeWrapper>
				</Tab>
				<Tab eventKey="roianalysis" title={t("ROI_ANALYSIS")}>
					<RelativeWrapper>
						<ROIAnalysisDashboard />
					</RelativeWrapper>
				</Tab>
			</CustomTabs>
		</PageContainer>
	);
}

Analytics.displayName = "Analytics";
export default Analytics;

const PageContainer = styled(PageTemplate)`
	margin-left: 16px;
`;

const CustomTabs = styled(Tabs)`
	border: none !important;
`;
