import CustomTooltip from "shared/components/CustomTooltip";
import { COLORS, FlexAlignCenter, IconDispatcher } from "shared/ui-basics";
import styled, { css } from "styled-components";
import WorkspaceLogo from "./workspace-logo";
import React from "react";
import { findFirstAlphabeticalCharacter } from "@utils/helpers";

const WorkspaceLogoAndName = React.forwardRef(({ workspace, primaryAction, secondaryAction, icon, size = "m", nameWidth, iconTooltip, isDisabled = false }, ref) => {
	const handlePrimaryAction = () => {
		if (!primaryAction || isDisabled) return;
		primaryAction();
	};

	return (
		<Container isDisabled={isDisabled}>
			<Wrapper>
				<CustomTooltip text={workspace?.name} disabled={!workspace || workspace?.name?.length < 15}>
					<FlexAlignCenter gap="8" onClick={primaryAction && handlePrimaryAction}>
						<div>
							<WorkspaceLogo letter={findFirstAlphabeticalCharacter(workspace?.name)} colorCode={workspace?.colorCode} size={size} />
						</div>
						<WorkspaceName namewidth={nameWidth}>{workspace?.name}</WorkspaceName>
					</FlexAlignCenter>
				</CustomTooltip>
				{icon && secondaryAction && (
					<CustomTooltip text={iconTooltip} disabled={iconTooltip ? false : true}>
						<IconContainer className="me-2" onClick={secondaryAction}>
							{IconDispatcher(icon)}
						</IconContainer>
					</CustomTooltip>
				)}
			</Wrapper>
		</Container>
	);
});

WorkspaceLogoAndName.displayName = "WorkspaceLogoAndName";
export default WorkspaceLogoAndName;

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;

	${(props) =>
		!props.isDisabled &&
		css`
			:hover {
				color: ${COLORS.label} !important;
				background-color: ${COLORS.lightHover} !important;
			}
		`}
`;

const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px;
`;

const WorkspaceName = styled.div`
	height: 100%;
	max-width: 180px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #333;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	font-size: 14px;
	${(props) =>
		props.namewidth &&
		css`
			width: ${props.namewidth}px;
			max-width: ${props.namewidth}px;
		`}
`;

const IconContainer = styled.div`
	width: 24px;
	height: 24px;
`;
