import { Translation } from "react-i18next";
import { GenericButton } from "shared/ui-basics/buttons.js";
import { Label } from "shared/ui-basics/index.js";
import styled from "styled-components";

export const SectionHeader = ({ entityText, onClick }) => {
	return (
		<Translation>
			{(t) => (
				<Description>
					<Label size="14" fw="600">
						{`${t("RECENT")} ${t(entityText)}`}
					</Label>
					<GenericButton variant="light-link" onClick={onClick}>
						{`${t("ASPX_208")} ${t(entityText)}`}
					</GenericButton>
				</Description>
			)}
		</Translation>
	);
};


const Description = styled.div`
	color: #605f60;
	display: flex;
	align-items: center;
	justify-content: space-between;
	a {
		color: #605f60;
		text-decoration: none;
	}
`;