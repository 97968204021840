import { createCxProgram, getCxProgramExcelExportList, getCxProgramLinkedExcelExportList, updateFolderLinks } from "@redux/index";
import { FilterItemsByStatus, tableDefaultColumn, toastWrapper } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { BulkActions } from "features/forms/cx-programs/actions/BulkActions";
import _ from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { useModal } from "use-modal-hook";
import BaseActions from "../../../forms/cx-programs/actions/BaseActions";

export const GetProgramContext = (props) => {
	const { linked, setRename, disableBulkActions } = props;
	const { pathname, search } = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const folderId = search.getFolderId();

	const cxPrograms = useSelector((state) => state.cxProgram.all_cxPrograms?.filter((cxProgram) => FilterItemsByStatus(cxProgram, pathname, "/cx-program", showSampleData, folderId)));
	const cxProgramData = useMemo(() => transformcxProgramData(cxPrograms), [cxPrograms]);

	const specificContext = props.context.split(".")[1];
	const linkedObjects = linked ? cxProgramData.filter((cx) => linked?.includes(cx.id)) : undefined;
	const loading = useSelector((state) => state.cxProgram.loading);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.CXProgram]?.[specificContext]?.columns || tableDefaultColumn(EntityList.CXProgram);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.CXProgram]?.[specificContext]?.filters;
	const [showListLinkedModal] = useModal(ListLinkedModal);

	const createCxProgramfn = ({ setIsCreateBtnDisabled }) => {
		setIsCreateBtnDisabled && setIsCreateBtnDisabled(true);
		const onSuccess = (data) => {
			setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
			if (folderId) {
				updateFolderLinks({ folderIds: [folderId], idsToLink: [data.id], entityName: "cem_cxprogram" });
			} else {
				history.push(`/EditCxProgram?programid=${data.id}`);
			}
		};
		const onFailed = () => setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
		createCxProgram({ name: "", onSuccess, onFailed });
	};

	const data = useMemo(() => {
		const newData = {
			cxProgramList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "PROGRAM_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
				visibleHeader: true,
				buttonCreate: createCxProgramfn,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "cxProgramName", "totalNumCjms", "totalNumPersonas", "totalNumOpps", "totalNumSols", "totalNumActions", "totalNumProjects", "lastUpdated", "actions"],
				emptyListText: "PROGRAM_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "cxProgramName", "totalNumCjms", "totalNumPersonas", "totalNumOpps", "totalNumSols", "totalNumActions", "totalNumProjects", "lastUpdated", "actions"],
				emptyListText: "PROGRAM_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			library: {
				showCheckbox: false,
				bulkActions: false,
				columns: ["cxProgramName"],
				emptyListText: "PROGRAM_EMPTY_LIST",
				clickToOpen: true,
				showOnlyLinked: true,
				linked: linked,
				disableFixHeader: true,
				showPagination: false,
			},
		};

		const contextData = _.get(newData, specificContext);

		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;

		contextData.exportExcel = (ids, columns) => {
			const promise = async () => {
				const { data, error } = await dispatch(getCxProgramExcelExportList(ids, columns));
				var fileDownload = require("js-file-download");
				fileDownload(data, "CX Programs List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
				if (error) throw error;
			};
			toastWrapper(promise, "EXPORT");
		};
		contextData.getLinkedDataExportExcel = (id, entityName) => {
			const promise = async () => {
				const { data, error } = await dispatch(getCxProgramLinkedExcelExportList({ entityName, id }));
				var fileDownload = require("js-file-download");
				fileDownload(data, "CX Programs List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
				if (error) throw error;
			};
			toastWrapper(promise, "EXPORT");
		};
		contextData.Actions = (cxProgram) => CreateActionsDropdown({ actions: BaseActions({ cxProgram, setRename }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = linkedObjects;
		contextData.listData = cxProgramData;
		contextData.dataKey = "id";
		contextData.entityType = EntityList.CXProgram;
		contextData.loading = loading;

		contextData.buttonCreate = createCxProgramfn;
		contextData.buttonCreateLabel = "NEW_PROGRAM";
		contextData.filter = filters;
		// folder case
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.CXProgram, saveButtonLabel: "ASPX_44" });
			contextData.LinkActionLabel = "LINK_CX_PROGRAM";
			contextData.displayExport = false;
		}

		return contextData;
	}, [isReadOnly, linked, linkedObjects, cxProgramData, loading, disableBulkActions, setRename, filters]);
	return data;
};

const transformcxProgramData = (cxPrograms) => {
	return cxPrograms.map((cx) => {
		const newDate = new Date(cx.lastModifiedOn);

		return {
			...cx,
			totalNumActions: cx?.linkedActions?.length,
			totalNumOpps: cx?.linkedOpps?.length,
			totalNumSols: cx?.linkedSols?.length,
			totalNumCjms: cx?.linkedCjms?.length,
			totalNumPersonas: cx?.linkedPersonas?.length,
			totalNumProjects: cx?.linkedProjects?.length,
			tagIds: cx?.tags?.join("|"),
			lastModifiedOn: newDate,
		};
	});
};
