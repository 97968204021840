import { GenericButton } from "shared/ui-basics/index";
import { useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import styled from "styled-components/macro";

export const CustomBreadCrumb = ({ paths,backFunction }) => {
	const history = useHistory();
	return (
		<PathContainer>
			<GenericButton variant="light-link" size="s" className="me-2" icon={"ARROW_LEFT"} onClick={backFunction ? backFunction : history.goBack} />
			<CustomBreadcrumb className="m-0">
				{paths?.map((path,index) => (
					<BreadcrumbItem key={index}>
						<Path>{path}</Path>
					</BreadcrumbItem>
				))}
			</CustomBreadcrumb>
		</PathContainer>
	);
};

const PathContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
`;

const CustomBreadcrumb = styled(Breadcrumb)`
	ol {
		margin: 0 !important;
		display: flex;
		align-items: center;
	}
	li {
		display: flex;
		align-items: center;
	}
`;

const Path = styled.div`
	white-space: nowrap;
	color: #8c8c8c;
	font-size: 14px;
	display: flex;
	align-items: center;
`;
