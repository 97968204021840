import { createOpportunity, getExcelExportListOpp, updateFolderLinks, updateMultiOpportunity } from "@redux/index";
import { FilterItemsByStatus, createDefaultOpp, tableDefaultColumn, toastWrapper } from "@utils/helpers";
import { EntityList } from "@utils/optionsSets/OptionSets";
import { BulkActions } from "features/opportunity/actions/BulkActions";
import { t } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CreateActionsDropdown } from "shared/dropdowns/CreateActionsDropdown";
import ListLinkedModal from "shared/modals/list-links/ListLinkedModal";
import { useModal } from "use-modal-hook";
import BaseActions from "../../../opportunity/actions/BaseActions";
import { ActionLink } from "../../columns-components/All_actions/ActionLink";
import { getAllMapsList } from "@redux/memoizedSelectors";

export const GetOpportunityContext = (props) => {
	const { linked, showAllActiveRecord, disableBulkActions } = props;
	const history = useHistory();
	const { pathname, search } = useLocation();
	const dispatch = useDispatch();
	const cxProgramId = useLocation().search.getCxProgramID();
	const isReadOnly = useSelector((state) => state.auth.userInfo.UserType)?.isReadOnly();
	const showSampleData = useSelector((state) => state.settings.organization?.showSampleData);
	const all_maps = useSelector(getAllMapsList);
	const folderId = search.getFolderId();
	const all_Opportunities = useSelector((state) => state.opportunity?.all_Opportunities || [],shallowEqual);

	const opportunities = useMemo(() => all_Opportunities?.filter((opp) => FilterItemsByStatus(opp, pathname, "/opportunity", showSampleData,all_maps)),[all_Opportunities,showSampleData,all_maps]); // this i sthe case of group by map
	const specificContext = props.context.split(".")[1];
	const linkedObjects = linked ? opportunities.filter((opp) => linked?.includes(opp.id)) : null;
	const OpportunityData = useMemo(() => FormatOpportunityData(opportunities, all_maps), [opportunities, all_maps]);
	const loading = useSelector((state) => state.opportunity.loading);
	const columnsArray = useSelector((state) => state.ui.table.entity)?.[EntityList.Opportunity]?.[specificContext]?.columns || tableDefaultColumn(EntityList.Opportunity);
	const filters = useSelector((state) => state.ui.table.entity)?.[EntityList.Opportunity]?.[specificContext]?.filters;
	const userInfo = useSelector((state) => state.auth.userInfo);
	const exportExcelOpportunity = (ids, columnView) => {
		const promise = async () => {
			const { data, error } = await dispatch(getExcelExportListOpp(ids, columnView));
			var fileDownload = require("js-file-download");
			fileDownload(data, "Opportunities List", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
			if (error) throw error;
		};
		toastWrapper(promise, "EXPORT");
	};
	const handleRedirect = ({ setIsCreateBtnDisabled }) => {
		setIsCreateBtnDisabled && setIsCreateBtnDisabled(true);
		const onSuccess = (data) => {
			setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
			if (folderId) {
				updateFolderLinks({ folderIds: [folderId], idsToLink: [data.id], entityName: "cem_cxopportunity" });
			} else {
				history.push(`/edit-opportunity?id=${data.id}`);
			}
		};
		const onFailed = () => setIsCreateBtnDisabled && setIsCreateBtnDisabled(false);
		const newOpportunity = createDefaultOpp(userInfo);
		createOpportunity({ item: newOpportunity, displayToast: "CREATE_NO_SUCCESS_TOAST", onSuccess, onFailed, cxProgramId });
	};
	const [showListLinkedModal] = useModal(ListLinkedModal);

	const data = useMemo(() => {
		const newData = {
			home: {
				showCheckbox: false,
				columns: ["subject", "priorityId", "ownerId", "statusId", "actions"],
				emptyListText: "OPPORTUNITY_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				linked: linked,
				clickToOpen: true,
				showOnlyLinked: true,
				disableFixHeader: false,
				noColumnHeader: true,
				showPagination: false,
			},
			OpportunitiesList: {
				showCheckbox: true,
				columns: columnsArray,
				emptyListText: "OPPORTUNITY_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: true,
				linked: linked,
				disableFixHeader: false,
				visibleHeader: true,
				listEditable: !isReadOnly,
				showPagination: true,
				visibleButtonCreate: !isReadOnly,
				displayView: folderId === undefined,
				showOnlyLinked: folderId !== undefined || linked?.length > 0,
			},
			archived: {
				showCheckbox: true,
				columns: ["select", "subject", "statusId", "mapId", "groupIds", "win", "actions"],
				emptyListText: "OPPORTUNITY_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			deleted: {
				showCheckbox: true,
				columns: ["select", "subject", "statusId", "mapId", "groupIds", "win", "actions"],
				emptyListText: "OPPORTUNITY_EMPTY_LIST",
				bulkActions: !disableBulkActions,
				clickToOpen: false,
				linked: linked,
				disableFixHeader: false,
				showPagination: true,
			},
			cxProgram: {
				showCheckbox: false, // modals of cxProgram that link/unlink
                columns: ["subject", "priorityId", "mapId", "stageName", "customerValue", "businessValue", "actions"],
                listEditable: !isReadOnly,
				clickToOpen: true,
				bulkActions: false,
				linked: linked,
				showOnlyLinked: true,
				disableFixHeader: true,
				showPagination: linked?.length > 10,
				nonDropDown: true,
				emptyListText: "OPPORTUNITY_PLACEHOLDER_PROGRAM",
			},
		};
		const contextData = _.get(newData, specificContext);
		if (isReadOnly) {
			// contextData.columns = contextData.columns.filter((col) => col !== "actions" && col !== "select");
			// contextData.showCheckbox = false;
			contextData.bulkActions = false;
		}
		contextData.readonly = isReadOnly;

		contextData.Actions = (opportunity) => CreateActionsDropdown({ actions: BaseActions({ opportunity }), variant: "default", dropdownClasses: "" });
		contextData.bulkActionsFunction = BulkActions;
		contextData.linkedObjects = FormatOpportunityData(linkedObjects, all_maps);
		contextData.listData = OpportunityData;
		contextData.dataKey = "id";
		contextData.reducerPath = "opportunity.all_Opportunities";
		contextData.entityType = EntityList.Opportunity;

		contextData.route = "edit-opportunity";
		contextData.exportExcel = exportExcelOpportunity;
		contextData.loading = loading;
		contextData.buttonCreateLabel = "NEW_OPPORTUNITY";
		contextData.buttonCreate = handleRedirect;
		contextData.onChangeObject = contextData?.listEditable ? (modifiedOpportunities, modifiedAttribNames, onSuccess) => updateMultiOpportunity({ modifiedOpportunities, modifiedAttribNames, onSuccess }) : null;
		contextData.filter = filters;
		if (specificContext === "cxProgram") {
			contextData.Actions = props?.disableAction ? () => { } : (options) => ActionLink(options, "cem_cxopportunity", cxProgramId);
		}
		// folder case
		if (folderId) {
			contextData.showLinkFolderButton = true;
			contextData.LinkFolderAction = () => showListLinkedModal({ folderId: folderId, linked: linked, entityType: EntityList.Opportunity, saveButtonLabel: 'ASPX_44' });
			contextData.LinkActionLabel = "LINK_UNLINK_OPPORTUNITIES";
			contextData.displayExport = false;

		}
		return contextData;
	}, [isReadOnly, linked, linkedObjects, OpportunityData, columnsArray, loading, disableBulkActions, showAllActiveRecord, cxProgramId, filters]);

	return data;
};

const FormatOpportunityData = (data, allMap) => {
	return data?.map((opp) => {
		const dueDateNew = new Date(opp.dueDate);
		const lastUpdatedNew = new Date(opp?.lastUpdated);

		const StageName = allMap.find((map) => map.cjmId === opp?.mapId)?.stages?.filter((stage) => stage.id === opp?.stageId)[0]?.name;
		const map = allMap.find((map) => map.cjmId === opp?.mapId);
		return {
			...opp,
			dueDateNew: dueDateNew,
			lastUpdated: lastUpdatedNew,
			stageName: StageName === "" || !StageName ? t("NEW_STAGE") : StageName,
			mapName: map?.name,
			authorizedUsers: map?.authorizedUsers,
			groupIds: opp?.groups?.join("|"),
			tagIds: opp?.tags?.join("|"),
		};
	});
};
