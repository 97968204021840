import { encode } from "@utils/helpers";

export const getAllStrategy = () => {
	return {
		type: "STRATEGY_GET_ALL",
		request: {
			url: "api/strategy/getAll",
			method: "GET",
		},
	};
};

export const createStrategy = (strategyName) => {
	return {
		type: "CREATE_STRATEGY",
		request: {
			url: "api/strategy/create",
			method: "POST",
			data: {
				name: encode(strategyName),
			},
		},
	};
};

export const addStrategyAttribute = ({ newFieldID, strategyId, attributeType, newObject }) => {
	return {
		type: "ADD_STRATEGY_ATTRIBUTE_TYPE",
		request: {
			url: "api/strategy/addStrategyAttribute",
			method: "post",
			data: {
				newFieldIdStr: newFieldID,
				strategyId: strategyId,
				attributeType: attributeType,
			},
		},
		params: newObject,
	};
};

export const getStrategyFormData = (strategyId) => {
	return {
		type: "GET_STRATEGY_FORM_DATA",
		request: {
			url: `api/strategy/getStrategyFormData?strategyId=${strategyId}`,
			method: "get",
		},
	};
};

export const updateOrderLocally = (newState) => {
	return {
		type: "UPDATE_ORDER_LOCALLY",
		payload: newState,
	};
};

export const updateStrategyAttribOrder = ({ strategyId, cxProgramId,newOrderConfig }) => {
	return {
		type: "UPDATE_STRATEGY_ATTRIB_ORDER",
		request: {
			url: "api/strategy/updateStrategyAttribOrder",
			method: "post",
			data: {
				strategyId: strategyId,
				newOrderConfig: newOrderConfig,
				cxProgramId: cxProgramId,
			},
		},
	};
};

export const deleteCxStrategy = ({ strategyId }) => {
	return {
		type: "DELETE_CX_STRATEGY",
		request: {
			url: "/api/strategy/delete",
			method: "POST",
			data: {
				strategyId,
			},
		},
	};
};

export const deleteManyCxStrategies = ({ strategyIds }) => {
	return {
		type: "DELETE_MANY_CX_STRATEGIES",
		request: {
			url: "/api/strategy/deleteMany",
			method: "POST",
			data: {
				strategyIds,
			},
		},
	};
};

export const deleteCxStrategyPermanent = ({ strategyId }) => {
	return {
		type: "DELETE_CX_STRATEGY_PERMANENT",
		request: {
			url: "/api/strategy/deletePermanently",
			method: "POST",
			data: {
				strategyId,
			},
		},
	};
};

export const deleteManyCxStrategiesPermanent = ({ strategyIds }) => {
	return {
		type: "DELETE_MANY_CX_STRATEGIES_PERMANENT",
		request: {
			url: "/api/strategy/deletePermanentlyMany",
			method: "POST",
			data: {
				strategyIds,
			},
		},
	};
};

export const archiveCxStrategy = ({ strategyId }) => {
	return {
		type: "ARCHIVE_CX_STRATEGY",
		request: {
			url: "api/strategy/archive",
			method: "post",
			data: {
				strategyId,
			},
		},
	};
};

export const archiveManyCxStrategies = ({ strategyIds }) => {
	return {
		type: "ARCHIVE_MANY_CX_STRATEGIES",
		request: {
			url: "api/strategy/archiveMany",
			method: "post",
			data: {
				strategyIds,
			},
		},
	};
};

export const copyCxStrategy = ({ strategyId, newSubject }) => {
	return {
		type: "COPY_CX_STRATEGY",
		request: {
			url: "/api/strategy/copy",
			method: "POST",
			data: {
				srcCxStrategyId: strategyId,
				newSubject: encode(newSubject),
			},
		},
	};
};



export const shareStrategy = ({ roleDetails, userId, userName, strategyId }) => {
	return {
		type: "UPDATE_SHARE",
		request: {
			url: "api/strategy/share",
			method: "post",
			data: {
				roleDetails: roleDetails,
				userId: userId,
				strategyId: strategyId,
			},
			param: {
				fullName: userName,
			},
		},
	};
};
export const unShareStrategy = (userId, strategyId) => {
	return {
		type: "UPDATE_UNSHARE",
		request: {
			url: "api/strategy/unShare",
			method: "post",
			data: {
				userId: userId,
				strategyId: strategyId,
			},
		},
	};
};
export const shareUsersSingleStrategy = ({ roleDetails, usersId, strategyId }) => {
	return {
		type: "SHARE_USERS_SINGLE_STRATEGY",
		request: {
			url: "api/strategy/shareMany",
			method: "post",
			data: {
				ids: usersId,
				strategyId: strategyId,
				roleDetails: roleDetails,
			},
		},
	};
};
export const shareUsersMulipleStrategy = ({ roleDetails, usersId, strategyIds }) => {
	return {
		type: "SHARE_USERS_MULTIPLE_STRATEGY",
		request: {
			url: "api/strategy/shareManyStrategy",
			method: "post",
			data: {
				ids: usersId,
				strategyIds: strategyIds,
				roleDetails: roleDetails,
			},
		},
	};
};
export const updateStrategyAttributeImage = (strategyId, attributeId, imageExtension, image64) => {
	return {
		type: "UPDATE_STRATEGY_ATTRIBUTE_IMAGE",
		request: {
			url: "api/strategy/uploadStrategyAttributeImage",
			method: "post",
			data: {
				strategyId: strategyId,
				attributeIdStr: attributeId,
				imageExtension: imageExtension,
				imageBody64: image64,
			},
		},
	};
};
export const updateStrategyAttribute = ({ modifiedStrategy, modifiedAttribNames }) => {
	return {
		type: "UPDATE_STRATEGY_ATTRIBUTE",
		request: {
			url: "api/strategy/updateStrategyAttribute",
			method: "post",
			data: {
				modifiedStrategyAttribute: modifiedStrategy,
				modifiedAttribNames: modifiedAttribNames,
			},
		},
	};
};
export const updateStrategy = ({ modifiedStrategy, modifiedAttribNames }) => {
	return {
		type: "UPDATE_STRATEGY",
		request: {
			url: "api/strategy/update",
			method: "post",
			data: {
				modifiedCxStrategy: { ...modifiedStrategy, strategyBrandValueDic: {} },
				modifiedAttribNames: modifiedAttribNames,
			},
		},
	};
};
export const deleteStrategyAttribute = ({ strategyId, attributeId }) => {
	return {
		type: "DELETE_STRATEGY_ATTRIBUTE",
		request: {
			url: "api/strategy/deleteStrategyAttribute",
			method: "post",
			data: {
				strategyId: strategyId,
				strategyAttributeId: attributeId,
			},
		},
	};
};
export const deleteStrategyAttributeImage = (strategyId, strategyAttributeId) => {
	return {
		type: "DELETE_IMAGE_ON_BOX",
		request: {
			url: "api/strategy/deleteStrategyAttributeImage",
			method: "post",
			data: {
				strategyId: strategyId,
				strategyAttributeId: strategyAttributeId,
			},
		},
	};
};

export const upsertStrategyBrandValue = ({ strategyBrandValue }) => {
	return {
		type: "UPSERT_STRATEGY_BRAND_VALUE",
		request: {
			url: "api/strategy/upsertStrategyBrandValue",
			method: "post",
			data: {
				strategyBrandValue
			},
		},
	};
};

export const addStrategyBrandValue = ({ brandValue, strategyIdStr, attributeIdStr }) => {
	return {
		type: "ADD_STRATEGY_BRAND_VALUE",
		request: {
			url: "api/strategy/addStrategyBrandValue",
			method: "post",
			data: {
				brandValue,
				strategyIdStr,
				attributeIdStr,
			},
		},
	};
};
export const updateStrategyBrandValue = ({ modifiedStrategyBrandValue, modifiedAttribNames }) => {
	return {
		type: "UPDATE_STRATEGY_BRAND_VALUE",
		request: {
			url: "api/strategy/updatedStrategyBrandValue",
			method: "post",
			data: {
				modifiedStrategyBrandValue,
				modifiedAttribNames,
			},
		},
	};
};
export const getPdfExportStrategy = (strategyId, width, height) => {
	return {
		type: "GET_PDF_EXPORT_STRATEGY",
		request: {
			url: `api/strategy/getPdfExport?strategyId=${strategyId}&width=${width}&height=${height}`,
			method: "get",
			responseType: "blob",
		},
	};
};

export const deleteStrategyBrandValue = ({ strategyBrandValue }) => {
	return {
		type: "DELETE_STRATEGY_BRAND_VALUE",
		request: {
			url: "api/strategy/deleteStrategyBrandValue",
			method: "post",
			data: {
				strategyBrandValue,
			},
		},
	};
};

export const resetCurrentStrategy = () => {
	return {
		type: "RESET_CURRENT_STRATEGY",
	};
};

export const restoreStrategy = ({ strategyId }) => {
	return {
		type: "RESTORE_CX_STRATEGY",
		request: {
			url: "/api/strategy/restore",
			method: "POST",
			data: {
				strategyId,
			},
		},
	};
};

export const restoreManyStrategies = ({ strategyIds }) => {
	return {
		type: "RESTORE_MANY_CX_STRATEGIES",
		request: {
			url: "/api/strategy/restoreMany",
			method: "POST",
			data: {
				strategyIds,
			},
		},
	};
};
export const getExcelExportList = (ids) => {
	return {
		type: "GET_EXCEL",
		request: {
			url: `/api/strategy/getExcelExport`,
			method: "POST",
			data: {
				ids: ids,
			},

			responseType: "blob",
		},
	};
};

export const updateCxStrategyListItem = ({ strategyIdStr, attributeIdStr, attributeItemIdStr, description }) => {
	return {
		type: "UPDATE_CXSTRATEGY_LIST_ITEM",
		request: {
			url: "api/strategy/upsertCustomListItem",
			method: "post",
			data: {
				strategyIdStr: strategyIdStr,
				attributeIdStr: attributeIdStr,
				attributeItemIdStr: attributeItemIdStr,
				description: encode(description),
			},
		},
	};
};
export const reorderCxStrategyListItem = ({ strategyIdStr, newOrder, attributeIdStr }) => {
	return {
		type: "REORDER_CXSTRATEGY_LIST_ITEM",
		request: {
			url: "api/strategy/updateCustomListItemsOrder",
			method: "post",
			data: {
				strategyIdStr: strategyIdStr,
				newItemsOrder: newOrder,
				attributeIdStr: attributeIdStr,
			},
		},
	};
};
export const deleteCxStrategyCustomListItem = ({ strategyIdStr, attributeIdStr, attributeItemIdStr }) => {
	return {
		type: "DELETE_CXSTRATEGY_LIST_ITEM",
		request: {
			url: "api/strategy/deleteCustomListItem",
			method: "post",
			data: {
				strategyIdStr: strategyIdStr,
				attributeItemIdStr: attributeItemIdStr,
				attributeIdStr: attributeIdStr,
			},
		},
	};
};
