/* eslint-disable no-extend-native */
import { getAllMapsListData } from "@redux/index.js";
import { resort } from "@utils/helpers";
import { StatusCode } from "@utils/optionsSets/OptionSets.js";
import DataTable from "features/grid-table/DataTable.js";
import MapLoadingCard from "features/home/components/loading-components/MapLoadingCard.js";
import { useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { useAsync } from "shared/hooks/index";
import * as Style from "./Style.js";
import { SectionHeader } from "./section-header.jsx";
import { useTranslation } from "react-i18next";
import DataLoader from "shared/components/data-loader.jsx";
import { getAllMapsList } from "@redux/memoizedSelectors.js";

export const Maps = () => {

	const allMaps = useSelector(getAllMapsList,shallowEqual)?.getActiveRecords();
	const history = useHistory();
	const maps = useMemo(() => resort(allMaps,"lastModifiedOn","down")?.slice(0, 3),[allMaps]);

	return (
		<Style.Section>
			<SectionHeader entityText="ASPX_97" onClick={() => history.push("/journey-map")} />
			<DataLoader reduxActions={[getAllMapsListData]} disabledSpinner={maps?.length > 0} CustomLoading={<LoadingMap />} >
				<MapTable maps={maps} />
			</DataLoader>
		</Style.Section>
	);
};


const LoadingMap = () => {
	return (
		<Style.ContainerLoading>
			<MapLoadingCard />
			<MapLoadingCard />
			<MapLoadingCard />
		</Style.ContainerLoading>
	);
};

const MapTable = ({ maps }) => {
	const { t } = useTranslation();

	if (maps?.length === 0)
		return (
			<Style.NoElementContainer>
				<img src={require("@Assets/images/EmptyListImages/no-maps.svg").default} alt="no maps" />
				<div id="no-data">{t("NO_CJM")}</div>
			</Style.NoElementContainer>
		);

	return (
		<Style.TableContainer hasData={maps?.length > 0}>
			<DataTable context="map.home" linked={maps?.map((m) => m.cjmId)} />
		</Style.TableContainer>
	);
};

