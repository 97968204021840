import { ActionConfirmModal } from "shared/modals/index";
import { EntityListItem } from "shared/ui-basics/ui-components/EntityListItem";
import styled from "styled-components/macro";
import { useModal } from "use-modal-hook";
const { IconDispatcher } = require("shared/Icons");
import React from "react";

export const ProjectRelatedItemCard = ({ entityType, entity, unlink, linkedAttribute, idAttribute, showConfirmAssociatedModal, isRemoveAllowed }) => {
	const [showConfirmAssociatedObjects] = useModal(ActionConfirmModal, {
		bodyContent: "PROJECT_UNLINK_ASSOCIATED_OBJECTS",
		Action: (unlinkRelated) => unlink({ recordId: entity?.[idAttribute], linkedAttribute, unlinkRelated }),
		actionBtnText: "UNLINK",
		secondBtnText: "NO",
		beforeClose: () => unlink({ recordId: entity?.[idAttribute], linkedAttribute, unlinkRelated: false }),
	});

	const handleUnlink = (e) => {
		e.stopPropagation();
		unlink({ recordId: entity?.[idAttribute], linkedAttribute, unlinkRelated: false });
    };

	return (
		<CardWrapper className="mb-2">
			<EntityListItem entityType={entityType} entity={entity} type="project" customClassName="d-flex" />
			{isRemoveAllowed && <UnlinkButton onClick={showConfirmAssociatedModal ? showConfirmAssociatedObjects : handleUnlink}>{IconDispatcher("X")}</UnlinkButton>}
		</CardWrapper>
	);
};

const UnlinkButton = styled.div`
	display: none;
	margin-left: 1rem;
	cursor: pointer;
`;

const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #ffffff;

	border: 1px solid #ffffff;
	:hover {
		border-radius: 4px;
		border: 1px solid #d2eefb;
	}
	:hover ${UnlinkButton} {
		display: block;
	}
`;
