import { EMPTY_GUID, InteractionArrayValidations, decode, findById, hasAttribute, isEmptyObject, isNullOrEmptyObject } from "@utils/helpers";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import jwt_decode from "jwt-decode";

export const updatedState = (state, key, newData, secondKey, secondData, idsToUpdate = []) => {
	const current_map = {
		...state.current_map,
		[key]: newData,
		lastModifiedOn: new Date().toISOString(),
	};
	const all_maps = state.all_maps?.map((map) => {
		if (idsToUpdate.includes(map.cjmId)) {
			return {
				...map,
				[key]: newData,
				lastModifiedOn: new Date().toISOString(),
			};
		}
		return map;
	});
	if (secondKey !== null && secondKey !== undefined) {
		current_map[secondKey] = secondData;
	}
	return {
		...state,
		all_maps,
		current_map,
	};
};


export const addIconToCardData = (cjmDataShortCut, cardId, attribute, iconId) =>
	cjmDataShortCut.map((group) => group.map((card) => (card.id === cardId ? { ...card, [attribute]: [...(card[attribute] || []), iconId] } : card)));

export const deleteIconFromCardData = (cjmDataShortCut, cardId, attribute, iconId) =>
	cjmDataShortCut.map((group) => group.map((card) => (card.id === cardId ? { ...card, [attribute]: card[attribute]?.filter((icon) => icon !== iconId) } : card)));

export const updateCustomerExperienceData = (stageCustomerExp, personaIdStr, stageId, attribute, iconId) => ({
	...stageCustomerExp,
	[personaIdStr]: {
		...(stageCustomerExp[personaIdStr] || {}),
		[stageId]: {
			...((stageCustomerExp[personaIdStr] || {})[stageId] || {}),
			[attribute]: stageCustomerExp[personaIdStr]?.[stageId]?.[attribute] ? [...stageCustomerExp[personaIdStr][stageId][attribute], iconId] : [iconId],
		},
	},
});

export const deleteIconFromExperienceData = (stageCustomerExp, personaIdStr, stageId, attribute, iconId) => ({
	...stageCustomerExp,
	[personaIdStr]: {
		...(stageCustomerExp[personaIdStr] || {}),
		[stageId]: {
			...((stageCustomerExp[personaIdStr] || {})[stageId] || {}),
			[attribute]: stageCustomerExp[personaIdStr]?.[stageId]?.[attribute]?.filter((icon) => icon !== iconId),
		},
	},
});

export const deleteIconFromSwimLaneDetails = (cjmSwimLaneDetails, key, stageId, iconToDelete) => {
	const swimLaneDetailsCopy = { ...cjmSwimLaneDetails };
	if (swimLaneDetailsCopy[key]?.[stageId]) {
		swimLaneDetailsCopy[key][stageId].icons = swimLaneDetailsCopy[key][stageId].icons?.filter((icon) => icon !== iconToDelete);
	}
	return swimLaneDetailsCopy;
};

export const updateCopyData = (data_copy, personaIdSTR_COPY, comments) => {
	const defaultPersonaObjectCxData = { [personaIdSTR_COPY]: {} };

	data_copy.cjmPhasesData = sortData(data_copy.cjmPhasesData);
	data_copy.cjmStagesData = filterStages(data_copy);
	data_copy.cjmData = normalizeData(data_copy.cjmData, data_copy.cjmStagesData, personaIdSTR_COPY);
	data_copy.cjmStagesCustomerExperienceData = normalizeCustomerExperienceData(data_copy.cjmStagesCustomerExperienceData, defaultPersonaObjectCxData, personaIdSTR_COPY);
	const comment = updateComments(comments, data_copy.cjmStagesData);

	return {
		cjmStagesData: data_copy.cjmStagesData,
		cjmSwimLaneDetails: data_copy.cjmSwimLaneDetails,
		cjmData: data_copy.cjmData,
		cjmStagesSystemData: data_copy.cjmStagesSystemData,
		cjmStagesDepartmentData: data_copy.cjmStagesDepartmentData,
		cjmStagesCustomerExperienceData: data_copy.cjmStagesCustomerExperienceData,
		cjmStagesKpiData: data_copy.cjmStagesKpiData,
		cjmCxActions: data_copy.cjmCxActions,
		cjmOpportunities: data_copy.cjmOpportunities,
		cjmComments: comment,
		cjmSolutions: data_copy.cjmSolutions,
		cjmCxGoals: data_copy.cjmCxGoals,
		cjmTimeLines: data_copy.cjmTimeLines,
	};
};

export const sortData = (data) => {
	if (!data) return [];
	return Object.entries(data)
		.map(([_, stage]) => stage)
		.sort((a, b) => a.order - b.order);
};

export const filterStages = (data_copy) => {
	if (!data_copy.cjmPhasesData || !data_copy.cjmStagesData) return [];
	return data_copy.cjmPhasesData.map((phase) => sortData(data_copy.cjmStagesData).filter((stage) => stage.phase.id == phase.id)).flat();
};

export const normalizeData = (cjmData, cjmStagesData, personaIdSTR_COPY) => {
	if (!cjmData || Object.keys(cjmData).length === 0) {
		return new Array(cjmStagesData.length).fill([]);
	}
	return InteractionArrayValidations(cjmStagesData, personaIdSTR_COPY, cjmData);
};

export const normalizeCustomerExperienceData = (cjmStagesCustomerExperienceData, defaultPersonaObjectCxData, personaIdSTR_COPY) => {
	if (!cjmStagesCustomerExperienceData || cjmStagesCustomerExperienceData[personaIdSTR_COPY] === undefined) {
		return defaultPersonaObjectCxData;
	}
	return cjmStagesCustomerExperienceData;
};

export const updateChart = (chart, stageIdOld, stageIdNew) => {
	if (chart) {
		let updatedFeelingPoints = getUpdatedFeelingPoints(chart, stageIdOld, stageIdNew);
		updatedFeelingPoints = updatePersonasFeelingPoints(chart, stageIdOld, stageIdNew, updatedFeelingPoints);
		return { ...chart, ...updatedFeelingPoints };
	}
	return chart;
};

export const getUpdatedFeelingPoints = (chart, stageIdOld, stageIdNew) => {
	const NewFeelingPointsGlobal = chart.feelingPoints_Global?.reduce((acc, point) => {
		if (point[0] === stageIdOld) {
			acc.push([stageIdNew, point[1], getPointLabel("Global", 4)]);
		}
		return acc;
	}, []);
	const NewFeelingPointsTarget = chart.feelingPoints_Target?.reduce((acc, point) => {
		if (point[0] === stageIdOld) {
			acc.push([stageIdNew, point[1], getPointLabel("Target", 4)]);
		}
		return acc;
	}, []);

	return {
		feelingPoints_Global: [...(chart.feelingPoints_Global || []), ...(NewFeelingPointsGlobal || [])],
		feelingPoints_Target: [...(chart.feelingPoints_Target || []), ...(NewFeelingPointsTarget || [])],
		feelingPoints_Contact: [...(chart.feelingPoints_Contact || []), [stageIdNew, 0, getPointLabel("Awareness", 3)]],
	};
};

export const getPointLabel = (name, value) => `<div style="padding: 5px 5px 5px 5px; width: 100%; font-size: 8pt;"><b></b><BR/>${name}<BR/><b>${value}</b></div>`;

export const updatePersonasFeelingPoints = (chart, stageIdOld, stageIdNew, updatedFeelingPoints) => {
	const personasRelated = Object.keys(chart.feelingPoints_Perso);

	personasRelated.forEach((persona) => {
		let personaToAdd = chart.feelingPoints_Perso[persona].reduce((acc, point) => {
			if (point[0] === stageIdOld) {
				acc.push([stageIdNew, point[1], point[2]]);
			}
			return acc;
		}, []);
		updatedFeelingPoints.feelingPoints_Perso = { ...updatedFeelingPoints.feelingPoints_Perso, [persona]: [...chart.feelingPoints_Perso[persona], ...personaToAdd] };
	});

	return updatedFeelingPoints;
};

export const updateSwimlaneItem = (state, customSwimLaneItemIdStr, swimlaneKey, stageIdStr, description) => {
	if (!state.current_map.cjmSwimLaneDetails?.[swimlaneKey]?.[stageIdStr]?.items?.find((item) => item.id === customSwimLaneItemIdStr)) {
		return createSwimlaneItem(state, swimlaneKey, stageIdStr, description, customSwimLaneItemIdStr);
	}
	return {
		...state,
		current_map: {
			...state.current_map,
			cjmSwimLaneDetails: {
				...state.current_map.cjmSwimLaneDetails,
				[swimlaneKey]: {
					...state.current_map.cjmSwimLaneDetails[swimlaneKey],
					[stageIdStr]: {
						...state.current_map.cjmSwimLaneDetails[swimlaneKey][stageIdStr],
						items: state.current_map.cjmSwimLaneDetails[swimlaneKey][stageIdStr].items.map((item) =>
							item.id === customSwimLaneItemIdStr ? { ...item, description: decode(description) } : item
						),
					},
				},
			},
			lastModifiedOn: new Date().toISOString(),
		},
	};
};

export const getStageSwimlanes = (state, swimlaneKey, stageIdStr) => {
	if (state.current_map.cjmSwimLaneDetails[swimlaneKey] === undefined) {
		return {
			[swimlaneKey]: [stageIdStr],
		};
	}

	let StageSwimlanes = { ...state.current_map.cjmSwimLaneDetails[swimlaneKey][stageIdStr] };

	if (!hasAttribute(StageSwimlanes, "id")) {
		StageSwimlanes = {
			id: uuidv4(),
			swimLaneId: swimlaneKey,
		};
	}

	return StageSwimlanes;
};
export const createSwimlaneItem = (state, swimlaneKey, stageIdStr, description, responseData) => {
	const newItem = {
		id: responseData,
		description: decode(description),
		icons: [],
	};

	const StageSwimlanes = getStageSwimlanes(state, swimlaneKey, stageIdStr);
	const pastItems = state.current_map.cjmSwimLaneDetails?.[swimlaneKey]?.[stageIdStr]?.items || [];

	return {
		...state,
		current_map: {
			...state.current_map,
			cjmSwimLaneDetails: {
				...state.current_map.cjmSwimLaneDetails,
				[swimlaneKey]: {
					...state.current_map.cjmSwimLaneDetails?.[swimlaneKey],
					[stageIdStr]: {
						...StageSwimlanes,
						items: [...pastItems, newItem],
					},
				},
			},
			lastModifiedOn: new Date().toISOString(),
		},
	};
};

export const updateComments = (comments, cjmStagesData) => {
	if (!comments || !cjmStagesData) return comments;

	// Loop over cjmStagesData
	cjmStagesData.forEach((stage) => {
		// Loop over comments
		for (let swimlaneKey in comments) {
			let swimlane = comments[swimlaneKey];
			let stageId = stage.id;
			let attributeId = EMPTY_GUID;

			if (swimlane[stageId]) {
				attributeId = Object.keys(swimlane[stageId])[0];
			}

			// Add new stage to each swimlane
			swimlane[stageId] = swimlane[stageId] || {};
			swimlane[stageId][attributeId] = {
				comments: swimlane[stageId][attributeId]?.comments || [],
				showComments: swimlane[stageId][attributeId]?.showComments || false,
			};
		}
	});

	return comments;
};
export const handleSwimLaneInitValues = (type, swimLaneInitValues, originalData, cjmStages, personaIdStr) => {
	switch (type) {
		case "ActionsList":
		case "Opportunity":
			return swimLaneInitValues;
		case "Timing":
			return cjmStages.map((stage) => {
				const relevantStage = swimLaneInitValues.cjmStagesData[stage.id];
				if (stage.id === relevantStage.id) stage.timing = relevantStage.timing;
				return stage;
			});
		case "Systems":
		case "Departments":
			return swimLaneInitValues;
		case "Touchpoints":
			return swimLaneInitValues === null || Object.keys(swimLaneInitValues).length === 0
				? new Array(cjmStages.length).fill([])
				: InteractionArrayValidations(cjmStages, personaIdStr, swimLaneInitValues);
		case "Feelings":
		case "AdvancedInteractions":
			return swimLaneInitValues?.personaInteractions
				? swimLaneInitValues === null || Object.keys(swimLaneInitValues?.personaInteractions).length === 0
					? new Array(cjmStages.length).fill([])
					: InteractionArrayValidations(cjmStages, personaIdStr, swimLaneInitValues?.personaInteractions)
				: originalData;
		default:
			return originalData;
	}
};
export function deleteItemFromStage(stageDetails, customSwimLaneItemIdStr) {
	return {
		...stageDetails,
		items: stageDetails.items.filter((item) => item.id !== customSwimLaneItemIdStr),
	};
}
export function deleteLibraryItemFromStage(stageDetails, swimLaneItemId) {
	return {
		...stageDetails,
		items: stageDetails.items.filter((item) => item.id !== swimLaneItemId),
	};
}
export function deleteLibraryItemsFromStage(stageDetails, swimLaneItemIds) {
	return {
		...stageDetails,
		items: stageDetails.items.filter((item) => !swimLaneItemIds.includes(item.id)),
	};
}
export function moveComments(object, swimlaneId, stageIdOrigin, stageIdDestination, attributeId) {
	const origin = object?.[swimlaneId]?.[stageIdOrigin]?.[attributeId];
	const destination = object?.[swimlaneId]?.[stageIdDestination]?.[attributeId];
	if (origin && destination) {
		destination.showComments = origin.showComments;
		destination.comments = [
			...(destination?.comments || []), // Use empty array if destination.comments is falsy
			...(origin?.comments || []), // Use empty array if origin.comments is falsy
		];
		origin.showComments = false;
		origin.comments = [];
	}

	return object;
}

export const formatCjmData = (cjmData, stages, personaIdStr) => {
	if (!cjmData || !Object.keys(cjmData).length) {
		// If the cjmData is empty or undefined, return an array of empty arrays
		return new Array(stages.length).fill([]);
	} else {
		// Otherwise, validate the cjmData against the stages and personaIdStr
		return InteractionArrayValidations(stages, personaIdStr, cjmData);
	}
};
export const formatPersonaInteractions = (cjmData, personaIdStr) => {
	const personaInteractions = { ...cjmData };
	// delete personaInteractions[personaIdStr];
	return personaInteractions;
};

export function filterGlobalLegend(legend, config) {
	if (!config) return legend;

	return legend.filter((item) => {
		switch (item) {
			case "X":
				return true;
			case "VoC":
				return config.showGlobalCustomerSentiment;
			case "Global":
				return config.showGlobalPersonaSentiment;
			case "Target":
				return config.showTargetSentiment;
			default:
				return false;
		}
	});
}

export function processResponseDataChart(data) {
	if (!data.feelingPoints_Global) return data;

	const parseArrayStr = (str) => {
		try {
			return eval(`[${str}]`);
		} catch (err) {
			return [];
		}
	};
	return {
		...data,
		feelingPoints_Global: parseArrayStr(data.feelingPoints_Global),
		feelingPoints_Target: parseArrayStr(data.feelingPoints_Target),
		feelingPoints_Contact: parseArrayStr(data.feelingPoints_Contact),
		feelingPoints_Perso: Object.fromEntries(Object.entries(data.feelingPoints_Perso).map(([key, value]) => [key, parseArrayStr(value)])),
		feelingPoints_PersoVOC: Object.fromEntries(Object.entries(data.feelingPoints_PersoVOC).map(([key, value]) => [key, parseArrayStr(value)])),
	};
}

export const updateStateMuliCJM = (state, updates, updateSameLevel = {}) => {
	const current_map = {
		...state.current_map,
		...updates,
		lastModifiedOn: new Date().toISOString(),
	};
	return {
		...state,
		current_map,
		...updateSameLevel,
	};
};

export const getLegend = (cjmSwimLanes, cjmPersonasData) => {
	const sentimentsConfig = cjmSwimLanes?.find((swimlane) => swimlane?.type === 100000012)?.sentimentsConfig;
	const cookie = Cookies.get("Authorization-cemantica") || window.location.pathname?.split("&token=")[1]?.split("&")[0];

	const isEnterprise = cookie ? jwt_decode(cookie)?.UserSubscriptionPlanName === 'Enterprise' ? true : false : true;
	const initialGlobalLegend = ["X", "Global", "Target", "VoC"].filter(item => !isEnterprise ? item !== "VoC" : item);
	const personaIdsOfMap = cjmPersonasData?.map((item) => item.personaId)?.filter((id) => sentimentsConfig?.showPersona[id] || true) || [];

	const globalLegend = filterGlobalLegend(initialGlobalLegend, sentimentsConfig);
	const personaSentiment = personaIdsOfMap
		.map((id) => {
			if (!sentimentsConfig || (sentimentsConfig.showPersonaSentiment?.[id] && sentimentsConfig?.showPersona?.[id])) return id;
		})
		.filter(Boolean);

	const personaSentimentVOC = isEnterprise ? personaIdsOfMap
		.map((id) => {
			if (!sentimentsConfig || (sentimentsConfig.showCustomerSentiment?.[id] && sentimentsConfig?.showPersona?.[id])) return `${id}_VOC`;
		})
		.filter(Boolean) : [];
	return [...globalLegend, ...personaSentiment, ...personaSentimentVOC];
};

export const extractAndSortData = (dataField) =>
	dataField !== null
		? Object.entries(dataField)
			.map(([, item]) => item)
			.sort((a, b) => a.order - b.order)
		: [];

export const incrementPendingRequests = (state) => {
	const pendingRequests = state.pendingRequests || 0;
	return {
		...state,
		pendingRequests: pendingRequests + 1,
	};
}
export const decrementPendingRequests = (state) => {
	const pendingRequests = state.pendingRequests || 0;
	return {
		...state,
		pendingRequests: pendingRequests - 1,
	};
}
export const calculteRoutes = (cjmContactData, personaIdStr) => {
	let interactionCountByStage = {};
	const personaContact = cjmContactData?.[personaIdStr];

	if (personaContact) {
		Object.keys(personaContact)?.forEach(stageKey => {
			let maxKey = null;
			let maxLength = 0;
			let secondMaxKey = null;
			let secondMaxLength = 0;

			const stageContacts = personaContact[stageKey];
			if (stageContacts) {
				Object.keys(stageContacts).forEach(key => {
					const contact = stageContacts[key];
					if (contact && Array.isArray(contact)) {
						if (contact.length > maxLength) {
							// Update second max before updating max
							secondMaxLength = maxLength;
							secondMaxKey = maxKey;

							// Update max
							maxLength = contact.length;
							maxKey = key;
						} else if (contact.length > secondMaxLength) {
							// Update second max
							secondMaxLength = contact.length;
							secondMaxKey = key;
						}
					}
				});
			}

			if (maxKey !== null) {
				interactionCountByStage[stageKey] = {
					mainKey: maxKey,
					mainLength: maxLength,
					alternativeKey: secondMaxKey,
					alternativeLength: secondMaxLength
				};
			}
		});
	}
	return interactionCountByStage;

}
export const applyRoutesToCJMData = (cjmData, interactionCountByStage, stages) => {

	// Transform cjmData using map
	return cjmData?.map((stageArray, stageIndex) => {
		const stageKey = stages[stageIndex].id;
		const interactionCount = interactionCountByStage[stageKey];

		if (stageArray && Array.isArray(stageArray) && interactionCount) {
			const { mainKey, alternativeKey } = interactionCount;

			return stageArray.map(card => ({
				...card,
				mainRoute: card.touchPointId === mainKey,
				alternativeRoute: card.touchPointId === alternativeKey
			}));
		}

		return stageArray;
	});
};