import styled from "styled-components";
import ProjectMenu from "./ProjectMenu";
import React,{ useRef, useState } from "react";
import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Flex } from "shared/ui-basics/index";
import { ProjectBusinessCase } from "./BusinessCase/ProjectBusinessCase";
import { ProjectGeneral } from "./ProjectGeneral";
import { ProjectRelatedItems } from "./ProjectRelatedItems";

export const ProjectBody = () => {
	const [activeLink, setActiveLink] = useState("projectGeneral");
	const bodyRef = useRef(null);
	const { search } = useLocation();

	const hideForPdf = search.hideForPdf();

	return (
		<Body>
			<Flex>
				{!hideForPdf && (
					<MenuContainer>
						<ProjectMenu activeLink={activeLink} />
					</MenuContainer>
				)}
				<ColWrapper ref={bodyRef}>
					<CustomContainer fluid="xl" id="projectPDFRef">
						<ProjectGeneral setActiveLink={setActiveLink} innerRef={bodyRef} />
						<ProjectBusinessCase setActiveLink={setActiveLink} innerRef={bodyRef} />
						<ProjectRelatedItems setActiveLink={setActiveLink} innerRef={bodyRef} />
					</CustomContainer>
				</ColWrapper>
			</Flex>
		</Body>
	);
}

const Body = styled.div`
	width: 100%;
	height: 100%;
    margin-top: 8px;
`;

const ColWrapper = styled.div`
	flex-basis: 80%;
	height: calc(100vh - 300px);
	overflow: scroll;
`;

const MenuContainer = styled.div`
	flex-basis: 20%;
	display: flex;
	flex-direction: column;
	align-items: start;
	padding-left: 1rem;
    border-right: 1px solid #efefef;
`;

const CustomContainer = styled(Container)`
	max-width: 960px;
`;
