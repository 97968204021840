import {
	libraryInit,
	cxGoalsInit,
	getAllCxAction,
	getAllCxMetrics,
	getAllCxProgram,
	getAllMapsListData,
	getAllOpportunity,
	getAllPersonas,
	getAllSolutions,
	getAllStakeholderRoles,
	getOrganizationSettings,
	groupInit,
	measureInit,
	priorityInit,
	statusInit,
	tagInit,
} from "@redux/index";
import { handleAddRenderElement, toastWrapper } from "@utils/helpers";
import NoDataComponent from "features/auth/view/no-data-component";
import { connectorInit } from "features/integrations/reducer/ConnectorAction";
import { getProject, getProjectPdfExport, getProjectUsers, resetProject } from "features/projects/reducer/ProjectsActions";
import $ from "jquery";
import "quill-mention";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { Spinner } from "shared/components/spinners/Spinner";
import { useAsyncs } from "shared/hooks/index";
import styled from "styled-components";
import { ProjectBody } from "../components/ProjectBody";
import ProjectHeader from "../header/ProjectHeader";

export default function ProjectPage() {
	const { search } = useLocation();
	const pdfRef = useRef();
	const hideForPdf = useLocation().search.hideForPdf();
	const projectId = search.getId();
	const dispatch = useDispatch();

	const userId = useSelector((state) => state.auth.userInfo.UserId);

	const noAccess = useSelector((state) => state.projects.current_project?.noAccess);

	const [loading, setLoading] = useState(true);
	const [counter, setCounters] = useState(0);

	const asyncFns = [
		{ asyncFn: getProjectUsers, parameter: { projectId, userId } },
		{ asyncFn: getAllOpportunity },
		{ asyncFn: getAllSolutions },
		{ asyncFn: getAllCxAction },
		{ asyncFn: getAllMapsListData },
		{ asyncFn: getAllPersonas },
		{ asyncFn: getAllCxProgram },
		{ asyncFn: getProject, parameter: { projectId } },
		{ asyncFn: statusInit },
		{ asyncFn: groupInit },
		{ asyncFn: priorityInit },
		{ asyncFn: cxGoalsInit },
		{ asyncFn: getAllStakeholderRoles },
		{ asyncFn: getAllCxMetrics },
		{ asyncFn: tagInit },
		{ asyncFn: libraryInit },
		{ asyncFn: measureInit },
		{ asyncFn: getOrganizationSettings },
		{ asyncFn: connectorInit },
	];

	useAsyncs({
		asyncFns,
		setLoading,
		resets: [resetProject],
	});

	const forceReload = () => setCounters((prevKey) => prevKey + 1);

	const getProjectPDFService = ({ id, name, width, height, renderDelay = -1 }) => {
		const promise = async () => {
			const { data, error } = await dispatch(getProjectPdfExport({ id, width, height, renderDelay }));
			var fileDownload = require("js-file-download");
			fileDownload(data, name + ".pdf");
			if (error) {
				throw error;
			}
		};
		toastWrapper(promise, "EXPORT_PDF");
	};
	const handleExport = (project) => {
		const width = pdfRef?.current?.offsetWidth;
		const height = $("#projectPDFRef")?.[0]?.offsetHeight;
		getProjectPDFService({ id: project.id, name: project.name, width, height });
	};
	if (noAccess) return <NoDataComponent label={"NO_ACCESS"} icon="CIRCLE-EXCLAMATION" fixed={false} />;

	return (
		<div key={counter} className="p-2">
			{loading ? (
				<Spinner />
			) : (
				<div ref={pdfRef}>
					<ProjectHeader forceReload={forceReload} handleExport={handleExport} />
					<MainContainer className="mt-2" fluid>
						<ProjectBody />
					</MainContainer>
					{hideForPdf && <div id="addRenderElement">{handleAddRenderElement()} </div>}
				</div>
			)}
		</div>
	);
}

const MainContainer = styled(Container)`
	display: flex;
	background: #ffffff;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	padding: 1rem;
	min-height: calc(100vh - 240px);
	padding-left: 0px;
	padding-right: 0px;
`;
