import { StripHtml } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import InsightType from "shared/ui-basics/ui-components/InsightType";
import styled, { css } from "styled-components/macro";

const InsightPickListCard = ({ insight, handleLink ,selected}) => {
	const { t } = useTranslation();

	const name = insight?.name ? insight.name : t("NEW_INSIGHT_NAME");
	const description = StripHtml(insight?.description);

	return (
		<Container onClick={() => handleLink({ recordId: insight?.id })} selected={selected}>
			<Details showDescription={description ? true : false}>
				<Name>{name}</Name>
				{description && <Description>{description}</Description>}
			</Details>
			<InsightTypeContainer>
				<InsightType typeId={insight?.typeId} />
			</InsightTypeContainer>
		</Container>
	);
};

InsightPickListCard.displayName = "InsightPickListCard";
export default InsightPickListCard;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	height: 56px;
	padding: 4px 16px;
	:hover {
		cursor: pointer;
		background: #efefef;
	}
	${(props) =>
		props.selected &&
		css`
			/* background: #efefef; */
			pointer-events: none;
			opacity: 0.5;
		`}
`;

const Details = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	height: 100%;
	width: 385px;
	${(props) =>
		!props?.showDescription &&
		css`
			display: flex;
			align-items: center;
		`}
`;

const Name = styled.div`
	color: #333;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const Description = styled.div`
	height: 100%;
	color: #605f60;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const InsightTypeContainer = styled.span`
	height: 100%;
	width: 70px;
    align-content: center;
`;
