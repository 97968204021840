import React from "react";
import styled from "styled-components/macro";
import { OneRow, XAxis, YAxis } from "shared/components/charts-components/ChartBuildingBlocks";
import { Badge, Col, Row } from "react-bootstrap";
import { ChartPoint } from "shared/components/charts-components/ChartPoint";
import { GroupPointSelector } from "shared/components/charts-components/GroupPointSelector";
import { useLocation } from "react-router-dom";
import { handleAddRenderElement } from "@utils/helpers";

export const Chart = ({ itemsByPosition, CustomPoint, CustomLabels, route, xTitle, yTitle, scale, baseUnit, pdfRef, yAxisPosLeft, yAxisPosBottom, yAxisTitlePos, isDoubleWidth }) => {
	const location = useLocation();
	const hideForPdf = location.search.hideForPdf();
	const isQuadrant = location.pathname === "/solutions";

	return (
		<ChartContainer hideForPdf={hideForPdf}>
			{hideForPdf && <div id="addRenderElement">{handleAddRenderElement()} </div>}

			<Legend pdfRef={pdfRef} hideForPdf={hideForPdf} itemsByPosition={itemsByPosition} />

			<StyledChart className="pt-4">
				{Array.from(Array(scale.length - 1).keys()).map((index) => (
					<OneRow key={index} scale={scale} halfYAxisSquare={isQuadrant && index === (scale.length - 1) / 2 - 1} baseUnit={baseUnit} isDoubleWidth={isDoubleWidth} />
				))}
				{Object.entries(itemsByPosition)?.map((position, index) => {
					const xCoordinate = position[0].split("|")[0];
					const yCoordinate = position[0].split("|")[1];
					return (
						<ChartPoint
							key={position[0] + index}
							items={position[1]}
							xCoordinate={xCoordinate}
							yCoordinate={yCoordinate}
							CustomPoint={CustomPoint}
							index={index}
							route={route}
							baseUnit={baseUnit}
							isDoubleWidth={isDoubleWidth}
						/>
					);
				})}
				<XAxis scale={scale} baseUnit={baseUnit * (isDoubleWidth ? 2 : 1)} title={xTitle} />
				<YAxis scale={scale} baseUnit={baseUnit} title={yTitle} yAxisPosLeft={yAxisPosLeft} yAxisPosBottom={yAxisPosBottom} yAxisTitlePos={yAxisTitlePos} />
				{CustomLabels && <CustomLabels />}
			</StyledChart>
		</ChartContainer>
	);
};

const Legend = ({ itemsByPosition, hideForPdf, pdfRef }) => {
	return (
		<LegendWrapper ref={pdfRef} hideForPdf={hideForPdf}>
			{Object.entries(itemsByPosition)?.map((position, index) => {
				const items = position[1];
				return (
					<Row key={index} className="pt-4">
						<Col xs={2}>
							<Badge pill bg="secondary">
								{index + 1}
							</Badge>
						</Col>
						<Col>
							<GroupPointSelector items={items} />
						</Col>
					</Row>
				);
			})}
		</LegendWrapper>
	);
};

const ChartContainer = styled.div`
	display: flex;
	justify-content: center;
	${(props) =>
		props.hideForPdf === true &&
		`
        margin-top: 50px;
        margin-left: 200px;
    `}
`;

const LegendWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 40px;
	${(props) => !props.hideForPdf && `visibility:hidden;`}
`;

const StyledChart = styled.div`
	position: relative;
`;
