import { unlinkPersona } from "@redux/index";
import { encode } from "@utils/helpers";
import PersonaBubble from "features/forms/personas/components/PersonaBubble";
import { getCurrentMap } from "features/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomTooltip from "shared/components/CustomTooltip";
import { ActionConfirmModal } from "shared/modals/index";
import { GenericButton } from "shared/ui-basics/index";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";

const ManagePersonaCard = ({ personaId, cjmIdStr, selectedCard, setSelectedCard, isCurrent, nextPersonaId, isViewable = true, isRemovable, isSelectable = true, displayLine }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const MAX_LENGTH = 30;

	// redux
	const cjmName = useSelector((state) => state.maps.current_map.cjmName);
	const personaDetails = useSelector((state) => state.personas.all_personas?.find((pers) => personaId === pers.personaId));
	//state
	const updateSentimentLevel = () => getCurrentMap({cjmIdStr, personaId,onlySentimentData: true});

	const viewJourney = () => {
		history.push(`/EditMap?cjmid=${cjmIdStr}&personaId=${nextPersonaId}`);

	};

	const onSuccess = () => {
		if (isCurrent) viewJourney();
		updateSentimentLevel();
	};

	// modals
	const [showModalRemove, hideModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: "UNLINK_PERSONA_MESSAGE",
		Action: () => unlinkPersona({cjmIdStr,personaIdStr:personaId, onSuccess}),
		actionBtnText: "REMOVE",
	});

	const redirectToPersona = () => history.push(`/EditPersona?personaIdStr=${personaId}&isInMap=1&origin=${encode(cjmName)}`);
	const handleSelection = () => setSelectedCard &&  setSelectedCard(personaDetails?.personaId);

	return (
		<CardContainer>
			<Card className="p-2" selected={personaDetails?.personaId === selectedCard} isSelectable={isSelectable} onClick={handleSelection}>
				{isSelectable && <RadioButton selected={personaDetails?.personaId === selectedCard} />}
				{displayLine && displayLine()}
				<div className="mx-2">
					<PersonaBubble id="persona-picture" persona={personaDetails} size={"40"} />
				</div>
				<DetailsWrapper className="me-5">
					<CustomTooltip text={personaDetails?.name} disabled={!personaDetails?.name || personaDetails?.name?.length <= MAX_LENGTH}>
						<PersonaName className="text-truncate" isSelectable={isSelectable}>
							{personaDetails?.name}
						</PersonaName>
					</CustomTooltip>
					<Occupation className="text-truncate">{personaDetails?.occupation}</Occupation>
				</DetailsWrapper>
				{isViewable && (
					<HiddenButton>
						<GenericButton variant="light-link" icon={"VIEW"} tooltipText={t("JS_165")} tooltip onClick={redirectToPersona} />
					</HiddenButton>
				)}
			</Card>
			{isRemovable && (
				<HiddenButton className="ms-2">
					<GenericButton id="removeButton" variant="light-link" icon={"X"} tooltipText={t("REMOVE_PERSONA")} tooltip onClick={showModalRemove} />
				</HiddenButton>
			)}
		</CardContainer>
	);
};

export default ManagePersonaCard;

const HiddenButton = styled.div`
	visibility: hidden;
`;

const CardContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	&:hover ${HiddenButton} {
		visibility: visible;
	}
`;

const Card = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid ${(props) => (props.selected ? "#333333" : "#D9D9D9")};
	:hover {
		box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}
	${(props) =>
		props.isSelectable === false &&
		css`
			border: none;
			:hover {
				box-shadow: none;
				cursor: default;
			}
		`};
`;

const RadioButton = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 100px;
	border: ${(props) => (props.selected ? "4px solid #333333" : "1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15))")};
	background: var(--gray-white, #fff);
`;

const PersonaName = styled.div`
	width: 100%;
	color: #333;
	font-size: 16px;
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;
	${(props) =>
		props.isSelectable === false &&
		css`
			cursor: default;
		`};
`;

const DetailsWrapper = styled.div`
	width: 200px;
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const Occupation = styled.div`
	color: #333;
	font-size: 14px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
