import { getFullUsersLicenseAvailable, getInactiveUsersCount } from "@utils/helpers";
import { SubscriptionPlan, UserType } from "@utils/optionsSets/OptionSets";
import { useTranslation } from "react-i18next";
import { FlexColumnAlignJustifyCenter, Label } from "shared/ui-basics";
import styled from "styled-components";

const ContractDetailsCard = ({ contract, users }) => {
	const { t } = useTranslation();
	const fullUsersLicenses = getFullUsersLicenseAvailable(users, contract?.subscriptionPlanValue);
	const isStandard = contract.subscriptionPlanValue === SubscriptionPlan.Standard;

	const seatUsed = fullUsersLicenses + (contract?.readOnlyUsersCount ? contract?.readOnlyUsersCount : 0);
	const ReadOnlyUsers = users?.reduce((acc, user) => (user.userType ===  UserType.readOnly ? acc + 1 : acc), 0);

	const DisplayNumber = ({ number, total }) => {
		return (
			<div className="d-flex align-items-center ">
				<Number>{number}</Number>
				{total && !isNaN(total) && number !== "NA" && (
					<>
						<div className="mx-2">{"/"}</div>
						<Total>{total}</Total>
					</>
				)}
			</div>
		);
	};

	return (
		<CardContainer>
			<PackageContainer>
				<Package>{contract?.subscriptionPlan}</Package>
			</PackageContainer>
			<Wrap>
				<FlexColumnAlignJustifyCenter>
					<DisplayNumber number={seatUsed} />
					<Label>{t("SEATS_USED")}</Label>
				</FlexColumnAlignJustifyCenter>
				<FlexColumnAlignJustifyCenter>
					<DisplayNumber number={fullUsersLicenses} total={String(contract?.usersNumber)} />
					<Label>{t("FULL_USERS")}</Label>
				</FlexColumnAlignJustifyCenter>
				<FlexColumnAlignJustifyCenter>
					<DisplayNumber number={!isStandard ? ReadOnlyUsers : "-"} />
					<Label>{t("READ_ONLY_2")}</Label>
				</FlexColumnAlignJustifyCenter>
				<FlexColumnAlignJustifyCenter>
					<DisplayNumber number={getInactiveUsersCount(users, contract.subscriptionPlanValue)} />
					<Label>{t("INACTIVE")}</Label>
				</FlexColumnAlignJustifyCenter>
			</Wrap>
		</CardContainer>
	);
};

ContractDetailsCard.displayName = "ContractDetailsCard";
export default ContractDetailsCard;

const CardContainer = styled.div`
	flex-basis: 33.3%;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	border: 1px solid #efefef;
	background: #f9fbfc;
	padding-left: 24px;
	padding-right: 24px;
`;

const PackageContainer = styled.div`
	width: 100%;
	border-bottom: 1px solid #efefef;
	display: flex;
	justify-content: center;
	padding-top: 24px;
	padding-bottom: 16px;
`;
const Wrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 16px;
	padding-bottom: 24px;
`;

const Package = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
`;

const Number = styled.div`
	color: #333;
	text-align: center;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
`;

const Total = styled.div`
	color: #333;
	text-align: center;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
