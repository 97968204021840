import { truncateWithEllipsis } from "@utils/helpers";
import styled from "styled-components";

export const AnalyticCustomTooltip = ({ data, entity }) => {
	return (
		<TooltipContainer className="p-2">
			{data.slice(0, 3).map((item,index) => (
				<p key={index}>
					{truncateWithEllipsis(item?.subject, 90)}
				</p>
			))}
			{data?.length > 3 ?  <p>{`+${data?.length - 3} ${entity}`}</p> : null}
		</TooltipContainer>
	);
};

const TooltipContainer = styled.div`
	max-width: 400px;
	max-height: 250px;
	background: #fff;
	color: #605f60;
	font-size: 14px;
	font-family: "Inter";
	border: 1px solid #c4c4c4;
	border-radius: 4px;
`;
