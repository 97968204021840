import styled from "styled-components";

export const SectionTitle = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	margin-bottom: 24px;
`;

export const CustomGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	gap: 16px;
`;
