import { changePassword } from "features/auth/reducer/AuthActions";
import { useState } from "react";
import { useModal } from "use-modal-hook";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormGroup } from "reactstrap";
import { ActionConfirmModal } from "shared/modals";
import { IconDispatcher, Input, Label } from "shared/ui-basics";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components";

const PasswordManager = ({ onClose }) => {
	// 8 alphanumeric characters
	const { t } = useTranslation();

	const [passwords, setPasswords] = useState({ newPassword: "", newPasswordVisible: false, confirmedPassword: "", confirmedPasswordVisible: false });
	const [passwordValidations, setPasswordValidations] = useState({ isValidFormat: true, areIdentical: true });
	const [actionConfirmModal] = useModal(ActionConfirmModal);

	function isValidPassword(password) {
		if (password.length < 8) {
			return false;
		}

		const letterPattern = /[A-Za-z]/;
		const numberPattern = /\d/;
		const validPattern = /^[A-Za-z\d]+$/;

		return letterPattern.test(password) && numberPattern.test(password) && validPattern.test(password);
	}

	const handlePasswordChange = (e) => {
		const attribute = e.target.name;
		const value = e.target.value;
		if (attribute === "newPassword") {
			const isValidFormat = isValidPassword(value);
			const areIdentical = passwords.confirmedPassword === value;
			setPasswordValidations({ ...passwordValidations, isValidFormat, areIdentical });
		}
		if (attribute === "confirmedPassword") {
			const isValid = passwords.newPassword === value;
			if (isValid) setPasswordValidations({ ...passwordValidations, areIdentical: true });
			else setPasswordValidations({ ...passwordValidations, areIdentical: false });
		}

		e.preventDefault();
		if (e.charCode === 13) return;
		setPasswords({ ...passwords, [attribute]: value });
	};

	const toggleVisibility = ({ attribute }) => {
		setPasswords({ ...passwords, [attribute]: !passwords[attribute] });
	};

	const handleChangePassword = () => {
		actionConfirmModal({
			bodyContent: "PASSWORD_CHANGE_WARNING",
			Action: () => changePassword({ password: passwords.newPassword, onSuccess: onClose }),
			actionBtnText: "CHANGE_PASSWORD",
		});
	};

	const isActionDisabled = passwords?.newPassword === "" || (!passwordValidations?.isValidFormat || !passwordValidations?.areIdentical);

	return (
		<>
			<PasswordSection className="mt-5">
				<SectionTitle className="mb-5">{t("CHANGE_PASSWORD")}</SectionTitle>
				<CustomGroup className="mb-3">
					<Label>{t("NEW_PASSWORD")}</Label>
					<InputGroup>
						<Input
							variant="greyed-disable"
							type={passwords?.newPasswordVisible ? "text" : "password"}
							name="newPassword"
							value={passwords?.newPassword || ""}
							onChange={handlePasswordChange}
							invalid={!passwordValidations?.isValidFormat}
						/>
						<FormFeedback>{t("PASSWORD_FORMAT_WARNING")}</FormFeedback>
						<InputIcon onClick={() => toggleVisibility({ attribute: "newPasswordVisible" })}>{IconDispatcher("eye")}</InputIcon>
					</InputGroup>
				</CustomGroup>
				<CustomGroup>
					<Label>{t("CONFIRM_NEW_PASSWORD")}</Label>
					<InputGroup>
						<Input
							variant="greyed-disable"
							type={passwords?.confirmedPasswordVisible ? "text" : "password"}
							name="confirmedPassword"
							value={passwords?.confirmedPassword || ""}
							onChange={handlePasswordChange}
							invalid={!passwordValidations?.areIdentical}
						/>
						<FormFeedback>{t("PASSWORD_CONFIRMATION_WARNING")}</FormFeedback>
						<InputIcon onClick={() => toggleVisibility({ attribute: "confirmedPasswordVisible" })}>{IconDispatcher("eye")}</InputIcon>
					</InputGroup>
				</CustomGroup>
			</PasswordSection>
			<GenericButton variant="primary" className="text-capitalized my-3" onClick={handleChangePassword} disabled={isActionDisabled}>
				{t("CHANGE_PASSWORD")}
			</GenericButton>
		</>
	);
};

PasswordManager.displayName = "PasswordManager";
export { PasswordManager };

const PasswordSection = styled.div`
	display: flex;
	flex-direction: column;
`;

const SectionTitle = styled.div`
	color: #333;
	font-family: "Inter";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const InputGroup = styled.div`
	position: relative;
	.form-control.is-invalid {
		background-image: none;
	}
`;

const InputIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 38px;
	position: absolute;
	left: 350px;
	top: 0px;
	:hover {
		cursor: pointer;
	}
`;

const CustomGroup = styled(FormGroup)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
