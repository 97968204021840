import styled, { css } from "styled-components/macro";
import { TableTemplate } from "../components/TableTemplate";
import { Link } from "react-router-dom";
import { DropDownPosition2 } from "shared/dropdowns/CreateActionsDropdown";

export const Redirect = styled(Link)`
	text-decoration: none;
	${(props) => props.disableclick === "true" && "pointer-events:none;"}
`;
export const StyledMapName = styled.div`
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: pointer;
	overflow: hidden;
	font-weight: 500 !important;
	white-space: normal;
	/* @media screen and (max-width: 1290px) {
    max-width: 8rem;
} */
`;


export const MapIconContainer = styled.div`


	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
	& img {
		width: 32.02px;
		height: 32px;
		object-fit: cover;
		position: relative;

		/* margin-right: 0.5rem; */
		border-radius: 50%;
		z-index: 1;
	}

	${(props) =>
		props.disabled == false &&
		`

:hover #icon-map{
  visibility: visible;
  z-index: 2;
}

:hover {
    background-color: #333333;
	z-index: 2;
	border-radius: 50%;
	img {
	visibility: hidden;
}
`}
`;
export const ChangeIconButton = styled.button`
	position: absolute;
	right: 29%;
	background-color: transparent;
	border: none;
	color: black;
	visibility: hidden;
	path {
		fill: #ffffff;
	}
`;
export const DropDownPosition = styled.div`
	position: absolute;
	right: -10px;
	top: -16px;
	display: flex;
`;
export const BubbleCount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #d4dde1;
	width: ${(props) => props.bubbleSize ?? 35 + 1}px;
	height: ${(props) => props.bubbleSize ?? 35 + 1}px;
	min-width: ${(props) => props.bubbleSize ?? 35 + 1}px;
	border-radius: 50%;
	color: #605f60;
`;
export const SentimentLevelWrapper = styled.div`
	/* line-height: 26px; */
	max-width: 60px;
	min-width: 60px;
	/* height: 26px; */
	border-radius: 4px;
	${(props) =>
		props.variant === "small" &&
		css`
			font-size: 14px !important;
		`}
`;
export const EmotionLevel = styled.div`
	height: inherit;
	svg {
		width: 26px;
		height: 26px;
	}
`;

export const DataTableCustom = styled(TableTemplate)`
	tr {

		${DropDownPosition2}{
			display: none;
		}
		:hover ${DropDownPosition2} {
			display: block;
		}

		:hover ${DropDownPosition} {
			${(props) => (props.selection === null || props.selection?.length === 0 || props.selection === undefined ? "display:block;" : "")}
		}
		max-height: 56px;
		/* background: #f8f9fa; */
		/* width:fit-content !important; */
	}

	tr {
		th:first-child {
			.p-column-resizer {
				display: none;
			}
		}
		th:last-child {
			.p-column-resizer {
				display: none;
			}
		}
	}
	.p-column-title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.p-datatable-wrapper .p-datatable-table {
		table-layout: fixed !important;
	}
	.p-datatable-table {
		width: 100%;
	}
	.p-datatable .p-checkbox.p-checkbox-focused {
		outline: none;
		box-shadow: none;
	}
`;
export const BoxContainer = styled.div`
	border-radius: 4px;
	${DropDownPosition} {
		right: -46px;
		top: -10px;
	}
	:hover ${DropDownPosition} {
		display: block;
	}
`;
export const PersonaPicture = styled.img`
	width: ${(props) => props.imgSize}px !important;
	height: ${(props) => props.imgSize}px !important;
	border-radius: 50%;
	object-fit: cover;
`;

export const EmotionContainer = styled.div`
	border-radius: 4px;
`;

export const MainDataTableContainer = styled.div`
	${(props) =>
		props.context !== "home" &&
		css`
			//   overflow-x: auto;
		`}

	border-radius: 0.25rem 0.25rem 0rem 0rem;
	${(props) => props.small == "true" && "padding:1rem;"}
	${(props) => props.nonDropDown == "true" && "min-height:120px"}
`;
export const HeaderWrapper = styled.div`
	display: flex;
	position: sticky;
	right: 1px;
	align-items: center;
`;

export const HierarchyIcon = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: -3px;
	bottom: -2px;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	background-color: #fff;
	filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
    z-index: 9999;
	svg {
		width: 14px;
		height: 14px;

	}
`;

export const EmotionPosition = styled.div`
	position: absolute;
	bottom: -8px;
	right: -8px;
`;
export const PopoverItem = styled.div`
	padding: 0.5rem 1rem;
	cursor: pointer;
	color: #333333;

	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	:hover {
		background-color: #f8f9fa;
	}

	${(props) =>
		props.ai &&
		css`
			background: var(--AI-gradient-bg, linear-gradient(104deg, #ffeee3 0.73%, #fff8ee 32.71%, #e8f7ff 66.16%, #f1f0ff 97.16%));
			svg {
				color: #333333;
			}
		`};
`;
