import { Dropdown } from "primereact/dropdown";
import { Label } from "reactstrap";
import styled, { css } from "styled-components/macro";

export const FieldWrapper = styled.div`
	margin-bottom: 24px;
`;

export const LabelWrapper = styled(Label)`
	color: #92919d;
	margin-bottom: 16px;
`;

export const DisplayFlexWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border: 1px solid #d9dbde;
	${(props) =>
		props.disabled &&
		css`
			pointer-events: none;
		`}

	svg {
		cursor: pointer;
		width: 35px;
		height: 35px;
	}
`;

export const DivDropdown = styled.div`
	.p-dropdown {
		:hover {
			border-color: #9df2f2;
		}
		:active {
			border-color: #9df2f2 !important;
		}
	}
	.p-focus {
		border-color: #18aead !important;
		-webkit-box-shadow: 0 0 3px #9df2f2 !important;
		-moz-box-shadow: 0 0 3px #9df2f2 !important;
		box-shadow: 0 0 3px #9df2f2 !important;
	}
`;
export const DropdownWrapper = styled(Dropdown)`
	/* min-width:auto !important; */
	/* z-index:1056 !important; */

	/* z-index:1056 !important; */
`;
export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;
export const FooterDiv = styled.div`
	padding-top: 1rem;
	display: flex;
	justify-content: ${(props) => (props.IsDeleteAllowed ? "space-between" : "flex-end")};
	/* border-top:1px solid #E5E5E5 !important; */
	width: 100%;
`;
export const WrapperBox = styled.div`
	border-radius: 4px;
	background: ${(props) => (props.active ? ColorCheck(props.type, true) : "#ffffff")};
	color: ${(props) => (props.active ? ColorCheck(props.type) : "#92919D")};
	width: fit-content;
`;
const ColorCheck = (type, bg = false) => {
	if (bg === false) {
		switch (type) {
			case "emotional":
				return "#B25BFF";
			case "functional":
				return "#20A9E9";

			default:
				return;
		}
	}
	if (bg === true) {
		switch (type) {
			case "emotional":
				return "#EED8FF";
			case "functional":
				return "#D2EEFB";
			default:
				return;
		}
	}
};
export const ImgWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-basis: 6%;
	border: 1px solid transparent;
	${(props) => props.selected && `border:1px solid #18AEAD;`}
`;
