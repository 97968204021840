import React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"

const { FlexEndAlignCenter, GenericButton } = require("shared/ui-basics/index")

const TextSuggestions = ({ text, onSave, onClose, displayButton = true, setTyping, size = '14', speed = 5, lineHeight = '1.58', className = 'p-2', typingEffect = true, regularMode = false }) => {

    const [typingtext, setTypingtext] = useState("")
    const [textHeight, setTextHeight] = useState('auto')
    const textRef = React.createRef();

    useEffect(() => {
        let isMounted = true;

        let index = 0;
        if (text && typingEffect && isMounted) {
            setTypingtext("");
            if (setTyping) {
                setTyping(true);
            }
            const intervalId = setInterval(() => {
                if (index < text.length && isMounted) {
                    setTypingtext((prev) => prev + text[index]);
                    index++;
                } else {
                    if (setTyping && isMounted) {
                        setTyping(false);
                    }
                    clearInterval(intervalId); // Clear the interval to stop the typing effect
                }
            }, speed);
            return () => {
                isMounted = false;
                clearInterval(intervalId);

            }

        }


    }, [text, setTyping, typingEffect, speed])
    useEffect(() => {
        let isMounted = true;
        if (textRef.current) {
            // Measure the text height
            const height = textRef.current.offsetHeight +50;
            // Set the popover height based on the text height
            if (isMounted)
                setTextHeight(`${height}px`);
        }
        return () => {
            isMounted = false;
        }
    }
        , [text, textRef, setTextHeight])

    return <div className={className}>
        {regularMode ? <Text size={size} lineHeight={lineHeight} dangerouslySetInnerHTML={{ __html: typingtext }} /> : <>
            <div style={{ visibility: 'hidden', position: 'absolute' }} ref={textRef}>
                <Text>{text}</Text>
            </div>
            <div style={{ height: textHeight }}>
                <Text size={size} lineHeight={lineHeight} dangerouslySetInnerHTML={{ __html: typingtext }} />

            </div>
        </>}

        {displayButton && <FlexEndAlignCenter>
            <GenericButton variant='light-link' icon="CHECK" onClick={onSave} />
            <GenericButton variant='light-link' icon="X" onClick={onClose} />
        </FlexEndAlignCenter>}
    </div>
}
export default TextSuggestions

export const Text = styled.div`
background-color: transparent !important;
    color: #333333 !important;
    padding: 8px;
    z-index: 0;
    overflow: visible;
    border-radius: 0;
    font-size: ${props => props.size}px;
    min-height: inherit;
    line-height: ${props => props.lineHeight}rem;
`