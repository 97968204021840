import { copyCustomIcon, deleteCustomIcon, deleteCustomImage, updateCustomIcon } from "@redux/index";
import { getSessionId } from "@redux/memoizedSelectors";
import { StickerIconValidation } from "@utils/helpers";
import { LibraryItemCard } from "features/library/components/LibraryItemCard";
import { currentWorkspaceId } from "index";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ActionConfirmModal, EditLibrariesModal } from "shared/modals/index";
import { useModal } from "use-modal-hook";

export const StickerCard = ({ icon, category, setFocus }) => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const sessionId = useSelector(getSessionId);

	const [picture, setPicture] = useState(pictureValidation(icon, sessionId));
	const [showEditModal] = useModal(EditLibrariesModal);


	useEffect(() => {
		setPicture(pictureValidation(icon, sessionId));
	}, [icon.category]);

	const updateCustom = (index, name, category, weight, FileExtension, DocumentBody) => {
		if (icon.isCustom) {
			UpdateCustomIcon(index, name, category, FileExtension ? FileExtension : "", DocumentBody ? DocumentBody : "");
			if (FileExtension === "") DeleteCustomImage(index);
		}
	};

	const UpdateCustomIcon = async (iconIdStr, newIconName, category, imageExtension, imageBody64) => {
		const onSuccess = () => {
			setPicture(`api/icon/getCustomImage?iconIdStr=${icon.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}&${Math.random()}=true`);
		};
		updateCustomIcon({ iconIdStr, newIconName, category, imageExtension, imageBody64, onSuccess, displayToast: "UPDATE" });

	};

	const DeleteCustomImage = async (iconIdStr) => {
		const onSuccess = () => {
			setPicture(`api/icon/getCustomImage?iconIdStr=${icon.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}&${Math.random()}=true`);
		}
		deleteCustomImage({ iconIdStr, onSuccess });
	};

	const deleteCustom = async () => {
		hideModalDelete();
		deleteCustomIcon({ id: icon?.id });
	};

	const [showModalDelete, hideModalDelete] = useModal(ActionConfirmModal, {
		title: "ASPX_145",
		bodyContent: "DELETE_ICON",
		Action: deleteCustom,
		actionBtnText: "ASPX_13",
	});

	const openEdit = (e) => {
		const index = e.currentTarget.id;
		showEditModal({
			onDelete: showModalDelete,
			object: icon,
			context: "icon",
			entityName: "icon",
			title: "STICKER",
			displayCjmTable: true,
			pictureValidation,
			deleteCustom,
			updateCustom,
			category,
			index,
		});
	};
	const copyIcon = ({ id, name, workspaceIds }) => {
		copyCustomIcon({
			id: id,
			name: name,
			workspaceIds: workspaceIds
		});
	};

	return <LibraryItemCard ref={ref}
		item={icon}
		imageSrc={picture}
		handleEdit={openEdit}
		deleteFunction={deleteCustomIcon}
		setFocus={setFocus}
		actionType='DELETE_CUSTOM_ICON'
		copyFunction={copyIcon}
		label={'STICKER'} />;
};

const pictureValidation = (icon, sessionId) => {
	if (icon.isCustom) return `api/icon/getCustomImage?iconIdStr=${icon.id}&sid=${sessionId}&workspaceId=${currentWorkspaceId}&${Math.random()}=true`;
	return StickerIconValidation({ number: icon.number, type: icon.category });
};
