import { error, success } from '@redux-requests/core';
import { appendListsAndRemoveDuplicates } from '@utils/helpers';
import { StatusCode } from '@utils/optionsSets/OptionSets';

const initialState = {
    all_Opportunities: [],
    loading: false

}

const OpportunityReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPPORTUNITY_GET_ALL':
            return {
                ...state,
                loading: state.all_Opportunities.length === 0
            }
        case success('OPPORTUNITY_GET_ALL'):
            return {
                ...state,
                all_Opportunities: action.response.data,
                loading: false
            }
        case error("OPPORTUNITY_GET_ALL"):
            return {
                ...state,
                loading: false
            }
        case success('CREATE_OPPORTUNITY'):
            return {
                ...state,
                all_Opportunities: [...state.all_Opportunities, action.response.data]
            }
        case success('COPY_OPPORTUNITY'):
            return {
                ...state,
                all_Opportunities: [...state.all_Opportunities, action?.response?.data]
            }
        case success('UPDATE_OPPORTUNITY'): {
            const modifiedOpportunity = action?.response?.data

            return {
                ...state,
                all_Opportunities: state.all_Opportunities.map(opp => {
                    if (opp.id === modifiedOpportunity.id) {
                        opp = modifiedOpportunity
                    }
                    return opp
                })
            }
        }
        case success('UPDATE_MULTI_OPPORTUNITY'): {
            const modifiedOpportunities = action.meta.requestAction.request.data.modifiedOpportunities;
            const ids = action.meta.requestAction.request.data.modifiedOpportunities?.map(x => x.id);
            const modifiedAttribNames = action.meta.requestAction.request.data.modifiedAttribNames

            return {
                ...state,
                all_Opportunities: state.all_Opportunities.map(opp => {
                    if (ids.includes(opp.id)) {
                        return {
                            ...opp,
                            [modifiedAttribNames]: modifiedOpportunities?.find(x => x.id === opp?.id)?.[modifiedAttribNames]
                        }
                    }
                    return opp
                })
            }
        }
        case success('ARCHIVE_OPPORTUNITY'):
            return {
                ...state,
                all_Opportunities: state?.all_Opportunities?.map(opp => {
                    if (opp.id === action.meta.requestAction.request.data.id) {
                        return {...opp, statusCode: StatusCode.Archived}
                    }
                    return opp
                })
            };
        case success('ARCHIVE_MANY_OPPORTUNITIES'):
            return {
                ...state,
                all_Opportunities: state?.all_Opportunities?.map(opp => {
                    if (action.response.data.includes(opp.id)) {
                        return {...opp, statusCode: StatusCode.Archived}
                    }
                    return opp
                })
            };
        case success('DELETE_OPPORTUNITY'):
            return {
                ...state,
                all_Opportunities: state?.all_Opportunities?.map(opp => {
                    if (opp.id === action.meta.requestAction.request.data.id) {
                        return {...opp, statusCode: StatusCode.Trashed}
                    }
                    return opp
                })
            }
        case success('DELETE_MANY_OPPORTUNITIES'):
            return {
                ...state,
                all_Opportunities: state?.all_Opportunities?.map(opp => {
                    if (action.response.data.includes(opp.id)) {
                        return {...opp, statusCode: StatusCode.Trashed}
                    }
                    return opp
                })
            }
        case success('DELETE_OPPORTUNITY_PERMANENT'):
            return {
                ...state,
                all_Opportunities: state.all_Opportunities.filter(opp => opp.id !== action.meta.requestAction.request.data.id)
            }
        case success('DELETE_MANY_OPPORTUNITIES_PERMANENT'):
            return {
                ...state,
                all_Opportunities: state.all_Opportunities.filter(opp => !action.response.data.includes(opp.id))
            }
        case success('RESTORE_OPPORTUNITY'):
            return {
                ...state,
                all_Opportunities: state.all_Opportunities.map(opp => {
                    if (opp.id === action.meta.requestAction.request.data.id) {
                        return {...opp, statusCode: StatusCode.Active}
                    }
                    return opp
                })
            };
        case success('RESTORE_MANY_OPPORTUNITIES'):
            return {
                ...state,
                all_Opportunities: state.all_Opportunities.map(opp => {
                    if (action.response.data.includes(opp.id)) {
                        return {...opp, statusCode: StatusCode.Active}
                    }
                    return opp
                })
            };

        case success("LINK_TAGS"): {
            const { tagIds, recordType, recordIds } = action.meta.requestAction.request.data;
            if (recordType !== "cem_cxopportunity") return state;

            return {
                ...state,
                all_Opportunities: state.all_Opportunities?.map(opp => {
                    if (recordIds.includes(opp.id)) {
                        opp.tags = [...appendListsAndRemoveDuplicates(opp.tags, tagIds)]
                    }
                    return opp
                })
            }
        }
        case success("LINK_GROUPS"): {
            const { groupIds, recordType, recordIds } = action.meta.requestAction.request.data;
            if (recordType !== "cem_cxopportunity") return state;

            return {
                ...state,
                all_Opportunities: state.all_Opportunities?.map(opp => {
                    if (recordIds.includes(opp.id)) {
                        opp.groups = [...appendListsAndRemoveDuplicates(opp.groups, groupIds)]
                    }
                    return opp
                })
            }
        }
        default:
            return state;
    }
}
export default OpportunityReducer;