import { IconDispatcher } from "shared/Icons";
import CustomTooltip from "shared/components/CustomTooltip";
import styled, { css } from "styled-components";

const ColorBubblePicker = ({ colors, selected, onSelect }) => {
	return (
		<Container>
			{colors.map(({ color, name }, i) => (
				<CustomTooltip key={i} text={name}>
					<ColorContainer key={i} onClick={() => onSelect({ color })} isselected={selected === color}>
						<ColorBubble color={color}>
							<CheckWrapper isselected={selected === color}>{IconDispatcher("CHECK")}</CheckWrapper>
						</ColorBubble>
					</ColorContainer>
				</CustomTooltip>
			))}
		</Container>
	);
};

ColorBubblePicker.displayName = "ColorBubblePicker";
export default ColorBubblePicker;

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ColorContainer = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	:hover {
		border: 2px solid #333;
		cursor: pointer;
	}

	${(props) =>
		props.isselected &&
		css`
			border: 2px solid #333;
		`}
`;

const ColorBubble = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.color};
	color: #fff;
	font-weight: bold;
`;

const CheckWrapper = styled.div`
	visibility: hidden;

	${(props) =>
		props.isselected &&
		css`
			visibility: visible;
		`}
`;
