import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components/macro';
import { GenericButton } from 'shared/ui-basics/buttons';
import PropTypes from 'prop-types';


 const ActionConfirmModal = memo(({ isOpen, onClose, bodyContent, Action, actionBtnText = 'ASPX_13', secondBtnText = 'ASPX_86', beforeClose}) => {
    const { t } = useTranslation();
    const confirm = () => {
        onClose()
        Action(true)
    }

    const handleClose = () => {
        if (beforeClose) beforeClose()
        onClose()
    }

    return (
        <>
            <ModalContainer fade={false} isOpen={isOpen} toggle={onClose} size="sm" centered={true}>
                <DeleteWarningText>
                    {t(bodyContent)}
                </DeleteWarningText>
                <ModalFooter>
                    <GenericButton variant='primary-outline' className='text-capitalized' onClick={handleClose}>{t(secondBtnText)}</GenericButton>
                    <GenericButton variant='primary' className='text-capitalized' onClick={confirm}>{t(actionBtnText)}</GenericButton>
                </ModalFooter>
            </ModalContainer>
        </>
    )
})

ActionConfirmModal.propTypes = {
    actionBtnText: PropTypes.string.isRequired,
    Action: PropTypes.func.isRequired
};
ActionConfirmModal.displayName = 'ActionConfirmModal';
export { ActionConfirmModal };
const ModalContainer = styled(Modal)`
    max-width: 400px;
`
const DeleteWarningText = styled(ModalBody)`
    color: #76838f;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
`