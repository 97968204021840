import { constructUrl, encode } from "@utils/helpers";
import { genericService } from "shared/GenericService";

const BASE_URL = "api/project/";

const InitActionCreator = ({ baseUrl, method }) => {
	return ({ type, endPoint, data, responseType, params, meta, ...props }) => ({
		type,
		request: {
			url: baseUrl + endPoint,
			method,
			data,
			responseType,
		},
		meta,
		params,
		...props,
	});
};
const createPostAction = InitActionCreator({ baseUrl: BASE_URL, method: "POST" }); // api actions type post
const createGetAction = InitActionCreator({ baseUrl: BASE_URL, method: "GET" }); // api actions type get

export const resetProject = () => ({ type: "RESET_PROJECT" });

export const getAllProjects = (props) =>
	createGetAction({
		type: "GET_ALL_PROJECTS",
		endPoint: constructUrl("getAll", {
			redirectReducer: props?.redirectReducer,
		}),
	});

export const getProject = (props) =>
	createGetAction({
		type: "GET_PROJECT",
		endPoint: constructUrl("get", {
			projectId: props?.projectId,
		}),
	});

export const getProjectUsers = (props) =>
	createGetAction({
		type: "GET_PROJECT_USERS",
		endPoint: constructUrl("getAuthorizedUsers", {
			projectId: props?.projectId,
			forceViewer: props?.forceViewer,
		}),
		params: { isPrint: props?.isPrint, userId: props?.userId, from: props?.from },
	});

export const createProject = ({ item, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "CREATE_PROJECT",
			endPoint: "create",
			data: item,
		}),
		displayToast: "CREATE_NO_SUCCESS_TOAST",
	});

export const copyProject = ({ id, name, workspaceIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "COPY_PROJECT",
			endPoint: "copy",
			data: {
				srcProjectId: id,
				newName: encode(name),
				workspaceIds,
			},
		}),
		displayToast: "COPY",
	});

export const updateProject = ({ modifiedProject, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_PROJECT",
			endPoint: "update",
			data: {
				modifiedProject,
				modifiedAttribNames,
			},
		}),
	});

export const updateMultiProject = ({ modifiedProjects, modifiedAttribNames, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UPDATE_MULTI_PROJECT",
			endPoint: "updateMulti",
			data: {
				modifiedProjects,
				modifiedAttribNames,
			},
		}),
	});

export const deleteProjects = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PROJECTS",
			endPoint: "delete",
			data: {
				ids,
			},
		}),
		displayToast: "DELETE",
	});

export const deleteProjectsPermanent = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "DELETE_PROJECTS_PERMANENT",
			endPoint: "deletePermanently",
			data: {
				ids,
			},
		}),
		displayToast: "DELETE",
	});

export const archiveProjects = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "ARCHIVE_PROJECTS",
			endPoint: "archive",
			data: {
				ids,
			},
		}),
		displayToast: "ARCHIVE",
	});

export const restoreProjects = ({ ids, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "RESTORE_PROJECTS",
			endPoint: "restore",
			data: {
				ids,
			},
		}),
		displayToast: "RESTORE",
	});

export const linkRecords = ({ recToLinkEntityName, recToLinkIds, projectId, linkRelated, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "LINK_UNLINK_RECORDS_TO_PROJECT",
			endPoint: "linkRecords",
			data: {
				recToLinkEntityName,
				recToLinkIds,
				projectId,
				linkRelated,
			},
		}),
		displayToast: "LINK_RECORD",
	});

export const unlinkRecords = ({ recToUnlinkEntityName, recToUnlinkIds, projectId, unlinkRelated, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "LINK_UNLINK_RECORDS_TO_PROJECT",
			endPoint: "unlinkRecords",
			data: {
				recToUnlinkEntityName,
				recToUnlinkIds,
				projectId,
				unlinkRelated,
			},
		}),
		displayToast: "UNLINK",
	});

export const upsertAttributeItem = ({ attribute, attributeItem, cxGoal, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "PROJECT_UPSERT_ATTRIBUTE_ITEM",
			endPoint: "upsertAttributeItem",
			data: {
				attribute,
				attributeItem: {
					...attributeItem,
					description: encode(attributeItem?.description),
					name: encode(attributeItem?.name),
				},
				cxGoal,
			},
		}),
	});

export const deleteAttributeItem = ({ projectId, attributeId, attributeItemId, cXGoalId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "PROJECT_DELETE_ATTRIBUTE_ITEM",
			endPoint: "deleteAttributeItem",
			data: {
				projectId,
				attributeId,
				attributeItemId,
				cXGoalId,
			},
		}),
		displayToast: "DELETE",
	});

export const shareProject = ({ recId, userId, roleDetails, teamId, userIdName, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_PROJECT",
			endPoint: "share",
			data: {
				recId,
				userId,
				roleDetails,
				teamId,
			},
			params: {
				fullName: userIdName,
			},
		}),
	});

export const shareManyProjects = ({ recIds, usersIds, roleDetails, teamIds, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SHARE_MANY_PROJECTS",
			endPoint: "shareMany",
			data: {
				recIds: recIds ? recIds : [],
				userIds: usersIds ? usersIds?.split("|") : [],
				roleDetails,
				teamIds: teamIds ? teamIds.split("|") : [],
			},
		}),
		displayToast: "UPDATE",
	});

export const unShareProject = ({ recId, userId, teamId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "UNSHARE_PROJECT",
			endPoint: "unShare",
			data: {
				recId,
				userId,
				teamId,
			},
		}),
		displayToast: "UPDATE",
	});

export const getProjectExcelExportList = (ids, columns) =>
	createPostAction({
		type: "GET_PROJECT_EXCEL",
		endPoint: "getExcelExport",
		data: {
			ids,
			columns: columns?.join("|").replace("projectName", "Project Name"),
		},
		responseType: "blob",
	});

export const getProjectPdfExport = ({ id, width, height, renderDelay }) =>
	createGetAction({
		type: "GET_PROJECT_PDF",
		endPoint: constructUrl("getPdfExport", {
			id,
			width,
			height: height + 300,
			renderDelay,
		}),
		responseType: "blob",
	});

export const resetProjectUsers = () => ({ type: "RESET_PROJECT_USERS" });

export const synchronizeProject = ({ projectId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "SYNCHRONIZE_PROJECT",
			endPoint: "synchronize",
			data: {
				projectId,
			},
		}),
	});

export const stopSynchronizationProject = ({ projectId, ...props }) =>
	genericService({
		...props,
		action: createPostAction({
			type: "STOP_SYNCHRONIZATION_PROJECT",
			endPoint: "stopSynchronization",
			data: {
				projectId,
			},
		}),
	});
