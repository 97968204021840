import { IconDispatcher } from "shared/Icons";
import styled, { css } from "styled-components/macro";

export const RecordsSelector = ({ allRecords, selectedRecords, renderCustomComponent, onSelect, onRemove }) => {
	const SelectedRecord = ({ record }) => {
		return (
			<SelectedRecordContainer onClick={() => onRemove({ record })}>
				{renderCustomComponent({ record })}
				<RemoveButton className="me-1">{IconDispatcher("x", "", { size: "12px" })}</RemoveButton>
			</SelectedRecordContainer>
		);
	};

	const isRecordSelected = (rec) => selectedRecords.map((r) => r.id).includes(rec.id);

	return (
		<Container>
			<BoxContainer>
				{selectedRecords.map((record, i) => (
					<SelectedRecord key={i} record={record} />
				))}
			</BoxContainer>
			<RecordsContainer>
				{allRecords.map((record, i) => (
					<Record key={i} onClick={() => onSelect({ record })} disabled={isRecordSelected(record)}>
						{renderCustomComponent({ record })}
					</Record>
				))}
			</RecordsContainer>
		</Container>
	);
};

RecordsSelector.displayName = "RecordsSelector";

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const BoxContainer = styled.div`
	min-height: 36px;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	display: flex;
	padding: 12px 9px;
	flex-wrap: wrap;
	gap: 8px;
`;

const SelectedRecordContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 4px;
	background: #efefef;
	:hover {
		cursor: pointer;
	}
`;

const RemoveButton = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #605f60;
`;

const RecordsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Record = styled.div`

	width: 100%;
    border-radius: 4px;
	:hover {
		cursor: pointer;
		background: #efefef;
	}
	${(props) =>
		props.disabled &&
		css`
			opacity: 0.7;
			:hover {
				cursor: not-allowed;
				background: none;
			}
		`}
`;
