import { importAll } from "@utils/helpers";
import { ConnectorSource } from "@utils/optionsSets/OptionSets";
import { MondayFormConnector } from "features/integrations/modals/MondayFormConnector";
import { IntegrationModal } from "features/integrations/modals/QualtricsIntegration/IntegrationModal";
import { SupportForm } from "features/navigation/Header/SupportForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import { FlexBetweenAlignCenter, Label } from "shared/ui-basics/index";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import { connectorInit } from "../reducer/ConnectorAction";
import DataLoader from "shared/components/data-loader";
import { findWorkspaceById } from "@redux/memoizedSelectors";
import { currentWorkspaceId } from "index";

const { GenericButton } = require("shared/ui-basics/buttons");

export const Integration = () => {
	const { t } = useTranslation();
	const connectors = useSelector((state) => state.settings.connector);
	const currentWorkspace = useSelector(findWorkspaceById(currentWorkspaceId));

	const [integrationModal] = useModal(IntegrationModal);
	const [supportFormModal] = useModal(SupportForm);
	const [mondayFormConnector] = useModal(MondayFormConnector);

	const displayBtnName = (connectorSource) => (connectors?.filter((connector) => connector.source === connectorSource)?.length > 0 ? "CONNECTED" : "BTN-CONNECT");
	const displayVariant = (connectorSource) => (connectors?.filter((connector) => connector.source === connectorSource)?.length > 0 ? "primary" : "primary-outline");

	const listIntegrations = [
		{
			variant: displayVariant(ConnectorSource.Qualtrics),
			icon: "qualtrics.png",
			buttonName: displayBtnName(ConnectorSource.Qualtrics),
			count: connectors?.filter((connector) => connector.source === ConnectorSource.Qualtrics)?.length,
			onClick: () => {
				integrationModal({ source: ConnectorSource.Qualtrics });
			},
			name: "QUALTRICS",
			description: "QUALTRICS_DESCRIPTION",
		},
		{
			variant: displayVariant(ConnectorSource.SurveyMonkey),
			icon: "survey-monkey.png",
			buttonName: displayBtnName(ConnectorSource.SurveyMonkey),
			count: connectors?.filter((connector) => connector.source === ConnectorSource.SurveyMonkey)?.length,
			onClick: () => {
				integrationModal({ source: ConnectorSource.SurveyMonkey });
			},
			name: "SURVEY_MONKEY",
			description: "SURVEY_MONKEY_DESCRIPTION",
		},
		{
			variant: displayVariant(ConnectorSource.Sandsiv),
			icon: "sandsiv.png",
			buttonName: displayBtnName(ConnectorSource.Sandsiv),
			count: connectors?.filter((connector) => connector.source === ConnectorSource.Sandsiv)?.length,
			onClick: () => {
				integrationModal({ source: ConnectorSource.Sandsiv });
			},
			name: "SANDSIV",
			description: "SANDSIV_DESCRIPTION",
		},
		{
			variant: displayVariant(ConnectorSource.Monday),
			icon: "monday.png",
			buttonName: displayBtnName(ConnectorSource.Monday),
			onClick: () => {
				mondayFormConnector({ connector: connectors?.find((connector) => connector.source === ConnectorSource.Monday) });
			},
			name: "MONDAY",
			description: "MONDAY_DESCRIPTION",
		},
		{
			variant: "primary-outline",
			icon: "mailchimp.png",
			buttonName: "CONTACT_US",
			onClick: () => {
				supportFormModal({ subject: `${t("MAILCHIMP")} ${t("INTEGRATION")}` });
			},
			name: "MAILCHIMP",
			description: "MAILCHIMP_DESCRIPTION",
		},
		{ variant: "light", disable: true, icon: "miro.png", buttonName: "SOON", onClick: () => {}, name: "MIRO", description: "MIRO_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "mural.png", buttonName: "SOON", onClick: () => {}, name: "MURAL", description: "MURAL_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "asana.png", buttonName: "SOON", onClick: () => {}, name: "ASANA", description: "ASANA_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "microsoft-dynamics.png", buttonName: "SOON", onClick: () => {}, name: "MICROSOFT_DYNAMICS", description: "MICROSOFT_DYNAMICS_DESCRIPTION" },
		{
			variant: "light",
			disable: true,
			icon: "microsoft-customer-voice.png",
			buttonName: "SOON",
			onClick: () => {},
			name: "MICROSOFT_CUSTOMER_VOICE",
			description: "MICROSOFT_CUSTOMER_VOICE_DESCRIPTION",
		},
		{ variant: "light", disable: true, icon: "intercom.png", buttonName: "SOON", onClick: () => {}, name: "INTERCOM", description: "INTERCOM_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "jira.png", buttonName: "SOON", onClick: () => {}, name: "JIRA", description: "JIRA_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "marketo.png", buttonName: "SOON", onClick: () => {}, name: "MARKETO", description: "MARKETO_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "medalia.png", buttonName: "SOON", onClick: () => {}, name: "MEDALLIA", description: "MEDALLIA_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "microsoft-teams.png", buttonName: "SOON", onClick: () => {}, name: "MICROSOFT_TEAMS", description: "MICROSOFT_TEAMS_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "microsoft-power-apps.png", buttonName: "SOON", onClick: () => {}, name: "MICROSOFT_POWER_APPS", description: "MICROSOFT_POWER_APPS_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "microsoft-power-bi.png", buttonName: "SOON", onClick: () => {}, name: "MICROSOFT_POWER_BI", description: "MICROSOFT_POWER_BI_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "salesforce.png", buttonName: "SOON", onClick: () => {}, name: "SALESFORCE", description: "SALESFORCE_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "sap.png", buttonName: "SOON", onClick: () => {}, name: "SAP", description: "SAP_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "service-now.png", buttonName: "SOON", onClick: () => {}, name: "SERVICE_NOW", description: "SERVICE_NOW_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "microsoft-sharepoint.png", buttonName: "SOON", onClick: () => {}, name: "MICROSOFT_SHARE_POINT", description: "MICROSOFT_SHARE_POINT_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "slack.png", buttonName: "SOON", onClick: () => {}, name: "SLACK", description: "SLACK_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "smartsheet.png", buttonName: "SOON", onClick: () => {}, name: "SMART_SHEET", description: "SMART_SHEET_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "trello.png", buttonName: "SOON", onClick: () => {}, name: "TRELLO", description: "TRELLO_DESCRIPTION" },
		{ variant: "light", disable: true, icon: "zendesk.png", buttonName: "SOON", onClick: () => {}, name: "ZENDESK", description: "ZENDESK_DESCRIPTION" },
	];

	const integrations = importAll(require.context("@Assets/images/integration", false, /\.(png|jpe?g|svg)$/));

	return (
		<DataLoader reduxActions={[connectorInit]}>
			<PageTemplate classNames="container pt-5" pageTitle={`${t("DISCOVER")} ${t("HOW")} ${currentWorkspace?.name} ${t("CAN_BE_MORE_CONNECTED_AND_PRODUCTIVE")}`}>
				<div className="d-flex flex-wrap gap-5 justify-content-between pt-4">
					{listIntegrations?.map((integration, index) => (
						<WrapperDiv className="pt-4" key={index}>
							<div>
								<div className="d-flex align-items-center pb-2">
									<ImgWrapper className="me-3" src={integrations[integration?.icon]?.default} />
									<Label size={20}>{t(integration?.name)}</Label>
								</div>
								<div>{t(integration?.description)}</div>
							</div>
							<FlexBetweenAlignCenter className="pt-4">
								<GenericButton variant={integration?.variant} disabled={integration?.disable} onClick={integration?.onClick}>
									{t(integration?.buttonName)}
								</GenericButton>
								{integration?.count > 0 && <Label>{integration?.count + " " + t("CONNECTION") + (integration?.count > 1 ? "s" : "")}</Label>}
							</FlexBetweenAlignCenter>
						</WrapperDiv>
					))}
				</div>
			</PageTemplate>
		</DataLoader>
	);
};
const WrapperDiv = styled.div`
	width: 374px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ImgWrapper = styled.img`
	height: 40px;
`;
