import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useModal } from "use-modal-hook";
import { ActionConfirmModal, EditLibrariesModal } from "shared/modals/index";
import { IconDispatcher } from "shared/ui-basics/index";
import CustomTooltip from "../../components/CustomTooltip";

import { CheckBoxWrapper } from "shared/modals/library-links/Style";
import { BackgroundLabel } from "shared/ui-basics/ui-components/BackgroundLabel";
import styled from "styled-components";

// mode = "display" | "selectable" | "unlinkable" | "editable";

const EditableItem = (props) => {
	const { itemSelectorPath, objectId, mode = "display", disabled, classNames } = props;
	let item = useSelector((state) => _.get(state, itemSelectorPath))?.find((item) => item?.id === objectId);

	const [isDeleting, setIsDeleting] = useState(false);
	const [showEditModal, hideEditModal] = useModal(EditLibrariesModal);
	const [confirmModal, hideConfirmModal] = useModal(ActionConfirmModal);

	if (!item?.name) return <></>;

	const unLinkComponent = () => {
		const { unlink } = props;
		return <IconComponent title="REMOVE" variant="secondary-outline" action={(e) =>{
			e.stopPropagation();
			unlink(objectId)
		}} icon="X" color={item?.colorCode} />;
	};
	const editableComponent = () => {
		const { deleteAction, context, title } = props;
		const openEdit = () => showEditModal({ onDelete: handleDelete, object: item, context, title });
		const deleteOnModal = () => {
			hideEditModal();
			deleteAction(item.id, setIsDeleting);
		};
		const handleDelete = () => {
			confirmModal({
				title: "ASPX_145",
				bodyContent: "DELETE_ITEM_FROM_LIB",
				Action: deleteOnModal,
				actionBtnText: "ASPX_13",
			});
		};

		return (
			<div className="ms-2 d-flex align-items-center" id="editable">
				<IconComponent title="EDIT" size="lg" variant={"light-link"} action={openEdit} icon="EDIT" />
				{!isDeleting && <IconComponent title="ASPX_13" size="lg" variant={"light-link"} action={handleDelete} icon="TRASH" />}
			</div>
		);
	};
	const selectableComponent = () => {
		const { selectedFromList } = props;
		return <CheckBoxWrapper type="checkbox" className="my-2 me-1" checked={selectedFromList.includes(objectId)} onChange={() => { }} />;
	};
	return (<div className="d-flex align-items-center">
		<BackgroundLabel id={objectId} className={"d-flex " + classNames} size={props.size} color={item?.colorCode} disabled={disabled}>
			<DivWrapper id={objectId}>
				{mode === "selectable" && selectableComponent()}
				<div className="name">{item?.name}</div>
			</DivWrapper>
			{mode === "editable" && editableComponent()}
		</BackgroundLabel>
		{mode === "unlinkable" ? unLinkComponent() : <div className=""></div>}
	</div>
	);
};

function IconComponent({ title, action, icon, variant, size = "xs", color }) {
	const { t } = useTranslation();

	return (
		<CustomTooltip text={t(title)}>
			<X size={size} variant={variant} onClick={action} color={color} className="d-flex">
				{IconDispatcher(icon)}
			</X>
		</CustomTooltip>
	);
}

export default EditableItem;
 const DivWrapper = styled.div`
	display: inline-flex;
	height: 24px;
	padding: 0px 4px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	align-self: center;
`;

 const X = styled.div`
	margin-left: 0.5rem;
	padding: 0px 0px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	width: 9px;
	height: 9px;
	${(props) => props.size == "lg" && `width:12px; height:12px;`};
	svg {
		path {
			fill: #605f60;
		}
		width: inherit !important;
		height: inherit;
	}
`;
