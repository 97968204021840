import InsightType from "shared/ui-basics/ui-components/InsightType";
import { Counter } from "features/analytics/Components/Counter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import styled, { css } from "styled-components/macro";

function Counters() {
	const { t } = useTranslation();
	const data = useSelector((state) => state.analytics?.currentData?.journeys);
	const insightTypes = useSelector((state) => state.analytics?.currentData?.insightTypes);

	const formatData = (data, insightTypes) => {
		const counters = {
			touchpoints: { value: 0, icon: "BULLSEYES-POINTER", nameComponent: () => <>{t("TOUCHPOINTS")}</> },
			opportunities: { value: 0, icon: "OPPORTUNITY", nameComponent: () => <>{t("OPPORTUNITIES")}</> },
			solutions: { value: 0, icon: "SOLUTION", nameComponent: () => <>{t("SOLUTIONS")}</> },
			actions: { value: 0, icon: "ACTION", nameComponent: () => <>{t("JS_80")}</> },
			gainPoints: { value: 0, nameComponent: () => <>{InsightType({ typeId: insightTypes?.gainPointTypeId })}</> },
			painPoints: { value: 0, nameComponent: () => <>{InsightType({ typeId: insightTypes?.painPointTypeId })}</> },
		};

		const memory = {};

		data?.forEach((map) => {
			map?.touchpointIds?.forEach((id) => {
				if (!(id in memory)) memory[id] = id;
			});
		});

		counters.touchpoints.value += Object.keys(memory)?.length;
		data?.forEach((map) => {
			counters.opportunities.value += map?.opportunityIds?.length;
			counters.solutions.value += map?.solutionIds?.length;
			counters.actions.value += map?.actionIds?.length;
			counters.gainPoints.value += map?.insightsGainPointIds?.length;
			counters.painPoints.value += map?.insightsPainPointIds?.length;
		});
		return counters;
	};

	const counters = formatData(data, insightTypes);

	return (
		<CounterContainer className="p-4">
			<Row>
				<Container xs={3} delimiter="true">
					<Counter data={counters.touchpoints} />
				</Container>
				<Container xs={6} delimiter="true">
					<Counter data={counters.opportunities} />
					<Counter data={counters.solutions} />
					<Counter data={counters.actions} />
				</Container>
				<Container xs={3}>
					<Counter data={counters.gainPoints} />
					<Counter data={counters.painPoints} />
				</Container>
			</Row>
		</CounterContainer>
	);
}

Counters.displayName = "Counters";
export default Counters;

const CounterContainer = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 4px;
	background: #fff;
	border: 1px solid #c4c4c4;
`;

const Container = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: space-around;
	${(props) =>
		props.delimiter === "true" &&
		css`
			border-right: 1px solid #efefef;
		`}
`;
