import {
	createCxAction,
	createInsight,
	createOpportunity,
	createSolution,
	getAllCxAction,
	getAllInsights,
	getAllOpportunity,
	getAllSolutions,
	linkInsightRecords,
	unLinkInsightRecords
} from "@redux/index";
import { getInsightById, makeGetCjmDataInteraction } from "@redux/memoizedSelectors";
import { EMPTY_GUID, createDefaultAction, createDefaultInsight, createDefaultOpp, createDefaultSol, encode } from "@utils/helpers";
import { InformationType } from "@utils/optionsSets/OptionSets";
import { CxActionPage, OpportunityPage, SolutionPage } from "features";
import InsightSearchPickList from "features/insights/components/pick-list/insight-search-pick-list";
import { CxComponents } from "features/journey-map/modals/CxComponents";
import { linkInteraction, unlinkInteraction } from "features/journey-map/reducer/CJMActions";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IconDispatcher } from "shared/Icons";
import { ActionItemCard } from "shared/components/cards/cx-cards/ActionCard";
import { InsightCard } from "shared/components/cards/cx-cards/InisightCard";
import { KpiRelationCard } from "shared/components/cards/cx-cards/KpisRelationCard";
import { OpportunityItemCard } from "shared/components/cards/cx-cards/OpportunityCard";
import { SolutionItemCard } from "shared/components/cards/cx-cards/SolutionCard";
import SearchPickList from "shared/cx-components/SearchPickList";
import { useAsyncs } from "shared/hooks/index";
import { GenericButton } from "shared/ui-basics/buttons";
import styled from "styled-components";
import { useModal } from "use-modal-hook";
import RelatedCjmsManager from "./RelatedCjmsManager";
export const TabTitleContext = React.createContext();

function RelationManager({ mode, ...props }) {
	const history = useHistory();

	const [key, setKey] = useState(props?.defaultKey);
	const { t } = useTranslation();
	const [count, setCount] = useState(null);
	const tabsData = GetTabsData(props.stageId, props.item?.id, props.variant, props?.item);
	const [showModal] = useModal(CxComponents);

	const redirect = useCallback((id, url) => {
		if (id !== "") {
			if (["/edit-opportunity", "/edit-solution", "/edit-cxactions"].includes(url)) {
				switch (url) {
					case "/edit-opportunity":
						showModal({ Component: OpportunityPage, id });
						break;
					case "/edit-solution":
						showModal({ Component: SolutionPage, id });

						break;
					case "/edit-cxactions":
						showModal({ Component: CxActionPage, id });
						break;
					default:
						break;
				}
				props.onClose && props.onClose();
			}
		} else {
			history.push(url);

		}
	}, []);

	return (
		<TabTitleContext.Provider value={{ count, setCount }}>
			<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 fs2">
				{tabsData.map((tab) => {
					if (tab.CustomManager) {
						return (
							<Tab key={tab.tabLabel} eventKey={tab.tabLabel} title={`${t(tab.tabLabel)} (${count?.[tab?.attribute]})`}>
								<tab.CustomManager key={tab.tabLabel} tabsData={tab} item={props?.item} openRoute={redirect} dataLoading={props?.dataLoading} />
							</Tab>
						);
					}
					return (
						<Tab key={tab.tabLabel} eventKey={tab.tabLabel} title={`${t(tab.tabLabel)} (${count?.[tab?.attribute]})`}>
							<RelatedItemsManager key={tab.tabLabel} tabsData={tab} mode={mode} redirect={redirect} dataLoading={props?.dataLoading} />
						</Tab>
					);
				})}
			</Tabs>
		</TabTitleContext.Provider>
	);
}

const RelatedItemsManager = ({ tabsData, redirect, mode, dataLoading = true }) => {
	const { t } = useTranslation();

	const refPopup = useRef(null);
	const { count, setCount } = useContext(TabTitleContext);

	const {
		attribute,
		CustomCard,
		addButtonLabel,
		getAllPath,
		reducerSelector,
		link,
		unlink,
		getAllApi = null,
		titleAttribute = "subject",
		entityType,
		createDefaultObject,
		createFunction,
		specificPathArray,
		noDataIcon,
		noDataText,
		CustomPickList,
	} = tabsData;

	useAsyncs({asyncFns:[{asyncFn: dataLoading ? getAllApi : null }]});

	const showSampleData = useSelector(({ settings }) => settings.organization.showSampleData);
	const currentItem = useSelector(reducerSelector);
	const userInfo = useSelector(({ auth }) => auth?.userInfo);
	const baseItems = useSelector((s) => _.get(s, specificPathArray ?? getAllPath, []) ?? []);
	const allItems = attribute === "linkedInsights" ? baseItems :  baseItems?.getActiveRecords(!showSampleData) ?? [];

	const isShowCreateObject = (createDefaultObject && createFunction) || allItems?.length > 0;

	const ids = currentItem?.[attribute] ?? [];
	const visibleItems = useMemo(() => allItems?.filter((item) => ids?.includes(item?.id)), [allItems, ids]);

	useEffect(() => {
		if (count?.[attribute] !== visibleItems?.length) {
			setCount((prev) => ({ ...(prev || {}), [attribute]: visibleItems?.length }));
		}
	}, [visibleItems]);

	const handleLinkRecord = useCallback(
		(res) => {
			link(res.recordId);
		},
		[link]
	);

	const handleUnlinkRecord = useCallback(
		(id) => {
			unlink(id);
		},
		[unlink]
	);

	const handleCreate = useCallback(
		({ globalFilterValue }) => {
			if (createDefaultObject && createFunction) {
				let item = createDefaultObject(userInfo);
				item[titleAttribute] = encode(globalFilterValue);

				createFunction({ item, displayToast: "CREATE", onSuccess: (response) => handleLinkRecord({ recordId: response.id }) });
			}
		},
		[createFunction, createDefaultObject, handleLinkRecord, titleAttribute, userInfo]
	);

	return (
		<div>
			<div className={visibleItems?.length === 0 ? "d-flex justify-content-center" : ""}>
				{!mode.IsViewer() && isShowCreateObject && (
					<GenericButton
						variant="light-link"
						icon="SQUARE_PLUS"
						iconClassName="me-1"
						onClick={(e) => refPopup.current.toggle(e)}
						aria-haspopup
						aria-controls="overlay_panel"
						className={"my-2 "}>
						{t(addButtonLabel)}
					</GenericButton>
				)}
				{!CustomPickList ? (
					<SearchPickList
						ref={refPopup}
						currentItem={currentItem}
						allItems={allItems}
						linkedAttribute={attribute}
						handleCreate={createDefaultObject && createFunction ? handleCreate : null}
						handleLinkRecord={handleLinkRecord}
						titleAttribute={titleAttribute}
						entityType={entityType}
					/>
				) : (
					CustomPickList(refPopup)
				)}
			</div>
			{visibleItems?.length > 0 ? visibleItems?.map((item, i, x) => {
				return <CustomCard key={item.id} index={i} entity={item} unlink={(id) => handleUnlinkRecord(item?.id || id)} openRoute={redirect} length={x?.length} disable={mode?.IsViewer()} />;
			}) : <NoData>
				<NoDataIcon>{IconDispatcher(noDataIcon, "", { size: "3rem", style: "thin" })}</NoDataIcon>
				<NoDataText>{noDataText}</NoDataText>
			</NoData>
			}
		</div>
	);


};

const GetTabsData = (stageId, itemId, variant) => {
	const { cjmIdStr } = useLocation().search.getAllMapsParams();
	const swimLaneInsights = useSelector((state) => state.maps.current_map.cjmSwimLanes?.find((swimLane) => swimLane.type === InformationType.Insights));
	const interaction = useSelector(makeGetCjmDataInteraction(stageId, itemId));
	const isStandard = useSelector((state) => state.auth?.userInfo?.UserSubscriptionPlanName === "Standard");

	const isInsight = variant === "insight";
	let data = [
		{
			tabLabel: "ASPX_33",
			addButtonLabel: "ADD_INSIGHT",
			CustomCard: InsightCard,
			attribute: "linkedInsights",
			getAllPath: "insights.all_insights",
			getAllApi: getAllInsights,
			entityType: "cem_insight",
			titleAttribute: "name",
			createDefaultObject: (currentUser) => createDefaultInsight({ currentUser }),
			createFunction: createInsight,
			noDataIcon: "GEM",
			noDataText: t("INTERACTION_INSIGHT_EMPTY"),
			CustomPickList: (refPopup) => <InsightSearchPickList ref={refPopup} interaction={interaction} swimlaneKey={swimLaneInsights?.key} stageId={stageId} selected={interaction?.linkedInsights} />,
		},
		{
			tabLabel: "OPPORTUNITIES",
			addButtonLabel: "ADD_OPPORTUNITY",
			CustomCard: OpportunityItemCard,
			attribute: "linkedOpps",
			getAllPath: "opportunity.all_Opportunities",
			specificPathArray: !isInsight ? ["maps", "current_map", "cjmOpportunities", stageId] : null,
			getAllApi: getAllOpportunity,
			entityType: "cem_cxopportunity",
			createDefaultObject: (userInfo) => createDefaultOpp(userInfo, stageId, cjmIdStr ?? EMPTY_GUID),
			createFunction: createOpportunity,
			noDataIcon: "OPPORTUNITY",
			noDataText: t("INTERACTION_OPPORTUNITY_EMPTY"),
		},
		{
			tabLabel: "SOLUTIONS",
			addButtonLabel: "ADD_SOLUTION",
			CustomCard: SolutionItemCard,
			attribute: "linkedSols",
			getAllPath: "solutions.all_solutions",
			getAllApi: getAllSolutions,
			specificPathArray: !isInsight ? ["maps", "current_map", "cjmSolutions", stageId] : null,
			entityType: "cem_cxsolution",
			createDefaultObject: (userInfo) => createDefaultSol(userInfo, stageId, cjmIdStr ?? EMPTY_GUID),
			createFunction: createSolution,
			noDataIcon: "SOLUTION",
			noDataText: t("INTERACTION_SOLUTION_EMPTY"),
		},
		{
			tabLabel: "JS_80",
			addButtonLabel: "ADD_ACTION",
			CustomCard: ActionItemCard,
			attribute: "linkedActions",
			getAllPath: "actions.all_actions",
			specificPathArray: !isInsight ? ["maps", "current_map", "cjmCxActions", stageId] : null,
			getAllApi: getAllCxAction,
			entityType: "cem_cxactiontask",
			createDefaultObject: (userInfo) => createDefaultAction(userInfo, stageId, cjmIdStr ?? EMPTY_GUID),
			createFunction: createCxAction,
			noDataIcon: "MENU-ACTION",
			noDataText: t("INTERACTION_ACTION_EMPTY"),
		},
		{
			tabLabel: "KPIS",
			addButtonLabel: "ADD_KPI",
			CustomCard: KpiRelationCard,
			attribute: "linkedKpis",
			specificPathArray: !isInsight ? ["maps", "current_map", "cjmCxGoals", stageId] : null,
			entityType: "cem_goal",
			titleAttribute: "measureName",
			isActiveRecord: false,
		},
		{
			tabLabel: "JS_71",
			CustomManager: RelatedCjmsManager,
			attribute: "linkedCjms",
		},
	];
	if (isInsight) {
		const subscriptionFilter = (relation) => (isStandard ? relation.tabLabel === "JS_71" : true);
		const insightRelations = (relation) => !["KPIS", "ASPX_33"].includes(relation.tabLabel);

		data = data.filter(insightRelations)?.filter(subscriptionFilter);
		data = data.map((d) => ({
			...d,
			reducerSelector: getInsightById(itemId),
			link: (id) => linkInsightRecords({ itemId, recIds: [id], recEntityName: d.entityType, attribute: d.attribute }),
			unlink: (id) => unLinkInsightRecords({ itemId, recIds: [id], recEntityName: d.entityType, attribute: d.attribute }),
		}));
	} else {
		const subscriptionFilter = (relation) => (isStandard ? relation.tabLabel === "ASPX_33" : true);
		const touchpointsRelations = (relation) => !["KPIS", "JS_71"].includes(relation.tabLabel);

		data = data.filter(touchpointsRelations)?.filter(subscriptionFilter);

		data = data.map((d) => ({
			...d,
			reducerSelector: makeGetCjmDataInteraction(stageId, itemId),
			link: (id) =>
				linkInteraction({
					recIds: [id],
					stageId,
					itemId,
					attribute: d.attribute,
					recEntityName: d.entityType,
				}),
			unlink: (id) => unlinkInteraction({ recIds: [id], stageId, itemId, attribute: d.attribute, recEntityName: d.entityType }),
		}));
	}

	return data;
};

RelationManager.displayName = "RelationManager";
RelatedItemsManager.displayName = "RelatedItemsManager";

RelationManager.propTypes = {
	stageId: PropTypes.string, // stageId is needed only on touchpoint
	item: PropTypes.object,
	updateFunction: PropTypes.func,
	mode: PropTypes.string,
};
export default RelationManager;

const NoData = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 23px;
`;

const NoDataIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NoDataText = styled.div`
	color: #605f60;
	text-align: center;
	font-family: "Inter";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
