import { encode } from "@utils/helpers";
import { urlOriginStatus } from "@utils/optionsSets/OptionSets";
import { updateProject } from "features/projects/reducer/ProjectsActions";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components/macro";
import EditTitle from "../../../shared/components/EditableText";

export const ProjectName = (project, rename, setRename, openToClick) => {
	const [projectName, setName] = useState(project?.name);
	const { pathname, search } = useLocation();
	const history = useHistory();
	const desactivateClick = !openToClick;
	const { cxProgramId } = search.getCxProps();
	const folderId = search.getFolderId();
	const blank = pathname === '/libraries' ? "_blank" : ""
	const Link = `/EditProject?id=${project?.id}${folderId ? `&folderid=${folderId}` : ""}`


	useEffect(() => {
		setName(project?.name);
	}, [project.name]);

	const handleRedirect = (e) => {
		if(pathname === '/EditCxProgram'){
			e.preventDefault(); // Prevents the default Link behavior
			history.push(`/EditProject?id=${project?.id}${cxProgramId? `&origin=${urlOriginStatus.DefaultReturn}` : ""}${folderId ? `&folderid=${folderId}` : ""}`);
		}
	};
	return (
		<div className="d-flex">
			{rename !== project?.id && (
					<Redirect
						className="d-flex align-items-center"
						desactivateclick={`${desactivateClick}`}
						onClick={handleRedirect}
						target={blank}
						to={Link}
						title={projectName}
						>
						<StyledName id={"input" + project?.id}>{projectName.defaultPlaceholder("PROJECT_NAME")}</StyledName>
					</Redirect>
			)}
			{rename === project?.id && (
				<EditTitle
					obj={project}
					obj_id={project.id}
					obj_name={projectName}
					updateObject={(modifiedProject, modifiedAttribNames) => updateProject({ modifiedProject: { ...modifiedProject, name: encode(modifiedProject.name) }, modifiedAttribNames })}
					setName={setName}
					setRename={setRename}
					nameAttribute={"name"}
				/>
			)}
		</div>
	);
};

const Redirect = styled(Link)`
	text-decoration: none;
	${(props) => props.desactivateclick === "true" && "pointer-events: none; cursor:default;"}
`;

const StyledName = styled.div`
	line-height: 20px;
	cursor: pointer;
	color: #333333;
	max-width: 100%;
	overflow: hidden;
	display: -webkit-box;

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	align-self: center !important;
	cursor: pointer;
	font-weight: 400 !important;
	    text-overflow: ellipsis;
    white-space: normal;
`;
