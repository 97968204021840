import { updateFolderLinks } from "@redux/index.js";
import { EMPTY_GUID, getEntityCurrentState } from "@utils/helpers.js";
import PersonaProfileCard, { ProfileCard } from "features/forms/personas/cards/persona-sections-cards/PersonaProfileCard.js";
import PersonaQuickCreate from "features/forms/personas/creation/PersonaQuickCreate.js";
import { createJourneyMap, resetCurrentMap } from "features/journey-map/reducer/CJMActions.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { GenericButton, IconDispatcher, SearchBar } from "shared/ui-basics/index";
import * as Style from "./Style.js";

import { GetActiveCJM, getActivePersona } from "@utils/helpers.js";
import { createAction } from "@reduxjs/toolkit";

export default function MapPersonas(props) {
	const { t } = useTranslation();
	const history = useHistory();
	const myRef = useRef(null);
	const { search } = useLocation()
	const dispatch = useDispatch();
	const showSampleData = useSelector((state) => state.settings.organization.showSampleData);
	const allPersonas = useSelector((state) => state.personas.all_personas);
	const all_maps = useSelector((state) => state.maps.all_maps);
	const cxProgramId = search.getCxProgramID();
	const folderId = search.getFolderId()

	const [allPersonaBase, setAllPErsonaBase] = useState(allPersonas);
	const personas = getActivePersona(allPersonas, showSampleData).sortAsc("name");
	const [showAnimation, setShowAnimation] = React.useState(true);
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [showWaveAnimation, setShowWaveAnimation] = React.useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const handleChange = (e) => {
		setGlobalFilterValue(e.target.value);
	};

	const Search = SearchBar({ globalFilterValue, handleChange, height: 40 });
	useEffect(() => {
		if (allPersonaBase.length !== allPersonas.length) {
			const newId = allPersonas[allPersonas.length - 1]?.personaId;
			props.setNewMap({ ...props.newMap, personaId: newId });
			handleFocus();
			setAllPErsonaBase(allPersonas);
		}
	}, [allPersonas]);

	const selectPersonas = (id) => {
		props.setNewMap({ ...props.newMap, personaId: id });
	};
	const create = () => {
		if (props.newMap.name.length === 0) {
			toast.info(t("WARNING_MAP_NAME"));
			return props.previousStep();
		}
		if (props.newMap.personaId === EMPTY_GUID) {
			return toast.info(t("WARNING_PERSONA"));
		}
		const onSuccess = (data) => {
			updateFolderLinks({
				folderIds: [folderId], idsToLink: [data.cjmId], entityName: "cem_customerjourney",
				onSuccess: () => history.push(`/journey-map${folderId ? `?folderId=${folderId}` : ""}${cxProgramId ? `&cxProgramId=${cxProgramId}` : ''}`)
			});
			setIsCreating(false)
		}
		setIsCreating(true)
		dispatch(resetCurrentMap());
		const SetCreationCJMParams = createAction("SET_CREATION_CJM_PARAMS")
		dispatch(SetCreationCJMParams(props.newMap))

		createJourneyMap({
			newMap: {
				...props.newMap,
				linkToCxProgramId: cxProgramId.isEmptyString() ? undefined : cxProgramId
			},
			goToMap: folderId ? false : true,
			history: history,
			onSuccess: folderId ? onSuccess : null
		});

	};

	const handleFocus = () => {
		setTimeout(() => {
			myRef.current?.scrollIntoView();
		}, 100);
	};

	const MagicSelect = () => {
		setShowWaveAnimation(true);
		setShowAnimation(false);
		const pers = personas.map((p) => {
			return { ...p, linkedCjms: GetActiveCJM(p.linkedCjms, all_maps) };
		});
		const mostCompleteMap = pers.reduce((prev, current) => {
			return prev.associatedContactsCount + prev.linkedCjms.length > current.associatedContactsCount + current.linkedCjms.length ? prev : current;
		});
		setTimeout(() => {
			setShowWaveAnimation(false);
			props.setNewMap({ ...props.newMap, personaId: mostCompleteMap.personaId });
			handleFocus();
		}, 1000);
	};

	return (
		<Style.StepWrapper showAnimation={showAnimation}>
			<Style.BackButton variant="primary-link" onClick={props.previousStep}>
				{IconDispatcher("ARROW_LEFT", "me-1")}
				{t("ASPX_132")}
			</Style.BackButton>
			<div className="text-center">
				<Style.Title className="mt-5">{t("ADDING_PERSONAS")}</Style.Title>
				<Style.WandContainer>
					<Style.MagicWand />
				</Style.WandContainer>
				<Style.SubTitle>{t(personas.length === 0 ? "NO_PERSONAS_WARNING" : "STEP3_SUBTITLE")}</Style.SubTitle>
			</div>
			<div className="border-bottom d-flex justify-content-end py-4">
				<div>{Search}</div>
			</div>
			<Style.Wrapper>
				{personas
					?.filter((perso) => perso.name.toLowerCase().includes(globalFilterValue.toLowerCase()))
					?.map((persona, index) => {
						return (
							<div key={index} ref={props.newMap.personaId === persona.personaId ? myRef : null}>
								<PersonaProfileCard personaId={persona.personaId} selectPersonas={selectPersonas} newMap={props.newMap} />
							</div>
						);
					})}
				<ProfileCard className="px-3" selected={false} selectable="false" onClick={null} noshadow="true">
					<PersonaQuickCreate />
				</ProfileCard>
			</Style.Wrapper>
			<div className="mt-5 d-flex justify-content-center position-relative">
				<Style.WandButton onClick={MagicSelect} showwave={showWaveAnimation.toString()}>
					{IconDispatcher("WAND")}
				</Style.WandButton>
				<GenericButton variant="primary" className="my-4 " onClick={create} disabled={isCreating}>
					{t("CREATE_JOURNEY_MAP")}
				</GenericButton>
			</div>
		</Style.StepWrapper>
	);
}




