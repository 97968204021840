import { getAllRegisteredUsers, getAllTeams } from "@redux/index";
import DataTable from "features/grid-table/DataTable";
import { getAllWorkspaces } from "features/workspace/reducer/WorkspaceActions";
import { useSelector } from "react-redux";
import DataLoader from "shared/components/data-loader";
import { PageTemplate } from "shared/global-layouts/PageTemplate";
import styled from "styled-components";
import { getSubscriptionDetails } from "../../reducer/SettingsActions";
import ContractDetailsCard from "../components/cards/contract-details-card";

const Users = () => {
	return (
		<DataLoader reduxActions={[getAllWorkspaces, getSubscriptionDetails, getAllRegisteredUsers, getAllTeams]}>
			<UsersPage />
		</DataLoader>
	);
};

const UsersPage = () => {
	const users = useSelector((state) => state.settings.users);
	const subscriptionDetails = useSelector((state) => state.settings?.subscriptionDetails);
	const contracts = subscriptionDetails?.subscriptionContracts?.sort((a, b) => b.subscriptionPlanValue - a.subscriptionPlanValue);

	return (
		<PageTemplate classNames="container-fluid px-5">
			<CardsContainer classNames="container-fluid">
				{contracts?.map((contract, i) => (
					<ContractDetailsCard key={i} contract={contract} users={users} />
				))}
			</CardsContainer>
			<DataTable context="users.manageUsers" />
		</PageTemplate>
	);
};

Users.displayName = "Users";
export default Users;

const CardsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	width: 100%;
	margin-bottom: 60px;
`;
