import Dropdown from "react-bootstrap/Dropdown";
import { useGetUserTeams } from "shared/hooks";
import { CustomDropdownButton } from "shared/ui-basics";
import styled from "styled-components/macro";

export const TeamsColumn = ({ user }) => {
	const userTeams = useGetUserTeams(user);

	const render = () => {
        if (userTeams?.length === 0) return "-";
        if (userTeams?.length === 1) return <Title>{userTeams[0]?.name}</Title>;
		return (
			<Dropdown>
				<Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownButton}>
					<Title>{userTeams?.length === 1 ? `${userTeams[0]?.name}` : `${userTeams[0]?.name} + ${userTeams?.length - 1}`}</Title>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{userTeams.map((team) => (
						<Dropdown.Item key={team?.id} disabled>
							<Title>{team?.name}</Title>
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	return <>{render()}</>;
};

TeamsColumn.displayName = "TeamsColumn";

const Title = styled.span`
	color: #605f60;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	align-self: flex-start;
`;
