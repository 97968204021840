import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, GenericButton } from "shared/ui-basics";
import styled, { css } from "styled-components/macro";
import { useModal } from "use-modal-hook";
import { SupportForm } from "../SupportForm";
import { DropdownWrapper } from "./nav-menu-header-style";

export default function NeedHelpOptions({ hideForPdf, innerRef }) {
	const { t } = useTranslation();
	const history = useHistory();

	const [isShow, setIsShow] = useState(false);
	const trainings = useSelector((state) => state?.settings?.trainings);
	const [showSupportModal] = useModal(SupportForm);

	const createHelpActions = () => {
		const actions = [];
		actions.push({ text: t("ON_DEMAND_TRAINING"), icon: "GRADUATION-HAT", type: "needHelp", handleClick: () => history.push(`/training`), disabled: trainings?.length === 0 });
		actions.push({ text: t("SEND_A_MESSAGE"), icon: "ENVELOPE", type: "needHelp", handleClick: showSupportModal });
		return actions;
	};

	const helpActions = createHelpActions();

	const handleClickItem = (action, e) => {
		e.stopPropagation();
		if (action?.handleClick) action?.handleClick();
		setIsShow(!isShow);
	};

	const handleToggle = (isOpen) => {
		if (isOpen) innerRef.current?.hide();
		setIsShow(isOpen);
	};

	return (
		<DropdownWrapper show={isShow} onToggle={handleToggle} autoClose={true}>
			<Dropdown.Toggle className="px-1" as={Button} variant="clean" isicon={false} size="l">
				<NeedHelp>
					{hideForPdf ? (
						<>
							{t("EXPORT_DATE")}
							<Moment format="DD/MM/YYYY">{new Date()}</Moment>
						</>
					) : (
						t("NEED_HELP") + "?"
					)}
				</NeedHelp>
			</Dropdown.Toggle>
			<DropdownMenu>
				{helpActions?.map((action, index) => (
					<div key={index}>
						<GenericButton
							variant="light-link"
							fullWidth={true}
							justifyStart={true}
							onClick={(e) => handleClickItem(action, e)}
							icon={action?.icon && action?.icon}
							disabled={action?.disabled}>
							<div className="ms-1">{action?.text}</div>
						</GenericButton>
					</div>
				))}
			</DropdownMenu>
		</DropdownWrapper>
	);
}

const NeedHelp = styled.div`
	color: #605f60;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	:hover {
		cursor: pointer;
		text-decoration: underline;
		opacity: 1;
	}
`;

const DropdownMenu = styled(Dropdown.Menu)`
	z-index: 1001;
	transform: translate(-16px, 30px) !important;
`;
