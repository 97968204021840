import { GenericButton } from "shared/ui-basics/buttons";
import { TouchpointSelectCard } from "../cx-cards/TouchpointSelectCard";

import PopoverEditMode from "shared/popovers/PopoverEditMode";
import { EMPTY_GUID } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const SelectionTouchPoint = ({ selectedTouchpointId, handleLinkTouchpoint, handleUnlinkTouchpoint, isEditable = true }) => {
    const { t } = useTranslation();

    const touchpoints = useSelector((state) => state.libraries.touchPoint);
    const inUseTouchpointsIds = useSelector((state) => state.maps.current_map.cjmData)
        ?.flat()
        ?.map((interaction) => interaction.touchPointId);
    const inUseTouchpoints = touchpoints?.filter((tp) => inUseTouchpointsIds?.includes(tp.id));
    const elementIds = selectedTouchpointId && selectedTouchpointId !== EMPTY_GUID ? [selectedTouchpointId] : [];
    const props = {
        popoverParams: {
            addLabel: "LINK_TOUCHPOINT",
            addFunction: handleLinkTouchpoint,
            type: "touchpoint",
            disabled: !isEditable,
            specificItems: inUseTouchpoints
        },
        RenderElement: (id, isEdit) => {
            const selectedTouchpoint = touchpoints?.find((tp) => tp.id === id);
            return (selectedTouchpoint ?
                <TouchpointSelectCard object={selectedTouchpoint} handleRemove={isEditable ? handleUnlinkTouchpoint : undefined} showBorders={true} isEdit={isEdit} />
                : <GenericButton key="linkTouchPoint" variant="light-link" size="s" icon="SQUARE_PLUS" iconClassName="me-1" disabled={!isEditable}>
                    {t("LINK_TOUCHPOINT")}
                </GenericButton>);
        }
    }

    return (
        <PopoverEditMode elementIds={elementIds}  {...props} />
    );
}
